import React from 'react';
import ProgressBar from 'components/FormControls/ProgressBar';
import './SingleRating.css';

const SingleRating = props => {
  const {rating} = props;
  const onHover = rating => {
    if (props.onHover) {
      props.onHover(rating);
    }
  };
  const onHoverOut = rating => {
    if (props.onHoverOut) {
      props.onHoverOut(rating);
    }
  };
  return (
    <div
      className="SingleRating"
      onMouseEnter={() => onHover(rating)}
      onMouseLeave={() => onHoverOut(rating)}>
      <span className="name">{rating.name}</span>
      <span className="progressbar">
        <ProgressBar percentage={(rating.rating * 100) / 5}></ProgressBar>
      </span>
      <span className="number">{rating.rating.toFixed(1)}</span>
    </div>
  );
};

export default SingleRating;
