import moment from 'moment-timezone';

// update moments fromNow configuration
const updateLocal = () => {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: timeString => {
        if (timeString === 'Now') {
          return timeString;
        } else {
          return `${timeString} ago`;
        }
      },
      s: 'Now',
      ss: 'Now',
      m: 'Now',
      mm: 'Now',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      w: '1w',
      ww: '%dw',
      M: '1m',
      MM: '%dm',
      y: '1y',
      yy: '%dy',
    },
  });
};
updateLocal();

const convertToSpecificTimezone = dateVal => {
  let timeZone = localStorage.getItem('UTZ');
  let systemTimeZone = moment.tz.guess();
  if (timeZone && timeZone !== '' && systemTimeZone !== timeZone) {
    return moment(dateVal).tz(timeZone, true);
  } else {
    return moment(dateVal).tz(systemTimeZone, true);
  }
};

const utcToLocalMoment = dateString => {
  //console.log("dateString",dateString);
  let timeZone = localStorage.getItem('UTZ');
  let systemTimeZone = moment.tz.guess();
  if (timeZone && timeZone !== '' && systemTimeZone !== timeZone) {
   // moment.tz.setDefault(timeZone);
    return moment.utc(dateString).tz(timeZone);
  }
  moment.tz.setDefault(systemTimeZone);
  return moment.utc(dateString).tz(systemTimeZone);
};

const utcToLocalDateFormat = (dateString, format = 'YYYY-MM-DD') => {
  return utcToLocalMoment(dateString).format(format);
};

const localToUtcMoment = dateString => {
  //console.log("dateString",dateString);
  // let timeZone=localStorage.getItem("UTZ")
  // if(timeZone && timeZone!==""){
  //   //console.log(" moment.tz(dateString,timeZone)", moment.tz(dateString,timeZone));
  //  //return moment.tz(dateString,timeZone).utc()
  // }
  return moment(dateString).utc();
};

const localToUtcDateTimeFormat = (dateString, format = 'YYYY-MM-DD HH:mm:ss') => {
  return localToUtcMoment(dateString).format(format);
};

const bigDateFormat = (dateString, format = 'MMMM DD, YYYY') => {
  //console.log('dateString');
  return dateOrCurrentDate(dateString).format(format);
};

const bigDateTimeFormat = (dateString, Y = false) => {
  const momentDate = dateOrCurrentDate(dateString);
  let formatedDateString = '';
  if (!Y) {
    if (isSameDay(momentDate)) {
      formatedDateString = 'Today, ' + dateOrCurrentDate(dateString).format('MMM DD \\at HH:mm');
    } else {
      formatedDateString = dateOrCurrentDate(dateString).format('dddd, MMM DD \\at HH:mm');
    }
  } else {
    if (isSameDay(momentDate)) {
      formatedDateString =
        'Today, ' + dateOrCurrentDate(dateString).format('MMM DD YYYY \\at HH:mm');
    } else {
      formatedDateString = dateOrCurrentDate(dateString).format('dddd, MMM DD YYYY \\at HH:mm');
    }
  }
  return formatedDateString;
};

const readableTimeFormat = dateString => {
  return dateOrCurrentDate(dateString).format('h:mm a');
};

const dateFormat = (dateString, format = 'YYYY-MM-DD') => {
  return dateOrCurrentDate(dateString).format(format);
};

const timeFormat = (dateString, format = 'HH:mm') => {
  return dateOrCurrentDate(dateString).format(format);
};

const dateTimeFormat = (dateString, format = 'YYYY-MM-DD HH:mm') => {
  return dateOrCurrentDate(dateString).format(format);
};

const dateOrCurrentDate = dateStringOrObject => {
  let momentInstance = null;
  if (dateStringOrObject) {
    if (dateStringOrObject instanceof moment) {
      momentInstance = dateStringOrObject.clone();
    } else {
      momentInstance = moment(dateStringOrObject);
    }
  } else {
    momentInstance = moment();
  }

  return momentInstance;
};

const isBetweenDates = (start, end, between) => {
  const startDate = dateOrCurrentDate(start);
  const endDate = dateOrCurrentDate(end);
  const betweenDate = dateOrCurrentDate(between);
  return betweenDate.isBetween(startDate, endDate);
};

const isAfter = (start, todayOrBeforeStart) => {
  // Is start is after todayOrBeforeStart
  const todayOrBeforeStartDatetime = dateOrCurrentDate(todayOrBeforeStart);
  const startDatetime = dateOrCurrentDate(start);
  return startDatetime.isAfter(todayOrBeforeStartDatetime);
};

const isBefore = (start, todayOrAfterStart) => {
  // Is start is before todayOrAfterStart
  const todayOrAfterStartDatetime = dateOrCurrentDate(todayOrAfterStart);
  const startDatetime = dateOrCurrentDate(start);
  return startDatetime.isBefore(todayOrAfterStartDatetime);
};

const isSame = (start, todayOrBeforeStart) => {
  // Is start is after todayOrBeforeStart
  const todayOrBeforeStartDatetime = dateOrCurrentDate(todayOrBeforeStart);
  const startDatetime = dateOrCurrentDate(start);
  return startDatetime.isSame(todayOrBeforeStartDatetime);
};

const isSameDay = (start, todayOrBeforeStart) => {
  const todayOrBeforeStartDatetime = dateOrCurrentDate(todayOrBeforeStart).startOf('day');
  const startDatetime = dateOrCurrentDate(start).startOf('day');
  return startDatetime.isSame(todayOrBeforeStartDatetime);
};

const isInLastNDay = (betweenDateString, n = 1) => {
  const endDate = dateOrCurrentDate();
  const startDate = endDate.clone().add(-1 * n, 'days');
  const betweenDate = dateOrCurrentDate(betweenDateString);
  return isBetweenDates(startDate, endDate, betweenDate);
};

const isSameOrAfter = (start, todayOrBeforeStart) => {
  // Is start is after todayOrBeforeStart
  const todayOrBeforeStartDatetime = dateOrCurrentDate(todayOrBeforeStart);
  const startDatetime = dateOrCurrentDate(start);
  return startDatetime.isSameOrAfter(todayOrBeforeStartDatetime);
};

const isSameOrBefore = (start, todayOrAfterStart) => {
  // Is start is after todayOrAfterStart
  const todayOrAfterStartDatetime = dateOrCurrentDate(todayOrAfterStart);
  const startDatetime = dateOrCurrentDate(start);
  return startDatetime.isSameOrBefore(todayOrAfterStartDatetime);
};

const duration = (end, start, differenceIn = 'minutes') => {
  // Is start is after todayOrBeforeStart
  const endDate = dateOrCurrentDate(end);
  const startDate = dateOrCurrentDate(start);
  return endDate.diff(startDate, differenceIn);
};

const startOf = (type = 'week', date) => {
  const dateObj = dateOrCurrentDate(date);
  return dateObj.startOf(type);
};

const endOf = (type = 'week', date) => {
  const dateObj = dateOrCurrentDate(date);
  return dateObj.endOf(type);
};

const checkIsDateAfterOrToday = currentDatePickerDate => {
  const startOfCurrentDay = startOf('day', dateOrCurrentDate());
  return isSameOrAfter(currentDatePickerDate, startOfCurrentDay);
};

const checkIsDateBeforeOrToday = currentDatePickerDate => {
  const startOfCurrentDay = startOf('day', dateOrCurrentDate());
  return isSameOrAfter(startOfCurrentDay, currentDatePickerDate);
};
const timeZoneAbbreviated = () => {
  let timeZone = localStorage.getItem('UTZ');
  let systemTimeZone = moment.tz.guess();
  let tz = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "long" })
    .split(" ");
    if (timeZone && timeZone !== "" && timeZone !== systemTimeZone) {
      tz = new Date()
        .toLocaleTimeString("en-us", {
          timeZone: timeZone,
          timeZoneName: "long",
        })
        .split(" ");
    }
    if (tz.length > 2) {
      tz = tz.splice(2).join(" ");
    }
    
    if (tz.includes(" ")) {
      return tz
        .split(" ")
        .map(([first]) => first)
        .join("");
    } else {
      return tz;
    }
  // if (timeZone && timeZone !== '' && systemTimeZone !== timeZone) {
  //   return moment.tz(timeZone).format('z');
  // }
  // return moment.tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
};

const timeZoneFullName = () => {
  // EST: 'Eastern Standard Time',
  // CST: 'Central Standard Time',
  // MST: 'Mountain Standard Time',
  // PST: 'Pacific Standard Time',
  // AST: 'Alaska Standard Time',
  // HST: 'Hawaii-Aleutian Standard Time',
  let timeZoneKL = localStorage.getItem('UTZ');
  let systemTimeZoneKL = moment.tz.guess();
  let tz = new Date().toLocaleTimeString('en-us', {timeZoneName: 'long'}).split(' ');
  if (timeZoneKL && timeZoneKL !== '' && timeZoneKL !== systemTimeZoneKL) {
    tz = new Date()
      .toLocaleTimeString('en-us', {timeZone: timeZoneKL, timeZoneName: 'long'})
      .split(' ');
  }

  if (tz.length > 2) {
    tz = tz.splice(2).join(' ');
  }
  return tz;
};

const momentHelper = {
  dateOrCurrentDate,
  utcToLocalMoment,
  localToUtcMoment,
  localToUtcDateTimeFormat,
  utcToLocalDateFormat,
  isBetweenDates,
  isAfter,
  isBefore,
  isSameOrAfter,
  isSameOrBefore,
  isSame,
  duration,
  startOf,
  endOf,
  dateFormat,
  timeFormat,
  dateTimeFormat,
  bigDateFormat,
  readableTimeFormat,
  isSameDay,
  bigDateTimeFormat,
  isInLastNDay,
  checkIsDateAfterOrToday,
  checkIsDateBeforeOrToday,
  timeZoneAbbreviated,
  timeZoneFullName,
  convertToSpecificTimezone,
};

export default momentHelper;
