import React from 'react';
import {withRouter} from 'react-router-dom';
import PatientLandingHeader from 'layout/PatientLayout/PatientLandingHeader';
import AppointmentConnecting from 'components/Patient/AppointmentConnecting';
import DoctorToday from 'components/Patient/DoctorToday';
import DoctorArticle from 'components/Patient/DoctorArticle';
import AmazingDoctor from 'components/Patient/AmazingDoctor';
import BuiltDoctors from 'components/Patient/BuiltDoctors';
import PatientFooter,{PatientFooterNew} from 'layout/PatientLayout/PatientFooter';
import Divider from 'components/Divider';
import DoctorNearMe from "components/Patient/DoctorNearMe"
import './PatientLanding.css';
import ShowProviderAvailability from 'components/ShowProviderAvailability';

const PatientLanding = props => {
  return (
    <div>
      <PatientLandingHeader />
      <div className="PatientLanding">
        <AppointmentConnecting />
        <DoctorToday />
        <DoctorArticle />
        <div className="divider">
          <Divider />
        </div>
        <BuiltDoctors />
        {/* <DoctorNearMe /> */}
        <div className="gradient">
          <AmazingDoctor />
          <PatientFooterNew />
        </div>
        <ShowProviderAvailability />
      </div>
    </div>
  );
};

export default withRouter(PatientLanding);
