import React, {useContext, useState} from 'react';
import _ from 'lodash';
import CircleButton from 'components/CircleButton';
import Checkbox from 'components/FormControls/Checkbox';
import Input from 'components/FormControls/Input';
import AuthContext from 'context/AuthContext';
import validators from 'data/validators';
import selectOptions from 'data/selectOptions';
import './PatientDemographic.css';

const {demographicRaceOptions, demographicEthnicityOptions} = selectOptions;
const {__required, _scrollToView} = validators;

const PatientDemographic = props => {
  const {onDemographicUpdate} = props;
  const authContext = useContext(AuthContext);
  const [race, ethnicity] = _.get(authContext, 'auth.demographicInformation', []);
  const zipCode = _.get(authContext, 'auth.zipCode', '');
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  const [formData, setFormData] = useState({
    values: {
      raceValues: [...race.values],
      raceOtherText: race.otherText,
      ethnicityValue: ethnicity.value,
      zipCode: zipCode,
    },
    errors: {},
  });
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validateRaceValues = (value = null) => {
    const fValue = value !== null ? value : formValues['raceValues'];
    if (fValue.length === 0) {
      return 'Race is required';
    }
    return null;
  };
  const __validateEthnicityValue = (value = null) => {
    const fValue = value !== null ? value : formValues['ethnicityValue'];
    if (fValue === '') {
      return 'Ethnicity is required';
    }
    return null;
  };
  const __validateRaceOtherText = (value = null, raceValues) => {
    const fValue = value !== null ? value : formValues['raceOtherText'];
    if (raceValues.includes(0) && !__required(fValue)) {
      return 'This field is required';
    }
    return null;
  };

  const __validateForm = () => {
    const validRaceValues = !__validateRaceValues();
    const validEthnicityValue = !__validateEthnicityValue();
    const validRaceOtherText = !__validateRaceOtherText(null, formValues['raceValues']);
    return validRaceValues && validRaceOtherText && validEthnicityValue;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name} = event.target;
    let {value} = event.target;
    let errorObject = {
      [name]: null,
    };
    let updateObject = {};
    switch (name) {
      case 'raceValues':
        updateObject = {
          raceOtherText: value === 0 ? '' : formValues['raceOtherText'],
        };

        value = _.xor(formValues['raceValues'], value || value === 0 ? [value] : []);

        errorObject = {
          [name]: __validateRaceValues(value),
        };
        break;
      case 'raceOtherText':
        errorObject = {
          [name]: __validateRaceOtherText(value, formValues['raceValues']),
        };
        break;
      case 'ethnicityValue':
        //value = formValues['ethnicityValue'] !== value ? value : '';
        errorObject = {
          [name]: __validateEthnicityValue(value),
        };
        break;
      default:
        break;
    }
    // setFormData({
    //   ...formData,
    //   values: {
    //     ...formData.values,
    //     [name]: value,
    //     ...updateObject,
    //   },
    //   errors: {
    //     ...formData.errors,
    //     ...errorObject,
    //   },
    // });

    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...formData.values,
          [name]: value,
          ...updateObject,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const handleForm = () => {
    setSaveBtnClicked(true);
    if (!isValidForm) {
      let valError = ['raceValues', 'ethnicityValue', 'raceOtherText'];

      valError.forEach(name => {
        changeHandler({
          target: {
            name: name,
            value:
              name === 'raceValues'
                ? formValues[name].length > 0
                  ? formValues[name]
                  : ''
                : formValues[name],
          },
        });
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }

    const formatedData = {
      demographicInformation: [
        {...race, otherText: formValues['raceOtherText'], values: formValues['raceValues']},
        {...ethnicity, value: formValues['ethnicityValue']},
      ],
      zipCode: formValues['zipCode'],
    };
    onDemographicUpdate(formatedData);
  };

  const toggleRaceValues = id => {
    const customEvent = {
      target: {
        value: id,
        name: 'raceValues',
      },
    };
    changeHandler(customEvent);
  };

  const setEthnicityValue = id => {
    const customEvent = {
      target: {
        value: id,
        name: 'ethnicityValue',
      },
    };
    changeHandler(customEvent);
  };

  const ziplabelComponent = (
    <>
      <span>ZIP</span>
      <span className="light-txt"> (optional)</span>
    </>
  );
  return (
    <div className="PatientDemographic">
      <div className="h3">Demographic Info</div>
      <div className="PatientDemographic-inner">
        <form>
          <div className="demographic-container">
            <div className="demographic-block">
              <div className="heading2 Input-control">
                Race <span className="sub-label">select one or more</span>
                {demographicRaceOptions.length > 0 &&
                  saveBtnClicked &&
                  formErrors['raceValues'] && (
                    <span className="error-text">{formErrors['raceValues']}</span>
                  )}
              </div>
              {demographicRaceOptions.map(option => {
                return (
                  <Checkbox
                    onChange={() => toggleRaceValues(option.id)}
                    key={option.id}
                    value={formValues.raceValues.includes(option.id)}
                    containerClass="lighBlueCheck">
                    {option.label}
                  </Checkbox>
                );
              })}
              <Checkbox
                value={formValues.raceValues.includes(0)}
                onChange={() => toggleRaceValues(0)}
                containerClass="lighBlueCheck">
                Other
              </Checkbox>
              {formValues.raceValues.includes(0) && (
                <Input
                  value={formValues['raceOtherText']}
                  name="raceOtherText"
                  onChange={changeHandler}
                  onBlur={changeHandler}
                  error={formErrors['raceOtherText']}
                  containerClass="two-input"
                  type="text"
                  label=""
                />
              )}
            </div>
            <div className="demographic-block">
              <div className="heading2 Input-control">
                Ethnicity
                {demographicEthnicityOptions.length > 0 &&
                  saveBtnClicked &&
                  formErrors['ethnicityValue'] && (
                    <span className="error-text">{formErrors['ethnicityValue']}</span>
                  )}
              </div>
              {demographicEthnicityOptions.map(option => {
                return (
                  <Checkbox
                    onClick={e => {
                      if (e.target.checked) {
                        setEthnicityValue(option.id);
                      } else {
                        setEthnicityValue('');
                      }
                    }}
                    key={option.id}
                    value={formValues['ethnicityValue'] === option.id}
                    containerClass="lighBlueCheck">
                    {option.label}
                  </Checkbox>
                );
              })}
            </div>
            <Input
              onChange={changeHandler}
              onBlur={changeHandler}
              value={formValues['zipCode']}
              name="zipCode"
              containerClass="two-input"
              type="text"
              label={ziplabelComponent}
            />
          </div>
          <div className="PatientDemographic-button">
            <CircleButton onClick={handleForm}>Save</CircleButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PatientDemographic;
