import React, {Component} from 'react';
import momentHelper from 'data/moment/momentHelper';

const {dateFormat} = momentHelper;

class AlgoConnect extends Component {
  componentDidMount() {
   // console.log(this.props);
    const query = new URLSearchParams(this.props.location.search);
    let data = query.get('data');
    if (data.length > 0) {
      data = JSON.parse(decodeURIComponent(data));
    }
    const {selectedResult} = data;
    if(selectedResult.algo && selectedResult.algo==="null")
    {
      selectedResult.algo=null
    }
    const {date, ...otherFilter} = data;
    const filter = {
      date: dateFormat(data['date'], 'YYYY-MM-DD'),
      ...otherFilter,
    };
    let finalData = {
      pathname: `/doctors`,
      state: {
        filter,
        answers: [],
        specialityIds: [],
      },
    };
    if (selectedResult) {
      switch (selectedResult.type) {
        case 'specialty':
          if (selectedResult.algo) {
            finalData.pathname = `/specialist/${selectedResult.algo}/${selectedResult.name}`;
          }
          break;
        case 'doctor':
          finalData.pathname = `/doctor`;
          break;
        case 'symptom':
          if (selectedResult.algo) {
            finalData.pathname = `/symptom/${selectedResult.algo}/${selectedResult.name}`;
          }
          break;
        case 'procedure':
          if (selectedResult.specialityIds) {
            if (selectedResult.algo) {
              finalData.pathname = `/procedure/${selectedResult.algo}/${selectedResult.name}`;
            } else {
              finalData.pathname = `/doctors`;
            }
          }
          break;
        default:
          break;
      }
    }
    this.props.history.push(finalData);
  }
  render() {
    return <></>;
  }
}

export default AlgoConnect;
