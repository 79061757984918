import axiosInstance from 'config/axios';

const getAllPains = () => {
  return axiosInstance.get('v1/master/pains');
};

const getAllProcedures = () => {
  return axiosInstance.get('v1/master/procedures');
};

const getAllVisitReasons = (isWithNoLoader = false) => {
  let config = {};
  if (isWithNoLoader) config = {noLoader: true};
  return axiosInstance.get('v1/master/visit-reasons', config);
};

const getAllSpecialties = (isWithNoLoader = false) => {
  let config = {};
  if (isWithNoLoader) config = {noLoader: true};
  return axiosInstance.get('v1/master/specialties', config);
};
const getAllSpecialtiesNearme = (isWithNoLoader = false) => {
  let config = {};
  if (isWithNoLoader) config = {noLoader: true};
  return axiosInstance.get('v1/master/specialties-nearme', config);
};

const getAllCertifications = () => {
  return axiosInstance.get('v1/master/certifications');
};

const getAllSuffixes = () => {
  return axiosInstance.get('v1/master/suffixes');
};

const getAllMyPracticeLocationList = () => {
  return axiosInstance.get('v1/practice/locations');
};

const getAllInsurancePlan = (isWithNoLoader = false) => {
  let config = {};
  if (isWithNoLoader) config = {noLoader: true};
  return axiosInstance.get('v1/master/insurances', config);
};
const getAllInsurancePlanPaginate = (isWithNoLoader = false,args={}) => {
  let config = {};
  if (isWithNoLoader) config = {noLoader: true};
  return axiosInstance.post('v1/master/insurances-filter',args, config);
};
const getAllInsuranceCompanysPaginate = (isWithNoLoader = false,args={}) => {
  let config = {};
  if (isWithNoLoader) config = {noLoader: true};
  return axiosInstance.post('v1/master/insurance-company/list',args, config);
};
const getAllInsurancePlansPaginate = (isWithNoLoader = false,args={}) => {
  let config = {};
  if (isWithNoLoader) config = {noLoader: true};
  return axiosInstance.post('v1/master/insurance-plan/list',args, config);
};

const getAllLanguages = () => {
  return axiosInstance.get('v1/master/languages');
};

const getAllStates = () => {
  return axiosInstance.get('v1/master/states');
};

const getCities = stateId => {
  return axiosInstance.get(`v1/master/cities/${stateId}`);
};

const getSiteSettings = () => {
  return axiosInstance.get('v1/master/site-setting');
};
const getEntryPointList = () => {
  return axiosInstance.get('v1/developer/nearme/fetchSpecialitiesByCity');
};
export default {
  getAllPains,
  getAllSpecialties,
  getAllCertifications,
  getAllSuffixes,
  getAllMyPracticeLocationList,
  getAllProcedures,
  getAllVisitReasons,
  getAllInsurancePlan,
  getAllInsurancePlanPaginate,
  getAllInsuranceCompanysPaginate,
  getAllInsurancePlansPaginate,
  getAllLanguages,
  getAllStates,
  getCities,
  getSiteSettings,
  getEntryPointList,
  getAllSpecialtiesNearme
};
