import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import images from 'data/images';
import './AnswerRating.css';

const AnswerRating = props => {
  const {value = 0, name = '', onChange = () => {}, isShowError = true, titles = null} = props;
  const [star, setStar] = useState(value);

  useEffect(() => {
    setStar(value);
  }, [value]);

  const startsComponent = [];
  for (let i = 1; i <= 5; i++) {
    let spanProps = {};
    const title = _.get(titles, i - 1);
    if (title) {
      spanProps = {
        title,
      };
    }
    startsComponent.push(
      <span
        {...spanProps}
        onMouseEnter={() => setStar(i)}
        onClick={() => {
          setStar(i);
          onChange({
            target: {
              name,
              value: i,
            },
          });
        }}
        key={i}
        className={`${i > star ? 'disable' : ''}`}>
        <img src={images.fillStar} alt="star" />
      </span>,
    );
  }
  return (
    <div className="AnswerRating">
      <div className={`answer-text ${!value && isShowError ? 'no-select' : ''}`}>
        {props.question}
      </div>
      <div className="rating" onMouseLeave={() => setStar(value)}>
        {startsComponent}
      </div>
    </div>
  );
};

export default AnswerRating;
