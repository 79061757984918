import axiosInstance from 'config/axios';

const joinUs = data => {
  var form = new FormData();
  Object.keys(data).map((i) => form.append(i, data[i]));
  return axiosInstance.post('v1/join-us', form, {
    headers: {
        "content-Type": "multipart/form-data",
    },
});
};

const getAlljoinUs = () => {
  return axiosInstance.get('v1/join-us');
};

const verifyJoinUsByToken = token => {
  return axiosInstance.get(`v1/join-us/verify/${token}`);
};
const rejectUser = token => {
  return axiosInstance.get(`v1/join-us/reject/${token}`);
};
const waitUser = token => {
  return axiosInstance.get(`v1/join-us/wait/${token}`);
};
export default {
  joinUs,
  getAlljoinUs,
  verifyJoinUsByToken,rejectUser,waitUser
};
