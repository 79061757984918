import React, {useContext, useEffect, useState} from 'react';
import images from 'data/images';
import {Link, useHistory} from 'react-router-dom';
import subscriptionService from 'services/subscription';
import _ from 'lodash';
import constants from 'data/constants';
import momentHelper from 'data/moment/momentHelper';
import './PracticeAccount.css';
import RootContext from 'context/RootContext';
import AuthContext from 'context/AuthContext';
import modalConfig from 'modals/modalConfig';
import StripeInfoModal from 'components/AfterAuth/Settings/BillingTab/ChangeCardModal/StripeInfoModal';
import reviewService from 'services/review';
import {Relogin} from 'components/AfterAuth/ConnectSocial/ReloginMsg';

import PaymentModal from '../PaymentModal';
import config from 'config/config';
//import PaymentSession from
const {getgoogleSSOConnect} = reviewService;
const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;
const {getSubscriptionDetails} = subscriptionService;
const {dateFormat} = momentHelper;

const PracticeAccount = props => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [showStripeInfo, setShowStripeInfo] = useState(false);
  const history = useHistory();
  const rootContext = useContext(RootContext);
  const authContext = useContext(AuthContext);

  const {updateSubscriptionText, getSubscriptionText, auth} = authContext;
  useEffect(() => {
    if (config.showStripe == true) {
      getSubscription();
    }
  }, []);

  const getSubscription = async () => {
    const response = await getSubscriptionDetails();
    if (response && response.data) {
      if (_.get(response.data, 'code', '') === 'yourStripeAccountNotCreated') {
        setSubscriptionData({
          status: 3, //Payment method require
        });
      } else {
        const {data = {}} = response;
        setSubscriptionData(data);
        if (getSubscriptionText !== 'subscribedAccount') {
          updateSubscriptionText('subscribedAccount');
        }
      }
    }
  };

  const nextBillingDate = new Date(_.get(subscriptionData, 'nextBillingDate', 0) * 1000).toString();
  const nextBillingDateLong = dateFormat(nextBillingDate, 'MMMM DD, YYYY');
  const trialEndDate = new Date(_.get(subscriptionData, 'trialEndDate', 0) * 1000);

  const nextBillingDateSort = subscriptionData?.nextBillingDate
    ? dateFormat(nextBillingDate, 'MM/D/YY')
    : null;
  const nextBillAmount = _.get(subscriptionData, 'nextBillAmount', 0) / 100; //cent to dollar
  const cardLastDigit = _.get(subscriptionData, 'cardLastDigit', '****');
  const cardExpiry =
    _.get(subscriptionData, 'cardExpiryMonth', '**') +
    '/' +
    _.get(subscriptionData, 'cardExpiryYear', '****');
  const cardType = _.get(subscriptionData, 'brand', 'American Express');

  const isTrial = new Date() < trialEndDate;
  let remainingTrialDays = 0;
  if (isTrial) {
    const diffTime = Math.abs(trialEndDate - new Date());
    remainingTrialDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
  const statusText = isTrial
    ? `${remainingTrialDays} days left of trial`
    : constants.StripeStatus[_.get(subscriptionData, 'status', '1')];
  if (
    subscriptionData &&
    subscriptionData.status &&
    !showStripeInfo &&
    (new Date() > nextBillingDate || [0, 3, 4].includes(subscriptionData.status))
  ) {
    if (!auth.email.endsWith('kaly.com')) setShowStripeInfo(true);
  } else if (
    subscriptionData &&
    subscriptionData.status &&
    subscriptionData.status == 1 &&
    showStripeInfo
  ) {
    setShowStripeInfo(false);
  }
  const getAndSetConnections = async () => {
    if (localStorage.getItem('showreviewLogin') == 'Y') {
      const response = await getgoogleSSOConnect().catch(async e => {
        if (e.message == 'Reconnection to Google is required.') {
          let deleteConfirm = await Relogin({
            context: rootContext,
            text: `Your Google My Business Review Account has been disconnected.  Please sign in it again.`,
          });
          localStorage.setItem('showreviewLogin', 'N');
          if (!deleteConfirm) return false;
          if (deleteConfirm == 'Go to Reviews') {
            history.push('/Reviews/account?tab=ConnectSocial');
          }
        }
      });
      if (response) {
        localStorage.setItem('showreviewLogin', 'N');
      }
    }
  };
  useEffect(() => {
    if (showStripeInfo) {
      const {setGlobal} = rootContext;
      // setGlobal(
      //   'modal',
      //   blankModalData({
      //     CustomComponent: StripeInfoModal,
      //     customComponentProps: {
      //       nextBillingDateSort: nextBillingDateSort,
      //       cardLastDigit: cardLastDigit,
      //       cardExpiry: cardExpiry,
      //       nextBillAmount: nextBillAmount,
      //       showcloseBtn:false,
      //       updateSubscriptionData: () => {
      //         getSubscription();
      //         // getInvoiceData();
      //       },
      //     },
      //     closeOnBGClick:false
      //   }),
      // );
      setGlobal(
        'modal',
        blankModalData({
          CustomComponent: PaymentModal,
          modalWrapperClass: 'mega-modal',
          customComponentProps: {
            nextBillingDateSort: nextBillingDateSort,
            cardLastDigit: cardLastDigit,
            cardExpiry: cardExpiry,
            nextBillAmount: nextBillAmount,
            showcloseBtn: false,
            hideTrail: true,
            updateSubscriptionData: () => {
              getSubscription();
              // getInvoiceData();
            },
          },
          closeOnBGClick: false,
        }),
      );
    } else if (subscriptionData) {
      //setGlobal('modal', { showModal: false });
      getAndSetConnections();
    }
  }, [showStripeInfo, subscriptionData]);
  if (!subscriptionData) return null;
  return (
    <div className="PracticeAccount">
      <div className="box-panel">
        <div className="box-header">
          <div className="heading3">
            <img src={images.logosymbol} alt="logo" /> Practice Account
          </div>
        </div>
        <div className="box-body">
          <div className="provider">
            <span className="providerCount">
              {_.get(subscriptionData, 'numberOfProvider', 1)} Providers
            </span>
            {_.get(subscriptionData, 'numberOfProvider', 1) < 5 && (
              <Link to="/settings?tab=providers">Add Providers</Link>
            )}
          </div>
          {subscriptionData.nextBillingDate && (
            <div className="paymentInfomation">
              Next monthly payment on
              <span>{nextBillingDateLong}</span>
            </div>
          )}
        </div>
        <div className="box-footer">
          <span className="palnleftdays">{statusText}</span>
        </div>
      </div>
    </div>
  );
};

export default PracticeAccount;
