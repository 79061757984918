import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './pdfviewer.css';
const Viewer = props => {
  const [loading, setLoading] = useState(true);
  let {url,setVisible} = props;
  const onLoad = () => {
    setLoading(false);
  };
  return (
    <>
    <div className="top-control">
      <button onClick={()=>setVisible(false)} className="close-btn">X</button>
    </div>
    <div className="viewer">
    <div style={{display: loading ? 'block' : 'none', padding: '100px'}}>
          {' '}
          <div style={{position: 'relative'}} className="Loader">
            Loader
          </div>
        </div>
      <iframe style={{display: loading===true ? 'none' : 'block'}} onLoad={onLoad} src={url}></iframe>
    </div>
    </>
  );
};
export default function PDFViewer(props) {
  const {url, setVisible} = props;  
  const divclick = () => {
    setVisible(false);
  };
 

 
  const parentDiv = document.createElement('div');
  parentDiv.className = 'pdf-viewer';
  parentDiv.onclick = divclick;
  useEffect(() => {
    document.body.appendChild(parentDiv);
    // returned function will be called on component unmount
    return () => {
      document.body.removeChild(parentDiv);
    };
  }, []);
  
  return ReactDOM.createPortal(<Viewer {...props} />, parentDiv);
}
