import React, {useState, useContext, useEffect} from 'react';
import _ from 'lodash';
import CircleButton from 'components/CircleButton';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import EditVisitReasonModal from './EditVisitReasonModal';
import {useLocation} from 'react-router-dom';
import './VisitReasons.css';

const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;

const VisitReasons = props => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const showVisitReason = query.get('visitReason');
  const {data, onSaveVisitReasons,checkSpecialitChange} = props;
  const {
    currentProviderName,
    specialties,
    visitReasons,
    newAppointmentTime,
    existingAppointmentTime,
    isAdmin,
    currentProviderId,
  } = data;

  const updatedSpecialties = _.map(specialties, specialty => {
    specialty.selectedVisitReasons = _.filter(visitReasons, {specialtyId: specialty.id});
    return specialty;
  });
  const [activeSpecialityTab, setActiveSpecialityTab] = useState(() => {
    if (updatedSpecialties.length > 0) {
      return updatedSpecialties[0];
    }
    return null;
  });
  const rootContext = useContext(RootContext);

  useEffect(() => {
    if (updatedSpecialties.length > 0) {
      setActiveSpecialityTab(updatedSpecialties[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitReasons]);

  useEffect(() => {
    if (showVisitReason) {
      openEditModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openEditModal = () => {
    const {setGlobal} = rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: EditVisitReasonModal,
        customComponentProps: {
          data: {
            currentProviderName,
            newAppointmentTime,
            existingAppointmentTime,
            isAdmin,
            currentProviderId,
            specialties:specialties,
          },
          onSaveVisitReasons,
        },
      }),
    );
  };

  const tabHeaderList = (
    <ol className="tab-list">
      {updatedSpecialties.map(specialty => {
        return (
          <li
            key={specialty.id}
            className={`visit-tab-list-item ${
              specialty === activeSpecialityTab ? 'visit-tab-list-active' : ''
            }`}
            onClick={() => setActiveSpecialityTab(specialty)}>
            {specialty.specialty}
          </li>
        );
      })}
    </ol>
  );
  const renderActiveTabContent = () => {
    const selectedVisitReasons = _.get(activeSpecialityTab, 'selectedVisitReasons', []);
    const activeTabComponent = (
      <>
        {selectedVisitReasons.map(visitReason => {
          return (
            <div key={visitReason.id} className="visit-list">
              {visitReason.reason}
            </div>
          );
        })}
      </>
    );
    return activeTabComponent;
  };

  return (
    <div className="VisitReasons">
       {checkSpecialitChange&&(
         <div className='blockEdit'></div>
       )}
      <div className="heading">
        <div className="h3">Add Visit Reasons</div>
        
        <CircleButton btnType="whiteButton" onClick={openEditModal}>
          Add/Edit Visit Reasons
        </CircleButton>
      </div>
      {checkSpecialitChange&&(
        <p style={{color:'red'}}>Save specialty change and continue Add/Edit Visit Reasons</p>
        )}
      <div className="visit-reason-tab">
        {tabHeaderList}
        <div className="visit-tab-content">{renderActiveTabContent()}</div>
      </div>
    </div>
  );
};

export default VisitReasons;
