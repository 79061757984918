const questionList = [
  {
    id: 161,
    question: 'Do you have any of these symptoms?',
    // if optionType is not specified we will use default UI
    optionType: 1, // {1=>'single line options',2=>'select option'} // other will be default UI
    descriptions: [
      'Fever greater than 100.3°F',
      'Recent severe traumatic injury to your back.',
      'Sudden and rapid loss of muscle strength.',
      'Loss of bowel or bladder control.',
      'History of cancer and sudden onset back pain.',
    ],
    options: [
      {nextQuestionId: 167, label: 'Yes', shortLabel: 'Red flags? Yes'},
      {nextQuestionId: 162, label: 'No', shortLabel: 'Red flags? Yes'},
    ],
  },
  {
    id: 162,
    question: 'How long have you had back issues for?',
    descriptions: [],
    options: [
      {nextQuestionId: 163, label: 'Less than 3 weeks', shortLabel: '<3 weeks'},
      {nextQuestionId: 164, label: 'More than 3 weeks', shortLabel: '>3 weeks'},
    ],
  },
  {
    id: 163,
    question: 'Which type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'N',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching, and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'E',
        label: 'Preliminary imaging (Xrays) and/or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'E',
        label: 'Not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
        showKalyMd: true,
      },
    ],
  },
  {
    id: 164,
    question: 'Are you considering surgery?',
    descriptions: [],
    options: [
      {groupId: 'O', label: 'Yes, in the near future', shortLabel: 'Interested in surgery'},
      {nextQuestionId: 165, label: 'Not at this time', shortLabel: 'No surgery'},
    ],
  },
  {
    id: 165,
    question: 'Are you considering minimally invasive procedures?',
    descriptions: [],
    options: [
      {groupId: 'P', label: 'Yes, such as an injection', shortLabel: 'Open to invasive procedures'},
      {nextQuestionId: 166, label: 'Not at this time', shortLabel: 'No invasive procedures'},
    ],
  },
  {
    id: 166,
    question: 'Which type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'N',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching, and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'Q',
        label: 'Diagnostic imaging studies (MRI/Xray) and/or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'Q',
        label: 'Not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
      },
    ],
  },
  {
    id: 167,
    question:
      'You may have a condition that requires immediate medical attention, such as seeking care at an Emergency Room or Urgent Care Center. Kaly does not diagnose conditions and is not meant to be a replacement for a trained medical professional.',
    optionType: 1,
    descriptions: [
      'Would you still like to see doctors who may help you after you seek urgent care?',
    ],
    options: [
      {nextQuestionId: 162, label: 'Yes'},
      {groupId: 'A', label: 'No'},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 161,
    treeDepth: 6,
  },
};
