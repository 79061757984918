import React from 'react';
import images from 'data/images';
import _ from 'lodash';
import './UserCounts.css';

const UserCounts = ({data}) => {
  const contentCompoent = data.map((item, i) => {
    let percentageClass = '';
    let sign = '+';
    let image = images.upArrow;
    if (item.parcentText < 0) {
      image = images.downArrow;
      percentageClass = 'decrement';
      sign = '';
    }

    return (
      <span className="Counter-box" key={i}>
        <span className="count-info">
          <span className="count">{item.number}</span>
          <span className="count-title">{item.title}</span>
          {item.parcentText && (
            <span className={`parcent ${percentageClass}`}>
              {sign}
              {item.parcentText}%
              <img src={image} alt="" />
            </span>
          )}
        </span>
        {_.get(item, 'infoIconVisible', false) && (
          <span className="info-wrapper">
            <img src={images.info} alt="info" className="info" />
            <span className="info-content">
              This is a kScore - an aggregate of reviews from top review websites in our industry.
            </span>
          </span>
        )}
      </span>
    );
  });
  return (
    <div>
      <div className="UserCounts">{contentCompoent}</div>
    </div>
  );
};

export default UserCounts;
