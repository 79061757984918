const questionList = [];

export default {
  questionList,
  config: {
    firstQuestionId: 2302,
    lastQuestionId: 2306,
    treeDepth: 5,
  },
};
