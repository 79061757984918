import React, {useState, useContext} from 'react';
import moment from 'moment';
import _ from 'lodash';
import Checkbox from 'components/FormControls/Checkbox';
import CircleButton from 'components/CircleButton';
import images from 'data/images';
import momentHelper from 'data/moment/momentHelper';
import RootContext from 'context/RootContext';
import TimeManual from 'components/FormControls/TimeManual';
import './AddAvailabilityBreak.css';

const {
  dateTimeFormat,
  isSameOrAfter,
  isSameDay,
  isBetweenDates,
  localToUtcDateTimeFormat,
  convertToSpecificTimezone,
  localToUtcMoment,
  utcToLocalMoment
} = momentHelper;

const AddAvailabilityBreak = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const {data = {}, onSave} = props;
  let duration = _.cloneDeep(data.duration) - 30;
  let startDatetime = _.cloneDeep(moment(data.start)).add(15, 'minutes');
  const now = () => {
    let nowTemp = localToUtcMoment(new Date()).format();
    nowTemp = utcToLocalMoment(nowTemp);
    let nowHH = nowTemp.format('HH');
    let nowMM = nowTemp.format('mm');
    let rdata = new Date();
    rdata.setDate(nowTemp.format('DD'));
    rdata.setHours(nowHH);
    rdata.setMinutes(nowMM);
    return rdata;
  };
  const [formData, setFormData] = useState({
    values: {
      days: [],
      ..._.cloneDeep(data),
      duration: duration > 59 ? 60 : duration,
      startDatetime,
      isRecurringBreak: false,
    },
    errors: {serverError: null},
  });
  const {values: formValues, errors: formErrors} = formData;
  const {isAllowRecurringEdit, isRecurringBreak} = formValues;

  const endTime = (starDateTime = null) => {
    return (starDateTime || formValues['startDatetime'])
      .clone()
      .add(formValues['duration'], 'minutes');
  };

  const onServerError = error => {
    setFormData({
      ...formData,
      errors: {
        ...formData.errors,
        serverError: error,
      },
    });
  };

  const changeHandler = event => {
    let {name} = event.target;
    let {value, duration} = event.target;
    if (name === 'duration') {
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          [name]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          [name]: value,
          duration: duration ? duration : formData.values.duration,
        },
      });
    }
  };

  const getGeneralCheckBoxProps = controlName => {
    const nameArr = controlName.split('-');
    let customProps = {};
    customProps = {
      ...customProps,
      value: formValues[nameArr[0]],
    };
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      name: controlName,
      ...customProps,
    };
  };

  const handleForm = () => {
    const updatedFormValues = {...formValues};
    let start = moment(updatedFormValues['start']);
    let end = moment(updatedFormValues['end']);
    let breakStartDatetime = moment(updatedFormValues['startDatetime']);
    let breakDuration = updatedFormValues['duration'];
    let firstStartDatetime = dateTimeFormat(start);
    let secondStartDatetime = dateTimeFormat(
      _.cloneDeep(breakStartDatetime).add(breakDuration, 'minutes'),
    );
    firstStartDatetime = convertToSpecificTimezone(firstStartDatetime).format();
    let secondStartDatetimeTemp = convertToSpecificTimezone(secondStartDatetime).format();
    let breakStartDatetimeTemp = convertToSpecificTimezone(breakStartDatetime).format();
    let data = {
      refId: updatedFormValues['id'],
      isAllowRepeatBreak: updatedFormValues['isRecurringBreak'],
      firstStartDatetime: localToUtcDateTimeFormat(firstStartDatetime),
      firstDuration: moment.duration(breakStartDatetime.diff(start)).asMinutes() * (1000 * 60),
      secondStartDatetime: localToUtcDateTimeFormat(secondStartDatetimeTemp),
      secondDuration: moment.duration(end.diff(secondStartDatetime)).asMinutes() * (1000 * 60),
      startBreakDatetime: localToUtcDateTimeFormat(breakStartDatetimeTemp),
    };
    onSave(data, onServerError);
  };

  const closeModal = () => {
    setGlobal('modal', {showModal: false});
  };

  // date time picker functions
  const [dateConfig, setDateConfig] = useState({
    start: {open: false, input: false, viewMode: 'days', timeFormat: 'hh:mm A'},
    end: {open: false, input: false, viewMode: 'days', timeFormat: 'hh:mm A'},
  });

  let isAllowStartDateTimeChange = true;
  const oldEndDate = data['startDatetime'].clone().add(data['duration'], 'minutes');
  if (data['id'] && !isAllowRecurringEdit && isBetweenDates(data['startDatetime'], oldEndDate,now())) {
    isAllowStartDateTimeChange = false;
  }

  let samedayTimeset = false;
  if (
    moment(formValues['startDatetime']).isSame(moment(), 'day') &&
    isAllowStartDateTimeChange === false
  ) {
    samedayTimeset = true;
  }
  const dateTimeFormatString = 'YYYY-MM-DD hh:mm a';
  const startTimeChangeHandle = event => {
    const {value} = event.target;
    const newVal = formValues['startDatetime'].format('YYYY-MM-DD') + ' ' + value;
    const startDatetimeObj = moment(newVal, dateTimeFormatString);
    const minutes = Math.ceil(startDatetimeObj.get('minutes') / 15) * 15;
    startDatetimeObj.set('minutes', minutes);
    let minDiff = moment.duration(formValues.startDatetime.diff(startDatetimeObj)).asMinutes();
    let newDuration = formValues.duration + minDiff;
    if (newDuration < 15) newDuration = 15;
    const endDataTime = startDatetimeObj.clone().add(newDuration, 'minutes');
    if (isSameOrAfter(startDatetimeObj) || (isAllowRecurringEdit && formValues.id)) {
      if (isSameDay(startDatetimeObj, endDataTime)) {
        const customEventObject = {
          target: {
            name: 'startDatetime',
            value: startDatetimeObj,
            duration: newDuration,
          },
        };
        changeHandler(customEventObject);
      }
    }
  };

  const endTimeChangeHandle = event => {
    const {value} = event.target;
    const newValEnd = formValues['startDatetime'].format('YYYY-MM-DD') + ' ' + value;
    let endDate = moment(newValEnd, dateTimeFormatString);
    let duration = endDate.diff(formValues['startDatetime'], 'minutes');
    if (duration <= 0) {
      duration = 15;
    } else {
      duration = Math.ceil(duration / 15) * 15;
    }
    endDate = formValues['startDatetime'].clone().add(duration, 'minutes');
    if (!isSameDay(formValues['startDatetime'], endDate)) {
      duration -= 15;
    }
    const customEventObject = {
      target: {
        name: 'duration',
        value: duration,
      },
    };
    changeHandler(customEventObject);
  };

  const closeDatePicker = e => {
    setDateConfig({
      ...dateConfig,
      start: {
        ...dateConfig.start,
        open: false,
      },
      end: {
        ...dateConfig.end,
        open: false,
      },
    });
  };

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="" />
    </span>
  );
  let headerTitle = 'Add Break In Today’s Availability Only';
  let saveButtonText = 'Add Break';
  if (formValues.id) {
    if (isRecurringBreak) {
      headerTitle = 'Add Break In Recurring Availability';
    } else {
      headerTitle = 'Add Break In Today’s Availability Only';
    }
  }
  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">{headerTitle}</div>
      {crossIconComponent}
    </div>
  );
  const remainder = 15 - (moment().minute() % 15);
  return (
    <div className="AddAvailabilityBreak no-select">
      {(dateConfig.start.open || dateConfig.end.open) && (
        <div className="datepicker-overlay" onClick={closeDatePicker}></div>
      )}
      {dialogHeader}
      <div className="dailog-body">
        <form>
          <div className="select-date-content">
            <span className="icon">
              <img src={images.clockIcon} alt="" />
            </span>
            <b className="select-time">
              <span>
                <TimeManual
                  minTime={
                    samedayTimeset === false
                      ? moment(formValues['start']).format('hh:mm a')
                      : moment().add(0, 'minute').format('hh:mm a')
                  }
                  maxTime={moment(formValues['end']).add(-15, 'minutes').format('hh:mm a')}
                  step={15}
                  value={
                    samedayTimeset === false || moment(formValues['startDatetime']).isAfter()
                      ? formValues['startDatetime'].format('hh:mm a')
                      : moment().add(remainder, 'minute').format('hh:mm a')
                  }
                  name="startDatetime"
                  //   disabled={samedayTimeset===true?false:!isAllowStartDateTimeChange}
                  onChange={startTimeChangeHandle}
                />
              </span>
              <span className="time-devider"> - </span>
              <span>
                <TimeManual
                  minTime={
                    samedayTimeset === false
                      ? formValues['startDatetime'].format('hh:mm a')
                      : moment()
                          .add(remainder + 15, 'minute')
                          .format('hh:mm a')
                  }
                  maxTime={moment(formValues['end']).format('hh:mm a')}
                  step={15}
                  value={
                    samedayTimeset === false || endTime().isAfter()
                      ? endTime().format('hh:mm a')
                      : moment()
                          .add(remainder + 15, 'minute')
                          .format('hh:mm a')
                  }
                  name="startDatetime"
                  onChange={endTimeChangeHandle}
                />
              </span>
            </b>
          </div>
          {isAllowRecurringEdit && (
            <label className="virtual-field">
              <Checkbox {...getGeneralCheckBoxProps('isRecurringBreak')}>
                Add Break In Recurring Availability
              </Checkbox>
            </label>
          )}
          <div className="action-btn">
            {formErrors['serverError'] && (
              <span className="error">{formErrors['serverError']}</span>
            )}
            <CircleButton onClick={closeModal} btnType="secondaryButton">
              Cancel
            </CircleButton>
            <CircleButton onClick={handleForm} btnType="whiteButton">
              {saveButtonText}
            </CircleButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAvailabilityBreak;
