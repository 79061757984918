const questionList = [
  {
    id: 181,
    question:
      'Have you had a recent traumatic injury to your elbow where you suspect you may have fractured a bone?',
    optionType: 1, // {1=>'single line options',2=>'select option'} // other will be default UI
    descriptions: [],
    options: [
      { groupId: 'B', showKalyMd: true, label: 'Yes', shortLabel: 'Red flags? Yes' },
      { nextQuestionId: 182, label: 'No', shortLabel: 'Red flags? No' },
    ],
  },
  {
    id: 182,
    question: 'How long have you had elbow pain for?',
    descriptions: [],
    options: [
      { nextQuestionId: 183, label: 'Less than 2 weeks', shortLabel: '<2 weeks' },
      { nextQuestionId: 184, label: 'More than 2 weeks', shortLabel: '>2 weeks' },
    ],
  },
  {
    id: 183,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'C',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching and exercises.',
        shortLabel: 'Conservative treatment',
      },
      { groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture' },
      {
        groupId: 'E',
        label: 'Preliminary imaging (Xrays) and/or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'E',
        label: 'Not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
        showKalyMd: true,
      },
    ],
  },
  {
    id: 184,
    question: 'Are you considering surgery?',
    descriptions: [],
    options: [
      { groupId: 'B', label: 'Yes, in the near future', shortLabel: 'Interested in surgery' },
      {
        nextQuestionId: 185,
        label: 'I am not considering surgery at this time',
        shortLabel: 'No surgery',
      },
    ],
  },
  {
    id: 185,
    question: 'Do you have numbness, tingling, or loss of strength in the forearm or hand?',
    descriptions: [],
    options: [
      { groupId: 'F', label: 'Yes', shortLabel: 'Neurologic symptoms present' },
      { nextQuestionId: 186, label: 'No', shortLabel: 'No neurologic symptoms present' },
    ],
  },
  {
    id: 186,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'C',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'G',
        label: 'Diagnostic imaging studies (MRI/Xray) or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'H',
        label: 'A minimally invasive procedure such as an injection.',
        shortLabel: 'Open to invasive procedures',
      },
      { groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture' },
      {
        groupId: 'G',
        label: 'I am not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
        showKalyMd: true,
      },
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 181,
    treeDepth: 5,
  },
};
