import axiosInstance from 'config/axios';


const getFeedbackProvider = (data) => {
  return axiosInstance.post(`v1/provider/getFeedback`,data)
};
const updateFeedback = (data,showLoader) => {
  return axiosInstance.put(`v1/provider/updateFeedback`,data,{noLoader: showLoader})
};
const getfeedbackPendingCount=()=>{
  return axiosInstance.get(`v1/provider/feedbackPendingCount`,{noLoader: true})
}
const getfeedbacksingle=(id)=>{
  return axiosInstance.get(`v1/provider/getFeedback/${id}`)
}
export default {
  getFeedbackProvider,
  updateFeedback,
  getfeedbackPendingCount,
  getfeedbacksingle
};
