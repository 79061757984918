import _ from 'lodash';
import axiosInstance from 'config/axios';
import momentHelper from 'data/moment/momentHelper';

const {isInLastNDay, bigDateTimeFormat} = momentHelper;

const getAdminDashboard = data => {
  return axiosInstance.get('v1/admin/dashboard').then(formatDashboardAnalysResponse);
};

const formatDashboardAnalysResponse = response => {
  const {data} = response;
  const {
    totalPracticeCount = 0,
    monthPracticeCount = 0,
    todayPracticeCount = 0,
    totalProviderCount = 0,
    monthProviderCount = 0,
    todayProviderCount = 0,
    totalPatientCount = 0,
    monthPatientCount = 0,
    todayPatientCount = 0,
    totalGhostProviderCount = 0,
    claimedGhostProviderCount = 0,
    unclaimedGhostProviderCount = 0,
  } = data;
  const analysisDataArray = [
    {
      number: totalPracticeCount,
      title: 'Total',
      countType: 'Practice',
    },
    {
      number: monthPracticeCount,
      title: 'This Month',
      countType: 'Practice',
    },
    {
      number: todayPracticeCount,
      title: 'Today',
      countType: 'Practice',
    },
    {
      number: totalProviderCount,
      title: 'Total',
      countType: 'Provider',
    },
    {
      number: monthProviderCount,
      title: 'This Month',
      countType: 'Provider',
    },
    {
      number: todayProviderCount,
      title: 'Today',
      countType: 'Provider',
    },
    {
      number: totalPatientCount,
      title: 'Total',
      countType: 'Patient',
    },
    {
      number: monthPatientCount,
      title: 'This Month',
      countType: 'Patient',
    },
    {
      number: todayPatientCount,
      title: 'Today',
      countType: 'Patient',
    },
    {
      number: totalGhostProviderCount,
      title: 'Total',
      countType: 'Ghost',
    },
    {
      number: claimedGhostProviderCount,
      title: 'Claimed',
      countType: 'Ghost',
    },
    {
      number: unclaimedGhostProviderCount,
      title: 'Unclaimed',
      countType: 'Ghost',
    },
  ];
  return {...response, data: {analysisData: analysisDataArray}};
};

const getProviderList = (data, noLoad) => {
  return axiosInstance.post('v1/admin/provider/count/list', data, {noLoader: noLoad});
};
const getInvitedProvidersList = (data, noLoad) => {
  return axiosInstance.post('v1/admin/provider/invitation/list', data, {noLoader: noLoad});
};
const getPatientList = (data, noLoad) => {
  return axiosInstance.post('v1/admin/patient/list', data, {noLoader: noLoad});
};

const getJoinUsRequestList = (data, noLoad) => {
  return axiosInstance.post('v1/admin/join-us/list', data, {noLoader: noLoad});
};

const getJoinUsRequestStatusList = (data, noLoad) => {
  return axiosInstance.post('v1/admin/join-us/status-list', data, {noLoader: noLoad});
};
const getProviderReviewStatus = userId => {
  return axiosInstance.get(`v1/admin/provider/review/${userId}`);
};

const getJoinUsCSVSampleURL = () => {
  //return axiosInstance.get(`v1/admin/join-us/sample-url`);
  return axiosInstance.get(`v1/admin/sampleProviderExcel`,{responseType: "blob"});
};

const postJoinUsCSVFile = data => {
  return axiosInstance.post(`v1/admin/join-us/import`, data);
};

const getConfigList = (data, noLoad) => {
  return axiosInstance.post(`v1/admin/site-setting/list`, data, {noLoader: noLoad});
};

const getLanguageList = (data, noLoad) => {
  return axiosInstance.post(`v1/admin/language/list`, data, {noLoader: noLoad});
};

const updateLanguage = (languageId, data) => {
  return axiosInstance.put(`v1/admin/language/update/${languageId}`, data);
};

const createLanguage = data => {
  return axiosInstance.post(`v1/admin/language/create`, data);
};

const updateSiteSetting = (slug, data) => {
  return axiosInstance.put(`v1/admin/site-setting/update/${slug}`, data);
};

const updateSiteSettingFile = (slug, data) => {
  return axiosInstance.post(`v1/admin/site-setting/upload/${slug}`, data);
};

const getCertificationList = (data, noLoad) => {
  return axiosInstance.post(`v1/admin/certification/list`, data, {noLoader: noLoad});
};

const createCertification = data => {
  return axiosInstance.post(`v1/admin/certification/create`, data);
};

const updateCertification = (certificationId, data) => {
  return axiosInstance.put(`v1/admin/certification/update/${certificationId}`, data);
};

const getSuffixList = (data, noLoad) => {
  return axiosInstance.post(`v1/admin/suffix/list`, data, {noLoader: noLoad});
};

const getInsuranceCompanyList = (data, noLoad) => {
  return axiosInstance.post(`v1/admin/insurance-company/list`, data, {noLoader: noLoad});
};

const createInsuranceCompany = data => {
  return axiosInstance.post(`v1/admin/insurance-company/create`, data);
};

const updateInsuranceCompany = (insuranceCompanyId, data) => {
  return axiosInstance.put(`v1/admin/insurance-company/update/${insuranceCompanyId}`, data);
};

const getInsurancePlanList = (data, noLoad) => {
  return axiosInstance.post(`v1/admin/insurance-plan/list`, data, {noLoader: noLoad});
};

const createInsurancePlan = data => {
  return axiosInstance.post(`v1/admin/insurance-plan/create`, data);
};

const updateInsurancePlan = (insurancePlanId, data) => {
  return axiosInstance.put(`v1/admin/insurance-plan/update/${insurancePlanId}`, data);
};

const updateSuffix = (suffixId, data) => {
  return axiosInstance.put(`v1/admin/suffix/update/${suffixId}`, data);
};

const createSuffix = data => {
  return axiosInstance.post(`v1/admin/suffix/create`, data);
};

const getNPIProviders = (data, noLoad) => {
  return axiosInstance.post(`v1/admin/npi-provider/list`, data, {noLoader: noLoad});
};
const getUserReviewSource = (data, noLoad) => {
  return axiosInstance.post(`v1/admin/review/user-review-source/list`, data, {noLoader: noLoad});
};
const deleteNPIProvider = (npiProviderId, isDelete) => {
  return axiosInstance.delete(`v1/admin/npi-provider/delete/${npiProviderId}/${isDelete}`);
};

const generateNPIProviders = npiNumber => {
  return axiosInstance.get(`v1/npi/generate/npiNumbers/${npiNumber}`);
};

const changePassword = data => {
  return axiosInstance.post(`v1/admin/profile/change-password`, data);
};

const getProviderDataById = providerId => {
  return axiosInstance.get(`v1/admin/provider/details/${providerId}`);
};

const updateProviderDetails = (method, providerId, data) => {
  return axiosInstance.put(`v1/admin/provider/${method}/${providerId}`, data);
};

const getVisitReasonByAdmin = providerId => {
  return axiosInstance.get(`v1/admin/provider/visit-reasons/${providerId}`);
};

const getProviderReviews = userId => {
  return axiosInstance.get(`v1/admin/review/summary/user/${userId}`);
};

const getProviderReviewList = (userId, data,loader=true) => {
  return axiosInstance
    .post(`v1/admin/review/list/user/${userId}`, data, {noLoader: loader})
    .then(formatReviewRespose);
};

const getGhostProviderReview = npiNumber => {
  return axiosInstance.get(`v1/admin/review/summary/npi/${npiNumber}`);
};

const getGhostProviderReviewList = (npiNumber, data) => {
  return axiosInstance
    .post(`v1/admin/review/list/npi/${npiNumber}`, data, {noLoader: true})
    .then((res)=>formatReviewRespose(res,data.sortBy?true:false));
};

const formatReviewRespose = (response, isAppliedSort) => {
  let sortedReviewData = response.data ? {...response.data.list} : null;
  if (!isAppliedSort) {
    sortedReviewData = _.orderBy(sortedReviewData, ['isFeatured', 'createdAt'], ['desc', 'desc']);
  }
  let totalRecentReviewCount = 0;
  let totalFeaturedReviewCount = 0;
  let totalPageCount = response.data ? response.data.totalPage : 0;
  let totalRecordCount = response.data ? response.data.totalRecord : 0;

  sortedReviewData = _.map(sortedReviewData, review => {
    const updatedReview = {...review};
    updatedReview.isRecentReview = isInLastNDay(updatedReview.createdAt, 7);
    updatedReview.formatedCreateDate = bigDateTimeFormat(updatedReview.createdAt,true);
    if (updatedReview.isRecentReview) {
      totalRecentReviewCount++;
    }
    if (updatedReview.isFeatured) {
      totalFeaturedReviewCount++;
    }
    const {
      doctorKnowledge,
      doctorApproachable,
      doctorBedsideManner,
      staffProfessional,
      staffResponseTime,
      staffScheduling,
      officeAtmosphere,
      officeCleanliness,
      officeWaitTimes,
    } = review;
    const doctorAverageRating = (doctorKnowledge + doctorApproachable + doctorBedsideManner) / 3;
    const staffAverageRating = (staffProfessional + staffResponseTime + staffScheduling) / 3;
    const officeAverageRating = (officeAtmosphere + officeCleanliness + officeWaitTimes) / 3;
    updatedReview.ratings = [
      {
        name: 'Doctor',
        rating: Math.round(doctorAverageRating * 10) / 10,
        subRatings: [
          {name: 'Knowledge', rating: doctorKnowledge},
          {name: 'Approachable', rating: doctorApproachable},
          {name: 'Bedside Manner', rating: doctorBedsideManner},
        ],
      },
      {
        name: 'Staff',
        rating: Math.round(staffAverageRating * 10) / 10,
        subRatings: [
          {name: 'Professional', rating: staffProfessional},
          {name: 'Scheduling', rating: staffScheduling},
          {name: 'Response Time', rating: staffResponseTime},
        ],
      },
      {
        name: 'Office',
        rating: Math.round(officeAverageRating * 10) / 10,
        subRatings: [
          {name: 'Cleanliness', rating: officeCleanliness},
          {name: 'Atmosphere', rating: officeAtmosphere},
          {name: 'Wait times', rating: officeWaitTimes},
        ],
      },
    ];
    return updatedReview;
  });
  const updatedResponse = {
    ...response,
    data: {
      totalRecentReview: totalRecentReviewCount,
      totalFeaturedReview: totalFeaturedReviewCount,
      reviewData: sortedReviewData,
      totalPage: totalPageCount,
      totalRecord: totalRecordCount,
    },
  };
  return updatedResponse;
};

const updateUserStatus = (userId, data) => {
  return axiosInstance.put(`v1/admin/user/update-status/${userId}`, data);
};

const featureReview = (userId, data) => {
  return axiosInstance.put(`v1/admin/review/featured/${userId}`, data);
};

const editProviderReply = (reviewId, data) => {
  return axiosInstance.put(`v1/admin/review/reply/${reviewId}`, data);
};

const deleteProviderReply = replyId => {
  return axiosInstance.delete(`v1/admin/review/reply/${replyId}`);
};

const deleteProviderReview = reviewId => {
  return axiosInstance.delete(`v1/admin/review/delete/${reviewId}`);
};

const getProviderReviewSource = userId => {
  return axiosInstance.get(`v1/admin/review/source/${userId}`);
};

const rejectReviewDeleteRequest = (userId, data) => {
  return axiosInstance.put(`v1/admin/review/delete-request/${userId}`, data);
};

const deletReviewRequestList = (data, noLoad) => {
  return axiosInstance
    .post(`v1/admin/review/request/delete`, data, {noLoader: noLoad})
    .then(res => formatReviewRespose(res, true));
};

const getProviderChangeRequest = (key, data, noLoad) => {
  return axiosInstance.post(`v1/admin/provider/pending/${key}`, data, {noLoader: noLoad});
};

const rejectProviderChangeRequest = (key, data) => {
  return axiosInstance.put(`v1/admin/provider/verify/${key}`, data);
};

const getRequestCounts = () => {
  return axiosInstance.get('v1/admin/provider/request/count');
};

const getAllPractices = (data, noLoad) => {
  return axiosInstance.post('v1/admin/practice/list', data, {noLoader: noLoad});
};

const updateProviderAccount = (userId, data) => {
  return axiosInstance.put(`v1/admin/user/update-details/${userId}`, data);
};
const updateProvideSeoPage = (userId, data) => {
  return axiosInstance.put(`v1/admin/provider/seo-page/${userId}`, data);
};

const getProviderPracticeDetail = practiceId => {
  return axiosInstance.get(`v1/admin/practice/details/${practiceId}`);
};

const setPracticeOverview = (practiceId, data) => {
  return axiosInstance.put(`v1/admin/practice/details/${practiceId}`, data);
};
const setPracticeSeoLink = (practiceId, data) => {
  return axiosInstance.put(`v1/admin/practice/update-seopage/${practiceId}`, data);
};
const setPracticeLocations = (practiceId, data) => {
  if(data.id){
    return axiosInstance.put(`v1/admin/practice/location/${practiceId}`, data);
  }
  return axiosInstance.post(`v1/admin/practice/location/${practiceId}`, data);
};

const deletePracticeLocations = (practiceId, locationid) => {
  return axiosInstance.delete(`v1/admin/practice/location/${practiceId}/${locationid}`);
};
const getLoginLog = (data, noLoad) => {
  return axiosInstance.post('v1/admin/login-log/list', data, {noLoader: noLoad});
};
const subscriptions=(data)=>{
  return axiosInstance.post('v1/admin/billing/subscriptions', data);
}
const getProviderProfilAccessLog = (data) => {
  return axiosInstance.post('v1/admin/userDevice/get-providerProfilAccessLog', data);
};
const getProviderProfilAccessLogVisualizations = (data) => {
  return axiosInstance.post('v1/admin/userDevice/providerStaticsDateRange', data);
};
const getproviderPracticeAccessLog = (data) => {
  return axiosInstance.post('v1/admin/userDevice/get-providerPracticeAccessLog', data);
};
const gePracticeAccessLogVisualizations = (data) => {
  return axiosInstance.post('v1/admin/userDevice/practiceStaticsDateRange', data);
};
const exportPracticeAsCsv = (id) => {
  
  return axiosInstance.post('v1/admin/provider-upload/exportPractice', {practiceId:id},{responseType: "blob"});
};
const createBirdEyeAccount = (id) => {
  
  return axiosInstance.post('v1/admin/review/bird-eye-account/create', {userId:id});
};
const importxlsx = data => {
  return axiosInstance.post(`v1/admin/provider-upload/importProvider`, data);
};
const updateCustomSpecialty=data=>{
  return axiosInstance.put('v1/admin/provider/customSpecialty',data)
}
export default {
  getProviderPracticeDetail,
  setPracticeOverview,
  setPracticeSeoLink,
  setPracticeLocations,
  deletePracticeLocations,
  getAdminDashboard,
  getProviderList,
  getInvitedProvidersList,
  getJoinUsRequestList,
  getJoinUsRequestStatusList,
  getPatientList,
  getProviderReviewStatus,
  getJoinUsCSVSampleURL,
  postJoinUsCSVFile,
  getConfigList,
  getLanguageList,
  updateSiteSetting,
  updateSiteSettingFile,
  getCertificationList,
  getSuffixList,
  updateLanguage,
  createLanguage,
  getInsuranceCompanyList,
  createInsuranceCompany,
  updateInsuranceCompany,
  getInsurancePlanList,
  createInsurancePlan,
  updateInsurancePlan,
  createCertification,
  updateCertification,
  updateSuffix,
  createSuffix,
  getNPIProviders,
  getUserReviewSource,
  generateNPIProviders,
  changePassword,
  getProviderDataById,
  updateProviderDetails,
  getVisitReasonByAdmin,
  getProviderReviews,
  getProviderReviewList,
  updateUserStatus,
  featureReview,
  editProviderReply,
  deleteProviderReply,
  deleteProviderReview,
  getProviderReviewSource,
  rejectReviewDeleteRequest,
  deletReviewRequestList,
  getProviderChangeRequest,
  rejectProviderChangeRequest,
  getRequestCounts,
  getAllPractices,
  updateProviderAccount,
  getGhostProviderReview,
  getGhostProviderReviewList,
  deleteNPIProvider,
  updateProvideSeoPage,
  getLoginLog,
  subscriptions,
  getProviderProfilAccessLog,
  getProviderProfilAccessLogVisualizations,
  gePracticeAccessLogVisualizations,
  getproviderPracticeAccessLog,
  exportPracticeAsCsv,
  createBirdEyeAccount,
  importxlsx,
  updateCustomSpecialty
};
