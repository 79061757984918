// import helper from 'data/helper';

// const {getCurrentEnvironment} = helper;
const getCurrentEnvironment = () => {
  let environment;
  const hostname = window.location.hostname;
  if (hostname.indexOf('sandbox') > -1) {
    environment = 'sandbox';
  } else if (hostname.indexOf('staging') > -1) {
    environment = 'staging';
  } else if (hostname.indexOf('app') > -1) {
    environment = 'app';
  } else if (hostname.indexOf('kaly') > -1) {
    environment = 'production';
  } else {
    environment = 'local';
  }
  return environment;
};
const BASENAME = 'app';
let config = {
  APP_URL: 'https://sandbox.kaly.com/',
  API_URL: 'https://api-sandbox.kalypsys.com/api/',
  AWS_STATIC_PAGE_URL: 'https://kalypsys-sandbox.s3.amazonaws.com/staticPages/',
  MAX_TEXT_LENGTH: 100,
  FACEBOOK_CLIENT_ID: '351598299508415',
  GOOGLE_CLIENT_ID: '152805412335-ruu6cfbg1dds9pkb5roeuqoc8094c885.apps.googleusercontent.com',
  GOOLGE_CLIENT_SECRET: '9wdthArwxQFpfHZ7nDGbfy5a',
  APPLE_CLIENT_ID: 'com.kalypsys.login',
  // GOOGLE_MAP_KEY: 'AIzaSyCt-gqjtzIF_bxENquSSaXKBN4lqhVsUY8',
  GOOGLE_MAP_KEY: 'AIzaSyCnorfaaCFEEGlyt9X1JFn9ZNBMdtR-98U',
  DEV_USERS: [],
  ENVIRONMENT: getCurrentEnvironment(), // get app environment based on URL
  StripePublishKey:
    'pk_test_51HpyJjHAdvmtKpNN6OvC2fUw5zQjlGVbLlqO7diRQa90IDSHkfoAiwMs9qQITctfuMgbb4qt1v04F6LzZLPj3rfD00pIheT981',
  GAID: 'G-68K4GW9VX9',
  enkey1: `K@|y-`,
  GA_NAME: 'Kalypsys',
  CHECK_VISIT_VALIDATION: true,
  IS_LOCKED: true,
  IPSTACK: 'a41dcd1c0686949fc47befca9522420a',
  APP_BASENAME: '/',
  showStripe: false,
  landingPageUrl: 'https://sandbox.kaly.com/index.html',
  enkey2: `~2024`,
};

switch (config.ENVIRONMENT) {
  case 'local':
    config = {
      ...config,
      APP_URL: 'http://localhost:3000/',
      API_URL: 'https://api-sandbox.kalypsys.com/api/', //'http://localhost:3001/', //
      APP_DOMAIN: 'https://sandbox.kaly.com/',
      AWS_STATIC_PAGE_URL: 'https://kalypsys-local.s3.amazonaws.com/staticPages/',
      CHECK_VISIT_VALIDATION: false,
      IS_LOCKED: false,
      APP_BASENAME: `/${BASENAME}/`,
      greatProvidersId: [390, 136, 162],
    };
    break;
  case 'sandbox':
    config = {
      ...config,
      APP_URL: `https://sandbox.kaly.com/${BASENAME}/`,
      API_URL: 'https://api-sandbox.kalypsys.com/api/',
      APP_DOMAIN: 'https://sandbox.kaly.com/',
      AWS_STATIC_PAGE_URL: 'https://sandbox.kalypsys.com/',
      GOOGLE_MAP_KEY: 'AIzaSyCnorfaaCFEEGlyt9X1JFn9ZNBMdtR-98U',
      GAID: 'G-9YZRYYBYEN',
      GA_NAME: 'kalypsys Sandbox',
      StripePublishKey:
        'pk_test_51HpyJjHAdvmtKpNN6OvC2fUw5zQjlGVbLlqO7diRQa90IDSHkfoAiwMs9qQITctfuMgbb4qt1v04F6LzZLPj3rfD00pIheT981',
      CHECK_VISIT_VALIDATION: false,
      IS_LOCKED: true,
      APP_BASENAME: `/${BASENAME}/`,
      showStripe: false,
      greatProvidersId: [390, 136, 162],
      landingPageUrl: 'https://sandbox.kaly.com/index.html',
    };
    break;
  // case 'app':
  //   config = {
  //     ...config,
  //     APP_URL: 'https://app.kalypsys.com/',
  //     API_URL: 'https://api.kalypsys.com/api/',
  //     APP_DOMAIN: 'https://sandbox.kalypsys.com/',
  //     AWS_STATIC_PAGE_URL: 'https://www.kalypsys.com/',
  //     GOOGLE_MAP_KEY: 'AIzaSyAZivnzT8lZfGLxZF3ggLh3zMXxaopdYPo',
  //     DEV_USERS: [1],
  //     StripePublishKey:
  //       'pk_live_51HpyJjHAdvmtKpNNTTEU1iGIv7Jvj4yidlnqvDI9JHZfeIo4TvksbspmiUOKxrBK8NXeIr3Eg4ACsJzYl5snvPHg00CBfQaYxT',
  //     GAID: 'G-XDTLXHQJFQ',
  //     GA_NAME: 'Kalypsys',
  //     CHECK_VISIT_VALIDATION: true,
  //     IS_LOCKED: false,
  //     APP_BASENAME: `/`,
  //     showStripe:false
  //   };
  //pk_test_51HeIvxAvaPI9JB0Rn40ANVA9HsPjEL9WtVgDF5bDUHZdIocZ1NqfjRGcjjGZi0Lxy4D2MeBrdRy3lGOFGgfjs9VN00TrY5dHxv
  //  break;
  case 'staging':
    config = {
      ...config,
      APP_URL: `https://staging.kaly.com/${BASENAME}/`,
      API_URL: 'https://api-staging.kalypsys.com/api/',
      APP_DOMAIN: 'https://staging.kaly.com/',
      AWS_STATIC_PAGE_URL: 'https://staging.kalypsys.com/',
      GOOGLE_MAP_KEY: 'AIzaSyCnorfaaCFEEGlyt9X1JFn9ZNBMdtR-98U',
      GAID: 'G-9YZRYYBYEN',
      GA_NAME: 'kalypsys Staging',
      StripePublishKey:
        'pk_test_51HpyJjHAdvmtKpNN6OvC2fUw5zQjlGVbLlqO7diRQa90IDSHkfoAiwMs9qQITctfuMgbb4qt1v04F6LzZLPj3rfD00pIheT981',
      CHECK_VISIT_VALIDATION: false,
      IS_LOCKED: true,
      APP_BASENAME: `/${BASENAME}/`,
      showStripe: false,
      greatProvidersId: [9, 10, 3],
      landingPageUrl: 'https://staging.kaly.com/index.html',
    };
    break;
  case 'production':
    config = {
      ...config,
      APP_URL: `https://www.kaly.com/${BASENAME}/`,
      API_URL: 'https://api.kalypsys.com/api/',
      APP_DOMAIN: 'https://www.kaly.com/',
      AWS_STATIC_PAGE_URL: 'https://www.kalypsys.com/',
      GOOGLE_MAP_KEY: 'AIzaSyAZivnzT8lZfGLxZF3ggLh3zMXxaopdYPo',
      DEV_USERS: [1],
      StripePublishKey:
        'pk_live_51HpyJjHAdvmtKpNNTTEU1iGIv7Jvj4yidlnqvDI9JHZfeIo4TvksbspmiUOKxrBK8NXeIr3Eg4ACsJzYl5snvPHg00CBfQaYxT',
      GAID: 'G-XDTLXHQJFQ',
      GA_NAME: 'Kalypsys',
      CHECK_VISIT_VALIDATION: true,
      IS_LOCKED: false,
      APP_BASENAME: `/${BASENAME}/`,
      showStripe: false,
      greatProvidersId: [9, 10, 3],
      landingPageUrl: 'https://www.kaly.com/',
    };
    break;

  default:
    break;
}

export default config;
