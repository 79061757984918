import React, {useState} from 'react';
import _ from 'lodash';
import images from 'data/images';
import Checkbox from 'components/FormControls/Checkbox';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import './AddInsurancePlansModal.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {Analytics} = analyticsData;

const AddInsurancePlansModal = props => {
  const {data, onSaveInsurancePlans, closeModal} = props;
  const [config, setConfig] = useState({
    error: null,
    searchText: '',
    instrancePlanList: data.instrancePlanList,
    selectAll: false,
  });
  const {error, searchText, instrancePlanList} = config;

  const handleSearchTextChange = e => {
    const {value} = e.target; // <-- moved outside asynchronous context
    setConfig(prevState => {
      return {
        ...prevState,
        searchText: value,
      };
    });
  };

  const showAllPlans = insuranceId => {
    let updatedInstrancePlanList = [...instrancePlanList];
    const insuranceIndex = _.findIndex(updatedInstrancePlanList, {id: insuranceId});
    updatedInstrancePlanList[insuranceIndex].isShowAllPlans = true;
    setConfig({
      ...config,
      instrancePlanList: updatedInstrancePlanList,
    });
  };

  const toggleItem = (insuranceId, isOpen) => {
    let updatedInstrancePlanList = [...instrancePlanList];
    const insuranceIndex = _.findIndex(updatedInstrancePlanList, {id: insuranceId});
    updatedInstrancePlanList[insuranceIndex].isOpen = isOpen;
    setConfig({
      ...config,
      instrancePlanList: updatedInstrancePlanList,
    });
  };

  const toggleCheckbox = (insuranceId, insurancePlanId, isSelected) => {
    setConfig(prevState => {
      let updatedInstrancePlanList = [...prevState.instrancePlanList];
      const insuranceIndex = _.findIndex(updatedInstrancePlanList, {id: insuranceId});
      const insurancePlans = updatedInstrancePlanList[insuranceIndex].insurancePlans;
      const insurancePlanIndex = _.findIndex(insurancePlans, {id: insurancePlanId});
      updatedInstrancePlanList[insuranceIndex].insurancePlans[insurancePlanIndex].isSelected =
        isSelected;
       
      return {
        ...prevState,
        error: null,
        instrancePlanList: updatedInstrancePlanList,
      };
    });
  };

  const saveInsurancePlans = async () => {
    Nux.sendEvent(
      Analytics.Category.DoctorSettingInsurance,
      Analytics.Actions.Click,
      'insuranceChange',
    );
    let selectedInsurancePlans = [];
    _.forEach(instrancePlanList, insurance => {
      _.forEach(insurance.insurancePlans, insurancePlan => {
        if (insurancePlan.isSelected) {
          selectedInsurancePlans.push(insurancePlan.id);
        }
      });
    });
    const errorMessage = await onSaveInsurancePlans({insurancePlans: selectedInsurancePlans});
    if (errorMessage) {
      setConfig(prevState => {
        return {
          ...prevState,
          error: errorMessage,
        };
      });
    }
  };

  let filteredInstrancePlanList = _.filter(_.cloneDeep(instrancePlanList), insurance => {
    if (searchText === '') {
      return true;
    } else {
      insurance.isOpen = true;
    }
    const isShowInsurance = insurance.companyName.toLowerCase().includes(searchText.toLowerCase());
    if (isShowInsurance) {
      return true;
    }
    insurance.insurancePlans = _.filter(insurance.insurancePlans, insurancePlan => {
      const isShowInsurancePlan = insurancePlan.plan
        .toLowerCase()
        .includes(searchText.toLowerCase());
      return isShowInsurancePlan;
    });
    if (insurance.insurancePlans.length > 0) {
      return true;
    }
  });
  const selectall = (showSelectAll) => {
    // console.log('filteredInstrancePlanList', filteredInstrancePlanList);
    // filteredInstrancePlanList = filteredInstrancePlanList.map(i => {
    //   i.insurancePlans = i.insurancePlans.map(p => {
    //     p.isSelected = true;
    //     return p;
    //   });
    //   return i;
    // });
    setConfig(prevState => {
      let updatedInstrancePlanList = [...prevState.instrancePlanList];

      filteredInstrancePlanList = filteredInstrancePlanList.map(i => {
        const insuranceIndex = _.findIndex(updatedInstrancePlanList, {id: i.id});
        const insurancePlans = updatedInstrancePlanList[insuranceIndex].insurancePlans;
        i.insurancePlans = i.insurancePlans.map(p => {
          const insurancePlanIndex = _.findIndex(insurancePlans, {id: p.id});
          updatedInstrancePlanList[insuranceIndex].insurancePlans[insurancePlanIndex].isSelected =
            !showSelectAll;
          return p;
        });
        return i;
      });
      return {
        ...prevState,
        selectAll: !prevState.selectAll,
        instrancePlanList: updatedInstrancePlanList,
      };
    });
  };
let showSelectAll=false
    let total=0;
    let selected=0
  let tempval= filteredInstrancePlanList.map((i)=>{
    total+=i.insurancePlans.length
    })
    
    tempval= filteredInstrancePlanList.map((i)=>{
      selected+=i.insurancePlans.filter((p)=>p.isSelected===true).length
      })
      
if(total===selected){
  showSelectAll=true
}
 
  const insurancePlanListComponent = filteredInstrancePlanList.map(insurance => {
    return (
      <div
        className={insurance.isOpen ? 'active-item insuranceplans-item' : 'insuranceplans-item'}
        key={insurance.id}>
        <div
          className="insuranceplans-title"
          onClick={() => {
            toggleItem(insurance.id, !insurance.isOpen);
          }}>
          <span>{insurance.companyName}</span>
          <img src={images.blackArrowPointer} alt="" />
        </div>
        <div className="insuranceplans-content">
          {insurance.isOpen &&
            insurance.insurancePlans.map((insurancePlan, insurancePlanIndex) => {
              if (
                insurance.isShowAllPlans ||
                insurance.maxShowPlans > insurancePlanIndex ||
                searchText !== '' ||
                insurancePlan.isSelected
              ) {
              } else {
                return null;
              }
              return (
                <div key={insurancePlan.id}>
                  <Checkbox
                    value={insurancePlan.isSelected}
                    onChange={e => toggleCheckbox(insurance.id, insurancePlan.id, e.target.value)}>
                    {insurancePlan.plan}
                  </Checkbox>
                </div>
              );
            })}
          {!insurance.isShowAllPlans &&
            insurance.maxShowPlans < insurance.insurancePlans.length &&
            searchText === '' && (
              <span onClick={() => showAllPlans(insurance.id)} className="showmore">
                Show All Plans
              </span>
            )}
        </div>
      </div>
    );
  });
  
  const noPlansComponent = <div className="no-records">No Plans found</div>;
  return (
    <div className="AddInsurancePlansModal">
      <div>
      <div className="box-title title">
            <span className='heading'>Add Insurance Plans</span>
            <span className="cross-icon" onClick={closeModal}>
              <img src={images.close} alt="" />
            </span>
          </div>
        <div className="box">
          
          <Input
            name="searchText"
            value={searchText}
            onChange={handleSearchTextChange}
            containerClass="search-input"
            placeholder="Search carrier or plan name"
            icon={images.search}
          />
          <div className="selectall-btn-div">
          <button className="insurance-selectall" onClick={() => selectall(showSelectAll)}>
            {showSelectAll ? 'Deselect All':'Select All'}
          </button>
          </div>
          {filteredInstrancePlanList.length === 0 && noPlansComponent}
          {filteredInstrancePlanList.length > 0 && (
            <div className="insuranceplans-list">{insurancePlanListComponent}</div>
          )}
          <div className="action-button-list">
            <span className="error-text">{error}</span>
            {filteredInstrancePlanList.length > 0 ? (
              <CircleButton containerClass="save" onClick={saveInsurancePlans}>
                Save
              </CircleButton>
            ) : (
              <CircleButton
                containerClass="clear"
                onClick={() => handleSearchTextChange({target: {value: ''}})}>
                Clear
              </CircleButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddInsurancePlansModal;
