import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import images from 'data/images';
import helper from 'data/helper';
import './FeedItem.css';

const {formatPhoneNumber} = helper;

const FeedItem = props => {
  const {notification = {}, onReviewAppointment, onViewAppointment} = props;
  const {slug, message = '', reviewId, patientAppointmentId, createdAt = undefined} = notification;
  let {isPast = true} = notification;
  const filePath = _.get(notification, 'filePath', '');
  const profileImage = _.get(notification, 'profileImage', null);
  const cancelledBy = _.get(notification, 'patientAppointment.cancelledBy', '');
  const cancelledAt = _.get(notification, 'patientAppointment.cancelledAt', null);
  const appointmentStatus = _.get(notification, 'patientAppointment.status', '');
  const officePhone = _.get(notification, 'officePhone', '');
  const formatCancellationDate = () => {
    return moment.utc(cancelledAt, 'YYYY-MM-DD HH:mm:ss').local().format('MMM DD, YYYY');
  };
  let profileImageUrl = null;
  if (profileImage) {
    profileImageUrl = filePath + profileImage;
  }
  let containerClass = '';
  let image = images.checkmark;
  let subComponent = null;
  let titleComponent = null;
  switch (slug) {
    case 'appointmentCancelledByProvider':
      containerClass = 'cancel';
      image = images.info;
      subComponent = (
        <div className="feed-status-text">
          Cancelled by {_.capitalize(cancelledBy)} on {formatCancellationDate()}
        </div>
      );
      break;
    case 'appointmentCancelledByPatient':
      containerClass = 'cancel';
      image = images.info;
      subComponent = (
        <div className="feed-status-text">Cancelled by you on {formatCancellationDate()}</div>
      );
      break;
    case 'appointmentApprovedByProvider':
      containerClass = 'confirmed';
      image = images.checkmark;
      subComponent = appointmentStatus === 1 && (
        <div className="feed-status-text" onClick={() => onViewAppointment(patientAppointmentId)}>
          View Appointment Details
        </div>
      );
      break;
    case 'appointmentCompleted':
      containerClass = 'confirmed doctor-profile';
      image = profileImageUrl || images.user;
      if (!reviewId) {
        isPast = false;
      }
      subComponent = !reviewId && (
        <div className="feed-status-text" onClick={() => onReviewAppointment(patientAppointmentId)}>
          Review your appointment
        </div>
      );
      break;
    case 'appointmentCallRequestByProvider':
      containerClass = 'cancel';
      image = images.bell;
      subComponent = <div className="feed-status-text">Call {formatPhoneNumber(officePhone)}</div>;
      break;
    default:
      image = images.logoIcon;
      containerClass = 'welcome-feed ';
      subComponent = <div className="feed-status-text">View Appointment Details</div>;
      titleComponent = <div className="welcome-feed-title">Welcome to Kaly!</div>;
      subComponent = (
        <div className="feed-status-text">
          Check here for appointment updates, reminders, reviews, wellness content
        </div>
      );
      break;
  }
  return (
    <div className={`FeedItem ${containerClass} ${isPast ? 'disable-item' : ''}`}>
      <div className="feed-details">
        {titleComponent}
        <div className="feed-text">{message}</div>
        <div className="feed-status">
          {subComponent}
          <div className="feed-time">{moment(createdAt).fromNow()}</div>
        </div>
      </div>
      <div className="feed-icon">
        <img src={image} alt="check mark" />
      </div>
    </div>
  );
};

export default FeedItem;
