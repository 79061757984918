import React, {Component} from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import constants from 'data/constants';
import _ from 'lodash';
import './Certification.css';
import EditCertification from './EditCertification';
import images from 'data/images';

const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  searchText: '',
  pageLimit: 10,
  sortBy: 'certification',
  orderBy: 'asc',
  totalRecord: 0,
};
const {modalFunctions} = modalConfig;
const {errorModalData, blankModalData} = modalFunctions;
const {getCertificationList} = adminService;
const {CertificationStatus, DefaultDebounceMSec} = constants;

class Certification extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount() {
    this.setCertificationList(false);
  }

  setCertificationList = async (noLoad = false) => {
    const {activePage, pageLimit, searchText, sortBy, orderBy} = this.state;
    const res = await getCertificationList(
      {
        page: activePage,
        limit: pageLimit,
        search: searchText,
        sortBy: sortBy, // allow 'certification', 'status', 'updatedAt'
        orderBy: orderBy,
      },
      noLoad,
    );
    this.setState({
      list: _.get(res, 'data.list', []),
      totalPage: _.get(res, 'data.totalPage', 0),
      totalRecord: _.get(res, 'data.totalRecord', 0),
    });
  };

  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.setCertificationList);
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };

  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.setCertificationList();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };
  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.setCertificationList);
  };
  handleSearch = () => {
    this.setState({activePage: 1}, this.setCertificationList);
  };

  openEditModal = data => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: EditCertification,
        customComponentProps: {data, updateList: this.setCertificationList},
      }),
    );
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'certification');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.setCertificationList,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.setCertificationList);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  render() {
    const {list, totalPage, searchText, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    const title = 'Certification';
    return (
      <Layout>
        <div className="Certification">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div>
                <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
                {searchText && (
                  <button className="btn-clear" onClick={this.handleClearSearch}>
                    <img src={images.close} alt="clear" />
                  </button>
                )}
              </div>
              <button onClick={this.handleSearch}>Search</button>
            </div>
            <button className="btn-add-new" onClick={() => this.openEditModal({})}>
              Add New Certification
            </button>
            <div className="dataCount">
              <span>{totalRecord} certifications found.</span>
            </div>
            <div className="table-overflow">
              <div className="rtable">
                <div className="heading">
                  <div
                    className="cell pointer"
                    data-key="certification"
                    onClick={this.handleSortingChange}>
                    Certification{this.renderShortIcon('certification')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="status"
                    onClick={this.handleSortingChange}>
                    Status{this.renderShortIcon('status')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="updatedAt"
                    onClick={this.handleSortingChange}>
                    Last Updated At{this.renderShortIcon('updatedAt')}
                  </div>
                  <div className="cell edit-cell">Action</div>
                </div>
                {_.map(list, (item, index) => (
                  <div className="row" key={index}>
                    <div className="cell">{_.get(item, 'certification', '')}</div>
                    <div className="cell">{CertificationStatus[_.get(item, 'status', 0)]}</div>
                    <div className="cell">
                      {item.updatedAt && this.formatedDate(item.updatedAt)}
                    </div>
                    <div className="cell edit-cell">
                      <img
                        onClick={() => this.openEditModal(item)}
                        src={images.editPen}
                        className="edit-icon"
                        alt="editPen"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Certification;
