import React, { Component } from 'react';
import Layout from 'layout';
import joinUsService from 'services/joinUs';
import userService from 'services/user';
import scheduleService from 'services/schedule';
import patientService from 'services/patient';
import reviewService from 'services/review';
import AuthContext from 'context/AuthContext';
import './LoadPage.css';

const { verifyJoinUsByToken, rejectUser } = joinUsService;
const { verifyEmailChangeByToken, verifyAnonymousEmail } = userService;
const { verifyPatientEmailByToken,consultRequestcancel } = patientService;
const { changeAppointmentStatus } = scheduleService;
const { updateReviewSoruceStatus } = reviewService;
const BASENAME = '/app';
class LoadPage extends Component {
  state = {
    message: 'Please wait',
  };
  static contextType = AuthContext;
  authContext = null;
  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { verificationType, token } = params;
    this.verify(verificationType, token);
  }
  checkAuthRequird = () => {
    const { auth } = this.authContext;
    if (!auth) {
      window.location.href =
        `${BASENAME}/login?goto=` + window.location.href;
      return false
    }
    return true;
  }

  verify = async (verificationType, token) => {
    let response;
    switch (verificationType) {
      case 'join-us':
        response = await verifyJoinUsByToken(token).catch(this.handleError);
        break;
      case 'reject-provider':
        response = await rejectUser(token).catch(this.handleError);
        break;
      case 'email-change':
        response = await verifyEmailChangeByToken(token).catch(this.handleError);
        break;
      case 'consultrequestcancel':
        response = await consultRequestcancel(token).catch(this.handleError);
        break;
      case 'confirm-appointment':
        {
          if (this.checkAuthRequird()) {
            const data = {
              appointmentId: token,
              status: 1, // confirm
              reason: '',
            };
            response = await changeAppointmentStatus(data).catch(this.handleError);
          }
        }
        break;
      case 'cancel-appointment':
        {
          if (this.checkAuthRequird()) {
            const data = {
              appointmentId: token,
              status: 2, // cancel
              reason: 'We could not reach patient for information',
            };
            response = await changeAppointmentStatus(data).catch(this.handleError);
          }
        }
        break;
      case 'patient-email':
        response = await verifyPatientEmailByToken(token).catch(this.handleError);
        break;
      case 'approve-review-source':
        {
          const postData = {
            token,
            status: 1,
          };
          response = await updateReviewSoruceStatus(postData).catch(this.handleError);
        }
        break;
      case 'reject-review-source':
        {
          const postData = {
            token,
            status: 2,
          };
          response = await updateReviewSoruceStatus(postData).catch(this.handleError);
        }
        break;
      case 'anonymous-email':
        response = await verifyAnonymousEmail(token).catch(this.handleError);
        break;
      default:
        this.setState({ message: 'Invalid URL' });
        break;
    }
    if (response) {
      const { message } = response;
      this.setState({ message });
    }
  };

  handleError = error => {
    const { message } = error;
    this.setState({ message });
    return null;
  };

  render() {
    const { message } = this.state;
    return (
      <Layout>
        <AuthContext.Consumer>
          {context => {
            this.authContext = context
          }}
        </AuthContext.Consumer>
        <div className="LoadPage">
          <div className="container">
            <div className="LoadPage-inner">
              <div className="paragraph">{message}</div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default LoadPage;
