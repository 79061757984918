import React, {useContext, useEffect, useState} from 'react';
import Overlay from 'components/Overlay';
import images from 'data/images';
import MyLink from 'components/MyLink';
import reviewService from 'services/review';
import './ConnectGoogleCalender.css';
import RootContext from 'context/RootContext';
import AuthContext from 'context/AuthContext';
import helper from 'data/helper';
import config from 'config/config';
import modalConfig from 'modals/modalConfig';
import Axios from 'axios';
let GoogleAuth = null;
const ConnectGoogleCalender = props => {
  const rootContext = useContext(RootContext);
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const {setGlobal} = rootContext;
  const {loadScript} = helper;
  const {postGoogleSSORegisterGoogleCalendar} = reviewService;
  const {modalFunctions} = modalConfig;
  const {errorModalData, successModalData, confirmModalData} = modalFunctions;
  const {connectGoogleCalender, disconnectGoogleCalender, checkGoogleCalendarConnected} =
    reviewService;
  const [calendarConnections, setCalendarConnections] = useState(null);
  useEffect(() => {
    checkGoogleCalendarConnectedFn();
    initGoogleSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  const initGoogleSetup = () => {
    try {
      loadScript(
        document,
        'script',
        'google-jssdk2',
        'https://apis.google.com/js/client:platform.js?onload=start',
        e => {
          window.gapi.load('auth2', () => {
            if (!document.querySelector('#google-jssdk')) {
              GoogleAuth = window.gapi.auth2.init({
                client_id: config.GOOGLE_CLIENT_ID,
                //cookiepolicy: 'single_host_origin',
                scope: `https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.events`,
                immediate: true,
                access_type: 'offline',
              });
            } else {
              GoogleAuth = window.gapi.auth2.getAuthInstance({
                client_id: config.GOOGLE_CLIENT_ID,
                //cookiepolicy: 'single_host_origin',
                scope: `https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.events`,
                immediate: true,
                access_type: 'offline',
              });
            }
          });
        },
      );
    } catch (error) {}
  };
  const connectGoogle = async () => {
    // let payload = {
    //   channelName: 'gmb',
    // };
    // const response = await addSocialConnect(payload).catch(handleError);
    // if (response) {
    //   const {data} = response;
    //   let googleConnectWindow = window.open(
    //     data.url,
    //     'googleConnectWindow',
    //     'width=500,height=500',
    //   );
    //   let googleConnectTimer = setInterval(() => {
    //     if (googleConnectWindow.closed) {
    //       getAndSetConnections();
    //       clearInterval(googleConnectTimer);
    //     }
    //   }, 500);
    // }
    if (GoogleAuth) {
      GoogleAuth.grantOfflineAccess({}).then(
        res => {
          Axios.post('https://www.googleapis.com/oauth2/v4/token', {
            code: res.code,
            client_id: config.GOOGLE_CLIENT_ID,
            client_secret: config.GOOLGE_CLIENT_SECRET,
            redirect_uri: window.location.origin,
            grant_type: 'authorization_code',
          }).then(async r => {
            let {data} = r;
            postGoogleSSORegisterGoogleCalendar({refreshToken: data.refresh_token})
              .then(res => {
                if (res.message === 'Your google calendar is successfully connected') {
                  setGlobal(
                    'modal',
                    successModalData(res.message, {
                      callBackOnClose: async () => {
                        checkGoogleCalendarConnectedFn();
                      },
                    }),
                  );
                }
              })
              .catch(error => {
                handleError(error);
              });
          });
        },
        err => {
          console.log('Google-err', err);
        },
      );
    }
  };
  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const connectCalenderFn = async () => {
    const response = await connectGoogleCalender().catch(handleError);
    if (response) {
      //const {data} = response;
      // if (data) {
      checkGoogleCalendarConnectedFn();
      // }
    }
  };
  const condirmDisconnect = () => {
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to disconnect Google Calendar?`,
        lastModalConfig: {},
        onConfirm: isConfirmed => {
          setGlobal('modal', {showModal: false});
          if (isConfirmed) {
            disconnectCalenderFn();
          }
        },
      }),
    );
  };
  const disconnectCalenderFn = async () => {
    const response = await disconnectGoogleCalender().catch(handleError);
    if (response) {
      //const {data} = response;
      // if (data) {
      checkGoogleCalendarConnectedFn();
      // }
    }
  };
  const checkGoogleCalendarConnectedFn = async () => {
    const response = await checkGoogleCalendarConnected().catch(e => {
      setCalendarConnections(e);
      //handleError(e);
    });
    if (response) {
      setCalendarConnections(response);
    }
  };

  return (
    <div>
      <div className="list-item-inner-content GoogleCalendar">
        <div className="logo">
          <img src={images.Googleicon} alt="google icon" />
        </div>
        <div className="name">
          Google Calendar{' '}
          <span className="info-wrapper">
            <img src={images.info} alt="info" className="info" />
            <span className="info-content">
              Connect your Google Calendar to edit your Kaly appointment availability to block out
              conflicting time slots.
            </span>
          </span>
        </div>
        {calendarConnections &&
          calendarConnections.message == 'You are connected to Google Calendar.' && (
            <>
              <div className="status" style={{color: '#79D174'}}>
                Connected
              </div>

              {calendarConnections && (
                <button
                  style={{fontSize: '15px'}}
                  className="mb-1 disconnect"
                  onClick={condirmDisconnect}>
                  Disconnect Calendar
                </button>
              )}
            </>
          )}
        {calendarConnections &&
          calendarConnections.message != 'You are connected to Google Calendar.' && (
            <>
              <div className="status not-connected">Not connected</div>
              <button onClick={connectGoogle}>Connect Google</button>
            </>
          )}
      </div>
    </div>
  );
};

export default ConnectGoogleCalender;
