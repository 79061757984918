const questionList = [
  {
    id: 1,
    question: 'Do you have any of these symptoms?',
    optionType: 1, // {1=>'single line options',2=>'select option'} // other will be default UI
    descriptions: [
      'New onset severe 10/10 pain - the worst headache of your life.',
      'Speech, vision, strength, movement, and balance problems that are suddenly new.',
      'Fever greater than 100.3.',
      'Loss of bowel and bladder control?',
    ],
    options: [
      // {groupId: 'A', label: 'Yes', shortLabel: 'Red flags? Yes'},
      {nextQuestionId: 11226, label: 'Yes', shortLabel: 'Red flags? Yes'},
      {nextQuestionId: 2, label: 'No', shortLabel: 'Red flags? No'},
    ],
  },
  {
    id: 2,
    question: 'How long have you had the headache for?',
    descriptions: [],
    options: [
      {groupId: 'W', label: 'Less than 3 weeks', shortLabel: '<3 weeks'},
      {nextQuestionId: 3, label: 'More than 3 weeks', shortLabel: '>3 weeks'},
    ],
  },
  {
    id: 3,
    question: 'Which statement is most accurate?',
    descriptions: [],
    options: [
      {
        groupId: 'A13',
        label:
          'I am looking to undergo diagnostic imaging studies (MRI/Xray) or be prescribed medications for my condition.',
        shortLabel: 'Imaging/Meds',
      },
      {
        groupId: 'A14',
        label:
          'I am looking to undergo a therapeutic procedure such as an injection.',
      },
      {
        groupId: 'N',
        label: 'I am looking for conservative type treatments including manual and massage therapy, stretching, and exercises.',
        // shortLabel: 'Undecided treatment',
      },
      {
        groupId: 'D',
        label: 'I am looking for acupuncture',
        // shortLabel: 'Undecided treatment',
      },
      {
        groupId: 'A15',
        label: 'I am not sure what type of treatment I am looking for.',
        // shortLabel: 'Undecided treatment',
      },
    ],
  },
  {
    id: 11226,
    question: 
      'You may have a condition that requires immediate medical attention, such as seeking care at an Emergency Room or Urgent Care Center. Kaly does not diagnose conditions and is not meant to be a replacement for a trained medical professional.',
    optionType: 1,
    descriptions: [
      'Would you still like to see doctors who may help you after you seek urgent care?',
    ],
    options: [
      {groupId: 'W', label: 'Yes'},
      {groupId: 'A', label: 'No'},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 1,
    treeDepth: 3,
  },
};
