const questionList = [
  {
    id: 917,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [9, 11, 13], // painIds,
    nextQuestionId: 918,
    descriptions: [],
  },
  {
    id: 918,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 919,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 9, questionId: 183, visitReasonIds: [39], extraQuestion: 1},
          {painId: 11, questionId: 203, visitReasonIds: [35], extraQuestion: 1},
          {painId: 13, questionId: 203, visitReasonIds: [], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 919,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 9, questionId: 184, visitReasonIds: [39], extraQuestion: 3},
          {painId: 11, questionId: 204, visitReasonIds: [35], extraQuestion: 3},
          {painId: 13, questionId: 204, visitReasonIds: [], extraQuestion: 3},
        ],
      },
    ],
    descriptions: [],
  },

  {
    id: 919,
    question: 'Do you still want to see a Occupational Therapist?',
    descriptions: [
      'An Occupational therapist is a medical professional who has a masters in Occupational Therapy, which takes 2-3 years to complete after obtaining a bachelor’s degree.  An Occupational Therapist may be able to treat your condition through  conservative treatments such as manual therapy and therapeutic exercises.  An OT cannot prescribe medications, order diagnostic tests, or perform injections or surgery.  Do you still want to see an occupational therapist?',
    ],
    title: 'Occupational Therapist',
    optionType: 1,
    questionType: 1,
    canDoList: [],
    options: [
      {groupId: 'A24', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 917,
    lastQuestionId: 364,
    treeDepth: 4,
  },
};
