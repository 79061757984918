import React, {useContext} from 'react';
import images from 'data/images';
import {Link} from 'react-router-dom';
import MyLink from 'components/MyLink';
import './Footer.css';
import moment from 'moment';
import AuthContext from 'context/AuthContext';
import _ from 'lodash';
import config from 'config/config';
import constants from 'data/constants';

const {StaticFooterPage} = constants;

const Footer = props => {
  const authContext = useContext(AuthContext);
  const siteSettings = _.get(authContext, 'siteSettings');

  return (
    <div className="Footer">
      <div className="footer-top">
        <div className="container">
          <div className="footer-main">
            <div className="footer-left">
              <div className="footer-left-inner">
                <div className="footer-widget widget-one">
                  <img src={images.logo} alt="logo" />
                  <div className="paragraph">Connecting you with the right care</div>
                </div>
                <div className="footer-widget widget-two">
                  <div className="h3">Company</div>
                  <div className="footer-menu-ul">
                    <div className="menu-item-list">
                      <a
                        href={config.APP_DOMAIN + StaticFooterPage.TERMS_CONDITION}
                        target="_blank"
                        rel="noopener noreferrer">
                        Terms & Conditions
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.APP_DOMAIN+ StaticFooterPage.PRIVACY_POLICY}
                        target="_blank"
                        rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.APP_DOMAIN+ StaticFooterPage.HIPAA}
                        target="_blank"
                        rel="noopener noreferrer">
                        HIPAA NPP
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.APP_DOMAIN+ StaticFooterPage.CCPA}
                        target="_blank"
                        rel="noopener noreferrer">
                        CCPA Policy
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.APP_DOMAIN+ StaticFooterPage.HEALTHCARE}
                        target="_blank"
                        rel="noopener noreferrer">
                        Healthcare Provider Agreement
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.APP_DOMAIN+ StaticFooterPage.REVIEW_POLICY}
                        target="_blank"
                        rel="noopener noreferrer">
                        Review Policy
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.APP_DOMAIN+ StaticFooterPage.FAQ}
                        target="_blank"
                        rel="noopener noreferrer">
                        FAQ
                      </a>
                    </div>
                   
                  </div>
                </div>
                <div className="footer-widget widget-three">
                  <div className="h3">Services</div>
                  <div className="footer-menu-ul">
                    <div className="menu-item-list">
                      <Link to="/list-your-practice">List your practice</Link>
                    </div>
                    <div className="menu-item-list">
                      <a href={`mailto:${_.get(siteSettings, 'contactUsLinks', '')}`}>Contact us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-right">
              <div className="footer-right-inner">
                <div className="footer-menu-ul">
                <div className="menu-item-list">
                    <MyLink to={_.get(siteSettings, 'LinkedInLink', '')}>
                      <img src={images.linkedIn} alt="LinkedIn" />
                    </MyLink>
                  </div>
                  <div className="menu-item-list">
                    <MyLink to={_.get(siteSettings, 'twitterLink', '')}>
                      <img src={images.twitter} alt="Twitter" />
                    </MyLink>
                  </div>
                  <div className="menu-item-list">
                    <MyLink to={_.get(siteSettings, 'instagramLink', '')}>
                      <img src={images.instagram} alt="Instagram" />
                    </MyLink>
                  </div>
                  <div className="menu-item-list">
                    <MyLink to={_.get(siteSettings, 'fbLink', '')}>
                      <img src={images.facebook} alt="Facebook" />
                    </MyLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="paragraph">©{moment().year()} Kaly All right reserved. </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
