import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import images from 'data/images';
import feedbackService from 'services/providerFeedback';
import EachFeedback from 'components/AfterAuth/ProviderFeedback/EachFeedback';
import "./ConsultrequestEdit.css"
import KalyMDRequestForm from 'containers/Patient/KalyMDRequestForm';
import Viewrequest from './Viewrequest';
import ViewFullrequest from './ViewFullrequest';
const {
  getfeedbacksingle
} = feedbackService;

const SingleFeedbackModal = props => {
  const { closeModal } = props;
  console.log("props.data", props.data);
  const [selectedItem, setSelectedItem] = useState(null)
  // useEffect(() => {
  //   if (props.data.selectedFeedbackid) {
  //     fetrchdata(props.data.selectedFeedbackid)
  //   }
  // }, [props.data.selectedFeedbackid])
  const fetrchdata = async (id) => {
    let item = await getfeedbacksingle(id)
    setSelectedItem(item.data)
  }
  return (
    <div className="InvitePractice ConsultrequestEdit">
      <div className="modal-content-header">
        <div className="h3">{props.data.consultRequestStatusId == 1 ? `Edit Consult Request` : `View Consult Request`}</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      {props.data.consultRequestStatusId == 1 ? (
        <div className="modal-content-body">
          <Viewrequest data={props.data} />
          <KalyMDRequestForm getConsultrequestData={props.getConsultrequestData} data={props.data} isEdit={true} />
        </div>
      ) : (
        <div className="modal-content-body viewmaindiv">
          <ViewFullrequest data={props.data} />

        </div>
      )}

    </div>
  );
};

export default SingleFeedbackModal;
