const questionList = [
  {
    id: 381,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [8, 10], // painIds,
    nextQuestionId: 382,
    descriptions: [],
  },
  {
    id: 382,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 383,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 8, questionId: 23, visitReasonIds: [74], extraQuestion: 1},
          {painId: 10, questionId: 24, visitReasonIds: [75], extraQuestion: 2},
        ],
      },
      {
        nextQuestionId: 383,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 8, questionId: 24, visitReasonIds: [74], extraQuestion: 2},
          {painId: 10, questionId: 24, visitReasonIds: [75], extraQuestion: 2},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 383,
    question: 'Are you interested in potentially undergoing surgery?',
    options: [
      {
        nextQuestionId: 384,
        label: 'Yes, in the near future',
        shortLabel: 'Interested in surgery',
      },
      {nextQuestionId: 384, label: 'No, not at this time', shortLabel: 'No surgery'},
    ],
    descriptions: [],
  },
  {
    id: 384,
    question: 'Do you still want to see a Foot and Ankle surgeon?',
    descriptions: [
      'A Foot and Ankle Surgeon is a medical physician (MD/DO) who completed a 1 year fellowship in Foot and Ankle surgery, after completing a 5 year residency in Orthopedic surgery. A Foot and Ankle surgeon has further training in treating foot and ankle conditions that may require surgery.',
    ],
    title: 'Foot and Ankle Surgeon',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can perform pain injections',
    ],
    options: [
      {groupId: 'R', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 381,
    lastQuestionId: 384,
    treeDepth: 4,
  },
};
