const questionList = [
  {
    id: 568,
    question: 'What type of procedure are you looking for?',
    optionType: 3,
    procedureOptionIds: [77, 80, 92, 93, 94, 85, 95, 96],
    nextQuestionId: 569,
    descriptions: [],
  },
  {
    id: 569,
    question: 'Do you get nervous when you go to the dentist?',
    questionType: 4,
    optionType: 0,
    options: [
      {
        nextQuestionId: 570,
        label: 'Not at all',
        shortLabel: 'Not nervous',
      },
      {
        nextQuestionId: 570,
        label: 'A little bit',
        shortLabel: 'A little nervous',
      },
      {
        nextQuestionId: 570,
        label: 'Very nervous',
        shortLabel: 'Very nervous',
      },
    ],
    descriptions: [
      'Your answer may help your dentist accomodate you by scheduling you at an ideal time or potentially offering you other options that may help.',
    ],
  },
  {
    id: 570,
    question: 'Do you still want to see an Oral Surgeon?',
    descriptions: [
      'An Oral surgeon is a dentist (DMD/DDS) who completed a four to six year residency after completing four years of dental school. An Oral Surgeon specializes in the diagnosis and surgical treatment of injuries, defects, and aesthetic aspects of the mouth, teeth, jaws, and face.',
    ],
    title: 'Oral Surgeon',
    optionType: 0,
    questionType: 1,
    canDoList: [],
    options: [
      {groupId: 'A2', label: 'Yes, see Oral Surgeon', isGoToSymptomFlow: false},
      {
        label: 'No, I am looking for something else',
        nextQuestionId: 2202,
        addExtraQuestion: 4,
      },
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 568,
    lastQuestionId: 570,
    treeDepth: 3,
  },
};
