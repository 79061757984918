import React from 'react';
import images from 'data/images';
import _ from 'lodash';
import './DashboardCount.css';

const DashboardCount = ({data}) => {
  const contentCompoent = data.map((item, i) => {
    let percentageClass = '';
    let sign = '+';
    let image = images.upArrow;
    if (item.parcentText < 0) {
      image = images.downArrow;
      percentageClass = 'decrement';
      sign = '';
    }

    return (
      <span className="Counter-box" key={i}>
        <span className="count-info">
          {(item.UpDown)?(
            <>
          
            <span className="count">{item.number}
            <span className={['indicater',item.trendKey==0?'normal':item.trendKey>0?'positive':'negative'].join(' ')}>
              <span className='shape'></span>
              <span>{item.trendKey>0?`+${item.trendKey}`:item.trendKey}</span>
            </span>
            </span>
            <span className="count-title">{item.title}</span>
            <span className="count-sub-title">(Last 30 Days)</span>
            </>
          ):(
            <>
          <span className="count">{item.number}</span>
          <span className="count-title">{item.title}</span>
          </>
          )}
          { item.parcentText>=0  && (
            <span className={`parcent ${percentageClass}`}>
              {/* {sign} */}
              {item.parcentText} {' '}
              {/* % */}
              {/* <img src={image} alt="" /> */}
              {item.parcentText>1?`Reviews`:`Review`}
            </span>
          )}
        </span>
        {
          _.get(item, "infoIconVisible" , false) &&   
          <span className="info-wrapper">
            <img src={images.info} alt="info" className="info" />
            <span className="info-content">
              This is a kScore - an aggregate of reviews from top review websites in our
              industry.
            </span>
          </span>
         }
      </span>
    );
  });
  return (
    <div>
      <div className="DashboardCount">
        {contentCompoent}
      </div>
    </div>
  );
};

export default DashboardCount;
