import React, {Component} from 'react';
import Layout from 'layout/VideoLayout';
import VideoSidebar from 'components/VideoVisits/VideoSidebar';
import VideoPersonScreen from 'components/VideoVisits/VideoPersonScreen';
import './VideoVisit.css';
import twillioService from 'services/twillio';
import AuthContext from 'context/AuthContext';
import _ from 'lodash';
import constants from 'data/constants';
import Chat from 'twilio-chat';
import scheduleService from 'services/schedule';
import patientService from 'services/patient';
import helper from 'data/helper';
import moment from 'moment';
import momentHelper from 'data/moment/momentHelper';
import VideoWaitingRoom from '../VideoWaitingRoom';
import rolePermission from 'data/rolePermission';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import config from 'config/config';

const {helperFunctions, permissionList} = rolePermission;
const {modalFunctions} = modalConfig;
const {errorModalData} = modalFunctions;
const {utcToLocalMoment} = momentHelper;
const {getSuffixString} = helper;
const {VideoUserType} = constants;
const {getTwilioToken} = twillioService;
const {getAllMyRoleIds, hasPermission} = helperFunctions;
const {getAppointmentForProviderBytId, getAllAppointments} = scheduleService;
const {getAppointmentDetailsById} = patientService;
const initialState = {
  username: 'provider',
  roomName: '',
  appointmentId: '',
  token: '',
  isMobile: '',
  userType: '',
  errors: '',
  messages: [],
  current: '',
  appointmentData: {},
  upcomingAppointment: {},
  nextId: '',
  otherParticipantName: '',
  isChannelCreated: false,
  doctorName: '',
  isProviderAvailable: false,
  memberCount: 0,
  isExpand: false,
  showVideo: true,
};

class VideoVisit extends Component {
  state = {...initialState};
  static contextType = AuthContext;
  rootContext = null;

  getUserType = () => {};

  handleLogout = () => {
    this.setState({token: null});
    this.chatLogout();
    const params = new URLSearchParams();
    if (this.state.appointmentId) {
      params.append('appointment', this.state.appointmentId);
    }
    const url =
      this.state.userType === VideoUserType.PROVIDER
        ? '/doctor-waiting-room'
        : '/patient-video-exit';
    this.props.history.push({
      pathname: url,
      search: `?${params.toString()}`,
    });
  };

  chatLogout = () => {
    if (this.state.channel) {
      this.state.channel.leave();
      if (this.state.channel.members.size < 1) {
        this.state.channel.delete();
      }
    }
    return true;
  };

  componentWillUnmount = () => {
    const {auth} = this.context;
    let isPatient = true;
    if (auth) {
      const roleIds = getAllMyRoleIds(auth);
      if (!hasPermission(roleIds, permissionList.FRONTEND_USER)) {
        isPatient = false;
      }
    }
    if (isPatient) {
      this.chatLogout();
    }
  };

  goToClosedRoom = () => {
    this.setState({token: null});
    this.chatLogout();
    if (this.state.userType === VideoUserType.PATIENT) {
      const params = new URLSearchParams();
      if (this.state.appointmentId) {
        params.append('appointment', this.state.appointmentId);
      }
      this.props.history.push({
        pathname: '/patient-video-closed',
        search: `?${params.toString()}`,
      });
    }
  };

  checkTab = isShow => {
    this.setState({showVideo: isShow});
  };

  componentDidMount = () => {
    const {auth} = this.context;
    const accessToken = localStorage.getItem('accessToken');
    if (auth && accessToken) {
      this.getAppointmentData();
      this.getToken();
      this.handleWindowSizeChange();
      window.addEventListener('resize', this.handleWindowSizeChange);
      window.addEventListener('beforeunload', this.chatLogout);
      return () => {
        window.removeEventListener('resize', this.handleWindowSizeChange);
      };
    }
  };

  handleWindowSizeChange = () => {
    if (window.innerWidth <= 767) {
      this.setState({isMobile: true});
    } else {
      this.setState({isMobile: false, showVideo: true});
    }
  };

  addError = error => {
    this.setState({
      errors: [...this.state.errors, error.message],
    });
  };

  getAppointmentData = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('appointment') || 0;
    const {auth} = this.context;
    const roleId = _.get(auth, 'roles.0.id', '');
    let appointmentDetail = {};
    let upcoming = {};
    let nextId = '';
    let user = '';
    let otherParticipantName = '';
    if (roleId == 2) {
      user = VideoUserType.PATIENT;
      this.getPatientData();
    }
    if (roleId == 3 || roleId == 4) {
      user = VideoUserType.PROVIDER;
      appointmentDetail = await this.getProviderData(id);
      const loginProvider = _.get(auth, 'provider.id', '');
      const appointmentProvider = _.get(appointmentDetail, 'providerAvailability.provider.id', '');
      if (loginProvider !== appointmentProvider) {
        this.props.history.push({pathname: '/'});
      }
      const patientUser = _.get(appointmentDetail, 'patient', {});
      otherParticipantName = `${_.get(patientUser, 'firstName', '')} ${_.get(
        patientUser,
        'lastName',
        '',
      )}`;
      if (config.CHECK_VISIT_VALIDATION) {
        this.checkAppointmentTime(appointmentDetail, otherParticipantName);
      }
      upcoming = await this.getAndSetUpcomingAppointments(id);
      nextId = _.get(upcoming, 'id', '');
    }
    this.setState(prevState => {
      return {
        ...prevState,
        appointmentId: id,
        userType: user,
        appointmentData: appointmentDetail,
        nextId: nextId,
        otherParticipantName: otherParticipantName,
      };
    });
  };

  checkAppointmentTime = (appointmentDetail, otherParticipantName) => {
    const {auth} = this.context;
    const roleId = _.get(auth, 'roles.0.id', '');
    const appointmentStartDateTime = _.get(appointmentDetail, 'appointmentStartDateTime', '');
    const appointmentEndDateTime = _.get(appointmentDetail, 'appointmentEndDateTime', '');
    const apStartDateTimeObj = utcToLocalMoment(appointmentStartDateTime);
    const apEndDateTimeObj = utcToLocalMoment(appointmentEndDateTime);
    let startDiff = moment().diff(apStartDateTimeObj, 'minutes');
    let endDiff = moment().diff(apEndDateTimeObj, 'minutes');
    if (!(startDiff > -5 && endDiff <= 0)) {
      this.handleAppointmentError(
        {
          message: `Your video visit with ${otherParticipantName} can be started at 5 minutes before the scheduled time.`,
        },
        false,
      );
      const goTo = roleId == 2 ? '/' : '/doctor-waiting-room';
      this.props.history.push({pathname: goTo});
    }
  };

  getProviderData = async id => {
    const upcomingAppointmentResponse = await getAppointmentForProviderBytId(id).catch(error => {
      this.handleAppointmentError(error);
      return null;
    });
    if (upcomingAppointmentResponse) {
      const {data} = upcomingAppointmentResponse;
      return data;
    }
  };

  getPatientData = async () => {
    const {auth} = this.context;
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('appointment') || 0;
    const response = await getAppointmentDetailsById(id).catch(error => {
      this.handleAppointmentError(error);
      return null;
    });
    if (response) {
      const {data} = response;
      const doctorUser = _.get(data, 'providerAvailability.provider.user', []);
      const suffixes = _.get(data, 'providerAvailability.provider.suffixes', []);
      const firstName = _.get(doctorUser, 'firstName', '');
      const lastName = _.get(doctorUser, 'lastName', '');
      const otherParticipantName = `${firstName} ${lastName}`;
      const doctorName = `${firstName} ${lastName}${getSuffixString(suffixes)}`;
      const loginPatient = _.get(auth, 'id', '');
      const appointmentPatient = _.get(data, 'patient.user.id', '');
      if (config.CHECK_VISIT_VALIDATION) {
        this.checkAppointmentTime(data, otherParticipantName);
      }
      if (loginPatient !== appointmentPatient) {
        this.props.history.push({pathname: '/'});
      }
      this.setState({
        otherParticipantName: otherParticipantName,
        doctorName: doctorName,
        appointmentData: data,
      });
    }
  };

  handleAppointmentError = error => {
    const {setGlobal} = this.rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    this.props.history.push({pathname: '/'});
    return null;
  };

  getAndSetUpcomingAppointments = async id => {
    let today = moment();
    const upcomingAppointmentResponse = await getAllAppointments({type: 'upcoming'}).catch(
      error => null,
    );
    if (upcomingAppointmentResponse) {
      const {data} = upcomingAppointmentResponse;
      const upcomingAppointmentData = data.filter(
        appointment =>
          appointment.id !== id &&
          appointment.isVirtual &&
          utcToLocalMoment(appointment.appointmentStartDateTime).isSame(today, 'day'),
      );
      const upcoming =
        upcomingAppointmentData && upcomingAppointmentData.length > 0
          ? upcomingAppointmentData[0]
          : {};
      this.getAppoinmentStatus(upcoming);
      return upcoming;
    }
  };

  getAppoinmentStatus = async data => {
    const id = _.get(data, 'id', '');
    const upcomingAppointment = {...data, patientInRoom: false, roomName: 'kalypsysRoom' + id};
    const {auth} = this.context;
    const firstName = _.get(auth, 'firstName', '');
    const lastName = _.get(auth, 'lastName', '');
    const fullName = `${firstName} ${lastName}`;
    if (id) {
      let response = await getTwilioToken({
        identity: fullName,
        roomName: _.get(upcomingAppointment, 'roomName', ''),
        appointmentId: id,
      });
      const {data: res} = response;
      this.getChatToken(res.token)
        .then(() => this.createChatClient(res.token))
        .then(client =>
          this.checkChannelData(
            client,
            upcomingAppointment.roomName,
            upcomingAppointment,
            origin,
            id,
          ),
        )
        .catch(error => {
          console.log('error:' + error);
        });
    }
  };

  checkChannelData = (chatClient, roomName, appointment, origin, id) => {
    return new Promise((resolve, reject) => {
      chatClient
        .getSubscribedChannels()
        .then(() => {
          chatClient
            .getChannelByUniqueName(roomName)
            .then(async channel => {
              await channel.getMembersCount().then(countMember => {
                const appointmentData = {
                  ...appointment,
                  patientInRoom: countMember && countMember > 0,
                };
                this.setState({upcomingAppointment: {...appointmentData}});
              });
              resolve(channel);
            })
            .catch(error => {
              const appointmentData = {
                ...appointment,
                patientInRoom: false,
              };
              this.setState({upcomingAppointment: {...appointmentData}});
              console.log(error);
            });
        })
        .catch(error => {
          console.log('error:' + error);
          reject(Error('Could not get channel list.'));
        });
    });
  };

  getToken = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('appointment') || 0;
    const roomName = 'kalypsysRoom' + id;
    const {auth} = this.context;
    const firstName = _.get(auth, 'firstName', '');
    const lastName = _.get(auth, 'lastName', '');
    const fullName = `${firstName} ${lastName}`;
    let response = await getTwilioToken({
      identity: fullName,
      roomName: roomName,
      appointmentId: id,
    });
    const {data} = response;
    this.setState({token: data.token, username: fullName, roomName: roomName});
    this.getChatToken(data.token)
      .then(() => this.createChatClient(data.token))
      .then(this.joinGeneralChannel)
      .then(this.configureChannelEvents)
      .catch(error => {
        this.addError(error);
      });
  };

  getChatToken = token => {
    return new Promise((resolve, reject) => {
      // this.addMessage({body: `Connecting...`});
      resolve(token);
    });
  };

  createChatClient = token => {
    return new Promise((resolve, reject) => {
      resolve(new Chat.create(token));
    });
  };

  joinGeneralChannel = chatClient => {
    return new Promise((resolve, reject) => {
      chatClient
        .getSubscribedChannels()
        .then(() => {
          chatClient
            .getChannelByUniqueName(this.state.roomName)
            .then(channel => {
              // this.addMessage({body: `Joining ${this.state.roomName} channel...`});
              this.setState({channel});
              this.setState({isChannelCreated: true});
              channel
                .join()
                .then(data => {
                  channel.getMembers().then(data => {
                    if (data && data.length > 1) {
                      this.setState({isProviderAvailable: true});
                    }
                  });
                  channel.getMessages().then(messages => {
                    if (messages && messages.items && messages.items.length > 0) {
                      _.forEach(messages.items, async msg => {
                        let {author, body, type, fileName, fileSize, media, fileType} = msg;
                        if (type === 'media') {
                          await msg.media.getContentTemporaryUrl().then(function (url) {
                            body = `${url}`;
                            fileName = media.filename;
                            fileSize = media.size;
                            fileType = media.contentType;
                          });
                        }
                        this.addMessage({...msg, author, body, type, fileName, fileSize, fileType});
                      });
                    }
                  });
                })
                .catch(error => {
                  this.addError(error);
                  reject(Error('Could not join general channel.'));
                });
              resolve(channel);
            })
            .catch(() => this.createGeneralChannel(chatClient));
        })
        .catch(error => {
          this.addError(error);
          reject(Error('Could not get channel list.'));
        });
    });
  };

  configureChannelEvents = channel => {
    channel.on('messageAdded', async msgData => {
      let {author, body, type, fileName, fileSize, media, fileType} = msgData;
      this.setState({loading: 1});
      if (type === 'media') {
        await msgData.media.getContentTemporaryUrl().then(function (url) {
          body = `${url}`;
          fileName = media.filename;
          fileSize = media.size;
          fileType = media.contentType;
        });
      }
      this.setState({loading: null});
      this.addMessage({author, body, type, fileName, fileSize, fileType});
    });

    channel.on('memberJoined', member => {
      const {userType, isProviderAvailable} = this.state;
      if (userType === VideoUserType.PATIENT && !isProviderAvailable) {
        this.setState({isProviderAvailable: true});
      }
    });

    channel.on('memberLeft', member => {
      if (this.state.userType === VideoUserType.PATIENT) {
        this.addMessage({body: `${member.identity} has left the channel.`});
        this.goToClosedRoom();
      }
    });
  };

  handleNewMessage = () => {
    if (this.state.channel) {
      this.state.channel.sendMessage(this.state.text);
      this.setState({text: ''});
    }
  };

  setInputText = val => {
    this.setState({current: val});
  };

  createGeneralChannel = chatClient => {
    return new Promise((resolve, reject) => {
      // this.addMessage({body: `Creating ${this.state.roomName} channel...`});
      chatClient
        .createChannel({uniqueName: this.state.roomName})
        .then(this.joinGeneralChannel(chatClient))
        .then(this.configureChannelEvents)
        .catch(error => {
          this.addError(error);
          reject(Error('Could not create general channel.'));
        });
    });
  };

  putMessage = event => {
    event.preventDefault();
    this.handleNewMessage();
  };

  handleNewMessage = () => {
    if (this.state.channel && this.state.current) {
      this.state.channel.sendMessage(this.state.current);
      this.setState({current: ''});
    }
  };

  addMessage = message => {
    const messageData = {
      body: message.body,
      author: message.author || 'system',
      type: message.type || 'system',
      dateTime: new Date().toString(),
      isPatient: false,
      isProvider: true,
      fileName: message.fileName,
      fileSize: message.fileSize,
      fileType: message.fileType,
    };
    this.setState({
      messages: [...this.state.messages, messageData],
    });
  };

  checkFile = e => {
    let file = e.target.files[0];
    if (this.state.channel) {
      const formData = new FormData();
      formData.append('file', file);
      this.state.channel.sendMessage(formData);
    }
  };

  viewFullScreen = isExpandApplied => {
    this.setState({isExpand: isExpandApplied});
  };
  openVideoTab=val=>{
   this.setState({showVideo:val})
  }

  render() {
    const {
      roomName,
      token,
      appointmentId,
      userType,
      username,
      current,
      messages,
      isProviderAvailable,
      isExpand,
      showVideo,
      isMobile,
    } = this.state;
    const videoVisitComponent = (
      <Layout
        showExit={userType === VideoUserType.PROVIDER ? true : false}
        id={appointmentId}
        onExit={this.handleLogout}
        isExpand={isExpand}>
        <div className="VideoVisitBlock">
          <div className="video-visit-row">
            <div className="video-left">
              {token && (
                <VideoPersonScreen
                  roomName={roomName}
                  token={token}
                  handleLogout={this.handleLogout}
                  otherParticipantName={this.state.otherParticipantName}
                  userType={userType}
                  appointmentId={appointmentId}
                  goToClosedRoom={this.goToClosedRoom}
                  viewFullScreen={this.viewFullScreen}
                  isExpand={isExpand}
                  showVideo={showVideo}
                  isMobile={isMobile}
                  checkTab={this.checkTab}
                
                />
              )}
            </div>
            <div className={isExpand ? 'video-right hide-sidebar' : 'video-right'}>
              {token && (
                <VideoSidebar
                  roomName={roomName}
                  token={token}
                  appointmentId={appointmentId}
                  userType={userType}
                  username={username}
                  setInputText={this.setInputText}
                  current={current}
                  messages={messages}
                  putMessage={this.putMessage}
                  checkFile={this.checkFile}
                  appointmentData={this.state.appointmentData}
                  upcomingAppointment={this.state.upcomingAppointment}
                  nextId={this.state.nextId}
                  otherParticipantName={this.state.otherParticipantName}
                  isChannelCreated={this.state.isChannelCreated}
                  doctorName={this.state.doctorName}
                  isMobile={isMobile}
                  checkTab={this.checkTab}
                  showVideo={showVideo}
                  openVideoTab={this.openVideoTab}
                />
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
    return (
      <>
        <RootContext.Consumer>
          {context => {
            this.rootContext = context;
            return null;
          }}
        </RootContext.Consumer>
        {userType === VideoUserType.PATIENT && !isProviderAvailable ? (
          <VideoWaitingRoom id={appointmentId} />
        ) : (
          videoVisitComponent
        )}
      </>
    );
  }
}

export default VideoVisit;
