import React, {useContext} from 'react';
import _ from 'lodash';
import AuthContext from 'context/AuthContext';
import helper from 'data/helper';
import './SubHeader.css';

const {getSuffixString} = helper;

const SubHeader = props => {
  const {title = 'Provider Dashboard', subtitle, hideName = false} = props;
  let updatedSubtitle = subtitle;
  const authContext = useContext(AuthContext);
  if (!updatedSubtitle) {
    const {auth} = authContext;
    const viewUserId = _.get(auth, 'viewUserId');
    const practiceMembers = _.get(auth, 'practiceMembers');
    let viewUser = _.cloneDeep(auth);
    if (viewUserId) {
      viewUser = _.find(practiceMembers, {id: viewUserId});
    }
    const firstName = _.get(viewUser, 'firstName', '');
    const lastName = _.get(viewUser, 'lastName', '');
    const fullName = firstName + ' ' + lastName;
    const suffixes = _.get(viewUser, 'provider.suffixes', []);
    updatedSubtitle = fullName + getSuffixString(suffixes);
  }

  return (
    <div className="AfterAuthSubHeader">
      <div className="main-content">
        <div className="main-inner-content">
          <div className="title">
            <span>{title}</span>
            {!hideName && <span className="name">{updatedSubtitle}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
