import React, {Component} from 'react';
import moment from 'moment';
import './PatientTab.css';
import NextVideoAppointment from '../NextVideoAppointment';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import ViewPatientOverlayModal from 'components/AfterAuth/Dashboard/ViewPatientOverlayModal';
import _ from 'lodash';
import momentHelper from 'data/moment/momentHelper';
import selectOptions from 'data/selectOptions';
import helper from 'data/helper';
import constants from 'data/constants';
import {getHideQuestionID} from 'data/questionnaires/questionnaire.helper'
import TimeZoneLabelShort from 'components/MyDatetime/TimeZoneLabelShort';
const HideQuestionID =getHideQuestionID()
const {VideoUserType} = constants;
const {genderOptions} = selectOptions;
const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;
const {utcToLocalMoment} = momentHelper;
const {getPatientUserAddress, formatPhoneNumber} = helper;
const initialState = {
  height: 0,
  appointmentData: {},
};

class PatientTab extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount() {
    this.showCount();
  }

  openViewPatientOverlayModal = patientUserId => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ViewPatientOverlayModal,
        modalWrapperClass: 'mega-modal',
        customComponentProps: {
          data: {
            patientUserId,
          },
        },
        callBackOnClose: () => {
          // refetchAppointmentEvents(appointmentId, 'modal');
          // this.getAndSetUpcomingAppointments();
        },
      }),
    );
  };

  showCount = e => {
    const middleContent = document.getElementsByClassName('tabbing-scroll');
    const offsetmiddleContent = middleContent[0].offsetTop;
    const sidebarBottom = document.getElementsByClassName('sidebar-bottom');
    const sidebarBottomHeight = sidebarBottom[0].clientHeight;
    const height = offsetmiddleContent + sidebarBottomHeight;
    this.setState({height});
  };

  render() {
    const {appointmentData} = this.props;
    const answers = _.get(appointmentData, 'flowResponse', []);
    const appointmentStartDateTimeObject = utcToLocalMoment(
      appointmentData.appointmentStartDateTime,
    );
    const appointmentEndDateTimeObject = utcToLocalMoment(appointmentData.appointmentEndDateTime);
    const patient = _.get(appointmentData, 'patient', {});
    const genderValueId = _.get(patient, 'gender', 1);
    const gender = _.find(genderOptions, {value: genderValueId});
    let dateOfBirth = _.get(patient, 'dateOfBirth', null);
    let dateOfBirthString = '';
    if (dateOfBirth) {
      dateOfBirthString =
        moment(dateOfBirth).format('MM/DD/YYYY') +
        ' (' +
        moment().diff(dateOfBirth, 'years') +
        ' years old) ';
    }
    const addressString = getPatientUserAddress(patient.user);
    const formatedPhoneNumber = formatPhoneNumber(appointmentData.phoneNumber);
    const insurancePlan = _.get(appointmentData, 'insurancePlan', {});
    const insuranceCompany = _.get(appointmentData, 'insuranceCompany', {});
    const insuranceMemberId = _.get(appointmentData, 'insuranceMemberId', '');
    const patientUserId = _.get(patient, 'user.id', 0);
    const duration = moment
      .duration(appointmentEndDateTimeObject.diff(appointmentStartDateTimeObject))
      .asMinutes();

    return (
      <div className="PatientTab">
        <div
          className="patient-details tabbing-scroll"
          style={{height: 'calc(100vh - ' + this.state.height + 'px)'}}>
          <div className="date-time">
            {appointmentStartDateTimeObject.format('dddd, MMM DD [at] h:mm a')}<TimeZoneLabelShort/>
          </div>
          <div className="time-duration">{duration} min Video Visit</div>
          <div className="patientname-block">
            <span className="patient-name">
              {patient.firstName} {patient.lastName}
            </span>
            <span
              className="patient-link"
              onClick={() => this.openViewPatientOverlayModal(patientUserId)}>
              View Patient
            </span>
          </div>
          <div className="patient-address">
            {gender && gender.label} - {dateOfBirthString}
            {addressString && (
              <>
                <br />
                {addressString}
              </>
            )}
            <br />
            {formatedPhoneNumber}
          </div>
          <div className="patient-data">
            {_.get(insuranceCompany, 'companyName', '') && (
              <span>{_.get(insuranceCompany, 'companyName', '')}</span>
            )}
            {insurancePlan && insurancePlan.plan}
            {insuranceCompany && insurancePlan && (
              <span>Member ID: {insuranceMemberId ? insuranceMemberId : 'not specified'}</span>
            )}
          </div>
          <div className="questionnaire">
            <div className="heading3">Questionnaire: </div>
            {answers.filter
            ((q)=>!HideQuestionID.includes(q.questionId)).map(answerObj => {
              return <span key={answerObj.question}>{answerObj.answer}</span>;
            })}
          </div>
        </div>
        <div className="sidebar-bottom">
          {this.props.userType === VideoUserType.PROVIDER && (
            <NextVideoAppointment
              showCount={this.showCount}
              onViewPatient={this.openViewPatientOverlayModal}
              currentAppointment={this.props.appointmentId}
              upcomingAppointment={this.props.upcomingAppointment}
              nextId={this.props.nextId}
            />
          )}
        </div>
      </div>
    );
  }
}

export default PatientTab;
