import React, {useState, useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import images from 'data/images';
import validators from 'data/validators';
import allRegex from 'data/regex';
import RootContext from 'context/RootContext';
import config from 'config/config';
import helper from 'data/helper';
import './Joinkalypsys.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import modalConfig from 'modals/modalConfig';
const {exceptDigitsRegex, alphabetsHyphenOnlyRegex} = allRegex;
const {__required, __phoneNumber, __email, __maxLength} = validators;
const {MAX_TEXT_LENGTH} = config;
const {scrollToDiv} = helper;
const {Analytics} = analyticsData;
const {errorModalData} = modalConfig.modalFunctions;
const ScreenOne = props => {
  const {getGeneralInputProps,specialties,formValues}=props
 
  return (
    <div className="split-screeninput">
      <div className="form-row">
        <Input
          {...getGeneralInputProps('firstName')}
          containerClass="two-input"
          type="text"
          label="First name"
        />
        <Input
          {...getGeneralInputProps('lastName')}
          containerClass="two-input"
          type="text"
          label="Last name"
        />
      </div>
      <Select
        {...getGeneralInputProps('specialtyId')}
        options={specialties}
        labelIndex="specialty"
        valueIndex="id"
        label="Primary Specialty"
      />
      {formValues['specialtyId'] === 'null' && (
        <Input
          type="text"
          InputComponent={InputMask}
          {...getGeneralInputProps('newSpecialtyRequest')}
          label="Your Specialty"
        />
      )}
      <Input {...getGeneralInputProps('email')} type="text" label="Practice Email" />
    </div>
  );
};

export default ScreenOne;
