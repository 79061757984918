import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Layout from 'layout';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Checkbox from 'components/FormControls/Checkbox';
import images from 'data/images';
import Divider from 'components/Divider';
import SocialButton from 'components/SocialButton';
import RootContext from 'context/RootContext';
import userService from 'services/user';
import patientService from 'services/patient';
import modalConfig from 'modals/modalConfig';
import validators from 'data/validators';
import AuthContext from 'context/AuthContext';
import config from 'config/config';
import './PatientLogin.css';
import EmailInputModal from 'components/Patient/ConfirmAppointment/EmailInputModal';
import _, {debounce} from 'lodash';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import {SocialTermsofUse} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/SocialTermsofUse';
import {Passwordrequired} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/Passwordrequired';
import {SocialGenderDobMain} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/SocialGenderDob';
import allRegex from 'data/regex';
import helper from 'data/helper';

const {signIn, validateEmailorPhoneNumber} = userService;
const {patientSocialLogin, verifyPatientPhoneNumberOtp, resendotpphonenumber, resendOtpEmail} =
  patientService;
const {errorModalData, blankModalData, successModalData} = modalConfig.modalFunctions;
const {formatPhoneNumberWithDash} = helper;
const {__required, __email, __phoneNumber} = validators;
const {Analytics} = analyticsData;
const {phonenumberRegex} = allRegex;
class PatientLogin extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {
    isProcessing: false,
    showPassword: false,
    showOtp: false,
    valueType: '',
    tempUserID: 0,
    enableLoginAction: false,
    values: {
      type: 'patient',
      email: '',
      password: '',
      rememberMe: false,
    },
    errors: {},
  };

  afterLoginHandler = response => {
    const {updateAuthStatus} = this.context;
    const {history} = this.props;
    const {data} = response;
    const {accessToken, refreshToken} = data;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    updateAuthStatus(true);
    let urlList = window.location.href.split('goto=');

    const {location} = history;

    if (urlList.length === 2) {
      // const url = new URL(urlList[1]);
      // let pathName = url.pathname.replace('/app', '');
      // pathName = pathName === '' ? '/' : pathName;
      // window.location.href = urlList[1]
      // setTimeout(() => {
      //   history.push({pathname: pathName, search: url.search});
      // }, 1000);
    } else if (location.state && location.state.fromReserveAppointment) {
      let tempState = {...location.state};
      delete tempState.fromReserveAppointment;
      history.push({
        pathname: `/confirm-appointment`,
        //search:appointmentSourceUrl,
        state: tempState,
      });
      return;
    } else if (location.state && location.state.fromReviewWrite) {
      let tempState = {...location.state, callSave: true};
      delete tempState.fromReviewWrite;
      history.push({
        pathname: `/write-review/${location.state.appointmentId}`,
        //search:appointmentSourceUrl,
        state: tempState,
      });
      return;
    } else if (history.length > 2 && history.action === 'PUSH' && location.state) {
      //console.log("history.location.pathname",history.location.goBack());
      // after login,go back to confirm appointment page if history state is available,else dashboard page.
      history.goBack();
    } else {
      history.push({
        pathname: '/',
      });
    }
  };

  loginHandler = async () => {
    if (this.state.showOtp) {
      const {values: formValues, tempUserID} = this.state;
      verifyPatientPhoneNumberOtp({
        userOtp: formValues['otp'],
        userID: tempUserID,
        hasEmailVerified: this.state.valueType === 'email' ? true : false,
        hasPhoneNumberVerified: this.state.valueType === 'phonenumber' ? true : false,
      })
        .then(res => {
          this.afterLoginHandler(res);
        })
        .catch(this.handleError);
    } else {
      const {values: formValues} = this.state;
      this.setState({isProcessing: true});
      const response = await signIn(formValues).catch(this.handleError);
      this.setState({isProcessing: false});
      if (response) {
        this.afterLoginHandler(response);
      }
    }
  };

  handleError = error => {
    const {setGlobal} = this.rootContext;
    let {message} = error;
    if (message === 'Invalid credentials.') {
      message = (
        <p>
          The username or password was not recognized. If you are a provider, please log in with the{' '}
          <Link
            onClick={() => {
              setGlobal('modal', {errorModalData, showModal: false});
            }}
            to="/login">
            Provider Login
          </Link>
          .
        </p>
      );
    }
    let objOp = {};

    if (
      message ==
      'Your account has been locked.  Please try again later, reset your password, or contact support.'
    ) {
      objOp = {
        closeButtonText: 'Ok',
      };
    }
    setGlobal(
      'modal',
      errorModalData(message, {
        ...objOp,
      }),
    );
    return null;
  };
  checkSameNumber = num => {
    if (num[0] === num[1] && num[0] === num[2]) return true;
    return false;
  };

  __validatePhoneNumber = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['phoneNumber'];

    if (fValue)
      if (fValue && !__phoneNumber(fValue)) {
        return 'Invalid Email or Phone Number';
      }
    if (fValue && !phonenumberRegex.test(formatPhoneNumberWithDash(fValue))) {
      return 'Invalid Email or Phone Number';
    }

    if (fValue && formatPhoneNumberWithDash(fValue).length > 2) {
      if (this.checkSameNumber(formatPhoneNumberWithDash(fValue).substring(0, 3))) {
        return 'Invalid Email or Phone Number';
      }
    }
    return null;
  };
  // validation methods
  __validateEmail = (value = null, callApi = false) => {
    const {values: formValues, showPassword, showOtp} = this.state;
    const fValue = value !== null ? value : formValues['email'];
    //this.setState({showPassword: false});
    if (showPassword && callApi) this.setState({showPassword: false, tempUserID: 0, valueType: ''});
    if (showOtp && callApi) this.setState({showOtp: false, tempUserID: 0, valueType: ''});
    if (!__required(fValue)) {
      return 'Email or Phone Number is required';
    }
    if (!__email(fValue)) {
      const phonenumber = this.__validatePhoneNumber(value);
      if (phonenumber) {
        return phonenumber;
      } else if (fValue) {
        //if (!showOtp) this.setState({showOtp: true});
        if (callApi) this.handleCheckValue('phonenumber', fValue);
      }
      //return 'Invalid Email';
    } else {
      if (callApi) this.handleCheckValue('email', fValue);
      // if (!showPassword && fValue) this.setState({showPassword: true});
      // if (showOtp) this.setState({showOtp: false});
    }
    return null;
  };
  __validatePassword = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['password'];
    if (!__required(fValue)) {
      return 'Password is required';
    }
    return null;
  };
  __validateOtp = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['otp'];
    if (!__required(fValue)) {
      return 'Security Code is required';
    }
    return null;
  };
  __validateForm = () => {
    const {showPassword, showOtp} = this.state;
    const validEmail = !this.__validateEmail();
    const validPassword = this.state.showPassword
      ? !this.__validatePassword()
      : !this.__validateOtp();
    const showotporpassword = showPassword || showOtp;
    return validEmail && validPassword && showotporpassword;
  };

  changeHandler = event => {
    const {name, value} = event.target;
    const {values: formValues} = this.state;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'email':
        if (formValues['email'] !== value)
          errorObject = {
            [name]: this.__validateEmail(value, true),
          };
        break;
      case 'password':
        errorObject = {
          [name]: this.__validatePassword(value),
        };
        break;
      case 'rememberMe':
        localStorage.setItem('rememberMe', value);
        break;
      case 'otp':
        errorObject = {
          [name]: this.__validateOtp(value),
        };
        break;
      default:
        break;
    }

    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  handleSocialLoginSuccess = async (provider, user, email = null, dob = null, genderId = 0) => {
    Nux.sendEvent(Analytics.Category.PatientLogin, Analytics.Actions.Click, 'socialLogin');
    const {setGlobal} = this.rootContext;
    let data = {
      type: provider,
      idToken: '',
      accessToken: '',
    };
    if (dob) data.dateOfBirth = dob;
    if (user.password) {
      data.password = user.password;
    }
    switch (provider) {
      case 'google':
        const {access_token, id_token} = user;
        data = {
          ...data,
          idToken: id_token,
          accessToken: access_token,
        };
        break;
      case 'facebook':
        const {accessToken, userID} = user;
        data = {
          ...data,
          idToken: userID,
          accessToken: accessToken,
          email: email,
        };
        break;
      case 'apple':
        const {authorization, user: AppleUser} = user;
        data = {
          ...data,
          accessToken: authorization.code,
          idToken: authorization.id_token,
          email: email ? email : AppleUser?.email,
          firstName: AppleUser?.name?.firstName,
          lastName: AppleUser?.name?.lastName,
        };
        break;
      default:
        break;
    }
    if (genderId > 0) data.genderId = genderId;
    try {
      const response = await patientSocialLogin(data);
      if (!_.isEmpty(response.data)) {
        this.afterLoginHandler(response);
      } else {
        setGlobal('modal', successModalData(response.message));
      }
    } catch (ex) {
      const {customCode, message} = ex;
      const callback = email => {
        if (user.password) delete user.password;
        this.handleSocialLoginSuccess(provider, user, email);
      };
      if (customCode === 'passwordrequired') {
        let passUser = {email: email ? email : user?.user?.email, message: message};
        let Prresponse = await Passwordrequired({
          context: this.rootContext,
          user: passUser,
          provider: provider,
        });
        let passUsertemp = {...user, password: Prresponse.password};
        this.handleSocialLoginSuccess(provider, passUsertemp, email);
        // if(Prresponse){
        //   const callbackAfterEmail = email => {
        //     this.handleSocialLoginSuccess(provider, user, email);
        //   };
        //   if (!_.isEmpty(Prresponse.data)) {
        //     this.afterLoginHandler(Prresponse);
        //     return false
        //   } else {
        //     setGlobal(
        //       'modal',
        //       blankModalData({
        //         CustomComponent: EmailInputModal,
        //         customComponentProps: {callback: callbackAfterEmail},
        //         modalWrapperClass: 'mega-modal',
        //       }),
        //     );
        //     return false
        //   }
        // }
      } else if (customCode == 'genderanddobrequiredSocialAccount') {
        let passUsertemp = {...user};
        let gdinfo = await SocialGenderDobMain({
          context: this.rootContext,
          user: passUsertemp,
          message: message,
          provider: provider,
        });

        this.handleSocialLoginSuccess(provider, passUsertemp, email, gdinfo.dob, gdinfo.genderId);
      } else if (customCode === 'socialSignUpEmailRequired') {
        setGlobal(
          'modal',
          blankModalData({
            CustomComponent: EmailInputModal,
            customComponentProps: {callback: callback, message: message},
            modalWrapperClass: 'mega-modal',
          }),
        );
      } else if (message === 'Date of birth is required') {
        let passUser = {};
        if (provider === 'apple') {
          passUser = {
            firstName: user.firstName ? user.firstName : user?.user?.name?.firstName,
            lastName: user.firstName ? user.firstName : user?.user?.name?.lastName,
            email: email ? email : user?.user?.email,
          };
        }
        let aditionalInfo = await SocialTermsofUse({
          context: this.rootContext,
          user: passUser,
          provider: provider,
        });
        if (!aditionalInfo) return false;

        if (provider === 'apple' && aditionalInfo?.user?.firstName) {
          user.user = {
            name: {firstName: aditionalInfo.user.firstName, lastName: aditionalInfo.user.lastName},
            email: aditionalInfo?.user?.email,
          };
        }
        this.handleSocialLoginSuccess(
          provider,
          user,
          email,
          aditionalInfo.dob,
          aditionalInfo.genderId,
        );
        return;
      } else {
        this.handleError(ex);
      }
    }
  };

  handleSocialLoginFailure = (provider, err) => {
    console.log(provider, err);
  };
  handleResentCode = () => {
    let {valueType, values: formValues, tempUserID} = this.state;
    const {setGlobal} = this.rootContext;
    if (valueType === 'email') {
      resendOtpEmail({login: true, email: formValues['email']})
        .then(() => {
          setGlobal('modal', successModalData('Security Code resent to your Email id!'));
        })
        .catch(this.handleError);
    } else {
      resendotpphonenumber({userID: tempUserID})
        .then(res => {
          setGlobal('modal', successModalData('Security Code resent to your Phone Number!'));
        })
        .catch(this.handleError);
    }
  };
  handleCheckValue = (type, value) => {
    const debounceSave = debounce(passvalue => {
      validateEmailorPhoneNumber({type: type, inputValue: passvalue})
        .then(res => {
          if (res.message === 'show_password') {
            this.setState({showPassword: true, showOtp: false});
          } else if (res.message === 'show_otp') {
            this.setState({
              showPassword: false,
              showOtp: true,
              tempUserID: res.data.userId,
              valueType: res.data.valueType,
            });
          }
        })
        .catch(er => {
          const {values: formValues} = this.state;
          let currentVal = formValues['email'];

          if (er.customData && er.customData.serverValue === currentVal) this.handleError(er);
          else if (!er.customData) {
            this.handleError(er);
          }
        });
    }, 1000);
    debounceSave(value);
  };

  render() {
    const {
      isProcessing,
      values: formValues,
      errors: formErrors,
      showPassword,
      showOtp,
    } = this.state;
    const getGeneralInputProps = controlName => {
      return {
        onChange: this.changeHandler,
        onBlur: this.changeHandler,
        error: formErrors[controlName],
        value: formValues[controlName],
        name: controlName,
      };
    };
    const isValidForm = this.__validateForm();
    return (
      <Layout>
        <div className="PatientLogin">
          <div className="login-container">
            <h1 className="h3">Log into kaly</h1>

            <form className="login-form" onSubmit={e => e.preventDefault()}>
              <Input
                {...getGeneralInputProps('email')}
                translationType="transform"
                label="Email or Phone Number"
              />
              {showPassword && !formErrors['email'] && (
                <Input
                  {...getGeneralInputProps('password')}
                  type="password"
                  translationType="transform"
                  icon={images.eyeView}
                  label="Password"
                  containerClass="password-group"
                />
              )}
              {showOtp && !formErrors['email'] && (
                <>
                  <Input
                    {...getGeneralInputProps('otp')}
                    type="text"
                    translationType="transform"
                    //icon={images.eyeView}
                    label="Security Code"
                    // containerClass="password-group"
                  />
                  <div className="resentdiv">
                    <span onClick={() => this.handleResentCode()}>Resent Code</span>
                  </div>
                </>
              )}
              <div className="checkbox-block">
                <Checkbox {...getGeneralInputProps('rememberMe')}>keep me logged in</Checkbox>
                <Link to="/forgot-password" className="reset-link">
                  Reset Password?
                </Link>
              </div>
              <RootContext.Consumer>
                {context => {
                  this.rootContext = context;
                  return (
                    <div className="login-btn">
                      <CircleButton
                        type="submit"
                        disabled={!isValidForm || isProcessing}
                        onClick={this.loginHandler}>
                        Log in
                      </CircleButton>
                    </div>
                  );
                }}
              </RootContext.Consumer>
              <div className="login-bottom">
                Not a member? <Link to="/patient/sign-up"> Click here to Sign up</Link>
              </div>
            </form>
            <div className="divider-content">
              <Divider></Divider>
              <span>Or</span>
            </div>
            <div className="ca-social">
              <SocialButton
                className="social-icon"
                provider="google"
                appId={config.GOOGLE_CLIENT_ID}
                scope="profile email"
                onLoginSuccess={data => this.handleSocialLoginSuccess('google', data)}
                onLoginFailure={error => this.handleSocialLoginFailure('google', error)}>
                <img src={images.Googleicon} alt="facebook icon" />
                Continue with Google
              </SocialButton>
              <SocialButton
                className="social-icon"
                provider="facebook"
                appId={config.FACEBOOK_CLIENT_ID}
                scope="email,public_profile"
                onLoginSuccess={data => this.handleSocialLoginSuccess('facebook', data)}
                onLoginFailure={error => this.handleSocialLoginFailure('facebook', error)}>
                <img src={images.facebookblue} alt="google icon" />
                Continue with Facebook
              </SocialButton>
              <SocialButton
                className="social-icon"
                provider="apple"
                appId={config.FACEBOOK_CLIENT_ID}
                scope="email,public_profile,email"
                onLoginSuccess={data => this.handleSocialLoginSuccess('apple', data)}
                onLoginFailure={error => this.handleSocialLoginFailure('apple', error)}>
                <img src={images.facebookblue} alt="google icon" />
                Continue with Apple
              </SocialButton>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PatientLogin;
