const questionList = [
  {
    id: 61,
    question: 'Do you have any of these symptoms?',
    // if optionType is not specified we will use default UI
    optionType: 1, // {1=>'single line options',2=>'select option'} // other will be default UI
    descriptions: [
      'Fever greater than 100.3°F',
      'Do you have significant swelling below the knee in the calf region or loss of blood flow.',
      'Have you had recent severe traumatic injury and is it impossible for you to weight bear on affected leg.',
    ],
    options: [
      {nextQuestionId: 66, label: 'Yes', shortLabel: 'Red flags? Yes'},
      {nextQuestionId: 62, label: 'No', shortLabel: 'Red flags? No'},
    ],
  },
  {
    id: 62,
    question: 'Are you interested in surgery?',
    descriptions: [],
    options: [
      {groupId: 'T', label: 'Yes, in the near future', shortLabel: 'Interested in surgery'},
      {nextQuestionId: 63, label: 'Not at this time', shortLabel: 'No surgery'},
    ],
  },
  {
    id: 63,
    question: 'How long have you had pain for?',
    descriptions: [],
    options: [
      {nextQuestionId: 64, label: 'Less than 2 weeks', shortLabel: '<2 weeks'},
      {nextQuestionId: 65, label: 'More than 2 weeks', shortLabel: '>2 weeks'},
    ],
  },
  {
    id: 64,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'L',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching, and exercises',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'E',
        label: 'Preliminary imaging studies (Xrays) or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'U',
        label: 'A minimally invasive procedure such as an injection.',
        shortLabel: 'Open to invasive procedures',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'E',
        label: 'I am not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
        showKalyMd: true,
      },
    ],
  },
  {
    id: 65,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'L',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'U',
        label: 'Diagnostic imaging studies (MRI/Xray) or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'U',
        label: 'A minimally invasive procedure such as an injection.',
        shortLabel: 'Open to invasive procedures',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'U',
        label: 'I am not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
      },
    ],
  },
  {
    id: 66,
    question:
      'You may have a condition that requires immediate medical attention, such as seeking care at an Emergency Room or Urgent Care Center. Kaly does not diagnose conditions and is not meant to be a replacement for a trained medical professional.',
    optionType: 1,
    descriptions: [
      'Would you still like to see doctors who may help you after you seek urgent care?',
    ],
    options: [
      {nextQuestionId: 62, label: 'Yes'},
      {groupId: 'A', label: 'No'},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 61,
    treeDepth: 4,
  },
};
