import Select from 'components/FormControls/Select';
import validators from 'data/validators';
import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import RootContext from 'context/RootContext';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import reviewService from 'services/review';
import images from 'data/images';
import "./ReviewAddComponent.css"
import { toast } from 'react-toastify';
const { __required, __isValidUrl } = validators;
const AddConnectReviews = props => {
    const rootContext = useContext(RootContext);
    const defaultFormData = {
        reviewSourceId: '',
        url: '',
    };
    const { setGlobal, reviewSourceList } = rootContext;
    const [fbConnected, setFbConnected] = useState(false);
    const [googleConnected, setGoogleConnected] = useState(false);
    const [selectedSourceFormData, setSourceFormData] = useState({ values: defaultFormData, errors: {} });
    const { values: formValues, errors: formErrors } = selectedSourceFormData;
    const { Analytics } = analyticsData;
    const {
        getAllReviewSource,
        getAllMyReviewSource,
        saveReviewSourceAdmin,
        deleteReviewSoruce,
        refetchReviews,
        getSocialConnectAdmin,
    } = reviewService;
    const handleError = error => {
        const { message } = error;
        //  setGlobal('modal', errorModalData(message));
        toast.error(message)
        return null;
    };
    useEffect(() => {
        getAndSetReviewSource();
       // getAndSetConnections();
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, []);
    useEffect(() => {
        
        if (props.sourceObj) {
            const { sourceObj } = props
            setSourceFormData({
                values: {
                    reviewSourceId: sourceObj.reviewSource.id,
                    url: sourceObj.url
                }, errors: {}
            })
         
        }
    }, [props.sourceObj])
    const getAndSetReviewSource = async () => {
        if (!reviewSourceList) {
            const response = await getAllReviewSource().catch(handleError);
            if (response) {
                const { data } = response;
                setGlobal('reviewSourceList', data);
            }
        }
    };
    const getAndSetConnections = async () => {
        const response = await getSocialConnectAdmin(props.userId).catch(handleError);
        if (response) {
            const { data } = response;
            if (data.facebook) {
                setFbConnected(true);
            }
            if (data.gmb) {
                setGoogleConnected(true);
            }
        }
    };

    const __validateSource = (value = null) => {
        const fValue = value !== null ? value : formValues['reviewSourceId'];
        if (!__required(fValue)) {
            return 'Please select a source';
        }
        return null;
    };
    const __validateWebsite = (value = null) => {
        const fValue = value !== null ? value : formValues['url'];

        if (!__required(fValue)) {
            return 'Please enter a URL';
        }
        if (fValue && !__isValidUrl(fValue)) {
            return 'Invalid URL';
        }
        return null;
    };

    const __validateForm = () => {
        const validSource = !__validateSource();
        console.log("validSource", validSource);
        return validSource;
    };
    const cancelAddEdit = () => {
        setSourceFormData(null);
    };



    const validSocialConnection = value => {
        let status = true;
        const filterList = _.filter(reviewSourceList, data => {
            return data.id === value;
        });
        if (filterList.length > 0) {
            let errorMsg = '';
            if (filterList[0].title === 'Facebook' && !fbConnected) {
                status = false;
                errorMsg =
                    'Facebook reviews are only fetched after you connect your facebook account. Proceed to Connect Social section for it.';
            }
            if (filterList[0].title === 'Google' && !googleConnected) {
                status = false;
                errorMsg =
                    'Google reviews can only be replied to after you connect to Google account. Proceed to Connect Social section for it.';
            }
            if (!status) {
                handleError({
                    message: errorMsg,
                });
            }
        }
        return status;
    };

    const changeHandler = event => {
        const { name, value } = event.target;
        let errorObject = {
            [name]: null,
        };
        switch (name) {
            case 'reviewSourceId':
                errorObject = {
                    [name]: __validateSource(value),
                };
                break;
            case 'url':
                errorObject = {
                    [name]: __validateWebsite(value),
                };
                break;
            default:
                break;
        }
        if (validSocialConnection(value)) {
            setSourceFormData({
                ...selectedSourceFormData,
                values: {
                    ...selectedSourceFormData.values,
                    [name]: value,
                },
                errors: {
                    ...selectedSourceFormData.errors,
                    ...errorObject,
                },
            });
        }
    };
    const validSourceURL = () => {
        let isValid = true;
        const reviewSourceId = _.get(selectedSourceFormData, 'values.reviewSourceId', '');
        const url = _.get(selectedSourceFormData, 'values.url', '');
        const filterList = _.filter(reviewSourceList, data => {
            return data.id === reviewSourceId;
        });
        if (filterList.length > 0) {
            isValid = url.toLowerCase().includes(`${filterList[0].title.toLowerCase()}.com`);
            if (!isValid) {
                handleError({
                    message: `Please enter valid ${filterList[0].title} URL.`,
                });
            }
        }
        return isValid;
    };

    const handleSaveSource = async () => {
        //  alert(55)
        Nux.sendEvent(Analytics.Category.DoctorReview, Analytics.Actions.Click, 'reviewLinkAction');
        let { values } = selectedSourceFormData;
        values.userId = props.userId
      
        if(props.sourceObj?.id){
            values.userReviewSourceId=props.sourceObj.id
        }
      
        
        if (validSourceURL()) {
            const response = await saveReviewSourceAdmin(values).catch(handleError);
            if (response) {
                let { message } = response
                // setGlobal(
                //   'modal',
                //   successModalData(
                //     'Once the website link is added, it will be sent to approval from administration. Once approved, it will take maximum 24 hours to fetch the reviews.',
                //   ),
                // );
                toast.success(message)
                setSourceFormData({ values: defaultFormData, errors: {} });
                closeModal()
                props.onRefresh()
            }
        }
    };
    const getGeneralInputProps = controlName => {
        return {
            onChange: changeHandler,
            onBlur: changeHandler,
            error: formErrors[controlName],
            value: formValues[controlName],
            name: controlName,
        };
    };
    const closeModal = () => {

        setGlobal('modal', { showModal: false });
    };

    const isValidForm = __validateForm();
    let exampleURL = '';
    let reviewSourceId = _.get(selectedSourceFormData, 'values.reviewSourceId', '');
    if (_.get(selectedSourceFormData, 'values.reviewSourceId', '') !== '') {
        const filterList = _.filter(reviewSourceList, data => {
            return data.id === reviewSourceId;
        });
        if (filterList.length > 0) {
            exampleURL = `Example URL: ${filterList[0].exampleUrl}`;
        }
    }
    let filterReviewSourceList = _.filter(reviewSourceList, data => {
        return data.isBirdEyeSource;
    });
    //if (!fbConnected) {
        filterReviewSourceList = filterReviewSourceList.filter((d) => d.slug !== 'facebook')
    //}
    if (!googleConnected) {
        filterReviewSourceList = filterReviewSourceList.filter((d) => d.slug !== 'google')
    }
    //console.log("reviewSourceList",reviewSourceList);
    return (
        <div className="ReviewAddComponent add-website-form CreatePasswordModal termsofuse viewPhotoIdmodal">
            <div style={{paddingLeft:"20px"}} className="modal-content-header">
                <div className="h3">{!props.sourceObj?.id?`Add new`:'Edit'} review source</div>
                <span
                    className="close"
                    onClick={() => {
                        closeModal();
                    }}>
                    <img src={images.close} alt="close" />
                </span>
            </div>
            <div className='body'>
                {/* <p style={{ fontSize: "17px" }}>Before adding the Google or Facebook review url, please go to the 'Connect Social' tab above and connect your Google or Facebook Account.  Only one provider can be connected to each Google or Facebook page.</p> */}
                <Select
                    {...getGeneralInputProps('reviewSourceId')}
                    labelIndex="title"
                    valueIndex="id"
                    options={filterReviewSourceList}
                    label="Select Source"
                />
                <Input
                    {...getGeneralInputProps('url')}
                    placeholder=" Your page URL on selected source"
                    label="Enter URL"
                />
                <div className="add-website-example">{exampleURL}</div>
                <div className="add-site-actions review-addactionbtn">
                    <CircleButton
                        btnType="secondaryButton"
                        onClick={closeModal}
                        //containerClass="cancel-btn"
                        >
                        Cancel
                    </CircleButton>
                    <CircleButton
                        disabled={!isValidForm}
                        onClick={handleSaveSource}>
                        Save
                    </CircleButton>
                </div>
            </div>
        </div>)
}
export default AddConnectReviews