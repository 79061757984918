import _ from 'lodash';

const groupList = [
  {
    id: 'A',
    title: 'Group A',
    specialityIds: [],
    descriptions: ['Urgent Care', 'Emergency Room'],
  },
  {
    id: 'A1',
    title: 'Group A',
    specialityIds: [],
    descriptions: ['Urgent Care', 'Emergency Room'],
  },
  {
    id: 'B',
    title: 'Group B',
    // Orthopedic Hand Surgeon,Orthopedic Shoulder Surgeon,Orthopedic Sports Medicine Surgeon
    specialityIds: [4, 3, 5],
    descriptions: [],
  },
  {
    id: 'C',
    title: 'Group C',
    // Physical Therapist,Occupational Therapist
    specialityIds: [15, 73],
    descriptions: [],
  },
  {
    id: 'D',
    title: 'Group D',
    // Acupuncturist
    specialityIds: [17],
    descriptions: [],
  },
  {
    id: 'E',
    title: 'Group E',
    // Primary Care Physician,Primary Care Physician->internal medicine
    specialityIds: [18, 38],
    descriptions: [],
  },
  {
    id: 'F',
    title: 'Group F',
    //PMR(Physiatrist)
    specialityIds: [12],
    descriptions: [],
  },
  {
    id: 'G',
    title: 'Group G',
    // Orthopedic Hand Surgeon,Orthopedic Shoulder Surgeon,Orthopedic Sports Medicine Surgeon,PMR(Physiatrist)
    specialityIds: [4, 3, 5, 12],
    descriptions: [],
  },
  {
    id: 'H',
    title: 'Group H',
    // Pain Management,Orthopedic Hand Surgeon,Orthopedic Shoulder Surgeon,Orthopedic Sports Medicine Surgeon,PMR(Physiatrist)
    specialityIds: [11, 4, 3, 5, 12],
    descriptions: [],
  },
  {
    id: 'I',
    title: 'Group I',
    // Orthopedic Hand Surgeon
    specialityIds: [4],
    descriptions: [],
  },
  {
    id: 'J',
    title: 'Group J',
    // Orthopedic Hand Surgeon,PMR(Physiatrist)
    specialityIds: [4, 12],
    descriptions: [],
  },
  {
    id: 'K',
    title: 'Group K',
    // Orthopedic Hip Surgeon,Orthopedic Sports Medicine Surgeon, Orthopedic Surgeon
    specialityIds: [6, 5, 10],
    descriptions: [],
  },
  {
    id: 'L',
    title: 'Group L',
    // Physical Therapist
    specialityIds: [15],
    descriptions: [],
  },
  {
    id: 'M',
    title: 'Group M',
    //  Orthopedic Hip Surgeon,Pain Management,Sports Med (Both),PMR(Physiatrist)
    specialityIds: [6, 11, 5, 12],
    descriptions: [],
  },
  {
    id: 'N',
    title: 'Group N',
    //  Physical Therapist,Chiropractor
    specialityIds: [15, 16],
    descriptions: [],
  },
  {
    id: 'O',
    title: 'Group O',
    // Spine Surgeon,Neurosurgeon
    specialityIds: [1, 2],
    descriptions: [],
  },
  {
    id: 'P',
    title: 'Group P',
    // Pain Management
    specialityIds: [11],
    descriptions: [],
  },
  {
    id: 'Q',
    title: 'Group Q',
    // Neurologist,Pain Management,Sports Med,PMR(Physiatrist)
    specialityIds: [13, 11, 5, 12],
    descriptions: [],
  },
  {
    id: 'R',
    title: 'Group R',
    // Podiatrist,Orthopedic Foot Surgeon
    specialityIds: [9, 8],
    descriptions: [],
  },
  {
    id: 'S',
    title: 'Group S',
    // Podiatrist,Orthopedic Foot Surgeon,PMR(Physiatrist)
    specialityIds: [9, 8, 12],
    descriptions: [],
  },
  {
    id: 'T',
    title: 'Group T',
    // Orthopedic Knee Surgeon,Orthopedic Sports Medicine Surgeon
    specialityIds: [7, 5],
    descriptions: [],
  },
  {
    id: 'U',
    title: 'Group U',
    // Pain Management,Sports Med (Both),PMR(Physiatrist)
    specialityIds: [11, 5, 12],
    descriptions: [],
  },
  {
    id: 'V',
    title: 'Group V',
    // Shoulder Surgeon,Ortho Sports Medicine,Hand Surgeon
    specialityIds: [3, 5, 4],
    descriptions: [],
  },
  {
    id: 'W',
    title: 'Group W',
    // Primary Care Physician,Neurologist,internal medicine, family medicine
    specialityIds: [18, 13, 38, 29],
    descriptions: [],
  },
  {
    id: 'X',
    title: 'Group X',
    // Neurologist, Pain Management,PMR(Physiatrist)
    specialityIds: [13, 11, 12],
    descriptions: [],
  },
  {
    id: 'Y',
    title: 'Group Y',
    // Interventional Sports Medicine Physician
    specialityIds: [14],
    descriptions: [],
  },
  {
    id: 'A1',
    title: 'Group A1',
    //Endodontist
    specialityIds: [28],
    descriptions: [],
  },
  {
    id: 'A2',
    title: 'Group A2',
    // Oral Surgeon
    specialityIds: [44],
    descriptions: [],
  },
  {
    id: 'A3',
    title: 'Group A3',
    // Orthodontist
    specialityIds: [45],
    descriptions: [],
  },
  {
    id: 'A4',
    title: 'Group A4',
    // Periodontist
    specialityIds: [49],
    descriptions: [],
  },
  {
    id: 'A5',
    title: 'Group A5',
    // Prosthodontist
    specialityIds: [51],
    descriptions: [],
  },
  {
    id: 'A6',
    title: 'Group A6',
    // dentist
    specialityIds: [22],
    descriptions: [],
  },
  {
    id: 'A7',
    title: 'Group A7',
    // pediatricDentist
    specialityIds: [46],
    descriptions: [],
  },
  {
    id: 'A6A7',
    title: 'Group A6A7',
    // dentist,pediatricDentist
    specialityIds: [22, 46],
    descriptions: [],
  },
  {
    id: 'A8',
    title: 'Group A8',
    specialityIds: [23], // dermatologist
    descriptions: [],
  },
  {
    id: 'A9',
    title: 'Group A9',
    specialityIds: [16], // Chiropractor
    descriptions: [],
  },
  {
    id: 'A10',
    title: 'Group A10',
    specialityIds: [38, 29], // internal medicine, family medicine
    descriptions: [],
  },
  {
    id: 'A11',
    title: 'Group A11',
    // Neurologist
    specialityIds: [13],
    descriptions: [],
  },
  {
    id: 'A12',
    title: 'Group A12',
    // Podiatrist
    specialityIds: [9],
    descriptions: [],
  },
  {
    id: 'A13',
    title: 'Group A13',
    // PMR, Pain Management, Neurology,
    specialityIds: [12, 11, 13],
    descriptions: [],
  },
  {
    id: 'A14',
    title: 'Group A14',
    // PMR,  pain management ,Certified Headache Specialist,
    specialityIds: [12, 11, 65],
    descriptions: [],
  },
  {
    id: 'A15',
    title: 'Group A15',
    //  PMR, Neurology, Pain Management, Certified Headache Specialist
    specialityIds: [12, 13, 11, 65],
    descriptions: [],
  },
  {
    id: 'A16',
    title: 'Group A16',
    //  PLASTIC SURGEON
    specialityIds: [66],
    descriptions: [],
  },
  {
    id: 'A17',
    title: 'Group A17',
    //  PLASTIC SURGEON,dermatologist,Family Physician
    specialityIds: [66, 23, 29],
    descriptions: [],
  },
  {
    id: 'A18',
    title: 'Group A18',
    // Primary Care Physician->internal medicine, OBGYN
    specialityIds: [38, 67],
    descriptions: [],
  },
  {
    id: 'A19',
    title: 'Group A19',
    //  OBGYN
    specialityIds: [67],
    descriptions: [],
  },
  {
    id: 'A20',
    title: 'Group A20',
    //  Reproductive Endocrinology and Infertility (REI)
    specialityIds: [69],
    descriptions: [],
  },
  {
    id: 'A21',
    title: 'Group A21',
    // Maternal Fetal Medicine Specialist
    specialityIds: [71],
    descriptions: [],
  },
  {
    id: 'A22',
    title: 'Group A22',
    // Gynecologic Oncologist
    specialityIds: [68],
    descriptions: [],
  },
  {
    id: 'A23',
    title: 'Group A23',
    // Urogynecologist
    specialityIds: [70],
    descriptions: [],
  },
  {
    id: 'A24',
    title: 'Group A24',
    // Occupational Therapist
    specialityIds: [73],
    descriptions: [],
  },
  {
    id: 'GOTO_LIST',
    title: 'Group GOTO_LIST',
    specialityIds: [],
    descriptions: [],
  },
  {
    id: 'GOTO_LIST_WITHOUT_SP',
    title: 'Group GOTO_LIST_WITHOUT_SP',
    specialityIds: [],
    descriptions: [],
  },
  {
    id: 'DONT_KNOW',
    title: 'Group DONT_KNOW',
    descriptions: ["Don't know"],
  },
];

const getGroupById = groupId => {
  return _.find(groupList, {id: groupId});
};

export default {
  data: {groupList},
  groupFunctions: {
    getGroupById,
  },
};
