import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import images from 'data/images';
import feedbackService from 'services/providerFeedback';
import EachFeedback from 'components/AfterAuth/ProviderFeedback/EachFeedback';
import "./singleFeedback.css"
const {
  getfeedbacksingle
} = feedbackService;

const SingleFeedbackModal = props => {
  const { closeModal } = props;
  const [selectedItem, setSelectedItem] = useState(null)
  useEffect(() => {
    if (props.data.selectedFeedbackid) {
      fetrchdata(props.data.selectedFeedbackid)
    }
  }, [props.data.selectedFeedbackid])
  const fetrchdata = async (id) => {
    let item = await getfeedbacksingle(id)
    setSelectedItem(item.data)
  }
  return (
    <div className="RescheduleAppointmentModal singleFeedback">
      <div className="modal-content-header">
        <div className="h3">Leave a feedback</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        {selectedItem ? (
          <EachFeedback
            key={props.data.selectedFeedbackid}
            statusclass={'completed'}
            itemDetails={selectedItem}
            propsFeedbackStatusId={selectedItem.appointmentFeedbackStatusId}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SingleFeedbackModal;
