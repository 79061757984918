import React, { useEffect, useRef, useState } from 'react';
import modalConfig from 'modals/modalConfig';
import images from 'data/images';
import './kalymdpopup.css';
import LoaderOnly from 'components/Loader/LoaderOnly';
import Checkbox from 'components/FormControls/Checkbox';

export function Kalymdpopup(props) {
  const { setGlobal } = props.context;
  const { type, callToActionData } = props
  const { modalFunctions } = modalConfig;
  const { blankModalData } = modalFunctions;
  const KalymdpopupComponent = props => {
    const { closeModal, callToActionData } = props;
    const [showLoader, setShowLoader] = useState(true)
    const [accept, setAccept] = useState(props.checkboxKey)
    const checkvalidHtml = (string) => {
      return /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(string)
    }
    const isValidUrl = (urlString) => {
      var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
      return !!urlPattern.test(urlString);
    }
    let dummyHtml = `<h2 className='heading2'>Introducing KalyMD, your team of on-demand virtual physicians!</h2>
   <p>
     Our distinguished team of Board Certified doctors can connect with you seamlessly via telemedicine, offering a convenient approach to finding the precise care you need.
   </p>
   <p>Kaly MD's doctors will help guide you to the correct care. KalyMD doctors can provide medical advice, prescribe essential medications (excludes controlled substances), and order imaging studies such as x-rays that you can get at your local imaging facility of choice. </p>
   <p>For a limited time, KalyMD is free to use (a $39 value)! If you would like a KalyMD physician to contact you within 2 hours (9 am-9 pm EST daily) regarding your health condition, please click below!</p>
   <p>Patient info needed: home address, cell phone number, preferred pharmacy name and phone number</p>`;
    let dummyUrl = 'https://images.iskysoft.com/pdfelement/pdfelement-mac/guide/fill-form.jpg'
    let data = props.type == 'HIPAA Policy' ? callToActionData.hipaaForm : callToActionData.patientAgreementForm
    let checkStringType = isValidUrl(data) ? 'url' : checkvalidHtml(data) ? 'html' : 'string';
    let frame=useRef(null)
    const iframeOnLoad = (event) => {
      setShowLoader(false);
      console.log(event.target);
      //event.scrollTo(0,0)
      //contentWindow
      try {
        frame.contentWindow.scrollTo(0,0);
      } catch (error) {
        
      }
     
    
     // document.querySelector('#kalymdpopiframe').contentWindow.scrollTo(0,0);
    }
    
    // useEffect(()=>{
    // let ifram=  document.querySelector('#kalymdpopiframe')
    // ifram.onLoad=()=>{
    //   setShowLoader(false);
    // }
    // },[])
    return (
      <div className="CreatePasswordModal kalymdpopup">
        <div className="modal-content-header">
          <div className="h3">{type}</div>
          <span
            className="close"
            onClick={() => {
              props.resolve(true);
              closeModal();
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div className="modal-content-body">
          {checkStringType == 'html' ? (
            <div dangerouslySetInnerHTML={{ __html: data }}></div>
          ) : checkStringType == 'url' ? (
            <>
              {showLoader && (
                <LoaderOnly />
              )}
              <iframe ref={(c)=>{frame = c}} id="kalymdpopiframe" onLoad={iframeOnLoad} src={`${data}#toolbar=0&zoom=100`} height="100%" width="100%"></iframe>
            </>
          ) : (
            <div>{data}</div>
          )}
    

        </div>

      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: KalymdpopupComponent,
        modalWrapperClass: 'mega-modal-kalymd',
        customComponentProps: {
          resolve: resolve,
          reject: reject,
          type: type,
          callToActionData: callToActionData,
          
          //onProviderClick: onProviderClick,

        },
        closeOnBGClick: false,
      }),
    );
  });
}
