import React, { useState, useContext, useEffect } from 'react';
import RootContext from 'context/RootContext';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import config from 'config/config';
import validators from 'data/validators';
import InputMask from 'react-input-mask';
import allRegex from 'data/regex';
import helper from 'data/helper';
import reviewService from 'services/review';
import modalConfig from 'modals/modalConfig';
import './SendReviewRequest.css';
import analyticsData from 'data/analytics/analyticsData';
import { toast } from 'react-toastify';
import SearchPatients from 'components/FormControls/SearchPatients';
import Checkbox from 'components/FormControls/Checkbox';

const { exceptDigitsRegex, phonenumberRegex } = allRegex;
const { MAX_TEXT_LENGTH } = config;
const { __required, __email, __maxLength, __phoneNumber } = validators;
const { formatPhoneNumberWithDash, formatPhoneNumberWithOutDash } = helper;
const { modalFunctions } = modalConfig;
const { errorModalData, successModalData } = modalFunctions;
const { Analytics } = analyticsData;

const AddEditContact = props => {
  const rootContext = useContext(RootContext);
  const { setGlobal } = rootContext;
  const [formData, setFormData] = useState({
    values: {
      sendEmail: false,
      sendSms: false
    },
    errors: { serverError: null },
  });
  const { type, getContactList, dataObj } = props
  const { values: formValues, errors: formErrors } = formData;
  useEffect(() => {
    if (dataObj && Object.keys(dataObj).length > 0) {
     
      setFormData({
        ...formData,
        values: {
          firstName: dataObj.firstName,
          lastName: dataObj.lastName,
          email: dataObj.email,
          phoneNumber: dataObj.phone,
          id: dataObj.id,
          sendEmail: dataObj.sendEmail,
          sendSms: dataObj.sendSms,
          patientId: dataObj.patientId
        },
        errors: {
          firstName: null,
          lastName: null,
          email: null,
          phoneNumber: null

        }
      })
      // setTimeout(() => {
      //   changeHandler({ target: { name: 'phoneNumber', value: dataObj.phone } })
      // }, 100);
    }

  }, [dataObj])
  useEffect(()=>{
    if(formValues['phoneNumber'])
    {
      //setTimeout(() => {
        changeHandler({
          target:{
            name:'phoneNumber',
            value:formValues['phoneNumber']
          }
        })
      //}, 1000);
     
    }
    
  },[formValues['phoneNumber']])
  const closeModal = () => {
    setGlobal('modal', { showModal: false });
  };
  const checkSameNumber = (num) => {

    if ((num[0] === num[1]) && (num[0] === num[2]))
      return true
    return false
  }
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First Name is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last Name is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  const __validatePhoneNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['phoneNumber'];
    if (fValue && !__phoneNumber(fValue)) {
      return 'Invalid Phone number';
    }
    if (fValue && !phonenumberRegex.test(formatPhoneNumberWithDash(fValue))) {
      return 'Invalid Phone number';
    }

    if (fValue && formatPhoneNumberWithDash(fValue).length > 2) {
      if (checkSameNumber(formatPhoneNumberWithDash(fValue).substring(0, 3))) {
        return 'Invalid Phone number';
      }
    }
    return null;
  };

  const changeHandler = event => {
    let { name, value } = event.target;
    value = value ? value : (name == 'sendSms' || name == 'sendEmail') ? false : ''
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      case 'phoneNumber':
        value = value.replace(exceptDigitsRegex, '');
        errorObject = {
          [name]: __validatePhoneNumber(value),
        };
        break;
      default:
        break;
    }

    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,

        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const __validateForm = () => {
    const validEmail = !__validateEmail();
    const validName = !__validateFirstName();
    const lastName = !__validateLastName();
    const validPhoneNumber = !__validatePhoneNumber();
    return validEmail && validName && validPhoneNumber && lastName;
  };
  const isValidateForm = __validateForm();

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="" />
    </span>
  );

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">{type} Contact</div>
      {crossIconComponent}
    </div>
  );

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  const handleError = error => {
    const { message } = error;
    toast.error(message)
    //setGlobal('modal', errorModalData(message));
    return null;
  };

  const sendRequest = async () => {

    const { addContacts, editContacts, } = reviewService;
    let tempFormValues = { ...formValues }

    if (tempFormValues.phoneNumber) {
      tempFormValues.phoneNumber = formatPhoneNumberWithOutDash(tempFormValues.phoneNumber);
    }
    try {
      let response = null
      if (type === 'Add') {
        response = await addContacts(tempFormValues);
      }
      else {
        response = await editContacts(tempFormValues);
      }
      if (response && response.data) {
        setGlobal('modal', successModalData(response.message));
        getContactList()
      }

    } catch (ex) {
      handleError(ex);
    }
  };
  const setPatientValue = (data) => {

    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.user.phoneNumber,
          patientId: data.id,
          sendSms: false,
          sendEmail: false
        },

      };
    });
  }

  return (
    <div className="SendReviewRequest no-select">
      {dialogHeader}
      <div className="dailog-body">
        <form>
          {/* <Input {...getGeneralInputProps('name')} placeholder="Enter Name" label="Name" /> */}
          <SearchPatients onSelect={setPatientValue} />
          <div className="form-row">
            <Input
              {...getGeneralInputProps('firstName')}
              containerClass="two-input"
              type="text"
              label="First name"
              placeholder="First Name"
            />
            <Input
              {...getGeneralInputProps('lastName')}
              containerClass="two-input"
              type="text"
              label="Last name"
              placeholder="Last Name"
            />
          </div>
          <Input
            {...getGeneralInputProps('email')}
            placeholder="Enter Email Address"
            label="Email"
          />
          <Input
            mask="(999) 999-9999"
            maskChar=" "
            type="tel"
            InputComponent={InputMask}
            {...getGeneralInputProps('phoneNumber')}
            placeholder="Enter Phone Number"
            label="Phone Number  eg. (480) 123 4567"
          />
          <div className="form-row">
            <Checkbox {...getGeneralInputProps('sendEmail')}>
              Send Email

            </Checkbox>
            <Checkbox disabled={formErrors['phoneNumber'] ? true : false} {...getGeneralInputProps('sendSms')}>
              Send SMS

            </Checkbox>
            <Checkbox value={(formValues['sendEmail'] && formValues['sendSms']) ? true : false} onChange={(e) => {
              const { value } = e.target
              setFormData(prevState => {
                return {
                  ...prevState,
                  values: {
                    ...prevState.values,

                    sendSms: value,
                    sendEmail: value
                  },

                };
              });
            }} >
              Both
            </Checkbox>
          </div>
          <div className="action-btn">
            <CircleButton onClick={closeModal} btnType="secondaryButton">
              Cancel
            </CircleButton>
            <CircleButton btnType="whiteButton" disabled={!isValidateForm} onClick={sendRequest}>
              {type === 'Add' ? 'Save' : 'Update'}
            </CircleButton>

          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditContact;
