import React from 'react';
import authDashboardContent from 'data/authDashboardContent';
import MySlider from 'components/MySlider';
import './BuiltDoctors.css';

const BuiltDoctors = props => {
  const {builtDoctorsList} = authDashboardContent;
  const settings = {
    infinite: true,
    autoplay: true,
    initialSlide: 1,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    rows: 1,
    speed: 500,
    swipeToSlide: true,
    // centerMode: false,
    variableWidth: true,
    // centerPadding: '5%',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0%',
        },
      },
      
    ],
  };
  const EachBuildDocuor=(props)=>{
    let {builtDoctor}=props;
    return (<div className={builtDoctor.classContent} >
      {/* <div className="dc-name">
        <span>Review For</span>
        <span className="name">{builtDoctor.dname}</span>
      </div> */}
      <div className="dc-description">{builtDoctor.content}</div>
      <div className="dc-date">
        {/* <span>{builtDoctor.pnamer}</span> */}
        {/* <span>{builtDoctor.date}</span> */}
      </div>
      <img src={builtDoctor.img} alt="list" />
    </div>)
  }
  const builtDoctorListComponent = builtDoctorsList.map((builtDoctor, i) => (
    // <div className={builtDoctor.classContent} key={i}>
    //   <div className="dc-name">
    //     <span>Review For</span>
    //     <span className="name">{builtDoctor.dname}</span>
    //   </div>
    //   <div className="dc-description">{builtDoctor.content}</div>
    //   <div className="dc-date">
    //     <span>{builtDoctor.pnamer}</span>
    //     <span>{builtDoctor.date}</span>
    //   </div>
    //   <img src={builtDoctor.img} alt="list" />
    // </div>
    <EachBuildDocuor builtDoctor={builtDoctor} key={i}  />
  ));

  return (
    <div className="BuiltDoctors">
      <div className="container">
        <div className="title">Happier, healthier patients</div>
        <div className="BuiltDoctors-testimonial">
        <MySlider className="team-slider" {...settings}>
        {builtDoctorListComponent}
      </MySlider>
          </div>
      </div>
    </div>
  );
};

export default BuiltDoctors;
