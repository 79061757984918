const defaultPracticeLocation = {
  id: 0,
  address: '',
  appointmentEmails: [''],
  contactPersonName: '',
  locationName: '',
  officePhone: '',
  officePhoneExt: '',
  officeSuite: '',
  status: 1,
  latitude: 40.7128,
  longitude: 74.006,
  hasEmailNotification:true,
};

export default {
  defaultPracticeLocation,
};
