import React, { useContext } from 'react';
import _ from 'lodash';
import ReviewData from '../ReviewData';
import './ReviewTab.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReviewConnect from '../ReviewConnect';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import AddConnectReviews from "components/AfterAuth/ConnectReviews/ReviewAddComponent"
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import reviewService from 'services/review';
import { DeleteConform } from 'containers/Admin/Practices/EditPracticeRegistration/DeleteConform';
const { modalFunctions } = modalConfig;
const { errorModalData, successModalData, blankModalData } = modalFunctions;
const ReviewTab = props => {
  const {
    reviewList = [],
    tabs = [],
    onReply,
    onDelete,
    onToggleFeatureType,
    onRejectRequest,
    handleSortChange,
    handlePageChange,
    onReviewDelete,
    hasMorePage,
    sortType,
    onActiveTabChange,
    activeTabIndex,
    sourceList,
    onRefresh,
    isGhost,
    getProviderReviews
  } = props;
  const {
    deleteReviewSourceAdmin,
    postSocialConnectEmail
  } = reviewService;
  let reviewListComponent = null;
  const rootContext = useContext(RootContext);
  let { setGlobal } = rootContext;
  const activeTabLabel = _.get(tabs, `${activeTabIndex}.label`);
  const { search } = useLocation();
  const addReviewSource = (userId = null) => {
    const searchParams = new URLSearchParams(search);
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: AddConnectReviews,
        customComponentProps: { userId: searchParams.get('user'),onRefresh:()=>{onRefresh();getProviderReviews()}},
        
      }),
    );
  };
  const sendsocialConnectLink=async()=>{
    const searchParams = new URLSearchParams(search);
    let response = await postSocialConnectEmail(searchParams.get('user')).catch(handleError);
    if (response) {
      let { setGlobal } = rootContext;
      let {message}=response
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            onRefresh()
          },
        }),
      );
    }
  }
  const handleError = error => {
    const { message } = error;
    //  setGlobal('modal', errorModalData(message));
    toast.error(message)
    return null;
  };
  const EditReviewSource = (sourceObj = {}) => {

    const searchParams = new URLSearchParams(search);
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: AddConnectReviews,
        customComponentProps: { userId: searchParams.get('user'), sourceObj: sourceObj,onRefresh:()=>{onRefresh();getProviderReviews()} },
      }),
    );
  };

  const deleteReviewSource = async id => {
    let aditionalInfo = await DeleteConform({
      context: rootContext,
      text: `Are you sure, you want to delete this source?`,
    });
    if (!aditionalInfo) return false;
    const searchParams = new URLSearchParams(search);
    let response = await deleteReviewSourceAdmin(id, searchParams.get('user')).catch(handleError);
    if (response) {
      let { setGlobal } = rootContext;
      let {message}=response
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            onRefresh()
            getProviderReviews()
          },
        }),
      );
    }
  };
  const getReviewTotal = reviewObj => {
    if(reviewObj.reviewSource.title=='Kaly')
    {
      let total=reviewObj.doctorApproachable +
      reviewObj.doctorBedsideManner +
      reviewObj.doctorKnowledge +
      reviewObj.officeAtmosphere +
      reviewObj.officeCleanliness +
      reviewObj.officeWaitTimes +
      reviewObj.staffProfessional +
      reviewObj.staffResponseTime +
      reviewObj.staffScheduling;
      return total/9
    }
   
    else
    return reviewObj.overallRating
  };

  if (activeTabLabel === 'Connected Accounts') {
    reviewListComponent = <p className="no-desingn">No design available</p>;
  } else {
    let filteredReviewList = _.filter(reviewList, review => {
      let isValidReviewForTab = false;
      switch (activeTabIndex) {
        case 0:
          isValidReviewForTab = true;
          break;
        case 1:
          isValidReviewForTab = review.isRecentReview;
          break;
        default:
          break;
      }

      return isValidReviewForTab;
    });
    // if (sortType === 'recent') filteredReviewList = _.orderBy(filteredReviewList, ['createdAt'], ['desc']);
    // else if (sortType === 'highestRated')
    //   filteredReviewList = _.orderBy(
    //     filteredReviewList,
    //     [
    //       item => {
    //         return getReviewTotal(item);
    //       },
    //       'createdAt',
    //     ],
    //     ['desc', 'desc'],
    //   );
    // else if (sortType === 'lowestRated')
    //   filteredReviewList = _.orderBy(
    //     filteredReviewList,
    //     [
    //       item => {
    //         return getReviewTotal(item);
    //       },
    //       'createdAt',
    //     ],
    //     ['asc', 'desc'],
    //   );
    reviewListComponent =
      activeTabIndex === 0 ? (
        <div className="review-list">
          <div className="review-sort-wrapper">
            <span
              className={sortType === 'recent' ? 'active' : ''}
              onClick={() => handleSortChange('recent')}>
              Newest
            </span>
            <span
              className={sortType === 'highestRated' ? 'active' : ''}
              onClick={() => handleSortChange('highestRated')}>
              Highest Rated
            </span>
            <span
              className={sortType === 'lowestRated' ? 'active' : ''}
              onClick={() => handleSortChange('lowestRated')}>
              Lowest Rated
            </span>
          </div>

          <InfiniteScroll
            dataLength={filteredReviewList.length}
            next={handlePageChange}
            hasMore={hasMorePage}
            loader={<h4>Loading...</h4>}
            endMessage={filteredReviewList.length > 0 ? '' : 'No Reviews Found.'}>
            {filteredReviewList.map((review,index) => {
              const reviewDetailProps = {
                review,
                key: `${review.id}-${index}`,
                onReply,
                onDelete,
                onToggleFeatureType,
                onReviewDelete,
                onRejectRequest,
                isGhost,
              };
              return <ReviewData {...reviewDetailProps}></ReviewData>;
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <>
          <div className="refesh">
            <button className="btn btn-refresh mr-1" onClick={() => sendsocialConnectLink()}>
              Send Social connect link
            </button>
            <button className="btn btn-refresh mr-1" onClick={() => addReviewSource()}>
              Add Website
            </button>
            <button className="btn btn-refresh" onClick={() => onRefresh()}>
              Refresh
            </button>

          </div>
          <ReviewConnect getProviderReviews={getProviderReviews} ondeleteReviewSource={deleteReviewSource} onEditReviewSource={EditReviewSource} sourceList={sourceList} onRefresh={onRefresh}></ReviewConnect>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      );
  }

  const tabHeaderList = (
    <ol className="tab-list">
      {tabs.map((tab, index) => {
        return (
          <li
            key={index}
            className={`tab-list-item ${index === activeTabIndex ? 'tab-list-active' : ''}`}
            onClick={() => onActiveTabChange(index)}>
            {tab.label}
            <span className="review-count">{tab.count}</span>
          </li>
        );
      })}
    </ol>
  );

  return (
    <div className="ReviewTab">

      {tabHeaderList}
      <div className="tab-content">{reviewListComponent}</div>
    </div>
  );
};

export default ReviewTab;
