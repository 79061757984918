import React, {Component, useCallback, useContext, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import images from 'data/images';
import patientService from 'services/patient';
import './writeReview.css';
import PatientHeader from 'layout/PatientLayout/PatientHeader';
import ReviewVisitModal from 'components/Patient/PatientDashboard/ReviewVisitModal';
import {PatientFooterNew} from 'layout/PatientLayout/PatientFooter';
import LoadingPage from 'containers/Patient/LoadingPage';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import AuthContext from 'context/AuthContext';
const {getAppointmentDetailsByIdPublic, reviewAppointment} = patientService;
const {errorModalData, successModalData, warningModalData} = modalConfig.modalFunctions;
const WriteReview = props => {
  const rootContext = useContext(RootContext);
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const appointmentId = props.match.params.appointmentId;
  const {setGlobal} = rootContext;
  let history = useHistory();
  const [appointment, setAppointment] = useState({});
  const [processingSave, setProcessingSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const {location} = history;
  const {state = {}} = location;
  const {callSave} = state;
  //   history &&
  //   history.location &&
  //   history.location.state &&
  //   history.location.state.callSave
  // componentDidMount = () => {
  //   const {history} = this.props;

  // };
  const handleError = (error, redirect = false) => {
    const {message} = error;
    setGlobal(
      'modal',
      errorModalData(message, {
        callBackOnClose: () => {
          if (auth || redirect) {
            history.push({pathname: '/'});
          }
        },
      }),
    );
    return null;
  };
  const getAppointmentDetailsById = useCallback(async () => {
    const response = await getAppointmentDetailsByIdPublic(appointmentId).catch(error => {
      handleError(error, true);
    });
    if (response) {
      const {data: appointment} = response;
      setAppointment(appointment);
      setLoading(false);
    }
  }, [setAppointment, setLoading, getAppointmentDetailsByIdPublic]);
  const onReviewSave = useCallback(async (data, clickbtn = false) => {
    const appointmentId = props.match.params.appointmentId;
    if (clickbtn && !auth) {
      //
      const loginStateToObj = {
        pathname: '/patient/login',
        state: {formData: data, appointmentId: appointmentId, fromReviewWrite: true},
      };
      // history.push(loginStateToObj);
      let message = (
        <p>
          Please click{' '}
          <Link
            className="text-underline"
            onClick={() => setGlobal('modal', {showModal: false})}
            to={loginStateToObj}>
            Login
          </Link>{' '}
          to share your valuable review.
        </p>
      );
      setGlobal('modal', warningModalData(message));
    } else {
      //setProcessingSave(true);
      const response = await reviewAppointment(data).catch(e => {
        //  setProcessingSave(false);
        handleError(e);
      });
      if (response) {
        //  setProcessingSave(false);
        const {message} = response;
        setGlobal(
          'modal',
          successModalData(message, {
            callBackOnClose: () => {
              if (auth) {
                history.push({pathname: '/'});
              }
            },
          }),
        );
      }
    }
  }, []);
  useEffect(() => {
    // if (!processingSave) {
    //   setProcessingSave(true);
    if (history && history.location && history.location.state && history.location.state.callSave) {
      // console.log('history.location.state', history.location.state);
      if (auth) onReviewSave(history.location.state.formData);
    } else {
      getAppointmentDetailsById();
    }
    //}
  }, []);
  if (loading || Object.keys(appointment).length === 0) {
    return <LoadingPage hideTitle={true} />;
  }
  return (
    <>
      <PatientHeader hideRightBtn={true}></PatientHeader>
      <div className="container writereview-public">
        <div>
          <ReviewVisitModal
            data={{appointment: {...appointment}}}
            onReviewSave={data => onReviewSave(data, true)}
          />
        </div>
      </div>
      <PatientFooterNew></PatientFooterNew>
    </>
  );
};

export default WriteReview;
