import React, { useState, useContext, useEffect } from 'react';
import RootContext from 'context/RootContext';
import images from 'data/images';
import CircleButton from 'components/CircleButton';


import helper from 'data/helper';
import reviewService from 'services/review';
import modalConfig from 'modals/modalConfig';
import './ManageLocation.css';

import Checkbox from 'components/FormControls/Checkbox';

const { formatPhoneNumberWithDash } = helper;
const { modalFunctions } = modalConfig;
const { errorModalData, successModalData } = modalFunctions;
const { getgoogleSSOLocations,saveLocationList } = reviewService;

const ManageLocation = props => {
  const rootContext = useContext(RootContext);
  const { setGlobal } = rootContext;
  const {closeOnBGClick}=props
  const [formData, setFormData] = useState({
    values: {},
    errors: { serverError: null },
  });
  const [locationList, setLocationList] = useState([])
  const [alreadySaved, setAlreadySaved] = useState(true)
  const { values: formValues, errors: formErrors } = formData;
  useEffect(() => {
    getLocationList()
  }, [])
  const closeModal = () => {
    setGlobal('modal', { showModal: false });
  };

  const getLocationList = async () => {
    let responce = await getgoogleSSOLocations().catch(handleError)
    if(responce){
    let { data } = responce;
    // let {locations=[]}=data
    let checkalreadySaved=data.find((l)=>l.isSelected==true)
    if(!checkalreadySaved){
      setAlreadySaved(false)
    }
    setLocationList(data)
  }
  }

  const changeHandler = event => {
    let { name, value } = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {

      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const __validateForm = () => {
    let isDefault=locationList.find((l)=>l.isDefault)
    if(isDefault)
    return true
    return false
  };
  const isValidateForm = __validateForm();

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="" />
    </span>
  );

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">Manage location</div>
      {closeOnBGClick&&crossIconComponent}
    </div>
  );

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  const handleError = error => {
    const { message } = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  const saveLocationListFn = async () => {


   let passdata=[...locationList]
    try {
      const response = await saveLocationList({locations:passdata});
      if (response && response.data) {
        setGlobal('modal', successModalData('Your changes has been saved successfully'));
      }
    } catch (ex) {
      handleError(ex);
    }
  };
  const locationSelect=(selectedValue,SelectedObj)=>{
    
    let tempVal=[...locationList];
    let selectedIndex=tempVal.findIndex((l)=>l.name==SelectedObj.name)
    if(selectedIndex>=0){
      if(!selectedValue){
        if(tempVal[selectedIndex].isDefault){
          tempVal[selectedIndex].isDefault=selectedValue
        }
      }
      tempVal[selectedIndex].isSelected=selectedValue
    }
    setLocationList(tempVal)
  }
  const locationSelectDefault=(selectedValue,SelectedObj)=>{
    if(selectedValue){
    let tempVal=[...locationList];
    tempVal=tempVal.map((l)=>{
      l.isDefault=false
      return l
    })
    let selectedIndex=tempVal.findIndex((l)=>l.name==SelectedObj.name)

    if(selectedIndex>=0){
      tempVal[selectedIndex].isDefault=selectedValue
      tempVal[selectedIndex].isSelected=selectedValue
    }
    setLocationList(tempVal)
  }
  }
  return (
    <div className="ManageLocation no-select">
      {dialogHeader}
      <div className="dailog-body">
        <form>
          <div className='each-location'>
            <div className='each-item item-name'><b>Location</b></div>
            <div className='each-item ' style={{marginRight:"10px"}}><b>Address</b></div>
            <div className='each-item'><b>Select</b></div>
            <div className='each-item'><b>Is Default</b></div>
          </div>
          {locationList.map((ll) => (
            <div key={ll.name} className='each-location'>
              <div className='each-item item-name'>{ll.title}</div>
              <div className='each-item ' style={{marginRight:"10px"}}>{ll?.storefrontAddress?.addressLines[0]}</div>
              <div className='each-item'>
                <Checkbox value={ll.isSelected} onChange={(e)=>{
                  if(alreadySaved)return
                  let { value } = e.target;
                  locationSelect(value,ll)
                }}>

              </Checkbox></div>
              <div className='each-item'>
                <Checkbox value={ll.isDefault}
                onChange={(e)=>{
                  if(alreadySaved)return
                  let { value } = e.target;
                  locationSelectDefault(value,ll)
                  ///console.log("value",value);
                }}
                >

              </Checkbox></div>
            </div>
          ))}




          <div className="action-btn">
            {closeOnBGClick &&(
            <CircleButton onClick={closeModal} btnType="secondaryButton">
              Cancel
            </CircleButton>
            )}
            {!alreadySaved &&(
            <CircleButton btnType="whiteButton" disabled={!isValidateForm} onClick={saveLocationListFn}>
              Save
            </CircleButton>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ManageLocation;
