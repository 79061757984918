import React, {useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import moment from 'moment';
import images from 'data/images';
import Select from 'components/FormControls/Select';
import selectOptions from 'data/selectOptions';
import InfiniteScroll from 'components/InfiniteScroll';
import AuthContext from 'context/AuthContext';
import scheduleService from 'services/schedule';
import userService from 'services/user';
import AppointmentDetail from 'components/AfterAuth/Schedule/AppointmentDetail';
import helper from 'data/helper';
import MyLink from 'components/MyLink';
import './ViewPatientOverlayModal.css';

const {activityPatientOptions, patientGenderOptions} = selectOptions;
const {getPaginatedAppointments, getAppointmentForProviderBytId} = scheduleService;
const {getPatientAnalysisData} = userService;
const {getPatientUserAddress, formatPhoneNumber} = helper;

const ViewPatientOverlayModal = props => {
  const {closeModal,refetchAppointmentEventsDashBoard} = props;
  const {data} = props;
  const {patientUserId} = data;
  const authContext = useContext(AuthContext);
  const {getViewUser} = authContext;
  const viewUser = getViewUser();
  const [appointmentConfig, setAppointmentConfig] = useState({
    appointmentTypeId: '',
    isFetching: false,
    allFetched: false,
    appointments: [],
    limit: 5,
    patientUserId,
  });
  const [patientUserData, setPatientUserData] = useState(null);
  const [isBlockedState, setIsBlockedState] = useState(false);
  const [patientAnalysisData, setPatientAnalysisData] = useState(null);
  const {appointmentTypeId, limit, isFetching, allFetched, appointments} = appointmentConfig;

  useEffect(() => {
    getAndSetPatientAnalysisData();
    getAndSetAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstNam = _.get(patientUserData, 'firstName', '');
  const lastName = _.get(patientUserData, 'lastName', '');
  const genderValueId = _.get(patientUserData, 'genderId', '');
  const phoneNumber = _.get(patientUserData, 'phoneNumber', '');

  const email = _.get(patientUserData, 'email');
  let dateOfBirth = _.get(patientUserData, 'dateOfBirth', null);
  const insuranceData = _.get(patientUserData, 'userInsurances.0', {});
  const insuranceCompanyName = _.get(insuranceData, 'insuranceCompanyName');
  const insurancePlanName = _.get(insuranceData, 'insurancePlanName');
  const insuranceMemberId = _.get(insuranceData, 'insuranceMemberId');
  const insuranceCardFrontPhotoUrl = _.get(insuranceData, 'insuranceCardFrontPhotoUrl');
  const insuranceCardBackPhotoUrl = _.get(insuranceData, 'insuranceCardBackPhotoUrl');
  // formatted data
  const addressString = getPatientUserAddress(patientUserData);
  let dateOfBirthString = '';
  if (dateOfBirth) {
    dateOfBirthString =
      moment(dateOfBirth).format('MM/DD/YYYY') +
      ' (' +
      moment().diff(dateOfBirth, 'years') +
      ' years old) ';
  }
 
  const gender = _.find(patientGenderOptions, {value: genderValueId});
  const formatedPhoneNumber = formatPhoneNumber(phoneNumber);

  const getAndSetPatientAnalysisData = async () => {
    const response = await getPatientAnalysisData(patientUserId);
    if (response) {
      const {data = {}} = response;
      const {
        totalAppointmentCount = 0,
        pendingAppointmentCount = 0,
        confirmedAppointmentCount = 0,
        completedAppointmentCount = 0,
        cancelledAppointmentCount = 0,
        noShowAppointmentCount = 0,
        rescheduledAppointmentCount = 0,
        patientUserData: newPatientUserData,
        isBlocked=false
      } = data;
      const formatedAnalysisData = [
        {count: totalAppointmentCount, name: 'Total', nameSubtext: 'Appointments'},
        {count: pendingAppointmentCount, name: 'Pending ', nameSubtext: 'Appointments'},
        {count: confirmedAppointmentCount, name: 'Confirmed ', nameSubtext: 'Appointments'},
        {count: completedAppointmentCount, name: 'Complete ', nameSubtext: 'Appointments'},
        {count: cancelledAppointmentCount, name: 'Cancelled ', nameSubtext: 'Appointments'},
        {count: noShowAppointmentCount, name: 'No Show ', nameSubtext: 'Appointments'},
        {count: rescheduledAppointmentCount, name: 'Number of', nameSubtext: 'Reschedules'},
      ];
      setPatientAnalysisData(formatedAnalysisData);
      setPatientUserData(newPatientUserData);
      setIsBlockedState(isBlocked)
    }
  };

  // for infinite scroll
  const getAndSetAppointments = async (appointmentType = null) => {
    let updateObject = {};
    appointmentType = appointmentType !== null ? appointmentType : appointmentTypeId;
    const isappointmentTypeIdChanged = appointmentTypeId !== appointmentType;
    let lastUpdatedAt = null;
    if (isappointmentTypeIdChanged) {
      updateObject.allFetched = false;
    }
    if (!isappointmentTypeIdChanged && appointments.length > 0) {
      lastUpdatedAt = moment(appointments[appointments.length - 1].updatedAt)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const dataObject = {
      status: appointmentType,
      limit,
      lastUpdatedAt,
      patientUserId,
    };
    const appointmentsResponse = await getPaginatedAppointments(dataObject);
    if (appointmentsResponse) {
      const {data} = appointmentsResponse;
      setAppointmentConfig(prevState => {
        let newAppointments = [...data];
        if (!isappointmentTypeIdChanged) {
          newAppointments = [...prevState.appointments, ...newAppointments];
          updateObject.allFetched = false;
        }
        if (data.length < limit) {
          updateObject.allFetched = true;
        }
        newAppointments = _.uniqBy(newAppointments, 'id');
        return {
          ...prevState,
          appointments: newAppointments,
          appointmentTypeId: appointmentType,
          ...updateObject,
        };
      });
    }
    changeFetchingStatus(false);
  };

  const changeFetchingStatus = status => {
    setAppointmentConfig(prevState => {
      return {
        ...prevState,
        isFetching: status,
      };
    });
  };

  const refetchAppointmentEvents = async appointmentId => {
    const response = await getAppointmentForProviderBytId(appointmentId);
    if (response) {
      const {data} = response;
      setAppointmentConfig(prevState => {
        let updatedAppointments = [...prevState.appointments];
        updatedAppointments = updatedAppointments.map(appointment => {
          if (appointment.id === appointmentId) {
            return data;
          } else {
            return appointment;
          }
        });
        return {
          ...prevState,
          appointments: updatedAppointments,
        };
      });
    }
  };

  const handleAppointmentTypeChange = event => {
    const {value} = event.target;
    getAndSetAppointments(value);
  };

  let appointmentTypetext = 'All';
  if (appointmentTypeId) {
    const selectedAppointment = _.find(activityPatientOptions, {value: appointmentTypeId});
    if (selectedAppointment) {
      appointmentTypetext = selectedAppointment.label;
    }
  }

  return (
    <div className="ViewPatientOverlayModal">
      <div className="modal-content-header">
        <div className="heading3">
          {firstNam} {lastName}
        </div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <div className="patient-details">
          <div className="patient-info">
            <div>
              {gender && gender.label} - {dateOfBirthString}
            </div>
            {addressString && (
              <>
                {addressString}
                <br />
              </>
            )}
            {formatedPhoneNumber}
            <br />
            <div className="link">
              <a className="link" href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          </div>
          <div className="insurance-info">
            {insuranceCompanyName && (
              <>
                <div>{insurancePlanName}</div>
                <div>Member ID: {insuranceMemberId ? insuranceMemberId : 'not specified'}</div>
              </>
            )}
            <div className="link-main">
              {insuranceCardFrontPhotoUrl && (
                <div>
                  <MyLink to={insuranceCardFrontPhotoUrl} className="link">
                    View Front Insurance Card Photo
                  </MyLink>
                </div>
              )}
              {insuranceCardBackPhotoUrl && (
                <div>
                  <MyLink to={insuranceCardBackPhotoUrl} className="link">
                    View Back Insurance Card Photo
                  </MyLink>
                </div>
              )}
            </div>
          </div>

        </div>
        {isBlockedState && (
          <div>
<p style={{color:"red"}}>Patient is blocked from booking appointments because of too many No Shows across all providers.</p>
</div>
        )}
        
        <div className="patient-counts-details">
          {patientAnalysisData &&
            patientAnalysisData.map((patientCount, i) => (
              <div className="box" key={i}>
                <span className="number">{patientCount.count}</span>
                <span className="text">{patientCount.name}</span>
                <span className="subtext">{patientCount.nameSubtext}</span>
              </div>
            ))}
        </div>
        <div className="patient-appointment-info">
          <div className="patient-appointment-head">
            <span className="title">
              {appointmentTypetext} Appointments <span>{moment().format('YYYY')}</span>
            </span>
            <Select
              isAllowDefaultOpen={true}
              className="active-dropdown"
              defaultOptionProp={{value: '', label: 'All'}}
              onChange={handleAppointmentTypeChange}
              value={appointmentTypeId}
              options={activityPatientOptions}
            />
          </div>
          <div className="patient-appointment-dtails">
            <InfiniteScroll
              offset={100}
              allFetched={allFetched}
              onScrollToBotton={() => getAndSetAppointments(appointmentTypeId)}
              isFetching={isFetching}
              setIsFetching={() => !isFetching && changeFetchingStatus(true)}>
              {appointments &&
                appointments.map(appointment => {
                  return (
                    <AppointmentDetail
                      hidePatientLink
                      key={appointment.id}
                      appointmentDetail={appointment}
                      viewUser={viewUser}
                      refetchAppointmentEvents={refetchAppointmentEvents}
                      refetchAppointmentEventsDashBoard={refetchAppointmentEventsDashBoard}
                    />
                  );
                })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPatientOverlayModal;
