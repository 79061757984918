import React, {Component} from 'react';
import Header from 'layout/Header';
import './PrivacyPolicy.css';

class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <Header hideHeaderButtons={true}></Header>
        <div className="PrivacyPolicy">
          <div className="container">
            <div className="template-inner">
              <div className="main-header">KALY PRIVACY POLICY</div>
              <div className="template-content">
                <div className="sub-header-title">Effective Date: October 22, 2021</div>
                <div className="para">
                  You are a valued customer of Kaly Inc. (“Kaly,” “us” or “we”) and we take
                  your privacy very seriously. This Privacy Policy (“Policy”) is intended to inform
                  you about the information we may collect from you or that you may provide to us,
                  and our practices for collecting, using, maintaining, protecting, and disclosing
                  that information. This Policy applies to both personal and non-personal
                  information which Kaly may collect and use depending on how you choose to
                  interact with our website, www.kaly.com (the “Website”), related websites,
                  applications, and services owned and operated by Kaly and that link to this
                  Privacy Policy, including our services to facilitate your search for healthcare
                  providers and to book medical appointments (collectively, the “Services”). BY
                  USING OR ACCESSING THE SERVICES IN ANY MANNER, YOU ACKNOWLEDGE THAT YOU ACCEPT THE
                  PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY, AND YOU HEREBY CONSENT
                  THAT WE WILL COLLECT, USE, AND SHARE YOUR INFORMATION IN THE FOLLOWING WAYS. IF
                  YOU DO NOT AGREE WITH THIS PRIVACY POLICY, YOU MAY NOT USE THE SERVICES. IF YOU
                  USE THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH
                  AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORIZED BY SUCH INDIVIDUAL OR
                  ENTITY TO ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF.
                  Please also refer to the Mandatory Terms of Use, which are expressly incorporated
                  as if fully recited herein, if you have not already done so. Unless otherwise
                  defined herein, all terms have the meanings they are assigned in the Mandatory
                  Terms of Use. Please be assured that Kaly will only use your information in
                  accordance with this Policy.
                </div>
                <div className="para text-bold">INFORMATION WE COLLECT</div>
                <div className="para">
                  We collect information that identifies, relates to, describes, references, is
                  capable of being associated with, or could reasonably be linked, directly or
                  indirectly, with a particular individual or device (“personal information”). In
                  particular, we may have collected the following categories of personal information
                  from consumers within the last twelve (12) months:
                  <ol>
                    <li>
                      Identifiers, for example your real name, email address, telephone number,
                      postal address, online identifier, user ID, device ID, domain server, type of
                      device/operating system, browser used to access our services, Internet
                      Protocol address, account name, driver's license number, passport number, or
                      other similar identifiers.
                    </li>
                    <li>
                      Biographical, medical and insurance information, for example education,
                      employment, employment history, health insurance information, medical history
                      and health information you provide us, including health conditions, healthcare
                      providers visited, reasons for visit, dates of visit, booking and appointment
                      data (including appointment date/time, provider information, appointment
                      procedure, whether or not user is a new patient for a particular provider).
                      Some information included in this category may overlap with other categories.
                    </li>
                    <li>
                      Protected classification characteristics, for example age, race, ethnicity,
                      color, ancestry, national origin, citizenship, religion or creed, marital
                      status, medical condition, physical or mental disability, sex (including
                      gender, gender identity, gender expression, pregnancy or childbirth and
                      related medical conditions), sexual orientation, veteran or military status,
                      genetic information (including familial genetic information).
                    </li>
                    <li>
                      Internet or similar network activity, for example browsing history, search
                      history, information on your interaction with the Website, application, or
                      advertisement, referring webpage/source through which you access our services,
                      non-identifiable request IDs, and statistics associated with the interaction
                      between device or browser and our services.
                    </li>
                    <li>
                      Geolocation data, for example the approximate location (city and state) of the
                      device from which you access our Services.
                    </li>
                    <li>
                      Other identifying information that you voluntarily chose to provide, for
                      example personal data in emails, letters or online forms that you send or
                      submit to us.
                    </li>
                  </ol>
                  We obtain the categories of personal information listed above from the following
                  categories of sources:
                  <ul>
                    <li>
                      Directly from our customers or their agents. For example, from information
                      that our customers provide to us related to the services for which they engage
                      us.
                    </li>
                    <li>
                      Indirectly from Healthcare Providers or their agents. For example, through
                      information we collect from Healthcare Providers in the course of providing
                      services to them.
                    </li>
                    <li>
                      Directly and indirectly from activity on our Website. For example, from
                      submissions through our Website portal or Website usage details collected
                      automatically.
                    </li>
                    <li>
                      From third-parties that interact with us in connection with the Services we
                      perform.
                    </li>
                  </ul>
                </div>
                <div className="para text-bold">PROTECTED HEALTH INFORMATION AND HIPAA</div>
                <div className="para">
                  Certain medical, health and/or health-related information that Kaly collects
                  about you as part of providing the Services to you may be considered “protected
                  health information” or “PHI” under the Health Insurance Portability and
                  Accountability Act (“HIPAA”). Specifically, when Kaly, acting as a “Business
                  Associate” (as such term is defined in HIPAA) receives identifiable information
                  about you from or on behalf of you, or your healthcare specialist, professional,
                  provider, organization or agent or affiliate thereof (collectively, “Healthcare
                  Providers”), this information is considered PHI. Personal data that you provides
                  to us outside of the foregoing context is not PHI. For example, when you create an
                  account with us, interact with the Website, search for Healthcare Providers or
                  available appointments with Healthcare Providers, or when you voluntarily provide
                  information in free-form text boxes through the Website or through responses to
                  surveys and questionnaires, or post reviews; or when you send us an email or
                  otherwise contact us, that information is not PHI.
                </div>
                <div className="para">
                  HIPAA provides specific protections for the privacy and security of PHI and
                  restricts how PHI is used and disclosed. We may only use and disclose PHI in the
                  ways permitted by your Healthcare Provider(s) or authorized by you. Please also
                  refer to our HIPAA BA Policy, which is expressly incorporated as if fully recited
                  herein, if you have not already done so.
                </div>
                <div className="para text-bold">USE OF PERSONAL INFORMATION</div>
                <div className="para">
                  We may use or disclose the personal information we collect for one or more of the
                  following business purposes:
                  <ul>
                    <li>
                      To fulfill or meet the reason for which the information is provided. For
                      example, if you provide us with personal information in order for us to
                      provide you with a selection of Healthcare Providers relevant to the criteria
                      you input, we will use that information to prepare and submit to you a
                      selection of such Healthcare Providers.
                    </li>
                    <li>
                      To provide you with information, products or services that you request from
                      us.
                    </li>
                    <li>
                      To provide you with email alerts and other notices concerning our products or
                      services, or events or news, that may be of interest to you.
                    </li>
                    <li>
                      To carry out our obligations and enforce our rights arising from any contracts
                      entered into between you and us, including for billing and collections.
                    </li>
                    <li>To improve our Website and present its contents to you.</li>
                    <li>For testing, research, analysis and development of service offerings.</li>
                    <li>
                      As necessary or appropriate to protect the rights, property or safety of us,
                      our clients or others.
                    </li>
                    <li>
                      To respond to law enforcement requests and as required by applicable law,
                      court order, or governmental regulations.
                    </li>
                    <li>As described to you when collecting your personal information.</li>
                    <li>
                      To evaluate or conduct a merger, divestiture, restructuring, reorganization,
                      dissolution, or other sale or transfer of some or all of our assets, whether
                      as a going concern or as part of bankruptcy, liquidation, or similar
                      proceeding, in which personal information held by us is among the assets
                      transferred or impacted.
                    </li>
                  </ul>
                  We will not collect additional categories of personal information or use the
                  personal information we collected for materially different, unrelated, or
                  incompatible purposes without providing you notice.
                </div>
                <div className="para text-bold">SHARING PERSONAL INFORMATION</div>
                <div className="para">
                  We may disclose your personal information to a third party for a business purpose.
                  When we disclose personal information for a business purpose, we enter a contract
                  that describes the purpose and requires the recipient to both keep that personal
                  information confidential and not use it for any purpose except performing the
                  contract.
                </div>
                <div className="para">
                  In the preceding twelve (12) months, we have or may have disclosed the following
                  categories of personal information for a business purpose:
                  <ol>
                    <li>Identifiers.</li>
                    <li>Biographical, medical and insurance information.</li>
                    <li>Protected classification characteristics.</li>
                    <li>Internet or similar network activity.</li>
                    <li>Geolocation data.</li>
                    <li>Other identifying information that you voluntarily chose to provide.</li>
                  </ol>
                  We disclose your personal information for a business purpose to the following
                  categories of third parties:
                  <ul>
                    <li>Our affiliates.</li>
                    <li>Service providers, including Healthcare Providers.</li>
                    <li>
                      Third parties to whom you or your agents authorize us to disclose your
                      personal information in connection with products or services we provide to
                      you.
                    </li>
                  </ul>
                  In the preceding twelve (12) months, we have not sold any personal information.
                </div>
                <div className="para text-bold">YOUR RIGHTS</div>
                <div className="para">
                  If you are a registered user of our Services, you can modify the personal data
                  that you provided to us or account information by logging in and accessing your
                  account. If you wish to close your account or delete all data that you provided to
                  us, please email us at <a href="mailto:hello@kaly.com">hello@kaly.com</a>.
                  Kaly will use reasonable efforts to delete your account and all associated
                  data as soon as reasonably possible. Please note, however, that Kaly reserves
                  the right to retain information from closed accounts consistent with all internal
                  data retention policies and procedures.
                </div>
                <div className="para">
                  To the extent required by the CAN-SPAM Act, or other similar domestic and
                  international regulation, commercial e-mail messages sent by Kaly will also
                  provide you with an opportunity to opt-out of receiving this information. Please
                  note that changes to your preferences may not be effective immediately and will
                  only be effective prospectively. If you opt-out, Kaly will also retain the
                  minimal information necessary for reference in an effort to ensure that you are
                  not contacted in the future. Any requests regarding your data will be handled
                  within any required period under applicable law.
                </div>
                <div className="para">
                  You must promptly notify us if any of your account data is lost, stolen or used
                  without permission, or if your password has been otherwise compromised.
                </div>
                <div className="para text-bold">CALIFORNIA PRIVACY RIGHTS</div>
                <div className="para">
                  If you a California resident, please refer to our Privacy Notice to California
                  Residents, which is expressly incorporated as if fully recited herein.
                </div>
                <div className="para text-bold">NON-DISCRIMINATION</div>
                <div className="para">
                  We will not discriminate against you for exercising any of your rights under this
                  Privacy Policy. In that regard, we will not take the following actions in response
                  to you exercising your rights under this Privacy Policy, unless permitted by the
                  applicable law:
                  <ul>
                    <li>Deny you goods or services.</li>
                    <li>
                      Charge you different prices or rates for goods or services, including through
                      granting discounts or other benefits, or imposing penalties.
                    </li>
                    <li>Provide you a different level or quality of goods or services.</li>
                    <li>
                      Suggest that you may receive a different price or rate for goods or services
                      or a different level or quality of goods or services.
                    </li>
                  </ul>
                </div>
                <div className="para text-bold">CHILDREN</div>
                <div className="para">
                  While the Website and the Services are not directed to individuals under the age
                  of 18 (“Children”), nor does it contain information which would be potentially
                  harmful to minors in any way, Kaly is committed to the protection of the
                  online privacy of Children as well as parental control over information collected
                  from Children online. It is our policy never to knowingly collect or maintain
                  information about any person under the age of 18 and visitors to the Website under
                  the age of 18 should not disclose or provide any personally identifiable
                  information. In the event that Kaly discovers that a child under the age of 18
                  has provided personally identifiable information to us, we will delete the child’s
                  personally identifiable information in accordance with the Children’s Online
                  Privacy Protection Act of 1998. Please see the Federal Trade Commission’s website
                  for this act&nbsp;
                  <a href="http://www.ftc.gov/bcp/conline/edcams/kidzprivacy/" target="_blank">
                    http://www.ftc.gov/bcp/conline/edcams/kidzprivacy/
                  </a>&nbsp;
                  for more information. Parents or guardians who are concerned about information
                  possibly submitted to Kaly by their Children should contact&nbsp;
                  <a href="mailto:hello@kaly.com">hello@kaly.com</a> for assistance in
                  identifying and removing any such data. Notwithstanding the foregoing, pursuant to
                  47 U.S.C. Section 230(d), as amended, we hereby notify you that parental control
                  protections are commercially available to assist you in limiting access to
                  material that is harmful to minors. More information on the availability of such
                  software can be found through publicly available sources. You may wish to contact
                  your Internet Service Provider for more information.
                </div>
                <div className="para text-bold">DO NOT TRACK</div>
                <div className="para">
                  Kaly does not track its customers over time and across third party websites to
                  provide targeted advertising and therefore does not respond to Do Not Track (DNT)
                  signals. However, some third party sites do keep track of your browsing activities
                  when they serve you content, which enables them to tailor what they present to
                  you. If you are visiting such sites, your browser provider may allow you to set
                  the DNT signal. For example, Safari allows you to set the DNT signal on your
                  browser so that third parties (particularly advertisers) know you do not want to
                  be tracked. If you would like to learn how to set Safari so that websites do not
                  track you, go here:{' '}
                  <a
                    href="https://support.apple.com/kb/PH21416"
                    rel="noopener noreferrer"
                    target="_blank">
                    https://support.apple.com/kb/PH21416
                  </a>
                  . With this option turned on, each time Safari loads content from a website,
                  Safari adds a request not to track you, but it’s up to the website to honor this
                  request. If using an Apple device, Choose Safari &gt; Preferences, click Privacy,
                  then select “Ask websites not to track me.” For other devices and browsers, check
                  with your provider for instructions.
                </div>
                <div className="para text-bold">PROTECTION OF INFORMATION</div>
                <div className="para">
                  We use reasonable physical, technical, and administrative measures to safeguard
                  information in our possession against loss, theft and unauthorized use,
                  disclosure, or modification. Please note, however, that no data transmission or
                  storage can be guaranteed to be 100% secure. As a result, while we strive to
                  protect the information we maintain, we cannot ensure, guaranty or warrant the
                  security of any information that you transmit to us or that we transmit to you.
                </div>
                <div className="para text-bold">RETENTION OF INFORMATION</div>
                <div className="para">
                  We endeavor not to retain information about you in an identifiable and
                  non-anonymized form for longer than necessary in relation to the purposes for
                  which they are processed or for as long as we maintain an ongoing relationship
                  with you, plus the duration of any applicable statute of limitations period in
                  which we may be subject to a legal claim. If any claim, legal process, or
                  enforceable government request is brought, we may continue to retain your
                  information for an additional time as necessary to protect our legal interests or
                  to comply with any applicable law, regulation, legal process or enforceable
                  governmental request.
                </div>
                <div className="para">
                  We reserve the right to and may retain information about you beyond the above
                  retention criteria for statistical purposes, and information retained for such
                  purposes will be in an anonymized form that is not identifiable to you.
                </div>
                <div className="para text-bold">THIRD-PARTY WEBSITES AND SERVICES</div>
                <div className="para">
                  Our Services may link to websites or other online services operated by third-party
                  companies. We are not responsible for the privacy practices of these third
                  parties. Once you have left our Services, you should check the applicable privacy
                  policy of the third party website or service to determine how the third party will
                  handle any information it collects from you.
                </div>
                <div className="para text-bold">CHANGES TO OUR PRIVACY NOTICE</div>
                <div className="para">
                  We reserve the right to amend this privacy notice at our discretion and at any
                  time. When we make changes to this privacy notice, we will notify you by email or
                  through a notice on our website homepage. You will also be able to determine the
                  date that the privacy policy has become effective, by viewing the Effective Date
                  at the top of this document.
                </div>
                <div className="para text-bold">CONTACT INFORMATION</div>
                <div className="para">
                  If you have any questions or comments about our Privacy Policy, the ways in which
                  we collect and use your personal information, your choices and rights regarding
                  such use, please do not hesitate to contact us at:
                  <br />
                  Phone: <a href="tel:9737355775">9737355775</a>
                  <br />
                  Email: <a href="mailto:hello@kaly.com">hello@kaly.com</a>
                  <br />
                  <table>
                    <tbody>
                      <tr>
                        <td className="address-cell">Postal Address:</td>
                        <td>
                          Kaly, LLC
                          <br />
                          c/o Wilson Elser
                          <br />
                          Attn: Neil Prupis
                          <br />
                          200 Campus Drive
                          <br />
                          Florham Park, NJ 07932
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PrivacyPolicy;
