import React, {useContext} from 'react';
import moment from 'moment';
import _ from 'lodash';
import images from 'data/images';
import momentHelper from 'data/moment/momentHelper';
import MyLink from 'components/MyLink';
import selectOptions from 'data/selectOptions';
import scheduleService from 'services/schedule';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import helper from 'data/helper';
import ViewPatientOverlayModal from 'components/AfterAuth/Dashboard/ViewPatientOverlayModal';
import './ActivityItem.css';
import TimeZoneLabelShort from 'components/MyDatetime/TimeZoneLabelShort';

const {requestInsuranceCard} = scheduleService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData, blankModalData} = modalFunctions;
const {getAppointmentClassFromStatus} = helper;

const ActivityItem = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const {appointment} = props;
  const startDateTime = momentHelper.utcToLocalMoment(appointment.appointmentStartDateTime);
  const appointmentId = _.get(appointment, 'id', 0);
  const status = _.get(appointment, 'status');
  const patient = _.get(appointment, 'patient', {});
  const patientUserId = _.get(patient, 'user.id', 0);
  const cancelledBy = _.get(appointment, 'cancelledBy', '');
  const cancelledAt = _.get(appointment, 'cancelledAt', null);
  const appointmentStatusClass = getAppointmentClassFromStatus(status);

  const formatCancellationDate = () => {
    return moment.utc(cancelledAt, 'YYYY-MM-DD HH:mm:ss').local().format('MMM DD, YYYY');
  };

  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  const handleRequestInsuranceCard = async () => {
    const response = await requestInsuranceCard(appointmentId).catch(handleError);
    if (response) {
      const {message} = response;
      setGlobal('modal', successModalData(message));
    }
  };

  const openViewPatientOverlayModal = patientUserId => {
    const {setGlobal} = rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ViewPatientOverlayModal,
        modalWrapperClass: 'mega-modal',
        customComponentProps: {
          data: {
            patientUserId,
          },
        },
      }),
    );
  };

  let insuranceCardComponent = null;
  if (appointment.status !== 2) {
    const {insuranceCardFrontPhotoUrl, insuranceCardBackPhotoUrl} = appointment;
    if (insuranceCardFrontPhotoUrl || insuranceCardBackPhotoUrl) {
      insuranceCardComponent = (
        <>
          {insuranceCardFrontPhotoUrl && (
            <MyLink to={insuranceCardFrontPhotoUrl}>View Front Insurance Card</MyLink>
          )}
          {insuranceCardBackPhotoUrl && (
            <MyLink to={insuranceCardBackPhotoUrl}>View Back Insurance Card</MyLink>
          )}
        </>
      );
    } else if (!appointment.insuranceCompany) {
      insuranceCardComponent = <span>{selectOptions.insuranceDefaultOption.label}</span>;
    } else {
      insuranceCardComponent = (
        <span onClick={handleRequestInsuranceCard} className="link-span">
          Request Insurance Card
        </span>
      );
    }
  }

  return (
    <div className={`ActivityItem ${appointmentStatusClass}`}>
      <div className="day">
        <span>{startDateTime.format('dddd, MMM DD [at] h:mm a')}<TimeZoneLabelShort/></span>
        <img src={appointment.isVirtual ? images.videoCamera : images.personIcon} alt="status" />
      </div>
      <div className="name">
        {patient.firstName} {patient.lastName}
      </div>
      <span
        onClick={() => openViewPatientOverlayModal(patientUserId)}
        className="link-span view-patient">
        View Patient
      </span>
      {insuranceCardComponent}
      {appointment.status === 2 && (
        <div className="cancel-staus">
          Cancelled by {_.capitalize(cancelledBy)} on {formatCancellationDate()}
        </div>
      )}
    </div>
  );
};

export default ActivityItem;
