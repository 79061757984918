const questionList = [
  {
    id: 521,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [8, 10], // painIds,
    nextQuestionId: 522,
    descriptions: [],
  },
  {
    id: 522,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 523,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 8, questionId: 23, visitReasonIds: [85], extraQuestion: 1},
          {painId: 10, questionId: 24, visitReasonIds: [86], extraQuestion: 2},
        ],
      },
      {
        nextQuestionId: 523,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 8, questionId: 24, visitReasonIds: [85], extraQuestion: 2},
          {painId: 10, questionId: 24, visitReasonIds: [86], extraQuestion: 2},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 523,
    question: 'Are you interested in potentially undergoing surgery?',
    options: [
      {
        nextQuestionId: 524,
        label: 'Yes, in the near future',
        shortLabel: 'Interested in surgery',
      },
      {nextQuestionId: 524, label: 'No, not at this time', shortLabel: 'No surgery'},
    ],
    descriptions: [],
  },
  {
    id: 524,
    question: 'Do you still want to see a Podiatrist?',
    descriptions: [
      'A Podiatrist is a Doctor of Podiatric Medicine (DPM) who completed a 4 year residency in Foot and Ankle Surgery. A Podiatrist has further training in treating foot and ankle conditions that may require surgery. ',
    ],
    title: 'Podiatrist',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can perform pain injections',
    ],
    options: [
      {groupId: 'A12', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 521,
    lastQuestionId: 524,
    treeDepth: 4,
  },
};
