import React, {Component} from 'react';
import _ from 'lodash';
import Layout from 'layout/AdminLayout';
import RootContext from 'context/RootContext';
import SubHeader from 'components/AfterAuth/SubHeader';
import KScore from 'components/AfterAuth/KScore';
import ReviewTab from '../../Providers/ReviewTab';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import modalConfig from 'modals/modalConfig';
import contextHelper from 'context/contextHelper';
import './GhostProviderReviews.css';
import adminService from 'services/admin';

const {modalFunctions} = modalConfig;
const {errorModalData, confirmModalData} = modalFunctions;
const {
  getGhostProviderReview,
  getGhostProviderReviewList,
  editProviderReply,
  deleteProviderReply,
  deleteProviderReview,
  getProviderReviewSource,
} = adminService;

class GhostProviderReviews extends Component {
  static contextType = RootContext;
  state = {
    activeTabIndex: 0,
    npiNumber: '',
    KScoreData: null,
    searchText: '',
    filterType: '',
    filterSourceId: null,
    sortBy: 'recent',
    currentPage: 1,
    totalPage: 0,
    reviewList: [],
    filterOptions: [],
    sourceList: [],
  };
  tabs = [{label: 'All Reviews', count: 0}];
  authContext = {};
  oldAuthContext = null;

  componentDidMount = () => {
    this.getProviderReviews();
  };

  getProviderReviews = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const npiNumber = query.get('npi') || 0;
    this.getkScore(npiNumber);
    this.getReviews(npiNumber, null);
  };

  getkScore = async reviewUserId => {
    const id = reviewUserId ? reviewUserId : this.state.npiNumber;
    if (id) {
      const response = await getGhostProviderReview(id).catch(this.handleError);
      if (response) {
        const {data} = response;
        if (data) {
          const {totalReview} = data;
          this.tabs[0].count = totalReview;
          const KScoreData = this.formatKScoreData(data);
          this.setState({
            KScoreData,
            npiNumber: id,
          });
        }
      }
    }
  };

  getReviewSource = async npiNumber => {
    const id = npiNumber ? npiNumber : this.state.npiNumber;
    const response = await getProviderReviewSource(id).catch(this.handleError);
    let sourceList = [];
    sourceList.push({value: 1, label: 'Kaly'});
    if (response) {
      _.forEach(response.data, source => {
        const sourceData = {...source.reviewSource};
        sourceList.push({value: sourceData.id, label: sourceData.title});
      });
      this.setState({
        filterOptions: _.orderBy(sourceList, ['label'], ['asc']),
        sourceList: response.data,
      });
    }
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  getReviews = async (npiId, isPageChange) => {
    const {sortBy, searchText, filterType, filterSourceId, currentPage} = this.state;
    const id = npiId ? npiId : this.state.npiNumber;
    if (id) {
      const reqData = {
        page: isPageChange ? currentPage : 1,
        limit: 25,
        sortBy: sortBy,
        search: searchText,
        filter: filterType,
        sourceId: filterSourceId === 0 ? '' : filterSourceId,
      };
      const reviewResponse = await getGhostProviderReviewList(id, reqData).catch(this.handleError);
      let reviewData = reviewResponse.data ? reviewResponse.data.reviewData : null;
      let totalPageCount = reviewResponse.data ? reviewResponse.data.totalPage : 0;
      if (isPageChange) {
        reviewData = [...(this.state.reviewList || []), ...reviewData];
      }
      this.setState({
        reviewList: reviewData,
        npiNumber: id,
        totalPage: totalPageCount,
        currentPage: reqData.page,
      });
    }
  };

  formatKScoreData = data => {
    const {totalRating, totalReview, doctorRating, staffRating, officeRating} = data;
    const KScoreData = {
      totalReview,
      totalAverageRating: totalRating,
      doctorRatingList: [
        {name: 'Knowledge', rating: doctorRating.knowledge},
        {name: 'Approachable', rating: doctorRating.approachable},
        {name: 'Bedside Manner', rating: doctorRating.bedsideManner},
      ],
      staffRatingList: [
        {name: 'Professional', rating: staffRating.professional},
        {name: 'Scheduling', rating: staffRating.scheduling},
        {name: 'Response Time', rating: staffRating.responseTime},
      ],
      officeRatingList: [
        {name: 'Cleanliness', rating: officeRating.cleanliness},
        {name: 'Atmosphere', rating: officeRating.atmosphere},
        {name: 'Wait times', rating: officeRating.waitTimes},
      ],
    };
    return KScoreData;
  };

  handleSearch = event => {
    this.setState({
      searchText: event.target.value,
    });
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.getReviews();
      }, 500);
    }
    this.debouncedFn();
  };

  handleReply = async data => {
    let response = {};
    if (data && data.replyId) {
      const reqData = {replyId: data.replyId, reply: data.reply};
      response = await editProviderReply(data.reviewId, reqData).catch(this.handleError);
      if (response) {
        const {reviewList} = this.state;
        let updatedReviewList = [...reviewList];
        updatedReviewList = _.map(updatedReviewList, review => {
          let updatedReview = {...review};
          if (review.id === data.reviewId) {
            updatedReview = {
              ...updatedReview,
              reply: {
                reply: response.data && response.data.reply ? response.data.reply : data.reply,
                id: response.data && response.data.id ? response.data.id : data.replyId,
              },
            };
          }
          return updatedReview;
        });
        this.setState({
          reviewList: updatedReviewList,
        });
      }
    }
  };

  handleDeleteReply = replyId => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete this Reply ?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmDelete(replyId);
          }
        },
      }),
    );
  };

  confirmDelete = async data => {
    const response = await deleteProviderReply(data.replyId).catch(this.handleError);
    if (response) {
      const {reviewList} = this.state;
      let updatedReviewList = [...reviewList];
      updatedReviewList = _.map(updatedReviewList, review => {
        let updatedReview = {...review};
        if (review.id === data.reviewId) {
          updatedReview = {
            ...updatedReview,
            reply: null,
          };
        }
        return updatedReview;
      });
      this.setState({
        reviewList: updatedReviewList,
      });
    }
  };

  backToProviderList = () => {
    const {location} = this.props;
    const searchData = _.get(location, 'state', {});
    this.props.history.push({
      pathname: '/admin/ghost-profiles',
      state: searchData,
    });
  };

  handleSortChange = newSortBy => {
    this.setState({sortBy: newSortBy},()=>{
      this.getkScore();
      this.getReviews()
    } );
  };

  handlePageChange = () => {
    this.setState(
      prevState => {
        return {
          ...prevState,
          currentPage: prevState.currentPage + 1,
        };
      },
      () => this.getReviews(null, true),
    );
  };

  handleReviewDelete = reviewId => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete this Review ?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmDeleteReview(reviewId);
          }
        },
      }),
    );
  };

  confirmDeleteReview = async reviewId => {
    const response = await deleteProviderReview(reviewId).catch(this.handleError);
    if (response) {
      this.getkScore()
      const {reviewList} = this.state;
      const updatedReviewList = [...reviewList];
      this.setState({
        reviewList: updatedReviewList.filter(review => review.id !== reviewId),
      });
    }
  };

  render() {
    const {
      activeTabIndex,
      reviewList,
      searchText,
      KScoreData,
      sortBy,
      currentPage,
      totalPage,
      sourceList,
    } = this.state;
    const title = 'Provider Reviews';

    return (
      <Layout>
        {contextHelper.AuthContextConsumer(this)}
        <div className="GhostProviderReviews">
          <SubHeader title={title} hideName />
          <div className="main-section-content">
            <div className="back-provider" onClick={this.backToProviderList}>
              <span className="left-arrow">
                <img src={images.arrowLeft} alt="left-arrow" />
              </span>
              Back to All Providers
            </div>
            {KScoreData && (
              <div className="box-panle">
                <KScore data={KScoreData}></KScore>
              </div>
            )}
            <div className="box-panle">
              {activeTabIndex === 0 && (
                <div className="search-review-list">
                  <Input
                    placeholder="Search Review"
                    onChange={this.handleSearch}
                    value={searchText}
                    icon={images.search}></Input>
                </div>
              )}

              <ReviewTab
                isGhost={true}
                onReply={this.handleReply}
                searchText={searchText}
                tabs={this.tabs}
                reviewList={reviewList}
                activeTabIndex={activeTabIndex}
                onDelete={this.handleDeleteReply}
                getReviews={this.getReviews}
                currentPage={currentPage}
                handleSortChange={this.handleSortChange}
                handlePageChange={this.handlePageChange}
                onReviewDelete={this.handleReviewDelete}
                hasMorePage={totalPage === currentPage || totalPage === 0 ? false : true}
                sortType={sortBy}
                sourceList={sourceList}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default GhostProviderReviews;
