import React from 'react';
import _ from 'lodash';
import selectOptions from 'data/selectOptions';
import './Select.css';

const {defaultOption} = selectOptions;

const Select = props => {
  const {
    label = '',
    containerClass = '',
    options = [],
    error = null,
    labelIndex = 'label',
    valueIndex = 'value',
    arrowCss = {},
    isAllowDefaultOpen = false,
    defaultOptionProp = null,
    ...otherInputProps
  } = props;
  const {value = ''} = otherInputProps;
  if (!isAllowDefaultOpen) {
    otherInputProps.required = 'required';
  }
  const updatedOptions = [...options];
  if (_.trim(value) === '' || isAllowDefaultOpen) {
    const defaultOptionObj = defaultOptionProp || defaultOption;
    const updatedDefaultOption = {};
    updatedDefaultOption[labelIndex] = defaultOptionObj['label'];
    updatedDefaultOption[valueIndex] = defaultOptionObj['value'];
    updatedOptions.unshift({...updatedDefaultOption});
  }
  const optionsComponent = updatedOptions.map(option => {
    return (
      <option key={option[valueIndex]} value={option[valueIndex]}>
        {option[labelIndex]}
      </option>
    );
  });
  let newContainerClass = containerClass;
  if (error) {
    newContainerClass += ' error';
  }
  if (_.trim(value) === '') {
    newContainerClass += ' no-filled-select';
  }

  return (
    <div className={`Select-control form-group ${newContainerClass}`}>
      <span className="label">{label}</span>
      <select {...otherInputProps}>
        {optionsComponent}
      </select>
      <span className="arrow" style={arrowCss}></span>
      {error && <span className="error-text">{error}</span>}
    </div>
  );
};

export default Select;
