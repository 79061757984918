import axios from 'axios';
import axiosInstance from 'config/axios';
import constants from 'data/constants';
import _ from 'lodash';
import momentHelper from 'data/moment/momentHelper';
import {searchCalls} from './patient';

const {localToUtcDateTimeFormat} = momentHelper;

const getAllAvailabilities = (startDate, enddate) => {
  return axiosInstance.get(`v1/schedule/provider-availability/${startDate}/${enddate}`);
};

const getAllAppointments = data => {
  return axiosInstance.post(`v1/appointment/list`, data);
};

const getPaginatedAppointments = data => {
  return axiosInstance.post(`v1/appointment/list-by-page`, data);
};

const requestInsuranceCard = appointmentId => {
  return axiosInstance.get(`v1/appointment/request-insurance/${appointmentId}`);
};

const requestPatientToCall = appointmentId => {
  return axiosInstance.get(`v1/appointment/request-call/${appointmentId}`);
};

const changeAppointmentStatus = data => {
  return axiosInstance.put(`v1/appointment/status`, data);
};
const reschedulebyprovider = data => {
  return axiosInstance.put(`v1/appointment/reschedulebyprovider`, data);
};
const saveAvailability = data => {
  let updatedData = {};
  if (data.id) {
    if (data.isDelete) {
      // Delete availability
      updatedData = {
        id: data.id,
        isDeleteRepeat: data.isDeleteRepeat,
        startDatetime: localToUtcDateTimeFormat(data.startDatetime),
      };
      return axiosInstance.delete(`v1/schedule/provider-availability`, {
        data: updatedData,
      });
    } else {
      // Update availability
      updatedData = {
        isVirtual: data.isVirtual,
        isInPerson: data.isInPerson,
        practiceLocationId: data.practiceLocationId,
        refId: data.id,
        startDatetime: localToUtcDateTimeFormat(data.startDatetime),
        deleteDatetime: localToUtcDateTimeFormat(data.deleteDatetime),
        duration: data.duration,
        isAllowRepeat: false,
        endDays: data.endDays,
      };
      if (data.isAllowRecurringEdit) {
        updatedData.isAllowRepeat = true;
        updatedData.repeat = data.repeat;
        if (data.repeat === 'CUSTOM') {
          let customDayString = '';
          const reverseDays = _.invert(constants.DAYS);
          _.map(data.days, (d, index) => {
            if (d) {
              customDayString = customDayString + '-' + reverseDays[index];
            }
          });
          updatedData.repeat = updatedData.repeat + customDayString;
        }
      }
      return axiosInstance.put(`v1/schedule/provider-availability`, updatedData);
    }
  } else {
    // Create availability
    updatedData = {
      isVirtual: data.isVirtual,
      isInPerson: data.isInPerson,
      practiceLocationId: data.practiceLocationId,
      startDatetime: localToUtcDateTimeFormat(data.startDatetime),
      duration: data.duration,
      repeat: data.repeat,
      endDays: data.endDays,
    };
    // if custom,then create string like CUSTOM-"DAY"
    if (data.repeat === 'CUSTOM') {
      let customDayString = '';
      const reverseDays = _.invert(constants.DAYS);
      _.map(data.days, (d, index) => {
        if (d) {
          customDayString = customDayString + '-' + reverseDays[index];
        }
      });
      updatedData.repeat = updatedData.repeat + customDayString;
    }
    return axiosInstance.post(`v1/schedule/provider-availability`, updatedData);
  }
};

const addAvailabilityBreak = data => {
  return axiosInstance.put(`v1/schedule/provider-availability-break`, data);
};

const getFilteredDoctors = data => {
  // cancel same previous request
  const apiUrl = 'v1/search/providers';
  if (searchCalls[apiUrl]) {
    searchCalls[apiUrl].cancel('');
    delete searchCalls[apiUrl];
  }
  searchCalls[apiUrl] = axios.CancelToken.source();
  let config = {cancelToken: searchCalls[apiUrl].token};
  if (_.get(data, 'page', 1) !== 1) {
    config.noLoader = true;
  }
  return axiosInstance.post(apiUrl, data, config);
};

const getFilteredAvailabilityOfDoctor = (
  providerId,
  utcStartDateString,
  endDate,
  locationId,
  isVirtual = false,
) => {
  if (isVirtual) locationId = 0;
  return axiosInstance.get(
    `v1/seo/availability/day/${providerId}/${utcStartDateString}/${endDate}/${isVirtual}/${locationId}`,
  );
};

const getAppointmentForProviderBytId = appointmentId => {
  return axiosInstance.get(`v1/appointment/provider/${appointmentId}`);
};

export default {
  getAllAvailabilities,
  saveAvailability,
  getFilteredDoctors,
  getFilteredAvailabilityOfDoctor,
  getAllAppointments,
  getPaginatedAppointments,
  requestInsuranceCard,
  requestPatientToCall,
  changeAppointmentStatus,
  getAppointmentForProviderBytId,
  addAvailabilityBreak,
  reschedulebyprovider
};
