import React, {useState, useEffect} from 'react';
import images from 'data/images';
import _ from 'lodash';
import momentHelper from 'data/moment/momentHelper';
import subscriptionService from 'services/subscription';
import './AddingProviderSeatModal.css';
import config from 'config/config';

const {dateFormat} = momentHelper;
const {getSubscriptionDetails} = subscriptionService;

const AddingProviderSeatModal = props => {
  const {closeModal} = props;
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    if (config.showStripe == true) {
      getSubscription();
    }
  }, []);

  const getSubscription = async () => {
    const response = await getSubscriptionDetails();
    if (response) {
      const {data = {}} = response;
      setSubscriptionData(data);
    }
  };

  //const today = dateFormat(new Date().toString(), 'MMMM DD, YYYY');
  const nextBillingDate = subscriptionData?.nextBillingDate
    ? new Date(_.get(subscriptionData, 'nextBillingDate', 0) * 1000).toString()
    : null;
  const nextBillingDateLong = nextBillingDate ? dateFormat(nextBillingDate, 'MMMM DD, YYYY') : '';
  let isReconcil = false;
  if (subscriptionData && subscriptionData.nextBillingDate && subscriptionData.quantity) {
    if (subscriptionData.quantity != subscriptionData.numberOfProvider) {
      isReconcil = true;
    }
  }
  return (
    <div className="AddingProviderSeatModal">
      <div className="modal-content-header">
        <div className="h3">Adding Provider Seats</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <div className="provider-description">
          <div className="name">
            {_.get(props, 'data.firstName', '') + ' ' + _.get(props, 'data.lastName', '')}
          </div>
          <div className="email">
            <span>{_.get(props, 'data.email', '')}</span>
            <img src={images.close} onClick={closeModal} alt="close" />
          </div>
          {nextBillingDateLong && (
            <div className="description">
              {isReconcil ? (
                <>
                  You are adding <span>1 provider seat</span> to your existing subscription.
                </>
              ) : (
                <>
                  You are adding <span>1 provider seat</span> to your subscription at $
                  {_.get(subscriptionData, 'perSeatPrice', 299)}/month/seat.{' '}
                  {nextBillingDateLong
                    ? `A prorated charge will be immediately billed for this provider for the remainder of the current billing period and your ${nextBillingDateLong} invoice will reflect the updated subscription.`
                    : ''}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="modal-content-footer">
        <span>
          <button className="save" onClick={props.onAddClick}>
            Add to practice
          </button>
          <button className="cancel" onClick={closeModal}>
            Cancel
          </button>
        </span>
        <img src={images.stripe} alt="stripe" />
      </div>
    </div>
  );
};

export default AddingProviderSeatModal;
