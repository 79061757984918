import React, {useState, useMemo, useEffect, useCallback} from 'react';
import _ from 'lodash';
import Input from '../Input';
import './AutoSuggestionSelect.css';

const AutoSuggestionSelect = props => {
  const {
    labelIndex = 'label',
    valueIndex = 'value',
    value = '',
    onSelection = () => {},
    name = '',
    options = [],
    inputIcon = null,
    inputPlaceholder = 'Search pain condition',
    containerClass = '',
  } = props;
  let isDestroyed = false;
  const updatedOptions = useMemo(() => {
    return options.map(option => {
      return {
        label: option[labelIndex],
        value: option[valueIndex],
      };
    });
  }, [options, labelIndex, valueIndex]);
  const [isFocused, setFocus] = useState(false);
  const [config, setConfig] = useState(() => {
    let valObj = null;
    if (value) {
      valObj = _.find(updatedOptions, {value});
    }
    return {
      value: _.get(valObj, 'value', ''),
      text: _.get(valObj, 'label', ''),
    };
  });

  useEffect(() => {
    let valObj = null;
    if (value) {
      valObj = _.find(updatedOptions, {value});
      const configObj = {
        value: _.get(valObj, 'value', ''),
        text: _.get(valObj, 'label', ''),
      };
      setConfig({...config, ...configObj});
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isDestroyed = true;
    };
  }, [value, updatedOptions]);

  const handleChange = event => {
    const {value: textValue} = event.target;
    setConfig(prevState => {
      return {
        ...prevState,
        value: '',
        text: textValue,
      };
    });
    if (value) {
      onSelection({target: {name, value: ''}});
    }
  };

  const filteredOptions = updatedOptions.filter(op => {
    return (
      !config.value &&
      (config.text.length > 0 || isFocused) &&
      op.label.toLowerCase().includes(config.text.toLowerCase())
    );
  });

  const handleOptionSelection = option => {
    onSelection({target: {name, value: option.value}});
  };

  const handleFocusOut = () => {
    setConfig(prevState => {
      let updateObj = {};
      if (!prevState.value) {
        const valObj = _.find(updatedOptions, op => {
          return op.label.toLowerCase() === prevState.text.toLowerCase();
        });
        if (valObj) {
          updateObj.value = valObj.value;
          updateObj.text = valObj.label;
          onSelection({target: {name, value: updateObj.value}});
        } else {
          updateObj.text = '';
        }
      }
      return {
        ...prevState,
        ...updateObj,
      };
    });
  };

  const onBlur = useCallback(
    _.debounce(() => {
      if (!isDestroyed) {
        setFocus(false);
        handleFocusOut();
      }
      isDestroyed = false;
    }, 1000),
    [],
  );
  return (
    <div className={`AutoSuggestionSelect ${containerClass}`}>
      <Input
        type="text"
        placeholder={inputPlaceholder}
        value={config.text}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={onBlur}
        onChange={handleChange}
        icon={inputIcon}
      />
      {filteredOptions.length > 0 && (
        <div className="result">
          {filteredOptions.map(option => {
            return (
              <label
                className="option"
                onClick={()=>handleOptionSelection(option)}
                key={option.value}>
                {option.label}
              </label>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AutoSuggestionSelect;
