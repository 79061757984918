import React, {useState} from 'react';
import images from 'data/images';

import {Link, useHistory} from 'react-router-dom';

import './ListyourpracticeIntrobodyText.css';

import analyticsData from 'data/analytics/analyticsData';

const {Analytics} = analyticsData;

const ListyourpracticeIntrobodyText = props => {
  const history = useHistory();

  return (
    <div className="ListyourpracticeIntrobodyText">
      <div className="container">
        <div className="maintext">
          Book patients, manage your reputation, and grow your practice with one turn-key platform!
        </div>
        <div className="box">
          <div className="fst">
            <h2 className="headding">Better patients</h2>
            <p className="paragraph">
              Match with only the patients who are seeking your clinical expertise - minimizing
              unsatisfactory visits.
            </p>
          </div>
          <div className="sec">
            {' '}
            <h2 className="headding">Clearer data</h2>
            <p className="paragraph">
            Gain valuable insights into your practice’s performance with our all-in-one dashboard.
            </p>
          </div>
          <div className="thr">
            {' '}
            <h2 className="headding">Smarter scheduling</h2>
            <p className="paragraph">
            Automate your front office, and fill last minute openings with our integrated booking tools.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListyourpracticeIntrobodyText;
