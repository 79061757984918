import React from 'react';
import images from 'data/images';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import config from 'config/config';
import StripeInput from './StripeInput';
import './ChangeCardModal.css';

const ChangeCardModal = props => {
  const stripePromise = loadStripe(config.StripePublishKey);
  const {closeModal,cardLastDigit} = props;

  return (
    <div className="ChangeCardModal">
      <div className="modal-content-header">
        <div className="h3">{cardLastDigit?`Edit Payment Method`:`Add Payment Method`}</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <Elements stripe={stripePromise}>
        <StripeInput {...props} />
      </Elements>
    </div>
  );
};

export default ChangeCardModal;
