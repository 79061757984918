import React from 'react';
import './SwitchToggle.css';

const SwitchToggle = props => {
  const {
    label = '',
    children,
    containerClass = '',
    error = null,
    onChange = () => {},
    onBlur = () => {}, // eslint-disable-line no-unused-vars
    icon = null,
    afterIcon = null,
    showLabel=true,
    ...otherCheckboxProps
  } = props;
  let newContainerClass = containerClass;
  if (error) {
    newContainerClass += ' error';
  }
  otherCheckboxProps.defaultChecked = otherCheckboxProps.value;

  const changeHandler = event => {
    onChange({target: {name: event.target.name, value: Boolean(event.target.checked)}});
  };
  const uid=new Date().getUTCMilliseconds()+Math.random();
  return (
    <div className={`SwitchToggle ${newContainerClass}`}>
      <div className="switchContainer">
        {children}
        <input  id={`SwitchToggle${uid}`} type="checkbox" onChange={changeHandler} {...otherCheckboxProps} />
        <label htmlFor={`SwitchToggle${uid}`} className="slider round">
          {icon && <img src={icon} alt="toggle" />}
          {afterIcon && <img src={afterIcon} alt="toggle" />}
        </label>
        {/* {error && <span className="error-text">{error}</span>} */}
      </div>
      {showLabel&&(
      <div className="switch-label">{label}</div>
      )}
    </div>
  );
};

export default SwitchToggle;
