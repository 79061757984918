import React, {useState, useContext, useEffect} from 'react';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import allRegex from 'data/regex';
import validators from 'data/validators';
import config from 'config/config';
import './AddProviderToPractice.css';
import AddingProviderSeatModal from '../AddingProviderSeatModal';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import { isNull } from 'lodash';

const defaultFormValues = {firstName: '', lastName: '', email: ''};
const {nameRegex} = allRegex;
const {__required, __email, __maxLength,_scrollToView} = validators;
const {MAX_TEXT_LENGTH} = config;
const {modalFunctions} = modalConfig;
const {blankModalData,errorModalData} = modalFunctions;
const {Analytics} = analyticsData;

const AddProviderToPractice = props => {
  const {addProviderRef, emailRef, onInviteToBeProvider} = props;

  const [formData, setFormData] = useState({
    values: {...defaultFormValues},
    errors: {},
  });
  const [subscriptionData,setSubscriptionData] = useState(null);
  const {values: formValues, errors: formErrors} = formData;
  const rootContext = useContext(RootContext);
useEffect(()=>{
  setSubscriptionData(props.subscriptionData)
},[props.subscriptionData])
  // Validation functions
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'First name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'Last name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  const __validateForm = () => {
    const validFirstName = !__validateFirstName();
    const validLastName = !__validateLastName();
    const validEmail = !__validateEmail();
    return validFirstName && validLastName && validEmail;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      default:
        break;
    }
    // setFormData({
    //   ...formData,
    //   values: {
    //     ...formData.values,
    //     [name]: value,
    //   },
    //   errors: {
    //     ...formData.errors,
    //     ...errorObject,
    //   },
    // });
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,          
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const resetForm = () => {
    setFormData({
      values: {...defaultFormValues},
      errors: {},
    });
  };

  const handleForm = () => {
    if (!isValidForm) {
      let valError = [
        'firstName',
        'lastName',
        'email',
      
      ];
      valError.forEach(name => {
        changeHandler({target: {name: name, value:formValues[name]}});
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    Nux.sendEvent(
      Analytics.Category.DoctorSettingProvider,
      Analytics.Actions.Click,
      'inviteProvider',
    );
    onInviteToBeProvider(formValues, resetForm);
  };

  // ====| START: Hide Stripe Code |====

  const takeConfirmation = () => {
    const {setGlobal} = rootContext;
    if(subscriptionData.status!==1 || isNull(subscriptionData.nextBillingDate)){
      setGlobal('modal', errorModalData("This operation cannot be performed because the Provider currently does not have a subscription."));
      return null
    }
    
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: AddingProviderSeatModal,
        customComponentProps: {
          onAddClick: handleForm,
          data: formValues,
        },
        modalWrapperClass: 'mega-modal',
      }),
    );
  };
  // ====| END: Hide Stripe Code |====
  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  return (
    <div className="AddProviderToPractice" ref={addProviderRef}>
      <div className="h3">
        <span>Add a Provider to your Practice</span>
        <div className="para">
          Recipients will receive an email to complete signup and their information will be verified
          by the Kaly team{' '}
        </div>
      </div>
      <form className="add-provider-form">
        <div className="email-row">
          <Input
            ref={emailRef}
            {...getGeneralInputProps('email')}
            placeholder="Enter Email"
            containerClass="email"
          />
          <Input {...getGeneralInputProps('firstName')} placeholder="First Name" />
          <Input {...getGeneralInputProps('lastName')} placeholder="Last Name" />
        </div>
        <div className="action">
          {/* ====| START: Hide Stripe Code |==== */}
          {config.showStripe===true?(
          <CircleButton onClick={takeConfirmation} disabled={!isValidForm} btnType="whiteButton">
            Invite Provider
          </CircleButton>
          ):(
          <CircleButton onClick={handleForm}  btnType="whiteButton">
            Invite Provider
          </CircleButton>
  )}
          {/* ====| END: Hide Stripe Code |==== */}
        </div>
      </form>
    </div>
  );
};

export default AddProviderToPractice;
