import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import Checkbox from 'components/FormControls/Checkbox';
import selectOptions from 'data/selectOptions';
import validators from 'data/validators';
import config from 'config/config';
import allRegex from 'data/regex';
import DateManual from 'components/FormControls/DateManual';
import './PatientSignUpStepTwo.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import helper from 'data/helper';

const {nameRegex, phonenumberRegex} = allRegex;
const {
  __required,
  __maxLength,
  __validDate,
  __validDateofBirthDate,
  __validDateofBirthDatelessthan300,
  __phoneNumber,
} = validators;

const {formatPhoneNumberWithDash} = helper;
const {MAX_TEXT_LENGTH} = config;
const {patientGenderOptions} = selectOptions;
const {Analytics} = analyticsData;

const PatientSignUpStepTwo = props => {
  const {nextStep, data} = props;
  const values = {...data};
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'First name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (fValue.length < 2) {
      return `Min 2 characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'Last name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (fValue.length < 2) {
      return `Min 2 characters`;
    }
    return null;
  };
  const __validateGender = (value = null) => {
    const fValue = value !== null ? value : formValues['gender'];
    if (!__required(fValue)) {
      return 'Gender is required';
    }
    return null;
  };
  const __validateHasAcceptedTerms = (value = null) => {
    const fValue = value !== null ? value : formValues['hasAcceptedTerms'];
    if (fValue === false || !fValue) {
      return 'Plaese accept Terms';
    }
    return null;
  };
  const __validatePhoneNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['phonenumber'];

    if (!__required(fValue)) {
      return 'Phone number is required';
    }
    if (fValue && !__phoneNumber(fValue)) {
      return 'Invalid Phone number';
    }
    if (fValue && !phonenumberRegex.test(formatPhoneNumberWithDash(fValue))) {
      return 'Invalid Phone number';
    }

    if (fValue && formatPhoneNumberWithDash(fValue).length > 2) {
      if (checkSameNumber(formatPhoneNumberWithDash(fValue).substring(0, 3))) {
        return 'Invalid Phone number';
      }
    }
    return null;
  };
  const checkSameNumber = num => {
    if (num[0] === num[1] && num[0] === num[2]) return true;
    return false;
  };
  const __validateHasReadHipaa = (value = null) => {
    const fValue = value !== null ? value : formValues['hasReadHipaa'];
    if (fValue === false || !fValue) {
      return 'Plaese accept HIPAA Authorization';
    }
    return null;
  };
  const __validateDateOfBirth = (value = null) => {
    const fValue = value !== null ? value : formValues['dateOfBirth'];
    if (!__validDate(fValue)) {
      return 'Date of birth is invalid ';
    }
    if (!__validDateofBirthDate(fValue)) {
      return "Date of birth must be before today's date";
    }
    if (!__validDateofBirthDatelessthan300(fValue)) {
      return 'Check date of birth is valid';
    }
    return null;
  };
  const __validateForm = () => {
    const validFirstName = !__validateFirstName();
    const validLastName = !__validateLastName();
    const validatePhoneNumber = !__validatePhoneNumber();
    const validGender = !__validateGender();
    const validHasAcceptedTerms = !__validateHasAcceptedTerms();
    const validHasReadHipaa = !__validateHasReadHipaa();
    const validDateOfBirth = !__validateDateOfBirth();

    return (
      validFirstName &&
      validLastName &&
      validGender &&
      validHasAcceptedTerms &&
      validHasReadHipaa &&
      validDateOfBirth &&
      validatePhoneNumber
    );
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'phonenumber':
        errorObject = {
          [name]: __validatePhoneNumber(value),
        };
        break;
      case 'gender':
        errorObject = {
          [name]: __validateGender(value),
        };
        break;
      case 'hasAcceptedTerms':
        errorObject = {
          [name]: __validateHasAcceptedTerms(value),
        };
        break;
      case 'hasReadHipaa':
        errorObject = {
          [name]: __validateHasReadHipaa(value),
        };
        break;
      case 'dateOfBirth':
        errorObject = {
          [name]: __validateDateOfBirth(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleForm = () => {
    Nux.sendEvent(Analytics.Category.PatientSignUp, Analytics.Actions.Click, 'saveAccount');
    nextStep(formValues);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  return (
    <div className="PatientSignUpStepTwo">
      <form>
        <div className="form-group-2-colum">
          <Input {...getGeneralInputProps('firstName')} label="First Name" />
          <Input {...getGeneralInputProps('lastName')} label="Last Name" />
        </div>
        <DateManual label="Date of Birth" {...getGeneralInputProps('dateOfBirth')} />
        <Input {...getGeneralInputProps('phonenumber')} label="Phone Number" />
        <Select
          {...getGeneralInputProps('gender')}
          defaultOptionProp={{value: '', label: 'Select Gender'}}
          options={patientGenderOptions}
          label="Gender"
        />
        <div className="checkbox-block privacy">
          <Checkbox {...getGeneralInputProps('hasAcceptedTerms')}></Checkbox>I have read and accept
          Kaly{' '}
          <Link to="/terms" target="_blank">
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link to="/privacy-policy" target="_blank">
            Privacy Policy.
          </Link>
        </div>
        <div className="checkbox-block">
          <Checkbox {...getGeneralInputProps('hasReadHipaa')}></Checkbox>I have read and accept Kaly{' '}
          <Link to="/hipaa-policy" target="_blank">
            HIPAA Authorization
          </Link>
        </div>
        <div className="login-btn">
          <CircleButton disabled={!isValidForm} onClick={handleForm}>
            Save and Continue
          </CircleButton>
        </div>
      </form>
    </div>
  );
};

export default PatientSignUpStepTwo;
