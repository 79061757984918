import React, { Component } from 'react';
import images from 'data/images';
import Select from 'components/FormControls/Select';
import Input from 'components/FormControls/Input';
import ContactSourceList from './ContactSourceList';
import './RequestContact.css';
import modalConfig from 'modals/modalConfig';
import reviewService from 'services/review';
import RootContext from 'context/RootContext';
import AddEditContact from 'components/AfterAuth/ConnectSocial/SendReviewRequest/addEditContact';
import ReactPaginate from 'react-paginate';
import AuthContext from 'context/AuthContext';
import { RequestContactLocation } from './RequestContactLocation';
const { modalFunctions } = modalConfig;
const { errorModalData, successModalData, blankModalData } = modalFunctions;
const { getContacts, sendReviewRequest } = reviewService;
const initialState = {
  contactList: [],
  activePage: 0,
  totalPages: 0,
  pageSize: 20,
  totalCount: null
};

class RequestContact extends Component {
  constructor(){
    super();
    window.RequestContactComponent = this;
}
  static contextType = AuthContext;
  rootContext = null;
  state = { ...initialState, viewUserId: 0 };
  // selectOptions = {
  //   patientsTypeOptions: [
  //     {value: 1, label: 'permission 1'},
  //     {value: 2, label: 'permission 2'},
  //     {value: 3, label: 'permission 3'},
  //   ],
  // };

  componentDidMount = () => {
    this.getContactList();
    const { auth } = this.context;
    this.setState({ viewUserId: auth.viewUserId })
  };

  componentDidUpdate(prevProps) {
    const { auth } = this.context;
    if (this.state.viewUserId !== auth.viewUserId) {
      this.setState({ viewUserId: auth.viewUserId }, () => {
       // this.getContactList();
      })

    }

  }
  
  getContactList = async () => {
    let { activePage, pageSize } = this.state
    let args = {
      page: activePage,
      size: pageSize
    }
    const response = await getContacts(args).catch(this.handleError);
    if (response.data) {
      let { data } = response

      this.setState({ contactList: data.contactList, totalPages: data.totalPage, totalCount: data.totalRecord });
    }
  };

  handleError = error => {
    const { setGlobal } = this.rootContext;
    const { message } = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  clickAddEditContact = (type, dataObj = null) => {
    const { setGlobal } = this.rootContext;
    setGlobal('modal', blankModalData({
      CustomComponent: AddEditContact,
      customComponentProps: { type: type, dataObj: dataObj, getContactList: this.getContactList },
    }));
  }
  sendRequest = async data => {
    const { setGlobal } = this.rootContext;
    let deleteConfirm = await RequestContactLocation({
      context: this.rootContext,
      passData:data
     // text: `Are you sure you want to disconnect your Google My Business Account?  This action cannot be undone.  You can choose to keep Google My Business Reviews in your profile or to remove them.`,
    });
    if (!deleteConfirm) return false;
    data.reviewSourceLocationId=deleteConfirm.reviewSourceLocationId
    data.sendEmail=deleteConfirm.sendEmail
    data.sendSms=deleteConfirm.sendSms
    data.providerContactId=data.contact.id
    const response = await sendReviewRequest(data).catch(this.handleError);
    if (response && response.code=='success') {
      setGlobal('modal', successModalData('Your request has been sent successfully'));
    }
  };
  handlePageChange = data => {
    this.setState({ activePage: data.selected }, this.getContactList);
  };
  render() {
    const { contactList, totalPages, activePage, totalCount } = this.state;
    return (
      <div>
        <RootContext.Consumer>
          {context => {
            this.rootContext = context;
            return null;
          }}
        </RootContext.Consumer>
        <div className="RequestContact">
          <div className="heading">
            <div className="title">{totalCount==null?'':totalCount==0?'No Contacts':`${totalCount} Contacts`}</div>
            <span className="right-panel">
              {/* <Input
                icon={images.search}
                placeholder="Search Contacts"
                containerClass="search-contact"
              /> */}
              {/* <Select
                options={this.selectOptions.patientsTypeOptions}
                arrowCss={{backgroundImage: `url(${images.angleDown})`}}
              /> */}
              <button onClick={() => this.clickAddEditContact('Add')} className="addwebsite-btn">Add Contacts</button>
            </span>
          </div>
          <div className="ConnectReviews-content">
            <ContactSourceList clickAddEditContact={(type, obj) => this.clickAddEditContact(type, obj)} contactData={contactList} totalCount={totalCount} getContactList={this.getContactList} sendContactRequest={this.sendRequest} />
          </div>
          {totalPages > 0 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={activePage}
            />
          )}
        </div>
      </div>
    );
  }
}

export default RequestContact;
