import React, {useState, useEffect} from 'react';
import axiosInstance from 'config/axios';
import './Loader.css';

const Loader = () => {
  const [ajaxCallCount, setAjaxCallCount] = useState(0);

  useEffect(() => {
    let isDestroyed = false;
    let reqInterceptor = null;
    let resInterceptor = null;
    reqInterceptor = axiosInstance.interceptors.request.use(request => {
      if (!isDestroyed && !request.noLoader) {
        handleAxiosCallCount(1);
      }
      return request;
    });
    resInterceptor = axiosInstance.interceptors.response.use(
      response => {
        if (!isDestroyed && !response.noLoader) {
          handleAxiosCallCount(-1);
        }
        return response;
      },
      error => {
        if (!isDestroyed && !error.noLoader) {
          handleAxiosCallCount(-1);
        }
        return Promise.reject(error);
      },
    );
    return () => {
      isDestroyed = true;
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.request.eject(resInterceptor);
    };
  }, []);

  const handleAxiosCallCount = number => {
    setAjaxCallCount(prevCount => {
      let nextCount = prevCount + number;
      if (nextCount < 0) {
        nextCount = 0;
      }
      return nextCount;
    });
  };

  if (ajaxCallCount === 0) {
    return null;
  }
  return <div className="Loader">Loader</div>;
};

export default Loader;
