import React from 'react';
import './Checkbox.css';

const Checkbox = props => {
  const {
    children,
    containerClass = '',
    error = null,
    onChange = () => {},
    onBlur = () => {}, // eslint-disable-line no-unused-vars
    childClass = '',
    ...otherCheckboxProps
  } = props;
  let newContainerClass = containerClass;
  if (error) {
    newContainerClass += ' error';
  }
  otherCheckboxProps.checked = otherCheckboxProps.value || false;

  const changeHandler = event => {
    onChange({target: {name: event.target.name, value: Boolean(event.target.checked)}});
  };
  var timestamp =Date.now() + Math.random()
  return (
    <div className={`Checkbox-control ${newContainerClass}`}>
      <input id={timestamp} type="checkbox" onChange={changeHandler} {...otherCheckboxProps} />
      <span className={["checkmark",otherCheckboxProps.disabled?'is-disabled':''].join(' ')}></span>
      <label htmlFor={timestamp} className={`Checkbox-control-label ${childClass}`}>{children}</label>
      {/* {error && <span className="error-text">{error}</span>} */}
    </div>
  );
};

export default Checkbox;
