import React, {useState, useContext} from 'react';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import validators from 'data/validators';
import images from 'data/images';
import './EditSuffix.css';
import adminService from 'services/admin';
import modalConfig from 'modals/modalConfig';
import _ from 'lodash';
import SwitchToggle from 'components/FormControls/SwitchToggle';

const {__required} = validators;
const {updateSuffix, createSuffix} = adminService;
const {modalFunctions} = modalConfig;
const {successModalData,errorModalData} = modalFunctions;

const EditSuffix = props => {
  const {data} = props;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const suffixId = _.get(data, 'id', 0);

  const [formData, setFormData] = useState({
    values: {
      suffix: _.get(data, 'suffix', ''),
      status: _.get(data, 'status', 1) === 1,
    },
    errors: {serverError: null},
  });
  const {values: formValues, errors: formErrors} = formData;

  const __validateSuffix = (value = null) => {
    const fValue = value !== null ? value : formValues['suffix'];
    if (!__required(fValue)) {
      return 'Suffix is required';
    }
    return null;
  };

  const changeHandler = event => {
    let {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'suffix':
        errorObject = {
          [name]: __validateSuffix(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const closeModal = () => {
    setGlobal('modal', {showModal: false});
  };

  const __validateForm = () => {
    const validateSuffix = !__validateSuffix();
    return validateSuffix;
  };

  const isValidateForm = __validateForm();

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="Close" title="Close" />
    </span>
  );

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">
        {suffixId > 0 ? `Update Suffix : ${_.get(data, 'suffix', '')}` : 'Add New Suffix'}
      </div>
      {crossIconComponent}
    </div>
  );

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const {updateList} = props;
    let response = null;
    let reqObj = {
      suffix: formValues['suffix'],
      status: formValues['status'] ? 1 : 0,
    };
    if (suffixId > 0) {
      response = await updateSuffix(suffixId, reqObj).catch(handleError);
    } else {
      response = await createSuffix(reqObj).catch(handleError);
    }
    if (response) {
      const {message} = response;
      setGlobal('modal', successModalData(message));
      updateList();
    }
  };

  const renderInputElements = () => {
    return (
      <>
        <Input {...getGeneralInputProps('suffix')} placeholder="Enter Suffix" label="Suffix" />
        <SwitchToggle {...getGeneralInputProps('status')} label="Status" />
      </>
    );
  };

  return (
    <div className="UpdateSuffixModal no-select">
      <div className="modal-body-wrapper">
        {dialogHeader}
        <div className="dailog-body">
          <form onSubmit={handleSubmit}>{renderInputElements()}</form>
        </div>
        <div className="action-btn">
          <CircleButton onClick={closeModal} btnType="secondaryButton">
            Cancel
          </CircleButton>
          <CircleButton btnType="whiteButton" disabled={!isValidateForm} onClick={handleSubmit}>
            {suffixId > 0 ? 'Update' : 'Add'}
          </CircleButton>
        </div>
      </div>
    </div>
  );
};

export default EditSuffix;
