import React, {Component} from 'react';
import Header from 'layout/Header';
import './HIPAAPolicy.css';

class HIPAAPolicy extends Component {
  render() {
    return (
      <>
        <Header hideHeaderButtons={true}></Header>
        <div className="HIPAAPolicy">
          <div className="container">
            <div className="template-inner">
              <div className="main-header">KALY HIPAA BUSINESS ASSOCIATE PRIVACY POLICY</div>
              <div className="template-content">
                <div className="sub-header-title">Effective Date: October 22, 2021</div>
                <div className="para">
                  You are a valued customer of Kaly Inc. (“Kalyp,” “us” or “we”) and we take
                  your privacy very seriously. As outlined in our&nbsp;
                  <a href="https://sandbox.kaly.com/privacy-policy">Privacy Policy</a>, certain
                  medical, health and/or health-related information that Kaly collects about you
                  as part of providing services to facilitate your search for healthcare providers
                  and to book medical appointments (collectively, the “Services”) to you may be
                  considered “protected health information” or “PHI” under the Health Insurance
                  Portability and Accountability Act (“HIPAA”).
                </div>
                <div className="para">
                  We share a commitment with Covered Entities to protect the privacy and
                  confidentiality of Protected Health Information (PHI) that we obtain subject to
                  the terms of a Business Associate Agreement.
                </div>
                <div className="para">
                  This policy is provided to help you better understand how we use, disclose, and
                  protect PHI in accordance with the terms of Business Associate Agreements.
                </div>
                <div className="para text-bold">DEFINITIONS</div>
                <div className="para">
                  <ul>
                    <li>
                      <i>Business Associate Agreement (the “BA Agreement”).</i> A formal written
                      contract between Kaly and a Covered Entity that requires Kaly to
                      comply with specific requirements related to PHI.
                    </li>
                    <li>
                      <i>Covered Entity.</i> A health plan, healthcare provider, or healthcare
                      clearinghouse that must comply with the HIPAA Privacy Rule.
                    </li>
                    <li>
                      <i>Protected Health Information (PHI). </i>PHI includes all “individually
                      identifiable health information” that is transmitted or maintained in any form
                      or medium by a Covered Entity. Individually identifiable health information is
                      any information that can be used to identify an individual and that was
                      created, used, or disclosed in (a) the course of providing a health care
                      service such as diagnosis or treatment, or (b) in relation to the payment for
                      the provision of health care services.
                    </li>
                  </ul>
                </div>
                <div className="para text-bold">Use and Disclosure of PHI</div>
                <div className="para">
                  We may use PHI for our management, administration, data aggregation and legal
                  obligations to the extent such use of PHI is permitted or required by the BA
                  Agreement and not prohibited by law. We may use or disclose PHI on behalf of, or
                  to provide services to, Covered Entities for purposes of fulfilling our service
                  obligations to them, if such use or disclosure of PHI is permitted or required by
                  the BA Agreement and would not violate the
                  <i>
                    Standards for Privacy of Individually Identifiable Health Information (the
                    Privacy Rule).
                  </i>
                </div>
                <div className="para">
                  In the event that PHI must be disclosed to a subcontractor or agent, we will
                  ensure that the subcontractor or agent agrees to abide by the same restrictions
                  and conditions that apply to us under the BA Agreement with respect to PHI,
                  including the implementation of reasonable and appropriate safeguards.
                </div>
                <div className="para">
                  We may also use PHI to report violations of law to appropriate federal and state
                  authorities.
                </div>
                <div className="para text-bold">Safeguards</div>
                <div className="para">
                  We use appropriate safeguards to prevent the use or disclosure of PHI other than
                  as provided for in the BA Agreement. We have implemented administrative, physical,
                  and technical safeguards that reasonably and appropriately protect the
                  confidentiality, integrity, and availability of the electronic protected health
                  information that we create, receive, maintain, or transmit on behalf of a Covered
                  Entity. Such safeguards include:
                  <ul>
                    <li>
                      Maintaining appropriate clearance procedures and providing supervision to
                      assure that our workforce follows appropriate security procedures;
                    </li>
                    <li>
                      Providing appropriate training for our staff to assure that our staff complies
                      with our security policies;
                    </li>
                    <li>
                      Making use of appropriate encryption when transmitting PHI over the Internet;
                    </li>
                    <li>
                      Utilizing appropriate storage, backup, disposal and reuse procedures to
                      protect PHI;
                    </li>
                    <li>
                      Utilizing appropriate authentication and access controls to safeguard PHI;
                    </li>
                    <li>
                      Utilizing appropriate security incident procedures and providing training to
                      our staff sufficient to detect and analyze security incidents; and
                    </li>
                    <li>
                      Maintaining a current contingency plan and emergency access plan in case of an
                      emergency to assure that the PHI we hold on behalf of a Covered Entity is
                      available when needed.
                    </li>
                  </ul>
                </div>
                <div className="para text-bold">Mitigation of Harm</div>
                <div className="para">
                  In the event of a use or disclosure of PHI that is in violation of the
                  requirements of the BA Agreement, we will mitigate, to the extent practicable, any
                  harmful effect resulting from the violation. Such mitigation will include:
                  <ul>
                    <li>
                      Reporting any use or disclosure of PHI not provided for by the BA Agreement
                      and any security incident of which we become aware to the Covered Entity; and
                    </li>
                    <li>
                      Documenting such disclosures of PHI and information related to such
                      disclosures as would be required for Covered Entity to respond to a request
                      for an accounting of disclosure of PHI in accordance with HIPAA.
                    </li>
                  </ul>
                </div>
                <div className="para text-bold">Access to PHI</div>
                <div className="para">
                  As provided in the BA Agreement, we will make available to Covered Entities,
                  information necessary for Covered Entity to give individuals their rights of
                  access, amendment, and accounting in accordance with HIPAA regulations.
                </div>
                <div className="para">
                  Upon request, we will make our internal practices, books, and records including
                  policies and procedures, relating to the use and disclosure of PHI received from,
                  or created or received by us on behalf of a Covered Entity available to the
                  Covered Entity or the Secretary of the U.S. Department of Health and Human
                  Services for the purpose of determining compliance with the terms of the BA
                  Agreement and HIPAA regulations.
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HIPAAPolicy;
