const questionList = [
  {
    id: 221,
    question: 'Do you have any of these symptoms?',
    optionType: 1, // {1=>'single line options',2=>'select option'} // other will be default UI
    descriptions: [
      'Fever greater than 100.3°F',
      'Have you had recent severe traumatic injury and is it impossible for you to weight bear on affected leg.',
    ],
    options: [
      {nextQuestionId: 226, label: 'Yes', shortLabel: 'Red flags? Yes'},
      {nextQuestionId: 222, label: 'No', shortLabel: 'Red flags? No'},
    ],
  },
  {
    id: 222,
    question: 'Are you interested in surgery?',
    descriptions: [],
    options: [
      {groupId: 'K', label: 'Yes, in the near future', shortLabel: 'Interested in surgery'},
      {nextQuestionId: 223, label: 'Not at this time', shortLabel: 'No surgery'},
    ],
  },
  {
    id: 223,
    question: 'How long have you had pain for?',
    descriptions: [],
    options: [
      {nextQuestionId: 224, label: 'Less than 2 weeks', shortLabel: '<2 weeks'},
      {nextQuestionId: 225, label: 'More than 2 weeks', shortLabel: '>2 weeks'},
    ],
  },
  {
    id: 224,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'L',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching, and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'E',
        label: 'Preliminary imaging studies (Xray) or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'M',
        label: 'A minimally invasive procedure such as an injection.',
        shortLabel: 'Open to invasive procedures',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'E',
        label: 'I am not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
        showKalyMd: true,
      },
    ],
  },
  {
    id: 225,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'E',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching, and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'M',
        label: 'Diagnostic imaging studies (MRI/Xray) or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'M',
        label: 'A minimally invasive procedure such as an injection.',
        shortLabel: 'Open to invasive procedures',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'M',
        label: 'I am not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
      },
    ],
  },
  {
    id: 226,
    question: 
      'You may have a condition that requires immediate medical attention, such as seeking care at an Emergency Room or Urgent Care Center. Kaly does not diagnose conditions and is not meant to be a replacement for a trained medical professional.',
    optionType: 1,
    descriptions: [
      'Would you still like to see doctors who may help you after you seek urgent care?',
    ],
    options: [
      {nextQuestionId: 222, label: 'Yes'},
      {groupId: 'A', label: 'No'},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 221,
    treeDepth: 5,
  },
};
