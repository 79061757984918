import React, { useState, useEffect, useCallback, useContext, Fragment } from 'react';
import _ from 'lodash';
import Input from '../Input';
import RootContext from 'context/RootContext';
import masterService from 'services/master';
import selectOptions from 'data/selectOptions';
import images from 'data/images';
import './InsuranceFilterSelect.css';
var debounce = require('lodash/debounce');
const { getAllInsurancePlan, getAllInsurancePlanPaginate } = masterService;
const { insuranceDefaultOption } = selectOptions;

const InsuranceFilterSelect = props => {
  const {
    containerClass = '',
    inputPlaceholder = '',
    inputIcon = null,
    insurance,
    name = '',
    onChange = () => { },
  } = props;
  const limit = 10
  //const rootContext = useContext(RootContext);
  // const {insurancePlans, setGlobal} = rootContext;
  const [insuranceList, setInsuranceList] = useState([])
  const [topCompanys, setTopCompanys] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [isFocused, setFocus] = useState(false);
  const [insuranceLoading, setInsuranceLoading] = useState(true);

  useEffect(() => {
    // const getAndSetInsurancePlans = async () => {
    //   setInsuranceLoading(true);
    //   if (!insurancePlans) {

    //   }
    //   setInsuranceLoading(false);
    // };
    // getAndSetInsurancePlans();

    apiCall(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function apiCall(pageNo = 1, text = '') {
    setInsuranceLoading(true);
    setCurrentPage(pageNo)
    let textVal=text||searchText
    const insurancePlansResponse = await getAllInsurancePlanPaginate(true, { "search": textVal, "page": pageNo, "limit": limit, "sortBy": "companyName", "orderBy": "asc", "status": null }).catch(() => { setInsuranceLoading(false); });
    if (insurancePlansResponse) {
      const { data } = insurancePlansResponse;
      let { totalPlanCount, topCompany, planList } = data
      setTopCompanys(topCompany)
      let ISTop = [...insuranceList]
      planList.map((item) => {


        if (!ISTop.find((company) => company.companyId === item.companyId && company.isLabel == true)) {
          ISTop.push({
            Name: item.companyName,
            isLabel: true,
            companyId: item.companyId
          });
        }
        if (!ISTop.find((company) => company.id === item.id && company.isPlan == true)) {
          ISTop.push({
            id: item.id,
            Name: item.plan,
            isPlan: true,
            companyName: item.companyName,
            companyId: item.companyId
          });

        }
        return item
      })

      setInsuranceList(ISTop)
      let TOTALPAGES = Math.ceil(totalPlanCount / limit);
      setTotalPages(TOTALPAGES)
    }
    setInsuranceLoading(false);
  }
  useEffect(() => {
    if (insuranceList && insurance) {
      if (insurance.companyId) {

        let selectedCompany = insuranceList.find((item) => item.companyId === insurance.companyId);
        // instrancePlanList.forEach(company => {
        //   if (company.id === insurance.companyId) {
        //     selectedCompany = {...company};
        //   }
        // });
        if (!selectedCompany) {
          selectedCompany = topCompanys.find((item) => item.id == insurance.companyId)
          selectedCompany.Name = selectedCompany.companyName
        }

        if (selectedCompany) {
          setSearchText(`${selectedCompany.Name}`);
        }
      } else if (insurance.planId) {

        let plan = insuranceList.find((plan) => plan.id === insurance.planId)
        // instrancePlanList.forEach(company => {
        //   company.insurancePlans.forEach(p => {
        //     if (parseInt(p.id) === parseInt(insurance.planId)) {
        //       plan = {...p};
        //     }
        //   });
        //   if (plan && !plan.companyName) {
        //     plan.companyName = company.companyName;
        //   }
        // });

        if (plan) {
          setSearchText(`${plan.companyName}-${plan.Name}`);
        }
      } else {
        setSearchText(insuranceDefaultOption.label);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurance, insuranceList, topCompanys]);

  //const instrancePlanList = insurancePlans || [];

  // let companies = [];
  // _.cloneDeep(instrancePlanList).forEach(insurance => {
  //   const {id, companyName, isTop = true} = insurance;
  //   if (
  //     isTop &&
  //     isFocused &&
  //     (companyName.toLowerCase().includes(searchText.toLowerCase()) || searchText === '')
  //   ) {
  //     companies.push({
  //       id,
  //       companyName,
  //     });
  //   }
  // });

  // let filteredInstrancePlanList = _.filter(_.cloneDeep(instrancePlanList), company => {
  //   if (!isFocused) {
  //     return false;
  //   }
  //   const isShowInsurance = company.companyName.toLowerCase().includes(searchText.toLowerCase());
  //   if (isShowInsurance) {
  //     return true;
  //   }
  //   company.insurancePlans = _.filter(company.insurancePlans, insurancePlan => {
  //     const isShowInsurancePlan = insurancePlan.plan
  //       .toLowerCase()
  //       .includes(searchText.toLowerCase());
  //     return isShowInsurancePlan;
  //   });
  //   if (company.insurancePlans.length > 0) {
  //     return true;
  //   }
  // });

  const handleChange = event => {
    const { value: textValue } = event.target;
    setSearchText(textValue);
    searchInsurance(textValue)
    if (insurance) {
      onChange({ target: { name, value: null } });
    }
  };

  const onBlur = useCallback(() => {
    setFocus(false);
  }, []);

  // if (insuranceList.length===0) {
  //   return null;
  // }

  const handleCompanySelection = company => {
    setFocus(false);
    onChange({ target: { name, value: { companyId: company.id } } });
  };

  const handlePlanSelection = plan => {
    setFocus(false);
    onChange({ target: { name, value: { planId: plan.id } } });
  };
  const searchInsurance = useCallback(
    debounce((value) => {

      if (value.length > 0 && value.length >= 2)
        apiCall(1, value)
      else {
        apiCall(1)
      }
      setInsuranceList([])
    }, 500),
    [],
  );
  const handleScroll = (e) => {

    const bottom = (e.target.scrollHeight - e.target.scrollTop - 10) <= e.target.clientHeight;

    if (bottom) {
      if (totalPages > 0) {
        if (currentPage + 1 <= totalPages)
          apiCall(currentPage + 1)

      }
    }
  }

  return (
    <div onScroll={handleScroll} className={`InsuranceFilterSelect ${isFocused ? 'active' : ''} ${containerClass}`}>
      <Input
        type="text"
        placeholder={inputPlaceholder}
        value={searchText}
        onFocus={() => {
          setFocus(true);
        }}
        onChange={handleChange}
        icon={inputIcon}
        title={searchText}
      />
      {isFocused && <div className="overlay" onClick={onBlur}></div>}
      {isFocused && (
        <div className="result">
          {insuranceList.length > 0 ? (
            <div className="result1">
              <div
                title={insuranceDefaultOption.label}
                className="option plan"
                onClick={() => handlePlanSelection({ id: null })}>
                {insuranceDefaultOption.label}
              </div>
              {topCompanys.length > 0 && searchText === '' && (
                <>
                  <div className="option company">Top Carriers</div>
                  {topCompanys.map(company => {
                    return (
                      <div
                        title={company.companyName}
                        className="option plan"
                        onClick={() => handleCompanySelection(company)}
                        key={`${company.id}`}>
                        {company.companyName}
                      </div>
                    );
                  })}
                </>
              )}
              {insuranceList.map(company => {
                return (
                  <Fragment key={company.id}>
                    {company.isLabel ? (
                      <div className="option company">{company.Name}</div>
                    ) : (


                      <div
                        className="option plan"
                        title={company.Name}
                        onClick={() => handlePlanSelection(company)}
                        key={`${company.companyId}-${company.id}`}>
                        {company.Name}
                      </div>
                    )}

                  </Fragment>
                );
              })}

            </div>
          ) : (
            <Fragment>
              {!insuranceLoading && insuranceList.length === 0 && (
                <div className="result1">
                  <div
                    className="option plan"
                  >
                    No match found
                  </div>
                </div>
              )}
            </Fragment>
          )}
          {isFocused && insuranceList.length > 0 && insuranceLoading && (
            <div className="transparent-loader">
              <div className="loader">
                <span>
                  <img src={images.logosymbol} alt="logo symbol" />
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {isFocused && insuranceLoading && insuranceList.length === 0 && (
        <div className="result">
          <div className="loader">
            <span>
              <img src={images.logosymbol} alt="logo symbol" />
            </span>
          </div>
        </div>
      )}

    </div>
  );
};

export default InsuranceFilterSelect;
