import React from 'react';
import PatientHeader from './PatientHeader';
import CustomFooter from 'layout/CustomFooter';

const PatientLayout = props => {
  const {
    children,
    hideHeader = false,
    showMyKalypsys = true,
    hideFooter = false,
    hideHeaderButtons = true,
    planeHeader = false,
  } = props;
  return (
    <div>
      {!hideHeader && (
        <PatientHeader
          planeHeader={planeHeader}
          showMyKalypsys={showMyKalypsys}
          hideHeaderButtons={hideHeaderButtons}
        />
      )}
      {children}
      {!hideFooter && <CustomFooter />}
    </div>
  );
};

export default PatientLayout;
