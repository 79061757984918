import React, {useContext, useEffect, useState} from 'react';
import images from 'data/images';
import ProgressBar from 'components/FormControls/ProgressBar';
import Overlay from 'components/Overlay';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import './ProviderItem.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import {RemoveStripeProvider} from './RemoveStripeProvider';
import {RemoveStripeSubscription} from './RemoveStripeSubscription';
import {StripeInactivateProvider} from './StripeInactivateProvider';
import config from 'config/config';
import subscriptionService from 'services/subscription';
import momentHelper from 'data/moment/momentHelper';
import _, {isNull} from 'lodash';
const {dateFormat} = momentHelper;
const {modalFunctions} = modalConfig;
const {confirmModalData, errorModalData} = modalFunctions;
const {Analytics} = analyticsData;
const {getSubscriptionDetails} = subscriptionService;
const ProviderItem = props => {
  const {data, onSelectProvider = () => {}, onProviderStatusChange, onDeleteProvider} = props;
  const {
    providerId,
    fullName,
    status,
    roleName,
    isProfileCompleted,
    image,
    isHideProfileCompleteSection,
    isAccountOwner,
    profileCompleted,
  } = data;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const [subscriptionData] = useState(props.subscriptionData ? props.subscriptionData : null);
  let statusObj = {
    statusName: 'Pending',
    statusClass: 'pending',
  };
  if (status === 1) {
    statusObj = {
      statusName: 'Active',
      statusClass: 'active',
    };
  } else if (status === 2) {
    statusObj = {
      statusName: 'Inactive',
      statusClass: 'inactive',
    };
  }

  const profileCompleteObject = {
    label: isProfileCompleted ? 'Profile Completion' : 'Profile Incomplete',
    className: profileCompleted > 95 ? 'active' : profileCompleted > 50 ? 'pending' : 'inactive',
    percentage: profileCompleted, // ? 90 : 30,
  };
  const [active, toggleActive] = useState(false);
  const handleStatusChange = async () => {
    Nux.sendEvent(
      Analytics.Category.DoctorSettingProvider,
      Analytics.Actions.Click,
      'providerStatusChange',
    );

    if (config.showStripe === true) {
      if (subscriptionData.status !== 1 || isNull(subscriptionData.nextBillingDate)) {
        setGlobal(
          'modal',
          errorModalData(
            'This operation cannot be performed because the Provider currently does not have a subscription.',
          ),
        );
        return null;
      }
      if (status === 1) {
        let inactiveUser = await StripeInactivateProvider({context: rootContext});
        if (inactiveUser) {
          let removestripe = await RemoveStripeSubscription({context: rootContext});
          let passData = {providerId, status: status === 1 ? 0 : 1};
          if (removestripe) passData.subscription = true;
          toggleActive(!active);
          onProviderStatusChange(passData);
        }
      } else {
        if (config.showStripe == true) {
          const response = await getSubscriptionDetails();
          if (response) {
            const {data = {}} = response;
            const nextBillingDate = data?.nextBillingDate
              ? new Date(_.get(data, 'nextBillingDate', 0) * 1000).toString()
              : null;
            const nextBillingDateLong = nextBillingDate
              ? dateFormat(nextBillingDate, 'MMMM DD, YYYY')
              : '';
            let perSeatPrice = _.get(data, 'perSeatPrice', 0);
            let msg = `You are adding 1 provider seat to your subscription at $${perSeatPrice}/month/seat.  A prorated charge will be immediately billed for this provider for the remainder of the current billing period and your ${nextBillingDateLong} invoice will reflect the updated subscription.`;
            if (
              data &&
              data.quantity &&
              data.numberOfProvider &&
              data.quantity != data.numberOfProvider
            ) {
              msg = (
                <span>
                  You are adding <b>1 provider seat</b> to your existing subscription.
                </span>
              );
            }
            setGlobal(
              'modal',
              confirmModalData({
                confirmMessage: msg,
                onConfirm: isConfirmed => {
                  if (isConfirmed) {
                    toggleActive(!active);
                    onProviderStatusChange({providerId, status: status === 1 ? 0 : 1});
                  }
                },
              }),
            );
          }
        }
      }
    } else {
      toggleActive(!active);
      onProviderStatusChange({providerId, status: status === 1 ? 0 : 1});
    }
  };

  const confirmRemove = async () => {
    Nux.sendEvent(
      Analytics.Category.DoctorSettingProvider,
      Analytics.Actions.Click,
      'removeProvider',
    );

    if (config.showStripe === true) {
      if (subscriptionData.status !== 1 || isNull(subscriptionData.nextBillingDate)) {
        setGlobal(
          'modal',
          errorModalData(
            'This operation cannot be performed because the Provider currently does not have a subscription.',
          ),
        );
        return null;
      }

      let removeProv = await RemoveStripeProvider({context: rootContext});
      if (removeProv) {
        let removestripe = await RemoveStripeSubscription({context: rootContext});
        onDeleteProvider(providerId, removestripe ? true : '');
      }
    } else {
      setGlobal(
        'modal',
        confirmModalData({
          confirmMessage: `Are you sure you want to delete provider?`,
          onConfirm: isConfirmed => {
            if (isConfirmed) {
              onDeleteProvider(providerId);
            }
          },
        }),
      );
    }
  };

  return (
    <div className="ProviderItem">
      <div className="provider-inner-content" onClick={onSelectProvider}>
        <div className="provider-image">
          <img src={image} alt="" />
        </div>
        <div className="member-detail">
          <span className="name">{fullName}</span>
          <div className="status">
            <div className={`status-text ${statusObj.statusClass}`}>{statusObj.statusName}</div> .{' '}
            {roleName}
          </div>
        </div>
        {!isHideProfileCompleteSection && (
          <div className="member-rating">
            <div
              className={`profile-progress incomplete-profile ${profileCompleteObject.className}`}>
              <span className="name">{profileCompleteObject.label}</span>
              <span className="progressbar">
                <ProgressBar percentage={profileCompleteObject.percentage}></ProgressBar>
              </span>
              <span className="number">{profileCompleteObject.percentage}%</span>
            </div>
          </div>
        )}
      </div>
      <Overlay className="overlay" show={active} onClick={() => toggleActive(!active)} />
      {!isAccountOwner && !isHideProfileCompleteSection && (
        <span
          onClick={() => toggleActive(!active)}
          className={active ? 'show-menu dot-icon' : 'dot-icon'}>
          <img src={images.threeDot} alt="" />
          <span className="dropdown-menu">
            {/* <span>Set as Admin</span> */}
            {status !== 0 && (
              <span onClick={() => handleStatusChange()}>
                Move to {status === 1 ? 'Inactive' : 'Active'}
              </span>
            )}
            <span className="remove-user" onClick={confirmRemove}>
              Remove User
            </span>
          </span>
        </span>
      )}
    </div>
  );
};

export default ProviderItem;
