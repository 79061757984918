import React, {useState} from 'react';
import Layout from 'layout';
import images from 'data/images';
import Input from 'components/FormControls/Input';
import UILabel from 'components/UILabel';
import MyDatetime from 'components/MyDatetime';
import './PatientForm.css';

const PatientForm = props => {
  const {currentWeekStartDate} = props;
  const handleDateChange = e => {
    toggle(false);   
  };
  const [isOpen, toggle] = useState(false);
  const [active, toggleActive] = useState(null);
  return (
    <Layout>
      <div className="PatientForm">
        <UILabel />
        <form>
          <div className={`search ${active ? 'active' : ''}`}>
            <Input
              icon={images.magnifier}
              onClick={() => {
                toggleActive(!active);
              }}
              placeholder="Conditions, procedure, doctor name…"></Input>
            <div className="search-result">
              <div className="search-category">
                <span className="h3">Top Specialties</span>
                <span className="search-item">Primary Care Physician</span>
                <span className="search-item height-light">Orthopedic Spine Surgeon</span>
                <span className="search-item">Pain Management</span>
                <span className="search-item">Neurosurgeon</span>
                <span className="search-item">Shoulder and Elbow Surgeon</span>
                <span className="search-item">Sports Medicine (Ortho)</span>
              </div>
              <div className="search-category">
                <span className="h3">All Specialties (A-Z)</span>
                <span className="search-item">Primary Care Physician</span>
                <span className="search-item">Orthopedic Spine Surgeon</span>
                <span className="search-item">Pain Management</span>
                <span className="search-item">Neurosurgeon</span>
                <span className="search-item">Shoulder and Elbow Surgeon</span>
                <span className="search-item">Sports Medicine (Ortho)</span>
              </div>
              <div className="search-category doctor">
                <span className="doctor-item">
                  <img src={images.DoctorImage} alt="doctor" />
                  <span>Jane Stevenson ,MD , CAc </span>
                  <span>Pain Management . 0.4 mi</span>
                </span>
                <span className="doctor-item">
                  <img src={images.DoctorImage}  alt="doctor"/>
                  <span>Jane Stevenson ,MD , CAc </span>
                  <span>Pain Management . 0.4 mi</span>
                </span>
              </div>
            </div>
          </div>
          <div className="select-appointment-date">
            <Input
              icon={images.calendar2}
              onClick={() => toggle(!isOpen)}
              placeholder="Mar 03, 2020"></Input>
            <div className="datepicker">
              <MyDatetime
                value={currentWeekStartDate}
                onChange={handleDateChange}
                viewMode="days"
                open={isOpen}
                input={false}
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default PatientForm;
