import React, {useContext, useState, useEffect} from 'react';
import images from 'data/images';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import ChangeCardModal from './ChangeCardModal';
import AuthCard from './AuthCard';
import subscriptionService from 'services/subscription';
import _, {isNull} from 'lodash';
import momentHelper from 'data/moment/momentHelper';
import constants from 'data/constants';
import './BillingTab.css';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import config from 'config/config';
import {CancelSubscription} from './CancelSubscription';
import moment from 'moment';
import StripeInfoModal from './ChangeCardModal/StripeInfoModal';
import {ReconcileConfirm} from './ReconcileConfirm';
import PaymentModal from 'components/AfterAuth/Dashboard/PaymentModal';
const {modalFunctions} = modalConfig;
const {blankModalData, errorModalData, successModalData} = modalFunctions;
const {dateFormat} = momentHelper;
const {getSubscriptionDetails, getInvoice, cancelSubscription, reconcile, reactivate} =
  subscriptionService;

const BillingTab = props => {
  const stripePromise = loadStripe(config.StripePublishKey);
  const rootContext = useContext(RootContext);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);

  useEffect(() => {
    getSubscription();
    getInvoiceData();
  }, []);
  const ActiveSubscription = () => {
    const {setGlobal} = rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: StripeInfoModal,
        customComponentProps: {
          nextBillingDateSort: nextBillingDateSort,
          cardLastDigit: cardLastDigit,
          cardExpiry: cardExpiry,
          nextBillAmount: nextBillAmount,
          showcloseBtn: true,
          updateSubscriptionData: () => {
            getSubscription();
            // getInvoiceData();
          },
        },
      }),
    );
  };
  const openChangeCardModal = () => {
    const {setGlobal} = rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ChangeCardModal,
        customComponentProps: {
          nextBillingDateSort: nextBillingDateSort,
          cardLastDigit: cardLastDigit,
          cardExpiry: cardExpiry,
          nextBillAmount: nextBillAmount,
          cardType: cardType,
          updateSubscriptionData: () => {
            getSubscription();
            getInvoiceData();
          },
        },
      }),
    );
  };
  const handleError = error => {
    const {setGlobal} = rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const getSubscription = async () => {
    if (config.showStripe == true) {
      const response = await getSubscriptionDetails();
      if (response) {
        if (_.get(response, 'code', '') === 'stripeAccountNotCreated') {
          setSubscriptionData({
            status: 3, //Payment method require
          });
        } else {
          const {data = {}} = response;
          setSubscriptionData(data);
        }
      }
    }
  };

  const getInvoiceData = async () => {
    const response = await getInvoice();
    if (response) {
      const {data = []} = response;
      setInvoiceData(data);
    }
  };

  const nextBillingDate = new Date(_.get(subscriptionData, 'nextBillingDate', 0) * 1000).toString();
  const nextBillingDateLong = subscriptionData?.nextBillingDate
    ? dateFormat(nextBillingDate, 'MMM DD, YYYY')
    : null;
  const nextBillingDateSort = subscriptionData?.nextBillingDate
    ? dateFormat(nextBillingDate, 'MM/D/YY')
    : null;
  const nextBillAmount = _.get(subscriptionData, 'nextBillAmount', 0) / 100; //cent to dollar
  const cardLastDigit = _.get(subscriptionData, 'cardLastDigit', '****');
  const cardExpiry =
    _.get(subscriptionData, 'cardExpiryMonth', '**') +
    '/' +
    _.get(subscriptionData, 'cardExpiryYear', '****');
  const cardType = _.get(subscriptionData, 'brand', 'American Express');
  const trialEndDate = new Date(_.get(subscriptionData, 'trialEndDate', 0) * 1000);
  const isTrial = new Date() < trialEndDate;
  let remainingTrialDays = 0;
  if (isTrial) {
    const diffTime = Math.abs(trialEndDate - new Date());
    remainingTrialDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
  const daysRemaining = () => {
    if (subscriptionData?.nextBillingDate) {
      var billDate = moment(nextBillingDate);
      var todaysdate = moment();
      return billDate.diff(todaysdate, 'days');
    }
    return -1;
  };

  const statusText = isTrial
    ? `${remainingTrialDays} days left of trial`
    : constants.StripeStatus[_.get(subscriptionData, 'status', '1')];
  const status = _.get(subscriptionData, 'status', '1');
  const cancelSubscriptionAction = async () => {
    const {setGlobal} = rootContext;
    let removeScrip = await CancelSubscription({
      context: rootContext,
      nextBillingDateLong: nextBillingDateLong,
    });
    if (removeScrip) {
      try {
        const response = await cancelSubscription();
        if (response && response.message) {
          setGlobal('modal', successModalData(response.message));
          getSubscription();
          getInvoiceData();
        }
      } catch (ex) {
        handleError(ex);
      }

      //onDeleteProvider(providerId,removestripe?true:'');
    }
  };
  const changeSubscription = (isAdd = false) => {
    const {setGlobal} = rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: PaymentModal,
        modalWrapperClass: 'mega-modal',
        customComponentProps: {
          TitleText: isAdd ? `Activate Subscription` : `Change Subscription`,
          nextBillingDateSort: nextBillingDateSort,
          cardLastDigit: cardLastDigit,
          cardExpiry: cardExpiry,
          nextBillAmount: nextBillAmount,
          showcloseBtn: true,
          hideInputInput: isAdd ? false : true,
          hideTrail: true,
          updateSubscriptionData: () => {
            getSubscription();
            // getInvoiceData();
          },
        },
        closeOnBGClick: false,
      }),
    );
  };
  const reconcileAction = async () => {
    const {setGlobal} = rootContext;

    let reconcileBtnaction = await ReconcileConfirm({
      context: rootContext,
      onProviderClick: () => props.onProviderClick(),
      numberOfProvider: subscriptionData?.numberOfProvider,
      quantity: subscriptionData?.quantity,
    });
    if (reconcileBtnaction) {
      try {
        const response = await reconcile();
        if (response && response.message) {
          setGlobal('modal', successModalData(response.message));
          getSubscription();
          getInvoiceData();
        }
      } catch (ex) {
        handleError(ex);
      }
    }
  };
  const reactivateAction = async () => {
    const {setGlobal} = rootContext;
    try {
      const response = await reactivate();
      if (response && response.message) {
        setGlobal('modal', successModalData(response.message));
        getSubscription();
        getInvoiceData();
      }
    } catch (ex) {
      handleError(ex);
    }
  };
  const BillingAction = props => {
    let {status, nextBillingDate, cardType} = props;
    if (status === 1 && nextBillingDate) {
      return (
        <div className="ChangeSubscriptionDiv">
          {cardType && (
            <button onClick={cancelSubscriptionAction} className="cancel-sub">
              Cancel Subscription
            </button>
          )}
          {!cardType && (
            <button
              onClick={() => changeSubscription(cardType ? false : true)}
              className="cancel-sub change-Subscription">
              Change Subscription
            </button>
          )}
        </div>
      );
    } else {
      if (isNull(nextBillingDate) && status === 1) {
        return (
          <button className="SubscribeBtn" onClick={reactivateAction}>
            Subscribe
          </button>
        );
      } else {
        return (
          <button
            className="SubscribeBtn"
            onClick={() => changeSubscription(!cardType ? true : false)}>
            Subscribe
          </button>
        );
      }
    }
  };
  const Reconcile = props => {
    let {status} = props;
    return (
      <div className="reconcile">
        <div>
          <p>
            You have more subscriptions than providers.{' '}
            <span className="linkspan" onClick={() => props.onProviderClick()}>
              Add providers
            </span>{' '}
            to match your subscription or reconcile your subscription.
          </p>
        </div>
        <div>
          <button onClick={() => reconcileAction()} className="Reconcilebtn">
            Reconcile
          </button>
        </div>
      </div>
    );
  };
  const invoiceList = () => {
    return _.map(invoiceData, (invoice, index) => (
      <div className="Rtable-row" key={`${index}-invoiceData`}>
        <div className="Rtable-cell date-cell content-cell">
          {dateFormat(invoice.paymentDateTime, 'MMM DD, YYYY')}
        </div>
        <div className="Rtable-cell payment-cell content-cell">**** {invoice.cardNumber}</div>
        <div className="Rtable-cell description-cell content-cell">{invoice.description}</div>
        <div className="Rtable-cell price-cell  content-cell">${invoice.amount / 100}</div>
        <div className="Rtable-cell status-cell content-cell">
          {constants.InvoiceStatus[invoice.status]}
        </div>
        <div className="Rtable-cell action-cell content-cell">
          <table>
            <tbody>
              <tr>
                <td>
                  <button onClick={() => window.open(invoice.invoicePdfUrl, '_blank')}>View</button>
                </td>
              </tr>
              <tr>
                <td>
                  {index === 0 && invoice.status === 3 && invoice.clientSecret && (
                    <Elements stripe={stripePromise}>
                      <AuthCard
                        {...props}
                        authData={invoiceData ? invoiceData[0] : ''}
                        paymentMethodId={_.get(subscriptionData, 'paymentMethodId', '')}
                        loadInvoice={getInvoiceData}
                      />
                    </Elements>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ));
  };

  return (
    <div className="BillingTab box-content">
      <div className="row-content">
        <div className="inner-box-content hlaf-box-content">
          <div className="provider-content">
            {subscriptionData && (
              <div className="provider-title">
                {subscriptionData.status === 1 ? (
                  <div className="totalprovider">
                    {_.get(subscriptionData, 'numberOfProvider', 1)}{' '}
                    {`Provider${
                      _.get(subscriptionData, 'numberOfProvider', 1) > 1 ? 's' : ''
                    } Subscription${_.get(subscriptionData, 'numberOfProvider', 1) > 1 ? 's' : ''}`}
                  </div>
                ) : (
                  <div className="totalprovider">Inactive Subscription</div>
                )}

                <div>
                  {/* {cardType &&( */}
                  <BillingAction
                    status={subscriptionData.status}
                    nextBillingDate={subscriptionData.nextBillingDate}
                    cardType={cardType}
                  />
                  {/* )} */}
                </div>
              </div>
            )}
            {subscriptionData &&
              (subscriptionData.code === 'yourStripeAccountNotCreated' ||
                isNull(subscriptionData.nextBillingDate)) && (
                <div className="description">
                  {status === 1
                    ? `Your subscription will expiry on ${dateFormat(
                        subscriptionData.validityEndDate,
                        'MMM DD, YYYY',
                      )} Subscribe to continue using the service.`
                    : `Click on subscribe button to start a subscription. All of your providers will be
                  include in the subscription.`}
                </div>
              )}
            {(status === 1 || status === 2) && subscriptionData.nextBillingDate && (
              <div className="description">
                Your plan will renew on <span>{nextBillingDateLong},</span> charging your credit
                card <span>${nextBillAmount} </span>
                Add/remove or deactivate{' '}
                <span className="linkspan" onClick={() => props.onProviderClick()}>
                  providers
                </span>{' '}
                to adjust subscription
              </div>
            )}
            {status === 4 && (
              <div className="description">
                You need to authenticate your card,
                <span className="linkspan"> click here to authenticate</span>
              </div>
            )}
            {status === 3 && (
              <div className="description">
                There is some issue with your attach payment method, to reactivate your subscription
                please change your payment method by clicking on "Change Card" button
              </div>
            )}
            {subscriptionData &&
              subscriptionData.nextBillingDate &&
              subscriptionData.quantity &&
              subscriptionData.numberOfProvider &&
              subscriptionData.quantity != subscriptionData.numberOfProvider && (
                <Reconcile onProviderClick={props.onProviderClick} />
              )}
          </div>
        </div>
        {subscriptionData && subscriptionData.nextBillingDate && (
          <div className="inner-box-content hlaf-box-content">
            <div className="payment-content">
              <div className="payment-title">
                <div className="paymentheading">Payment Method</div>
                {cardLastDigit && cardType && (
                  <button onClick={openChangeCardModal}>
                    {cardLastDigit && cardType ? `Change Card` : `Add Card Details`}
                  </button>
                )}
              </div>
              {subscriptionData.nextBillingDate ? (
                <div className="description text-cap">
                  {cardLastDigit && cardType ? (
                    <span>
                      {cardType} ****{cardLastDigit}, {cardExpiry}
                    </span>
                  ) : (
                    <span>****</span>
                  )}
                </div>
              ) : (
                <div className="description text-cap">Enter your card details</div>
              )}
              <div className="description">
                Next payment will be automatically collected from this card.
              </div>
              {subscriptionData.nextBillingDate && (
                <div className="description">
                  Next billing is due on {nextBillingDateSort} for ${nextBillAmount}.
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="row-content">
        <div className="inner-box-content full-box-content">
          <div className="invoice-content">
            <div className="heading">
              <span>Invoices</span>
              <span>
                <img src={images.stripe} alt="strip" />
              </span>
            </div>
            <div className="InvoiceDetail">
              <div className="Rtable">
                <div className="Rtable-row Rtable-row--head">
                  <div className="Rtable-cell date-cell column-heading">Date </div>
                  <div className="Rtable-cell payment-cell column-heading">Payment</div>
                  <div className="Rtable-cell description-cell column-heading">Description</div>
                  <div className="Rtable-cell price-cell column-heading">Price</div>
                  <div className="Rtable-cell status-cell column-heading">Status</div>
                  <div className="Rtable-cell action-cell column-heading"></div>
                </div>
                {invoiceList()}
                {/* <div className="Rtable-row">
                  <div className="Rtable-cell date-cell content-cell">Oct 02, 2020</div>
                  <div className="Rtable-cell payment-cell content-cell">**** 2002</div>
                  <div className="Rtable-cell description-cell content-cell">Monthly Renewel</div>
                  <div className="Rtable-cell price-cell  content-cell">$1599.00</div>
                  <div className="Rtable-cell status-cell content-cell">Paid</div>
                  <div className="Rtable-cell action-cell content-cell">
                    <button>View</button>
                  </div>
                </div>
                <div className="Rtable-row">
                  <div className="Rtable-cell date-cell content-cell">Sep 19, 2020</div>
                  <div className="Rtable-cell payment-cell content-cell">**** 2002</div>
                  <div className="Rtable-cell description-cell content-cell">+1 Provider Seat</div>
                  <div className="Rtable-cell price-cell  content-cell">$249.17</div>
                  <div className="Rtable-cell status-cell content-cell">Paid</div>
                  <div className="Rtable-cell action-cell content-cell">
                    <button>View</button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingTab;
