import React, {useState, useContext} from 'react';
import _ from 'lodash';
import {Link, useHistory} from 'react-router-dom';
import images from 'data/images';
import AuthContext from 'context/AuthContext';
import './PatientHeader.css';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import patientServices from 'services/patient';
import config from 'config/config';
const {modalFunctions} = modalConfig;
const {errorModalData, confirmModalData} = modalFunctions;
const PatientHeader = props => {
  const {showMyKalypsys, planeHeader = false, hideRightBtn = false} = props;
  const history = useHistory();
  const [active, toggleActive] = useState(null);
  const [submenu, ActiveSubmenu] = useState(null);
  const [show, setActiveMenu] = useState(null);
  const authContext = useContext(AuthContext);
  const {updateAuthStatus, auth} = authContext;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;

  const firstName = _.get(auth, 'firstName', '');
  const lastName = _.get(auth, 'lastName', '');
  const siteSettings = _.get(authContext, 'siteSettings');
  const {deleteAccount} = patientServices;
  let rightHeaderComponent = null;
  rightHeaderComponent = (
    <>
      <div className="right-col">
        <div className="menu-content">
          <span>
            Already a Member? <Link to="/patient/login"> Click here to login</Link>
          </span>
        </div>
      </div>
    </>
  );
  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const deleteAccountFn = () => {
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete your account, once deleted all data are removed?`,

        onConfirm: async isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            const response = await deleteAccount().catch(handleError);
            if (response) {
              if (response.code === 'success') {
                updateAuthStatus(false);
              }
            }
          }
        },
      }),
    );
  };
  let afterLoginRightHeaderComponent = null;
  afterLoginRightHeaderComponent = (
    <>
      <div className="right-col after-login">
        <div className="menu-content">
          {show && (
            <div
              className="overlay"
              onClick={() => {
                setActiveMenu(!show);
              }}></div>
          )}
          {showMyKalypsys && (
            <Link className="home-link" to="/">
              My Kaly
            </Link>
          )}
          <span
            className={`submenu ${show ? 'show-menu' : ''}`}
            onClick={() => {
              setActiveMenu(!show);
            }}>
            <span className="username">
              {firstName} {lastName}{' '}
              <img alt="" src={images.arrowPointerWhite} className="arrowPointer" />
            </span>
            <span className="title">My profile</span>

            <span className="dropdown-menu">
              {/* <span className="email">
                <img alt="" src={profileImageUrl || images.user} />
                {email}
              </span> */}
              <span onClick={() => history.push('/settings')} className="settings-web">
                <img alt="setting" src={images.settingGray} /> Settings
              </span>
              <span className={`settings-mobile ${submenu ? 'show-menu' : ''}`}>
                <span
                  className="setting-sm"
                  onClick={() => {
                    ActiveSubmenu(!submenu);
                  }}>
                  {' '}
                  <img alt="setting" src={images.settingWhite} /> Settings
                </span>
                <span className="submenu-mobile-list" onClick={() => toggleActive(!active)}>
                  <Link to="/settings?tab=account">Account Info</Link>
                  <Link to="/settings?tab=password">Password</Link>
                  <Link to="/settings?tab=notifications">Notifications</Link>
                  <Link to="/settings?tab=insurance">Insurance</Link>
                  <Link to="/settings?tab=demographic">Demographic Info</Link>
                  {/* <Link to="/settings?tab=authorizations">Authorizations</Link> */}
                  <Link to="/settings?tab=privacy">Data Privacy</Link>
                </span>
              </span>
              {/* <span onClick={() =>deleteAccountFn()} className="settings-web deletegraycolor">
                <img  alt="setting" src={images.deleteBlue} /> Delete Account
              </span> */}
              {/* <span onClick={() => deleteAccountFn()} className={`settings-mobile  ${submenu ? 'show-menu' : ''}`}>
                <span className="setting-sm ">
                <img className='logout-sm deletewhitecolor' alt="setting" src={images.deleteBlue} /> Delete Account
                </span>
              </span> */}
              <span className="contact-us">
                <a href={`mailto:${_.get(siteSettings, 'contactUsLinks', '')}`}>Contact us</a>
              </span>
              <span
                className="logout"
                onClick={() => {
                  updateAuthStatus(false);
                }}>
                <img alt="logout" src={images.logoutGray} />
                <img className="logout-sm" alt="logout" src={images.logoutWhite} />
                Logout
              </span>
            </span>
          </span>
        </div>
      </div>
    </>
  );
  if (planeHeader) {
    return (
      <div className={`PatientHeader small ${active ? 'menu-active' : ''}`}>
        <div className="container">
          <div className="header-content"></div>
        </div>
      </div>
    );
  }
  if (hideRightBtn) {
    rightHeaderComponent = null;
    afterLoginRightHeaderComponent = null;
  }
  return (
    <div className={`PatientHeader ${active ? 'menu-active' : ''}`}>
      <div className="container">
        <div className="header-content">
          {auth ? (
            <div className="logo">
              <Link to="/" className="logoblue">
                <img alt="" src={images.logo} />
              </Link>
              <Link to="/" className="logowhite">
                <img alt="" src={images.logoWhite} />
              </Link>
            </div>
          ) : (
            <div className="logo">
              <a href={config.landingPageUrl} className="logoblue">
                <img alt="" src={images.logo} />
              </a>
              <a href={config.landingPageUrl} className="logowhite">
                <img alt="" src={images.logoWhite} />
              </a>
            </div>
          )}
          {!auth && rightHeaderComponent}
          {auth && afterLoginRightHeaderComponent}
          <div
            className="mobile-menu"
            onClick={() => {
              toggleActive(!active);
            }}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientHeader;
