import React, {Component} from 'react';
import moment from 'moment';
import _ from 'lodash';
import PatientLayout from 'layout/PatientLayout';
import {Link} from 'react-router-dom';
import ReserveAppointment from 'components/Patient/ConfirmAppointment/ReserveAppointment';
import ReviewConfirm from 'components/Patient/ConfirmAppointment/ReviewConfirm';
import AppointmentRequest from 'components/Patient/ConfirmAppointment/AppointmentRequest';
import AuthContext from 'context/AuthContext';
import patientService from 'services/patient';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import AppointmentBookedModal from 'components/Patient/ConfirmAppointment/AppointmentBookedModal';
import images from 'data/images';
import RescheduleAppointmentModal from 'components/Patient/PatientDashboard/RescheduleAppointmentModal';
import VerifyPhoneNumberModal from 'components/Patient/ConfirmAppointment/VerifyPhoneNumberModal';
import EmailInputModal from 'components/Patient/ConfirmAppointment/EmailInputModal';
import cookieHelper from 'data/cookie.helper';
import './ConfirmAppointment.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import {SocialTermsofUse} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/SocialTermsofUse';
import momentHelper from 'data/moment/momentHelper';
import {Passwordrequired} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/Passwordrequired';
import algoStorageHelper from 'data/algoStorage.helper';
import {SocialGenderDobMain} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/SocialGenderDob';
const {utcToLocalMoment, localToUtcMoment} = momentHelper;
const {
  reserveAppointment,
  patientSignUp,
  patientSocialLogin,
  savePatient,
  getFamilyMembers,
  getMyProfileInsurance,
} = patientService;
const {modalFunctions} = modalConfig;
const {errorModalData, warningModalData, blankModalData, successModalData, infoModalData} =
  modalFunctions;
const {deleteAlgo} = algoStorageHelper;
const {Analytics} = analyticsData;

class ConfirmAppointment extends Component {
  static contextType = AuthContext;
  state = {
    showAppointmentRequest: false,
    showPromptOtp: false,
    tempUserId: 0,
  };
  rootContext = null;

  getHistoryState = () => {
    const {location} = this.props;
    const {state} = location;
    return state;
  };

  handleTimeSelection = (dateString, timeObject) => {
    const query = new URLSearchParams(window.location.search);
    const appointmentSource = query.get('appointmentSource');
    const accessLogProviderId = query.get('accessLogProviderId');
    const accessLogPracticeId = query.get('accessLogPracticeId');
    let appointmentSourceUrl = appointmentSource ? `?appointmentSource=${appointmentSource}` : '';
    const accessLog = accessLogProviderId
      ? `accessLogProviderId=${accessLogProviderId}`
      : accessLogPracticeId
      ? `accessLogPracticeId=${accessLogPracticeId}`
      : '';
    appointmentSourceUrl = appointmentSourceUrl
      ? `${appointmentSourceUrl}&${accessLog}`
      : `?${accessLog}`;

    const state = this.getHistoryState();
    this.props.history.replace({
      pathname: `/confirm-appointment`,
      search: appointmentSourceUrl,
      state: {
        ...state,
        dateAndTimeObject: {dateString, timeObject},
      },
    });
  };

  openEditAppointmentTimeModal = () => {
    const state = this.getHistoryState();
    const dateAndTimeObject = _.get(state, 'dateAndTimeObject');
    const isVirtual = _.get(state, 'dateAndTimeObject.timeObject.isVirtual', false);
    const dateTimeString = dateAndTimeObject.dateString + ' ' + dateAndTimeObject.timeObject.time;
    const apStartDateTimeObj = utcToLocalMoment(
      localToUtcMoment(moment(dateTimeString, 'YYYY-MM-DD h:mm a', true)).format(),
    );

    const providerId = _.get(state, 'doctorDetail.providerId', 0);
    const locationId = _.get(state, 'doctorDetail.locationId', 7);
    const address = _.get(state, 'doctorDetail.address', '');
    const doctorName = _.get(state, 'doctorDetail.name', '');
    const suffixes = _.get(state, 'doctorDetail.suffixes', []);
    const specialties = _.get(state, 'doctorDetail.specialties', []);
    const existingAppointmentTime = _.get(state, 'doctorDetail.existingAppointmentTime', 15);
    const newAppointmentTime = _.get(state, 'doctorDetail.newAppointmentTime', 15);
    const customSpecialty = _.get(state, 'doctorDetail.customSpecialty', null);
    const newAppointmentTimeorExAT =
      existingAppointmentTime > newAppointmentTime ? existingAppointmentTime : newAppointmentTime;
    const appointment = {
      appointmentStartDateTime: apStartDateTimeObj.utc().toISOString(),
      isExistingPatient: true,
      providerAvailability: {
        isVirtual,
        practiceLocation: {
          id: locationId,
          address,
        },
        provider: {
          id: providerId,
          suffixes,
          specialties,
          doctorName,
          newAppointmentTimeorExAT,
          newAppointmentTime,
          existingAppointmentTime,
          customSpecialty,
        },
      },
    };
    Nux.sendEvent(
      Analytics.Category.PatientAppointment,
      Analytics.Actions.Click,
      'editAppointmentTime',
    );
    const {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: RescheduleAppointmentModal,
        customComponentProps: {
          data: {
            title: 'Edit Appointment Time',
            appointment,
          },
          timeSelected: this.handleTimeSelection,
        },
      }),
    );
  };

  handleError = error => {
    const {setGlobal} = this.rootContext;
    let {message, customData} = error;

    if (customData && customData.showLogin === 1) {
      const state = this.getHistoryState();
      const loginStateToObj = {
        pathname: '/patient/login',
        state: {...state, fromReserveAppointment: true},
      };
      message = (
        <p>
          {message} Please log in with the{' '}
          <Link onClick={() => setGlobal('modal', {showModal: false})} to={loginStateToObj}>
            Patient Login
          </Link>
          .
        </p>
      );
    }
    setGlobal('modal', errorModalData(message));
    return null;
  };

  handleSavePatient = async (data, closeModal, handlePatientSelection) => {
    const response = await savePatient(data).catch(() => null);
    if (response) {
      closeModal();
      const {setAuthState} = this.context;
      const familyeMembersResponse = await getFamilyMembers().catch(() => null);
      if (familyeMembersResponse) {
        const {data: familyMembers} = familyeMembersResponse;
        setAuthState(prevState => {
          return {
            ...prevState,
            auth: {
              ...prevState.auth,
              familyMembers,
            },
          };
        });
        if (familyMembers.length > 0) {
          handlePatientSelection(familyMembers[familyMembers.length - 1].id);
        }
      }
    }
  };
  getAndSetMyProfileInsurance = async () => {
    const {setAuthState} = this.context;
    const profileInsuranceResponse = await getMyProfileInsurance().catch(() => null);
    if (profileInsuranceResponse) {
      const {data: insurance} = profileInsuranceResponse;
      setAuthState(prevState => {
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            insurance,
          },
        };
      });
    }
  };
  handleReserveAppointment = async (data, updateVerifyInsuranceFlag, recallBooking) => {
    const {history} = this.props;
    let postData = data;
    let passData = {...data};
    if (localStorage.getItem('providerSearchRanking'))
      passData.providerSearchRanking = localStorage.getItem('providerSearchRanking');
    if (localStorage.getItem('SearchValueKey'))
      passData.searchValue = localStorage.getItem('SearchValueKey');

    const response = await reserveAppointment(passData).catch(error => {
      const {setGlobal} = this.rootContext;
      if (error.code === 406) {
        Nux.sendEvent(
          Analytics.Category.PatientAppointment,
          Analytics.Actions.Click,
          'outOfNetworkInsurance',
        );
        const {message} = error;
        setGlobal(
          'modal',
          warningModalData(
            message,
            {
              closeButtonText: 'Continue Booking',
              callBackOnClose: updateVerifyInsuranceFlag,
              returnToSearch: true,
              history: this.props.history,
            },
            'This Provider is out-of-network',
          ),
        );
      } else if (error.customCode === 'phoneNumberVerificationFailed') {
        // if patient has not verified his/her phonenumber
        setGlobal(
          'modal',
          blankModalData({
            CustomComponent: VerifyPhoneNumberModal,
            customComponentProps: {
              data,
              afterVerify: () => {
                const {setAuthState} = this.context;
                setAuthState(prevState => {
                  return {
                    ...prevState,
                    auth: {
                      ...prevState.auth,
                      hasPhoneNumberVerified: true,
                      phoneNumber: data.phoneNumber,
                    },
                  };
                });
                recallBooking();
              },
            },
            modalWrapperClass: 'mega-modal',
          }),
        );
      } else {
        this.handleError(error);
      }
      return null;
    });
    if (response && response.data && Object.keys(response.data).length > 0) {
      localStorage.removeItem('appointmentSource');
      localStorage.removeItem('providerSearchRanking');
      localStorage.removeItem('SearchValueKey');
      localStorage.removeItem('trackFlowQuestions');
      const {data} = response;
      let host = window.location.host;
      document.cookie = `kdid=${data.kdid};domain=.${host};path=/;secure; SameSite=none; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      localStorage.setItem('kdid', data.kdid);
      const {setGlobal} = this.rootContext;
      const state = this.getHistoryState();
      if (postData.isSaveToProfile === true) {
        this.getAndSetMyProfileInsurance();
      }
      // setGlobal(
      //   'modal',
      //   blankModalData({
      //     CustomComponent: AppointmentBookedModal,
      //     customComponentProps: {
      //       state: state,
      //       isShowEditAppointmentButton: false,
      //     },
      //     modalWrapperClass: 'mega-modal',
      //   }),
      // );

      deleteAlgo();

      history.replace({
        pathname: 'confirm-appointment-thanks',
        state: {...state, appointmentData: data},
      });
    } else {
      const state = this.getHistoryState();
      const loginStateToObj = {
        pathname: '/patient/login',
        state: {...state, fromReserveAppointment: true},
      };
      const {setGlobal} = this.rootContext;
      const {updateAuthStatus} = this.context;
      updateAuthStatus(true);
      let message = (
        <p>
          Your session token has expired, and for security reasons, you need to log in again to
          continue accessing our services. Please click{' '}
          <Link onClick={() => setGlobal('modal', {showModal: false})} to={loginStateToObj}>
            Login
          </Link>{' '}
          to Review & Confirm Appointment.
        </p>
      );
      setGlobal('modal', errorModalData(message));
    }
  };

  handleSignUp = async formData => {
    const signUpResponse = await patientSignUp(formData).catch(this.handleError);

    if (signUpResponse && signUpResponse.data && signUpResponse.data.action === 'promptOtp') {
      this.setState({
        tempUserId: signUpResponse.data.userId,
        showPromptOtp: true,
      });
    } else if (signUpResponse) {
      this.props.history.push('/patient/login');
    }
  };
  callAfterPhoneNumberOtp = response => {
    const {updateAuthStatus} = this.context;
    const {data} = response;
    const {accessToken, refreshToken} = data;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    updateAuthStatus(true);
  };
  handleSocialLoginSuccess = async (provider, user, email = null, dob = null, genderId = null) => {
    const {updateAuthStatus} = this.context;
    const {setGlobal} = this.rootContext;
    // let ok=await SocialTermsofUse({context:this.rootContext})
    // if(!ok)
    // return false
    let data = {
      type: provider,
      idToken: '',
      accessToken: '',
    };
    if (dob) data.dateOfBirth = dob;
    if (user.password) {
      data.password = user.password;
    }
    if (genderId && genderId > 0) data.genderId = genderId;
    switch (provider) {
      case 'google':
        const {access_token, id_token} = user;
        data = {
          ...data,
          idToken: id_token,
          accessToken: access_token,
        };
        break;
      case 'facebook':
        const {accessToken, userID} = user;
        data = {
          ...data,
          idToken: userID,
          accessToken: accessToken,
          email: email,
        };
        break;
      case 'apple':
        const {authorization, user: AppleUser} = user;
        data = {
          ...data,
          accessToken: authorization.code,
          idToken: authorization.id_token,
          email: email ? email : AppleUser?.email,
          firstName: AppleUser?.name?.firstName,
          lastName: AppleUser?.name?.lastName,
        };
        break;
      default:
        break;
    }
    try {
      const response = await patientSocialLogin(data);
      if (!_.isEmpty(response.data)) {
        const {data} = response;
        const {accessToken, refreshToken} = data;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        updateAuthStatus(true);
      } else {
        setGlobal('modal', successModalData(response.message));
      }
    } catch (ex) {
      const {customCode, message} = ex;
      const callback = email => {
        if (user.password) delete user.password;
        this.handleSocialLoginSuccess(provider, user, email);
      };
      if (customCode === 'passwordrequired') {
        let passUser = {email: email ? email : user?.user?.email};
        let Prresponse = await Passwordrequired({
          context: this.rootContext,
          user: passUser,
          provider: provider,
        });
        let passUsertemp = {...user, password: Prresponse.password};
        this.handleSocialLoginSuccess(provider, passUsertemp, email);
        // if(Prresponse){
        //   const callbackAfterEmail = email => {
        //     this.handleSocialLoginSuccess(provider, user, email);
        //   };
        //   if (!_.isEmpty(Prresponse.data)) {
        //     this.afterLoginHandler(Prresponse);
        //     return false
        //   } else {
        //     setGlobal(
        //       'modal',
        //       blankModalData({
        //         CustomComponent: EmailInputModal,
        //         customComponentProps: {callback: callbackAfterEmail},
        //         modalWrapperClass: 'mega-modal',
        //       }),
        //     );
        //     return false
        //   }
        // }
      } else if (customCode === 'socialSignUpEmailRequired') {
        setGlobal(
          'modal',
          blankModalData({
            CustomComponent: EmailInputModal,
            customComponentProps: {callback: callback, message: message},
            modalWrapperClass: 'mega-modal',
          }),
        );
      } else if (customCode == 'genderanddobrequiredSocialAccount') {
        let passUser = {email: email ? email : user?.user?.email};
        let gdinfo = await SocialGenderDobMain({
          context: this.rootContext,
          user: passUser,
          message: message,
          provider: provider,
        });
        let passUsertemp = {...user};
        this.handleSocialLoginSuccess(provider, passUsertemp, email, gdinfo.dob, gdinfo.genderId);
      } else if (message === 'Date of birth is required') {
        let passUser = {};
        if (provider === 'apple') {
          passUser = {
            firstName: user.firstName ? user.firstName : user?.user?.name?.firstName,
            lastName: user.firstName ? user.firstName : user?.user?.name?.lastName,
            email: email ? email : user?.user?.email,
          };
        }
        let aditionalInfo = await SocialTermsofUse({
          context: this.rootContext,
          user: passUser,
          provider: provider,
        });
        if (!aditionalInfo) return false;
        if (provider === 'apple' && aditionalInfo?.user?.firstName) {
          user.user = {
            name: {firstName: aditionalInfo.user.firstName, lastName: aditionalInfo.user.lastName},
            email: aditionalInfo?.user?.email,
          };
        }

        this.handleSocialLoginSuccess(
          provider,
          user,
          email,
          aditionalInfo.dob,
          aditionalInfo.genderId,
        );
        return;
      } else {
        this.handleError(ex);
      }
    }
  };

  handleToggleAppointment = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showAppointmentRequest: !prevState.showAppointmentRequest,
      };
    });
  };

  handleSocialLoginFailure = (provider, err) => {
    console.log(provider, err);
  };

  render() {
    const state = this.getHistoryState();

    if (!state) {
      this.props.history.push('/');
      return null;
    }
    const {auth} = this.context;
    const {showAppointmentRequest} = this.state;
    let currentStep = 1;
    if (auth) {
      currentStep = 2;
    }
    return (
      <div
        className={`ConfirmAppointment ${showAppointmentRequest ? 'showappointment-mobile' : ''}`}>
        <PatientLayout planeHeader={true}>
          <RootContext.Consumer>
            {context => {
              this.rootContext = context;
              return null;
            }}
          </RootContext.Consumer>
          <div className="ConfirmAppointment-content">
            <div className="left-content">
              <div className="title-heading">
                <span className="title-mobile">
                  <span>Appointment Request Details</span>
                  <img src={images.close} onClick={this.handleToggleAppointment} alt="close" />
                </span>
              </div>
              <AppointmentRequest
                state={state}
                openEditAppointmentTimeModal={this.openEditAppointmentTimeModal}
              />
              <div className="reserve-appointment">
                By selecting “Reserve Appointment” I certify that the insurance or payment selected
                is the one that I will be using when I see this medical professional, and that I
                have read and agree to the Kaly{' '}
                <Link to="/terms" target="_blank">
                  terms of use
                </Link>
                .
              </div>
            </div>
            <div className="right-content">
              {currentStep === 1 && (
                <ReserveAppointment
                  state={state}
                  showPromptOtp={this.state.showPromptOtp}
                  tempUserId={this.state.tempUserId}
                  callAfterPhoneNumberOtp={this.callAfterPhoneNumberOtp}
                  onSignUp={this.handleSignUp}
                  onSocialLoginSuccess={this.handleSocialLoginSuccess}
                  onleSocialLoginFailure={this.handleSocialLoginFailure}
                  onToggleAppointment={this.handleToggleAppointment}
                />
              )}
              {currentStep === 2 && (
                <ReviewConfirm
                  state={state}
                  bookAppointment={this.handleReserveAppointment}
                  onSavePatient={this.handleSavePatient}
                  onToggleAppointment={this.handleToggleAppointment}
                />
              )}
            </div>
          </div>
        </PatientLayout>
      </div>
    );
  }
}

export default ConfirmAppointment;
