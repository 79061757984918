import React, {useState} from 'react';
import images from 'data/images';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import validators from 'data/validators';
import './CreatePasswordModal.css';
import constants from 'data/constants';

const {__required, __password} = validators;
const {DEFAULT_MESSAGES} = constants;
const CreatePasswordModal = props => {
  const {closeModal, onPasswordSave} = props;
  const values = {password: ''};
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validatePassword = (value = null) => {
    const fValue = value !== null ? value : formValues['password'];
    if (!__required(fValue)) {
      return 'Password is required';
    }
    if (!__password(fValue)) {
      return 'Invalid password';
    }
    return null;
  };
  const __validateForm = () => {
    const validPassword = !__validatePassword();
    return validPassword;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'password':
        errorObject = {
          [name]: __validatePassword(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleForm = () => {
    onPasswordSave(formValues);
    closeModal();
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  return (
    <div className="CreatePasswordModal">
      <div className="modal-content-header">
        <div className="h3">Create a password </div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <Input
          {...getGeneralInputProps('password')}
          type="password"
          icon={images.eyeView}
          label="Password"
        />
        <span className="password-information">
          {DEFAULT_MESSAGES.PASSWORD_INSTRUCTION_TEXT}
        </span>
      </div>
      <div className="modal-content-footer">
        <CircleButton disabled={!isValidForm} onClick={handleForm}>
          Continue
        </CircleButton>
      </div>
    </div>
  );
};

export default CreatePasswordModal;
