import images from 'data/images';
import React from 'react';
import {useHistory} from 'react-router-dom';
import './CircleButton.css';

const CircleButton = props => {
  const {
    link = null,
    isOpenInNewTab = false,
    children,
    btnType = 'whiteButton',
    containerClass = '',
    showClose = false,
    handleCloseClick,
    ...otherProps
  } = props;
  const history = useHistory();
  const goToLink = () => {
    if (isOpenInNewTab) {
      window.open(link, '_blank');
    } else {
      history.push(link);
    }
  };
  let clickProp = {};
  if (link) {
    clickProp = {onClick: goToLink};
  }
  let className;
  switch (btnType) {
    case 'whiteButton':
      className = 'WhiteButton';
      break;
    case 'secondaryButton':
      className = 'SecondaryButton';
      break;
    case 'smallClose':
      className = 'smallClose';
      break;
    default:
      className = 'SecondaryButton';
      break;
  }
  // use the attribute passed in props to overwrite the JSX type attibute
  return (
    <>
      {showClose && (
        <div className={'CircleButtonWrapper ' + (showClose ? 'with-close-icon' : '')}>
          <button
            type="button"
            {...otherProps}
            {...clickProp}
            className={`CircleButton ${className} ${containerClass}`}>
            {children}
          </button>
          <img src={images.close} alt="close" className="close-icon" onClick={handleCloseClick} />
        </div>
      )}
      {!showClose && (
        <button
          type="button"
          {...otherProps}
          {...clickProp}
          className={`CircleButton ${className} ${containerClass}`}>
          {children}
        </button>
      )}
    </>
  );
};

export default CircleButton;
