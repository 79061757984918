import React, { useContext, useEffect, useState } from 'react';
import images from 'data/images';
import reviewService from 'services/review';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
//import SendReviewRequest from './SendReviewRequest';

import './ConnectSocial.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import AuthContext from 'context/AuthContext';
import config from 'config/config';
import helper from 'data/helper';
import axios from 'axios';
import ManageLocation from './ManageLocation';
import { DisconnectSocialConform } from './DisconnectSocialConform';
import { Relogin } from './ReloginMsg';
import { useHistory } from 'react-router-dom';
const { getgoogleSSOConnect,getgoogleSSOLocations, addSocialConnect, deleteSocialConnect, postGoogleSSO, disconnectGoogleSso } = reviewService;
const { modalFunctions } = modalConfig;
const { errorModalData, successModalData, confirmModalData, blankModalData } = modalFunctions;
const { Analytics } = analyticsData;
const { loadScript } = helper;

let GoogleAuth = null;
const ConnectSocial = props => {
  const rootContext = useContext(RootContext);
  const authContext = useContext(AuthContext);
  let history = useHistory();
  const { auth } = authContext;
  const { setGlobal } = rootContext;
  const [connections, setConnections] = useState({});
  // const [oldviewUserId,setOldViewUserId]=useState(0);
  useEffect(() => {
    getAndSetConnections();
    initGoogleSetup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  const initGoogleSetup = () => {
    try {
      loadScript(document, 'script', 'google-jssdk2', 'https://apis.google.com/js/client:platform.js?onload=start', e => {
        window.gapi.load('auth2', () => {
          if(!document.querySelector('#google-jssdk')){
          GoogleAuth = window.gapi.auth2.init({
            client_id: config.GOOGLE_CLIENT_ID,
            //cookiepolicy: 'single_host_origin',
            scope: 'https://www.googleapis.com/auth/business.manage',
            immediate: true,
            access_type: "offline"
          });
        }else{
          GoogleAuth = window.gapi.auth2.getAuthInstance({
            client_id: config.GOOGLE_CLIENT_ID,
            //cookiepolicy: 'single_host_origin',
            scope: 'https://www.googleapis.com/auth/business.manage',
            immediate: true,
            access_type: "offline"
          });
        }
        });
      });
    } catch (error) {

    }
  }
  const handleError = error => {
    const { message } = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  const getAndSetConnections = async () => {
    const response = await getgoogleSSOConnect().catch(async (e) => {
      // if(e.message=='Reconnection to Google is required.'){
      //   setConnections({
      //     gmb: false
      //   });
      //   // let deleteConfirm = await Relogin({
      //   //   context: rootContext,
      //   //   text: `Your Google My Business Review Account has been disconnected.  Please sign in it again.`,
      //   // });
      //   // if (!deleteConfirm) return false;
      //   // if(deleteConfirm=='Go to Reviews'){
      //   //   history.push("/reviews");
      //   // }

      // }else{
      if (e.message === 'Connection to Google is required.' || e.message === 'Reconnection to Google is required.') {

        setConnections({
          gmb: false
        });
      } else {
        handleError(e)
        setConnections({
          gmb: false
        });
      }

    });
    if (response) {
      // const { data } = response;
      setConnections(response);
    }
  };

  const connectGoogle = async () => {
    Nux.sendEvent(Analytics.Category.DoctorReview, Analytics.Actions.Click, 'socialAccountAction');
    // let payload = {
    //   channelName: 'gmb',
    // };
    // const response = await addSocialConnect(payload).catch(handleError);
    // if (response) {
    //   const {data} = response;
    //   let googleConnectWindow = window.open(
    //     data.url,
    //     'googleConnectWindow',
    //     'width=500,height=500',
    //   );
    //   let googleConnectTimer = setInterval(() => {
    //     if (googleConnectWindow.closed) {
    //       getAndSetConnections();
    //       clearInterval(googleConnectTimer);
    //     }
    //   }, 500);
    // }
    if (GoogleAuth) {
      GoogleAuth.grantOfflineAccess({}).then(
        res => {

          axios.post('https://www.googleapis.com/oauth2/v4/token', {

            'code': res.code,
            'client_id': config.GOOGLE_CLIENT_ID,
            'client_secret': config.GOOLGE_CLIENT_SECRET,
            'redirect_uri': window.location.origin,
            'grant_type': 'authorization_code'

          }).then(async (r) => {

            let { data } = r
            saveGoogleSSO(data.refresh_token)


          })
        },
        err => {
          console.log('Google-err', err);
        },
      );
    }
  };

  const connectFacebook = async () => {
    Nux.sendEvent(Analytics.Category.DoctorReview, Analytics.Actions.Click, 'socialAccountAction');
    let payload = {
      channelName: 'facebook',
    };
    const response = await addSocialConnect(payload).catch(handleError);
    if (response) {
      const { data } = response;
      let facebookConnectWindow = window.open(
        data.url,
        'facebookConnectWindow',
        'width=500,height=500',
      );
      let facebookConnectTimer = setInterval(() => {
        if (facebookConnectWindow.closed) {
          //  getAndSetConnections();
          clearInterval(facebookConnectTimer);
        }
      }, 500);
    }
  };

  const disconnectFacebook = async () => {
    Nux.sendEvent(Analytics.Category.DoctorReview, Analytics.Actions.Click, 'socialAccountAction');
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to disconnect your Facebook account?`,
        onConfirm: async isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', { showModal: false });
            const response = await deleteSocialConnect('facebook').catch(handleError);
            if (response) {
              const { data } = response;
              if (data) getAndSetConnections();
            }
          }
        },
      }),
    );
  };

  const disconnectGoogle = async () => {
    Nux.sendEvent(Analytics.Category.DoctorReview, Analytics.Actions.Click, 'socialAccountAction');
    // setGlobal(
    //   'modal',
    //   blankModalData({
    //     confirmMessage: `Are you sure you want to disconnect your Google account?`,
    //     onConfirm: async isConfirmed => {
    //       if (isConfirmed) {
    //         setGlobal('modal', {showModal: false});
    //         // const response = await deleteSocialConnect('gmb').catch(handleError);
    //         // if (response) {
    //         //   const {data} = response;
    //         //   //if (data) getAndSetConnections();
    //         // }
    //       }
    //     },
    //   }),
    // );
    let deleteConfirm = await DisconnectSocialConform({
      context: rootContext,
      text: `Are you sure you want to disconnect your Google My Business Account?  This action cannot be undone.  You can choose to keep Google My Business Reviews in your profile or to remove them.`,
    });
    if (!deleteConfirm) return false;
    let passData = {
      shouldKeepReviews: deleteConfirm == 'Keep Reviews' ? true : false
    }
    const response = await disconnectGoogleSso(passData).catch(handleError);
    if (response) {
      const { data } = response;
      if (data) {
        getAndSetConnections();
      }
    }

  };
  const manageLocation = (closeOnBGClick) => {
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ManageLocation,
        customComponentProps: {
          closeOnBGClick: closeOnBGClick
        },
        closeOnBGClick: closeOnBGClick
      }),
    );
  };
  // const sendReviewRequest = () => {
  //   setGlobal(
  //     'modal',
  //     blankModalData({
  //       CustomComponent: SendReviewRequest,
  //       customComponentProps: {},
  //     }),
  //   );
  // };
  const saveGoogleSSO = async (token) => {
    if (token) {
      const response = await postGoogleSSO({ 'refreshToken': token }).catch(
        handleError

      );
      if (response) {
        const { message } = response;

        if (message) {
          setGlobal('modal', successModalData(message, {
            callBackOnClose: async () => {

              let responce = await getgoogleSSOLocations().catch(handleError)
              if (responce) {
                let { data } = responce;

                let checkalreadySaved = data.find((l) => l.isSelected == true)
                if (!checkalreadySaved) {
                  manageLocation(false)
                }

              }


              getAndSetConnections();
            }
          }));
        }

      }
    }
  }
  return (
    <div className="ConnectSocial">
      <div className="heading">
        <div className="title">Integrate Social Accounts </div> <span></span>
        {/* {As per call on 13-07-2021} */}
        {/* <span>
          <button className="refresh-btn send-review-request" onClick={sendReviewRequest}>
            Send review request
          </button>
          <button className="refresh-btn">Get link to connect</button>
        </span> */}
      </div>
      <div className="inner-content">
        <div className="social-ac-info-text">
          <span>
            Integrating your social account will allow you to directly reply to reviews coming from these accounts in your Kaly Practice Portal. When you connect your social account, you will need to use an email account that has administrative permission to access these social accounts.
            {/* Only one kaly provider may use the specified social account. */}
          </span>
        </div>
        <div className="social-ac-list">
          <div className="social-ac-list-item">
            <div className="list-item-inner-content">
              <div className="logo">
                <img src={images.Googleicon} alt="google icon" />
              </div>
              <div className="name">Google My Business</div>
              {connections.message == 'You are connected to Google.' && (
                <>
                  <div className="status" style={{ color: "#79D174" }}>Connected</div>
                  <div style={{ marginBottom: "15px", cursor: "pointer" }}
                    onClick={() => manageLocation(true)}
                    className="status">Manage Locations</div>
                  <button className="disconnect" onClick={disconnectGoogle}>
                    Disconnect Google
                  </button>
                </>
              )}
              {connections.message != 'You are connected to Google.' && (
                <>
                  <div className="status not-connected">Not connected</div>
                  <button onClick={connectGoogle}>Connect Google</button>
                </>
              )}
            </div>
          </div>
          {/* <div className="social-ac-list-item">
            <div className="list-item-inner-content">
              <div className="logo">
                <img src={images.facebookicon} alt="facebook icon" />
              </div>
              <div className="name">Facebook</div> */}
          {/* {connections.facebook && (
                <>
                  <div className="status">Connected</div>
                  <button className="disconnect" onClick={disconnectFacebook}>
                    Disconnect Facebook
                  </button>
                </>
              )} */}
          {/* {!connections.facebook && (
                <>
                  <div className="status not-connected">Not connected</div>
                  <button onClick={connectFacebook}>Connect Facebook</button>
                </>
              )} */}
          {/* </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ConnectSocial;
