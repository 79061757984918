import React, {Component} from 'react';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Layout from 'layout';
import RootContext from 'context/RootContext';
import userService from 'services/user';
import modalConfig from 'modals/modalConfig';
import validators from 'data/validators';
import './ResetPassword.css';

const {forgetPasswordByEmail} = userService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;
const {__required, __email} = validators;

class ResetPassword extends Component {
  static contextType = RootContext;
  state = {
    values: {
      email: '',
    },
    errors: {},
  };

  forgetPasswordHandler = async () => {
  
    const {setGlobal} = this.context;
    const {history} = this.props;
    const {values: formValues} = this.state;
    const response = await forgetPasswordByEmail(formValues).catch(this.handleError);
    if (response) {
      const {
        message,
        data: {userType = 'provider'},
      } = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            let pathToGo = '/';
            if (userType === 'provider') {
              pathToGo = '/list-your-practice';
            }
            history.push(pathToGo);
          },
        }),
      );
    }
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  changeHandler = event => {
    let {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'email':
        value=value?value.toLowerCase():value
        errorObject = {
          [name]: this.__validateEmail(value),
        };
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  // validation methods
  __validateEmail = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  __validateForm = () => {
    const validEmail = !this.__validateEmail();
    return validEmail;
  };

  getGeneralInputProps = controlName => {
    const {values: formValues, errors: formErrors} = this.state;
    return {
      onChange: this.changeHandler,
      onBlur: this.changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  render() {
    const isValidForm = this.__validateForm();
    return (
      <Layout>
        <div className="ResetPassword">
          <div className="reset-container">
            <div className="h3">Reset Password</div>
            <div className="paragraph">
              Enter the email address you use to log in to your account. We'll send you a link to
              reset your password.
            </div>
            <form onSubmit={(e)=>{e.preventDefault(); return false;}} className="reset-form">
              <Input
                {...this.getGeneralInputProps('email')}
                translationType="transform"
                label="Email"
                type="email"
               
              />
              <div className="reset-btn">
                <CircleButton
                  disabled={!isValidForm}
                  onClick={this.forgetPasswordHandler}
                  type="submit"
                  btnType="whiteButton">
                  Reset Password
                </CircleButton>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ResetPassword;
