import React, {useState} from 'react';
import images from 'data/images';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import ElasticSearch from 'components/FormControls/ElasticSearch';
import SelectDate from 'components/FormControls/SelectDate';
import momentHelper from 'data/moment/momentHelper';
import {useHistory} from 'react-router-dom';
import InsuranceFilterSelect from 'components/FormControls/InsuranceFilterSelect';
import './AppointmentConnecting.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import InsuranceFilterSelectPlan from 'components/FormControls/InsuranceFilterSelect/InsuranceFilterSelectPlan';
import {setOrUpdateLocalData} from 'data/trackFlowQuestions.helper';
const {dateOrCurrentDate, checkIsDateAfterOrToday, dateFormat} = momentHelper;
const {Analytics} = analyticsData;

const AppointmentConnecting = props => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    values: {
      selectedResult: null,
      zipCode: '',
      insurance: null,
      date: dateOrCurrentDate(),
    },
    errors: {},
  });

  const {values: formValues, errors: formErrors} = formData;

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'selectedResult':
        break;
      case 'zipCode':
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const dateConfig = {
    inputProps: {label: ''},
    format: 'MMM DD, YYYY',
    datePickerProps: {
      name: 'date',
      value: formValues['date'],
      isValidDate: checkIsDateAfterOrToday,
    },
    disableViewModeChange: true,
    onDateChange: changeHandler,
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  const handleRedirect = () => {
    Nux.sendEvent(Analytics.Category.PatientLanding, Analytics.Actions.Click, 'findDoctor');

    const {selectedResult} = formValues;
    localStorage.setItem('SearchValueKey', selectedResult.name);
    //setOrUpdateLocalData("searchValue",selectedResult.name)
    const {date, ...otherFilter} = formValues;
    const filter = {
      date: formValues['date'].format('YYYY-MM-DD'),
      ...otherFilter,
    };
    let finalData = {
      pathname: `/doctors`,
      state: {
        filter,
        answers: [],
        specialityIds: [],
      },
    };
    if (selectedResult) {
      switch (selectedResult.type) {
        case 'specialty':
          if (selectedResult.algo) {
            finalData.pathname = `/specialist/${selectedResult.algo}/${selectedResult.name}`;
          }
          break;
        case 'doctor':
          finalData.pathname = `/doctor`;
          break;
        case 'symptom':
          if (selectedResult.algo) {
            finalData.pathname = `/symptom/${selectedResult.algo}/${selectedResult.name}`;
          }
          break;
        case 'procedure':
          if (selectedResult.specialityIds) {
            if (selectedResult.algo) {
              finalData.pathname = `/procedure/${selectedResult.algo}/${selectedResult.name}`;
            } else {
              finalData.pathname = `/doctors`;
            }
          }
          break;
        default:
          break;
      }
    }
    history.push(finalData);
  };
  const pageScrollDown = divName => {
    document.querySelector(`#${divName}`).scrollIntoView({
      behavior: 'smooth',
    });
  };
  const handleStaticRedirect = type => {
    Nux.sendEvent(Analytics.Category.PatientLanding, Analytics.Actions.Click, 'specialtyIcon');
    let today = dateFormat(new Date().toString(), 'YYYY-MM-DD');
    let historyState = {
      date: today,
      selectedResult: {
        type: 'specialty',
        algo: type,
        specialityIds: [],
      },
      zipCode: '',
      insurance: null,
    };
    switch (type) {
      case 'physicalTherapist':
        historyState.selectedResult.id = '15';
        historyState.selectedResult.name = 'Physical Therapist';
        break;
      case 'podiatrist':
        historyState.selectedResult.id = '9';
        historyState.selectedResult.name = 'Podiatrist';
        break;
      case 'orthopedicSurgeon':
        historyState.selectedResult.id = '10';
        historyState.selectedResult.name = 'Orthopedic Surgeon';
        break;
      case 'neurologist':
        historyState.selectedResult.id = '13';
        historyState.selectedResult.name = 'Neurologist';
        break;
      case 'chiropractor':
        historyState.selectedResult.id = '16';
        historyState.selectedResult.name = 'Chiropractor';
        break;
      case 'dentist':
        historyState.selectedResult.id = '22';
        historyState.selectedResult.name = 'Dentist';
        break;
      case 'dermatologist':
        historyState.selectedResult.id = '23';
        historyState.selectedResult.name = 'Dermatologist';
        break;
      case 'primaryCarePhysician':
        historyState.selectedResult.id = '18';
        historyState.selectedResult.name = 'Primary Care Physician';
        break;
      default:
        break;
    }
    history.push({
      pathname: `/specialist/${type}`,
      state: historyState,
      answers: [],
      specialityIds: [],
    });
  };

  return (
    <div className="AppointmentConnecting">
      <div className="Ac-left">
        <h1 className="heading1">Connecting you with the right care</h1>
        <div className="sub-text">
          Find the exact care you need, from exactly the right doctors.
        </div>
        <div className="Appointment-form">
          <form>
            <div className="Appointment-filed-control">
              <span className="label-text">Search</span>
              <ElasticSearch
                name="selectedResult"
                onSelect={changeHandler}
                selectedResult={formValues['selectedResult']}
              />
            </div>

            <div className="Appointment-filed">
              <div className="Appointment-filed-control zip-code-control">
                <span className="label-text">Location</span>
                <Input
                  {...getGeneralInputProps('zipCode')}
                  placeholder="Zip Code"
                  containerClass="zip-code"
                />
              </div>
              <div className="Appointment-filed-control select-date-contorl">
                <span className="label-text">Date</span>
                <SelectDate {...dateConfig} />
              </div>
              <div className="Appointment-filed-control">
                <span className="label-text">Insurance</span>
                {/* <InsuranceFilterSelect
                  inputPlaceholder="Select Insurance"
                  containerClass="chooseinsurance"
                  insurance={formValues['insurance']}
                  name="insurance"
                  onChange={changeHandler}
                /> */}
                <InsuranceFilterSelectPlan
                  inputPlaceholder="Select Insurance"
                  containerClass="chooseinsurance"
                  insurance={formValues['insurance']}
                  name="insurance"
                  onChange={changeHandler}
                />
              </div>
            </div>
            <CircleButton disabled={!formValues['selectedResult']} onClick={handleRedirect}>
              <span className="search-icon">
                <img src={images.searchWhite} alt="search" />
              </span>
              Find Doctors
            </CircleButton>
          </form>
        </div>
        <div className="doctor-specialist-content">
          {/* <div className="dsc-item" onClick={() => handleStaticRedirect('primaryCarePhysician')}>
            <span>
              <img src={images.stethoscope} alt="stethoscopet" />
            </span>
            <span className="dsc-name">General Practitioners</span>
          </div> */}
          <div className="dsc-item" onClick={() => handleStaticRedirect('physicalTherapist')}>
            <span>
              <img src={images.physicalTherapist} alt=" Physical therapist" />
            </span>
            <span className="dsc-name">Physical Therapists</span>
          </div>
          <div className="dsc-item" onClick={() => handleStaticRedirect('podiatrist')}>
            <span>
              <img src={images.podiatrists} alt="" />
            </span>
            <span className="dsc-name">Podiatrists</span>
          </div>
          <div className="dsc-item" onClick={() => handleStaticRedirect('orthopedicSurgeon')}>
            <span>
              <img src={images.orthopedicSurgeon} alt=" Orthopedic Surgeon" />
            </span>
            <span className="dsc-name">Orthopedic Surgeons</span>
          </div>
          <div className="dsc-item" onClick={() => handleStaticRedirect('neurologist')}>
            <span>
              <img src={images.neurologist} alt=" Neurologist" />
            </span>
            <span className="dsc-name">Neurologists</span>
          </div>
          <div className="dsc-item" onClick={() => handleStaticRedirect('chiropractor')}>
            <span>
              <img src={images.chiropractorSquare} alt=" Chiropractors" />
            </span>
            <span className="dsc-name">Chiropractors</span>
          </div>
          {/* <div className="dsc-item"  onClick={() => handleStaticRedirect('dentist')}>
            <span>
              <img
                src={images.dentistSquare}
                alt="Dentist"               
              />
            </span>
            <span className="dsc-name">Dentists</span>
          </div> */}
          {/* <div className="dsc-item" onClick={() => handleStaticRedirect('dermatologist')}>
            <span>
              <img src={images.dermatologist} alt="dermatologist" />
            </span>
            <span className="dsc-name">Dermatologists</span>
          </div> */}
          <div className="dsc-item" onClick={() => handleStaticRedirect('orthopedicSportsSurgeon')}>
            <span>
              <img src={images.orthopedicSportsSurgeon} alt="orthopedicSportsSurgeon" />
            </span>
            <span className="dsc-name">Orthopedic Sports Surgeon</span>
          </div>
        </div>

        <span onClick={() => pageScrollDown('doctorTodayContainer')} className="scroll-down">
          Scroll down to learn more
        </span>
      </div>
    </div>
  );
};

export default AppointmentConnecting;
