import React, {Component} from 'react';
import images from 'data/images';
import {Link} from 'react-router-dom';
import './NextVideoAppointment.css';
import _ from 'lodash';
import momentHelper from 'data/moment/momentHelper';
import moment from 'moment';
import config from 'config/config';

const {utcToLocalMoment} = momentHelper;

class NextVideoAppointment extends Component {
  componentDidMount() {
    if (this.props.nextId) {
      window.addEventListener('resize', this.props.showCount());
    }
    if (document.querySelector('.appoinment-heading')) {
      document.querySelector('.appoinment-heading').addEventListener('click', this.showAppoinment);
    }
  }

  showAppoinment = e => {
    document.querySelector('.appoinment-detail').classList.toggle('show-appoinment');
    this.props.showCount();
  };

  isValidAppointmentTime = appointmentDetail => {
    const appointmentStartDateTime = _.get(appointmentDetail, 'appointmentStartDateTime', '');
    const appointmentEndDateTime = _.get(appointmentDetail, 'appointmentEndDateTime', '');
    const apStartDateTimeObj = utcToLocalMoment(appointmentStartDateTime);
    const apEndDateTimeObj = utcToLocalMoment(appointmentEndDateTime);
    let startDiff = moment().diff(apStartDateTimeObj, 'minutes');
    let endDiff = moment().diff(apEndDateTimeObj, 'minutes');
    if (startDiff > -5 && endDiff <= 0) {
      return true;
    }
    return false;
  };

  render() {
    const {upcomingAppointment, nextId} = this.props;
    let firstname = _.get(upcomingAppointment, 'patient.firstName', '');
    let lastname = _.get(upcomingAppointment, 'patient.lastName', '');
    let fullname = `${firstname} ${lastname}`;
    let appointmentStartDateTime = utcToLocalMoment(upcomingAppointment.appointmentStartDateTime);
    let appointmentEndDateTime = utcToLocalMoment(upcomingAppointment.appointmentEndDateTime);
    let patientUser = _.get(upcomingAppointment, 'patient.user', '');
    let patientUserId = _.get(patientUser, 'id', '');
    const patientInRoom = _.get(upcomingAppointment, 'patientInRoom', false);
    const showJoinVisit = config.CHECK_VISIT_VALIDATION
      ? this.isValidAppointmentTime(upcomingAppointment)
      : true;
    const appointmentDetail = (
      <>
        <div className="appname-block">
          <div className="appname-left">
            <span className="patient-name">{fullname}</span>
            <span className="patient-link" onClick={() => this.props.onViewPatient(patientUserId)}>
              View Patient
            </span>
          </div>
          <div className="appoinment-time">{appointmentStartDateTime.format('h:mm a')}</div>
        </div>
        <div className={`link-actions ${!showJoinVisit ? 'no-room-txt' : ''}`}>
          {showJoinVisit && moment().isBefore(appointmentEndDateTime) && (
            <Link to={`/video-visit?appointment=${nextId}`} className="start-videolink">
              Start Video Visit
            </Link>
          )}
          <span className="noroom-txt">{patientInRoom ? 'In Room' : 'Not In Room'}</span>
        </div>
      </>
    );
    return (
      <div className="NextVideoAppointment">
        <div className="upcoming-appoinment">
          <div className="appoinment-heading">
            <img className="schedule-icon" src={images.scheduleIcon} alt="scheduleIcon" />
            <span>Upcoming Appointment</span>
            <img className="down-arrow" src={images.arrowDownBlue} alt="arrow-down-blue" />
          </div>
          <div className="appoinment-detail">
            {nextId ? appointmentDetail : <p>No Upcoming Appointments</p>}
          </div>
        </div>
      </div>
    );
  }
}

export default NextVideoAppointment;
