import React, {useState, useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import _ from 'lodash';
import CircleButton from 'components/CircleButton';
import images from 'data/images';
import validators from 'data/validators';
import allRegex from 'data/regex';
import RootContext from 'context/RootContext';
import config from 'config/config';
import helper from 'data/helper';
import './Joinkalypsys.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import modalConfig from 'modals/modalConfig';
import ScreenOne from './ScreenOne';
import ScreenTwo from './ScreenTwo';
import ScreenThree from './ScreenThree';
import userService from 'services/user';
const {exceptDigitsRegex, nameRegex,numberOnly} = allRegex;
const {__required, __phoneNumber, __email, __maxLength,__isNumeric} = validators;
const {MAX_TEXT_LENGTH} = config;
const {scrollToDiv} = helper;
const {Analytics} = analyticsData;
const {errorModalData} = modalConfig.modalFunctions;
const Joinkalypsys = props => {
  const {formSubmit,scrollToJoinkalypsys} = props;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const context = useContext(RootContext);
  const {specialties} = context;
  const {verifyEmail,getReferredByPracticeIdDetails} = userService;
  const values = {
    firstName: '',
    lastName: '',
    specialtyId: '',
    phoneNumber: '',
    zipCode: '',
    email: '',
    idProof: '',
    npiNumber: '',
  };
  const errors = {
    firstName: null,
    lastName: null,
    specialtyId: null,
    phoneNumber: null,
    zipCode: null,
    email: null,
    idProof: null,
    npiNumber:null,
  };
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const [photoidPreview, setPhotoidPreview] = useState('');
  const [imageName, setImageName] = useState('Photo ID');
  const [displayForm, setDisplayForm] = useState(1);
  const [chekcTC, setChekcTC] = useState(false)
  const {values: formValues, errors: formErrors} = formData;
  const [practiceId,setPracticeId]=useState(null);
  ///const [providerId,setProviderId]=useState(null);
  const [referredPracticeDetails,setReferredPracticeDetails]=useState(null)
  useEffect(() => {
    const firstName = query.get('firstName') || '';
    const lastName = query.get('lastName') || '';
    const zipCode = query.get('zipCode') || '';
    const npiNumber= query.get('npiNumber') || '';
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          firstName,
          lastName,
          zipCode,
          npiNumber
        },
      };
    });
    setPracticeId(query.get('practiceId') || null);
    //setProviderId(query.get('providerId') || null);
    
    if(query.get('practiceId')){
      getReferredByPracticeDetailsFn(query.get('practiceId'))
    }
    if (firstName && lastName && zipCode) {
      scrollToDiv('Joinkalypsys');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let specialtiesString = query.get('specialties') || '';
    specialtiesString = specialtiesString.toLowerCase();
    const specialtiesStringList = specialtiesString.split(',');
    const specilityObject = _.find(specialties, sp => {
      return specialtiesStringList.includes(sp.specialty.toLowerCase());
    });
    if (specilityObject) {
      setFormData(prevState => {
        return {
          ...prevState,
          values: {
            ...prevState.values,
            specialtyId: specilityObject.id,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialties]);
  const getReferredByPracticeDetailsFn=async(id)=>{
    const {data}=await getReferredByPracticeIdDetails(id);
    if(data){
      setReferredPracticeDetails(data)
      setFormData(prevState => {
        return {
          ...prevState,
          values: {
            ...prevState.values,
            firstName:data.firstName,
            lastName:data.lastName,
            email:data.email,
            phoneNumber:data.phoneNumber,
            specialtyId:data.specialtyId,
            //specialtyId:data.practiceName,
            zipCode:data.zipCode,
           // npiNumber
          },
        };
      });
      setTimeout(() => {
       
          scrollToDiv('Joinkalypsys');
       
      }, 100);
    }
  }
  const handleError = error => {
    const {setGlobal} = context;
    let {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  // Validation functions
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'First name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'Last name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateSpecialty = (value = null) => {
    const fValue = value !== null ? value : formValues['specialtyId'];
    if (!__required(fValue)) {
      return 'Specialty is required';
    }
    return null;
  };
  const __validateSpecialtyNew = (value = null) => {
    const fValue = value !== null ? value : formValues['newSpecialtyRequest'];
    if (!__required(fValue)) {
      return 'Your Specialty is required';
    }
    return null;
  };
  const __validatePhoneNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['phoneNumber'];
    if (!__required(fValue)) {
      return 'Phone number is required';
    }
    if (!__phoneNumber(fValue)) {
      return 'Invalid Phone number';
    }
    return null;
  };
  const __validateNpiNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['npiNumber'];
    if (!__required(fValue)) {
      return 'NPI number is required';
    }
    if (!numberOnly.test(fValue)) {
      return 'NPI number is not valid.';
    }    
    if(!__isNumeric(fValue)){
      return `NPI number is not valid.`;
    }
    if (fValue.toString().length!==10) {
      return `NPI number is not valid.`;
    }
    return null;
  };
  const __validateZipCode = (value = null) => {
    const fValue = value !== null ? value : formValues['zipCode'];
    if (!__required(fValue)) {
      return 'Zip code is required';
    }
    if (!__maxLength(fValue, 10)) {
      return `Max 10 characters`;
    }
    return null;
  };
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  const __validatePhotoID = () => {
    if (formValues['idProof'] === '' || !formValues['idProof']) return 'Photo Id is required';
    return null;
  };
  const validateFile = fileObj => {
    const docTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/PNG',
      'image/JPEG',
      'image/JPG',
      'application/pdf',
    ];
    const allowedTypes = [...docTypes];
    return allowedTypes.includes(fileObj.type);
  };
  const handleFileSelection = async event => {
    const {files} = event.target;
    if (files.length > 0) {
      const newFile = event.target.files[0];
      if (!validateFile(newFile)) {
        handleError({message: 'Only jpg, jpeg, png and pdf file is allowed'});
        return;
      }
      if (!newFile.name.endsWith('.pdf')) setPhotoidPreview(URL.createObjectURL(newFile));
      else {
        setPhotoidPreview('');
      }
      setImageName(newFile.name);
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          ['idProof']: newFile,
        },
        errors: {
          ...formData.errors,
          // ...errorObject,
        },
      });
    }
    // return null;
  };
  const __validateForm = () => {
    const validFirstName = !__validateFirstName();
    const validLastName = !__validateLastName();
    const validSpecialty = !__validateSpecialty();
    const validPhoneNumber = !__validatePhoneNumber();
    const validZipCode = !__validateZipCode();
    const validEmail = !__validateEmail();
    const photoId = !__validatePhotoID();
    const npiNumber = !__validateNpiNumber();
    const validSpecialtyNew =
      validSpecialty && formValues['specialtyId'] === 'null' ? !__validateSpecialtyNew() : true;
    if (displayForm === 1) {
      return validFirstName && validLastName && validSpecialty && validSpecialtyNew && validEmail;
    } else if (displayForm === 2) {
      return true;
    } else if (displayForm === 3) {
      return validPhoneNumber && validZipCode && photoId && npiNumber&&chekcTC;
    }
    return (
      validFirstName &&
      validLastName &&
      validSpecialty &&
      validPhoneNumber &&
      validZipCode &&
      validEmail &&
      validSpecialtyNew &&
      photoId
    );
  };

  const changeHandler = event => {
    const {name} = event.target;
    let {value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'specialtyId':
        errorObject = {
          [name]: __validateSpecialty(value),
        };
        break;
      case 'newSpecialtyRequest':
        errorObject = {
          [name]: __validateSpecialtyNew(value),
        };
        break;
      case 'phoneNumber':
        value = value.replace(exceptDigitsRegex, '');
        errorObject = {
          [name]: __validatePhoneNumber(value),
        };
        break;
      case 'zipCode':
        errorObject = {
          [name]: __validateZipCode(value),
        };
        break;
      case 'npiNumber':
        errorObject = {
          [name]: __validateNpiNumber(value),
        };
        break;
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };
  useEffect(() => {
    if (displayForm === 2) {
      setTimeout(() => {
        if (displayForm === 2) {
          setDisplayForm(3);
        }
      }, 10000);
    }
  }, [displayForm]);
  const resetForm = () => {
    setDisplayForm(1);
    setFormData({
      values,
      errors,
    });
    setImageName('Photo ID');
    setPhotoidPreview('');
  };

  const handleForm = async () => {
    if (displayForm === 1) {
      const isExist = await verifyEmail({email: formValues['email']}).catch(handleError);
      if (!isExist) {
        return false;
      }
    }
    if (displayForm < 3) {
      setDisplayForm(displayForm + 1);
      return;
    }

    Nux.sendEvent(Analytics.Category.DoctorLanding, Analytics.Actions.Click, 'joinUsSubmit');
    let passData = {...formValues};
    if (passData.specialtyId === 'null') {
      passData.specialtyId = null;
    } else if (passData.newSpecialtyRequest) {
      delete passData.newSpecialtyRequest;
    }
    if(practiceId){
      passData.referredPracticeId=practiceId
    }
    formSubmit(passData, resetForm);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const isValidForm = __validateForm();
  let displayComponent = (
    <ScreenOne
      getGeneralInputProps={getGeneralInputProps}
      specialties={specialties}
      formValues={formValues}
    />
  );

  switch (displayForm) {
    case 1:
      displayComponent = (
        <ScreenOne
          getGeneralInputProps={getGeneralInputProps}
          specialties={specialties}
          formValues={formValues}
        />
      );
      break;
    case 2:
      displayComponent = <ScreenTwo />;
      break;
    case 3:
      displayComponent = (
        <ScreenThree
          getGeneralInputProps={getGeneralInputProps}
          photoidPreview={photoidPreview}
          imageName={imageName}
          chekcTC={chekcTC}
          ontcChange={(c)=>setChekcTC(c)}
          handleFileSelection={handleFileSelection}
        />
      );
      break;
    default:
      displayComponent = (
        <ScreenOne
          getGeneralInputProps={getGeneralInputProps}
          specialties={specialties}
          formValues={formValues}
        />
      );
      break;
  }

  return (
    <div
      className="Joinkalypsys"
      style={{
        backgroundImage: `url(${images.groupdoctor}),url(${images.groupdoctor2})`,
        backgroundSize: '300px',
        backgroundPosition: '10%,85%',
      }}>
      <div className="container">
        <div id="signup" className="title text-center">
          <div className="heading2">List your practice today!</div>
          <span>Try Kaly for free for 3 months!</span>
          {referredPracticeDetails &&(
            <div style={{marginTop:'10px'}}>
          <span style={{fontSize:'18px',fontWeight:'normal'}}>Referred by <b>{referredPracticeDetails.providerName}</b></span>
          </div>
          )}
        </div>
        <div className="join-form">
          <form>
            {displayComponent}
            <div className="text-center joinform-action">
              <CircleButton onClick={handleForm} disabled={!isValidForm} btnType="whiteButton">
                {displayForm === 1
                  ? 'Get Started'
                  : displayForm == 2
                  ? 'Continue'
                  : displayForm === 3
                  ? 'Finish'
                  : 'Get Started'}
              </CircleButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Joinkalypsys;
