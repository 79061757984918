import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import axiosInstance from 'config/axios';
import momentHelper from 'data/moment/momentHelper';
import helper from 'data/helper';
import cookieHelper from 'data/cookie.helper';
const {getCookie} = cookieHelper;
const {detect} = require('detect-browser');
const browser = detect();
const {getLocationAwait} = helper;
const {utcToLocalMoment} = momentHelper;

export const searchCalls = {};
export const patientsearchCalls = {};
// patient apis
const patientSignUp = data => {
  if (window.Intl) {
    data.timezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return axiosInstance.post(`v1/patient-signup`, data);
};

const patientSocialLogin = data => {
  if (window.Intl) {
    data.timezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return new Promise(async (resolve, reject) => {
    let passData = {...data};
    let ipData = await getLocationAwait();
    passData.screenWidth = window.screen.width;
    passData.screenHeight = window.screen.height;
    passData.countryId = ipData?.loc_details?.country_code;
    passData.location = ipData?.loc_details?.location;
    passData.postalCode = ipData?.loc_details?.zip;
    passData.ipAddress = ipData?.loc_details?.ip;
    passData.platform = browser.os;
    passData.browser = browser.name;
    passData.version = browser.version;
    let kdid = getCookie('kdid');
    if (kdid) {
      passData.kdid = kdid;
    }
    resolve(axiosInstance.post(`v1/patient-signup/social`, passData));
  });
  //return ;
};

const getFamilyMembers = () => {
  return axiosInstance.get(`v1/patient`);
};

const reserveAppointment = data => {
  return axiosInstance.post(`v1/appointment/request`, data);
};

const savePatient = data => {
  return axiosInstance.post(`v1/patient`, data);
};
const findConsultRequestPractice = data => {
  return axiosInstance.post(`v1/master/consultRequest/findConsultRequestPractice`, data, {
    timeout: 5000,
  });
};

const consultRequestCreate = data => {
  return axiosInstance.post(`v1/master/consultRequest/create`, data);
};
const consultRequestcancel = token => {
  return axiosInstance.get(`v1/master/consultRequest/cancel/${token}`);
};
const searchForAlgoAndDoctorList = searchText => {
  // cancel same previous request
  const apiUrl = 'v1/search/name';
  const callRequest = () => {
    if (searchCalls[apiUrl]) {
      searchCalls[apiUrl].cancel('');
      delete searchCalls[apiUrl];
    }
    searchCalls[apiUrl] = axios.CancelToken.source();
    // noloader to renove loader from this api
    return axiosInstance.get(`${apiUrl}/${searchText}`, {
      cancelToken: searchCalls[apiUrl].token,
      noLoader: true,
    });
  };
  return callRequest();

  // var CancelToken = axiosInstance.CancelToken;
  // var call1 = CancelToken.source();
  // // noloader to renove loader from this api
  // return axiosInstance.get(`v1/search/name/${searchText}`, {
  //   cancelToken: call1.token,
  //   noLoader: true,
  // });
};

const getMyAppointments = appointmentType => {
  return axiosInstance.get(`v1/appointment/${appointmentType}`);
};

const saveAppointmentInsurance = data => {
  const {appointmentId, ...otherData} = data;
  const formData = new FormData();
  if (otherData.isSaveToProfile) {
    otherData.medicalFrontPhoto = otherData.insuranceCardFrontPhoto;
    otherData.medicalBackPhoto = otherData.insuranceCardBackPhoto;
  }
  _.map(otherData, (value, index) => {
    formData.append(index, value);
  });

  return axiosInstance.post(`v1/appointment/insurance/${appointmentId}`, formData);
};

const cancelAppointment = data => {
  return axiosInstance.put(`v1/appointment/cancel`, data);
};

const updateAppointmentTime = data => {
  return axiosInstance.put(`v1/appointment/reschedule`, data);
};

const reviewAppointment = data => {
  return axiosInstance.post(`v1/review`, data);
};

const updateProfile = data => {
  return axiosInstance.put(`v1/user/information`, data);
};

const getNotificationSettings = data => {
  return axiosInstance.get(`v1/notification/setting`);
};

const updateNotificationSettings = data => {
  return axiosInstance.put(`v1/notification/setting`, data);
};

const getMyProfileInsurance = () => {
  return axiosInstance.get(`v1/insurance/patient`);
};

const updateDemographicInformation = data => {
  return axiosInstance.put(`v1/user/demographic-information`, data);
};

const updateHipaaPolicy = data => {
  return axiosInstance.put(`v1/user/hipaa-authorization`, data);
};
const inActivateMyAccount = data => {
  return axiosInstance.get(`v1/user/inactive`);
};

const getMyNotifications = data => {
  return axiosInstance.post(`v1/notification/list`, data).then(addPastStatus);
};

const clearCompletedNotification = data => {
  return axiosInstance.get(`v1/notification/clear-completed`);
};

const getMyNotificationCount = () => {
  return axiosInstance.get(`v1/notification/count`);
};

const getAppointmentDetailsById = appointmentId => {
  return axiosInstance.get(`v1/appointment/details/${appointmentId}`);
};
const getAppointmentDetailsByIdPublic = appointmentId => {
  return axiosInstance.get(`v1/developer/getappointmentbyid/${appointmentId}`);
};
const updateMyProfileInsurance = data => {
  const formData = new FormData();
  _.map(data, (value, index) => {
    formData.append(index, value);
  });
  return axiosInstance.post(`v1/insurance/patient`, formData);
};

const sendVerificationCode = data => {
  return axiosInstance.post(`v1/patient/phone-number/send-code`, data);
};

const verifyPhoneNumber = data => {
  return axiosInstance.post(`v1/patient/phone-number/verify`, data);
};

const verifyPatientEmailByToken = token => {
  return axiosInstance.get(`v1/patient-signup/verify-email/${token}`);
};

const deleteAccount = () => {
  return axiosInstance.delete(`v1/patient`);
};
const addPastStatus = response => {
  const notificationList = _.get(response, 'data', []);
  const newNotificationList = notificationList.map(notification => {
    const appointmentStartDateTime = utcToLocalMoment(notification.appointmentStartDateTime);
    notification.isPast = appointmentStartDateTime.isBefore(moment());
    return notification;
  });
  response.data = newNotificationList;
  return response;
};
const getAllPatientsSearchall = text => {
  const apiUrl = 'v1/patient/search-all';
  const callRequest = () => {
    if (patientsearchCalls[apiUrl]) {
      patientsearchCalls[apiUrl].cancel('');
      delete patientsearchCalls[apiUrl];
    }
    patientsearchCalls[apiUrl] = axios.CancelToken.source();
    // noloader to renove loader from this api
    return axiosInstance.get(`${apiUrl}/${text}`, {
      cancelToken: patientsearchCalls[apiUrl].token,
      noLoader: true,
    });
  };
  return callRequest();
};
const updatepatientphonenumberotp = data => {
  return axiosInstance.put(`v1/patient-signup/updatepatientphonenumberotp`, data);
};
const verifyPatientPhoneNumberOtp = async data => {
  let passData = {...data};
  let ipData = await getLocationAwait();
  passData.screenWidth = window.screen.width;
  passData.screenHeight = window.screen.height;
  passData.countryId = ipData?.loc_details?.country_code;
  passData.location = ipData?.loc_details?.location;
  passData.postalCode = ipData?.loc_details?.zip;
  passData.ipAddress = ipData?.loc_details?.ip;
  passData.platform = browser.os;
  passData.browser = browser.name;
  passData.version = browser.version;
  let kdid = getCookie('kdid');
  if (kdid) {
    passData.kdid = kdid;
  }
  return axiosInstance.post(`v1/patient-signup/verifyPatientPhoneNumberOtp`, passData);
};
const resendotpphonenumber = data => {
  return axiosInstance.put(`v1/patient-signup/resendotpphonenumber`, data);
};
const resendOtpEmail = data => {
  return axiosInstance.post(`v1/resend/otp`, data);
};

export default {
  patientSignUp,
  patientSocialLogin,
  getFamilyMembers,
  reserveAppointment,
  savePatient,
  searchForAlgoAndDoctorList,
  getMyAppointments,
  saveAppointmentInsurance,
  cancelAppointment,
  updateAppointmentTime,
  reviewAppointment,
  updateProfile,
  getNotificationSettings,
  updateNotificationSettings,
  getMyProfileInsurance,
  updateMyProfileInsurance,
  updateDemographicInformation,
  updateHipaaPolicy,
  inActivateMyAccount,
  getMyNotifications,
  getMyNotificationCount,
  clearCompletedNotification,
  getAppointmentDetailsById,
  sendVerificationCode,
  verifyPhoneNumber,
  verifyPatientEmailByToken,
  deleteAccount,
  getAllPatientsSearchall,
  findConsultRequestPractice,
  consultRequestCreate,
  consultRequestcancel,
  updatepatientphonenumberotp,
  verifyPatientPhoneNumberOtp,
  resendotpphonenumber,
  resendOtpEmail,
  getAppointmentDetailsByIdPublic,
};
