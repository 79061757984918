import React, {useContext} from 'react';
import Textarea from 'components/FormControls/Textarea';
import simpleWidget from 'data/widgets/simpleWidget';
import MyLink from 'components/MyLink';
import config from 'config/config';
import images from 'data/images';
import clipboard from 'clipboard';
import './WidgetTab.css';
import AuthContext from 'context/AuthContext';
import _ from 'lodash';

const WidgetTab = props => {
  new clipboard('.copy-link');
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const {practice} = auth;
  const seoPageUrl = _.get(practice, 'seoPageUrl', false);
  const link = seoPageUrl || config.APP_URL;

  return (
    <div className="WidgetTab box-content">
      <div className="h3">Practice Booking Widget</div>
      <div className="subtitle">
        Copy and paste the below snippet to your website to add the below button on your website.
        Patients will be brought to your
        <MyLink to={link}> practice page</MyLink> to book an appointment with a provider.
      </div>
      <div className="widget-inner-content">
        <Textarea
          containerClass="widget-textarea copy-booking"
          value={simpleWidget(link)}
          readOnly={true}
        />
        <span
          className="copy-link"
          data-clipboard-action="copy"
          data-clipboard-target=".copy-booking">
          Copy source code
        </span>
      </div>
      <div className="booking-widget">
        <div className="label-text">Sample Booking Widget:</div>
        <button>
          <img src={images.logoIcon} alt="logo" />
          Book Appointment
        </button>
        <div className="GoogleMyBusinessAppointment">
        <h4>Google My Business Appointment Link.</h4>
        <p className="subtitle">Please remember to add your practice page <a style={{color:"#26acda"}} href={link} target="_blank" rel="noopener noreferrer">link</a> to your Google My Business appointment listing.  </p>
        </div>
      </div>
    </div>
  );
};

export default WidgetTab;
