import React, {Component} from 'react';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import Layout from 'layout';
import Loader from 'components/Loader';
import RootContext from 'context/RootContext';
import userService from 'services/user';
import modalConfig from 'modals/modalConfig';
import validators from 'data/validators';
import constants from 'data/constants';
import config from 'config/config';
import './NewPassword.css';

const {verifyResetToken, resetPassword} = userService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;
const {__required, __password, __eq, __maxLength} = validators;
const {DEFAULT_MESSAGES} = constants;
const {MAX_TEXT_LENGTH} = config;

class NewPassword extends Component {
  static contextType = RootContext;
  state = {
    values: {
      password: '',
      confirmPassword: '',
    },
    errors: {},
    isTokenVerified: false,
  };

  componentDidMount() {
    this.verifyToken();
  }

  getToken = () => {
    const {match} = this.props;
    const {params} = match;
    const {token} = params;
    return token;
  };

  verifyToken = async () => {
    const token = this.getToken();
    const response = await verifyResetToken(token).catch(error => {
      const {history} = this.props;
      const {setGlobal} = this.context;
      const {message} = error;
      setGlobal(
        'modal',
        errorModalData(message, {
          callBackOnClose: () => {
            history.push('/');
          },
        }),
      );
      return null;
    });
    if (response) {
      this.setState({isTokenVerified: true});
    }
  };

  resetPasswordHandler = async () => {
    const {setGlobal} = this.context;
    const {history} = this.props;
    const {values: formValues} = this.state;
    const token = this.getToken();
    const response = await resetPassword({...formValues, token}).catch(this.handleError);
    if (response) {
      const {
        message,
        data: {userType = 'provider'},
      } = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            let pathToGo = '/';
            if (userType === 'provider') {
              pathToGo = '/list-your-practice';
            }
            history.push(pathToGo);
          },
        }),
      );
    }
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  changeHandler = event => {
    const {name, value} = event.target;
    const {values}=this.state
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'password':
        errorObject = {
          [name]: this.__validatePassword(value),
        };
        if(value && values.confirmPassword){
          errorObject['confirmPassword']=this.__validateConfirmPassword(values.confirmPassword,value)
          
        }
        break;
      case 'confirmPassword':
        errorObject = {
          [name]: this.__validateConfirmPassword(value),
        };
        // if(value && values.password){
        //   errorObject['password']=this.__validatePassword(values.password)
        // }
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  // validation methods
  __validatePassword = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['password'];
    if (!__required(fValue)) {
      return 'Password is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (!__password(fValue)) {
      return 'Invalid password';
    }
    return null;
  };
  __validateConfirmPassword = (value = null,pValue=null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['confirmPassword'];
    if (!__required(fValue)) {
      return 'Confirm Password is required';
    }
    if (!__eq(fValue,pValue||formValues['password'])) {
      return 'Password and confirm password must be same';
    }
    return null;
  };
  __validateForm = () => {
    const validPassword = !this.__validatePassword();
    const validConfirmPassword = !this.__validateConfirmPassword();
    return validPassword && validConfirmPassword;
  };

  getGeneralInputProps = controlName => {
    const {values: formValues, errors: formErrors} = this.state;
    return {
      onChange: this.changeHandler,
      onBlur: this.changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  render() {
    const {isTokenVerified} = this.state;
    if (!isTokenVerified) {
      return <Loader />;
    }
    const isValidForm = this.__validateForm();
    const passwordLabelComponent = (
      <>
        New Password{' '}
        <span className="password-instruction-text">
          ({DEFAULT_MESSAGES.PASSWORD_INSTRUCTION_TEXT})
        </span>
      </>
    );
    return (
      <Layout hideHeaderButtons={false}>
        <div className="NewPassword">
          <div className="newpassword-container">
            <div className="h3">Set New Password</div>
            <div className="paragraph">
              Enter and confirm your new password. You’ll be able to log in after you tap confirm.
            </div>
            <form className="reset-form">
              <input type="hidden" name="password" value="password" />
              <Input
                {...this.getGeneralInputProps('password')}
                type="password"
                label={passwordLabelComponent}
                icon={images.eyeView}
              />
              <Input
                {...this.getGeneralInputProps('confirmPassword')}
                type="password"
                label="Confirm Password"
                icon={images.eyeView}
              />
              <div className="confirm-btn">
                <CircleButton
                  disabled={!isValidForm}
                  onClick={this.resetPasswordHandler}
                  btnType="whiteButton">
                  Confirm
                </CircleButton>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NewPassword;
