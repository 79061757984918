import React, { Component } from "react";
import Chat from 'twilio-chat';


class VideoMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            username: this.props.username,
            channel: null,
            text: '',
            media: '',
            errors: [],
            loading: null
        }
    }

    addMessage = (message) => {
        const messageData = {
            body: message.body,
            author: message.author || 'system',
            type: message.type || 'system',
            dateTime: new Date().toString()
        }
        this.setState({
            messages: [...this.state.messages, messageData],
        })
    }

    getToken = () => {
        return new Promise((resolve, reject) => {
            this.addMessage({ body: `Connecting...` });
            resolve(this.props.token)
        })
    }

    addError = (error) => {
        this.setState({
            errors: [...this.state.errors, error.message],
        })
    }

    componentDidMount = () => {
        window.addEventListener("beforeunload", this.chatLogout);
        this.getToken()
            .then(this.createChatClient)
            .then(this.joinGeneralChannel)
            .then(this.configureChannelEvents)
            .catch((error) => {
                console.log('==============error1', error);
                this.addError(error);
            })
    }

    componentWillUnmount() {
        this.chatLogout();
    }

    createChatClient = () => {
        return new Promise((resolve, reject) => {
            resolve(new Chat.create(this.props.token))
        })
    }

    chatLogout = () => {
        if (this.state.channel) {
            this.state.channel.leave();
            if (this.state.channel.members.length < 1) {
                this.state.channel.delete();
            }
        }
        return true;
    }

    joinGeneralChannel = (chatClient) => {
        return new Promise((resolve, reject) => {
            chatClient.getSubscribedChannels().then(() => {
                chatClient.getChannelByUniqueName(this.props.roomName).then((channel) => {
                    this.addMessage({ body: `Joining ${this.props.roomName} channel...` })
                    this.setState({ channel })
                    channel.join().then(() => {
                        this.addMessage({ body: `Joined ${this.props.roomName} channel as ${this.props.username}` })
                    }).catch((error) => {
                        console.log('==============error2', error);
                        this.addError(error);
                        reject(Error('Could not join general channel.'))
                    })
                    resolve(channel)
                }).catch(() => this.createGeneralChannel(chatClient))
            }).catch((error) => {
                console.log('==============error3', error);
                this.addError(error);
                reject(Error('Could not get channel list.'))
            })
        })
    }

    createGeneralChannel = (chatClient) => {
        return new Promise((resolve, reject) => {
            this.addMessage({ body: `Creating ${this.props.roomName} channel...` })
            chatClient
                .createChannel({ uniqueName: this.props.roomName })
                .then(this.joinGeneralChannel(chatClient))
                .then(this.configureChannelEvents)
                .catch((error) => {
                    console.log('==============error4', error);
                    this.addError(error);
                    reject(Error('Could not create general channel.'))
                })
        })
    }

    configureChannelEvents = (channel) => {
        channel.on('messageAdded', async (msgData) => {
            let { author, body, type } = msgData;
            this.setState({ loading: 1 });
            if (type === "media") {
                await msgData.media.getContentTemporaryUrl().then(function (url) {
                    body = `<a href="${url}" download><b>Media Link</b></a>`;
                })
            }
            this.setState({ loading: null });
            this.addMessage({ author, body, type });
        })

        channel.on('memberJoined', (member) => {
            this.addMessage({ body: `${member.identity} has joined the channel.` })
        })

        channel.on('memberLeft', (member) => {
            this.addMessage({ body: `${member.identity} has left the channel.` })
        })
    }

    handleNewMessage = () => {
        if (this.state.channel) {
            this.state.channel.sendMessage(this.state.text);
            this.setState({ text: '' });
        }
    }

    updateText = (event) => {
        this.setState({ text: event.target.value })
    }

    handleNewMedia = () => {
        if (this.state.channel) {
            const formData = new FormData();
            formData.append('file', this.state.media);
            // send media with all FormData parsed atrtibutes
            this.state.channel.sendMessage(formData);
            this.setState({ media: '' });
        }
    }

    updateMedia = (event) => {
        this.setState({ media: event.target.files[0] })
    }

    render() {
        const messageHistory = this.state.messages.map((msgData, index) => (
            (msgData.type === 'system') ?
                (<div key={index} className="chat system-chat">
                    {msgData.body}
                </div>)
                :
                (<div key={index} className={msgData.author === this.props.username ? "chat my-chat" : "chat remote-chat"}>
                    <span className="name">{msgData.author === this.props.username ? "ME" : msgData.author}: </span>
                    <span className="text" >
                        {(msgData.type === 'media') ?
                            <a href={msgData.body} download><b>Media Link</b></a>
                            : msgData.body}</span>
                    <span className="date">{msgData.dateTime}</span>
                </div>)
        ));

        const errorsHistory = this.state.errors.map((er, index) => (
            <li key={index}>{er}</li>
        ));

        return <div className="message">
            <div className="message-history">
                {messageHistory}
                {(this.state.loading === 1) ? <div className="loading">Message sending....</div> : ''}
            </div>
            <div className="message-action">
                <input type="text" className="send-msg-text" rows="1" value={this.state.text} onChange={this.updateText} />
                <button type="button" className="send-msg-btn" onClick={this.handleNewMessage}>Send</button>
                <br /><br />
                <input type="file" className="send-msg-text" rows="1" onChange={this.updateMedia} />
                <button type="button" className="send-msg-btn" onClick={this.handleNewMedia}>Send Media</button>
                <br /><br />
                <button type="button" className="send-msg-btn" onClick={this.chatLogout}>Logout</button>
            </div>
            <div className="error-box">
                <h4>Error Console</h4>
                <ul>
                    {this.state.errors.length > 0 ? errorsHistory : null}
                </ul>
            </div>
        </div>
    }
}
export default VideoMessage;