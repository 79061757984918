const questionList = [
  {
    id: 4601,
    question: 'What type of treatment are you looking for?',
    options: [
      {nextQuestionId: 4605, label: 'Routine Cleaning'},
      {nextQuestionId: 4602, label: 'A Specific Procedure', addExtraQuestion: 1},
      {nextQuestionId: 4603, label: 'Urgent Problem', addExtraQuestion: 2},
    ],
    descriptions: [],
  },
  {
    id: 4602,
    question: 'Which Procedure are you looking for?',
    optionType: 4,
    procedureOptionIds: [101, 102, 103, 77, 104, 105, 106, 94],
    nextQuestionId: 4605,
    multiColOption: true,
    descriptions: [],
  },
  {
    id: 4603,
    question: 'Which type of urgent condition do you have?',
    optionType: 5,
    painOptionIds: [16, 17, 18, 19],
    nextQuestionId: 4604,
    descriptions: [],
  },
  {
    id: 4604,
    question: 'How severe is your pain?',
    options: [
      {
        nextQuestionId: 4605,
        label: 'Mild: I can eat and drink with no problem',
        shortLabel: 'Mild Pain',
      },
      {
        nextQuestionId: 4605,
        label: 'Moderate: I am having some difficulty with eating and drinking',
        shortLabel: 'Moderate Pain',
      },
      {
        nextQuestionId: 4605,
        label: 'Severe: It is impossible for me to eat or drink',
        shortLabel: 'Severe Pain',
      },
    ],
    descriptions: [],
  },
  {
    id: 4605,
    question: 'When was your last visit to the dentist?',
    options: [
      {nextQuestionId: 4606, label: 'Less than 6 months ago'},
      {nextQuestionId: 4606, label: '1-2 years ago'},
      {nextQuestionId: 4606, label: 'More than 2 years ago'},
    ],
    descriptions: [],
  },
  {
    id: 4606,
    question: 'Do you get nervous when you go to the dentist?',
    questionType: 4,
    optionType: 0,
    options: [
      {
        nextQuestionId: 4607,
        label: 'Not at all',
        shortLabel: 'Not nervous',
      },
      {
        nextQuestionId: 4607,
        label: 'A little bit',
        shortLabel: 'A little nervous',
      },
      {
        nextQuestionId: 4607,
        label: 'Very nervous',
        shortLabel: 'Very nervous',
      },
    ],
    descriptions: [
      'Your answer may help your dentist accomodate you by scheduling you at an ideal time or potentially offering you other options that may help.',
    ],
  },
  {
    id: 4607,
    question: 'How old is the patient you are booking this appointment for?',
    questionType: 4,
    optionType: 0,
    options: [
      {groupId: 'A7', label: '0-6 years of age', patientsAccepted: 2},
      {
        nextQuestionId: 4608,
        label: '6-12 years of age',
        addExtraQuestion: 1,
      },
      {
        nextQuestionId: 4609,
        label: 'Older than 12 years of age',
        addExtraQuestion: 1,
      },
    ],
    descriptions: [],
  },
  {
    id: 4608,
    question: 'Some adult dentists also see children ages 6-12',
    questionType: 4,
    optionType: 0,
    options: [
      {
        groupId: 'A7',
        label: 'I would like to see listing of only pediatric dentists',
        patientsAccepted: 2,
      },
      {
        groupId: 'A6A7',
        label: 'I would like to see listing of both pediatric and adult dentists',
        patientsAccepted: 3,
      },
    ],
    descriptions: [],
  },
  {
    id: 4609,
    question:
      'Around age 12-13, teenagers lose all their baby teeth and this is the time it may be recommended to begin seeing a dentist for adults',
    questionType: 4,
    optionType: 0,
    options: [
      {
        groupId: 'A7',
        label: 'I would still like to see listing of only pediatric dentists',
        patientsAccepted: 2,
      },
      {
        groupId: 'A6A7',
        label: 'I would like to see listing of both pediatric and adult dentists',
        patientsAccepted: 3,
      },
      {
        groupId: 'A6A7',
        label: 'I would like to see listing of only adult dentists',
        patientsAccepted: 1,
      },
    ],
    descriptions: [],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 4601,
    lastQuestionId: 4609,
    treeDepth: 4,
  },
};
