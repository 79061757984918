const questionList = [
  {
    id: 421,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [12], // painIds,
    nextQuestionId: 422,
    descriptions: [],
  },
  {
    id: 422,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 423,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [{painId: 12, questionId: 64, visitReasonIds: [63], extraQuestion: 1}],
      },
      {
        nextQuestionId: 423,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [{painId: 12, questionId: 65, visitReasonIds: [63], extraQuestion: 1}],
      },
    ],
    descriptions: [],
  },
  {
    id: 423,
    question: 'Are you interested in potentially undergoing surgery?',
    options: [
      {
        nextQuestionId: 424,
        label: 'Yes, in the near future',
        shortLabel: 'Interested in surgery',
      },
      {nextQuestionId: 424, label: 'No, not at this time', shortLabel: 'No surgery'},
    ],
    descriptions: [],
  },
  {
    id: 424,
    question: 'Do you still want to see a Knee Surgeon?',
    descriptions: [
      'A Knee Surgeon is a medical physician (MD/DO) who completed a 1 year fellowship in Knee Surgery or Sports Medicine, after completing a 5 year residency in Orthopedic Surgery. A Knee Surgeon has further training in treating knee conditions that may require surgery.',
    ],
    title: 'Knee Surgeon',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can perform pain injections',
    ],
    options: [
      {groupId: 'T', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 421,
    lastQuestionId: 424,
    treeDepth: 4,
  },
};
