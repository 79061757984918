import React, {useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import Loader from 'components/Loader';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import manageFileUpload from 'services/manageFileUpload';
import moment from 'moment';
import PopConfirm from 'components/Popconfirm';
import Select from 'components/FormControls/Select';
import validators from 'data/validators';
import {toast, ToastContainer} from 'react-toastify';
import Viewer from 'react-viewer';
import ViewPhotoPDF from './viewPhotoPDF';
const {__required} = validators;
//import PopConfirm from 'react-popconfirm'
const {blankModalData} =
  modalConfig.modalFunctions;
const {getMyFiles, deleteUploadFile, updateStatusUploadFile, postFileAdmin} = manageFileUpload;
const categoryList = [
  {id: 1, label: 'Photo ID'},
  {id: 2, label: 'Graduate School Diploma'},
  {id: 3, label: 'Undergraduate School Diploma'},
  {id: 4, label: 'Active State Professional license.'},
  {id: 5, label: 'Post-Graduate Diploma (Residency, Fellowship, etc)'},
  {id: 6, label: 'Hospital Affiliation'},
  {id: 7, label: 'Board Certificate'},
  {id: 8, label: 'Other'},
];

const StatusObj = {
  0: <span className="Pending-text">Pending</span>,
  1: <span className="Approved-text">Approved</span>,
  2: <span className="Rejected-text">Rejected</span>,
};
function ViewDocumentFiles(props) {
  const {userId, userName, fileType, closeModal, data,joinUsId} = props;
  const [docList, setDocList] = useState([]);
  const [visible, setVisible] = React.useState(false);
  const [viewUrl, setViewUrl] = React.useState('');
  const [pageParms, setPageParms] = useState({
    pageSize: 1,
    pageLimit: 100,
    search: '',
    sortBy: 'createdAt',
    orderBy: 'DESC',
  });
  const [showAddNewUpload, setShowAddNewUpload] = useState(false);
  const [photoidPreview, setPhotoidPreview] = useState('');
  const [imageName, setImageName] = useState('File');
  const [formData, setFormData] = useState({
    values: {
      categoryId: _.get(data, 'categoryId', ''),
      document: _.get(data, 'document', ''),
    },
    errors: {serverError: null},
  });
  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const {values: formValues, errors: formErrors} = formData;

  const validateFile = fileObj => {
    const docTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/PNG',
      'image/JPEG',
      'image/JPG',
      'application/pdf',
    ];
    const allowedTypes = [...docTypes];
    return allowedTypes.includes(fileObj.type);
  };
  const __validateDocument = () => {
    if (formValues['document'] === '' || !formValues['document']) return 'File is required';
    return null;
  };
  const handleFileSelection = async event => {
    const {files} = event.target;
    if (files.length > 0) {
      const newFile = event.target.files[0];
      if (!validateFile(newFile)) {
        handleError({message: 'Only jpg, jpeg, png and pdf file is allowed'});
        return;
      }
      if (!newFile.name.endsWith('.pdf')) setPhotoidPreview(URL.createObjectURL(newFile));
      else {
        setPhotoidPreview('');
      }

      setImageName(newFile.name);
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          ['document']: newFile,
        },
        errors: {
          ...formData.errors,
          // ...errorObject,
        },
      });
    }
    // return null;
  };
  const __validateCategory = (value = null) => {
    const fValue = value !== null ? value : formValues['categoryId'];
    if (!__required(fValue)) {
      return 'Category is required';
    }
    return null;
  };
  const __validateotherCategoryName = (value = null) => {
    const fValue = value !== null ? value : formValues['otherCategoryName'];
    if (!__required(fValue)) {
      return 'Your File category is required';
    }
    return null;
  };
  const __validateForm = () => {
    const validateCategory = !__validateCategory();
    const validateDocument = !__validateDocument();
    //const validateAnswer=!__validateAnswer();
    const validSpecialtyNew =
      validateCategory && formValues['categoryId'] === 8 ? !__validateotherCategoryName() : true;
    return validateCategory && validateDocument && validSpecialtyNew;
  };

  const isValidateForm = __validateForm();
  const changeHandler = event => {
    let {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'categoryId':
        errorObject = {
          [name]: __validateCategory(value),
        };
        break;

      case 'otherCategoryName':
        errorObject = {
          [name]: __validateotherCategoryName(value),
        };
        break;
      default:
        break;
    }

    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };
  const handleError = error => {
    const {message} = error;
    toast.error(message);
    return null;
  };

  const confirmDelete = async id => {
    let response = await deleteUploadFile(id).catch(handleError);
    if (response) {
      toast.success(response.message);
      fetchData();
    }
  };
  var getFilename = function (str) {
    return str.substring(str.lastIndexOf('/') + 1);
  };
  useEffect(() => {
    setDocList([]);
  }, [userId]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    // You can await here
    let passData = {...pageParms, userId: userId,joinUsId:joinUsId};
    const response = await getMyFiles({...passData});
    if (response.data) {
      // this.setState({
      //   list: _.get(res, 'data.rows', []),
      //   totalPage:Math.ceil(_.get(res, 'data.count', 0)/pageLimit) ,
      //   totalRecord: _.get(res, 'data.count', 0),
      // });
      setDocList(response.data.rows);
    }
    // ...
  };
  const handleRejectRequest = (userId, reviewId, reject) => {
    confirmRejectRequest(userId, reviewId);
  };
  const confirmRejectRequest = async (userId, reviewId) => {
    const response = await updateStatusUploadFile(userId, reviewId).catch(handleError);
    if (response) {
      toast.success(response.message);
      fetchData();
      // this.updateContextData();
    }
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const {updateList} = props;
    let response = null;
    var formData = new FormData();
    formData.append('document', formValues['document']);
    formData.append('categoryId', formValues['categoryId']);
    if (formValues['categoryId'] === '8' || formValues['categoryId'] === 8)
      formData.append('otherCategoryName', formValues['otherCategoryName']);
    formData.append('userId', userId);

    response = await postFileAdmin(formData).catch(handleError);

    if (response) {
      const {message} = response;
      toast.success(message);
      setShowAddNewUpload(!showAddNewUpload);
      setFormData({
        values: {
          categoryId: '',
          document: '',
        },
        errors: {serverError: null},
      });
      setImageName('File');
      setPhotoidPreview('');
      fetchData();
    }
  };
  return (
    <div className="CreatePasswordModal termsofuse viewPhotoIdmodal">
      <div className="modal-content-header">
        <div className="h3">{userName ? `${userName}'s ` : ''} Documents</div>
        <span
          className="close"
          onClick={() => {
            closeModal();
          }}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      {!showAddNewUpload ? (
        <div style={{width: '100%'}} className="modal-content-body ReviewRequest ">
          <div className="table-overflow">
            <div className="rtable">
              <div className="heading">
                <div className="cell pointer" data-key="name">
                  File #
                </div>

                <div className="cell pointer" data-key="companyName">
                  Category
                </div>
                <div className="cell pointer" data-key="createdAt">
                  Upload Date
                </div>
                <div className="cell pointer" data-key="companyName">
                  Status
                </div>
                <div className="cell edit-cell">Action</div>
              </div>
              {docList.length === 0 && (
                <div className="row" key={`eachQ`}>
                  No files
                </div>
              )}
              {docList.map((item, index) => (
                <div className="row" key={`eachQ${index}`}>
                  <div className="cell">
                    <a style={{color: '#26acda'}}>
                      <span
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          setViewUrl(item.documentLink);
                          setVisible(true);
                        }}
                        className="filenameSpan">
                        {getFilename(item.documentLink)}
                      </span>
                    </a>
                  </div>
                  <div className="cell ">
                    <div style={{display: 'flex', flexFlow: 'column', maxWidth: '250px',wordBreak:"break-all"}}>
                      {categoryList.find(c => c.id == item.categoryId)?.label}
                      {item.categoryId === '8' && (
                        <span style={{fontSize: '15px'}}>
                          <i>{_.get(item, 'otherCategoryName', '')}</i>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="cell">{formatedDate(_.get(item, 'createdAt', ''))}</div>
                  <div className="cell">
                    {StatusObj[item.status]}
                    {item.status === 0 && (
                      <div class="actionbtndev">
                        <PopConfirm
                          text={'Are you sure to Approve'}
                          onConfirm={() =>
                            handleRejectRequest(_.get(item, 'id', ''), '1', 'Approve')
                          }>
                          <button className="btn btn-view">Approve</button>
                        </PopConfirm>
                        <PopConfirm
                          text={'Are you sure to Reject'}
                          onConfirm={() =>
                            handleRejectRequest(_.get(item, 'id', ''), '2', 'Reject')
                          }>
                          <button className="btn btn-delete">Reject</button>
                        </PopConfirm>
                      </div>
                    )}
                  </div>
                  <div className="cell ">
                    {item.status !== 1 && (
                      <PopConfirm
                        text={'Are you sure to Delete'}
                        onConfirm={() => confirmDelete(item.id)}>
                        <img
                          src={images.deleteRound}
                          className="delete-icon delete-confirm-img"
                          alt="delete"
                        />
                      </PopConfirm>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div style={{width: '100%'}} className="modal-content-body admin-uploadfile">
          <div>
            <Select
              {...getGeneralInputProps('categoryId')}
              options={categoryList}
              labelIndex="label"
              valueIndex="id"
              label="Category"
            />
            {formValues['categoryId'] === '8' && (
              <div className="Input-control  form-group">
                <span className="label">Other Category Name</span>
                <input
                  className="form-control"
                  onChange={changeHandler}
                  onBlur={changeHandler}
                  type="text"
                  autoComplete="off"
                  name="otherCategoryName"
                  placeholder="Other Category Name"
                  value={formValues['otherCategoryName']}
                />
                {formErrors['otherCategoryName'] !== '' && (
                  <span class="error-text">{formErrors['otherCategoryName']}</span>
                )}
              </div>
            )}
            <div className="Input-control  form-group">
              <span className="label">File</span>
              <div className="fileupload">
                <div
                  className={['preview-img', !photoidPreview ? '' : 'preview-img-multy'].join(' ')}>
                  {photoidPreview && <img src={photoidPreview} />}
                  <span>{imageName}</span>
                </div>
                <span className="fileupload-btn">
                  <input
                    type="file"
                    onChange={handleFileSelection}
                    onClick={event => {
                      event.target.value = null;
                    }}
                  />

                  <button>Choose file</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="modal-content-footer  ">
        {!showAddNewUpload ? (
          <button onClick={() => setShowAddNewUpload(!showAddNewUpload)} className="Upload-newFile">
            Add File
          </button>
        ) : (
          <>
            <button
              onClick={e => handleSubmit(e)}
              disabled={!isValidateForm}
              className="Upload-newFile">
              Upload
            </button>
            <button
              onClick={() => setShowAddNewUpload(!showAddNewUpload)}
              className="Upload-newFile">
              Cancel
            </button>
          </>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
     <ViewPhotoPDF url={viewUrl} visible={visible} setVisible={(val)=>setVisible(val)} setViewUrl={(val)=>{setViewUrl(val)}} />
    </div>
  );
}
export default function ViewDocumentFile(props) {
  const {userId, userName, closeModal, fileType = `Photo Id`,joinUsId} = props;
  const [loading, setLoading] = useState(true);
  let {setGlobal} = useContext(RootContext);
  const viewFile = (url, userName,joinUsId) => {
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ViewDocumentFiles,
        modalWrapperClass: 'mega-modal',
        customComponentProps: {userId: userId, userName: userName,joinUsId:joinUsId},
      }),
    );
  };
  const onLoad = () => {
    setLoading(false);
  };

  return (
    <>
      <br />
      <img
        onClick={() => viewFile(userId, `${userName}`,joinUsId)}
        className="photoid"
        src={images.file}
        alt="photoID"
      />
    </>
  );
}
