import React from 'react';
import _ from 'lodash';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import {Link} from 'react-router-dom';
import Divider from 'components/Divider';
import helper from 'data/helper';
import MyLink from 'components/MyLink';
import selectOptions from 'data/selectOptions';
import momentHelper from 'data/moment/momentHelper';
import handleAddToCalendar from 'data/addToCalendar';
import './AppointmentConfirmedModal.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import TimeZoneLabelShort from 'components/MyDatetime/TimeZoneLabelShort';

const {getSuffixString,setAppointmentSourceStore} = helper;
const {utcToLocalMoment, readableTimeFormat} = momentHelper;
const {Analytics} = analyticsData;

const AppointmentConfirmedModal = props => {
  const {closeModal} = props;
  const {data, onRescheduleAppointmentModal} = props;
  const {appointment} = data;
  const appointmentStartDateTime = _.get(appointment, 'appointmentStartDateTime', '');
  const appointmentEndDateTime = _.get(appointment, 'appointmentEndDateTime', '');
  const firstName = _.get(appointment, 'patient.user.firstName', '');
  const patient = _.get(appointment, 'patient', {});
  const practiceLocation = _.get(appointment, 'providerAvailability.practiceLocation', {});
  const insurancePlan = _.get(appointment, 'insurancePlan', {});
  const suffixes = _.get(appointment, 'providerAvailability.provider.suffixes', []);
  const specialties = _.get(appointment, 'providerAvailability.provider.specialties', []);
  const seoPageUrl = _.get(appointment, 'providerAvailability.provider.seoPageUrl', null);
  const doctorUser = _.get(appointment, 'providerAvailability.provider.user', []);
  const appointmentDateTime = utcToLocalMoment(appointmentStartDateTime);
  const doctorName = `${doctorUser.firstName} ${doctorUser.lastName}${getSuffixString(suffixes)}`;
  const calendarEvent = {
    title: 'Appointment with ' + doctorName,
    description: '',
    location: practiceLocation.address,
    startTime: appointmentStartDateTime,
    endTime: appointmentEndDateTime,
  };
  return (
    <div className="AppointmentConfirmedModal">
      <div className="modal-content-header">
        <div className="h3">Appointment Confirmed </div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <div className="DoctorAppointmentTime">
          <div className="appointment-time">
            <span>
              <span className="day">{appointmentDateTime.format('dddd')}</span>
              <span className="date">{appointmentDateTime.format('MMM DD')}</span>
              <span className="time"> {readableTimeFormat(appointmentDateTime)}<TimeZoneLabelShort/></span>
            </span>
          </div>
          <div className="dc-info">
            <div className="name">
              Hi {firstName}! Your appointment has been confirmed by the office of{' '}
              <MyLink onClick={()=>setAppointmentSourceStore(1)}  to={seoPageUrl}>{doctorName}</MyLink>
            </div>
            <div className="social-calender">
              <span className="social-calender-title">Add to Calendar</span>
              <div className="social-link">
                <Link
                  to=""
                  onClick={() => {
                    handleAddToCalendar('google', calendarEvent);
                  }}>
                  Google
                </Link>
                <Link
                  onClick={() => {
                    handleAddToCalendar('ical', calendarEvent);
                  }}>
                  iCal
                </Link>
                <Link
                  onClick={() => {
                    handleAddToCalendar('outlook', calendarEvent);
                  }}>
                  Outlook
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="doctor-details">
          <div className="dc-img">
            <img src={doctorUser.profileImageUrl || images.user} alt="doc" />
          </div>
          <div className="doctor-info">
            <div className="doctor-name">
              <div className="h3"> {doctorName}</div>
              <div className="speciality">{_.map(specialties, 'specialty').join(', ')}</div>
            </div>
            <div className="address">
              <div className="icon">
                <img src={images.mapslocation} alt="doc" />
              </div>
              <div className="text">
                <span>{practiceLocation.address}</span>
                <span> {helper.formatPhoneNumber(practiceLocation.officePhone)} </span>
              </div>
            </div>
            <div className="insurance">
              <div className="icon">
                <img src={images.guardian} alt="doc" />
              </div>
              <div className="text">
                {insurancePlan ? insurancePlan.plan : selectOptions.insuranceDefaultOption.label}
              </div>
            </div>
            <div className="paitent">
              <div className="icon">
                <img src={images.profile} alt="doc" />
              </div>
              <div className="text">
                {patient.firstName} {patient.lastName.substr(0, 1)}.
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="reschedule">
          <div className="que"> Need to Reschedule?</div>
          <div className="decription">
            We understand plans change. We want to respect everyone's time and make sure all
            patients and providers have the best experience. If a provider or patient cancels or
            misses appointments then their account could be locked.
          </div>
        </div>
      </div>
      <div className="modal-content-footer">
        <CircleButton
          btnType="secondaryButton"
          onClick={() => {
            Nux.sendEvent(
              Analytics.Category.PatientDashboard,
              Analytics.Actions.Click,
              'rescheduleAppointment',
            );
            onRescheduleAppointmentModal(appointment);
          }}>
          Reschedule Appointment
        </CircleButton>
        <span className="cancel">
          <CircleButton btnType="secondaryButton" onClick={closeModal}>
            Cancel
          </CircleButton>
        </span>
      </div>
    </div>
  );
};

export default AppointmentConfirmedModal;
