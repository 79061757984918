import React, { useCallback, useContext, useEffect, useState } from 'react';
import './EachFeedback.css';
import './rc-slider.css';
import Slider from 'rc-slider';
import Textarea from 'components/FormControls/Textarea';
import momentHelper from 'data/moment/momentHelper';
import TimeZoneLabelShort from 'components/MyDatetime/TimeZoneLabelShort';
import feedbackService from 'services/providerFeedback';
import AuthContext from 'context/AuthContext';
import LikeUnlike from '../LikeUnlike';
var debounce = require('lodash/debounce');
const { utcToLocalMoment } = momentHelper;
const {
  updateFeedback,
  getfeedbackPendingCount
} = feedbackService;
const EachFeedback = props => {
  const authContext = useContext(AuthContext);
  const [comment, setComment] = useState('');
  const [rangeValue, setRangeValue] = useState(null)
  const [passRangeValue, setPassRangeValue] = useState(3)
  const [feedbackStatus, setFeedbackStatus] = useState(1)
  const [showAutoSave, setAutoSave] = useState(false)
  useEffect(() => {
    const { itemDetails } = props
    // let value = itemDetails.appropriateMatch - 1
    // value = value < 0 ? 0 : value
    // value = value * 25
    setRangeValue(itemDetails.appropriateMatch)
    setPassRangeValue(itemDetails.appropriateMatch)
    setFeedbackStatus(itemDetails.appointmentFeedbackStatusId)
    setComment(itemDetails.comments)
  }, [props.itemDetails.appropriateMatch])
  // const onChangerangeValue = (value) => {
  //   setPassRangeValue(formatRangeValue(value))
  //   setRangeValue(value)
  // };
  const onChangerangeValue = (value) => {
    setRangeValue(value)
    // const debounceSave = debounce((value) => {
    //   let val = formatRangeValue(value)
      setPassRangeValue(value)
      updateFeedbackFn(value,"#lk^")
    // }, 500)
    // debounceSave(value)
  }
  const onCommentChangeF = (value) => {
    setComment(value)
    const debounceSave = debounce((value) => {

      updateFeedbackFn(passRangeValue, value)
    }, 500)
    debounceSave(value)
  }

  const formatRangeValue = (value) => {
    if (value > 1)
      return Math.round(value / 25) + 1
    else
      return 1

  }
  const updateFeedbackFn = async (rating = 1, comment = null) => {
    const { id } = props.itemDetails
    let passData = { "id": id, "comments": comment, "appointmentFeedbackStatusId": "2", "appropriateMatch": rating }
    let showLoader = true
    if (!comment) {
      showLoader = false
      
    }
    if(comment==="#lk^"){
      delete passData.comments
    }
    setAutoSave(true)
    let responce = await updateFeedback(passData, showLoader)
    if (responce.code == "success") {
      updateContextData()
      let val = feedbackStatus == 1 ? 2 : feedbackStatus
      
      setFeedbackStatus(val)
    }
    setAutoSave(false)
  };
  const updateContextData = async () => {
    const requestCountData = await getfeedbackPendingCount().catch(() => null);
    if (requestCountData) {
      const { setAdminRequestCountState } = authContext;
      let requestFeedbackCount = requestCountData.data ? requestCountData.data : 0
      setAdminRequestCountState(prevState => {
        return {
          ...prevState,
          requestCount: {
            feedbackPendingCount: requestFeedbackCount || 0
          },
        };
      });
    }
  };
  const { itemDetails } = props

  const appointmentDate = utcToLocalMoment(itemDetails.appointmentDate);
  return (
    <div className="EachFeedback">
      <div className='col-left'>
        <div className={['content', feedbackStatus == 1 ? 'pending' : 'completed'].join(' ')}>
          {/* <div> */}
            <div className='each-q'>
              <label className='title'>Appointment Date</label>
              <label className='ans'> {appointmentDate.format('dddd, MMM DD [at] h:mm a')}<TimeZoneLabelShort /></label>
            </div>
            
          {/* </div>
          <div> */}
            <div className='each-q'>
              <label className='title'>Practice Location</label>
              <label className='ans'>{itemDetails.locationName}</label>
            </div>
            <div className='each-q'>
              <label className='title'>Patient Name</label>
              <label className='ans'>{`${itemDetails.firstName} ${itemDetails.lastName}`}</label>
            </div>
            <div className='each-q'>
              <label className='title'>Status</label>
              <label className='ans'>{feedbackStatus == 1 ? 'Pending' : 'Completed'}</label>
            </div>
          {/* </div> */}
        </div>
      </div>
      <div className='col-right'>

        <div className='each-block'>
          <label className='title'>Was the reason for this patient’s visit an appropriate match for your medical expertise? </label>
          <label className='labeltext'>Please answer with Thumbs Up (strong match) or Thumbs Down (poor match).</label>
          <div className='slider-maindiv'>

            {/* <Slider disabled={props.propsFeedbackStatusId == 2 ? true : false} key={itemDetails.id} onChange={onChangerangeValue} value={rangeValue} min={1} defaultValue={50} marks={{ 0: 1, 25: 2, 50: 3, 75: 4, 100: 5 }} step={null} /> */}
            <LikeUnlike rangeValue={rangeValue} propsFeedbackStatusId={props.propsFeedbackStatusId} onChangerangeValue={onChangerangeValue} />
          </div>
          {props.propsFeedbackStatusId == 1 ? (
            <div style={{ position: "relative" }}>
              <Textarea value={comment} onChange={(e) => onCommentChangeF(e.target.value)} className="textarea-comments" placeholder="Comment" label="Comment"></Textarea>
              {showAutoSave && (
                <span className='auto-save'>Autosave</span>
              )}
            </div>
          ) : (
            <>
              {comment !== '' && (
                <>
                  <label className='comment-label'>Comment</label>
                  <div className='comment-text'>{comment}</div>
                </>
              )}
            </>

          )}
          {/* <Button className="savebtn">Save</Button> */}
        </div>
      </div>
    </div>
  );
};

export default EachFeedback;
