const questionList = [
  {
    id: 281,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14], // painIds,
    nextQuestionId: 282,
    descriptions: [],
  },
  {
    id: 282,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 283,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 84, visitReasonIds: [125], extraQuestion: 1},
          {painId: 2, questionId: 163, visitReasonIds: [124], extraQuestion: 1},
          {painId: 3, questionId: 224, visitReasonIds: [127], extraQuestion: 1},
          {painId: 4, questionId: 43, visitReasonIds: [119], extraQuestion: 1},
          {painId: 5, questionId: 104, visitReasonIds: [120], extraQuestion: 1},
          {painId: 6, questionId: 123, visitReasonIds: [126], extraQuestion: 1},
          {painId: 7, questionId: 144, visitReasonIds: [121], extraQuestion: 1},
          {painId: 8, questionId: 23, visitReasonIds: [129], extraQuestion: 1},
          {painId: 9, questionId: 183, visitReasonIds: [122], extraQuestion: 1},
          {painId: 10, questionId: 24, visitReasonIds: [130], extraQuestion: 2},
          {painId: 11, questionId: 203, visitReasonIds: [123], extraQuestion: 1},
          {painId: 12, questionId: 64, visitReasonIds: [128], extraQuestion: 1},
          {painId: 13, questionId: 203, visitReasonIds: [], extraQuestion: 1},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 283,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 83, visitReasonIds: [125], extraQuestion: 3},
          {painId: 2, questionId: 164, visitReasonIds: [124], extraQuestion: 3},
          {painId: 3, questionId: 225, visitReasonIds: [127], extraQuestion: 1},
          {painId: 4, questionId: 44, visitReasonIds: [119], extraQuestion: 3},
          {painId: 5, questionId: 103, visitReasonIds: [120], extraQuestion: 3},
          {painId: 6, questionId: 124, visitReasonIds: [126], extraQuestion: 3},
          {painId: 7, questionId: 143, visitReasonIds: [121], extraQuestion: 2},
          {painId: 8, questionId: 24, visitReasonIds: [129], extraQuestion: 2},
          {painId: 9, questionId: 184, visitReasonIds: [122], extraQuestion: 3},
          {painId: 10, questionId: 24, visitReasonIds: [130], extraQuestion: 2},
          {painId: 11, questionId: 204, visitReasonIds: [123], extraQuestion: 3},
          {painId: 12, questionId: 65, visitReasonIds: [128], extraQuestion: 1},
          {painId: 13, questionId: 204, visitReasonIds: [], extraQuestion: 3},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 283,
    question: 'Are you considering minimally invasive procedures, such as an injection?',
    options: [
      {
        nextQuestionId: 284,
        label: 'Yes, in the near future.',
        shortLabel: 'Open to invasive procedures',
      },
      {nextQuestionId: 285, label: 'No, not at this time', shortLabel: 'No invasive procedures'},
      // {nextQuestionId: 284, label: 'Not sure'},
    ],
    descriptions: [],
  },
  {
    id: 284,
    question: 'Do you still want to see a Interventional Spine and Pain Specialist?',
    descriptions: [
      'A Interventional Spine and Pain Specialist is a medical physician (MD/DO) who completed a 1 year fellowship in Interventional Spine and Pain Specialist, after completing a 3-4 year residency in Anesthesiology, Physiatry, Neurology, Psychiatry, or Family Medicine.',
      'A Interventional Spine and Pain Physician has further training in treating complex pain conditions through therapeutic injections and minimally invasive surgeries.',
    ],
    title: 'Interventional Spine and Pain Specialist',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can not perform more invasive orthopedic surgeries',
    ],
    options: [
      {groupId: 'P', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
  {
    id: 285,
    question:
      'Would you still like to see a Interventional Spine and Pain Physician or are you interested in something else?',
    questionType: 2,
    options: [
      {nextQuestionId: 284, label: 'Interventional Spine and Pain', isGoToSymptomFlow: false},
      {label: 'I am interested in something else', isGoToSymptomFlow: true},
    ],
    descriptions: [
      'Some Interventional Spine and Pain Physicians will only schedule patients who are interested in undergoing minimally invasive procedures in the near future.',
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 281,
    lastQuestionId: 284,
    treeDepth: 5,
  },
};
