import React, {useContext} from 'react';
import _ from 'lodash';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import CircleButton from 'components/CircleButton';
import AddInsurancePlansModal from '../AddInsurancePlansModal';
import './AcceptedInsurancePlans.css';

const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;

const AcceptedInsurancePlans = props => {
  const {practiceInsurancePlanList, onSaveInsurancePlans} = props;
  const rootContext = useContext(RootContext);

  const openEditModal = () => {
    const {setGlobal, insurancePlans} = rootContext;
    let updatedInsurancePlans = insurancePlans || [];
    let allAcceptedPlans = [];
    _.map(practiceInsurancePlanList, insurance => {
      const acceptedPlans = _.map(insurance.insurancePlans, 'id');
      allAcceptedPlans = [...allAcceptedPlans, ...acceptedPlans];
    });
    updatedInsurancePlans = _.map(updatedInsurancePlans, insurance => {
      insurance.isOpen = true;
      insurance.isShowAllPlans = false;
      insurance.maxShowPlans = 4;
      let selectedCount = 0;
      insurance.insurancePlans = _.map(insurance.insurancePlans, insurancePlan => {
        insurancePlan.isSelected = allAcceptedPlans.includes(insurancePlan.id);
        if (insurancePlan.isSelected) {
          selectedCount++;
        }
        return insurancePlan;
      });
      if (selectedCount === insurance.insurancePlans.length) {
        insurance.isShowAllPlans = true;
      }
      insurance.insurancePlans = _.orderBy(insurance.insurancePlans, ['isSelected'], ['desc']);
      return insurance;
    });
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: AddInsurancePlansModal,
        customComponentProps: {
          data: {instrancePlanList: updatedInsurancePlans},
          onSaveInsurancePlans,
        },
      }),
    );
  };

  const insurancePlansListComponent = practiceInsurancePlanList.map((insurance, index) => {
    return (
      <div className="insuranceplans-item" key={index}>
        <div className="insuranceplans-title">{insurance.companyName}</div>
        <div className="insuranceplans-content">
          {insurance.insurancePlans.map((insurancePlan, i) => {
            return <div key={i}>{insurancePlan.plan}</div>;
          })}
        </div>
      </div>
    );
  });
  return (
    <div className="AcceptedInsurancePlans">
      <div className="box">
        <div className="title">
          <span>Accepted Insurance Plans</span>
          <CircleButton onClick={openEditModal}>Edit</CircleButton>
        </div>
        <div className="insuranceplans-list">{insurancePlansListComponent}</div>
      </div>
    </div>
  );
};

export default AcceptedInsurancePlans;
