import React, {useState} from 'react';
import fileUploadService from 'services/fileUpload';
import helper from 'data/helper';
import './EditPracticeRegistration.css';

const {getFileNameFromUrl} = helper;
const {adminProviderFileUpload} = fileUploadService;

const EditPracticeRegistration = props => {
  const {defaultFileUrl = '', onError, afterUpload, practiceId, hasPermissionToEdit} = props;
  // file input
  const [fileObj, setFile] = useState({
    file: null,
    preview: defaultFileUrl,
  });
  const {preview} = fileObj;

  const handleFileSelection = async event => {
    const {files} = event.target;
    if (files.length > 0) {
      const newFile = event.target.files[0];
      if (!validateFile(newFile)) {
        onError({message: 'Only doc and pdf file is allowed'});
        return;
      }
      const response = await adminProviderFileUpload(newFile, practiceId).catch(onError);
      if (response) {
        const {data} = response;
        const {fileUrl} = data;
        setFile({
          file: newFile,
          preview: fileUrl,
        });
        afterUpload(fileUrl);
      }
    }
    return null;
  };

  const validateFile = fileObj => {
    const docTypes = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const pdfTypes = ['application/pdf'];
    const allowedTypes = [...docTypes, ...pdfTypes];
    return allowedTypes.includes(fileObj.type);
  };

  const fileUrl = preview || defaultFileUrl;
  const fileName = getFileNameFromUrl(fileUrl) || '---';
  return (
    <div className="box-content EditPracticeRegistration">
      <div className="h3">Patient Registration Form</div>
      <div className="EditPracticeRegistration-inner">
        {hasPermissionToEdit && (
          <span className="label-content">
            <span>Upload a form</span>
            <span className="uploadfile-text">pdf or doc file</span>
            {/* <span className="uploadfile-text">Remove</span> */}
          </span>
        )}
        <div className="fileupload">
          {fileName}
          {hasPermissionToEdit && (
            <span className="fileupload-btn">
              <input
                type="file"
                onChange={handleFileSelection}
                onClick={event => {
                  event.target.value = null;
                }}
              />
              <button>Choose file</button>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditPracticeRegistration;
