import React, {Component} from 'react';
import './InfoTemplate.css';

class InfoTemplate extends Component {
  render() {
    return (
      <div className="modal-dialog info">
        <div className="modal-header">
          <div className="title">{this.props.title || 'Information!'}</div>
        </div>
        <div className="modal-body">
          {this.props.description || 'No functionalities working yet.'}
        </div>
      </div>
    );
  }
}

export default InfoTemplate;
