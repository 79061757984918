import React, {Component} from 'react';
import _ from 'lodash';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import adminService from 'services/admin';
import AuthContext from 'context/AuthContext';
import ReactPaginate from 'react-paginate';
import constants from 'data/constants';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
//import './Patients.css';
import images from 'data/images';
import moment from 'moment-timezone';

const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  searchText: '',
  sortBy: 'loginTimestamp',
  orderBy: 'desc',
  totalRecord: 0,
};
const {errorModalData} = modalConfig.modalFunctions;
const {getLoginLog} = adminService;
const {DefaultDebounceMSec} = constants;

class Loginlog extends Component {
  static contextType = AuthContext;
  context = null;
  state = {...initialState};

  componentDidMount() {
    this.getLoginLogData(false);
  }

  getLoginLogData = async (noLoad = false) => {
    const {activePage, searchText, sortBy, orderBy} = this.state;
    const response = await getLoginLog(
      {
        search: searchText,
        page: activePage,
        limit: 10,
        sortBy: sortBy,
        orderBy: orderBy,
      },
      noLoad,
    ).catch(this.handleError);
    if (response) {
      const {data} = response;
      this.setState({
        list: data.list,
        totalPage: data.totalPage,
        totalRecord: data.totalRecord,
      });
    }
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.getLoginLogData);
  };
  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.getLoginLogData();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };
  handleSearch = () => {
    this.setState({activePage: 1}, this.getLoginLogData);
  };
  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.getLoginLogData);
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    //console.log("dataset",dataset);
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.getLoginLogData,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.getLoginLogData);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  render() {
    const title = 'Login Log';
    const {list, totalPage, searchText, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    // wait for api data
    if (!list) {
      return null;
    }
    // list rows
    const defaultText = '--';
    let tableOutput = '';
    let listingComponent = _.map(list, (item, index) => {
      const {
        id = defaultText,
        firstName = defaultText,
        lastName = defaultText,
        email = defaultText,
        gender = defaultText,
        status = defaultText,
        loginLog={},
        
      } = item;
      const {browser=defaultText,
        version=defaultText,
        ipAddress=defaultText,
        platform=defaultText,
        postalCode=defaultText,
        screenHeight=defaultText,
        screenWidth=defaultText,failedLogin}=loginLog
      return (
        <tr key={`${index}-log`} data-id={id}>
          <td className="text-cap">{`${firstName}`}</td>
          <td className="text-cap">{`${lastName}`}</td>
          <td>{email}</td>
          <td>{loginLog.userType.type}</td>
          <td>{loginLog.loginTimestamp?moment(loginLog.loginTimestamp).format('YYYY-MM-DD h:mm a'):defaultText}</td>
          <td>{failedLogin?'Failed':'Success'}</td>
          <td>{loginLog.countryId?loginLog.countryId:defaultText}</td>
          <td>{browser}</td>
          <td>{version}</td>
          <td>{ipAddress}</td>
          <td>{platform}</td>
          <td>{postalCode}</td>
          <td>{screenHeight}</td>
          <td>{screenWidth}</td>
        </tr>
      );
    });
    if (list.length === 0) {
      tableOutput = <div className="no-record">No Records found</div>;
    } else {
      tableOutput = (
        <div className="table-overflow">
          <table className="admin-provider-list">
            <thead>
              <tr>
                <td className="pointer" data-key="firstName" onClick={this.handleSortingChange}>
                FirstName{this.renderShortIcon('firstName')}
                </td>
                <td className="pointer" data-key="lastName" onClick={this.handleSortingChange}>
                LastName{this.renderShortIcon('lastName')}
                </td>
                <td className="pointer" data-key="email" onClick={this.handleSortingChange}>
                Email{this.renderShortIcon('email')}
                </td>
                <td className="pointer" data-key="type" onClick={this.handleSortingChange}>
                UserType{this.renderShortIcon('type')}
                </td>
                <td style={{width:"150px"}} className="pointer" data-key="loginTimestamp" onClick={this.handleSortingChange}>
                <span data-key="loginTimestamp" onClick={this.handleSortingChange} style={{display: "block",width: "147px"}}>Login Date Time {this.renderShortIcon('loginTimestamp')}</span>
                </td>
                <td className="pointer" data-key="countryId" >
                Login Status
                </td>
                <td className="pointer" data-key="countryId" onClick={this.handleSortingChange}>
                CountryID{this.renderShortIcon('countryId')}
                </td>
                <td className="pointer" data-key="browser" onClick={this.handleSortingChange}>
                Browser{this.renderShortIcon('browser')}
                </td>
                <td className="pointer" data-key="version" onClick={this.handleSortingChange}>
                Browser Version{this.renderShortIcon('version')}
                </td>
                <td className="pointer" data-key="ipAddress" onClick={this.handleSortingChange}>
                IP{this.renderShortIcon('ipAddress')}
                </td>
                <td className="pointer" data-key="platform" onClick={this.handleSortingChange}>
                Platform{this.renderShortIcon('platform')}
                </td>
                <td className="pointer" data-key="postalCode" onClick={this.handleSortingChange}>
                Postal Code{this.renderShortIcon('postalCode')}
                </td>
                <td className="pointer" data-key="screenHeight" onClick={this.handleSortingChange}>
                Screen Height{this.renderShortIcon('screenHeight')}
                </td>
                <td className="pointer" data-key="screenWidth" onClick={this.handleSortingChange}>
                Screen Width{this.renderShortIcon('screenWidth')}
                </td>
              </tr>
            </thead>
            <tbody>{listingComponent}</tbody>
          </table>
        </div>
      );
    }
    return (
      <Layout>
        <RootContext.Consumer>
          {context => {
            this.context = context;
          }}
        </RootContext.Consumer>
        <div className="AdminPatients">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div>
                <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
                {searchText && (
                  <button className="btn-clear" onClick={this.handleClearSearch}>
                    <img src={images.close} alt="clear" />
                  </button>
                )}
              </div>
              <button onClick={this.handleSearch}>Search</button>
            </div>
            <div className="dataCount">
              <span>{totalRecord} records found.</span>
            </div>
            {tableOutput}
            {totalPage > 0 && list.length>0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Loginlog;
