const questionList = [
  {
    id: 2201,
    question: 'What type of treatment are you looking for?',
    options: [
      // {nextQuestionId: 2205, label: 'Routine Cleaning and Checkup', procedureId: 113},
      {nextQuestionId: 2202, label: 'A Specific Procedure', addExtraQuestion: 1},
      {nextQuestionId: 2203, label: 'Urgent Problem', addExtraQuestion: 2},
    ],
    descriptions: [],
  },
  {
    id: 2202,
    question: 'Which Procedure are you looking for?',
    optionType: 4,
    procedureOptionIds: [
      70,
      71,
      72,
      73,
      74,
      75,
      76,
      77,
      78,
      79,
      80,
      81,
      82,
      83,
      84,
      85,
      86,
      87,
      88,
    ],
    nextQuestionId: 2205,
    multiColOption: true,
    descriptions: [],
  },
  {
    id: 2203,
    question: 'Which type of urgent condition do you have?',
    optionType: 5,
    painOptionIds: [16, 17, 18],
    nextQuestionId: 2204,
    descriptions: [],
  },
  {
    id: 2204,
    question: 'How severe is your pain?',
    options: [
      {
        nextQuestionId: 2205,
        label: 'Mild: I can eat and drink with no problem',
        shortLabel: 'Mild Pain',
      },
      {
        nextQuestionId: 2205,
        label: 'Moderate: I am having some difficulty with eating and drinking',
        shortLabel: 'Moderate Pain',
      },
      {
        nextQuestionId: 2205,
        label: 'Severe: It is impossible for me to eat or drink',
        shortLabel: 'Severe Pain',
      },
    ],
    descriptions: [],
  },
  {
    id: 2205,
    question: 'When was your last visit to the dentist?',
    options: [
      {nextQuestionId: 2206, label: 'Less than 6 months ago'},
      {nextQuestionId: 2206, label: '1-2 years ago'},
      {nextQuestionId: 2206, label: 'More than 2 years ago'},
    ],
    descriptions: [],
  },
  {
    id: 2206,
    question: 'Do you get nervous when you go to the dentist?',
    questionType: 4,
    optionType: 0,
    options: [
      {
        nextQuestionId: 2207,
        label: 'Not at all',
        shortLabel: 'Not nervous',
      },
      {
        nextQuestionId: 2207,
        label: 'A little bit',
        shortLabel: 'A little nervous',
      },
      {
        nextQuestionId: 2207,
        label: 'Very nervous',
        shortLabel: 'Very nervous',
      },
    ],
    descriptions: [
      'Your answer may help your dentist accomodate you by scheduling you at an ideal time or potentially offering you other options that may help.',
    ],
  },
  {
    id: 2207,
    question: 'Are you happy with your smile? Your dentist may discuss options that may help.',
    optionType: 0,
    questionType: 4,
    options: [
      {
        groupId: 'A6',
        label: 'Yes, I am happy with my smile',
        shortLabel: 'Happy with smile',
        isGoToSymptomFlow: false,
      },
      {
        groupId: 'A6',
        label: 'No, I am interested in discussing treatment options',
        shortLabel: 'Interested in smile treatments',
        isGoToSymptomFlow: false,
      },
    ],
    descriptions: [],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 2201,
    lastQuestionId: 2207,
    treeDepth: 4,
  },
};
