const questionList = [];

export default {
  questionList,
  config: {
    firstQuestionId: 1301,
    lastQuestionId: 1313,
    treeDepth: 3,
    groupIdNewRedirect:[38,35]
  },
};
