import React, {Component} from 'react';
import _ from 'lodash';

import RootContext from 'context/RootContext';

class RootContextWrapper extends Component {
  state = {};

  setGlobal = (index, newData) => {
    const {[index]: oldData} = this.state;
    if (!_.isEqual(oldData, newData)) {
      this.setState({
        [index]: newData,
      });
    }
  };

  setGlobalState = callback => {
    this.setState(callback);
  };

  render() {
    const {children} = this.props;
    return (
      <RootContext.Provider
        value={{...this.state, setGlobal: this.setGlobal, setGlobalState: this.setGlobalState}}>
        {children}
      </RootContext.Provider>
    );
  }
}

export default RootContextWrapper;
