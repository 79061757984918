const DAYS = {
  SU: 0,
  M: 1,
  T: 2,
  W: 3,
  R: 4,
  F: 5,
  S: 6,
};

const DEFAULT_MESSAGES = {
  PASSWORD_INSTRUCTION_TEXT: 'Minimum 8 characters with at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character.',
  RUNNING_APPOINTMENT_CAN_NOT_BE_CANCELLED:
    'The appointment is in progress. You can not cancel the appointment now',
  RUNNING_APPOINTMENT_CAN_NOT_BE_RESCHEDULED:
    'The appointment is in progress. You can not reschedule the appointment now',
  CAN_ONLY_VIEW_CONFIRMED_APPOINTMENT: 'You can only view confirmed appointment',
  REVIEW_ALREADY_SUBMITTED: 'Your review has already been submitted.',
  PERCENTAGE_GREATERTHAN_100: 'Total Percentage should not be greater than 100',
};

const WAIT_TIME_TITLES = [
  'more than 40 min',
  '31-40 min',
  '21-30 min',
  '11-20 min',
  '10 min or less',
];

const StripeStatus = {
  0: 'Inactive',
  1: 'Active',
  2: 'Trial',
  3: 'Requires Payment Method',
  4: 'Requires Action',
};

const InvoiceStatus = {
  0: 'Pending',
  1: 'Paid',
  2: 'Canceled ',
  3: 'Failed',
};

const MaxSeatsToShow = 10;

const Gender = {1: 'Male', 2: 'Female',3:'Non-binary',4:'Rather not say'};

const UserStatus = {0: 'In Process', 1: 'Active', 2: 'Inactive',3:"Deleted"};

const SuffixStatus = {0: 'Inactive', 1: 'Active'};

const CertificationStatus = {0: 'Inactive', 1: 'Active'};

const LanguageStatus = {0: 'Inactive', 1: 'Active'};

const InsuranceCompanyStatus = {0: 'Inactive', 1: 'Active'};

const InsurancePlanStatus = {0: 'Inactive', 1: 'Active'};

const ClaimStatus = {true: 'Yes', false: 'No'};

const ProviderGender = {M: 'Male', F: 'Female'};

const DoctorFilters = {
  AVAILABILITY: 'Availability',
  GENDER: 'Gender',
  LANGUAGE: 'Language',
  SORTBY: 'SortBy',
};

const OtherChangeTypes = {
  1: 'Graduate School',
  2: 'Undergraduate School',
  3: 'Residency/Fellowships/Training ',
  4: 'Hospital Affiliations',
};

const DayIndex = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const DefaultSiteSettings = [
  {
    value: 'https://twitter.com/kalypsys',
    slug: 'siteTwitterAccountUrl',
  },
  {
    value: 'https://www.facebook.com/Kalypsys-866736300337100/',
    slug: 'siteFacebookAccountUrl',
  },
  {
    value: 'https://www.instagram.com/kalypsys',
    slug: 'siteInstagramAccountUrl',
  },
  {
    value: ['hello@kaly.com'],
    slug: 'siteContactUsEmail',
  },
];

const SwitchPreventionRoutes = [
 // '/Reviews/account',
  '/settings',
  // '/reviews',
  // '/reviews/Sharereview',
  // '/reviews/requestreviews',
  // '/reviews/account',
];

const StaticFooterPage = {
  TERMS_CONDITION: 'terms-and-conditions',
  PRIVACY_POLICY: 'privacy-policy',
  HIPAA: 'hipaa-policy',
  CCPA: 'ccpa-policy',
  HEALTHCARE: 'healthcare-provider-agreement',
  REVIEW_POLICY: 'review-policy',
  PRICING: 'pricing',
  FAQ: 'faq',
  COOKIEPOLICY:'cookie-policy'
};

const VideoUserType = {
  PROVIDER: 'provider',
  PATIENT: 'patient',
};

const DefaultDebounceMSec = 800;

const DocumentEmailId = 'mydocuments@kaly.com';

export default {
  DAYS,
  DEFAULT_MESSAGES,
  WAIT_TIME_TITLES,
  MaxSeatsToShow,
  StripeStatus,
  InvoiceStatus,
  Gender,
  UserStatus,
  DayIndex,
  SuffixStatus,
  CertificationStatus,
  LanguageStatus,
  InsuranceCompanyStatus,
  InsurancePlanStatus,
  ClaimStatus,
  ProviderGender,
  DoctorFilters,
  DefaultSiteSettings,
  OtherChangeTypes,
  VideoUserType,
  StaticFooterPage,
  DefaultDebounceMSec,
  SwitchPreventionRoutes,
  DocumentEmailId,
};
