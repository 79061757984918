import React, {Component} from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import _ from 'lodash';
import './ImportProvider.css';
import images from 'data/images';

const initialState = {sampleURL: ''};
const {modalFunctions} = modalConfig;
const {errorModalData, successModalData} = modalFunctions;
const {getJoinUsCSVSampleURL, postJoinUsCSVFile, importxlsx} = adminService;

class ImportProvider extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount() {
    //this.getCSVSampleURL();
  }

  isValidCsvFormat = fileName => {
    try {
      const getExtentions = fileName.lastIndexOf('.');
      const ext = fileName.substring(getExtentions + 1);
      if (ext === 'csv') {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };
  isValidxlsxFormat = fileName => {
    try {
      const getExtentions = fileName.lastIndexOf('.');
      const ext = fileName.substring(getExtentions + 1);
      if (ext === 'xlsx') {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };
  getCSVSampleURL = async () => {
    const res = await getJoinUsCSVSampleURL();
    this.setState({sampleURL: _.get(res, 'data.sampleUrl', '')});
  };

  uploadFile = async file => {
    const {setGlobal} = this.context;
    try {
      let formData = new FormData();
      formData.append('csvFile', file);
      let res = this.props.new ? await importxlsx(formData) : await postJoinUsCSVFile(formData);
      const {message} = res;
      if (
        message == 'provider details uploaded successfully' ||
        message == 'Provider details uploaded successfully'
      )
        setGlobal('modal', successModalData(message));
      else {
        setGlobal('modal', errorModalData(message));
      }
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  exportxlsx = async () => {
    let file = await getJoinUsCSVSampleURL();
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = 'sample.xlsx';
    link.href = URL.createObjectURL(new Blob([file]));
    link.click();
  };
  handleFileUpload = event => {
    const {files} = event.target;
    const {setGlobal} = this.context;
    if (files.length > 0) {
      if (!this.props.new && this.isValidCsvFormat(files[0].name)) {
        this.uploadFile(files[0]);
      } else if (this.props.new && this.isValidxlsxFormat(files[0].name)) {
        this.uploadFile(files[0]);
      } else {
        let fType = this.props.new ? 'xlsx' : 'csv';
        setGlobal(
          'modal',
          errorModalData(
            `You can only select ${fType} file, please select ${fType} file and try again.`,
          ),
        );
      }
    }
  };

  backToProviderList = () => {
    this.props.history.push({
      pathname: '/admin/providers',
    });
  };
  ftype = this.props.new ? 'xlsx' : 'csv';
  render() {
    const {sampleURL} = this.state;
    const title = 'Import Provider';
    return (
      <Layout>
        <div className="ImportProvider">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="back-provider" onClick={this.backToProviderList}>
              <span className="left-arrow">
                <img src={images.arrowLeft} alt="left-arrow" />
              </span>
              Back To All Providers
            </div>
            <div className="fileupload">
              <span className="fileupload-btn">
                <input
                  type="file"
                  accept={`.${this.ftype}`}
                  onChange={this.handleFileUpload}
                  onClick={event => {
                    event.target.value = null;
                  }}
                  title={`By clicking here, you can import a ${this.ftype} file to send the mass invitation to Providers`}
                />
                <button>{`Import ${this.ftype} file`}</button>
              </span>
            </div>
            <div className="note">
              {`You can import a`} <b style={{textTransform: 'capitalize'}}>{`${this.ftype}`}</b>{' '}
              {`file here to send the mass invitation to Providers`}
              <>
                , and you can download the sample file by{' '}
                <a
                  style={{cursor: 'pointer'}}
                  rel="noopener noreferrer"
                  onClick={() => this.exportxlsx()}>
                  click here
                </a>
                .
              </>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ImportProvider;
