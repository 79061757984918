import React from 'react';
import FacebookLoginButton from './FacebookLoginButton';
import GoogleLoginButton from './GoogleLoginButton';
import AppleLoginButton from "./AppleLoginButton"

const SocialButton = props => {
  const {provider} = props;
  let loginButtonComponent = null;
  switch (provider) {
    case 'facebook':
      loginButtonComponent = <FacebookLoginButton {...props} />;
      break;
      case 'google':
        loginButtonComponent = <GoogleLoginButton {...props} />;
        break;
      case 'apple':  
      loginButtonComponent = <AppleLoginButton {...props} />;
    default:
      break;
  }
  return loginButtonComponent;
};

export default SocialButton;
