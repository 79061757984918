import React from 'react';
import _ from 'lodash';
import images from 'data/images';
import momentHelper from 'data/moment/momentHelper';
import helper from 'data/helper';
import './AppointmentItem.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import {Link} from 'react-router-dom';
import moment from 'moment';
import config from 'config/config';
import TimeZoneLabelShort from 'components/MyDatetime/TimeZoneLabelShort';

const {utcToLocalMoment, readableTimeFormat} = momentHelper;
const {
  getSuffixString,
  getAppointmentClassFromStatus,
  focusElasticSearchBox,
  getDistance,
  getAppointmentStatusName,
  setAppointmentSourceStore
} = helper;
const {Analytics} = analyticsData;

const AppointmentItem = props => {
  const {
    appointment,
    appointmentType,
    onViewAppointmentModal,
    onViewInsuranceInfoModal,
    onViewCancelAppointmentModal,
    onViewRescheduleAppointmentModal,
    userLatitude,
    userLongitude,
  } = props;

  const isVirtual = _.get(appointment, 'isVirtual', false);
  const {appointmentStartDateTime, appointmentEndDateTime} = appointment;
  const apStartDateTimeObj = utcToLocalMoment(appointmentStartDateTime);
  const apEndDateTimeObj = utcToLocalMoment(appointmentEndDateTime);
  const appointmentId = _.get(appointment, 'id');
  const status = _.get(appointment, 'status');
  const insuranceCompany = _.get(appointment, 'insuranceCompany');
  const insurancePlan = _.get(appointment, 'insurancePlan');
  const insuranceCardFrontPhotoUrl = _.get(appointment, 'insuranceCardFrontPhotoUrl');
  const insuranceCardBackPhotoUrl = _.get(appointment, 'insuranceCardBackPhotoUrl');
  const insuranceMemberId = _.get(appointment, 'insuranceMemberId');
  const isInsuranceAdded = insuranceCompany && insurancePlan ? true : false;
  const location = _.get(appointment, 'providerAvailability.practiceLocation');
  const provider = _.get(appointment, 'providerAvailability.provider');
  const doctorUser = _.get(provider, 'user', {});
  const suffixes = _.get(provider, 'suffixes', []);
  const specialties = _.get(provider, 'specialties', []);
  const customSpecialty=_.get(provider, 'customSpecialty', null);
  const appointmentStatusClass = getAppointmentClassFromStatus(status);
  const appointmentStatusName = getAppointmentStatusName(status);
  const seoLink = _.get(appointment, 'providerAvailability.provider.seoPageUrl', '');

  let bookNewAppointLinkComponent = null;
  const bookNewAppointment = () => {
    Nux.sendEvent(
      Analytics.Category.PatientDashboard,
      Analytics.Actions.Click,
      'bookNewAppointment',
    );
    if (seoLink) {
      setAppointmentSourceStore(1)
      window.open(seoLink, '_blank');
    } else {
      focusElasticSearchBox();
    }
  };

  if (status === 3 || status === 2) {
    bookNewAppointLinkComponent = (
      <div className="book-appointment" onClick={bookNewAppointment}>
        <span className="span-link">Book New appointment</span>
      </div>
    );
  }
  let distance = null;
  if (userLatitude && userLongitude) {
    distance = getDistance(
      [location.latitude, location.longitude],
      [userLatitude, userLongitude],
    ).toFixed(1);
  }

  const gotoSeoProfile = e => {
    e.preventDefault();
    setAppointmentSourceStore(1)
    if (seoLink) window.open(seoLink, '_blank');
  };

  const virtualVisitComponent = (
    <>
      <span className="video-visit">
        <img src={images.videoBtn} alt="video" /> Video Visit
      </span>
    </>
  );

  const isValidAppointmentTime = () => {
    let startDiff = moment().diff(apStartDateTimeObj, 'minutes');
    let endDiff = moment().diff(apEndDateTimeObj, 'minutes');
    if (startDiff > -5 && endDiff <= 0) {
      return true;
    }
    return false;
  };

  const isValidTime = config.CHECK_VISIT_VALIDATION ? isValidAppointmentTime() : true;
  let specialtyNames = _.map(specialties, 'specialty');
  if(customSpecialty){
    specialtyNames=[...specialtyNames,customSpecialty]
  }
  return (
    <div className={`AppointmentItem ${appointmentStatusClass}`}>
      <div className="ap-dc-info">
        <div className="dc-img" onClick={gotoSeoProfile}>
          <img src={doctorUser.profileImageUrl || images.user} alt="doctor" />
        </div>
        <div className="dc-info">
          <div className="name" onClick={gotoSeoProfile}>
            {doctorUser.firstName} {doctorUser.lastName}
            {getSuffixString(suffixes)}
          </div>
          <div className="dc-specialist" onClick={gotoSeoProfile}>
            {specialtyNames.join(', ')}
          </div>
          {isVirtual ? (
            virtualVisitComponent
          ) : (
            <span className="person-visit">
              <img src={images.personBtn} alt="In Person" /> In-Person
            </span>
          )}
          <div className="address">
            <span className="icon">
              <img src={images.mapslocation} alt="address" />
            </span>
            <span>{location.address}</span>
          </div>
          {appointmentType === 'upcoming' && (
            <>
              {isInsuranceAdded && (
                <div className="dc-guardian">
                  <span className="icon insurance-icon">
                    <img src={images.guardian} alt="guardian" />
                  </span>
                  <div>
                    {_.get(insuranceCompany, 'companyName', '') && (
                      <span>{_.get(insuranceCompany, 'companyName', '')}</span>
                    )}
                    <div>
                      {insurancePlan.plan} {insuranceMemberId}
                    </div>
                  </div>
                </div>
              )}
              {isInsuranceAdded && (!insuranceCardFrontPhotoUrl || !insuranceCardBackPhotoUrl) && (
                <div className="appointment-notification">
                  <img src={images.bell} alt="bell" className="notification-icon" />
                  <span> Prepare for your appointment</span>
                  <span className="ins-info" onClick={() => onViewInsuranceInfoModal(appointment)}>
                    Please provide your insurance Information
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="ap-dc-timeinfo">
        <div className="appointment-time">
          <span className="day">{appointmentStatusName}</span>
          <span className="month">
            {' '}
            {apStartDateTimeObj.format('ddd')}, {apStartDateTimeObj.format('MMM DD')}
          </span>
          <span className="time">{readableTimeFormat(apStartDateTimeObj)}<TimeZoneLabelShort/></span>
        </div>
        {!isVirtual && distance && <div className="distance">{distance} Mi Away</div>}
        {isVirtual && status === 1 && isValidTime && moment().isBefore(apEndDateTimeObj) && (
          <Link to={`/video-visit?appointment=${appointmentId}`} className="start-videolink">
            Start Video Visit
          </Link>
        )}
        {appointmentType === 'upcoming' && (
          <div className="book-appointment">
            <span className="span-link">Modify Appointment</span>
            <div className="appointment-action">
              <span
                className="menu-item"
                onClick={() => onViewRescheduleAppointmentModal(appointment)}>
                Reschedule Appointment
              </span>
              <span
                className="menu-item cancel"
                onClick={() => onViewCancelAppointmentModal(appointment)}>
                Cancel Appointment
              </span>
            </div>
          </div>
        )}
        {[1].includes(status) && (
          <div className="show-appointment-details">
            <span className="span-link" onClick={() => onViewAppointmentModal(appointmentId)}>
              View Appointment Details
            </span>
          </div>
        )}
        {bookNewAppointLinkComponent}
      </div>
    </div>
  );
};

export default AppointmentItem;
