const modalData = {
  modalName: 'error',
  showModal: true,
  closeButton: true,
  closeOnBGClick: true,
  callBackOnClose: () => {},
  options: {description: null},
  CustomComponent: null,
  customComponentProps: {},
};

const errorModalData = (description = null, otherOptions = {}, title = null) => {
  const updatedModelData = {
    ...modalData,
    ...otherOptions,
    options: {...modalData.options, description, title},
  };
  return updatedModelData;
};

const warningModalData = (description = null, otherOptions = {}, title = null) => {
  const updatedModelData = {
    ...modalData,
    ...otherOptions,
    modalName: 'warning',
    options: {...modalData.options, description, title},
  };
  return updatedModelData;
};

const successModalData = (description = null, otherOptions = {}, title = null) => {
  const updatedModelData = {
    ...modalData,
    ...otherOptions,
    modalName: 'success',
    options: {...modalData.options, description, title},
  };
  return updatedModelData;
};

const confirmModalData = (otherOptions = {}) => {
  const updatedModelData = {
    ...modalData,
    ...otherOptions,
    modalName: 'confirm',
    closeButton: false,
    options: {...modalData.options, description: ''},
  };
  return updatedModelData;
};

const blankModalData = (otherOptions = {}) => {
  const updatedModelData = {
    ...modalData,
    ...otherOptions,
    modalName: 'blank',
    closeButton: false,
    options: {...modalData.options, description: ''},
  };
  return updatedModelData;
};

const infoModalData = (description = null, otherOptions = {}, title = null) => {
  const updatedModelData = {
    ...modalData,
    ...otherOptions,
    modalName: 'info',
    options: {...modalData.options, description, title},
  };
  return updatedModelData;
};

const agreeModalData = (description = null, otherOptions = {}, title = null) => {
  const updatedModelData = {
    ...modalData,
    ...otherOptions,
    modalName: 'agree',
    disagreeButton: true,
    options: {...modalData.options, description, title},
  };
  return updatedModelData;
};

const modalFunctions = {
  errorModalData,
  warningModalData,
  successModalData,
  confirmModalData,
  blankModalData,
  infoModalData,
  agreeModalData,
};
export default {
  defaultModalData: modalData,
  modalFunctions,
};
