import React, {useState} from 'react';
import AboutYourPracticeStepOne from 'components/SignUpSteps/AboutYourPractice/AboutYourPracticeStepOne';
import AboutYourPracticeStepTwo from 'components/SignUpSteps/AboutYourPractice/AboutYourPracticeStepTwo';

const AboutYourPractice = props => {
  const {nextStep, email, data: stepData, token} = props;
  const [formData, setFormData] = useState({
    step: 1,
    data: stepData,
  });
  const {step, data} = formData;
  const {subStep1Data, subStep2Data} = data;

  const handleError = () => {
    // reset step to 1 if we get error from api
    setFormData(prevState => {
      return {
        ...prevState,
        step: 1,
      };
    });
  };

  const changeStep = subStepData => {
    if (step === 1) {
      setFormData({
        ...formData,
        step: step + 1,
        data: {
          ...formData.data,
          subStep1Data: {
            ...subStepData,
          },
        },
      });
    } else {
      nextStep({subStep1Data, subStep2Data: {...subStepData}}, handleError);
    }
  };
  return (
    <div className="AboutYourPractice">
      {step === 1 && (
        <AboutYourPracticeStepOne
          token={token}
          email={email}
          data={subStep1Data}
          nextStep={changeStep}
        />
      )}
      {step === 2 && <AboutYourPracticeStepTwo data={subStep2Data} nextStep={changeStep} />}
    </div>
  );
};

export default AboutYourPractice;
