import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import validators from 'data/validators';
import Checkbox from 'components/FormControls/Checkbox';
import config from 'config/config';
import constants from 'data/constants';
import allRegex from 'data/regex';
import './FinalizeAccount.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const { nameRegex } = allRegex;
const { __required, __password, __eq, __maxLength } = validators;
const { MAX_TEXT_LENGTH } = config;
const { DEFAULT_MESSAGES } = constants;
const { Analytics } = analyticsData;

const FinalizeAccount = props => {
  const { nextStep, data, email } = props;
  const values = { ...data };
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const { values: formValues, errors: formErrors } = formData;

  // Validation functions
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'First name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'Last name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validatePassword = (value = null) => {
    const fValue = value !== null ? value : formValues['password'];
    if (!__required(fValue)) {
      return 'Password is required';
    }
    if (!__password(fValue)) {
      return 'Invalid password';
    }
    return null;
  };
  const __validateConfirmPassword = (value = null) => {
    const fValue = value !== null ? value : formValues['confirmPassword'];
    if (!__required(fValue)) {
      return 'Confirm Password is required';
    }
    if (!__eq(fValue, formValues['password'])) {
      return 'Password and confirm password must be same';
    }
    return null;
  };
  const __validateHasAcceptedTerms = (value = null) => {
    const fValue = value !== null ? value : formValues['hasAcceptedTerms'];
    if (fValue === false || !fValue) {
      return 'Plaese accept Terms';
    }
    return null;
  };
  const __validateHasReadHipaa = (value = null) => {
    const fValue = value !== null ? value : formValues['hasReadHipaa'];
    if (fValue === false || !fValue) {
      return 'Plaese accept HIPAA Authorization';
    }
    return null;
  };
  const __validateForm = () => {
    const validFirstName = !__validateFirstName();
    const validLastName = !__validateLastName();
    const validPassword = !__validatePassword();
    const validConfirmPassword = !__validateConfirmPassword();
    const validHasAcceptedTerms = !__validateHasAcceptedTerms();
    const validHasReadHipaa = !__validateHasReadHipaa();
    return (
      validFirstName &&
      validLastName &&
      validPassword &&
      validConfirmPassword &&
      validHasAcceptedTerms &&
      validHasReadHipaa
    );
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const { name, value } = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'password':
        errorObject = {
          [name]: __validatePassword(value),
        };
        break;
      case 'confirmPassword':
        errorObject = {
          [name]: __validateConfirmPassword(value),
        };
        break;
      case 'hasAcceptedTerms':
        errorObject = {
          [name]: __validateHasAcceptedTerms(value),
        };
        break;
      case 'hasReadHipaa':
        errorObject = {
          [name]: __validateHasReadHipaa(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleForm = () => {
    Nux.sendEvent(
      Analytics.Category.DoctorRegistration,
      Analytics.Actions.Click,
      'finalizeAccount',
    );
    nextStep(formValues);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const passwordLabelComponent = (
    <>
      Password{' '}
      <span className="password-instruction-text">
        ({DEFAULT_MESSAGES.PASSWORD_INSTRUCTION_TEXT})
      </span>
    </>
  );
  return (
    <div className="FinalizeAccount">
      <div className="h3">Welcome to Kaly!</div>
      <div className="paragraph">You’re signing up as {email}</div>
      <form className="signup-form">
        <div className="form-row">
          <Input
            {...getGeneralInputProps('firstName')}
            containerClass="two-input"
            type="text"
            label="First name"
          />
          <Input
            {...getGeneralInputProps('lastName')}
            containerClass="two-input"
            type="text"
            label="Last name"
          />
        </div>
        <Input
          {...getGeneralInputProps('password')}
          type="password"
          label={passwordLabelComponent}
          icon={images.eyeView}
        />
        <Input
          {...getGeneralInputProps('confirmPassword')}
          type="password"
          label="Confirm Password"
          icon={images.eyeView}
        />
        <div className="checkbox-block">
          <Checkbox {...getGeneralInputProps('hasAcceptedTerms')}>
            I have read and accept Kaly{' '}
            <Link to="/terms" target="_blank">
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
            .
          </Checkbox>
          <Checkbox {...getGeneralInputProps('hasReadHipaa')}>
            I have read and accept Kaly{' '}
            <Link to="/hipaa-policy" target="_blank">
              HIPAA Authorization
            </Link>{' '}
            and{' '}
            <Link to="/healthcare-provider-agreement" target="_blank">
              Healthcare Provider Agreement
            </Link>
            .
          </Checkbox>
        </div>
        <div className="signup-btn">
          <CircleButton onClick={handleForm} disabled={!isValidForm} btnType="whiteButton">
            Save and Continue
          </CircleButton>
        </div>
        <div className="signup-bottom">
          Already registered? <Link to="/login">Click here to login</Link>
        </div>
      </form>
    </div>
  );
};

export default FinalizeAccount;
