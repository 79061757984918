import React, {useEffect, useState} from 'react';
import './DoctorNearMe.css';
import images from 'data/images';
import master from 'services/master';
import config from 'config/config';
let {getEntryPointList} = master;
const {blackArrowPointer} = images;

const DoctorNearMe = props => {
  const [entryPoint, setEntryPoint] = useState({});
  const [selectedKey, setSelectedKey] = useState('');
  useEffect(() => {
    async function fetchData() {
      let responce = await getEntryPointList();
      if (responce && responce.data) {
        let filteredData = Object.keys(responce.data)
          .filter(key => responce.data[key].specialities.length > 0)
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: responce.data[key],
            };
          }, {});
  
       
        setEntryPoint(filteredData);
      }
    }
    fetchData()
  }, []);
  let tempcount=Object.keys(entryPoint).length<5?Object.keys(entryPoint).map(()=>'1fr'):['1fr','1fr','1fr','1fr','1fr']
  if(Object.keys(entryPoint).length===0)
  return <div></div>
  return (
    <div className="doctorNearMe">
      <div className="container">
        <h3 className="doctornearme-title">Find doctors by spaciality and city</h3>
        <div className="doctor-locations-div" style={{gridTemplateColumns:tempcount.join(' ')}}>
          {Object.keys(entryPoint).map((s, index) => (
            <div
              key={`city${index}`}
              className={['parent-doctorme', selectedKey === s ? 'active' : ''].join(' ')}>
              <label
                // onClick={() => {
                //   selectedKey !== s ? setSelectedKey(s) : setSelectedKey('');
                // }}
                className={['parent-label'].join(' ')}>
                <span>{s}</span>
                {/* <span className={'iconlabel'}>
                  <img src={blackArrowPointer} />
                </span> */}
              </label>
              <ul className="each-spacility">
                {entryPoint[s].specialities.map(sp => (
                  <li key={`${index}-${sp.id}`}>
                    <a target="_blank" href={`${config.APP_DOMAIN}nearme/${sp.seoPageSlug}`}>
                      {sp.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DoctorNearMe;
