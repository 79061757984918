import React, {useContext, useEffect, useRef, useState} from 'react';
import './DoctorArticle.css';
import images from 'data/images';
import RootContext from 'context/RootContext';
const loopingText = [
  {
    Q: 'Shoulder pain affecting your golf swing? ',
    A: 'Find a physical therapist who specializes in rotator cuff injuries.',
  },
  {
    Q: `Knee pain after a 5k? `,
    A: `Find a physiatrist who specializes in runner’s knee.`,
  },
  {
    Q: `Neck pain from online meetings?`,
    A: `Find a chiropractor who specializes in spondylosis.`,
  },
  {
    Q: `Pain in your hands?`,
    A: `Find a rheumatologist who specializes in arthritis.`,
  },
  {
    Q: `Were you told you need a cervical fusion?`,
    A: `Find a spine surgeon who specializes in cervical fusions.`,
  },
  {
    Q: `Does your child need scoliosis repair?`,
    A: `Find a spine surgeon who specializes in VBT.`,
  },
  {
    Q: `Headaches after bumping you head?`,
    A: `Find a neurologist who specializes in post-concussion headaches.`,
  },
];
function DoctorArticle(props) {
  const context = useContext(RootContext);
  const {specialties} = context;
  const [startIntex, setStartIntex] = useState(0);
  const ansRef = useRef(null);
  const QRef = useRef(null);
  setTimeout(() => {
    if (ansRef?.current) {
      ansRef.current.classList.remove('hide');
      ansRef.current.classList.add('animation');
    }
  }, 2000);
  useEffect(() => {
    const intervalId = setInterval(() => {
      QRef.current.classList.add('hide');
      ansRef.current.classList.add('hide');
      QRef.current.classList.remove('animation');
      ansRef.current.classList.remove('animation');
      if (startIntex < loopingText.length - 1) {
        setTimeout(() => {
          setStartIntex(startIntex + 1);
        }, 1000);
      } else {
        setTimeout(() => {
          setStartIntex(0);
        }, 1000);
      }
      setTimeout(() => {
        if (QRef?.current) {
          QRef.current.classList.remove('hide');
          QRef.current.classList.add('animation');
        }
      }, 1000);

      setTimeout(() => {
        if (ansRef?.current) {
          ansRef.current.classList.remove('hide');
          ansRef.current.classList.add('animation');
        }
      }, 3000);
    }, 8000);

    return () => clearInterval(intervalId); //This is important
  }, [startIntex]);

  return (
    <div className="DoctorArticle">
      <div className="container">
        <div className="da-arical-row">
          <div className="da-deserve-title da-arical-colum">
            <div className="scrolling-div">
              <div className="Losingyourvision looping-animation-Q">
                <span className="animation" ref={QRef}>
                  {loopingText[startIntex].Q}
                </span>
              </div>
              <div className="para looping-animation-A">
                <span className="hide" ref={ansRef}>
                  {loopingText[startIntex].A}
                </span>
              </div>
            </div>
          </div>
          <div className="dc-artical dc-artical1 da-arical-colum">
            <div className="img">
              {/* <img className="designyellow" src={images.designyellow} alt="Doctor" /> */}
              <img src={images.dcartical2} alt="Doctor" />
              {/* <img className="design1" src={images.design} alt="design1" />
              <img className="design2" src={images.design2} alt="design2" /> */}
            </div>
            <div className="heading2">Fast, secure, and convenient</div>
            <div className="description">
              Not only can patients breeze through the process of finding a doctor, but they can
              also seamlessly book appointments with our scheduling tool. Factoring in HIPAA
              compliance, we ensure personal information stays safe when booking an appointment on
              our platform.
            </div>
          </div>
        </div>
        <div className="da-arical-Powered">
          <div className="da-arical-right">
            <div className="dc-artical dc-artical3">
              <div className="img raingdiv">
                {/* <img src={images.owl} alt="owl" className="owlshape" />
                <img src={images.dotshapemark} alt="dot" className="dotshapemark" /> */}
                <div className="patients-result">
                  <div className="left">
                    <span className="review">
                      <img src={images.reviewStarPatient} className="star" alt="star" />
                      <span className="review-rate">4.9</span>
                    </span>
                    <img src={images.googleLogo} className="google-logo" alt="logo" />
                  </div>
                  <div className="right">
                    <div className="patients-treated">
                      <div className="h3">Patients Commonly Treated</div>
                      <div className="patients-treated-btn">
                        <span className="neck"></span>
                        <span className="sciatica"></span>
                        <span className="herniated"></span>
                        <span className="scoliosis"></span>
                      </div>
                      <div className="patients-treated-percent">
                        <span className="neck">40% Neck Pain </span>
                        <span className="spansciatica">30% Sciatica </span>
                        <span className="herniated">15% Herniated Disc</span>
                        <span className="scoliosis">15% Scoliosis</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="heading2">Transparent reviews, clearly superior care.</div>
              <div className="description">
                With comprehensive reviews from Google, Healthgrades, and more, you can confidently
                select the providers with the highest patient satisfaction and standards possible.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="da-arical-Powered">
        <div className="container">
          <div className="da-arical-right logoimgdiv">
            {/* <div className="dc-artical dc-artical3">
              <div className="img">
                <div className="patients-result">
                  <div className="left">
                    <span className="review">
                      <img src={images.reviewStarPatient} className="star" alt="star" />
                      <span className="review-rate">4.9</span>
                    </span>
                    <img src={images.googleLogo} className="google-logo" alt="logo" />
                  </div>
                  <div className="right">
                    <div className="patients-treated">
                      <div className="h3">Patients Commonly Treated</div>
                      <div className="patients-treated-btn">
                        <span className="neck"></span>
                        <span className="sciatica"></span>
                        <span className="scoliosis"></span>
                        <span className="herniated"></span>
                      </div>
                      <div className="patients-treated-percent">
                        <span className="neck">40% Neck Pain </span>
                        <span className="spansciatica">30% Sciatica </span>
                        <span className="scoliosis">15% Scoliosis</span>
                        <span className="herniated">15% Herniated Disc</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="heading2">Transparent reviews, clearly superior care.</div>
              <div className="description">
                With comprehensive reviews from Google, Healthgrades, and more, you can confidently
                select the providers with the highest patient satisfaction and standards possible.
              </div>
            </div> */}
            <img className="logo" style={{marginTop: '20px'}} src={images.logosymbol} alt="logo" />
          </div>
          <div className="da-arical-left">
            {/* <img className="doctor5" src={images.doctor5} alt="doctor5" />
            <h5 className="makerightchoice">Making the right choice, made easy.</h5>
            <p className="paraputtingyourhealth">
              Putting your health in someone else’s hands is a big decision. So we put all the
              information you need to choose the right provider at your fingertips - from
              transparent reviews, to detailed provider profiles.
            </p> */}
            <div className="da-arical-row">
              <div className="dc-artical dc-artical2 da-arical-colum makingtheright">
                <div className="img">
                  <img src={images.dcartical3} alt="Doctor" />
                </div>
                <div className="heading2">Making the right choice, made easy.</div>
                <div className="description">
                  Putting your health in someone else’s hands is a big decision. So we put all the
                  information you need to choose the right provider at your fingertips - from
                  transparent reviews, to detailed provider profiles.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorArticle;
