import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import constants from 'data/constants';
import Select from 'components/FormControls/Select';
import Checkbox from 'components/FormControls/Checkbox';
import validators from 'data/validators';
import CircleButton from 'components/CircleButton';
import images from 'data/images';
import selectOptions from 'data/selectOptions';
import MyDatetime from 'components/MyDatetime';
import momentHelper from 'data/moment/momentHelper';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import TimeManual from 'components/FormControls/TimeManual';
import holidays from 'data/holidays';
import scheduleService from 'services/schedule';
import helper from 'data/helper';
import './AddAvailability.css';
import TimeZoneLabel from 'components/MyDatetime/TimeZoneLabel';


const { getHoliday } = holidays;
const { __required } = validators;
const {
  dateTimeFormat,
  startOf,
  isSameOrAfter,
  isSameOrBefore,
  dateFormat,
  bigDateFormat,
  isSameDay,
  isBetweenDates,
  bigDateTimeFormat,
  utcToLocalDateFormat,
  isSame,
  timeFormat,
  utcToLocalMoment,
  convertToSpecificTimezone,
  localToUtcMoment,
  isAfter
} = momentHelper;
const { getAllAvailabilities } = scheduleService;
const { isIntersect } = helper;

const { modalFunctions } = modalConfig;
const { confirmModalData, errorModalData } = modalFunctions;

const AddAvailability = props => {
  const rootContext = useContext(RootContext);
  const { setGlobal } = rootContext;
  const { practiceLocationList } = rootContext;
  const { data = {}, onSave } = props;
  const now = () => {
    let nowTemp = localToUtcMoment(new Date()).format();
    nowTemp = utcToLocalMoment(nowTemp);
    let nowHH = nowTemp.format('HH');
    let nowMM = nowTemp.format('mm');
    let rdata = new Date();
    rdata.setDate(nowTemp.format('DD'));
    rdata.setHours(nowHH);
    rdata.setMinutes(nowMM);
    return rdata;
  };
  if (data.repeat.includes('CUSTOM')) {
    data.days = [];
    const DAYS = constants.DAYS;
    const array = data.repeat.split('-');
    array.shift();
    array.forEach(shortDayName => {
      data.days[DAYS[shortDayName]] = true;
    });
    data.repeat = 'CUSTOM';
  }
  // if only one location is available then set first location as selected
  if (!data.practiceLocationId && practiceLocationList.length === 1) {
    data.practiceLocationId = practiceLocationList[0].id;
  }
  const currentDateTime = moment();
  const [formData, setFormData] = useState({
    values: {
      days: [],
      startDatetime: moment(),
      practiceLocationId: '',
      endDatetime: moment(),
      ...data,
    },
    errors: { serverError: null },
  });
  const { values: formValues, errors: formErrors } = formData;
  const { isAllowRecurringEdit } = formValues;

  const endTime = (starDateTime = null) => {
    return (starDateTime || formValues['startDatetime'])
      .clone()
      .add(formValues['duration'], 'minutes');
  };

  // Validation functions
  const __validateRepeat = (value = null) => {
    const fValue = value !== null ? value : formValues['repeat'];
    if (!__required(fValue)) {
      return 'Repeat is required';
    }
    return null;
  };
  const __validateDays = (value = null, repeatValue) => {
    const fValue = value !== null ? value : formValues['days'];
    if (repeatValue === 'CUSTOM' && !fValue.includes(true)) {
      return 'Please select atleast one day';
    }
    return null;
  };
  const __validateLocation = (value = null) => {
    const fValue = value !== null ? value : formValues['practiceLocationId'];
    if (!__required(fValue)) {
      return 'Location is required';
    }
    return null;
  };
  const __validateDuration = (value = null) => {
    const fValue = value !== null ? value : formValues['duration'];
    if (!__required(fValue)) {
      return 'Duration is required';
    }
    return null;
  };
  const __validateStartDate = (value = null) => {
    const fValue = value !== null ? value : formValues['startDatetime'];
    if (!__required(fValue)) {
      return 'Start Date is required';
    }
    if (!fValue.isValid()) {
      return 'Start Date is Invalid';
    }
    return null;
  };
  const __validateEndDate = (value = null) => {
    const fValue = value !== null ? value : formValues['endDatetime'];
    if (!__required(fValue)) {
      return 'End Date is required';
    }
    if (!fValue.isValid()) {
      return 'End Date is Invalid';
    }
    return null;
  };
  const __validateType = (value = null) => {
    if (!formValues['isInPerson'] && !formValues['isVirtual']) {
      return 'Availability Type is required';
    }
    return null;
  };
  const __validateForm = () => {
    const validRepeat = !__validateRepeat();
    const validDays = !__validateDays(null, formValues['repeat']);
    const validDuration = !__validateDuration();
    const validStartDate = !__validateStartDate();
    const validLocation = !__validateLocation();
    const validType = !__validateType();
    return (
      validRepeat && validDays && validStartDate && validDuration && validLocation && validType
    );
  };
  const isValidForm = __validateForm();

  const onServerError = error => {
    setFormData({
      ...formData,
      errors: {
        ...formData.errors,
        serverError: error,
      },
    });
  };

  const validateAvailabilityConflict = async availabilityData => {
    let newStartDatetime = availabilityData.startDatetime;
    let newEndDatetime = newStartDatetime.clone().add(availabilityData.duration, 'minutes');
    let repeatType = availabilityData.repeat;
    if (availabilityData.id > 0 && !availabilityData.isAllowRecurringEdit) repeatType = 'none';
    let dayCount = 90;
    if (repeatType === 'none') {
      dayCount = 1;
    } else {
      let days = Math.round(
        moment.duration(localToUtcMoment(availabilityData.endDatetime) - localToUtcMoment(availabilityData.startDatetime)).asDays(),
      );
      days = days - 1;
      days = days > 0 ? days : 1;
      dayCount = days;
    }
    const startDate = dateFormat(newStartDatetime.clone().utc());
    const endDate = dateFormat(newStartDatetime.clone().add(dayCount, 'days').endOf('day').utc());
    const response = await getAllAvailabilities(startDate, endDate).catch(() => null);
    let calendarEvensArray = [];
    let newAvailabilityEventsArray = [];
    if (response) {
      const { data } = response;
      newAvailabilityEventsArray = getAvailabilityArray(newStartDatetime, data, dayCount);
      _.forEach(newAvailabilityEventsArray, (valueArray, dateindex) => {
        const holidayObject = getHoliday(dateindex);
        if (!holidayObject) {
          _.forEach(valueArray, event => {
            let updatedEvent = { ...event };
            const timeIndex = timeFormat(utcToLocalMoment(updatedEvent.startDatetime));

            const dateString = dateindex + ' ' + timeIndex;

            const startTime = convertToSpecificTimezone(dateString);
            const endTime = startTime.clone().add(updatedEvent.duration, 'milliseconds');

            if (repeatType === 'daily') {
              let compareStartTime = moment(dateindex + ' ' + timeFormat(newStartDatetime));
              let compareEndTime = moment(dateindex + ' ' + timeFormat(newEndDatetime));
              if (
                _.get(availabilityData, 'id', '') !== updatedEvent.id &&
                isIntersect([compareStartTime, compareEndTime], [startTime, endTime]) &&
                !isSame(endTime, compareStartTime) &&
                !isSame(startTime, compareEndTime)
              ) {
                updatedEvent.startDatetime = startTime;
                calendarEvensArray.push(updatedEvent);
              }
            }
            if (repeatType === 'none')
              if (
                _.get(availabilityData, 'id', '') !== updatedEvent.id &&
                isIntersect([newStartDatetime, newEndDatetime], [startTime, endTime]) &&
                !isSame(endTime, newStartDatetime) &&
                !isSame(startTime, newEndDatetime)
              ) {
                updatedEvent.startDatetime = moment(dateindex + ' ' + timeFormat(startTime));
                calendarEvensArray.push(updatedEvent);
              }
            if (repeatType === 'CUSTOM') {
              let dateDay = dateFormat(dateindex, 'dddd');
              let dayIndex = constants.DayIndex[dateDay];
              if (_.get(availabilityData, 'days[' + dayIndex + ']', false)) {
                let compareStartTime = moment(dateindex + ' ' + timeFormat(newStartDatetime));
                let compareEndTime = moment(dateindex + ' ' + timeFormat(newEndDatetime));
                if (
                  _.get(availabilityData, 'id', '') !== updatedEvent.id &&
                  isIntersect([compareStartTime, compareEndTime], [startTime, endTime]) &&
                  !isSame(endTime, compareStartTime) &&
                  !isSame(startTime, compareEndTime)
                ) {
                  updatedEvent.startDatetime = startTime;
                  calendarEvensArray.push(updatedEvent);
                }
              }
            }
          });
        }
      });
    }
    return calendarEvensArray;
  };

  const getAvailabilityArray = (currentWeekStartDate, dbEvents, daysCount = 6) => {
    const availabilityArray = {};
    let startDate = currentWeekStartDate;
    for (let i = 0; i <= daysCount; i++) {
      const clonedDate = startDate.clone();
      const formatedDate = dateFormat(clonedDate.add(i, 'days'));
      availabilityArray[formatedDate] = [];
    }
    const regularEvents = [];
    const dailyEvents = [];
    const weeklyCustomEvents = [];
    const refEvents = [];
    // devide events according to repeat type
    _.forEach(dbEvents, event => {
      if (event.refId !== null) {
        refEvents.push(event);
        return;
      }
      if (event.repeat === 'none') {
        regularEvents.push(event);
      }
      if (event.repeat === 'daily') {
        dailyEvents.push(event);
      }
      if (event.repeat.includes('CUSTOM')) {
        weeklyCustomEvents.push(event);
      }
    });
    // none
    _.forEach(regularEvents, dEvent => {
      const date = utcToLocalDateFormat(dEvent.startDatetime);
      _.forEach(availabilityArray, (value, keyDate) => {
        const mainDate = keyDate;
        if (isSame(mainDate, date)) {
          availabilityArray[mainDate].push(dEvent);
        }
      });
    });
    // daily
    _.forEach(dailyEvents, dEvent => {
      const date = utcToLocalDateFormat(dEvent.startDatetime);
      const eDate = utcToLocalDateFormat(dEvent.endDatetime);
      _.forEach(availabilityArray, (value, keyDate) => {
        const mainDate = keyDate;
        if (isSameOrAfter(mainDate, date) && isSameOrBefore(mainDate, eDate)) {
          availabilityArray[mainDate].push(dEvent);
        }
      });
    });
    // weekly
    _.forEach(weeklyCustomEvents, dEvent => {
      const date = utcToLocalDateFormat(dEvent.startDatetime);
      const eDate = utcToLocalDateFormat(dEvent.endDatetime);
      const repeatDays = _.split(dEvent.repeat, '-');
      repeatDays.splice(0, 1);
      const days = getDays(repeatDays);
      _.forEach(availabilityArray, (value, keyDate) => {
        const mainDate = keyDate;
        let dateDay = dateFormat(mainDate, 'dddd');
        let dayIndex = constants.DayIndex[dateDay];
        if (
          isSameOrAfter(mainDate, date) &&
          isSameOrBefore(mainDate, eDate) &&
          days.includes(dayIndex)
        ) {
          availabilityArray[mainDate].push(dEvent);
        }
      });
    });
    // remove or modify ref events
    _.forEach(refEvents, refEvent => {
      const formatedRefdate = utcToLocalDateFormat(refEvent.startDatetime);
      const currentFormatedDatesEvents = availabilityArray[formatedRefdate];
      _.forEach(currentFormatedDatesEvents, (cfde, index) => {
        if (cfde.id === refEvent.refId) {
          if (!refEvent.makeAvailable) {
            delete availabilityArray[formatedRefdate][index];
          } else {
            availabilityArray[formatedRefdate][index] = refEvent;
          }
        }
      });
      availabilityArray[formatedRefdate] = _.compact(availabilityArray[formatedRefdate]);
    });
    return availabilityArray;
  };

  const getDays = repeatDays => {
    const newArray = _.map(repeatDays, d => {
      return constants.DAYS[d];
    });
    return newArray;
  };

  const changeHandler = event => {
    let { name } = event.target;
    let { value, duration } = event.target;
    let errorObject = {
      [name]: null,
    };
    const nameArr = name.split('-');
    switch (nameArr[0]) {
      case 'repeat':
        const oldDays = [...formValues['days']];
        errorObject = {
          [name]: __validateRepeat(value),
          days: __validateDays(oldDays, value),
        };
        break;
      case 'startDatetime':
        errorObject = {
          [name]: __validateStartDate(value),
        };
        break;
      case 'endDatetime':
        errorObject = {
          [name]: __validateEndDate(value),
        };
        break;
      case 'duration':
        errorObject = {
          [name]: __validateDuration(value),
        };
        break;
      case 'days':
        name = 'days';
        const oldValues = [...formValues[name]];
        oldValues[nameArr[1]] = value;
        value = oldValues;
        errorObject = {
          [name]: __validateDays(value, formValues['repeat']),
        };
        break;
      case 'practiceLocationId':
        errorObject = {
          [name]: __validateLocation(value),
        };
        break;
      case 'isVirtual':
        errorObject = {};
        break;
      case 'isInPerson':
        errorObject = {};
        break;
      default:
        break;
    }
    if (name === 'duration') {
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          [name]: value,
        },
        errors: {
          ...formData.errors,
          ...errorObject,
        },
      });
    } else {
      setFormData(prevformData => {
          return {
          ...prevformData,
          values: {
            ...prevformData.values,
            endDatetime:(name=='repeat' && value!=='none')?moment(prevformData.values.startDatetime).add(1,'day'):prevformData.values.endDatetime,
            [name]: value,
            duration: duration ? duration : prevformData.values.duration,

          },
          errors: {
            ...prevformData.errors,
            ...errorObject,
          },
        };
      });
    }
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const getGeneralCheckBoxProps = controlName => {
    const nameArr = controlName.split('-');
    let customProps = {};
    if (nameArr.length === 2) {
      // for days selection
      customProps = {
        ...customProps,
        value: formValues[nameArr[0]][nameArr[1]],
        disabled: !isAllowRecurringEdit,
      };
    } else {
      // for virtual check box
      customProps = {
        ...customProps,
        value: formValues[nameArr[0]],
      };
    }
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      name: controlName,
      ...customProps,
    };
  };

  const handleForm = async () => {
    const updatedFormValues = { ...formValues };
    if (typeof updatedFormValues.endDatetime === 'string') {
      updatedFormValues.endDatetime = moment(formValues.endDatetime)
    }

    let startDat = convertToSpecificTimezone(updatedFormValues.startDatetime).format()
    updatedFormValues.startDatetime = moment(startDat);
    let endDatetime = convertToSpecificTimezone(updatedFormValues.endDatetime).format()
    updatedFormValues.endDatetime = moment(endDatetime);

    //return;

    // updatedFormValues['startDatetime'] = dateTimeFormat(updatedFormValues['startDatetime']);
    let result = await validateAvailabilityConflict(updatedFormValues);

    let endDays = 0;
    if (formValues.repeat !== 'none') {
     
      // let days = Math.round(
      //   moment.duration(localToUtcMoment(formValues.endDatetime) - localToUtcMoment(formValues.startDatetime)).asDays(),
      // )
      let days=Math.round(updatedFormValues.endDatetime.diff(updatedFormValues.startDatetime,'days'))
      //  days = days - 1;
      // days = days > 0 ? days : 1;
      endDays = days ? days : 0;
    }
    updatedFormValues.endDays = endDays;


    if (result.length === 0) onSave(updatedFormValues, onServerError);
    else {
      setGlobal(
        'modal',
        errorModalData(
          `Your selected availability time conflicting with ${result[0].startDatetime.format(
            'MMM DD, hh:mm A',
          )}'s availability`,
        ),
      );
    }
  };

  const closeModal = () => {
    setGlobal('modal', { showModal: false });
  };

  const deleteAvailability = deleteType => {
    const updatedFormValues = { ...formValues, isDeleteRepeat: false };
    let deleteTypeText = 'Today’s';
    if (data.startDatetime) {
      deleteTypeText = bigDateTimeFormat(data.startDatetime);
    }
    if (deleteType === 'recuring') {
      deleteTypeText = `${data.startDatetime.format('HH:mm')} to ${endTime(
        data.startDatetime,
      ).format('HH:mm')} hrs Recurring`;
      updatedFormValues['isDeleteRepeat'] = true;
    }

    updatedFormValues['startDatetime'] = convertToSpecificTimezone(data['startDatetime']);
    let availabilityType = formValues.isInPerson ? 'In-Person' : '';
    const virtual = availabilityType.length ? ' and Virtual' : 'Virtual';
    availabilityType += formValues.isVirtual ? virtual : '';
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete ${deleteTypeText} ${availabilityType} Availability ?`,
        lastModalConfig: {
          ...props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            onSave({ ...updatedFormValues, isDelete: true }, onServerError);
          }
        },
      }),
    );
  };

  // date time picker functions
  const [dateConfig, setDateConfig] = useState({
    start: { open: false, input: false, viewMode: 'days', timeFormat: 'hh:mm A' },
    end: { open: false, input: false, viewMode: 'days', timeFormat: 'hh:mm A' },
  });

  const updateTimeConstraints = date => {
    const isCurrentDate = moment().startOf('day').diff(date, 'days') === 0;
    const isAllowPastEdit = isAllowRecurringEdit && formValues.id;
    if (isCurrentDate && !isAllowPastEdit) {
      const hours = currentDateTime.get('hours');
      if (date.get('hours') < hours) {
        // if selected hours are greater then current hours,then reset hors to current
        date.set({ hours: hours });
      }
      // if selected hours are queal to current hour,check for minutes
      let minutes = 0;
      if (hours === date.get('hours')) {
        minutes = Math.ceil(currentDateTime.get('minutes') / 15) * 15;
        date.set({ minutes: minutes });
      }
    }
  };
  const handleStartDatetimeChange = starDate => {
    let updatedStartDate = formValues['startDatetime'];
    if (dateFormat(endTime(starDate)) === dateFormat(starDate)) {
      // update Time Constraints to restrict time picker to not select before current time
      updateTimeConstraints(starDate);
      updatedStartDate = starDate;
    }
    const customEventObject = {
      target: {
        name: 'startDatetime',
        value: updatedStartDate,
      },
    };
    changeHandler(customEventObject);

    var isafter = moment(updatedStartDate).isAfter(formValues['endDatetime']);
    if (isafter) {
      const customEventObjectEnd = {
        target: {
          name: 'endDatetime',
          value: formValues['repeat']=='none'?updatedStartDate:moment(updatedStartDate).add(1,'day'),
        },
      };
      changeHandler(customEventObjectEnd);
    }
    setDateConfig({
      ...dateConfig,
      start: {
        ...dateConfig.start,
        open: false,
      },
    });
  };
  const handleEndtDatetimeChange = endDate => {
    // let updatedStartDate = formValues['endDatetime'];
    // if (dateFormat(endTime(starDate)) === dateFormat(starDate)) {
    //   // update Time Constraints to restrict time picker to not select before current time
    //   updateTimeConstraints(starDate);
    //   updatedStartDate = starDate;
    //}
    let updatedStartDate = endDate;

    const customEventObject = {
      target: {
        name: 'endDatetime',
        value: updatedStartDate,
      },
    };
    changeHandler(customEventObject);
    setDateConfig({
      ...dateConfig,
      end: {
        ...dateConfig.end,
        open: false,
      },
    });
  };
  let isAllowStartDateTimeChange = true;
  const oldEndDate = data['startDatetime'].clone().add(data['duration'], 'minutes');
  if (data['id'] && !isAllowRecurringEdit && isBetweenDates(data['startDatetime'], oldEndDate, now())) {
    isAllowStartDateTimeChange = false;
  }

  const dateTimeFormatString = 'YYYY-MM-DD hh:mm a';
  const startTimeChangeHandle = event => {
    const { value } = event.target;
    const newVal = formValues['startDatetime'].format('YYYY-MM-DD') + ' ' + value;
    const startDatetimeObj = moment(newVal, dateTimeFormatString);
    const minutes = Math.ceil(startDatetimeObj.get('minutes') / 15) * 15;
    startDatetimeObj.set('minutes', minutes);
    let minDiff = moment.duration(formValues.startDatetime.diff(startDatetimeObj)).asMinutes();
    let newDuration = formValues.duration + minDiff;
    if (newDuration < 15) newDuration = 15;
    const endDataTime = startDatetimeObj.clone().add(newDuration, 'minutes');
    if (isSameOrAfter(startDatetimeObj, now()) || (isAllowRecurringEdit && formValues.id)) {
      if (isSameDay(startDatetimeObj, endDataTime)) {
        const customEventObject = {
          target: {
            name: 'startDatetime',
            value: startDatetimeObj,
            duration: newDuration,
          },
        };
        changeHandler(customEventObject);
      }
    }
  };

  const endTimeChangeHandle = event => {
    const { value } = event.target;
    const newValEnd = formValues['startDatetime'].format('YYYY-MM-DD') + ' ' + value;
    let endDate = moment(newValEnd, dateTimeFormatString);
    let duration = endDate.diff(formValues['startDatetime'], 'minutes');
    if (duration <= 0) {
      duration = 15;
    } else {
      duration = Math.ceil(duration / 15) * 15;
    }
    endDate = formValues['startDatetime'].clone().add(duration, 'minutes');
    if (!isSameDay(formValues['startDatetime'], endDate)) {
      duration -= 15;
    }
    const customEventObject = {
      target: {
        name: 'duration',
        value: duration,
      },
    };

    changeHandler(customEventObject);
  };

  const changeViewMode = (startOrEnd, viewMode) => {
    const updatedDateConfigs = { ...dateConfig };
    let endConfig = { ...updatedDateConfigs.end };
    let startConfig = { ...updatedDateConfigs.start };
    if (startOrEnd === 'start') {
      endConfig.open = false;
      startConfig = {
        ...startConfig,
        viewMode,
        open: dateConfig[startOrEnd].viewMode === viewMode ? !dateConfig[startOrEnd].open : true,
      };
    } else if (startOrEnd === 'end') {
      startConfig.open = false;
      endConfig = {
        ...startConfig,
        viewMode,
        open: dateConfig[startOrEnd].viewMode === viewMode ? !dateConfig[startOrEnd].open : true,
      };
    }
    setDateConfig({
      ...dateConfig,
      start: startConfig,
      end: endConfig,
    });
  };
  const closeDatePicker = e => {
    setDateConfig({
      ...dateConfig,
      start: {
        ...dateConfig.start,
        open: false,
      },
      end: {
        ...dateConfig.end,
        open: false,
      },
    });
  };

  const chechIsDateAfterOrToday = (currentDatePickerDate, selectedDate) => {
    if (getHoliday(dateFormat(currentDatePickerDate))) {
      return false;
    }
    if (isAllowRecurringEdit && formValues.id) {
      return true;
    }
    const startOfCurrentDay = startOf('day', currentDateTime.clone());
    return isSameOrAfter(currentDatePickerDate, startOfCurrentDay);
    // return true;
  };
  const chechIsDateAfterStart = (currentDatePickerDate, selectedDate) => {
    if (getHoliday(dateFormat(currentDatePickerDate))) {
      return false;
    }
    const startOfCurrentDay = startOf('day', formValues.startDatetime);
    if (formValues.repeat != 'none') {
      return isAfter(currentDatePickerDate, startOfCurrentDay)
    }
    // if (isAllowRecurringEdit && formValues.id) {
    //   return true;
    // }

    return isSameOrAfter(currentDatePickerDate, startOfCurrentDay);
    // return true;
  };

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="" />
    </span>
  );
  let headerTitle = 'Add Recurring Availability';
  let saveButtonText = 'Create';
  if (formValues.id) {
    saveButtonText = 'Update';
    if (isAllowRecurringEdit) {
      headerTitle = 'Edit Recurring Availability';
    } else {
      headerTitle = 'Edit Today’s Availability Only';
    }
  }
  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">{headerTitle}
        <TimeZoneLabel />
      </div>
      {_.has(props, 'data.id') ? (
        <div className="dropdown">
          <div>
            <button
              className="dailog-close-btn"
              onClick={() => {
                deleteAvailability(isAllowRecurringEdit ? 'recuring' : 'this_event');
              }}>
              <img alt="" src={images.deleteRound} />
            </button>
          </div>
        </div>
      ) : (
        crossIconComponent
      )}
    </div>
  );

  if (!practiceLocationList) {
    return null;
  }

  const getStartMinTime = () => {
    // set limit to start time only if availibility is not recurring and its not on today
    if (!isAllowRecurringEdit && isSameDay(formValues['startDatetime'])) {
      return currentDateTime.format('hh:mm a');
    } else {
      return null;
    }
  };

  return (
    <div className="AddAvailability no-select">
      {(dateConfig.start.open || dateConfig.end.open) && (
        <div className="datepicker-overlay" onClick={closeDatePicker}></div>
      )}
      {dialogHeader}
      <div className="dailog-body">
        <form>
          <div className={`select-event-day-content ${!isAllowRecurringEdit ? 'disabled' : ''}`}>
            <span>
              <img src={images.repeatIcon} alt="" />
            </span>
            <Select
              disabled={!isAllowRecurringEdit}
              {...getGeneralInputProps('repeat')}
              options={selectOptions.availabilityRepeatOptions}
              labelIndex="label"
              valueIndex="value"
              icon={images.calender}
            />
            {formValues['repeat'] === 'CUSTOM' && (
              <div className="days-checkbox-container">
                <span className="Select-days">
                  <Checkbox {...getGeneralCheckBoxProps('days-1')}>Mon</Checkbox>
                  <Checkbox {...getGeneralCheckBoxProps('days-2')}>Tue</Checkbox>
                  <Checkbox {...getGeneralCheckBoxProps('days-3')}>Wed</Checkbox>
                  <Checkbox {...getGeneralCheckBoxProps('days-4')}>Thu</Checkbox>
                  <Checkbox {...getGeneralCheckBoxProps('days-5')}>Fri</Checkbox>
                  <Checkbox {...getGeneralCheckBoxProps('days-6')}>Sat</Checkbox>
                  <Checkbox {...getGeneralCheckBoxProps('days-0')}>Sun</Checkbox>
                </span>
                <span style={{ color: 'red' }}>{formErrors['days']}</span>
              </div>
            )}
          </div>
          <div className="select-date-content">
            <span className="icon">
              <img src={images.clockIcon} alt="" />
            </span>
            <div className="select-picker-content">
              <span
                className={`time-padding ${dateConfig.start.open && dateConfig.start.viewMode === 'days' ? 'gray-box' : ''
                  }`}
                onClick={() => isAllowStartDateTimeChange && changeViewMode('start', 'days')}>
                <span className="small-label">Start Date</span>
                <span className="gray-back"></span>
                {bigDateFormat(formValues['startDatetime'], 'MMM DD, YYYY')}
              </span>
              <div className={`Datepicker-content ${dateConfig.start.viewMode}`}>
                <MyDatetime
                  isValidDate={chechIsDateAfterOrToday}
                  value={formValues['startDatetime']}
                  onChange={handleStartDatetimeChange}
                  onViewModeChange={viewMode => changeViewMode('start', viewMode)}
                  {...dateConfig.start}
                />
              </div>
            </div>
            <b className="select-time">
              <span>
                <span className="small-label">Start Time</span>
                <TimeManual
                  minTime={getStartMinTime()}
                  step={15}
                  value={formValues['startDatetime'].format('hh:mm a')}
                  name="startDatetime"
                  disabled={!isAllowStartDateTimeChange}
                  onChange={startTimeChangeHandle}
                />
              </span>
              <span className="time-devider"> - </span>
              <span>
                <span className="small-label">End Time</span>
                <TimeManual
                  minTime={formValues['startDatetime'].format('hh:mm a')}
                  step={15}
                  value={endTime().format('hh:mm a')}
                  name="startDatetime"
                  onChange={endTimeChangeHandle}
                />
              </span>
            </b>
            {formValues['repeat'] !== 'none' && (
              <div className="select-picker-content end-date">
                <span
                  className={`time-padding ${dateConfig.end.open && dateConfig.end.viewMode === 'days' ? 'gray-box' : ''
                    }`}
                  onClick={() =>
                    isAllowStartDateTimeChange &&
                    isAllowRecurringEdit &&
                    changeViewMode('end', 'days')
                  }>
                  <span className="small-label">End Date</span>
                  <span className="gray-back"></span>
                  {bigDateFormat(formValues['endDatetime'], 'MMM DD, YYYY')}
                </span>
                <div
                  style={{ left: '-400px' }}
                  className={`Datepicker-content ${dateConfig.end.viewMode}`}>
                  <MyDatetime
                    isValidDate={chechIsDateAfterStart}

                    value={formValues['endDatetime']}
                    onChange={handleEndtDatetimeChange}
                    onViewModeChange={viewMode => changeViewMode('end', viewMode)}
                    {...dateConfig.end}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="location">
            <span className="icon">
              <img src={images.markerIcon} alt="" />
            </span>
            <Select
              {...getGeneralInputProps('practiceLocationId')}
              options={practiceLocationList}
              labelIndex="locationName"
              valueIndex="id"
              arrowCss={{ backgroundImage: `url(${images.angleDown})` }}
            />
          </div>
          <label className="virtual-field">
            <Checkbox {...getGeneralCheckBoxProps('isInPerson')}>In Person</Checkbox>
          </label>
          <label className="virtual-field">
            <Checkbox {...getGeneralCheckBoxProps('isVirtual')}>Virtual</Checkbox>
          </label>
          <div className="action-btn">
            {formErrors['serverError'] && (
              <span className="error">{formErrors['serverError']}</span>
            )}
            <CircleButton onClick={closeModal} btnType="secondaryButton">
              Cancel
            </CircleButton>
            <CircleButton onClick={handleForm} disabled={!isValidForm} btnType="whiteButton">
              {saveButtonText}
            </CircleButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAvailability;
