import React, {useState, useContext} from 'react';
import images from 'data/images';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import allRegex from 'data/regex';
import './EmailInputModal.css';
import AuthContext from 'context/AuthContext';
import _ from 'lodash';

const {emailRegex} = allRegex;

const EmailInputModal = props => {
  const authContext = useContext(AuthContext);
  const siteSettings = _.get(authContext, 'siteSettings');
  const {closeModal, callback} = props;

  const [emailData, setemailData] = useState({email: ''});

  const handleChange = event => {
    const {name} = event.target;
    let {value} = event.target;
    setemailData(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <div className="EmailInputModal">
      <div className="modal-content-header">
        <div className="h3">Please Enter Your Email</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        {props.message && (
          <p>{props.message}</p>
        )}
        <Input
          label="Enter Your Email"
          name="email"
          value={emailData.email}
          onChange={handleChange}
        />
      </div>
      <div className="modal-content-footer">
        <CircleButton
          disabled={!emailRegex.test(emailData.email)}
          onClick={() => {
            closeModal();
            callback(emailData.email);
          }}>
          Continue
        </CircleButton>
        <div className="contact-us-text">
          If you are still having trouble,{' '}
          <a href={`mailto:${_.get(siteSettings, 'contactUsLinks', '')}`}>Contact us.</a>
        </div>
      </div>
    </div>
  );
};

export default EmailInputModal;
