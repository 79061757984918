import React, {Component} from 'react';
import Layout from 'layout/VideoLayout';
import images from 'data/images';
import {Link} from 'react-router-dom';
import './PatientVideoExit.css';
import patientService from 'services/patient';
import _ from 'lodash';
import helper from 'data/helper';

const {getAppointmentDetailsById} = patientService;
const {getSuffixString} = helper;
const initialState = {
  appointmentData: {},
  id: '',
};

class PatientVideoExit extends Component {
  state = {...initialState};

  componentDidMount = () => {
    this.getAppointmentData();
  };

  getAppointmentData = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('appointment') || 0;
    const response = await getAppointmentDetailsById(id).catch(this.handleError);
    if (response) {
      const {data} = response;
      const appointmentId = _.get(data, 'id', '');
      this.setState({appointmentData: data, id: appointmentId});
    }
  };

  render() {
    const {appointmentData, id} = this.state;
    const doctorUser = _.get(appointmentData, 'providerAvailability.provider.user', []);
    const suffixes = _.get(appointmentData, 'providerAvailability.provider.suffixes', []);
    const doctorName = `${_.get(doctorUser, 'firstName', '')} ${_.get(
      doctorUser,
      'lastName',
      '',
    )} ${getSuffixString(suffixes)}`;
    const profileImg = _.get(doctorUser, 'profileImageUrl', '');

    return (
      <Layout>
        <div className="PatientVideoExit">
          <div className="sub-heading">You left the video visit with</div>
          <div className="heading2">{doctorName}</div>
          <span className="doctor-img">
            {profileImg && <img src={profileImg} alt="doctor-img" />}
          </span>
          <Link to={`/video-visit?appointment=${id}`} className="rejoin-videolink">
            Rejoin Video Visit
          </Link>
        </div>
      </Layout>
    );
  }
}

export default PatientVideoExit;
