import symptom from 'data/questionnaires/symptom';
import specialist from 'data/questionnaires/specialist';
import procedure from 'data/questionnaires/procedure';
import doctor from 'data/questionnaires/doctor';

const {questionList, config} = doctor;
const {allSymptomQuestionList, symptomConfig} = symptom;
const {allProcedureQuestionList, procedureConfig} = procedure;
const {allSpecialistQuestionList, specialistConfig} = specialist;

// merge all questions in one list
const allQuestionList = [
  ...allSpecialistQuestionList,
  ...allSymptomQuestionList,
  ...questionList,
  ...allProcedureQuestionList,
];
const allConfig = {...specialistConfig, ...symptomConfig, ...procedureConfig, doctor: config};

export default {
  allQuestionList,
  allConfig,
};
