import React, {useContext, useState} from 'react';
import fileUploadService from 'services/fileUpload';
import helper from 'data/helper';
import './PatientRegistration.css';
import {DeleteConform} from 'containers/Admin/Practices/EditPracticeRegistration/DeleteConform';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
const {getFileNameFromUrl} = helper;
const {fileUpload, PraticeRemoveForm} = fileUploadService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;
const PatientRegistration = props => {
  const rootContext = useContext(RootContext);
  const {
    defaultFileUrl = '',
    uploadServerUrl,
    onError,
    afterUpload,
    // onRemove
    hasPermissionToEdit,
  } = props;
  // file input
  const [fileObj, setFile] = useState({
    file: null,
    preview: defaultFileUrl,
  });
  const {preview} = fileObj;

  const handleFileSelection = async event => {
    const {files} = event.target;
    if (files.length > 0) {
      const newFile = event.target.files[0];
      if (!validateFile(newFile)) {
        onError({message: 'Only doc and pdf file is allowed'});
        return;
      }
      const response = await fileUpload(newFile, uploadServerUrl, preview).catch(onError);
      if (response) {
        const {data} = response;
        const {fileUrl} = data;
        setFile({
          file: newFile,
          preview: fileUrl,
        });
        afterUpload(fileUrl);
        // return newFile.name;
      }
    }
    return null;
  };
  const handleError = error => {
    let {setGlobal} = rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const removeForm = async fileName => {
    let aditionalInfo = await DeleteConform({
      context: rootContext,
      text: `Are you sure you want to delete this from?`,
    });
    if (!aditionalInfo) return false;

    let response = await PraticeRemoveForm().catch(handleError);
    if (response) {
      let {setGlobal} = rootContext;
      setGlobal(
        'modal',
        successModalData('Form removed successfully', {
          callBackOnClose: () => {
            setFile({
              file: '',
              preview: '',
            });
            afterUpload('');
          },
        }),
      );
    }
  };
  const validateFile = fileObj => {
    const docTypes = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const pdfTypes = ['application/pdf'];
    const allowedTypes = [...docTypes, ...pdfTypes];
    return allowedTypes.includes(fileObj.type);
  };

  const fileName = getFileNameFromUrl(preview) || '---';
  return (
    <div className="box-content PatientRegistration">
      <div className="h3">Patient Registration Form</div>
      <div className="PatientRegistration-inner">
        {hasPermissionToEdit && (
          <>
            <span className="label-content">
              <span>Upload a form</span>
              <span className="uploadfile-text">pdf or doc file</span>
            </span>
            {fileName && fileName!=='---'  &&(
            <span onClick={() => removeForm(fileName)} className="removebtn ">
              Remove
            </span>
            )}
          </>
        )}
        <div className="fileupload">
          {fileName}
          {hasPermissionToEdit && (
            <span className="fileupload-btn">
              <input
                type="file"
                onChange={handleFileSelection}
                onClick={event => {
                  event.target.value = null;
                }}
              />
              <button>Choose file</button>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientRegistration;
