import React from 'react';
import AcceptedInsurancePlans from './AcceptedInsurancePlans';
import './InsuranceTab.css';

const InsuranceTab = props => {
  return (
    <div className="InsuranceTab">
      <div className="rightpanle">
        <AcceptedInsurancePlans {...props} />
      </div>
    </div>
  );
};
export default InsuranceTab;
