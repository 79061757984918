import React from 'react';
import './Textarea.css';

const Textarea = (props, ref) => {
  // translationType will be basic or translation
  const {
    label,
    containerClass = '',
    error = null,
    translationType = 'basic',
    CharCount = null,
    ...otherInputProps
  } = props;
  // set value to blank string, if value is null or undefind
  otherInputProps.value = otherInputProps.value || '';
  const {id} = otherInputProps;
  if (ref) otherInputProps.ref = ref;
  let newContainerClass = containerClass;
  if (translationType === 'basic') {
    newContainerClass += ' form-group';
  } else if (translationType === 'transform') {
    otherInputProps.placeholder = label;
    newContainerClass += ' transform-input';
  }
  if (error) {
    newContainerClass += ' error';
  }

  let labelComponent = (
    <span className="label" htmlFor={id}>
      {label}
    </span>
  );

  return (
    <div className={`Textarea ${newContainerClass}`}>
      {translationType === 'basic' && labelComponent}
      <textarea className="form-control" {...otherInputProps} />
      {translationType !== 'basic' && labelComponent}
      {error && <span className="error-text">{error}</span>}
      {CharCount && <span className="charcount-text">{CharCount}</span>}
    </div>
  );
};

export default Textarea;
