const questionList = [ {
  id: 1301,
  question: 'What kind of care are you looking for?',
  options: [
    {groupId: 'E', label: 'Routine Physical', procedureId: 114},
    {nextQuestionId: 1302, label: 'Vaccinations'},
    {nextQuestionId: 1303, label: 'Specific Problem', addExtraQuestion: 1},
  ],
  descriptions: [],
},
{
  id: 1302,
  question: 'Are you specifically looking to be vaccinated for COVID-19?',
  optionType: 1,
  options: [
    {groupId: 'E', label: 'Yes', procedureId: 116, shortLabel: 'COVID-19 vaccination'},
    {groupId: 'E', label: 'No', procedureId: 115, shortLabel: 'Routine vaccination'},
  ],
  descriptions: [],
},
{
  id: 1303,
  question: 'What type of condition do you have?',
  options: [
    {nextQuestionId: 1304, label: 'Allergy', painId: 20},
    {nextQuestionId: 1304, label: 'Skin Problem', painId: 21},
    {nextQuestionId: 1305, label: 'Respiratory Infection', painId: 22, addExtraQuestion: 1},
    {nextQuestionId: 1307, label: 'Insomnia', painId: 24},
    {nextQuestionId: 1307, label: 'Erectile Dysfunction', painId: 25},
    {nextQuestionId: 1307, label: 'Birth Control', painId: 26},
    {nextQuestionId: 1307, label: 'Hair Loss', painId: 27},
    {nextQuestionId: 1307, label: 'Acne', painId: 28},
    {nextQuestionId: 1307, label: 'Premature Ejaculation', painId: 29},
    {nextQuestionId: 1307, label: 'Urinary Tract Infection', painId: 30},
    {nextQuestionId: 1307, label: 'Cold Sore', painId: 31},
    {nextQuestionId: 1307, label: 'Genital Herpes', painId: 32},
    {nextQuestionId: 1307, label: 'Asthma', painId: 33},
    {nextQuestionId: 1307, label: 'Acid Reflux', painId: 34},
    {nextQuestionId: 1307, label: 'Hot Flashes', painId: 35},
    {nextQuestionId: 1307, label: 'Dark Spots', painId: 36},
    {nextQuestionId: 1307, label: 'Hypothyroidism', painId: 37},
    {nextQuestionId: 1307, label: 'Stop Smoking', painId: 38},
    {nextQuestionId: 1307, label: 'High Blood Pressure', painId: 39},
    {nextQuestionId: 1307, label: 'Cholesterol', painId: 40},
    {nextQuestionId: 1307, label: 'STD testing', painId: 41},
    {nextQuestionId: 1308, label: 'Anxiety', painId: 42},
    {nextQuestionId: 1308, label: 'Depression', painId: 43},
    {nextQuestionId: 1308, label: 'Mental Health Issue', painId: 23},
    {nextQuestionId: 1311, label: 'Pain'},
    {nextQuestionId: 1309, label: 'Something else'},
  ],
  multiColOption: true,
  descriptions: [],
},
{
  id: 1304,
  question:
    'Sometimes an allergy or a skin problem can be a symptom of an urgent medical problem. If you are experiencing difficulty breathing or shortness of breath, please call 911 or go to the nearest emergency room.',
  questionType: 2,
  optionType: 1,
  options: [{nextQuestionId: 1307, label: 'Okay'}],
  descriptions: [],
},
{
  id: 1305,
  question: 'Are you specifically looking to be tested for COVID-19?',
  optionType: 1,
  options: [
    {nextQuestionId: 1806, label: 'Yes', procedureId: 117, shortLabel: 'COVID-19 testing'},
    {nextQuestionId: 1307, label: 'No', shortLabel: 'Non COVID-19 related infection'},
  ],
  descriptions: [],
},
{
  id: 1806,
  question:
    'Once you make an appointment, your doctor’s office will call you to advise you what to do. Please do not go to the doctor’s office until you have spoken to the staff.',
  questionType: 2,
  optionType: 1,
  options: [{nextQuestionId: 1307, label: 'Okay'}],
  descriptions: [],
},
{
  id: 1307,
  question: 'How long have you had symptoms for?',
  options: [
    {nextQuestionId: 1310, label: 'Less than 24 hours'},
    {nextQuestionId: 1310, label: 'A few days'},
    {nextQuestionId: 1310, label: 'More than a week'},
  ],
  descriptions: [],
},
{
  id: 1308,
  question:
    'If you are considering harming yourself or others, or are experiencing a mental health crisis, please call 911 or go to the nearest emergency room.',
  questionType: 2,
  optionType: 1,
  options: [{nextQuestionId: 1307, label: 'Okay'}],
  descriptions: [],
},
{
  id: 1309,
  question: 'You will have a chance to type in your condition after you book the appointment.',
  questionType: 2,
  optionType: 1,
  options: [{nextQuestionId: 1307, label: 'Okay'}],
  descriptions: [],
},
{
  id: 1310,
  question: 'Have you been treated for this before?',
  optionType: 1,
  options: [
    {nextQuestionId: 1811, label: 'Yes', addExtraQuestion: 1, shortLabel: 'Treated previously'},
    {groupId: 'E', label: 'No', shortLabel: 'Never been treated before'},
  ],
  descriptions: [],
},
{
  id: 1811,
  question: 'When was the last time you were treated for this?',
  options: [
    {
      groupId: 'E',
      label: 'I am currently under treatment and looking for a new provider.',
      shortLabel: 'Currently under treatment',
    },
    {
      groupId: 'E',
      label: 'It has been over 3 months since I last had treatment.',
      shortLabel: 'Last treated > 3months ago',
    },
  ],
  descriptions: [],
},
{
  id: 1311,
  question: 'What type of pain condition do you have?',
  optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
  painOptionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // painIds,
  nextQuestionId: 1312,
  descriptions: [],
},
{
  id: 1312,
  question: 'How long have you had pain for?',
  options: [
    {
      nextQuestionId: 1313,
      label: 'Less than 3 weeks',
      shortLabel: '<3 weeks',
      redirectQuestionIds: [
        {painId: 1, questionId: 84, visitReasonIds: [252], extraQuestion: 1},
        {painId: 2, questionId: 163, visitReasonIds: [251], extraQuestion: 1},
        {painId: 3, questionId: 224, visitReasonIds: [254], extraQuestion: 1},
        {painId: 4, questionId: 43, visitReasonIds: [246], extraQuestion: 1},
        {painId: 5, questionId: 104, visitReasonIds: [247], extraQuestion: 1},
        {painId: 6, questionId: 123, visitReasonIds: [253], extraQuestion: 1},
        {painId: 7, questionId: 144, visitReasonIds: [248], extraQuestion: 1},
        {painId: 8, questionId: 23, visitReasonIds: [256], extraQuestion: 1},
        {painId: 9, questionId: 183, visitReasonIds: [249], extraQuestion: 1},
        {painId: 10, questionId: 24, visitReasonIds: [257], extraQuestion: 2},
        {painId: 11, questionId: 203, visitReasonIds: [250], extraQuestion: 1},
        {painId: 12, questionId: 64, visitReasonIds: [255], extraQuestion: 1},
        {painId: 13, questionId: 203, visitReasonIds: [], extraQuestion: 1},
      ],
    },
    {
      nextQuestionId: 1313,
      label: 'More than 3 weeks',
      shortLabel: '>3 weeks',
      redirectQuestionIds: [
        {painId: 1, questionId: 83, visitReasonIds: [252], extraQuestion: 3},
        {painId: 2, questionId: 164, visitReasonIds: [251], extraQuestion: 3},
        {painId: 3, questionId: 225, visitReasonIds: [254], extraQuestion: 1},
        {painId: 4, questionId: 44, visitReasonIds: [246], extraQuestion: 3},
        {painId: 5, questionId: 103, visitReasonIds: [247], extraQuestion: 3},
        {painId: 6, questionId: 124, visitReasonIds: [253], extraQuestion: 3},
        {painId: 7, questionId: 143, visitReasonIds: [248], extraQuestion: 2},
        {painId: 8, questionId: 24, visitReasonIds: [256], extraQuestion: 2},
        {painId: 9, questionId: 184, visitReasonIds: [249], extraQuestion: 3},
        {painId: 10, questionId: 24, visitReasonIds: [257], extraQuestion: 2},
        {painId: 11, questionId: 204, visitReasonIds: [250], extraQuestion: 3},
        {painId: 12, questionId: 65, visitReasonIds: [255], extraQuestion: 1},
        {painId: 13, questionId: 204, visitReasonIds: [], extraQuestion: 3},
      ],
    },
  ],
  descriptions: [],
},
{
  id: 1313,
  question: 'Do you still want to see a Primary Care Physician?',
  descriptions: [
    'A Primary Care Physician is a medical professional (MD/DO) who completed a 3 year residency in Family Medicine or Internal Medicine. A Primary Care Physician has broad training in treating most medical conditions.',
  ],
  title: 'Primary Care Physician',
  optionType: 1,
  questionType: 1,
  canDoList: [
    'Can order some diagnostic imaging tests',
    'Can prescribe medications',
    'Can perform basic injections',
    'Can not perform surgery',
  ],
  options: [
    {groupId: 'A10', label: 'Yes', isGoToSymptomFlow: false},
    {label: 'No', isGoToSymptomFlow: true},
  ],
},
];

export default {
  questionList,
  config: {
    firstQuestionId: 1301,
    lastQuestionId: 1313,
    treeDepth: 3,
    groupIdNewRedirect:[29]
  },
};
