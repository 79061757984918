import React, {useContext, useState} from 'react';
import _ from 'lodash';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import CircleButton from 'components/CircleButton';
import {useHistory} from 'react-router-dom';
import AuthContext from 'context/AuthContext';
import RootContext from 'context/RootContext';
import InputMask from 'react-input-mask';
import selectOptions from 'data/selectOptions';
import config from 'config/config';
import validators from 'data/validators';
import allRegex from 'data/regex';
import DateManual from 'components/FormControls/DateManual';
import PlaceAutocomplete from 'components/FormControls/PlaceAutocomplete';
import MyLink from 'components/MyLink';
import './PatientAccount.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import modalConfig from 'modals/modalConfig';
const {modalFunctions} = modalConfig;
const {errorModalData} = modalFunctions;
const {patientGenderOptions, preferredNumberOptions} = selectOptions;
const {exceptDigitsRegex} = allRegex;
const {
  __required,
  __phoneNumber,
  __email,
  __maxLength,
  __validDate,
  __validDateofBirthDate,
  __validDateofBirthDatelessthan300,
  _scrollToView,
} = validators;
const {MAX_TEXT_LENGTH} = config;
const {Analytics} = analyticsData;

const PatientAccount = props => {
  const history = useHistory();
  const {onUpdateProfile} = props;
  const authContext = useContext(AuthContext);
  const auth = _.get(authContext, 'auth', {});
  const firstName = _.get(authContext, 'auth.firstName', '');
  const lastName = _.get(authContext, 'auth.lastName', '');
  const phoneNumber = _.get(auth, 'phoneNumber', '');
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const values = {
    email: _.get(auth, 'email'),
    dateOfBirth: _.get(auth, 'dateOfBirth', ''),
    gender: _.get(auth, 'gender', 1),
    phoneNumber,
    workPhoneNumber: _.get(auth, 'workPhoneNumber', ''),
    address: _.get(auth, 'address', ''),
    latitude: _.get(auth, 'latitude', ''),
    longitude: _.get(auth, 'longitude', ''),
    homePhoneNumber: _.get(auth, 'homePhoneNumber', ''),
    preferredNumber: _.get(auth, 'preferredNumber', 0).toString(),
  };
  const [formData, setFormData] = useState({
    values: {...values},
    errors: {},
  });
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  const __validateGender = (value = null) => {
    const fValue = value !== null ? value : formValues['gender'];
    if (!__required(fValue)) {
      return 'Gender is required';
    }
    return null;
  };
  const __validateCellNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['phoneNumber'];
    if (!__required(fValue)) {
      return 'Cell number is required';
    }
    if (!__phoneNumber(fValue)) {
      return 'Invalid Cell number';
    }
    return null;
  };
  const __validateWorkNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['workPhoneNumber'];

    if (fValue && !__phoneNumber(fValue)) {
      return 'Invalid Work number';
    }
    return null;
  };
  const __validateHomeNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['homePhoneNumber'];

    if (fValue && !__phoneNumber(fValue)) {
      return 'Invalid Home number';
    }
    return null;
  };
  const __validatePreferredNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['preferredNumber'];
    if (!__required(fValue)) {
      return 'Preferred Number is required';
    }
    return null;
  };
  const __validateAddress = (value = null) => {
    const fValue =
      value !== null
        ? value
        : {
            address: formValues['address'],
            latitude: formValues['latitude'],
            longitude: formValues['longitude'],
          };
   
    if (!__required(fValue['address'])) {
      return 'Address is required';
    }
    if (!__required(fValue['latitude'])) {
      return 'Address is invalid';
    }
    return null;
  };
  const __validateDateOfBirth = (value = null) => {
    const fValue = value !== null ? value : formValues['dateOfBirth'];
    if (!__validDate(fValue)) {
      return 'Date of birth is invalid ';
    }
    if (!__validDateofBirthDate(fValue)) {
      return "Date of birth must be before today's date";
    }
    if(!__validDateofBirthDatelessthan300(fValue)){
      return "Check date of birth is valid";
    }
    return null;
  };

  const __validateForm = () => {
    const validEmail = !__validateEmail();
    const validGender = !__validateGender();
    const validCellNumber = !__validateCellNumber();
    const validWorkNumber = !__validateWorkNumber();
    const validHomeNumber = !__validateHomeNumber();
    const validPreferredNumber = !__validatePreferredNumber();
    const validAddress = !__validateAddress();
    const validDateOfBirth = !__validateDateOfBirth();
    return (
      validEmail &&
      validGender &&
      validCellNumber &&
      validWorkNumber &&
      validHomeNumber &&
      validPreferredNumber &&
      validAddress &&
      validDateOfBirth
    );
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name} = event.target;
    let {value} = event.target;
    let errorObject = {
      [name]: null,
    };
    let updateValueObj = {};
    switch (name) {
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      case 'gender':
        errorObject = {
          [name]: __validateGender(value),
        };
        break;
      case 'phoneNumber':
        value = value.replace(exceptDigitsRegex, '');
        errorObject = {
          [name]: __validateCellNumber(value),
        };
        break;
      case 'workPhoneNumber':
        value = value.replace(exceptDigitsRegex, '');
        errorObject = {
          [name]: __validateWorkNumber(value),
        };
        break;
      case 'homePhoneNumber':
        value = value.replace(exceptDigitsRegex, '');
        errorObject = {
          [name]: __validateHomeNumber(value),
        };
        break;
      case 'preferredNumber':
        errorObject = {
          [name]: __validatePreferredNumber(value),
        };
        break;
      case 'address':
       
        updateValueObj = {
          ...value,
        };
        errorObject = {
          [name]: __validateAddress(value),
        };
        break;
      case 'dateOfBirth':
        errorObject = {
          [name]: __validateDateOfBirth(value),
        };
        break;
      default:
        break;
    }
    setFormData(prevState => {
     
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
          ...updateValueObj,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };
  // const checkValidity=()=>{

  //   let checkError=__validateEmail();
  //   if(checkError){
  //     setGlobal('modal',errorModalData(checkError));
  //     return false
  //   }
  //   checkError=__validateGender();
  //   if(checkError){
  //     setGlobal('modal', errorModalData(checkError));
  //     return false
  //   }
  //   checkError=__validateCellNumber();
  //   if(checkError){
  //     setGlobal('modal',errorModalData(checkError));
  //     return false
  //   }
  //   checkError=__validateWorkNumber();
  //   if(checkError){
  //     setGlobal('modal', errorModalData(checkError));
  //     return false
  //   }
  //   checkError=__validateHomeNumber();
  //   if(checkError){
  //     setGlobal('modal', errorModalData(checkError));
  //     return false
  //   }
  //   checkError=__validatePreferredNumber();
  //   if(checkError){
  //     setGlobal('modal', errorModalData(checkError));
  //     return false
  //   }
  //   checkError=__validateAddress();
  //   if(checkError){
  //     setGlobal('modal', errorModalData(checkError));
  //     return false
  //   }
  //   checkError=__validateDateOfBirth();
  //   if(checkError){
  //     setGlobal('modal', errorModalData(checkError));
  //     return false
  //   }

  // }

  const handleForm = () => {
    if (!isValidForm) {
      let valError = [
        'email',
        'gender',
        'phoneNumber',
        'workPhoneNumber',
        'homePhoneNumber',
        'preferredNumber',
        'address',
        'dateOfBirth',
      ];
    
      valError.forEach(name => {
        changeHandler({
          target: {
            name: name,
            value:
              name === 'address'
                ? {
                    address: formValues['address'],
                    latitude: formValues['latitude'],
                    longitude: formValues['longitude'],
                  }
                : formValues[name],
          },
        });
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    let updatedFormValues = _.cloneDeep(formValues);
    if (updatedFormValues.phoneNumber !== phoneNumber) {
      updatedFormValues.hasPhoneNumberVerified = false;
    }
    onUpdateProfile(updatedFormValues);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  const optionalComponent = <span className="optional-text">(optional)</span>;

  const cellLabelComponent = <>Cell</>;
  const workLabelComponent = <>Work {optionalComponent}</>;
  const homeLabelComponent = <>Home {optionalComponent}</>;

  return (
    <div className="PatientAccount">
      <div className="h3">Account</div>
      <div className="PatientAccount-inner">
        <div className="pateintaccount-top">
          <div className="heading2">
            {firstName} {lastName}
          </div>
          <span className="para">
            To change your name please email us at{' '}
            <MyLink
              target="_self"
              className="spanlink"
              to="mailto:account@kaly.com"
              onClick={() =>
                Nux.sendEvent(
                  Analytics.Category.PatientSettings,
                  Analytics.Actions.Click,
                  'updateNameMail',
                )
              }>
              account@kaly.com
            </MyLink>
          </span>
        </div>
        <form>
          <div className="PatientAccount-block">
            <Input {...getGeneralInputProps('email')} label="Email" />
            <div className="form-row">
              <DateManual
                {...getGeneralInputProps('dateOfBirth')}
                label="Date of Birth"
                containerClass="column6"
              />
              <Select
                {...getGeneralInputProps('gender')}
                options={patientGenderOptions}
                label="Gender"
                containerClass="column6"
              />
            </div>
          </div>
          <div className="PatientAccount-block">
            <div className="heading2">Phone</div>
            <div className="form-row">
              <Input
                mask="(999) 999-9999"
                maskChar=" "
                type="tel"
                InputComponent={InputMask}
                {...getGeneralInputProps('phoneNumber')}
                containerClass="column6"
                label={cellLabelComponent}
              />
              <Input
                mask="(999) 999-9999"
                maskChar=" "
                type="tel"
                InputComponent={InputMask}
                {...getGeneralInputProps('workPhoneNumber')}
                containerClass="column6"
                label={workLabelComponent}
              />
            </div>
            <div className="form-row">
              <Input
                mask="(999) 999-9999"
                maskChar=" "
                type="tel"
                InputComponent={InputMask}
                {...getGeneralInputProps('homePhoneNumber')}
                containerClass="column6"
                label={homeLabelComponent}
              />
              <Select
                {...getGeneralInputProps('preferredNumber')}
                label="Preferred Number"
                options={preferredNumberOptions}
                containerClass="column6"
              />
            </div>
          </div>
          <div className="PatientAccount-block">
            <div className="heading2">Address</div>
            <PlaceAutocomplete
              name="address"
              value={{
                address: formValues['address'],
                latitude: formValues['latitude'],
                longitude: formValues['longitude'],
              }}
              error={formErrors['address']}
              onPlaceSelect={changeHandler}
              label=""
            />
          </div>
          <div className="patientAccount-button">
            <CircleButton onClick={handleForm}>Save</CircleButton>
            <span
              className="deactive-link"
              onClick={() => {
                history.push({
                  pathname: '/settings',
                  search: `?tab=privacy`,
                });
              }}>
              Deactivate my account
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PatientAccount;
