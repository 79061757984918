import React, {useState, useContext, useEffect} from 'react';
import {withRouter, Link} from 'react-router-dom';
import images from 'data/images';
import './PatientLandingHeader.css';
import AuthContext from 'context/AuthContext';
import _ from 'lodash';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import config from 'config/config';
import constants from 'data/constants';
const {StaticFooterPage} = constants;
const {Analytics} = analyticsData;

const PatientLandingHeader = props => {
  const authContext = useContext(AuthContext);
  const siteSettings = _.get(authContext, 'siteSettings');
  const {hideHeaderButtons} = props;
  const [active, toggleActive] = useState(null);
  const {scrollToJoinkalypsys, hideBtn} = props;
  const [screenWidth, setScreenWidth] = useState(null);
  useEffect(() => {
    function handleResize() {
      let w = window.screen.width;
      setScreenWidth(w);
    }
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  let rightHeaderComponent = null;
  if (!hideHeaderButtons) {
    rightHeaderComponent = (
      <>
        <div className="right-col">
          {scrollToJoinkalypsys && hideBtn ? (
            // <a
            //   className="desktopPricing"
            //   href={config.APP_DOMAIN + StaticFooterPage.PRICING}
            //   target="_self">
            //   Pricing
            // </a>
            <></>
          ) : (
            <div className="precticewith">
              <Link
                to="/list-your-practice"
                onClick={() =>
                  Nux.sendEvent(
                    Analytics.Category.PatientLanding,
                    Analytics.Actions.Click,
                    'listYourPractice',
                  )
                }
                style={{letterSpacing: '-.38px'}}>
                List your practice
              </Link>
            </div>
          )}
          <div className={['menu-content', hideBtn ? 'afternnone' : ''].join(' ')}>
            <span className="login-mobile">
              <Link to="/patient/login">Log in</Link>
            </span>
            <span className="login-web">
              <span>
                Log in <img src={images.whiteArrowPointer} alt="Black Arrow" />
              </span>
              <span className="dropdown-menu">
                <span>
                  <Link to="/patient/login">
                    <span>Patient</span> Log in
                  </Link>
                </span>
                <span>
                  <Link to="/login">
                    <span>Provider</span> Log in
                  </Link>
                </span>
              </span>
            </span>
            <span>
              <Link to="/patient/sign-up">Sign up</Link>
            </span>
            {/* {screenWidth < 767 && hideBtn && (
              <span className="mobile-Pricing">
                <a href={config.APP_DOMAIN + StaticFooterPage.PRICING} target="_self">
                  Pricing
                </a>
              </span>
            )} */}
          </div>
          <div className="header-bottom">
            <span className="provider-login">
              <Link to="/login">Provider Login</Link>
            </span>
            <span className="list-practice">
              <Link to="/list-your-practice">
                <span>Doctor?</span> List your practice
              </Link>
            </span>

            <span>
              <a href={`mailto:${_.get(siteSettings, 'contactUsLinks', '')}`}>Contact us</a>
            </span>
          </div>
        </div>
        <div
          className="mobile-menu"
          onClick={() => {
            toggleActive(!active);
          }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </>
    );
  }

  return (
    <div className={`PatientLandingHeader ${active ? 'menu-active' : ''}`}>
      <div className="header-content">
        <div className="left-col">
          <div className="logo">
            <a href={config.landingPageUrl}>
              <img alt="" className="logoblue" src={images.logo} />
              <img alt="" className="logowhite" src={images.logoWhite} />
            </a>
          </div>
        </div>
        {rightHeaderComponent}
      </div>
    </div>
  );
};

export default withRouter(PatientLandingHeader);
