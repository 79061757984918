import React, {Component} from 'react';
import _ from 'lodash';
import Layout from 'layout/AdminLayout';
import AuthContext from 'context/AuthContext';
import SubHeader from 'components/AfterAuth/SubHeader';
import SpecialtyRequest from './SpecialtyRequest';
import CertificationRequest from './CertificationRequest';
import SuffixRequest from './SuffixRequest';
import OtherInfoRequest from './OtherInfoRequest';
import './UpdateRequest.css';
import adminService from 'services/admin';
import NPIRequests from './NPIRequests';

const {getRequestCounts} = adminService;

class UpdateRequest extends Component {
  static contextType = AuthContext;
  state = {
    activeTab: null,
    totalCount: 0,
  };
  tabs = [
    {label: 'Suffix Requests', tab: 'suffix'},
    {label: 'Specialty Requests', tab: 'specialty'},
    {label: 'Certification Requests', tab: 'certification'},
    {label: 'NPI Requests', tab: 'NPIRequests'},
    {label: 'Other Requests', tab: 'other'},
  ];

  componentDidMount() {
    const tab = this.getTabName(this.props);
    this.getAndSetActiveTab(tab);
    this.getRequestCount();
  }

  getRequestCount = () => {
    const {requestCount} = this.context;
    if (requestCount && this.tabs.length > 0) {
      this.tabs[0].count = _.get(requestCount, 'suffixCount', 0);
      this.tabs[1].count = _.get(requestCount, 'specialtyCount', 0);
      this.tabs[2].count = _.get(requestCount, 'certificationCount', 0);
      this.tabs[3].count = _.get(requestCount, 'npiNumberCount', 0);
      this.tabs[4].count = _.get(requestCount, 'informationCount', 0);
    }
    this.setState({
      totalCount:
        _.get(requestCount, 'suffixCount', 0) +
        _.get(requestCount, 'specialtyCount', 0) +
        _.get(requestCount, 'certificationCount', 0) + _.get(requestCount, 'npiNumberCount', 0) +
        _.get(requestCount, 'informationCount', 0),
    });
  };

  componentDidUpdate(prevProps) {
    const tab = this.getTabName(this.props);
    const prevTab = this.getTabName(prevProps);
    if (tab !== prevTab) {
      this.getAndSetActiveTab(tab);
    }
  }

  getTabName = props => {
    const query = new URLSearchParams(props.location.search);
    const tab = query.get('tab') || _.get(this, 'tabs[0].tab');
    return tab;
  };

  getAndSetActiveTab = async tab => {
    const tabObj = _.find(this.tabs, {tab});
    if (!tabObj) {
      tab = _.get(this, 'tabs[0].tab');
    }
    this.setState({
      activeTab: tab,
    });
  };

  setActiveTab = async activeTab => {
    const params = new URLSearchParams();
    params.append('tab', activeTab);
    this.props.history.push({
      pathname: '/admin/update-request',
      search: `?${params.toString()}`,
    });
  };

  renderActiveTab = () => {
    const {activeTab} = this.state;
    let activeTabComponent = null;
    switch (activeTab) {
      case 'specialty':
        activeTabComponent = (
          <SpecialtyRequest updateContextRequestCountData={this.updateContextRequestCountData} />
        );
        break;
      case 'certification':
        activeTabComponent = (
          <CertificationRequest
            updateContextRequestCountData={this.updateContextRequestCountData}
          />
        );
        break;
      case 'suffix':
        activeTabComponent = (
          <SuffixRequest updateContextRequestCountData={this.updateContextRequestCountData} />
        );
        break;
        case 'NPIRequests':
        activeTabComponent = (
          <NPIRequests updateContextRequestCountData={this.updateContextRequestCountData} />
        );
        break;
      case 'other':
        activeTabComponent = (
          <OtherInfoRequest updateContextRequestCountData={this.updateContextRequestCountData} />
        );
        break;
      default:
        activeTabComponent = null;
        break;
    }
    return activeTabComponent;
  };

  updateContextRequestCountData = async () => {
    await this.updateContextData();
    this.getRequestCount();
  };

  updateContextData = async () => {
    const requestCountData = await getRequestCounts().catch(() => null);
    if (requestCountData && requestCountData.data) {
      const {setAdminRequestCountState} = this.context;
      setAdminRequestCountState(prevState => {
        return {
          ...prevState,
          requestCount: {
            ...requestCountData.data,
          },
        };
      });
    }
  };

  render() {
    const {activeTab} = this.state;
    const {name="Profile Update Approvals"}=this.props
    const tabHeaderList = (
      <ol className="tab-list">
        {this.tabs.map((tab, index) => {
          return (
            <li
              key={index}
              className={`tab-list-item ${tab.tab === activeTab ? 'tab-list-active' : ''}`}
              onClick={() => this.setActiveTab(tab.tab)}>
              {tab.label}
              <span className="review-count">{tab.count}</span>
            </li>
          );
        })}
      </ol>
    );
    return (
      <Layout>
        <div className="UpdateRequest">
          <SubHeader title={name} hideName />
          <div className="box-panel">
            <div className="UpdateRequest-content">
              <div className="setting-tabs">
                {tabHeaderList}
                <div className="tab-content">
                  <div>{this.renderActiveTab()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default UpdateRequest;
