import React, {Component} from 'react';
import _ from 'lodash';
import Layout from 'layout/AfterAuth';
import SubHeader from 'components/AfterAuth/SubHeader';
import ApprovalCard from 'components/AfterAuth/ApprovalCard';
import ConnectReviews from 'components/AfterAuth/ConnectReviews';
import ConnectSocial from 'components/AfterAuth/ConnectSocial';
import './ReviewAccounts.css';
import AuthContext from 'context/AuthContext';
import NotConnectMsg from '../BirdEyeNotConnectMsg';

class ReviewAccounts extends Component {
  static contextType = AuthContext;
  state = {
    activeTab: null,
    invitedUserList: null,
    practiceInsurancePlanList: null,
    practiceStaffMemberList: null,
  };
  tabs = [
    {label: 'Connect Reviews', tab: 'ConnectReviews'},
    {label: 'Connect Social', tab: 'ConnectSocial'},
  ];

  componentDidMount() {
    this.changeViewUser();
    const tab = this.getTabName(this.props);
    this.getAndSetActiveTab(tab);
  }

  componentDidUpdate(prevProps) {
    const tab = this.getTabName(this.props);
    const prevTab = this.getTabName(prevProps);
    if (tab !== prevTab) {
      this.getAndSetActiveTab(tab);
    }
  }

  changeViewUser = () => {
    const {auth, setAuthState} = this.context;
    
    const newViewUserId = _.get(auth, 'id', '');
    
    let isStaff=false
    let {roles}=auth
    if(roles && roles.length>0 &&roles[0].role === 'Staff'){
      isStaff=true
    }
    if (newViewUserId && !isStaff) {
      setAuthState(prevState => {
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            viewUserId: newViewUserId,
          },
        };
      });
    }
  };

  getTabName = props => {
    const query = new URLSearchParams(props.location.search);
    const tab = query.get('tab') || _.get(this, 'tabs[0].tab');
    return tab;
  };

  getAndSetActiveTab = async tab => {
    const tabObj = _.find(this.tabs, {tab});
    if (!tabObj) {
      tab = _.get(this, 'tabs[0].tab');
    }
    switch (tab) {
      case 'ConnectReviews':
        break;
      case 'ConnectSocial':
        break;
      default:
        break;
    }
    this.setState({
      activeTab: tab,
    });
  };

  setActiveTab = async activeTab => {
    const params = new URLSearchParams();
    params.append('tab', activeTab);
    this.props.history.push({
      pathname: '/Reviews/account',
      search: `?${params.toString()}`,
    });
  };

  renderActiveTab = () => {
    const {activeTab} = this.state;
    let activeTabComponent = null;
    switch (activeTab) {
      case 'ConnectReviews':
        activeTabComponent = <ConnectReviews />;
        break;
      case 'ConnectSocial':
        activeTabComponent = <ConnectSocial />;
        break;
      default:
        activeTabComponent = null;
        break;
    }
    return activeTabComponent;
  };
  render() {
    //const {auth} = this.context;
   // const birdEyeBusinessId=_.get(auth, 'birdEyeBusinessId', null);
    // if(!birdEyeBusinessId){
    //   return <NotConnectMsg/>
    // }
    const {activeTab} = this.state;
    const tabHeaderList = (
      <ol className="tab-list">
        {this.tabs.map((tab, index) => {
          return (
            <li
              key={index}
              className={`tab-list-item ${tab.tab === activeTab ? 'tab-list-active' : ''}`}
              onClick={() => this.setActiveTab(tab.tab)}>
              {tab.label}
              <span className="review-count">{tab.count}</span>
            </li>
          );
        })}
      </ol>
    );
    return (
      <Layout>
        <div className="ReviewAccounts">
          <SubHeader title="Review Accounts" />
          <div className="ReviewAccounts-content">
            <ApprovalCard></ApprovalCard>
            <div className="setting-tabs">
              {tabHeaderList}
              <div className="tab-content">
                <div className="box-panel">{this.renderActiveTab()}</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ReviewAccounts;
