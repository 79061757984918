import React, {useState, useCallback, useEffect} from 'react';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import Textarea from 'components/FormControls/Textarea';
import InputList from 'components/FormControls/InputList';
import CircleButton from 'components/CircleButton';
import selectOptions from 'data/selectOptions';
import validators from 'data/validators';
import config from 'config/config';
import './PracticeOverview.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {practiceSoftwareOptions} = selectOptions;
const {__required, __maxLength, __isValidUrl,_scrollToView} = validators;
const {MAX_TEXT_LENGTH} = config;
const {Analytics} = analyticsData;

const PracticeOverview = props => {
  const {data, onFormSubmit, hasPermissionToEdit} = props;
  const websiteErrors = useCallback(() => {
    return data.websites.map(website => false);
  }, [data.websites]);
  const [formData, setFormData] = useState({
    values: data,
    errors: {websites: websiteErrors()},
  });
  const {values: formValues, errors: formErrors} = formData;

  useEffect(() => {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        values: data,
        errors: {
          websites: websiteErrors(),
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  // Validation functions
  const __validateList = name => {
    const array = formValues[name];
    let isValid = true;
    array.forEach(email => {
      if (!__isValidUrl(email)) {
        isValid = false;
      }
    });
    return isValid;
  };
  const __validateWebsite = (name) => {
    const array = formValues[name];
    let tempvalArray=[]
    array.forEach(email => {
      if(!email){
        tempvalArray.push('Website is required')
      }else
      if (!__isValidUrl(email)) {
        tempvalArray.push('Enter a valid Website')
      }else
      tempvalArray.push(null)
    });
    
    return tempvalArray;
  };
  const __validateName = (value = null) => {
    const fValue = value !== null ? value : formValues['name'];
    if (!__required(fValue)) {
      return 'Practice name is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateDescription = (value = null) => {
    const fValue = value !== null ? value : formValues['description'];
    if (!__required(fValue)) {
      return 'Practice Description is required';
    }
    if (!__maxLength(fValue, 2000)) {
      return `Max 2000 characters`;
    }
    return null;
  };
  const __validateSoftware = (value = null) => {
    const fValue = value !== null ? value : formValues['software'];
    if (!__required(fValue)) {
      return 'Practice software is required';
    }
    return null;
  };

  const __validateForm = () => {
    const validName = !__validateName();
    const validDescription = !__validateDescription();
    const validSoftware = !__validateSoftware();
    const validWebsites = __validateList('websites');

    return validName && validDescription && validSoftware && validWebsites;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'name':
        errorObject = {
          [name]: __validateName(value),
        };
        break;
      case 'description':
        errorObject = {
          [name]: __validateDescription(value),
        };
        break;
      case 'websites':
        errorObject = {
          [name]: __validateWebsite('websites'),
        };
        break;
      case 'software':
        errorObject = {
          [name]: __validateSoftware(value),
        };
        break;
      default:
        break;
    }
    // setFormData({
    //   ...formData,
    //   values: {
    //     ...formData.values,
    //     [name]: value,
    //   },
    //   errors: {
    //     ...formData.errors,
    //     ...errorObject,
    //   },
    // });
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,          
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };
  const handleForm = () => {
    if (!isValidForm) {
      let valError = [
        'name',
        'description',
        'websites',
        'software'
      
      ];
      valError.forEach(name => {
        changeHandler({target: {name: name, value:formValues[name]}});
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    Nux.sendEvent(Analytics.Category.DoctorPractice, Analytics.Actions.Click, 'practiceInfoChange');
    onFormSubmit(formValues);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
      disabled: !hasPermissionToEdit,
    };
  };

  const websiteListProps = {
    name: 'websites',
    listItems: formValues['websites'],
    errors: formErrors['websites'],
    onListUpdated: changeHandler,
    validateItem: __isValidUrl,
    label: 'Practice Websites',
    addLabelText: 'Add Website',
    removeLabelText: 'Remove',
    maxItems: 5,
    minItems: 0,
    disabled: !hasPermissionToEdit,
  };
  return (
    <div className="box-content PracticeOverview">
      <div className="h3">Practice Overview</div>
      <div className="practiceOverview-inner">
        <form>
          <Input {...getGeneralInputProps('name')} label="Practice Name"></Input>
          <Textarea
            {...getGeneralInputProps('description')}
            label="Practice Description"
            rows="5"
            placeholder="Methods and philosophy, or other points about your Practice"
          />
          <InputList {...websiteListProps} />
          <Select
            {...getGeneralInputProps('software')}
            label="Practice Software"
            options={practiceSoftwareOptions}></Select>
          {hasPermissionToEdit && (
            <CircleButton  onClick={handleForm} btnType="whiteButton">
              Save
            </CircleButton>
          )}
        </form>
      </div>
    </div>
  );
};

export default PracticeOverview;
