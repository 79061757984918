import React, {useState} from 'react';
import _ from 'lodash';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import Textarea from 'components/FormControls/Textarea';
import AnswerRating from '../AnswerRating';
import Checkbox from 'components/FormControls/Checkbox';
import momentHelper from 'data/moment/momentHelper';
import helper from 'data/helper';
import validators from 'data/validators';
import constants from 'data/constants';
import './ReviewVisitModal.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import TimeZoneLabelShort from 'components/MyDatetime/TimeZoneLabelShort';

const {utcToLocalMoment, readableTimeFormat} = momentHelper;
const {getSuffixString} = helper;
const {__required, __maxLength} = validators;
const {WAIT_TIME_TITLES} = constants;
const {Analytics} = analyticsData;

const ReviewVisitModal = props => {
  const {closeModal} = props;
  const {data, onReviewSave} = props;
  const appointment = _.get(data, 'appointment');
  const {appointmentStartDateTime} = appointment;
  const apStartDateTimeObj = utcToLocalMoment(appointmentStartDateTime);
  const location = _.get(appointment, 'providerAvailability.practiceLocation');
  const provider = _.get(appointment, 'providerAvailability.provider');
  const specialties = _.get(provider, 'specialties', []);
  const suffixes = _.get(provider, 'suffixes', []);
  const doctorUser = _.get(provider, 'user', {});
  const customSpecialty = _.get(provider, 'customSpecialty', null);
  const [formData, setFormData] = useState({
    values: {
      patientAppointmentId: appointment.id,
      review: '',
      doctorKnowledge: 0,
      doctorApproachable: 0,
      doctorBedsideManner: 0,
      staffProfessional: 0,
      staffScheduling: 0,
      staffResponseTime: 0,
      officeCleanliness: 0,
      officeWaitTimes: 0,
      officeAtmosphere: 0,
      isAnonymousUser: false,
    },
    errors: {
      isShowError: false,
    },
  });
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validateRating = (name, value = null) => {
    const fValue = value !== null ? value : formValues[name];
    if (!__required(fValue)) {
      return 'Rating is required';
    }
    return null;
  };
  const __validateReview = (value = null) => {
    const fValue = value !== null ? value : formValues['review'];
    if (!__required(fValue)) {
      return 'Review is required';
    }
    if (!__maxLength(fValue, 1000)) {
      return `Max 1000 characters`;
    }
    return null;
  };

  const __validateForm = () => {
    const validDoctorKnowledge = !__validateRating('doctorKnowledge');
    const validDoctorApproachable = !__validateRating('doctorApproachable');
    const validDoctorBedsideManner = !__validateRating('doctorBedsideManner');
    const validStaffProfessional = !__validateRating('staffProfessional');
    const validStaffScheduling = !__validateRating('staffScheduling');
    const validStaffResponseTime = !__validateRating('staffResponseTime');
    const validOfficeCleanliness = !__validateRating('officeCleanliness');
    const validOfficeWaitTimes = !__validateRating('officeWaitTimes');
    const validOfficeAtmosphere = !__validateRating('officeAtmosphere');
    const validReview = !__validateReview();
    return (
      validReview &&
      validDoctorKnowledge &&
      validDoctorApproachable &&
      validDoctorBedsideManner &&
      validStaffProfessional &&
      validStaffScheduling &&
      validStaffResponseTime &&
      validOfficeCleanliness &&
      validOfficeWaitTimes &&
      validOfficeAtmosphere
    );
  };

  const handleForm = () => {
    const isValidForm = __validateForm();
    if (isValidForm) {
      Nux.sendEvent(
        Analytics.Category.PatientDashboard,
        Analytics.Actions.Click,
        'reviewAppointment',
      );
      onReviewSave(formValues);
    } else {
      setFormData(prevState => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            isShowError: true,
          },
        };
      });
    }
  };

  const changeHandler = event => {
    const {name} = event.target;
    let {value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'review':
        if (value.length > 1000) {
          value = formValues['review'];
        }
        errorObject = {
          [name]: __validateReview(value),
        };
        break;
      case 'doctorKnowledge':
        errorObject = {
          [name]: __validateRating(name, value),
        };
        break;
      case 'doctorApproachable':
        errorObject = {
          [name]: __validateRating(name, value),
        };
        break;
      case 'doctorBedsideManner':
        errorObject = {
          [name]: __validateRating(name, value),
        };
        break;
      case 'staffProfessional':
        errorObject = {
          [name]: __validateRating(name, value),
        };
        break;
      case 'staffScheduling':
        errorObject = {
          [name]: __validateRating(name, value),
        };
        break;
      case 'staffResponseTime':
        errorObject = {
          [name]: __validateRating(name, value),
        };
        break;
      case 'officeCleanliness':
        errorObject = {
          [name]: __validateRating(name, value),
        };
        break;
      case 'officeWaitTimes':
        errorObject = {
          [name]: __validateRating(name, value),
        };
        break;
      case 'officeAtmosphere':
        errorObject = {
          [name]: __validateRating(name, value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };
  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
      childClass: 'anonymous-txt',
    };
  };
  let specialtyNames = _.map(specialties, 'specialty');
  if (customSpecialty) {
    specialtyNames = [...specialtyNames, customSpecialty];
  }
  return (
    <div className="ReviewVisitModal">
      <div className="modal-content-header">
        <div className="h3">How was your recent visit?</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <div className="AppointmentItem">
          <div className="ap-dc-info">
            <div className="dc-img">
              <img src={doctorUser.profileImageUrl || images.user} alt="doctor" />
            </div>
            <div className="dc-info">
              <div className="name">
                {doctorUser.firstName} {doctorUser.lastName}
                {getSuffixString(suffixes)}
              </div>
              <div className="dc-specialist">{specialtyNames.join(', ')}</div>
              <div className="address">
                <span>{location.address}</span>
              </div>
            </div>
          </div>
          <div className="ap-dc-timeinfo">
            <div className="appointment-time">
              <span className="day">Completed</span>
              <span className="month">{apStartDateTimeObj.format('MMM DD')}</span>
              <span className="time">
                {readableTimeFormat(apStartDateTimeObj)}
                <TimeZoneLabelShort />
              </span>
            </div>
          </div>
        </div>
        <div className="recent-visit-info">
          <div className="visit-description">
            <Textarea label="Tell us more about your visit." {...getGeneralInputProps('review')} />
            <span className="total-character">
              {1000 - formValues['review'].length} characters remaining
            </span>
          </div>
          <div className="visit-feedback-rating">
            <div className="question-list">
              <div className="question-text">1. Tell us more about your provider</div>
              <div className="answer">
                <AnswerRating
                  isShowError={formErrors['isShowError']}
                  {...getGeneralInputProps('doctorKnowledge')}
                  question="Knowledge"
                />
                <AnswerRating
                  isShowError={formErrors['isShowError']}
                  {...getGeneralInputProps('doctorApproachable')}
                  question="Approachability"
                />
                <AnswerRating
                  isShowError={formErrors['isShowError']}
                  {...getGeneralInputProps('doctorBedsideManner')}
                  question="Bedside Manner"
                />
              </div>
            </div>
            <div className="question-list">
              <div className="question-text">2. Tell us more about the staff</div>
              <div className="answer">
                <AnswerRating
                  isShowError={formErrors['isShowError']}
                  {...getGeneralInputProps('staffProfessional')}
                  question="Professionalism"
                />
                <AnswerRating
                  isShowError={formErrors['isShowError']}
                  {...getGeneralInputProps('staffScheduling')}
                  question="Ease of scheduling"
                />
                <AnswerRating
                  isShowError={formErrors['isShowError']}
                  {...getGeneralInputProps('staffResponseTime')}
                  question="Response Time"
                />
              </div>
            </div>
            <div className="question-list">
              <div className="question-text">3. Tell us more about the office</div>
              <div className="answer">
                <AnswerRating
                  isShowError={formErrors['isShowError']}
                  {...getGeneralInputProps('officeCleanliness')}
                  question="Cleanliness"
                />
                <AnswerRating
                  isShowError={formErrors['isShowError']}
                  {...getGeneralInputProps('officeAtmosphere')}
                  question="Atmosphere"
                />
                <AnswerRating
                  titles={WAIT_TIME_TITLES}
                  isShowError={formErrors['isShowError']}
                  {...getGeneralInputProps('officeWaitTimes')}
                  question="Wait times"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="privacy-text">
          Your first name and only the first letter of your last name will display for privacy
          purposes. By clicking “Submit Review” I verify that I, or my family member, have received
          treatment from this provider.
        </div>
      </div>
      <div className="modal-content-footer">
        <Checkbox {...getGeneralInputProps('isAnonymousUser')}>Post my review anonymously</Checkbox>
        <div>
          <CircleButton onClick={handleForm}>Submit Review</CircleButton>
          {formErrors['isShowError'] && (
            <span className="error-text">All fields are required to submit </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewVisitModal;
