import React, { useCallback, useContext, useEffect, useState } from 'react';

import images from 'data/images';
import "./LikeUnlike.css"
var debounce = require('lodash/debounce');


const LikeUnlike = props => {


  const { propsFeedbackStatusId, rangeValue, onChangerangeValue } = props;
  const [ratingValue, setRatingValue] = useState(0)
  useEffect(()=>{
    if(rangeValue>0)
    setRatingValue(rangeValue)
  },[rangeValue])
  useEffect(() => {
    if (ratingValue > 0 && propsFeedbackStatusId !== 2){
      onChangerangeValue(ratingValue)
    }
     
  }, [ratingValue])
  
  if (propsFeedbackStatusId == 2) {
    if(ratingValue===0){
      return (
        <div className="like_unlike">
          
            <div className={['noallow novalue likediv'].join(' ')} >
              <img className='likeimg' src={images['likeIcon']} />
            </div>
        
            <div className={['unlike','novalue', 'noallow'].join(' ')}  >
              <img src={images['UnlikeIcon']} />
            </div>
         
        </div>
      );
    }
    return (
      <div className="like_unlike">
        {ratingValue > 1 && (
          <div className={['likediv noallow'].join(' ')} >
            <img className='likeimg' src={images['likeIconGreen']} />
          </div>
        )}
        {ratingValue === 1 && (
          <div className={['unlike', 'noallow'].join(' ')}  >
            <img src={images['UnlikeIconRed']} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="like_unlike">
      <div className={["likediv",propsFeedbackStatusId == 2 ? 'noallow' : ''].join(' ')} onClick={() => setRatingValue(5)}>
        <img className='likeimg' src={images[ratingValue === 5 ? 'likeIconGreen' : 'likeIcon']} />
      </div>
      <div className={['unlike', propsFeedbackStatusId == 2 ? 'noallow' : ''].join(' ')} onClick={() => setRatingValue(1)} >
        <img src={images[ratingValue === 1 ? 'UnlikeIconRed' : 'UnlikeIcon']} />
      </div>
    </div>
  );
};

export default LikeUnlike;
