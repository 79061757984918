import React, {Component} from 'react';
import Header from 'layout/Header';
import './HealthcareProviderAgreement.css';

class HealthcareProviderAgreement extends Component {
  render() {
    return (
      <>
        <Header hideHeaderButtons={true}></Header>
        <div className="HealthcareProviderAgreement">
          <div className="container">
            <div className="template-inner">
              <div className="main-header">Kaly Healthcare Provider Agreement</div>
              <div className="template-content">
                <div className="sub-header-title">V.01.1220</div>
                <div className="para">
                  This Healthcare Provider Agreement (“Agreement”) between Kaly Inc.
                  (“Kaly”) and Healthcare Provider, as that term is defined below, is effective
                  as of the date that Healthcare Provider submits this Agreement to Kaly.
                  Kaly and Healthcare Provider are referred to collectively as “Parties,” and
                  each individually as a “Party.”
                </div>
                <div className="para">
                  <b>Whereas,</b> Kaly provides the website www.kaly.com (“the Website”) to
                  the public and for registered Users to facilitate a connection of patients to
                  Healthcare Providers;
                </div>
                <div className="para">
                  <b>Whereas,</b> patients may schedule appointments with a Healthcare Provider on
                  the Website;
                </div>
                <div className="para">
                  <b>Whereas,</b> the Website displays information about Healthcare Providers to the
                  public, including information regarding specialty and user ratings that are
                  aggregated from the internet;
                </div>
                <div className="para">
                  <b>Whereas,</b> Healthcare Provider desires that its information be available and
                  displayed on the Website and desires that Kaly facilitate the connection of
                  patients to Healthcare Provider and for patients to be able to schedule
                  appointments with Healthcare Provider via the Website;
                </div>
                <div className="para">
                  Therefore, the Parties agree as follows:
                  <ol type="I">
                    <li>
                      Definitions:
                      <ol type="a">
                        <li>
                          “Healthcare Provider” means the entity on behalf of which this Agreement
                          is completed and submitted, and includes all Members and Employees of the
                          entity. It may also mean an individual healthcare practitioner.
                        </li>
                        <li>
                          “Members and Employees” means all doctors, dentists, nurses, physicians
                          assistants, therapists and other specialists that are affiliated with the
                          entity and who see patients by appointment, and who are part of a single
                          covered entity (as that term is defined under HIPAA) or a single organized
                          health care arrangement (as that term is defined under HIPAA). A list of
                          Members and Employees of Healthcare Provider is included herein as
                          Schedule A.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Representations and Warranties of Kaly:
                      <ol type="a">
                        <li>
                          Kaly will use Healthcare Provider’s information only as permitted by
                          this Agreement and for no other purpose.
                        </li>
                        <li>
                          Kaly expressly disclaims any representation and warranty regarding the
                          accuracy of information provided to Kaly by patients, including
                          patients who make appointments with Healthcare Provider via the Website.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Representations and Warranties of Healthcare Provider:
                      <ol type="a">
                        <li>
                          Healthcare Provider represents and warrants that all Members and Employees
                          of Healthcare Provider have the necessary valid, unexpired, unrevoked and
                          unrestricted credentials, certifications and licenses required to practice
                          the Member’s and Employee’s healthcare specialty in all jurisdictions in
                          which the member or employee practices.
                        </li>
                        <li>
                          Healthcare Provider represents and warrants that all Members and Employees
                          of Healthcare Provider are not excluded from Medicare and Medicaid
                          programs.
                        </li>
                        <li>
                          Healthcare Provider represents and warrants that it has the right to enter
                          into this Agreement for each of its Members and Employees.
                        </li>
                        <li>
                          Healthcare Provider represents and warrants that it has all necessary
                          right, title, and interest in and to the information provided by
                          Healthcare Provider to Kaly, including any and all rights and
                          interests to any copyright, trademark, other intellectual property, rights
                          of publicity, and rights of privacy.
                        </li>
                        <li>
                          Healthcare Provider represents and warrants that all information provided
                          to Kaly, regardless of whether that information is displayed on the
                          Website, is true and accurate of the date of provision of such
                          information.
                        </li>
                        <li>
                          Healthcare Provider represents and warrants that it will keep its
                          information displayed on the Website up to date and will certify the
                          accuracy of the information no less than once per year, and upon the
                          renewal of the Healthcare Provider’s contract.
                        </li>
                        <li>
                          Healthcare Provider will truthfully advertise its services and refrain
                          from making any promises it cannot keep.
                        </li>
                        <li>
                          Healthcare Provider will abide by all of its/their ethical and legal
                          obligations in connection with the Kaly service and the provision of
                          Healthcare Provider’s services.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Responsibilities of Kaly
                      <ol type="a">
                        <li>
                          Kaly will connect patients to Healthcare Provider as determined by
                          Kaly’ proprietary computer algorithm. The connection will account for
                          specialty, geography, insurance, and other factors, including a patient
                          score that is determined by aggregating information provided by various
                          Healthcare Providers about individual patients. Healthcare Provider may
                          specify, based on patient score, whether it will be connected to a
                          particular patient. See Schedule B.
                        </li>
                        <li>
                          Kaly will maintain and host a location on the Website dedicated to
                          Healthcare Provider (the “Page”). The Page will include a Healthcare
                          Provider description, including a description of the healthcare services
                          provided by Healthcare Provider, insurance accepted by Healthcare
                          Provider, full name, address, phone number, and fax number of Healthcare
                          Provider. Healthcare Provider will provide the information to be included
                          on the Page.
                        </li>
                        <li>
                          Kaly will allow patients who are selected for connection to Healthcare
                          Provider to view Healthcare Provider’s available appointments and book an
                          appointment directly from the Page.
                        </li>
                        <li>
                          Kaly will send reminders to patients of upcoming appointments with
                          Healthcare Provider.
                        </li>
                        <li>
                          Kaly will aggregate information from various websites that will be
                          used to provide a rating for Healthcare Provider and display that
                          information on the Page. Healthcare Provider will be able to access that
                          rating and provide comments that will be displayed on the Page. Kaly
                          reserves the right to review Healthcare Provider comments before
                          displaying such comments on the Page and to refuse to display comments
                          that it, in its sole discretion, believes are inappropriate content for
                          the Website. Kaly will not withhold display of the comments on the
                          Page unreasonably. Healthcare Provider may challenge a determination by
                          Kaly not to display comments on the Page, however, the final decision
                          regarding whether to post a Healthcare Provider comment to the Page
                          resides with Kaly.
                        </li>
                        <li>
                          Kaly hereby grants to Healthcare Provider a renewable, non-exclusive,
                          revocable license to use the Kaly logo in Healthcare Provider’s
                          advertising, for the sole purpose of advertising that Healthcare Provider
                          is affiliated with Kaly.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Responsibilities of Healthcare Provider
                      <ol type="a">
                        <li>
                          Healthcare Provider will grant Kaly access to Healthcare Provider’s
                          calendar system such that Kaly may be able to inform patients of
                          available appointments and may be able to schedule appointments between
                          patients and Healthcare Provider. Healthcare Provider will provide
                          cooperation and technical assistance to Kaly to facilitate the access
                          to Healthcare Provider’s calendar system.
                        </li>
                        <li>
                          Healthcare Provider will provide information, including a description of
                          services, relevant certifications and licenses, general Healthcare
                          Provider information, trademarks, logos, address, phone number, fax
                          number, and insurance accepted, to Kaly, and agrees that Kaly may
                          display that information on the Page. Healthcare Provider allows Kaly
                          to display calendar and appointment information on the Page. Healthcare
                          Provider will cooperate with any and all of Kaly’ efforts to verify
                          any information provided by Healthcare Provider. See Schedule C.
                        </li>
                        <li>
                          Healthcare Provider will maintain an account with Kaly through which
                          Healthcare Provider provides Kaly with its information as described in
                          part b of this section. Healthcare Provider will review the information
                          not less often than once per calendar year, and certify that the
                          information is accurate not less often than once per calendar year.
                          Healthcare Provider will update the information within fifteen calendar
                          days of any change of information, including certification and licensing
                          information, and certify that the information is accurate.
                        </li>
                        <li>
                          Healthcare Provider will have access to patient information after a
                          patient schedules an appointment with Healthcare Provider. Healthcare
                          Provider will use this information solely as needed to facilitate the
                          appointment with the patient. Healthcare Provider will use this
                          information only in accordance with all applicable rules, laws, and
                          regulations, including HIPAA. With respect to the patients that are
                          California Residents, Healthcare Provider will comply with the additional
                          privacy requirements under the California Consumer Privacy Act of 2018, as
                          amended (Cal. Civ. Code §§ 1798.100 to 1798.199) and as set forth in the
                          CCPA Addendum hereto, which Addendum is expressly made part of and
                          incorporated by reference into this Agreement.
                        </li>
                        <li>
                          Healthcare Provider will be responsible for the healthcare
                          provider/patient relationship between Healthcare Provider and the patient.
                          Healthcare Provider agrees that no patient relationship arises between any
                          patient and Kaly and that no action of Kaly under this Agreement
                          constitutes the practice of medicine. Healthcare Provider is responsible
                          for collecting all fees and payments from the patients and agrees that
                          Kaly has no obligation to reimburse Healthcare Provider for any
                          services rendered by Healthcare Provider and has no obligation to assist
                          Healthcare Provider in the collection of any fees from patients.
                        </li>
                        <li>
                          Healthcare Provider expressly opts in to receiving email, facsimile or
                          other forms of communication from Kaly. This opt-in supersedes any opt
                          out that Healthcare Provider may have previously submitted.
                        </li>
                        <li>
                          Healthcare Provider will promptly pay any charges for the service, and any
                          other charges it incurs in business dealings with Kaly. Failure to
                          timely pay its charges will subject Healthcare Provider to suspension or
                          termination of the Kaly service with no right to refund or other
                          liability owed by Kaly.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Healthcare Provider Restrictions
                      <ol type="a">
                        <li>
                          The information provided by Kaly will continue to be the exclusive
                          property of Kaly. This Agreement shall not be deemed to convey any
                          right, title or interest, including patent, copyright or other proprietary
                          right, in or to any portion or part of the Website. The programs and
                          software which operate the Website are confidential trade secrets of
                          Kaly and, therefore, Healthcare Provider and its members and employees
                          agree not to modify the programs or any embodiment thereof or attempt to
                          decipher, decompile, disassemble or reverse engineer the programs or
                          software that constitute the Website or any embodiment thereof.
                        </li>
                        <li>
                          Non-Compete. During the term of this Agreement and for two years
                          thereafter, Healthcare Provider and its Members and Employees will not,
                          directly or indirectly, on Healthcare Provider’s own behalf or on behalf
                          of or in conjunction with any person, business, firm, company, or other
                          entity, set up, join, become employed by, be engaged in, or provide any
                          advice or services to, any enterprise (including, without limitation, any
                          corporation, partnership, proprietorship, or other venture) which competes
                          with Kaly.
                        </li>
                        <li>
                          Non-Solicitation. During the term of this Agreement and for two years
                          thereafter, Healthcare Provider and its Members and Employees will not,
                          directly or indirectly, on Healthcare Provider’s own behalf or on behalf
                          of or in conjunction with any person, business, firm, company, or other
                          entity, solicit any employee or contractor of Kaly for employment,
                          retention, or other job or work without the express written approval of
                          Kaly. Moreover, during the term of this Agreement and for two years
                          thereafter, Healthcare Provider and its Members and Employees will not,
                          directly or indirectly, on Healthcare Provider’s own behalf or on behalf
                          of or in conjunction with any person, business, firm, company, or other
                          entity, solicit, encourage, or recommend that any Healthcare Provider,
                          Medical Consultant, or User leave, discontinue, or cease its relationship
                          with Kaly or otherwise conduct business with a competitor of Kaly.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Fees and Payments
                      <ol type="a">
                        <li>$299 per month, billed on the first of the month.</li>
                        <li>Late fee: 3% interest per month on late invoices.</li>
                        <li>
                          Termination. Upon termination of this Agreement for any reason, Healthcare
                          Provider shall pay to Kaly all fees due or accrued under this
                          Agreement as of the date of the termination. Kaly shall not be
                          responsible to repay any portion of any fee (used or unused) paid to
                          Kaly upon termination of this Agreement.
                        </li>
                        <li>
                          Same Fee. Healthcare Provider agrees not to charge any patients different
                          amounts based on the patient’s connection to Healthcare Provider being
                          made by Kaly.
                        </li>
                        <li>
                          Regulatory Notice. The Parties agree that any and all Healthcare Provider
                          payments for Kaly’ services are:
                          <ol type="i">
                            <li>
                              consistent with fair market value in an arm’s length transaction;
                            </li>
                            <li>
                              not based on the value or volume of any items or services provided by
                              Healthcare Provider to patients; and
                            </li>
                            <li>
                              do not constitute splitting of any professional fees paid to
                              Healthcare Provider by patients.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      Limitation of Liability
                      <ol type="a">
                        <li>
                          The Parties agree that Kaly and its parents, subsidiaries, affiliates,
                          partners, directors, officers, employees, suppliers, vendors or licensors
                          shall not be liable or in any way responsible for the cancellation or
                          rescheduling of any appointment that was made via the Website, mistakes or
                          errors appearing in the content of Healthcare Provider’s Page, or for any
                          other reason unless Kaly actions is wilfull or intentional. To the
                          extent such limitation of liability is unavailable under the applicable
                          law, the Parties agree that this provision should be interpreted to apply
                          to the Parties in the most favorable manner to fulfill the intention of
                          this clause, which is to limit Kaly’ liability.
                        </li>
                        <li>
                          TO THE MAXIMUM EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES SHALL
                          KALY OR ITS PARENTS, SUBSIDIARIES, AFFILIATES, PARTNERS, DIRECTORS,
                          OFFICERS, EMPLOYEES, SUPPLIERS, VENDORS OR LICENSORS BE LIABLE TO
                          HEALTHCARE PROVIDER, ITS MEMBERS AND EMPLOYEES FOR ANY (i) LOSS OF
                          PROFITS, LOST REVENUE, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY
                          OR CONSEQUENTIAL DAMAGES, (ii) LOSS OF DATA, (iii) LOSS OR INTERRUPTION OF
                          THE WEBSITE, (iv) PROVISION OF SERVICES, HEALTH CARE OR OTHERWISE, TO
                          PATIENTS, OR (v) PATIENT REVIEWS, WHETHER IN AN ACTION IN CONTRACT, TORT
                          OR BASED ON A WARRANTY, EVEN IF KALY HAS BEEN ADVISED OF THE
                          POSSIBILITY OF SUCH DAMAGES.
                        </li>
                        <li>
                          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE MAXIMUM
                          AGGREGATE LIABILITY OF KALY OR ITS PARENTS, SUBSIDIARIES, AFFILIATES,
                          PARTNERS, DIRECTORS, OFFICERS, EMPLOYEES, SUPPLIERS, VENDORS OR LICENSORS
                          FOR ANY LOSS OR DAMAGES (WHETHER BASED IN CONTRACT, TORT OR ANY OTHER FORM
                          OF ACTION) (A) RELATING TO OR ARISING OUT OF THIS AGREEMENT, OR (B)
                          RELATING TO OR ARISING OUT OF ANY OTHER AGREEMENT BETWEEN, ON THE ONE
                          HAND, HEALTHCARE PROVIDER AND ITS MEMBERS AND EMPLOYEES, AND, ON THE OTHER
                          HAND, KALY OR ANY THIRD-PARTY THAT IS A PARTNER, VENDOR, SUPPLIER,
                          LICENSOR OR LICENSEE OF KALY, EXCEED THE GREATER OF: (Y) THE AGGREGATE
                          OF THE AMOUNT OF FEES ACTUALLY PAID BY HEALTHCARE PROVIDER TO KAL
                          HEREUNDER DURING THE PREVIOUS TWELVE (12)-MONTH PERIOD, OR (Z) ONE HUNDRED
                          DOLLARS ($100).
                        </li>
                        <li>
                          ANY CLAIM THAT IS MADE BY HEALTHCARE PROVIDER ARISING UNDER THIS AGREEMENT
                          OR OTHERWISE, MUST BE MADE AND FILED IN A FORUM OF COMPETENT JURISDICTION
                          BEFORE THE EXPIRATION OF THE APPLICABLE STATUTE OF LIMITATIONS OR WITHIN
                          ONE (1) YEAR OF ACCRUAL OF THE CAUSE OF ACTION, WHICHEVER IS EARLIER . IT
                          IS ACKNOWLEDGED THAT THIS MAY BE A SHORTENING OF THE STATUTE OF
                          LIMITATIONS APPLICABLE TO CERTAIN CLAIMS.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Indemnification
                      <ol type="a">
                        <li>
                          Healthcare Provider shall defend, indemnify and hold Kaly, its
                          parents, subsidiaries, predecessors, successors and affiliates, and their
                          respective officers, directors, shareholders, members, employees and
                          agents harmless from and against any claims, actions, demands, lawsuits,
                          losses, damages, costs, expenses, judgments, fines, penalties, and
                          liabilities (including reasonable attorney’s fees and courts costs)
                          incurred in connection with any third-party demands, assertions, claims,
                          suits, actions or other proceedings: (a) alleging the services provided by
                          Kaly violate any applicable law, rule, regulation or judicial order;
                          (b) arising from the acts or omissions of Healthcare Provider, including
                          its Members and Employees, agents or permitted contractors in connection
                          with the performance of any services provided.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Other Representations and Warranties; Disclaimer of Warranties.
                      <ol type="a">
                        <li>
                          Mutual Warranties. Each Party represents and warrants to the other Party
                          that: (i) it is duly organized, validly existing, and in good standing
                          under the laws of the jurisdiction in which it is organized; (ii) it has
                          the full power to enter into this Agreement and to perform its obligations
                          hereunder; (iii) the execution and delivery of this Agreement will not
                          result in any breach of any terms and conditions of, or constitute a
                          default under, any other agreement to which such Party is bound; and (iv)
                          it is not currently the subject of a voluntary or involuntary petition in
                          bankruptcy, does not currently contemplate filing any such voluntary
                          petition, and is not aware of any basis for the filing of an involuntary
                          petition.
                        </li>
                        <li>
                          No Other Warranties. EXCEPT AS EXPRESSLY STATED HEREIN, THE WEBSITE IS
                          PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND. EXCEPT
                          AS EXPRESSLY SET FORTH HEREIN, TO THE MAXIMUM EXTENT PERMITTED BY
                          APPLICABLE LAW, KALY HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR
                          IMPLIED, ORAL OR WRITTEN, INCLUDING WITHOUT LIMITATION ALL IMPLIED
                          WARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY OR
                          FITNESS FOR ANY PARTICULAR PURPOSE, ALL WARRANTIES ARISING FROM ANY COURSE
                          OF DEALING OR PERFORMANCE OR USAGE OF TRADE, AND ALL WARRANTIES OF THE
                          SERVICES, SOFTWARE OR THE WEBSITE BEING BUG-FREE, ERROR-FREE OR FREE FROM
                          DEFECTS.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Intellectual Property.
                      <ol type="a">
                        <li>
                          All right, title and interest, including without limitation all
                          intellectual property rights, in and to the Website, as well as all URLs
                          and domains registered by Kaly (even if such URLs and domains
                          incorporate Healthcare Provider trademarks), shall remain the valuable and
                          exclusive property of Kaly. Kaly retains all proprietary rights,
                          title, and interest, including, without limitation, all patents,
                          copyrights, trademarks, service marks, trade dress, rights to the look and
                          feel of the Website, and trade secrets in and to any inventions, data,
                          information, know-how, logos, ideas, concepts, technology, software and
                          documentation related to or resulting from the utilization of the Website.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Confidentiality/HIPAA Compliance.
                      <ol type="a">
                        <li>
                          Confidential Information. As used herein, “Confidential Information” means
                          all confidential and proprietary information of a party (“Disclosing
                          Party”) disclosed to the other party (“Receiving Party”) that: (i) if
                          disclosed orally is designated as confidential at the time of disclosure;
                          (ii) if disclosed in writing is marked as “Confidential” and/or
                          “Proprietary”; or (iii) reasonably should be understood to be confidential
                          given the nature of the information and the circumstances of disclosure.
                          Confidential Information includes, without limitation, the terms and
                          conditions of this Agreement. Confidential Information shall not include
                          Protected Healthcare Information (PHI) or any information that: (1) is or
                          becomes generally known to the public without breach of any obligation
                          owed to the Disclosing Party; (2) was known to the Receiving Party prior
                          to its disclosure by the Disclosing Party without breach of any obligation
                          owed to the Disclosing Party; (3) was independently developed by the
                          Receiving Party without breach of any obligation owed to the Disclosing
                          Party; or (4) is received from a third party without breach of any
                          obligation owed to the Disclosing Party.
                        </li>
                        <li>
                          Moreover, any information encountered by Healthcare Provider referring to
                          Kaly’ proprietary business information should be deemed and Healthcare
                          Provider agrees to the designation of such information as a trade secret
                          of Kaly. Healthcare Provider agrees that such information shall be
                          protected as a trade secret as required under the Defend Trade Secrets Act
                          of 2016 (“DTSA”), as amended. Such information may include, but not be
                          limited to: information concerning processes; methods; research; secret
                          data; costs; names and contact information of users, purchasers of its
                          products or services, vendors, healthcare providers, prospective Medical
                          Consultants, developers, or other service providers; business methods;
                          operating procedures or programs; methods of promotion and sale; domain
                          names; programs, software and code, business plans; financials;
                          projections; plans; prospective customers; training manuals; product
                          development plans; bidding and pricing procedures; market plans and
                          strategies; internal performance statistics; confidential personnel
                          information concerning employees of Kaly; operational or
                          administrative plans; policy manuals; terms and conditions of contracts
                          and agreements; business analytics; patents; systems; equipment;
                          creations; designs; formats; programming; discoveries; inventions;
                          improvements; data kept on computers; engineering, research, and
                          development applications; financial information; information regarding
                          services and products in development; market information, including test
                          marketing or localized marketing; and other information regarding know-how
                          of the Kaly. The foregoing is not intended to be all inclusive and
                          proprietary information should be construed in the broadest manner to
                          encompass business information that is typically considered proprietary or
                          has value to Kaly if kept secret or proprietary to Kaly.
                          <ol type="i">
                            <li>
                              Notwithstanding any provisions in this Agreement or Kaly policy
                              applicable to the unauthorized use or disclosure of trade secrets, you
                              are hereby notified that you cannot be held criminally or civilly
                              liable under any Federal or State trade secret law for the disclosure
                              of a trade secret that is made (A) in confidence to a Federal, State,
                              or local government official, either directly or indirectly, or to an
                              attorney; and (ii) solely for the purpose of reporting or
                              investigating a suspected violation of law. You also may not be held
                              liable for such disclosures made in a complaint or other document
                              filed in a lawsuit or other proceeding, if such filing is made under
                              seal. In addition, an individual who files a lawsuit for retaliation
                              by an employer for reporting a suspected violation of law may disclose
                              the trade secret to the attorney of the individual and use the trade
                              secret information in the court proceeding, if the individual (A)
                              files any document containing the trade secret under seal and (B) does
                              not disclose the trade secret, except pursuant to court order.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Non-Disclosure. The Receiving Party shall not disclose or use any
                          Confidential Information of the Disclosing Party for any purpose outside
                          the scope of this Agreement, except with the Disclosing Party’s prior
                          written permission. Notwithstanding the foregoing, the Receiving Party may
                          disclose such Confidential Information to those of its employees and
                          contractors who need to know such information for purposes of performing
                          the Receiving Party’s obligations under this Agreement, provided that the
                          Receiving Party certifies that such employees and contractors have agreed,
                          either as a condition of employment or in order to obtain such
                          Confidential Information, to be bound by terms and conditions
                          substantially similar to those in this Agreement. The Receiving Party
                          shall use the same degree of care to protect the Confidential Information
                          of the Disclosing Party as it uses to protect its own information of a
                          confidential and proprietary nature, but in no event less than a
                          reasonable degree of care.
                        </li>
                        <li>
                          Compelled Disclosure. If the Receiving Party is compelled by law to
                          disclose Confidential Information of the Disclosing Party, it shall
                          provide the Disclosing Party with prior notice of such compelled
                          disclosure (to the extent legally permitted) and reasonable assistance, at
                          the Disclosing Party’s request and cost, if the Disclosing Party wishes to
                          contest the disclosure.
                        </li>
                        <li>
                          HIPAA. In the event that Kaly creates, receives, maintains, or
                          transmits Protected Health Information (or “PHI”, as such term is defined
                          by HIPAA) from or on behalf of Healthcare Provider, the parties agree and
                          acknowledge that Kaly is serving as a “Business Associate” (as such
                          term is defined by HIPAA of Healthcare Provider. Healthcare Provider and
                          Kaly agree to discharge their respective duties hereunder in
                          accordance with the applicable provisions of HIPAA. In furtherance of the
                          foregoing, the terms of the Business Associate Agreement set forth in
                          Exhibit A of this Agreement are incorporated herein by reference.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Term; Termination.
                      <ol type="a">
                        <li>
                          Term. This Agreement will commence as of the date of Healthcare Provider’s
                          submission of this Agreement to the Website and shall continue in effect
                          for a period of one year (the “initial term”). This Agreement shall
                          automatically renew for successive periods of one year unless either Party
                          terminates the Agreement in accordance with this section. The Initial Term
                          and all renewal periods are collectively referred to herein as the “Term.”
                        </li>
                        <li>
                          Termination.
                          <ol type="i">
                            <li>
                              Termination for Breach. Either Party may terminate this Agreement at
                              any time in the event of a breach by the other Party of a material
                              covenant, commitment or obligation under this Agreement that remains
                              uncured for 30 days following written notice thereof. Such termination
                              shall be effective immediately and automatically upon the expiration
                              of the applicable notice period, without further notice or action by
                              either Party. Termination shall be in addition to any other remedies
                              that may be available to the non-breaching Party.
                            </li>
                            <li>
                              Termination for Convenience. Either Party may terminate this
                              Agreement, at any time with or without cause by giving 30 days prior
                              written notice.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Survival. Each term and provision of this Agreement that should by its
                          sense and context survive any termination or expiration of this Agreement,
                          shall so survive regardless of the cause and even if resulting from the
                          material breach of either Party to this Agreement.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Miscellaneous.
                      <ol type="a">
                        <li>
                          Assignment. This Agreement is personal to you, and you have no right to
                          assign this Agreement to any party. Kaly may assign all of part of
                          this Agreement to any party, at any time. Kaly may substitute itself,
                          by way of unilateral novation, effective upon notice to you or upon
                          posting to the Website, for any third party that assumes Kaly’ rights
                          and obligations under this Agreement.
                        </li>
                        <li>
                          This Agreement will be binding upon, and inure to the benefit of, the
                          successors, representatives, and permitted assigns of the parties
                        </li>
                        <li>
                          No Inducement. Nothing contained in this Agreement, including any
                          compensation paid or payable, is intended or shall be construed: (i) to
                          require, influence, or otherwise induce or solicit a party or any of its
                          affiliates for referrals or arranging for the referrals of persons for
                          items or services, or recommending the ordering of any items or services,
                          of any kind whatsoever, to any of the other parties or their affiliates,
                          or to any other person; (ii) as splitting of any professional fees paid to
                          Healthcare Provider; (iii) to interfere with a patient's right to choose
                          his or her own health care provider, or with a provider’s judgment
                          regarding the ordering of any items or health care services; or (iv) as
                          remuneration in exchange for a disclosure of PHI or other information from
                          a Customer or otherwise.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Arbitration.
                      <ol type="a">
                        <li>
                          Any dispute, controversy or claim arising out of or related in any manner
                          to this Agreement which cannot be amicably resolved by the Parties shall
                          be solely and finally settled by arbitration administered by the American
                          Arbitration Association in accordance with its commercial arbitration
                          rules. Judgment on the award rendered by the arbitrator(s) may be entered
                          in any court having jurisdiction. The arbitration shall take place before
                          a panel of one arbitrator sitting in New Jersey. The language of the
                          arbitration shall be English. The arbitrator will be bound to adjudicate
                          all disputes in accordance with the laws of the State of New Jersey. The
                          decision of the arbitrator shall be in writing with written findings of
                          fact and shall be final and binding on the Parties. Each Party shall bear
                          its own costs relating to the arbitration proceedings irrespective of its
                          outcome. This section provides the sole recourse for the settlement of any
                          disputes arising out of, in connection with, or related to this Agreement,
                          except that a Party may seek a preliminary injunction or other injunctive
                          relief in any court of competent jurisdiction if in its reasonable
                          judgment such action is necessary to avoid irreparable harm.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Attorney’s Fees.
                      <ol type="a">
                        <li>
                          In the event there is any dispute concerning or arising out of this
                          Agreement and, as a result, a Party incurs attorneys’ fees for the purpose
                          of enforcing any provision of this Agreement or in defending any claims
                          asserted by the other Party with respect to this Agreement, each Party
                          shall be solely responsible for its own costs and attorney’s fees incurred
                          in connection with the dispute, regardless of whether or not a lawsuit is
                          actually filed and irrespective of which Party prevails in such legal
                          proceedings.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Force Majeure.
                      <ol type="a">
                        <li>
                          Neither Party shall be liable hereunder for any failure or delay in the
                          performance of its obligations under this Agreement if such failure or
                          delay is on account of causes beyond its reasonable control, including
                          civil commotion, war, fires, floods, accident, earthquakes, pandemic,
                          inclement weather, telecommunications line failures, electrical outages,
                          network failures, governmental regulations or controls, casualty, strikes
                          or labor disputes, terrorism, acts of God, or other similar or different
                          occurrences beyond the reasonable control of the Party so defaulting or
                          delaying in the performance of this Agreement, for so long as such force
                          majeure event is in effect. Each Party shall use reasonable efforts to
                          notify the other Party of the occurrence of such an event within five
                          business days of its occurrence. Should a Party experience a force majeure
                          event, it shall take reasonable measures to mitigate any impact that such
                          event has on its performance of this Agreement, and shall take all
                          reasonable steps to perform despite such event.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Severability.
                      <ol type="a">
                        <li>
                          If any provision or portion of this Agreement shall be rendered by
                          applicable law or held by a court of competent jurisdiction to be illegal,
                          invalid, or unenforceable, the remaining provisions or portions shall
                          remain in full force and effect.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Headings; Construction.
                      <ol type="a">
                        <li>
                          The headings and captions appearing in this Agreement have been inserted
                          for the purposes of convenience and ready reference, and do not purport to
                          and shall not be deemed to define, limit or extend the scope or intent of
                          the provisions to which they pertain. This Agreement shall not be
                          construed more strongly against either Party regardless of which Party is
                          more responsible for its preparation, and any ambiguity that might exist
                          herein shall not be construed against the drafting Party.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Entire Agreement.
                      <ol type="a">
                        <li>
                          This Agreement, any exhibits or addenda hereto, and the policies referred
                          to herein, constitute the entire agreement between the parties and
                          supersede all previous or contemporaneous agreements, promises,
                          representations, whether written or oral, between the parties with respect
                          to the subject matter hereof.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Independent Contractors.
                      <ol type="a">
                        <li>
                          The relationship of the Parties shall be that of independent contractors.
                          Any employee, servant, subcontractor or agent of Kaly who is assigned
                          to provide services under this Agreement shall remain at all times under
                          the exclusive direction and control of Kaly and shall not be deemed to
                          be an employee, servant, subcontractor or agent of Healthcare Provider.
                          Neither Party will represent that it has any authority to bind the other,
                          or assume or create any obligation, express or implied, on behalf of the
                          other Party, or to represent the other Party as agent, employee, or in any
                          other capacity, except as specifically provided herein.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Notice
                      <ol type="a">
                        <li>
                          Any notice under this Agreement must be in writing and mailed by
                          registered or certified mail, return receipt requested, postage page, in
                          all instances, and with a copy sent by electronic mail, as follows:
                          <br />
                          If to Kaly:
                          <br />
                          Kaly, LLC
                          <br />
                          c/o Wilson Elser
                          <br />
                          Attn: Neil Prupis
                          <br />
                          200 Campus Drive
                          <br />
                          Florham Park, NJ 07932
                          <br />
                          <a href="mailto:hello@kaly.com">hello@kaly.com</a>
                          <br />
                          <br />
                          If to Healthcare Provider:
                          <br />
                          To the mailing address and email address specified in Schedule D to this
                          Agreement.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <div className="para">
                    BY CLICKING ON THE “ACCEPT” BUTTON OR DOWNLOADING, ACCESSING OR OTHERWISE USING
                    THE WEBSITE, YOU HEREBY REPRESENT AND WARRANT THAT YOU ARE DULY AUTHORIZED TO
                    ENTER INTO AND BIND HEALTHCARE PROVIDER AND ITS MEMBERS AND EMPLOYEES TO THE
                    TERMS AND CONDITIONS OF THIS AGREEMENT, AND ACKNOWLEDGE AND AGREE THAT ALL SUCH
                    USE BY HEALTHCARE PROVIDER IS SUBJECT TO SUCH TERMS AND CONDITIONS. IF YOU DO
                    NOT AGREE TO THESE TERMS OR ARE NOT AUTHORIZED TO BIND HEALTHCARE PROVIDER THEN
                    DO NOT CLICK THE “ACCEPT” BUTTON AND HEALTHCARE PROVIDER IS NOT LICENSED OR
                    PERMITTED TO USE THE WEBSITE. ALL OTHER USES OF THE WEBSITE ARE STRICTLY
                    PROHIBITED.
                  </div>
                  <div className="sub-header-title title-txt-center new-section">SCHEDULE A</div>
                  <div className="para">INSERT A LIST OF MEMBERS AND EMPLOYEES HERE:</div>
                  <div className="sub-header-title title-txt-center new-section">SCHEDULE B</div>
                  <div className="para">
                    Insert here a description of the patient scoring information and a drop down
                    menu for the minimum scores for connection to the Healthcare Provider.
                  </div>
                  <div className="sub-header-title title-txt-center new-section">SCHEDULE C</div>
                  <div className="para">
                    Insert here form for the information that Healthcare Provider has to provide to
                    Kaly: full name, address, phone number, fax number, insurance accepted, any
                    logo to be included on the Page
                  </div>
                  <div className="sub-header-title title-txt-center new-section">SCHEDULE D</div>
                  <div className="para">
                    Insert form for Healthcare Provider to provide mailing address and email address
                    for notice here.
                  </div>
                  <div className="sub-header-title title-txt-center new-section mb-10">
                    EXHIBIT A
                  </div>
                  <div className="sub-header-title title-txt-center">
                    HIPAA Business Associate Agreement
                  </div>
                  <div className="para">
                    This Business Associate Agreement (the “BAA”) is entered into between Healthcare
                    Provider and Kaly, LLC to be effective on the date on which the main
                    Agreement between Healthcare Provider and Kaly becomes effective.
                    <ol type="1">
                      <li>
                        Definitions
                        <ul className="nobull">
                          <li>
                            Catch-all definitions:
                            <ul className="nobull">
                              <li>
                                The following terms used in this BAA shall have the same meaning as
                                those terms in the HIPAA Rules: Breach, Breach Notification, Data
                                Aggregation, Designated Record Set, De-Identified Information,
                                Disclosure (Disclose), Electronic Protected Health Information,
                                Electronic Transactions Rule, Enforcement Rule, Genetic Information,
                                Health Care Operations, Individual, Minimum Necessary, Notice of
                                Privacy Practices, Protected Health Information, Required By Law,
                                Sale, Secretary, Security Incident, Security Rule, Subcontractor,
                                Transaction, Unsecured Protected Health Information, and Use.
                              </li>
                            </ul>
                          </li>
                          <li>
                            Specific definitions:
                            <ol type="a">
                              <li>
                                “Business Associate” shall generally have the same meaning as the
                                term “Business Associate” at 45 C.F.R. § 160.103, and in reference
                                to the party to this agreement, shall mean Kaly, LLC.
                              </li>
                              <li>
                                “Covered Entity” shall generally have the same meaning as the term
                                “Covered Entity” at 45 C.F.R. § 160.103, and in reference to the
                                party to this Agreement, shall mean Healthcare Provider.
                              </li>
                              <li>
                                “HIPAA Rules” shall mean the Privacy, Security, Breach Notification,
                                and Enforcement Rules at 45 C.F.R. Part 160 and Part 164.
                              </li>
                              <li>
                                “HITECH Act” shall mean the Health Information Technology for
                                Economic and Clinical Health Act.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Obligations and Activities of Business Associate
                        <ul className="nobull">
                          <li>
                            Business Associate agrees to:
                            <ol type="a">
                              <li>
                                Not Use or Disclose Protected Health Information other than as
                                permitted or required by the BAA or as Required by Law;
                              </li>
                              <li>
                                Use appropriate safeguards, and comply with Subpart C of 45 C.F.R.
                                Part 164 with respect to Electronic Protected Health Information, to
                                prevent Use or Disclosure of Protected Health Information other than
                                as provided for by the BAA;
                              </li>
                              <li>
                                Report to Covered Entity any Use or Disclosure of Protected Health
                                Information not provided for by the BAA of which it becomes aware,
                                including Breaches of Unsecured Protected Health Information as
                                required at 45 C.F.R. § 164.410, and any Security Incident of which
                                it becomes aware;
                              </li>
                              <li>
                                In accordance with 45 C.F.R. §§ 164.502(e)(1)(ii) and 164.308(b)(2),
                                if applicable, ensure that any Subcontractors that create, receive,
                                maintain, or transmit Protected Health Information on behalf of the
                                Business Associate agree to the same restrictions, conditions, and
                                requirements that apply to the Business Associate with respect to
                                such information;
                              </li>
                              <li>
                                Make available Protected Health Information in a Designated Record
                                Set to Covered Entity as necessary to satisfy Covered Entity’s
                                obligations under 45 C.F.R. § 164.524, including furnishing upon
                                Covered Entity’s request or direction an electronic copy of
                                Protected Health Information that is maintained in a Designated
                                Record Set;
                              </li>
                              <li>
                                Make any amendment(s) to Protected Health Information in a
                                Designated Record Set as directed or agreed to by the Covered Entity
                                pursuant to 45 C.F.R. § 164.526, or take other measures as necessary
                                to satisfy Covered Entity’s obligations under 45 C.F.R. § 164.526;
                              </li>
                              <li>
                                Maintain and make available the information required to provide an
                                accounting of Disclosures to Covered Entity as necessary to satisfy
                                Covered Entity’s obligations under 45 C.F.R. § 164.528.
                              </li>
                              <li>
                                To the extent the Business Associate is to carry out one or more of
                                Covered Entity's obligation(s) under Subpart E of 45 C.F.R. Part 164
                                (“Privacy of Individually Identifiable Health Information”), comply
                                with the requirements of such Subpart E that apply to the Covered
                                Entity in the performance of such obligation(s);
                              </li>
                              <li>
                                Make its internal practices, books, and records available to the
                                Secretary for purposes of determining compliance with the HIPAA
                                Rules;
                              </li>
                              <li>Not participate in any Sale of Protected Health Information;</li>
                              <li>
                                Not Use or Disclose Genetic Information for underwriting purposes in
                                violation of the HIPAA Rules;
                              </li>
                              <li>
                                Comply with the Electronic Transaction Rule and any applicable
                                corresponding requirements adopted by HHS with respect to any
                                Electronic Transactions conducted by Business Associate on behalf of
                                Covered Entity in connection with the services provided under this
                                BAA.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Representations of Business Associate
                        <ul className="nobull">
                          <li>
                            Business Associate agrees that it is directly liable under the HIPAA
                            Rules and the HITECH Act and is subject to civil and, in some cases,
                            criminal penalties for making Uses and Disclosures of Protected Health
                            Information that are not authorized by this BAA or Required by Law.
                            Business Associate also acknowledges that it is liable and subject to
                            civil penalties for failing to safeguard Electronic Protected Health
                            Information in accordance with the HIPAA Security Rule.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Permitted Uses and Disclosures by Business Associate
                        <ul className="nobull">
                          <li>
                            Business Associate shall not Use or Disclose Protected Health
                            Information relating to Covered Entity, except as expressly permitted
                            under and consistent with this Section 4.
                            <ol type="a">
                              <li>
                                Business Associate may Use or Disclose Protected Health Information
                                as Required by Law.
                              </li>
                              <li>
                                Business Associate agrees to make Uses and Disclosures and requests
                                for Protected Health Information consistent with Covered Entity’s
                                Minimum Necessary policies and procedures, a copy of which has been
                                furnished to Business Associate.
                              </li>
                              <li>
                                Business Associate may not Use or Disclose Protected Health
                                Information in a manner that would violate Subpart E of 45 C.F.R.
                                Part 164 if done by Covered Entity.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Notification of Breach
                        <ul className="nobull">
                          <li>
                            If Business Associate discovers a Breach of Protected Health
                            Information, the Business Associate shall, following the discovery of
                            the Breach of Unsecured Protected Health Information, notify the Covered
                            Entity of such breach in accordance with this Section 5.
                            <ol type="a">
                              <li>
                                A Breach is treated as discovered by Business Associate on the first
                                day on which such breach is known to Business Associate or, by
                                exercising reasonable diligence, would have been known to Business
                                Associate. Business Associate shall be deemed to have knowledge of a
                                Breach if the Breach is known, or by exercising reasonable diligence
                                would have been known, to any person, other than the person
                                committing the Breach, who is an employee, officer, or other agent
                                of Business Associate.
                              </li>
                              <li>
                                Business Associate shall provide the notification required under
                                this Section 6 without unreasonable delay and in no case later than
                                60 calendar days after discovery of the Breach.
                              </li>
                              <li>
                                The notification shall include, to the extent possible, the
                                identification of each individual whose Unsecured Protected Health
                                Information has been, or is reasonably believed by Business
                                Associate to have been, accessed, acquired, used, or disclosed
                                during the Breach.
                              </li>
                              <li>
                                Business Associate shall provide the Covered Entity with any other
                                available information that the Covered Entity is required to include
                                in notification to the individual under 45 C.F.R. § 164.404(c) at
                                the time of the notification by Business Associate, and any
                                information that is not then available promptly after such
                                information becomes available. Information to be provided includes,
                                to the extent possible:
                                <ol type="i">
                                  <li>
                                    A brief description of what happened, including the date of the
                                    Breach and the date of the discovery of the Breach, if known;
                                  </li>
                                  <li>
                                    A description of the types of Unsecured Protected Health
                                    Information that were involved in the Breach (such as whether
                                    full name, social security number, date of birth, home address,
                                    account number, diagnosis, disability code, or other types of
                                    information were involved); and
                                  </li>
                                  <li>
                                    A brief description of what Business Associate is doing to
                                    investigate the Breach, to mitigate harm to Individuals, and to
                                    protect against any further Breaches.
                                  </li>
                                </ol>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Term and Termination
                        <ol type="a">
                          <li>
                            Term. The Term of this Agreement shall be effective as of the Effective
                            Date and shall terminate on as described in the Agreement.
                          </li>
                          <li>
                            Obligations of Business Associate Upon Termination. Upon termination of
                            this Agreement for any reason, Business Associate shall return to
                            Covered Entity (or, if agreed to by Covered Entity, destroy) all
                            Protected Health Information received from Covered Entity, or created,
                            maintained, or received by Business Associate on behalf of Covered
                            Entity, that the Business Associate still maintains in any form.
                            Business Associate shall retain no copies of the Protected Health
                            Information.
                          </li>
                          <li>
                            Survival. The obligations of Business Associate under this Section shall
                            survive the termination of this Agreement.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Miscellaneous
                        <ol type="a">
                          <li>
                            Regulatory References. A reference in this BAA to a section in the HIPAA
                            Rules means the section as in effect or as amended.
                          </li>
                          <li>
                            Amendment. The parties agree to take such action as is necessary to
                            amend this BAA from time to time as is necessary for compliance with the
                            requirements of the HIPAA Rules and any other applicable law. Any
                            amendment to this BAA must be in writing and signed by both parties.
                          </li>
                          <li>
                            Interpretation. Any ambiguity in this BAA shall be interpreted to permit
                            compliance with the HIPAA Rules.
                          </li>
                          <li>
                            Governing law. This BAA shall be governed by the laws of the State of
                            New Jersey, except to the extent preempted by federal law.
                          </li>
                          <li>
                            Severability. The provisions of this BAA shall be severable, and the
                            invalidity of any provision shall not affect the validity of other
                            provisions.
                          </li>
                          <li>
                            Entire Agreement. This BAA contains the entire agreement between the
                            parties regarding this subject matter. This BAA supersedes all prior
                            agreements, understandings or writings, whether oral or written with
                            regard to this subject matter.
                          </li>
                          <li>
                            Notice. Any notice required under this BAA shall be in writing and shall
                            be given by (i) delivery in person, (ii) by a nationally recognized next
                            day courier service, (iii) by first className, registered or certified
                            mail, postage prepaid, (iv) by electronic mail to the address of the
                            party specified in this BAA or such other address as either party may
                            specify in writing.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                  <div className="para text-bold">CCPA Addendum</div>
                  <div className="para">
                    This Addendum modifies the Agreement with respect to patients that are residents
                    of the State of California.
                    <ol type="1">
                      <li>
                        Definitions. The following definitions and rules of interpretation apply in
                        this Addendum, in addition to the definitions set forth in the Agreement:
                        <ol type="a">
                          <li>
                            CCPA means the California Consumer Privacy Act of 2018, as amended (Cal.
                            Civ. Code §§ 1798.100 to 1798.199), and any related regulations or
                            guidance provided by the California Attorney General. Terms defined in
                            the CCPA, including personal information and business purposes, carry
                            the same meaning in this Addendum.
                          </li>
                          <li>
                            Contracted Business Purposes means the services described in the
                            Agreement, or for any other purpose specifically identified in Appendix
                            A below for which Healthcare Provider receives or accesses personal
                            information.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Healthcare Provider’s CCPA Obligations
                        <ol type="a">
                          <li>
                            Healthcare Provider will only collect, use, retain, or disclose personal
                            information for the Contracted Business Purposes for which Kaly
                            provides or permits personal information access in accordance with the
                            Agreement and Kaly’s instructions.
                          </li>
                          <li>
                            Healthcare Provider will not collect, use, retain, disclose, sell, or
                            otherwise make personal information available for Healthcare Provider’s
                            own commercial purposes or in a way that does not comply with the CCPA.
                            If a law requires the Healthcare Provider to disclose personal
                            information for a purpose unrelated to the Contracted Business Purpose,
                            the Healthcare Provider must first inform Kaly of the legal
                            requirement and give Kaly an opportunity to object or challenge the
                            requirement, unless the law prohibits such notice.
                          </li>
                          <li>
                            Healthcare Provider will limit personal information collection, use,
                            retention, and disclosure to activities reasonably necessary and
                            proportionate to achieve the Contracted Business Purposes or another
                            compatible operational purpose.
                          </li>
                          <li>
                            Healthcare Provider must promptly comply with any Kaly’s request or
                            instruction requiring Healthcare Provider to provide, amend, transfer,
                            or delete the personal information, or to stop, mitigate, or remedy any
                            unauthorized processing.
                          </li>
                          <li>
                            If the Contracted Business Purposes require the collection of personal
                            information from individuals on Kaly’s behalf, Healthcare Provider
                            will always provide a CCPA-compliant notice addressing use and
                            collection methods that Kaly specifically pre-approves in writing.
                            Healthcare Provider will not modify or alter the notice in any way
                            without Kaly’s prior written consent.
                          </li>
                          <li>
                            If the CCPA permits, Healthcare Provider may aggregate, deidentify, or
                            anonymize personal information by acceptable methods under the CCPA, so
                            it no longer meets the personal information definition, and may use such
                            aggregated, deidentified, or anonymized data for its own research and
                            development purposes. Healthcare Provider will not attempt to or
                            actually re-identify any previously aggregated, deidentified, or
                            anonymized data and will contractually prohibit downstream data
                            recipients from attempting to or actually re-identifying such data.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Assistance with Kaly’s CCPA Obligations
                        <ol type="a">
                          <li>
                            Healthcare Provider will reasonably cooperate and assist Kaly with
                            meeting Kaly’s CCPA compliance obligations and responding to
                            CCPA-related inquiries, including responding to verifiable consumer
                            requests, taking into account the nature of Healthcare Provider’s
                            processing and the information available to Healthcare Provider.
                          </li>
                          <li>
                            Healthcare Provider must notify Kaly immediately if it receives any
                            complaint, notice, or communication that directly or indirectly relates
                            to either party’s compliance with the CCPA. Specifically, Healthcare
                            Provider must notify Kaly within 10 working days if it receives a
                            verifiable consumer request under the CCPA.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Subcontracting
                        <ul className="nobull">
                          <li>
                            Healthcare Provider may not use subcontractors to provide the Contracted
                            Business Services.
                          </li>
                        </ul>
                      </li>
                      <li>
                        CCPA Warranties
                        <ol type="a">
                          <li>
                            Both parties will comply with all applicable requirements of the CCPA
                            when collecting, using, retaining, or disclosing personal information.
                          </li>
                          <li>
                            Healthcare Provider certifies that it understands this Addendum’s and
                            the CCPA’s restrictions and prohibitions on selling personal information
                            and retaining, using, or disclosing personal information outside of the
                            parties’ direct business relationship, and it will comply with them.
                          </li>
                          <li>
                            Healthcare Provider warrants that it has no reason to believe any CCPA
                            requirements or restrictions prevent it from providing any of the
                            Contracted Business Purposes or otherwise performing under this
                            Addendum. Healthcare Provider must promptly notify Kaly of any
                            changes to the CCPA’s requirements that may adversely affect its
                            performance under the Addendum.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                  <div className="sub-header-title title-txt-center new-section mb-10">
                    APPENDIX A
                  </div>
                  <div className="sub-header-title title-txt-center">
                    PERSONAL INFORMATION PROCESSING PURPOSES AND DETAILS
                  </div>
                  <div className="para">
                    <b>Contracted Business Purposes:</b> Advertising, marketing, CRM, payment
                    processing and other business managements services
                    <br />
                    <b>Personal Information Categories:</b> This Agreement involves the following
                    types of Personal Information, as defined and classified in CCPA Cal. Civ. Code
                    § 1798.140(o).
                    <table className="tbl-with-style">
                      <tbody>
                        <tr>
                          <th>Categories</th>
                          <th>Examples of Data</th>
                          <th>Collected?</th>
                        </tr>
                        <tr>
                          <td>A. Identifiers.</td>
                          <td>
                            A real name, email address, telephone number, postal address, online
                            identifier, user ID, device ID, domain server, type of device/operating
                            system, browser used to access our services, Internet Protocol address,
                            account name, driver's license number, passport number, or other similar
                            identifiers.
                          </td>
                          <td>YES</td>
                        </tr>
                        <tr>
                          <td>
                            B. Personal information categories listed in the California Customer
                            Records statute (Cal. Civ. Code § 1798.80(e)).
                          </td>
                          <td>
                            A real name, email address, telephone number, postal address, passport
                            number, driver's license or state identification card number, insurance
                            information (including insurance carrier, insurance plan, member ID,
                            group ID, payer ID), education, employment, employment history, medical
                            information, or health insurance information. Some personal information
                            included in this category may overlap with other categories.
                          </td>
                          <td>YES</td>
                        </tr>
                        <tr>
                          <td>
                            C. Protected classification characteristics under California or federal
                            law.
                          </td>
                          <td>
                            Age, race, ethnicity, color, ancestry, national origin, citizenship, zip
                            code, religion or creed, marital status, medical condition, physical or
                            mental disability, sex (including gender, gender identity, gender
                            expression, pregnancy or childbirth and related medical conditions),
                            sexual orientation, veteran or military status, genetic information
                            (including familial genetic information).
                          </td>
                          <td>YES</td>
                        </tr>
                        <tr>
                          <td>D. Commercial information.</td>
                          <td>
                            Records of personal property, products or services purchased, obtained,
                            or considered, or other purchasing or consuming histories or tendencies.
                          </td>
                          <td>NO</td>
                        </tr>
                        <tr>
                          <td>E. Biometric information.</td>
                          <td>
                            Genetic, physiological, behavioral, and biological characteristics, or
                            activity patterns used to extract a template or other identifier or
                            identifying information, such as, fingerprints, faceprints, and
                            voiceprints, iris or retina scans, keystroke, gait, or other physical
                            patterns, and sleep or exercise data.
                          </td>
                          <td>NO</td>
                        </tr>
                        <tr>
                          <td>F. Internet or other similar network activity.</td>
                          <td>
                            Browsing history, search history, information on a consumer's
                            interaction with a website, application, or advertisement, referring
                            webpage/source through which you access our services, non-identifiable
                            request IDs, and statistics associated with the interaction between
                            device or browser and our services.
                          </td>
                          <td>YES</td>
                        </tr>
                        <tr>
                          <td>G. Geolocation data.</td>
                          <td>
                            Approximate location (city and state) of the device from which you
                            access out services.
                          </td>
                          <td>YES</td>
                        </tr>
                        <tr>
                          <td>H. Sensory data.</td>
                          <td>
                            Audio, electronic, visual, thermal, olfactory, or similar information.
                          </td>
                          <td>NO</td>
                        </tr>
                        <tr>
                          <td>I. Professional or employment-related information.</td>
                          <td>Current or past job history.</td>
                          <td>YES</td>
                        </tr>
                        <tr>
                          <td>
                            J. Non-public education information (per the Family Educational Rights
                            and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).
                          </td>
                          <td>
                            Education records directly related to a student maintained by an
                            educational institution or party acting on its behalf, such as grades,
                            transcripts, className lists, student schedules, student identification
                            codes, student financial information, or student disciplinary records.
                          </td>
                          <td>NO</td>
                        </tr>
                        <tr>
                          <td>K. Inferences drawn from other personal information.</td>
                          <td>
                            Profile reflecting a person's preferences, characteristics,
                            psychological trends, predispositions, behavior, attitudes,
                            intelligence, abilities, and aptitudes.
                          </td>
                          <td>NO</td>
                        </tr>
                        <tr>
                          <td>L. Medical Data</td>
                          <td>
                            Medical history and health information you provide us, including health
                            conditions, healthcare providers visited, reasons for visit, dates of
                            visit, booking and appointment data (including appointment date/time,
                            provider information, appointment procedure, whether or not user is a
                            new patient for a particular provider).
                          </td>
                          <td>YES</td>
                        </tr>
                        <tr>
                          <td>
                            M. Other Identifying Information That You Voluntarily Chose to Provide
                          </td>
                          <td>
                            Personal Data in emails, letters or online forms that you send or submit
                            to us.
                          </td>
                          <td>YES</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HealthcareProviderAgreement;
