import React from 'react';
import momentHelper from 'data/moment/momentHelper';


const {timeZoneFullName,timeZoneAbbreviated}=momentHelper
const TimeZoneLabel = props => {
  let txAbbr=timeZoneAbbreviated();
  let fullTimeZone=timeZoneFullName()
  let fullNmae=fullTimeZone!==txAbbr?` ${fullTimeZone} `:' '

  return (
    <div style={{fontSize: '13px', fontWeight: 'normal'}}>
      <small>
        <i>{`All times are in${fullNmae}(${txAbbr})`}</i>
      </small>
    </div>
  );
};

export default TimeZoneLabel;
