const questionList = [
  {
    id: 501,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // painIds,
    nextQuestionId: 502,
    descriptions: [],
  },
  {
    id: 502,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 503,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 84, visitReasonIds: [102], extraQuestion: 1},
          {painId: 2, questionId: 163, visitReasonIds: [101], extraQuestion: 1},
          {painId: 3, questionId: 224, visitReasonIds: [104], extraQuestion: 1},
          {painId: 4, questionId: 43, visitReasonIds: [96], extraQuestion: 1},
          {painId: 5, questionId: 104, visitReasonIds: [97], extraQuestion: 1},
          {painId: 6, questionId: 123, visitReasonIds: [103], extraQuestion: 1},
          {painId: 7, questionId: 144, visitReasonIds: [98], extraQuestion: 1},
          {painId: 8, questionId: 23, visitReasonIds: [106], extraQuestion: 1},
          {painId: 9, questionId: 183, visitReasonIds: [99], extraQuestion: 1},
          {painId: 10, questionId: 24, visitReasonIds: [107], extraQuestion: 2},
          {painId: 11, questionId: 203, visitReasonIds: [100], extraQuestion: 1},
          {painId: 12, questionId: 64, visitReasonIds: [105], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 503,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 83, visitReasonIds: [102], extraQuestion: 3},
          {painId: 2, questionId: 164, visitReasonIds: [101], extraQuestion: 3},
          {painId: 3, questionId: 225, visitReasonIds: [104], extraQuestion: 1},
          {painId: 4, questionId: 44, visitReasonIds: [96], extraQuestion: 3},
          {painId: 5, questionId: 103, visitReasonIds: [97], extraQuestion: 3},
          {painId: 6, questionId: 124, visitReasonIds: [103], extraQuestion: 3},
          {painId: 7, questionId: 143, visitReasonIds: [98], extraQuestion: 2},
          {painId: 8, questionId: 24, visitReasonIds: [106], extraQuestion: 2},
          {painId: 9, questionId: 184, visitReasonIds: [99], extraQuestion: 3},
          {painId: 10, questionId: 24, visitReasonIds: [107], extraQuestion: 2},
          {painId: 11, questionId: 204, visitReasonIds: [100], extraQuestion: 3},
          {painId: 12, questionId: 65, visitReasonIds: [105], extraQuestion: 1},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 503,
    question: 'Are you interested in potentially undergoing surgery?',
    options: [
      {
        nextQuestionId: 504,
        label: 'Yes, in the near future',
        shortLabel: 'Interested in surgery',
      },
      {nextQuestionId: 504, label: 'No, not at this time', shortLabel: 'No surgery'},
    ],
    descriptions: [],
  },
  {
    id: 504,
    question: 'Do you still want to see a Orthopedic Surgeon?',
    descriptions: [
      'An Orthopedic Surgeon is a medical physician (MD/DO) who completed 5 year residency in Orthopedic Surgery.',
      'An Orthopedic Surgeon has further training in treating musculoskeletal conditions that may require surgery.',
    ],
    title: 'Orthopedic Surgeon',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can perform pain injections',
    ],
    options: [
      {groupId: 'K', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 501,
    lastQuestionId: 504,
    treeDepth: 4,
  },
};
