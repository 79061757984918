import React from 'react';
import images from 'data/images';
import Input from 'components/FormControls/Input';
import './InputList.css';

const InputList = props => {
  const {
    name = 'list',
    listItems = [''],
    label = 'Label',
    validateItem = null,
    addLabelText = 'Add Item',
    removeLabelText = 'Remove',
    onListUpdated = () => {},
    maxItems = 5,
    minItems = 1,
    errors = [],
    disabled,
    checkStatus = false,
    dataStatus = [],
    placeholder = '',
    subTitle = '',
  } = props;

  const __validateItem = value => {
    if (validateItem) {
     
      return validateItem(value);
    } else {
      return true;
    }
  };
  let errorList = [...errors];
  const changeHandler = event => {
    const {name: itemIndex, value} = event.target;
    const updatedItemValues = [...listItems];
    updatedItemValues[itemIndex] = value;
    const updatedItemErrors = [...errorList];
    updatedItemErrors[itemIndex] = !__validateItem(value);
    const customEvent = {
      target: {
        name,
        value: updatedItemValues,
        error: updatedItemErrors,
      },
    };
    onListUpdated(customEvent);
  };
  const addItem = () => {
    if (maxItems > listItems.length) {
      const updatedItemErrors = [...errorList, false];
      const updatedItemValues = [...listItems, ''];
      const customEvent = {
        target: {
          name,
          value: updatedItemValues,
          error: updatedItemErrors,
        },
      };
      onListUpdated(customEvent);
    }
  };
  const removeItem = index => {
    const updatedItemErrors = [...errorList];
    const updatedItemValues = [...listItems];
    updatedItemErrors.splice(index, 1);
    updatedItemValues.splice(index, 1);
    const customEvent = {
      target: {
        name,
        value: updatedItemValues,
        error: updatedItemErrors,
      },
    };
    onListUpdated(customEvent);
  };
  const getGeneralInputProps = controlIndex => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: errorList[controlIndex],
      value: listItems[controlIndex],
      name: controlIndex,
      disabled,
    };
  };

  const getStatusIcon = index => {
    let imgIcon = '';
    if (dataStatus && dataStatus.length > index) {
      switch (dataStatus[index]) {
        case 0: // pending
          imgIcon = (
            <span className="status-icon">
              <img src={images.minus} alt="" />
            </span>
          );
          break;
        case 2: // rejected
          imgIcon = (
            <span className="status-icon">
              <img src={images.closeError} alt="" />
            </span>
          );
          break;
        default:
          break;
      }
    }
    return imgIcon;
  };

  const isHideRemoveButton = listItems.length <= minItems;
  return (
    <div className="InputList">
      <div className={['input-list-label', subTitle ? 'parent-title' : ''].join(' ')}>
        {label}
        {subTitle && <p className="subTitle">{subTitle}</p>}
      </div>
      {listItems.map((item, index) => {
        return (
          <div key={index} className="input-list-item" style={{marginBottom:"5px"}}>
            <Input {...getGeneralInputProps(index)} type="text" placeholder={placeholder} />
            {checkStatus && getStatusIcon(index)}
            {!disabled && !isHideRemoveButton && (
              <span className="remove-link" onClick={() => removeItem(index)}>
                {removeLabelText}{' '}
              </span>
            )}
          </div>
        );
      })}
      {!disabled && maxItems > listItems.length && (
        <div className="add-input">
          <span onClick={addItem}>
            <img src={images.plusBlue} alt="" /> {addLabelText}
          </span>
        </div>
      )}
    </div>
  );
};

export default InputList;
