import React, {Component} from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import masterService from 'services/master';
import staffService from 'services/staff';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import images from 'data/images';
import constants from 'data/constants';
import AddStaff from './AddStaff';
import _ from 'lodash';
import './staff.css';
import Select from 'components/FormControls/Select';

const initialState = {
  fromDbList: [],
  list: [],
  mainList: [],
  StatusFilter: [
   
    {id: 1, label: 'Active'},
    {id: 2, label: 'Inactive'},
  ],
  activePage: 1,
  totalPage: 0,

  searchText: '',
  filterStatus: '',
  pageLimit: 10,
  sortBy: 'plan',
  orderBy: 'asc',
  totalRecord: 0,
};
const { updateUserStatus} = adminService;
const StatusObj = {
  0: <span className="Pending-text">Pending</span>,
  1: <span className="Approved-text">Approved</span>,
  2: <span className="Rejected-text">Rejected</span>,
  99: <span></span>,
};
const {modalFunctions} = modalConfig;
const {errorModalData, blankModalData, successModalData, confirmModalData} = modalFunctions;
const {getAllStaff, deleteStaff,updateStaffData} = staffService;
//InsurancePlanStatus
const {DefaultDebounceMSec} = constants;

class Staff extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount() {
    this.getAllStaff(false);
  }
  setProviderStatus = async (userId, userStatus) => {
    const response = await updateStaffData({staffId:userId,status:userStatus}).catch(this.handleError);
    if (response) {
      const {setGlobal} = this.context;
      setGlobal('modal', successModalData(response.message));
      this.getAllStaff(false);
    }
  };
  confirmUpdateStatus = (userId, currentStatus, firstName, lastName) => {
    const newStatus = currentStatus === 1 ? 2 : 1;
    const statusTitle = currentStatus === 1 ? 'deactivate' : 'activate';
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to ${statusTitle} ${firstName} ${lastName}?`,
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.setProviderStatus(userId, newStatus);
          }
        },
      }),
    );
  };
  getAllStaff = async (noLoad = false) => {
    const {activePage, pageLimit, searchText, sortBy, orderBy, filterStatus} = this.state;
    // console.log('filterStatus', filterStatus);
    let passsData = {
      page: activePage,
      limit: pageLimit,
      search: searchText,
      sortBy: sortBy,
      orderBy: orderBy,
      status:
         filterStatus === '1'
          ? 'active'
          : filterStatus === '2'
          ? 'inActive'
          : '',
    };
    if (passsData['status'] === '') {
      delete passsData['status'];
    }
    const res = await getAllStaff(passsData);

    let resData = _.get(res, 'data', []);

    this.setState({
      list: resData.rows,
      totalPage: Math.ceil(resData.count / pageLimit),
      totalRecord: resData.count,
    });
  };
  // updateSubmit = async (subIndex, reqObj) => {
  //   let {fromDbList} = this.state;
  //   let selectedObj = fromDbList.find(d => d.id === reqObj.speciality);
  //   if (selectedObj && Object.keys(selectedObj).length > 0) {
  //     let {faqs} = selectedObj;
  //     faqs[subIndex] = {answer: reqObj.faqAnswer, question: reqObj.faqQuestion};
  //     let response = await updateFaq({
  //       speciality: reqObj.speciality,
  //       faq: JSON.stringify(faqs),
  //     }).catch(this.handleError);
  //     if (response) {
  //       const {message} = response;
  //       const {setGlobal} = this.context;
  //       setGlobal('modal', successModalData(message));
  //       this.getAllStaff();
  //     }
  //   }
  // };

  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.getAllStaff);
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };

  openEditModal = data => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: AddStaff,
        modalWrapperClass: '',
        closeOnBGClick:false,
        customComponentProps: {
          data,
          getAllStaff: this.getAllStaff,

          // updateSubmit: this.updateSubmit,
        },
      }),
    );
  };

  confirmDelete = (id, practiceID) => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete this Staff?`,
        onConfirm: async isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});

            let response = await deleteStaff(id, practiceID).catch(this.handleError);
            if (response) {
              setGlobal('modal', successModalData(response.message));
              this.getAllStaff();
            }
          }
        },
      }),
    );
  };

  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.getAllStaff();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };

  handleSearch = () => {
    this.setState({activePage: 1}, this.getAllStaff);
  };

  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.getAllStaff);
  };

  handleCompanySearch = event => {
    this.setState({activePage: 1, filterStatus: event.target.value}, this.getAllStaff);
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'firstName');
    if (sortBy === key) {
      this.setState({orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1}, this.getAllStaff);
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.getAllStaff);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  render() {
    const {list, StatusFilter, totalPage, searchText, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    const title = 'Manage Staff';
    return (
      <Layout>
        <div className="staff-list">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div>
                <Select
                  options={StatusFilter}
                  labelIndex="label"
                  valueIndex="id"
                  name="companyId"
                  defaultOptionProp={{value: '', label: 'All Status'}}
                  isAllowDefaultOpen={true}
                  onChange={this.handleCompanySearch}
                />
              </div>
              <div className="input-control">
                <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
                {searchText && (
                  <button className="btn-clear" onClick={this.handleClearSearch}>
                    <img src={images.close} alt="clear" />
                  </button>
                )}
              </div>
              <button onClick={this.handleSearch}>Search</button>
            </div>
            <button className="btn-add-new" onClick={() => this.openEditModal({})}>
              Add New Staff
            </button>
            <div className="dataCount">
              <span>{totalRecord} Staff found.</span>
            </div>
            <div className="table-overflow">
              <div className="rtable">
                <div className="heading">
                  <div
                    className="cell pointer"
                    data-key="firstName"
                    onClick={this.handleSortingChange}>
                    Name{this.renderShortIcon('firstName')}
                  </div>
                  <div className="cell pointer" data-key="email" onClick={this.handleSortingChange}>
                    Email{this.renderShortIcon('email')}
                  </div>
                  <div className="cell pointer" data-key="practiceName" onClick={this.handleSortingChange}>Practice{this.renderShortIcon('practiceName')}</div>
                  <div className="cell pointer">Provider</div>
                  <div className="cell pointer">Role</div>
                  <div className="cell pointer">Status</div>
                  <div className="cell edit-cell">Action</div>
                </div>
                {list.length===0&&(<div className="no-record">No Records found</div>)}
                {list.map((item, index) => (
                  <div className="row" key={`eachQ${index}`}>
                    <div className="cell">
                      {_.get(item, 'firstName', '')} {_.get(item, 'lastName', '')}
                    </div>
                    <div className="cell">{_.get(item, 'email', '')}</div>
                    <div className="cell">{_.get(item, 'practiceName', '')}</div>
                    <div className="cell ">
                      <div className="providerslist">
                        {item.providers.filter((p)=>p.user.roles.length>0).map((p,index) => (
                          <span key={p.user.id}>
                            {p.user.firstName} {p.user.lastName}{(item.providers.filter((p)=>p.user.roles.length>0).length===(index+1))?'':','}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="cell">Staff</div>
                    <div className="cell">
                      {' '}
                      <div className="providerslist">
                        {constants.UserStatus[item.userStatus]}
                        {constants.UserStatus[item.userStatus] !== 'In Process' && (
                          
                          <button
                            className={
                              constants.UserStatus[item.userStatus] === 'Active'
                                ? 'btn-deactivate'
                                : 'btn-edit'
                            }
                            onClick={() =>
                              this.confirmUpdateStatus(
                                _.get(item, 'provideId', ''),
                                _.get(item, 'userStatus', ''),
                                _.get(item, 'firstName', ''),
                                _.get(item, 'lastName', ''),
                              )
                            }>
                            {constants.UserStatus[item.userStatus] === 'Active'
                              ? 'Deactivate'
                              : 'Activate'}
                          </button>
                         
                        )}
                      </div>
                    </div>
                    <div className="cell edit-cell">
                      {/* <img
                        onClick={() => this.openEditModal(item)}
                        src={images.editPen}
                        className="edit-icon"
                        alt="editPen"
                      /> */}
                      <img
                        onClick={() => this.confirmDelete(item.provideId, item.practiceId)}
                        src={images.deleteRound}
                        className="delete-icon"
                        alt="delete"
                      />
                    </div>
                  </div>
                ))}
                {/* {_.map(list, (item, index) => (
                  <div className="row" key={index}>
                    <div className="cell">{_.get(item, 'plan', '')}</div>
                    <div className="cell">{_.get(item, 'companyName', '')}</div>
                    <div className="cell">{InsurancePlanStatus[_.get(item, 'status', '')]}</div>
                    <div className="cell">
                      {item.updatedAt && this.formatedDate(item.updatedAt)}
                    </div>
                    <div className="cell edit-cell">
                      <img
                        onClick={() => this.openEditModal(item)}
                        src={images.editPen}
                        className="edit-icon"
                        alt="editPen"
                      />
                    </div>
                  </div>
                ))} */}
              </div>
            </div>
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Staff;
