import React, {Fragment, useContext} from 'react';
import _ from 'lodash';
import moment from 'moment';
import constants from './constants';
import momentHelper from './moment/momentHelper';
import holidays from './holidays';
import config from 'config/config';
import axiosInstance from 'config/axios';

const {getHoliday} = holidays;
const {utcToLocalMoment, duration, dateOrCurrentDate, isAfter, dateFormat} = momentHelper;

var ipdata = {};
const getFileNameFromUrl = url => {
  if (isValidUrl(url)) {
    const filename = decodeURIComponent(new URL(url).pathname.split('/').pop());
    if (!filename) return 'index.html'; // some default filename
    return filename;
  } else {
    return '';
  }
};

const isValidUrl = string => {
  try {
    new URL(string);
  } catch (error) {
    return false;
  }
  return true;
};

const getSuffixString = suffixes => {
  const prefixString = suffixes.length > 0 ? ', ' : '';
  return prefixString + _.map(suffixes, 'suffix').join(', ');
};

const getHighlightedText = (text, highlight) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <>
      {parts.map((part, i) => {
        if (part.toLowerCase() === highlight.toLowerCase()) {
          return <b key={i}>{part}</b>;
        } else {
          return <Fragment key={i}>{part}</Fragment>;
        }
      })}
    </>
  );
};

const scrollToDiv = (className = null, id = null) => {
  let topPos = 0;
  if (className) {
    const classElements = document.getElementsByClassName(className);
    if (classElements.length > 0) {
      topPos = classElements[0].offsetTop;
    }
  } else if (id) {
    const idElement = document.getElementById(id);
    if (idElement) {
      topPos = idElement.offsetTop;
    }
  }
  window.scrollTo({
    top: topPos, //document.getElementsByClassName('Joinkalypsys')[0].offsetTop,
    left: 0,
    behavior: 'smooth',
  });
};

// this is like a widget loader, we will use this to dynamically load facebook and google js snippets for API access
const loadScript = (d, s, id, jsSrc, cb) => {
  const idElement = d.getElementById(id);
  if (!idElement) {
    // only load js, if it is not already loaded
    const element = d.getElementsByTagName(s)[0];
    const fjs = element;
    let js = element;
    js = d.createElement(s);
    js.id = id;
    js.src = jsSrc;
    if (fjs && fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs);
    } else {
      d.head.appendChild(js);
    }
    js.onload = cb;
  } else {
    cb && cb();
  }
};

const isIntersect = ([start1, end1], [start2, end2]) => {
  // (StartDate1 <= EndDate2) and (StartDate2 <= EndDate1)
  return start1.isSameOrBefore(end2) && start2.isSameOrBefore(end1);
};

// clone moment method
// cloneDeep is lodash method
const mergeIntersectingTimes = array => {
  let interSectingArray = [];
  let clonedArray = _.cloneDeep(array);
  do {
    interSectingArray = [];
    // eslint-disable-next-line no-loop-func
    clonedArray.forEach((a, i) => {
      clonedArray.forEach((aa, ii) => {
        if (i !== ii) {
          if (
            isIntersect([a.s, a.e], [aa.s, aa.e]) &&
            !clonedArray[i].isProcessed &&
            !clonedArray[ii].isProcessed
          ) {
            clonedArray[i].isProcessed = true;
            clonedArray[ii].isProcessed = true;
            let newS = null;
            let newE = null;
            if (a.s.isSameOrBefore(aa.s)) {
              newS = a.s.clone();
            } else {
              newS = aa.s.clone();
            }
            if (a.e.isSameOrBefore(aa.e)) {
              newE = aa.e.clone();
            } else {
              newE = a.e.clone();
            }
            interSectingArray.push({
              id: a.id,
              isVirtual: a.isVirtual,
              s: newS.clone(),
              e: newE.clone(),
            });
          }
        }
      });
    });
    clonedArray = clonedArray.filter(a => !a.isProcessed);
    clonedArray = [...clonedArray, ...interSectingArray];
  } while (interSectingArray.length > 0);
  return clonedArray;
};

// format db availabilityList to time array
const getAvailability = (
  availabilityList,
  datesObject,
  appointmentTimeInMinutes = 15,
  appointments = [],
  providerTimeZone = null,
  nextOpenDate = null,
) => {
  availabilityList = _.sortBy(availabilityList, 'id');
  availabilityList = _.sortBy(availabilityList, 'startDatetime');

  let systemTimeZone = moment.tz.guess();
  if (!providerTimeZone) {
    providerTimeZone = systemTimeZone;
  }
  if (
    localStorage.getItem('UTZ') &&
    localStorage.getItem('UTZ') !== '' &&
    localStorage.getItem('UTZ') !== systemTimeZone
  ) {
    moment.tz.setDefault(localStorage.getItem('UTZ'));
  } else {
    moment.tz.setDefault(systemTimeZone);
  }

  let dateFormat = 'YYYY-MM-DD';
  let slotFormat = 'h:mm a';
  let dateSlotFormat = `${dateFormat} ${slotFormat}`;
  const slotTime = appointmentTimeInMinutes;
  let weeklyData = _.values(_.invert(constants.DAYS));
  const newArray = _.cloneDeep(datesObject);
  let datesArray = _.keys(datesObject);
  datesArray = _.sortBy(datesArray, dateString => {
    return moment(dateString);
  });
  datesArray.forEach(dateString => {
    newArray[dateString] = [];
  });
  availabilityList.forEach(availability => {
    const {repeat, makeAvailable} = availability;
    const repeatCustom = repeat.substr(0, 6);
    let startDatetimeObj = moment.parseZone(availability.startDatetime);
    let OrginalStartTime = startDatetimeObj.format(slotFormat);
    let repeatEnd = moment.parseZone(availability.endDatetime);
    let startDateString = startDatetimeObj.format(dateFormat);
    let endDateString = repeatEnd.format(dateFormat);
    let recurringEndUtc = moment.utc(availability.endDatetime);
    let localStartDate = utcToLocalMoment(moment(availability.startDatetime).utc()).format(
      dateFormat,
    );
    let startTimeString =
      repeatCustom === 'none'
        ? startDatetimeObj.format(slotFormat)
        : utcToLocalMoment(availability.startDatetime).format(slotFormat);
    let endDatetimeObj =
      repeatCustom === 'none'
        ? startDatetimeObj
            .clone()
            .add(availability.refDuration || availability.duration, 'millisecond')
        : utcToLocalMoment(availability.startDatetime)
            .clone()
            .add(availability.refDuration || availability.duration, 'millisecond');
    // let endDateString = endDatetimeObj.format(dateFormat);
    let endTimeString = endDatetimeObj.format(slotFormat);
    if (repeatCustom === 'none') {
      if (startDateString !== endDateString) {
        if (!datesArray.includes(startDateString)) {
          datesArray.push(startDateString);
          newArray[startDateString] = [];
        }
        if (!datesArray.includes(endDateString)) {
          datesArray.push(endDateString);
          newArray[endDateString] = [];
        }
        datesArray = _.sortBy(datesArray, dateString => {
          return moment(dateString);
        });
      }
    } else {
      if (datesArray.length === 1) {
        let preDate = moment(datesArray[0]).subtract(1, 'days').format('YYYY-MM-DD');
        if (!datesArray.includes(preDate)) {
          datesArray.push(preDate);
          newArray[preDate] = [];
        }
        let nxtDate = moment(datesArray[0]).add(1, 'days').format('YYYY-MM-DD');
        if (!datesArray.includes(nxtDate)) {
          datesArray.push(nxtDate);
          newArray[nxtDate] = [];
        }
      } else if (datesArray.length === Object.keys(datesObject).length) {
        let preDate = moment(datesArray[0]).subtract(1, 'days').format('YYYY-MM-DD');
        if (!datesArray.includes(preDate)) {
          datesArray.push(preDate);
          newArray[preDate] = [];
        }
      }
    }
    if (makeAvailable === true) {
      if (repeatCustom === 'daily') {
        datesArray.forEach(dateString => {
          let Providertime = moment
            .utc(dateString + ' ' + OrginalStartTime, 'YYYY-MM-DD HH:mm A')
            .tz(providerTimeZone)
            .format('HH:mm');
          let ProvidertimeUtc = moment(
            dateString + 'T' + Providertime + ':00' + moment.tz(providerTimeZone).format('Z'),
          )
            .parseZone()
            .format();
          let checkData = moment(ProvidertimeUtc).utc().format(dateFormat);
          if (
            moment(checkData).isSameOrAfter(moment(startDateString)) &&
            moment(checkData).isSameOrBefore(moment(endDateString))
          ) {
            checkData = utcToLocalMoment(moment(ProvidertimeUtc).utc()).format(dateFormat);
            let endDateCalc = moment(ProvidertimeUtc)
              .clone()
              .add(availability.refDuration || availability.duration, 'millisecond')
              .format();
            endDateCalc = utcToLocalMoment(moment(endDateCalc).utc()).format(dateFormat);
            let endStringMoment = moment(endDateCalc + ' ' + endTimeString, dateSlotFormat);
            let endStringMomentUtc = endStringMoment.clone().utc();
            if (endStringMomentUtc.isSameOrBefore(recurringEndUtc)) {
              newArray[dateString].push({
                id: availability.id,
                isVirtual: availability.isVirtual,
                isInPerson: availability.isInPerson,
                s: moment(checkData + ' ' + startTimeString, dateSlotFormat),
                e: endStringMoment,
                practiceLocationId: availability.practiceLocationId,
              });
            }
          }
        });
      } else if (repeatCustom === 'CUSTOM') {
        let availabilityDays = repeat.replace('CUSTOM-', '').split('-');

        datesArray.forEach(dateString => {
          let weekDayNumber = moment(dateString).day();
          let Providertime = moment
            .utc(dateString + ' ' + OrginalStartTime, 'YYYY-MM-DD HH:mm A')
            .tz(providerTimeZone)
            .format('HH:mm');
          let ProvidertimeUtc = moment(
            dateString + 'T' + Providertime + ':00' + moment.tz(providerTimeZone).format('Z'),
          )
            .parseZone()
            .format();
          let checkData = moment(ProvidertimeUtc).utc().format(dateFormat);
          if (
            moment(checkData).isSameOrAfter(moment(startDateString)) &&
            moment(checkData).isSameOrBefore(moment(endDateString)) &&
            _.includes(availabilityDays, weeklyData[weekDayNumber])
          ) {
            checkData = utcToLocalMoment(moment(ProvidertimeUtc).utc()).format(dateFormat);
            let endDateCalc = moment(ProvidertimeUtc)
              .clone()
              .add(availability.refDuration || availability.duration, 'millisecond')
              .format();
            endDateCalc = utcToLocalMoment(moment(endDateCalc).utc()).format(dateFormat);
            let endStringMoment = moment(endDateCalc + ' ' + endTimeString, dateSlotFormat);
            let endStringMomentUtc = endStringMoment.clone().utc();

            //Issue Custom data 28-05-2022 show 29 sunday
            if (endStringMomentUtc.isSameOrBefore(recurringEndUtc)) {
              newArray[dateString].push({
                id: availability.id,
                isVirtual: availability.isVirtual,
                s: moment(checkData + ' ' + startTimeString, dateSlotFormat),
                e: endStringMoment,
                practiceLocationId: availability.practiceLocationId,
              });
            }
          }
        });
      } else if (repeatCustom === 'none') {
        //**25-02-2022 Ranjith Find Appoinment Issue Booked Issue always show
        let StartLocal = moment(startDatetimeObj).tz(providerTimeZone).format(dateFormat);
        if (newArray[StartLocal]) {
          newArray[StartLocal].push({
            id: availability.id,
            isVirtual: availability.isVirtual,
            s: startDatetimeObj.clone(),
            e: endDatetimeObj.clone(),
            practiceLocationId: availability.practiceLocationId,
          });
        }
      }
    } else {
      if (repeatCustom === 'none') {
        if (newArray[localStartDate]) {
          newArray[localStartDate] = newArray[localStartDate].filter((a, i) => {
            return a.id !== availability.refId;
          });
        }
      }
    }
  });

  // merge intersecting availability
  datesArray.forEach(dateString => {
    newArray[dateString] = mergeIntersectingTimes(newArray[dateString]);
  });
  //console.log('datesArray', datesArray);
  // remove availability of Public Holiday
  // _.forEach(datesArray, dateString => {
  //   if (getHoliday(dateString)) {
  //     newArray[dateString] = [];
  //   }
  // });

  const bookedAp = [];
  _.map(appointments, appointment => {
    let APStartObj = moment.utc(appointment.startTime);
    let APstring = APStartObj.tz(providerTimeZone).format();

    //const appointmeneStartDateString = utcToLocalMoment( moment.utc(APStartObj.tz(providerTimeZone))).format(dateFormat);

    const appointmeneStartDateString = APStartObj.tz(providerTimeZone).format(dateFormat);
    let appointmeneStartDate = utcToLocalMoment(moment.utc(APstring));
    //let appointmeneStartDate = moment.utc(APstring);
    let APEndObj = moment.utc(appointment.endTime);
    let APEndObjStr = APEndObj.tz(providerTimeZone).format();
    let appointmeneEndDate = utcToLocalMoment(moment.utc(APEndObjStr));
    // let appointmeneEndDate = moment.utc(APEndObjStr);

    //const appointmeneStartDateString = appointmeneStartDate.format(dateFormat);

    if (newArray[appointmeneStartDateString]) {
      if (!bookedAp[appointmeneStartDateString]) {
        bookedAp[appointmeneStartDateString] = [];
      }
      newArray[appointmeneStartDateString].forEach((a, i) => {
        if (isIntersect([a.s, a.e], [appointmeneStartDate, appointmeneEndDate])) {
          const nevAv = [];
          bookedAp[appointmeneStartDateString].push({
            apId: appointment.id,
            time: appointmeneStartDate.format(slotFormat),
            ef: appointmeneEndDate.format(slotFormat),
            isBooked: true,
            appointmeneStartDate: appointmeneStartDate,
            appointmeneEndDate: appointmeneEndDate,
          });
          if (!a.s.isSame(appointmeneStartDate)) {
            nevAv.push({
              id: a.id,
              isVirtual: a.isVirtual,
              s: a.s.clone(),
              e: appointmeneStartDate.clone(),
            });
          }
          if (!appointmeneEndDate.isSame(a.e)) {
            nevAv.push({
              id: a.id,
              isVirtual: a.isVirtual,
              s: appointmeneEndDate.clone(),
              e: a.e.clone(),
            });
          }
          delete newArray[appointmeneStartDateString][i];
          newArray[appointmeneStartDateString] = [
            ...newArray[appointmeneStartDateString],
            ...nevAv,
          ];
          newArray[appointmeneStartDateString] = _.compact(newArray[appointmeneStartDateString]);
        }
      });
    }
  });
  //console.log("bookedAp",bookedAp);
  // ====| START: 2 hours buffer |====
  let businessMinutes = 120;
  let usedBusinessMinutes = 0;
  // ====| END: 2 hours buffer |====
  let slots = {};
  datesArray = _.sortBy(datesArray);
  datesArray.forEach(dateString => {
    if (!slots[dateString]) {
      slots[dateString] = [];
    }
    // if (bookedAp[dateString]) {
    //   slots[dateString] = bookedAp[dateString];
    // }
    // sort by data-time
    newArray[dateString].sort(function (left, right) {
      return moment.utc(left.s).diff(moment.utc(right.s));
    });
    if (usedBusinessMinutes < businessMinutes) {
      if (bookedAp[dateString]) {
        bookedAp[dateString].forEach(ba => {
          const appDuration = duration(ba.appointmeneEndDate, ba.appointmeneStartDate);
          if (
            moment().isSameOrBefore(moment(ba.appointmeneStartDate).add()) &&
            moment(ba.appointmeneStartDate).isSameOrBefore(moment().add(3, 'hours'))
          ) {
            usedBusinessMinutes = usedBusinessMinutes + appDuration;
          }
        });
      }
    }
    newArray[dateString].forEach(a => {
      if (a.e.isBefore(moment())) {
        return;
      }

      let sDate = utcToLocalMoment(a.s).clone();
      // if(sDate.isSameOrBefore(moment().add(2,'hours'))){
      //   return
      // }
      if (moment().isBetween(sDate, a.e, undefined, '[]')) {
        const minutes = Math.ceil(moment().get('minutes') / 15) * 15;
        sDate = moment().minutes(minutes).seconds(0).milliseconds(0);
      }
      // remove 2 business hours from current date and next date
      // ====| START: 2 hours buffer |====
      // ====| 'duration' can be imported from 'momenthelper' |====
      let providerStartDate = moment(a.s).utc().tz(providerTimeZone);
      // console.log('providerStartDate', providerStartDate);
      // console.log(moment().tz(providerTimeZone).format());
      // console.log('sDate', sDate.format());
      if (nextOpenDate) {
        let nextDay = nextOpenDate ? nextOpenDate : moment().format('YYYY-MM-DD');
        if (
          sDate.isBetween(
            moment(nextDay, 'YYYY-MM-DD').startOf('day'),
            moment(nextDay, 'YYYY-MM-DD').add(1, 'day').endOf('day'),
            undefined,
            '[]',
          ) ||
          sDate.isBetween(
            moment().startOf('day'),
            moment().add(1, 'day').endOf('day'),
            undefined,
            '[]',
          )
        ) {
          if (
            Object.keys(datesObject).length > 1 ||
            providerStartDate.isBetween(
              moment(nextDay, 'YYYY-MM-DD').tz(providerTimeZone).startOf('day'),
              moment(nextDay, 'YYYY-MM-DD').tz(providerTimeZone).add(1, 'day').endOf('day'),
              undefined,
              '[]',
            ) ||
            providerStartDate.isBetween(
              moment().tz(providerTimeZone).startOf('day'),
              moment().tz(providerTimeZone).add(1, 'day').endOf('day'),
              undefined,
              '[]',
            ) ||
            usedBusinessMinutes === 0
          ) {
            let canBeUsedMinutes = businessMinutes - usedBusinessMinutes;
            if (canBeUsedMinutes > 0) {
              const avDuration = duration(a.e, sDate);
              let minutesTobeUsed = 0;
              if (canBeUsedMinutes > avDuration) {
                minutesTobeUsed = avDuration;
              } else {
                minutesTobeUsed = canBeUsedMinutes;
              }
              usedBusinessMinutes = usedBusinessMinutes + minutesTobeUsed;
              sDate.add(minutesTobeUsed, 'minutes');
            }
          }
        }
      }
      // ====| END: 2 hours buffer |====
      // if (duration(a.e, sDate) <= 0) {
      //   return;
      // }
      while (sDate.clone().add(slotTime, 'minutes').isBetween(sDate, a.e, undefined, '[]')) {
        let time = sDate.format(slotFormat);
        let eTime = sDate.clone().add(slotTime, 'minutes').format('h:mm:ss a');
        let pm5 = moment('5:00pm', 'h:mma');
        let am9 = moment('9:00am', 'h:mma');
        let momentS = moment(time, 'h:mma');
        let momentE = moment(eTime, 'h:mma');
        let isSpecialTime = momentS.isAfter(pm5) || momentE.isAfter(pm5) || momentS.isBefore(am9);
        let datestringTemp = sDate.format('YYYY-MM-DD');
        if (!slots[datestringTemp]) {
          slots[datestringTemp] = [];
        }
        if (sDate.clone().isSameOrAfter(moment().add(2, 'hours'))) {
          slots[datestringTemp].push({
            // ...a,
            providerAvailabilityId: a.id,
            isVirtual: a.isVirtual,
            isBooked: false,
            time: time,
            eTime: eTime,
            isSpecialTime: isSpecialTime,
            practiceLocationId: a.practiceLocationId,
          });
        }
        sDate.add(slotTime, 'minutes');
      }
    });
  });

  // sort time
  datesArray.forEach(dateString => {
    slots[dateString] = slots[dateString].sort((a, b) => {
      return moment(a.time, slotFormat).isBefore(moment(b.time, slotFormat)) ? -1 : 1;
    });
  });
  let tempSlots = {};

  Object.keys(slots).map(k => {
    if (Object.keys(datesObject).length === 5) {
      if (slots[k].length > 0 || datesObject[k]) {
        tempSlots[k] = slots[k];
      }
    } else if (Object.keys(datesObject).length > 5) {
      if (datesObject[k]) {
        tempSlots[k] = slots[k];
      }
    } else {
      tempSlots = slots;
    }
  });

  //console.log('datesObject', datesObject);
  if (Object.keys(tempSlots).length > 5 && Object.keys(datesObject).length === 5) {
    let tempObj = firstN(tempSlots, 5);
    tempSlots = tempObj;
  }
  // console.log("datesObject",datesObject);
  // console.log("slots",tempSlots);
  tempSlots = Object.keys(tempSlots)
    .sort()
    .reduce((obj, key) => {
      obj[key] = tempSlots[key];
      return obj;
    }, {});
  return tempSlots;
};
const firstN = (obj, n) => {
  return Object.keys(obj) //get the keys out
    .slice(0, n) //get the first N
    .reduce(function (memo, current) {
      //generate a new object out of them
      memo[current] = obj[current];
      return memo;
    }, {});
};
const getLatestAvailabilityDate = (
  type,
  latestAvailability,
  providerAvailabilities,
  existingAppointmentTime,
  patientAppointments,
  timezone,
) => {
  let availabilityObj = latestAvailability
    ? type
      ? latestAvailability.practiceLocationDataVirtual
      : latestAvailability.practiceLocationDataInPerson
    : null;
  if (availabilityObj) {
    let startDate = dateOrCurrentDate();
    let startDateString = moment(availabilityObj.startDatetime);

    if (isAfter(startDateString, startDate)) {
      startDate = moment(startDateString, 'YYYY-MM-DD');
    }
    //let currentopenDay=startDate.clone().add(existingAppointmentTime,'minutes');
    let currentPassDate = null;
    // if(moment().isBetween(moment(availabilityObj.startDatetime),moment(availabilityObj.endDatetime),undefined,'[]')){
    //  // currentPassDate=moment().format('YYYY-MM-DD');
    //  // return currentPassDate
    // }
    startDate.subtract(1, 'day');
    let datesObj = {};
    datesObj[dateFormat(startDate)] = [];
    for (let i = 1; i < 8; i++) {
      startDate.add(1, 'day');
      datesObj[dateFormat(startDate)] = [];
    }

    let updatedAvailabilities = getAvailability(
      providerAvailabilities,
      datesObj,
      existingAppointmentTime,
      [],
      timezone,
      currentPassDate,
    );
    if (Object.keys(updatedAvailabilities).length > 0) {
      let selectedVal = Object.entries(updatedAvailabilities).find(
        ([key, item]) => item.length > 0,
      )?.[0];
      return selectedVal ? selectedVal : null;
    }
  }
  return null;
};
const formatPhoneNumber = (phoneNumber = '') => {
  if (phoneNumber.length === 10) {
    const first3Degits = phoneNumber.substring(0, 3);
    const second3Degits = phoneNumber.substring(3, 6);
    const third4Degits = phoneNumber.substring(6, 10);
    return `(${first3Degits}) ${second3Degits}-${third4Degits}`;
  } else {
    return phoneNumber;
  }
};

const formatPhoneNumberWithDash = (phoneNumber = '') => {
  if (phoneNumber.length === 10) {
    const first3Degits = phoneNumber.substring(0, 3);
    const second3Degits = phoneNumber.substring(3, 6);
    const third4Degits = phoneNumber.substring(6, 10);
    return `${first3Degits}-${second3Degits}-${third4Degits}`;
  } else {
    return phoneNumber;
  }
};
const formatPhoneNumberWithOutDash = (phoneNumber = '') => {
  if (phoneNumber.length > 10) {
    let phoneNumberTemp = phoneNumber.replace('(', '');
    phoneNumberTemp = phoneNumberTemp.replace(/ /g, '');
    phoneNumberTemp = phoneNumberTemp.replace(/-/g, '');
    phoneNumberTemp = phoneNumberTemp.replace(')', '');
    return phoneNumberTemp;
  } else {
    return phoneNumber;
  }
};
const getOffset = el => {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
};

const validateFile = async fileObj => {
  const _URL = window.URL || window.webkitURL;
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = _URL.createObjectURL(fileObj);
    img.onload = () => {
      if (img.width > 0 && img.height > 0) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    img.onerror = () => {
      resolve(false);
    };
  });
};

const getAppointmentClassFromStatus = status => {
  let appointmentStatusClass = '';
  switch (status) {
    case 0:
      appointmentStatusClass = 'pending';
      break;
    case 1:
      appointmentStatusClass = 'confirmed';
      break;
    case 2:
      appointmentStatusClass = 'cancelled';
      break;
    case 3:
      appointmentStatusClass = 'completed';
      break;
    case 4:
      // patient no show status
      appointmentStatusClass = 'cancelled';
      break;
    default:
      break;
  }
  return appointmentStatusClass;
};

const getAppointmentStatusName = status => {
  let appointmentStatus = '';
  switch (status) {
    case 0:
      appointmentStatus = 'Pending';
      break;
    case 1:
      appointmentStatus = 'Confirmed';
      break;
    case 2:
      appointmentStatus = 'Cancelled';
      break;
    case 3:
      appointmentStatus = 'Completed';
      break;
    case 4:
      // patient no show status
      appointmentStatus = 'No show';
      break;
    default:
      break;
  }
  return appointmentStatus;
};

const focusElasticSearchBox = () => {
  const elasticSearchTextBox = document.querySelector('[name="searchText"]');
  if (elasticSearchTextBox) {
    elasticSearchTextBox.focus();
  }
};
const getLocationAwait = async () => {
  if (Object.keys(ipdata).length === 0) {
    let ipstack = await axiosInstance
      .get(`https://api.ipstack.com/check?access_key=${config.IPSTACK}&format=1`)
      .catch(error => {
        return null;
      });

    if (ipstack && ipstack.success !== false) {
      let returnData = {
        coords: {latitude: ipstack.latitude, longitude: ipstack.longitude},
        loc_details: {
          country_code: ipstack.country_code,
          country_name: ipstack.country_name,
          region_code: ipstack.region_code,
          region_name: ipstack.region_name,
          zip: ipstack.zip,
          ip: ipstack.ip,
        },
      };
      ipdata = returnData;
      return returnData;
    } else {
      return {
        coords: {
          latitude: '',
          longitude: '',
        },
        loc_details: {},
      };
    }
  } else {
    return ipdata;
  }
};
const getLocation = async (callback = () => {}, errorCallback = () => {}) => {
  // if (navigator.geolocation) {
  //   navigator.geolocation.getCurrentPosition(callback, errorCallback);
  // }

  if (Object.keys(ipdata).length === 0) {
    // let ipify = await axiosInstance.get('https://api.ipify.org/?format=json');
    // if (ipify.ip) {
    let ipstack = await axiosInstance
      .get(`https://api.ipstack.com/check?access_key=${config.IPSTACK}&format=1`)
      .catch(error => {
        return null;
      });

    if (ipstack && ipstack.success !== false) {
      let returnData = {
        coords: {latitude: ipstack.latitude, longitude: ipstack.longitude},
        loc_details: {
          country_code: ipstack.country_code,
          country_name: ipstack.country_name,
          region_code: ipstack.region_code,
          region_name: ipstack.region_name,
          zip: ipstack.zip,
          ip: ipstack.ip,
        },
      };
      callback(returnData);
      ipdata = returnData;
    } else {
      callback({});
    }
    // }
  } else {
    callback({...ipdata});
  }
};

const getPatientUserAddress = (user = {}) => {
  const address = _.get(user, 'address') || '';
  return address;
};

// https://stackoverflow.com/questions/6671183/calculate-the-center-point-of-multiple-latitude-longitude-coordinate-pairs
const getCenterPoint = data => {
  if (data.length === 0) {
    return null;
  }
  let X = 0.0;
  let Y = 0.0;
  let Z = 0.0;
  data.forEach(item => {
    const lat = (item.latitude * Math.PI) / 180;
    const lon = (item.longitude * Math.PI) / 180;
    const a = Math.cos(lat) * Math.cos(lon);
    const b = Math.cos(lat) * Math.sin(lon);
    const c = Math.sin(lat);
    X += a;
    Y += b;
    Z += c;
  });
  X /= data.length;
  Y /= data.length;
  Z /= data.length;
  const hyp = Math.sqrt(X * X + Y * Y);
  const finalLat = (Math.atan2(Z, hyp) * 180) / Math.PI;
  const finalLng = (Math.atan2(Y, X) * 180) / Math.PI;
  return {
    lat: finalLat,
    lng: finalLng,
  };
};

// https://www.geodatasource.com/developers/javascript
//unit:
//  'M' is statute miles (default)
//  'K' is kilometers
//  'N' is nautical miles
const getDistance = ([lat1, lon1], [lat2, lon2], unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

const getGreetingTime = () => {
  let currentTime = moment();
  let systemTimeZone = moment.tz.guess();
  if (
    localStorage.getItem('UTZ') &&
    localStorage.getItem('UTZ') !== '' &&
    localStorage.getItem('UTZ') !== systemTimeZone
  ) {
    currentTime = moment.tz(localStorage.getItem('UTZ'));
  }
  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(currentTime.format('HH'));
  if (currentHour >= splitAfternoon && currentHour < splitEvening) {
    return 'Good Afternoon';
  } else if (currentHour >= splitEvening) {
    return 'Good Evening';
  }
  return 'Good Morning';
};

const toggleBodyScrollClass = show => {
  const htmls = document.getElementsByTagName('html');
  const html = _.get(htmls, '0');
  if (html) {
    if (show) {
      html.classList.add('no-scroll');
    } else {
      html.classList.remove('no-scroll');
    }
  }
};

const getProviderSuffix = suffixes => {
  const prefixString = suffixes.length > 0 ? ', ' : '';
  return prefixString + suffixes.join(', ');
};

const isArrayEqual = (x, y) => {
  //Ref: https://stackoverflow.com/questions/37065663/array-of-object-deep-comparison-with-lodash
  return _(x).xorWith(y, _.isEqual).isEmpty();
};

const parseSpecialCharacter = string => {
  if (string)
    string = string
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'");
  return string;
};
const convertStringToSlug = string => {
  string = string
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
    .replace('--', '-');
  let realString = string.replace('-', '');
  if (realString.length < 1) {
    return false;
  }
  return string;
};
const setAppointmentSourceStore = val => {
  let sourceObj = {val: val, createTime: moment().format()};
  localStorage.setItem('appointmentSource', JSON.stringify(sourceObj));
};
const setproviderSearchRanking = e => {
  let selectedDr = e.target.closest('.DoctorItem');
  let ii = [].indexOf.call(selectedDr.parentNode.children, selectedDr);
  localStorage.setItem('providerSearchRanking', ii + 1);
};
const getAppointmentSourceStore = val => {
  if (localStorage.getItem('appointmentSource')) {
    let appointmentSourceVal = JSON.parse(localStorage.getItem('appointmentSource'));
    var now = moment(new Date()); //todays date

    var end = moment(appointmentSourceVal.createTime);
    var duration = moment.duration(now.diff(end));
    var Minutes = duration.asMinutes();
    if (Minutes < 15) {
      return appointmentSourceVal.val;
    } else {
      localStorage.removeItem('appointmentSource');
    }
  }

  return false;
};
const setUserTimeZone = val => {
  localStorage.setItem('UTZ', val);
};
export const encrypt = text => {
  try {
    const key = `${config.enkey1}${config.enkey2}`;
    return [...text]
      .map((x, i) =>
        (x.codePointAt() ^ key.charCodeAt(i % key.length) % 255).toString(16).padStart(2, '0'),
      )
      .join('');
  } catch (error) {
    return null;
  }
};
export const decrypt = text => {
  try {
    const key = `${config.enkey1}${config.enkey2}`;
    return String.fromCharCode(
      ...text
        .match(/.{1,2}/g)
        .map((e, i) => parseInt(e, 16) ^ key.charCodeAt(i % key.length) % 255),
    );
  } catch (error) {
    return null;
  }
};
export default {
  focusElasticSearchBox,
  getFileNameFromUrl,
  getSuffixString,
  scrollToDiv,
  loadScript,
  getAvailability,
  formatPhoneNumber,
  getOffset,
  getHighlightedText,
  validateFile,
  getAppointmentClassFromStatus,
  getLocation,
  //getCurrentEnvironment,
  getPatientUserAddress,
  getCenterPoint,
  getDistance,
  getGreetingTime,
  toggleBodyScrollClass,
  getAppointmentStatusName,
  isIntersect,
  formatPhoneNumberWithDash,
  formatPhoneNumberWithOutDash,
  getProviderSuffix,
  isArrayEqual,
  parseSpecialCharacter,
  convertStringToSlug,
  setAppointmentSourceStore,
  getAppointmentSourceStore,
  setUserTimeZone,
  getLocationAwait,
  getLatestAvailabilityDate,
  setproviderSearchRanking,
  encrypt,
};
