import moment from 'moment';

//Thanksgiving Day

const getHoliday = (dateString = '') => {
  let year = dateString ? moment(dateString).year() : moment().year();
  let holidayList = [
    {
      label: "New Year's Day",
      value: '01-01', // 1st January
    },
    {
      label: 'Juneteenth',
      value: '06-19', // 19th Jun
    },
    {
      label: 'Independence Day',
      value: '07-04', // 4th July
    },
    {
      label: `Veterans Day`,
      value: '11-11', //11th November
    },
    {
      label: 'Christmas Day',
      value: '12-25', // 25th December
    },
  ];

  //Birthday of Martin Luther King
  let date = moment().year(year).month(0).date(1).day(8);
  if (date.date() > 7) date.day(-6);
  date.add(14, 'days');
  let MartinLutherKing = {label: `Bday of MLK, Jr.`, value: `01-${date.date()}`};
  holidayList = [...holidayList, MartinLutherKing];
  //Birthday of Martin Luther King

  //Washington's Birthday
  let wdate = moment().year(year).month(1).date(1).day(8);
  if (wdate.date() > 7) wdate.day(-6);
  wdate.add(14, 'days');
  let WashingtonBirthday = {label: `Washington's Birthday`, value: `02-${wdate.date()}`};
  holidayList = [...holidayList, WashingtonBirthday];
  //Washington's Birthday

  // Memorial Day
  date = moment().year(year).month(4).endOf('month').day('Monday');
  let MemorialDay = {label: `Memorial Day`, value: `05-${date.date()}`};
  holidayList = [...holidayList, MemorialDay];
  //Memorial Day

  // Labor Day
  date = moment().year(year).month(8).date(1).day(8);
  if (date.date() > 7) date.day(-6);

  let LaborDay = {label: `Labor Day`, value: `09-0${date.date()}`};
  holidayList = [...holidayList, LaborDay];
  //Labor Day

  // Columbus Day
  date = moment().year(year).month(9).date(1).day(8);
  if (date.date() > 7) date.day(-6);
  date.add(7, 'days');
  let dayval = date.date().toString().length === 1 ? `0${date.date()}` : date.date();
  let ColumbusDay = {
    label: `Columbus Day`,
    value: `10-${dayval}`,
  };
  holidayList = [...holidayList, ColumbusDay];

  //Columbus Day

  //Thanksgiving Day
  date = moment().year(year).month(10).date(1).day(11);
  if (date.date() > 7) date.subtract(7, 'days');
  date.add(21, 'days');
  let Thanksgiving = {label: `Thanksgiving Day`, value: `11-${date.date()}`};
  holidayList = [...holidayList, Thanksgiving];
  let holiday = false;
  holidayList.forEach(holidayObj => {
    if (dateString.substr(5) === holidayObj.value) {
      holiday = {...holidayObj, dateString};
    }
  });
  return holiday;
};

export default {
  // holidayList,
  getHoliday,
};
