import React from 'react';
import images from 'data/images';
import Select from 'components/FormControls/Select';
import RecipientSourceList from './RecipientSourceList';
import './RequestRecipients.css';

const RequestRecipients = props => {
  const selectOptions = {
    patientsTypeOptions: [
      {value: 1, label: 'permission 1'},
      {value: 2, label: 'permission 2'},
      {value: 3, label: 'permission 3'},
    ],
  };
  return (
    <div>
      <div className="RequestRecipients">
        <div className="heading">
          <div className="title">Review Request Recipients </div>
          {/* <span className="right-panel">
            <Select
              containerClass="select-content"
              options={selectOptions.patientsTypeOptions}
              arrowCss={{backgroundImage: `url(${images.angleDown})`}}
            />
          </span> */}
        </div>
        <div className="ConnectReviews-content">
          <RecipientSourceList />
        </div>
      </div>
    </div>
  );
};

export default RequestRecipients;
