import axiosInstance from 'config/axios';

const getAllFaq = () => {
  return axiosInstance.get(`v1/developer/getSpeciality-Faq`);
};

const addFaq = (data) => {
  return axiosInstance.post(`v1/admin/createFaq`, data);
};

const updateFaq = (data) => {
  return axiosInstance.put(`v1/admin/update-Faq`,data);
};

export default {
  getAllFaq,
  addFaq,
  updateFaq,
};
