import React, {useEffect} from 'react';
import helper from 'data/helper';
import './FacebookLoginButton.css';

const {loadScript} = helper;

const FacebookLoginButton = props => {
  const {
    className = '',
    scope = 'email',
    appId,
    children,
    onLoginSuccess = () => {},
    onLoginFailure = () => {},
  } = props;

  const onLoad = () => {
    window.FB.init({
      appId: appId,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v7.0',
    });

    // if(window.location.href.includes('fblogin=true')){
    //   setTimeout(() => {
    //     document.querySelector('.FacebookLoginButton').click()
    //   }, 1000);
      
    // }
  };

  useEffect(() => {
    loadScript(document, 'script', 'facebook-jssdk', 'https://connect.facebook.net/en_US/sdk.js');
    window.fbAsyncInit = onLoad;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkLoginState = () => {
      // if user is logged in then logout him
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          window.FB.logout(() => {
            resolve(true);
          });
        } else {
          resolve(true);
        }
      });
    });
  };

  const login = async () => {
    //let currentUrl=window.location.href
    // if(!currentUrl.includes('kalypsys')&&currentUrl.includes('kaly')){
    //   let redirectPAth=currentUrl.replace('kaly','kalypsys')
    //   redirectPAth =`${redirectPAth}?fblogin=true`
    //   window.location.replace(redirectPAth);
    //   return
    // }
    const response = await checkLoginState();
    if (response) {
      window.FB.login(onStatusChange, {
        scope: scope,
        auth_type: 'reauthenticate',
      });
    }
  };

  const onStatusChange = response => {
    if (response.status === 'connected') {
      onLoginSuccess(response.authResponse);
    } else {
      onLoginFailure(response);
    }
  };

  return (
    <span className={`FacebookLoginButton ${className}`} onClick={login}>
      {children}
    </span>
  );
};

export default FacebookLoginButton;
