import React, {Component} from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import images from 'data/images';
import constants from 'data/constants';
import EditLanguages from './EditLanguages';
import _ from 'lodash';
import './Languages.css';

const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  searchText: '',
  pageLimit: 10,
  sortBy: 'name',
  orderBy: 'asc',
  totalRecord: 0,
};
const {modalFunctions} = modalConfig;
const {errorModalData, blankModalData} = modalFunctions;
const {getLanguageList} = adminService;
const {SuffixStatus, DefaultDebounceMSec} = constants;

class Languages extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount() {
    this.setLanguageList(false);
  }

  setLanguageList = async (noLoad = false) => {
    const {activePage, pageLimit, searchText, sortBy, orderBy} = this.state;
    const res = await getLanguageList(
      {
        page: activePage,
        limit: pageLimit,
        search: searchText,
        sortBy: sortBy, // allow 'code', 'name', 'nativeName', 'status', 'updatedAt'
        orderBy: orderBy,
      },
      noLoad,
    );
    this.setState({
      list: _.get(res, 'data.list', []),
      totalPage: _.get(res, 'data.totalPage', 0),
      totalRecord: _.get(res, 'data.totalRecord', 0),
    });
  };

  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.setLanguageList);
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };

  openEditModal = data => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: EditLanguages,
        customComponentProps: {data, updateList: this.setLanguageList},
      }),
    );
  };

  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.setLanguageList();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };
  handleSearch = () => {
    this.setState({activePage: 1}, this.setLanguageList);
  };
  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.setLanguageList);
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.setLanguageList,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.setLanguageList);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  render() {
    const {list, totalPage, searchText, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    const title = 'Languages';
    return (
      <Layout>
        <div className="Languages">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div>
                <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
                {searchText && (
                  <button className="btn-clear" onClick={this.handleClearSearch}>
                    <img src={images.close} alt="clear" />
                  </button>
                )}
              </div>
              <button onClick={this.handleSearch}>Search</button>
            </div>
            <button className="btn-add-new" onClick={() => this.openEditModal({})}>
              Add New Languages
            </button>
            <div className="dataCount">
              <span>{totalRecord} languages found.</span>
            </div>
            <div className="table-overflow">
              <div className="rtable">
                <div className="heading">
                  <div className="cell pointer" data-key="name" onClick={this.handleSortingChange}>
                    Name{this.renderShortIcon('name')}
                  </div>
                  <div className="cell pointer" data-key="code" onClick={this.handleSortingChange}>
                    Code{this.renderShortIcon('code')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="nativeName"
                    onClick={this.handleSortingChange}>
                    Native Name{this.renderShortIcon('nativeName')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="status"
                    onClick={this.handleSortingChange}>
                    Status{this.renderShortIcon('status')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="updatedAt"
                    onClick={this.handleSortingChange}>
                    Last Updated At{this.renderShortIcon('updatedAt')}
                  </div>
                  <div className="cell edit-cell">Action</div>
                </div>
                {_.map(list, (item, index) => (
                  <div className="row" key={index}>
                    <div className="cell">{_.get(item, 'name', '')}</div>
                    <div className="cell">{_.get(item, 'code', '')}</div>
                    <div className="cell">{_.get(item, 'nativeName', '')}</div>
                    <div className="cell">{SuffixStatus[_.get(item, 'status', '')]}</div>
                    <div className="cell">
                      {item.updatedAt && this.formatedDate(item.updatedAt)}
                    </div>
                    <div className="cell edit-cell">
                      <img
                        onClick={() => this.openEditModal(item)}
                        src={images.editPen}
                        className="edit-icon"
                        alt="editPen"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Languages;
