import React from 'react';
import './ErrorTemplate.css';

const ErrorTemplate = props => {
  return (
    <div className="modal-dialog error">
      <div className="modal-header">
        <div className="title">Error!</div>
      </div>
      <div className="modal-body">{props.description || 'There is an error. Please try again.'}</div>
    </div>
  );
};

export default ErrorTemplate;
