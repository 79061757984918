import _ from 'lodash';
import rolePermission from 'data/rolePermission';
import helper from './helper';

const {helperFunctions, permissionList} = rolePermission;
const {getAllMyRoleIds, hasPermission} = helperFunctions;
const {setUserTimeZone} = helper;
const checkProviderProfileCompleted = user => {
  const {profileImageUrl, gender, provider} = user;
  const {
    providerPhotos = [],
    professionalIntroduction,
    hasPatientsAccepted,
    npi,
    certifications,
    suffixes,
  } = provider;
  const {
    educationGraduateSchool,
    educationUndergraduateSchool,
    // trainings, // not required
    languages,
    // hospitalAffiliations, // not required
    boardCertifications,
  } = provider;
  const {specialties = []} = provider;
  const {visitReasons = []} = provider;
  const {pains: conditions = []} = provider;
  const {procedures = []} = provider;

  let photoStepCompleted,
    overviewStepCompleted,
    additionalInfoStepCompleted,
    specialityStepCompelted,
    visitReasonStepCompelted,
    conditionStepCompleted,
    procedureStepCompleted;
  // check `Photo` section is filled
  photoStepCompleted = !!(profileImageUrl && providerPhotos.length > 0);
  // check `Provider Overview` section is filled
  overviewStepCompleted = !!(
    professionalIntroduction &&
    gender &&
    hasPatientsAccepted &&
    npi &&
    certifications.length > 0 &&
    suffixes.length > 0
  );
  // check `Additonal Information` section is filled
  additionalInfoStepCompleted = !!(
    educationGraduateSchool &&
    educationUndergraduateSchool &&
    // trainings.length > 0 &&  // not required
    languages.length > 0 &&
    // hospitalAffiliations.length > 0 && // not required
    boardCertifications.length > 0
  );
  // check `Add Specialities` section is filled
  specialityStepCompelted = specialties.length > 0;
  // check `Add Visit Reasons` section is filled
  visitReasonStepCompelted = visitReasons.length > 0;
  // check `Conditions Commonly Treated` section is filled
  conditionStepCompleted = conditions.length > 0;
  // check `Procedures Performed` section is filled
  procedureStepCompleted = procedures.length > 0;

  const isProfileCompleted =
    photoStepCompleted &&
    overviewStepCompleted &&
    additionalInfoStepCompleted &&
    specialityStepCompelted &&
    visitReasonStepCompelted &&
    conditionStepCompleted &&
    procedureStepCompleted;

  return isProfileCompleted;
};

const setViewUserId = (user, exceptProviderId = null) => {
  const roleIds = getAllMyRoleIds(user);
  const practiceMembers = _.get(user, 'practiceMembers', []);

  if (hasPermission(roleIds, permissionList.CAN_SWITCH_USER)) {
    let viewUserId = null;

    _.forEach(practiceMembers, pm => {
      if (exceptProviderId === pm.provider.id) {
        return;
      }
      if (pm.status === 1 && !viewUserId) {
        viewUserId = pm.id;
      }
      if (pm.id === user.id) {
        viewUserId = pm.id;
      }
    });
    if (!viewUserId) {
      viewUserId = _.get(practiceMembers, '0.id', null);
    }
    user.viewUserId = viewUserId;
  }
  if (roleIds[0] === '2') {
    setUserTimeZone(user.timezone);
  } else if (['3', '4', '5'].includes(roleIds[0]) && user.viewUserId) {
    let viewuserTimeZone = practiceMembers.find(pm => pm.id === user.viewUserId);
    if (viewuserTimeZone) setUserTimeZone(viewuserTimeZone.timezone);
  }
  
  return user;
};

export default {
  providerFunctions: {
    checkProviderProfileCompleted,
    setViewUserId,
  },
};
