import React, {useState} from 'react';
// import {Viewer as ViewPDF} from '@react-pdf-viewer/core';
import Viewer from 'react-viewer';
import PDFViewer from "./PDFViewer"
// import '@react-pdf-viewer/core/lib/styles/index.css';
export default function ViewPhotoPDF(props) {
  const {url, visible, setVisible, setViewUrl} = props;
  return (
    <>
      {!url.endsWith('.pdf') ? (
        <Viewer
          visible={visible}
          onClose={() => {
            setVisible(false);
            setViewUrl('');
          }}
          images={[{src: url, alt: 'Document View'}]}
          zIndex="5000"
          rotatable={false}
          noFooter={true}
        />
      ) : (
        <>
          {visible && (
            <PDFViewer setVisible={(val)=>setVisible(val)} url={url} />
          )}
        </>
      )}
    </>
  );
}
