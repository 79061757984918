import config from 'config/config';
import _ from 'lodash';

const defaultSignUpStepsData = {
  step1Data: {
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    hasAcceptedTerms: false,
    hasReadHipaa: false,
  },
  step2Data: {
    subStep1Data: {
      name: '', // practiceName
      website: '',
      software: '',
      logo: '',
    },
    subStep2Data: {
      locationName: '',
      address: '',
      officePhone: '',
      officePhoneExt: '',
      contactPersonName: '',
      appointmentEmail: '',
      latitude: '',
      longitude: '',
    },
  },
  step3Data: {
    subStep1Data: {
      firstName: '',
      lastName: '',
      gender: '',
      hasPatientsAccepted: '',
      npi: '',
    },
    subStep2Data: {
      certifications: [],
      specialties: [],
      suffixes: [], //Professional Suffix
    },
  },
  step4Data: {
    userDetails: [],
  },
};

const _formatStepsData = data => {
  const defaultText = '';
  const { hasInvited, currentStep } = data;
  let newcurrentStep = hasInvited && currentStep === 3 ? 4 : currentStep
  newcurrentStep = (!config.showStripe && currentStep === 3) ? 4 : currentStep;
  let { userData, provider, practice, practiceLocation } = data;
  if (!userData) userData = {};
  if (!provider) provider = {};
  if (!practice) practice = {};
  if (!practiceLocation) practiceLocation = {};
  const { firstName = defaultText, lastName = defaultText, gender = defaultText } = userData;
  const {
    hasPatientsAccepted = defaultText,
    specialties = [],
    certifications = [],
    suffixes = [],
    npi = defaultText,
  } = provider;
  const {
    // longitude = defaultText,
    // latitude = defaultText,
    name = defaultText,
    websites = [],
    software = defaultText,
    // logo = defaultText,
    logoUrl = defaultText,
  } = practice;
  const website = _.get(websites, '0', defaultText);
  const {
    locationName = defaultText,
    address = defaultText,
    officePhone = defaultText,
    officePhoneExt = defaultText,
    contactPersonName = defaultText,
    appointmentEmails = [],
    longitude = defaultText,
    latitude = defaultText,
  } = practiceLocation;
  const appointmentEmail = _.get(appointmentEmails, '0', defaultText);
  const stepsData = {
    ...defaultSignUpStepsData,
    hasInvited,
    currentStep: newcurrentStep,
    step1Data: {
      ...defaultSignUpStepsData.step1Data,
      firstName,
      lastName,
    },
    step2Data: {
      ...defaultSignUpStepsData.step2Data,
      subStep1Data: {
        ...defaultSignUpStepsData.step2Data.subStep1Data,
        name,
        website,
        software,
        logoUrl,
      },
      subStep2Data: {
        ...defaultSignUpStepsData.step2Data.subStep2Data,
        locationName,
        address,
        officePhone,
        officePhoneExt,
        contactPersonName,
        appointmentEmail,
        longitude,
        latitude,
      },
    },
    step3Data: {
      ...defaultSignUpStepsData.step3Data,
      subStep1Data: {
        ...defaultSignUpStepsData.step3Data.subStep1Data,
        firstName,
        lastName,
        gender: gender === 0 ? '' : gender,
        hasPatientsAccepted: hasPatientsAccepted === 0 ? '' : hasPatientsAccepted,
        npi,
      },
      subStep2Data: {
        ...defaultSignUpStepsData.step3Data.subStep2Data,
        specialties,
        certifications,
        suffixes,
      },
    },
  };
  return stepsData;
};

export default {
  defaultSignUpStepsData,
  stepFunctions: {
    _formatStepsData,
  },
};
