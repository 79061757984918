import React, { useState, useCallback } from 'react';
import axiosInstance from 'config/axios';

import VideoLobby from 'components/VideoLobby';
import VideoRoom from 'components/VideoRoom';
import VideoMessage from 'components/VideoMessage';
import './VideoChat.css';

const VideoChat = () => {
    const [username, setUsername] = useState('');
    const [roomName, setRoomName] = useState('');
    const [token, setToken] = useState(null);

    const handleUsernameChange = useCallback(event => {
        setUsername(event.target.value);
    }, []);

    const handleRoomNameChange = useCallback(event => {
        setRoomName(event.target.value);
    }, []);

    const getTwilioToken = data => {
        return axiosInstance.post(`v1/video-chat/token`, data);
    };

    const handleSubmit = useCallback(async event => {
        event.preventDefault();
        let response = await getTwilioToken({
            identity: username,
            roomName: roomName
        });
        const { data } = response;
        setToken(data.token);
    }, [username, roomName]);

    const handleLogout = useCallback(event => {
        setToken(null);
    }, []);

    let render;
    if (token) {
        render = (
            <>
                <VideoRoom roomName={roomName} token={token} handleLogout={handleLogout} />
                <VideoMessage roomName={roomName} token={token} username={username} handleLogout={handleLogout} />
            </>
        );
    } else {
        render = (
            <VideoLobby
                username={username}
                roomName={roomName}
                handleUsernameChange={handleUsernameChange}
                handleRoomNameChange={handleRoomNameChange}
                handleSubmit={handleSubmit}
            />
        );
    }
    return (
        <div className="VideoChat">
            <div className="app">
                <header>
                    <h1>VIDEO CHAT DEMO With Text Chat and Media Share</h1>
                </header>
                <main>
                    {render}
                </main>
                <footer>
                    <p>Made For Kaly</p>
                </footer>
            </div>
        </div>
    );
};

export default VideoChat;
