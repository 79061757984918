import React, {useState, useContext} from 'react';
import moment from 'moment';
import _ from 'lodash';
import images from 'data/images';
import helper from 'data/helper';
import momentHelper from 'data/moment/momentHelper';
import scheduleService from 'services/schedule';
import MyLink from 'components/MyLink';
import modalConfig from 'modals/modalConfig';
import DoctorViewAvailabilityModal from '../ConfirmAppointment/DoctorViewAvailabilityModal';
import RootContext from 'context/RootContext';
import './DoctorItemOrg.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import TimeZoneLabel from 'components/MyDatetime/TimeZoneLabel';
import {setOrUpdateLocalData} from 'data/trackFlowQuestions.helper';

const {getFilteredAvailabilityOfDoctor} = scheduleService;
const {
  getAvailability,
  getSuffixString,
  setAppointmentSourceStore,
  setproviderSearchRanking,
  getLatestAvailabilityDate,
} = helper;
const {dateOrCurrentDate, isSameOrAfter, isSameDay, dateFormat, endOf} = momentHelper;
const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;
const showAvailabilityOfDays = 5;
const {Analytics} = analyticsData;

const DoctorItemOrg = props => {
  const {doctorDetail, isVirtual, selectedResult, answers = [], onTimeSelect} = props;
  const [showMore, setShowMore] = useState(false);
  let painId = null;
  let procedureId = null;
  if (selectedResult && selectedResult.type === 'symptom') {
    painId = selectedResult.id;
  } else if (selectedResult) {
    //pain
    const answerWithPainData = _.find(answers, answer => {
      return answer.painData;
    });
    if (answerWithPainData) {
      painId = answerWithPainData.painData.id;
    }
    const answerWithMultiPainData = _.find(answers, answer => {
      return answer.multiPainData;
    });
    if (answerWithMultiPainData && _.get(answerWithMultiPainData, 'multiPainData', []).length > 0) {
      painId = answerWithMultiPainData.multiPainData[0].id;
    }
    //procedure
    const answerWithProcedureData = _.find(answers, answer => {
      return answer.procedureData;
    });
    if (answerWithProcedureData) {
      procedureId = answerWithProcedureData.procedureData.id;
    }
    const answerWithMultiProcedureData = _.find(answers, answer => {
      return answer.multiProcedureData;
    });
    if (
      answerWithMultiProcedureData &&
      _.get(answerWithMultiProcedureData, 'multiProcedureData', []).length > 0
    ) {
      procedureId = answerWithMultiProcedureData.multiProcedureData[0].id;
    }
  }
  if (procedureId == null)
    procedureId = selectedResult && selectedResult.type === 'procedure' && selectedResult.id;

  const rootContext = useContext(RootContext);
  const {setGlobal, reviewSourceList} = rootContext;
  const {
    userId,
    providerId,
    profileImageLink,
    profileLink,
    name,
    locationId,
    address,
    totalReview,
    totalRating,
    suffixes,
    specialtys,
    conditions,
    procedures = [],
    existingAppointmentTime,
    newAppointmentTime,
    formatedAvailabilities,
    location,
    reviewSourceIds,
    customSpecialty = null,
    firstName,
    lastName,
    bookNowLink,
  } = doctorDetail;
  const newAppointmentTimeorExAT =
    existingAppointmentTime > newAppointmentTime ? existingAppointmentTime : newAppointmentTime;
  const otherPercentageAfterNumber = 5;

  const sortedConditions = _.orderBy(conditions, ['percentage'], ['desc']);
  let mainConditionIndex = null;
  sortedConditions.forEach((condition, index) => {
    if (painId && condition.id.toString() === painId.toString()) {
      mainConditionIndex = index;
    }
  });
  const isOtherConditionActive =
    mainConditionIndex === null && sortedConditions.length === otherPercentageAfterNumber;
  if (mainConditionIndex === null && sortedConditions.length > 0) {
    mainConditionIndex = 0;
  }

  const sortedProcedures = _.orderBy(procedures, ['percentage'], ['desc']);
  let mainProcedureIndex = null;
  sortedProcedures.forEach((procedure, index) => {
    if (procedureId && procedure.id.toString() === procedureId.toString()) {
      mainProcedureIndex = index;
    }
  });
  const isOtherProcedureActive =
    mainProcedureIndex === null && sortedProcedures.length === otherPercentageAfterNumber;
  if (mainProcedureIndex === null && sortedProcedures.length > 0) {
    mainProcedureIndex = 0;
  }

  const [availabilities, setAvailabilities] = useState(formatedAvailabilities);
  let specialtyNames = _.map(specialtys, 'specialty');
  if (customSpecialty) {
    specialtyNames = [...specialtyNames, customSpecialty];
  }
  let max = 1;
  _.map(availabilities, (val, dateIndex) => {
    const arrayLength = val.length;
    if (arrayLength > max) {
      max = arrayLength;
    }
  });
  const dateArray = _.keys(availabilities);
  let isDisablePrevButton = true;
  const firstStartDate = _.get(dateArray, '0', null);
  if (firstStartDate) {
    isDisablePrevButton = isSameOrAfter(dateOrCurrentDate(), dateOrCurrentDate(firstStartDate));
  }
  const timeSelected = (dateString, timeObject) => {
    onTimeSelect({
      dateString,
      timeObject,
    });
  };
  const setFlowTrackProvicerID = () => {
    setOrUpdateLocalData('providerId', providerId);
  };
  const getAndSetDoctorAvailabilities = async (type = 'next') => {
    const startDate = dateArray[0];
    const endDate = dateArray[dateArray.length - 1];
    let nextStartDateObj = null;
    let nextEndDateObj = null;
    switch (type) {
      case 'next':
        nextStartDateObj = dateOrCurrentDate(startDate).add(showAvailabilityOfDays, 'days');
        nextEndDateObj = dateOrCurrentDate(endDate);
        nextEndDateObj.add(showAvailabilityOfDays, 'days');
        break;
      case 'prev':
        nextStartDateObj = dateOrCurrentDate(startDate).subtract(showAvailabilityOfDays, 'days');
        nextEndDateObj = dateOrCurrentDate(startDate);
        nextEndDateObj.subtract(1, 'days');
        break;
      default:
        break;
    }
    if (nextEndDateObj) {
      const datesObj = {};
      // if previous day is current date then add additional day to remove business hours from it
      let extraDay = 0;
      if (isSameDay(nextStartDateObj.clone().subtract(1, 'day'))) {
        extraDay = 1;
        datesObj[dateFormat()] = [];
        nextStartDateObj.subtract(1, 'day');
      }
      const startDateString = dateFormat(nextStartDateObj.clone().subtract(1, 'day').utc());
      const endDateString = dateFormat(endOf('day', nextEndDateObj.clone()).utc());
      // add extra day if removed 1 day previously
      nextStartDateObj.add(extraDay, 'day');
      datesObj[dateFormat(nextStartDateObj)] = [];
      for (let i = 1; i < showAvailabilityOfDays; i++) {
        nextStartDateObj.add(1, 'day');
        datesObj[dateFormat(nextStartDateObj)] = [];
      }
      const response = await getFilteredAvailabilityOfDoctor(
        providerId,
        startDateString,
        endDateString,
        locationId,
        isVirtual,
      ).catch(() => null);
      if (response) {
        const {data} = response;
        const providerAvailabilities = _.get(data, '0.providerAvailabilities', []);
        const patientAppointments = _.get(data, '0.patientAppointments', []);
        const timezone = _.get(data, '0.timezone', null);
        const api_isVirtual = _.get(data, '0.isVirtual', null);
        const api_latestAvailability = _.get(data, '0.latestAvailability', null);
        let nextOpenDate = getLatestAvailabilityDate(
          api_isVirtual,
          api_latestAvailability,
          providerAvailabilities,
          newAppointmentTimeorExAT,
          patientAppointments,
          timezone,
        );
        const updatedAvailabilities = getAvailability(
          providerAvailabilities,
          datesObj,
          newAppointmentTimeorExAT,
          patientAppointments,
          timezone,
          nextOpenDate,
        );
        // if extra day then delete availability of extra day
        if (extraDay) {
          delete updatedAvailabilities[dateFormat()];
        }
        setAvailabilities(updatedAvailabilities);
      }
    }
  };

  let reviewIconList = [];
  if (reviewSourceList) {
    let reviewIconIds = ('' + reviewSourceIds).split(',');
    reviewIconList = _.filter(reviewSourceList, item => {
      return reviewIconIds.includes(_.get(item, 'id', ''));
    });
  }

  return (
    <div
      data-user-id={userId}
      className={[
        `DoctorItem DoctorItemOrg ${mainConditionIndex !== null ? '' : 'rating-content-show'}`,
        isVirtual ? 'isVirtual' : '',
      ].join(' ')}>
      <div className="DC-image">
        <span>
          <MyLink
            onClick={e => {
              setproviderSearchRanking(e);
              setAppointmentSourceStore(1);
              setFlowTrackProvicerID();
            }}
            to={`${profileLink}`}>
            <img src={profileImageLink || images.user} alt="doctor" />
          </MyLink>
        </span>
        {/* {isVirtual ? (
          <span className="video-visit">
            <img src={images.videoBtn} alt="video" /> Video Visit
          </span>
        ) : (
          <span className="person-visit">
            <img src={images.personBtn} alt="In Person" /> In-Person
          </span>
        )} */}
        <span style={{marginTop: '10px', display: 'block'}} className="dc-profile-link doctor-link">
          {!isVirtual && <span>{location.distance.toFixed(1)} mi away</span>}
          <MyLink
            onClick={e => {
              setproviderSearchRanking(e);
              setAppointmentSourceStore(1);
              setFlowTrackProvicerID();
            }}
            className="hideon-mobile"
            to={`${profileLink}`}>
            <img src={images.profile2} alt="" />
            View Profile
          </MyLink>
        </span>
      </div>
      <div className="dc-info-content">
        <div className="dc-information">
          <div className="dc-content">
            <div className="dc-info-inner-content">
              <div className="dc-name">
                <MyLink
                  onClick={e => {
                    setproviderSearchRanking(e);
                    setAppointmentSourceStore(1);
                    setFlowTrackProvicerID();
                  }}
                  to={`${profileLink}`}>
                  {firstName} {lastName}
                  {getSuffixString(suffixes)}
                </MyLink>
              </div>
              <div className="dc-specialist">{specialtyNames.join(', ')}</div>
              <div className="dc-address">
                {location.locationName},{location.address.replace(', ,', ',')}
              </div>
              {!isVirtual && (
                <span className="dc-sm-distance">{location.distance.toFixed(1)} mi away</span>
              )}
            </div>
            <div className="progress-bar-wrapper">
              {sortedConditions.length > 0 && (
                <div className="progress-bar">
                  <div className="pt-category-box">
                    {sortedConditions.map((conditionObj, key) => {
                      return (
                        conditionObj.percentage !== 0 && (
                          <span
                            key={key}
                            className={`box ${mainConditionIndex === key ? 'active' : ''}`}
                            style={{width: `${conditionObj.percentage}%`}}>
                            <span className="dc-symptoms-result">
                              <span className="dc-symptoms-result-inner">
                                <span className="dc-symptoms-inner">
                                  {conditionObj.percentage}% {conditionObj.pain}
                                </span>
                              </span>
                            </span>
                          </span>
                        )
                      );
                    })}
                    {sortedConditions.length >= 5 && (
                      <span
                        className={`box ${mainConditionIndex > 4 ? 'active' : ''}`}
                        style={{width: `10%`}}>
                        <span className="dc-symptoms-result">
                          <span className="dc-symptoms-result-inner">
                            <span className="dc-symptoms-inner">10% Other</span>
                            {sortedConditions.map(
                              (conditionObj, key) =>
                                conditionObj.percentage === 0 && (
                                  <span className="dc-symptoms-inner">{conditionObj.pain} </span>
                                ),
                            )}
                          </span>
                        </span>
                      </span>
                    )}
                  </div>
                  {mainConditionIndex !== null && sortedConditions[mainConditionIndex] && (
                    <div className={`dc-symptoms`}>
                      <div>
                        {sortedConditions[mainConditionIndex].percentage !== 0 &&
                          `${sortedConditions[mainConditionIndex].percentage} % `}
                        {sortedConditions[mainConditionIndex].pain}
                      </div>
                    </div>
                  )}
                  <div className="dc-symptoms-text">Conditions Commonly Treated</div>
                </div>
              )}
            </div>
            <span className="dc-profile-link">
              {/* {!isVirtual && <span>{distance.toFixed(1)} mi away</span>} */}
              <MyLink
                onClick={e => {
                  setproviderSearchRanking(e);
                  setAppointmentSourceStore(1);
                  setFlowTrackProvicerID();
                }}
                to={`${profileLink}`}>
                <img src={images.profile2} alt="" /> View Profile
              </MyLink>
            </span>
          </div>
          <div className="dc-review">
            <span className="reviwe-content">
              <span>
                <img src={images.star} alt="star" className="star" />
                <span className={['rating', totalReview > 0 ? '' : 'grey'].join(' ')}>
                  {totalRating}
                </span>
                <span className="info-wrapper">
                  <img src={images.info} alt="info" className="info" />
                  <span className="info-content">
                    This is a kScore - an aggregate of reviews from top review websites in our
                    industry.
                  </span>
                </span>
              </span>
              <span>
                {_.map(reviewIconList, (data, index) => (
                  <img
                    src={data.logoUrl}
                    key={index}
                    alt="info"
                    className="social-icon"
                    title={data.title}
                  />
                ))}
              </span>
              <span>
                <span className="rating-text">({totalReview})</span>
              </span>
            </span>
          </div>
        </div>
        <div className="dc-appointment-time">
          <div className="appointment-inner-block">
            <div className="table-wrapper">
              <div className="table-content">
                <div className="book-now">
                  <a target={'_blank'} href={bookNowLink} className="bookaappointment">
                    <img width={20} src={images.calender} />
                    <span>Book Now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorItemOrg;
