import React, { useState, useEffect, useCallback } from 'react';
import Video from 'twilio-video';

import VideoParticipant from 'components/VideoParticipant';

const VideoRoom = ({ roomName, token, handleLogout }) => {
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);    
    // const [connecting, setConnecting] = useState(false);
    const [videoMute, setVideoMute] = useState(false);
    const [audioMute, setAudioMute] = useState(false);
    const [error, setError] = useState(null);

    const remoteParticipants = participants.map(participant => (
        <VideoParticipant key={participant.sid} participant={participant} />
    ));

    useEffect(() => {
        // When a Participant joins the Room
        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        // When a Participant leaves the Room
        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };

        Video.connect(token, {
            name: roomName
        }).then(room => {
            // setConnecting(true);
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        }).catch(error => {
            // setConnecting(false);
            console.log("twilio->error",error);
            setError(error);
        });

        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
    }, [roomName, token]);

    const muteUnmuteAudio = () => {
        if (audioMute) {
            room.localParticipant.audioTracks.forEach(publication => {
                publication.track.enable();
            });
            setAudioMute(false);
        } else {
            room.localParticipant.audioTracks.forEach(publication => {
                publication.track.disable();
            });
            setAudioMute(true);
        }
    }

    const muteUnmuteVideo = () => {
        if (videoMute) {
            room.localParticipant.videoTracks.forEach(publication => {
                publication.track.enable();
            });
            setVideoMute(false);
        } else {
            room.localParticipant.videoTracks.forEach(publication => {
                publication.track.disable();
            });
            setVideoMute(true);
        }
    }

    const handleRoomLogout = useCallback(event => {
        setRoom((prevRoom) => {
            if (prevRoom) {
                prevRoom.localParticipant.tracks.forEach((trackPub) => {
                    trackPub.track.stop();
                });
                prevRoom.disconnect();
                handleLogout();
            }else{
                handleLogout();
            }
            return null;
        });
    }, [handleLogout]);

    return (
        <div className="room">
            <h2>Room: {roomName}</h2>
            <div className="video-action">
                <button onClick={handleRoomLogout}>Log out</button>
                <button onClick={muteUnmuteAudio}>{audioMute ? 'Unmute Audio' : 'Mute Audio'}</button>
                <button onClick={muteUnmuteVideo}>{videoMute ? 'Unmute Video' : 'Mute Video'}</button>
            </div>
            <div className="local-participant">
                {room ? (
                    <VideoParticipant
                        key={room.localParticipant.sid}
                        participant={room.localParticipant}
                    />
                ) : (
                        ''
                    )}
            </div>
            <h3>Remote Participants</h3>
            <div className="remote-participants">{remoteParticipants}</div>
            <div className="error-box">
                <h4>Error Console</h4>
                <div>
                    {error ? error.message : null}
                </div>
            </div>
        </div>
    );
};


export default VideoRoom;