import React, {useState} from 'react';
import _ from 'lodash';
import images from 'data/images';
import fileUploadService from 'services/fileUpload';
import './ProviderPhotos.css';
import MyImageCrop from 'components/MyImageCrop';

const {
  fileUpload,
  removeFileFromServer,
  uploadProvidePhotos,
  removeProvidePhoto,
  uploadProviderVideos,
  removeProviderVideo,
  adminUploadProviderPhoto,
  adminUploadPhotos,
  adminRemovePhotos,
  adminUploadVideos,
  adminRemoveVideos,
} = fileUploadService;
const uploadProfilePhotoUrl = 'provider/profile';

const ProviderPhotos = props => {
  const {
    data,
    backToProviderList,
    onError,
    onProfileImageChange,
    onAdditionalPhotosUpload,
    onAdditionalPhotosRemove,
    onAdditionalVideoUpload,
    onAdditionalVideoRemove,
    isAdmin,
    currentUserId,
    currentProviderId,
  } = props;
  const {name, profileImageUrl, providerPhotos, providerVideos} = data;
  // file input
  const [fileObj, setFile] = useState({
    file: null,
    preview: profileImageUrl,
  });
  const {files, preview} = fileObj;

  // Functions for Profile photo
  const handleProfilePhotoSelection = async blobFile => {
    if (blobFile) {
      let response = '';
      if (isAdmin) {
        response = await adminUploadProviderPhoto(blobFile, currentUserId, false).catch(onError);
      } else {
        response = await fileUpload(blobFile, uploadProfilePhotoUrl, preview).catch(onError);
      }
      if (response) {
        const {data} = response;
        const {fileUrl} = data;
        setFile({
          file: blobFile,
          preview: fileUrl,
        });
        onProfileImageChange(fileUrl);
      }
    }
    return null;
  };

  const validateUploadedPhoto = file => {
    if (
      ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(
        file.length > 0 ? file[0].type : '',
      )
    ) {
      return true;
    } else {
      onError({message: 'Only JPG or PNG allowed'});
    }
    return false;
  };

  const removeProfilePhoto = async () => {
    let response = '';
    if (isAdmin) {
      response = await adminUploadProviderPhoto(null, currentUserId, true).catch(onError);
    } else {
      response = await removeFileFromServer(uploadProfilePhotoUrl, preview).catch(onError);
    }
    if (response) {
      onProfileImageChange(null);
      setFile({
        file: null,
        name: null,
        preview: null,
      });
    }
  };

  // Functions for additional photos
  const validatePhotos = files => {
    let isAllValidFiles = true;
    _.map(files, file => {
      if (!['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(file.type)) {
        isAllValidFiles = false;
      }
    });
    if (!isAllValidFiles) {
      onError({message: 'Only JPG or PNG allowed'});
    }
    return isAllValidFiles;
  };

  const handleAdditionalPhotosSelection = async event => {
    const {files} = event.target;
    if (files.length > 0) {
      if (!validatePhotos(files)) {
        return;
      }
      let response = '';
      if (isAdmin) {
        response = await adminUploadPhotos(files, currentProviderId).catch(onError);
      } else {
        response = await uploadProvidePhotos(files).catch(onError);
      }
      if (response) {
        const {data} = response;
        onAdditionalPhotosUpload(data);
      }
    }
    return null;
  };

  const handleRemoveProvidePhoto = async photoId => {
    let response = '';
    if (isAdmin) {
      response = await adminRemovePhotos(currentProviderId, photoId).catch(onError);
    } else {
      response = await removeProvidePhoto(photoId).catch(onError);
    }

    if (response) {
      onAdditionalPhotosRemove(photoId);
    }
  };

  const handleFileSelection = event => {
    const {files: filesInstance} = event.target;
    if (filesInstance) {
      const isValid = validateUploadedPhoto(filesInstance);
      if (isValid) {
        setFile(prevState => {
          return {
            ...prevState,
            files: filesInstance,
          };
        });
      }
    }
  };

  const handleVideoSelection = async event => {
    const {files} = event.target;
    if (files.length > 0) {
      if (!validateVideos(files)) {
        return;
      }
      let response = '';
      if (isAdmin) {
        response = await adminUploadVideos(files, currentProviderId).catch(onError);
      } else {
        response = await uploadProviderVideos(files).catch(onError);
      }
      if (response) {
        const {data} = response;
        onAdditionalVideoUpload(data);
      }
    }
    return null;
  };

  const validateVideos = files => {
    let isAllValidFiles = true;
    _.map(files, file => {
      if (!['video/mp4', 'video/avi', 'video/x-msvideo', 'video/x-ms-wmv'].includes(file.type)) {
        isAllValidFiles = false;
      }
    });
    if (!isAllValidFiles) {
      onError({message: 'Only MP4, AVI or WMV allowed'});
    }
    return isAllValidFiles;
  };

  const handleRemoveVideo = async videoId => {
    let response = '';
    if (isAdmin) {
      response = await adminRemoveVideos(currentProviderId, videoId).catch(onError);
    } else {
      response = await removeProviderVideo(videoId).catch(onError);
    }
    if (response) {
      onAdditionalVideoRemove(videoId);
    }
  };

  return (
    <div className="ProviderPhotos">
      <div className="provider-logo">
        <span className="logo-img">
          <img src={preview || images.user} alt="" />
        </span>
        <MyImageCrop
          cropConfig={{
            aspect: 200 / 300,
            // width: 200,
            // height: 300,
            // unit: 'px',
           
          }}
          files={files}
          afterCropImage={handleProfilePhotoSelection}
          onError={onError}
          cropextraProps={{minHeight:300,minWidth:200}}
          cropElementProps={{minHeight:600,minWidth:400}}
        />
        <div className="logo-inner-content">
          <div className="name">{name}</div>
          <span className="label">Primary Photo Patients will see</span>
          <span className="image-size">Min 200px x 300px jpg or png</span>
          <span className="image-size">Better Photo = More Bookings</span>
          <div className="logo-content-action">
            <div className="fileupload">
              <span className="fileupload-btn">
                <input
                  type="file"
                  onClick={event => {
                    event.target.value = null;
                  }}
                  accept="image/*"
                  onChange={handleFileSelection}
                />
                <button>Change Photo</button>
              </span>
            </div>
            {preview && (
              <button onClick={removeProfilePhoto} className="circle-btn">
                Remove
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="additional-photo">
        <div className="h3">Upload Additional Photos</div>
        <div className="sub-txt">Drag and drop or tap to upload</div>
        <div className="photo-upload-row">
          <div className="filephoto-upload">
            <div className="fileupload-input">
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleAdditionalPhotosSelection}
                onClick={event => {
                  event.target.value = null;
                }}
              />
              <img src={images.uploadCloud} alt="cloudIcom" />
            </div>
          </div>
          {providerPhotos.map(imageObj => {
            return (
              <div className="photo-col" key={imageObj.id}>
                <span className="photo-img">
                  <img src={imageObj.imageUrl || images.logo} alt="upload-img" />
                </span>
                <span onClick={() => handleRemoveProvidePhoto(imageObj.id)} className="remove-link">
                  Remove
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="additional-video">
        <div className="h3">Upload Videos</div>
        <div className="sub-txt">Drag and drop or tap to upload videos up to 20 MB in size</div>
        <div className="photo-upload-row">
          <div className="filephoto-upload">
            <div className="fileupload-input">
              <input
                type="file"
                multiple
                onChange={handleVideoSelection}
                accept="video/*"
                onClick={event => {
                  event.target.value = null;
                }}
              />
              <img src={images.uploadCloud} alt="cloudIcom" />
            </div>
          </div>
          {providerVideos.map(obj => {
            return (
              <div className="photo-col" key={obj.id}>
                <span className="photo-img">
                  <video>
                    <source src={obj.videoFileUrl || images.logo} alt="video" />
                  </video>
                </span>
                <span onClick={() => handleRemoveVideo(obj.id)} className="remove-link">
                  Remove
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProviderPhotos;
