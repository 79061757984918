import React, { useState, useContext } from 'react';
import _ from 'lodash';
import CircleButton from 'components/CircleButton';
import TagsInput from 'components/FormControls/TagsInput';
import RootContext from 'context/RootContext';
import validators from 'data/validators';
import './AboutYouAsProviderStepTwo.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const { __required, __minLength } = validators;
const { Analytics } = analyticsData;

const AboutYouAsProviderStepTwo = props => {
  const rootContext = useContext(RootContext);
  const { specialties, suffixes } = rootContext;
  const { nextStep, data } = props;
  const values = { ...data };
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const { values: formValues, errors: formErrors } = formData;

  // Validation functions
  const __validateSpecialty = (value = null) => {
    const fValue = value !== null ? value : formValues['specialties'];
    if (!__required(fValue)) {
      return 'Specialty is required';
    }
    if (!__minLength(fValue, 1)) {
      return 'Specialty is required';
    }
    let checkEnabled = fValue.find((ii) => !ii.doNotDisplay)
    if (!checkEnabled) {
      return 'Required at least one specialty for your profile.';
    }
    return null;
  };
  const __validateSuffix = (value = null) => {
    const fValue = value !== null ? value : formValues['suffixes'];
    if (!__required(fValue)) {
      return 'Professional Suffix is required';
    }
    if (!__minLength(fValue, 1)) {
      return 'Professional Suffix is required';
    }
    return null;
  };
  const __validateForm = () => {
    const validSpecialty = !__validateSpecialty();
    const validSuffix = !__validateSuffix();
    return validSpecialty && validSuffix;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const { name } = event.target;
    let { value } = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'specialties':
        errorObject = {
          [name]: __validateSpecialty(value),
        };
        break;
      case 'suffixes':
        errorObject = {
          [name]: __validateSuffix(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleSubmit = () => {
    Nux.sendEvent(
      Analytics.Category.DoctorRegistration,
      Analytics.Actions.Click,
      'aboutProviderStep2',
    );
    nextStep(formValues);
  };

  const onAdd = (name, specialty) => {
    const customEvent = {
      target: {
        value: [...formValues[name], specialty],
        name,
      },
    };
    changeHandler(customEvent);
  };
  const onRemove = (name, specialty) => {
    const updatedSpecialities = [...formValues[name]];
    if (name == 'specialties')
      _.remove(updatedSpecialities, value => value.id == specialty.id);
    else
      _.remove(updatedSpecialities, value => value === specialty);
    const customEvent = {
      target: {
        value: updatedSpecialities,
        name,
      },
    };
    changeHandler(customEvent);
  };
  const onInputBlur = (name, selectedValues) => {
    const customEvent = {
      target: {
        value: selectedValues,
        name,
      },
    };
    changeHandler(customEvent);
  };
  if (!specialties || !suffixes) {
    return null;
  }
  return (
    <div className="AboutYouAsProviderStepTwo">
      <div className="h3">About you as a provider</div>
      <div className="paragraph">
        You may receive a call from our team to confirm this information
      </div>
      <form className="signup-form">
        <TagsInput
          error={formErrors['specialties']}
          label="Specialty"
          options={specialties}
          labelIndex="specialty"
          valueIndex="id"
          selectedValues={formValues['specialties']}
          onAdd={specialty => onAdd('specialties', specialty)}
          onRemove={specialty => onRemove('specialties', specialty)}
          onInputBlur={selectedList => onInputBlur('specialties', selectedList)}
          showSwitchToggle={true}
          subLabel={'Switch off to hide the specialty name from your profile. Patients can still find your visit reasons in search.'}
        />
        <TagsInput
          className="suffix-tag-input"
          error={formErrors['suffixes']}
          label="Professional Suffix"
          options={suffixes}
          labelIndex="suffix"
          valueIndex="id"
          selectedValues={formValues['suffixes']}
          onAdd={specialty => onAdd('suffixes', specialty)}
          onRemove={specialty => onRemove('suffixes', specialty)}
          onInputBlur={selectedList => onInputBlur('suffixes', selectedList)}
        />
        <div className="signup-btn">
          <CircleButton disabled={!isValidForm} onClick={handleSubmit} btnType="whiteButton">
            Continue
          </CircleButton>
        </div>
      </form>
    </div>
  );
};

export default AboutYouAsProviderStepTwo;
