import React, {Component} from 'react';
import Layout from 'layout';
import PatientSignUpStepOne from 'components/Patient/Auth/SignUp/PatientSignUpStepOne';
import PatientSignUpStepTwo from 'components/Patient/Auth/SignUp/PatientSignUpStepTwo';
import patientService from 'services/patient';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import contextHelper from 'context/contextHelper';
import EmailInputModal from 'components/Patient/ConfirmAppointment/EmailInputModal';
import _ from 'lodash';
import './PatientSignUp.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import {SocialTermsofUse} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/SocialTermsofUse';
import {Passwordrequired} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/Passwordrequired';
import {SocialGenderDobMain} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/SocialGenderDob';
import PrompOTPModal from 'components/Patient/ConfirmAppointment/PrompOTPModal';
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData, blankModalData} = modalFunctions;
const {patientSignUp, patientSocialLogin} = patientService;
const {Analytics} = analyticsData;

class PatientSignUp extends Component {
  static contextType = RootContext;
  state = {
    currentStep: 1,
    step1Data: {email: '', password: ''},
    step2Data: {firstName: '', lastName: '', dateOfBirth: '', gender: '', phonenumber: ''},
  };
  authContext = null;

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  handleSocialLoginSuccess = async (provider, user, email = null, aditionalInfo = {}) => {
    let passUser = {};

    if (provider === 'apple') {
      passUser = {
        firstName: user.firstName ? user.firstName : user?.user?.name?.firstName,
        lastName: user.firstName ? user.firstName : user?.user?.name?.lastName,
        email: email ? email : user?.user?.email,
      };
    }
    const {setGlobal} = this.context;
    const {updateAuthStatus} = this.authContext;
    const {history} = this.props;

    // if(Object.keys(aditionalInfo).length===0){
    //   aditionalInfo=await SocialTermsofUse({context:this.context,user:passUser,provider:provider})
    //   if(!aditionalInfo)
    //   return false
    // }
    Nux.sendEvent(Analytics.Category.PatientSignUp, Analytics.Actions.Click, 'socialSignUp');

    let data = {
      type: provider,
      idToken: '',
      accessToken: '',
    };
    if (aditionalInfo.dob) data.dateOfBirth = aditionalInfo.dob;
    // console.log("aditionalInfo",aditionalInfo);
    if (aditionalInfo.genderId) {
      data.genderId = aditionalInfo.genderId;
    }
    if (user.password) {
      data.password = user.password;
    }
    switch (provider) {
      case 'google':
        const {access_token, id_token} = user;
        data = {
          ...data,
          idToken: id_token,
          accessToken: access_token,
        };

        break;
      case 'facebook':
        const {accessToken, userID} = user;
        data = {
          ...data,
          idToken: userID,
          accessToken: accessToken,
          email: email,
        };
        break;
      case 'apple':
        const {authorization, user: AppleUser} = user;
        let firstName = aditionalInfo?.user?.firstName;
        // firstName=!firstName?aditionalInfo?.user?.firstName:firstName;
        let lastName = aditionalInfo?.user?.lastName;
        //lastName=!lastName?aditionalInfo?.user?.lastName:lastName
        let passemail = email ? email : aditionalInfo?.user?.email;
        data = {
          ...data,
          accessToken: authorization.code,
          idToken: authorization.id_token,
          email: passemail,
          firstName: firstName,
          lastName: lastName,
        };
        // console.log("data",data);
        break;
      default:
        break;
    }
    //console.log("data",data);
    //return
    try {
      const response = await patientSocialLogin(data);
      if (!_.isEmpty(response.data)) {
        const {data} = response;
        const {accessToken, refreshToken} = data;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        updateAuthStatus(true);
        history.push('/');
      } else {
        setGlobal('modal', successModalData(response.message));
      }
    } catch (ex) {
      const {customCode, message} = ex;
      const callback = email => {
        if (user.password) delete user.password;
        this.handleSocialLoginSuccess(provider, user, email, aditionalInfo);
      };
      if (customCode === 'socialSignUpEmailRequired') {
        setGlobal(
          'modal',
          blankModalData({
            CustomComponent: EmailInputModal,
            customComponentProps: {callback: callback, message: message},
            modalWrapperClass: 'mega-modal',
          }),
        );
      } else if (customCode === 'passwordrequired') {
        let Prresponse = await Passwordrequired({
          context: this.context,
          user: passUser,
          message: message,
          provider: provider,
        });
        let passUsertemp = {...user, password: Prresponse.password};
        this.handleSocialLoginSuccess(provider, passUsertemp, email, aditionalInfo);
        // if(Prresponse){
        //   const callbackAfterEmail = email => {
        //     this.handleSocialLoginSuccess(provider, user, email);
        //   };
        //   if (!_.isEmpty(Prresponse.data)) {
        //     const {data} = Prresponse;
        //     const {accessToken, refreshToken} = data;
        //     localStorage.setItem('accessToken', accessToken);
        //     localStorage.setItem('refreshToken', refreshToken);
        //     updateAuthStatus(true);
        //     setTimeout(() => {
        //       history.push('/');
        //     }, 1000);
        //     return false
        //   } else {
        //     setGlobal(
        //       'modal',
        //       blankModalData({
        //         CustomComponent: EmailInputModal,
        //         customComponentProps: {callback: callbackAfterEmail},
        //         modalWrapperClass: 'mega-modal',
        //       }),
        //     );
        //     return false
        //   }
        // }
      } else if (customCode == 'genderanddobrequiredSocialAccount') {
        let gdinfo = await SocialGenderDobMain({
          context: this.context,
          user: passUser,
          message: message,
          provider: provider,
        });
        let passUsertemp = {...user};
        this.handleSocialLoginSuccess(provider, passUsertemp, email, {
          ...aditionalInfo,
          dob: gdinfo.dob,
          genderId: gdinfo.genderId,
        });
      } else if (message === 'Date of birth is required') {
        let passUser = {};
        if (provider === 'apple') {
          passUser = {
            firstName: user.firstName ? user.firstName : user?.user?.name?.firstName,
            lastName: user.firstName ? user.firstName : user?.user?.name?.lastName,
            email: email ? email : user?.user?.email,
          };
        }
        let aditionalInfo = await SocialTermsofUse({
          context: this.context,
          user: passUser,
          provider: provider,
        });
        if (!aditionalInfo) return false;

        if (provider === 'apple' && aditionalInfo?.user?.firstName) {
          user.user = {
            name: {firstName: aditionalInfo.user.firstName, lastName: aditionalInfo.user.lastName},
            email: aditionalInfo?.user?.email,
          };
        }
        this.handleSocialLoginSuccess(provider, user, email, {
          ...aditionalInfo,
          dob: aditionalInfo.dob,
          genderId: aditionalInfo.genderId,
        });
        return;
      } else {
        this.handleError(ex);
      }
    }
  };

  handleSocialLoginFailure = (provider, err) => {
    console.log(provider, err);
  };
  callAfterPhoneNumberOtp = response => {
    const {updateAuthStatus} = this.authContext;
    const {data} = response;
    const {accessToken, refreshToken} = data;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    updateAuthStatus(true);
  };
  changeStep = async newStepData => {
    const {history} = this.props;
    const {setGlobal} = this.context;
    const {step1Data, currentStep} = this.state;
    if (currentStep === 1) {
      this.setState({
        step1Data: newStepData,
        currentStep: currentStep + 1,
      });
    } else {
      const allFormValuse = {...step1Data, ...newStepData};
      const response = await patientSignUp(allFormValuse).catch(this.handleError);
      if (response) {
        if (response && response.data && response.data.action === 'promptOtp') {
          // this.setState({
          //   tempUserId: signUpResponse.data.userId,
          //   showPromptOtp: true,
          // });
          setGlobal(
            'modal',
            blankModalData({
              CustomComponent: PrompOTPModal,
              customComponentProps: {
                state: null,
                tempUserId: response.data.userId,
                callAfterPhoneNumberOtp: this.callAfterPhoneNumberOtp,
              },
              closeOnBGClick: false,
            }),
          );
        } else {
          const {message} = response;
          setGlobal(
            'modal',
            successModalData(message, {
              callBackOnClose: () => {
                history.replace('/patient/login');
              },
            }),
          );
        }
      } else {
        this.setState({
          step2Data: newStepData,
          currentStep: 1,
        });
      }
    }
  };

  _currentStepComponent = () => {
    const {step1Data, step2Data, currentStep} = this.state;
    let component = null;
    switch (currentStep) {
      case 1:
        component = (
          <PatientSignUpStepOne
            data={step1Data}
            nextStep={this.changeStep}
            handleSocialLoginSuccess={this.handleSocialLoginSuccess}
            handleSocialLoginFailure={this.handleSocialLoginFailure}
          />
        );
        break;
      case 2:
        component = <PatientSignUpStepTwo data={step2Data} nextStep={this.changeStep} />;
        break;
      default:
        break;
    }
    return component;
  };

  render() {
    const currentStepComponent = this._currentStepComponent();
    return (
      <Layout>
        {contextHelper.AuthContextConsumer(this)}
        <div className="PatientSignUp">
          <div className="ca-container">
            <h1 className="h3">Create Account</h1>
            {currentStepComponent}
          </div>
        </div>
      </Layout>
    );
  }
}

export default PatientSignUp;
