import React, {Component} from 'react';
import AutoLogoutInactiveWarning from './AutoLogoutInactiveWarning';
import RootContext from 'context/RootContext';
import userService from 'services/user';
import moment from 'moment';
import clearLocalStorageOnlogout from 'data/localStorageClear';
import helper from 'data/helper';
import cookieHelper from 'data/cookie.helper';
const BASENAME = 'app';
const {getLocationAwait}=helper
const {newDeviceNotification} = userService;
var time;
var callDone = false;
const limitMin = 15;
const { removeUserIdCookie } = cookieHelper;
class ActiveWatcher extends Component {
  static contextType = RootContext;

  componentDidMount() {
    let rememberMe = false;
    if (!localStorage.getItem('rd')) {
      localStorage.setItem('rd', 1);
      this.callEmailApi(this.props.auth.email,`${this.props.auth.firstName} ${this.props.auth.lastName}`);
    }
    if (
      localStorage.getItem('rememberMe') &&
      (localStorage.getItem('rememberMe') === true || localStorage.getItem('rememberMe') === 'true')
    )
      rememberMe = true;
    // if(localStorage.getItem('expiryTimeDuration')&& localStorage.getItem('expiryTimeDuration') !==""){
    //   limitMin=parseInt(localStorage.getItem('expiryTimeDuration'))/60/1000
    // }
    //console.log('rememberMe', rememberMe);
    if (!rememberMe) {
      var now = moment(new Date()); //todays date

      var end = moment(localStorage.getItem('lastActiveTime'));
      var duration = moment.duration(now.diff(end));
      var Minutes = duration.asMinutes();

      if (localStorage.getItem('lastActiveTime') && Minutes > limitMin) {
        let {roles} = this.props.auth;
        let temprole = 1;

        if (roles.length > 0) {
          temprole = roles[0].id;
        }
        clearLocalStorageOnlogout()
        removeUserIdCookie()
        window.location.href =
          temprole === `2`
            ? `/${BASENAME}/patient/login`
            : temprole === `1`
            ? `/${BASENAME}/admin/login`
            : `/${BASENAME}`;
      } else {
        window.addEventListener('storage', this.localStorageUpdated);
        window.onload = this.resetTimer;

        document.addEventListener('mousemove', this.resetTimer);
        document.addEventListener('keypress', this.resetTimer);

        this.resetTimer();
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('storage', this.localStorageUpdated);
    document.removeEventListener('mousemove', this.resetTimer);
    document.removeEventListener('keypress', this.resetTimer);

    clearTimeout(time);
  }
  callEmailApi=async(email,name)=>{
    let ipdata=await getLocationAwait()
    //Call Email API
    newDeviceNotification({
      email: email,
      name: name,
      ipAddress: ipdata?.loc_details?.ip,
      location: ipdata?.loc_details?.region_name,
      country: ipdata?.loc_details?.country_name,
    });
  }
  localStorageUpdated = () => {
    //console.log(123123);
    let {setGlobal} = this.context;
    let {roles} = this.props.auth;
    let temprole = 1;

    if (roles.length > 0) {
      temprole = roles[0].id;
    }
    clearTimeout(time);

    // if (localStorage.getItem('lastActivityTimeSS')) {
    //   if (localStorage.getItem('lastActivityTimeSS') !== '') {
    //     var durationSS = moment.duration(
    //       moment().diff(moment(localStorage.getItem('lastActivityTimeSS'))),
    //     );
    //     var MinutesSS = durationSS.asMinutes();
    //     if (MinutesSS > -2) {
    //       if (loopUserCall === false) {
    //         loopUserCall = true;
    //         getLoggedInUser().then(response=>{
    //           let {data: user} = response;
    //       // set initial viewUserId if user can switch to other user

    //       //this.inactivityTime()

    //       if (user.lastActivityTime) {

    //           localStorage.setItem("lastActivityTimeSS",moment(user.lastActivityTime.replace('T', ' '))
    //           .local()
    //           .add(user.expiryTimeDuration, 'millisecond')
    //           .format('YYYY-MM-DD HH:mm:ss'))

    //       }
    //             });
    //       }
    //     } else {
    //       loopUserCall = false;
    //     }
    //    // console.log('MinutesSS', MinutesSS);
    //   }
    // }
    if (callDone === false) {
      //console.log(`time->${moment().format()}`,((limitMin-1) * 60 * 1000));
      time = setTimeout(async () => {
        //console.log(`time->${moment().format()}`,((limitMin-1) * 60 * 1000));
        callDone = true;
        var now = moment(new Date()); //todays date

        var end = moment(localStorage.getItem('lastActiveTime')); // another date
        //console.log("end",end);

        var duration = moment.duration(now.diff(end));
        var Minutes = duration.asMinutes();
        // console.log("Minutes",Minutes);
        if (isNaN(Minutes)) {
          clearLocalStorageOnlogout()
          removeUserIdCookie()
          window.location.href =
            temprole === `2`
              ? `/${BASENAME}/patient/login`
              : temprole === `1`
              ? `/${BASENAME}/admin/login`
              : `/${BASENAME}/login`;
        }
        // console.log("Minutes",parseInt(Minutes))
        // console.log("temprole",temprole)
        if (Minutes > limitMin - 1) {
          let responce = await AutoLogoutInactiveWarning({
            setGlobal: setGlobal,
            limitMin: limitMin,
          });
          callDone = false;
          if (responce) {
            //   getLoggedInUser().then(response=>{
            //     console.log("response",response);
            //     let {data: user} = response;
            // // set initial viewUserId if user can switch to other user

            // //this.inactivityTime()

            // if (user.lastActivityTime) {

            //     localStorage.setItem("lastActivityTimeSS",moment(user.lastActivityTime.replace('T', ' '))
            //     .local()
            //     .add(user.expiryTimeDuration, 'millisecond')
            //     .format('YYYY-MM-DD HH:mm:ss'))

            // }
            //       });
            clearTimeout(time);
            this.resetTimer();
            this.localStorageUpdated();
          } else {
            // now = moment(new Date()); //todays date
            // end = moment(localStorage.getItem('lastActiveTime')); // another date
            // duration = moment.duration(now.diff(end));
            // Minutes = duration.asMinutes();
            // //console.log("MinutesMinutes",Minutes);
            // //console.log("ceil",Math.ceil(Minutes));
            // if ((Math.ceil(Minutes) > limitMin) || isNaN(Minutes)) {
              clearLocalStorageOnlogout()
              removeUserIdCookie()
              window.location.href =
                temprole === `2`
                  ? `/${BASENAME}/patient/login`
                  : temprole === `1`
                  ? `/${BASENAME}/admin/login`
                  : `/${BASENAME}/login`;
            // } else {
            //   callDone = false;
            //   this.localStorageUpdated();
            //   //this.resetTimer('Y', Minutes);
            // }
          }
        } else {
          callDone = false;
          this.localStorageUpdated();
          // this.resetTimer('Y', Minutes);
        }
      }, (limitMin - 1) * 60 * 1000);
    }
  };
  resetTimer = (notset = false) => {
    ///console.log("notset",notset);
    //if (notset !== 'Y') {
    if (callDone === false) {
      localStorage.setItem('lastActiveTime', moment().format());
      window.dispatchEvent(new Event('storage'));
    }
    // }
  };
  render() {
    return <></>;
  }
}

export default ActiveWatcher;
