import React from 'react';
import './ProgressBar.css';

const ProgressBar = props => {
  const {percentage = 10} = props;
  return (
    <span className="ProgressBar">
      <span className="progress-bar" style={{width: `${percentage}%`}}></span>
    </span>
  );
};

export default ProgressBar;
