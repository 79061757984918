import axiosInstance from 'config/axios';
import _ from 'lodash';

const getAllStaff = (data) => {
  return axiosInstance.post('v1/admin/get-staff',data);
};
const addStaff = (data) => {
  return axiosInstance.post('v1/admin/staff',data);
};
const updateStaffData = (data) => {
  return axiosInstance.put(`v1/admin/staff`,data);
};
const deleteStaff = (staffID,PracticeID) => {
  return axiosInstance.delete(`v1/admin/staff/${staffID}/${PracticeID}`);
};
export default {
  getAllStaff,
  addStaff,
  updateStaffData,
  deleteStaff
};
