import React, { useContext, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import Textarea from 'components/FormControls/Textarea';
import Checkbox from 'components/FormControls/Checkbox';
import modalConfig from 'modals/modalConfig';
import AddPatientModal from '../AddPatientModal';
import RootContext from 'context/RootContext';
import AuthContext from 'context/AuthContext';
import masterService from 'services/master';
import validators from 'data/validators';
import allRegex from 'data/regex';
import selectOptions from 'data/selectOptions';
import { Link } from 'react-router-dom';
import './ReviewConfirm.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import Upload from 'components/FormControls/Upload';
import helper from 'data/helper';
import cookieHelper from 'data/cookie.helper';
const {getCookie} = cookieHelper;
const { validateFile, getAppointmentSourceStore } = helper;
const { modalFunctions } = modalConfig;
const { blankModalData, infoModalData, errorModalData } = modalFunctions;
const { getAllInsurancePlan } = masterService;
const { exceptDigitsRegex } = allRegex;
const { __required, __phoneNumber, _scrollToView } = validators;
const { insuranceDefaultOption } = selectOptions;
const { Analytics } = analyticsData;

const ReviewConfirm = props => {
  const { state, bookAppointment, onSavePatient, onToggleAppointment } = props;
  const { dateAndTimeObject, answers, doctorDetail, flowType } = state;
  const { dateString, timeObject } = dateAndTimeObject;
  const localAppointmentDateTime = moment(dateString + ' ' + timeObject.time, 'YYYY-MM-DD h:mm a');
  const utcDateTimeString = localAppointmentDateTime.clone().utc().format('YYYY-MM-DD HH:mm:ss');
  const authContext = useContext(AuthContext);
  const rootContext = useContext(RootContext);
  const { setGlobal, insurancePlans } = rootContext;
  const familyMembers = useMemo(() => {
    return authContext.auth.familyMembers || [];
  }, [authContext.auth.familyMembers]);
  const phoneNumber = _.get(authContext, 'auth.phoneNumber', '');
  let insuranceCompanyId = _.get(authContext, 'auth.insurance.medicalCompanyId', '');
  let insurancePlanId = _.get(authContext, 'auth.insurance.medicalPlanId', '');
  let insuranceMemberId = _.get(authContext, 'auth.insurance.medicalMemberId', '');
  let insuranceType = 0
  if (authContext && authContext.auth && authContext.auth.insurance) {
    const dentistSpecialist = [22, 28, 45, 44, 51, 49];
    let dentistSelect = doctorDetail.specialties.find(D =>
      dentistSpecialist.includes(parseInt(D.id)),
    );
    if (dentistSelect) {
      insuranceType = 1
      insuranceCompanyId = _.get(authContext, 'auth.insurance.dentalCompanyId', '');
      insurancePlanId = _.get(authContext, 'auth.insurance.dentalPlanId', '');
      insuranceMemberId = _.get(authContext, 'auth.insurance.dentalMemberId', '');
    }

    const VisionSpecialist = [];
    let visionSelect = doctorDetail.specialties.find(D =>
      VisionSpecialist.includes(parseInt(D.id)),
    );
    if (visionSelect) {
      insuranceType = 2
      insuranceCompanyId = _.get(authContext, 'auth.insurance.visionCompanyId', '');
      insurancePlanId = _.get(authContext, 'auth.insurance.visionPlanId', '');
      insuranceMemberId = _.get(authContext, 'auth.insurance.visionMemberId', '');
    }
  }
  const [IntialInsuranceData, setIntialInsuranceData] = useState({
    insuranceCompanyId: insuranceCompanyId, insurancePlanId: insurancePlanId, insuranceMemberId: insuranceMemberId
  })
  const isVirtual = _.get(doctorDetail, 'isVirtual', false);
  const [isSaveBtnClicked, setIsSaveBtnClicked] = useState(false);
  useEffect(() => {
    const getAndSetInsurancePlans = async () => {
      if (!insurancePlans) {
        const insurancePlansResponse = await getAllInsurancePlan().catch(() => null);
        if (insurancePlansResponse) {
          const { data } = insurancePlansResponse;
          setGlobal('insurancePlans', data);
        }
      }
    };
    getAndSetInsurancePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultStateData = {
    providerAvailabilityId: timeObject.providerAvailabilityId,
    patientId: familyMembers.length > 0 ? familyMembers[0].id : null,
    appointmentDateTime: utcDateTimeString,
    flowType,
    flowResponse: answers,
    isExistingPatient: isVirtual === false ? false : null,
    phoneNumber,
    insuranceCompanyId,
    insurancePlanId,
    insuranceMemberId,
    verifyInsurance: true,
    note: '',
    isSaveToProfile: false,
    // insuranceCardFrontPhoto:null,
    //  insuranceCardBackPhoto:null,
    insuranceType: 0
  };

  const [formData, setFormData] = useState({
    values: defaultStateData,
    errors: {},
  });
  const { values: formValues, errors: formErrors } = formData;

  useEffect(() => {
    setIntialInsuranceData({
      insuranceCompanyId: insuranceCompanyId,
      insurancePlanId: insurancePlanId,
      insuranceMemberId: insuranceMemberId
    })
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          patientId: familyMembers.length > 0 ? familyMembers[0].id : null,
          insuranceCompanyId,
          insurancePlanId,
          insuranceMemberId,
          insuranceType
        },
      };
    });
  }, [familyMembers, insuranceCompanyId, insurancePlanId, insuranceMemberId, insuranceType]);
  const handleFileSelection = async event => {
    const { files, name } = event.target;
    if (files.length > 0) {
      const newFile = files[0];
      if (await validateFile(newFile)) {
        const customEvent = {
          target: {
            name: name,
            value: newFile,
          },
        };
        changeHandler(customEvent);
      }
    }
  };
  // Validation functions
  const __validatePhoneNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['phoneNumber'];
    if (!__required(fValue)) {
      return 'Phone number is required';
    }
    if (!__phoneNumber(fValue)) {
      return 'Invalid Phone number';
    }
    return null;
  };
  const __validateExistingPatient = (value = null) => {
    const fValue = value !== null ? value : formValues['isExistingPatient'];
    if (fValue === null) {
      return 'Select anyone of the conditon';
    }

    return null;
  };
  const __validateInsuranceMemberId = (value = null) => {
    const fValue = value !== null ? value : formValues['insuranceMemberId'];
    if (!__required(fValue) && formValues['insuranceCompanyId'] && formValues['insurancePlanId']) {
      return 'Member ID is required';
    }
    return null;
  };
  const __validateInsurancePlanId = (value = null) => {
    const fValue = value !== null ? value : formValues['insurancePlanId'];
    if (!__required(fValue) && formValues['insuranceCompanyId']) {
      return 'Insurance Plan is required';
    }
    return null;
  };
  const __validateForm = () => {
    const validPhoneNumber = !__validatePhoneNumber();
    const validInsuranceMember = !__validateInsuranceMemberId();
    const validateInsurancePlanId = !__validateInsurancePlanId();

    return validPhoneNumber && validInsuranceMember && validateInsurancePlanId;
  };
  const showInfo = () => {
    const { setGlobal } = rootContext;
    setGlobal(
      'modal',
      infoModalData(`If this is the first time consulting
  with this provider, you must be physically
  located in the same state during the video visit.
  If you have questions, please discuss this with
  the provider’s office.`),
    );
  };
  const changeHandler = event => {
    let { name } = event.target;
    let { value } = event.target;
    let errorObject = {
      [name]: null,
    };
    let updateValueObj = {};
    switch (name) {
      case 'patientId':
        break;
      case 'phoneNumber':
        value = value.replace(exceptDigitsRegex, '');
        errorObject = {
          [name]: __validatePhoneNumber(value),
        };
        break;
      case 'isExistingPatient':
        value = true;
        errorObject = {
          [name]: __validateExistingPatient(value),
        };
        break;
      case 'isNewPatient':
        if (isVirtual) showInfo();
        name = 'isExistingPatient';
        value = false;
        errorObject = {
          isExistingPatient: __validateExistingPatient(value),
        };
        break;
      case 'insuranceCompanyId':
        updateValueObj = {
          verifyInsurance: true,
          insurancePlanId: '',
          insuranceMemberId: '',
        };
        if (!value) {
          errorObject = {
            ['insurancePlanId']: null,
            ['insuranceMemberId']: null,
          }
        }
        break;
      case 'insurancePlanId':
        updateValueObj = {
          verifyInsurance: true,
        };
        errorObject = {
          [name]: __validateInsurancePlanId(value),
        };
        break;
      case 'insuranceMemberId':
        errorObject = {
          [name]: __validateInsuranceMemberId(value),
        };
        break;
      case 'note':
        break;
      default:
        break;
    }
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
          ...updateValueObj,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      }
    });
  };

  const defaultFunction = () => { };
  const updateVerifyInsuranceFlag = passFrom => {
    if (passFrom === 'closeButton') {
      const query = new URLSearchParams(window.location.search);
      let appointmentSource = query.get('appointmentSource');
      appointmentSource = appointmentSource ? appointmentSource : getAppointmentSourceStore() ? getAppointmentSourceStore() : 3;
      let passObj = { ...formValues }
      passObj.appointmentSource = appointmentSource
      const accessLogProviderId = query.get('accessLogProviderId');
      if (accessLogProviderId)
        passObj.accessLogProviderId = accessLogProviderId
      const accessLogPracticeId = query.get('accessLogPracticeId');
      if (accessLogPracticeId)
        passObj.accessLogPracticeId = accessLogPracticeId
        let kdid=localStorage.getItem('kdid')||getCookie('kdid')
      
      if (kdid) {
        passObj.kdid = kdid
      }
      bookAppointment(
        { ...passObj, verifyInsurance: false, isVirtual },
        defaultFunction,
        recallBooking,
      );
    }
  };

  const recallBooking = () => {
    const query = new URLSearchParams(window.location.search);
    let appointmentSource = query.get('appointmentSource');
    appointmentSource = appointmentSource ? appointmentSource : getAppointmentSourceStore() ? getAppointmentSourceStore() : 3;
    let passObj = { ...formValues, isVirtual }
    passObj.appointmentSource = appointmentSource
    const accessLogProviderId = query.get('accessLogProviderId');
    if (accessLogProviderId)
      passObj.accessLogProviderId = accessLogProviderId
    const accessLogPracticeId = query.get('accessLogPracticeId');
    if (accessLogPracticeId)
      passObj.accessLogPracticeId = accessLogPracticeId
      let kdid=localStorage.getItem('kdid')||getCookie('kdid')
    
    if (kdid) {
      passObj.kdid = kdid
    }
    bookAppointment(passObj, updateVerifyInsuranceFlag, defaultFunction);
  };

  const handleForm = () => {
    setIsSaveBtnClicked(true);
    if (__validateExistingPatient()) {
      setTimeout(() => {
        _scrollToView(`error-txt`);
      }, 100);
      return false;
    }
    if (!isValidForm) {
      let valError = [
        'phoneNumber', 'insuranceMemberId', 'insurancePlanId'
      ];
      valError.forEach(name => {
        changeHandler({ target: { name: name, value: formValues[name] } });
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    Nux.sendEvent(
      Analytics.Category.PatientAppointment,
      Analytics.Actions.Click,
      'reserveAppointment',
    );
    const query = new URLSearchParams(window.location.search);
    let appointmentSource = query.get('appointmentSource');
    appointmentSource = appointmentSource ? appointmentSource : getAppointmentSourceStore() ? getAppointmentSourceStore() : 3;
    let passObj = { ...formValues, isVirtual }
    passObj.appointmentSource = appointmentSource
    const accessLogProviderId = query.get('accessLogProviderId');
    if (accessLogProviderId)
      passObj.accessLogProviderId = accessLogProviderId
    const accessLogPracticeId = query.get('accessLogPracticeId');
    if (accessLogPracticeId)
      passObj.accessLogPracticeId = accessLogPracticeId
      let kdid=localStorage.getItem('kdid')||getCookie('kdid')
   
    if (kdid) {
      passObj.kdid = kdid
    }
    bookAppointment(passObj, updateVerifyInsuranceFlag, recallBooking);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const isValidForm = __validateForm();

  const handlePatientSelection = patientId => {
    const customEvent = {
      target: {
        name: 'patientId',
        value: patientId,
      },
    };
    changeHandler(customEvent);
  };

  const openAddPatientModal = () => {
    const { setGlobal } = rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: AddPatientModal,
        customComponentProps: {
          onSavePatient: (data, closeModal) => {
            onSavePatient(data, closeModal, handlePatientSelection);
          },
        },
      }),
    );
  };

  const getInsuranceCompanyPlans = () => {
    let selectedInsuranceCompany = null;
    if (formValues['insuranceCompanyId']) {
      selectedInsuranceCompany = _.find(insurancePlans, { id: formValues['insuranceCompanyId'] });
    }
    const plans = _.get(selectedInsuranceCompany, 'insurancePlans', []);
    return plans;
  };

  if (!insurancePlans) {
    return null;
  }
  let changeInsurance = false

  if (IntialInsuranceData.insuranceCompanyId !== formValues.insuranceCompanyId || IntialInsuranceData.insuranceMemberId !== formValues.insuranceMemberId || IntialInsuranceData.insurancePlanId !== formValues.insurancePlanId) {
    changeInsurance = true
  }
  if (formValues.isSaveToProfile && (!formValues.insuranceCompanyId || !formValues.insuranceMemberId || !formValues.insurancePlanId)) {
    changeHandler({ target: { name: 'isSaveToProfile', value: false } })
  }
  return (
    <div className="ReviewConfirm">
      <div className="h3">Review & Confirm Appointment Details</div>
      <form>
        <div className="box-content">
          <div className="title">The appointment is for:</div>
          <div className="box-inner-content">
            {familyMembers.length > 1 &&
              familyMembers.map(member => {
                const val = member.id === formValues['patientId'];
                return (
                  <div className="name" key={member.id}>
                    <Checkbox
                      name="patientId"
                      value={val}
                      disabled={val}
                      onChange={() => handlePatientSelection(member.id)}></Checkbox>{' '}
                    {member.firstName} {member.lastName}{' '}
                  </div>
                );
              })}
            {familyMembers.length === 1 &&
              familyMembers.map(member => {
                return (
                  <div className="name" key={member.id}>
                    {member.firstName} {member.lastName}{' '}
                  </div>
                );
              })}
            <div className="choose-other">
              Or book for <span onClick={openAddPatientModal}>someone else</span>
            </div>
          </div>
        </div>
        <div className={'box-content existing-patient'}>
          <div className={'title'}>Are you {doctorDetail.name}’s existing patient?</div>
          <div
            className={[
              'box-inner-content',
              isSaveBtnClicked && __validateExistingPatient() ? 'error-box' : '',
            ].join(' ')}>
            <Checkbox
              name="isNewPatient"
              className={[isSaveBtnClicked && __validateExistingPatient() ? 'error-txt' : ''].join(
                ' ',
              )}
              value={
                formValues['isExistingPatient'] !== null ? !formValues['isExistingPatient'] : false
              }
              disabled={
                formValues['isExistingPatient'] !== null ? !formValues['isExistingPatient'] : false
              }
              onChange={changeHandler}>
              No, I’m a new patient
            </Checkbox>
            <Checkbox
              name="isExistingPatient"
              value={formValues['isExistingPatient']}
              disabled={formValues['isExistingPatient']}
              onChange={changeHandler}>
              Yes, I am an existing patient
            </Checkbox>
            {isSaveBtnClicked && __validateExistingPatient() && (
              <span className="error-text">Is Existing Patient field is required</span>
            )}
          </div>
        </div>
        <div className="phonenumber">
          <Input
            mask="(999) 999-9999"
            maskChar=" "
            type="tel"
            InputComponent={InputMask}
            {...getGeneralInputProps('phoneNumber')}
            label="Primary phone number where the doctor will contact you"
          />
          <span className="update-number">Update</span>
        </div>
        <div className="select-insurance">
          <Select
            {...getGeneralInputProps('insuranceCompanyId')}
            options={insurancePlans}
            labelIndex="companyName"
            valueIndex="id"
            label="Insurance Information"
            isAllowDefaultOpen={true}
            defaultOptionProp={insuranceDefaultOption}
          />
          <Select
            {...getGeneralInputProps('insurancePlanId')}
            options={getInsuranceCompanyPlans()}
            labelIndex="plan"
            valueIndex="id"
            containerClass={`${formValues['insuranceCompanyId'] ? 'errorpading' : ''} `}
            className={`plan ${!formValues['insuranceCompanyId'] ? 'disable' : ''} insuranceCompanyId`}
            disabled={!formValues['insuranceCompanyId']}
            defaultOptionProp={{ label: 'Select Plan', value: '' }}
          />
          <Input
            {...getGeneralInputProps('insuranceMemberId')}
            {...getGeneralInputProps('insuranceMemberId')}
            containerClass={`${(!formValues['insurancePlanId'] || !formValues['insuranceCompanyId']) ? 'disable' : ''} insuranceMemberId`}
            disabled={!formValues['insurancePlanId'] || !formValues['insuranceCompanyId']}
            label="Insurance Member ID"
          //  style={{marginBottom: "10px"}}
          // translationType="transform"
          />
          {/* {changeInsurance && (formValues['insurancePlanId'] && formValues['insuranceCompanyId']) &&(
           <div className="form-group-2-colum " style={{margin:0}}>
              <Upload
                name="insuranceCardFrontPhoto"
                label="Front Photo"
                onChange={handleFileSelection}
                file={formValues['insuranceCardFrontPhoto']}
                fileUrl={formValues['insuranceCardFrontPhotoUrl']}
                error={formErrors['insuranceCardFrontPhoto']}
              />
              <Upload
                name="insuranceCardBackPhoto"
                label="Back Photo"
                onChange={handleFileSelection}
                file={formValues['insuranceCardBackPhoto']}
                fileUrl={formValues['insuranceCardBackPhotoUrl']}
                error={formErrors['insuranceCardBackPhoto']}
              />
            </div>
            )} */}
          <Checkbox disabled={!changeInsurance || (!formValues['insurancePlanId'] || !formValues['insuranceCompanyId'])} {...getGeneralInputProps('isSaveToProfile')} className={!changeInsurance ? 'is-disabled' : ""}>
            Save to my profile for later use
          </Checkbox>
        </div>
        <Textarea
          {...getGeneralInputProps('note')}
          label="Note’s for the Doctor’s Office (Optional)"
        />
        <div className="action-btn action-bottom">
          <div className="appoitment-smtime">
            <span className="title">Appointment Request</span>
            <span className="time-detail">
              {localAppointmentDateTime.format('ddd MMM DD, h:mm a')}
            </span>
            <div className="view-details" onClick={onToggleAppointment}>
              View Details
            </div>
          </div>
          <CircleButton onClick={handleForm} >
            Reserve <span className="sm-hide">Appointment</span>
          </CircleButton>
        </div>
        <div className="reserve-appointment">
          By selecting “Reserve Appointment” I certify that the insurance or payment selected is the
          one that I will be using when I see this medical professional, and that I have read and
          agree to the Kaly{' '}
          <Link to="/terms" target="_blank">
            terms of use
          </Link>
          .
        </div>
      </form>
    </div>
  );
};

export default ReviewConfirm;
