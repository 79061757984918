import React, {useEffect} from 'react';
import modalConfig from 'modals/modalConfig';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import './AutoLogoutInactiveWarning.css';
import moment from 'moment';
const AutoLogoutInactiveWarning = props => {
  const {setGlobal, limitMin} = props;
  const {modalFunctions} = modalConfig;
  const {blankModalData} = modalFunctions;

  const WarnigComponent = props => {
    const {closeModal} = props;

    var seconds = 1000 * 60; //1000 = 1 second in JS
    let existingval = localStorage.getItem('lastActiveTime');
    var timer;
    setTimeout(() => {
      myFunction();
    }, 100);

    //When a key is pressed in the text area, update the timer using myFunction

    function myFunction() {
      if (seconds == 60000) timer = setInterval(myFunction, 1000);
      seconds -= 1000;
      let timeval = (seconds / 1000).toString();
      if (document.querySelector('#timer'))
        document.querySelector('#timer').innerText =
          '00:' + (timeval.length > 1 ? timeval : `0${timeval}`);
      if (existingval !== localStorage.getItem('lastActiveTime')) {
        clearInterval(timer);
        props.resolve(true);
        closeModal();
      }
      var now = moment(new Date()); //todays date

      var end = moment(localStorage.getItem('lastActiveTime')); // another date
      //console.log("end",end);

      var duration = moment.duration(now.diff(end));
      var Minutes = duration.asMinutes();
      //console.log('Minutes', Minutes);
      if (isNaN(Minutes) || Minutes > limitMin) {
        //console.log("000");
        clearInterval(timer);
        props.resolve(false);
        closeModal();
      }
      //console.log("seconds",seconds);
      if (parseInt(seconds) <= 0) {
       // console.log(1);
        clearInterval(timer);
        
        props.resolve(false);
        closeModal();
      }
    } //If seconds are equal or greater than 0, countdown until 1 minute has passed

    return (
      <div className="CreatePasswordModal termsofuse ActiveWatcher">
        <div className="modal-content-header">
          <div className="h3">Warning</div>
          <span
            className="close"
            onClick={() => {
              props.resolve(true);
              closeModal();
              clearInterval(timer);
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div className="modal-content-body">
          <div className="otp-maindiv">
            <p className="text">
              Session has been idle over 14 minutes. It will be disconnected in{' '}
              <span id="timer">00:00</span> seconds. Click Cancel to continue session.
            </p>
          </div>
        </div>
        <div className="modal-content-footer">
          <CircleButton
            // disabled={otp.length===6?false:true}
            onClick={() => {
              props.resolve(true);
              props.closeModal();
              clearInterval(timer);
            }}>
            Cancel
          </CircleButton>
        </div>
      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        closeOnBGClick:false,
        CustomComponent: WarnigComponent,
        customComponentProps: {resolve: resolve, reject: reject},
      }),
    );
  });
};
export default AutoLogoutInactiveWarning;
