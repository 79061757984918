import React, {useState, useEffect, useCallback, useContext, useMemo} from 'react';
import _ from 'lodash';
import Input from '../Input';
import images from 'data/images';
import patientService from 'services/patient';
import RootContext from 'context/RootContext';
import masterService from 'services/master';
import helper from 'data/helper';
import painData from 'data/painData';
import './ElasticSearch.css';

const {painList} = painData;
const {searchForAlgoAndDoctorList} = patientService;
const {getAllSpecialties} = masterService;
const {getSuffixString, getHighlightedText, getLocation, getDistance} = helper;

const ElasticSearch = props => {
  const {name, selectedResult, onSelect} = props;
  const rootContext = useContext(RootContext);
  const {specialties: specialtyList} = rootContext;
  const [latLong, setLatLong] = useState(null);
  const [specialityLoading, setSpecialityLoading] = useState(true);
  const [tempAutoFindData, setTempAutoFindData] = useState(null);

  useEffect(() => {
    getAndSetSpecialties();
    const {setGlobal} = rootContext;
    getLocation(position => {
      if (Object.keys(position).length > 0) {
        setLatLong({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        setGlobal('geoLocationDetails', position.loc_details);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDistanceFromDoctorLocation = doctorSource => {
    if (latLong) {
      const latitude = _.get(doctorSource, 'locations.0.latitude', null);
      const longitude = _.get(doctorSource, 'locations.0.longitude', null);
      if (latitude && longitude) {
        return (
          '. ' +
          getDistance([latLong.latitude, latLong.longitude], [latitude, longitude]).toFixed(1) +
          ' mi'
        );
      } else {
        return '';
      }
    }
    return '';
  };

  // get and set specialties to global store
  const getAndSetSpecialties = async () => {
    const {setGlobal} = rootContext;
    setSpecialityLoading(true);
    if (!specialtyList) {
      const response = await getAllSpecialties(true).catch(() => null);
      if (response) {
        const {data: specialties} = response;
        setGlobal('specialties', specialties);
      }
    }
    setSpecialityLoading(false);
  };

  const [config, setConfig] = useState({
    searchText: _.get(selectedResult, 'name', ''),
    isFocused: false,
    searchResultList: null,
  });
  const {searchText, isFocused, searchResultList} = config;

  const getAndSetResultList = useCallback(
    _.debounce(async value => {
      let updatedSearchResultList = null;
      if (value.length > 1) {
        setSpecialityLoading(true);

        const response = await searchForAlgoAndDoctorList(value).catch(() => null);

        if (response) {
          const {data} = response;
          updatedSearchResultList = data;
          if ('pain management'.match(value.toLocaleLowerCase())) {
            let painMAnaObj = {
              _index: 'specialties',
              _type: '_doc',
              _id: '11-P',
              _score: 2,
              _source: {
                name: 'Pain Management',
                id: '11',
                algoFile: 'painManagement',
              },
            };
            updatedSearchResultList['specialties'] = [
              ...updatedSearchResultList['specialties'],
              painMAnaObj,
            ];
          }
          // if ('primary care'.match(value.toLocaleLowerCase())||'internist'.match(value.toLocaleLowerCase())) {
          //   let InternalObj = {
          //     "_index": "specialties",
          //     "_type": "_doc",
          //     "_id": "38-I",
          //     "_score": 1,
          //     "_source": {
          //       "name": "Internal Medicine Physician",
          //       "id": "38",
          //       "algoFile": "internalMedicinePhysician"
          //     }
          //   }
          //   let painMAnaObjArr = []
          //   if (!updatedSearchResultList['specialties'].find((it) => it._id == "38"))
          //     painMAnaObjArr.push(InternalObj)
          //   let FP = {
          //     "_index": "specialties",
          //     "_type": "_doc",
          //     "_id": "29-F",
          //     "_score": 1,
          //     "_source": {
          //       "name": "Family Physician",
          //       "id": "29",
          //       "algoFile": "familyPhysician"
          //     }
          //   }
          //   if (!updatedSearchResultList['specialties'].find((it) => it._id == "29"))
          //     painMAnaObjArr.push(FP)
          //   updatedSearchResultList['specialties'] = [...updatedSearchResultList["specialties"], ...painMAnaObjArr]
          // }
        }
        setSpecialityLoading(false);
      }
      if (
        updatedSearchResultList &&
        updatedSearchResultList.providers &&
        updatedSearchResultList.providers.length > 0
      ) {
        updatedSearchResultList['providers'] = updatedSearchResultList.providers.filter(
          p => p._source.specialties.length > 0,
        );
      }
      setConfig(prevConfig => {
        return {
          ...prevConfig,
          searchResultList: updatedSearchResultList,
        };
      });
    }, 500),
    [],
  );

  const handleSearchTextChange = event => {
    let {value} = event.target;
    value = value.length > 0 ? value.replace(/[^a-zA-Z0-9 ]/g, '') : '';
    handleSelectedResult(null);
    getAndSetResultList(value);

    setConfig(prevConfig => {
      return {
        ...prevConfig,
        searchText: value,
        searchResultList: null,
      };
    });
  };

  const handleSelectedResult = data => {
    let isFocused = false;
    if (data && data.hide === false) {
      isFocused = true;
    }
    const customEventObject = {
      target: {
        name: name,
        value: data,
      },
    };
    if (data) {
      setConfig(prevConfig => {
        return {
          ...prevConfig,
          searchText: data.name,
          isFocused: isFocused,
        };
      });
    }
    onSelect(customEventObject);
  };

  const onClickOutSide = useCallback(() => {
    setConfig(prevConfig => {
      return {
        ...prevConfig,
        isFocused: false,
      };
    });
  }, []);
  // top pain List
  const topPainList = useMemo(() => {
    const topPains = [];
    _.forEach(painList, pain => {
      if (pain.isTop) {
        const newPain = {};
        newPain.type = 'symptom';
        newPain.id = pain.id;
        newPain.name = pain.label;
        newPain.algo = pain.fileName;
        topPains.push(newPain);
      }
    });
    return topPains;
  }, []);
  // used useMemo to memorize specialty
  const topSpecialtyList = useMemo(() => {
    const topSpecialities = [];
    _.forEach(specialtyList, sp => {
      if (sp.isTop) {
        const newSpecialty = {};
        newSpecialty.type = 'specialty';
        newSpecialty.id = sp.id;
        newSpecialty.name = sp.specialty;
        newSpecialty.algo = sp.algoFile;
        newSpecialty.isTop = sp.isTop;
        topSpecialities.push(newSpecialty);
      }
    });
    return topSpecialities;
  }, [specialtyList]);
  const isShowFilterResult = searchResultList && isFocused;

  let specialitySymptomProcedureList = [];
  if (isShowFilterResult) {
    specialitySymptomProcedureList = [
      ...searchResultList.symptoms,
      ...searchResultList.specialties,
      ...searchResultList.procedures,
    ];
    specialitySymptomProcedureList = _.orderBy(
      specialitySymptomProcedureList,
      [
        item => {
          return (
            _.get(item, '_source.name', '')
              .toLocaleLowerCase()
              .indexOf(searchText.toLocaleLowerCase()) !== -1
          );
        },
        '_source.name',
      ],
      ['desc', 'asc'],
    );
    let checkMatchKeyword = specialitySymptomProcedureList.findIndex(
      data => data._source.name.toLocaleLowerCase() === searchText.toLocaleLowerCase(),
    );
    if (checkMatchKeyword >= 0) {
      let selectedData = specialitySymptomProcedureList[checkMatchKeyword];

      let selected_Type = '';
      let selected_SpecialityIds = [];
      switch (selectedData._index) {
        case 'symptoms':
          selected_Type = 'symptom';
          break;
        case 'specialties':
          selected_Type = 'specialty';
          break;
        case 'procedures':
          selected_Type = 'procedure';
          break;
        default:
          break;
      }
      if (!tempAutoFindData) {
        let tempVAl = {
          type: selected_Type,
          id: selectedData._source.id,
          name: selectedData._source.name,
          algo: selectedData._source.algoFile || '',
          hide: false,
          selected_SpecialityIds,
        };
        handleSelectedResult(tempVAl);
        setTempAutoFindData(tempVAl);
        //props.autoFindData(tempVAl);
      }
    } else {
      if (tempAutoFindData) {
        setTempAutoFindData(null);
        //props.autoFindData(null);
        handleSelectedResult(null);
      }
    }
  }

  const getFormatedSepcialities = doctor => {
    let tempspecialties = _.map(doctor.specialties, 'specialty');
    let customSpecialty = doctor.customSpecialty;
    if (customSpecialty) tempspecialties = [...tempspecialties, customSpecialty];
    return tempspecialties.join(', ');
  };

  if (!specialtyList) {
    return null;
  }

  let filteredResultComponent = null;
  if (isShowFilterResult && specialitySymptomProcedureList.length > 0) {
    filteredResultComponent = (
      <div className="search-category">
        {specialitySymptomProcedureList.map(specialtyOrSymptomOrProcedure => {
          return (
            <span
              key={`${specialtyOrSymptomOrProcedure._index}-${specialtyOrSymptomOrProcedure._id}`}
              onClick={() => {
                let type = '';
                let specialityIds = [];
                switch (specialtyOrSymptomOrProcedure._index) {
                  case 'symptoms':
                    type = 'symptom';
                    break;
                  case 'specialties':
                    type = 'specialty';
                    break;
                  case 'procedures':
                    type = 'procedure';
                    break;
                  default:
                    break;
                }
                handleSelectedResult({
                  type: type,
                  id: specialtyOrSymptomOrProcedure._source.id,
                  name: specialtyOrSymptomOrProcedure._source.name,
                  algo: specialtyOrSymptomOrProcedure._source.algoFile || '',
                  specialityIds,
                });
              }}
              className="search-item">
              {getHighlightedText(specialtyOrSymptomOrProcedure._source.name, searchText)}
            </span>
          );
        })}
      </div>
    );
  }

  return (
    <div className="ElasticSearch">
      <div className={`search ${isFocused ? 'active' : ''}`}>
        <Input
          name="searchText"
          value={searchText}
          icon={images.magnifier}
          onFocus={() => {
            setConfig({...config, isFocused: true});
          }}
          onChange={handleSearchTextChange}
          placeholder="Condition, specialty, procedure, doctor"
        />
        {isFocused && <div className="overlay" onClick={onClickOutSide}></div>}

        {isShowFilterResult &&
          (specialitySymptomProcedureList.length > 0 || searchResultList.providers.length > 0) && (
            <div className="search-result">
              {filteredResultComponent}
              {searchResultList.providers.length > 0 && (
                <div className="search-category doctor">
                  {searchResultList.providers
                    .filter(p => p._source.specialties.length > 0)
                    .map(doctor => {
                      const {_source} = doctor;
                      return (
                        <span
                          data-user-id={_source.id}
                          key={_source.id}
                          className="doctor-item"
                          onClick={() => {
                            handleSelectedResult({
                              type: 'doctor',
                              ..._source,
                            });
                          }}>
                          <img src={_source.profileImageUrl || images.user} alt="doctor" />
                          {/* <span>{_source.name}</span> */}
                          <span>
                            {getHighlightedText(_source.name, searchText)}
                            {getSuffixString(_source.suffixes)}
                          </span>
                          <span>
                            {getFormatedSepcialities(_source)}{' '}
                            {getDistanceFromDoctorLocation(_source)}
                          </span>
                        </span>
                      );
                    })}
                </div>
              )}
            </div>
          )}
        {isFocused && !searchResultList && !specialityLoading && (
          <div className="search-result">
            {topPainList.length > 0 && (
              <div className="search-category">
                <span className="h3">Common Pain Conditions</span>
                {topPainList.map(pain => {
                  return (
                    <span
                      onClick={() => handleSelectedResult(pain)}
                      key={pain.id}
                      className="search-item">
                      {pain.name}
                    </span>
                  );
                })}
              </div>
            )}
            {topSpecialtyList.length > 0 && (
              <div className="search-category">
                <span className="h3">Top Specialties</span>
                {topSpecialtyList.map(sp => {
                  return (
                    <span
                      onClick={() => handleSelectedResult(sp)}
                      key={sp.id}
                      className="search-item">
                      {sp.name}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {isFocused && specialityLoading && (
          <div className="search-result">
            <div className="loader">
              <span>
                <img src={images.logosymbol} alt="logo symbol" />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ElasticSearch;
