import axiosInstance from 'config/axios';


const getPracticeInsurancePlans = () => {
  return axiosInstance.get('v1/practice/insurance');
};

const updatePracticeInsurancePlans = data => {
  return axiosInstance.put('v1/practice/insurance', data);
};

export default {
  getPracticeInsurancePlans,
  updatePracticeInsurancePlans,
};
