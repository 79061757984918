import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from 'containers/App/App';
import ScrollToTop from 'components/ScrollToTop';
import './index.css';
 import * as serviceWorker from './serviceWorker';

// const history = createBrowserHistory();

const MOUNT_NODE = document.getElementById('root');

const renderApp = RootComponent => {
  render(
    <BrowserRouter basename={'/app/'}>
      <ScrollToTop />
      <RootComponent />
    </BrowserRouter>,
    MOUNT_NODE,
  );
};
renderApp(App);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
