import React, {useState, useContext} from 'react';
import MyLink from 'components/MyLink';
import './ReviewConnect.css';
//import _ from 'lodash';
import reviewService from 'services/review';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';

const {updateReviewSoruceStatus} = reviewService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;

const ReviewConnect = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  //const [activeId, toggleActive] = useState(0);
  const {sourceList = [],getProviderReviews} = props;
  // if (sourceList.length === 0) {
  //   return null;
  // }

  const getStatusText = status => {
    switch (status) {
      case 0:
        return <span className="pending">Pending</span>;
      case 1:
        return <span className="approved">Approved</span>;
      case 2:
        return <span className="rejected">Rejected</span>;
      default:
        return '';
    }
  };

  const handleReviewSourceActions = async (token, status) => {
    //1 - approve, 2 - reject
    const postData = {
      token,
      status: status,
    };
    const response = await updateReviewSoruceStatus(postData).catch(handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            const {onRefresh} = props;
            onRefresh();
            getProviderReviews()
          },
        }),
      );
    }
  };

  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  return (
    <div className="ReviewConnect">
      {/* <Overlay className="overlay" show={!!activeId} onClick={() => handleSetActive(0)} /> */}
      <div className="Rtable">
        <div className="Rtable-row Rtable-row--head">
          <div className="Rtable-cell date-cell column-heading">Source</div>
          <div className="Rtable-cell access-link-cell column-heading">Review Site</div>
          <div className="Rtable-cell staus-cell column-heading">Status</div>
          <div className="Rtable-cell action-cell column-heading">Actions</div>
        </div>
        {sourceList.map(source => {
          const {
            id,
            reviewSource: {title: sourceName = ''},
            url,
            status,
            token,
          } = source;
          let cssstyle=source.status === 0?{padding: "10px 0",flexDirection:'column',gap:'10px'}:{}
          return (
            <div key={id} className="Rtable-row">
              <div className="Rtable-cell date-cell content-cell">{sourceName}</div>
              <div className="Rtable-cell access-link-cell content-cell">
                <MyLink to={url}>{url}</MyLink>
              </div>
              <div className="Rtable-cell staus-cell content-cell">{getStatusText(status)}</div>
              <div style={{justifyContent:'center',...cssstyle}} className="Rtable-cell action-cell content-cell">
                {source.status === 0 && (
                  <>
                    <button
                      className="btn btn-approve"
                      onClick={() => handleReviewSourceActions(token, 1)}>
                      Approve
                    </button>
                    <button
                      className="btn btn-reject"
                      onClick={() => handleReviewSourceActions(token, 2)}>
                      Reject
                    </button>
                  </>
                )}
                <button
                      className="btn btn-approve"
                      onClick={() => props.onEditReviewSource(source)}>
                      Edit
                    </button>
                    <button
                      className="btn btn-approve"
                      onClick={() => props.ondeleteReviewSource(source.id)}>
                      Delete
                    </button>
              </div>
            </div>
          );
        })}
        {sourceList.length===0&&(
          <p className="">No Source Found.</p>
        )}
      </div>
    </div>
  );
};

export default ReviewConnect;
