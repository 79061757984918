import moment from 'moment';
import _ from 'lodash';

const setCookie = (cookieName = '', cookieValue = '', hourToExpire = 1 / 3) => {
  // default cookie will be expire after 20 minutes
  let date = moment();
  date.add(hourToExpire, 'hour');
  let value = _.cloneDeep(cookieValue);
  if (_.isArray(value) || _.isObject(value)) {
    value = JSON.stringify(value);
  }
  const encryptedValue = btoa(value);
  document.cookie =
    cookieName + ' = ' + encryptedValue + '; expires = ' + date.toDate().toGMTString();
};

const deleteCookie = (cookieName = '') => {
  setCookie(cookieName, '', 0);
};

const getCookie = cookieName => {
  const cookieToFind = cookieName + '=';
  let allCookieArray = document.cookie.split(';');
  let cookieValue = null;
  allCookieArray.forEach(cookie => {
    let trimedCookie = cookie.trim();
    if (trimedCookie.indexOf(cookieToFind) === 0) {
      let encryptedValue = trimedCookie.substring(cookieToFind.length, trimedCookie.length);
      let decryptedValue = '';
      if(COOKIE_CONSTANTS.ALGO===cookieName)
      if (encryptedValue) {
        decryptedValue = atob(encryptedValue);
      }
      try {
        cookieValue = JSON.parse(decryptedValue);
      } catch (error) {
        cookieValue = decryptedValue;
      }
      return true;
    }
  });
  return cookieValue;
};

const COOKIE_CONSTANTS = {
  ALGO: 'ALGO',
};
const removeUserIdCookie=()=>{
  var date = new Date();
          date.setTime(date.getTime() + (0 * 60 * 1000));
          var expires = "; expires=" + date.toGMTString();
          let host = window.location.host
          document.cookie = `userId=0;domain=.${host};path=/;secure; SameSite=none${expires}`;
     
}
export default {
  getCookie,
  setCookie,
  deleteCookie,
  COOKIE_CONSTANTS,
  removeUserIdCookie
};
