import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import images from 'data/images';
import Input from 'components/FormControls/Input';
import AutoSuggestionSelect from 'components/FormControls/AutoSuggestionSelect';
import allRegex from 'data/regex';
import constants from 'data/constants';
import './ProviderPercentage.css';
import helper from 'data/helper';

const { isArrayEqual } = helper;
const { exceptDigitsRegex } = allRegex;
const { DEFAULT_MESSAGES } = constants;


const __validateFirstField = value => {
  if (!value) {
    return 'Text field is required';
  }
  return null;
};
const __validateSecondField = value => {
  const percentage = parseInt(value);
  if (!percentage || percentage <= 0) {
    return 'Percentage must be greater than 0';
  }
  return null;
};

const ProviderPercentage = props => {
  const {
    mainTitle,
    mainSubTitle,
    subTitle,
    addLabelText,
    options,
    updateConditionsData,
    updateProceduresData,
    listNew,
    checkSpecialitChange
  } = props;

  const { list, listIndexes, optionIndexes, maxItems = 5, minItems = 1, otherItem } = props;
  const [textInputIndex, percentageInputIndex] = listIndexes;
  const [optionValueIndex = 'value', optionLabelIndex = 'label'] = optionIndexes;

  const getErrors = () => {
    const errors = [];
    list.forEach(item => {
      errors.push({
        [textInputIndex]: __validateFirstField(item[textInputIndex]),
        [percentageInputIndex]: __validateSecondField(item[percentageInputIndex]),
      });
    });
    return errors;
  };

  const [formData, setFormData] = useState({
    values: [],
    errors: [],
  });
  const { values: formValues, errors: formErrors } = formData;
  const checkFormDataChange =  useCallback(() => {
    let valueArraty=_.get(formData, 'values', [])
    
    if (listNew) {
      const ischanged = !isArrayEqual(listNew,valueArraty);
      return ischanged;
    } else {
      return false
    }
  },[listNew,formData]);
  const filteredOptions = useCallback(
    item => {
      let alreadySelectedValues = _.map(formValues, i => {
        return i[textInputIndex];
      });
      const newOptions = _.filter(options, option => {
        return (
          option[optionValueIndex].toString() === item[textInputIndex].toString() ||
          !alreadySelectedValues.includes(option[optionValueIndex])
        );
      });
      return newOptions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues, options],
  );

  useEffect(() => {
    const errors = [];
    
    list.forEach(item => {
      errors.push({
        [textInputIndex]: __validateFirstField(item[textInputIndex]),
        [percentageInputIndex]: __validateSecondField(item[percentageInputIndex]),
      });
    });
    setFormData({
      values: [...list],
      errors: getErrors(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);
  useEffect(() => {
    if (listNew&&checkSpecialitChange===true) {
      
      if(checkFormDataChange()){
      let tempFormValues = [...formValues]
     
      let inputIndexId = listNew.map((ln) => ln[textInputIndex].toString())
      
      tempFormValues = tempFormValues.filter((ii) => inputIndexId.includes(ii[textInputIndex].toString()))
      let formDataErrors=[...formData.errors]
    
      if(tempFormValues.length===0){
        let tempObj={}
        tempObj[textInputIndex]='';
        tempObj[percentageInputIndex]=''
        tempFormValues=[tempObj]
        let tempErrorObj={}
        tempErrorObj[textInputIndex]=null;
        tempErrorObj[percentageInputIndex]=null;
        formDataErrors=[tempErrorObj]
      }else{
        if(formDataErrors.length!==tempFormValues.length){
          
          formDataErrors=[];
          _.forEach(tempFormValues, event => {
            formDataErrors.push({
            [textInputIndex]: __validateFirstField(event[textInputIndex]),
            [percentageInputIndex]: __validateSecondField(event[percentageInputIndex]),
          });
        })
        }
      }
    
      setFormData({
        ...formData,
        values: tempFormValues,
        errors:formDataErrors
       
      });
      }
    }
  }, [listNew,checkSpecialitChange])
  let totalPercentage = 0;
  formValues.forEach(item => {
    totalPercentage = totalPercentage + (parseInt(item[percentageInputIndex]) || 0);
  });
  if (formValues.length >= maxItems) {
    totalPercentage += otherItem.percentage;
  }

  const getErrorMessage = () => {
    let errorMessage = null;
    if (!errorMessage) {
      if (totalPercentage > 100) {
        errorMessage = DEFAULT_MESSAGES.PERCENTAGE_GREATERTHAN_100;
      }
    }
    formErrors.forEach(error => {
      if (!errorMessage) {
        if (error[textInputIndex]) {
          errorMessage = error[textInputIndex];
        }
      }
      if (!errorMessage) {
        if (error[percentageInputIndex]) {
          errorMessage = error[percentageInputIndex];
        }
      }
    });
    return errorMessage;
  };
  const errorMessage = getErrorMessage();

  useEffect(() => {
    const isValid = errorMessage ? false : true;
   
    switch (textInputIndex) {
      case 'painId':
        updateConditionsData(isValid, [...formValues]);
        break;
      case 'procedureId':
        updateProceduresData(isValid, [...formValues]);
        break;
      default:
        break;
    }
  }, [errorMessage, formValues]);

  const handleChange = (index, event) => {
    const { name } = event.target;
    let { value } = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case textInputIndex:
        errorObject = {
          [name]: __validateFirstField(value),
        };
        break;
      case percentageInputIndex:
        value = value.replace(exceptDigitsRegex, '');
        value = value.substring(0, 3);
        if (value > 100) {
          value = _.toNumber(value.substring(0, 2));
        }
        errorObject = {
          [name]: __validateSecondField(value),
        };
        break;
      default:
        break;
    }
    const updatedValues = [...formValues];
    updatedValues[index] = {
      ...updatedValues[index],
      [name]: value,
    };
    if (name === textInputIndex) {
      updatedValues[index] = {
        ...updatedValues[index],
        [name]: value,
        [percentageInputIndex]: 0
      };
      errorObject = {
        ...errorObject,
        [percentageInputIndex]: __validateSecondField('0'),
      };
    }
    const updatedErrors = [...formErrors];
    updatedErrors[index] = {
      ...updatedErrors[index],
      ...errorObject,
    };
    setFormData({
      ...formData,
      values: updatedValues,
      errors: updatedErrors,
    });
  };

  const removeItem = index => {
    setFormData(prevState => {
      const updatedValues = [...prevState.values];
      updatedValues.splice(index, 1);
      const updatedErrors = [...prevState.errors];
      updatedErrors.splice(index, 1);
      return {
        ...prevState,
        values: updatedValues,
        errors: updatedErrors,
      };
    });
  };

  const addItem = () => {
    setFormData(prevState => {
      const updatedValues = [...prevState.values];
      updatedValues.push({
        [textInputIndex]: '',
        [percentageInputIndex]: '',
      });

      const updatedErrors = [...prevState.errors];
      updatedErrors.push({
        [textInputIndex]: __validateFirstField(''),
        [percentageInputIndex]: __validateSecondField(''),
      });
      
      return {
        ...prevState,
        values: updatedValues,
        errors: updatedErrors,
      };
    });
  };

  const isHideRemoveButton = formValues.length <= minItems;

  return (
    <div className="ProviderPercentage">
      <div className="percentage-content">
        <div className="titleheading">
          <div>
            <div className="h3">
              {mainTitle}
              <span className="per-txt"> {mainSubTitle}</span>
            </div>
            <div className="sub-para">{subTitle}</div>
          </div>
        </div>
        <form>
          <div className="pt-category-box">
            {formValues.map((item, index) => {
              return (
                <div
                  key={index}
                  className="box-wrapper"
                  style={{ width: `calc(${item[percentageInputIndex] || 0}% + 5px)` }}>
                  <span className="box"></span>
                </div>
              );
            })}
            {otherItem && formValues.length >= maxItems && (
              <div
                className="box-wrapper"
                style={{ width: `calc(${otherItem.percentage || 0}% + 5px)` }}>
                <span className="box"></span>
              </div>
            )}
          </div>
          <div className="percentage-wrapper">
            <div className="percentage-label">
              <div className="column9 label">{addLabelText}</div>
              <div className="column3 label">Total = {totalPercentage} %</div>
            </div>
            <div className="percentage-group">
              {formValues.map((item, index) => {
                const key = item[textInputIndex]
                  ? `${textInputIndex}_${item[textInputIndex]}index_${index}`
                  : `index_${index}`;

                let selectedVal = options.find((ii) => ii[optionValueIndex].toString() === item[textInputIndex].toString())
                return (
                  <div className="percentage-inner" key={key}>
                    <AutoSuggestionSelect
                      inputPlaceholder=""
                      name={textInputIndex}
                      options={filteredOptions(item)}
                      labelIndex={optionLabelIndex}
                      valueIndex={optionValueIndex}
                      value={item[textInputIndex]}
                      onSelection={event => handleChange(index, event)}
                      error={formErrors[index][textInputIndex] ? true : false}
                      containerClass="column9"
                    />
                    <Input
                      error={formErrors[index][percentageInputIndex] ? true : false}
                      type="text"
                      name={percentageInputIndex}
                      onChange={event => handleChange(index, event)}
                      value={selectedVal ? item[percentageInputIndex] : ''}
                      containerClass="column3"
                    />
                    {!isHideRemoveButton && (
                      <span className="remove-link" onClick={() => removeItem(index)}>
                        <span className="remove-text">Remove</span>
                        <span className="close-icon">
                          <img src={images.closeGray} alt="close" />
                        </span>
                      </span>
                    )}
                  </div>
                );
              })}
              {formValues.length >= maxItems && (
                <div className="percentage-inner other">
                  <div className="other-control column9">
                    <div className="other-input">
                      <span>{otherItem.label}</span>
                      <span> {otherItem.subLabel}</span>
                    </div>
                  </div>
                  <Input
                    type="text"
                    disabled={true}
                    name={'percentage'}
                    value={otherItem.percentage}
                    containerClass="column3"
                  />
                </div>
              )}
            </div>
            {maxItems > formValues.length && (
              <div className="add-input">
                <span onClick={addItem}>
                  <img src={images.plusBlue} alt="" /> {addLabelText}
                </span>
              </div>
            )}
          </div>
          <div className="action-block">
            <div className="error-txt">{errorMessage}</div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProviderPercentage;
