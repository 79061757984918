import React, {useContext} from 'react';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import MenuItem from 'components/AfterAuth/MenuItem';
import DoctorList from 'components/AfterAuth/DoctorList';
import authDashboardContent from 'data/authDashboardContent';
import images from 'data/images';
import AuthContext from 'context/AuthContext';
import rolePermission from 'data/rolePermission';
import './Sidebar.css';
import MyLink from 'components/MyLink';
import helper from 'data/helper';
const {helperFunctions} = rolePermission;
const {hasAnyPermission, getAllMyRoleIds} = helperFunctions;
const {setAppointmentSourceStore}=helper;
const Sidebar = props => {
  const siderbarMenuItems = [...authDashboardContent.siderbarMenuItems];
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const {practice} = auth;

  const {location} = props;
  const {pathname} = location;
  const menuitemsComponent = [];
  const roleIds = getAllMyRoleIds(auth);
  const enableConsultRequest=_.get(auth?.practice,'enableConsultRequest',false)
  _.map(siderbarMenuItems, menuItem => {
    if (hasAnyPermission(roleIds, menuItem.permissions)) {
      const {id, link} = menuItem;
      let isActive = pathname === link;
      if(!enableConsultRequest && menuItem.name=='Consult Request'){
        return
      }
      menuitemsComponent.push(
        <MenuItem
          roleIds={roleIds}
          key={id}
          isActive={isActive}
          item={menuItem}
          currentPath={pathname}
        />,
      );
    }
  });
  const practiceName = _.get(practice, 'name', '');
  let logoUrl = _.get(practice, 'logoUrl', null) || images.defaultPracticeLogo;
  const doctors = _.get(auth, 'practiceMembers', []);
  const seoPageUrl = _.get(practice, 'seoPageUrl', false);
  return (
    <div className="Sidebar">
      <div className="Sidebar-inner-content">
        <div className="medical-content">
          <div className="medical-text">Practice</div>
          <div className="medical-name">
            <span className="medical-logo">
              <img src={logoUrl} alt="" />
            </span>
            <div>{practiceName}</div>
            {seoPageUrl && (
              <MyLink onClick={()=>setAppointmentSourceStore(1)} to={`${seoPageUrl}`} className="viewprofile">
                View practice profile
              </MyLink>
            )}
          </div>
        </div>
        <div className="menu">{menuitemsComponent}</div>
        <DoctorList doctorList={doctors} />
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
