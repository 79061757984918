const questionList = [
  {
    id: 21,
    question:
      'Have you had a recent traumatic injury to your foot where you suspect you may have fractured a bone?',
    optionType: 1, // {1=>'single line options',2=>'select option'} // other will be default UI
    descriptions: [],
    options: [
      {groupId: 'R', label: 'Yes',showKalyMd: true, shortLabel: 'Red flags? Yes'},
      {nextQuestionId: 22, label: 'No', shortLabel: 'Red flags? No'},
    ],
  },
  {
    id: 22,
    question: 'How long have you had foot pain for?',
    descriptions: [],
    options: [
      {nextQuestionId: 23, label: 'Less than 2 weeks', shortLabel: '<2 weeks'},
      {nextQuestionId: 24, label: 'More than 2 weeks', shortLabel: '>2 weeks'},
    ],
  },
  {
    id: 23,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'L',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching, and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'E',
        label: 'Preliminary imaging (Xrays) and/or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'E',
        label: 'Not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
        showKalyMd: true,
      },
    ],
  },
  {
    id: 24,
    question: 'Are you considering surgery?',
    descriptions: [],
    options: [
      {groupId: 'R', label: 'Yes, in the near future', shortLabel: 'Interested in surgery'},
      {
        nextQuestionId: 25,
        label: 'I am not considering surgery at this time',
        shortLabel: 'No surgery',
      },
    ],
  },
  {
    id: 25,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'L',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching, and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'S',
        label: 'Diagnostic imaging studies (MRI/Xray) and/or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'S',
        label: 'A minimally invasive procedure such as an injection.',
        shortLabel: 'Open to invasive procedures',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'S',
        label: 'I am not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
      },
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 21,
    treeDepth: 4,
  },
};
