import React, {useState, useEffect, useCallback, useContext} from 'react';
import Video from 'twilio-video';
import AuthContext from 'context/AuthContext';
import _ from 'lodash';
import VideoParticipant from 'components/VideoParticipant';
import './VideoPersonScreen.css';
import images from 'data/images';
//import {Link} from 'react-router-dom';
import moment from 'moment';
import constants from 'data/constants';

const {VideoUserType} = constants;
let roomToBeClosed = {};
let visibilityTime = '';
const VideoPersonScreen = props => {
  const {
    roomName,
    token,
    handleLogout,
    otherParticipantName,
    userType,
    goToClosedRoom,
    viewFullScreen,
    isExpand,
    showVideo,
    isMobile,
    checkTab,
  } = props;
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const firstName = _.get(auth, 'firstName', '');
  const lastName = _.get(auth, 'lastName', '');
  const profileImageUrl = _.get(auth, 'profileImageUrl', '');
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [videoMute, setVideoMute] = useState(false);
  const [audioMute, setAudioMute] = useState(false);
  //const [error, setError] = useState(null);
  const [isRemoteMutedVideo, setRemoteMuteVideo] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isParticipantPresent, setParticipantPresent] = useState(false);
  const [isBottomVisible, toggleBottombar] = useState(true);

  const remoteParticipants = participants.map(participant => {
    return (
      <VideoParticipant
        key={participant.sid}
        participant={participant}
        isMain={true}
        setRemoteMuteVideo={setRemoteMuteVideo}
        otherClass={isRemoteMutedVideo ? ' video-disable' : ''}
      />
    );
  });

  useEffect(() => {
    let timer = setInterval(() => {
      setDuration(duration + 1);
      clearInterval(timer);
    }, 1000);
    return () => clearInterval(timer);
  }, [duration]);

  useEffect(() => {
    setTimeout(() => {
      toggleBottombar(false);
    }, 10000);
  }, []);

  useEffect(() => {
    // When a Participant joins the Room
    const participantConnected = participant => {
      // setParticipants(prevParticipants => [...prevParticipants, participant]);
      setParticipants([participant]);
      setParticipantPresent(true);
    };

    // When a Participant leaves the Room
    const participantDisconnected = participant => {
      //console.log("participant",participant);
      if (userType === VideoUserType.PATIENT) {
        goToClosedRoom();
      }
      setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
      setParticipantPresent(false);
    };

    Video.connect(token, {
      name: roomName,
    })
      .then(room => {
        setRoom(room);
        roomToBeClosed = room;
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.once('participantDisconnected', participant => {
          console.log(`Participant "${participant.identity}" has disconnected from the Room`);
        });
        room.participants.forEach(participantConnected);
      })
      .catch(error => {
        console.log("twilio->error",error);
        //setError(error);
      });

    return () => {
      if (
        roomToBeClosed &&
        roomToBeClosed.localParticipant &&
        roomToBeClosed.localParticipant.state === 'connected'
      ) {
        roomToBeClosed.localParticipant.tracks.forEach(function (trackPublication) {
          trackPublication.track.stop();
        });
        roomToBeClosed.disconnect();
      }
    };
  }, [roomName, token, userType]);

  const muteUnmuteAudio = () => {
    if (room) {
      if (audioMute) {
        room.localParticipant.audioTracks.forEach(publication => {
          publication.track.enable();
        });
        setAudioMute(false);
      } else {
        room.localParticipant.audioTracks.forEach(publication => {
          publication.track.disable();
        });
        setAudioMute(true);
      }
    }
  };

  const muteUnmuteVideo = () => {
    if (room) {
      if (videoMute) {
        room.localParticipant.videoTracks.forEach(publication => {
          publication.track.enable();
        });
        setVideoMute(false);
      } else {
        room.localParticipant.videoTracks.forEach(publication => {
          publication.track.disable();
        });
        setVideoMute(true);
      }
    }
  };

  const handleRoomLogout = useCallback(
    event => {
      setRoom(prevRoom => {
      
        if (prevRoom) {
          prevRoom.localParticipant.tracks.forEach(trackPub => {
            trackPub.track.stop();
          });
          prevRoom.disconnect();
          handleLogout();
        }else{
          handleLogout();
        }
        roomToBeClosed = null;
        return null;
      });
    },
    [handleLogout],
  );
  const videoStyle = !showVideo ? 'video-hide' : '';
  const smallVideo = !showVideo && isMobile ? 'video-small' : 'videoscreen-inner';
  const patientStyle = userType === VideoUserType.PATIENT ? videoStyle : '';
  const bottomComponent = (
    <div className={`video-bottom ${!isBottomVisible ? 'video-timer' : ''}`}>
      <div className="video-timing">
        <img alt="description" src={images.description} />
        <span>{moment.utc(duration * 1000).format('mm:ss')}</span>
      </div>
      <div className="video-action">
        <button className="middle-link" onClick={muteUnmuteAudio}>
          {audioMute ? (
            <img alt="voice" src={images.voiceDisabled} />
          ) : (
            <img alt="voice" src={images.voice} />
          )}
        </button>
        <button className="middle-link" onClick={muteUnmuteVideo}>
          {videoMute ? (
            <img alt="videoCameraChat" src={images.videoCameraDisabled} />
          ) : (
            <img alt="videoCameraChat" src={images.videoCameraChat} />
          )}
        </button>
        <button className="middle-link" onClick={handleRoomLogout}>
          <img alt="phoneCall" src={images.phoneCall} />
        </button>
      </div>
      <div className="video-scale">
        <button className="scale-screen" onClick={() => viewFullScreen(!isExpand)}>
          <img alt="scaleScreen" src={images.scaleScreen} />
        </button>
      </div>
    </div>
  );

  return (
    <div
      className="VideoPersonScreen"
      onMouseOver={() => {
        if (!(!showVideo && isMobile)) {
          toggleBottombar(true);
          clearTimeout(visibilityTime);
          if (duration > 10) {
            visibilityTime = setTimeout(() => {
              toggleBottombar(false);
            }, 3000);
          }
        }
      }}>
      <div
        className={isExpand ? `videoscreen-full` : `${smallVideo} ${patientStyle}`}
        onClick={() => checkTab(true)}>
        {remoteParticipants}
        {!isParticipantPresent && (
          <div className="novideo-name">
            {otherParticipantName ? `${otherParticipantName} has not joined yet` : 'connecting..'}{' '}
          </div>
        )}
        {isParticipantPresent && isRemoteMutedVideo && (
          <div className="novideo-name">{otherParticipantName}</div>
        )}
        <div className={`other-participant ${!showVideo ? 'participant-hide' : ''}`}>
          {room && (
            <VideoParticipant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
              isMain={false}
              setRemoteMuteVideo={setRemoteMuteVideo}
              otherClass={videoMute ? 'video-disable' : ''}
            />
          )}
          {videoMute && (
            <>
              {profileImageUrl && <img alt="patient-img" src={profileImageUrl} />}
              <span className="participant-name">
                {firstName} {lastName}
              </span>
            </>
          )}
        </div>
        <div className={`video-sm-timing ${videoStyle}`}>
          <img alt="description" src={images.descriptionWhite} />
          <span>{moment.utc(duration * 1000).format('mm:ss')}</span>
        </div>
        {bottomComponent}
      </div>
    </div>
  );
};

export default VideoPersonScreen;
