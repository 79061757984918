import React from 'react';
import Header from 'layout/VideoLayout/Header';
import './VideoLayout.css';

const VideoLayout = props => {
  const {children} = props;
  return (
    <div className="VideoLayout">
      {!props.isExpand && <Header showExit={props.showExit} id={props.id} onExit={props.onExit} />}
      <div className={props.isExpand ? 'wrap-content-row-full' : 'wrap-content-row'}>
        {children}
      </div>
    </div>
  );
};

export default VideoLayout;
