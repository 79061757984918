import React, {Component} from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import _ from 'lodash';
import './ReviewRequest.css';
import adminService from '../../../services/admin';
import modalConfig from 'modals/modalConfig';
import images from 'data/images';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import ReadMore from 'components/ReadMore';
import constants from 'data/constants';
import AuthContext from 'context/AuthContext';

const {errorModalData, confirmModalData} = modalConfig.modalFunctions;
const {
  deletReviewRequestList,
  deleteProviderReview,
  rejectReviewDeleteRequest,
  getRequestCounts,
} = adminService;
const {DefaultDebounceMSec} = constants;

const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  pageLimit: 10,
  searchText: '',
  searchState: '',
  searchCity: '',
  sortBy: 'firstName',
  orderBy: 'asc',
  totalRecord: 0,
};

class ReviewRequest extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {...initialState};

  componentDidMount() {
    this.getDeleteReviewList(false, true);
  }

  componentWillMount = () => {
    this.props.history.replace({state: {...initialState}});
  };

  getDeleteReviewList = async (noLoad = false, findSearchData = false) => {
    const {location} = this.props;
    const isBackLink = _.get(location, 'state.isBackLink', false);
    if (isBackLink) {
      this.getSearchedData(location);
    } else {
      const {activePage, pageLimit, searchText, sortBy, orderBy} = this.state;
      const res = await deletReviewRequestList(
        {
          page: activePage,
          limit: pageLimit,
          search: searchText,
          sortBy: sortBy,
          orderBy: orderBy,
        },
        noLoad,
      );
      this.setState({
        list: _.get(res, 'data.reviewData', []),
        totalPage: _.get(res, 'data.totalPage', 0),
        totalRecord: _.get(res, 'data.totalRecord', 0),
      });
    }
  };

  getSearchedData = location => {
    const searchData = _.get(location, 'state', {
      activePage: 1,
      searchText: '',
      pageLimit: 10,
      sortBy: 'name',
      orderBy: 'asc',
      totalRecord: 0,
    });
    const {
      activePage,
      pageLimit,
      searchText,
      sortBy,
      orderBy,
      list,
      totalPage,
      totalRecord,
    } = searchData;
    this.setState({
      list: list,
      totalPage: totalPage,
      activePage: activePage,
      searchText: searchText,
      pageLimit: pageLimit,
      sortBy: sortBy,
      orderBy: orderBy,
      totalRecord: totalRecord,
    });
  };

  handleError = error => {
    const {setGlobal} = this.rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.getDeleteReviewList,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.getDeleteReviewList);
    }
  };

  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.getDeleteReviewList();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };

  handleSearch = () => {
    this.setState({activePage: 1}, this.getDeleteReviewList);
  };

  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.getDeleteReviewList);
  };

  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.getDeleteReviewList);
  };

  handleReviewDelete = reviewId => {
    const {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete this Review ?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmDeleteReview(reviewId);
          }
        },
      }),
    );
  };

  confirmDeleteReview = async reviewId => {
    const response = await deleteProviderReview(reviewId).catch(this.handleError);
    if (response) {
      this.getDeleteReviewList();
      this.updateContextData();
    }
  };

  handleRejectRequest = (userId, reviewId) => {
    const {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to reject this Review Request?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmRejectRequest(userId, reviewId);
          }
        },
      }),
    );
  };

  confirmRejectRequest = async (userId, reviewId) => {
    const data = {
      reviewId: reviewId,
      hasRequestedForDelete: false,
    };
    const response = await rejectReviewDeleteRequest(userId, data).catch(this.handleError);
    if (response) {
      this.getDeleteReviewList();
      this.updateContextData();
    }
  };

  handleViewDetails = userId => {
    const params = new URLSearchParams();
    if (userId) {
      params.append('user', userId);
    }
    params.append('isRequest', 1);
    this.props.history.push({
      pathname: '/admin/provider-reviews',
      search: `?${params.toString()}`,
      state: {...this.state, isBackLink: true},
    });
  };

  updateContextData = async () => {
    const requestCountData = await getRequestCounts().catch(() => null);
    if (requestCountData && requestCountData.data) {
      const {setAdminRequestCountState} = this.context;
      setAdminRequestCountState(prevState => {
        return {
          ...prevState,
          requestCount: {
            ...requestCountData.data,
          },
        };
      });
    }
  };

  render() {
    const {name = 'Review Approvals'}=this.props;
    const {list, totalPage, searchText, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    return (
      <Layout>
        <RootContext.Consumer>
          {context => {
            this.rootContext = context;
          }}
        </RootContext.Consumer>
        <div className="ReviewRequest">
          <SubHeader title={name} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div className="input-control">
                <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
                {searchText && (
                  <button className="btn-clear" onClick={this.handleClearSearch}>
                    <img src={images.close} alt="clear" />
                  </button>
                )}
              </div>
              <button onClick={this.handleSearch}>Search</button>
            </div>
            <div className="dataCount">
              <span>{totalRecord} review approvals found.</span>
            </div>
            {
              <div className="table-overflow">
                <div className="rtable">
                  <div className="heading">
                    <div
                      className="cell pointer"
                      data-key="firstName"
                      onClick={this.handleSortingChange}>
                      Provider Name{this.renderShortIcon('firstName')}
                    </div>
                    <div
                      className="cell pointer"
                      data-key="email"
                      onClick={this.handleSortingChange}>
                      Provider Email{this.renderShortIcon('email')}
                    </div>
                    <div
                      className="cell pointer"
                      data-key="review"
                      onClick={this.handleSortingChange}>
                      Review{this.renderShortIcon('review')}
                    </div>
                    <div
                      className="cell pointer"
                      data-key="createdAt"
                      onClick={this.handleSortingChange}>
                      Review Date{this.renderShortIcon('createdAt')}
                    </div>
                    <div className="cell edit-cell">Action</div>
                  </div>
                  {list.length > 0 ? (
                    _.map(list, (review, index) => (
                      <div className="row" key={index}>
                        <div className="cell">
                          {review.reviewRelation &&
                            review.reviewRelation.provider &&
                            review.reviewRelation.provider.seoPageUrl && (
                              <span
                                className="link-span"
                                onClick={() =>
                                  window.open(review.reviewRelation.provider.seoPageUrl, '_blank')
                                }>
                                {`${_.get(review.reviewRelation, 'firstName', '')} ${_.get(
                                  review.reviewRelation,
                                  'lastName',
                                  '',
                                )}`}
                              </span>
                            )}
                          {review.reviewRelation &&
                            !review.reviewRelation.provider.seoPageUrl &&
                            `${_.get(review.reviewRelation, 'firstName', '')} ${_.get(
                              review.reviewRelation,
                              'lastName',
                              '',
                            )}`}
                        </div>
                        <div className="cell">{_.get(review.reviewRelation, 'email', '')}</div>
                        <div className="cell">
                          <ReadMore
                            textString={_.get(review, 'review', '')}
                            min={50}
                            readMoreText="read more"
                          />
                        </div>
                        <div className="cell">
                          {this.formatedDate(_.get(review, 'createdAt', ''))}
                        </div>
                        <div className="cell">
                          <button
                            className="btn-view"
                            onClick={() =>
                              this.handleViewDetails(_.get(review.reviewRelation, 'id', ''))
                            }>
                            View Details
                          </button>
                          <button
                            className="btn-delete"
                            onClick={() => this.handleReviewDelete(_.get(review, 'id', ''))}>
                            Delete Review
                          </button>
                          <button
                            className="btn-delete"
                            onClick={() =>
                              this.handleRejectRequest(
                                _.get(review.reviewRelation, 'id', ''),
                                _.get(review, 'id', ''),
                              )
                            }>
                            Reject Request
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-record">No Records found</div>
                  )}
                </div>
              </div>
            }
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default ReviewRequest;
