import React from 'react';
import AuthContext from './AuthContext';
import RootContext from './RootContext';

const AuthContextConsumer = (componentSelf, index) => {
  return (
    <AuthContext.Consumer>
      {authContext => {
        componentSelf[index || 'authContext'] = authContext;
        return null;
      }}
    </AuthContext.Consumer>
  );
};

const RootContextConsumer = (componentSelf, index) => {
  return (
    <RootContext.Consumer>
      {rootContext => {
        componentSelf[index || 'rootContext'] = rootContext;
        return null;
      }}
    </RootContext.Consumer>
  );
};

const contextHelper = {
  AuthContextConsumer,
  RootContextConsumer,
};

export default contextHelper;
