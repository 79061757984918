import React, {useEffect} from 'react';
import helper from 'data/helper';
import _ from 'lodash';
import './GoogleLoginButton.css';

const {loadScript} = helper;

let GoogleAuth = null;

const GoogleLoginButton = props => {
  const {
    className = '',
    scope = 'profile',
    appId,
    children,
    onLoginSuccess = () => {},
    onLoginFailure = () => {},
  } = props;

  useEffect(() => {
    try {
      loadScript(document, 'script', 'google-jssdk', 'https://apis.google.com/js/api.js', e => {
        window.gapi.load('auth2', () => {
          if(!document.querySelector('#google-jssdk2')){
          GoogleAuth = window.gapi.auth2.init({
            client_id: appId,
            cookiepolicy: 'single_host_origin',
            // fetch_basic_profile: false,
            scope,
          });
        }else{
        
          GoogleAuth = window.gapi.auth2.getAuthInstance({
            client_id: appId,
            cookiepolicy: 'single_host_origin',
            // fetch_basic_profile: false,
            scope,
          });
        }
        });
      });
    } catch (error) {
      console.log("error",error);
      
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = () => {
    if (GoogleAuth) {
      GoogleAuth.signIn({}).then(
        res => onStatusChange(res),
        err => onStatusChange(err),
      );
    }
  };

  const onStatusChange = response => {
    try {
      //https://github.com/anthonyjgrove/react-google-login/issues/17
      let res = response.getAuthResponse();
      if (res && _.get(res, 'access_token', false) && _.get(res, 'id_token', false)) {
        onLoginSuccess({
          access_token: _.get(res, 'access_token', false),
          id_token: _.get(res, 'id_token', false),
        });
      } else {
        onLoginFailure(response);
      }
    } catch (ex) {
      onLoginFailure(response);
    }
  };

  return (
    <span className={`GoogleLoginButton ${className}`} onClick={login}>
      {children}
    </span>
  );
};

export default GoogleLoginButton;
