import React, {useState} from 'react';
import images from 'data/images';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import CircleButton from 'components/CircleButton';
import selectOptions from 'data/selectOptions';
import validators from 'data/validators';
import allRegex from 'data/regex';
import config from 'config/config';
import './AddPatientModal.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {patientGenderOptions, legalGuardianOptions} = selectOptions;
const {__required, __maxLength, __email, __eq} = validators;
const {nameRegex} = allRegex;
const {MAX_TEXT_LENGTH} = config;
const defaultFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  gender: '',
  relation: '',
};
const {Analytics} = analyticsData;

const AddPatientModal = props => {
  const {closeModal} = props;
  const {onSavePatient} = props;
  const [formData, setFormData] = useState({
    values: {...defaultFormValues},
    errors: {},
  });
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'First name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'Last name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateGender = (value = null) => {
    const fValue = value !== null ? value : formValues['gender'];
    if (!__required(fValue)) {
      return 'Gender is required';
    }
    return null;
  };
  const __validateRelation = (value = null) => {
    const fValue = value !== null ? value : formValues['relation'];
    if (!__required(fValue)) {
      return 'This field is required';
    }
    return null;
  };
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (fValue && !__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  const __validateConfirmEmail = (value = null, emailValue = null) => {
    const fValue = value !== null ? value : formValues['confirmEmail'];
    const eValue = emailValue !== null ? emailValue : formValues['email'];
    if (!__eq(fValue, eValue)) {
      return 'Email and Confirm Email must be same';
    }
    return null;
  };
  const __validateForm = () => {
    const validEmail = !__validateEmail();
    const validConfirmEmail = !__validateConfirmEmail();
    const validFirstName = !__validateFirstName();
    const validLastName = !__validateLastName();
    const validGender = !__validateGender();
    const validRelation = !__validateRelation();
    return (
      validEmail &&
      validConfirmEmail &&
      validFirstName &&
      validLastName &&
      validGender &&
      validRelation
    );
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'gender':
        errorObject = {
          [name]: __validateGender(value),
        };
        break;
      case 'relation':
        errorObject = {
          [name]: __validateRelation(value),
        };
        break;
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
          confirmEmail: __validateConfirmEmail(null, value),
        };
        break;
      case 'confirmEmail':
        errorObject = {
          email: __validateEmail(),
          [name]: __validateConfirmEmail(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleForm = () => {
    Nux.sendEvent(Analytics.Category.PatientAppointment, Analytics.Actions.Click, 'addNewPatient');
    onSavePatient(formValues, closeModal);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  return (
    <div className="AddPatientModal">
      <div className="modal-content-header">
        <div className="h3">Add Patient </div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <form>
          <div className="form-group-2-colum">
            <Input {...getGeneralInputProps('firstName')} label="First name" />
            <Input {...getGeneralInputProps('lastName')} label="Last name" />
          </div>
          <Input {...getGeneralInputProps('email')} label="Patient’s Email (Optional)" />
          <Input
            {...getGeneralInputProps('confirmEmail')}
            label="Patient’s Confirm Email (Optional)"
          />
          <Select
            {...getGeneralInputProps('gender')}
            label="Gender"
            options={patientGenderOptions}
          />
          <Select
            {...getGeneralInputProps('relation')}
            label="Are you the parent or legal guardian of the above patient?"
            options={legalGuardianOptions}
            containerClass="confirm-patient-select"
          />
        </form>
      </div>
      <div className="modal-content-footer">
        <CircleButton disabled={!isValidForm} onClick={handleForm}>
          Continue
        </CircleButton>
      </div>
    </div>
  );
};

export default AddPatientModal;
