import React, {useContext} from 'react';
import CircleButton from 'components/CircleButton';
import Divider from 'components/Divider';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import _ from 'lodash';
import './ConfirmTemplate.css';

const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;

const ConfirmTemplate = props => {
  const rootContext = useContext(RootContext);
  const {
    closeModal,
    confirmMessage = 'Are you sure you want to delete?',
    lastModalConfig,
    onConfirm,
    confirmTitle,
    CustomFooter
  } = props;
  const onButtonClick = isConfirmed => {
    if (isConfirmed) {
      onConfirm(true);
    } else {
      if (!_.isEmpty(lastModalConfig)) {
        rootContext.setGlobal(
          'modal',
          blankModalData({
            ...lastModalConfig,
          }),
        );
      } else {
        closeModal();
      }
    }
  };
  const CustomBtnClick=val=>{
  }
  return (
    <div className="ConfirmTemplate">
      <div className="modal-content-header">
        <div className="h3">{confirmTitle?confirmTitle:'Confirm'}</div>
      </div>
      <div className="modal-content-body">
        <div className="confirm-msg">{confirmMessage}</div>
      </div>
      <Divider></Divider>
      {CustomFooter?(
        <CustomFooter />
      ):(
      <div className="modal-content-footer">
        <CircleButton btnType="secondaryButton" onClick={() => onButtonClick(false)}>
          No
        </CircleButton>
        <CircleButton onClick={() => onButtonClick(true)}>Yes</CircleButton>
        
      </div>
      )}
    </div>
  );
};

export default ConfirmTemplate;
