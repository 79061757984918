import React, {Component} from 'react';
import Layout from 'layout';
import FinalizeAccount from 'components/SignUpSteps/FinalizeAccount';
import AboutYourPractice from 'components/SignUpSteps/AboutYourPractice';
import AboutYouAsProvider from 'components/SignUpSteps/AboutYouAsProvider';
import InviteYourProviders from 'components/SignUpSteps/InviteYourProviders';
import PaymentSession from 'components/SignUpSteps/PaymentSession'
import userService from 'services/user';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import signUpSteps from 'data/signUpSteps';
import masterService from 'services/master';
import './SignUp.css';
import config from 'config/config';

const {getAllSpecialties, getAllCertifications, getAllSuffixes} = masterService;
const {getUserFromToken, signUp,registerPracticeWithStripe} = userService;
const {successModalData, errorModalData} = modalConfig.modalFunctions;
const {defaultSignUpStepsData, stepFunctions} = signUpSteps;
const {step1Data, step2Data, step3Data, step4Data} = defaultSignUpStepsData;
const {_formatStepsData} = stepFunctions;

class SignUp extends Component {
  static contextType = RootContext;
  state = {
    currentStep: 1,
    step1Data: {
      ...step1Data,
    },
    step2Data: {
      ...step2Data,
    },
    step3Data: {
      ...step3Data,
    },
    step4Data: {
      ...step4Data,
    },
    step5Data: {
      ...step4Data,
    },
    email: '',
    isTokenVerified: false,
    hasInvited: false,
    npiNumber:''
  };
  token = null;

  componentDidMount() {
    this.handleTokenVerification();
  }

  handleTokenVerification = async () => {
    const {match} = this.props;
    const {params} = match;
    const {token} = params;
    if (token) {
      this.token = token;
      const isTokenVerified = await this.verifyToken(token);
      if (isTokenVerified) {
        this.getSpecialities();
        this.getCertifications();
        this.getSuffixes();
      }
    } else {
      this.handleError({message: 'Token is required'});
    }
  };

  verifyToken = async token => {
    const response = await getUserFromToken(token).catch(error => {
      const {history} = this.props;
      const {setGlobal} = this.context;
      const {message} = error;
      setGlobal(
        'modal',
        errorModalData(message, {
          callBackOnClose: () => {
            history.push('/');
          },
        }),
      );
      return null;
    });
    if (response) {
      const {data} = response;
      const {email,npiNumber,practiceName=''} = data;
      const newStepsData = _formatStepsData(data);
      this.setState(prevState => {
        return {
          ...prevState,
          ...newStepsData,
          email,
          practiceName:practiceName,
          npiNumber:npiNumber,
          isTokenVerified: true,
        };
      });
      return true;
    } else {
      return false;
    }
  };

  // get and set specialties to global store
  getSpecialities = async () => {
    const {setGlobal, specialties} = this.context;
    if (!specialties) {
      const response = await getAllSpecialties().catch(this.handleError);
      if (response) {
        const {data: specialties} = response;
        setGlobal('specialties', specialties);
      }
    }
  };

  // get and set certifications to global store
  getCertifications = async () => {
    const {setGlobal, certifications} = this.context;
    if (!certifications) {
      const response = await getAllCertifications().catch(this.handleError);
      if (response) {
        const {data: certifications} = response;
        setGlobal('certifications', certifications);
      }
    }
  };

  // get and set suffixes to global store
  getSuffixes = async () => {
    const {setGlobal, suffixes} = this.context;
    if (!suffixes) {
      const response = await getAllSuffixes().catch(this.handleError);
      if (response) {
        const {data: suffixes} = response;
        setGlobal('suffixes', suffixes);
      }
    }
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  completePlanSelection = async (formValues, handleError = () => {}) => {
  
    const {currentStep, hasInvited} = this.state;
   
    let nextStep = currentStep + 1;
    if (hasInvited) {
      nextStep = nextStep + 2;
    }
    
    const response = await registerPracticeWithStripe({
      ...formValues,
      hasInvited,
      token: this.token,
      step: currentStep,
    }).catch(error => {
      this.handleError(error);
      handleError(error);
    });
    if (response) {
      const {message} = response;
      const updatedStateObject = {
        [`step${currentStep}Data`]: {
          ...formValues,
        },
      };
    
       this.setState({currentStep: nextStep});
     
       // this.setState({});
      
    }
  };
  changeStep = async (formValues, handleError = () => {}) => {
    const {setGlobal} = this.context;
    const {currentStep, hasInvited, step3Data} = this.state;
    const {history} = this.props;
    let nextStep = currentStep + 1;
    if(config.showStripe==false && currentStep==2){
      nextStep=nextStep+1
    }
    if (hasInvited) {
      nextStep = nextStep + 2;
    }
    
    const response = await signUp({
      formValues,
      hasInvited,
      token: this.token,
      step: currentStep,
    }).catch(error => {
      this.handleError(error);
      handleError(error);
    });
    if (response) {
      const {message} = response;
      const updatedStateObject = {
        [`step${currentStep}Data`]: {
          ...formValues,
        },
      };
      if (currentStep === 1) {
        const {firstName, lastName} = formValues;
        updatedStateObject['step3Data'] = {
          ...step3Data,
          subStep1Data: {
            ...step3Data.subStep1Data,
            firstName,
            lastName,
          },
        };
      }
      this.setState(updatedStateObject);
      const isAllStepCompleted =
        (hasInvited && currentStep === 4) || (!hasInvited && currentStep === 5);
      if (isAllStepCompleted) {
        setGlobal(
          'modal',
          successModalData(message, {
            callBackOnClose: () => {
              history.push('/login');
            },
          }),
        );
      } else {
        this.setState({currentStep: nextStep});
      }
    }
  };

  _currentStepComponent = () => {
    const {currentStep, step1Data, step2Data, step3Data, step4Data,step5Data, email,npiNumber,practiceName} = this.state;
    const {history} = this.props;
    let passstep2Data={...step2Data}
    if(practiceName){
      passstep2Data['subStep1Data'].name=practiceName
    }
    let component = null;
    switch (currentStep) {
      case 1:
        component = <FinalizeAccount email={email} data={step1Data} nextStep={this.changeStep} />;
        break;
      case 2:
        //AboutYourPractice
        component = (
          <AboutYourPractice
            token={this.token}
            email={email}
            data={passstep2Data}
            nextStep={this.changeStep}
          />
        );
        break;
        case 3:
        component = (
          <PaymentSession
            token={this.token}
            email={email}
            data={step2Data}
            nextStep={this.completePlanSelection}
          />
        );
        break;
      case 4:
        component = <AboutYouAsProvider data={step3Data} npiNumber={npiNumber} nextStep={this.changeStep} />;
        break;
      case 5:
        component = <InviteYourProviders data={step5Data} nextStep={this.changeStep} />;
        break;
      default:
        // if step is invalid,redirect user to home page.
        history.push('/');
        break;
    }
    return component;
  };

  render() {
    const {currentStep, hasInvited, isTokenVerified} = this.state;
    if (!isTokenVerified) {
      return null;
    }
    const currentStepComponent = this._currentStepComponent();
    const _currentStepClasses = step => {
      return `step-li ${currentStep >= step && 'current'}`;
    };
    let stepNameComponent = null;
    if (!hasInvited) {
      if(config.showStripe===true){
      stepNameComponent = (
        <>
          <div className={_currentStepClasses(1)}>1. Finalize Account</div>
          <div className={_currentStepClasses(2)}>2. About your Practice</div>
          <div className={_currentStepClasses(3)}>3. Plan Selection</div>
          <div className={_currentStepClasses(4)}>4. About you as a Provider</div>
          <div className={_currentStepClasses(5)}>5. Invite your Providers</div>
        </>
      );
      }else{
        stepNameComponent = (
          <>
            <div className={_currentStepClasses(1)}>1. Finalize Account</div>
            <div className={_currentStepClasses(2)}>2. About your Practice</div>
            <div className={_currentStepClasses(4)}>3. About you as a Provider</div>
            <div className={_currentStepClasses(5)}>4. Invite your Providers</div>
          </>
        );
      }
     
    } else {
      stepNameComponent = (
        <>
          <div className={_currentStepClasses(1)}>1. Finalize Account</div>
          <div className={_currentStepClasses(2)}>2. Provider Details</div>
        </>
      );
    }
    return (
      <Layout>
        <div className="SignUp">
          <div className="container">
            <div className="signup-row">
              <div className="step-ul">{stepNameComponent}</div>
              <div className="middle-section">{currentStepComponent}</div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default SignUp;
