import React, {useState, useEffect, useContext} from 'react';
import _ from 'lodash';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import Textarea from 'components/FormControls/Textarea';
import TagsInput from 'components/FormControls/TagsInput';
import CircleButton from 'components/CircleButton';
import selectOptions from 'data/selectOptions';
import validators from 'data/validators';
import './ProviderOverview.css';
import helper from 'data/helper';
import images from 'data/images';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';

const {isArrayEqual} = helper;
const {genderOptions, patientsTypeOptions} = selectOptions;
const NPI_LINK = 'https://npiregistry.cms.hhs.gov/';
const {__required, __isNumeric, __eqLength, __minLength, _scrollToView} = validators;
const {modalFunctions} = modalConfig;
const {confirmModalData} = modalFunctions;
const ProviderOverview = props => {
  const {data, formData, setFormData, options, onSaveOverView, setChangedSection,isAdmin} = props;
  const {suffixList} = options;
  const {values: formValues, errors: formErrors} = formData;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  useEffect(() => {
    const isAnyChanged =
      checkProfessionalIntroduction() ||
      checkGender() ||
      checkHasPatientsAccepted() ||
      checkNpi() ||
      checkSuffixes();
    setSectionActivation(isAnyChanged);
  }, [
    formValues.professionalIntroduction,
    formValues.gender,
    formValues.hasPatientsAccepted,
    formValues.npi,
    formValues.suffixes,
  ]);

  const checkProfessionalIntroduction = () => {
    const ischanged = !_.isEqual(
      _.get(data, 'professionalIntroduction', ''),
      _.get(formValues, 'professionalIntroduction', ''),
    );
    return ischanged;
  };

  const checkGender = () => {
    const ischanged = !_.isEqual(
      _.get(data, 'gender', ''),
      _.toNumber(_.get(formValues, 'gender', '')),
    );
    return ischanged;
  };

  const checkHasPatientsAccepted = () => {
    const ischanged = !_.isEqual(
      _.get(data, 'hasPatientsAccepted', ''),
      _.toNumber(_.get(formValues, 'hasPatientsAccepted', '')),
    );
    return ischanged;
  };

  const checkNpi = () => {
    const ischanged = !_.isEqual(_.get(data, 'npi', ''), _.get(formValues, 'npi', ''));
    return ischanged;
  };
  const seoLinkContainsNPI = () => {
    return data?.seoPage?.includes(data.npi);
  };
  const checkSuffixes = () => {
    const ischanged = !isArrayEqual(_.get(data, 'suffixes', ''), _.get(formValues, 'suffixes', []));
    return ischanged;
  };

  const setSectionActivation = isChanged => {
    if (isChanged) {
      setChangedSection('overview');
    } else {
      setChangedSection('');
    }
  };

  // Validation functions
  const __validateProfessionalIntroduction = (value = null) => {
    const fValue = value !== null ? value : formValues['professionalIntroduction'];
    if (!__required(fValue)) {
      return 'Professional Introduction is required';
    }
    return null;
  };
  const __validateGender = (value = null) => {
    const fValue = value !== null ? value : formValues['gender'];
    if (!__required(fValue)) {
      return 'Gender is required';
    }
    return null;
  };
  const __validateHasPatientsAccepted = (value = null) => {
    const fValue = value !== null ? value : formValues['hasPatientsAccepted'];
    if (!__required(fValue)) {
      return 'Patients accepted is required';
    }
    return null;
  };
  const __validateNpi = (value = null) => {
    const fValue = value !== null ? value : formValues['npi'];
    if (!__required(fValue)) {
      return 'NPI is required';
    }
    if (!__isNumeric(fValue)) {
      return `Must be numaric`;
    }
    if (!__eqLength(fValue, 10)) {
      return `Must be 10 digits`;
    }
    return null;
  };
  const __validateSuffix = (value = null) => {
    const fValue = value !== null ? value : formValues['suffixes'];
    if (!__required(fValue)) {
      return 'Professional Suffix is required';
    }
    if (!__minLength(fValue, 1)) {
      return 'Professional Suffix is required';
    }
    return null;
  };
  const __validateForm = () => {
    const validProfessionalIntroduction = !__validateProfessionalIntroduction();
    const validGender = !__validateGender();
    const validHasPatientsAccepted = !__validateHasPatientsAccepted();
    const validNpi = !__validateNpi();
    const validSuffix = !__validateSuffix();
    return (
      validProfessionalIntroduction &&
      validGender &&
      validHasPatientsAccepted &&
      validNpi &&
      validSuffix
    );
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'professionalIntroduction':
        errorObject = {
          [name]: __validateProfessionalIntroduction(value),
        };
        break;
      case 'gender':
        errorObject = {
          [name]: __validateGender(value),
        };
        break;
      case 'hasPatientsAccepted':
        errorObject = {
          [name]: __validateHasPatientsAccepted(value),
        };
        break;
      case 'npi':
        errorObject = {
          [name]: __validateNpi(value),
        };
        break;
      case 'suffixes':
        errorObject = {
          [name]: __validateSuffix(value),
        };
        break;
      default:
        break;
    }
    // setFormData({
    //   ...formData,
    //   values: {
    //     ...formData.values,
    //     [name]: value,
    //   },
    //   errors: {
    //     ...formData.errors,
    //     ...errorObject,
    //   },
    // });
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };
  const CustomFooter = () => {
    return (
      <div className="modal-content-footer custom-footer-warning">
        <CircleButton
          onClick={() => {
            let tempformValues = {...formValues};
            tempformValues.isAllowToUpdateSeoPage = 1;
            onSaveOverView(tempformValues);
          }}>
          Save and Update SEO Link
        </CircleButton>
        <CircleButton
          onClick={() => {
            let tempformValues = {...formValues};
            tempformValues.isAllowToUpdateSeoPage = 0;
            onSaveOverView(tempformValues);
          }}>
          Save and Do Not Update SEO Link
        </CircleButton>
        <CircleButton
          btnType="secondaryButton"
          onClick={() => setGlobal('modal', {showModal: false})}>
          Cancel
        </CircleButton>
      </div>
    );
  };
  const handleForm = () => {
    if (!isValidForm) {
      let valError = [
        'professionalIntroduction',
        'gender',
        'hasPatientsAccepted',
        'npi',
        'suffixes',
      ];
      valError.forEach(name => {
        changeHandler({target: {name: name, value: formValues[name]}});
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    if (checkNpi()&&seoLinkContainsNPI() && isAdmin) {
      setGlobal(
        'modal',
        confirmModalData({
          confirmTitle: `Confirm SEO Link should also be updated.`,
          confirmMessage: (
            <div className="confirm-seolink">
              <div>
                <p><span>Current SEO Link:</span> <span>{data.seoPage}</span></p>
                <p><span>New SEO Link:</span> <span>{data.seoPage.replace(data.npi, formValues['npi'])}</span></p>
              </div>
            </div>
          ),
          lastModalConfig: {
            ...props.componentProps,
          },
          CustomFooter: CustomFooter,
          onConfirm: isConfirmed => {
            if (isConfirmed) {
              onSaveOverView(formValues);
            }
          },
        }),
      );
    } else {
      let tempformValues = {...formValues};
      tempformValues.isAllowToUpdateSeoPage = 0;
      tempformValues.professionalIntroduction=tempformValues.professionalIntroduction.trim()
      onSaveOverView(tempformValues);
    }
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  const onAdd = (name, specialty) => {
    const customEvent = {
      target: {
        value: [...formValues[name], specialty],
        name,
      },
    };
    changeHandler(customEvent);
  };
  const onRemove = (name, specialty) => {
    const updatedSpecialities = [...formValues[name]];
    _.remove(updatedSpecialities, value => value === specialty);
    const customEvent = {
      target: {
        value: updatedSpecialities,
        name,
      },
    };
    changeHandler(customEvent);
  };
  const onInputBlur = (name, selectedValues) => {
    const customEvent = {
      target: {
        value: selectedValues,
        name,
      },
    };
    changeHandler(customEvent);
  };
  const PendingNpiNumber = pendingNpiNumber => {
    if (pendingNpiNumber)
      return (
        <span>
          NPI#
          <span style={{margin: '0 10px'}} className="Pending">
            <img src={images.minus} alt="" />
          </span>
          {pendingNpiNumber}
        </span>
      );
    else return <span>NPI#</span>;
  };

  return (
    <div className="ProviderOverview">
      <div className="titleHeading">
        <div className="h3">Provider Overview</div>
      </div>
      <div className="ProviderOverview-inner">
        <form>
          <Textarea
            {...getGeneralInputProps('professionalIntroduction')}
            label="Professional Introduction"
            rows="5"
            placeholder="Professional history, methods and philosophy, or other points about you as a provider"
          />
          <Select {...getGeneralInputProps('gender')} label="Gender" options={genderOptions} />
          <Select
            {...getGeneralInputProps('hasPatientsAccepted')}
            label="Patients accepted"
            options={patientsTypeOptions}
          />
          <Input
            {...getGeneralInputProps('npi')}
            label={PendingNpiNumber(data.pendingNpiNumber)}
            linkData={{externalLink: NPI_LINK, label: 'Look Up'}}
            containerClass={data.npiNumberFlag === false ? 'error-text' : ''}
          />
          {/* {data.pendingNpiNumber && ( */}

          {/* )} */}
          <TagsInput
            className="suffix-tag-input"
            error={formErrors['suffixes']}
            label="Professional Suffix"
            options={suffixList}
            labelIndex="suffix"
            valueIndex="id"
            selectedValues={formValues['suffixes']}
            onAdd={item => onAdd('suffixes', item)}
            onRemove={item => onRemove('suffixes', item)}
            onInputBlur={selectedList => onInputBlur('suffixes', selectedList)}
            checkStatus={true}
          />
        </form>
      </div>
      <div className="overview-bottom">
        <CircleButton onClick={handleForm}>Save</CircleButton>
      </div>
    </div>
  );
};

export default ProviderOverview;
