import React from 'react';
import _ from 'lodash';
import images from 'data/images';
import helper from 'data/helper';
import momentHelper from 'data/moment/momentHelper';
import './DoctorAppointmentTime.css';
import TimeZoneLabelShort from 'components/MyDatetime/TimeZoneLabelShort';

const {utcToLocalMoment, readableTimeFormat} = momentHelper;
const {getSuffixString} = helper;

const DoctorAppointmentTime = props => {
  const {appointment,showAppointmentTime=true,showMsg=false,msg=''} = props;
  const {appointmentStartDateTime} = appointment;
  const apStartDateTimeObj = utcToLocalMoment(appointmentStartDateTime);
  const location = _.get(appointment, 'providerAvailability.practiceLocation');
  const provider = _.get(appointment, 'providerAvailability.provider');
  const specialties = _.get(provider, 'specialties', []);
  const suffixes = _.get(provider, 'suffixes', []);
  const doctorUser = _.get(provider, 'user', null);
  const customSpecialty= _.get(provider, 'customSpecialty', null);
  let doctorName = _.get(provider, 'doctorName', '');
  if (doctorUser) {
    doctorName = `${doctorUser.firstName} ${doctorUser.lastName}`;
  }
  let specialtyNames = _.map(specialties, 'specialty');
  if(customSpecialty){
    specialtyNames=[...specialtyNames,customSpecialty]
  }
  return (
    <div className="DoctorAppointmentTime">
      {showAppointmentTime &&(
      <div className="appointment-time">
        <span className="day">{apStartDateTimeObj.format('dddd')}</span>
        <span className="month">{apStartDateTimeObj.format('MMM DD')}</span>
        <span className="time">{readableTimeFormat(apStartDateTimeObj)}<TimeZoneLabelShort/></span>
      </div>
      )}
      <div className="dc-info">
       
        <div className="name">
          {doctorName}
          {getSuffixString(suffixes)}
        </div>
        <div className="dc-specialist">{specialtyNames.join(', ')}</div>
        <div className="address">
          <span className="icon">
            <img src={images.mapslocation} alt="address" />
          </span>
          <span>{location.address}</span>
        </div>
        {showMsg &&(
        <div>
          <span className='msg'>{msg}</span>
        </div>
        )}
      </div>
    </div>
  );
};

export default DoctorAppointmentTime;
