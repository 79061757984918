import React, {Component} from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import masterService from 'services/master';
import ReactPaginate from 'react-paginate';
import constants from 'data/constants';
import _ from 'lodash';
import './GhostProfiles.css';
import helper from 'data/helper';
import images from 'data/images';
import Select from 'components/FormControls/Select';

const {DefaultDebounceMSec} = constants;
const initialState = {
  list: [],
  stateList: [],
  cityList: [],
  activePage: 1,
  totalPage: 0,
  pageLimit: 10,
  stateId: 0,
  searchText: '',
  searchState: '',
  searchCity: '',
  sortBy: 'firstName',
  orderBy: 'asc',
  totalRecord: 0,
};

const {getNPIProviders, generateNPIProviders, deleteNPIProvider} = adminService;
const {getAllStates, getCities} = masterService;
const {ClaimStatus, ProviderGender} = constants;
const {modalFunctions} = modalConfig;
const {errorModalData, confirmModalData, successModalData} = modalFunctions;
const {getSuffixString} = helper;

class GhostProfiles extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount() {
    this.setNPIProviderList(false, true);
    this.getStateList();
  }

  componentWillMount = () => {
    this.props.history.replace({state: {...initialState}});
  };

  setNPIProviderList = async (noLoad = false, findSearchData = false) => {
    const {location} = this.props;
    const isBackLink = _.get(location, 'state.isBackLink', false);
    if (isBackLink) {
      this.getSearchedData(location);
    } else {
      const {
        activePage,
        pageLimit,
        searchText,
        searchState,
        searchCity,
        sortBy,
        orderBy,
      } = this.state;
      const res = await getNPIProviders(
        {
          page: activePage,
          limit: pageLimit,
          search: searchText,
          stateId: searchState,
          cityId: searchCity,
          sortBy: sortBy,
          orderBy: orderBy,
        },
        noLoad,
      );
      this.setState({
        list: _.get(res, 'data.list', []),
        totalPage: _.get(res, 'data.totalPage', 0),
        totalRecord: _.get(res, 'data.totalRecord', 0),
      });
    }
  };

  getSearchedData = async location => {
    const searchData = _.get(location, 'state', {
      activePage: 1,
      totalPage: 0,
      searchText: '',
      pageLimit: 10,
      sortBy: 'firstName',
      orderBy: 'asc',
      searchState: '',
      searchCity: '',
      stateId: 0,
    });
    if (searchData.stateId) {
      this.getCityList(searchData.stateId);
    }
    const {
      activePage,
      pageLimit,
      searchText,
      searchState,
      searchCity,
      sortBy,
      orderBy,
      stateId,
      list,
      totalPage,
      totalRecord,
    } = searchData;
    this.setState({
      list: list,
      totalPage: totalPage,
      totalRecord: totalRecord,
      activePage: activePage,
      searchText: searchText,
      pageLimit: pageLimit,
      sortBy: sortBy,
      orderBy: orderBy,
      searchState: searchState,
      searchCity: searchCity,
      stateId: stateId,
    });
  };

  getStateList = async () => {
    const res = await getAllStates();
    this.setState({stateList: _.get(res, 'data', [])});
  };

  getCityList = async state => {
    const stateId = state ? state : this.state.stateId;
    const res = await getCities(stateId);
    this.setState({cityList: _.get(res, 'data', [])});
  };

  getProviderName = item => {
    const providerSuffix = [
      {
        suffix: item.suffix,
      },
    ];
    return `${item.firstName} ${item.lastName}${getSuffixString(
      item.suffix ? providerSuffix : '',
    )}`;
  };

  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.setNPIProviderList);
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  getProviderAddress = provider => {
    const fullAddress = _.values({
      address: _.get(provider, 'address', ''),
      city: _.get(provider, 'city', ''),
      state: _.get(provider, 'state', ''),
    }).join(', ');
    return `${fullAddress} ${_.get(provider, 'zipCode', '')}`;
  };

  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.setNPIProviderList();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };

  handleSearch = () => {
    this.setState({activePage: 1}, this.setNPIProviderList);
  };

  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.setNPIProviderList);
  };

  handleStateSearch = event => {
    const selectedState = _.find(this.state.stateList, data => {
      return data.id === event.target.value;
    });
    this.setState(
      {
        searchState: _.get(selectedState, 'id', ''),
        stateId: _.get(selectedState, 'id', ''),
        searchCity: '',
        activePage: 1,
      },
      this.updateAllData,
    );
  };

  updateAllData = () => {
    this.setNPIProviderList();
    if (this.state.stateId) {
      this.getCityList();
    } else {
      this.setState({cityList: []});
    }
  };

  handleCitySearch = event => {
    const selectedCity = _.find(this.state.cityList, data => {
      return data.id === event.target.value;
    });
    this.setState(
      {searchCity: _.get(selectedCity, 'id', ''), activePage: 1},
      this.setNPIProviderList,
    );
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.setNPIProviderList,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.setNPIProviderList);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  refreshNPIProvider = async npiNumber => {
    await generateNPIProviders(npiNumber).catch(this.handleError);
  };

  ShowProviderReviews = npiNumber => {
    const params = new URLSearchParams();
    if (npiNumber) {
      params.append('npi', npiNumber);
    }
    this.props.history.push({
      pathname: '/admin/ghost-reviews',
      search: `?${params.toString()}`,
      state: {
        ...this.state,
        isBackLink: true,
        stateList: [],
        cityList: [],
      },
    });
  };

  confirmDeleteGhost = (npiNumber, isDelete) => {
    const {setGlobal} = this.context;
    const operation = isDelete ? 'delete' : 'restore';
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to ${operation} this ghost provider?`,
        onConfirm: async isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            const response = await deleteNPIProvider(npiNumber, isDelete).catch(this.handleError);
            if (response) {
              setGlobal('modal', successModalData(response.message));
              this.setNPIProviderList();
            }
          }
        },
      }),
    );
  };

  getActionData = provider => {
    const deleteRestoreComponent = (
      <button
        className="btn-delete"
        onClick={() => this.confirmDeleteGhost(provider.id, provider.hasDeleted ? false : true)}>
        {provider.hasDeleted ? 'Restore' : 'Delete'}
      </button>
    );
    const otherOperationComponent = (
      <>
        {/* <button
          className="btn-refresh"
          onClick={() => this.refreshNPIProvider(_.get(provider, 'npiNumber', ''))}>
          Refresh
        </button> */}
        <button className="btn-edit" onClick={() => this.ShowProviderReviews(provider.npiNumber)}>
          Reviews
        </button>
      </>
    );
    return (
      <>
        {!provider.hasDeleted && otherOperationComponent}
        {deleteRestoreComponent}
      </>
    );
  };

  render() {
    const {list, totalPage, searchText, stateList, cityList, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    const title = 'Ghost Providers';
    return (
      <Layout>
        <div className="GhostProfiles">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div className="select-search">
                <Select
                  options={stateList}
                  labelIndex="state"
                  valueIndex="id"
                  name="stateId"
                  defaultOptionProp={{value: '', label: 'All States'}}
                  isAllowDefaultOpen={true}
                  onChange={this.handleStateSearch}
                  value={this.state.stateId}
                />
              </div>
              <div className="select-search">
                <Select
                  options={cityList}
                  labelIndex="city"
                  valueIndex="id"
                  name="cityId"
                  defaultOptionProp={{value: '', label: 'All Cities'}}
                  isAllowDefaultOpen={true}
                  onChange={this.handleCitySearch}
                  value={this.state.searchCity}
                />
              </div>
              <div className="input-search">
                <div className="input-control">
                  <input
                    placeholder="Search"
                    value={searchText}
                    onChange={this.handleChange}></input>
                  {searchText && (
                    <button className="btn-clear" onClick={this.handleClearSearch}>
                      <img src={images.close} alt="clear" />
                    </button>
                  )}
                </div>
                <button onClick={this.handleSearch}>Search</button>
              </div>
            </div>
            <div className="ghostCount">
              <span>{totalRecord} providers found.</span>
            </div>
            <div className="table-overflow">
              <div className="rtable">
                <div className="heading">
                  <div
                    className="cell pointer"
                    data-key="firstName"
                    onClick={this.handleSortingChange}>
                    Provider Name{this.renderShortIcon('firstName')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="npiNumber"
                    onClick={this.handleSortingChange}>
                    NPI Number{this.renderShortIcon('npiNumber')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="specialties"
                    onClick={this.handleSortingChange}>
                    Specialties{this.renderShortIcon('specialties')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="address"
                    onClick={this.handleSortingChange}>
                    Address{this.renderShortIcon('address')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="gender"
                    onClick={this.handleSortingChange}>
                    Gender{this.renderShortIcon('gender')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="isClaimed"
                    onClick={this.handleSortingChange}>
                    Is Claimed{this.renderShortIcon('isClaimed')}
                  </div>
                  <div className="cell edit-cell">Action</div>
                </div>
                {_.map(list, (provider, index) => (
                  <div className={provider.hasDeleted ? 'deleted-row' : 'row'} key={index}>
                    <div className="cell">
                      {!provider.hasDeleted && _.get(provider, 'seoPageUrl', '') && (
                        <span
                          className="link-span"
                          onClick={() => window.open(_.get(provider, 'seoPageUrl', ''), '_blank')}>
                          {`${this.getProviderName(provider)}`}
                        </span>
                      )}
                      {(provider.hasDeleted || !_.get(provider, 'seoPageUrl', '')) &&
                        `${this.getProviderName(provider)}`}
                    </div>
                    <div className="cell">{_.get(provider, 'npiNumber', '')}</div>
                    <div className="cell">
                      {_.map(_.get(provider, 'specialties', [])).join(', ')}
                    </div>
                    <div className="cell">{`${this.getProviderAddress(provider)}`}</div>
                    <div className="cell">{ProviderGender[_.get(provider, 'gender', '')]}</div>
                    <div className="cell">{ClaimStatus[_.get(provider, 'isClaimed', '')]}</div>
                    <div className="cell">{this.getActionData(provider)}</div>
                  </div>
                ))}
              </div>
            </div>
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default GhostProfiles;
