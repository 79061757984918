import React from 'react';
import TeamCard from 'components/TeamCard';
import landingPageConstants from 'data/landingPage';
import MySlider from 'components/MySlider';
import './Team.css';
import './KalypsysAdvisors.css';
const Team = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    initialSlide: 1,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    rows: 1,
    speed: 500,
    swipeToSlide: true,
   // centerMode: true,
 //   centerPadding: '10%',
 //cssEase: 'linear',
    variableWidth: true,
    //variableHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
     
     
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 666,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
         // centerPadding: '0%',
        },
      },
    ],
  };

  const cards = [...landingPageConstants.advisoryboard];

  const teamCardsComponent = cards.map((card, index) => {
    return (
      <div className="team-slider-content" key={index}>
        <TeamCard card={card} />
      </div>
    );
  });
  return (
    <div className="Team">
      <div className="container">
        <div className="title text-center">
          <div className="heading2 text-bold">Kaly Medical Advisors</div>
        </div>
      </div>
      <MySlider className="team-slider" {...settings}>
        {teamCardsComponent}
      </MySlider>
    </div>
  );
};
const KalypsysAdvisors = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    initialSlide: 1,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    rows: 1,
    speed: 500,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '5%',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.9,
          slidesToScroll: 1,
          centerPadding: '0%',
        },
      },
    ],
  };

  const cards = [...landingPageConstants.advisoryboard];

  const teamCardsComponent = cards.map((card, index) => {
    return (
      <div className="team-slider-content" key={index}>
        <TeamCard card={card} />
      </div>
    );
  });
  return (
    <div className="KalypsysAdvisors">
      <div className="container">
        <div className="title text-center">
          <div className="heading2">Kaly Medical Advisors</div>
        </div>
      </div>
      <MySlider className="team-slider" {...settings}>
        {teamCardsComponent}
      </MySlider>
    </div>
  );
};

export default Team;
export {KalypsysAdvisors};