import React, {useContext} from 'react';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import MenuItem from 'components/AfterAuth/MenuItem';
import authDashboardContent from 'data/authDashboardContent';
import AuthContext from 'context/AuthContext';
import rolePermission from 'data/rolePermission';
import './Sidebar.css';

const {helperFunctions} = rolePermission;
const {hasAnyPermission, getAllMyRoleIds} = helperFunctions;

const Sidebar = props => {
  const siderbarMenuItems = [...authDashboardContent.siderbarMenuItems];
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const {location} = props;
  const {pathname} = location;
  const menuitemsComponent = [];
  const roleIds = getAllMyRoleIds(auth);
  _.forEach(siderbarMenuItems, menuItem => {
    if (hasAnyPermission(roleIds, menuItem.permissions)) {
      const {id, link} = menuItem;
      let isActive = pathname === link;
      menuitemsComponent.push(
        <MenuItem
          roleIds={roleIds}
          key={id}
          isActive={isActive}
          item={menuItem}
          currentPath={pathname}
        />,
      );
    }
  });

  return (
    <div className="Sidebar">
      <div className="Sidebar-inner-content">
        <div className="medical-content">
          <div className="medical-text">Admin</div>
          <div className="medical-name"></div>
        </div>
        <div className="menu">{menuitemsComponent}</div>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
