import React, {useEffect, useRef, useState} from 'react';
import images from 'data/images';
import './ContactViewHistory.css';
import ReactPaginate from 'react-paginate';
import MyDatetime from 'components/MyDatetime';
import momentHelper from 'data/moment/momentHelper';
import moment from 'moment';
import reviewService from 'services/review';
const {ViewContactHistory} = reviewService;

const {
  // dateTimeFormat,
  // startOf,
  // isSameOrAfter,
  // isSameOrBefore,
  dateFormat,
  bigDateFormat,
  // isSameDay,
  // isBetweenDates,
   bigDateTimeFormat,
  // utcToLocalDateFormat,
  // isSame,
  // timeFormat,
  // utcToLocalMoment,
} = momentHelper;

const ContactViewHistoryList = props => {
  const {closeModal,data} = props;
  const refStart = useRef(null);
  const refEnd = useRef(null);
  const {id}=data
  const [historyList, setHistoryList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [dateConfig, setDateConfig] = useState({
    start: {
      open: false,
      input: false,
      viewMode: 'days',
      timeFormat: 'hh:mm A',
      value: moment().subtract(7, 'd'),
    },
    end: {open: false, input: false, viewMode: 'days', timeFormat: 'hh:mm A', value: moment()},
  });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (refStart.current && !refStart.current.contains(event.target)&&refEnd.current&&!refEnd.current.contains(event.target)) {
        closeDatePicker()
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [  ]);
  const handlePageChange = data => {
    setActivePage(data.selected + 1);
  };

  const isvalidStartDate = (current) => {
   
    return !current.isAfter( dateConfig.end.value );
  };
  const isvalidEndDate = (current) => {
   
    return !current.isAfter(moment() );
  };
  const changeViewMode = (startOrEnd, viewMode) => {
    const updatedDateConfigs = {...dateConfig};
    let endConfig = {...updatedDateConfigs.end};
    let startConfig = {...updatedDateConfigs.start};
    if (startOrEnd === 'start') {
      endConfig.open = false;
      startConfig = {
        ...startConfig,
        viewMode,
        open: dateConfig[startOrEnd].viewMode === viewMode ? !dateConfig[startOrEnd].open : true,
      };
    } else if (startOrEnd === 'end') {
      startConfig.open = false;
      endConfig = {
        ...endConfig,
        viewMode,
        open: dateConfig[startOrEnd].viewMode === viewMode ? !dateConfig[startOrEnd].open : true,
      };
    }
    setDateConfig({
      ...dateConfig,
      start: startConfig,
      end: endConfig,
    });
  };

  const handleStartDatetimeChange = starDate => {
   
   
    setDateConfig({
      ...dateConfig,
      start: {
        ...dateConfig.start,
        open: false,
        value:starDate
      },
    });
  };
  const handleEndDatetimeChange = endDate => {
   
   
    setDateConfig({
      ...dateConfig,
      end: {
        ...dateConfig.end,
        open: false,
        value:endDate
      },
    });
  };
  const closeDatePicker = e => {
    setDateConfig({
      ...dateConfig,
      start: {
        ...dateConfig.start,
        open: false,
      },
      end: {
        ...dateConfig.end,
        open: false,
      },
    });
  };
  const callApi=async ()=>{
    let args={
      startDate:moment(dateConfig.start.value).format('YYYY-MM-DD'),
      endDate:moment(dateConfig.end.value).format('YYYY-MM-DD'),
      providerContactId:id
    }
    let responce =await ViewContactHistory(args);
    if(responce.data){
      setHistoryList(responce.data.contactHistoryList)
    }
  }
useEffect(()=>{
  callApi()
  
},[id,dateConfig.start.value,dateConfig.end.value])
  const EachSession = () => {
    return (
      <section className="list-history">
        <ul className="eachHistory">
          {historyList.map((data)=>(
            <li key={data.id} className="eachHistory">
              <div style={{display:"flex",alignItems:"center"}}>
            <img src={images.arrowBlue}></img>
            </div>
            <div>
              <p>{data.contactContactType.type}</p>
              <span>{bigDateTimeFormat(data.sentAt)}</span>
            </div>
            <div style={{marginLeft:"15px"}}>
              <p>Responded At</p>
              <span>{data.respondedAt?bigDateTimeFormat(data.respondedAt):'-'}</span>
            </div>
          </li>
          ))}
          {historyList.length===0&&(
             <li className="eachHistory">
             
             <div>
               <p>No activity found.</p>
              
             </div>
           </li>
          )}
        </ul>
      </section>
    );
  };

  return (
    <div className="ContactSourceHistoryView">
      <div className="modal-content-header">
        <div className="heading3">view History</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <div className="AddAvailability viewHistory">
          {/* {(dateConfig.start.open || dateConfig.end.open) && (
            <div className="datepicker-overlay" onClick={closeDatePicker}></div>
          )} */}
          <div className="mt-10">
          <div className="select-date-content " >
            <span
              // className={`time-padding ${
              //   dateConfig.start.open && dateConfig.start.viewMode === 'days' ? 'gray-box' : ''
              // }`}
              onClick={() => changeViewMode('start', 'days')}>
              <span className="small-label">Start Date</span>
              <span className='date-box'>
              <span className="gray-back"></span>
              {bigDateFormat(dateConfig?.start?.value, 'MMM DD, YYYY')}
              <img src={images.calender} />
              </span>
            </span>
            <div ref={refStart} className={`Datepicker-content ${dateConfig.start.viewMode}`}>
              <MyDatetime
              
                 isValidDate={isvalidStartDate}
                value={dateConfig.start.value}
                onChange={handleStartDatetimeChange}
                onViewModeChange={viewMode => changeViewMode('start', viewMode)}
                maxDate={dateConfig.end.value}
                
                {...dateConfig.start}
                disableOnClickOutside={false}
              />
            </div>
          </div>
          </div>
          <div className="mt-10">
          <div className="select-date-content end-date" style={{position: 'relative'}}>
            <span
              // className={`time-padding ${
              //   dateConfig.start.open && dateConfig.start.viewMode === 'days' ? 'gray-box' : ''
              // }`}
              onClick={() => changeViewMode('end', 'days')}>
              <span className="small-label">end Date</span>
              <span className='date-box'>
              <span className="gray-back"></span>
              {bigDateFormat(dateConfig?.end?.value, 'MMM DD, YYYY')}
              <img src={images.calender} />
              </span>
            </span>
            <div ref={refEnd} className={`Datepicker-content ${dateConfig.end.viewMode}`}>
              <MyDatetime
                isValidDate={isvalidEndDate}
                value={dateConfig.end.value}
                onChange={handleEndDatetimeChange}
                onViewModeChange={viewMode => changeViewMode('end', viewMode)}
                maxDate={moment()}
                disableOnClickOutside={false}
                {...dateConfig.end}
              />
            </div>
            </div>
          </div>
        </div>
        <div className="rtable">
          <EachSession />
        </div>
        {/* {totalPages>0 &&(
          <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activePage}
              />
              )} */}
      </div>
    </div>
  );
};

export default ContactViewHistoryList;
