import React, { Component } from 'react';
import _ from 'lodash';
import Layout from 'layout/AfterAuth';
import FeedbackComponent from 'components/AfterAuth/ProviderFeedback';
import './Feedback.css';
import AuthContext from 'context/AuthContext';
import RootContext from 'context/RootContext';
import SingleFeedbackModal from "./singleFeedback"
import modalConfig from 'modals/modalConfig';
const { modalFunctions } = modalConfig;
const {  blankModalData } =
  modalFunctions;
class Feedback extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {
    activeTab: null,
    invitedUserList: null,
    practiceInsurancePlanList: null,
    practiceStaffMemberList: null,
  };
  tabs = [
    { label: 'Pending Feedback', tab: 'PendingFeedback' },
    { label: 'Completed Feedback', tab: 'Feedback' },
  ];

  componentDidMount() {
    this.changeViewUser();
    const tab = this.getTabName(this.props);
    this.getAndSetActiveTab(tab);
    const { location } = this.props;
    const query = new URLSearchParams(location.search);

    let selectedFeedbackid = query.get('feedbackid')
    if (selectedFeedbackid) {
      const {setGlobal} = this.rootContext;
      // setGlobal(
      //   'modal',
      //   blankModalData({
      //     CustomComponent: ContactViewHistoryList,
      //     modalWrapperClass: 'mega-modal',
      //     customComponentProps: {
      //       data: {
      //         contactId,
      //       },
      //     },
      //   }),
      // );
      setGlobal(
        'modal',
        blankModalData({
          CustomComponent: SingleFeedbackModal,
          customComponentProps: {
            data: {
              title: 'Edit Appointment Time',
              selectedFeedbackid:selectedFeedbackid,
            }
          },
        }),
      );
    }
  }

  componentDidUpdate(prevProps) {
    const tab = this.getTabName(this.props);
    const prevTab = this.getTabName(prevProps);
    if (tab !== prevTab) {
      this.getAndSetActiveTab(tab);
    }
  }

  changeViewUser = () => {
    const { auth, setAuthState } = this.context;

    const newViewUserId = _.get(auth, 'id', '');

    let isStaff = false
    let { roles } = auth
    if (roles && roles.length > 0 && roles[0].role === 'Staff') {
      isStaff = true
    }
    if (newViewUserId && !isStaff) {
      setAuthState(prevState => {
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            viewUserId: newViewUserId,
          },
        };
      });
    }
  };

  getTabName = props => {
    const query = new URLSearchParams(props.location.search);
    const tab = query.get('tab') || _.get(this, 'tabs[0].tab');
    return tab;
  };

  getAndSetActiveTab = async tab => {
    const tabObj = _.find(this.tabs, { tab });
    if (!tabObj) {
      tab = _.get(this, 'tabs[0].tab');
    }
    switch (tab) {
      case 'PendingFeedback':
        break;
      case 'Feedback':
        break;
      default:
        break;
    }
    this.setState({
      activeTab: tab,
    });
  };

  setActiveTab = async activeTab => {
    const params = new URLSearchParams();
    params.append('tab', activeTab);
    this.props.history.push({
      pathname: '/feedback',
      search: `?${params.toString()}`,
    });
  };

  renderActiveTab = () => {
    const { activeTab } = this.state;
    let activeTabComponent = null;
    switch (activeTab) {
      case 'PendingFeedback':
        activeTabComponent = <FeedbackComponent appointmentFeedbackStatusId={1} title="Pending Feedback" />;
        break;
      case 'Feedback':
        activeTabComponent = <FeedbackComponent appointmentFeedbackStatusId={2} title="Completed Feedback" />;
        break;
      default:
        activeTabComponent = null;
        break;
    }
    return activeTabComponent;
  };
  render() {
    const { auth } = this.context;

    const { activeTab } = this.state;
    const tabHeaderList = (
      <ol className="tab-list">
        {this.tabs.map((tab, index) => {
          return (
            <li
              key={index}
              className={`tab-list-item ${tab.tab === activeTab ? 'tab-list-active' : ''}`}
              onClick={() => this.setActiveTab(tab.tab)}>
              {tab.label}
              <span className="review-count">{tab.count}</span>
            </li>
          );
        })}
      </ol>
    );
    return (
      <Layout>
        <RootContext.Consumer>
          {context => {
            this.rootContext = context;
            return null;
          }}
        </RootContext.Consumer>
        <div className="ReviewAccounts">
          {/* <SubHeader title="Review Accounts" /> */}
          <div className="ReviewAccounts-content">
            {/* <ApprovalCardFeedback></ApprovalCardFeedback> */}
            <div className="setting-tabs">
              {tabHeaderList}
              <div className="tab-content">
                <div className="box-panel">{this.renderActiveTab()}</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Feedback;
