import React, {useState, useContext} from 'react';
import _ from 'lodash';
import {withRouter, Link} from 'react-router-dom';
import images from 'data/images';
import AuthContext from 'context/AuthContext';
import Overlay from 'components/Overlay';
import './Header.css';

const Header = props => {
  const {history} = props;
  const authContext = useContext(AuthContext);
  const {updateAuthStatus, auth} = authContext;
  const firstName = _.get(auth, 'firstName', '');
  const lastName = _.get(auth, 'lastName', '');
  const email = _.get(auth, 'email', '');
  const roleName = _.get(auth, 'roles.0.role', 'Default');
  const [active, toggleActive] = useState(false);
  const [show, menuActive] = useState(false);
  const rightHeaderComponent = (
    <div className="right-col">
      <div className="user">
        <span className="user-content">
          <div className={active ? 'show-menu' : ''}>
            <div className="staff-default-img">
              <img alt="" src={images.logosymbol} />
            </div>
            <div className="user-info">
              <div className="user-name">
                {firstName} {lastName}
              </div>
              <div className="user-post">{roleName}</div>
              <img
                onClick={() => {
                  toggleActive(!active);
                }}
                alt=""
                src={images.arrowPointer}
                className="arrowPointer"
              />
            </div>
            <div className="dropdown-menu">
              {/* <span className="email">
                <img alt="" src={images.user} /> My Profile
              </span> */}
              <span className="change-pwd"
                onClick={() => {
                  history.push({
                    pathname: '/admin/change-password',
                  });
                }}>
                <img alt="password" src={images.keyGray} />Change Password
              </span>
              <span className="logout"
                onClick={() => {
                  updateAuthStatus(null);
                  history.push({
                    pathname: '/admin/login',
                  });
                }}>
                <img alt="logout" src={images.logoutGray} />Logout
              </span>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
  return (
    <div className={`AdminLayoutHeader ${show ? 'menu-active' : ''}`}>
      <Overlay show={active} onClick={() => toggleActive(!active)} />
      <div className="header-content">
        <div
          className="mobile-menu"
          onClick={() => {
            menuActive(!show);
          }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="logo">
          <Link to="/admin/dashboard">
            <img alt="" src={images.logo} />
          </Link>
        </div>
        {rightHeaderComponent}
      </div>
    </div>
  );
};

export default withRouter(Header);
