const questionList = [
  {
    id: 18807,
    question: 'How long have you had symptoms for?',
    options: [
      {nextQuestionId: 18810, label: 'Less than 24 hours'},
      {nextQuestionId: 18810, label: 'A few days'},
      {nextQuestionId: 18810, label: 'More than a week'},
    ],
    descriptions: [],
  },
  {
    id: 18810,
    question: 'Have you been treated for this before?',
    optionType: 1,
    options: [
      {nextQuestionId: 18811, label: 'Yes', addExtraQuestion: 1, shortLabel: 'Treated previously'},
      {groupId: 'GOTO_LIST', label: 'No', shortLabel: 'Never been treated before'},
    ],
    descriptions: [],
  },
  {
    id: 18811,
    question: 'When was the last time you were treated for this?',
    options: [
      {
        groupId: 'GOTO_LIST',
        label: 'I am currently under treatment and looking for a new provider.',
        shortLabel: 'Currently under treatment',
      },
      {
        groupId: 'GOTO_LIST',
        label: 'It has been over 3 months since I last had treatment.',
        shortLabel: 'Last treated > 3months ago',
      },
    ],
    descriptions: [],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 18807,
    treeDepth: 4,
  },
};
