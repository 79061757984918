import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from 'data/images';
import './spin.css';


class Gohandle extends Component {


    componentDidMount = () => {
        this.getAppointmentData();
    };
    tinyUrlRedirection = (url) => {

        if(url.startsWith('?type=reschedule&source=patientemail')||url.startsWith('?type=reschedule&origin=patientemail')
        ||url.startsWith('?type=cancel&source=patientemail')||url.startsWith('?type=cancel&origin=patientemail')
        ||url.startsWith('?type=review&source=patientemail')||url.startsWith('?type=review&origin=patientemail'))
        return `?${url}`
        try {
            let urlList = [
                {
                    url: '&gv',
                    orginalUrl: '?gtag=verifyUpdatedEmail-verifyUpdatedEmailLink',
                },
                {
                    url: '&pr',
                    orginalUrl: '?type=reschedule&source=patientemail&id=',
                },
                {
                    url: '&gr',
                    orginalUrl: '&gtag=appointmentConfirmedEmail-rescheduleAppointment',
                },
                {
                    url: '&pc',
                    orginalUrl: '?type=cancel&source=patientemail&id=',
                },
                {
                    url: '&gw',
                    orginalUrl: '&gtag=appointmentConfirmedEmail-cancelAppointment',
                },
                {
                    url: '/vc/',
                    orginalUrl: '/verify/cancel-appointment/',
                }, {
                    url: '&ge',
                    orginalUrl: '?gtag=appointmentConfirmedEmail-cancelAppointment',
                }, {
                    url: '&lr',
                    orginalUrl: '?type=review&source=patientemail&id=',
                }, {
                    url: '&gt',
                    orginalUrl: '?gtag=reviewTheDoctorEmail-leaveReview',
                }, {
                    url: '&vr',
                    orginalUrl: "?type=review&source=providermail&id=",
                }, {
                    url: '&gp',
                    orginalUrl: '&gtag=patientReviewEmail-viewReview',
                }, {
                    url: '/ca/',
                    orginalUrl: '/verify/confirm-appointment/',
                }, {
                    url: '&gs',
                    orginalUrl: '?gtag=appointmentRescheduledEmail-confirmAppointment',
                }, {
                    url: '/va/',
                    orginalUrl: '/verify/cancel-appointment/',
                }, {
                    url: '&gn',
                    orginalUrl: '?gtag=newAppointmentEmail-confirmAppointment',
                }, {
                    url: '&gm',
                    orginalUrl: '?gtag=newAppointmentEmail-cancelAppointment',
                },{
                    url: '?gb',
                    orginalUrl: '??gtag=appointmentCancelledByDoctorEmail-findNewDoctor',
                },
                 {
                    url: '&gb',
                    orginalUrl: '?gtag=appointmentCancelledByDoctorEmail-findNewDoctor',
                }, {
                    url: '&gc',
                    orginalUrl: '?gtag=appointmentCancelledByDoctorEmail-bookAgain',
                }, {
                    url: '?gl',
                    orginalUrl: '??gtag=appointmentCancelledByPatientEmail-findNewDoctor',
                },{
                    url: '&gl',
                    orginalUrl: '?gtag=appointmentCancelledByPatientEmail-findNewDoctor',
                },{
                    url: '?gk',
                    orginalUrl: '??gtag=appointmentCancelledByPatientEmail-bookAgain',
                },
                 {
                    url: '&gk',
                    orginalUrl: '?gtag=appointmentCancelledByPatientEmail-bookAgain',
                }, {
                    url: '&gu',
                    orginalUrl: '?gtag=verifyEmail-verifyEmailLink',
                }, {
                    url: '&gi',
                    orginalUrl: '?gtag=providerInviteEmail-verifyProviderLink',
                }, {
                    url: '&gj',
                    orginalUrl: '?gtag=completePracticeEmail-verifyEmailLink',
                }, {
                    url: '/vh',
                    orginalUrl: '/video-visit?appointment=',
                }, {
                    url: '&sp',
                    orginalUrl: '&source=patientemail',
                }, {
                    url: '/dw',
                    orginalUrl: '/doctor-waiting-room?appointment=',
                }, {
                    url: '&se',
                    orginalUrl: '&source=providermail',
                },
            ];

            for (let index = 0; index < urlList.length; index++) {

                url = url.replace(urlList[index].url, urlList[index].orginalUrl);

            }
            return url

        } catch (error) {
            return url
        }
    };
     redirectionUrl = (template, data = {}) => {
        switch (template) {
    
    
            case 'updatedEmailVerify': {
                // let newUrl = data.updatedEmailVerifyUrl.substring(data.updatedEmailVerifyUrl.indexOf('https://sandbox.kaly.com/app/') + 29);
                data.updatedEmailVerifyUrl = data.updatedEmailVerifyUrl.replace('/app', "/app/go?");
                data.updatedEmailVerifyUrl = data.updatedEmailVerifyUrl.replace('?gtag=verifyUpdatedEmail-verifyUpdatedEmailLink', "&gv");
                break;
            }
            case 'appointmentConfirmed':
            case 'appointmentConfirmedVideo':
            case 'appointmentConfirmedReminderToDr': {
                data.patientRescheduleAppointmentUrl = data.patientRescheduleAppointmentUrl.replace('/app', "/app/go?");
                data.patientRescheduleAppointmentUrl = data.patientRescheduleAppointmentUrl.replace('?type=reschedule&source=patientemail&id=', "&pr");
                data.patientRescheduleAppointmentUrl = data.patientRescheduleAppointmentUrl.replace('&gtag=appointmentConfirmedEmail-rescheduleAppointment', "&gr");
    
                data.patientCancelAppointmentUrl = data.patientCancelAppointmentUrl.replace('/app', "/app/go?");
                data.patientCancelAppointmentUrl = data.patientCancelAppointmentUrl.replace('?type=cancel&source=patientemail&id=', "&pc");
                data.patientCancelAppointmentUrl = data.patientCancelAppointmentUrl.replace('&gtag=appointmentConfirmedEmail-cancelAppointment', "&gw");
    
                data.providerCancelAppointmentUrl = data.providerCancelAppointmentUrl.replace('/app', "/app/go?");
                data.providerCancelAppointmentUrl = data.providerCancelAppointmentUrl.replace('/verify/cancel-appointment/', "/vc/");
                data.providerCancelAppointmentUrl = data.providerCancelAppointmentUrl.replace('?gtag=appointmentConfirmedEmail-cancelAppointment', "&ge");
                break;
            }
            case 'leaveReviewPatient': {
                data.patientLeaveReviewUrl = data.patientLeaveReviewUrl.replace('/app', "/app/go?");
                data.patientLeaveReviewUrl = data.patientLeaveReviewUrl.replace('?type=review&source=patientemail&id=', "&lr");
                data.patientLeaveReviewUrl = data.patientLeaveReviewUrl.replace('?gtag=reviewTheDoctorEmail-leaveReview', "&gt");
    
                break;
            }
            case 'newPatientReview': {
                data.providerViewReviewUrl = data.providerViewReviewUrl.replace('/app', "/app/go?");
                data.providerViewReviewUrl = data.providerViewReviewUrl.replace('?type=review&source=providermail&id=', "&vr");
                data.providerViewReviewUrl = data.providerViewReviewUrl.replace('&gtag=patientReviewEmail-viewReview', "&gp");
    
    
                break;
            }
            case 'appointmentRescheduleToDr':
            case 'appointmentConfirmedReminderToDr': {
                data.providerConfirmAppointmentUrl = data.providerConfirmAppointmentUrl.replace('/app', "/app/go?");
                data.providerConfirmAppointmentUrl = data.providerConfirmAppointmentUrl.replace('/verify/confirm-appointment/', "/ca/");
                data.providerConfirmAppointmentUrl = data.providerConfirmAppointmentUrl.replace('?gtag=appointmentRescheduledEmail-confirmAppointment', "&gs");
    
    
                data.providerCancelAppointmentUrl = data.providerCancelAppointmentUrl.replace('/app', "/app/go?");
                data.providerCancelAppointmentUrl = data.providerCancelAppointmentUrl.replace('/verify/cancel-appointment/', "/va/");
                data.providerCancelAppointmentUrl = data.providerCancelAppointmentUrl.replace('?gtag=appointmentRescheduledEmail-confirmAppointment', "&gs");
    
                break;
            }
    
            case 'appointmentPendingToDr': {
                data.providerConfirmAppointmentUrl = data.providerConfirmAppointmentUrl.replace('/app', "/app/go?");
                data.providerConfirmAppointmentUrl = data.providerConfirmAppointmentUrl.replace('/verify/confirm-appointment/', "/ca/");
                data.providerConfirmAppointmentUrl = data.providerConfirmAppointmentUrl.replace('?gtag=newAppointmentEmail-confirmAppointment', "&gn");
    
                data.providerCancelAppointmentUrl = data.providerCancelAppointmentUrl.replace('/app', "/app/go?");
                data.providerCancelAppointmentUrl = data.providerCancelAppointmentUrl.replace('/verify/cancel-appointment/', "/va/");
                data.providerCancelAppointmentUrl = data.providerCancelAppointmentUrl.replace('?gtag=newAppointmentEmail-cancelAppointment', "&gm");
    
    
                break;
            }
            case 'appointmentCancelledByProvider':
            case 'appointmentCancelledByProviderVideo': {
                data.patientFindDoctorUrl = data.patientFindDoctorUrl.replace('/app', "/app/go?");
                data.patientFindDoctorUrl = data.patientFindDoctorUrl.replace('?gtag=appointmentCancelledByDoctorEmail-findNewDoctor', "?gb");
    
                data.providerSeoUrl = data.providerSeoUrl.replace('?gtag=appointmentCancelledByDoctorEmail-bookAgain', "&gc");
                data.providerSeoUrl = data.providerSeoUrl.replace('/app', "/app/go?");
    
                break;
            }
            case 'appointmentCancelledByPatient':
            case 'appointmentCancelledByPatientVideo': {
                data.patientFindDoctorUrl = data.patientFindDoctorUrl.replace('?gtag=appointmentCancelledByPatientEmail-findNewDoctor', "?gl");
                data.patientFindDoctorUrl = data.patientFindDoctorUrl.replace('/app', "/app/go");
    
    
    
    
                data.providerSeoUrl = data.providerSeoUrl.replace('?gtag=appointmentCancelledByPatientEmail-bookAgain', "?gk");
                data.providerSeoUrl = data.providerSeoUrl.replace('/app', "/app/go?");
    
                break;
            }
            case 'patientEmailVerify': {
                data.patientEmailVerifyUrl = data.patientEmailVerifyUrl.replace('?gtag=verifyEmail-verifyEmailLink', "&gu");
                data.patientEmailVerifyUrl = data.patientEmailVerifyUrl.replace('/app', "/app/go?");
    
    
    
                break;
            }
            case 'providerInvite': {
                data.registrationUrl = data.registrationUrl.replace('?gtag=providerInviteEmail-verifyProviderLink', "&gi");
                data.registrationUrl = data.registrationUrl.replace('/app', "/app/go?");
    
                break;
            }
            case 'completeSignUp': {
                data.registrationUrl = data.registrationUrl.replace('?gtag=completePracticeEmail-verifyEmailLink', "&gj");
                data.registrationUrl = data.registrationUrl.replace('/app', "/app/go?");
    
                break;
            }
            case 'appointmentReminderVideo': {
                data.patientVideoVisitAppointmentUrl = data.patientVideoVisitAppointmentUrl.replace('/video-visit?appointment=', "/vh");
                data.patientVideoVisitAppointmentUrl = data.patientVideoVisitAppointmentUrl.replace('&source=patientemail', "&sp");
                data.patientVideoVisitAppointmentUrl = data.patientVideoVisitAppointmentUrl.replace('/app', "/app/go?");
    
    
            }
            case 'appointmentConfirmedReminderToDr': {
                data.providerVideoVisitAppointmentUrl = data.providerVideoVisitAppointmentUrl.replace('/doctor-waiting-room?appointment=', "/dw");
                data.providerVideoVisitAppointmentUrl = data.providerVideoVisitAppointmentUrl.replace('&source=providermail', "&se");
                data.providerVideoVisitAppointmentUrl = data.providerVideoVisitAppointmentUrl.replace('/app', "/app/go?");
    
    
            }
    
    
        }
        return data;
    };
    getAppointmentData = async () => {
        const query = window.location.search

        let redirecturl = this.tinyUrlRedirection(`${query}`)
        redirecturl = redirecturl.replace('?', "");

        const { history } = this.props;
        if (redirecturl.startsWith('?')) {
            history.push({
                pathname: '/',
                search: redirecturl
            })
        } else {
            
            history.push(redirecturl)
        }
    };

    render() {
        return (
            <div >
                <img className='gospin' src={images.logosymbol} />
            </div>
        );
    }
}

export default Gohandle;
