import React, { Component } from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import ReactPaginate from 'react-paginate';
import constants from 'data/constants';
import _ from 'lodash';
import './Metrics.css';
import images from 'data/images';
import Select from 'components/FormControls/Select';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import momentHelper from 'data/moment/momentHelper';
import { providerVisualizations } from "../index"
const { DefaultDebounceMSec } = constants;
const initialState = {
  list: [],
  stateList: [],
  cityList: [],
  activePage: 1,
  totalPage: 0,
  pageLimit: 10,
  stateId: 0,
  searchText: '',
  searchState: '',
  searchCity: '',
  sortBy: 'firstName',
  orderBy: 'asc',
  appointmentFilteration: 2,
  totalRecord: 0,
  showDatePicker: false,
  dateRange: [

  ],
  totalHits: {}
};

const { getProviderProfilAccessLog, getProviderProfilAccessLogVisualizations } = adminService;


const { modalFunctions } = modalConfig;

const { errorModalData, blankModalData } = modalFunctions;

const { isSameDay } = momentHelper
const defineds = {

  startOfToday: moment().startOf('day').toDate(),
  endOfToday: moment().endOf('day').toDate(),
  startLast7day: moment().startOf('day').subtract(6, 'days').toDate(),
  startLast14day: moment().startOf('day').subtract(13, 'days').toDate(),
  startLast30day: moment().startOf('day').subtract(29, 'days').toDate(),
  LastYear: moment().startOf('day').subtract(364, 'days').toDate(),

};
class GhostProfiles extends Component {
  constructor(props) {
    super(props);
    this.calenderRef = React.createRef();
  }
  static contextType = RootContext;
  state = { ...initialState, viewSummery: false, };

  componentDidMount() {

    //  this.getStateList();
    document.addEventListener('mousedown', this.handleClickOutside, true);
    let range = [{
      startDate: defineds.startLast7day,
      endDate: new Date(),
      key: 'selection'
    }]
    this.setState({ dateRange: range }, () => {
      this.setProviderList(false, true);
    })
  }

  componentWillMount = () => {
    this.props.history.replace({ state: { ...initialState } });
  };
  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }
  createStaticRanges = (ranges) => {
    return ranges.map(range => ({ ...this.staticRangeHandler, ...range }));
  }
  staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };

  defaultStaticRanges = this.createStaticRanges([
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Last 7 days',
      range: () => ({
        startDate: defineds.startLast7day,
        endDate: defineds.startOfToday,
      }),
    },

    {
      label: 'Last 14 days',
      range: () => ({
        startDate: defineds.startLast14day,
        endDate: defineds.startOfToday,
      }),
    },
    {
      label: 'Last 30 days',
      range: () => ({
        startDate: defineds.startLast30day,
        endDate: defineds.startOfToday,
      }),
    },
    {
      label: 'Last 365 days',
      range: () => ({
        startDate: defineds.LastYear,
        endDate: defineds.startOfToday,
      }),
    }
  ]);
  handleClickOutside = event => {
    const { showDatePicker } = this.state
    if (showDatePicker && this.calenderRef && this.calenderRef.current && !this.calenderRef.current.contains(event.target)) {
      this.setState({ showDatePicker: false })
    }
  }
  setProviderList = async () => {

    const {
      activePage,
      pageLimit,
      searchText,
      sortBy,
      orderBy,
      appointmentFilteration,
      dateRange
    } = this.state;

    let startDate = dateRange.length > 0 ? moment(dateRange[0].startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
    let endDate = dateRange.length > 0 ? moment(dateRange[0].endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')

    //let selectedRange=dateRange.length>0&&dateRange[0].startDate && dateRange[0].endDate?`${moment(dateRange[0].startDate).format('DD MMM YYYY')} - ${moment(dateRange[0].endDate).format('DD MMM YYYY')}`:''
    const res = await getProviderProfilAccessLog(
      {
        page: activePage,
        limit: pageLimit,
        search: searchText,
        sortBy: sortBy,
        orderBy: orderBy,
        status: '',
        appointmentFilteration: appointmentFilteration,
        startDate: moment(`${startDate}T00:00:00`, "YYYY-MM-DDTHH:mm:ss").format( "YYYY-MM-DDTHH:mm:ssZZ"),
        endDate: moment(`${endDate}T23:59:59`, "YYYY-MM-DDTHH:mm:ss").format( "YYYY-MM-DDTHH:mm:ssZZ")
      },
    );
    this.setState({
      list: _.get(res, 'data.list', []),
      totalPage: _.get(res, 'data.totalPage', 0),
      totalRecord: _.get(res, 'data.totalRecord', 0),
     // totalHits: _.get(res, 'data.totalHits', {}),
    });

  };

  getSearchedData = async location => {
    const searchData = _.get(location, 'state', {
      activePage: 1,
      totalPage: 0,
      searchText: '',
      pageLimit: 10,
      sortBy: 'firstName',
      orderBy: 'asc',
      searchState: '',
      searchCity: '',
      stateId: 0,
    });
    if (searchData.stateId) {
      this.getCityList(searchData.stateId);
    }
    const {
      activePage,
      pageLimit,
      searchText,
      searchState,
      searchCity,
      sortBy,
      orderBy,
      stateId,
      list,
      totalPage,
      totalRecord,
    } = searchData;
    this.setState({
      list: list,
      totalPage: totalPage,
      totalRecord: totalRecord,
      activePage: activePage,
      searchText: searchText,
      pageLimit: pageLimit,
      sortBy: sortBy,
      orderBy: orderBy,
      searchState: searchState,
      searchCity: searchCity,
      stateId: stateId,

    });
  };

  // getStateList = async () => {
  //   const res = await getAllStates();
  //   this.setState({ stateList: _.get(res, 'data', []) });
  // };

  // getCityList = async state => {
  //   const stateId = state ? state : this.state.stateId;
  //   const res = await getCities(stateId);
  //   this.setState({ cityList: _.get(res, 'data', []) });
  // };

  getProviderName = item => {

    return `${item.firstName} ${item.lastName}`;
  };

  handlePageChange = data => {
    this.setState({ activePage: data.selected + 1 }, this.setProviderList);
  };

  handleError = error => {
    const { setGlobal } = this.context;
    const { message } = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };



  handleChange = event => {
    this.setState({ activePage: 1, searchText: event.target.value });
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.setProviderList();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };

  handleSearch = () => {
    this.setState({ activePage: 1 }, this.setProviderList);
  };

  handleClearSearch = () => {
    this.setState({ activePage: 1, searchText: '' }, this.setProviderList);
  };



  updateAllData = () => {
    this.setProviderList();
    // if (this.state.stateId) {
    //   this.getCityList();
    // } else {
    //   this.setState({ cityList: [] });
    // }
  };

  handleappointmentFilteration = event => {

    this.setState(
      { appointmentFilteration: event.target.value, activePage: 1 },
      this.setProviderList,
    );
  };

  handleSortingChange = e => {
    e.preventDefault();
    const { sortBy, orderBy } = this.state;
    const { dataset } = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        { orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1 },
        this.setProviderList,
      );
    } else {
      this.setState({ orderBy: 'asc', sortBy: key, activePage: 1 }, this.setProviderList);
    }
  };

  renderShortIcon = key => {
    const { sortBy, orderBy } = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };






  setDateRangeFn = (item) => {
    const { selection } = item
    let datapickElement = document.querySelector('.rdrDateDisplay').children[1].classList.contains('rdrDateDisplayItemActive');
    if (datapickElement || (moment(selection.startDate).format('dd-MM-YYYY') !== moment(selection.endDate).format('dd-MM-YYYY')))
    {
      let dateRange=[selection]
      let daysDifference = dateRange.length > 0 ? moment(dateRange[0].endDate).diff(moment(dateRange[0].startDate), 'days') : 0

      this.setState({ dateRange: [selection], showDatePicker: false, activePage: 1 },()=>{this.setProviderList();this.getTotalValue(null,daysDifference)})
    }
      else
      this.setState({ dateRange: [selection] })
  }
  getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(moment(dt).format('MMM DD'));
    }
    return arr;
  };
  getWeeksArray = function (start, end) {
    for (var arr = {}, dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr[`${moment(dt).isoWeek()}`] = [];
    }
    return Object.keys(arr);
  };
  getMonthArray = function (start, end) {
    for (var arr = {}, dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {

      let ke = `${moment(dt).format('YYYY')}`
      let val = `${moment(dt).format('MM')}`
      if (arr[ke])
        arr[ke] = [...arr[ke], val];
      else
        arr[ke] = [val];
    }

    let passarray = Object.keys(arr).map((item) => {
      let filteredMonth = arr[item].filter((ii, index, selfArray) => selfArray.indexOf(ii) == index);

      return { year: item, month: filteredMonth }
    })

    return passarray;
  };
  getTotalValue = async (providerData, daysDifference) => {

    const { dateRange, viewSummery } = this.state
    if (viewSummery) {
      let startDate = dateRange.length > 0 ? moment(dateRange[0].startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      let endDate = dateRange.length > 0 ? moment(dateRange[0].endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      let labels = []
      if (daysDifference + 1 <= 30) {
        labels = []
      } else if (daysDifference + 1 <= 90) {
        labels = this.getWeeksArray(moment(dateRange[0].startDate).toDate(), moment(dateRange[0].endDate).toDate())
      }
      else if (daysDifference + 1 >= 91) {
        labels = this.getMonthArray(moment(dateRange[0].startDate).toDate(), moment(dateRange[0].endDate).toDate())
      }
      let passData = {
        startDate: moment(`${startDate}T00:00:00`, "YYYY-MM-DDTHH:mm:ss").format( "YYYY-MM-DDTHH:mm:ssZZ"),
        endDate: moment(`${endDate}T23:59:59`, "YYYY-MM-DDTHH:mm:ss").format( "YYYY-MM-DDTHH:mm:ssZZ"),
        range: daysDifference + 1 <= 30 ? 'days' : daysDifference + 1 <= 90 ? 'Week' : 'Month',
        rangeArray: [...labels]
      }
      if (providerData) {
        passData.providerId = providerData.providerId
      }
      let graphData1 = await getProviderProfilAccessLogVisualizations(passData);
      let graphData = graphData1.data ? graphData1.data: []
      
      let  totalHitsstate={}
      if(graphData.length>1){
        totalHitsstate={
          finalTotalHits:graphData.reduce((accr,Obj)=>isNaN(accr)?accr[Object.keys(accr)[0]].providerProfileTotalHits+Obj[Object.keys(Obj)[0]].providerProfileTotalHits:accr+Obj[Object.keys(Obj)[0]].providerProfileTotalHits
          ),
          totalLoggedInHits:graphData.reduce((accr,Obj)=>isNaN(accr)?accr[Object.keys(accr)[0]].providerProfileLoggedInHits+Obj[Object.keys(Obj)[0]].providerProfileLoggedInHits:accr+Obj[Object.keys(Obj)[0]].providerProfileLoggedInHits),
          totalUnRegisteredUserHits:graphData.reduce((accr,Obj)=>isNaN(accr)?accr[Object.keys(accr)[0]].providerProfileUnRegisteredUserHits+Obj[Object.keys(Obj)[0]].providerProfileUnRegisteredUserHits:accr+Obj[Object.keys(Obj)[0]].providerProfileUnRegisteredUserHits),
          totalUniqueDeviceGuid:graphData.reduce((accr,Obj)=>isNaN(accr)?accr[Object.keys(accr)[0]].providerProfileUniqueDeviceGuid+Obj[Object.keys(Obj)[0]].providerProfileUniqueDeviceGuid:accr+Obj[Object.keys(Obj)[0]].providerProfileUniqueDeviceGuid),
          totalUniqueLoggedInHits:graphData.reduce((accr,Obj)=>isNaN(accr)?accr[Object.keys(accr)[0]].providerProfileUniqueLoggedInHits+Obj[Object.keys(Obj)[0]].providerProfileUniqueLoggedInHits:accr+Obj[Object.keys(Obj)[0]].providerProfileUniqueLoggedInHits),
          totalAppointmentConversions:graphData.reduce((accr,Obj)=>isNaN(accr)?accr[Object.keys(accr)[0]].providerProfileAppointmentConversions+Obj[Object.keys(Obj)[0]].providerProfileAppointmentConversions:accr+Obj[Object.keys(Obj)[0]].providerProfileAppointmentConversions),
          totalProviderProfileTotalIosApphits:graphData.reduce((accr,Obj)=>isNaN(accr)?accr[Object.keys(accr)[0]].providerProfileTotalIosApphits+Obj[Object.keys(Obj)[0]].providerProfileTotalIosApphits:accr+Obj[Object.keys(Obj)[0]].providerProfileTotalIosApphits),
          totalProviderProfileTotalAndroidApphits:graphData.reduce((accr,Obj)=>isNaN(accr)?accr[Object.keys(accr)[0]].providerProfileTotalAndroidApphits+Obj[Object.keys(Obj)[0]].providerProfileTotalAndroidApphits:accr+Obj[Object.keys(Obj)[0]].providerProfileTotalAndroidApphits),
          totalProviderProfileTotalMobileBrowserhits:graphData.reduce((accr,Obj)=>isNaN(accr)?accr[Object.keys(accr)[0]].providerProfileTotalMobileBrowserhits+Obj[Object.keys(Obj)[0]].providerProfileTotalMobileBrowserhits:accr+Obj[Object.keys(Obj)[0]].providerProfileTotalMobileBrowserhits),
          totalProviderProfileTotalDesktopBrowserhits:graphData.reduce((accr,Obj)=>isNaN(accr)?accr[Object.keys(accr)[0]].providerProfileTotalDesktopBrowserhits+Obj[Object.keys(Obj)[0]].providerProfileTotalDesktopBrowserhits:accr+Obj[Object.keys(Obj)[0]].providerProfileTotalDesktopBrowserhits),
          
        }
      }else if(graphData.length>0){
        totalHitsstate={
          finalTotalHits:graphData[0][Object.keys(graphData[0])[0]].providerProfileTotalHits,
          totalLoggedInHits:graphData[0][Object.keys(graphData[0])[0]].providerProfileLoggedInHits,
          totalUnRegisteredUserHits:graphData[0][Object.keys(graphData[0])[0]].providerProfileUnRegisteredUserHits,
          totalUniqueDeviceGuid:graphData[0][Object.keys(graphData[0])[0]].providerProfileUniqueDeviceGuid,
          totalUniqueLoggedInHits:graphData[0][Object.keys(graphData[0])[0]].providerProfileUniqueLoggedInHits,
          totalAppointmentConversions:graphData[0][Object.keys(graphData[0])[0]].providerProfileAppointmentConversions,
          totalProviderProfileTotalIosApphits:graphData[0][Object.keys(graphData[0])[0]].providerProfileTotalIosApphits,
          totalProviderProfileTotalAndroidApphits:graphData[0][Object.keys(graphData[0])[0]].providerProfileTotalAndroidApphits,
          totalProviderProfileTotalMobileBrowserhits:graphData[0][Object.keys(graphData[0])[0]].providerProfileTotalMobileBrowserhits,
          totalProviderProfileTotalDesktopBrowserhits:graphData[0][Object.keys(graphData[0])[0]].providerProfileTotalDesktopBrowserhits,
          
        }
      }
       
     
      this.setState({totalHits:totalHitsstate})
    }else{
      this.setState({totalHits:{}})
    }
  }
  viewVisualizations = async (providerData, range, daysDifference) => {
    const { setGlobal } = this.context;
    let name = !providerData ? 'All Providers' : this.getProviderName(providerData)
    const { dateRange } = this.state

    let startDate = dateRange.length > 0 ? moment(dateRange[0].startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
    let endDate = dateRange.length > 0 ? moment(dateRange[0].endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
    let labels = []
    if (daysDifference + 1 <= 30) {
      labels = []
    } else if (daysDifference + 1 <= 90) {
      labels = this.getWeeksArray(moment(dateRange[0].startDate).toDate(), moment(dateRange[0].endDate).toDate())
    }
    else if (daysDifference + 1 >= 91) {
      labels = this.getMonthArray(moment(dateRange[0].startDate).toDate(), moment(dateRange[0].endDate).toDate())
    }
    let passData = {
      startDate: moment(`${startDate}T00:00:00`, "YYYY-MM-DDTHH:mm:ss").format( "YYYY-MM-DDTHH:mm:ssZZ"),
      endDate: moment(`${endDate}T23:59:59`, "YYYY-MM-DDTHH:mm:ss").format( "YYYY-MM-DDTHH:mm:ssZZ"),
      range: daysDifference + 1 <= 30 ? 'days' : daysDifference + 1 <= 90 ? 'Week' : 'Month',
      rangeArray: [...labels]
    }
    if (providerData) {
      passData.providerId = providerData.providerId
    }
    let graphData = await getProviderProfilAccessLogVisualizations(passData);
    graphData = graphData.data ? graphData.data : []
    // if (daysDifference <= 30) {
    //   graphData.shift()
    // }
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: providerVisualizations,
        modalWrapperClass: 'visualizations-xl',
        customComponentProps: { name, dateRange, setGlobal, range, daysDifference, graphData },
      }),
    );
  };
  render() {
    const { list, totalPage, searchText, viewSummery, totalHits, showDatePicker, activePage, totalRecord, dateRange } = this.state;
    const activeIndex = activePage - 1;
    const title = 'Provider Access Log';
    let selectedRange = dateRange.length > 0 && dateRange[0].startDate && dateRange[0].endDate ? `${moment(dateRange[0].startDate).format('DD MMM YYYY')} - ${moment(dateRange[0].endDate).format('DD MMM YYYY')}` : ''
    let daysDifference = dateRange.length > 0 ? moment(dateRange[0].endDate).diff(moment(dateRange[0].startDate), 'days') : 0
    return (
      <Layout>
        <div className="Provideraccesslog">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div>
              <div className="search">
                <div ref={this.calenderRef} className="select-search">
                  {/* <Select
                  options={stateList}
                  labelIndex="state"
                  valueIndex="id"
                  name="stateId"
                  defaultOptionProp={{value: '', label: 'All States'}}
                  isAllowDefaultOpen={true}
                  onChange={this.handleStateSearch}
                  value={this.state.stateId}
                /> */}
                  <input
                    placeholder="Search by date range"
                    value={selectedRange}
                    style={{ width: "95%" }}
                    readOnly={true}
                    onFocus={() => this.setState({ showDatePicker: true })}
                  ></input>
                  {showDatePicker && (
                    <div className='DateRangePicker-firstdiv'>
                      <div className='DateRangePicker-seconddiv'>
                        <DateRangePicker
                          onChange={item => { this.setDateRangeFn(item) }}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={1}
                          ranges={dateRange}
                          direction="horizontal"
                          inputRanges={[]}
                          staticRanges={this.defaultStaticRanges}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="select-search">
                  <label style={{ marginBottom: '5px', display: 'block' }}>Show Conversion Hits Only</label>
                  <Select
                    options={[{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }]}
                    labelIndex="label"
                    valueIndex="value"
                    name="cityId"
                    defaultOptionProp={{ value: 2, label: 'Show All Hits' }}
                    isAllowDefaultOpen={true}
                    onChange={this.handleappointmentFilteration}
                    value={this.state.appointmentFilteration}
                  />

                </div>
                <div className="input-search">
                  <div className="input-control">
                    <input
                      placeholder="Search"
                      value={searchText}
                      onChange={this.handleChange}></input>
                    {searchText && (
                      <button className="btn-clear" onClick={this.handleClearSearch}>
                        <img src={images.close} alt="clear" />
                      </button>
                    )}
                  </div>
                  <button className='searchBtn' onClick={this.handleSearch}>Search</button>
                </div>
              </div>
              {totalRecord > 0 && (
                <div style={{ display: "flex" }}>
                  <input onChange={() => this.setState({ viewSummery: !viewSummery }, () => this.getTotalValue(null, daysDifference))} checked={viewSummery} type="checkbox" id="summary" name="summary" value="summary" />
                  <label style={{ paddingTop: "6px", paddingLeft: "5px" }} htmlFor="summary"> View summary of all providers</label>
                </div>
              )}
            </div>
            <div className="ghostCount">
              <span>{totalRecord} providers found.</span>
            </div>
            <div className="table-overflow">
              <div className="rtable">
                <div className="heading">
                  <div
                    className="cell pointer"
                    data-key="firstName"
                    onClick={this.handleSortingChange}>
                    Provider Name{this.renderShortIcon('firstName')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="npiNumber"
                  >
                    Total Hits
                  </div>
                  <div
                    className="cell pointer"
                    data-key="specialties"
                  >
                    Logged in User Hits
                  </div>
                  <div
                    className="cell pointer"
                    data-key="address"
                  >
                    Unregistered User Hits
                  </div>
                  <div
                    className="cell pointer"
                    data-key="gender"
                  >
                    Unique Device Hits
                  </div>
                  <div
                    className="cell pointer"
                    data-key="isClaimed"
                  >
                    Unique Logged in User Hits
                  </div>
                  <div className="cell edit-cell">Appointment Conversions</div>
                  <div className="cell edit-cell">IOS App Hits</div>
                  <div className="cell edit-cell">Android App Hits</div>
                  <div className="cell edit-cell">Mobile Browser Hits</div>
                  <div className="cell edit-cell">Desktop Browser Hits</div>
                </div>
                {viewSummery && Object.keys(totalHits).length>0 && (
                  <div className={'row'} key={'allPro'}>
                    <div className="cell"><div style={{ display: 'flex', gap: "5px" }}>All Providers{daysDifference > 0 && (
                      <img onClick={() => this.viewVisualizations(null, selectedRange, daysDifference)} style={{ width: "15px", height: "15px", cursor: "pointer" }} src={images.graph_icon} />
                    )}</div></div>
                    <div className="cell">{_.get(totalHits, 'finalTotalHits', 0)}</div>
                    <div className="cell">{_.get(totalHits, 'totalLoggedInHits', 0)}</div>
                    <div className="cell">{_.get(totalHits, 'totalUnRegisteredUserHits', 0)}</div>
                    <div className="cell">{_.get(totalHits, 'totalUniqueDeviceGuid', 0)}</div>
                    <div className="cell">{_.get(totalHits, 'totalUniqueLoggedInHits', 0)}</div>
                    <div className="cell">{_.get(totalHits, 'totalAppointmentConversions', 0)}</div>
                    <div className="cell">{_.get(totalHits, 'totalProviderProfileTotalIosApphits', 0)}</div>
                    <div className="cell">{_.get(totalHits, 'totalProviderProfileTotalAndroidApphits', 0)}</div>
                    <div className="cell">{_.get(totalHits, 'totalProviderProfileTotalMobileBrowserhits', 0)}</div>
                    <div className="cell">{_.get(totalHits, 'totalProviderProfileTotalDesktopBrowserhits', 0)}</div>

                  </div>
                )}
                {_.map(list, (provider, index) => (
                  <div className={'row'} key={index}>
                    <div className="cell">
                      <div style={{ display: 'flex', gap: "5px" }}>
                        {_.get(provider, 'seoPageUrl', '') ? (
                          <span
                            className="link-span"
                            onClick={() => window.open(_.get(provider.providerData, 'seoPageUrl', ''), '_blank')}>
                            {`${this.getProviderName(provider.providerData.user)}`}
                          </span>
                        ) :
                          (
                            `${this.getProviderName(provider)}`)}

                        {daysDifference > 0 && (
                          <img onClick={() => this.viewVisualizations(provider, selectedRange, daysDifference)} style={{ width: "15px", height: "15px", cursor: "pointer" }} src={images.graph_icon} />
                        )}
                      </div>
                    </div>
                    <div className="cell">{_.get(provider, 'totalHits', 0)}</div>
                    <div className="cell">
                      {_.get(provider, 'loggedInHits', 0)}
                    </div>
                    <div className="cell">{_.get(provider, 'unRegisteredUserHits', 0)}</div>
                    <div className="cell">{_.get(provider, 'uniqueDeviceGuid', 0)}</div>
                    <div className="cell">{_.get(provider, 'uniqueLoggedInHits', 0)}</div>
                    <div className="cell">{_.get(provider, 'appointmentConversions', 0)}</div>
                    <div className="cell">{_.get(provider, 'providerProfileTotalIosApphits', 0)}</div>
                    <div className="cell">{_.get(provider, 'providerProfileTotalAndroidApphits', 0)}</div>
                    <div className="cell">{_.get(provider, 'providerProfileTotalMobileBrowserhits', 0)}</div>
                    <div className="cell">{_.get(provider, 'providerProfileTotalDesktopBrowserhits', 0)}</div>
                  </div>
                ))}
              </div>
            </div>
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default GhostProfiles;
