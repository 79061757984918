import React, {Component} from 'react';
import review from 'services/review';
import _ from 'lodash';
import selectOptions from 'data/selectOptions';
import ReviewWidgetView from './ReviewWidgetView';

const {getReviewsForWidget} = review;

class ReviewWidget extends Component {
  state = {
    reviewData: {},
    shapeClass: '',
    backgroundColor: '026ca2',
  };
  componentDidMount() {
    const {match} = this.props;
    const providerId = _.get(match, 'params.providerId', 0);
    const shape = _.get(match, 'params.shape', 1);
    const color = _.get(match, 'params.color', '026ca2');
    this.getReviewData(providerId);
    const shapeData = _.find(selectOptions.reviewShapeOptions, data => data.value === +shape);
    const shapeClass = _.get(shapeData, 'className', '');
    this.setState({
      shapeClass: shapeClass,
      backgroundColor: color,
    });
  }
  getReviewData = async providerId => {
    try {
      const response = await getReviewsForWidget(providerId); //2342423423
      this.setState({reviewData: _.get(response, 'data', {})});
    } catch (ex) {
      console.log(ex);
    }
  };
  render() {
    const {reviewData, shapeClass, backgroundColor} = this.state;
    return (
      <ReviewWidgetView
        reviewData={reviewData}
        shapeClass={shapeClass}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default ReviewWidget;
