import React from 'react';
import './UILabel.css';

const UILabel = props => {
  return (
    <div className="UILabel">
        UI ONLY
    </div>
  );
};

export default UILabel;
