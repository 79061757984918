import React, { Component } from 'react';
import Introduction from 'components/Introduction';
import BetterTools from 'components/BetterTools/BetterTools';
import Taking from 'components/Taking';
import Divider from 'components/Divider';
//import Team from 'components/Team';
import Team, { KalypsysAdvisors } from 'components/Team/Team';
import joinUsService from 'services/joinUs';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import masterService from 'services/master';
import Header from 'layout/Header';
import Joinkalypsys from 'components/Joinkalypsyssplit';
import Footer from 'layout/Footer';
import helper from 'data/helper';
import './LandingPage.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import PatientLandingHeader from 'layout/PatientLayout/PatientLandingHeader';
import ListyourpracticeIntro from 'components/ListyourpracticeIntro';
import ListyourpracticeIntrobodyText from 'components/ListyourpracticeIntrobodyText';
import Differentiateyourpractice from 'components/differentiateyourpractice';
import PatientFooter, { PatientFooterNew } from 'layout/PatientLayout/PatientFooter';
import AuthContext from 'context/AuthContext';
const { getAllSpecialties } = masterService;
const { joinUs } = joinUsService;
const { successModalData, errorModalData } = modalConfig.modalFunctions;
const { scrollToDiv, getLocation } = helper;
const { Analytics } = analyticsData;

class LandingPage extends Component {
  static contextType = RootContext;
  authContext = null;
  componentDidMount() {
    this.getSpecialities();
    localStorage.setItem('islistyourpractice', true)
    this.checkLoginAndLogout()

    const { setGlobal } = this.context;
    getLocation(position => {

      if (Object.keys(position).length > 0) {

        setGlobal('geoLocationDetails', position.loc_details);
      }
    })
    if (window.location.href.toLowerCase().endsWith('#signup')) {
      setTimeout(() => {
        let element = document.getElementsByClassName('Joinkalypsys');
        if (element.length > 0) {
          scrollToDiv('Joinkalypsys');
        } else {
          let timerVar = setInterval(() => {
            let elementJk = document.getElementsByClassName('Joinkalypsys');
            if (elementJk.length > 0) {
              scrollToDiv('Joinkalypsys');
              clearInterval(timerVar)
            }
          })
        }
      }, 1000);
    }

  }

  componentWillUnmount() {
    localStorage.removeItem('islistyourpractice')
  }
  checkLoginAndLogout = () => {
    const { auth, updateAuthStatus } = this.authContext;
    if (auth) {

      updateAuthStatus(null, false)

    }
    setTimeout(() => {
      localStorage.removeItem('islistyourpractice')
    }, 3000);
  }
  scrollToJoinkalypsys = () => {
    Nux.sendEvent(Analytics.Category.DoctorLanding, Analytics.Actions.Click, 'listWithUs');
    scrollToDiv('Joinkalypsys');
  };
  scrollToTeam = () => {
    scrollToDiv('Team');
  };
  // get and set specialties to global store
  getSpecialities = async () => {
    const { setGlobal } = this.context;
    const response = await getAllSpecialties().catch(this.handleError);
    if (response) {
      let { data: specialties } = response;
      specialties = [
        ...specialties,
        {
          algoFile: 'specialty not listed',
          id: 'null',
          isTop: false,
          pluralName: 'specialty not listed',
          specialty: 'Specialty not listed',
        },
      ];
      setGlobal('specialties', specialties);
    }
  };

  handleError = error => {
    const { setGlobal } = this.context;
    const { message } = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  handleJoinKalypsys = async (formValues, resetForm) => {
    const { setGlobal } = this.context;
    const response = await joinUs(formValues).catch(this.handleError);
    if (response) {
      const { message } = response;
      // reset form if data has been saved successfully
      resetForm();
      setGlobal('modal', successModalData(message));
    }
  };
  render() {
    const { specialties } = this.context;
    return (
      <>
        {/* <PatientLandingHeader />
        <div className="LandingPage">
          <ListyourpracticeIntro />
          <ListyourpracticeIntrobodyText />
         
          <Differentiateyourpractice />
       
          <KalypsysAdvisors/>
          <div style={{background:'#F3F6F6'}}>
            {specialties && <Joinkalypsys formSubmit={this.handleJoinKalypsys}></Joinkalypsys>}
          
            <PatientFooter />
          </div>
        </div> */}
        <AuthContext.Consumer>
          {context => {
            this.authContext = context;
            return null;
          }}
        </AuthContext.Consumer>
        <PatientLandingHeader hideBtn={true} scrollToJoinkalypsys={this.scrollToJoinkalypsys} />
        <div className="LandingPage">
          <ListyourpracticeIntro lp={this.props.lp ? this.props.lp : ''} scrollToJoinkalypsys={this.scrollToJoinkalypsys} />
          <ListyourpracticeIntrobodyText />
          <Differentiateyourpractice scrollToTeam={this.scrollToTeam} scrollToJoinkalypsys={this.scrollToJoinkalypsys} />
          {/* <BetterTools></BetterTools>
          <Divider></Divider> */}
          {/* <Taking scrollToJoinkalypsys={this.scrollToJoinkalypsys}></Taking> */}
          <Divider></Divider>
          <Team></Team>
          <div className="gradient">
            {specialties && <Joinkalypsys scrollToJoinkalypsys={this.scrollToJoinkalypsys} formSubmit={this.handleJoinKalypsys}></Joinkalypsys>}
            <Divider></Divider>
            <PatientFooterNew />
          </div>
        </div>
      </>
    );
  }
}

export default LandingPage;
