const questionList = [
  {
    id: 241,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [1, 2, 4, 5, 6], // painIds,
    nextQuestionId: 242,
    descriptions: [],
  },
  {
    id: 242,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 243,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 84, visitReasonIds: [4], extraQuestion: 1},
          {painId: 2, questionId: 163, visitReasonIds: [3], extraQuestion: 1},
          {painId: 4, questionId: 43, visitReasonIds: [1], extraQuestion: 1},
          {painId: 5, questionId: 104, visitReasonIds: [2], extraQuestion: 1},
          {painId: 6, questionId: 123, visitReasonIds: [5], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 243,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 83, visitReasonIds: [4], extraQuestion: 3},
          {painId: 2, questionId: 164, visitReasonIds: [3], extraQuestion: 3},
          {painId: 4, questionId: 44, visitReasonIds: [1], extraQuestion: 3},
          {painId: 5, questionId: 103, visitReasonIds: [2], extraQuestion: 3},
          {painId: 6, questionId: 124, visitReasonIds: [5], extraQuestion: 3},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 243,
    question: 'Are you interested in potentially undergoing surgery?',
    options: [
      {
        nextQuestionId: 244,
        label: 'Yes, in the near future.',
        shortLabel: 'Interested in surgery',
      },
      {nextQuestionId: 245, label: 'No, not at this time. ', shortLabel: 'No surgery'},
    ],
    descriptions: [],
  },
  {
    id: 244,
    question: 'Have you had recent imaging studies? ',
    questionType: 4,
    optionType: 1,
    options: [
      {nextQuestionId: 246, label: 'Yes'},
      {nextQuestionId: 247, label: 'No'},
    ],
    descriptions: [
      'Some spine surgeons require recent advanced diagnostic imaging (MRI/CT) prior to confirming your appointment to make sure you are a right candidate. ',
    ],
  },
  {
    id: 245,
    question:
      'Would you still like to see a spine surgeon or are you interested in seeing a non-surgical specialist for your condition?',
    questionType: 2,
    options: [
      {nextQuestionId: 244, label: 'Yes, spine surgeon', isGoToSymptomFlow: false},
      {label: 'Non surgical specialist', isGoToSymptomFlow: true},
    ],
    descriptions: [
      'Some spine surgeons will only schedule patients who are interested in undergoing spine surgery in the near future.',
    ],
  },
  {
    id: 246,
    question: 'Do you still want to see a spine surgeon?',
    descriptions: [
      'A Spine Surgeon is a medical physician (MD/DO) who completed a 1 year fellowship in spine surgery, after undergoing a 5-7 year residency in Orthopedic surgery or Neurosurgery',
      'A Spine Surgeon has specific training in treating spine related conditions that may require surgery. A Spine Surgeon can also prescribe medications.',
    ],
    title: 'Spine Surgeon',
    optionType: 0,
    questionType: 1,
    canDoList: [],
    options: [
      {groupId: 'P', label: 'Yes, see Spine Surgeons', isGoToSymptomFlow: false},
      {label: 'No, I am looking for something else', isGoToSymptomFlow: true},
    ],
  },
  {
    id: 247,
    question: 'Do you still want to see a spine surgeon?',
    descriptions: [
      'A Spine Surgeon is a medical physician (MD/DO) who completed a 1 year fellowship in spine surgery, after undergoing a 5-7 year residency in Orthopedic surgery or Neurosurgery',
      'A Spine Surgeon has specific training in treating spine related conditions that may require surgery. A Spine Surgeon can also prescribe medications.',
    ],
    title: 'Spine Surgeon',
    optionType: 0,
    questionType: 1,
    canDoList: [],
    options: [
      {groupId: 'O', label: 'Yes, see Spine Surgeons', isGoToSymptomFlow: false},
      {label: 'No, I am looking for something else', isGoToSymptomFlow: true},
      {
        groupId: 'Q',
        label: 'No, I am looking to undergo imaging studies.',
        isGoToSymptomFlow: false,
      },
    ],
  },
  {
    id: 248,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'N',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'Q',
        label: 'Diagnostic imaging studies (MRI/xray) and/or medications.',
        shortLabel: 'Imaging/Meds',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'Q',
        label: 'I am not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
      },
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 241,
    lastQuestionId: 246,
    treeDepth: 5,
  },
};
