import _ from 'lodash';
import axiosInstance from 'config/axios';
import helper from 'data/helper';
import moment from 'moment';
import cookieHelper from 'data/cookie.helper';
const {getCookie} = cookieHelper;
const {detect} = require('detect-browser');
const browser = detect();
const {getFileNameFromUrl, getLocationAwait} = helper;

const getLoggedInUser = () => {
  return axiosInstance.get('v1/user');
};

const signIn = data => {
  return new Promise(async (resolve, reject) => {
    let passData = {...data};
    let ipData = await getLocationAwait();
    passData.screenWidth = window.screen.width;
    passData.screenHeight = window.screen.height;
    passData.countryId = ipData?.loc_details?.country_code;
    passData.location = ipData?.loc_details?.location;
    passData.postalCode = ipData?.loc_details?.zip;
    passData.ipAddress = ipData?.loc_details?.ip;
    passData.platform = browser.os;
    passData.browser = browser.name;
    passData.version = browser.version;
    let kdid = getCookie('kdid');
    if (kdid) {
      passData.kdid = kdid;
    }
    resolve(axiosInstance.post(`v1/sign-in`, passData));
  });
};

const signUp = data => {
  const {formValues, ...otherData} = data;
  const {step} = otherData;
  // add timezone in provider signup
  if (window.Intl) {
    otherData.timezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  let newFormData = {...formValues};
  // step 2 has two sub steps.
  let additionalUrl = '';
  if (step === 2) {
    additionalUrl = '/practice';
    const {subStep1Data, subStep2Data} = newFormData;
    newFormData = {
      ...subStep1Data,
      ...subStep2Data,
    };
    if (newFormData['logoUrl']) {
      newFormData['logo'] = getFileNameFromUrl(newFormData['logoUrl']);
    }
    newFormData['appointmentEmails'] = [];
    if (newFormData['appointmentEmail']) {
      newFormData['appointmentEmails'].push(newFormData['appointmentEmail']);
    }
    newFormData['websites'] = [];
    if (newFormData['website']) {
      newFormData['websites'].push(newFormData['website']);
    }
  }
  if (step === 4) {
    additionalUrl = '/provider';
    const {subStep1Data, subStep2Data} = newFormData;
    newFormData = {
      ...subStep1Data,
      ...subStep2Data,
    };
  }
  if (step === 5) {
    additionalUrl = '/invite';
  }
  return axiosInstance.post(`v1/sign-up${additionalUrl}`, {...newFormData, ...otherData});
};

const getUserFromToken = token => {
  return axiosInstance.get(`v1/sign-up/${token}`);
};

const verifyEmailChangeByToken = token => {
  return axiosInstance.get(`v1/update-email/${token}`);
};

const requestEmailChange = data => {
  return axiosInstance.put(`v1/user/email`, data);
};
const validateEmailorPhoneNumber = data => {
  return axiosInstance.post(`v1/validate-email-or-phonenumber`, data);
};
const changePassword = data => {
  return axiosInstance.put(`v1/user/password`, data);
};

const forgetPasswordByEmail = data => {
  return axiosInstance.post(`v1/forgot-password`, data);
};

const verifyResetToken = token => {
  return axiosInstance.get(`v1/reset-password/${token}`);
};

const resetPassword = data => {
  return axiosInstance.post(`v1/reset-password`, data);
};

const getAllMyPracticeProvider = () => {
  return axiosInstance.get('v1/provider/list');
};

const getAllMyPracticeStaff = () => {
  return axiosInstance.get('v1/staff/list');
};

const inviteToBeStaff = data => {
  return axiosInstance.post('v1/staff/create', data);
};

const getAllInvitedUsers = () => {
  return axiosInstance.get('v1/provider/invited');
};

const inviteToBeProvider = data => {
  return axiosInstance.post('v1/provider/invite', data);
};

const updateProviderStatus = data => {
  return axiosInstance.put('v1/provider/status', data);
};

const deleteProvider = (providerId, parmams = '') => {
  return axiosInstance.delete(`v1/provider/user/${providerId}/${parmams}`);
};

const updateStaffStatus = data => {
  return axiosInstance.put('v1/staff/status', data);
};

const deleteStaff = staffId => {
  return axiosInstance.delete(`v1/staff/user/${staffId}`);
};

const getProviderByProviderId = providerId => {
  return axiosInstance.get(`v1/provider/details/${providerId}`);
};

const updateProviderOverview = data => {
  return axiosInstance.put(`v1/provider/overview`, data);
};

const updateProviderAdditionalInfo = data => {
  return axiosInstance.put(`v1/provider/information`, data);
};

const updateProviderSpecialities = data => {
  return axiosInstance.put(`v1/provider/specialties`, data);
};

const updateProviderCommonData = data => {
  return axiosInstance.put(`v1/provider/specialties-conditions-procedures`, data);
};

const updateProviderConditions = data => {
  return axiosInstance.put(`v1/provider/conditions`, data);
};

const updateProviderProcedures = data => {
  return axiosInstance.put(`v1/provider/procedures`, data);
};

const getProviderVisitReasons = data => {
  return axiosInstance.get(`v1/provider/visit-reasons`, data);
};

const updateProviderVisitReasons = data => {
  return axiosInstance.put(`v1/provider/visit-reasons`, data);
};

const getDashboardAnalysisData = () => {
  return axiosInstance.get(`v1/dashboard/provider`).then(formatDashboardAnalysResponse);
};

const getPatientAnalysisData = patientId => {
  return axiosInstance.get(`v1/dashboard/appointment/${patientId}`);
};

const canSwitchToUser = userId => {
  return axiosInstance.get(`v1/provider/verify/${userId}`);
};

const verifyAnonymousEmail = token => {
  return axiosInstance.get(`v1/npi/review/anonymous-email-verify/${token}`);
};

const verifyEmail = data => {
  return axiosInstance.post('v1/validate/email', data);
};
const newDeviceNotification = data => {
  return axiosInstance.post('v1/developer/newDeviceNotification', data);
};
const getStripeProducts = data => {
  return axiosInstance.get('v1/developer/stripeProducts');
};
const verifyPromoCode = data => {
  return axiosInstance.post('v1/developer/verifyPromoCode', data);
};
const registerPracticeWithStripe = data => {
  return axiosInstance.post(`v1/stripe/registerPracticeWithStripe`, {...data});
};
const getReferredByPracticeIdDetails = referredPracticeId => {
  return axiosInstance.get(`v1/find/referredPractice/${referredPracticeId}`);
};
export default {
  getLoggedInUser,
  signIn,
  signUp,
  getUserFromToken,
  forgetPasswordByEmail,
  verifyResetToken,
  resetPassword,
  verifyEmailChangeByToken,
  requestEmailChange,
  changePassword,
  getAllMyPracticeProvider,
  getAllMyPracticeStaff,
  inviteToBeStaff,
  getAllInvitedUsers,
  inviteToBeProvider,
  updateProviderStatus,
  updateStaffStatus,
  deleteProvider,
  deleteStaff,
  getProviderByProviderId,
  updateProviderOverview,
  updateProviderAdditionalInfo,
  updateProviderSpecialities,
  updateProviderConditions,
  updateProviderProcedures,
  updateProviderVisitReasons,
  getProviderVisitReasons,
  getDashboardAnalysisData,
  getPatientAnalysisData,
  canSwitchToUser,
  verifyAnonymousEmail,
  verifyEmail,
  updateProviderCommonData,
  newDeviceNotification,
  getStripeProducts,
  verifyPromoCode,
  registerPracticeWithStripe,
  getReferredByPracticeIdDetails,
  validateEmailorPhoneNumber,
};

const formatDashboardAnalysResponse = response => {
  const {data} = response;
  const {totalUpcomingAppointment = 0} = data;
  const {totalNewPatient = 0} = data;
  const {totalBooking = 0} = data;
  const {totalKScore = 0, kScoreRatio = 0, patientTrend = 0, bookingTrend = 0} = data;

  const profileStatusData = {
    isPracticeUpdated: _.get(data, 'isPracticeUpdated', false),
    isProviderUpdated: _.get(data, 'isProviderUpdated', false),
    isAvailabilityUpdated: _.get(data, 'isAvailabilityUpdated', false),
    isVisitReasonUpdated: _.get(data, 'isVisitReasonUpdated', false),
    isConditionUpdate: _.get(data, 'isConditionUpdate', false),
    isProcedureUpdate: _.get(data, 'isProcedureUpdate', false),
  };
  const analysisDataArray = [
    {
      number: totalUpcomingAppointment,
      title: 'Upcoming Appointments',
    },
    {
      number: totalNewPatient,
      title: 'New Patients',
      UpDown: true,
      trendKey: patientTrend,
    },
    {
      number: totalBooking,
      title: 'Total Bookings',
      UpDown: true,
      trendKey: bookingTrend,
    },
    {
      number: totalKScore.toFixed(1),
      title: 'Your kScore',
      parcentText: kScoreRatio, //.toFixed(1),
      infoIconVisible: true,
    },
  ];
  return {...response, data: {analysisData: analysisDataArray, profileStatusData}};
};
