import React, {Component} from 'react';
import _ from 'lodash';
import Layout from 'layout/AdminLayout';
import RootContext from 'context/RootContext';
import SubHeader from 'components/AfterAuth/SubHeader';
import KScore from 'components/AfterAuth/KScore';
import Select from 'components/FormControls/Select';
import ReviewTab from '../ReviewTab';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import modalConfig from 'modals/modalConfig';
import contextHelper from 'context/contextHelper';
import './ProviderReviews.css';
import adminService from 'services/admin';
import selectOptions from 'data/selectOptions';

const {modalFunctions} = modalConfig;
const {errorModalData, confirmModalData} = modalFunctions;
const {
  getProviderReviews,
  getProviderReviewList,
  featureReview,
  editProviderReply,
  deleteProviderReply,
  deleteProviderReview,
  getProviderReviewSource,
  rejectReviewDeleteRequest,
} = adminService;

class ProviderReviews extends Component {
  static contextType = RootContext;
  state = {
    activeTabIndex: 0,
    userId: '',
    KScoreData: null,
    searchText: '',
    filterType: '',
    filterSourceId: null,
    sortBy: 'recent',
    currentPage: 1,
    totalPage: 0,
    reviewList: [],
    filterOptions: [],
    sourceList: [],
    isDeleteRequest: 0,
  };
  tabs = [{label: 'All Reviews', count: 0}, {label: 'Review Source'}];
  authContext = {};
  oldAuthContext = null;

  componentDidMount = () => {
    this.getProviderReviews();
    this.scroll();
  };

  getReviewSource = async userId => {
    const id = userId ? userId : this.state.userId;
    const response = await getProviderReviewSource(id).catch(this.handleError);
    let sourceList = [];
    sourceList.push({value: 1, label: 'Kaly'});
    if (response) {
      _.forEach(response.data, source => {
        const sourceData = {...source.reviewSource};
        sourceList.push({value: sourceData.id, label: sourceData.title});
      });
      this.setState({
        filterOptions: _.orderBy(sourceList, ['label'], ['asc']),
        sourceList: response.data,
      });
    }
    
  };

  getProviderReviews = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const userId = query.get('user') || 0;
    const isRequest = query.get('isRequest') || 0;
    this.getkScore(userId);
    this.getReviews(userId, null, isRequest);
    this.getReviewSource(userId);
    if (isRequest === '1') {
      this.setDeleteRequest(isRequest);
    }
  };

  scroll = () => {
    if (this.props.location.hash) {
      //Ref: https://stackoverflow.com/questions/11662693/how-do-i-get-the-fragment-identifier-value-after-hash-from-a-url
      const id = this.props.location.hash.substr(1);
      if (!id) {
        return;
      }
      const element = document.getElementById(id);
      if (element) {
        //Ref: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
        element.scrollIntoView({behavior: 'smooth'});
      }
    }
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  getkScore = async reviewUserId => {
    const id = reviewUserId ? reviewUserId : this.state.userId;
    if (id) {
      const response = await getProviderReviews(id).catch(this.handleError);
      if (response) {
        const {data} = response;
        if (data) {
          const {totalReview} = data;
          this.tabs[0].count = totalReview;
          const KScoreData = this.formatKScoreData(data);
          this.setState({
            KScoreData,
            userId: id,
          });
        }
      }
    }
  };

  getReviews = async (reviewUserId, isPageChange, isRequest,loader=true) => {
    const {sortBy, searchText, filterType, filterSourceId, currentPage} = this.state;
    const id = reviewUserId ? reviewUserId : this.state.userId;
    if (id) {
      const reqData = {
        page: isPageChange ? currentPage : 1,
        limit: 25,
        sortBy: sortBy,
        search: searchText,
        filter: isRequest === '1' ? 'requestForDelete' : filterType,
        sourceId: filterSourceId === 0 ? '' : filterSourceId,
      };
      const reviewResponse = await getProviderReviewList(id, reqData,loader).catch(this.handleError);
      let reviewData = reviewResponse.data ? reviewResponse.data.reviewData : null;
      let totalPageCount = reviewResponse.data ? reviewResponse.data.totalPage : 0;
      if (isPageChange) {
        reviewData = [...(this.state.reviewList || []), ...reviewData];
      }
      this.setState({
        reviewList: reviewData,
        userId: id,
        totalPage: totalPageCount,
        currentPage: reqData.page,
      });
    }
  };

  setDeleteRequest = isDelete => {
    this.setState({isDeleteRequest: isDelete, filterType: 'requestForDelete'});
  };

  formatKScoreData = data => {
    const {totalRating, totalReview, doctorRating, staffRating, officeRating} = data;
    const KScoreData = {
      totalReview,
      totalAverageRating: totalRating,
      doctorRatingList: [
        {name: 'Knowledge', rating: doctorRating.knowledge},
        {name: 'Approachable', rating: doctorRating.approachable},
        {name: 'Bedside Manner', rating: doctorRating.bedsideManner},
      ],
      staffRatingList: [
        {name: 'Professional', rating: staffRating.professional},
        {name: 'Scheduling', rating: staffRating.scheduling},
        {name: 'Response Time', rating: staffRating.responseTime},
      ],
      officeRatingList: [
        {name: 'Cleanliness', rating: officeRating.cleanliness},
        {name: 'Atmosphere', rating: officeRating.atmosphere},
        {name: 'Wait times', rating: officeRating.waitTimes},
      ],
    };
    return KScoreData;
  };

  handleSearch = event => {
    this.setState({
      searchText: event.target.value,
    });
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.getReviews();
      }, 500);
    }
    this.debouncedFn();
  };

  handleReply = async data => {
    let response = {};
    if (data && data.replyId) {
      const reqData = {replyId: data.replyId, reply: data.reply};
      response = await editProviderReply(data.reviewId, reqData).catch(this.handleError);
      if (response) {
        const {reviewList} = this.state;
        let updatedReviewList = [...reviewList];
        updatedReviewList = _.map(updatedReviewList, review => {
          let updatedReview = {...review};
          if (review.id === data.reviewId) {
            updatedReview = {
              ...updatedReview,
              reply: {
                reply: response.data && response.data.reply ? response.data.reply : data.reply,
                id: response.data && response.data.id ? response.data.id : data.replyId,
              },
            };
          }
          return updatedReview;
        });
        this.setState({
          reviewList: updatedReviewList,
        });
      }
    }
  };

  handleDeleteReply = replyId => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete this Reply ?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmDelete(replyId);
          }
        },
      }),
    );
  };

  confirmDelete = async data => {
    const response = await deleteProviderReply(data.replyId).catch(this.handleError);
    if (response) {
      const {reviewList} = this.state;
      let updatedReviewList = [...reviewList];
      updatedReviewList = _.map(updatedReviewList, review => {
        let updatedReview = {...review};
        if (review.id === data.reviewId) {
          updatedReview = {
            ...updatedReview,
            reply: null,
          };
        }
        return updatedReview;
      });
      this.setState({
        reviewList: updatedReviewList,
      });
    }
  };

  handleToggleFeatureType = async data => {
    const response = await featureReview(this.state.userId, data);
    if (response) {
      this.getReviews();
    }
  };

  handleFilterChange = event => {
    this.setState({filterType: event.target.value}, this.getReviews);
  };

  handleSourceChange = event => {
    this.setState({filterSourceId: event.target.value}, this.getReviews);
  };

  backToProviderList = () => {
    const {location} = this.props;
    const searchData = _.get(location, 'state', {});
    this.props.history.push({
      pathname: this.state.isDeleteRequest
        ? '/admin/review-request'
        : searchData.searchStatus === 'inActive'
        ? '/admin/join-us/inactive-providers'
        : '/admin/providers',
      state: searchData,
    });
  };

  handleSortChange = newSortBy => {
    this.setState({sortBy: newSortBy}, ()=>
     { this.getReviews(null,null,null,false);
    });
  };

  handlePageChange = () => {
    this.setState(
      prevState => {
        return {
          ...prevState,
          currentPage: prevState.currentPage + 1,
        };
      },
      () => this.getReviews(null, true),
    );
  };

  handleReviewDelete = reviewId => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete this Review ?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmDeleteReview(reviewId);
          }
        },
      }),
    );
  };

  confirmDeleteReview = async reviewId => {
    const response = await deleteProviderReview(reviewId).catch(this.handleError);
    if (response) {
      const {reviewList} = this.state;
      const updatedReviewList = [...reviewList];
      this.setState({
        reviewList: updatedReviewList.filter(review => review.id !== reviewId),
      });
    }
  };

  handleRejectRequest = data => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to reject this Review Request?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmRejectRequest(data);
          }
        },
      }),
    );
  };

  confirmRejectRequest = async data => {
    const response = await rejectReviewDeleteRequest(this.state.userId, data).catch(
      this.handleError,
    );
    if (response) {
      this.getReviews();
    }
  };

  handleActiveTabIndex = index => {
    this.setState({activeTabIndex: index});
  };

  render() {
    const {
      activeTabIndex,
      reviewList,
      searchText,
      KScoreData,
      sortBy,
      filterOptions,
      currentPage,
      totalPage,
      sourceList,
      isDeleteRequest,
      filterType,
    } = this.state;
    const title = 'Provider Reviews';

    return (
      <Layout>
        {contextHelper.AuthContextConsumer(this)}
        <div className="ProviderReviews">
          <SubHeader title={title} hideName />
          <div className="main-section-content">
            <div className="back-provider" onClick={this.backToProviderList}>
              <span className="left-arrow">
                <img src={images.arrowLeft} alt="left-arrow" />
              </span>
              Back to All {isDeleteRequest ? 'Requests' : 'Providers'}
            </div>
            {KScoreData && (
              <div className="box-panle">
                <KScore data={KScoreData}></KScore>
              </div>
            )}
            <div className="box-panle">
              {activeTabIndex === 0 && (
                <div className="search-review-list">
                  <Select
                    options={selectOptions.reviewFilterOptions}
                    onChange={this.handleFilterChange}
                    defaultOptionProp={{value: '', label: 'All Reviews'}}
                    isAllowDefaultOpen={true}
                    value={filterType}></Select>
                  <Select
                    options={filterOptions}
                    onChange={this.handleSourceChange}
                    defaultOptionProp={{value: '', label: 'All Sources'}}
                    isAllowDefaultOpen={true}
                  />
                  <Input
                    placeholder="Search Review"
                    onChange={this.handleSearch}
                    value={searchText}
                    icon={images.search}></Input>
                </div>
              )}

              <ReviewTab
                onReply={this.handleReply}
                onToggleFeatureType={this.handleToggleFeatureType}
                searchText={searchText}
                tabs={this.tabs}
                reviewList={reviewList}
                activeTabIndex={activeTabIndex}
                onDelete={this.handleDeleteReply}
                getReviews={this.getReviews}
                currentPage={currentPage}
                handleSortChange={this.handleSortChange}
                handlePageChange={this.handlePageChange}
                onReviewDelete={this.handleReviewDelete}
                hasMorePage={totalPage === currentPage || totalPage === 0 ? false : true}
                sortType={sortBy}
                onRejectRequest={this.handleRejectRequest}
                onActiveTabChange={this.handleActiveTabIndex}
                sourceList={sourceList}
                onRefresh={this.getReviewSource}
                getProviderReviews={this.getProviderReviews}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ProviderReviews;
