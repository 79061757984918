import React, { Component, useRef } from 'react';
import constants from 'data/constants';
import _ from 'lodash';
import './Metrics.css';
import images from 'data/images';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Print } from './PrintBtn';
const { DefaultDebounceMSec } = constants;


const defineds = {

  startOfToday: moment().startOf('day').toDate(),
  endOfToday: moment().endOf('day').toDate(),
  startLast7day: moment().subtract(7, 'days').toDate(),
  startLast14day: moment().subtract(14, 'days').toDate(),
  startLast30day: moment().subtract(30, 'days').toDate(),
  LastYear: moment().subtract(365, 'days').toDate(),

};
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);


const Visualizations = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.calenderRef = React.createRef();
  //   this.componentRef = React.createRef();
  // }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Provider Hits Over Time - ${props.name} (${props.range}) ${props.daysDifference + 1} days`,
      },
    },
  };

  //static contextType = RootContext;




  // componentDidMount() {

  // //  this.getStateList();
  //   document.addEventListener('mousedown', this.handleClickOutside, true);
  //   let range=[ {
  //     startDate: defineds.startLast7day,
  //     endDate: new Date(),
  //     key: 'selection'
  //   }]
  //   this.setState({dateRange:range},()=>{
  //     this.setProviderList(false, true);
  //   })
  // }


  // componentWillUnmount=()=>{
  //   document.removeEventListener('mousedown', this.handleClickOutside, true);
  // }









  const closeModal = () => {
    const { setGlobal } = props
    setGlobal('modal', { showModal: false });
  };

  const getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(moment(dt).format('MMM DD'));
    }
    return arr;
  };
  const getWeeksArray = function (start, end) {
    for (var arr = {}, dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr[`Week ${moment(dt).isoWeek()}`] = [];
    }
    return Object.keys(arr);
  };
  const getMonthArray = function (start, end) {
    for (var arr = {}, dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr[`${moment(dt).format('MMM YYYY')}`] = [];
    }
    return Object.keys(arr);
  };
  const setxaxis = (daysDifference, data) => {
    let returnarray = [];
    if (daysDifference+1 <= 30) {
      returnarray = data.map((item) => moment(Object.keys(item)[0]).format('MMM DD'))
    } else if (daysDifference+1 <= 90) {
      returnarray = data.map((item) => `Week ${Object.keys(item)[0]}`)
    } else if (daysDifference+1 >= 91) {
      returnarray = data.map((item) => {
        let itemarray = Object.keys(item)[0].split('-')
        return `${moment(itemarray[1],'MM').format('MMM')} ${itemarray[0]}`
      })
    }
    return returnarray;
  }
  const { daysDifference, dateRange, graphData } = props
  let labels = []
  // if (daysDifference <= 30) {
  //   labels = getDaysArray(moment(dateRange[0].startDate).toDate(), moment(dateRange[0].endDate).toDate())
  // }else if(daysDifference <= 90){
  //   labels = getWeeksArray(moment(dateRange[0].startDate).toDate(), moment(dateRange[0].endDate).toDate())
  // }
  // else if(daysDifference >= 91){
  //   labels = getMonthArray(moment(dateRange[0].startDate).toDate(), moment(dateRange[0].endDate).toDate())
  // }
  //labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  labels = setxaxis(daysDifference, graphData)
  const data = {
    labels,
    datasets: [
      {
        fill: false,
        label: 'Total Hits',
        data: graphData.map((item) => item[Object.keys(item)[0]].providerProfileTotalHits),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        fill: false,
        label: 'Unique Device Hits',
        data: graphData.map((item) => item[Object.keys(item)[0]].providerProfileUniqueDeviceGuid),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        fill: false,
        label: 'Appointment Conversions',
        data: graphData.map((item) => item[Object.keys(item)[0]].providerProfileAppointmentConversions),
        borderColor: 'rgb(3, 252, 7)',
        backgroundColor: 'rgba(3, 252, 7, 0.5)',
      },
      {
        fill: false,
        label: 'IOS App Hits',
        data: graphData.map((item) => item[Object.keys(item)[0]].providerProfileTotalIosApphits),
        borderColor: 'rgb(255, 136, 0)',
        backgroundColor: 'rgba(255, 136, 0, 0.5)',
      },
      {
        fill: false,
        label: 'Android App Hits',
        data: graphData.map((item) => item[Object.keys(item)[0]].providerProfileTotalAndroidApphits),
        borderColor: 'rgb(255, 187, 51)',
        backgroundColor: 'rgba(255, 187, 51, 0.5)',
      },
      {
        fill: false,
        label: 'Mobile Browser Hits',
        data: graphData.map((item) => item[Object.keys(item)[0]].providerProfileTotalMobileBrowserhits),
        borderColor: 'rgb(170,102,204)',
        backgroundColor: 'rgba(170,102,204, 0.5)',
      },
      {
        fill: false,
        label: 'Desktop Browser Hits',
        data: graphData.map((item) => item[Object.keys(item)[0]].providerProfileTotalDesktopBrowserhits),
        borderColor: 'rgb(0, 126, 51)',
        backgroundColor: 'rgba(0, 126, 51, 0.5)',
      },
    ],
  };
  // const chartRef = useRef<ChartJS<"line">>(null);
  // console.log("chartRef",chartRef)
  // console.log("this.componentRef",chartRef);
   return (

    <div className='Visualizations'>
      <div className="modal-content-header">
        <div className="h3">{`Provider Hits Over Time - ${props.name} (${props.range}) ${props.daysDifference + 1} days`}</div>
        <div>
          <Print />
          <span className="close" onClick={closeModal}>
            <img src={images.close} alt="close" />
          </span>

        </div>

      </div>
      {/* <div className="search">
              <div ref={this.calenderRef} className="select-search">
                
                <input
                  placeholder="Search by date range"
                  value={selectedRange}
                  style={{width:"95%"}}
                  readOnly={true}
                  onFocus={()=>this.setState({showDatePicker:true})}
                ></input>
                {showDatePicker && (
                <div   className='DateRangePicker-firstdiv'>
                  <div className='DateRangePicker-seconddiv'>
                    <DateRangePicker
                      onChange={item =>{this.setDateRangeFn(item) }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={dateRange}
                      direction="horizontal"
                      inputRanges={[]}
                      staticRanges={this.defaultStaticRanges}
                      maxDate={new Date()}
                    />
                    </div>
                </div>
                )}
              </div>
              <div className="select-search">
              <label style={{marginBottom:'5px',display:'block'}}>Show Conversion Hits Only</label>
                <Select
                  options={[{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }]}
                  labelIndex="label"
                  valueIndex="value"
                  name="cityId"
                  defaultOptionProp={{ value: 2, label: 'Show All Hits' }}
                  isAllowDefaultOpen={true}
                  onChange={this.handleappointmentFilteration}
                  value={this.state.appointmentFilteration}
                />
                
              </div>
              
            </div> */}
      <div className='chartBody'>
        <Line options={options} data={data} id="chartRef" />
      </div>

    </div>

  );

}

export default Visualizations;
