const specialityList = [
  {id: 1, specialty: 'Spine Surgeon', fileName: 'spineSurgeon'},
  {id: 2, specialty: 'Neurosurgeon', fileName: ''},
  {id: 3, specialty: 'Orthopedic Shoulder Surgeon', fileName: 'shoulderSurgeon'},
  {id: 4, specialty: 'Orthopedic Hand Surgeon', fileName: 'handSurgeon'},
  {id: 5, specialty: 'Orthopedic Sports Medicine Surgeon', fileName: 'orthopedicSportsSurgeon'},
  {id: 6, specialty: 'Orthopedic Hip Surgeon', fileName: 'hipSurgeon'},
  {id: 7, specialty: 'Orthopedic Knee Surgeon', fileName: 'kneeSurgeon'},
  {id: 8, specialty: 'Orthopedic Foot And Ankle Surgeon', fileName: 'footAnkleSurgeon'},
  {id: 9, specialty: 'Podiatrist', fileName: 'podiatrist'},
  {id: 10, specialty: 'Orthopedic Surgeon', fileName: 'orthopedicSurgeon'},
  {id: 11, specialty: 'Interventional Spine and Pain Specialist', fileName: 'painManagement'},
  {id: 12, specialty: 'Physiatrist', fileName: 'physiatrist'},
  {id: 13, specialty: 'Neurologist', fileName: 'neurologist'},
  {
    id: 14,
    specialty: 'Interventional Sports Medicine Physician',
    fileName: 'interventionalSportsPhysician',
  },
  {id: 15, specialty: 'Physical Therapist', fileName: 'physicalTherapist'},
  {id: 16, specialty: 'Chiropractor', fileName: 'chiropractor'},
  {id: 17, specialty: 'Acupuncturist', fileName: 'acupuncturist'},
  {id: 18, specialty: 'Primary Care Physician', fileName: 'primaryCarePhysician'},
  {id: 19, specialty: 'Allergist', fileName: ''},
  {id: 20, specialty: 'Audiologist', fileName: ''},
  {id: 21, specialty: 'Cardiologist', fileName: ''},
  {id: 22, specialty: 'Dentist', fileName: 'dentist'},
  {id: 23, specialty: 'Dermatologist', fileName: 'dermatologist'},
  {id: 24, specialty: 'Dietitian', fileName: ''},
  {id: 25, specialty: 'ENT', fileName: ''},
  {id: 26, specialty: 'Emergency Medicine', fileName: ''},
  {id: 27, specialty: 'Endocrinologist', fileName: ''},
  {id: 28, specialty: 'Endodontist', fileName: 'endodontist'},
  {id: 29, specialty: 'Family Physician', fileName: 'familyPhysician'},
  {id: 30, specialty: 'Gastroenterologist', fileName: ''},
  {id: 31, specialty: 'Hearing Specialist', fileName: ''},
  {id: 32, specialty: 'Hematologist', fileName: ''},
  {id: 33, specialty: 'Infectious Disease Specialist', fileName: ''},
  {id: 34, specialty: 'Intertility Specialist', fileName: ''},
  {id: 35, specialty: 'Internist', fileName: 'internist'},
  {id: 36, specialty: 'Naturopathic Doctor', fileName: ''},
  {id: 37, specialty: 'Nephrologist', fileName: ''},
  {id: 38, specialty: 'Internal Medicine Physician', fileName: 'internalMedicinePhysician'},
  {id: 39, specialty: 'Nutritionist', fileName: ''},
  {id: 40, specialty: 'OB-GYN', fileName: ''},
  {id: 41, specialty: 'ONcologist', fileName: ''},
  {id: 42, specialty: 'Ophthalmologist', fileName: ''},
  {id: 43, specialty: 'Optometrist', fileName: ''},
  {id: 44, specialty: 'Oral Surgeon', fileName: 'oralSurgeon'},
  {id: 45, specialty: 'Orthodontist', fileName: 'orthodontist'},
  {id: 46, specialty: 'Pediatric Dentist', fileName: 'pediatricDentist'},
  {id: 47, specialty: 'Pediatric Urgent Care', fileName: ''},
  {id: 48, specialty: 'Pediatrician', fileName: ''},
  {id: 49, specialty: 'Periodontist', fileName: 'periodontist'},
  {id: 50, specialty: 'Plastic Surgeon', fileName: ''},
  {id: 51, specialty: 'Prosthodontist', fileName: 'prosthodontist'},
  {id: 52, specialty: 'Psychiatrist', fileName: ''},
  {id: 53, specialty: 'Psychologist', fileName: ''},
  {id: 54, specialty: 'Psychotherapist', fileName: ''},
  {id: 55, specialty: 'Pulmonologist', fileName: ''},
  {id: 56, specialty: 'Radiologist', fileName: ''},
  {id: 57, specialty: 'Rheumatologist', fileName: ''},
  {id: 58, specialty: 'Sleep Medicine', fileName: ''},
  {id: 59, specialty: 'Surgeon', fileName: ''},
  {id: 60, specialty: 'Mental Health Specialist', fileName: ''},
  {id: 61, specialty: 'Travel Medicine Specialist', fileName: ''},
  {id: 62, specialty: 'Urgent Care Specialist', fileName: ''},
  {id: 63, specialty: 'Urologist', fileName: ''},
  {id: 65, specialty: 'Certified Headache Specialist', fileName: 'headache'},
  {id: 66, specialty: 'Plastic Surgeon', fileName: 'plasticsurgery'},
  {id: 67, specialty: 'OBGYN', fileName: 'obgyn'},
  {id: 68, specialty: 'Gynecologic Oncologist', fileName: 'obgyn'},
  {id: 69, specialty: 'Reproductive Endocrinology and Infertility', fileName: 'obgyn'},
  {id: 70, specialty: 'Urogynecologist', fileName: 'obgyn'},
  {id: 71, specialty: 'Maternal Fetal Medicine Specialist', fileName: 'obgyn'},
  {id: 73, specialty: 'Occupational Therapist', fileName: 'occupationalTherapist'},
];

export default {
  specialityList,
};
