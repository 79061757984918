const questionList = [
  {
    id: 565,
    question: 'What type of procedure are you looking for?',
    optionType: 3,
    procedureOptionIds: [89, 90, 91],
    nextQuestionId: 566,
    descriptions: [],
  },
  {
    id: 566,
    question: 'Do you get nervous when you go to the dentist?',
    questionType: 4,
    optionType: 0,
    options: [
      {
        nextQuestionId: 567,
        label: 'Not at all',
        shortLabel: 'Not nervous',
      },
      {
        nextQuestionId: 567,
        label: 'A little bit',
        shortLabel: 'A little nervous',
      },
      {
        nextQuestionId: 567,
        label: 'Very nervous',
        shortLabel: 'Very nervous',
      },
    ],
    descriptions: [
      'Your answer may help your dentist accomodate you by scheduling you at an ideal time or potentially offering you other options that may help.',
    ],
  },
  {
    id: 567,
    question: 'Do you still want to see an Endodontist?',
    descriptions: [
      'An Endodontist is a dentist (DMD/DDS) who completed a two to three year residency in Endodontic Disorders after completing four years of dental school. An Endodontist specializes in diagnosis and treating problems related to the soft tissue inside the tooth.',
    ],
    title: 'Endodontist',
    optionType: 0,
    questionType: 1,
    canDoList: [],
    options: [
      {groupId: 'A1', label: 'Yes, see Endodontist', isGoToSymptomFlow: false},
      {label: 'No, I am looking for something else', nextQuestionId: 2202, addExtraQuestion: 4},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 565,
    lastQuestionId: 567,
    treeDepth: 3,
  },
};
