const questionList = [
  {
    id: 321,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13,14], // painIds,
    nextQuestionId: 322,
    descriptions: [],
  },
  {
    id: 322,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 323,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 84, visitReasonIds: [217], extraQuestion: 1},
          {painId: 2, questionId: 163, visitReasonIds: [216], extraQuestion: 1},
          {painId: 3, questionId: 224, visitReasonIds: [219], extraQuestion: 1},
          {painId: 4, questionId: 43, visitReasonIds: [211], extraQuestion: 1},
          {painId: 6, questionId: 123, visitReasonIds: [218], extraQuestion: 1},
          {painId: 7, questionId: 144, visitReasonIds: [213], extraQuestion: 1},
          {painId: 8, questionId: 23, visitReasonIds: [221], extraQuestion: 1},
          {painId: 9, questionId: 183, visitReasonIds: [214], extraQuestion: 1},
          {painId: 10, questionId: 24, visitReasonIds: [222], extraQuestion: 2},
          {painId: 11, questionId: 203, visitReasonIds: [215], extraQuestion: 1},
          {painId: 12, questionId: 64, visitReasonIds: [220], extraQuestion: 1},
          {painId: 13, questionId: 203, visitReasonIds: [], extraQuestion: 1},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 323,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 83, visitReasonIds: [217], extraQuestion: 3},
          {painId: 2, questionId: 164, visitReasonIds: [216], extraQuestion: 3},
          {painId: 3, questionId: 225, visitReasonIds: [219], extraQuestion: 1},
          {painId: 4, questionId: 44, visitReasonIds: [211], extraQuestion: 3},
          {painId: 6, questionId: 124, visitReasonIds: [218], extraQuestion: 3},
          {painId: 7, questionId: 143, visitReasonIds: [213], extraQuestion: 2},
          {painId: 8, questionId: 24, visitReasonIds: [221], extraQuestion: 2},
          {painId: 9, questionId: 184, visitReasonIds: [214], extraQuestion: 3},
          {painId: 10, questionId: 24, visitReasonIds: [222], extraQuestion: 2},
          {painId: 11, questionId: 204, visitReasonIds: [215], extraQuestion: 3},
          {painId: 12, questionId: 65, visitReasonIds: [220], extraQuestion: 1},
          {painId: 13, questionId: 204, visitReasonIds: [], extraQuestion: 3},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 323,
    question: 'Do you still want to see a Physical Therapist?',
    descriptions: [
      'A Physical Therapist is a medical professional who has a doctorate in Physical Therapy (DPT), which takes 3 years to complete after obtaining a bachelor’s degree.',
      'A Physical Therapist may be able to treat your condition through conservative treatments such as manual therapy and therapeutic exercises.',
    ],
    title: 'Physical Therapist',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can not prescribe medications',
      'Can not order diagnostic tests',
      'Can not perform injections',
      'Can not perform surgery',
    ],
    options: [
      {groupId: 'L', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 321,
    lastQuestionId: 323,
    treeDepth: 3,
  },
};
