import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Checkbox from 'components/FormControls/Checkbox';
import InputMask from 'react-input-mask';
import RootContext from 'context/RootContext';
import patientService from 'services/patient';
import modalConfig from 'modals/modalConfig';
import validators from 'data/validators';
import AuthContext from 'context/AuthContext';
import config from 'config/config';
import './KalyMDRequestForm.css';
import _ from 'lodash';

import analyticsData from 'data/analytics/analyticsData';
import PlaceAutocomplete from 'components/FormControls/PlaceAutocomplete';
import allRegex from 'data/regex';
import helper from 'data/helper';
import { Kalymdpopup } from 'components/KalyMDPopup/kalymdpopup';
import Textarea from 'components/FormControls/Textarea';
import practiceService from 'services/practice'

const { consultRequestCreate } = patientService
const { consultRequestUpdate } = practiceService;
const { errorModalData, successModalData } = modalConfig.modalFunctions;
const { formatPhoneNumberWithDash } = helper;
const { __required, __email, __maxLength, __phoneNumber } = validators;
const { Analytics } = analyticsData;
const { exceptDigitsRegex, phonenumberRegex } = allRegex;
const { MAX_TEXT_LENGTH } = config;
class KalyMDRequestForm extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {

    values: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: '',
      State: '',
      City: '',
      Zipcode: '',
      PharmacyName: '',
      PharmacyphoneNumber: '',
      callBack: true,
      sms: false,
      readandaccept: false,
      practiceNotes: '',

    },
    requestId: 0,
    hippaAccept: false,
    patientAccept: false,
    errors: {},
  };
  componentDidMount() {
    const { data, isEdit = false } = this.props;
    if (isEdit) {
      let perviousData = {
        firstName: data.patientFirstName,
        lastName: data.patientLastName,
        email: data.patientEmail,
        phoneNumber: data.mobilePhone,
        address: data.patientAddress,
        State: data.state.state,
        City: data.patientCity,
        Zipcode: data.zipCode.zipCode,
        PharmacyName: data.preferredPharmacyName,
        PharmacyphoneNumber: data.preferredPharmacyPhone,
        callBack: data.requestPhoneCall,
        sms: data.requestSMS,
        latitude: data.patientAddress,
        practiceNotes: data.practiceNotes
      }
      this.setState(prevState => {
        return {
          ...prevState,
          values: {
            ...prevState.values,
            ...perviousData

          },
          requestId: data.id
        };
      });
    } else {
      const { auth } = this.context;
      if (auth && Object.keys(auth).length > 0) {
        if (auth.roles[0].id == 2) {
          let perviousData = {
            firstName: auth.firstName,
            lastName: auth.lastName,
            email: auth.email,
            phoneNumber: auth.phoneNumber,
            address: auth.address,
            // State: data.state.state,
            // City: data.patientCity,
            // Zipcode: data.zipCode.zipCode,
            // PharmacyName: data.preferredPharmacyName,
            // PharmacyphoneNumber: data.preferredPharmacyPhone,
            // callBack: data.requestPhoneCall,
            // sms: data.requestSMS,
            // latitude: data.patientAddress,
            // practiceNotes: data.practiceNotes
          }
          this.setState(prevState => {
            return {
              ...prevState,
              values: {
                ...prevState.values,
                ...perviousData

              },
            };
          });
        }
      }
      console.log("auth", auth);
    }
  }



  handleError = error => {
    const { setGlobal } = this.rootContext;
    let { message } = error;

    let objOp = {}


    setGlobal('modal', errorModalData(message, {
      ...objOp
    }));
    return null;
  };
  submitConsultRequestCreate = () => {
    const { values, requestId } = this.state;
    const { answers, flowType, callToActionData, selectedPainId } = this.props
    const { firstName, lastName, email, address, City, State, Zipcode, phoneNumber, callBack, sms, PharmacyName, PharmacyphoneNumber, practiceNotes } = values;
    let patientId = 0
    if (localStorage.getItem('p-id-me') && localStorage.getItem('accessToken')) {
      patientId = localStorage.getItem('p-id-me')
    }
    let passData = {

      "requestPhoneCall": callBack,
      "requestSMS": sms,
      "patientFirstName": firstName,
      "patientLastName": lastName,
      "patientEmail": email,
      "patientAddress": address,
      "patientCity": City,
      "patientState": State,
      "patientZipCode": Zipcode,
      "mobilePhone": phoneNumber
    }
    if (PharmacyName) {
      passData['preferredPharmacyName'] = PharmacyName
    }
    if (PharmacyphoneNumber) {
      passData['preferredPharmacyPhone'] = PharmacyphoneNumber
    }

    const { setGlobal } = this.rootContext;
    if (this.props.isEdit) {
      delete passData.patientEmail;
      passData['practiceNotes'] = practiceNotes
      consultRequestUpdate(passData, requestId).then((res) => {
        const { message } = res;
        setGlobal('modal', successModalData(message, {
          callBackOnClose: () => {
            this.props.getConsultrequestData()
          }
        }));
      }).catch(this.handleError)
    } else {
      passData = {
        ...passData, ...{
          "painId": selectedPainId,
          "practiceId": callToActionData.id,
          "patientId": patientId,
          "flowTypeId": flowType,
          "flowResponse": answers,
        }
      }
      consultRequestCreate(passData).then(() => {
        setGlobal('modal', successModalData(callToActionData.submissionText, {
          callBackOnClose: () => {
            this.props.history.replace('/');
          }
        }));
      }).catch(this.handleError)
    }

  }
  // validation methods
  checkSameNumber = (num) => {

    if ((num[0] === num[1]) && (num[0] === num[2]))
      return true
    return false
  }
  __validateFirstName = (value = null) => {
    const { values: formValues } = this.state;
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First Name is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  __validateLastName = (value = null) => {
    const { values: formValues } = this.state;
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last Name is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  __validateEmail = (value = null) => {
    const { values: formValues } = this.state;
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  __validatePhoneNumber = (value = null, key, labelkey = null) => {

    const { values: formValues } = this.state;
    const fValue = value !== null ? value : formValues[key];
    if (!__required(fValue) && key == 'phoneNumber') {
      return `${labelkey || key} is required`;
    }

    if (fValue && !__phoneNumber(fValue)) {
      return `Invalid ${labelkey || key}`;
    }
    if (fValue && !phonenumberRegex.test(formatPhoneNumberWithDash(fValue))) {
      return `Invalid ${labelkey || key}`;
    }

    if (fValue && formatPhoneNumberWithDash(fValue).length > 2) {
      if (this.checkSameNumber(formatPhoneNumberWithDash(fValue).substring(0, 3))) {
        return `Invalid ${labelkey || key}`;
      }
    }
    return null;
  };
  __validateAddress = (value = null) => {
    const { values: formValues } = this.state;
    const fValue =
      value !== null
        ? value
        : {
          address: formValues['address'],
          // latitude: formValues['latitude'],
          //longitude: formValues['longitude'],
        };

    if (!__required(fValue['address'])) {
      return 'Address is required';
    }
    // if (!__required(fValue['latitude'])) {
    //   return 'Address is invalid';
    // }
    return null;
  };
  __validateField = (value = null, Key, label = null, required = true) => {
    const { values: formValues } = this.state;
    const fValue = value !== null ? value : formValues[Key];
    if (!__required(fValue) && required) {
      return `${label || Key} is required`;
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  __validatereadandaccept = () => {
    const { values } = this.state;
    const { callBack, sms, readandaccept } = values
    if (this.props.isEdit) {
      return !((callBack || sms))
    }
    return !((callBack || sms) && readandaccept)
  }
  __validateForm = () => {

    const validFirstName = !this.__validateFirstName();
    const validLastName = !this.__validateLastName();
    const validEmail = !this.__validateEmail();
    const validPhone = !this.__validatePhoneNumber(null, 'phoneNumber', 'Mobile Number')
    const validAddress = !this.__validateAddress();
    const validState = !this.__validateField(null, 'State');
    const validCity = !this.__validateField(null, 'City');
    const validZipcode = !this.__validateField(null, 'Zipcode');
    const validReadandaccept = !this.__validatereadandaccept();
    return validFirstName && validEmail && validLastName && validPhone && validAddress && validState && validCity && validZipcode && validReadandaccept;
  };

  changeHandler = event => {
    let { name, value } = event.target;
    let errorObject = {
      [name]: null,
    };
    let updateValueObj = {};
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: this.__validateFirstName(value)
        }
        break;
      case 'lastName':
        errorObject = {
          [name]: this.__validateLastName(value)
        }
        break;
      case 'email':
        errorObject = {
          [name]: this.__validateEmail(value),
        };
        break;
      case 'phoneNumber':
        value = value.replace(exceptDigitsRegex, '');
        errorObject = {
          [name]: this.__validatePhoneNumber(value, 'phoneNumber', 'Mobile Number'),
        };
        break;
      case 'rememberMe':
        localStorage.setItem('rememberMe', value);
        break;
      case 'address':
        let stateObj = {}
        if (value.state)
          stateObj['State'] = value.state.long_name
        if (value.city)
          stateObj['City'] = value.city.long_name
        if (value.postal_code)
          stateObj['Zipcode'] = value.postal_code.long_name
        updateValueObj = {
          ...value,
          ...stateObj
        };

        errorObject = {
          [name]: this.__validateAddress(value),
          State:null,
          City:null,
          Zipcode:null
        };
        break;
      case 'State':
        errorObject = {
          [name]: this.__validateField(value, 'State', 'State', true),
        };
        break;
      case 'City':
        errorObject = {
          [name]: this.__validateField(value, 'City', 'City', true),
        };
        break;
      case 'Zipcode':
        errorObject = {
          [name]: this.__validateField(value, 'Zipcode', 'Zip Code', true),
        };
        break;
      case 'PharmacyName':
        errorObject = {
          [name]: this.__validateField(value, 'PharmacyName', 'Pharmacy Name', false),
        };
        break;
      case 'PharmacyphoneNumber':
        value = value.replace(exceptDigitsRegex, '');
        errorObject = {
          [name]: this.__validatePhoneNumber(value, 'PharmacyphoneNumber', 'Pharmacy Phone Number'),
        };
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
          ...updateValueObj,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };




  formatLabel = (text, required = true) => {
    if (required)
      return (<span>{text}<span style={{ color: 'red' }}> *</span></span>)
    else
      return (<span>{text}</span>)
  }
  showpopup = (type) => {
    // const { hippaAccept,
    //   patientAccept, } = this.state
    Kalymdpopup({
      context: this.rootContext,
      type: type,
      callToActionData: this.props.callToActionData,
      content: '',
      // checkboxKey: type == 'HIPAA Policy' ? hippaAccept : patientAccept,
    });
  }
  unCheckAcceptRead = (value) => {

    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          readandaccept: value

        },
        hippaAccept: value,
        patientAccept: value,
      };
    });
  }
  render() {
    const { values: formValues, errors: formErrors } = this.state;
    const getGeneralInputProps = controlName => {
      return {
        onChange: this.changeHandler,
        onBlur: this.changeHandler,
        error: formErrors[controlName],
        value: formValues[controlName],
        name: controlName,
      };
    };
    const isValidForm = this.__validateForm();
    const { isEdit = false } = this.props
    return (

      <div className="kalymdrequestform">
        <div className="kalymdrequestform-container">

          <form className="login-form" onSubmit={e => e.preventDefault()}>
            <div className="form-row" >
              <Input
                {...getGeneralInputProps('firstName')}
                containerClass="two-input"
                type="text"
                label={this.formatLabel('First Name')}
                placeholder="First Name"
              />
              <Input
                {...getGeneralInputProps('lastName')}
                containerClass="two-input"
                type="text"
                label={this.formatLabel('Last Name')}
                placeholder="Last Name"
              />
            </div>
            <div className="form-row">
              <Input
                {...getGeneralInputProps('email')}
                className="KalyMDRequestForm"
                placeholder="Enter Email Address"
                readOnly={this.props.isEdit}
                label={this.formatLabel("Email")}
              />
              <Input
                mask="(999) 999-9999"
                maskChar=" "
                type="tel"
                InputComponent={InputMask}
                {...getGeneralInputProps('phoneNumber')}
                placeholder="Enter Mobile Number"
                label={this.formatLabel("Mobile Number  eg. (480) 123 4567")}
              />
            </div>
            <div className="row" >
              <PlaceAutocomplete
                name="address"
                value={{
                  address: formValues['address'],
                  latitude: formValues['latitude'],
                  longitude: formValues['longitude'],
                }}
                //disabled={!hasPermissionToEdit}
                onBlur={this.changeHandler}
                onPlaceSelect={this.changeHandler}
                label={this.formatLabel(`Address`)}
                placeholder={`Address`}
                containerClass="column-9"
                error={formErrors['address']}

              />

            </div>
            <div className="form-row" >
              <Input
                {...getGeneralInputProps('State')}
                containerClass="two-input"
                type="text"
                label={this.formatLabel('State')}
                placeholder="State"
              />
              <Input
                {...getGeneralInputProps('City')}
                containerClass="two-input"
                type="text"
                label={this.formatLabel('City')}
                placeholder="City"
              />
            </div>
            <div className="form-row" >
              <Input
                {...getGeneralInputProps('Zipcode')}
                containerClass="two-input"
                type="text"
                label={this.formatLabel('Zip Code')}
                placeholder="Zipcode"
              />
              <Input
                {...getGeneralInputProps('PharmacyName')}
                containerClass="two-input"
                type="text"
                label={this.formatLabel('Pharmacy Name', false)}
                placeholder="Pharmacy Name"
              />
            </div>
            <div className="row" >
              <Input
                mask="(999) 999-9999"
                maskChar=" "
                type="tel"
                InputComponent={InputMask}
                {...getGeneralInputProps('PharmacyphoneNumber')}
                placeholder="Enter Pharmacy Phone Number"
                label={this.formatLabel("Pharmacy Number  eg. (480) 123 4567", false)}
              />

            </div>
            <div className="checkbox-block Input-control form-group">
              <span className='label'><span>Contact Preferences</span></span>
              <div style={{ display: 'flex', paddingTop: '5px' }}>
                <Checkbox {...getGeneralInputProps('callBack')}>Call Back</Checkbox>
                <Checkbox {...getGeneralInputProps('sms')}  >SMS</Checkbox>
              </div>
            </div>
            {!isEdit ? (
              <div className="checkbox-block readandacceptclass">
                <Checkbox id="readandacceptid" onChange={(event) => { this.unCheckAcceptRead(event.target.value) }} value={formValues['readandaccept']}  ></Checkbox>
                <div className='labeldiv'>
                  <label htmlFor='readandacceptid'>I have read and accept the practice </label>
                  <a onClick={() => this.showpopup('HIPAA Policy')} className="reset-link">HIPAA Policy</a> and <a onClick={() => this.showpopup('Patient Agreement Form')} className="reset-link">Patient Agreement Form</a>.
                </div>
                {/* <i>The checkbox enable once you read and accept both HIPAA Policy and Patient Agreement</i> */}
              </div>
            ) : (
              <Textarea  {...getGeneralInputProps('practiceNotes')} className="textarea-comments" placeholder="Practice Notes" label="Practice Notes"></Textarea>
            )}
            <RootContext.Consumer>
              {context => {
                this.rootContext = context;
                return (
                  <div className="login-btn">
                    <CircleButton
                      type="submit"
                      disabled={!isValidForm}
                      onClick={this.submitConsultRequestCreate}
                    >
                      Submit
                    </CircleButton>
                  </div>
                );
              }}
            </RootContext.Consumer>
          </form>
        </div>
      </div>

    );
  }
}

export default KalyMDRequestForm;
