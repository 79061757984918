const questionList = [
  {
    id: 441,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [1, 2, 4, 5, 6, 11,14], // painIds,
    nextQuestionId: 442,
    descriptions: [],
  },
  {
    id: 442,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 443,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 84, visitReasonIds: [], extraQuestion: 1},
          {painId: 2, questionId: 163, visitReasonIds: [], extraQuestion: 1},
          {painId: 4, questionId: 43, visitReasonIds: [], extraQuestion: 1},
          {painId: 5, questionId: 104, visitReasonIds: [182], extraQuestion: 1},
          {painId: 6, questionId: 123, visitReasonIds: [183], extraQuestion: 1},
          {painId: 11, questionId: 203, visitReasonIds: [], extraQuestion: 1},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 443,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 83, visitReasonIds: [], extraQuestion: 3},
          {painId: 2, questionId: 164, visitReasonIds: [], extraQuestion: 3},
          {painId: 4, questionId: 44, visitReasonIds: [], extraQuestion: 3},
          {painId: 5, questionId: 103, visitReasonIds: [182], extraQuestion: 3},
          {painId: 6, questionId: 124, visitReasonIds: [183], extraQuestion: 3},
          {painId: 11, questionId: 204, visitReasonIds: [], extraQuestion: 3},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 443,
    question: 'Do you still want to see a Neurologist?',
    descriptions: [
      'A Neurologist is a medical physician (MD/DO) who completed a 4 year residency after medical school who specializes in non-surgical neurological conditions.',
    ],
    title: 'Neurologist',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can perform general pain injections',
      'Can not perform surgery',
    ],
    options: [
      {groupId: 'A11', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 441,
    lastQuestionId: 443,
    treeDepth: 3,
  },
};
