import moment from 'moment';
import _ from 'lodash';
const STORAGE_CONSTANTS = {
  ALGO: 'ALGO',
};
const setAlgo = (cookieValue) => {
  // default cookie will be expire after 20 minutes
  let date = moment();
  date.add(20, 'minutes');
  let value = _.cloneDeep(cookieValue);
  if (_.isArray(value) || _.isObject(value)) {
    value = JSON.stringify(value);
  }
  const encryptedValue = btoa(unescape(encodeURIComponent(value)));
  let Obj={
    setDate:date,
    value:encryptedValue
  }
  localStorage.setItem(STORAGE_CONSTANTS.ALGO,JSON.stringify(Obj))
 };

const deleteAlgo = () => {
  localStorage.removeItem(STORAGE_CONSTANTS.ALGO)
};

const getAlgo = cookieName => {
  let lcValue=localStorage.getItem(STORAGE_CONSTANTS.ALGO)
  let now=new Date()
  if(!lcValue)
  return null;
  lcValue=JSON.parse(lcValue)
  lcValue.setDate=moment(lcValue.setDate)
  if(lcValue.setDate.isBefore()){
    return null;
  }else{
    let decryptedValue = decodeURIComponent(escape(atob(lcValue.value)));

    return JSON.parse(decryptedValue);
  }
  
};


export default {
  getAlgo,
  setAlgo,
  deleteAlgo
  
};
