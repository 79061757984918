import React, {useRef, useState} from 'react';
import _ from 'lodash';
import moment from 'moment';
import images from 'data/images';
import momentHelper from 'data/moment/momentHelper';
import './CalendarEvent.css';

const {duration, isAfter, readableTimeFormat, isBetweenDates,utcToLocalMoment,localToUtcMoment} = momentHelper;

const CalendarEvent = props => {
  // const {style} = props;
  const style = _.get(props, 'style', {});
  const inlineStyle = {
    height: `${style.height}%`,
    top: `${style.top}%`,
    width: `100%`,
    left: `${0}%`,
    position: 'absolute',
  };
  const now = () => {
    let nowTemp = localToUtcMoment(new Date()).format();
    nowTemp = utcToLocalMoment(nowTemp);
    let nowHH = nowTemp.format('HH');
    let nowMM = nowTemp.format('mm');
    let rdata = new Date();
    rdata.setDate(nowTemp.format('DD'));
    rdata.setHours(nowHH);
    rdata.setMinutes(nowMM);
    return rdata;
  };
  let CurrentDiv = useRef();
  let eventPopUp = useRef();
  const [hoverClassName, setHoverClassName] = useState('');
  const {event, onEventSelect, onAddBreak} = props;
  const isAfterToday = isAfter(event.start,now());
  const isBetweenStartEnd = isBetweenDates(event.start, event.end,now());
  const isAllowEdit = isAfterToday || isBetweenStartEnd;
  const startTime = readableTimeFormat(event.start);
  const startDayName = moment(event.start).format('dddd');
  const endTime = readableTimeFormat(event.end);
  const isRecursive = event.repeat !== 'none';
  const durationInMinutes = duration(event.end, event.start);
  const isVirtual = event.isVirtual;
  const isInPerson = event.isInPerson;
  let isShow = true;
  if (durationInMinutes <= 45) {
    isShow = false;
  }
  const durationInMinutesNow = duration(event.end, now());
  const recurringComponent = isRecursive && (
    <span className="icon">
      <img alt="" src={images.repeatIcon} />
    </span>
  );
  const timeComponent = (
    <>
      {recurringComponent}
      <div className="time">
        {startTime}
        <br />
        {endTime}
      </div>
    </>
  );
  const relativeCoords = event => {
    var bounds = event.target.getBoundingClientRect();
    var x = event.clientX - bounds.left;
    var y = event.clientY - bounds.top;
    return {x: x, y: y};
  };

  // console.log('nowTemp', nowMM);
  //const dateInNY = moment.tz(dateTimeFormat("2000-10-01 00:00:00"), "M/D/YYYY h:mm a",  "America/New_York");
  
  const hoverContentClasses = `hover-content ${hoverClassName} 
  ${isAllowEdit ? '' : 'no-edit'} ${isVirtual ? 'video-visit' : ''}  ${
    isInPerson ? 'in-person-visit' : ''
  }`;
  return (
    <div
      style={inlineStyle}
      className={`CalendarEvent rbc-event ${isVirtual ? 'video-visit' : ''} ${
        isInPerson ? 'in-person-visit' : ''
      }`}
      ref={CurrentDiv}
      onClick={e => {
        let pos = relativeCoords(e);
        eventPopUp.current.style.left = pos.x - 20 + 'px';
        eventPopUp.current.style.top = pos.y - 30 + 'px';
        // console.log("relBoxCoords",)
      }}
      onMouseEnter={() => setHoverClassName('show')}
      onMouseLeave={() => setHoverClassName('')}>
      <div className="rbc-event-content">
        <div className="main-event">
          {isShow && timeComponent}
          <div ref={eventPopUp} className={hoverContentClasses}>
            <div>
              <div className="hover-content-date">
                <span className="day">
                  {startDayName} {recurringComponent}
                </span>
                <br />
                <span className="time">
                  {startTime} — {endTime}
                </span>
              </div>
              {isAllowEdit && (
                <>
                  <div onClick={() => onEventSelect(event, false)} className="edit">
                    Edit Today’s Availability
                  </div>
                  {isRecursive && isAfterToday && (
                    <div onClick={() => onEventSelect(event, true)} className="edit">
                      Edit Recurring Availability
                    </div>
                  )}
                  {durationInMinutes > 30 && durationInMinutesNow > 30 && (
                    <div
                      onClick={() => onAddBreak(event, isRecursive && isAfterToday)}
                      className="edit">
                      Add Break
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarEvent;
