import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import momentHelper from 'data/moment/momentHelper';
import helper from 'data/helper';
import './CalendarAppointmentEvent.css';

const {duration, isAfter, utcToLocalMoment, localToUtcMoment} = momentHelper;
const {getAppointmentClassFromStatus} = helper;
const now = () => {
  let nowTemp = localToUtcMoment(new Date()).format();
  nowTemp = utcToLocalMoment(nowTemp);
  let nowHH = nowTemp.format('HH');
  let nowMM = nowTemp.format('mm');
  let rdata = new Date();
  rdata.setDate(nowTemp.format('DD'));
  rdata.setHours(nowHH);
  rdata.setMinutes(nowMM);
  return rdata;
};
const CalendarAppointmentEvent = props => {
  const style = _.get(props, 'style', {});
  const inlineStyle = {
    height: `${style.height}%`,
    top: `${style.top}%`,
    width: `100%`,
    left: `${0}%`,
    position: 'absolute',
  };
  const {event, onAppointmentSelect} = props;
  const isAfterToday = isAfter(event.start, now());
  const startTime = moment(event.start).format('h:mm');
  const endTime = moment(event.end).format('h:mm');
  const durationInMinutes = duration(event.end, event.start);
  let isShow = true;
  if (durationInMinutes <= 45) {
    // isShow = false;
  }
  const appointmentStatusClass = getAppointmentClassFromStatus(event.status);
  const patient = _.get(event, 'patient', {});
  const timeComponent = (
    <>
      {patient.email !== 'google_calendar@kaly.com' ? (
        <div className="time">
          {patient.firstName} {patient.lastName}
        </div>
      ) : (
        <div className="time">Blocked Out</div>
      )}
      <div className="time">
        {startTime} — {endTime}
      </div>
    </>
  );
  const pendingStatusComponent = (
    <span className="overlay">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </span>
  );
  return (
    <div
      style={inlineStyle}
      className={`CalendarAppointmentEvent rbc-event ${appointmentStatusClass}`}
      onClick={e => (isAfterToday ? onAppointmentSelect(event.id, e) : null)}>
      <div className="rbc-event-content">
        {pendingStatusComponent}
        <div className="main-event">{isShow && timeComponent}</div>
      </div>
    </div>
  );
};

export default CalendarAppointmentEvent;
