import React, {useState} from 'react';
import {withRouter, Link, useHistory} from 'react-router-dom';
import CircleButton from 'components/CircleButton';
import images from 'data/images';
import helper from 'data/helper';
import './Header.css';
import constants from 'data/constants';
import config from 'config/config';

const {StaticFooterPage} = constants;
const {scrollToDiv} = helper;

const Header = props => {
  const {hideHeaderButtons} = props;
  const [active, toggleActive] = useState(null);
  const history = useHistory();

  const scrollToJoinkalypsys = () => {
    const joinkalypsysElement = document.querySelector('.Joinkalypsys');
    toggleActive(!active);
    if (joinkalypsysElement) {
      scrollToDiv('Joinkalypsys');
    } else {
      history.push('/list-your-practice');
    }
  };

  let rightHeaderComponent = null;
  if (!hideHeaderButtons) {
    rightHeaderComponent = (
      <>
        <div className="right-col">
          <div className="precticewith">
            {/* <a href={config.APP_DOMAIN + StaticFooterPage.PRICING} target="_self">
              Pricing
            </a> */}
          </div>
          <div className="menu-content">
            <span>
              <Link to="/login">Login</Link>
            </span>
            <span>
              <a
                className="pricing-link"
                href={config.APP_DOMAIN + StaticFooterPage.PRICING}
                target="_self">
                Pricing
              </a>
            </span>
            <span>
              <CircleButton onClick={scrollToJoinkalypsys} btnType="secondaryButton">
                List your practice with us
              </CircleButton>
            </span>
          </div>
        </div>
        <div
          className="mobile-menu"
          onClick={() => {
            toggleActive(!active);
          }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </>
    );
  }

  return (
    <div className={`Header ${active ? 'menu-active' : ''}`}>
      <span
        className="header-menu-overlay"
        onClick={() => {
          toggleActive(!active);
        }}></span>
      <div className="container">
        <div className="header-content">
          <div className="logo">
            <a href={config.landingPageUrl}>
              <img alt="" src={images.logoWhite} />
            </a>
          </div>
          {rightHeaderComponent}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Header);
