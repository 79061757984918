import React from 'react';
import images from 'data/images';
import _ from 'lodash';
import './SpecialityModal.css';

const SpecialityModal = props => {
  const {closeModal} = props;
  const {title = 'Please Note…. ', currentQuestion = {}, storeAnswer, goBack} = props;
  let className = '';
  if (_.get(currentQuestion, 'optionType', 0) === 1) {
    className = ' txt-center';
  }
  return (
    <div className="SpecialityModal">
      <div className="modal-content-header">
        <div className="h3">{title}</div>
        <span
          className="close"
          onClick={() => {
            goBack();
            closeModal();
          }}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        {currentQuestion.descriptions.map((description, index) => {
          return (
            <div className="question" key={index}>
              {description}
            </div>
          );
        })}
        <div className="sub-text">{currentQuestion.question}</div>
        <div className={`sf-list-content sf-select-answer${className}`}>
          {currentQuestion.options &&
            currentQuestion.options.map((option, index) => {
              return (
                <span
                  key={index}
                  onClick={() => {
                    storeAnswer(option);
                    closeModal();
                  }}
                  className="sf-description-item">
                  <span>{option.label}</span>
                </span>
              );
            })}
        </div>
        <div className="sf-action">
          <span className="select-option-text">
            {currentQuestion.options && currentQuestion.options.length > 1 && 'Please select one'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SpecialityModal;
