import React, { useState, useContext } from 'react';
import _ from 'lodash';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import constants from 'data/constants';
import AuthContext from 'context/AuthContext';
import validators from 'data/validators';
import config from 'config/config';
import './AccountTab.css';

const { DEFAULT_MESSAGES } = constants;
const { MAX_TEXT_LENGTH } = config;
const { __required, __password, __email, __maxLength, __eq, _scrollToView } = validators;

const AccountTab = props => {
  const { onChangePassword, onChangeEmail } = props;
  // For email change Form
  const authContext = useContext(AuthContext);
  const { auth } = authContext;
  const email = _.get(auth, 'email');
  const emailFormConfig = {
    values: { email, password: '' },
    errors: {},
    isEditEmail: false,
  };
  const [emailFormData, setEmailFormData] = useState({ ...emailFormConfig });
  const { isEditEmail, values: emailFormValues, errors: emailFormErrors } = emailFormData;

  const resetEmailForm = () => {
    setEmailFormData({ ...emailFormConfig });
  };

  const toggleEmailEdit = () => {
    setEmailFormData(prevState => {
      return {
        ...prevState,
        isEditEmail: !prevState.isEditEmail,
      };
    });
  };

  // Validation functions for email form
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : emailFormValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };

  const __validatePassword = (value = null) => {
    const fValue = value !== null ? value : emailFormValues['password'];
    if (!__required(fValue)) {
      return 'Password is required';
    }
    if (!__password(fValue)) {
      return 'Invalid password';
    }
    return null;
  };

  // Validation functions for password change form
  const changeEmailFormHandler = event => {
    const { name, value } = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      case 'password':
        errorObject = {
          [name]: __validatePassword(value),
        };
        break;
      default:
        break;
    }
    setEmailFormData({
      ...emailFormData,
      values: {
        ...emailFormData.values,
        [name]: value,
      },
      errors: {
        ...emailFormData.errors,
        ...errorObject,
      },
    });
  };

  const __validateEmailForm = () => {
    const validEmail = !__validateEmail();
    const validPassword = !__validatePassword();
    return validEmail && validPassword;
  };
  const isValidEmailForm = __validateEmailForm();

  const getGeneralEmailFormInputProps = controlName => {
    return {
      onChange: changeEmailFormHandler,
      onBlur: changeEmailFormHandler,
      error: emailFormErrors[controlName],
      value: emailFormValues[controlName],
      name: controlName,
    };
  };

  // For password change Form
  const passwordFormConfig = {
    values: { password: '', newPassword: '', confirmPassword: '' },
    errors: {},
  };
  const [passwordFormData, setPasswordFormData] = useState({ ...passwordFormConfig });
  const { values: passwordFormValues, errors: passwordFormErrors } = passwordFormData;

  const resetPasswordForm = () => {
    setPasswordFormData({ ...passwordFormConfig });
  };

  // Validation functions
  const __validateCurrentPassword = (value = null) => {
    const fValue = value !== null ? value : passwordFormValues['password'];
    if (!__required(fValue)) {
      return 'Current password is required';
    }
    return null;
  };
  const __validateNewPassword = (value = null) => {
    const fValue = value !== null ? value : passwordFormValues['newPassword'];
    if (!__required(fValue)) {
      return 'New password is required';
    }
    if (!__password(fValue)) {
      return 'Invalid new password';
    }
    return null;
  };
  const __validateConfirmPassword = (value = null) => {
    const fValue = value !== null ? value : passwordFormValues['confirmPassword'];
    if (!__required(fValue)) {
      return 'Confirm password is required';
    }
    if (!__eq(fValue, passwordFormValues['newPassword'])) {
      return 'New password and confirm password must be same';
    }
    return null;
  };
  const __validateConfirmPasswordChangeNew = (value = null) => {
    const fValue = value !== null ? value : passwordFormValues['newPassword'];
    if (!__required(fValue)) {
      return 'Confirm password is required';
    }
    if (!__eq(fValue, passwordFormValues['confirmPassword'])) {
      return 'New password and confirm password must be same';
    }
    return null;
  };
  const changePasswordFormHandler = event => {
    const { name, value } = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'password':
        errorObject = {
          [name]: __validateCurrentPassword(value),
        };
        break;
      case 'newPassword':
        errorObject = {
          [name]: __validateNewPassword(value),
        };

        if (passwordFormData.values['confirmPassword']) {
          errorObject = { ...errorObject, 'confirmPassword': __validateConfirmPasswordChangeNew(value) }
        }
        break;
      case 'confirmPassword':
        errorObject = {
          [name]: __validateConfirmPassword(value),
        };
        break;
      default:
        break;
    }
    // setPasswordFormData({
    //   ...passwordFormData,
    //   values: {
    //     ...passwordFormData.values,
    //     [name]: value,
    //   },
    //   errors: {
    //     ...passwordFormData.errors,
    //     ...errorObject,
    //   },
    // });
    setPasswordFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...passwordFormData.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const __validatePasswordForm = () => {
    const validCuurrentPassword = !__validateCurrentPassword();
    const validNewPassword = !__validateNewPassword();
    const validConfirmPassword = !__validateConfirmPassword();
    return validCuurrentPassword && validNewPassword && validConfirmPassword;
  };
  const isValidPasswordForm = __validatePasswordForm();

  const getGeneralPasswordFormInputProps = controlName => {
    return {
      onChange: changePasswordFormHandler,
      onBlur: changePasswordFormHandler,
      error: passwordFormErrors[controlName],
      value: passwordFormValues[controlName],
      name: controlName,
    };
  };

  const getPassowrdComponent = labelText => {
    return (
      <>
        {labelText}{' '}
        <span className="password-instruction-text">
          ({DEFAULT_MESSAGES.PASSWORD_INSTRUCTION_TEXT})
        </span>
      </>
    );
  };

  const handleEmailChange = () => {
    onChangeEmail(emailFormValues, resetEmailForm);
  };

  const handlePasswordChange = () => {
    if (!isValidPasswordForm) {
      let valError = ['password', 'newPassword', 'confirmPassword'];
      valError.forEach(name => {
        changePasswordFormHandler({ target: { name: name, value: passwordFormValues[name] } });
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    onChangePassword(passwordFormValues, resetPasswordForm);
  };

  return (
    <div className="AccountTab">
      <div className="email-form">
        <div className="h3">Account Email:</div>
        <form className="accountEmail-form">
          <div className="form-row">
            <Input
              {...getGeneralEmailFormInputProps('email')}
              type="email"
              disabled={!isEditEmail}
            />
            <span onClick={toggleEmailEdit} className={isEditEmail ? 'cancel-link' : 'edit-link'}>
              {isEditEmail ? 'Cancel' : 'Edit Account Email'}
            </span>
          </div>
          {isEditEmail && (
            <>
              <Input
                {...getGeneralEmailFormInputProps('password')}
                type="password"
                label="Current Password"
                icon={images.eyeView}
              />
              <div className="update-wrapper">
                <CircleButton disabled={!isValidEmailForm} onClick={handleEmailChange}>
                  Update
                </CircleButton>
                <div className="para">
                  Enter a new account email for your account and we'll send a verification email to
                  that address.
                </div>
              </div>
            </>
          )}
        </form>
      </div>
      <div className="password-form">
        <div className="h3">Update Password</div>
        <form className="accountPassword-form">
          <Input
            {...getGeneralPasswordFormInputProps('password')}
            type="password"
            label="Current Password"
            icon={images.eyeView}
          />
          <Input
            {...getGeneralPasswordFormInputProps('newPassword')}
            type="password"
            label={getPassowrdComponent('New Password')}
            icon={images.eyeView}
          />
          <Input
            {...getGeneralPasswordFormInputProps('confirmPassword')}
            type="password"
            label="Confirm Password"
            icon={images.eyeView}
          />
          <div className="save-btn">
            <CircleButton onClick={handlePasswordChange}>Save</CircleButton>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AccountTab;
