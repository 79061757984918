import React, { Component } from 'react';
import _ from 'lodash';
import Layout from 'layout/AfterAuth';
import SubHeader from 'components/AfterAuth/SubHeader';
import RequestSettings from 'components/AfterAuth/RequestSettings';
import RequestContact from 'components/AfterAuth/RequestContact';
import RequestRecipients from 'components/AfterAuth/RequestRecipients';
import images from 'data/images';
import './RequestReviews.css';
import SendReviewRequest from 'components/AfterAuth/ConnectSocial/SendReviewRequest';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import NotConnectMsg from '../BirdEyeNotConnectMsg';
import AuthContext from 'context/AuthContext';
import reviewService from 'services/review';
const { modalFunctions } = modalConfig;
const { blankModalData } = modalFunctions;
const { getgoogleSSOConnect,getGoogleSSouserReviewSourceLocation } = reviewService
class ReviewAccounts extends Component {
  static contextType = RootContext;
  authContext = null;
  state = {
    activeTab: null,
    invitedUserList: null,
    practiceInsurancePlanList: null,
    practiceStaffMemberList: null,
    isSSoConnected: null,
    isSetLoaction:true
  };
  tabs = [
    { label: 'Request Settings', tab: 'RequestSettings' },
    { label: 'Contacts', tab: 'Contacts' },
    // {label: 'Recipients', tab: 'Recipients'},
  ];

  componentDidMount() {
    this.checkConnectedGoogleSso()
    const tab = this.getTabName(this.props);
    this.getAndSetActiveTab(tab);
  }

  componentDidUpdate(prevProps) {
    const tab = this.getTabName(this.props);
    const prevTab = this.getTabName(prevProps);
    if (tab !== prevTab) {
      this.getAndSetActiveTab(tab);
    }
  }
  checkConnectedGoogleSso = async () => {
    const response = await getgoogleSSOConnect().catch((e) => {

      this.setState({ isSSoConnected: false })

    });
    if (response) {
      this.get_setReviewLocationList()
    }
  }
  getTabName = props => {
    const query = new URLSearchParams(props.location.search);
    const tab = query.get('tab') || _.get(this, 'tabs[0].tab');
    return tab;
  };

  getAndSetActiveTab = async tab => {
    const tabObj = _.find(this.tabs, { tab });
    if (!tabObj) {
      tab = _.get(this, 'tabs[0].tab');
    }
    switch (tab) {
      case 'Templates':
        break;
      case 'RequestSettings':
        break;
      case 'Contacts':
        break;
      case 'Recipients':
        break;
      default:
        break;
    }
    this.setState({
      activeTab: tab,
    });
  };

  setActiveTab = async activeTab => {
    const params = new URLSearchParams();
    params.append('tab', activeTab);
    this.props.history.push({
      pathname: '/reviews/requestreviews',
      search: `?${params.toString()}`,
    });
  };

  renderActiveTab = () => {
    const { activeTab } = this.state;
    let activeTabComponent = null;
    switch (activeTab) {
      case 'RequestSettings':
        activeTabComponent = <RequestSettings />;
        break;
      case 'Contacts':
        activeTabComponent = <RequestContact />;
        break;
      case 'Recipients':
        activeTabComponent = <RequestRecipients />;
        break;
      default:
        activeTabComponent = null;
        break;
    }
    return activeTabComponent;
  };
   get_setReviewLocationList = async () => {
    let responce = await getGoogleSSouserReviewSourceLocation().catch()
    if (responce) {
      let { data } = responce
      let selectedDefaultValue = data.find((d) => d.isDefault)
      if (selectedDefaultValue)
      this.setState({ isSSoConnected: true,isSetLoaction:true })
      else{
        this.setState({ isSSoConnected: false,isSetLoaction:false })
      }

     
    }
  }
  sendReviewRequest = () => {
    const { setGlobal } = this.context;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: SendReviewRequest,
        customComponentProps: {
          callback: () => {
            window.RequestContactComponent.getContactList()
          }
        },
      }),
    );
  };

  render() {
    const { activeTab, isSSoConnected,isSetLoaction } = this.state;
    const tabHeaderList = (
      <ol className="tab-list">
        {this.tabs.map((tab, index) => {
          return (
            <li
              key={index}
              className={`tab-list-item ${tab.tab === activeTab ? 'tab-list-active' : ''}`}
              onClick={() => this.setActiveTab(tab.tab)}>
              {tab.label}
              <span className="review-count">{tab.count}</span>
            </li>
          );
        })}
      </ol>
    );
    if (this.authContext) {
      //const { auth } = this.authContext;
      //const birdEyeBusinessId = _.get(auth, 'birdEyeBusinessId', null);
      // if (!birdEyeBusinessId) {
      //   return <NotConnectMsg />
      // }
    }
    const notConfigMsg = (<p className='googlenotconnectmsg'>{!isSetLoaction?`Please configure a Google Review Source Place to enable Google Reviews`:`Please configure a Google Review Source to enable Google Reviews`}</p>);
    return (
      <Layout>
        <AuthContext.Consumer>
          {context => {
            this.authContext = context;
            return null;
          }}
        </AuthContext.Consumer>
        <div className="RequestReviews">
          <SubHeader title="Request Reviews" />
          <div className="ReviewAccounts-content">
            {isSSoConnected && (
              <div className="SendingReviewRequest">

                <div className="heading3">Request Reviews From Patients </div>
                <button className="addwebsite-btn send-request-btn" onClick={this.sendReviewRequest}>
                  <img src={images.send} alt="Send Review Request" /> Send Review Request
                </button>

              </div>
            )}
            <div className="setting-tabs">
              {isSSoConnected == null ? (
                <p className='googlenotconnectmsg'>Checking...</p>
              ) : (
                <>
                  {!isSSoConnected ? (notConfigMsg) : (
                    <>
                      {tabHeaderList}
                      <div className="tab-content">
                        <div className="box-panel">{this.renderActiveTab()}</div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ReviewAccounts;
