import React, {useContext, useEffect, useState} from 'react';
import Textarea from 'components/FormControls/Textarea';
import simpleWidget from 'data/widgets/simpleWidget';
import MyLink from 'components/MyLink';
import config from 'config/config';
import images from 'data/images';
import clipboard from 'clipboard';
import './uploadFiles.css';
import AuthContext from 'context/AuthContext';
import _ from 'lodash';
import RootContext from 'context/RootContext';
import SubHeader from 'components/AfterAuth/SubHeader';
import ReactPaginate from 'react-paginate';
import EditFaq from 'containers/Admin/ManageFaq/EditFaq';
import modalConfig from 'modals/modalConfig';
import constants from 'data/constants';
import manageFileUpload from 'services/manageFileUpload';
import AddFile from './addFile';
import {ViewPhotoId} from 'containers/Admin/JoinUs/viewPhotoId';
import moment from 'moment';
const {getMyFiles, deleteUploadFile} = manageFileUpload;
const {modalFunctions} = modalConfig;
const {DefaultDebounceMSec} = constants;
const {errorModalData, blankModalData, successModalData, confirmModalData} = modalFunctions;
const categoryList = [
  {id: 1, label: 'Photo ID'},
  {id: 2, label: 'Graduate School Diploma'},
  {id: 3, label: 'Undergraduate School Diploma'},
  {id: 4, label: 'Active State Professional license.'},
  {id: 5, label: 'Post-Graduate Diploma (Residency, Fellowship, etc)'},
  {id: 6, label: 'Hospital Affiliation'},
  {id: 7, label: 'Board Certificate'},
  {id: 8, label: 'Other'},
];
const StatusObj = {
  0: <span className="Pending-text">Pending</span>,
  1: <span className="Approved-text">Approved</span>,
  2: <span className="Rejected-text">Rejected</span>,
};
const UploadFiles = props => {
  new clipboard('.copy-link');
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const {practice} = auth;
  const seoPageUrl = _.get(practice, 'seoPageUrl', false);
  const link = seoPageUrl || config.APP_URL;

  const [list, setList] = useState([]);
  const [pageParms, setPageParms] = useState({
    pageSize: 1,
    pageLimit: 150,
    search: '',
    sortBy: 'createdAt',
    orderBy: 'DESC',
  });
  const {setGlobal} = useContext(RootContext);
  const formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    // You can await here
    const response = await getMyFiles({...pageParms});
    if (response.data) {
      // this.setState({
      //   list: _.get(res, 'data.rows', []),
      //   totalPage:Math.ceil(_.get(res, 'data.count', 0)/pageLimit) ,
      //   totalRecord: _.get(res, 'data.count', 0),
      // });
      setList(response.data.rows);
    }
    // ...
  };
  const handleClearSearch = () => {
    //this.setState({activePage: 1, searchText: ''}, this.handleSearchLogic);
  };
  const handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.handleSearchLogic();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };
  const handleSearch = () => {
    this.setState({activePage: 1}, this.handleSearchLogic);
  };
  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  const confirmDelete = id => {
    // const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete this File?`,
        onConfirm: async isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            let response = await deleteUploadFile(id).catch(handleError);
            if (response) {
              setGlobal('modal', successModalData(response.message));
              fetchData();
            }
          }
        },
      }),
    );
  };
  const viewPhotoId = (url, userName, fileType) => {
    //let {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ViewPhotoId,
        customComponentProps: {url: url, userName: userName, fileType: fileType},
      }),
    );
  };
  const renderShortIcon = key => {
    const {sortBy, orderBy} = pageParms;
    if (key === sortBy) {
      if (orderBy === 'ASC') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };
  const handleSortingChange = e => {
    e.preventDefault();
    //setPageParms
    const temppageParms = {...pageParms};
    const {sortBy, orderBy} = temppageParms;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      setPageParms({...temppageParms, orderBy: orderBy === 'ASC' ? 'DESC' : 'ASC', activePage: 1});
    } else {
      setPageParms({...temppageParms, orderBy: 'ASC', sortBy: key, activePage: 1});
    }
    setTimeout(() => {
      fetchData();
    }, 100);
  };
  const openEditModal = data => {
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: AddFile,
        modalWrapperClass: '',
        customComponentProps: {
          data,
          updateList: fetchData,
          // specialtyList: this.state.specialtyList,
          // updateSubmit: this.updateSubmit,
        },
      }),
    );
  };
  return (
    <div className="uploadFiles box-content">
      <SubHeader title={'Upload Files'} hideName />
      <div className="dashboard-panle">
        <div className="search">
          {/* <div>
              <Select
                options={specialtyList}
                labelIndex="specialty"
                valueIndex="id"
                name="companyId"
                defaultOptionProp={{value: '', label: 'All Specialtys'}}
                isAllowDefaultOpen={true}
                onChange={this.handleCompanySearch}
              />
            </div> */}
          {/* <div className="input-control">
              <input placeholder="Search" value={searchText} onChange={handleChange}></input>
              {searchText && (
                <button className="btn-clear" onClick={handleClearSearch}>
                  <img src={images.close} alt="clear" />
                </button>
              )}
            </div>
            <button onClick={handleSearch}>Search</button> */}
        </div>
        <button className="btn-add-new" onClick={() => openEditModal({})}>
          Add New File
        </button>
        {/* <div className="dataCount">
            <span>{totalRecord} faq found.</span>
          </div> */}
        <div className="table-overflow">
          <div className="rtable">
            <div className="heading">
              <div className="cell pointer" data-key="name">
                File #
              </div>

              <div className="cell pointer" data-key="companyName">
                Category
              </div>
              <div className="cell pointer" data-key="createdAt" onClick={handleSortingChange}>
                Upload Date{renderShortIcon('createdAt')}
              </div>
              <div className="cell pointer" data-key="companyName">
                Status
              </div>
              <div className="cell edit-cell">Action</div>
            </div>
            {list.length === 0 && (
              <div className="row" key={`eachQ`}>
                No files
              </div>
            )}
            {list.map((item, index) => (
              <div className="row" key={`eachQ${index}`}>
                <div className="cell">
                  <img
                    onClick={() =>
                      viewPhotoId(
                        item.documentLink,
                        ``,
                        item.categoryId === '8'
                          ? item.otherCategoryName
                          : categoryList.find(c => c.id == item.categoryId)?.label,
                      )
                    }
                    className="photoid"
                    src={images.photoID}
                    alt="photoID"
                  />
                </div>
                <div className="cell ">
                  <div style={{display: 'flex', flexFlow: 'column', maxWidth: '250px'}}>
                    {categoryList.find(c => c.id == item.categoryId)?.label}
                    {item.categoryId === '8' && (
                      <span style={{fontSize: '15px'}}>
                        <i>{_.get(item, 'otherCategoryName', '')}</i>
                      </span>
                    )}
                  </div>
                </div>
                <div className="cell">{formatedDate(_.get(item, 'createdAt', ''))}</div>
                <div className="cell">{StatusObj[item.status]}</div>
                <div className="cell edit-cell">
                  {/* <img
                      onClick={() => this.openEditModal(item)}
                      src={images.editPen}
                      className="edit-icon"
                      alt="editPen"
                    /> */}
                  {item.status !== 1 && (
                    <img
                      onClick={() => confirmDelete(item.id)}
                      src={images.deleteRound}
                      className="delete-icon"
                      alt="delete"
                    />
                  )}
                </div>
              </div>
            ))}
            {/* {_.map(list, (item, index) => (
                <div className="row" key={index}>
                  <div className="cell">{_.get(item, 'plan', '')}</div>
                  <div className="cell">{_.get(item, 'companyName', '')}</div>
                  <div className="cell">{InsurancePlanStatus[_.get(item, 'status', '')]}</div>
                  <div className="cell">
                    {item.updatedAt && this.formatedDate(item.updatedAt)}
                  </div>
                  <div className="cell edit-cell">
                    <img
                      onClick={() => this.openEditModal(item)}
                      src={images.editPen}
                      className="edit-icon"
                      alt="editPen"
                    />
                  </div>
                </div>
              ))} */}
          </div>
        </div>
        {/* {totalPage > 0 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={activeIndex}
            />
          )} */}
      </div>
    </div>
  );
};

export default UploadFiles;
