import React from 'react';
import modalConfig from 'modals/modalConfig';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import './RemoveStripeProvider.css';

export function StripeInactivateProvider(props) {
  const {setGlobal} = props.context;
  const {modalFunctions} = modalConfig;
  const {blankModalData} = modalFunctions;  
  const ConfirmComponent = props => {
    const {closeModal} = props;
  
  
    
    const onSubmit = () => {
     
        props.resolve(true);
        closeModal();
      
    };
    return (
      <div className="CreatePasswordModal RemoveStripeProvider RemoveStripeSubscription">
        <div className="modal-content-header">
          <div className="h3">Confirm</div>
          <span
            className="close"
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div className="modal-content-body">
          <p>
          Please confirm that you want to inactivate the provider.
          </p>
        </div>
        <div className="modal-content-footer">
          <CircleButton
           
            onClick={() => {
              onSubmit()
            }}>
            Inactivate Provider
          </CircleButton>
          <CircleButton
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            Cancel
          </CircleButton>
        </div>
      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ConfirmComponent,
        customComponentProps: {resolve: resolve, reject: reject},
        closeOnBGClick: false,
      }),
    );
  });
}
