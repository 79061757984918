import React, {useContext} from 'react';
import CircleButton from 'components/CircleButton';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import './PatientDataPrivacy.css';
import AuthContext from 'context/AuthContext';
import patientServices from 'services/patient'
const {modalFunctions} = modalConfig;
const {warningModalData,errorModalData,successModalData} = modalFunctions;
const {deleteAccount}=patientServices
const PatientDataPrivacy = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const authContext = useContext(AuthContext);
  const {updateAuthStatus} = authContext;
  const {onInActivateMyAccount} = props;

  const confirmModal = () => {
    const message = 'Are you sure that you want to deactivate your account?';
    setGlobal(
      'modal',
      warningModalData(message, {
        closeButtonText: 'Deactivate Account',
        showCancelBtn:true,
        callBackOnClose: passFrom => {
          if (passFrom === 'closeButton') {
            onInActivateMyAccount();
          }
        },
      }),
    );
  };
  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const deleteModal =  () => {
    const message = 'Are you sure that you want to delete your account?  This action is permanent and cannot be undone.';
    setGlobal(
      'modal',
      warningModalData(message, {
        closeButtonText: 'Delete Account',
        showCancelBtn:true,
        callBackOnClose: async(passFrom) => {
          if (passFrom === 'closeButton') {
            const response = await deleteAccount().catch(handleError);
            if (response) {
             if(response.code==="success"){
              const {message} = response;
              setGlobal(
                'modal',
                successModalData(message, {
                  callBackOnClose: () => {
                    updateAuthStatus(false);
                  },
                }),
              );
              }
            }
          }
        },
      }),
    );
  };

  
  return (
    <div className="PatientDataPrivacy">
      <div className="h3">How does Kaly safeguard my data?</div>
      <div className="dataprivacy-inner">
        <form>
          <div className="dataprivacy-block">
            <div className="para">
              At Kaly, we are committed to the security and privacy of patients and providers.
              This means we’re always working hard to safeguard your data and continually earn your
              trust. We undergo industry security standard audits, such as SOC 2 Type II and
              HITRUST, annually, to maintain high standards. Kaly.com, along with Kaly
              Marketplace, Bookable Directory (an enterprise tool from Kaly), and our office
              infrastructure have earned Certified status (CSF v9) for information security by
              HITRUST. Keeping your information secure is a top priority for us. Here are some of
              the ways we protect it:
            </div>
            <div className="para">
              -Privacy and security training: Everyone at Kaly receives training to ensure that
              we stay focused on privacy and security. This includes privacy training that covers
              HIPAA ( the Health Insurance Portability and Accountability Act, which establishes
              national standards for protecting the identifiable health information of health plan
              beneficiaries and patients) as well as additional security training administered by a
              third party.
            </div>
            <div className="para">
              -Strong encryption: We employ industry standard technology to safeguard data and our
              technology is evaluated annually by third party auditors. Our site uses SHA-256
              encryption and Kaly data is transmitted over Transport Layer Security (TLS)
              connections (HTTPS).
            </div>
            <div className="para">
              -Secure storage: Kaly leverages Amazon Web Services (a leading cloud computing
              services provider) to store data in physically and electronically secure facilities.
            </div>
            <div className="para">
              -Expert auditing: Kaly works with outside experts to maintain high standards and
              rigorous security practices. We hire third parties to test our service and processes
              as part of our recurring penetration test program, as well as conduct HITRUST and SOC
              2 Type II audits.
            </div>
            <div className="para">
              -Smart features: We build features that protect data on your end, too. For instance,
              we automatically sign you out of your account if you’re inactive, we set requirements
              on your password strength, and we lock your account if the wrong password is entered
              too many times.
            </div>
          </div>
          <div className="PatientDataPrivacy-button">
            <CircleButton onClick={confirmModal}>Deactivate my account</CircleButton>
            <CircleButton containerClass="deletemyaccount" onClick={deleteModal}>Delete my account</CircleButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PatientDataPrivacy;
