const questionList = [
  {
    id: 481,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [3, 7, 12], // painIds,
    nextQuestionId: 482,
    descriptions: [],
  },
  {
    id: 482,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 483,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 3, questionId: 224, visitReasonIds: [43], extraQuestion: 1},
          {painId: 7, questionId: 144, visitReasonIds: [42], extraQuestion: 1},
          {painId: 12, questionId: 64, visitReasonIds: [44], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 483,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 3, questionId: 225, visitReasonIds: [43], extraQuestion: 1},
          {painId: 7, questionId: 143, visitReasonIds: [42], extraQuestion: 2},
          {painId: 12, questionId: 65, visitReasonIds: [44], extraQuestion: 1},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 483,
    question: 'Are you interested in potentially undergoing surgery?',
    options: [
      {
        nextQuestionId: 484,
        label: 'Yes, in the near future',
        shortLabel: 'Interested in surgery',
      },
      {nextQuestionId: 484, label: 'No, not at this time', shortLabel: 'No surgery'},
    ],
    descriptions: [],
  },
  {
    id: 484,
    question: 'Do you still want to see a Sports Medicine Orthopedic Surgeon?',
    descriptions: [
      'A Sports Medicine Orthopedic Surgeon is a medical physician (MD/DO) who completed a 1 year fellowship in Sports Medicine, after completing a 5 year residency in Orthopedic Surgery.',
      'A Sports Medicine Orthopedic Surgeon has further training in treating shoulder, elbow, hip and knee conditions that may require surgery.',
    ],
    title: 'Sports Medicine Orthopedic Surgeon',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can perform pain injections',
    ],
    options: [
      {groupId: 'T', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 481,
    lastQuestionId: 484,
    treeDepth: 4,
  },
};
