const questionList = [
  {
    id: 600,
    question: 'Has the patient seen this provider before?',
    optionType: 1,
    options: [
      {nextQuestionId: 601, label: 'Yes', shortLabel: 'Existing Patient'},
      {nextQuestionId: 601, label: 'No', shortLabel: 'New Patient'},
    ],
    descriptions: [],
  },
  {
    id: 601,
    question: 'What are you seeing this doctor for?',
    questionType: 3,
    nextQuestionId: 602,
    descriptions: [],
  },
  {
    id: 602,
    question: 'What type of pain condition do you have?',
    optionType: 2,
    painOptionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], // painIds will be dynamic( pains of doctor ),
    nextQuestionId: 603,
    descriptions: [],
  },
  {
    id: 603,
    question: 'How long have you had pain for?',
    isNextQuestionOfSpeciality: true,
    options: [
      {
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 84},
          {painId: 2, questionId: 163},
          {painId: 3, questionId: 224},
          {painId: 4, questionId: 43},
          {painId: 5, questionId: 104},
          {painId: 6, questionId: 123},
          {painId: 7, questionId: 144},
          {painId: 8, questionId: 23},
          {painId: 9, questionId: 183},
          {painId: 10, questionId: 24},
          {painId: 11, questionId: 203},
          {painId: 12, questionId: 64},
          {painId: 13, questionId: 203},
          {painId: 14, groupId: 'W'},
        ],
      },
      {
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 83},
          {painId: 2, questionId: 164},
          {painId: 3, questionId: 225},
          {painId: 4, questionId: 44},
          {painId: 5, questionId: 103},
          {painId: 6, questionId: 124},
          {painId: 7, questionId: 143},
          {painId: 8, questionId: 24},
          {painId: 9, questionId: 184},
          {painId: 10, questionId: 24},
          {painId: 11, questionId: 204},
          {painId: 12, questionId: 65},
          {painId: 13, questionId: 204},
          {painId: 14, questionId: 3},
        ],
      },
    ],
    descriptions: [],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 600,
    treeDepth: 4,
  },
};
