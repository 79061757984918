import React, {useState, useContext, useEffect} from 'react';
import _ from 'lodash';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import AuthContext from 'context/AuthContext';
import helper from 'data/helper';
import MyLink from 'components/MyLink';
import rolePermission from 'data/rolePermission';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import userService from 'services/user';
import {useLocation} from 'react-router-dom';
import './DoctorList.css';
import constants from 'data/constants';

const {canSwitchToUser} = userService;
const {getSuffixString,setAppointmentSourceStore} = helper;
const {permissionList, helperFunctions} = rolePermission;
const {getAllMyRoleIds, hasPermission} = helperFunctions;
const {modalFunctions} = modalConfig;
const {errorModalData} = modalFunctions;
const {SwitchPreventionRoutes} = constants;

const DoctorList = props => {
  const authContext = useContext(AuthContext);
  const rootContext = useContext(RootContext);
  const {auth, setAuthState} = authContext;
  const {setGlobal} = rootContext;
  const authUserId = _.get(auth, 'id');
  const viewUserId = _.get(auth, 'viewUserId', authUserId);
  const roleIds = getAllMyRoleIds(auth);
  const {doctorList} = props;
  const [inputText, setInputText] = useState('');
  const [active, toggleActive] = useState(null);
  //const [isStaff, setIsStaff] = useState(false);
  const location = useLocation();
  const getFullName = u => {
    return u.firstName + ' ' + u.lastName;
  };
  const filteredDoctorList = _.filter(doctorList, doctor => {
    return inputText === '' || getFullName(doctor).toLowerCase().includes(inputText.toLowerCase());
  });

  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  useEffect(() => {
    let {roles, id} = auth;
    if (roles && id === viewUserId && roles.length > 0) {
      if (roles[0].role === 'Staff') {
        let firstDoctorList =
          filteredDoctorList && filteredDoctorList.length > 0 ? filteredDoctorList[0].id : false;
        if (firstDoctorList && viewUserId !== firstDoctorList) {
          changeViewUser(firstDoctorList);
        }
      }
    }
  }, [filteredDoctorList, auth, viewUserId]);
  const changeViewUser = async newViewUserId => {
    const {pathname} = location;
    let {roles, id} = auth;
    const isPreventedRoute = SwitchPreventionRoutes.filter(path => path === pathname);
    let allowSwitch = isPreventedRoute && isPreventedRoute.length > 0 ? false : true;
    if (roles[0].role !== 'Staff' && allowSwitch) {
      let preventarray = [
        '/Reviews/account',
        '/reviews',
        '/reviews/Sharereview',
        '/reviews/requestreviews',
        '/reviews/account',
      ];

      allowSwitch = preventarray.filter(path => path === pathname).length > 0 ? false : true;
    }
    if (allowSwitch && hasPermission(roleIds, permissionList.CAN_SWITCH_USER)) {
      const response = await canSwitchToUser(newViewUserId).catch(handleError);
      if (response) {
        setAuthState(prevState => {
          return {
            ...prevState,
            auth: {
              ...prevState.auth,
              viewUserId: newViewUserId,
            },
          };
        });
      }
    }
  };

  const contentCompoent = _.map(filteredDoctorList, doctor => {
    const {id, profileImageUrl, status} = doctor;
    const provider = _.get(doctor, 'provider', {});
    const {suffixes = [], seoPageUrl,specialties=[]} = provider;

    if (status !== 1) {
      return null;
    }

    return (
      <span className={`doctor-item ${id === viewUserId && 'active'}`} key={id}>
        <span className="doctor-detail" onClick={() => changeViewUser(id)}>
          <img src={profileImageUrl || images.user} alt="" />
          <span className="doctor-info">
            <span className="doctor-name">
              {getFullName(doctor)}
              <span className="doctor-position">{getSuffixString(suffixes)}</span>
            </span>
          </span>
        </span>
        {seoPageUrl && suffixes.length>0 && specialties.length>0&& (
          <MyLink to={`${seoPageUrl}`} onClick={()=>setAppointmentSourceStore(1)} className="viewprofile provider-list">
            View Profile
          </MyLink>
        )}
      </span>
    );
  });
  return (
    <div className={`DoctorList ${active ? '' : 'open-accodian'}`}>
      <div
        className="h3"
        onClick={() => {
          toggleActive(!active);
        }}>
        DOCTORS{' '}
        <span className="toggle-icon">
          <img alt="" src={images.arrowPointer} />
        </span>
      </div>
      <Input
        value={inputText}
        onChange={e => setInputText(e.target.value)}
        type="text"
        containerClass="search-input"
        placeholder="Quick Find"
        icon={images.search}
      />
      <div className="doctor-list">{contentCompoent}</div>
    </div>
  );
};

export default DoctorList;
