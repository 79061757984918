import React, {useState, useEffect, useContext} from 'react';
import _ from 'lodash';
import CircleButton from 'components/CircleButton';
import Checkbox from '../Checkbox';
import RootContext from 'context/RootContext';
import masterService from 'services/master';
import './Language.css';
import constants from 'data/constants';

const {getAllLanguages} = masterService;
const {DoctorFilters} = constants;

const Language = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal, languages: languageList} = rootContext;
  const {
    values = [],
    labelIndex = 'name',
    valueIndex = 'id',
    onFilterApply = () => {},
    activeFilter = null,
    onFilterSelect = () => {},
  } = props;
  let {containerClass = ''} = props;
  const [selectedFilter, setFilter] = useState(values);
  const [active, toggleActive] = useState(activeFilter === DoctorFilters.LANGUAGE);

  useEffect(() => {
    setFilter(values);
  }, [values]);

  useEffect(() => {
    if (active && activeFilter !== DoctorFilters.LANGUAGE) {
      onFilterSelect(DoctorFilters.LANGUAGE);
    }
  }, [active]);

  useEffect(() => {
    if (active && activeFilter !== DoctorFilters.LANGUAGE) {
      toggleActive(false);
    }
  }, [activeFilter]);

  useEffect(() => {
    getLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // get and set languages to global store
  const getLanguages = async () => {
    if (!languageList) {
      const response = await getAllLanguages().catch();
      if (response) {
        const {data} = response;
        setGlobal('languages', data);
      }
    }
  };

  const toggleCheckbox = value => {
    const newSelectedFilter = _.xor(selectedFilter, [value]);
    setFilter(newSelectedFilter);
  };

  const applyFilter = () => {
    toggleActive(false);
    if (!_.isEqual(values, selectedFilter)) {
      onFilterApply(selectedFilter);
    }
  };
  if (values.length > 0) {
    containerClass += ' selected';
  }

  return (
    <div className={`Language ${containerClass}`}>
      <CircleButton
        btnType="secondaryButton"
        onClick={() => {
          toggleActive(!active);
        }}>
        Language
      </CircleButton>
      {active && <div className="overlay" onClick={() => toggleActive(false)}></div>}
      {active && (
        <div className={`dropdown-menu show-menu`}>
          <div className="dropdown-option-wrapper">
            {languageList.map(option => {
              return (
                <div className="dropdown-option" key={option[valueIndex]}>
                  <Checkbox
                    value={selectedFilter.includes(option[valueIndex])}
                    onChange={e => toggleCheckbox(option[valueIndex])}>
                    {option[labelIndex]}
                    {/* English <span className="count">(802)</span> */}
                  </Checkbox>
                </div>
              );
            })}
          </div>
          <div className="dropdown-bottom">
            {selectedFilter.length > 0 && (
              <CircleButton btnType="secondaryButton" onClick={() => setFilter([])}>
                Clear
              </CircleButton>
            )}
            <CircleButton btnType="whiteButton" onClick={applyFilter}>
              Apply
            </CircleButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default Language;
