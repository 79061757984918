import React, {Component} from 'react';
import _ from 'lodash';
import PatientTab from 'components/AfterAuth/PatientTab';
import ChatTab from 'components/AfterAuth/ChatTab';
import images from 'data/images';
import './VideoSidebar.css';
import AuthContext from 'context/AuthContext';
import helper from 'data/helper';
import constants from 'data/constants';

const {VideoUserType} = constants;
const {getSuffixString} = helper;
const initialState = {
  activeTab: null,
  providerName: '',
  nextAppointment: null,
  appointmentId: '',
  userType: '',
};

class VideoSidebar extends Component {
  static contextType = AuthContext;
  state = {...initialState, appointmentId: this.props.appointmentId};
  tabs = [
    {
      label: 'Patient',
      tab: 'patient',
      image: images.personBlue,
    },
    {label: 'Provider', tab: 'video', image: images.cameraWhite},
    {label: 'Chat', tab: 'chat', image: images.chatGray},
    {label:'Video',tab:'PatientVideo',image: images.cameraWhite}
  ];

  componentDidMount() {
    const {userType, isMobile, showVideo} = this.props;
    let firstTab = '';
    if (isMobile) {
      firstTab = userType === VideoUserType.PATIENT ? 'video' : 'patient';
    } else {
      firstTab = userType === VideoUserType.PATIENT ? 'chat' : 'patient';
    }

    this.setState({
      activeTab: isMobile && showVideo && userType === VideoUserType.PROVIDER ? '' : firstTab,
      userType: userType,
    });
    this.getCurrentProvider();
  }

  getCurrentProvider = () => {
    const {auth} = this.context;
    const firstName = _.get(auth, 'firstName', '');
    const lastName = _.get(auth, 'lastName', '');
    const suffixes = _.get(auth, 'provider.suffixes', []);
    const fullName = firstName + ' ' + lastName + getSuffixString(suffixes);
    this.setState({providerName: fullName});
  };

  setActiveTab = tab => {
    this.setState({activeTab: tab});
    const isMobileView = this.props.isMobile;
    if (isMobileView) {
      if(tab==='PatientVideo'){
        this.props.openVideoTab(true)
      }else
      this.props.checkTab(tab === 'video' ? true : false);
    }
  };

  renderActiveTab = () => {
    const {activeTab} = this.state;
    let activeTabComponent = null;
    switch (activeTab) {
      case 'patient':
        activeTabComponent = (
          <PatientTab
            appointmentId={this.props.appointmentId}
            appointmentData={this.props.appointmentData}
            userType={this.props.userType}
            upcomingAppointment={this.props.upcomingAppointment}
            nextId={this.props.nextId}
          />
        );
        break;
      case 'chat':
        activeTabComponent = (
          <ChatTab
            roomName={this.props.roomName}
            token={this.props.token}
            userType={this.props.userType}
            username={this.props.username}
            setInputText={this.props.setInputText}
            current={this.props.current}
            messages={this.props.messages}
            putMessage={this.props.putMessage}
            checkFile={this.props.checkFile}
            upcomingAppointment={this.props.upcomingAppointment}
            nextId={this.props.nextId}
            otherParticipantName={this.props.otherParticipantName}
            isChannelCreated={this.props.isChannelCreated}
            appointmentData={this.props.appointmentData}
          />
        );
        break;
      default:
        activeTabComponent = null;
        break;
    }
    return activeTabComponent;
  };

  render() {
    const {activeTab, providerName, doctor} = this.state;
    const {userType: type, isMobile, showVideo} = this.props;
    let tabData = [...this.tabs];
    if (type && type === VideoUserType.PATIENT) {
      tabData = tabData.filter(t => t.tab !== 'patient' && t.tab!=='PatientVideo' && (!isMobile ? t.tab !== 'video' : true));
    }
    if (type && type === VideoUserType.PROVIDER) {
      tabData = tabData.filter(t => t.tab !== 'video');
      const isMobileView = this.props.isMobile;
      if ( !isMobileView) {
        tabData = tabData.filter(t => t.tab !== 'PatientVideo');
      }
    }
    const tabHeaderList = (
      <ol className="tab-list">
        {tabData.map((tab, index) => {
          return (
            <li
              key={index}
              className={`tab-list-item ${
                tab.tab === activeTab &&
                (!isMobile || (isMobile && (!showVideo || type === VideoUserType.PATIENT)))
                  ? 'tab-list-active'
                  : ''
              }`}
              onClick={() => this.setActiveTab(tab.tab)}>
              <span className="tab-icon">
                <img src={tab.image} alt="tab-icon" />
              </span>
              {tab.label}
            </li>
          );
        })}
      </ol>
    );
    return (
      <div className="VideoSidebar">
        <div className="video-sidebar-top">
          <div className="room-name">Kaly Telemedicine Room</div>
          <div className="name">{this.props.doctorName || providerName}</div>
        </div>
        <div className="videovisit-tabs">
          {tabHeaderList}
          <div className="tab-content">
            <div className="box-panel">{this.renderActiveTab()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoSidebar;
