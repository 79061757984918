import React, {useContext, useState, useEffect} from 'react';
import _ from 'lodash';
import CircleButton from 'components/CircleButton';
import SwitchToggle from 'components/FormControls/SwitchToggle';
import AuthContext from 'context/AuthContext';
import './PatientNotification.css';

const PatientNotification = props => {
  const {onUpdateNotificationSettings} = props;
  const authContext = useContext(AuthContext);
  const notificationSettings = _.get(authContext, 'auth.notificationSettings', {
    sendAppointmentReminderEmail: false,
    sendAppointmentRescheduleCancelEmail: false,
    sendAppointmentReminderSMS: false,
    sendAppointmentRescheduleCancelSMS: false,
  });

  const [formData, setFormData] = useState({
    values: notificationSettings,
    errors: {},
  });
  const {values: formValues, errors: formErrors} = formData;

  useEffect(() => {
    setFormData({...formData, values: {...authContext.auth.notificationSettings}});
  }, [authContext.auth.notificationSettings]);

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleForm = () => {
    onUpdateNotificationSettings(formValues);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
      key: `${controlName}${formValues[controlName]}`,
    };
  };

  return (
    <div className="PatientNotification">
      <div className="h3">Notifications Settings</div>
      <div className="PatientNotification-inner">
        <form>
          <div className="notification-container">
            <div className="notification-block">
              <div className="heading2">Email Messages</div>
              <SwitchToggle
                {...getGeneralInputProps('sendAppointmentReminderEmail')}
                label="Appointment Reminders"
              />
              <SwitchToggle
                {...getGeneralInputProps('sendAppointmentRescheduleCancelEmail')}
                label="Notify if appointments are rescheduled or cancelled"
              />
            </div>
            <div className="notification-block">
              <div className="heading2">Text Messages</div>
              <SwitchToggle
                {...getGeneralInputProps('sendAppointmentReminderSMS')}
                label="Appointment Reminders"
              />
              <SwitchToggle
                {...getGeneralInputProps('sendAppointmentRescheduleCancelSMS')}
                label="Notify if appointments are rescheduled or cancelled"
              />
            </div>
          </div>
          <div className="patientNotification-button">
            <CircleButton onClick={handleForm} btnType="whiteButton">
              Save
            </CircleButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PatientNotification;
