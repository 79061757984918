import React from 'react';
import {Link} from 'react-router-dom';
import './Button.css';

const Button = props => {
  // As we don't have wrapper div here and we want inline css,we have used class based css.
  const {link = null, children, btnType = 'primary', ...otherProps} = props;

  let styleObj;
  switch (btnType) {
    case 'whiteBtn':
      styleObj = whiteBtnCssObj;
      break;
    default:
      styleObj = primaryCssObj;
      break;
  }

  if (link) {
    return (
      <Link {...otherProps} style={{...btnCssObj, ...styleObj}} to={link}>
        {children}
      </Link>
    );
  } else {
    return (
      <button {...otherProps} style={{...btnCssObj, ...styleObj}}>
        {children}
      </button>
    );
  }
};

export default Button;

let btnCssObj = {
  height: '35px',
  borderRadius: '4px',
  padding: '0px 20px',
  letterSpacing: '0.3px',
  textTransform: 'uppercase',
  lineHeight: '16px',
  fontSize: '14px',
  fontWeight: '600',
  margin: '0 15px 15px',
  cursor: 'pointer',
  textAlign: 'center',
  width: '27.33%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
let primaryCssObj = {
  // background: '#595959',
  color: '#fff',
  border: '0',
  fontFamily: 'Heldane Display',
  backgroundColor: '#013648',
};
let whiteBtnCssObj = {
  color: '#174C99',
  border: '2px solid #174C99',
  background: '#fff',
};
