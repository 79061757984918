import React, {useRef} from 'react';
import _ from 'lodash';
import images from 'data/images';
import StaffItem from './StaffItem';
import AddStaffToPractice from './AddStaffToPractice';
import rolePermission from 'data/rolePermission';
import './StaffTab.css';

const {roleList} = rolePermission;

const StaffTab = props => {
  const {
    loggedInProviderId,
    practiceStaffMemberList,
    onStaffStatusChange,
    onInviteToBeStaff,
    onDeleteStaff,
  } = props;
  const addStaffRef = useRef(null);
  const emailRef = useRef(null);
  const totalMembers = practiceStaffMemberList.length;

  const scrillToAddStaff = () => {
    addStaffRef.current && addStaffRef.current.scrollIntoView({behavior: 'smooth'});
    emailRef.current && emailRef.current.focus();
  };
  const practiceStaffMembersComponent = practiceStaffMemberList.map(user => {
    const providerId = _.get(user, 'provider.id');
    const roleObject = _.find(roleList, {id: parseInt(5)});
    const data = {
      providerId,
      image: images.logosymbol,
      fullName: `${user.firstName} ${user.lastName}`,
      status: user.status,
      roleName: _.get(roleObject, 'name', '--'),
    };
    return (
      <StaffItem
        loggedInProviderId={loggedInProviderId}
        key={user.id}
        data={data}
        onStaffStatusChange={onStaffStatusChange}
        onDeleteStaff={onDeleteStaff}
      />
    );
  });
  return (
    <div>
      <div className="StaffTab">
        <div className="active-staffs">
          <div className="h3">
            {totalMembers} Staff
            <span className="sub-heading"> Unlimited Staff Seats</span>
          </div>
        </div>
        <div className="staffs-content">
          <div className="staff-list">{practiceStaffMembersComponent}</div>
          <div className="add-staff-content">
            {Array.from({length: 1}).map((e, index) => {
              return (
                <div className="add-staff" key={index} onClick={scrillToAddStaff}>
                  <div className="add-staff-inner">
                    <span className="plus-icon">
                      <img src={images.blueCross} alt="" />
                    </span>
                    Add Staff
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <AddStaffToPractice
        emailRef={emailRef}
        addStaffRef={addStaffRef}
        onInviteToBeStaff={onInviteToBeStaff}
      />
    </div>
  );
};

export default StaffTab;
