import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import _ from 'lodash';
import images from 'data/images';
import ProviderItem from './ProviderItem';
import AddProviderToPractice from './AddProviderToPractice';
import ProviderProfile from './ProviderProfile';
import rolePermission from 'data/rolePermission';
import helper from 'data/helper';
import userService from 'services/user';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import AuthContext from 'context/AuthContext';
import './ProvidersTab.css';
import constants from 'data/constants';
import subscriptionService from 'services/subscription';
import config from 'config/config';
const {getSuffixString} = helper;
const {helperFunctions, permissionList} = rolePermission;
const {getAllMyRoleIds, hasPermission} = helperFunctions;
const {
  getAllMyPracticeProvider,
  getAllInvitedUsers,
  updateProviderStatus,
  deleteProvider,
  inviteToBeProvider,
} = userService;
const {modalFunctions} = modalConfig;
const {successModalData} = modalFunctions;
const {MaxSeatsToShow} = constants;

const ProvidersTab = props => {
  const rootContext = useContext(RootContext);
  const authContext = useContext(AuthContext);
  const auth = _.get(authContext, 'auth');
  const authPracticeMembers = _.get(auth, 'practiceMembers', []);
  const history = useHistory();
  const location = useLocation();
  const addProviderRef = useRef(null);
  const emailRef = useRef(null);
  const query = new URLSearchParams(location.search);
  const profile = query.get('profile');
  const {
    switchBackToOtherUser,
    loggedInProviderId,
    totalSeats,
    onProfileImageUrlUpdate,
    onError,
    onAdditionalPhotosUpload,
    onAdditionalPhotosRemove,
    onAdditionalVideoUpload,
    onAdditionalVideoRemove,
    onSaveOverView,
    onSaveAdditionalInfo,
    onCommonDataSave,
    onSaveVisitReasons,
    getProviderData,
  } = props;

  const [tabData, setTabData] = useState({
    invitedUserList: [],
    practiceMembers: [],
  });
  const {invitedUserList, practiceMembers} = tabData;
  const {getSubscriptionDetails} = subscriptionService;
  useEffect(() => {
    getAndSetProviderTabData();
    if (config.showStripe == true) {
      getSubscription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSubscription = async () => {
    const response = await getSubscriptionDetails();
    if (response) {
      if (_.get(response, 'code', '') === 'stripeAccountNotCreated') {
        setSubscriptionData({
          status: 3, //Payment method require
        });
      } else {
        const {data = {}} = response;
        setSubscriptionData(data);
      }
    }
  };
  const [subscriptionData, setSubscriptionData] = useState(null);
  const getAndSetProviderTabData = async () => {
    const providerMembersResponse = await getAllMyPracticeProvider().catch(onError);
    const invitedUserResponse = await getAllInvitedUsers().catch(onError);
    let updateObj = {
      invitedUserList: [],
      practiceMembers: [],
    };
    if (providerMembersResponse) {
      const {data} = providerMembersResponse;
      updateObj.practiceMembers = data;
    }
    if (invitedUserResponse) {
      const {data} = invitedUserResponse;
      updateObj.invitedUserList = data;
    }
    setTabData(updateObj);
  };

  const handleProviderStatusChange = async data => {
    const {setGlobal} = rootContext;
    const response = await updateProviderStatus(data).catch(onError);
    if (response) {
      const {message} = response;
      if (data.status === 0) {
        switchBackToOtherUser(data.providerId);
      }
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            getAndSetProviderTabData();
          },
        }),
      );
    }
  };

  const handleDeleteProvider = async (providerId, params = '') => {
    const {setGlobal} = rootContext;
    const response = await deleteProvider(providerId, params).catch(onError);
    if (response) {
      const {message} = response;
      switchBackToOtherUser(providerId);
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            getAndSetProviderTabData();
          },
        }),
      );
    }
  };
  const handleInviteToBeProvider = async (data, resetForm) => {
    const {setGlobal} = rootContext;
    const response = await inviteToBeProvider(data).catch(onError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            resetForm();
            getAndSetProviderTabData();
          },
        }),
      );
    }
  };

  if (!practiceMembers) {
    return null;
  }

  let selectedProviderId = null;
  if (profile && profile === loggedInProviderId) {
    selectedProviderId = loggedInProviderId;
  }

  const handleProviderSelection = providerId => {
    if (!providerId || loggedInProviderId === providerId) {
      const params = new URLSearchParams();
      params.append('tab', 'providers');
      if (providerId) {
        params.append('profile', providerId);
      } else {
        getAndSetProviderTabData();
      }
      history.push({
        pathname: '/settings',
        search: `?${params.toString()}`,
      });
    } else {
      onError({message: 'You can only edit your own profile'});
    }
  };

  //if provider is selected,then render its profile page
  if (selectedProviderId) {
    const selectedProvider = _.find(
      authPracticeMembers,
      user => user.provider.id === selectedProviderId,
    );
    if (!selectedProvider) {
      const params = new URLSearchParams();
      params.append('tab', 'providers');
      history.push({
        pathname: '/settings',
        search: `?${params.toString()}`,
      });
      return null;
    }
    return (
      <ProviderProfile
        providerId={selectedProviderId}
        getProviderData={getProviderData}
        user={selectedProvider}
        onProfileImageChange={profileImageUrl =>
          onProfileImageUrlUpdate(selectedProviderId, profileImageUrl)
        }
        onError={onError}
        onAdditionalPhotosUpload={uploadedFileArray =>
          onAdditionalPhotosUpload(selectedProviderId, uploadedFileArray)
        }
        onAdditionalPhotosRemove={photoId => onAdditionalPhotosRemove(selectedProviderId, photoId)}
        onAdditionalVideoUpload={uploadedFileArray =>
          onAdditionalVideoUpload(selectedProviderId, uploadedFileArray)
        }
        onAdditionalVideoRemove={videoId => onAdditionalVideoRemove(selectedProviderId, videoId)}
        onClearProviderSelection={() => handleProviderSelection(null)}
        onSaveOverView={data => onSaveOverView(selectedProviderId, data)}
        onSaveAdditionalInfo={data => onSaveAdditionalInfo(selectedProviderId, data)}
        onCommonDataSave={data => onCommonDataSave(selectedProviderId, data)}
        onSaveVisitReasons={data => onSaveVisitReasons(selectedProviderId, data)}
      />
    );
  }
  const totalMembers = practiceMembers.length;
  const remainingSeats = totalSeats - totalMembers;

  const scrillToAddProvider = () => {
    addProviderRef.current && addProviderRef.current.scrollIntoView({behavior: 'smooth'});
    emailRef.current && emailRef.current.focus();
  };
  const practiceMembersComponent = practiceMembers.map(user => {
    const firstName = _.get(user, 'firstName', '');
    const lastName = _.get(user, 'lastName', '');
    const status = _.get(user, 'status', '');
    const profileCompleted = _.get(user, 'profileCompleted', 0);
    const profileImageUrl = _.get(user, 'profileImageUrl');
    const providerId = _.get(user, 'providerId', 0);
    const roleId = _.get(user, 'roles.0.id', 0);
    const roleName = _.get(user, 'roles.0.role', '--');
    const suffixList = _.get(user, 'suffixes', []);
    const isAccountOwner = roleId === 3;
    const data = {
      providerId,
      image: profileImageUrl || images.user,
      fullName: `${firstName} ${lastName}${getSuffixString(suffixList)}`,
      status,
      roleName,
      isAccountOwner: isAccountOwner,
      isProfileCompleted: profileCompleted === 100,
      profileCompleted: profileCompleted,
    };
    return (
      <ProviderItem
        onSelectProvider={() => handleProviderSelection(providerId)}
        key={user.id}
        data={data}
        onProviderStatusChange={handleProviderStatusChange}
        onDeleteProvider={handleDeleteProvider}
        subscriptionData={subscriptionData}
      />
    );
  });
  const invitedUserListComponent = invitedUserList.map(user => {
    const data = {
      image: images.user,
      fullName: `${user.firstName} ${user.lastName}`,
      status: 0,
      roleName: user.email,
      isHideProfileCompleteSection: true,
    };
    return <ProviderItem key={user.id} data={data} />;
  });
  const roleIds = getAllMyRoleIds(auth);
  const canInviteProvider = hasPermission(roleIds, permissionList.CAN_INVITE_PROVIDER);
  const hasMoreRemaining = remainingSeats > MaxSeatsToShow;
  return (
    <div>
      <div className="ProvidersTab">
        <div className="active-providers">
          <div className="h3">
            {totalMembers} Members
            {!hasMoreRemaining && (
              <span className="sub-heading"> You have {remainingSeats} seats remaining</span>
            )}
            {/* <span className="update-txt"> (Upgrade for More)</span> */}
          </div>
        </div>
        <div className="providers-content">
          <div className="provider-list">
            {practiceMembersComponent}
            {invitedUserListComponent}
          </div>
          <div className="add-provider-content">
            {Array.from({length: hasMoreRemaining ? 1 : remainingSeats}).map((e, index) => {
              return (
                <div className="add-provider" key={index} onClick={scrillToAddProvider}>
                  <div className="add-provider-inner">
                    <span className="plus-icon">
                      <img src={images.blueCross} alt="" />
                    </span>
                    Add Provider
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {canInviteProvider && remainingSeats > 0 && (
        <AddProviderToPractice
          emailRef={emailRef}
          addProviderRef={addProviderRef}
          onInviteToBeProvider={handleInviteToBeProvider}
          subscriptionData={subscriptionData}
        />
      )}
    </div>
  );
};

export default ProvidersTab;
