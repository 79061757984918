import React, {useState, useContext} from 'react';
//import _ from 'lodash';
import {withRouter, Link} from 'react-router-dom';
import images from 'data/images';
import AuthContext from 'context/AuthContext';
import Overlay from 'components/Overlay';
import './Header.css';
import config from 'config/config';

const Header = props => {
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const [active, toggleActive] = useState(false);
  const [show] = useState(false);
  const rightHeaderComponent = (
    <div className="right-col">
      <button onClick={() => props.onExit()} className="exit-room-link">
        Exit Kaly Room{' '}
      </button>
    </div>
  );
  return (
    <div className={`VideoLayoutHeader ${show ? 'menu-active' : ''}`}>
      <Overlay show={active} onClick={() => toggleActive(!active)} />
      <div className="header-content">
        <div className="logo">
          {auth ? (
            <Link to="/">
              <img alt="" src={images.logo} />
            </Link>
          ) : (
            <a href={config.landingPageUrl}>
              <img alt="" src={images.logo} />
            </a>
          )}
        </div>
        {props.showExit && rightHeaderComponent}
      </div>
    </div>
  );
};

export default withRouter(Header);
