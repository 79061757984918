const questionList = [];

export default {
  questionList,
  config: {
    firstQuestionId: 2204,
    lastQuestionId: 2207,
    treeDepth: 4,
  },
};
