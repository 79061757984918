import React, {useEffect, useRef, useState} from 'react';
import Input from '../Input';
import _ from 'lodash';
import helper from 'data/helper';
import config from 'config/config';
import './PlaceAutocomplete.css';

const {GOOGLE_MAP_KEY} = config;
const {loadScript} = helper;
const PLACE_SDK = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${GOOGLE_MAP_KEY}`;

const PlaceAutocomplete = props => {
  const propName = props.name || 'address';
  const defaultAddress = {[propName]: '', latitude: '', longitude: ''};
  const {
    name = 'address',
    onPlaceSelect = () => {},
    value = {...defaultAddress},
    containerClass = '',
    ...otherProps
  } = props;
  otherProps.placeholder = otherProps.placeholder || '';
  otherProps.autoComplete="nope"
  const [addressObj, setAddressObj] = useState(value);
  const [isGoogleLoaded, setGoogleLoaded] = useState(true);
  const ref = useRef(null);
  useEffect(() => {
    setAddressObj(value);
  }, [value]);

  useEffect(() => {
    let listener = null;
    loadScript(document, 'script', 'google-place', PLACE_SDK, e => {
      const placeOptions = {
        componentRestrictions: {
          country: ['us'],
        },
        types: ['address'],
      };
      const Autocomplete = _.get(window, 'google.maps.places.Autocomplete');
      if (Autocomplete) {
        const places = new Autocomplete(ref.current, placeOptions);
        listener = window.google.maps.event.addListener(places, 'place_changed', e => {
          const place = places.getPlace();
          let state=place.address_components.find((s)=>s.types.includes('administrative_area_level_1'))
          let city=place.address_components.find((s)=>s.types.includes('administrative_area_level_2'));
          let postal_code=place.address_components.find((s)=>s.types.includes('postal_code'));
          // remove USA from address string
          let addressArray = place.formatted_address.split(', ');
          if (addressArray[addressArray.length - 1].trim() === 'USA') {
            addressArray = addressArray.slice(0, -1);
          }
          const address = addressArray.join(', ');
          // const address = place.formatted_address;
          // const address = ref.current.value || place.formatted_address;
          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();
          const obj = {
            [name]: address,
            latitude,
            longitude,
            state,
            city,
            postal_code
          };
          setAddressObj(obj);
          const customEventObject = getCustomEventObject(obj);
          onPlaceSelect(customEventObject);
        });
      } else {
        setGoogleLoaded(false);
      }
    });
    return () => {
      try {
        window.google.maps.event.removeListener(listener);
      } catch (error) {
        
      }
      
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomEventObject = obj => {
    return {
      target: {
        name: name,
        value: obj,
      },
    };
  };
  const onFocus = event => {
    if(ref.current.autocomplete==="off"){
      ref.current.autocomplete="nope"
    }
    if(props.onBlur)
    handleChange(event)
  }
  const handleChange = event => {
    const {value} = event.target;
    const addressObject = {
      [name]: value,
      latitude: '',
      longitude: '',
    };
    setAddressObj(addressObject);
    if (addressObj.latitude) {
      const customEventObject = getCustomEventObject(addressObject);
      onPlaceSelect(customEventObject);
    } else {
      onPlaceSelect(getCustomEventObject(addressObject));
    }
  };

  return (
    <div className={`PlaceAutocomplete ${containerClass}`}>
      {isGoogleLoaded && (
        <Input {...otherProps}  onFocus={ onFocus }  onBlur={ onFocus }   onChange={handleChange} value={addressObj.address} ref={ref} />
      )}
    </div>
  );
};

export default PlaceAutocomplete;
