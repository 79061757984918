const procedureList = [
  { id: 1, procedure: 'ACL Repair' },
  { id: 2, procedure: 'Achilles Tendon Repair' },
  { id: 3, procedure: 'Acupressure' },
  { id: 4, procedure: 'Acupunture' },
  { id: 5, procedure: 'Ankle Fracture Repair' },
  { id: 6, procedure: 'Ankle Tendon Repair' },
  { id: 7, procedure: 'Botox Injection (Headache)' },
  { id: 8, procedure: 'Bunionectomy' },
  { id: 9, procedure: 'Bursa Injection' },
  { id: 10, procedure: 'Carpal Tunnel Injection' },
  { id: 11, procedure: 'Carpal Tunnel Surgery' },
  { id: 12, procedure: 'Cervical Disc Replacement' },
  { id: 13, procedure: 'Cervical Epidural Injection' },
  { id: 14, procedure: 'Cervical Facet Injection' },
  { id: 15, procedure: 'Cervical Fusion' },
  { id: 16, procedure: 'Cervical Medial Branch Block Injection' },
  { id: 17, procedure: 'Cervical Radiofrequency Ablation' },
  { id: 18, procedure: 'Chiropractic Adjustment' },
  { id: 19, procedure: "De Quervain's Tendinosis Surgery" },
  { id: 20, procedure: "Dupuytren's Contracture Surgery" },
  { id: 21, procedure: 'EMG' },
  { id: 22, procedure: 'Elbow Injection' },
  { id: 23, procedure: 'Elbow Replacement Surgery' },
  { id: 24, procedure: 'Foot Injection' },
  { id: 25, procedure: 'Hammer Toe Surgery' },
  { id: 26, procedure: 'Hand Injection' },
  { id: 27, procedure: 'Hip Arthoscopy' },
  { id: 28, procedure: 'Hip Injection' },
  { id: 29, procedure: 'Hip Labral Repair' },
  { id: 30, procedure: 'Hip Replacement' },
  { id: 31, procedure: 'Knee Arthroscopy' },
  { id: 32, procedure: 'Knee Injection' },
  { id: 33, procedure: 'Knee Replacement' },
  { id: 34, procedure: 'LCL Repair' },
  { id: 35, procedure: 'Lis Franc Fracture Surgery' },
  { id: 36, procedure: 'Lumbar Disc Replacement' },
  { id: 37, procedure: 'Lumbar Discectomy' },
  { id: 38, procedure: 'Lumbar Epidural Injection' },
  { id: 39, procedure: 'Lumbar Facet Injection' },
  { id: 40, procedure: 'Lumbar Fusion' },
  { id: 41, procedure: 'Lumbar Laminectomy' },
  { id: 42, procedure: 'Lumbar Laminotomy' },
  { id: 43, procedure: 'Lumbar Medial Branch Block Injection' },
  { id: 44, procedure: 'Lumbar Radiofrequency Ablation' },
  { id: 45, procedure: 'MCL Repair' },
  { id: 46, procedure: 'Meniscectomy' },
  { id: 47, procedure: 'Microfracture Surgery' },
  { id: 48, procedure: "Morton's Neuroma Surgery" },
  { id: 49, procedure: 'Moxibustion' },
  { id: 50, procedure: 'Occipital Nerve Block' },
  { id: 51, procedure: 'PCL Repair' },
  { id: 52, procedure: 'PRP Injection' },
  { id: 53, procedure: 'Physical Therapy' },
  { id: 54, procedure: 'Plantar Fascia Surgery' },
  { id: 55, procedure: 'Rotator Cuff Repair' },
  { id: 56, procedure: 'Sacroiliac Fusion' },
  { id: 57, procedure: 'Sacroiliac Injection' },
  { id: 58, procedure: 'Shoulder Arthroscopy' },
  { id: 59, procedure: 'Shoulder Injection' },
  { id: 60, procedure: 'Shoulder Labral Repair' },
  { id: 61, procedure: 'Spinal Cord Stimulator' },
  { id: 62, procedure: 'Tendon Repair' },
  { id: 63, procedure: 'Thoracic Epidural Injection' },
  { id: 64, procedure: 'Thoracic Facet Injection' },
  { id: 65, procedure: 'Thoracic Medial Branch Block Injection' },
  { id: 66, procedure: 'Thoracic Radiofrequency Ablation' },
  { id: 67, procedure: 'Trigger Finger Surgery' },
  { id: 68, procedure: 'Trigger Point Injection' },
  { id: 69, procedure: 'Ulnar Collateral Ligament Repair' },
  { id: 70, procedure: 'Filling' },
  { id: 71, procedure: 'Nightguard' },
  { id: 72, procedure: 'Teeth Whitening' },
  { id: 73, procedure: 'Sealant' },
  { id: 74, procedure: 'Fluoride Treatment' },
  { id: 75, procedure: 'Invisalign' },
  { id: 76, procedure: 'Dental Cleaning' },
  { id: 77, procedure: 'Tooth Extraction' },
  { id: 78, procedure: 'Crown' },
  { id: 79, procedure: 'Cracked Tooth Repair' },
  { id: 80, procedure: 'Wisdom Tooth Extraction' },
  { id: 81, procedure: 'Scaling and Root Planing' },
  { id: 82, procedure: 'Teeth Bonding' },
  { id: 83, procedure: 'Dental Inlay' },
  { id: 84, procedure: 'Dental Onlay' },
  { id: 85, procedure: 'Botox Treatment' },
  { id: 86, procedure: 'Dental Xrays' },
  { id: 87, procedure: 'Dentures' },
  { id: 88, procedure: 'Veneers' },
  { id: 89, procedure: 'Root Canal' },
  { id: 90, procedure: 'Endodontic Surgery' },
  { id: 91, procedure: 'Apicoectomy' },
  { id: 92, procedure: 'Dental Bone Graft' },
  { id: 93, procedure: 'Dental Implants' },
  { id: 94, procedure: 'Frenectomy' },
  { id: 95, procedure: 'TMJ Dysfunction Treatment' },
  { id: 96, procedure: 'Jaw Surgery' },
  { id: 97, procedure: 'Retainers' },
  { id: 98, procedure: 'Ceramic Braces' },
  { id: 99, procedure: 'Lingual Braces' },
  { id: 100, procedure: 'Braces' },
  { id: 101, procedure: 'Pediatric Broken Tooth' },
  { id: 102, procedure: 'Pediatric Dental Cleaning' },
  { id: 103, procedure: 'Pediatric Dentist Consultation' },
  { id: 104, procedure: 'Pulpotomy' },
  { id: 105, procedure: 'Flippers' },
  { id: 106, procedure: 'Space Maintainers' },
  { id: 107, procedure: 'Gum Graft' },
  { id: 108, procedure: 'Gum Surgery' },
  { id: 109, procedure: 'Periodontal Treatment' },
  { id: 110, procedure: 'Crown Lengthening' },
  { id: 111, procedure: 'Bridges' },
  { id: 112, procedure: 'Lumineers' },
  // {id: 113, procedure: 'Routine Cleaning And Checkup'},
  { id: 113, procedure: 'Skin Biopsy' },
  { id: 114, procedure: 'Routine Physical' },
  { id: 115, procedure: 'Vaccinations' },
  { id: 116, procedure: 'COVID-19 Vaccination' },
  { id: 117, procedure: 'COVID-19 Testing', fileName: 'covid19Testing' },
  { id: 118, procedure: 'Kyphoplasty' },
  { id: 119, procedure: 'Kyphosis Surgery' },
  { id: 120, procedure: 'Scoliosis Surgery' },
  { id: 121, procedure: 'Spinal Cord Surgery' },
  { id: 122, procedure: "Tommy John's Surgery" },
  { id: 123, procedure: 'Shoulder Joint Replacement' },
  { id: 124, procedure: 'AC Joint Surgery' },
  { id: 125, procedure: 'Biceps Tendon Surgery' },
  { id: 126, procedure: 'Mallet Toe Surgery' },
  { id: 127, procedure: 'Claw Toe Surgery' },
  { id: 128, procedure: 'Tarsal Tunnel Surgery' },
  { id: 129, procedure: 'Toenail Surgery' },
  { id: 130, procedure: 'Metatarsal Surgery' },
  { id: 131, procedure: 'Lumbar Sympathetic Block Injection' },
  { id: 132, procedure: 'Manual Therapy' },
  { id: 133, procedure: 'Neuromuscular Re-education' },
  { id: 134, procedure: 'Therapeutic Exercise' },
  { id: 135, procedure: 'Graston Technique' },
  { id: 136, procedure: 'Active Release Technique' },
  { id: 137, procedure: 'Massage Therapy' },
  { id: 138, procedure: 'Manual Spinal Decompression' },
  { id: 139, procedure: 'Cyst Excision' },
  { id: 140, procedure: 'Phototherapy' },
  { id: 141, procedure: 'Skin Tag Removal' },
  { id: 142, procedure: 'Scar Revision' },
  { id: 143, procedure: 'Laser Skin Treatment' },
  { id: 144, procedure: 'Cryosurgery' },
  { id: 145, procedure: 'Skin Incision and Drainage' },
  { id: 146, procedure: 'Mohs Surgery' },
  { id: 147, procedure: 'Cosmetic Botox' },
  { id: 148, procedure: 'Laser Hair Removal' },
  { id: 149, procedure: 'Tatto Removal' },
  { id: 150, procedure: 'Stiches' },
  { id: 151, procedure: 'Annual Skin Screening' },
  { id: 152, procedure: 'Kybella' },
  { id: 153, procedure: 'Cool Sculpting' },
  { id: 154, procedure: 'Facial Fillers' },
  { id: 155, procedure: 'Lip Fillers' },
  { id: 156, procedure: 'Dermatology Extraction' },
  { id: 161, procedure: 'Chemical Peel' },
  { id: 162, procedure: 'Emsculpt' },
  { id: 163, procedure: 'Fractional Laser Treatment' },
  { id: 164, procedure: 'Laser Hair Removal' },
  { id: 165, procedure: 'Laser Skin Treatment' },
  { id: 166, procedure: 'Coolsculpting' },
  { id: 167, procedure: 'Surgical Tattoo Removal' },
  { id: 168, procedure: 'Botox Injections (Cosmetic)' },
  { id: 169, procedure: 'Buccal Fat Removal' },
  { id: 170, procedure: 'Cheek Dimples & Chin Clefts' },
  { id: 171, procedure: 'Facial Liposuction' },
  { id: 172, procedure: 'Juvederm Injections' },
  { id: 173, procedure: 'Keloid Injection' },
  { id: 174, procedure: 'Kybella Injections' },
  { id: 175, procedure: 'Lip Enhancement' },
  { id: 176, procedure: 'Microneedling' },
  { id: 177, procedure: 'PRP injections (Hair Loss)' },
  { id: 178, procedure: 'PRP injections (Facial)' },
  { id: 179, procedure: 'Radiesse Injections' },
  { id: 180, procedure: 'Restylane injections' },
  { id: 181, procedure: 'Scar Injection' },
  { id: 182, procedure: 'Abdominoplasty' },
  { id: 183, procedure: 'Body Building Implants' },
  { id: 184, procedure: 'Brazillian Butt lift' },
  { id: 185, procedure: 'Breast Implant Surgery' },
  { id: 186, procedure: 'Breast Lift' },
  { id: 187, procedure: 'Buttock Enhancement' },
  { id: 188, procedure: 'Chin Surgery' },
  { id: 189, procedure: 'Ear Surgery' },
  { id: 190, procedure: 'Eyelid Surgery' },
  { id: 191, procedure: 'Facelift' },
  { id: 192, procedure: 'Forehead Lift (Brow Lift)' },
  { id: 193, procedure: 'Hair Transplant' },
  { id: 194, procedure: 'Labiaplasty' },
  { id: 195, procedure: 'Liposuction' },
  { id: 196, procedure: 'Neck Lift' },
  { id: 197, procedure: 'Penile Enhancement' },
  { id: 198, procedure: 'Rhinoplasty' },
  { id: 199, procedure: 'Scar Revision' },
  { id: 200, procedure: 'Septoplasty' },
  { id: 201, procedure: 'Thigh Lift' },
  { id: 202, procedure: 'Tummy Tuck' },
  { id: 203, procedure: 'Vaginal Rejuvenation' },
  { id: 204, procedure: 'Breast Implant Removal' },
  { id: 205, procedure: 'Breast Reconstruction' },
  { id: 206, procedure: 'Breast Reduction' },
  { id: 207, procedure: 'Cleft Lip Surgery' },
  { id: 208, procedure: 'Cleft Palate Surgery' },
  { id: 209, procedure: 'Congenital Anomalies' },
  { id: 210, procedure: 'Corrective Jaw Surgery' },
  { id: 211, procedure: 'Craniosynostosis Surgery' },
  { id: 212, procedure: 'Gender Affirmation Surgery' },
  { id: 213, procedure: 'Male Breast Reduction' },
  { id: 214, procedure: 'Skin Cancer Removal' },
  { id: 215, procedure: 'Skin Graft' },
  { id: 0, procedure: 'Something Else', msg: 'Once you select your doctor, please write a message describing what you are looking when you book your appointment time.' },
  { id: 216, procedure: "Ketamine Infusion" },
  { id: 217, procedure: "Obstetrics (Labor & Delivery)" },
  { id: 218, procedure: "Gynecologic Surgery" },
  { id: 219, procedure: "Family Planning" },
  { id: 220, procedure: "Urologic procedures" },
  { id: 221, procedure: "Routine Gynecologic procedures" },
  { id: 222, procedure: "Amniocentesis" },
  { id: 223, procedure: "Chorionic Villus Sampling" },
  { id: 224, procedure: "Cesarean Section" },
  { id: 225, procedure: "Vaginal Childbirth" },
  { id: 226, procedure: "Ultrasound for Pregnancy" },
  { id: 227, procedure: "Routine Obstetric Visit" },
  { id: 228, procedure: "Hysterectomy" },
  { id: 229, procedure: "Oophorectomy" },
  { id: 230, procedure: "Cervical Cryosurgery" },
  { id: 231, procedure: "Colposcopy" },
  { id: 232, procedure: "Dilation and Curettage" },
  { id: 233, procedure: "LEEP Procedure (cervical excision)" },
  { id: 234, procedure: "Lysis of Adhesions" },
  { id: 235, procedure: "Cancer Debulking DSurgery" },
  { id: 236, procedure: "Cervical Cone Biopsy (cervical excision)" },
  { id: 237, procedure: "Endometrial Ablation" },
  { id: 238, procedure: "Endometrial Biopsy" },
  { id: 239, procedure: "Uterine Biopsy" },
  { id: 240, procedure: "Hysterosalpingography" },
  { id: 241, procedure: "Myomectomy" },
  { id: 242, procedure: "Trachelectomy" },
  { id: 243, procedure: "Tubal Ligation" },
  { id: 244, procedure: "Vulvectomy" },
  { id: 245, procedure: "Ovarian Cyst Removal" },
  { id: 246, procedure: "Bowel Surgery" },
  { id: 247, procedure: "Lymph Node Dissection" },
  { id: 248, procedure: "Radical Hysterectomy" },
  { id: 249, procedure: "Birth Control Treatment" },
  { id: 250, procedure: "Infertility Treatment" },
  { id: 251, procedure: "IVF" },
  { id: 252, procedure: "High Risk Pregnancy" },
  { id: 253, procedure: "Urodynamics" },
  { id: 254, procedure: "Pessary" },
  { id: 255, procedure: "Cystoscopy" },
  { id: 257, procedure: "Annual Gynecologic Exam" },
  { id: 258, procedure: "Pap Smear" },
  { id: 259, procedure: "Mamogram" },
  { id: 260, procedure: "Breast Exam" },
  { id: 261, procedure: "Salpingo-oophorectomy" },
  { id: 262, procedure: "Omentectomy" },
  { id: 263, procedure: "Abortion" },
  { id: 264, procedure: "Cancer Debulking Surgery" }



];

export default {
  procedureList,
};
