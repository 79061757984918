const questionList = [
  {
    id: 201,
    question:
      'Have you had a recent traumatic injury to your hand or wrist where you suspect you may have fractured a bone?',
    optionType: 1, // {1=>'single line options',2=>'select option'} // other will be default UI
    descriptions: [],
    options: [
      {groupId: 'I',showKalyMd: true, label: 'Yes', shortLabel: 'Red flags? Yes'},
      {nextQuestionId: 202, label: 'No', shortLabel: 'Red flags? No'},
    ],
  },
  {
    id: 202,
    question: 'How long have you had hand pain for?',
    descriptions: [],
    options: [
      {nextQuestionId: 203, label: 'Less than 2 weeks', shortLabel: '<2 weeks'},
      {nextQuestionId: 204, label: 'More than 2 weeks', shortLabel: '>2 weeks'},
    ],
  },
  {
    id: 203,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'C',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching, and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'E',
        label: 'Preliminary imaging (Xrays) and/or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'E',
        label: 'Not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
        showKalyMd: true,
      },
    ],
  },
  {
    id: 204,
    question: 'Are you considering surgery?',
    descriptions: [],
    options: [
      {groupId: 'I', label: 'Yes, in the near future', shortLabel: 'Interested in surgery'},
      {
        nextQuestionId: 205,
        label: 'I am not considering surgery at this time',
        shortLabel: 'No surgery',
      },
    ],
  },
  {
    id: 205,
    question: 'Do you have numbness, tingling, or loss of strength in the hand?',
    descriptions: [],
    options: [
      {groupId: 'F', label: 'Yes', shortLabel: 'Neurologic symptoms present'},
      {nextQuestionId: 206, label: 'No', shortLabel: 'No neurologic symptoms present'},
    ],
  },
  {
    id: 206,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'C',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'J',
        label: 'Diagnostic imaging studies (MRI/Xray) or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'J',
        label: 'A minimally invasive procedure such as an injection.',
        shortLabel: 'Open to invasive procedures',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'J',
        label: 'I am not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
      },
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 201,
    treeDepth: 5,
  },
};
