import React, {Component} from 'react';
import Layout from 'layout/VideoLayout';
import images from 'data/images';
import {Link} from 'react-router-dom';
import './PatientVideoClosed.css';
import patientService from 'services/patient';
import _ from 'lodash';
import helper from 'data/helper';

const {getAppointmentDetailsById} = patientService;
const {getSuffixString} = helper;
const initialState = {
  appointmentData: {},
};

class PatientVideoClosed extends Component {
  state = {...initialState};

  componentDidMount = () => {
    this.getAppointmentData();
  };

  getAppointmentData = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('appointment') || 0;
    const response = await getAppointmentDetailsById(id).catch(this.handleError);
    if (response) {
      const {data} = response;
      this.setState({appointmentData: data});
    }
  };

  render() {
    const {appointmentData} = this.state;
    const doctorUser = _.get(appointmentData, 'providerAvailability.provider.user', []);
    const suffixes = _.get(appointmentData, 'providerAvailability.provider.suffixes', []);
    const doctorName = `${_.get(doctorUser, 'firstName', '')} ${_.get(
      doctorUser,
      'lastName',
      '',
    )} ${getSuffixString(suffixes)}`;
    const profileImg = _.get(doctorUser, 'profileImageUrl', '');

    return (
      <Layout>
        <div className="PatientVideoClosed">
          <div className="sub-heading">Thanks for your visit with</div>
          <div className="heading2">{doctorName}</div>
          <span className="doctor-img">
            {profileImg && <img src={profileImg} alt="doctor-img" />}
          </span>
          <div className="para">This room has been closed by the provider</div>
          <Link to="/" className="home-videolink">
            Return Home
          </Link>
        </div>
      </Layout>
    );
  }
}

export default PatientVideoClosed;
