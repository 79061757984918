import React from 'react';
import _ from 'lodash';
import './CalendarHolidayEvent.css';

const CalendarHolidayEvent = props => {
  const label = _.get(props, 'event.label');
  return <div className="CalendarHolidayEvent"><span>{label}</span></div>;
};

export default CalendarHolidayEvent;
