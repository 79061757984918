import React from 'react';
import './CalendarDayHeader.css';

const CalendarDayHeader = props => {
  const {date, localizer} = props;
  return (
    <div className="CalendarDayHeader">
      {localizer.format(date, 'DD')}
      <br />
      {localizer.format(date, 'ddd')}
    </div>
  );
};
export default CalendarDayHeader;
