const questionList = [
  {
    id: 2301,
    question: 'How would you best describe you condition?',
    options: [
      {
        nextQuestionId: 2302,
        label: 'Acne',
        painId: 49,
      },
      {nextQuestionId: 2302, label: 'Skin or Nail Infection', painId: 51},
      {nextQuestionId: 2302, label: 'Rash', painId: 50},
      {nextQuestionId: 2302, label: 'Growth', painId: 52},
      {
        nextQuestionId: 2307,
        label:
          'I have a cosmetic dermatologic concern that I would like to have improved or removed.',
        painId: 54,
        shortLabel: 'Cosmetic',
      },
      {
        nextQuestionId: 2310,
        label: 'I am concerned I may have skin cancer.',
        painId: 53,
        shortLabel: 'Cancer',
      },
    ],
    descriptions: [],
  },
  {
    id: 2302,
    question: 'How long have you had the problem for?',
    descriptions: [],
    options: [
      {nextQuestionId: 2303, label: 'Less than 2 weeks', shortLabel: '<2 weeks'},
      {nextQuestionId: 2303, label: 'Two weeks - three months', shortLabel: '>2 weeks - <3 months'},
      {nextQuestionId: 2303, label: 'More than three months', shortLabel: '>3 months'},
    ],
  },
  {
    id: 2303,
    question: 'Have you seen someone for this before?',
    questionType: 4,
    optionType: 1,
    options: [
      {nextQuestionId: 2304, label: 'Yes', shortLabel: 'Seen someone before'},
      {nextQuestionId: 2304, label: 'No', shortLabel: `Haven't seen someone`},
    ],
    descriptions: [],
  },
  {
    id: 2304,
    question: 'Have you tried any over the counter medication for this before?',
    questionType: 4,
    optionType: 1,
    options: [
      {nextQuestionId: 2305, label: 'Yes', shortLabel: 'Tried medication'},
      {nextQuestionId: 2306, label: 'No', shortLabel: 'Not tried medication'},
    ],
    descriptions: [],
  },
  {
    id: 2305,
    question: 'Is the condition getting better, worse or the same?',
    descriptions: [],
    options: [
      {nextQuestionId: 2306, label: 'Better', shortLabel: 'Better condition'},
      {nextQuestionId: 2306, label: 'Worse', shortLabel: 'Worse condition'},
      {nextQuestionId: 2306, label: 'Same', shortLabel: 'Same condition'},
    ],
  },
  {
    id: 2306,
    question: 'Do you still want to see a dermatologist?',
    descriptions: [
      'A Dermatologist is a medical physician (MD/DO) who completed a four year residency in Dermatologist. A Dermatologist specializes in the diagnosis, prevention, and treatment of conditions that involves the skin, hair and nails.',
      'A Dermatologist can prescribe medications, order diagnostic tests, and perform minimally invasive surgeries.',
    ],
    title: 'Dermatologist',
    optionType: 0,
    questionType: 1,
    canDoList: [],
    options: [
      {groupId: 'A8', label: 'Yes', shortLabel: 'See Dermatologist', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
  {
    id: 2307,
    question: 'What best describes what you would like to have improved or removed?',
    staticOptionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    optionType: 6,
    nextQuestionId: 2308,
    multiColOption: true,
    descriptions: [],
  },
  {
    id: 2308,
    question: 'Have you seen someone for this before?',
    questionType: 4,
    optionType: 1,
    options: [
      {nextQuestionId: 2309, label: 'Yes', shortLabel: 'Seen someone before'},
      {nextQuestionId: 2309, label: 'No', shortLabel: `Haven't seen someone`},
    ],
    descriptions: [],
  },
  {
    id: 2309,
    question: 'Have you had cosmetic procedures in the past?',
    questionType: 4,
    optionType: 1,
    options: [
      {nextQuestionId: 2306, label: 'Yes', shortLabel: 'Had cosmetic procedures'},
      {nextQuestionId: 2306, label: 'No', shortLabel: `havn't had cosmetic procedures`},
    ],
    descriptions: [],
  },
  {
    id: 2310,
    question: 'How long have you had the problem for?',
    descriptions: [],
    options: [
      {nextQuestionId: 2311, label: 'Less than 2 weeks', shortLabel: '<2 weeks'},
      {nextQuestionId: 2311, label: 'Two weeks - three months', shortLabel: '>2 weeks - <3 months'},
      {nextQuestionId: 2311, label: 'More than three months', shortLabel: '>3 months'},
    ],
  },
  {
    id: 2311,
    question: 'Have you seen someone for this before?',
    questionType: 4,
    optionType: 1,
    options: [
      {nextQuestionId: 2312, label: 'Yes', shortLabel: 'Seen someone before'},
      {nextQuestionId: 2312, label: 'No', shortLabel: `Haven't seen someone`},
    ],
    descriptions: [],
  },
  {
    id: 2312,
    question: 'Do you have a prior history of skin cancer or any other types of cancer?',
    questionType: 4,
    optionType: 1,
    options: [
      {nextQuestionId: 2305, label: 'Yes', shortLabel: 'Prior history of cancer'},
      {nextQuestionId: 2305, label: 'No', shortLabel: 'No history of cancer'},
    ],
    descriptions: [],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 2301,
    lastQuestionId: 2306,
    treeDepth: 6,
  },
};
