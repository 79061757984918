import React from 'react';
import _ from 'lodash';
import images from 'data/images';
import DoctorAppointmentTime from '../DoctorAppointmentTime';
import Divider from 'components/Divider';
import './CancelAppointmentModal.css';

const CancelAppointmentModal = props => {
  const {closeModal} = props;
  const {data, onCancelAppointment} = props;
  const appointment = _.get(data, 'appointment');
  const {id: appointmentId} = appointment;
  const cancelAppointmentReasons = [
    'My condition has improved',
    'Provider and/or practice cancelled or rescheduled',
    'This time or location no longer works for me',
    'Provider is out-of-network',
    'I’m requesting a sooner appointment',
    'COVID-19 related reasons',
    'Other',
  ];

  return (
    <div className="CancelAppointmentModal">
      <div className="modal-content-header">
        <div className="h3">Cancel your appointment</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <DoctorAppointmentTime appointment={appointment} />
        <Divider />
        <div className="cancel-reason">
          <div className="h3">Please select a reason for your cancellation. </div>
          <div className="cancel-reason-list">
            {cancelAppointmentReasons.map((reason, index) => {
              return (
                <div
                  onClick={() => {
                    onCancelAppointment({
                      appointmentId,
                      reason,
                    });
                  }}
                  key={index}>
                  {reason}
                </div>
              );
            })}
          </div>
          <div className="keep-appointment">
            <span onClick={() => closeModal()}>Nevermind, keep my appointment</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelAppointmentModal;
