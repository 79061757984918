import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import TagsInput from 'components/FormControls/TagsInput';
import CircleButton from 'components/CircleButton';
import VisitReasons from './VisitReasons';
import ProviderPercentage from './ProviderPercentage';
import validators from 'data/validators';
import './ProviderCare.css';
import helper from 'data/helper';
import { toast, ToastContainer } from 'react-toastify';
const { isArrayEqual } = helper;
const { __required, __minLength, _scrollToView } = validators;

const ProviderCare = props => {
  const {
    data,
    options,
    onCommonDataSave,
    onSaveVisitReasons,
    isAdmin,
    currentProviderId,
    setChangedSection,
  } = props;
  const { specialtyList, painList, procedureList, visitReasonList } = options;
  const {
    specialties,
    visitReasons,
    newAppointmentTime,
    existingAppointmentTime,
    conditions = [],
    procedures = [],
    currentProviderName = '',
  } = data;
  const [formData, setFormData] = useState({
    values: { specialties },
    errors: {},
  });
  const { values: formValues, errors: formErrors } = formData;
  const [isValidConditions, setValidCondition] = useState(true);
  const [isValidProcedure, setValidProcedure] = useState(true);
  const [conditionsData, setConditionsData] = useState();
  const [procedureData, setProcedureData] = useState();

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          specialties,
        },
      };
    });
  }, [specialties]);

  useEffect(() => {
    const ifAnyChanged = checkSpecialties() || checkConditionsData() || checkProcedureData();
    setSectionActivation(ifAnyChanged);

  }, [formData.values.specialties, conditionsData, procedureData]);
  useEffect(() => {
    const ifAnyChanged = checkSpecialties()
    if (ifAnyChanged)
      setConditionData()
  }, [formData.values.specialties])
  const checkSpecialties = () => {
    const ischanged = !isArrayEqual(specialties, _.get(formData, 'values.specialties', []));
    return ischanged;
  };

  const checkConditionsData = () => {
    const ischanged = !isArrayEqual(conditions, conditionsData);
    return ischanged;
  };

  const checkProcedureData = () => {
    const updatedProcedures = _.map(procedureData, (data, index) => {
      return { ...data, percentage: data.percentage ? _.toNumber(data.percentage) : '' };
    });
    const ischanged = !isArrayEqual(procedures, updatedProcedures);
    return ischanged;
  };

  const setSectionActivation = isChanged => {
    if (isChanged) {
      setChangedSection('care');
    } else {
      setChangedSection('');
    }
  };

  const filteredVisitReasons = _.filter(visitReasonList, visitReason => {
    let isValid = false;
    _.forEach(formValues['specialties'], specialty => {
      if (specialty.id === visitReason.specialtyId) {
        isValid = true;
      }
    });
    return isValid;
  });
  const filteredPainList = _.filter(painList, pain => {
    let isValid = false;
    _.forEach(filteredVisitReasons, visitReason => {
      if (pain.id === visitReason.painId) {
        isValid = true;
      }
    });
    return isValid;
  });
  const filteredProcedureList = _.filter(procedureList, procedure => {
    let isValid = false;
    _.forEach(filteredVisitReasons, visitReason => {
      if (procedure.id === visitReason.procedureId) {
        isValid = true;
      }
    });
    return isValid;
  });
  if (conditions.length === 0) conditions.push({ painId: '', percentage: '' });

  const updateConditionsData = (isValid, data) => {
    setValidCondition(isValid);
    setConditionsData(data);
  };

  const updateProceduresData = (isValid, data) => {
    setValidProcedure(isValid);
    setProcedureData(data);
  };

  const conditionsProps = {
    list: conditions,
    listIndexes: ['painId', 'percentage'],
    options: filteredPainList,
    optionIndexes: ['id', 'pain'],
    mainTitle: 'Conditions Commonly Treated',
    mainSubTitle: 'by Percentage',
    subTitle: 'Percentage will be calculated by subtracting from the percentage above',
    addLabelText: 'Add Condition',
    maxItems: 5,
    minItems: 1,
    otherItem: {
      label: 'Other',
      subLabel: ': Any conditions not above',
      percentage: 10,
    },
    updateConditionsData: updateConditionsData,
  };
  if (procedures.length === 0) procedures.push({ procedureId: '', percentage: '' });
  const proceduresProps = {
    list: procedures,
    listIndexes: ['procedureId', 'percentage'],
    options: filteredProcedureList,
    optionIndexes: ['id', 'procedure'],
    mainTitle: 'Procedures Performed',
    mainSubTitle: 'by Percentage',
    subTitle: 'Percentage will be calculated by subtracting from the percentage above',
    addLabelText: 'Add Procedure',
    maxItems: 5,
    minItems: 1,
    otherItem: {
      label: 'Other',
      subLabel: ': Any procedures not above',
      percentage: 10,
    },
    updateProceduresData: updateProceduresData,
  };

  // Validation functions
  const __validateSpecialty = (value = null) => {
    const fValue = value !== null ? value : formValues['specialties'];
    if (!__required(fValue)) {
      return 'Specialty is required';
    }
    if (!__minLength(fValue, 1)) {
      return 'Specialty is required';
    }
    let checkEnabled = fValue.find((ii) => !ii.doNotDisplay)
    if (!checkEnabled) {
      return 'Required at least one specialty for your profile.';
    }
    checkEnabled = fValue.find((ii) => !ii.doNotDisplay && ii.status == 1)
    let previousApprovedValue=specialties.find((ii)=>ii.status==1)
    if (!checkEnabled&&previousApprovedValue) {
      
      return 'Required at least one admin approved specialty for your profile.';
    }
    return null;
  };
  const __validateForm = () => {
    const validSpecialty = !__validateSpecialty();
    return validSpecialty;
  };
  const isValidForm = __validateForm();
  const setConditionData = async (val = null) => {

    let tempPDVal = [...procedureData]

    let fpd = filteredProcedureList.map((p) => p.id);
    tempPDVal = tempPDVal.filter((ppd) => fpd.includes(ppd.procedureId))
    setProcedureData(tempPDVal)
    let tempCVal = [...conditionsData];

    let fpl = filteredPainList.map((p) => p.id);
    tempCVal = tempCVal.filter((ppd) => fpl.includes(ppd.painId))
    setConditionsData(tempCVal)
  }
  const changeHandler = event => {
    const { name, value } = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'specialties':
        errorObject = {
          [name]: __validateSpecialty(value),
        };

        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const onAdd = (name, item) => {
    if (name == 'specialties') {
      item.status = 0;
    }
    const customEvent = {
      target: {
        value: [...formValues[name], item],
        name,
      },
    };
    changeHandler(customEvent);
  };
  const onRemove = (name, item) => {
    const updatedItems = [...formValues[name]];
    if (name == 'specialties' && item.status == 1) {
      let approvedLength = updatedItems.filter((ii) => !ii.doNotDisplay && ii.status == 1 && item.id!==ii.id)
      if (approvedLength.length <= 0) {
        toast.error('Required at least one admin approved specialty visible for your profile.')
        return;
      }
    }
    _.remove(updatedItems, value => value.id == item.id);
    const customEvent = {
      target: {
        value: updatedItems,
        name,
      },
    };
    changeHandler(customEvent);
  };
  const onInputBlur = (name, selectedValues) => {
    const customEvent = {
      target: {
        value: selectedValues,
        name,
      },
    };
    changeHandler(customEvent);
  };

  const handleCommonProviderData = () => {
    if (!isValidForm || !isValidConditions || !isValidProcedure) {
      setTimeout(() => {
        _scrollToView(`.ProviderCare .error-text`,true);
      }, 100);
      return false;
    }
    const reqData = {
      ...formValues,
      conditions: [...conditionsData],
      procedures: [...procedureData],
    };
    onCommonDataSave(reqData);
  };
  const SaveSpecialitiesChange = () => {
    if (!isValidForm) {
      setTimeout(() => {
        _scrollToView(`.ProviderCare .error-text`,true);
      }, 100);
      return false;
    }
    const reqData = {
      ...formValues
    };
    onCommonDataSave(reqData);
  };
  let checkSpecialitChange = checkSpecialties()
  return (
    <div className="ProviderCare">
      <div className="ProvidercareFocus">
        <div className="ProviderOverview-inner provider-Specialities">
          <form>
            <div className="titleHeading">
              <div className="heading3">Add Specialities
              </div>


            </div>
            <div className='sub-para'>Toggle to Show Specialty Name in Title</div>
            <TagsInput
              error={formErrors['specialties']}
              options={specialtyList}
              labelIndex="specialty"
              label={'Specialities'}
              showLabel={false}
              valueIndex="id"
              selectedValues={formValues['specialties']}
              onAdd={item => onAdd('specialties', item)}
              onRemove={item => onRemove('specialties', item)}
              onInputBlur={selectedList => onInputBlur('specialties', selectedList)}
              checkStatus={true}
              showSwitchToggle={true}
              isProviderCare={true}
              subLabel={'Switch off to hide the specialty name from your profile. Patients can still find your visit reasons in search.'}
            />
          </form>
          <div style={{ textAlign: "end" }}>
            <CircleButton
              disabled={!checkSpecialitChange}
              onClick={SaveSpecialitiesChange}>
              Save
            </CircleButton>
          </div>
        </div>
      </div>
      <VisitReasons
        data={{
          specialties,
          visitReasons,
          newAppointmentTime,
          existingAppointmentTime,
          currentProviderName,
          isAdmin,
          currentProviderId,
        }}
        checkSpecialitChange={checkSpecialitChange}
        onSaveVisitReasons={onSaveVisitReasons}
      />
      <ProviderPercentage key="conditionsProps" {...conditionsProps} checkSpecialitChange={checkSpecialitChange} listNew={conditionsData} />
      <ProviderPercentage key="proceduresProps" {...proceduresProps} checkSpecialitChange={checkSpecialitChange} listNew={procedureData} />
      <div className="commonSave">
        <CircleButton
          //disabled={!isValidForm || !isValidConditions || !isValidProcedure}
          onClick={handleCommonProviderData}>
          Save
        </CircleButton>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ProviderCare;
