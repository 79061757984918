import React, { Component } from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';

import ReactPaginate from 'react-paginate';
import constants from 'data/constants';
import momentHelper from 'data/moment/momentHelper';
import reviewService from 'services/review';
import _ from 'lodash';
import './Reviewsources.css';
import helper from 'data/helper';
import images from 'data/images';
import Select from 'components/FormControls/Select';

const { DefaultDebounceMSec } = constants;
const { dateTimeFormat } = momentHelper
const initialState = {
  list: [],
  sourceList: [],
  isLoading: false,
  activePage: 1,
  totalPage: 0,
  pageLimit: 10,
  stateId: 0,
  searchText: '',
  ActiveProvider: 1,
  deletedAtNotNull: 'n',
  reviewFetched: 'f',
  reviewSource: 'All',
  sortBy: 'firstName',
  orderBy: 'asc',
  totalRecord: 0,
};

const { getUserReviewSource, generateNPIProviders } = adminService;
const { getAllReviewSource } = reviewService


const { modalFunctions } = modalConfig;
const { errorModalData, confirmModalData, successModalData } = modalFunctions;
const { getSuffixString } = helper;

class Reviewsources extends Component {
  static contextType = RootContext;
  state = { ...initialState };

  componentDidMount() {
    this.setReviewSourceList(false, true);
    this.getReviewSource();
  }

  componentWillMount = () => {
    this.props.history.replace({ state: { ...initialState } });
  };

  setReviewSourceList = async (noLoad = false, findSearchData = false) => {
    const { location } = this.props;
    const isBackLink = _.get(location, 'state.isBackLink', false);
    if (isBackLink) {
      this.getSearchedData(location);
    } else {
      const {
        activePage,
        pageLimit,
        searchText,
        ActiveProvider,
        deletedAtNotNull,
        reviewFetched,
        reviewSource,
        sortBy,
        orderBy,
      } = this.state;
      this.setState({ isLoading: true })
      const res = await getUserReviewSource(
        {
          page: activePage,
          limit: pageLimit,
          search: searchText,
          ActiveProvider: ActiveProvider,
          deletedAtNotNull: deletedAtNotNull,
          reviewFetched: reviewFetched,
          reviewSource: reviewSource,
          sortBy: sortBy,
          orderBy: orderBy,
        },
        noLoad,
      );
      this.setState({
        list: _.get(res, 'data.list', []),
        totalPage: _.get(res, 'data.totalPage', 0),
        totalRecord: _.get(res, 'data.totalRecord', 0),
        isLoading: false
      });
    }
  };

  getSearchedData = async location => {
    const searchData = _.get(location, 'state', {
      activePage: 1,
      totalPage: 0,
      searchText: '',
      pageLimit: 10,
      sortBy: 'firstName',
      orderBy: 'asc',
      searchState: '',
      searchCity: '',
      stateId: 0,
    });
    if (searchData.stateId) {
      this.getCityList(searchData.stateId);
    }
    const {
      activePage,
      pageLimit,
      searchText,
      searchState,
      searchCity,
      sortBy,
      orderBy,
      stateId,
      list,
      totalPage,
      totalRecord,
    } = searchData;
    this.setState({
      list: list,
      totalPage: totalPage,
      totalRecord: totalRecord,
      activePage: activePage,
      searchText: searchText,
      pageLimit: pageLimit,
      sortBy: sortBy,
      orderBy: orderBy,
      searchState: searchState,
      searchCity: searchCity,
      stateId: stateId,
    });
  };

  getReviewSource = async () => {
    const res = await getAllReviewSource();
    this.setState({ sourceList: _.get(res, 'data', []) });
  };


  getProviderName = item => {
    const providerSuffix = [
      {
        suffix: item.suffix,
      },
    ];
    return `${item.firstName} ${item.lastName}${getSuffixString(
      item.suffix ? providerSuffix : '',
    )}`;
  };

  handlePageChange = data => {
    this.setState({ activePage: data.selected + 1 }, this.setReviewSourceList);
  };

  handleError = error => {
    const { setGlobal } = this.context;
    const { message } = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };



  handleChange = event => {
    this.setState({ activePage: 1, searchText: event.target.value });
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.setReviewSourceList();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };

  handleSearch = () => {
    this.setState({ activePage: 1 }, this.setReviewSourceList);
  };

  handleClearSearch = () => {
    this.setState({ activePage: 1, searchText: '' }, this.setReviewSourceList);
  };






  handleSortingChange = e => {
    e.preventDefault();
    const { sortBy, orderBy } = this.state;
    const { dataset } = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        { orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1 },
        this.setReviewSourceList,
      );
    } else {
      this.setState({ orderBy: 'asc', sortBy: key, activePage: 1 }, this.setReviewSourceList);
    }
  };

  renderShortIcon = key => {
    const { sortBy, orderBy } = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  refreshNPIProvider = async npiNumber => {
    await generateNPIProviders(npiNumber).catch(this.handleError);
  };






  render() {
    const { list, totalPage, searchText, sourceList, activePage, totalRecord, isLoading } = this.state;
    const activeIndex = activePage - 1;
    const title = 'Review Sources';
    return (
      <Layout>
        <div className="Reviewsources">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div className="select-search">
                <label style={{ marginBottom: '5px', display: 'block' }}>Active Providers</label>
                <Select
                  options={[{ value: 1, label: 'Yes' }, { value: 2, label: 'No' }]}
                  labelIndex="label"
                  valueIndex="value"
                  name="cityId"
                  // defaultOptionProp={{ value: 1, label: 'Yes' }}
                  isAllowDefaultOpen={true}
                  onChange={(obj) => { this.setState({ ActiveProvider: obj.target.value, activePage: 1 }, this.setReviewSourceList); }}
                  value={this.state.ActiveProvider}
                />

              </div>
              <div className="select-search">
                <label style={{ marginBottom: '5px', display: 'block' }}>Active Review Sources</label>
                <Select
                  options={[{ value: 'n', label: 'Yes' }, { value: 'nn', label: 'No' }]}
                  labelIndex="label"
                  valueIndex="value"
                  name="deletedAtNotNull"
                  //defaultOptionProp={{ value: 'n', label: 'Yes' }}
                  isAllowDefaultOpen={true}
                  onChange={(obj) => { this.setState({ deletedAtNotNull: obj.target.value, activePage: 1 }, this.setReviewSourceList); }}
                  value={this.state.deletedAtNotNull}
                />

              </div>
              <div className="select-search">
                <label style={{ marginBottom: '5px', display: 'block' }}>Review Fetched</label>
                <Select
                  options={[{ value: 't', label: 'Yes' }, { value: 'f', label: 'No' }]}
                  labelIndex="label"
                  valueIndex="value"
                  name="reviewFetched"
                  // defaultOptionProp={{ value: 'f', label: 'No' }}
                  isAllowDefaultOpen={true}
                  onChange={(obj) => { this.setState({ reviewFetched: obj.target.value, activePage: 1 }, this.setReviewSourceList); }}
                  value={this.state.reviewFetched}
                />

              </div>
              <div className="select-search">
                <label style={{ marginBottom: '5px', display: 'block' }}>Review Source</label>
                <Select
                  options={sourceList}
                  labelIndex="title"
                  valueIndex="id"
                  name="reviewSource"
                  defaultOptionProp={{ value: "All", label: 'All' }}
                  isAllowDefaultOpen={true}
                  onChange={(obj) => { this.setState({ reviewSource: obj.target.value, activePage: 1 }, this.setReviewSourceList); }}
                  value={this.state.reviewSource}
                />

              </div>

              <div className="input-search select-search" style={{ flexDirection: "column" }}>
                <label style={{ marginBottom: '5px', display: 'block' }}>Search Provider name</label>
                <div className='input-search'>
                  <div className="input-control">
                    <input
                      placeholder="Search"
                      value={searchText}
                      onChange={this.handleChange}></input>
                    {searchText && (
                      <button className="btn-clear" onClick={this.handleClearSearch}>
                        <img src={images.close} alt="clear" />
                      </button>
                    )}
                  </div>
                  <button onClick={this.handleSearch}>Search</button>
                </div>
              </div>
            </div>
            <div className="ghostCount">
              <span>{totalRecord} Source found.</span>
            </div>
            <div className="table-overflow">
              <div className="rtable">
                <div className="heading">
                  <div
                    className="cell pointer"
                    data-key="firstName"
                    onClick={this.handleSortingChange}>
                    Provider Name{this.renderShortIcon('firstName')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="userStatusId"
                    onClick={this.handleSortingChange}>
                    Provider Status{this.renderShortIcon('userStatusId')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="title"
                    onClick={this.handleSortingChange}>
                    Review Source{this.renderShortIcon('title')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="isReviewFetched"
                    onClick={this.handleSortingChange}>
                    Is Review Fetched{this.renderShortIcon('isReviewFetched')}
                  </div>
                  <div
                    className="cell"
                  >
                    URL
                  </div>
                  <div
                    className="cell"
                  >
                    Created At
                  </div>
                  <div
                    className="cell"
                  >
                    Updated At
                  </div>
                  <div className="cell edit-cell">Deleted At</div>
                </div>
                {list.length === 0 && !isLoading && (
                  <div className="no-record">No Records found</div>
                )}
                {_.map(list, (provider, index) => (
                  <div className={provider.hasDeleted ? 'deleted-row' : 'row'} key={index}>
                    <div className="cell">
                      {(_.get(provider.user.provider, 'seoPageUrl', '')) ? (
                        <span
                          className="link-span"
                          onClick={() => window.open(_.get(provider.user.provider, 'seoPageUrl', ''), '_blank')}>
                          {`${this.getProviderName(provider.user)}`}
                        </span>
                      ) : (
                        `${this.getProviderName(provider.user)}`)}
                    </div>
                    <div className="cell">{constants.UserStatus[_.get(provider.user, 'userStatusId', '')]}</div>
                    <div className="cell">
                      {_.get(provider.reviewSource, 'title', '')}
                    </div>
                    <div className="cell">
                      {_.get(provider, 'isReviewFetched', false) ? 'Yes' : 'No'}
                    </div>
                    <div className="cell url-cell" >
                      {_.get(provider, 'url', '')}
                    </div>
                    <div className="cell">
                      {dateTimeFormat(_.get(provider, 'createdAt', ''), 'YYYY-MM-DD HH:mm a')}
                    </div>
                    <div className="cell">
                      {dateTimeFormat(_.get(provider, 'updatedAt', ''), 'YYYY-MM-DD HH:mm a')}
                    </div>
                    <div className="cell">
                      {provider.deletedAt ? dateTimeFormat(_.get(provider, 'deletedAt', '-'), 'YYYY-MM-DD HH:mm a') : '-'}
                    </div>

                  </div>
                ))}
              </div>
            </div>
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Reviewsources;
