import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import images from 'data/images';
import momentHelper from 'data/moment/momentHelper';
import scheduleService from 'services/schedule';
import helper from 'data/helper';
import './DoctorViewAvailabilityModal.css';

const {getAvailability, getSuffixString,getLatestAvailabilityDate} = helper;
const {startOf, endOf, dateOrCurrentDate, isSameDay, dateFormat} = momentHelper;
const {getFilteredAvailabilityOfDoctor} = scheduleService;

const DoctorViewAvailabilityModal = props => {
  const {closeModal} = props;
  const {data, timeSelected} = props;
  
  const {doctorDetail, isVirtual, otherData} = data;
  
  let nextAppointmentMonth=startOf('month')
  let CurrentSelectedDate=null
  if(doctorDetail.formatedAvailabilities){
    if(Object.keys(doctorDetail.formatedAvailabilities).length>0){
      let selectedKey=Object.entries(doctorDetail.formatedAvailabilities).find(([key,value])=>value.length>0)
      if(selectedKey.length>0){
        selectedKey=selectedKey[0]
        nextAppointmentMonth=startOf('month',selectedKey)
        CurrentSelectedDate=selectedKey
      }
    }
  }
  const {sortedConditions, mainConditionIndex, sortedProcedures, mainProcedureIndex} = otherData;
  const {
    providerId,
    profileImageUrl,
    name,
    locationId,
    practiceName,
    address,
    totalReview,
    totalRating,
    suffixes,
    specialties,
    newAppointmentTime,
    existingAppointmentTime,
    customSpecialty=null
  } = doctorDetail;
  const newAppointmentTimeorExAT=existingAppointmentTime>newAppointmentTime?existingAppointmentTime:newAppointmentTime
  let specialtyNames = _.map(specialties, 'specialty');
  if(customSpecialty){
    specialtyNames=[...specialtyNames,customSpecialty]
  }
  const [availabilities, setAvailabilities] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dateOrCurrentDate(CurrentSelectedDate));
  const [calendarAvailabilities, setCalendarAvailabilities] = useState(null);
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(nextAppointmentMonth);

  useEffect(() => {
    getAndSetCalendarAvailabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDayOfMonth]);

  useEffect(() => {
    getAndSetAvailabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const getAndSetCalendarAvailabilities = async () => {
    let firstDayForCalendar = startOf('week', firstDayOfMonth.clone());
    const currentMonthEndDate = endOf('month', firstDayOfMonth.clone());
    const lastDayForCalendar = endOf('week', currentMonthEndDate);
    const datesObject = {};
    // if previous day is current date then add additional day to remove business hours from it
    let extraDay = 0;
    if (isSameDay(firstDayForCalendar, dateOrCurrentDate().add(1, 'day'))) {
      extraDay = 1;
      datesObject[dateFormat()] = [];
      firstDayForCalendar.subtract(1, 'day');
    }
    const utcStartDateString = dateFormat(firstDayForCalendar.clone().utc());
    // add extra day if removed 1 day previously
    firstDayForCalendar.add(extraDay, 'day');
    while (!firstDayForCalendar.isSameOrAfter(lastDayForCalendar)) {
      datesObject[dateFormat(firstDayForCalendar)] = [];
      firstDayForCalendar.add(1, 'day');
    }

    const utcEndDateString = dateFormat(endOf('day', lastDayForCalendar.clone()).utc());
    const response = await getFilteredAvailabilityOfDoctor(
      providerId,
      utcStartDateString,
      utcEndDateString,
      locationId,
      isVirtual,
    ).catch(() => null);
    const {data} = response;
    const providerAvailabilities = _.get(data, '0.providerAvailabilities', []);
    const patientAppointments = _.get(data, '0.patientAppointments', []);
    const timezone = _.get(data, '0.timezone', null);
    const api_isVirtual = _.get(data, '0.isVirtual', null);
    const api_latestAvailability = _.get(data, '0.latestAvailability', null);
    let nextOpenDate=getLatestAvailabilityDate(api_isVirtual,api_latestAvailability, providerAvailabilities,
      newAppointmentTimeorExAT,
      patientAppointments,
      timezone);
    const newAvailabilities = getAvailability(
      providerAvailabilities,
      datesObject,
      newAppointmentTimeorExAT,
      patientAppointments,
      timezone,
      nextOpenDate
    );
    // if extra day then delete availability of extra day
    if (extraDay) {
      delete newAvailabilities[dateFormat()];
    }
    setCalendarAvailabilities(newAvailabilities);
  };

  const getAndSetAvailabilities = async () => {
    const updatedSelectedDate = selectedDate.clone();
    const sDate = updatedSelectedDate.clone().subtract(9, 'days');
    const datesObject = {};
    // if previous day is current date then add additional day to remove business hours from it
    let extraDay = 0;
    if (isSameDay(sDate.clone().subtract(1, 'day'))) {
      extraDay = 1;
      datesObject[dateFormat()] = [];
      sDate.subtract(1, 'day');
    }
    const utcStartDateString = sDate.clone().utc().format('YYYY-MM-DD');
    // add extra day if removed 1 day previously
    sDate.add(extraDay, 'day');
    for (let i = 0; i < 30; i++) {
      datesObject[sDate.format('YYYY-MM-DD')] = [];
      sDate.add(1, 'day');
    }
    const utcEndDateString = endOf('day', sDate.clone()).utc().format('YYYY-MM-DD');
    const response = await getFilteredAvailabilityOfDoctor(
      providerId,
      utcStartDateString,
      utcEndDateString,
      locationId,
      isVirtual,
    ).catch(() => null);
    const {data} = response;
    const providerAvailabilities = _.get(data, '0.providerAvailabilities', []);
    const patientAppointments = _.get(data, '0.patientAppointments', []);
    const timezone = _.get(data, '0.timezone', null);
    const api_isVirtual = _.get(data, '0.isVirtual', null);
    const api_latestAvailability = _.get(data, '0.latestAvailability', null);
    let nextOpenDate=getLatestAvailabilityDate(api_isVirtual,api_latestAvailability, providerAvailabilities,
      newAppointmentTimeorExAT,
      patientAppointments,
      timezone);
    const newAvailabilities = getAvailability(
      providerAvailabilities,
      datesObject,
      newAppointmentTimeorExAT,
      patientAppointments,
      timezone,
      nextOpenDate
    );
    // if extra day then delete availability of extra day
    if (extraDay) {
      delete newAvailabilities[dateFormat()];
    }
    setAvailabilities(newAvailabilities);
    setTimeout(() => {
      const timeDiv = document.getElementById(selectedDate.format('YYYY-MM-DD'));
      if (timeDiv) {
        timeDiv.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }, 500);
  };

  const handleChangeSelectedDate = sDate => {
    setSelectedDate(sDate);
  };
  const handleMonthChange = (type = 'next') => {
    const nextFirstDay = firstDayOfMonth.clone();
    switch (type) {
      case 'next':
        nextFirstDay.add(1, 'month');
        break;
      case 'prev':
        nextFirstDay.subtract(1, 'month');
        break;
      default:
        break;
    }
   // setSelectedDate(dateOrCurrentDate(nextFirstDay))
    setFirstDayOfMonth(nextFirstDay);
  };
  let isDisablePrevButton = true;
  if (firstDayOfMonth) {
    isDisablePrevButton = isSameDay(startOf('month'), dateOrCurrentDate(firstDayOfMonth));
  }
  // availability time list component
  const availabilityListCompoennt = _.map(availabilities, (availabilitiesOfDay, dateString) => {
    if (availabilitiesOfDay.length === 0) {
      return null;
    }
    const dateObj = dateOrCurrentDate(dateString);
    return (
      <div className="time-list-item" id={dateString} key={dateString}>
        <div className="day">
          <span>{dateObj.format('ddd, MMM DD')}</span>
        </div>
        <div className="time">
          {availabilitiesOfDay.map(timeObject => {
            return (
              <span
                key={timeObject.time}
                onClick={() => {
                  if (!timeObject.isBooked) {
                    timeSelected(dateString, timeObject);
                    closeModal();
                  }
                }}
                className={`time-box ${timeObject.isBooked ? 'booked' : ''}`}>
                {timeObject.time}
              </span>
            );
          })}
        </div>
      </div>
    );
  });

  if (!availabilities) {
    return null;
  }
  return (
    <div className="DoctorViewAvailabilityModal">
      <div className="modal-content-header">
        <div className="h3">View Full Availability</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className={["modal-content-body",isVirtual?'videomode':''].join(' ')}>
        <div className="dc-info-content">
          <div className="DC-image">
            <span>
              <img src={profileImageUrl || images.user} alt="doctor" />
              {isVirtual ? (
                <span className="video-visit">
                  <img src={images.videoBtn} alt="video" /> Video Visit
                </span>
              ) : (
                <span className="person-visit">
                  <img src={images.personBtn} alt="In Person" /> In-Person
                </span>
              )}
            </span>
          </div>

          <div className="dc-information">
            <div className="dc-content">
              <div className="dc-name">
                {name}
                {getSuffixString(suffixes)}
              </div>
              <div className="dc-specialist">{specialtyNames.join(', ')}</div>
              <div className="dc-address">{address}</div>
              <div className="progress-bar-wrapper">
                {sortedConditions.length > 0 && (
                  <div className="progress-bar">
                    <div className="pt-category-box">
                      {sortedConditions.map((conditionObj, key) => {
                        return (
                          conditionObj.percentage !== 0 && (
                            <span
                              key={key}
                              className={`box ${mainConditionIndex === key ? 'active' : ''}`}
                              style={{width: `${conditionObj.percentage}%`}}>
                              <span className="dc-symptoms-result">
                                <span className="dc-symptoms-result-inner">
                                  <span className="dc-symptoms-inner">
                                    {conditionObj.percentage}% {conditionObj.pain}
                                  </span>
                                </span>
                              </span>
                            </span>
                          )
                        );
                      })}
                      {sortedConditions.length >= 5 && (
                        <span
                          className={`box ${mainConditionIndex > 4 ? 'active' : ''}`}
                          style={{width: `10%`}}>
                          <span className="dc-symptoms-result">
                            <span className="dc-symptoms-result-inner">
                              <span className="dc-symptoms-inner">10% Other</span>
                              {sortedConditions.map(
                                (conditionObj, key) =>
                                  conditionObj.percentage === 0 && (
                                    <span className="dc-symptoms-inner" key={key}>
                                      {conditionObj.pain}
                                    </span>
                                  ),
                              )}
                            </span>
                          </span>
                        </span>
                      )}
                    </div>
                    {mainConditionIndex !== null && sortedConditions[mainConditionIndex] && (
                      <div className={`dc-symptoms`}>
                        <div>
                          {sortedConditions[mainConditionIndex].percentage !== 0 &&
                            `${sortedConditions[mainConditionIndex].percentage} % `}
                          {sortedConditions[mainConditionIndex].pain}
                        </div>
                      </div>
                    )}
                    <div className="dc-symptoms-text">Conditions Commonly Treated</div>
                  </div>
                )}
                {sortedProcedures.length > 0 && (
                  <div className="progress-bar">
                    <div className="pt-category-box">
                      {sortedProcedures.map((procedureObj, key) => {
                        return (
                          procedureObj.percentage !== 0 && (
                            <span
                              key={key}
                              className={`box ${mainProcedureIndex === key ? 'active' : ''}`}
                              style={{width: `${procedureObj.percentage}%`}}>
                              <span className="dc-symptoms-result">
                                <span className="dc-symptoms-result-inner">
                                  <span className="dc-symptoms-inner">
                                    {procedureObj.percentage}% {procedureObj.procedure}
                                  </span>
                                </span>
                              </span>
                            </span>
                          )
                        );
                      })}
                      {sortedProcedures.length >= 5 && (
                        <span
                          className={`box ${mainProcedureIndex > 4 ? 'active' : ''}`}
                          style={{width: `10%`}}>
                          <span className="dc-symptoms-result">
                            <span className="dc-symptoms-result-inner">
                              <span className="dc-symptoms-inner">10% Other</span>
                              {sortedProcedures.map(
                                (procedureObj, key) =>
                                  procedureObj.percentage === 0 && (
                                    <span className="dc-symptoms-inner" key={key}>
                                      {procedureObj.procedure}
                                    </span>
                                  ),
                              )}
                            </span>
                          </span>
                        </span>
                      )}
                    </div>
                    {mainProcedureIndex !== null && sortedProcedures[mainProcedureIndex] && (
                      <div className="dc-symptoms">
                        <div>
                          {sortedProcedures[mainProcedureIndex].percentage} %{' '}
                          {sortedProcedures[mainProcedureIndex].procedure}
                        </div>
                      </div>
                    )}
                    <div className="dc-symptoms-text">Procedures Commonly Performed</div>
                  </div>
                )}
              </div>
            </div>
            <div className="dc-review">
              <span className="reviwe-content">
                <span>
                  <img src={images.reviewStarPatient} alt="star" className="star" />
                  <span className="rating">{totalRating}</span>
                  <span className="info-wrapper">
                    <img src={images.info} alt="info" className="info" />
                    <span className="info-content">
                      This is a kScore - an aggregate of reviews from top review websites in our
                      industry.
                    </span>
                  </span>
                </span>
                <span>
                  <span className="rating-text">({totalReview})</span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="dc-time-info">
          <div className="dc-calender">
            <div className="appointment-block appointments-day-block">
              <div className="appointment-heading">
                <div className="heading-txt">{firstDayOfMonth.format('MMMM')} Openings</div>
                <div className="appointment-link">
                  <span
                    onClick={() => !isDisablePrevButton && handleMonthChange('prev')}
                    className={`prev-link ${isDisablePrevButton ? 'disabled' : ''}`}>
                    <img src={images.leftArrow} alt="left-arrow" />
                  </span>
                  <span onClick={() => handleMonthChange('next')} className="right-link">
                    <img src={images.rightArrow} alt="right-arrow" />
                  </span>
                </div>
              </div>
              <div className="day-table-wrapper">
                <div className="table day-table">
                  <div className="tr">
                    {_.map(calendarAvailabilities, (availabilitiesOfDay, dateString) => {
                      const dateObj = dateOrCurrentDate(dateString);
                      return (
                        <div
                          onClick={() =>
                            availabilitiesOfDay.length > 0 && handleChangeSelectedDate(dateObj)
                          }
                          key={dateString}
                          className={`${availabilitiesOfDay.length === 0 ? 'prev' : ''} td`}>
                          <div
                            className={`day ${
                              selectedDate.format('YYYY-MM-DD') === dateString ? 'selected' : ''
                            }`}>
                            {dateObj.format('D')}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dc-time">
            <div className="dc-address">
              <div>{practiceName}</div>
              <div>{address}</div>
            </div>
            {/* <div className="week-time">
              <span className="date-title">Mar 6 - Mar 13</span>
              <span className="next-week-action">
                <span className="prev-link disable">
                  <img src={images.arrowLeft} alt="left-arrow" />
                </span>
                <span className="right-link">
                  <img src={images.arrowLeft} alt="right-arrow" />
                </span>
              </span>
            </div> */}
            <div className="dc-time-list">{availabilityListCompoennt}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorViewAvailabilityModal;
