import React, { useContext, useEffect, useState } from 'react';

import EachFeedback from './EachFeedback';

import RootContext from 'context/RootContext';
import feedbackService from 'services/providerFeedback';
import modalConfig from 'modals/modalConfig';
import _ from 'lodash';
import './Feedback.css';
import analyticsData from 'data/analytics/analyticsData';
import AuthContext from 'context/AuthContext';
import Button from 'components/Button';

const {
  getFeedbackProvider
} = feedbackService;
const { modalFunctions } = modalConfig;
const {  errorModalData } = modalFunctions;
const { Analytics } = analyticsData;

// STUB data
const defaultFormData = {
  reviewSourceId: '',
  url: '',
};

const Feedback = props => {
  const rootContext = useContext(RootContext);
  const authContext = useContext(AuthContext);
  const { auth } = authContext;
  const { setGlobal, reviewSourceList } = rootContext;
  const [feedbackCount, setFeedbackCount] = useState(0);
  const [feedbackCurrentPage, setfeedbackCurrentPage] = useState(1);
  const [feedbackList, setFeedbackList] = useState([]);
  const [nofeedbackbool, setNofeedbackbool] = useState(true);
  useEffect(() => {
    setfeedbackCurrentPage(1)
    setFeedbackList([])
    setFeedbackCount(0)
    setNofeedbackbool(true)
    setTimeout(() => {
      loadData()
    }, 500);

  }, [props.appointmentFeedbackStatusId]);

  const handleError = error => {
    const { message } = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };


  const loadData = async (page = 1) => {

    setfeedbackCurrentPage(page)
    let data = await getFeedbackProvider({ "page": page, "limit": 10, appointmentFeedbackStatusId: props.appointmentFeedbackStatusId })
    if (page !== 1)
      setFeedbackList([...feedbackList, ...data.data.feedbackData])
    else {
      setFeedbackList([...data.data.feedbackData])
    }
    setNofeedbackbool(false)
    setFeedbackCount(data.data.totalRecord)
  };

  const LoadMore = () => {
    loadData(feedbackCurrentPage + 1)
  }

  const getActiveComponent = () => {

    const { title } = props
    // if(title==='Completed Feedback')
    return (
      <>
        {feedbackList.map((item, index) => (
          <EachFeedback
            key={item.id}
            statusclass={'completed'}
            itemDetails={item}
            propsFeedbackStatusId={props.appointmentFeedbackStatusId}
          />
        ))}

        {feedbackList.length === 0 && !nofeedbackbool && feedbackCurrentPage === 1 && (
          <div className='nofeedbacks'>{`No ${props.appointmentFeedbackStatusId===1?'pending':'completed'} feedbacks`}</div>
        )}
      </>
    );

  };
  const { title } = props
  return (
    <div className="feedback-maindiv">
      <div className="heading">
        <div className="main-title">{title} </div>
        <div>
          <p>Providing feedback to Kaly provides essential data analytics to help us deliver more patients that are seeking your specific expertise.  Our goal is to promote the efficient utilization of limited and costly healthcare services, which will help increase both patient and doctor satisfaction.</p>
          <p>Please help kaly promote efficiency by rating the appropriateness of your patient’s visit reason.</p>
        </div>

        <span>

        </span>
      </div>
      <div className="feedback-content">{getActiveComponent()}</div>
      {feedbackCount > feedbackList.length && (
        <div className='loadmorediv'>
          <Button onClick={LoadMore}>Load More</Button>
        </div>
      )}
    </div>
  );
};

export default Feedback;
