import React, {Component} from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import _ from 'lodash';
import './UploadFiles.css';
import adminService from '../../../services/admin';
import modalConfig from 'modals/modalConfig';
import images from 'data/images';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import ReadMore from 'components/ReadMore';
import constants from 'data/constants';
import AuthContext from 'context/AuthContext';
import manageFileUpload from 'services/manageFileUpload';
import {ViewPhotoId} from '../JoinUs/viewPhotoId';
const {errorModalData, confirmModalData, blankModalData,successModalData} = modalConfig.modalFunctions;
const {getMyFiles, updateStatusUploadFile, deleteUploadFile} = manageFileUpload;
const StatusFilter={
  0:'Pending',
  1:'Approved',
  2:'Rejected'
} 
const categoryList = [
  {id: 1, label: 'Photo ID'},
  {id: 2, label: 'Graduate School Diploma'},
  {id: 3, label: 'Undergraduate School Diploma'},
  {id: 4, label: 'Active State Professional license.'},
  {id: 5, label: 'Post-Graduate Diploma (Residency, Fellowship, etc)'},
  {id: 6, label: 'Hospital Affiliation'},
  {id: 7, label: 'Board Certificate'},
  {id: 8, label: 'Other'},
];
const {DefaultDebounceMSec} = constants;

const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  pageLimit: 10,
  searchText: '',
  searchState: '',
  searchCity: '',
  sortBy: 'createdAt',
  orderBy: 'DESC',
  totalRecord: 0,
};

class UploadFiles extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {...initialState};

  componentDidMount() {
    this.getList(false, true);
  }

  componentWillMount = () => {
    this.props.history.replace({state: {...initialState}});
  };

  getList = async (noLoad = false, findSearchData = false) => {
    const {location} = this.props;
    const isBackLink = _.get(location, 'state.isBackLink', false);
    if (isBackLink) {
      this.getSearchedData(location);
    } else {
      const {activePage, pageLimit, searchText, sortBy, orderBy} = this.state;
      const res = await getMyFiles(
        {
          pageSize: activePage,
          pageLimit: pageLimit,
          search: searchText,
          sortBy: sortBy,
          orderBy: orderBy,
        },
        noLoad,
      );
      this.setState({
        list: _.get(res, 'data.rows', []),
        totalPage:Math.ceil(_.get(res, 'data.count', 0)/pageLimit) ,
        totalRecord: _.get(res, 'data.count', 0),
      });
    }
  };

  getSearchedData = location => {
    const searchData = _.get(location, 'state', {
      activePage: 1,
      searchText: '',
      pageLimit: 10,
      sortBy: 'createdAt',
      orderBy: 'ASC',
      totalRecord: 0,
    });
    const {activePage, pageLimit, searchText, sortBy, orderBy, list, totalPage, totalRecord} =
      searchData;
    this.setState({
      list: list,
      totalPage: totalPage,
      activePage: activePage,
      searchText: searchText,
      pageLimit: pageLimit,
      sortBy: sortBy,
      orderBy: orderBy,
      totalRecord: totalRecord,
    });
  };
  viewPhotoId = (url, userName, fileType) => {
    let {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ViewPhotoId,
        customComponentProps: {url: url, userName: userName, fileType: fileType},
      }),
    );
  };
  handleError = error => {
    const {setGlobal} = this.rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'ASC') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'createdAt')
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'ASC' ? 'DESC' : 'ASC', activePage: 1},
        this.getList,
      );
    } else {
      this.setState({orderBy: 'ASC', sortBy: key, activePage: 1}, this.getList);
    }
  };

  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.getList();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };

  handleSearch = () => {
    this.setState({activePage: 1}, this.getList);
  };

  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.getList);
  };

  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.getList);
  };

  handleReviewDelete = reviewId => {
    const {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete this File ?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmDeleteReview(reviewId);
          }
        },
      }),
    );
  };

  confirmDeleteReview = async reviewId => {
    let {setGlobal} = this.rootContext;
    const response = await deleteUploadFile(reviewId).catch(this.handleError);
    if (response) {
      
        setGlobal('modal', successModalData(response.message));
        this.getList();
    }
  };

  handleRejectRequest = (userId, reviewId,reject) => {
    const {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to ${reject} this File?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmRejectRequest(userId, reviewId);
          }
        },
      }),
    );
  };

  confirmRejectRequest = async (userId, reviewId) => {
    let {setGlobal} = this.rootContext;
    const response = await updateStatusUploadFile(userId, reviewId).catch(this.handleError);
    if (response) {
      setGlobal('modal', successModalData(response.message));
      this.getList();
     // this.updateContextData();
    }
  };

  handleViewDetails = userId => {
    const params = new URLSearchParams();
    if (userId) {
      params.append('user', userId);
    }
    params.append('isRequest', 1);
    this.props.history.push({
      pathname: '/admin/provider-reviews',
      search: `?${params.toString()}`,
      state: {...this.state, isBackLink: true},
    });
  };

  // updateContextData = async () => {
  //   const requestCountData = await getRequestCounts().catch(() => null);
  //   if (requestCountData && requestCountData.data) {
  //     const {setAdminRequestCountState} = this.context;
  //     setAdminRequestCountState(prevState => {
  //       return {
  //         ...prevState,
  //         requestCount: {
  //           ...requestCountData.data,
  //         },
  //       };
  //     });
  //   }
  // };

  render() {
    const title = 'Upload Files';
    const {list, totalPage, searchText, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    return (
      <Layout>
        <RootContext.Consumer>
          {context => {
            this.rootContext = context;
          }}
        </RootContext.Consumer>
        <div className="ReviewRequest uploadFiles">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div className="input-control">
                <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
                {searchText && (
                  <button className="btn-clear" onClick={this.handleClearSearch}>
                    <img src={images.close} alt="clear" />
                  </button>
                )}
              </div>
              <button onClick={this.handleSearch}>Search</button>
            </div>
            <div className="dataCount">
              <span>{totalRecord} Files found.</span>
            </div>
            {
              <div className="table-overflow">
                <div className="rtable">
                  <div className="heading">
                    <div
                      className="cell pointer"
                      data-key="firstName"
                      onClick={this.handleSortingChange}>
                      Provider Name{this.renderShortIcon('firstName')}
                    </div>
                    <div
                      className="cell pointer"
                      data-key="email"
                      onClick={this.handleSortingChange}>
                      Provider Email{this.renderShortIcon('email')}
                    </div>
                    <div
                      className="cell pointer"
                      data-key="npiNumber"
                      onClick={this.handleSortingChange}>
                      Npi #{this.renderShortIcon('npiNumber')}
                    </div>
                    <div
                      className="cell pointer"
                      data-key="review"
                      >
                      Category
                    </div>
                    <div
                      className="cell pointer"
                      data-key="review"
                     >
                      File
                    </div>
                    <div
                      className="cell pointer"
                      data-key="createdAt"
                      onClick={this.handleSortingChange}>
                      Upload Date{this.renderShortIcon('createdAt')}
                    </div>
                    <div className="cell edit-cell">Status</div>
                    <div className="cell edit-cell">Action</div>
                  </div>
                  {list.length > 0 ? (
                    _.map(list, (fileList, index) => (
                      <div className="row" key={index}>
                        <div className="cell">
                          {
                            `${_.get(fileList, 'firstName', '')} ${_.get(
                              fileList,
                              'lastName',
                              '',
                            )}`}
                        </div>
                        <div className="cell">{_.get(fileList, 'email', '')}</div>
                        <div className="cell">
                        {_.get(fileList, 'npiNumber', '')}
                        </div>
                        <div className="cell">
                          <div style={{display: 'flex', flexFlow: 'column',maxWidth:"200px",wordBreak:"break-all"}}>
                            {categoryList.find(c => c.id == fileList.categoryId)?.label}
                            {fileList.categoryId === '8' && (
                              <span style={{fontSize: '15px'}}>
                                <i>{_.get(fileList, 'otherCategoryName', '')}</i>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="cell">
                          <div className="photoid-div">
                          <img
                            onClick={() =>
                              this.viewPhotoId(
                                fileList.documentLink,
                                `${fileList.firstName} ${fileList.lastName}`,
                                fileList.categoryId === '8'
                                  ? fileList.otherCategoryName
                                  : categoryList.find(c => c.id == fileList.categoryId)?.label,
                              )
                            }
                            className="photoid"
                            src={
                              images.photoID
                                
                            }
                            alt="photoID"
                          />
                          </div>
                        </div>
                        <div className="cell">
                          {this.formatedDate(_.get(fileList, 'createdAt', ''))}
                        </div>
                        <div className="cell">
                          <span>{StatusFilter[fileList.status]}</span>
                          {fileList.status===0 &&(
                        <div class="actionbtndev">
                          <button
                            className="btn btn-view"
                            onClick={() => this.handleRejectRequest(
                              _.get(fileList, 'id', ''),
                             '1','Approve')}>
                           Approve
                          </button>
                          <button
                            className="btn btn-delete"
                            onClick={() =>
                              this.handleRejectRequest(
                                _.get(fileList, 'id', ''),
                               '2','Reject'
                              )
                            }>
                            Reject
                          </button>
                          </div>
                          )}
                        </div>
                        <div className="cell">
                        <div class="actionbtndev1">
                          {fileList.status!==1 &&(
                        <button
                            className="btn btn-delete"
                            onClick={() =>
                              this.handleReviewDelete(_.get(fileList, 'id', ''))
                            }>
                            Delete
                          </button>
                          )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-record">No Records found</div>
                  )}
                </div>
              </div>
            }
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default UploadFiles;
