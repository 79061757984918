import React, {useState, useEffect} from 'react';
import images from 'data/images';
import fileUploadService from 'services/fileUpload';
import MyImageCrop from 'components/MyImageCrop';
import './EditPracticeLogo.css';

const {adminProviderLogoUpload, adminProviderRemoveLogo} = fileUploadService;

const EditPracticeLogo = props => {
  const {defaultFileUrl = '', onError, practiceId, hasPermissionToEdit} = props;

  const [fileObj, setFile] = useState({
    files: null,
    preview: defaultFileUrl,
  });
  const {files, preview} = fileObj;

  const handleFileSelection = event => {
    const {files: filesInstance} = event.target;
    if (filesInstance) {
      setFile(prevState => {
        return {
          ...prevState,
          files: filesInstance,
        };
      });
    }
  };

  const handleFileUpload = async blobFile => {
    const response = await adminProviderLogoUpload(blobFile, practiceId).catch(onError);
    if (response) {
      const {data} = response;
      const {fileUrl} = data;
      setFile({
        files: null,
        preview: fileUrl,
      });
    }
  };

  const removeFile = async () => {
    const response = await adminProviderRemoveLogo(practiceId).catch(onError);
    if (response) {
      setFile({
        files: null,
        preview: null,
      });
    }
  };
  if (!practiceId) return null;
  return (
    <div className="box-content EditPracticeLogo">
      <div className="h3">Practice Logo</div>
      <div className="PracticeLogo">
        <span className="logo-img">
          <img src={preview || defaultFileUrl || images.defaultPracticeLogo} alt="" />
        </span>
        <MyImageCrop
          cropConfig={{
            aspect: 300 / 300,
            // width: 500,
            // height: 500,
            // unit: 'px',
          }}
          files={files}
          afterCropImage={handleFileUpload}
          onError={onError}
          cropextraProps={{minHeight: 300, minWidth: 300}}
          cropElementProps={{minHeight: 300, minWidth: 300}}
        />
        {hasPermissionToEdit && (
          <div className="logo-inner-content">
            <span className="label">Upload a photo</span>
            <span className="image-size">Minimum 300 x 300px, use JPG or PNG</span>
            <div className="logo-content-action">
              <div className="fileupload">
                <span className="fileupload-btn">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileSelection}
                    onClick={event => {
                      event.target.value = null;
                    }}
                  />
                  <button>Change Photo</button>
                </span>
              </div>
              {(preview || defaultFileUrl) && (
                <button className="circle-btn" onClick={removeFile}>
                  Remove
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditPracticeLogo;
