import React, {useContext, useEffect, useState} from 'react';
import images from 'data/images';
import Select from 'components/FormControls/Select';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import ReviewSourceList from './ReviewSourceList';
import validators from 'data/validators';
import RootContext from 'context/RootContext';
import reviewService from 'services/review';
import modalConfig from 'modals/modalConfig';
import _ from 'lodash';
import './ConnectReviews.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import AuthContext from 'context/AuthContext';
const {__required, __isValidUrl} = validators;
const {
  getAllReviewSource,
  getAllMyReviewSource,
  saveReviewSource,
  deleteReviewSoruce,
  refetchReviews,
  getSocialConnect,
} = reviewService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;
const {Analytics} = analyticsData;

// STUB data
const defaultFormData = {
  reviewSourceId: '',
  url: '',
};

const ConnectReviews = props => {
  const rootContext = useContext(RootContext);
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const {setGlobal, reviewSourceList} = rootContext;
  const [selectedSourceFormData, setSourceFormData] = useState(null);
  const [sourceList, setSourceList] = useState([]);
  const [fbConnected, setFbConnected] = useState(false);
  const [googleConnected, setGoogleConnected] = useState(false);
  const [oldviewUserId,setOldViewUserId]=useState(0);
  useEffect(() => {
    getAndSetReviewSource();
    //getAndSetConnections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedSourceFormData === null) {
      getAndSetUserReviewSource();
      setOldViewUserId(auth.viewUserId)
    }else if(auth.viewUserId!==oldviewUserId){
      setOldViewUserId(auth.viewUserId);
      getAndSetUserReviewSource();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSourceFormData,auth,oldviewUserId]);

  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  const getAndSetConnections = async () => {
    const response = await getSocialConnect().catch(handleError);
    if (response) {
      const {data} = response;
      if (data.facebook) {
        setFbConnected(true);
      }
      if (data.gmb) {
        setGoogleConnected(true);
      }
    }
  };

  const getAndSetUserReviewSource = async () => {
    const response = await getAllMyReviewSource().catch(handleError);
    if (response) {
      const {data} = response;
      setSourceList(data);
    }
  };

  const getAndSetReviewSource = async () => {
    if (!reviewSourceList) {
      const response = await getAllReviewSource().catch(handleError);
      if (response) {
        const {data} = response;
        setGlobal('reviewSourceList', data);
      }
    }
  };

  const cancelAddEdit = () => {
    setSourceFormData(null);
  };

  const handleAddWebsite = () => {
    if (!selectedSourceFormData) {
      setSourceFormData({values: defaultFormData, errors: {}});
    }
  };

  const handleSourceSelect = source => {
    const {
      id: userReviewSourceId,
      reviewSource: {id: reviewSourceId},
      url,
    } = source;
    const formData = {userReviewSourceId, reviewSourceId, url};
    setSourceFormData({values: formData, errors: {}});
  };

  const handleDeleteSource = async userReviewSourceId => {
    if(userReviewSourceId==='Google'){
      let errorMsg =
      `${userReviewSourceId} reviews can Delete by disconnect the ${userReviewSourceId} account. Proceed to Connect Social section for it.`;
      handleError({
        message: errorMsg,
    });
    return
    }
    Nux.sendEvent(Analytics.Category.DoctorReview, Analytics.Actions.Click, 'reviewLinkAction');
    const response = await deleteReviewSoruce(userReviewSourceId).catch(handleError);
    if (response) {
      getAndSetUserReviewSource();
    }
  };

  const handleRefreshReviews = async (userReviewSourceId, toggleActive) => {
    const response = await refetchReviews(userReviewSourceId).catch(handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            toggleActive(0);
          },
        }),
      );
    }
  };

  const handleSaveSource = async () => {
    Nux.sendEvent(Analytics.Category.DoctorReview, Analytics.Actions.Click, 'reviewLinkAction');
    const {values} = selectedSourceFormData;
    if (validSourceURL()) {
      const response = await saveReviewSource(values).catch(handleError);
      if (response) {
        setGlobal(
          'modal',
          successModalData(
            'Once the website link is added, it will be sent to approval from administration. Once approved, it will take maximum 24 hours to fetch the reviews.',
          ),
        );
        setSourceFormData(null);
      }
    }
  };

  const validSourceURL = () => {
    let isValid = true;
    const reviewSourceId = _.get(selectedSourceFormData, 'values.reviewSourceId', '');
    const url = _.get(selectedSourceFormData, 'values.url', '');
    const filterList = _.filter(reviewSourceList, data => {
      return data.id === reviewSourceId;
    });
    if (filterList.length > 0) {
      isValid = url.toLowerCase().includes(`${filterList[0].title.toLowerCase()}.com`);
      if (!isValid) {
        handleError({
          message: `Please enter valid ${filterList[0].title} URL.`,
        });
      }
    }
    return isValid;
  };

  if (!reviewSourceList) {
    return null;
  }

  const noSourceComponent = (
    <div className="no-review-text">
      <div className="Reviewtitle">Display your reviews from multiple websites</div>
      <div className="review-logo">
        <span>
          <img src={images.reviewLogo1} alt="" />
        </span>
        <span>
          <img src={images.reviewLogo2} alt="" />
        </span>
        {/* <span>
          <img src={images.reviewLogo3} alt="" />
        </span> */}
        <span>
          <img src={images.reviewLogo4} alt="" />
        </span>
      </div>
      <div className="desciption">
        Added reviews will be aggregated into your kScore and displayed alongside your Kaly
        reviews while being monitored.
      </div>
      <span className="linkspan" onClick={handleAddWebsite}>
        Add Website
      </span>
    </div>
  );

  const addSourceComponent = () => {
    const {values: formValues, errors: formErrors} = selectedSourceFormData;

    // Validation functions
    const __validateSource = (value = null) => {
      const fValue = value !== null ? value : formValues['reviewSourceId'];
      if (!__required(fValue)) {
        return 'Please select a source';
      }
      return null;
    };
    const __validateWebsite = (value = null) => {
      const fValue = value !== null ? value : formValues['url'];

      if (!__required(fValue)) {
        return 'Please enter a URL';
      }
      if (fValue && !__isValidUrl(fValue)) {
        return 'Invalid URL';
      }
      return null;
    };

    const __validateForm = () => {
      const validSource = !__validateSource();
      return validSource;
    };

    const validSocialConnection = value => {
      let status = true;
      const filterList = _.filter(reviewSourceList, data => {
        return data.id === value;
      });
      if (filterList.length > 0) {
        let errorMsg = '';
        if (filterList[0].title === 'Facebook' && !fbConnected) {
          status = false;
          errorMsg =
            'Facebook reviews are only fetched after you connect your facebook account. Proceed to Connect Social section for it.';
        }
        if (filterList[0].title === 'Google' && !googleConnected) {
          status = false;
          errorMsg =
            'Google reviews can only be replied to after you connect to Google account. Proceed to Connect Social section for it.';
        }
        if (!status) {
          handleError({
            message: errorMsg,
          });
        }
      }
      return status;
    };

    const changeHandler = event => {
      const {name, value} = event.target;
      let errorObject = {
        [name]: null,
      };
      switch (name) {
        case 'reviewSourceId':
          errorObject = {
            [name]: __validateSource(value),
          };
          break;
        case 'url':
          errorObject = {
            [name]: __validateWebsite(value),
          };
          break;
        default:
          break;
      }
      if (validSocialConnection(value)) {
        setSourceFormData({
          ...selectedSourceFormData,
          values: {
            ...selectedSourceFormData.values,
            [name]: value,
          },
          errors: {
            ...selectedSourceFormData.errors,
            ...errorObject,
          },
        });
      }
    };

    const getGeneralInputProps = controlName => {
      return {
        onChange: changeHandler,
        onBlur: changeHandler,
        error: formErrors[controlName],
        value: formValues[controlName],
        name: controlName,
      };
    };
    const isValidForm = __validateForm();
    let exampleURL = '';
    let reviewSourceId = _.get(selectedSourceFormData, 'values.reviewSourceId', '');
    if (_.get(selectedSourceFormData, 'values.reviewSourceId', '') !== '') {
      const filterList = _.filter(reviewSourceList, data => {
        return data.id === reviewSourceId;
      });
      if (filterList.length > 0) {
        exampleURL = `Example URL: ${filterList[0].exampleUrl}`;
      }
    }
    let filterReviewSourceList = _.filter(reviewSourceList, data => {
      return data.isBirdEyeSource;
    });
    filterReviewSourceList = filterReviewSourceList.filter((d) => d.slug !== 'facebook')
    return (
      <div className="add-website-form">
        {/* <p style={{fontSize:"17px"}}>Before adding the Google or Facebook review url, please go to the 'Connect Social' tab above and connect your Google or Facebook Account.  Only one provider can be connected to each Google or Facebook page.</p> */}
        <Select
          {...getGeneralInputProps('reviewSourceId')}
          labelIndex="title"
          valueIndex="id"
          options={filterReviewSourceList}
          label="Select Source"
        />
        <Input
          {...getGeneralInputProps('url')}
          placeholder=" Your page URL on selected source"
          label="Enter URL"
        />
        <div className="add-website-example">{exampleURL}</div>
        <div className="add-site-actions">
          <CircleButton
            btnType="secondaryButton"
            onClick={cancelAddEdit}
            containerClass="cancel-btn">
            Cancel
          </CircleButton>
          <CircleButton disabled={!isValidForm} onClick={handleSaveSource}>
            Save
          </CircleButton>
        </div>
      </div>
    );
  };

  const getActiveComponent = () => {
    if (selectedSourceFormData) {
      return addSourceComponent();
    } else {
      return (
        <>
          <ReviewSourceList
            sourceList={sourceList}
            onSourceSelect={handleSourceSelect}
            onDelete={handleDeleteSource}
            onRefresh={handleRefreshReviews}
          />
          {sourceList.length === 0 && noSourceComponent}
        </>
      );
    }
  };
  return (
    <div className="ConnectReviews">
      <div className="heading">
        <div className="title">Add Reviews to kaly </div>
        <span>
          {!selectedSourceFormData && (
            <button className="refresh-btn" onClick={() => getAndSetUserReviewSource()}>
              Refresh
            </button>
          )}
          {!selectedSourceFormData && (
            <button className="addwebsite-btn" onClick={handleAddWebsite}>
              Add Website
            </button>
          )}
        </span>
      </div>
      <div className="ConnectReviews-content">{getActiveComponent()}</div>
    </div>
  );
};

export default ConnectReviews;
