import React, {useState} from 'react';
import images from 'data/images';

import {Link, useHistory} from 'react-router-dom';

import './Differentiateyourpractice.css';
import Divider from 'components/Divider';
import config from 'config/config';

const Differentiateyourpractice = props => {
  const history = useHistory();
  const {scrollToJoinkalypsys, scrollToTeam} = props;
  return (
    <div className="Differentiateyourpractice">
      <div className="container">
        <h3 className="heading">Differentiate your practice</h3>
        <p className="content">
          Showcase your practice’s unique offerings- including the conditions, treatments, and style
          of care you specialize in- to ensure the right patients find you first.
        </p>
        <img
          className="doctorprofiledashboard"
          src={images.doctorprofileimg}
          alt={'doctorprofileimg'}
        />
        <h3 style={{marginTop: '80px', marginBottom: '40px'}} className="heading">
          Taking your practice in a great new direction!
        </h3>
        {/* <img className="googleLogo" src={images.googleLogo} alt={'doctorprofileimg'} /> */}
        <div className="googleLogo-div">
          <img src={images.logosymbol} alt={'kaly'} />
          <img className="healthgrades-svg" src={images.healthgrades} alt={'healthgrades'} />
          <img className="google-svg" src={images.google} alt={'google'} />
        </div>
        <p className="Strengthen">Strengthen your reputation</p>
        <p className="Ensureyouronline">
          Ensure your online presence shines with the ability to showcase and respond to reviews
          from multiple platforms all in one place. (Like Google, Healthgrades, and more!)
        </p>

        {/* <h4 className="Acommitment">A commitment to fair pricing</h4>
        <div className="price-per-month">
          <h5 className="permonth">$299 per month</h5>
          <p className="discription">
            Just one flat, monthly subscription fee for unlimited patient bookings, telemedicine,
            and reputation management. It’s not just better economics, it’s better ethics too!
          </p>
          <div className="discription2box">
            <p className="discription">
              Compare to our competitors’ $110 per booking fee which could add up to THOUSANDS of
              dollars per month
            </p>
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${config.APP_DOMAIN}pricing`}
            className="listyour-practice">
            Learn more
          </a>
        </div> */}
        <div className="TakingPoint-parent-professional">
          <div className="TakingPoint  professional">
            <span className="images">
              <img alt="" src={images.experienceDoctors} className="experience-dc" />
            </span>
            <div className="h3">Designed by doctors</div>
            <div className="paragraph">
              Kaly was built by practicing doctors with the firsthand experience and dedication
              needed to meet the ever-evolving needs of the medical community.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Differentiateyourpractice;
