import React, {useContext, useEffect, useState} from 'react';
import RootContext from 'context/RootContext';
import images from 'data/images';
import moment from 'moment';
import adminService from 'services/admin';
import _ from 'lodash';
import './ReviewStatus.css';

const {getProviderReviewStatus} = adminService;

const ReviewStatus = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const [data, setData] = useState({});

  useEffect(() => {
    getReviewStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReviewStatus = async () => {
    const {id} = props;
    let response = await getProviderReviewStatus(id);
    const {data} = response;
    if (data) {
      setData(data);
    }
  };

  const closeModal = () => {
    setGlobal('modal', {showModal: false});
  };

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="" />
    </span>
  );

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">Review Status : {props.name}</div>
      <button className="btn btn-refresh" onClick={getReviewStatus}>
        Refresh
      </button>
      {crossIconComponent}
    </div>
  );

  const formatedDate = date => {
    return moment.utc(date).local().format('MMM DD, YYYY hh:mm A');
  };

  const getStatusText = status => {
    switch (status) {
      case 0:
        return <span className="pending">(pending)</span>;
      case 1:
        return <span className="approved">(approved)</span>;
      case 2:
        return <span className="rejected">(rejected)</span>;
      default:
        return '';
    }
  };

  return (
    <div className="ReviewStatus no-select">
      {dialogHeader}
      <div className="dailog-body">
        <div className="heading1 total">
          Total Reviews: {_.get(data, 'totalReview', 0)}
          {data.lastAddedReview && (
            <div className="h3">Last review fetched at {formatedDate(data.lastAddedReview)}</div>
          )}
        </div>
        {_.map(_.get(data, 'userReviewSourceList', []), (item, index) => (
          <div className="review-source" key={index}>
            <div
              className="heading2 link"
              onClick={() => window.open(item.url, '_blank')}
              title={item.url}>
              {_.get(item, 'reviewSource.title', '')} {getStatusText(item.status)}
            </div>
            <div className="h3">Source added at {formatedDate(item.createdAt)}</div>
            {item.status === 0 && (
              <>
                <button
                  className="btn btn-approve"
                  onClick={() => window.open(_.get(item, 'approveUrl', ''), '_blank')}>
                  Approve
                </button>
                <button
                  className="btn btn-reject"
                  onClick={() => window.open(_.get(item, 'rejectUrl', ''), '_blank')}>
                  Reject
                </button>
              </>
            )}
          </div>
        ))}
        {_.get(data, 'userReviewSourceList', []).length === 0 && (
          <div className="review-source">Provider has not added any review source yet</div>
        )}
      </div>
    </div>
  );
};

export default ReviewStatus;
