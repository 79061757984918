import React, {useEffect, useContext, useCallback, useState} from 'react';
import ProviderOverview from './ProviderOverview';
import ProviderInformation from './ProviderInformation';
import ProviderPhotos from './ProviderPhotos';
import ProviderCare from './ProviderCare';
import helper from 'data/helper';
import RootContext from 'context/RootContext';
import masterService from 'services/master';
import images from 'data/images';
import './ProviderProfile.css';
import _ from 'lodash';
import ProviderAccount from 'containers/Admin/Providers/ProviderAccount';
import ProviderSeoLink from './ProviderSeoLink';

const {getSuffixString} = helper;
const {
  getAllCertifications,
  getAllSuffixes,
  getAllSpecialties,
  getAllPains,
  getAllProcedures,
  getAllVisitReasons,
  getAllLanguages,
} = masterService;

const ProviderProfile = props => {
  const rootContext = useContext(RootContext);
  const {
    certifications: certificationList,
    suffixes: suffixList,
    specialties: specialtyList,
    pains: painList,
    procedures: procedureList,
    visitReasons: visitReasonList,
    languages: languageList,
  } = rootContext;
  const {setGlobal} = rootContext;
  const {providerId, getProviderData} = props;
  useEffect(() => {
    getCertifications();
    getLanguages();
    getSuffixes();
    getSpecialities();
    getPains();
    getProcedures();
    getVisitReasons();
    getProviderData(providerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isDisabledOverview, setDisabledOverview] = useState(false);
  const [isDisabledAdditionalInfo, setDisabledAdditionalInfo] = useState(false);
  const [isDisabledCare, setDisabledCare] = useState(false);
  const [changedSection, setChangedSection] = useState(false);
  const errors = {};
  const [formData, setFormData] = useState({
    values: {},
    errors,
  });
  const [infoFormData, setInfoFormData] = useState({
    values: {},
    errors,
  });

  useEffect(() => {
    if (props.user) {
      setFormData(prevState => {
        return {
          ...prevState,
          values: {
            professionalIntroduction,
            gender,
            hasPatientsAccepted,
            npi,
            certifications,
            suffixes,
          },
          errors: {},
        };
      });
      setInfoFormData(prevState => {
        return {
          ...prevState,
          values: {
            educationGraduateSchool,
            educationUndergraduateSchool,
            trainings,
            languages,
            hospitalAffiliations,
            boardCertifications,
            undergraduateSchoolStatus,
            graduateSchoolStatus,
            trainingStatus,
            hospitalAffiliationStatus,
          },
          errors: {},
        };
      });
    }
  }, [props.user]);

  useEffect(() => {
    if (changedSection === 'care') {
      setDisabledOverview(true);
      setDisabledAdditionalInfo(true);
    } else if (changedSection === 'info') {
      setDisabledOverview(true);
      setDisabledCare(true);
    } else if (changedSection === 'overview') {
      setDisabledAdditionalInfo(true);
      setDisabledCare(true);
    } else {
      setDisabledOverview(false);
      setDisabledAdditionalInfo(false);
      setDisabledCare(false);
    }
  }, [changedSection]);

  // get and set certifications to global store
  const getCertifications = async () => {
    if (!certificationList) {
      const response = await getAllCertifications().catch(onError);
      if (response) {
        const {data} = response;
        setGlobal('certifications', data);
      }
    }
  };
  // get and set languages to global store
  const getLanguages = async () => {
    if (!languageList) {
      const response = await getAllLanguages().catch(onError);
      if (response) {
        const {data} = response;
        setGlobal('languages', data);
      }
    }
  };
  // get and set suffixes to global store
  const getSuffixes = async () => {
    if (!suffixList) {
      const response = await getAllSuffixes().catch(onError);
      if (response) {
        const {data} = response;
        setGlobal('suffixes', data);
      }
    }
  };
  // get and set specialties to global store
  const getSpecialities = async () => {
    if (!specialtyList) {
      const response = await getAllSpecialties().catch(onError);
      if (response) {
        const {data} = response;
        setGlobal('specialties', data);
      }
    }
  };
  // get and set painList to global store
  const getPains = async () => {
    if (!painList) {
      const response = await getAllPains().catch(onError);
      if (response) {
        const {data} = response;
        setGlobal('pains', data);
      }
    }
  };
  // get and set painList to global store
  const getProcedures = async () => {
    if (!procedureList) {
      const response = await getAllProcedures().catch(onError);
      if (response) {
        const {data} = response;
        setGlobal('procedures', data);
      }
    }
  };
  // get and set painList to global store
  const getVisitReasons = async () => {
    if (!visitReasonList) {
      const response = await getAllVisitReasons().catch(onError);
      if (response) {
        const {data} = response;
        setGlobal('visitReasons', data);
      }
    }
  };

  const {
    user,
    onClearProviderSelection,
    onProfileImageChange,
    onError,
    onAdditionalPhotosUpload,
    onAdditionalPhotosRemove,
    onAdditionalVideoUpload,
    onAdditionalVideoRemove,
    onSaveOverView,
    onSaveAdditionalInfo,
    onCommonDataSave,
    onSaveVisitReasons,
    onSaveProviderAccount,
    isAdmin,
    currentUserId,
    onHandleSaveSeoLink
  } = props;
  if (!user) {
    return null;
  }
  const {
    firstName,
    lastName,
    profileImageUrl,
    gender,
    languages = [],
    provider = {},
    email = '',
    pendingNpiNumber=""
  } = user;
  const {
    providerPhotos = [],
    providerVideos = [],
    professionalIntroduction,
    hasPatientsAccepted,
    npi,
    suffixes = [],
  } = provider;
  const {information = []} = provider;
  const educationGraduateData = information.filter(info => info.typeId === 1);
  const educationGraduateSchool =
    educationGraduateData.length > 0 ? _.get(educationGraduateData[0], 'information', '') : '';
  const graduateSchoolStatus = _.get(educationGraduateData[0], 'statusId', '');
  const educationUndergraduateData = information.filter(info => info.typeId === 2);
  const educationUndergraduateSchool =
    educationUndergraduateData.length > 0
      ? _.get(educationUndergraduateData[0], 'information', '')
      : '';
  const undergraduateSchoolStatus = _.get(educationUndergraduateData[0], 'statusId', '');
  const trainingData = information.filter(info => info.typeId === 3);
  const trainings = trainingData.map(training => training.information);
  const trainingStatus = trainingData.map(training => training.statusId);
  const hospitalAffiliationsData = information.filter(info => info.typeId === 4);
  const hospitalAffiliations = hospitalAffiliationsData.map(affiliation => affiliation.information);
  const hospitalAffiliationStatus = hospitalAffiliationsData.map(affiliation => affiliation.statusId);
  const certifications = [];
  const boardCertifications = _.get(provider, 'certifications', []);
  const {specialties = []} = provider;
  const {visitReasons = [], newAppointmentTime, existingAppointmentTime} = provider;
  const {pains: conditions = []} = provider;
  const {procedures = []} = provider;
  const currentProviderName = `${firstName} ${lastName}${getSuffixString(suffixes)}`;

  const photosComponentProps = {
    data: {
      profileImageUrl,
      providerPhotos,
      providerVideos,
      name: currentProviderName,
    },
    backToProviderList: onClearProviderSelection,
    onError: onError,
    onProfileImageChange: onProfileImageChange,
    onAdditionalPhotosUpload,
    onAdditionalPhotosRemove,
    onAdditionalVideoUpload,
    onAdditionalVideoRemove,
    isAdmin,
    currentUserId,
    currentProviderId: providerId,
  };
  const accountComponentProps = {
    data: {
      firstName,
      lastName,
      email,
    },
    onSaveProviderAccount: onSaveProviderAccount,
  };
  const overviewComponentProps = {
    data: {
      professionalIntroduction,
      gender,
      hasPatientsAccepted,
      npi,
      certifications,
      suffixes,
      pendingNpiNumber,
      npiNumberFlag:provider.npiNumberFlag,
      seoPage:provider.seoPage
    },
    options: {
      certificationList,
      suffixList,
    },
    onError: onError,
    onSaveOverView: onSaveOverView,
    setChangedSection: setChangedSection,
    formData: formData,
    setFormData: setFormData,
    isAdmin:isAdmin
  };
  const additionalInfoComponentProps = {
    data: {
      educationGraduateSchool,
      educationUndergraduateSchool,
      trainings,
      languages,
      hospitalAffiliations,
      boardCertifications,
      undergraduateSchoolStatus,
      graduateSchoolStatus,
      trainingStatus,
      hospitalAffiliationStatus,
    },
    options: {
      certificationList,
      languageList,
    },
    onError: onError,
    onSaveAdditionalInfo: onSaveAdditionalInfo,
    setChangedSection,
    infoFormData,
    setInfoFormData,
  };
  const rightsideSetionComponentProps = {
    data: {
      specialties,
      visitReasons,
      newAppointmentTime,
      existingAppointmentTime,
      conditions,
      procedures,
      currentProviderName,
    },
    options: {
      specialtyList,
      painList,
      procedureList,
      visitReasonList,
    },
    onCommonDataSave,
    onSaveVisitReasons,
    isAdmin,
    currentProviderId: providerId,
    setChangedSection,
  };
  const seoLinkProps = {
    seoPage:provider.seoPage,
    onFormSubmit:onHandleSaveSeoLink,
    hasPermissionToEdit:true
  }
  
  if (
    !certificationList ||
    !suffixList ||
    !specialtyList ||
    !painList ||
    !procedureList ||
    !visitReasonList ||
    !languageList
  ) {
    return null;
  }

  return (
    <div className="ProviderProfile">
      <div className="setting-approvalcard">
        <div className="heading3">Profile Approval</div>
        <span className="sub-text">Kaly will review and approve updated items.</span>
        <div className="approval-action">
          <span className="Pending">
            <img src={images.minus} alt="" />
            Pending
          </span>
          <span className="Rejected">
            <img src={images.closeError} alt="" />
            Rejected
          </span>
        </div>
      </div>
      <div className="back-provider" onClick={onClearProviderSelection}>
        <span className="left-arrow">
          <img src={images.arrowLeft} alt="left-arrow" />
        </span>
        Back to All Providers
      </div>
      <div className="profile-section Provider-TabViewEach">
        <div className="middlepanel">
          <ProviderPhotos {...photosComponentProps} />
          {isAdmin && <ProviderAccount {...accountComponentProps} />}
          <div className={isDisabledOverview ? 'disabled-section' : ''}>
            <ProviderOverview {...overviewComponentProps} />
          </div>
          <div className={isDisabledAdditionalInfo ? 'disabled-section' : ''}>
            <ProviderInformation {...additionalInfoComponentProps} />
          </div>
          <div className={isDisabledCare ? 'disabled-section' : ''}>
            <ProviderCare {...rightsideSetionComponentProps} />
          </div>
          {isAdmin &&(
          <div >
            <ProviderSeoLink {...seoLinkProps} />
          </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProviderProfile);
