import React, { Component } from 'react';
import _ from 'lodash';
import Layout from 'layout/AfterAuth';
import RootContext from 'context/RootContext';
import SubHeader from 'components/AfterAuth/SubHeader';
import AccountTab from 'components/AfterAuth/Settings/AccountTab';
import PracticeTab from 'components/AfterAuth/Settings/PracticeTab';
import ProvidersTab from 'components/AfterAuth/Settings/ProvidersTab';
import InsuranceTab from 'components/AfterAuth/Settings/InsuranceTab';
import userService from 'services/user';
import practiceService from 'services/practice';
import modalConfig from 'modals/modalConfig';
import AuthContext from 'context/AuthContext';
import rolePermission from 'data/rolePermission';
import insuranceService from 'services/insurance';
import masterService from 'services/master';
import providerData from 'data/providerData';
import StaffTab from 'components/AfterAuth/Settings/StaffTab';
import WidgetTab from 'components/AfterAuth/Settings/WidgetTab';
import UploadFiles from 'components/AfterAuth/Settings/uploadFiles'
import './Settings.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import constants from 'data/constants';
import BillingTab from 'components/AfterAuth/Settings/BillingTab';
import config from 'config/config';

const { helperFunctions, permissionList } = rolePermission;
const { getAllMyRoleIds, hasPermission } = helperFunctions;
const { DocumentEmailId } = constants;
const { getMyPracticeDetail, savePracticeOverview, saveLocation, deleteLocation } = practiceService;
const {
  requestEmailChange,
  changePassword,
  updateStaffStatus,
  deleteStaff,
  getProviderByProviderId,
  updateProviderOverview,
  updateProviderAdditionalInfo,
  updateProviderCommonData,
  updateProviderVisitReasons,
  getAllMyPracticeStaff,
  inviteToBeStaff,
} = userService;
const { getPracticeInsurancePlans, updatePracticeInsurancePlans } = insuranceService;
const { getAllInsurancePlan } = masterService;
const { modalFunctions } = modalConfig;
const { successModalData, errorModalData, infoModalData } = modalFunctions;
const { providerFunctions } = providerData;
const { setViewUserId } = providerFunctions;
const { Analytics } = analyticsData;

class Settings extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {
    activeTab: null,
    invitedUserList: null,
    practiceInsurancePlanList: null,
    practiceStaffMemberList: null,
  };

  tabs = [
    { label: 'Account', tab: 'account' },
    { label: 'Practice', tab: 'practice' },
    { label: 'Providers', tab: 'providers' },
    { label: 'Staff', tab: 'staff' },
    { label: 'Insurance', tab: 'insurance' },
    { label: 'Widget', tab: 'widget' },
    { label: 'Upload Files', tab: 'uploadfiles' },
    //====| START: Hide Stripe Code |====
    { label: 'Billing', tab: 'Billing' },
    //====| END: Hide Stripe Code |====
  ];

  componentDidMount() {
    this.changeViewUser();
    const tab = this.getTabName(this.props);
    this.getAndSetActiveTab(tab);
  }

  componentDidUpdate(prevProps) {
    const tab = this.getTabName(this.props);
    const prevTab = this.getTabName(prevProps);
    if (tab !== prevTab) {
      this.getAndSetActiveTab(tab);
    }
  }

  changeViewUser = () => {
    const { auth, setAuthState } = this.context;
    const newViewUserId = _.get(auth, 'id', '');
    if (newViewUserId) {
      setAuthState(prevState => {
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            viewUserId: newViewUserId,
          },
        };
      });
    }
  };

  getTabName = props => {
    const query = new URLSearchParams(props.location.search);
    const tab = query.get('tab') || this.tabs[0].tab;
    return tab;
  };

  getAndSetActiveTab = async tab => {
    const tabObj = _.find(this.tabs, { tab });
    if (!tabObj) {
      tab = this.tabs[0].tab;
    }
    switch (tab) {
      case 'practice':
        await this.getAndSetPracticeData();
        break;
      case 'providers':
        break;
      case 'staff':
        await this.getAndSetStaffTabData();
        break;
      case 'insurance':
        await this.getAndSetInsuranceTabData();
        break;
      default:
        break;
    }
    this.setState({
      activeTab: tab,
    });
  };

  _updateContextPracticeData = obj => {
    const { setAuthState } = this.context;
    setAuthState(prevState => {
      return {
        ...prevState,
        auth: {
          ...prevState.auth,
          practice: {
            ...prevState.auth.practice,
            ...obj,
          },
        },
      };
    });
  };

  getAndSetPracticeData = async () => {
    const response = await getMyPracticeDetail().catch(this.handleError);
    if (response) {
      const { data } = response;
      this._updateContextPracticeData(data);
    }
  };

  getAndSetPracticeInsuranceData = async () => {
    const practiceInsurancePlanListResponse = await getPracticeInsurancePlans().catch(
      this.handleError,
    );
    let updateObj = { practiceInsurancePlanList: [] };
    if (practiceInsurancePlanListResponse) {
      const { data } = practiceInsurancePlanListResponse;
      updateObj.practiceInsurancePlanList = data;
    }
    this.setState(updateObj);
  };

  getAndSetInsuranceTabData = async () => {
    const { setGlobal, insurancePlans } = this.rootContext;
    if (!insurancePlans) {
      const insurancePlansResponse = await getAllInsurancePlan().catch(this.handleError);
      const { data } = insurancePlansResponse;
      setGlobal('insurancePlans', data);
    }
    this.getAndSetPracticeInsuranceData();
  };

  setActiveTab = async activeTab => {
    const params = new URLSearchParams();
    params.append('tab', activeTab);
    this.props.history.push({
      pathname: '/settings',
      search: `?${params.toString()}`,
    });
  };

  handleDeleteLocation = async locationId => {
    if (locationId) {
      const response = await deleteLocation(locationId).catch(this.handleError);
      if (response) {
        const { message } = response;
        const { setGlobal } = this.rootContext;
        setGlobal(
          'modal',
          successModalData(message, {
            callBackOnClose: () => {
              this.getAndSetPracticeData();
            },
          }),
        );
        //this.getAndSetPracticeData();
      }
    }
  };

  handleSaveLocation = async locationData => {
    const response = await saveLocation(locationData).catch(this.handleError);
    if (response) {
      const { message } = response;
      const { setGlobal } = this.rootContext;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getAndSetPracticeData();
          },
        }),
      );
      //this.getAndSetPracticeData();
    }
  };

  handleFileChange = (name, URL) => {
    this._updateContextPracticeData({ [name]: URL });
  };

  handlePracticeOverviewSave = async practiceData => {
    const response = await savePracticeOverview(practiceData).catch(this.handleError);
    if (response) {
      this._updateContextPracticeData(practiceData);
    }
  };

  switchBackToOtherUser = providerId => {
    const { auth, setAuthState } = this.context;
    const practiceMembers = _.get(auth, 'practiceMembers', []);
    const viewUserId = _.get(auth, 'viewUserId');
    const selectedUser = practiceMembers.find(pm => {
      return pm.provider.id === providerId;
    });
    if (selectedUser && selectedUser.id === viewUserId) {
      setAuthState(prevState => {
        setViewUserId(auth, selectedUser.provider.id);
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            viewUserId: auth.viewUserId,
          },
        };
      });
    }
  };

  // staff functions start
  getAndSetStaffTabData = async () => {
    const staffMembersResponse = await getAllMyPracticeStaff().catch(this.handleError);
    let updateObj = {
      practiceStaffMemberList: [],
    };
    if (staffMembersResponse) {
      const { data: practiceStaffMemberList } = staffMembersResponse;
      updateObj.practiceStaffMemberList = practiceStaffMemberList;
    }
    this.setState(updateObj);
  };

  handleStaffStatusChange = async data => {
    const { setGlobal } = this.rootContext;
    const response = await updateStaffStatus(data).catch(this.handleError);
    if (response) {
      const { message } = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getAndSetStaffTabData();
          },
        }),
      );
    }
  };

  handleDeleteStaff = async staffId => {
    Nux.sendEvent(Analytics.Category.DoctorSettingStaff, Analytics.Actions.Click, 'removeStaff');
    const { setGlobal } = this.rootContext;
    const response = await deleteStaff(staffId).catch(this.handleError);
    if (response) {
      const { message } = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getAndSetStaffTabData();
          },
        }),
      );
    }
  };

  handleInviteToBeStaff = async (data, resetForm) => {
    Nux.sendEvent(Analytics.Category.DoctorSettingStaff, Analytics.Actions.Click, 'inviteStaff');
    const { setGlobal } = this.rootContext;
    const response = await inviteToBeStaff(data).catch(this.handleError);
    if (response) {
      const { message } = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            resetForm();
            this.getAndSetStaffTabData();
          },
        }),
      );
    }
  };
  // staff functions end

  handleSaveInsurancePlans = async data => {
    const { setGlobal } = this.rootContext;
    let errorMessage = null;
    const response = await updatePracticeInsurancePlans(data).catch(error => {
      const { message } = error;
      errorMessage = message;
      return null;
    });
    if (errorMessage) {
      return errorMessage;
    }
    if (response) {
      const { message } = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getAndSetPracticeInsuranceData();
          },
        }),
      );
    }
  };

  handleSaveVisitReasons = async (providerId, data) => {
    const { setGlobal } = this.rootContext;
    const response = await updateProviderVisitReasons(data).catch(this.handleError);
    if (response) {
      const { message } = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.handleGetProviderData(providerId);
          },
        }),
      );
    }
  };

  handleCommonProviderData = async (id, data) => {
    const { setGlobal } = this.rootContext;
    const response = await updateProviderCommonData(data).catch(this.handleError);
    if (response) {
      const message = `Certain credentials changes will require submitting supporting documentation, please submit documents to approval at ${DocumentEmailId}`;
      setGlobal(
        'modal',
        infoModalData(
          message,
          {
            callBackOnClose: () => {
              this.handleGetProviderData(id);
            },
          },
          'Share your documents',
        ),
      );
    }
  };

  handleSaveAdditionalInfo = async (providerId, data) => {
    const { setGlobal } = this.rootContext;
    const response = await updateProviderAdditionalInfo(data).catch(this.handleError);
    if (response) {
      const message = `Certain credentials changes will require submitting supporting documentation, please submit documents to approval at ${DocumentEmailId}`;
      setGlobal(
        'modal',
        infoModalData(
          message,
          {
            callBackOnClose: () => {
              this.handleGetProviderData(providerId);
            },
          },
          'Share your documents',
        ),
      );
    }
  };

  handleSaveOverView = async (providerId, data) => {
    const { setGlobal } = this.rootContext;
    const { setAuthState } = this.context;
    const { suffixes } = data;
    const response = await updateProviderOverview(data).catch(this.handleError);
    if (response) {
      const message = `Certain credentials changes will require submitting supporting documentation, please submit documents to approval at ${DocumentEmailId}`;
      setGlobal(
        'modal',
        infoModalData(
          message,
          {
            callBackOnClose: () => {
              this.handleGetProviderData(providerId);
              // update auth suffixes after Provider update.
              setAuthState(prevState => {
                const updatedPracticeMembers = [...prevState.auth.practiceMembers];
                const index = _.findIndex(updatedPracticeMembers, member => {
                  return member.provider.id === providerId;
                });
                updatedPracticeMembers[index] = {
                  ...updatedPracticeMembers[index],
                  suffixes,
                };
                return {
                  ...prevState,
                  auth: {
                    ...prevState.auth,
                    provider: { ...prevState.auth.provider, suffixes },
                    practiceMembers: updatedPracticeMembers,
                  },
                };
              });
            },
          },
          'Share your documents',
        ),
      );
    }
  };

  handleProfileUrlChange = async (providerId, newURL) => {
    const { setAuthState } = this.context;
    setAuthState(prevState => {
      const updatedPracticeMembers = [...prevState.auth.practiceMembers];
      const index = _.findIndex(updatedPracticeMembers, member => {
        return member.provider.id === providerId;
      });
      updatedPracticeMembers[index] = {
        ...updatedPracticeMembers[index],
        profileImageUrl: newURL,
      };
      return {
        ...prevState,
        auth: {
          ...prevState.auth,
          profileImageUrl: newURL,
          practiceMembers: updatedPracticeMembers,
        },
      };
    });
  };

  handleGetProviderData = async providerId => {
    const { setAuthState } = this.context;
    const response = await getProviderByProviderId(providerId).catch(this.handleError);
    if (response) {
      const { data } = response;
      setAuthState(prevState => {
        const updatedPracticeMembers = [...prevState.auth.practiceMembers];
        const index = _.findIndex(updatedPracticeMembers, user => {
          return user.provider.id === providerId;
        });
        updatedPracticeMembers[index] = data;
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            practiceMembers: updatedPracticeMembers,
          },
        };
      });
    }
  };

  handleAdditionalPhotosUpload = (providerId, uploadedFileArray) => {
    // append new uploaded files to providerPhotos
    const { setAuthState } = this.context;
    setAuthState(prevState => {
      const updatedPracticeMembers = [...prevState.auth.practiceMembers];
      const index = _.findIndex(updatedPracticeMembers, user => {
        return user.provider.id === providerId;
      });
      let updatedProviderPhotos = [
        ...updatedPracticeMembers[index].provider.providerPhotos,
        ...uploadedFileArray,
      ];
      updatedProviderPhotos = _.reverse(
        _.sortBy(updatedProviderPhotos, [
          photoObj => {
            return parseInt(photoObj.id);
          },
        ]),
      );
      updatedPracticeMembers[index] = {
        ...updatedPracticeMembers[index],
        provider: {
          ...updatedPracticeMembers[index].provider,
          providerPhotos: updatedProviderPhotos,
        },
      };
      return {
        ...prevState,
        auth: {
          ...prevState.auth,
          practiceMembers: updatedPracticeMembers,
        },
      };
    });
  };

  handleAdditionalPhotosRemove = (providerId, photoId) => {
    // remove file from providerPhotos
    const { setAuthState } = this.context;
    setAuthState(prevState => {
      const updatedPracticeMembers = [...prevState.auth.practiceMembers];
      const index = _.findIndex(updatedPracticeMembers, user => {
        return user.provider.id === providerId;
      });
      let updatedProviderPhotos = [...updatedPracticeMembers[index].provider.providerPhotos];
      updatedProviderPhotos = _.filter(updatedProviderPhotos, photoObj => photoObj.id !== photoId);
      updatedPracticeMembers[index] = {
        ...updatedPracticeMembers[index],
        provider: {
          ...updatedPracticeMembers[index].provider,
          providerPhotos: updatedProviderPhotos,
        },
      };
      return {
        ...prevState,
        auth: {
          ...prevState.auth,
          practiceMembers: updatedPracticeMembers,
        },
      };
    });
  };

  handleAdditionalVideoUpload = (providerId, uploadedFileArray) => {
    // append new uploaded files to provider videos
    const { setAuthState } = this.context;
    setAuthState(prevState => {
      const updatedPracticeMembers = [...prevState.auth.practiceMembers];
      const index = _.findIndex(updatedPracticeMembers, user => {
        return user.provider.id === providerId;
      });
      let updatedProviderVideo = [
        ...updatedPracticeMembers[index].provider.providerVideos,
        ...uploadedFileArray,
      ];
      updatedProviderVideo = _.reverse(
        _.sortBy(updatedProviderVideo, [
          photoObj => {
            return parseInt(photoObj.id);
          },
        ]),
      );
      updatedPracticeMembers[index] = {
        ...updatedPracticeMembers[index],
        provider: {
          ...updatedPracticeMembers[index].provider,
          providerVideos: updatedProviderVideo,
        },
      };
      return {
        ...prevState,
        auth: {
          ...prevState.auth,
          practiceMembers: updatedPracticeMembers,
        },
      };
    });
  };

  handleAdditionalVideoRemove = (providerId, videoId) => {
    // remove file from provider videos
    const { setAuthState } = this.context;
    setAuthState(prevState => {
      const updatedPracticeMembers = [...prevState.auth.practiceMembers];
      const index = _.findIndex(updatedPracticeMembers, user => {
        return user.provider.id === providerId;
      });
      let updatedProviderVideo = [...updatedPracticeMembers[index].provider.providerVideos];
      updatedProviderVideo = _.filter(updatedProviderVideo, photoObj => photoObj.id !== videoId);
      updatedPracticeMembers[index] = {
        ...updatedPracticeMembers[index],
        provider: {
          ...updatedPracticeMembers[index].provider,
          providerVideos: updatedProviderVideo,
        },
      };
      return {
        ...prevState,
        auth: {
          ...prevState.auth,
          practiceMembers: updatedPracticeMembers,
        },
      };
    });
  };

  renderActiveTab = () => {
    const { auth } = this.context;
    const { practice } = auth;
    const { activeTab, practiceInsurancePlanList, practiceStaffMemberList } = this.state;
    const roleIds = getAllMyRoleIds(auth);
    let activeTabComponent = null;
    switch (activeTab) {
      case 'account':
        activeTabComponent = (
          <AccountTab
            onChangePassword={this.handlePasswordChange}
            onChangeEmail={this.handleEmailChange}
          />
        );
        break;
      case 'practice':
        const hasPermissionToEdit = hasPermission(roleIds, permissionList.CAN_EDIT_PRACTICE);
        activeTabComponent = practice && (
          <>
            {!hasPermissionToEdit && (
              <div className="no-permission-text">
                You need to be account owner or provider to edit the practice
              </div>
            )}
            <PracticeTab
              onError={this.handleError}
              afterFileChange={this.handleFileChange}
              practice={practice}
              onPracticeOverviewSave={this.handlePracticeOverviewSave}
              saveLocation={this.handleSaveLocation}
              deleteLocation={this.handleDeleteLocation}
              hasPermissionToEdit={hasPermissionToEdit}
            />
          </>
        );
        break;
      case 'providers':
        activeTabComponent = practice && (
          <ProvidersTab
            switchBackToOtherUser={this.switchBackToOtherUser}
            loggedInProviderId={auth.provider.id}
            totalSeats={practice.seats}
            onProfileImageUrlUpdate={this.handleProfileUrlChange}
            onError={this.handleError}
            onAdditionalPhotosUpload={this.handleAdditionalPhotosUpload}
            onAdditionalPhotosRemove={this.handleAdditionalPhotosRemove}
            onAdditionalVideoUpload={this.handleAdditionalVideoUpload}
            onAdditionalVideoRemove={this.handleAdditionalVideoRemove}
            onSaveOverView={this.handleSaveOverView}
            onSaveAdditionalInfo={this.handleSaveAdditionalInfo}
            onCommonDataSave={this.handleCommonProviderData}
            onSaveVisitReasons={this.handleSaveVisitReasons}
            getProviderData={this.handleGetProviderData}
          />
        );
        break;
      case 'staff':
        activeTabComponent = practiceStaffMemberList && (
          <StaffTab
            loggedInProviderId={auth.provider.id}
            practiceStaffMemberList={practiceStaffMemberList}
            onStaffStatusChange={this.handleStaffStatusChange}
            onInviteToBeStaff={this.handleInviteToBeStaff}
            onDeleteStaff={this.handleDeleteStaff}
          />
        );
        break;
      case 'insurance':
        activeTabComponent = practiceInsurancePlanList && (
          <InsuranceTab
            practiceInsurancePlanList={practiceInsurancePlanList}
            onSaveInsurancePlans={this.handleSaveInsurancePlans}
          />
        );
        break;
      case 'widget':
        activeTabComponent = <WidgetTab practiceSeoUrl={null} />;
        break;
      case 'uploadfiles':
        activeTabComponent = <UploadFiles practiceSeoUrl={null} />;
        break;
      //====| START: Hide Stripe Code |====
      case 'Billing':
        activeTabComponent = <BillingTab onProviderClick={() => {
          let providerTab = this.tabs.find((t) => t.label === 'Providers')
          if (providerTab)
            this.setActiveTab(providerTab.tab)
        }
        } />;
        break;
      //====| END: Hide Stripe Code |====
      default:
        activeTabComponent = null;
        break;
    }
    return activeTabComponent;
  };

  handleError = error => {
    const { setGlobal } = this.rootContext;
    const { message } = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  handleEmailChange = async (data, resetForm) => {
    const { setGlobal } = this.rootContext;
    const response = await requestEmailChange(data).catch(this.handleError);
    if (response) {
      const { message } = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            resetForm();
          },
        }),
      );
    }
  };

  handlePasswordChange = async (data, resetForm) => {
    const { setGlobal } = this.rootContext;
    const response = await changePassword(data).catch(this.handleError);
    if (response) {
      const { message } = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            resetForm();
          },
        }),
      );
    }
  };

  render() {
    const { activeTab } = this.state;
    const { auth } = this.context;
    const practiceName = _.get(auth, 'practice.name');
    if (config.showStripe !== true) {
      let index = this.tabs.findIndex((t) => t.label === 'Billing');
      if (index >= 0)
        this.tabs.splice(index, 1)
    }
    if (auth && auth.roles && auth.roles.length > 0 && auth.roles[0].id === "5") {
      let index = this.tabs.findIndex((t) => t.tab === 'uploadfiles');
      if (index >= 0)
        this.tabs.splice(index, 1)
    }
    if (auth && auth.roles && auth.roles.length > 0 && auth.roles[0].id !== "3") {
      let index = this.tabs.findIndex((t) => t.label === 'Billing');
      if (index >= 0)
        this.tabs.splice(index, 1)
    }
    const tabHeaderList = (
      <ol className="tab-list">
        {this.tabs.map((tab, index) => {
          return (
            <li
              key={index}
              className={`tab-list-item ${tab.tab === activeTab ? 'tab-list-active' : ''}`}
              onClick={() => this.setActiveTab(tab.tab)}>
              {tab.label}
              <span className="review-count">{tab.count}</span>
            </li>
          );
        })}
      </ol>
    );
    return (
      <Layout>
        <RootContext.Consumer>
          {context => {
            this.rootContext = context;
            return null;
          }}
        </RootContext.Consumer>
        <div className="AfterAuthSettings">
          <SubHeader title="Settings" subtitle={practiceName} />
          <div className="setting-conettn">
            <div className="setting-tabs">
              {tabHeaderList}
              <div className="tab-content">{this.renderActiveTab()}</div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Settings;
