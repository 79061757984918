import React, {useState} from 'react';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import validators from 'data/validators';
import './PatientPassword.css';
import images from 'data/images';
import constants from 'data/constants';

const {__required, __password, __eq, _scrollToView} = validators;
const {DEFAULT_MESSAGES} = constants;
const PatientPassword = props => {
  const {onUpdatePassword} = props;
  const formConfig = {
    values: {password: '', newPassword: '', confirmPassword: ''},
    errors: {},
  };
  const [formData, setFormData] = useState({...formConfig});
  const {values: formValues, errors: formErrors} = formData;

  const resetForm = () => {
    setFormData({...formConfig});
  };

  // Validation functions
  const __validateCurrentPassword = (value = null) => {
    const fValue = value !== null ? value : formValues['password'];

    if (!__required(fValue)) {
      return 'Current password is required';
    }
    return null;
  };
  const __validateNewPassword = (value = null) => {
    const fValue = value !== null ? value : formValues['newPassword'];
    if (!__required(fValue)) {
      return 'New password is required';
    }
    if (!__password(fValue)) {
      return 'Invalid new password';
    }
    return null;
  };
  const __validateConfirmPassword = (value = null) => {
    const fValue = value !== null ? value : formValues['confirmPassword'];
    if (!__required(fValue)) {
      return 'Confirm password is required';
    }
    if (!__eq(fValue, formValues['newPassword'])) {
      return 'New password and confirm password must be same';
    }
    return null;
  };
  const __validateConfirmPasswordChangeNew = (value = null) => {
    const fValue = value !== null ? value : formValues['newPassword'];
    if (!__required(fValue)) {
      return 'Confirm password is required';
    }
    if (!__eq(fValue, formValues['confirmPassword'])) {
      return 'New password and confirm password must be same';
    }
    return null;
  };
  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'password':
        errorObject = {
          [name]: __validateCurrentPassword(value),
        };

        break;
      case 'newPassword':
        errorObject = {
          [name]: __validateNewPassword(value),
        };
        if (formValues['confirmPassword']) {
          errorObject = { ...errorObject, 'confirmPassword': __validateConfirmPasswordChangeNew(value) }
        }
        break;
      case 'confirmPassword':
        errorObject = {
          [name]: __validateConfirmPassword(value),
        };
        break;
      default:
        break;
    }
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const __validateForm = () => {
    const validCuurrentPassword = !__validateCurrentPassword();
    const validNewPassword = !__validateNewPassword();
    const validConfirmPassword = !__validateConfirmPassword();
    return validCuurrentPassword && validNewPassword && validConfirmPassword;
  };
  const isValidForm = __validateForm();

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const getPassowrdComponent = labelText => {
    return (
      <>
        {labelText}{' '}
        <span className="password-instruction-text">
          ({DEFAULT_MESSAGES.PASSWORD_INSTRUCTION_TEXT})
        </span>
      </>
    );
  };
  const handleForm = () => {
    if (!isValidForm) {
      let valError = ['password', 'newPassword', 'confirmPassword'];
      valError.forEach(name => {
        changeHandler({target: {name: name, value: formValues[name]}});
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    onUpdatePassword(formValues, resetForm);
  };

  return (
    <div className="PatientPassword">
      <div className="h3">Password</div>
      <div className="PatientPassword-inner">
        <form className="password-form">
          <Input
            {...getGeneralInputProps('password')}
            type="password"
            label="Current Password"
            icon={images.eyeView}
          />
          <Input
            {...getGeneralInputProps('newPassword')}
            type="password"
            label={getPassowrdComponent("New  Password")}
            icon={images.eyeView}
          />
          <Input
            {...getGeneralInputProps('confirmPassword')}
            type="password"
            label="Confirm Password"
            icon={images.eyeView}
          />
          <div className="patientPassword-button">
            <CircleButton onClick={handleForm}>Save</CircleButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PatientPassword;
