import React, {Component} from 'react';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import images from 'data/images';
import _ from 'lodash';
import './SpecialtyRequest.css';
import constants from 'data/constants';

const {DefaultDebounceMSec} = constants;
const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  searchText: '',
  pageLimit: 10,
  sortBy: 'createdAt',
  orderBy: 'desc',
  totalRecord: 0,
};
const {modalFunctions} = modalConfig;
const {errorModalData, confirmModalData} = modalFunctions;
const {getProviderChangeRequest, rejectProviderChangeRequest} = adminService;

class SpecialtyRequest extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount() {
    this.setSpecialtyRequest(false);
    const {updateContextRequestCountData} = this.props;
    updateContextRequestCountData();
  }

  setSpecialtyRequest = async (noLoad = false) => {
    const {activePage, pageLimit, searchText, sortBy, orderBy} = this.state;
    const res = await getProviderChangeRequest(
      'specialty',
      {
        page: activePage,
        limit: pageLimit,
        search: searchText,
        sortBy: sortBy,
        orderBy: orderBy,
      },
      noLoad,
    );
    this.setState({
      list: _.get(res, 'data.list', []),
      totalPage: _.get(res, 'data.totalPage', 0),
      totalRecord: _.get(res, 'data.totalRecord', 0),
    });
  };

  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.setSpecialtyRequest);
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };

  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.setSpecialtyRequest();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };

  handleSearch = () => {
    this.setState({activePage: 1}, this.setSpecialtyRequest);
  };

  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.setSpecialtyRequest);
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'firstName');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.setSpecialtyRequest,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.setSpecialtyRequest);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  getSpecialty = specialtyData => {
    const data = _.map(specialtyData, data => {
      return data.specialty;
    });
    return data.join(', ');
  };

  handleActionRequest = (providerId, specialtyId, isApprove) => {
    const {setGlobal} = this.context;
    const msg = isApprove ? 'approve' : 'reject';
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to ${msg} this specialty request?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmRejectRequest(providerId, specialtyId, isApprove);
          }
        },
      }),
    );
  };

  confirmRejectRequest = async (id, specialtyId, isApprove) => {
    const data = {
      providerId: id,
      updateId: specialtyId,
      status: isApprove,
    };
    const response = await rejectProviderChangeRequest('specialty', data).catch(this.handleError);
    if (response) {
      this.setSpecialtyRequest();
      const {updateContextRequestCountData} = this.props;
      updateContextRequestCountData();
    }
  };

  render() {
    const {list, totalPage, searchText, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    const requestListComponent = _.map(list, (specialty, index) => (
      <div className="row" key={index}>
        <div className="cell">
          {specialty.provider && specialty.provider.seoPageUrl && (
            <span
              className="link-span"
              onClick={() => window.open(specialty.provider.seoPageUrl, '_blank')}>
              {`${_.get(specialty.provider, 'firstName', '')} ${_.get(
                specialty.provider,
                'lastName',
                '',
              )}`}
            </span>
          )}
          {specialty.provider &&
            !specialty.provider.seoPageUrl &&
            `${_.get(specialty.provider, 'firstName', '')} ${_.get(
              specialty.provider,
              'lastName',
              '',
            )}`}
        </div>
        <div className="cell">{_.get(specialty.provider, 'email', '')}</div>
        <div className="cell">{_.get(specialty, 'specialtyName', '')}</div>
        <div className="cell">{specialty.createdAt && this.formatedDate(specialty.createdAt)}</div>
        <div className="cell edit-cell">
          <button
            className="btn-approve"
            onClick={() => this.handleActionRequest(specialty.provider.id, specialty.id, 1)}>
            Approve
          </button>
          <button
            className="btn-reject"
            onClick={() => this.handleActionRequest(specialty.provider.id, specialty.id, 0)}>
            Reject
          </button>
        </div>
      </div>
    ));
    const noRecordComponent = <div className="no-record">No Records found</div>;
    return (
      <div className="SpecialtyRequest">
        <div className="dashboard-panle">
          <div className="search">
            <div>
              <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
              {searchText && (
                <button className="btn-clear" onClick={this.handleClearSearch}>
                  <img src={images.close} alt="clear" />
                </button>
              )}
            </div>
            <button onClick={this.handleSearch}>Search</button>
          </div>
          <div className="dataCount">
            <span>{totalRecord} specialty requests found.</span>
          </div>
          <div className="table-overflow">
            <div className="rtable">
              <div className="heading">
                <div
                  className="cell pointer"
                  data-key="firstName"
                  onClick={this.handleSortingChange}>
                  Provider Name{this.renderShortIcon('firstName')}
                </div>
                <div className="cell pointer" data-key="email" onClick={this.handleSortingChange}>
                  Provider Email{this.renderShortIcon('email')}
                </div>
                <div
                  className="cell pointer"
                  data-key="specialty"
                  onClick={this.handleSortingChange}>
                  Specialty{this.renderShortIcon('specialty')}
                </div>
                <div
                  className="cell pointer"
                  data-key="createdAt"
                  onClick={this.handleSortingChange}>
                  Request Date{this.renderShortIcon('createdAt')}
                </div>
                <div className="cell edit-cell">Action</div>
              </div>
              {list.length > 0 ? requestListComponent : noRecordComponent}
            </div>
          </div>
          {totalPage > 0 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={activeIndex}
            />
          )}
        </div>
      </div>
    );
  }
}

export default SpecialtyRequest;
