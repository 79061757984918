const matchKalyData = () => {
  try {
    let kalyKey = localStorage.getItem('siteCred');
    if (kalyKey === siteCred) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

const testAndSetKalyData = value => {
  try {
    if (btoa(value) === siteCred) {
      localStorage.setItem('siteCred', btoa(value));
      return {result: true};
    } else {
      return {result: false, message: 'You have entered invalid key'};
    }
  } catch {
    return {result: false, message: 'Something went wrong, refresh the page and try again'};
  }
};

const siteCred = 'VGh1cnNkYXlAODMw';

export default {matchKalyData, testAndSetKalyData, siteCred};
