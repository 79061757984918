import React, {useState, useContext} from 'react';
import _ from 'lodash';
import {withRouter, Link} from 'react-router-dom';
import images from 'data/images';
import AuthContext from 'context/AuthContext';
import Overlay from 'components/Overlay';
import InvitePractice from 'components/AfterAuth/Dashboard/InvitePractice'
import './Header.css';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import config from 'config/config';

const Header = props => {
  const authContext = useContext(AuthContext);
  const rootContext = useContext(RootContext);
  const {updateAuthStatus, auth} = authContext;
  const {setGlobal,specialties} = rootContext;
  const { modalFunctions } = modalConfig;
  const { blankModalData } = modalFunctions;
  const firstName = _.get(auth, 'firstName', '');
  const lastName = _.get(auth, 'lastName', '');
  const email = _.get(auth, 'email', '');
  const profileImageUrl = _.get(auth, 'profileImageUrl', '');
  const roleName = _.get(auth, 'roles.0.role', 'Default');
  const providerData = _.get(auth, 'provider', {});
  const providerId = _.get(providerData, 'id', '');
  const [active, toggleActive] = useState(false);
  const [show, menuActive] = useState(false);
  const isStaffMember = roleName === 'Staff' ? true : false;

  const goToProfile = () => {
    if (providerId) {
      const params = new URLSearchParams();
      params.append('tab', 'providers');
      params.append('profile', providerId);
      props.history.push({
        pathname: '/settings',
        search: `?${params.toString()}`,
      });
    }
  };

  const goToWaitingRoom = () => {
    if (providerId) {
      props.history.push({
        pathname: '/doctor-waiting-room',
      });
    }
  };
const invite_practices=()=>{
  setGlobal(
    'modal',
    blankModalData({
      CustomComponent: InvitePractice,
      modalWrapperClass: 'mega-modal',
      customComponentProps: {
        data:{specialties:specialties},
        getProviderData:()=>{this.getProviderData(true,false)} 
      },
      callBackOnClose: () => {
       
       
      },
    }),
  );
  toggleActive(false)
}
  const rightHeaderComponent = (
    <div className="right-col">
      <div className="user">
        <span className="user-content">
          <div className={active ? 'show-menu' : ''}>
            {!isStaffMember && (
              <div className="user-pic">
                <img alt="" src={profileImageUrl || images.user} />
              </div>
            )}
            {isStaffMember && (
              <div className="staff-default-img">
                <img alt="" src={images.logosymbol} />
              </div>
            )}
            <div className="user-info">
              <div className="user-name">
                {firstName} {lastName}
              </div>
              <div className="user-post">{roleName}</div>
              <img
                onClick={() => {
                  toggleActive(!active);
                }}
                alt=""
                src={images.arrowPointer}
                className="arrowPointer"
              />
            </div>
            <div className="dropdown-menu">
              <span className="email" onClick={goToProfile}>
                <img alt="" src={images.user} /> My Profile
              </span>
              <span className="email" onClick={goToWaitingRoom}>
                <img alt="" src={images.waitingRoom} /> My Waiting Room
              </span>
              {config.showStripe && (
              <span className="email" onClick={invite_practices}>
                <img alt="" src={images.invite_icon} /> Invite New Practices, Earn $299!
              </span>
              )}
              {/* <span>Help</span> */}
              <span className="logout" onClick={() => updateAuthStatus(null)}>
                <img alt="logout" src={images.logoutGray} /> Logout
              </span>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
  return (
    <div className={`AfterAuthHeader ${show ? 'menu-active' : ''}`}>
      <Overlay show={active} onClick={() => toggleActive(!active)} />
      <div className="header-content">
        <div
          className="mobile-menu"
          onClick={() => {
            menuActive(!show);
          }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="logo">
          <Link to="/">
            <img alt="" src={images.logo} />
          </Link>
        </div>
        {rightHeaderComponent}
      </div>
    </div>
  );
};

export default withRouter(Header);
