const questionList = [
  {
    id: 4701,
    question: 'What type of dental procedure are you looking for to improve your smile?',
    optionType: 4,
    procedureOptionIds: [72, 82, 85, 88],
    questionGroupId: 'GOTO_LIST',
    descriptions: [],
  },
];
export default {
  questionList,
  config: {
    firstQuestionId: 4701,
    lastQuestionId: 4701,
    treeDepth: 1,
  },
};
