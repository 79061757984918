import React, {useState, useContext} from 'react';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import './EditCertification.css';
import SwitchToggle from 'components/FormControls/SwitchToggle';
import validators from 'data/validators';
import _ from 'lodash';
import adminService from 'services/admin';
import modalConfig from 'modals/modalConfig';

const {__required} = validators;
const {updateCertification, createCertification} = adminService;
const {modalFunctions} = modalConfig;
const {successModalData,errorModalData} = modalFunctions;

const EditCertification = props => {
  const {data} = props;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const certificationId = _.get(data, 'id', 0);

  const [formData, setFormData] = useState({
    values: {
      certification: _.get(data, 'certification', ''),
      status: _.get(data, 'status', 1) === 1,
    },
    errors: {serverError: null},
  });
  const {values: formValues, errors: formErrors} = formData;

  const __validateCertification = (value = null) => {
    const fValue = value !== null ? value : formValues['certification'];
    if (!__required(fValue)) {
      return 'Certification name is required';
    }
    return null;
  };

  const changeHandler = event => {
    let {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'certification':
        errorObject = {
          [name]: __validateCertification(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const closeModal = () => {
    setGlobal('modal', {showModal: false});
  };

  const __validateForm = () => {
    return !__validateCertification();
  };

  const isValidateForm = __validateForm();

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="Close" title="Close" />
    </span>
  );

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">
        {certificationId > 0
          ? `Update Certification : ${_.get(data, 'certification', '')}`
          : 'Add New Certification'}
      </div>
      {crossIconComponent}
    </div>
  );

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const {updateList} = props;
    let response = null;
    let reqObj = {
      certification: formValues['certification'],
      status: formValues['status'] ? 1 : 0,
    };
    if (certificationId > 0) {
      response = await updateCertification(certificationId, reqObj).catch(handleError);
    } else {
      response = await createCertification(reqObj).catch(handleError);
    }
    if (response) {
      const {message} = response;
      setGlobal('modal', successModalData(message));
      updateList();
    }
  };

  const renderInputElements = () => {
    return (
      <>
        <Input
          {...getGeneralInputProps('certification')}
          placeholder="Enter Certification Name"
          label="Certification"
        />
        <SwitchToggle {...getGeneralInputProps('status')} label="Status" />
      </>
    );
  };

  return (
    <div className="UpdateConfigModal no-select">
      <div className="modal-body-wrapper">
        {dialogHeader}
        <div className="dailog-body">
          <form onSubmit={handleSubmit}>{renderInputElements()}</form>
        </div>
        <div className="action-btn">
          <CircleButton onClick={closeModal} btnType="secondaryButton">
            Cancel
          </CircleButton>
          <CircleButton btnType="whiteButton" disabled={!isValidateForm} onClick={handleSubmit}>
            {certificationId > 0 ? 'Update' : 'Add'}
          </CircleButton>
        </div>
      </div>
    </div>
  );
};

export default EditCertification;
