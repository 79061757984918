import axiosInstance from 'config/axios';
//import helper from 'data/helper';
import _ from 'lodash';

const fileUpload = (file, uploadServerUrl, oldFile) => {
  const formData = new FormData();
  let params = ['file', file];
  const fileName = _.get(file, 'name');
  if (fileName) {
    params.push(fileName);
  }
  formData.append(...params); // It will be fn('file',file) or fn('file',file,fileName).
  return axiosInstance.post(`v1/${uploadServerUrl}`, formData);
};

const adminProviderLogoUpload = (file, practiceId) => {
  const formData = new FormData();
  let params = ['file', file];
  const fileName = _.get(file, 'name');
  if (fileName) {
    params.push(fileName);
  }
  formData.append(...params);
  return axiosInstance.post(`v1/admin/practice/upload/logo/${practiceId}`, formData);
};

const adminProviderRemoveLogo = practiceId => {
  const isDelete = true;
  return axiosInstance.post(`v1/admin/practice/upload/logo/${practiceId}/${isDelete}`, {});
};

const adminProviderFileUpload = (file, practiceId) => {
  const formData = new FormData();
  let params = ['file', file];
  const fileName = _.get(file, 'name');
  if (fileName) {
    params.push(fileName);
  }
  formData.append(...params);
  return axiosInstance.post(`v1/admin/practice/upload/form/${practiceId}`, formData);
};

const removeFileFromServer = (uploadServerUrl, oldFile) => {
  const isDelete = true;
  return axiosInstance.post(`v1/${uploadServerUrl}/${isDelete}`, {});
};

const uploadProvidePhotos = files => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('photos', files[i]);
  }
  return axiosInstance.post(`v1/provider/photos`, formData);
};
const removeProvidePhoto = photoId => {
  return axiosInstance.delete(`v1/provider/photo/${photoId}`);
};

const uploadProviderVideos = files => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('videos', files[i]);
  }
  return axiosInstance.post('v1/provider/videos', formData);
};

const removeProviderVideo = videoId => {
  return axiosInstance.delete(`v1/provider/video/${videoId}`);
};

const adminUploadProviderPhoto = (file, userId, isRemove) => {
  const formData = new FormData();
  if (file) {
    let params = ['file', file];
    const fileName = _.get(file, 'name');
    if (fileName) {
      params.push(fileName);
    }
    formData.append(...params);
  }
  return axiosInstance.post(`v1/admin/user/photo/${userId}/${isRemove}`, formData);
};

const adminUploadPhotos = (files, providerId) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('photos', files[i]);
  }
  return axiosInstance.post(`v1/admin/provider/photos/${providerId}`, formData);
};

const adminRemovePhotos = (providerId, photoId) => {
  return axiosInstance.delete(`v1/admin/provider/photo/${providerId}/${photoId}`);
};

const adminUploadVideos = (files, providerId) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('videos', files[i]);
  }
  return axiosInstance.post(`v1/admin/provider/videos/${providerId}`, formData);
};

const adminRemoveVideos = (providerId, videoId) => {
  return axiosInstance.delete(`v1/admin/provider/video/${providerId}/${videoId}`);
};
const PraticeRemoveForm = () => {
  return axiosInstance.post(`v1/practice/upload/form/true`);
  
};
export default {
  fileUpload,
  adminProviderLogoUpload,
  adminProviderRemoveLogo,
  adminProviderFileUpload,
  removeFileFromServer,
  uploadProvidePhotos,
  removeProvidePhoto,
  uploadProviderVideos,
  removeProviderVideo,
  adminUploadProviderPhoto,
  adminUploadPhotos,
  adminRemovePhotos,
  adminUploadVideos,
  adminRemoveVideos,
  PraticeRemoveForm
};
