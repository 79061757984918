import _ from 'lodash';
import moment from 'moment';
import allRegex from './regex';
// Regex
const {emailRegex, passwordRegex} = allRegex;

// validation functions
const __required = valueToCheck => valueToCheck && _.trim(valueToCheck) !== '';
const __isNumeric = valueToCheck => !_.isNaN(_.toNumber(valueToCheck));
const __eqLength = (valueToCheck, length) => _.isEqual(_.size(valueToCheck), length);
const __maxLength = (valueToCheck, length) => _.size(valueToCheck) <= length;
const __minLength = (valueToCheck, length) => _.size(valueToCheck) >= length;
const __phoneNumber = valueToCheck => {
  valueToCheck = valueToCheck.toString();
  return __required(valueToCheck) && __isNumeric(valueToCheck) && __eqLength(valueToCheck, 10);
};
const __email = valueToCheck => emailRegex.test(valueToCheck);
const __password = valueToCheck => passwordRegex.test(valueToCheck);
const __eq = (valueToCheck, valueToCheck2) => _.isEqual(valueToCheck, valueToCheck2);
const __isValidUrl = valueToCheck => valueToCheck.includes('.');
const __validDate = dateString => moment(dateString, 'YYYY-MM-DD', true).isValid();
const __validDateofBirthDate = dateString => {
  const dateObj = moment(dateString, 'YYYY-MM-DD', true);
  if (dateObj.isValid() && dateObj.isBefore(moment())) {
    return true;
  } else {
    return false;
  }
};
const __validDateofBirthDatelessthan300 = dateString => {
  const dateObj = moment(dateString, 'YYYY-MM-DD', true);
  var years = moment().diff(dateObj, 'years');
  if (years<300) {
    return true;
  } else {
    return false;
  }
};
const __isFile = input => 'File' in window && input instanceof File;
const _scrollToView = (className,withParent=false) => {
  let passClassNAme=!withParent?`.${className}`:className
  if (document.querySelector(passClassNAme)) {
    document
      .querySelector(passClassNAme)
      .parentElement.scrollIntoView({behavior: 'smooth', block: 'start'});
  }
};
const validators = {
  __required,
  __isNumeric,
  __eqLength,
  __phoneNumber,
  __email,
  __maxLength,
  __minLength,
  __password,
  __eq,
  __isValidUrl,
  __validDate,
  __validDateofBirthDate,
  __validDateofBirthDatelessthan300,
  __isFile,
  _scrollToView,
};
export default validators;
