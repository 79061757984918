import React, {Component} from 'react';
import _ from 'lodash';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import adminService from 'services/admin';
import AuthContext from 'context/AuthContext';
import ReactPaginate from 'react-paginate';
import constants from 'data/constants';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import ReviewStatus from './ReviewStatus';
import helper from 'data/helper';
import './Providers.css';
import images from 'data/images';
import selectOptions from 'data/selectOptions';
import Select from 'components/FormControls/Select';
import ViewDocumentFile from '../JoinUs/viewDocumentFile';
import momentHelper from 'data/moment/momentHelper';
const {bigDateTimeFormat}=momentHelper
const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  searchText: '',
  sortBy: 'createdAt',
  orderBy: 'desc',
  isEditProvider: false,
  editProviderId: '',
  editUserId: '',
  searchStatus: 'onboardeduser',
  searchUserType: 'all',
  totalRecord: 0,
};
const {confirmModalData, errorModalData, blankModalData} = modalConfig.modalFunctions;
const {getProviderSuffix} = helper;
const {DefaultDebounceMSec} = constants;
const {getProviderList,getInvitedProvidersList, updateUserStatus, getRequestCounts} = adminService;

class Inactiveproviders extends Component {
  static contextType = AuthContext;
  context = null;
  authContext=null;
  state = {...initialState};

  componentDidMount() {
   this.getProviderData(true, false);
  }

  componentWillMount = () => {
    this.props.history.replace({state: {...initialState}});
  };

  getSearchPractice = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const searchPractice = query.get('practice', '');
    if (searchPractice) {
      this.setState({searchText: searchPractice, searchUserType: 'provider'});
    }
  };

  getProviderData = async (isCheckSearch, noLoad = false) => {
    const {location} = this.props;
    // const isBackLink = _.get(location, 'state.isBackLink', false);
    // if (isBackLink) {
    //   this.getSearchedData(location);
    // } else {
      await this.getSearchPractice();
      const {
        activePage,
        searchText,
        sortBy: sort,
        orderBy: order,
        searchStatus: status,
        searchUserType,
      } = this.state;
      const response = await getInvitedProvidersList(
        {
          search: searchText,
          page: activePage,
          limit: 10,
          sortBy: sort,
          orderBy: order,
          status: status,
          userType: 'provider', // searchUserType === 'all' ? null : searchUserType,
        },
        noLoad,
      ).catch(this.handleError);
      if (response) {
        const {data} = response;
        this.setState({
          list: data.list,
          totalPage: data.totalPage,
          totalRecord: _.get(data, 'totalRecord', 0),
        });
      }
   // }
  };
  updateContextData = async () => {
    const requestCountData = await getRequestCounts().catch(() => null);
    if (requestCountData && requestCountData.data) {
      const {setAdminRequestCountState} = this.authContext;
      setAdminRequestCountState(prevState => {
        return {
          ...prevState,
          requestCount: {
            ...requestCountData.data,
          },
        };
      });
    }
  };
  getSearchedData = location => {
    const searchData = _.get(location, 'state', {
      activePage: 1,
      searchText: '',
      sortBy: 'practiceName',
      orderBy: 'asc',
      searchStatus: 'all',
      totalRecord: 0,
    });
    const {
      activePage,
      searchText,
      sortBy: sort,
      orderBy: order,
      searchStatus: status,
      list,
      totalPage,
      totalRecord,
    } = searchData;
    this.setState({
      list: list,
      totalPage: totalPage,
      activePage: activePage,
      searchText: searchText,
      sortBy: sort,
      orderBy: order,
      searchStatus: status,
      totalRecord: totalRecord,
    });
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.getProviderData);
  };
  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.getProviderData();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };
  handleSearch = () => {
    this.setState({activePage: 1}, this.getProviderData);
  };
  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.getProviderData);
  };

  viewReviewStatus = (name, id) => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ReviewStatus,
        customComponentProps: {name, id},
      }),
    );
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.getProviderData,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.getProviderData);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  ShowEditProvider = (providerId, userId) => {
    this.setState({isEditProvider: true, editProviderId: providerId, editUserId: userId});
    const params = new URLSearchParams();
    if (providerId) {
      params.append('provider', providerId);
    }
    if (userId) {
      params.append('user', userId);
    }
    this.props.history.push({
      pathname: '/admin/providerDetails',
      search: `?${params.toString()}`,
      state: {...this.state, isBackLink: true},
    });
  };

  ShowProviderReviews = (providerId, userId) => {
    this.setState({isEditProvider: true, editProviderId: providerId, editUserId: userId});
    const params = new URLSearchParams();
    if (providerId) {
      params.append('provider', providerId);
    }
    if (userId) {
      params.append('user', userId);
    }
    this.props.history.push({
      pathname: '/admin/provider-reviews',
      search: `?${params.toString()}`,
      state: {...this.state, isBackLink: true},
    });
  };

  handleStatusChange = event => {
    this.setState({searchStatus: event.target.value, activePage: 1}, this.getProviderData);
  };

  handleUserTypeChange = event => {
    this.setState({searchUserType: event.target.value, activePage: 1}, this.getProviderData);
  };

  confirmUpdateStatus = (userId, currentStatus, firstName, lastName) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    const statusTitle = currentStatus === 1 ? 'deactivate' : 'activate';
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to ${statusTitle} ${firstName} ${lastName}?`,
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.setProviderStatus(userId, newStatus);
          }
        },
      }),
    );
  };

  setProviderStatus = async (userId, userStatus) => {
    const response = await updateUserStatus(userId, {status: userStatus}).catch(this.handleError);
    if (response) {
      this.getProviderData();
      await this.updateContextData();
    }
  };

  // importProviders = () => {
  //   this.props.history.push({
  //     pathname: '/admin/import-provider',
  //   });
  // };

  render() {
    const title = this.props.name ? this.props.name : 'Active Providers';
    const {list, totalPage, searchText, activePage, isEditProvider, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    // wait for api data
    if (!list) {
      return null;
    }

    // list rows
    let tableOutput = '';

    let listingData = _.map(list, (provider, index) => {
      const {practice = []} = provider;
      return (
        <tr key={provider.id}>
         
          <td className="text-cap">            
             { `${provider.firstName}`}  
          </td>
          <td className="text-cap">            
             { `${provider.lastName}`}  
          </td>
          <td>{_.get(provider, 'email', '')}</td>
          <td>{_.get(provider, 'practice.name', '')}</td>
          <td>{bigDateTimeFormat(_.get(provider, 'createdAt', ''))}</td>
          
        </tr>
      );
    });
    if (list.length === 0) {
      tableOutput = <div className="no-record">No Records found</div>;
    } else {
      tableOutput = (
        <div className="table-overflow">
          <table className="admin-provider-list">
            <thead>
              <tr>
                {/* <td className="pointer" data-key="practiceName" onClick={this.handleSortingChange}>
                  Practice Name{this.renderShortIcon('practiceName')}
                </td> */}
                <td className="pointer" data-key="firstName" onClick={this.handleSortingChange}>
                  First Name{this.renderShortIcon('firstName')}
                </td>
                <td className="pointer" data-key="lastName" onClick={this.handleSortingChange}>
                  Last Name{this.renderShortIcon('lastName')}
                </td>
                <td className="pointer" data-key="email" onClick={this.handleSortingChange}>
                  Email{this.renderShortIcon('email')}
                </td>
                <td className="pointer" data-key="practiceName" onClick={this.handleSortingChange}>
                Practice{this.renderShortIcon('practiceName')}
                </td>
                <td className="pointer" data-key="createdAt" onClick={this.handleSortingChange}>
                  Invite At{this.renderShortIcon('createdAt')}
                </td>
                {/* <td className="pointer" data-key="role" onClick={this.handleSortingChange}>
                  Role{this.renderShortIcon('role')}
                </td>
                <td>Specialties</td>
                <td className="pointer" data-key="status">
                  Status */}
                  {/* {this.renderShortIcon('status')} */}
                {/* </td> 
                <td>Action</td>*/}
              </tr>
            </thead>
            <tbody>{listingData}</tbody>
          </table>
        </div>
      );
    }
    return (
      <Layout>
        <RootContext.Consumer>
          {context => {
            this.context = context;
          }}
        </RootContext.Consumer>
        <AuthContext.Consumer>
        {context => {
         this.authContext=context
        }}
      </AuthContext.Consumer>
        <div className="AdminProviders">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            {!isEditProvider && (
              <>
                <div className="search">
                  {/* <div>
                    <Select
                      options={selectOptions.providerFilterOption}
                      labelIndex="label"
                      valueIndex="value"
                      name="userType"
                      defaultOptionProp={{value: 'all', label: 'All Users'}}
                      isAllowDefaultOpen={true}
                      onChange={this.handleUserTypeChange}
                      value={this.state.searchUserType}
                    />
                  </div> */}
                  {!this.props.name && (
                    <div>
                      <Select
                        options={selectOptions.providerStatusOptions}
                        labelIndex="label"
                        valueIndex="value"
                        name="providerStatus"
                        defaultOptionProp={{value: 'onboardeduser', label: 'All Status'}}
                        isAllowDefaultOpen={true}
                        onChange={this.handleStatusChange}
                        value={this.state.searchStatus}
                      />
                    </div>
                  )}
                  <div>
                    <input
                      placeholder="Search"
                      value={searchText}
                      onChange={this.handleChange}></input>
                    {searchText && (
                      <button className="btn-clear" onClick={this.handleClearSearch}>
                        <img src={images.close} alt="clear" />
                      </button>
                    )}
                  </div>
                  <button onClick={this.handleSearch}>Search</button>
                </div>
                {/* {!this.props.name &&( */}
                {/* <button className="btn-add-new" onClick={this.importProviders}>
                  Import Providers
                </button> */}
              </>
            )}
            <div className="dataCount">
              <span>{totalRecord} providers found.</span>
            </div>
            {!isEditProvider && tableOutput}
            {!isEditProvider && totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Inactiveproviders;
