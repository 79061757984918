import React, {Component} from 'react';
import images from 'data/images';
import Input from 'components/FormControls/Input';
//import {Link} from 'react-router-dom';
import './ChatTab.css';
import NextVideoAppointment from '../NextVideoAppointment';
//import Chat from 'twilio-chat';
import moment from 'moment';
//import _ from 'lodash';
import constants from 'data/constants';
import momentHelper from 'data/moment/momentHelper';

const {VideoUserType} = constants;
const {utcToLocalMoment} = momentHelper;
const initialState = {
  messages: [],
  username: '',
  channel: null,
  text: '',
  media: '',
  errors: [],
  loading: null,
  values: {
    message: '',
  },
  current: '',
  upComingAppointment: {},
};

class ChatTab extends Component {
  state = {
    ...initialState,
    username: this.props.username,
    upComingAppointment: this.props.upComingAppointment,
  };

  componentDidMount() {
    this.showCount();
  }

  showCount = e => {
    const middleContent = document.getElementsByClassName('tabbing-scroll');
    const offsetmiddleContent = middleContent[0].offsetTop;
    const sidebarBottom = document.getElementsByClassName('sidebar-bottom');
    const sidebarBottomHeight = sidebarBottom[0].clientHeight;
    const height = offsetmiddleContent + sidebarBottomHeight;
    this.setState({height});
  };

  changeHandler = event => {
    const {name, value} = event.target;
    this.setState(prevState => {
      return {
        ...prevState,
        value: {
          ...prevState.filter,
          [name]: value,
        },
      };
    });
  };

  getGeneralInputProps = controlName => {
    const {values: formValues, errors: formErrors} = this.props;
    return {
      onChange: this.changeHandler,
      onBlur: this.changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  addError = error => {
    this.setState({
      errors: [...this.state.errors, error.message],
    });
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  getMsgComponent = (msgData, index) => {
    const msgComponent = msgData.type === 'media' && (
      <div className="file-box">
        <div className="file-img">
          {msgData && msgData.fileType && msgData.fileType.includes('image') ? (
            <img src={msgData.body} alt="file-img" />
          ) : (
            <img src={images.document} alt="file-img" />
          )}
        </div>
        <div className="file-detail">
          <div className="file-name">{msgData.fileName}</div>
          <div className="file-size">{this.formatBytes(msgData.fileSize)}</div>
          <a href={msgData.body} target="_blank" rel="noopener noreferrer" className="file-link">
            Open File
          </a>
        </div>
      </div>
    );

    const msgClass =
      msgData.author === this.props.username
        ? 'message-box outgoing-msg'
        : 'message-box incoming-msg';
    return (
      <div className={msgClass} key={index}>
        <div className="time-name">
          <span className="name">
            {msgData.author === this.props.username ? 'You' : msgData.author}
          </span>
          <span className="time">{moment(msgData.dateTime).format('h:mm a')}</span>
        </div>
        {msgData.type === 'media' ? (
          <div>{msgComponent}</div>
        ) : (
          <div className="msg">{msgData.body}</div>
        )}
      </div>
    );
  };

  render() {
    const {
      current = '',
      messages = [],
      upComingAppointment = {},
      setInputText,
      putMessage,
      checkFile,
      otherParticipantName,
      isChannelCreated,
      appointmentData,
    } = this.props;
    const appointmentStartDateTimeObject = utcToLocalMoment(
      appointmentData.appointmentStartDateTime,
    );
    const appointmentEndDateTimeObject = utcToLocalMoment(appointmentData.appointmentEndDateTime);
    const duration = moment
      .duration(appointmentEndDateTimeObject.diff(appointmentStartDateTimeObject))
      .asMinutes();
    const messageHistory = messages.map((msgData, index) =>
      msgData.type === 'system' ? (
        <div key={index} className="chat system-chat">
          {msgData.body}
        </div>
      ) : (
        this.getMsgComponent(msgData, index)
      ),
    );
    const patientAppointment = (
      <div className="patient-schedule">
        <div className="patient-schedule-inner">
          <img className="schedule-icon" src={images.scheduleIcon} alt="scheduleIcon" />
          <div className="date-time">
            {appointmentStartDateTimeObject.format('dddd, MMM DD [at] h:mm a')}
          </div>
          <div className="time-duration">{duration} min Video Visit</div>
        </div>
      </div>
    );
    return (
      <div className="ChatTab">
        {this.props.userType === VideoUserType.PATIENT && (
          <div className="patient-sm-schedule">{patientAppointment}</div>
        )}
        <div
          className="chat-details tabbing-scroll"
          style={{height: 'calc(100vh - ' + this.state.height + 'px)'}}>
          <div className="message-info">
            Please note, no documents, videos, or chat messages provided during Telemedicine are
            ever stored on Kaly.
          </div>
          {messageHistory}
        </div>
        <div className="sidebar-bottom">
          <div className="typemsg-box">
            <div className="typemsg-top">
              <span className="receiver-name">To: {otherParticipantName}</span>
              <div className="fileupload-input">
                <input type="file" onChange={e => checkFile(e)} />
                <span className="upload-doc">upload</span>
                <img src={images.uploadCloud3} alt="cloudIcom" />
              </div>
            </div>
            <form className="msg-area" onSubmit={e => putMessage(e)}>
              {isChannelCreated && (
                <>
                  <Input
                    value={current}
                    onChange={e => setInputText(e.target.value)}
                    type="text"
                    placeholder="Type Message here"
                    containerClass="msg-input"
                  />
                  <button className="btn-send-msg" type="submit">
                    <img src={images.sendMsgBlack} alt="send" />
                  </button>
                </>
              )}
              {!isChannelCreated && <span>Connecting..</span>}
            </form>
          </div>
          {this.props.userType === VideoUserType.PROVIDER && (
            <NextVideoAppointment
              showCount={this.showCount}
              upComingAppointment={upComingAppointment}
              upcomingAppointment={this.props.upcomingAppointment}
              nextId={this.props.nextId}
            />
          )}
          {this.props.userType === VideoUserType.PATIENT && patientAppointment}
        </div>
      </div>
    );
  }
}

export default ChatTab;
