import axiosInstance from 'config/axios';

const getSubscriptionDetails = () => {
  return axiosInstance.get(`v1/billing/subscription`);
};

const updatePayment = paymentId => {
  return axiosInstance.put(`v1/billing/payment-method`, {paymentMethodId: paymentId});
};
const updatePaymentWithProduct = data => {
  return axiosInstance.put(`v1/billing/updateSubscription`, data);
};
const getInvoice = () => {
  return axiosInstance.get(`v1/billing/invoices`);
};
const cancelSubscription = () => {
  return axiosInstance.post(`v1/billing/cancel`);
};
const reconcile = () => {
  return axiosInstance.post(`v1/billing/reconcile`);
};
const reactivate = () => {
  return axiosInstance.post(`v1/billing/reactivate`);
};
export default {
  getSubscriptionDetails,
  updatePayment,
  updatePaymentWithProduct,
  getInvoice,
  cancelSubscription,
  reconcile,
  reactivate,
};
