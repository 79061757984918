const questionList = [
  {
    id: 901,
    question: 'What type of procedure are you looking for?',
    // if optionType is not specified we will use default UI
    descriptions: [],
    options: [
      { nextQuestionId: 902, label: 'Non-invasive procedure (laser, sculpting)', shortLabel: 'Non-invasive procedure' },
      { nextQuestionId: 905, label: 'Minimally invasive cosmetic procedure such as an injection.', shortLabel: 'Minimally invasive' },
      { nextQuestionId: 908, label: 'Surgery', shortLabel: 'Surgery' },
    ],
  },
  {
    id: 902,
    question: 'What body part(s) are you looking for help with?',
    staticOptionIds: [18,19,20,21,22],
    optionType: 6,
    nextQuestionId: 903,
    multiColOption: true,
    descriptions: [],
  },
  {
    id: 903,
    question: 'When was the last time you had a procedure on the same area?',
    descriptions: [],
    options: [
      { nextQuestionId: 904, label: 'Never', shortLabel: 'Never' },
      { nextQuestionId: 904, label: 'In the last week', shortLabel: 'Last week' },
      { nextQuestionId: 904, label: '1-4 weeks ago', shortLabel: '1-4 weeks ago' },
      { nextQuestionId: 904, label: '2-3 months ago', shortLabel: '2-3 months ago' },
      { nextQuestionId: 904, label: 'More than 3 months ago', shortLabel: 'More than 3 months ago' },
    ],
  },
  {
    id: 904,
    question: 'Which procedure(s) are you looking for?',
    descriptions: [],
    procedureOptionIds:[161,162,163,164,165,166,167,0],
    optionType: 7,
    questionGroupId: 'A17',
    multiColOption: true,
  },
  {
    id: 905,
    question: 'What body parts(s) are you looking for help with?',
    descriptions: [],
    staticOptionIds: [19,23,21,24,25,26,27,28,29],
    optionType: 6,
    nextQuestionId: 906,
    multiColOption: true,
  },
  {
    id: 906,
    question: 'When was the last time you had a procedure on the same area?',
    descriptions: [],
    options: [
      { nextQuestionId: 907, label: 'Never', shortLabel: 'Never' },
      { nextQuestionId: 907, label: 'In the last week', shortLabel: 'Last week' },
      { nextQuestionId: 907, label: '1-4 weeks ago', shortLabel: '1-4 weeks ago' },
      { nextQuestionId: 907, label: '2-3 months ago', shortLabel: '2-3 months ago' },
      { nextQuestionId: 907, label: 'More than 3 months ago', shortLabel: 'More than 3 months ago' },

    ],
  },
  {
    id: 907,
    question:
      'Which procedure(s) are you looking for?',
    descriptions: [
    ],
    procedureOptionIds:[168,169,170,171,172,173,174,175,176,177,178,179,180,181,0],
    optionType: 7,
    questionGroupId: 'A17',
    multiColOption: true,
  },
  {
    id: 908,
    question:
      'What body parts(s) are you looking for help with?',
    descriptions: [
    ],
    staticOptionIds: [30,31,32,24,25,33,26,34,18,35,21,36,37,38,39,40],
    optionType: 6,
    nextQuestionId: 909,
    multiColOption: true,
  },
  {
    id: 909,
    question: 'When was the last time you had a procedure on the same area?',
    descriptions: [],
    options: [
      { nextQuestionId: 9011, label: 'Never', shortLabel: 'Never' },
      { nextQuestionId: 9010,addExtraQuestion: 1, label: 'In the last week', shortLabel: 'Last week' },
      { nextQuestionId: 9010,addExtraQuestion: 1, label: '1-4 weeks ago', shortLabel: '1-4 weeks ago' },
      { nextQuestionId: 9010,addExtraQuestion: 1, label: '2-3 months ago', shortLabel: '2-3 months ago' },
      { nextQuestionId: 9010,addExtraQuestion: 1, label: 'More than 3 months ago', shortLabel: 'More than 3 months ago' },

    ],
  },
  {
    id: 9010,
    question: 'If there is an implant or filler in the same area, when was the procedure performed?',
    descriptions: [],
    options: [
      { nextQuestionId: 9011, label: 'No implant or filler in the area', shortLabel: 'No implant' },
      { nextQuestionId: 9011, label: 'In the last week', shortLabel: 'Last week' },
      { nextQuestionId: 9011, label: '1-4 weeks ago', shortLabel: '1-4 weeks ago' },
      { nextQuestionId: 9011, label: '2-3 months ago', shortLabel: '2-3 months ago' },
      { nextQuestionId: 9011, label: '3-12 month ago', shortLabel: '3-12 month ago' },
      { nextQuestionId: 9011, label: '1-3 years ago', shortLabel: '1-3 years ago' },
      { nextQuestionId: 9011, label: 'More than 3 months ago', shortLabel: 'More than 3 months ago' },

    ],
  },
  {
    id: 9011,
    question:
      'Which procedure(s) are you looking for?',
    descriptions: [
    ],
    procedureOptionIds:[182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,0],
    optionType: 7,
    questionGroupId: 'A16',
    multiColOption: true,
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 901,
    treeDepth: 4,
  },
};
