import React from 'react';
import _ from 'lodash';
import {withRouter} from 'react-router-dom';
import MainRoutes from 'routes';
import RootContextWrapper from 'components/RootContextWrapper';
import AuthContext from 'context/AuthContext';
import Modal from 'modals/Modal';
import AuthRoutes from 'routes/AuthRoutes';
import userService from 'services/user';
import masterService from 'services/master';
import adminService from 'services/admin';
import providerFeedback from 'services/providerFeedback';
import patientService from 'services/patient';
import Loader from 'components/Loader';
import rolePermission from 'data/rolePermission';
import practiceService from 'services/practice';
import axiosInstance from 'config/axios';
import providerData from 'data/providerData';
import config from 'config/config';
import accessHelper from 'data/kalypsys.helper';
import AccessChecker from 'containers/AccessChecker';
import ActiveWatcher from 'containers/Connectors/ActiveWatcher';
import './App.css';
import constants from 'data/constants';
import moment from 'moment';
import clearLocalStorageOnlogout from 'data/localStorageClear';
import cookieHelper from 'data/cookie.helper';
import subscriptionService from 'services/subscription';
import helper from 'data/helper';
// import {Viewer} from "../cookies/cookies"
const BASENAME = '/app';
const {helperFunctions, permissionList} = rolePermission;
const {getAllMyRoleIds, hasPermission} = helperFunctions;
const {getLoggedInUser, getAllMyPracticeProvider} = userService;
const {getSiteSettings} = masterService;
const {getRequestCounts} = adminService;
const {getfeedbackPendingCount} = providerFeedback;
const {getFamilyMembers, getNotificationSettings, getMyProfileInsurance} = patientService;
const {getMyPracticeDetail} = practiceService;
const {providerFunctions} = providerData;
const {setViewUserId} = providerFunctions;
const {matchKalyData, testAndSetKalyData, siteCred} = accessHelper;
const {DefaultSiteSettings} = constants;
const {removeUserIdCookie} = cookieHelper;
const {getSubscriptionDetails} = subscriptionService;
const {encrypt} = helper;
class App extends React.Component {
  // static contextType = RootContext;
  state = {
    auth: null,
    isTokenChecked: false,
    hasAccessKey: false,
    siteSettings: null,
    requestCount: null,
  };
  requestInterceptor = null;
  responseInterceptor = null;

  componentDidMount() {
    this.checkAccess();
    this.hideDevUsers();
    this.getAndSetUserFromToken();
    this.switcherInterceptor();
    localStorage.setItem('showreviewLogin', 'Y');
    this.catchStorageChange();
  }
  catchStorageChange = () => {
    window.addEventListener('storage', event => {
      if (event.key == 'accessToken' && event.newValue == null) {
        this.updateAuthStatus(null, false);
      }
      // alert(`${event.key}"accessToken"`)
      // alert(`${event.newValue}`)
    });
  };
  checkAccess = () => {
    const checkLock = config.IS_LOCKED;
    if (checkLock) {
      let res = matchKalyData();
      this.setState({hasAccessKey: res});
    } else {
      this.setState({hasAccessKey: true});
    }
  };

  componentWillUnmount() {
    if (this.requestInterceptor) {
      axiosInstance.interceptors.request.eject(this.requestInterceptor);
      this.requestInterceptor = null;
    }
    if (this.responseInterceptor) {
      axiosInstance.interceptors.response.eject(this.responseInterceptor);
      this.responseInterceptor = null;
    }
    window.removeEventListener('storage');
  }

  // Reference link: https://stackoverflow.com/a/524721/7160641
  hideDevUsers = () => {
    const userIds = config.DEV_USERS;
    let selectors = [];
    userIds.forEach((userId, index) => {
      let appendString = `[data-user-id='${userId}']`;
      selectors.push(appendString);
    });
    if (selectors.length > 0) {
      const css = selectors.join(',') + '{ display: none !important; }';
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      head.appendChild(style);
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
  };

  getViewUser = () => {
    const user = _.get(this.state, 'auth');
    const viewUserId = _.get(user, 'viewUserId');
    let viewUser = null;
    if (viewUserId) {
      const practiceMembers = _.get(user, 'practiceMembers', []);
      _.forEach(practiceMembers, pm => {
        if (viewUserId === pm.id) {
          viewUser = pm;
        }
      });
    }
    return viewUser;
  };

  switcherInterceptor = () => {
    this.requestInterceptor = axiosInstance.interceptors.request.use(request => {
      const viewUserId = _.get(this.state, 'auth.viewUserId');
      if (viewUserId) {
        request.headers.viewUserId = viewUserId;
      }
      return request;
    });
    this.responseInterceptor = axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        const auth = _.get(this.state, 'auth');
        const {customCode} = error;
        if (customCode === 'userSwitcherOperationFailed') {
          setViewUserId(auth);
          if (auth && auth.viewUserId) {
            this.setState({auth});
          } else {
            this.updateAuthStatus(false);
          }
        }
        return Promise.reject(error);
      },
    );
  };

  getAndSetPracticeMembers = async roleIds => {
    const isGetPracticeMembers = hasPermission(roleIds, permissionList.GET_PRACTICE_MEMBERS);
    if (isGetPracticeMembers) {
      const practiceMembersResponse = await getAllMyPracticeProvider().catch(() => null);
      if (practiceMembersResponse) {
        const {data: practiceMembers} = practiceMembersResponse;
        this.setState(prevState => {
          return {
            ...prevState,
            auth: {
              ...prevState.auth,
              practiceMembers,
            },
          };
        });
      }
    }
  };

  getAndSetPracticeData = async roleIds => {
    const isGetPractice = hasPermission(roleIds, permissionList.GET_PRACTICE);
    if (isGetPractice) {
      const practiceResponse = await getMyPracticeDetail().catch(() => null);
      if (practiceResponse) {
        const {data: practice} = practiceResponse;
        this.setState(prevState => {
          return {
            ...prevState,
            auth: {
              ...prevState.auth,
              practice,
            },
          };
        });
      }
    }
  };

  setDemographicInformation = () => {
    this.setState(prevState => {
      let updatedDemographicInformation = _.get(prevState.auth, 'demographicInformation', []);
      if (!updatedDemographicInformation || updatedDemographicInformation.length === 0) {
        updatedDemographicInformation = [
          {label: 'Race', otherText: '', values: []},
          {label: 'Ethnicity', value: ''},
        ];
      }
      return {
        ...prevState,
        auth: {
          ...prevState.auth,
          demographicInformation: updatedDemographicInformation,
        },
      };
    });
  };

  setRequestCountsForAdmin = async () => {
    const response = await getRequestCounts().catch(() => null);
    return response;
  };
  setProiderFeedbackcount = async () => {
    const response = await getfeedbackPendingCount().catch(() => null);
    return response;
  };
  getSubscription = async () => {
    if (config.showStripe == true) {
      const response = await getSubscriptionDetails();
      if (response && response.data) {
        if (_.get(response.data, 'code', '') === 'yourStripeAccountNotCreated') {
          return 'yourStripeAccountNotCreated';
        } else {
          return 'subscribedAccount';
        }
      }
    } else {
      return 'subscribedAccount';
    }
  };
  getAndSetNotificationSettings = async () => {
    const notificationSettingsResponse = await getNotificationSettings().catch(() => null);
    if (notificationSettingsResponse) {
      const {data: notificationSettings} = notificationSettingsResponse;
      this.setState(prevState => {
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            notificationSettings,
          },
        };
      });
    }
  };

  getAndSetMyProfileInsurance = async () => {
    const profileInsuranceResponse = await getMyProfileInsurance().catch(() => null);
    if (profileInsuranceResponse) {
      const {data: insurance} = profileInsuranceResponse;
      this.setState(prevState => {
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            insurance,
          },
        };
      });
    }
  };
  checkredirect = () => {
    const {history} = this.props;
    let urlList = window.location.href.split('goto=');
    if (urlList.length === 2) {
      const url = new URL(urlList[1]);
      let pathName = url.pathname.replace('/app', '');
      pathName = pathName === '' ? '/' : pathName;
      history.push({pathname: pathName, search: url.search, hash: url.hash});
    }
  };
  getAndSetFamilyMembers = async () => {
    const familyeMembersResponse = await getFamilyMembers().catch(() => null);
    if (familyeMembersResponse) {
      const {data: familyMembers} = familyeMembersResponse;
      let me = Array.isArray(familyMembers) ? familyMembers.find(f => f.relation == 0) : null;
      if (me) {
        localStorage.setItem('p-id-me', me.id);
      }
      this.setState(prevState => {
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            familyMembers,
          },
        };
      });
    }
  };

  getAdditionalUserData = () => {
    const {auth: user} = this.state;
    const roleIds = getAllMyRoleIds(user);
    this.getAndSetPracticeData(roleIds);
    // this.getAndSetPracticeMembers(roleIds);

    // if user is patient then get his/her family members
    const isFrontEndUser = hasPermission(roleIds, permissionList.FRONTEND_USER);
    if (isFrontEndUser) {
      this.setDemographicInformation();
      this.getAndSetFamilyMembers();
      this.getAndSetNotificationSettings();
      this.getAndSetMyProfileInsurance();
    } else {
      const isAdminUser = hasPermission(roleIds, permissionList.ADMIN);
      if (
        isAdminUser &&
        (config.APP_URL === window.location.href || !window.location.pathname.includes('/admin/'))
      ) {
        this.props.history.push('/admin/dashboard');
      }
    }
  };

  getSiteSetting = async () => {
    const siteSettings = await getSiteSettings().catch(() => null);
    let settings = null;
    let allSettings = null;
    if (siteSettings) {
      allSettings = siteSettings.data ? siteSettings.data : [...DefaultSiteSettings];
    } else {
      allSettings = [...DefaultSiteSettings];
    }

    if (allSettings) {
      const contactUsSetting = _.find(allSettings, {slug: 'siteContactUsEmail'});
      const fbSetting = _.find(allSettings, {slug: 'siteFacebookAccountUrl'});
      const instagramSetting = _.find(allSettings, {slug: 'siteInstagramAccountUrl'});
      const twitterSetting = _.find(allSettings, {slug: 'siteTwitterAccountUrl'});
      const LinkedInSetting = _.find(allSettings, {slug: 'siteLinkedInAccountUrl'});
      settings = {
        contactUsLinks: _.get(contactUsSetting, 'value', '').join(','),
        fbLink: _.get(fbSetting, 'value', ''),
        instagramLink: _.get(instagramSetting, 'value', ''),
        twitterLink: _.get(twitterSetting, 'value', ''),
        LinkedInLink: _.get(LinkedInSetting, 'value', ''),
      };
    }
    return settings;
  };

  getAndSetUserFromToken = async () => {
    const settingsData = await this.getSiteSetting();
    try {
      const accessToken = localStorage.getItem('accessToken');
      this.setState({
        isTokenChecked: false,
        siteSettings: settingsData,
      });
      if (accessToken) {
        const response = await getLoggedInUser().catch(error => {
          let {history} = this.props;
          clearLocalStorageOnlogout();
          removeUserIdCookie();
          if (config.IS_LOCKED) {
            testAndSetKalyData(siteCred);
          }

          history.push('/login');
          this.setState({
            isTokenChecked: true,
            siteSettings: settingsData,
          });
        });

        if (response && response.data && Object.keys(response.data).length > 0) {
          let {data: user} = response;
          // set initial viewUserId if user can switch to other user
          localStorage.setItem('userID', user.id);
          if (user.roles && user.roles.length > 0) {
            if (user.roles.find(i => i.id == 2)) {
              var date = new Date();
              date.setTime(date.getTime() + 60 * 60 * 1000);
              var expires = '; expires=' + date.toGMTString();
              //  setCookie('userID',localStorage.getItem('userID'),0.0333333)
              let host = window.location.host;

              document.cookie = `userId=${user.id};domain=.${host};path=/;secure; SameSite=none${expires}`;
            }
          }
          setViewUserId(user);
          //this.inactivityTime()

          // if (user.lastActivityTime) {

          //     localStorage.setItem("lastActivityTimeSS",moment(user.lastActivityTime.replace('T', ' '))
          //     .local()
          //     .add(user.expiryTimeDuration, 'millisecond')
          //     .format('YYYY-MM-DD HH:mm:ss'))
          //     localStorage.setItem('expiryTimeDuration',user.expiryTimeDuration)

          // }
          const roleIds = getAllMyRoleIds(user);
          const isAdminUser = hasPermission(roleIds, permissionList.ADMIN);
          if (isAdminUser) {
            const requestCountData = await this.setRequestCountsForAdmin();
            if (requestCountData && requestCountData.data) {
              this.setState(
                {
                  auth: user,
                  isTokenChecked: true,
                  siteSettings: settingsData,
                  requestCount: {...requestCountData.data},
                },
                () => {
                  this.getAdditionalUserData();
                },
              );
            }
          } else {
            this.checkredirect();
            const isFrontEndUser = hasPermission(roleIds, permissionList.FRONTEND_USER);
            let requestFeedbackCount = null;
            let getSubscriptionText = 'subscribedAccount';
            if (!isFrontEndUser) {
              requestFeedbackCount = await this.setProiderFeedbackcount();
              getSubscriptionText = await this.getSubscription();
              requestFeedbackCount = requestFeedbackCount.data ? requestFeedbackCount.data : 0;
            }
            this.setState(
              {
                auth: user,
                isTokenChecked: true,
                siteSettings: settingsData,
                requestCount: {feedbackPendingCount: requestFeedbackCount || 0},
                getSubscriptionText: getSubscriptionText,
              },
              () => {
                this.getAdditionalUserData();
              },
            );
          }
        } else {
          this.setState({
            auth: null,
            isTokenChecked: true,
            siteSettings: settingsData,
          });
        }
      } else {
        this.setState({
          auth: null,
          isTokenChecked: true,
          siteSettings: settingsData,
        });
      }
    } catch (exception) {
      this.setState({
        auth: null,
        isTokenChecked: true,
        siteSettings: settingsData,
      });
    }
  };

  setAuthState = callback => {
    this.setState(callback);
  };
  updateSubscriptionText = text => {
    this.setState({
      getSubscriptionText: text,
    });
  };
  updateAuthStatus = (isFetchUser, takeRedirect = true) => {
    let {history} = this.props;
    let {auth} = this.state;
    if (!isFetchUser) {
      const roleIds = getAllMyRoleIds(auth);
      const isFrontEndUser = hasPermission(roleIds, permissionList.FRONTEND_USER);
      clearLocalStorageOnlogout();
      removeUserIdCookie();
      if (config.IS_LOCKED) {
        testAndSetKalyData(siteCred);
      }
      this.setState({auth: null});
      if (!takeRedirect) {
        return;
      }
      // if user is of frontend(Patient) then redirect to landing page, else redirect to doctor login page
      if (isFrontEndUser) {
        history.push('/');
      } else {
        console.log('checking auth data..');
        history.push('/login');
      }
    } else {
      this.getAndSetUserFromToken();
    }
  };

  handleDeepLinks = () => {
    const {auth, isTokenChecked} = this.state;

    if (isTokenChecked) {
      if (!auth) {
        const {location} = this.props;
        const query = new URLSearchParams(location.search);
        const source = query.get('source');
        const appointment = query.get('appointment');
        const patientUserId = query.get('patientUserId');
        const type = query.get('type');

        if (type === 'review' && source === 'patientemail') {
          let rid = query.get('id');
          if (rid) {
            rid = encrypt(rid);
            let r_url = config.APP_DOMAIN + `app/write-review/${rid}`;
            window.location.href = r_url;
          }
        } else if (source === 'patientemail') {
          window.location.href =
            `${BASENAME}/patient/login?goto=` + window.location.href.replace('source=', 'origin=');
        } else if (source === 'providermail') {
          const id = query.get('id');
          if (type === 'review') {
            let reviewId = '';
            if (id) {
              query.delete('id');
              reviewId = `#${id}`;
            }
            const newURL =
              window.location.protocol +
              '//' +
              window.location.host +
              `${BASENAME}/reviews?` +
              query +
              reviewId;
            window.location.href = `${BASENAME}/login?goto=` + newURL.replace('source=', 'origin=');
          } else if (type === 'ConnectSocial') {
            let r_url = config.APP_DOMAIN + `app/Reviews/account?tab=ConnectSocial`;
            window.location.href = `${BASENAME}/login?goto=` + r_url;
          } else if (appointment || patientUserId) {
            window.location.href =
              `${BASENAME}/login?goto=` + window.location.href.replace('source=', 'origin=');
          } else if (type == 'feedback') {
            window.location.href = `${BASENAME}/login?goto=` + window.location.href;
          }
        }
      } else {
        const {location} = this.props;
        const query = new URLSearchParams(location.search);
        let source = query.get('source');
        // if(!source)
        // source=query.get('origin');
        if (source === 'providermail') {
          const type = query.get('type');
          if (type === 'review') {
            const id = query.get('id');
            let reviewId = '';
            if (id) {
              query.delete('id');
              reviewId = `#${id}`;
            }
            const url = `${BASENAME}/reviews?` + query + reviewId;
            window.location.href = url.replace('source=', 'origin=');
          } else if (type === 'ConnectSocial') {
            let r_url = config.APP_DOMAIN + `app/Reviews/account?tab=ConnectSocial`;
            window.location.href = r_url;
          } else if (type === 'dashboard') {
            const url = `?${query}`;
            window.location.href = url.replace('source=', 'origin=');
          } else if (type === 'feedback') {
            const id = query.get('id');
            if (id) {
              const url = `${BASENAME}/feedback?` + query + `&tab=PendingFeedback&feedbackid=` + id;
              window.location.href = url.replace('source=', 'origin=');
            } else {
              const url = `${BASENAME}/feedback?` + query + `&tab=PendingFeedback`;
              window.location.href = url.replace('source=', 'origin=');
            }
          }
        }
      }
    }
  };

  setAdminRequestCountState = callback => {
    this.setState(callback);
  };

  render() {
    let mainApp = null;
    const {
      auth,
      isTokenChecked,
      hasAccessKey,
      getSubscriptionText = 'subscribedAccount',
    } = this.state;
    // if (!isTokenChecked) {
    //   return null;
    // }
    if (hasAccessKey) {
      if (isTokenChecked) {
        if (auth) {
          mainApp = (
            <div className="App">
              <ActiveWatcher auth={auth} />
              <MainRoutes {...this.props} auth={auth} getSubscriptionText={getSubscriptionText} />
            </div>
          );
        } else {
          mainApp = <AuthRoutes />;
        }
      }
    } else {
      mainApp = <AccessChecker onSuccess={() => this.setState({hasAccessKey: true})} />;
    }
    this.handleDeepLinks();
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          updateAuthStatus: this.updateAuthStatus,
          setAuthState: this.setAuthState,
          getViewUser: this.getViewUser,
          setAdminRequestCountState: this.setAdminRequestCountState,
          updateSubscriptionText: this.updateSubscriptionText,
        }}>
        <RootContextWrapper>
          <Loader />
          <Modal />
          {mainApp}
          {/* <Viewer/> */}
        </RootContextWrapper>
      </AuthContext.Provider>
    );
  }
}

export default withRouter(App);
