import React, {Component} from 'react';
import Layout from 'layout';
import CircleButton from 'components/CircleButton';
import AuthContext from 'context/AuthContext';
import './RouteNotFound.css';

class RouteNotFound extends Component {
  static contextType = AuthContext;

  redirectToHome = () => {
    const {history} = this.props;
    const {auth} = this.context;
    if (auth) {
      history.push('/');
    } else {
      console.log('OOPS!');
      history.push('/login');
    }
  };

  render() {
    return (
      <Layout>
        <div className="RouteNotFound">
          <div className="container">
            <div className="RouteNotFound-inner">
              <div className="heading1">OOPS!</div>
              <div className="paragraph">This route does not exist</div>
              <div className="route-link">
                <CircleButton onClick={this.redirectToHome} btnType="whiteButton">
                  Back
                </CircleButton>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default RouteNotFound;
