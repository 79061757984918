const questionList = [
  {
    id: 401,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [3], // painIds,
    nextQuestionId: 402,
    descriptions: [],
  },
  {
    id: 402,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 403,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [{painId: 3, questionId: 224, visitReasonIds: [57], extraQuestion: 1}],
      },
      {
        nextQuestionId: 403,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [{painId: 3, questionId: 225, visitReasonIds: [57], extraQuestion: 1}],
      },
    ],
    descriptions: [],
  },
  {
    id: 403,
    question: 'Are you interested in potentially undergoing surgery?',
    options: [
      {nextQuestionId: 404, label: 'Yes', shortLabel: 'Interested in surgery'},
      {nextQuestionId: 405, label: 'No', shortLabel: 'No surgery'},
    ],
    descriptions: [],
  },
  {
    id: 404,
    question: 'Do you still want to see a Hip Surgeon?',
    descriptions: [
      'A Hip Surgeon is a medical physician (MD/DO) who completed a 1 year fellowship in Hip Surgery or Sports Medicine, after completing a 5 year residency in Orthopedic Surgery. A Hip Surgeon has further training in treating hip conditions that may require surgery.',
    ],
    title: 'Hip Surgeon',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can perform pain injections',
    ],
    options: [
      {groupId: 'K', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
  {
    id: 405,
    question:
      'Would you still like to see a Hip Surgeon or are you interested in seeing a non-surgical specialist for your condition?',
    questionType: 2,
    options: [
      {nextQuestionId: 404, label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
    descriptions: [
      'Some Hip Surgeons will only schedule patients who are interested in undergoing surgery in the near future.',
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 401,
    lastQuestionId: 404,
    treeDepth: 5,
  },
};
