import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import validators from 'data/validators';
import allRegex from 'data/regex';
import config from 'config/config';
import PlaceAutocomplete from 'components/FormControls/PlaceAutocomplete';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import StripeInput from './StripeInput';
import './AboutYourPracticeStepTwo.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {exceptDigitsRegex} = allRegex;
const {__required, __phoneNumber, __email, __maxLength} = validators;
const {MAX_TEXT_LENGTH} = config;
const {Analytics} = analyticsData;

const AboutYourPracticeStepTwo = props => {
  const {nextStep, data} = props;
  const values = {...data};
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  //====| START: Hide Stripe Code |====
  const [isPaymentValid, setPaymentValid] = useState(false);
  const [stripePaymentMethod, setStripePaymentMethod] = useState();
  //====| END: Hide Stripe Code |====
  const {values: formValues, errors: formErrors} = formData;
  const stripePromise = loadStripe(config.StripePublishKey);

  // Validation functions
  const __validateLocationName = (value = null) => {
    const fValue = value !== null ? value : formValues['locationName'];
    if (!__required(fValue)) {
      return 'Primary Location Name is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateAddress = (value = null) => {
    const fValue =
      value !== null
        ? value
        : {
            address: formValues['address'],
            latitude: formValues['latitude'],
            longitude: formValues['longitude'],
          };
    if (!__required(fValue['address'])) {
      return 'Address is required';
    }
    if (!__required(fValue['latitude'])) {
      return 'Address is invalid';
    }
    return null;
  };
  const __validateOfficePhone = (value = null) => {
    const fValue = value !== null ? value : formValues['officePhone'];
    if (!__required(fValue)) {
      return 'Office Phone is required';
    }
    if (!__phoneNumber(fValue)) {
      return 'Invalid office phone';
    }
    return null;
  };
  const __validateExt = (value = null) => {
    const fValue = value !== null ? value : formValues['officePhoneExt'];
    if (!__maxLength(fValue, 5)) {
      return `Max 5 characters`;
    }
    return null;
  };
  const __validateContactPerson = (value = null) => {
    const fValue = value !== null ? value : formValues['contactPersonName'];
    if (!__required(fValue)) {
      return 'Contact Person is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateAppointmentEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['appointmentEmail'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  const __validateForm = () => {
    const validLocationName = !__validateLocationName();
    const validAddress = !__validateAddress();
    const validOfficePhone = !__validateOfficePhone();
    const validExt = !__validateExt();
    const validContactPerson = !__validateContactPerson();
    const validAppointmentEmail = !__validateAppointmentEmail();
    if( config.showStripe===true){
      return (
        validLocationName &&
        validAddress &&
        validOfficePhone &&
        validExt &&
        validContactPerson &&
        validAppointmentEmail
        //====| START: Hide Stripe Code |====
         //&& isPaymentValid
        //====| END: Hide Stripe Code |====
      );
    }
    return (
      validLocationName &&
      validAddress &&
      validOfficePhone &&
      validExt &&
      validContactPerson &&
      validAppointmentEmail
      //====| START: Hide Stripe Code |====
      // && isPaymentValid
      //====| END: Hide Stripe Code |====
    );
    
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name} = event.target;
    let {value} = event.target;
    let errorObject = {
      [name]: null,
    };
    let updateValueObj = {};
    switch (name) {
      case 'locationName':
        errorObject = {
          [name]: __validateLocationName(value),
        };
        break;
      case 'address':
        updateValueObj = {
          ...value,
        };
        errorObject = {
          [name]: __validateAddress(value),
        };
        break;
      case 'officePhone':
        value = value.replace(exceptDigitsRegex, '');
        errorObject = {
          [name]: __validateOfficePhone(value),
        };
        break;
      case 'officePhoneExt':
        errorObject = {
          [name]: __validateExt(value),
        };
        break;
      case 'contactPersonName':
        errorObject = {
          [name]: __validateContactPerson(value),
        };
        break;
      case 'appointmentEmail':
        errorObject = {
          [name]: __validateAppointmentEmail(value),
        };
        break;
      default:
        break;
    }
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
          ...updateValueObj,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const handleSubmit = () => {
    Nux.sendEvent(
      Analytics.Category.DoctorRegistration,
      Analytics.Actions.Click,
      'aboutPracticeStep2',
    );
    //====| START: Hide Stripe Code |====
    // if(config.showStripe===true)
    // formValues['paymentMethodId'] = stripePaymentMethod.id;
    //====| END: Hide Stripe Code |====
    nextStep(formValues);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  const emailLabel = (
    <>
      <span>Email for appointment notifications</span>
      <span className="inner-label"> (not visible)</span>
    </>
  );
  const contactLabel = (
    <>
      <span>Contact Person</span>
      <span className="inner-label"> (not visible)</span>
    </>
  );
  return (
    <div className="AboutYourPracticeStepTwo">
      <div className="h3">About your practice</div>
      <div className="paragraph">You can add more locations under settings once approved</div>
      <form className="signup-form">
        <Input {...getGeneralInputProps('locationName')} label="Primary Location Name" />
        <PlaceAutocomplete
          name="address"
          value={{
            address: formValues['address'],
            latitude: formValues['latitude'],
            longitude: formValues['longitude'],
          }}
          onPlaceSelect={changeHandler}
          label="Address"
        />
        <div className="form-row">
          <Input
            mask="(999) 999-9999"
            maskChar=" "
            type="tel"
            InputComponent={InputMask}
            {...getGeneralInputProps('officePhone')}
            containerClass="column-9"
            label="Office Phone"
          />
          <Input
            {...getGeneralInputProps('officePhoneExt')}
            containerClass="column-3"
            label="Ext."
          />
        </div>
        <Input
          {...getGeneralInputProps('contactPersonName')}
          containerClass="two-input"
          label={contactLabel}
        />
        <Input
          {...getGeneralInputProps('appointmentEmail')}
          containerClass="two-input"
          label={emailLabel}
        />
        {/* ====| START: Hide Stripe Code |==== */}
        {/* {config.showStripe===true&&(
        <Elements stripe={stripePromise}>
          <StripeInput
            {...props}
            setPaymentValid={setPaymentValid}
            setStripePaymentMethod={setStripePaymentMethod}
          />
        </Elements>
        )} */}
        {/* ====| END: Hide Stripe Code |==== */}
        <div className="signup-btn">
          <CircleButton disabled={!isValidForm} onClick={handleSubmit} btnType="whiteButton">
            Continue
          </CircleButton>
        </div>
      </form>
    </div>
  );
};

export default AboutYourPracticeStepTwo;
