import React, {Component} from 'react';
import Layout from 'layout';
import AuthContext from 'context/AuthContext';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import userService from 'services/user';
import images from 'data/images';
import validators from 'data/validators';
import modalConfig from 'modals/modalConfig';
import './AdminLogin.css';

const {signIn} = userService;
const {errorModalData} = modalConfig.modalFunctions;
const {__required, __email} = validators;

class AdminLogin extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {
    isProcessing: false,
    values: {
      type: 'admin',
      email: '',
      password: '',
      rememberMe: false,
    },
    errors: {},
  };

  loginHandler = async () => {
    const {updateAuthStatus} = this.context;
    const {history} = this.props;
    const {values: formValues} = this.state;
    this.setState({isProcessing: true});
    const response = await signIn(formValues).catch(this.handleError);
    this.setState({isProcessing: false});
    if (response) {
      const {data} = response;
      const {accessToken, refreshToken} = data;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      updateAuthStatus(true);
      history.push('/admin/dashboard');
    }
  };

  handleError = error => {
    const {setGlobal} = this.rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  // validation methods
  __validateEmail = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  __validatePassword = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['password'];
    if (!__required(fValue)) {
      return 'Password is required';
    }
    return null;
  };
  __validateForm = () => {
    const validEmail = !this.__validateEmail();
    const validPassword = !this.__validatePassword();
    return validEmail && validPassword;
  };

  changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'email':
        errorObject = {
          [name]: this.__validateEmail(value),
        };
        break;
      case 'password':
        errorObject = {
          [name]: this.__validatePassword(value),
        };
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  render() {
    const {isProcessing, values: formValues, errors: formErrors} = this.state;
    const getGeneralInputProps = controlName => {
      return {
        onChange: this.changeHandler,
        onBlur: this.changeHandler,
        error: formErrors[controlName],
        value: formValues[controlName],
        name: controlName,
      };
    };

    const isValidForm = this.__validateForm();
    return (
      <Layout>
        <div className="AdminLogin">
          <div className="login-container">
            <div className="h3">Admin Login</div>
            <form className="login-form" onSubmit={e => e.preventDefault()}>
              <Input {...getGeneralInputProps('email')} translationType="transform" label="Email" />
              <Input
                {...getGeneralInputProps('password')}
                type="password"
                translationType="transform"
                icon={images.eyeView}
                label="Password"
              />
              {/* <div className="checkbox-block">
                <Checkbox {...getGeneralInputProps('rememberMe')}>keep me logged in</Checkbox>
                <Link to="/forgot-password" className="reset-link">
                  Reset Password?
                </Link>
              </div> */}
              <RootContext.Consumer>
                {context => {
                  this.rootContext = context;
                  return (
                    <div className="login-btn doctor-loginbtn">
                      <CircleButton
                        type="submit"
                        disabled={!isValidForm || isProcessing}
                        onClick={this.loginHandler}
                        btnType="whiteButton">
                        Log in
                      </CircleButton>
                    </div>
                  );
                }}
              </RootContext.Consumer>
              {/* <div className="login-bottom">
                Patient?{' '}
                <Link to="/patient/login" replace>
                  Click here to login
                </Link>
              </div> */}
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default AdminLogin;
