import React from 'react';
import _ from 'lodash';
import './Viewrequest.css'
import images from 'data/images';

const ViewFullrequest = props => {
  const { data } = props;
console.log("data.practiceNotes",data.practiceNotes);
  return (
    <div className='viewrequest'>
      <div className='leftpanel'>
        <div className='eachitem'>
          <span className='title'>Patient type</span>
          <span className='ans'>{data.registerStatus}</span>
        </div>
        <div className='eachitem'>
          <span className='title'>Patient Name</span>
          <span className='ans'>{data.patientFirstName} {data.patientLastName}</span>
        </div>
        <div className='eachitem'>
          <span className='title'>Patient Email</span>
          <span className='ans'>{data.patientEmail}</span>
        </div>
        <div className='eachitem'>
          <span className='title'>Patient Mobile No:</span>
          <span className='ans'>{data.mobilePhone}</span>
        </div>
        <div className='eachitem'>
          <span className='title'>Contact Preferences</span>
          <span className='ans'> {data.requestPhoneCall && (
            <div className="imgtextdisplay">
              <img src={images.phone} />
              <span>Call Back</span>
            </div>
          )}
            {data.requestSMS && (
              <div className="imgtextdisplay">
                <img src={images.email} />
                <span>SMS</span>
              </div>
            )}</span>
        </div>
        <div className='eachitem'>
          <span className='title'>Preferred Pharmacy Name</span>
          <span className='ans' >{data.preferredPharmacyName?data.preferredPharmacyName:'--'}</span>
        </div>
        <div className='eachitem'>
          <span className='title'>Preferred Pharmacy Phone</span>
          <span className='ans' >{data.preferredPharmacyPhone?data.preferredPharmacyPhone:'--'}</span>
        </div>
      </div>
      <div className='rightpanel'>
        <div className='eachitem'>
          <span className='title'>Question Response</span>
          <span className='ans' dangerouslySetInnerHTML={{ __html: data.flowResponse.map((a) => a.answer).join('<br/>') }}></span>
        </div>
        <div className='eachitem'>
          <span className='title'>Address</span>
          <span className='ans' >{data.patientAddress}</span>
        </div>
        <div className='eachitem'>
          <span className='title'>State</span>
          <span className='ans' >{data.state.state}</span>
        </div>
        <div className='eachitem'>
          <span className='title'>City</span>
          <span className='ans' >{data.patientCity}</span>
        </div>
        <div className='eachitem'>
          <span className='title'>Zipcode</span>
          <span className='ans' >{data.zipCode.zipCode}</span>
        </div>
        <div className='eachitem'>
          <span className='title'>Practice Notes</span>
          <p className='ans PracticeNote' >{data.practiceNotes?data.practiceNotes:'--'}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewFullrequest;
