import React from 'react';
import Header from './Header';
import CustomFooter from './CustomFooter';
import Footer from './Footer';

const Layout = props => {
  const {
    children,
    // header configuration
    hideHeader = false,
    hideHeaderButtons = true,
    // footer configuration
    hideFooter = false,
    customFooter = true,
  } = props;
  let footer = null;
  if (!hideFooter) {
    footer = <Footer />;
    if (customFooter) {
      footer = <CustomFooter />;
    }
  }

  return (
    <div>
      {!hideHeader && <Header hideHeaderButtons={hideHeaderButtons} />}
      {children}
      {footer}
    </div>
  );
};

export default Layout;
