import React from 'react';
import _ from 'lodash';
import './Viewrequest.css'

const Viewrequest = props => {
  const { data } = props;

  return (
    <div className='viewrequest'>
      <div className='leftpanel'>
        <div className='eachitem'>
          <span className='title'>Patient type</span>
          <span className='ans'>{data.registerStatus}</span>
        </div>
        {/* <div className='eachitem'>
          <span className='title'>Symptom</span>
          <span className='ans'>Low Back Pain</span>
        </div> */}
      </div>
      <div className='rightpanel'>
        <div className='eachitem'>
          <span className='title'>Question Response</span>
          <span className='ans' dangerouslySetInnerHTML={{ __html:data.flowResponse.map((a)=>a.answer).join('<br/>')}}></span>
        </div>
      </div>
    </div>
  );
};

export default Viewrequest;
