const staticOptionsList = [
  { id: 1, label: 'Wrinkles', shortLabel: 'Wrinkles' },
  {
    id: 2,
    label: 'Telangetasia (Ruptured Blood Vessels)',
    shortLabel: 'Telangetasia',
  },
  { id: 3, label: 'Fillers', shortLabel: 'Fillers' },
  { id: 4, label: 'Melasma (Hyperpigmentattion)', shortLabel: 'Melasma' },
  { id: 5, label: 'Facial Rejuvenation', shortLabel: 'Facial Rejuvenation' },
  { id: 6, label: 'Hair Removal', shortLabel: 'Hair Removal' },
  { id: 7, label: 'Excessive Sweating', shortLabel: 'Excessive Sweating' },
  { id: 8, label: 'Milia', shortLabel: 'Milia' },
  { id: 9, label: 'ScarMole', shortLabel: 'ScarMole' },
  { id: 10, label: 'Skin Tag', shortLabel: 'Skin Tag' },
  { id: 11, label: 'Stretch Marks', shortLabel: 'Stretch Marks' },
  { id: 12, label: 'Cellulite', shortLabel: 'Cellulite' },
  { id: 13, label: 'Fat loss', shortLabel: 'Fat loss' },
  { id: 14, label: 'Tattoo Removal', shortLabel: 'Tattoo Removal' },
  { id: 15, label: 'Neck Fat', shortLabel: 'Neck Fat' },
  { id: 16, label: 'Cyst', shortLabel: 'Cyst' },
  { id: 17, label: 'Something else', shortLabel: 'Something else' },
  { id: 18, label: 'Face', shortLabel: 'Face' },
  { id: 19, label: 'Hair', shortLabel: 'Hair' },
  { id: 20, label: 'Skin', shortLabel: 'Skin' },
  { id: 21, label: 'Body', shortLabel: 'Body' },
  { id: 22, label: 'Tattoo Removal', shortLabel: 'Tattoo Removal' },
  { id: 23, label: 'Face wrinkles', shortLabel: 'Face wrinkles' },
  { id: 24, label: 'Nose', shortLabel: 'Nose' },
  { id: 25, label: 'Lips', shortLabel: 'Lips' },
  { id: 26, label: 'Chin', shortLabel: 'Chin' },
  { id: 27, label: 'Skin on Face', shortLabel: 'Skin on Face' },
  { id: 28, label: 'Skin on Body', shortLabel: 'Skin on Body' },
  { id: 29, label: 'Neck', shortLabel: 'Neck' },
  { id: 30, label: 'Hair on Head', shortLabel: 'Hair on Head' },
  { id: 31, label: 'Forehead, including Eye brows', shortLabel: 'Forehead, including Eye brows' },
  { id: 32, label: 'Eyelids', shortLabel: 'Eyelids' },
  { id: 33, label: 'Mouth', shortLabel: 'Mouth' },
  { id: 34, label: 'Ears', shortLabel: 'Ears' },
  { id: 35, label: 'Breast', shortLabel: 'Breast' },
  { id: 36, label: 'Buttocks', shortLabel: 'Buttocks' },
  { id: 37, label: 'Genitalia', shortLabel: 'Genitalia' },
  { id: 38, label: 'Thighs', shortLabel: 'Thighs' },
  { id: 39, label: 'Abdominal region', shortLabel: 'Abdominal region' },
  { id: 40, label: 'Legs', shortLabel: 'Legs' }
];

export default {
  staticOptionsList,
};
