import React, { useState, useMemo, useRef, useEffect } from 'react';
import _ from 'lodash';
import images from 'data/images';
import './TagsInput.css';
import SwitchToggle from '../SwitchToggle';

const TagsInput = props => {
  const {
    label = '',

    options = [],
    isDisabled = false,
    onAdd,
    onRemove,
    onValidate = () => true,
    onInputBlur = () => { },
    labelIndex = null,
    valueIndex = null,
    error = null,
    className = '',
    checkStatus = false,
    showLabel = true,
    showSwitchToggle = false,
    subLabel = false,
    isProviderCare = false
  } = props;
  let [selectedValues, setSelectedValues] = useState([])
  let [localError, setLocalError] = useState(error)
  //let {selectedValues = []}= props;
  useEffect(() => {
    if (props.selectedValues) {
      if (showSwitchToggle && labelIndex == 'specialty') {
        let tempselectedValues = props.selectedValues
        tempselectedValues = tempselectedValues.map((item) => {
          let tempVal = { ...item }
          if (item.doNotDisplay == undefined)
            tempVal.doNotDisplay = false
          return tempVal
        })

        setSelectedValues(tempselectedValues)
      } else {
        setSelectedValues(props.selectedValues)
      }
    }
  }, [showSwitchToggle, labelIndex, props.selectedValues])
  const inputEl = useRef(null);
  const focusInput = () => {
    if (inputEl) {
      inputEl.current.focus();
    }
  };

  const [searchText, setSearchText] = useState('');

  // on enter handle
  const _handleKeyDown = e => {
    const value = e.target.value;
    if (options.length === 0 && e.key === 'Enter') {
      if (!selectedValues.includes(value) && onValidate(e.target.value)) {
        onAdd(value);
        setSearchText('');
      }
      e.preventDefault();
    }
  };

  const getText = (objOrVal, index) => {
    if (index) {
      return objOrVal[index];
    } else {
      return objOrVal;
    }
  };

  const getClass = objOrVal => {

    let cssClass = '';
    if (checkStatus) {
      switch (objOrVal.status) {
        case 0: // pending
          cssClass = 'pending-approval';
          break;
        case 2: // rejected
          cssClass = 'rejected-approval';
          break;
        default:
          break;
      }
    }
    return cssClass;
  };

  const getStatusIcon = objOrVal => {
    let imgIcon = images.blueCross;
    if (checkStatus) {
      switch (objOrVal.status) {
        case 0: // pending
          imgIcon = images.orangeCross;
          break;
        case 2: // rejected
          imgIcon = images.redCross;
          break;
        default:
          break;
      }
    }
    return imgIcon;
  };

  const onSearchBlur = () => {
    if (filteredList.length === 0) {
      setSearchText('');
    }
    onInputBlur(selectedValues);
  };
  const changeHandler = (event) => {
    const { name } = event.target;
    let { value } = event.target;
    let tempselectedValues = [...selectedValues]


    let changeIndex = tempselectedValues.findIndex((i) => i.id == name)
    if (changeIndex >= 0) {
      tempselectedValues[changeIndex].doNotDisplay = !value
      let trueVal = tempselectedValues.find((ii) => !ii.doNotDisplay)
      if (!trueVal) {
        setLocalError('Required at least one specialty for your profile.')
        return;
      } else if (isProviderCare) {
        trueVal = tempselectedValues.find((ii) => !ii.doNotDisplay && ii.status == 1);
        if (!trueVal) {
          setLocalError('Required at least one admin approved specialty visible for your profile.')
          return;
        }
      }
      setLocalError(null)
      onInputBlur(tempselectedValues);
    }

  }
  let filteredList = [];
  const selectedValuesArray = useMemo(() => {
    return _.map(selectedValues, val => {
      return getText(val, valueIndex);
    });
  }, [selectedValues, valueIndex]);

  if (options.length > 0) {
    filteredList = _.filter(options, option => {
      const valueText = getText(option, valueIndex);
      const labelText = getText(option, labelIndex);

      return (
        searchText !== '' &&
        !selectedValuesArray.includes(valueText) &&
        labelText.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }

  const componentWithEdit = (
    <div className="form-col">
      <div className="multiselectsearch-disabled">
        <div className="multiselect-result">
          {selectedValues.map(value => {
            return <div key={getText(value, valueIndex)}>{getText(value, valueIndex)}</div>;
          })}
        </div>
      </div>
    </div>
  );

  const componentWithoutEdit = (
    <div className="form-col">
      <div className="multiselectsearch-block" onClick={focusInput}>
        <div className="multiselect-result">
          {selectedValues.map((value, index) => {
            return (
              <div key={getText(value, valueIndex)} className={['each-tag', checkStatus ? getClass(value) : ''].join(' ')}>
                {getText(value, labelIndex)}{' '}
                <span style={{ display: 'inline-flex' }}>
                  {showSwitchToggle && (
                    <span className='only-Toggle'>
                      <SwitchToggle
                        showLabel={false}
                        //  {...getGeneralInputProps('sendAppointmentReminderEmail')}
                        name={value.id}

                        onChange={changeHandler}
                        value={!value.doNotDisplay ? 1 : 0}

                      />
                    </span>
                  )}
                  <img
                    onClick={() => { onRemove(value) }}
                    src={checkStatus ? getStatusIcon(value) : images.blueCross}
                    alt="remove"
                  />
                </span>
              </div>
            );
          })}
        </div>
        <div className="outer-suggetion-box">
          <input
            ref={inputEl}
            onChange={event => {
              setSearchText(event.target.value);
            }}
            onKeyDown={_handleKeyDown}
            onBlur={() => onSearchBlur()}
            value={searchText}
            type="text"
            placeholder={`Search ${label}`}
            className="form-input custom-input-box"
          />
          {filteredList.length > 0 && (
            <div className="autocomplete-box">
              {filteredList.map(value => {
                return (
                  <div
                    onClick={() => {
                      onAdd(value);
                      setSearchText('');
                    }}
                    key={value.id}>
                    {value[labelIndex]}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {localError && <span className="error-text">{localError}</span>}
    </div>
  );

  return (
    <div className={`TagsInput form-group ${className}`}>
      <div>
        {showLabel && (
          <div className="form-label">{label}</div>
        )}
        {subLabel && (
          <div>{subLabel}</div>
        )}
      </div>
      {isDisabled ? componentWithEdit : componentWithoutEdit}
    </div>
  );
};

export default TagsInput;
