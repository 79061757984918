import React, {useContext} from 'react';
import './AgreementTemplate.css';
import _ from 'lodash';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';

const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;

const AgreementTemplate = props => {
  const rootContext = useContext(RootContext);
  const {title, description, closeModal, lastModalConfig, onConfirm} = props;

  const onButtonClick = isConfirmed => {
    if (isConfirmed) {
      onConfirm(true);
    } else {
      if (!_.isEmpty(lastModalConfig)) {
        rootContext.setGlobal(
          'modal',
          blankModalData({
            ...lastModalConfig,
          }),
        );
      } else {
        closeModal();
      }
    }
  };

  return (
    <div className="modal-dialog agreement">
      <div className="modal-header">
        <div className="title">{title}</div>
      </div>
      <div className="modal-body">{description}</div>
      <div className="modal-footer">
        <button className="cancel-btn" onClick={() => onButtonClick(false)}>
          Cancel
        </button>
        <button className="cancel-btn" onClick={() => onButtonClick(true)}>
          I Agree
        </button>
      </div>
    </div>
  );
};

export default AgreementTemplate;
