import React, {useState} from 'react';
import AppointmentsList from 'components/AfterAuth/Dashboard/UpcomingAppointments/AppointmentsList';
import './UpcomingAppointments.css';

const maxUpcomingAppointments = 3;

const UpcomingAppointments = props => {
  const {appointments = []} = props;
  const [isShowAll, setIsShowAll] = useState(false);
  return (
    <div className="UpcomingAppointments">
      <div className="box-panel">
        <div className="box-header">
          <div className="heading3">Upcoming Appointments</div>
        </div>
        {appointments.length > 0 && (
          <div className="box-body">
            <AppointmentsList
              {...props}
              isShowAll={isShowAll}
              maxUpcomingAppointments={maxUpcomingAppointments}
            />
          </div>
        )}
        {appointments.length === 0 && (
          <div className="box-body no-records">
            No records found 
          </div>
        )}
        {!isShowAll && appointments.length > maxUpcomingAppointments && (
          <div
            className="box-footer"
            onClick={() => {
              setIsShowAll(true);
            }}>
            <span>See All</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingAppointments;
