import { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import AuthContext from 'context/AuthContext';
import rolePermission from 'data/rolePermission';
import scheduleService from 'services/schedule';
import momentHelper from 'data/moment/momentHelper';
import helper from 'data/helper';
import './ValidateAvailability.css';
import algoStorageHelper from 'data/algoStorage.helper';

const { permissionList, helperFunctions } = rolePermission;
const { getAllMyRoleIds, hasPermission } = helperFunctions;
const { getFilteredDoctors } = scheduleService;
const { dateFormat, isSameDay, endOf, dateOrCurrentDate } = momentHelper;
const { getAvailability, getLatestAvailabilityDate } = helper;

const { getAlgo } = algoStorageHelper

// validate availability,if it's invalid then redirect to home landing page
class ValidateAvailability extends Component {
  static contextType = AuthContext;
  additionalFilter = {
    isVirtual: false,
    date: null,
  };

  componentDidMount() {

    const { location } = this.props;
    const { auth } = this.context;
    let canBook = true;
    if (auth) {
      const roleIds = getAllMyRoleIds(auth);
      if (!hasPermission(roleIds, permissionList.FRONTEND_USER)) {
        canBook = false;
      }
    }

    if (canBook) {

      const query = new URLSearchParams(location.search);
      const isVirtual = query.get('isVirtual') === 'true' ? true : false;
      const providerId = parseInt(query.get('providerId'));
      const date = query.get('date')
      const pLocationId = query.get('pLocationId')
      const momentDate = moment.utc(date, 'YYYY-MM-DD HH:mm:ss', true);
      const appointmentSource = query.get('appointmentSource')
      const accessLogProviderId = query.get('accessLogProviderId');
      const accessLogPracticeId = query.get('accessLogPracticeId');
      //console.log("momentDate",momentDate.format('YYYY-MM-DD HH:mm:ss'));
      if (momentDate.isValid() && providerId) {
        this.additionalFilter = {
          ...this.additionalFilter,
          isVirtual,
          date: momentDate,
        };
        const localStartDate = momentDate.clone();
        let utcStartDateString = dateFormat(momentDate.clone().startOf('day').utc());
        let datesObj = {};
        datesObj[dateFormat(localStartDate)] = [];
        let extraDay = 0;
        if (isSameDay(dateFormat(localStartDate.clone().subtract(1, 'days').startOf('day').utc()))) {
          extraDay = 1;
          datesObj[dateFormat()] = [];
          localStartDate.subtract(1, 'day');
          utcStartDateString = dateFormat(momentDate.clone().subtract(1, 'days').startOf('day').utc());
          localStartDate.add(extraDay, 'day');
          datesObj[dateFormat(localStartDate.add(1, 'day'))] = [];
        } else {
          let startDateTemp = dateOrCurrentDate();
          let startDateString = moment(momentDate).clone().add(1, 'days')
          let diff = startDateString.diff(startDateTemp, 'days')
          datesObj[dateFormat(startDateTemp)] = [];
          for (let i = 1; i <= diff; i++) {
            startDateTemp.add(1, 'day');
            datesObj[dateFormat(startDateTemp)] = [];
          }

        }
        // add extra day if removed 1 day previously

        const filter = {
          startDate: dateFormat(moment().clone().subtract(1, 'days')),
          endDate: dateFormat(endOf('day', localStartDate.clone().add(1, 'days')).utc()),
          providerId,
          pLocationId: pLocationId,
          date: date,
          isVirtual: isVirtual,
          appointmentSource: appointmentSource,
          accessLogProviderId: accessLogProviderId,
          accessLogPracticeId: accessLogPracticeId
        };

        this.getAndvalidateDoctorAvailibility(filter, datesObj);
      } else {
        // invalid URL

        this.props.history.replace('/');
      }
    } else {
      this.props.history.replace('/');
    }
  }

  getAndvalidateDoctorAvailibility = async (filter, datesObj) => {
    const response = await getFilteredDoctors(filter).catch(() => null);
    if (response) {
      const { data } = response;
      let updatedData = data.map(item => {
        const { providerAvailabilities, newAppointmentTime, existingAppointmentTime, patientAppointments = [], timezone = null } = item;
        const api_isVirtual = item.isVirtual;
        const api_latestAvailability = item.latestAvailability
        const newAppointmentTimeorExAT = existingAppointmentTime > newAppointmentTime ? existingAppointmentTime : newAppointmentTime
        let nextOpenDate = getLatestAvailabilityDate(api_isVirtual, api_latestAvailability, providerAvailabilities,
          newAppointmentTimeorExAT,
          patientAppointments,
          timezone);
        const updatedAvailabilities = getAvailability(
          providerAvailabilities,
          datesObj,
          newAppointmentTimeorExAT,
          patientAppointments,
          timezone,
          nextOpenDate
        );
        let isAvailable = false;
        _.forEach(updatedAvailabilities, value => {
          if (!isAvailable && value.length > 0) {
            isAvailable = true;
          }
        });
        item.isAvailable = isAvailable;
        item.formatedAvailabilities = updatedAvailabilities;
        return item;
      });
      // remove doctor from list if doctor has no availability
      updatedData = data.filter(item => {
        let isAvailable = false;
        if (item.isAvailable) {
          if (this.additionalFilter.isVirtual) {
            isAvailable = item.isVirtual;
          } else {
            isAvailable = !item.isVirtual;
          }
        }
        return isAvailable;
      });
      let dateAndTimeObject = null;
      let mainDoctor = null;
      if (updatedData.length > 0) {
        _.forEach(updatedData, doctor => {
          const { formatedAvailabilities } = doctor;
          _.forEach(formatedAvailabilities, (availabilities, dateString) => {
            _.forEach(availabilities, av => {
              const avDateObj = moment(`${dateString} ${av.time}`, 'YYYY-MM-DD h:mm a').utc();
              //if (avDateObj.isSame(this.additionalFilter.date)) {
              //console.log("avDateObj.format('YYYY-MM-DD HH:mm:ss')",avDateObj.format('YYYY-MM-DD HH:mm:ss'));
              //  console.log("this.additionalFilter.date.format('YYYY-MM-DD HH:mm:ss')",this.additionalFilter.date.format('YYYY-MM-DD HH:mm:ss'));
              if (avDateObj.format('YYYY-MM-DD HH:mm:ss') === this.additionalFilter.date.format('YYYY-MM-DD HH:mm:ss')) {
                mainDoctor = doctor;
                dateAndTimeObject = {
                  dateString: dateString,
                  timeObject: av,
                };
              }
            });
          });
        });
      }
      if (dateAndTimeObject) {
        this.redirectToAppointmentPage(dateAndTimeObject, mainDoctor);
      } else if (updatedData.length > 0 && updatedData.find((d) => d.providerId == filter.providerId)) {
        let selectedD = null;
        if (filter.pLocationId && !filter.isVirtual)
          selectedD = updatedData.find((d) => d.providerId == filter.providerId && d.locationId == filter.pLocationId)
        else
          selectedD = updatedData.find((d) => d.providerId == filter.providerId)
        this.props.history.push({
          pathname: '/',
          state: {
            doctordetails: selectedD, pLocationId: filter.pLocationId, isVirtual: filter.isVirtual,
            date: filter.date, appointmentSource: filter.appointmentSource,
            accessLogProviderId: filter.accessLogProviderId,
            accessLogPracticeId: filter.accessLogPracticeId
          }
        }
        )

      } else {
        this.props.history.replace('/');
      }
    } else {
      this.props.history.replace('/');
    }
  };

  redirectToAppointmentPage = (dateAndTimeObject, doctorDetail) => {
    let algoCookie = getAlgo();
    //&& algoCookie.providerId===doctorDetail.providerId
    if (algoCookie && doctorDetail && algoCookie.providerId != doctorDetail.providerId) {
      algoCookie = null
    }
    const query = new URLSearchParams(window.location.search);
    const appointmentSource = query.get('appointmentSource');
    const accessLogProviderId = query.get('accessLogProviderId');
    const accessLogPracticeId = query.get('accessLogPracticeId');
    let appointmentSourceUrl = appointmentSource ? `?appointmentSource=${appointmentSource}` : '';
    const accessLog = accessLogProviderId ? `accessLogProviderId=${accessLogProviderId}` : accessLogPracticeId ? `accessLogPracticeId=${accessLogPracticeId}` : '';
    appointmentSourceUrl = appointmentSourceUrl ? `${appointmentSourceUrl}&${accessLog}` : `?${accessLog}`
    if (algoCookie) {
      this.props.history.replace({
        pathname: `/confirm-appointment`,
        search: appointmentSourceUrl,
        state: {
          ...algoCookie,
          doctorDetail,
          dateAndTimeObject,
        },
      });
    } else {
      this.props.history.replace({
        pathname: '/doctor',
        search: appointmentSourceUrl,
        state: {
          filter: {
            selectedResult: {
              ...doctorDetail,
            },
          },
          dateAndTimeObject,
        },
      });
    }
    return;
  };

  render() {
    return null;
  }
}

export default ValidateAvailability;
