import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import validators from 'data/validators';
import allRegex from 'data/regex';
import config from 'config/config';

import { loadStripe } from '@stripe/stripe-js';

import './PaymentSession.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import images from 'data/images';
import Button from 'components/Button';
import StripeInput from '../AboutYourPractice/AboutYourPracticeStepTwo/StripeInput';
import { Elements } from '@stripe/react-stripe-js';
import helper from 'data/helper';
import userService from "services/user"
import Loader from 'components/Loader';
import { isObject } from 'lodash';
const { getStripeProducts, verifyPromoCode } = userService
const { scrollToDiv } = helper;
const { exceptDigitsRegex } = allRegex;
const { __required, __phoneNumber, __email, __maxLength } = validators;
const { MAX_TEXT_LENGTH } = config;
const { Analytics } = analyticsData;

const PaymentSession = props => {
  const { nextStep, data, email = '', isSubscription = false, onScrollView = null, hideTrail = null, hideInputInput = false } = props;

  const values = { ...data };
  const { subStep1Data } = values
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });

  //====| START: Hide Stripe Code |====
  const [isPaymentValid, setPaymentValid] = useState(false);
  const [stripePaymentMethod, setStripePaymentMethod] = useState();
  const [selectedPlan, setSelectedPlan] = useState(false);
  const [applyPromoCode, setApplyPromoCode] = useState(false);
  const [promotionCodeText, setPromotionCodeText] = useState('');
  const [trailPeriodGlobal, setTrailPeriodGlobal] = useState(30);
  const [priceGlobal, setPriceGlobal] = useState(0);
  const [displayAmount, setDisplayAmount] = useState(0);
  const [disCountGlobal, setDisCountGlobal] = useState(0);

  //====| END: Hide Stripe Code |====
  const [productList, setProductList] = useState([]);
  const { values: formValues, errors: formErrors } = formData;
  const stripePromise = loadStripe(config.StripePublishKey);
  useEffect(() => {
    const chekcfn = async () => {
      let dataList = await getStripeProducts()
      let { data } = dataList
      if (data) {
        if (!email.toLowerCase().endsWith('kaly.com')) {
          data = data.filter((d) => d.name.toLowerCase().search('demo') < 0)
        }

        setProductList(data)
      }

    }
    chekcfn()

  }, [])
  useEffect(() => {
    if (selectedPlan) {
      const { price } = selectedPlan
      let unit_amount = price.unit_amount;
      unit_amount = unit_amount ? unit_amount / 100 : 0
      if (applyPromoCode && isObject(applyPromoCode)) {


        let freeTrail = 30
        let { internalCode, trialDays, discount } = applyPromoCode

        if (internalCode) {
          freeTrail = trialDays
        }
        if (trailPeriodGlobal !== freeTrail) {
          setTrailPeriodGlobal(freeTrail)
        }
        let disAmount = unit_amount
        if (discount) {
          disAmount = (unit_amount * discount) / 100
          setDisplayAmount(disAmount)
          setDisCountGlobal(discount)
        } else {
          setDisplayAmount(unit_amount)
        }
      } else {
        if (displayAmount != unit_amount) {
          setDisplayAmount(unit_amount)
        }
      }

      if (priceGlobal !== unit_amount) {
        setPriceGlobal(unit_amount)

      }
    }

  }, [applyPromoCode, selectedPlan])
  const _verifyPromoCode = async () => {
    const dataApi = await verifyPromoCode({ promotionCode: promotionCodeText }).catch((error) => {
      if (error.message)
        setApplyPromoCode(error.message)
    });
    if (dataApi) {
      let { data } = dataApi
      setApplyPromoCode(data)
    }

  }
  const __validateForm = () => {

    if (config.showStripe === true) {
      if (priceGlobal === 0||hideInputInput) {
        return selectedPlan
      }

      return (
        selectedPlan &&
        isPaymentValid
        //====| END: Hide Stripe Code |====
      );
    }


  };
  const isValidForm = __validateForm();



  const handleSubmit = () => {
    Nux.sendEvent(
      Analytics.Category.DoctorRegistration,
      Analytics.Actions.Click,
      'completePlanSelection',
    );
    let passData = {
      productId: selectedPlan.id,

    }
    if (subStep1Data && subStep1Data.name) {
      passData['name'] = subStep1Data.name
    }
    if (promotionCodeText && applyPromoCode && isObject(applyPromoCode)) {
      passData['promotionCode'] = promotionCodeText
    }
    if (priceGlobal > 0&&!hideInputInput) {
      passData['paymentMethodId'] = stripePaymentMethod.id;
    }
    //====| START: Hide Stripe Code |====
    //if (config.showStripe === true)
    //formValues['paymentMethodId'] = stripePaymentMethod.id;
    //====| END: Hide Stripe Code |====
    nextStep(passData);
  };



  const emailLabel = (
    <>
      <span>Email for appointment notifications</span>
      <span className="inner-label"> (not visible)</span>
    </>
  );
  const contactLabel = (
    <>
      <span>Contact Person</span>
      <span className="inner-label"> (not visible)</span>
    </>
  );
  const ProductCard = (props) => {
    const { data, planNo } = props


    const { name, subscribeText = "Select Plan", description, price } = data
    let unit_amount = price.unit_amount;
    unit_amount = unit_amount ? unit_amount / 100 : 0
    return (
      <div onClick={() => {
        setSelectedPlan(data);
        setApplyPromoCode(false)
        setPromotionCodeText('')
        setTimeout(() => {
          if (!onScrollView)
            scrollToDiv('scroll-stripeCard')
          else
            onScrollView('scroll-stripeCard')
        }, 500);
      }} className={['plan-card', planNo == selectedPlan.id ? 'active' : ''].join(' ')}>

        <div className='tilte'>
          <h3>{name}</h3>
        </div>
        <div className='discription'>
          <p>{description}</p>
        </div>
        <div style={{ marginTop: "auto" }}>
          <div className='pricing'>
            {(selectedPlan.id == planNo && promotionCodeText && disCountGlobal && applyPromoCode && isObject(applyPromoCode) && applyPromoCode.discount) ? (
              <>
                <h3 className='main-price'>${parseFloat(displayAmount).toFixed(2)} <span className='price-label'><span>per</span> <span>month</span></span></h3>
                <del className='delelement'><h3>${parseFloat(unit_amount).toFixed(2)} <span className='price-label'><span>per</span> <span>month</span></span></h3></del>
              </>
            ) : (
              <h3>${parseFloat(unit_amount).toFixed(2)} <span className='price-label'><span>per</span> <span>month</span></span></h3>
            )}

          </div>
          <div>
            <button type='button' className="subscribe">
              {subscribeText}
            </button>
          </div>
          {/* <div className='subtitle'>
            <h4>This includes</h4>
          </div>
          <div className='product-info'>
            <ul>
              <li><img className='bullet' src={images.checkGreen} /><span className='text'>Package pricing</span></li>
              <li><img className='bullet' src={images.checkGreen} /><span className='text'>Package pricing</span></li>
              <li><img className='bullet' src={images.checkGreen} /><span className='text'>Package pricing</span></li>
              <li><img className='bullet' src={images.checkGreen} /><span className='text'>Package pricing</span></li>
              <li><img className='bullet' src={images.checkGreen} /><span className='text'>Package pricing</span></li>
            </ul>
          </div>*/}
        </div>

      </div>
    )
  }
  const Promobaner = () => {
    return <div className='promobaner'>
      <h4>Kaly Early Adopters Promotion</h4>
      <h6>Use PROMO CODE:  <b>KALYFREETRIAL</b></h6>
      <p>For a limited time, we are extending our 30 day free trial to 90 days for early adopters. Valid for new subscriptions. </p>
    </div>
  }
  const Selectedplandisplay = (props) => {
    const { data, applyPromoCode } = props
    const { name, price } = data
    let unit_amount = price.unit_amount;
    unit_amount = unit_amount ? unit_amount / 100 : 0
    let freeTrail = 30
    let isApplyPromoCode = false
    let disAmount = 0
    if (applyPromoCode && isObject(applyPromoCode)) {

      let { internalCode, trialDays, discount } = applyPromoCode
      isApplyPromoCode = true
      if (internalCode) {
        freeTrail = trialDays
      }
      if (discount) {
        disAmount = (unit_amount * discount) / 100
      }
    }

    return <div className='selectedPlan'>
      <div>
        <label>Selected Plan:</label>
        <label>{name}</label>
      </div>
      <div>
        <label>Price:</label>
        {/* {isApplyPromoCode && disAmount > 0 ? (
          <label><del style={{ color: "red" }}>${parseFloat(unit_amount).toFixed(2)}</del> ${parseFloat(disAmount).toFixed(2)} <span className='price-label'><span>per</span> <span>month</span></span></label>
        ) : ( */}
        <label>${parseFloat(displayAmount).toFixed(2)} <span className='price-label'><span>per</span> <span>month</span></span></label>
        {/* )} */}

      </div>
      {!hideTrail  && (
        <div>
          <label>Free Trial:</label>
          {isApplyPromoCode && freeTrail > 30 ? (
            <label><del style={{ color: "red" }}>30 Days</del> {freeTrail} Days</label>
          ) : (
            <label>{freeTrail} Days</label>
          )}

        </div>
      )}
      {hideTrail && freeTrail>30  && (
        <div>
          <label>Free Trial:</label>
        
            <label>{freeTrail} Days</label>
          
        </div>
      )}
    </div>
  }
  if (productList.length == 0) {
    return (
      <div className="paymentSession">
        <div className="h3">Plan Selection</div>
        <Loader />
      </div>
    )
  }
  return (
    <div className="paymentSession">
      <div className="h3">Plan Selection</div>

      <form className="signup-form">
        <div className='product-list'>
          {productList.map((item) => (
            <ProductCard planNo={item.id} key={item.id} data={item} />
          ))}

          {/* <ProductCard planNo="2" title={'Kaly Provider -Monthly'} subscribeText={'Select Plan'} price={'299.00'} /> */}
          {/* <ProductCard planNo="3" title={'Kaly Demo User'} subscribeText={'Start Demo'} price={'00.0'}/> */}
        </div>
        {!isSubscription && (
          <Promobaner />
        )}
        {selectedPlan && (
          <>
            <div className='selectedPlanParent'>
              <Selectedplandisplay applyPromoCode={applyPromoCode} data={selectedPlan} />
            </div>
            {priceGlobal > 0 && (
              <>
                <label className='elementlabel'>Promo Code</label>
                <div className='promocodediv'>
                  <div>
                    <input type="text"
                      name="PROMOCODE"
                      autoComplete='false'
                      label={'Promo Code'}
                      placeholder={'Promo Code'}
                      onChange={(e) => {
                        setPromotionCodeText(e.target.value);
                        setApplyPromoCode(false);
                        setDisplayAmount(priceGlobal)
                      }}
                      value={promotionCodeText}
                    />
                    {promotionCodeText && applyPromoCode && (
                      <p className={!isObject(applyPromoCode) ? 'error' : 'success'}><i>{isObject(applyPromoCode) ? 'Promo Code Applied' : applyPromoCode}</i></p>
                    )}
                  </div>
                  <button disabled={!promotionCodeText} type="button" onClick={() => _verifyPromoCode()}>Apply</button>
                </div>
                <div className='stripe-ccinput'>
                  {!hideInputInput && (
                    <Elements stripe={stripePromise}>
                      <StripeInput
                        {...props}
                        trailPeriodGlobal={trailPeriodGlobal}
                        setPaymentValid={setPaymentValid}
                        setStripePaymentMethod={setStripePaymentMethod}
                      />
                    </Elements>
                  )}
                </div>
              </>
            )}



            <div className="signup-btn scroll-stripeCard">
              <CircleButton disabled={!isValidForm} onClick={handleSubmit} btnType="whiteButton">
                Continue
              </CircleButton>
            </div>
          </>
        )}

      </form>
    </div>
  );
};

export default PaymentSession;
