import React from 'react';
import momentHelper from 'data/moment/momentHelper';


const {timeZoneAbbreviated}=momentHelper
const TimeZoneLabelShort = props => {
  
  return (
    <small>{` (${timeZoneAbbreviated()})`}</small>
  );
};

export default TimeZoneLabelShort;
