import React, {useState, useContext} from 'react';
import RootContext from 'context/RootContext';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import validators from 'data/validators';
import accessHelper from 'data/kalypsys.helper';
import './AccessChecker.css';

const {__required} = validators;
const {testAndSetKalyData} = accessHelper;

const AccessChecker = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const [formData, setFormData] = useState({
    values: {},
    errors: {serverError: null},
  });
  const {values: formValues, errors: formErrors} = formData;

  const __validateAccessKey = (value = null) => {
    const fValue = value !== null ? value : formValues['accessKey'];
    if (!__required(fValue)) {
      return 'Access Key is required';
    }
    return null;
  };

  const changeHandler = event => {
    let {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'accessKey':
        errorObject = {
          [name]: __validateAccessKey(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value.trim(),
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const __validateForm = () => {
    const validAccessKey = !__validateAccessKey();
    return validAccessKey;
  };
  const isValidateForm = __validateForm();

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">
        <img src={images.logosymbol} className="logo" alt="logo" /> Kaly
      </div>
    </div>
  );

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  const handleSubmit = event => {
    event.preventDefault();
    let res = testAndSetKalyData(formValues['accessKey']);
    if (res.result) {
      setGlobal('modal', {showModal: false});
      props.onSuccess();
    } else {
      let errorObject = {
        accessKey: res.message,
      };
      setFormData({
        ...formData,
        errors: {
          ...formData.errors,
          ...errorObject,
        },
      });
    }
  };

  return (
    <div className="AccessChecker no-select">
      <div className="modal-body">
        {dialogHeader}
        <div className="dailog-body">
          <form onSubmit={handleSubmit}>
            <Input
              {...getGeneralInputProps('accessKey')}
              placeholder="Enter your access key"
              label="Access Key"
              type="password"
              icon={images.eyeView}
            />

            <div className="action-btn">
              <CircleButton btnType="whiteButton" disabled={!isValidateForm} onClick={handleSubmit}>
                Submit
              </CircleButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccessChecker;
