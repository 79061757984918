const allRegex = {
  //email regex link:- https://stackoverflow.com/a/46181/7160641
  // eslint-disable-next-line
  emailRegex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  exceptDigitsRegex: /[^\d]/g,
  // passwordRegex : one lowercase,uppercase,digit,special character and must be 8 character long
  passwordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  imageTypeRegex: /image-*/,
  alphabetsOnlyRegex: /^[a-z]+$/i,
  alphabetsHyphenOnlyRegex: /^[a-z-]+$/i,
  nameRegex: /^[A-Z a-z-]+$/i,
  numberOnly:/^[0-9]+$/i,
  urlSlugRegex:/^[0-9-a-z]+$/i,
  timeHHmmttRegex: /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9] [aApP][mM]$/,
  phonenumberRegex:/^[\+]?[(]?[2-9]{1}[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
  // urlRegex: /^(.*)([\w]{1,})(\.([\w]{1,})){1,}$/,
  // urlRegex: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
};
export default allRegex;
