import _ from 'lodash';
import questionnaires from 'data/questionnaires';

const {allQuestionList} = questionnaires;

//helper functions
const getQuestionById = questionId => {
  const currentQuestion = _.find(allQuestionList, {id: parseInt(questionId)});
  return currentQuestion;
};
export const getHideQuestionID=()=>{
  return [343,263,2306,567,384,364,404,583,424,443,570,573,484,504,284,576,303,323,524,543,579,564,246,247]
}
export default {
  getQuestionById,
};
