import React, {useState} from 'react';
import images from 'data/images';
import './RecipientSourceList.css';

const RecipientSourceList = props => {
  const [active, toggleActive] = useState(null);
  return (
    <div className="RecipientSourceList">
      {/* <Overlay className="overlay" show={!!activeId} onClick={() => handleSetActive(0)} /> */}
      <div className="rtable">
        <div className="rtable-row rtable-row--head">
          <div className="rtable-cell name-cell column-heading">Name </div>
          <div className="rtable-cell tempalte-cell column-heading">Status</div>
          <div className="rtable-cell type-cell column-heading">Source</div>
          <div className="rtable-cell usage-cell column-heading">Last Activity</div>
          <div className="rtable-cell action-cell column-heading"></div>
        </div>
        <div className="rtable-row">
          <div className="rtable-cell name-cell content-cell">Amr Hosny</div>
          <div className="rtable-cell tempalte-cell content-cell">Delivered</div>
          <div className="rtable-cell type-cell content-cell">Text</div>
          <div className="rtable-cell usage-cell content-cell">Dec 19 2020</div>
          <div className="rtable-cell action-cell content-cell">
            <div className={`action-menu ${active ? 'show-menu' : ''}`}>
              <span
                className="action-btn"
                onClick={() => {
                  toggleActive(!active);
                }}>
                <img src={images.threeDot} alt="" />
              </span>
              <span className="dropdown-menu">
                <span>Edit Contact</span>
                <span className="remove-user">Delete Contact</span>
              </span>
            </div>
          </div>
        </div>
        <div className="rtable-row">
          <div className="rtable-cell name-cell content-cell">Amr Hosny</div>
          <div className="rtable-cell tempalte-cell content-cell">Delivered</div>
          <div className="rtable-cell type-cell content-cell">Text</div>
          <div className="rtable-cell usage-cell content-cell">Dec 19 2020</div>
          <div className="rtable-cell action-cell content-cell">
            <div className="action-menu">
              <span className="action-btn">
                <img src={images.threeDot} alt="" />
              </span>
              <span className="dropdown-menu">
                <span>Refresh Reviews</span>
                <span>Edit</span>
                <span className="remove-user">Delete</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipientSourceList;
