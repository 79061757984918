import React, {useState, useEffect, useRef} from 'react';

const VideoParticipant = ({participant, isMain, setRemoteMuteVideo, otherClass}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  //const [error, setError] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackPubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);

  useEffect(() => {
    setVideoTracks(trackPubsToTracks(participant.videoTracks));
    setAudioTracks(trackPubsToTracks(participant.audioTracks));

    const trackSubscribed = track => {
      if (track.kind === 'video') {
        track.on('disabled', () => {
          setRemoteMuteVideo(true);
        });
        track.on('enabled', () => {
          setRemoteMuteVideo(false);
        });

        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant, setRemoteMuteVideo]);

  useEffect(() => {
    console.log("videoTracks",videoTracks);
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      
      videoTrack.attach(videoRef.current);
      return () => {
        
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack && audioTrack.attach) {
      audioTrack.attach(audioRef.current);
      return () => {
        if(audioTrack.detach)
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <>
      <video
        ref={videoRef}
        autoPlay={true}
        className={isMain ? `main-participant ${otherClass}` : otherClass}
      />
      <audio ref={audioRef} autoPlay={true} />
    </>
  );
};

export default VideoParticipant;
