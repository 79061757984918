import React, {useState, useEffect} from 'react';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import validators from 'data/validators';
import './ProviderAccount.css';

const {__required, __email} = validators;

const ProviderOverview = props => {
  const {data, onSaveProviderAccount} = props;
  const errors = {};
  const [formData, setFormData] = useState({
    values: data,
    errors,
  });
  const {values: formValues, errors: formErrors} = formData;
  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        values: data,
        errors: {},
      };
    });
  }, [data]);

  // Validation functions
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    return null;
  };

  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    return null;
  };

  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };

  const __validateForm = () => {
    const validFirstName = !__validateFirstName();
    const validLastName = !__validateLastName();
    const validEmail = !__validateEmail();
    return validFirstName && validLastName && validEmail;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleForm = async () => {
    onSaveProviderAccount(formValues);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  return (
    <div className="ProviderAccount">
      <div className="titleHeading">
        <div className="h3">Provider Account</div>
        <CircleButton disabled={!isValidForm} onClick={handleForm}>
          Save
        </CircleButton>
      </div>
      <div className="ProviderAccount-inner">
        <form>
          <Input
            {...getGeneralInputProps('firstName')}
            label="First Name"
            placeholder="First Name"
          />
          <Input {...getGeneralInputProps('lastName')} label="Last Name" placeholder="Last Name" />
          <Input {...getGeneralInputProps('email')} label="Email" placeholder="Email" />
        </form>
      </div>
    </div>
  );
};

export default ProviderOverview;
