import React, {useState, useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import images from 'data/images';
import validators from 'data/validators';
import allRegex from 'data/regex';
import RootContext from 'context/RootContext';
import config from 'config/config';
import helper from 'data/helper';
import './Joinkalypsys.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import modalConfig from 'modals/modalConfig';
const {exceptDigitsRegex, alphabetsHyphenOnlyRegex} = allRegex;
const {__required, __phoneNumber, __email, __maxLength} = validators;
const {MAX_TEXT_LENGTH} = config;
const {scrollToDiv} = helper;
const {Analytics} = analyticsData;
const {errorModalData} = modalConfig.modalFunctions;
const ScreenTwo = props => {
  const {getGeneralInputProps,specialties,formValues}=props
 const successmessageStyle={
   backgroundImage: `url(${images.okstar})`,
   
 }
  return (
    <div className="split-screeninput">
      <div style={successmessageStyle} className="successmessage">
<img src={images.ok} alt={'ok'}/>
      </div>
      <h5 className="great-text">Great!
Almost done.</h5>
<p className="infotext">We just need a little more information!</p>
    </div>
  );
};

export default ScreenTwo;
