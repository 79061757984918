import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import images from 'data/images';
import validators from 'data/validators';
import allRegex from 'data/regex';
import RootContext from 'context/RootContext';
import config from 'config/config';
import helper from 'data/helper';
import './Joinkalypsys.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import modalConfig from 'modals/modalConfig';
import Checkbox from 'components/FormControls/Checkbox';
import constants from 'data/constants';
const {StaticFooterPage} = constants;
const NPI_LINK = 'https://npiregistry.cms.hhs.gov/';
const { exceptDigitsRegex, alphabetsHyphenOnlyRegex } = allRegex;
const { __required, __phoneNumber, __email, __maxLength } = validators;
const { MAX_TEXT_LENGTH } = config;
const { scrollToDiv } = helper;
const { Analytics } = analyticsData;
const { errorModalData } = modalConfig.modalFunctions;
const ScreenThree = props => {
  const { getGeneralInputProps,chekcTC,ontcChange, photoidPreview, handleFileSelection, imageName } = props;
  
  return (
    <div className="split-screeninput">
      <Input
        mask="(999) 999-9999"
        maskChar=" "
        type="tel"
        InputComponent={InputMask}
        {...getGeneralInputProps('phoneNumber')}
        label="Phone Number  eg. (480) 123 4567"
      />
      <Input {...getGeneralInputProps('zipCode')} type="text" label="Practice Zip Code" />
      <Input {...getGeneralInputProps('npiNumber')} type="text" label="NPI Number" linkData={{ externalLink: NPI_LINK, label: 'Look Up' }} />
      <div className="Input-control  form-group">
        <span className="label">
          Photo ID
          <span className="info-providers-photoid">
            {' '}
            (i.e. Driver's license or hospital ID card). We use this to verify the identity of our providers.
          </span>
        </span>
        <div className="fileupload">
          <div className={['preview-img', !photoidPreview ? '' : 'preview-img-multy'].join(' ')}>
            {photoidPreview && <img src={photoidPreview} />}
            <span>{imageName}</span>
          </div>

          <span className="fileupload-btn">
            <input
              type="file"
              onChange={handleFileSelection}
              onClick={event => {
                event.target.value = null;
              }}
            />

            <button>Choose file</button>
          </span>
        </div>
        <div style={{ marginTop: "30px" }} className="checkbox-block">
          <Checkbox onChange={() => ontcChange(!chekcTC)} value={chekcTC} >I consent to receiving notifications through SMS messages.  Message and data rates may apply.  Reply with STOP at any time to cancel.  I have read and accept the kaly.com 
          <a
                href={config.APP_DOMAIN + StaticFooterPage.TERMS_CONDITION}
                target="_blank"
                style={{position:"relative",display:"inline-block"}}
                rel="noopener noreferrer">
                Terms & Conditions
              </a> and <a
                href={config.APP_DOMAIN + StaticFooterPage.PRIVACY_POLICY}
                target="_blank"
                style={{position:"relative",display:"inline-block"}}
                rel="noopener noreferrer">
                Privacy Policy
              </a>.</Checkbox>

        </div>
      </div>
    </div>
  );
};

export default ScreenThree;
