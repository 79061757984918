import React, {useContext} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import ProgressBar from 'components/FormControls/ProgressBar';
import AuthContext from 'context/AuthContext';
import rolePermission from 'data/rolePermission';
import './FinishProfile.css';

const {permissionList, helperFunctions} = rolePermission;
const {getAllMyRoleIds, hasPermission} = helperFunctions;

const FinishProfile = props => {
  const authContext = useContext(AuthContext);
  const auth = _.get(authContext, 'auth');
  const providerId = _.get(authContext, 'auth.provider.id', '');
  const {profileStatusData} = props;
  const roleIds = getAllMyRoleIds(auth);
  const canViewFinishProfile = hasPermission(roleIds, permissionList.CAN_VIEW_FINISH_PROFILE);
  if (!profileStatusData || !canViewFinishProfile) {
    return null;
  }
  const {
    isPracticeUpdated = false,
    isProviderUpdated = false,
    isVisitReasonUpdated = false,
    isAvailabilityUpdated = false,
    isConditionUpdate = false,
    isProcedureUpdate = false,
  } = profileStatusData;
  const links = [
    {
      to: '/settings?tab=practice',
      label: 'Review and edit practice profile',
      status: isPracticeUpdated,
    },
    {
      to: '/settings?tab=providers',
      label: 'Review and edit provider profile',
      status: isProviderUpdated,
    },
    {
      to: `/settings?tab=providers&&profile=${providerId}`,
      label: 'Add provider conditions',
      status: isConditionUpdate,
    },
    {
      to: `/settings?tab=providers&&profile=${providerId}`,
      label: 'Add provider procedures',
      status: isProcedureUpdate,
    },
    {
      to: `/settings?tab=providers&&profile=${providerId}&&visitReason=true`,
      label: 'Add Visit Reasons',
      status: isVisitReasonUpdated,
    },
    {
      to: '/schedule',
      label: 'Add/Edit your Availability',
      status: isAvailabilityUpdated,
    },
    // {
    //   to: `/settings?tab=widget`,
    //   label: 'Add Booking Widget',
    //   status: false,
    // },
  ];
  let fullProfileCompletePercentage = 0;
  const component = links.map((linkObject, i) => {
    if (linkObject.status) {
      fullProfileCompletePercentage += 25;
      return (
        <span key={i} className="completed">
          <span>
            {i + 1}. {linkObject.label}
          </span>
        </span>
      );
    } else {
      return (
        <span key={i}>
          <Link to={linkObject.to}>
            {i + 1}. {linkObject.label}
          </Link>
        </span>
      );
    }
  });
  let checkAnyOnePending=links.find((L)=>!L.status)
  if(!checkAnyOnePending){
return null
  }
  return (
    <div className="FinishProfile">
      <div className="box-panel">
        <div className="box-header">
          <div className="heading3">Finish your profile </div>
          <ProgressBar percentage={fullProfileCompletePercentage}></ProgressBar>
        </div>
        <div className="box-body">{component}</div>
      </div>
    </div>
  );
};

export default FinishProfile;
