import _ from 'lodash';

const permissionList = {
  ADMIN: 'ADMIN',
  FRONTEND_USER: 'FRONTEND_USER',
  CAN_SWITCH_USER: 'CAN_SWITCH_USER',
  BACKEND_USER: 'BACKEND_USER',
  CAN_EDIT_PRACTICE: 'CAN_EDIT_PRACTICE',
  GET_PRACTICE: 'GET_PRACTICE',
  GET_PRACTICE_MEMBERS: 'GET_PRACTICE_MEMBERS',
  CAN_INVITE_PROVIDER: 'CAN_INVITE_PROVIDER',
  CAN_VIEW_FINISH_PROFILE: 'CAN_VIEW_FINISH_PROFILE',
  CAN_EDIT_STAFF: 'CAN_EDIT_STAFF',
};

const roleList = [
  {id: 0, permissions: []},
  {
    id: 1,
    name: 'Admin',
    permissions: [
      permissionList.ADMIN,
      // permissionList.BACKEND_USER,
      // permissionList.CAN_EDIT_PRACTICE,
      // permissionList.GET_PRACTICE,
      // permissionList.GET_PRACTICE_MEMBERS,
      // permissionList.CAN_EDIT_STAFF,
    ],
  },
  {id: 2, name: 'Patient', permissions: [permissionList.FRONTEND_USER]},
  {
    id: 3,
    name: 'Account Owner',
    permissions: [
      permissionList.BACKEND_USER,
      permissionList.CAN_EDIT_PRACTICE,
      permissionList.CAN_EDIT_PRACTICE,
      permissionList.GET_PRACTICE,
      permissionList.GET_PRACTICE_MEMBERS,
      permissionList.CAN_SWITCH_USER,
      permissionList.CAN_INVITE_PROVIDER,
      permissionList.CAN_VIEW_FINISH_PROFILE,
      permissionList.CAN_EDIT_STAFF,
    ],
  },
  {
    id: 4,
    name: 'Provider',
    permissions: [
      permissionList.BACKEND_USER,
      permissionList.CAN_EDIT_PRACTICE,
      permissionList.GET_PRACTICE,
      permissionList.GET_PRACTICE_MEMBERS,
      permissionList.CAN_SWITCH_USER,
      permissionList.CAN_INVITE_PROVIDER,
      permissionList.CAN_VIEW_FINISH_PROFILE,
      permissionList.CAN_EDIT_STAFF,
    ],
  },
  {
    id: 5,
    name: 'Staff',
    permissions: [
      permissionList.CAN_SWITCH_USER,
      permissionList.BACKEND_USER,
      permissionList.GET_PRACTICE,
    ],
  },
];

const hasPermission = (roles = [], permission = '') => {
  if (permission === '') {
    return true;
  }
  let permissions = [];
  _.map(roles, roleIndex => {
    permissions = [...permissions, ..._.get(roleList, `${roleIndex}.permissions`, [])];
  });
  return _.includes(permissions, permission);
};

const hasAnyPermission = (roles = [], permissionList = []) => {
  if (permissionList.length === 0) {
    return true;
  }
  let permissions = [];
  _.map(roles, roleIndex => {
    permissions = [...permissions, ..._.get(roleList, `${roleIndex}.permissions`, [])];
  });
  let hasPermission = false;
  _.forEach(permissionList, permission => {
    if (_.includes(permissions, permission)) {
      hasPermission = true;
    }
  });
  return hasPermission;
};

const getAllMyRoleIds = auth => {
  // user's roles
  const userRoles = _.get(auth, 'roles', []);
  const roleIds = _.map(userRoles, 'id');
  return roleIds;
};

export default {
  roleList,
  permissionList,
  helperFunctions: {
    hasPermission,
    hasAnyPermission,
    getAllMyRoleIds,
  },
};
