import React, { useEffect, useState } from 'react';
import modalConfig from 'modals/modalConfig';
import reviewService from 'services/review';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import Select from 'components/FormControls/Select';
import './RequestContactLocation.css';
import Checkbox from 'components/FormControls/Checkbox';
import helper from 'data/helper';
export function RequestContactLocation(props) {
  const { setGlobal } = props.context;
  const { passData } = props
  const { contact } = passData
  const { modalFunctions } = modalConfig;
  const { blankModalData } = modalFunctions;
  const { formatPhoneNumberWithDash } = helper
  const { getGoogleSSouserReviewSourceLocation } = reviewService
  const Warning = props => {
    const { closeModal, text } = props;
    const [locationList, setLocationList] = useState([])
    const [reviewSourceLocationId, setReviewSourceLocationId] = useState(0)

    const [sendSms, setSendSms] = useState(false)
    const [sendEmail, setSendEmail] = useState(false)

    useEffect(() => {
      get_setReviewLocationList();
      loadValFromStorage();
    }, [])
    const loadValFromStorage = () => {

      setSendSms(localStorage.getItem('sendSmsReview') == 'true' ? true : false);
      setSendEmail(localStorage.getItem('sendEmailReview') == 'true' ? true : false)
    }
    const get_setReviewLocationList = async () => {
      let responce = await getGoogleSSouserReviewSourceLocation().catch()
      if (responce) {
        let { data } = responce
        let selectedDefaultValue = data.find((d) => d.isDefault)
        if (selectedDefaultValue)
          setReviewSourceLocationId(selectedDefaultValue.id)
        data = data.map((d) => {
          let addressLinesAddress = JSON.parse(d.addressLines)
          if (addressLinesAddress.length > 0)
            d.customText = `${d.title} (${addressLinesAddress[0]})`
          else {
            d.customText = `${d.title}`
          }
          return d
        })
        setLocationList(data)
      }
    }
    const setlocalStorage = (name, value) => {
      switch (name) {
        case 'sendSms':
          localStorage.setItem('sendSmsReview', value);
          break;
        case 'sendEmail':
          localStorage.setItem('sendEmailReview', value);
          break;
        default:
          localStorage.setItem('sendSmsReview', value);
          localStorage.setItem('sendEmailReview', value);
          break;
      }
    }

    return (
      <div className="CreatePasswordModal ConfirmTemplate termsofuse DisconnectSocialConform">
        <div style={{ minHeight: "auto", paddingTop: "12px" }} className="modal-content-header">
          <div className="h3">Select Location</div>
          <span
            className="close"
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div style={{ paddingTop: "20px", paddingBottom: "30px" }} className="modal-content-body">
          <div className='RequestContactLocation'>
            <div className=" eachrow">
              <div className='item'>
                <span>First Name</span>
                <p>{contact.firstName}</p>
              </div>
              <div className='item'>
                <span>Last Name</span>
                <p>{contact.lastName}</p>
              </div>
            </div>
            <div className=" eachrow">
              <div className='item'>
                <span>Email</span>
                <p>{contact.email}</p>
              </div>
              <div className='item'>
                <span>Phone Number</span>
                <p>{formatPhoneNumberWithDash(contact.phone)}</p>
              </div>
            </div>
            <div className=" eachrow">
              <Checkbox onChange={(e) => { setlocalStorage('sendEmail', e.target.value); setSendEmail(e.target.value) }} value={sendEmail}>
                Send Email

              </Checkbox>
              <Checkbox onChange={(e) => { setlocalStorage('sendSms', e.target.value); setSendSms(e.target.value) }} value={sendSms}>
                Send SMS

              </Checkbox>
              <Checkbox onChange={(e) => {
                const { value } = e.target
                setSendEmail(value)
                setSendSms(value);
                setlocalStorage('both', value);
              }} value={sendEmail && sendSms} >
                Both
              </Checkbox>
            </div>
          </div>
          <Select
            /// {...getGeneralInputProps('reviewSourceId')}
            labelIndex="customText"
            valueIndex="id"
            onChange={(e) => {
              let { value } = e.target;
              setReviewSourceLocationId(value)
            }}
            value={reviewSourceLocationId}
            options={locationList}
            label="Select Place"
          />
        </div>
        <div className="modal-content-footer">
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            <CircleButton
              style={{ width: "25%" }}
              onClick={() => {
                props.resolve(false);
                props.closeModal();
              }}>
              Cancel
            </CircleButton>
            <CircleButton
              style={{ width: "25%" }}
              // display={reviewSourceLocationId == 0 ? 'block' : 'none'}
              disabled={!sendEmail && !sendSms}
              onClick={() => {
                props.resolve({ reviewSourceLocationId: reviewSourceLocationId, sendEmail: sendEmail, sendSms: sendSms });
                props.closeModal();
              }}>
              Send
            </CircleButton>

          </div>
        </div>
      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: Warning,
        customComponentProps: { resolve: resolve, reject: reject, text: props.text },
      }),
    );
  });
}
