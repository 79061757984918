import images from 'data/images';
import React from 'react';

export const Print = () =>{
    
    // it will print the bar chart
    const handlePrint = ()=>{
      const dataUrl = document.getElementById('chartRefPractice').toDataURL(); 

      var windowContent = '<!DOCTYPE html>';
      windowContent += '<html>'
      windowContent += `<head><title></title><style>body{background-color:white !important;}@page { size: 58cm 35.7cm;margin: 1cm 1cm 1cm 1cm; }</style></head><script>function step1(){
        window.print();window.close()
      }</script>`;
      windowContent += '<body>'
      windowContent += '<img onload="step1()" src="' + dataUrl + '">';
      windowContent += '</body>';
      windowContent += '</html>';
      var printWin = window.open('', '', 'width=' + window.screen.availWidth + ',height=' + window.screen.availHeight);
      printWin.document.open();
      printWin.document.write(windowContent);
      printWin.document.close();
    }
    return(
      <span className="close" style={{marginRight:"10px"}} onClick={handlePrint}>
      <img src={images.print} alt="close" />
    </span>
    );
};
