import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import images from 'data/images';
import {Link} from 'react-router-dom';
import Divider from 'components/Divider';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import Checkbox from 'components/FormControls/Checkbox';
import SocialButton from 'components/SocialButton';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import CreatePasswordModal from '../CreatePasswordModal';
import PrompOTPModal from '../PrompOTPModal';
import allRegex from 'data/regex';
import validators from 'data/validators';
import config from 'config/config';
import selectOptions from 'data/selectOptions';
import DateManual from 'components/FormControls/DateManual';
import './ReserveAppointment.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import helper from 'data/helper';

const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;

const {nameRegex} = allRegex;
const {
  __required,
  __maxLength,
  __email,
  __eq,
  __validDate,
  __validDateofBirthDate,
  __validDateofBirthDatelessthan300,
  __phoneNumber,
} = validators;
const {phonenumberRegex} = allRegex;
const {formatPhoneNumberWithDash} = helper;
const {MAX_TEXT_LENGTH} = config;
const {patientGenderOptions} = selectOptions;
const defaultFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  password: '',
  gender: '',
  dateOfBirth: '',
  hasAcceptedTerms: false,
  hasReadHipaa: false,
};
const {Analytics} = analyticsData;

const ReserveAppointment = props => {
  const {
    state,
    onSignUp,
    showPromptOtp = false,
    tempUserId = 0,
    callAfterPhoneNumberOtp,
    onSocialLoginSuccess,
    onleSocialLoginFailure,
    onToggleAppointment,
  } = props;

  const {dateAndTimeObject} = state;
  const {dateString, timeObject} = dateAndTimeObject;
  const localAppointmentDateTime = moment(dateString + ' ' + timeObject.time, 'YYYY-MM-DD h:mm a');
  const rootContext = useContext(RootContext);
  const [formData, setFormData] = useState({
    values: {...defaultFormValues},
    errors: {},
  });
  const {values: formValues, errors: formErrors} = formData;
  // Validation functions
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'First name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (fValue.length < 2) {
      return `Min 2 characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'Last name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (fValue.length < 2) {
      return `Min 2 characters`;
    }
    return null;
  };
  const __validateGender = (value = null) => {
    const fValue = value !== null ? value : formValues['gender'];
    if (!__required(fValue)) {
      return 'Gender is required';
    }
    return null;
  };
  const __validateHasAcceptedTerms = (value = null) => {
    const fValue = value !== null ? value : formValues['hasAcceptedTerms'];
    if (fValue === false || !fValue) {
      return 'Plaese accept Terms';
    }
    return null;
  };
  const __validateHasReadHipaa = (value = null) => {
    const fValue = value !== null ? value : formValues['hasReadHipaa'];
    if (fValue === false || !fValue) {
      return 'Plaese accept HIPAA Authorization';
    }
    return null;
  };
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  const __validatePhoneNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['phonenumber'];

    if (!__required(fValue)) {
      return 'Phone number is required';
    }
    if (fValue && !__phoneNumber(fValue)) {
      return 'Invalid Phone number';
    }
    if (fValue && !phonenumberRegex.test(formatPhoneNumberWithDash(fValue))) {
      return 'Invalid Phone number';
    }

    if (fValue && formatPhoneNumberWithDash(fValue).length > 2) {
      if (checkSameNumber(formatPhoneNumberWithDash(fValue).substring(0, 3))) {
        return 'Invalid Phone number';
      }
    }
    return null;
  };
  const checkSameNumber = num => {
    if (num[0] === num[1] && num[0] === num[2]) return true;
    return false;
  };
  // const __validateConfirmEmail = (value = null) => {
  //   const fValue = value !== null ? value : formValues['confirmEmail'];
  //   if (!__required(fValue)) {
  //     return 'Confirm Email is required';
  //   }
  //   if (!__eq(fValue, formValues['email'])) {
  //     return 'Email and Confirm Email must be same';
  //   }
  //   return null;
  // };
  const __validateDateOfBirth = (value = null) => {
    const fValue = value !== null ? value : formValues['dateOfBirth'];
    if (!__validDate(fValue)) {
      return 'Date of birth is invalid ';
    }
    if (!__validDateofBirthDate(fValue)) {
      return "Date of birth must be before today's date";
    }
    if (!__validDateofBirthDatelessthan300(fValue)) {
      return 'Check date of birth is valid';
    }
    return null;
  };
  const __validateForm = () => {
    const validEmail = !__validateEmail();
    //const validConfirmEmail = !__validateConfirmEmail();
    const validatePhoneNumber = !__validatePhoneNumber();
    const validFirstName = !__validateFirstName();
    const validLastName = !__validateLastName();
    const validGender = !__validateGender();
    const validHasAcceptedTerms = !__validateHasAcceptedTerms();
    const validHasReadHipaa = !__validateHasReadHipaa();
    const validDateOfBirth = !__validateDateOfBirth();
    return (
      validatePhoneNumber &&
      validEmail &&
      validFirstName &&
      validLastName &&
      validGender &&
      validHasAcceptedTerms &&
      validHasReadHipaa &&
      validDateOfBirth
    );
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      case 'phonenumber':
        errorObject = {
          [name]: __validatePhoneNumber(value),
        };
        break;
      // case 'confirmEmail':
      //   errorObject = {
      //     [name]: __validateConfirmEmail(value),
      //   };
      //   break;
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'gender':
        errorObject = {
          [name]: __validateGender(value),
        };
        break;
      case 'hasAcceptedTerms':
        errorObject = {
          [name]: __validateHasAcceptedTerms(value),
        };
        break;
      case 'hasReadHipaa':
        errorObject = {
          [name]: __validateHasReadHipaa(value),
        };
        break;
      case 'dateOfBirth':
        errorObject = {
          [name]: __validateDateOfBirth(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleForm = (passwordformValues = null) => {
    Nux.sendEvent(
      Analytics.Category.PatientAppointment,
      Analytics.Actions.Click,
      'patientRegister',
    );
    onSignUp({
      ...formValues,
      // ...passwordformValues,
    });
  };

  const openCreatePasswordModal = () => {
    const {setGlobal} = rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: CreatePasswordModal,
        customComponentProps: {
          onPasswordSave: handleForm,
        },
      }),
    );
  };
  useEffect(() => {
    if (showPromptOtp) {
      const {setGlobal} = rootContext;
      setGlobal(
        'modal',
        blankModalData({
          CustomComponent: PrompOTPModal,
          customComponentProps: {
            state: state,
            tempUserId: tempUserId,
            callAfterPhoneNumberOtp: callAfterPhoneNumberOtp,
          },
          closeOnBGClick: false,
        }),
      );
    }
  }, [showPromptOtp]);
  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const loginStateToObj = {
    pathname: '/patient/login',
    state: {...state, fromReserveAppointment: true},
  };
  return (
    <div className="ReserveAppointment reserveappointmentnew">
      <div className="h3">Review & Book</div>
      <div className="already-login-text">
        Already a member? <Link to={loginStateToObj}>Click here to login</Link>
      </div>
      <form>
        <div className="form-group-2-colum">
          <Input
            {...getGeneralInputProps('firstName')}
            placeholder="First Name"
            //translationType="transform"
          />
          <Input
            {...getGeneralInputProps('lastName')}
            placeholder="Last Name"
            // translationType="transform"
          />
        </div>
        <Input {...getGeneralInputProps('email')} placeholder="Email" />
        <Input {...getGeneralInputProps('phonenumber')} placeholder="Phone Number" />
        <DateManual {...getGeneralInputProps('dateOfBirth')} label="" />
        <Select
          {...getGeneralInputProps('gender')}
          options={patientGenderOptions}
          defaultOptionProp={{value: '', label: 'Gender'}}
        />
        <div className="checkbox-block privacy">
          <Checkbox {...getGeneralInputProps('hasAcceptedTerms')}></Checkbox>I have read and accept
          Kaly{' '}
          <Link to="/terms" target="_blank">
            Terms of Use{' '}
          </Link>
          and{' '}
          <Link to="/privacy-policy" target="_blank">
            Privacy Policy.
          </Link>
        </div>
        <div className="checkbox-block">
          <Checkbox {...getGeneralInputProps('hasReadHipaa')}></Checkbox>I have read and accept Kaly{' '}
          <Link to="/hipaa-policy" target="_blank">
            HIPAA Authorization
          </Link>
        </div>
        <div className="action-btn action-bottom">
          <div className="appoitment-smtime">
            <span className="title">Appointment Request</span>
            <span className="time-detail">
              {localAppointmentDateTime.format('ddd MMM DD, h:mm a')}
            </span>
            <div className="view-details" onClick={onToggleAppointment}>
              View Details
            </div>
          </div>
          <CircleButton disabled={!isValidForm} btnType="whiteButton" onClick={handleForm}>
            Save and Continue
          </CircleButton>
        </div>
      </form>
      {/* <div className="divider-content">
        <Divider></Divider>
        <span>Or</span>
      </div>
      <div className="ca-social">
        <SocialButton
          className="social-icon"
          provider="google"
          appId={config.GOOGLE_CLIENT_ID}
          scope="profile email"
          onLoginSuccess={data => onSocialLoginSuccess('google', data)}
          onLoginFailure={error => onleSocialLoginFailure('google', error)}>
          <img src={images.Googleicon} alt="facebook icon" />
          Continue with Google
        </SocialButton>
        <SocialButton
          className="social-icon"
          provider="facebook"
          appId={config.FACEBOOK_CLIENT_ID}
          scope="email,public_profile,email"
          onLoginSuccess={data => onSocialLoginSuccess('facebook', data)}
          onLoginFailure={error => onleSocialLoginFailure('facebook', error)}>
          <img src={images.facebookblue} alt="google icon" />
          Continue with Facebook
        </SocialButton>
        <SocialButton
          className="social-icon"
          provider="apple"
          appId={config.FACEBOOK_CLIENT_ID}
          scope="email,public_profile,email"
          onLoginSuccess={data => onSocialLoginSuccess('apple', data)}
          onLoginFailure={error => onleSocialLoginFailure('apple', error)}>
          <img src={images.facebookblue} alt="google icon" />
          Continue with Apple
        </SocialButton> */}
      {/* </div> */}
    </div>
  );
};

export default ReserveAppointment;
