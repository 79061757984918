import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import ReactCrop from 'react-image-crop';
import helper from 'data/helper';
import CircleButton from 'components/CircleButton';
import 'react-image-crop/dist/ReactCrop.css';
import './MyImageCrop.css';

const {validateFile} = helper;

const MyImageCrop = props => {
  const _URL = window.URL || window.webkitURL;
  const {
    cropConfig = {aspect: 1 / 1},
    afterCropImage = () => {},
    onError = () => {},
    files,
    cropextraProps = {},
    cropElementProps = {},
  } = props;
  const [crop, setCrop] = useState(cropConfig);
  const [fileName, setFileName] = useState('logo.jpg');
  const [imageUrl, setImageUrl] = useState(null);
  const [imageElement, setImageElement] = useState(null);

  useEffect(() => {
    if (files) {
      onChange(files);
      setCrop({...cropConfig});
    } else {
      setImageUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);
  const validateImageSize = (w, h, src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        if (this.width >= w && this.height >= h) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.src = _URL.createObjectURL(src);
    });
  };
  const validatePhotos = file => {
    let isAllValidFiles = true;
    // _.map(files, file => {
      if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
        isAllValidFiles = false;
      }
    // });
    if (!isAllValidFiles) {
      onError({message: 'Only JPG or PNG allowed'});
    }
    return isAllValidFiles;
  };
  const onChange = async files => {
    if (files.length > 0) {
      const file = files[0];
      const originalFileName = file.name;
     
      if (await validatePhotos(file)) {
        if (cropextraProps.minWidth && cropextraProps.minHeight) {
          if (!(await validateImageSize(cropextraProps.minWidth, cropextraProps.minHeight, file))) {
            onError({
              message: `Select an image with minimum ${cropextraProps.minWidth} width and ${cropextraProps.minHeight} height.`,
            });
            return false;
          }
        }
        if (imageUrl) {
          _URL.revokeObjectURL(imageUrl);
        }
        const newImageUrl = _URL.createObjectURL(file);
        setFileName(originalFileName);
        setImageUrl(newImageUrl);
      } else {
        setImageUrl(null);
        onError({message: 'Only JPG or PNG allowed'});
      }
    }
  };

  const onCrop = async () => {
    const blobImage = width > 0 ? await getCroppedImg(fileName) : await getFullImg(fileName);
    if (Object.keys(cropElementProps).length > 0 && !isExactSize()) {
      const base64img = await blobToBase64(blobImage);
      const resizeimage = await ResizeImage(
        base64img,
        cropElementProps.minWidth,
        cropElementProps.minHeight,
        fileName,
      );

      if (blobImage && resizeimage === 'same') {
        afterCropImage(blobImage);
      } else if (resizeimage) {
        afterCropImage(resizeimage);
      }
    } else {
      if (blobImage) {
        afterCropImage(blobImage);
      }
    }
  };
  const witoutCrop = async () => {};

  const onCancel = () => {
    if (imageUrl) {
      _URL.revokeObjectURL(imageUrl);
    }
    setImageUrl(null);
  };

  const onImageLoaded = image => {
    setImageElement(image);
  };

  const getCroppedImg = fileName => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = imageElement.naturalWidth / imageElement.width;
    const scaleY = imageElement.naturalHeight / imageElement.height;
    canvas.width = Math.ceil(crop.width*scaleX);
    canvas.height = Math.ceil(crop.height*scaleY);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      imageElement,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (blob) {
            blob.name = fileName;
            blob.originalname = fileName;
            resolve(blob);
          } else {
            reject(null);
          }
        },
        'image/jpeg',
        1,
      );
    });
  };
  const isExactSize = ()=>{
    if (Object.keys(cropElementProps).length > 0 && imageElement) {
      if (
        imageElement.naturalWidth &&
        cropElementProps.minWidth === imageElement.naturalWidth &&
        imageElement.naturalHeight &&
        cropElementProps.minHeight === imageElement.naturalHeight
      ) {
        return true
      }
    }
    return false
  }
 
  const getFullImg = fileName => {
    const canvas = document.createElement('canvas');

    canvas.width = imageElement.naturalWidth;
    canvas.height = imageElement.naturalHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imageElement, 0, 0);
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (blob) {
            blob.name = fileName;
            blob.originalname = fileName;
            resolve(blob);
          } else {
            reject(null);
          }
        },
        'image/jpeg',
        1,
      );
    });
  };
  const blobToBase64 = blob => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };
  const ResizeImage = (base64image, width, height, fileName) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = base64image;

      img.onload = () => {
        // Check if the image require resize at all
        if (img.height == height && img.width == width) {
          resolve('same');

          // TODO: Call method to do something with the resize image
        } else {
          // Make sure the width and height preserve the original aspect ratio and adjust if needed
          // if (img.height > img.width) {
          //   width = Math.floor(height * (img.width / img.height));
          // } else {
          //   height = Math.floor(width * (img.height / img.width));
          // }

          let resizingCanvas = document.createElement('canvas');
          let resizingCanvasContext = resizingCanvas.getContext('2d');

          // Start with original image size
          resizingCanvas.width = img.width;
          resizingCanvas.height = img.height;

          // Draw the original image on the (temp) resizing canvas
          resizingCanvasContext.drawImage(img, 0, 0, resizingCanvas.width, resizingCanvas.height);

          let curImageDimensions = {
            width: Math.floor(img.width),
            height: Math.floor(img.height),
          };

          let halfImageDimensions = {
            width: null,
            height: null,
          };

          // Quickly reduce the dize by 50% each time in few iterations until the size is less then
          // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
          // created with direct reduction of very big image to small image
          while (curImageDimensions.width * 0.5 > width) {
            // Reduce the resizing canvas by half and refresh the image
            halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
            halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

            resizingCanvasContext.drawImage(
              resizingCanvas,
              0,
              0,
              curImageDimensions.width,
              curImageDimensions.height,
              0,
              0,
              halfImageDimensions.width,
              halfImageDimensions.height,
            );

            curImageDimensions.width = halfImageDimensions.width;
            curImageDimensions.height = halfImageDimensions.height;
          }

          // Now do final resize for the resizingCanvas to meet the dimension requirments
          // directly to the output canvas, that will output the final image
          let outputCanvas = document.createElement('canvas');
          let outputCanvasContext = outputCanvas.getContext('2d');

          outputCanvas.width = width;
          outputCanvas.height = height;

          outputCanvasContext.drawImage(
            resizingCanvas,
            0,
            0,
            curImageDimensions.width,
            curImageDimensions.height,
            0,
            0,
            width,
            height,
          );

          // output the canvas pixels as an image. params: format, quality
          // return outputCanvas.toDataURL('image/jpeg', 0.85);
          outputCanvas.toBlob(
            blob => {
              if (blob) {
                blob.name = fileName;
                blob.originalname = fileName;
                resolve(blob);
              } else {
                reject(null);
              }
            },
            'image/jpeg',
            1,
          );
          // TODO: Call method to do something with the resize image
        }
      };
    });
  };
  if (!imageUrl) {
    return null;
  }
  const width = _.get(crop, 'width', 0);
  

  return (
    <div className="MyImageCrop">
      <div className="content">
        {/* <div className="modal-content-header">
          <div className="h3">Crop Image </div>
          <span className="close" onClick={closeModal}>
            <img src={images.close} alt="close" />
          </span>
        </div> */}
        <div className="modal-content-body">
          <ReactCrop
            src={imageUrl}
            onImageLoaded={onImageLoaded}
            crop={crop}
            onChange={newCrop => setCrop(newCrop)}
              locked={false}
            // {...cropElementProps}
          />
         
            <small className="cropinfotext">
              <i>
              {!isExactSize()&& (<span>This image is too large. It must be cropped before saving. <br /></span>)}
                Crop by clicking and dragging on the image
              </i>
            </small>
       
        </div>
        <div className="modal-content-footer">
          {imageUrl && (
            <>
              <CircleButton onClick={onCancel} btnType="secondaryButton">
                Cancel
              </CircleButton>
              <CircleButton
                onClick={() => {
                  onCrop();
                }}>
                {(isExactSize() && width===0) ? 'Save' : width > 0 ? 'Crop & Save' : 'Auto-Crop & Save'}
              </CircleButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyImageCrop;
