//Ref https://www.npmjs.com/package/react-add-to-calendar
//Code is taken from node package react-add-to-calendar
//https://github.com/jasonsalzman/react-add-to-calendar/tree/master/src

import helpersClass from './addToCalendar.helpers';
const helpers = new helpersClass();

const handleAddToCalendar = (calType, event) => {
  let url = helpers.buildUrl(event, calType, false);
  if (!helpers.isMobile() && (url.startsWith('data') || url.startsWith('BEGIN'))) {
    let filename = calType + '.ics';
    let blob = new Blob([url], {type: 'text/calendar;charset=utf-8'});
    //For IE
    if (typeof window !== 'undefined' && window.navigator.msSaveOrOpenBlob && window.Blob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      /****************************************************************
        // many browsers do not properly support downloading data URIs
        // (even with "download" attribute in use) so this solution
        // ensures the event will download cross-browser
        ****************************************************************/
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } else {
    window.open(url, '_self');
  }
};

export default handleAddToCalendar;
