import React, {useEffect, useMemo, useState, useContext} from 'react';
import AuthContext from 'context/AuthContext';
import _ from 'lodash';
import images from 'data/images';
import rolePermission from 'data/rolePermission';
import {Link} from 'react-router-dom';
import './MenuItem.css';

const {helperFunctions} = rolePermission;
const {hasAnyPermission} = helperFunctions;

const MenuItem = props => {
  const {item, isActive, roleIds = [], currentPath} = props;
  const {name, image, link, subMenuItems = [],hasCount=false,countKey=''} = item;
  const authContext = useContext(AuthContext);
  let countData = _.get(authContext, 'requestCount', '');
  const [active, toggleMenu] = useState(isActive);
  const filteredSubMenus = useMemo(() => {
    return subMenuItems.filter(subMenuItem => {
      return hasAnyPermission(roleIds, subMenuItem.permissions);
    });
  }, [roleIds, subMenuItems]);
  // if current url path matches link path then sub menu is active
  const isSubActive = useMemo(() => {
    let isSubActive = false;
    filteredSubMenus.forEach(subMenuItem => {
      if (subMenuItem.link === currentPath) {
        isSubActive = true;
      }
    });
    return isSubActive;
  }, [currentPath, filteredSubMenus]);

  useEffect(() => {
    if (!active && isSubActive) {
      toggleMenu(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubActive]);

  return (
    <>
      <span className={`MenuItem ${(isActive || isSubActive) && 'active'}`}>
        <Link to={link}>
          <img alt="" src={image} className="icon" />
          {name}
          {hasCount &&countData[countKey] !='0' &&  (
                  <span className="pending-count main">
                    {_.get(countData, countKey, '')}
                  </span>
                )}
        </Link>
        {filteredSubMenus.length > 0 && (
          <img
            src={images.arrowPointer}
            alt=""
            className="arrow"
            onClick={() => toggleMenu(!active)}
          />
        )}
      </span>
      {active &&
        filteredSubMenus.map(subMenuItem => {
          return (
            <span
              key={subMenuItem.id}
              className={`MenuItem submenu ${
                subMenuItem.link === currentPath ? 'sub-menu-active' : ''
              }`}>
              <Link to={subMenuItem.link}>
                {subMenuItem.name}
                {subMenuItem.hasCount && (
                  <span className="pending-count">
                    {_.get(countData, subMenuItem.countKey, '')}
                  </span>
                )}
              </Link>
            </span>
          );
        })}
    </>
  );
};

export default MenuItem;
