import React, {useEffect, useRef, useState} from 'react';
import './popConfirm.css';
const PopConfirm = props => {
  const {text}=props
  const [showConfirm, setShowConfirm] = useState(false);
  const [top,setTop]=useState(0)
  const handleClick = (e) => {
setTop(92-e.target.getBoundingClientRect().height)
    setShowConfirm(true);
  };
  const wrapperRef = useRef(null);

  useEffect(() => {
    if(wrapperRef){
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      
        if (wrapperRef.current && !wrapperRef.current.contains(event.target) && showConfirm) {
            //alert("You clicked outside of me!");
            setShowConfirm(false)
        }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }
}, [wrapperRef,showConfirm]);

  return (
    <div ref={wrapperRef}>
      {React.cloneElement(props.children, {onClick: handleClick})}
      <div style={{position:"relative"}}>
      <div  style={{top:`-${top}px`}}
        className={[
          'ant-popover ant-popconfirm ant-popover-placement-topLeft',
          showConfirm ? 'show' : 'hide',
        ].join(' ')}>
        <div className="ant-popover-content">
          
          <div className="ant-popover-inner" role="tooltip">
            <div className="ant-popover-inner-content">
              <div className="ant-popover-message">
                
                <div className="ant-popover-message-title">{text}?</div>
              </div>
              <div className="ant-popover-buttons">
                <button type="button" onClick={()=>setShowConfirm(false)} className="ant-btn ant-btn-sm ant-btn-danger">
                  <span>No</span>
                </button>
                <button type="button" onClick={()=>{props.onConfirm()}} className="ant-btn ant-btn-primary ant-btn-sm">
                  <span>Yes</span>
                </button>
              </div>
            </div>
          </div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default PopConfirm;
