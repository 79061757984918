import React, {useEffect, useRef} from 'react';
import images from 'data/images';
import './InfiniteScroll.css';

const InfiniteScroll = props => {
  const {children, allFetched, offset = 0, onScrollToBotton, isFetching, setIsFetching} = props;
  const hiddenDivRef = useRef(null);
  const infinitScrollRef = useRef(null);
  // register scroll event when component mounts
  useEffect(() => {
    infinitScrollRef.current.addEventListener('scroll', handleScroll);
    return () => {
      // remove scroll event when component destroys
      // eslint-disable-next-line react-hooks/exhaustive-deps
      infinitScrollRef.current.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if previous request of fetching is going on or all data fetched,then don't call api for data
    if (isFetching && !allFetched) {
      onScrollToBotton();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleScroll = () => {
    const element = hiddenDivRef.current;
    const position = element.getBoundingClientRect();
    if (isFetching || allFetched) {
      return;
    }
    // checking for partial visibility
    if (position.top - offset <= window.innerHeight && position.bottom >= 0) {
      setIsFetching(true);
    }
  };

  return (
    <div className="InfiniteScroll" ref={infinitScrollRef}>
      {children}
      <div ref={hiddenDivRef}></div>
      {isFetching && !allFetched && (
        <div className="infinite-scroll-loader">
          <span>
            <img src={images.logosymbol} alt="logo symbol" />
          </span>
        </div>
      )}
    </div>
  );
};

export default InfiniteScroll;
