const questionList = [
  {
    id: 101,
    question: 'Do you have any of these symptoms?',
    optionType: 1, // {1=>'single line options',2=>'select option'} // other will be default UI
    descriptions: [
      'Fever greater than 100.3°F',
      'Recent severe traumatic injury to your spine.',
      'Sudden and rapid loss of muscle strength.',
      'Loss of bowel or bladder control.',
      'History of cancer and sudden onset back pain.',
      'Chest Pain or Shortness of Breath.',
    ],
    options: [
      {nextQuestionId: 107, label: 'Yes', shortLabel: 'Red flags? Yes'},
      {nextQuestionId: 102, label: 'No', shortLabel: 'Red flags? No'},
    ],
  },
  {
    id: 102,
    question: 'How long have you had a pinched nerve for?',
    descriptions: [],
    options: [
      {nextQuestionId: 104, label: 'Less than 3 weeks', shortLabel: '<3 weeks'},
      {nextQuestionId: 103, label: 'More than 3 weeks', shortLabel: '>3 weeks'},
    ],
  },
  {
    id: 103,
    question: 'Are you considering surgery?',
    descriptions: [],
    options: [
      {groupId: 'O', label: 'Yes, in the near future', shortLabel: 'Interested in surgery'},
      {nextQuestionId: 105, label: 'Not at this time', shortLabel: 'No surgery'},
    ],
  },
  {
    id: 104,
    question: 'Which type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'N',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching, and exercises',
        shortLabel: 'Conservative treatment',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'E',
        label: 'Preliminary imaging (Xrays) and/or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'E',
        label: 'Not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
        showKalyMd: true,
      },
    ],
  },
  {
    id: 105,
    question: 'Are you considering minimally invasive procedures?',
    descriptions: [],
    options: [
      {
        groupId: 'P',
        label: 'Yes such as an injection',
        shortLabel: 'Open to invasive procedures',
      },
      {nextQuestionId: 106, label: 'Not at this time.', shortLabel: 'No invasive procedures'},
    ],
  },
  {
    id: 106,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'N',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'Q',
        label: 'Diagnostic imaging studies (MRI/Xray) and/or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'Q',
        label: 'Not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
      },
    ],
  },
  {
    id: 107,
    question:
      'You may have a condition that requires immediate medical attention, such as seeking care at an Emergency Room or Urgent Care Center. Kaly does not diagnose conditions and is not meant to be a replacement for a trained medical professional.',
    optionType: 1,
    descriptions: [
      'Would you still like to see doctors who may help you after you seek urgent care?',
    ],
    options: [
      {nextQuestionId: 102, label: 'Yes'},
      {groupId: 'A', label: 'No'},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 101,
    treeDepth: 6,
  },
};
