const selectOptions = {
  defaultOption: {value: '', label: 'Please Select'},
  availabilityOptions: [
    {value: 1, label: 'Next 3 Days', days: 3},
    {value: 2, label: 'Today', days: 1},
    {value: 3, label: 'Special (5 PM to 9 AM)', days: -1},
  ],
  genderOptions: [
    {value: 1, label: 'Male'},
    {value: 2, label: 'Female'},
  ],
  patientGenderOptions: [
    {value: 1, label: 'Male'},
    {value: 2, label: 'Female'},
    {value: 3, label: 'Non-binary'},
    {value: 4, label: 'Rather not say'},
  ],
  practiceLocationStatusOptions: [
    {value: 1, label: 'Active'},
    {value: 0, label: 'Inactive'},
  ],
  patientsTypeOptions: [
    {value: 1, label: 'Adult'},
    {value: 2, label: 'Pediatric'},
    {value: 3, label: 'Both adult and pediatric'},
  ],
  practiceSoftwareOptions: [
    {value: 1, label: '75Health'},
    // {value: 2, label: 'add Epic'},
    {value: 3, label: 'Advanced Data Systems'},
    {value: 4, label: 'AdvancedMD'},
    {value: 5, label: 'Allscripts'},
    {value: 6, label: 'Amazing Charts'},
    {value: 7, label: 'Athenahealth'},
    {value: 8, label: 'Carecloud'},
    {value: 9, label: 'Centricity'},
    {value: 10, label: 'Cerner'},
    {value: 11, label: 'CureMD'},
    {value: 12, label: 'eClinicalWorks'},
    {value: 13, label: 'Epic'},
    {value: 14, label: 'e-MDs'},
    {value: 15, label: 'Greenway'},
    {value: 16, label: 'iPatientCare'},
    {value: 17, label: 'Kareo'},
    {value: 25, label: 'MacPractice'},
    {value: 18, label: 'Nextgen'},
    {value: 19, label: 'NueMD'},
    {value: 26, label: 'Populate'},
    {value: 20, label: 'Practice Fusion'},
    {value: 21, label: 'Praxis'},
    {value: 22, label: 'SOAPware'},
    {value: 23, label: 'WebPT'},
    {value: 24, label: 'Other'},
  ],
  certificationOptions: [{value: 1, label: 'Doctor'}],
  specialtyOptions: [
    {value: 1, label: 'Surgery'},
    {value: 2, label: 'Orthopaedics'},
  ],
  availabilityRepeatOptions: [
    {id: 1, label: 'Non-Repeating', value: 'none'},
    {id: 2, label: 'Repeat Daily', value: 'daily'},
    {id: 3, label: 'Repeat Weekly', value: 'CUSTOM'},
  ],
  locationOptions: [
    {id: 1, label: 'West River Spine & Rehab (45th)', value: 1},
    {id: 2, label: 'Location A', value: 2},
    {id: 3, label: 'Location B', value: 3},
  ],
  activityOptions: [
    {value: 0, label: 'Pending'},
    {value: 1, label: 'Confirmed'},
    {value: 2, label: 'Cancelled'},
  ],
  legalGuardianOptions: [
    {value: 1, label: 'Yes'},
    {value: 2, label: 'No'},
  ],
  insuranceDefaultOption: {label: 'I’m paying for myself', value: ''},
  preferredNumberOptions: [
    {value: 0, label: 'Cell'},
    {value: 1, label: 'Work'},
    {value: 2, label: 'Home'},
  ],
  demographicRaceOptions: [
    {id: 1, label: 'American Indian or Alaska Native'},
    {id: 2, label: 'Asian'},
    {id: 3, label: 'Black or African American'},
    {id: 4, label: 'Native Hawaiian'},
    {id: 5, label: 'Other Pacfic Islander'},
    {id: 6, label: 'White'},
    {id: 7, label: 'Decline to Answer'},
  ],
  demographicEthnicityOptions: [
    {id: 1, label: 'Hispanic or Latino'},
    {id: 2, label: 'Not Hispanic or Latino'},
    {id: 3, label: 'Decline to Answer'},
  ],
  activityPatientOptions: [
    {value: 'upcoming', label: 'Upcoming'},
    {value: 'past', label: 'Past'},
    {value: '0', label: 'Pending'},
    {value: '1', label: 'Confirmed'},
    {value: '2', label: 'Cancelled'},
  ],
  availabilityTypeOptions: [
    {value: 'all', label: 'All Appointments'},
    {value: 'inPerson', label: 'In-person'},
    {value: 'video', label: 'Video Visit'},
  ],
  reviewShapeOptions: [
    {label: 'Rectangle', value: 1, className: ''},
    {label: 'Rounded', value: 2, className: 'rounded'},
    {label: 'Mini-Rectangle', value: 3, className: 'mini-rectangle'},
    {label: 'Square', value: 4, className: 'square'},
  ],
  sortByoptions: [
    {label: 'Nearest distance', value: 'distance'},
    {label: 'Highest kScore rating', value: 'kScore'},
  ],
  providerStatusOptions: [
    {label: 'Active', value: 'active'},
    // {label: 'Inactive', value: 'inActive'},
    {label: 'In Process', value: 'inProgress'},
  ],
  providerFilterOption: [
    {label: 'Provider', value: 'provider'},
    {label: 'Staff', value: 'staff'},
  ],
  reviewFilterOptions: [
    {label: 'Recent', value: 'recent'},
    {label: 'Featured', value: 'featured'},
    {label: 'Request For Delete', value: 'requestForDelete'},
  ],
  OtherReqFilterOptions: [
    {label: 'Graduate School', value: '1'},
    {label: 'Undergraduate School', value: '2'},
    {label: 'Residency/Fellowships/Training', value: '3'},
    {label: 'Hospital Affiliations', value: '4'},
  ],
  CertificatReqFilterOptions: [
    {label: 'Certification', value: '1'},
    {label: 'Board Certification', value: '2'},
  ],
};
export default selectOptions;
