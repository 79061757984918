import React, {useState, useContext} from 'react';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import validators from 'data/validators';
import images from 'data/images';
import './EditPlan.css';
import adminService from 'services/admin';
import modalConfig from 'modals/modalConfig';
import _ from 'lodash';
import SwitchToggle from 'components/FormControls/SwitchToggle';
import Select from 'components/FormControls/Select';

const {__required} = validators;
const {updateInsurancePlan, createInsurancePlan} = adminService;
const {modalFunctions} = modalConfig;
const {successModalData,errorModalData} = modalFunctions;

const EditPlan = props => {
  const {data, companyList} = props;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const planId = _.get(data, 'id', 0);

  const [formData, setFormData] = useState({
    values: {
      plan: _.get(data, 'plan', ''),
      insuranceCompanyId: _.get(data, 'companyId', ''),
      status: _.get(data, 'status', 1) === 1,
    },
    errors: {serverError: null},
  });
  const {values: formValues, errors: formErrors} = formData;

  const __validatePlanName = (value = null) => {
    const fValue = value !== null ? value : formValues['plan'];
    if (!__required(fValue)) {
      return 'Plan name is required';
    }
    return null;
  };

  const __validateCompanyId = (value = null) => {
    const fValue = value !== null ? value : formValues['insuranceCompanyId'];
    if (!__required(fValue)) {
      return 'Insurance company is required';
    }
    return null;
  };

  const changeHandler = event => {
    let {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'plan':
        errorObject = {
          [name]: __validatePlanName(value),
        };
        break;
      case 'insuranceCompanyId':
        errorObject = {
          [name]: __validateCompanyId(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const closeModal = () => {
    setGlobal('modal', {showModal: false});
  };

  const __validateForm = () => {
    const validatePlanName = !__validatePlanName();
    const validateCompany = !__validateCompanyId();
    return validatePlanName && validateCompany;
  };

  const isValidateForm = __validateForm();

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="Close" title="Close" />
    </span>
  );

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">
        {planId > 0
          ? `Update Insurance Plan : ${_.get(data, 'plan', '')}`
          : 'Add New Insurance Plan'}
      </div>
      {crossIconComponent}
    </div>
  );

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const {updateList} = props;
    let response = null;
    let reqObj = {
      plan: formValues['plan'],
      insuranceCompanyId: formValues['insuranceCompanyId'],
      status: formValues['status'] ? 1 : 0,
    };
    if (planId > 0) {
      response = await updateInsurancePlan(planId, reqObj).catch(handleError);
    } else {
      response = await createInsurancePlan(reqObj).catch(handleError);
    }
    if (response) {
      const {message} = response;
      setGlobal('modal', successModalData(message));
      updateList();
    }
  };

  const renderInputElements = () => {
    return (
      <>
        <Input
          {...getGeneralInputProps('plan')}
          placeholder="Enter Plan Name"
          label="Insurance Plan"
        />
        <Select
          {...getGeneralInputProps('insuranceCompanyId')}
          options={companyList}
          labelIndex="companyName"
          valueIndex="id"
          label="Insurance Company"
        />
        <SwitchToggle {...getGeneralInputProps('status')} label="Status" />
      </>
    );
  };

  return (
    <div className="EditPlan no-select">
      <div className="modal-body-wrapper">
        {dialogHeader}
        <div className="dailog-body">
          <form onSubmit={handleSubmit}>{renderInputElements()}</form>
        </div>
        <div className="action-btn">
          <CircleButton onClick={closeModal} btnType="secondaryButton">
            Cancel
          </CircleButton>
          <CircleButton btnType="whiteButton" disabled={!isValidateForm} onClick={handleSubmit}>
            {planId > 0 ? 'Update' : 'Add'}
          </CircleButton>
        </div>
      </div>
    </div>
  );
};

export default EditPlan;
