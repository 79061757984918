import React, {Component} from 'react';
import RootContext from 'context/RootContext';
import EditPracticeLogo from '../EditPracticeLogo';
import EditPracticeOverview from '../EditPracticeOverview';
import EditPracticeLocations from '../EditPracticeLocations';
import EditPracticeRegistration from '../EditPracticeRegistration';
import EditPracticeSeoLink from '../EditPracticeSeoLink';
import './EditPractice.css';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import images from 'data/images';
import _ from 'lodash';

const {modalFunctions} = modalConfig;
const {errorModalData, successModalData} = modalFunctions;
const {
  getProviderPracticeDetail,
  setPracticeOverview,
  setPracticeSeoLink,
  setPracticeLocations,
  deletePracticeLocations,
} = adminService;

const initialState = {
  practice: {},
  practiceId: '',
};

class EditPractice extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount = () => {
    const query = new URLSearchParams(this.props.location.search);
    const practiceId = query.get('practice') || 0;
    this.getProviderPractice(practiceId);
  
  };

  getProviderPractice = async id => {
    const response = await getProviderPracticeDetail(id).catch(this.handleError);
    if(response&&response.data)
    this.setState({practiceId: id, practice: {...response.data}});
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  backToProviderList = () => {
    const {location} = this.props;
    const searchData = _.get(location, 'state', {});
    let passData={...searchData}
    if(passData.list && passData.list.length>0){
      const query = new URLSearchParams(this.props.location.search);
      const practiceId = query.get('practice') || 0;
      let index=passData.list.findIndex((pra)=>pra.id==practiceId)
      let {practice}=this.state
      if(index>=0){
        passData.list[index]={...passData.list[index],seoPage:practice.seoPage,seoPageUrl:practice.seoPageUrl}
      }
    }
    
    this.props.history.push({
      pathname: '/admin/practices',
      state: passData,
    });
  };

  handlePracticeOverviewSave = async formValues => {
    const {practiceId = ''} = this.state;
    const {setGlobal} = this.context;
    if (!formValues.hasEmailNotification || formValues.hasEmailNotification === 'null') {
      formValues.hasEmailNotification = false;
    }
    const response = await setPracticeOverview(practiceId, formValues).catch(this.handleError);
    if (response) {
      const getResponse = await getProviderPracticeDetail(practiceId).catch(this.handleError);
      this.setState({practice: {...getResponse.data}});
      const {message} = response;
      setGlobal('modal', successModalData(message));
    }
  };
  handlePracticeLinkSave = async formValues => {
    const {practiceId = ''} = this.state;
    const {setGlobal} = this.context;
  
    const response = await setPracticeSeoLink(practiceId, formValues).catch(this.handleError);
    if (response) {
      const getResponse = await getProviderPracticeDetail(practiceId).catch(this.handleError);
      this.setState({practice: {...getResponse.data}});
      const {message} = response;
      setGlobal('modal', successModalData(message));
    }
  };
  handleSaveLocation = async locationData => {
    const {practiceId = ''} = this.state;
    const {setGlobal} = this.context;
    if (!locationData.hasEmailNotification || locationData.hasEmailNotification === 'null') {
      locationData.hasEmailNotification = false;
    }
    const response = await setPracticeLocations(practiceId, locationData).catch(this.handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getProviderPractice(practiceId);
          },
        }),
      );
    }
  };

  handleDeleteLocation = async locationId => {
    const {practiceId = ''} = this.state;
    const {setGlobal} = this.context;
    const response = await deletePracticeLocations(practiceId, locationId).catch(this.handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getProviderPractice(practiceId);
          },
        }),
      );
    }
  };

  render() {
    const {practice, practiceId} = this.state;
    const {
      logoUrl = '',
      name = '',
      description = '',
      websites = [],
      software = '',
      patientFormUrl = '',
      practiceLocations = [],
      seats = '',
      seoPage=''
    } = practice;
    const practiceOverViewData = {name, description, websites, software, seats};
    const title = `${name}`;
    return (
      <Layout>
        <div className="EditPractice">
          <SubHeader title={title} hideName />
          <div className="back-provider" onClick={this.backToProviderList}>
            <span className="left-arrow">
              <img src={images.arrowLeft} alt="left-arrow" />
            </span>
            Back To All Practices
          </div>
          <div className="dashboard-panel">
            <div className="leftpanel">
              <EditPracticeLogo
                defaultFileUrl={logoUrl}
                onError={this.handleError}
                practiceId={practiceId}
                hasPermissionToEdit={true}
              />
              <EditPracticeSeoLink
                seoPage={seoPage}
                onFormSubmit={this.handlePracticeLinkSave}
                hasPermissionToEdit={true}
              />
              <EditPracticeOverview
                data={practiceOverViewData}
                onFormSubmit={this.handlePracticeOverviewSave}
                hasPermissionToEdit={true}
              />
              <EditPracticeRegistration
                defaultFileUrl={patientFormUrl}
                uploadServerUrl={`practice/upload/form`}
                onError={this.handleError}
                hasPermissionToEdit={true}
                practiceId={practiceId}
              />
            </div>
            <div className="rightpanel">
              <EditPracticeLocations
                locationList={practiceLocations}
                onSaveLocation={this.handleSaveLocation}
                onDeleteLocation={this.handleDeleteLocation}
                hasPermissionToEdit={true}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EditPractice;
