import React from 'react';
import './WarningTemplate.css';

const WarningTemplate = props => {
  return (
    <div className="modal-dialog warning">
      <div className="modal-header">
        <div className="title">{props.title || 'Warning!'}</div>
      </div>
      <div className="modal-body">{props.description || 'No functionalities working yet.'}</div>
    </div>
  );
};

export default WarningTemplate;
