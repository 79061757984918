import React, {useState} from 'react';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import selectOptions from 'data/selectOptions';
import validators from 'data/validators';
import config from 'config/config';
import allRegex from 'data/regex';
import './AboutYouAsProviderStepOne.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {nameRegex} = allRegex;
const {__required, __maxLength, __eqLength, __isNumeric} = validators;
const {genderOptions, patientsTypeOptions} = selectOptions;
const {MAX_TEXT_LENGTH} = config;
const NPI_LINK = 'https://npiregistry.cms.hhs.gov/';
const {Analytics} = analyticsData;

const AboutYouAsProviderStepOne = props => {
  const {nextStep, data,npiNumber} = props;
  const values = {...data};
  if(values['npi']===""&&npiNumber!==""){
    values['npi']= npiNumber
  }
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'First name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'Last name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateGender = (value = null) => {
    const fValue = value !== null ? value : formValues['gender'];
    if (!__required(fValue)) {
      return 'Gender is required';
    }
    return null;
  };
  const __validateHasPatientsAccepted = (value = null) => {
    const fValue = value !== null ? value : formValues['hasPatientsAccepted'];
    if (!__required(fValue)) {
      return 'Patients accepted is required';
    }
    return null;
  };
  const __validateNpi = (value = null) => {
    const fValue = value !== null ? value : formValues['npi'];
    if (!__required(fValue)) {
      return 'NPI is required';
    }
    if (!__isNumeric(fValue)) {
      return `Must be numaric`;
    }
    if (!__eqLength(fValue, 10)) {
      return `Must be 10 digits`;
    }
    return null;
  };
  const __validateForm = () => {
    const validFirstName = !__validateFirstName();
    const validLastName = !__validateLastName();
    const valideGender = !__validateGender();
    const validHasPatientsAccepted = !__validateHasPatientsAccepted();
    const validNpi = !__validateNpi();
    return validFirstName && validLastName && valideGender && validHasPatientsAccepted && validNpi;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name} = event.target;
    const {value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'gender':
        errorObject = {
          [name]: __validateGender(value),
        };
        break;
      case 'hasPatientsAccepted':
        errorObject = {
          [name]: __validateHasPatientsAccepted(value),
        };
        break;
      case 'npi':
        errorObject = {
          [name]: __validateNpi(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleSubmit = () => {
    Nux.sendEvent(
      Analytics.Category.DoctorRegistration,
      Analytics.Actions.Click,
      'aboutProviderStep1',
    );
    nextStep(formValues);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  return (
    <div className="AboutYouAsProviderStepOne">
      <div className="h3">About you as a provider</div>
      <div className="paragraph">
        You may receive a call from our team to confirm this information
      </div>
      <form className="signup-form">
        <div className="form-row">
          <Input
            {...getGeneralInputProps('firstName')}
            containerClass="column-6"
            label="First name"
          />
          <Input
            {...getGeneralInputProps('lastName')}
            containerClass="column-6"
            label="Last name"
          />
        </div>
        <Select {...getGeneralInputProps('gender')} options={genderOptions} label="Gender" />
        <Select
          {...getGeneralInputProps('hasPatientsAccepted')}
          options={patientsTypeOptions}
          label="Patients accepted"
        />
        <Input
          {...getGeneralInputProps('npi')}
          label="NPI#"
          linkData={{externalLink: NPI_LINK, label: 'Look Up'}}
        />
        <div className="signup-btn">
          <CircleButton disabled={!isValidForm} onClick={handleSubmit} btnType="whiteButton">
            Continue
          </CircleButton>
        </div>
      </form>
    </div>
  );
};

export default AboutYouAsProviderStepOne;
