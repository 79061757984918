import React, {useState, useContext, useEffect} from 'react';
import _ from 'lodash';
import images from 'data/images';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import Checkbox from 'components/FormControls/Checkbox';
import Select from 'components/FormControls/Select';
import userService from 'services/user';
import adminService from 'services/admin';
import './EditVisitReasonModal.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {getProviderVisitReasons} = userService;
const {getVisitReasonByAdmin} = adminService;
const {Analytics} = analyticsData;

const timeListOptions = [
  {label: '15', value: 15},
  {label: '30', value: 30},
  {label: '45', value: 45},
  {label: '60', value: 60},
];

const EditVisitReasonModal = props => {
  const {data, onSaveVisitReasons} = props;
  const {
    currentProviderName,
    newAppointmentTime,
    existingAppointmentTime,
    isAdmin,
    currentProviderId,
    specialties
  } = data;
  const rootContext = useContext(RootContext);
  const [config, setConfig] = useState({
    activeTabIndex: null,
    specialities: specialties,
    newAppointmentTime: newAppointmentTime || 15,
    existingAppointmentTime: existingAppointmentTime || 15,
  });
  const {activeTabIndex, specialities} = config;

  useEffect(() => {
    const getandSetSpecialities = async () => {
      let response = '';
      if (isAdmin) {
        response = await getVisitReasonByAdmin(currentProviderId);
      } else {
        response = await getProviderVisitReasons();
      }
      if (response) {
        const {data} = response;
        setConfig(prevState => {
          return {
            ...prevState,
            activeTabIndex: 0,
            specialities: data,
          };
        });
      }
    };
    getandSetSpecialities();
  }, [isAdmin,currentProviderId]);

  const setActiveTabIndex = index => {
    setConfig(prevState => {
      return {...prevState, activeTabIndex: index};
    });
  };

  const closeModal = () => {
    const {setGlobal} = rootContext;
    setGlobal('modal', {showModal: false});
  };
  const tabHeaderList = (
    <ol className="tab-list">
      {specialities.map((speciality, index) => {
        return (
          <li
            key={speciality.id}
            className={`tab-list-item ${index === activeTabIndex ? 'tab-list-active' : ''}`}
            onClick={() => setActiveTabIndex(index)}>
            {speciality.specialty}
          </li>
        );
      })}
    </ol>
  );
  const toggleCheckbox = (id, newVal) => {
    setConfig(prevState => {
      const updatedSpecialities = [...prevState.specialities];
      const specialityTobeUpdated = updatedSpecialities[prevState.activeTabIndex];
      let visitReasonIndex=specialityTobeUpdated.visitReasons.findIndex((it)=>it.id==id)
      specialityTobeUpdated.visitReasons[visitReasonIndex].selected = newVal;
      updatedSpecialities[prevState.activeTabIndex] = specialityTobeUpdated;
      return {
        ...prevState,
        specialities: updatedSpecialities,
      };
    });
  };

  let activeTabComponent = null;
  if (activeTabIndex !== null) {
    let selectedeSpVisit=specialities[activeTabIndex].visitReasons
    if(specialities[activeTabIndex].id=='66'){
      selectedeSpVisit=selectedeSpVisit.filter((item)=>item.painId==null)
    }
    else
    if(['67', '68', '69', '70', '71','72'].includes(specialities[activeTabIndex].id)){
      selectedeSpVisit=selectedeSpVisit.filter((item)=>item.painId==null)
    }
    activeTabComponent = (
      <div className="visit-list">
        {selectedeSpVisit.map((item, visitReasonIndex) => (
          <div className="visit-item" key={item.id}>
            <Checkbox
              value={item.selected}
              onChange={e => toggleCheckbox(item.id, e.target.value)}>
              {item.reason}
            </Checkbox>
          </div>
        ))}
      </div>
    );
  }

  const handleFormSubmit = () => {
    if (!isAdmin) {
      Nux.sendEvent(
        Analytics.Category.DoctorSettingProvider,
        Analytics.Actions.Click,
        'providerProfileChange',
      );
    }

    const visitReasons = [];
    _.map(specialities, speciality => {
      _.map(speciality.visitReasons, visitReason => {
        if (visitReason.selected) {
          visitReasons.push(visitReason.id);
        }
      });
    });
    const formValues = {
      visitReasons,
      newAppointmentTime: config.newAppointmentTime,
      existingAppointmentTime: config.existingAppointmentTime,
    };
    onSaveVisitReasons(formValues);
  };

  const timeChanged = event => {
    const {name, value} = event.target;
    setConfig(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <div className="EditVisitReasonModal">
      <div className="head-model">
        <span onClick={closeModal}>
          <span className="icon">
            <img src={images.arrowLeft} alt="" />
          </span>
          Back to Provider
        </span>
        <CircleButton containerClass="visitreasonSaveBtn" onClick={handleFormSubmit}>Save</CircleButton>
      </div>
      <div className="pms-body">
        <div className="heading1">{currentProviderName}</div>
        <div className="pms-first-question">1. How long do you see new/exisiting patients for?</div>
        <div className="add-time">
          <Select
            onChange={timeChanged}
            name="newAppointmentTime"
            label="New Patient Appointments"
            options={timeListOptions}
            value={config.newAppointmentTime}
          />
          <Select
            onChange={timeChanged}
            name="existingAppointmentTime"
            label="Existing Patient Appointments"
            options={timeListOptions}
            value={config.existingAppointmentTime}
          />
        </div>
        <div className="visit-reason-tab">
          <div className="pms-second-question">
            2. Select Visit Reasons below for your specialities to help patients find you when
            searching for related terms.
          </div>
          {tabHeaderList}
          <div className="tab-content">{activeTabComponent}</div>
        </div>
      </div>
    </div>
  );
};

export default EditVisitReasonModal;
