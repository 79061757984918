const questionList = [
  {
    id: 571,
    question: 'What type of procedure are you looking for?',
    optionType: 3,
    procedureOptionIds: [75, 97, 98, 99],
    nextQuestionId: 572,
    descriptions: [],
  },
  {
    id: 572,
    question: 'Do you get nervous when you go to the dentist?',
    questionType: 4,
    optionType: 0,
    options: [
      {
        nextQuestionId: 573,
        label: 'Not at all',
        shortLabel: 'Not nervous',
      },
      {
        nextQuestionId: 573,
        label: 'A little bit',
        shortLabel: 'A little nervous',
      },
      {
        nextQuestionId: 573,
        label: 'Very nervous',
        shortLabel: 'Very nervous',
      },
    ],
    descriptions: [
      'Your answer may help your dentist accomodate you by scheduling you at an ideal time or potentially offering you other options that may help.',
    ],
  },
  {
    id: 573,
    question: 'Do you still want to see an Orthodontist ?',
    descriptions: [
      'An Orthodontist is a dentist (DMD/DDS) who completed a two to three year residency in Orthodontic Disorders after completing four years of dental school. An Orthodontist  specializes in the diagnosis, prevention, and treatment of dental and facial irregularities.',
    ],
    title: 'Orthodontist ',
    optionType: 0,
    questionType: 1,
    canDoList: [],
    options: [
      {groupId: 'A3', label: 'Yes, see Orthodontist', isGoToSymptomFlow: false},
      {label: 'No, I am looking for something else', nextQuestionId: 2202, addExtraQuestion: 4},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 571,
    lastQuestionId: 573,
    treeDepth: 3,
  },
};
