import React, {Component} from 'react';
import moment from 'moment';
import _ from 'lodash';
import PatientLayout from 'layout/PatientLayout';

import AuthContext from 'context/AuthContext';
import patientService from 'services/patient';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';

import images from 'data/images';

import './ConfirmAppointmentThanks.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import {SocialTermsofUse} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/SocialTermsofUse';
import momentHelper from 'data/moment/momentHelper';
import {Passwordrequired} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/Passwordrequired';
import algoStorageHelper from 'data/algoStorage.helper';
import {SocialGenderDobMain} from 'components/Patient/Auth/SignUp/PatientSignUpStepOne/SocialGenderDob';
import AppointmentRequestThankYou from 'components/Patient/ConfirmAppointment/AppointmentRequest/AppointmentRequestThankYou';
import RescheduleAppointmentModal from 'components/Patient/PatientDashboard/RescheduleAppointmentModal';
import {Link} from 'react-router-dom';
const {utcToLocalMoment, localToUtcMoment} = momentHelper;
const {updateAppointmentTime} = patientService;
const {modalFunctions} = modalConfig;
const {errorModalData, warningModalData, blankModalData, successModalData, infoModalData} =
  modalFunctions;
const {deleteAlgo} = algoStorageHelper;
const {Analytics} = analyticsData;

class ConfirmAppointmentThanks extends Component {
  static contextType = AuthContext;
  state = {
    showAppointmentRequest: false,
    differentDomain: false,
    domainLink: '',
    domainName: '',
  };
  rootContext = null;

  getHistoryState = () => {
    const {location} = this.props;
    const {state} = location;
    return state;
  };

  handleError = error => {
    const {setGlobal} = this.rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  openEditAppointmentTimeModal = () => {
    const state = this.getHistoryState();
    const dateAndTimeObject = _.get(state, 'dateAndTimeObject');
    const isVirtual = _.get(state, 'dateAndTimeObject.timeObject.isVirtual', false);
    const dateTimeString = dateAndTimeObject.dateString + ' ' + dateAndTimeObject.timeObject.time;
    const apStartDateTimeObj = utcToLocalMoment(
      localToUtcMoment(moment(dateTimeString, 'YYYY-MM-DD h:mm a', true)).format(),
    );

    const providerId = _.get(state, 'doctorDetail.providerId', 0);
    const locationId = _.get(state, 'doctorDetail.locationId', 7);
    const address = _.get(state, 'doctorDetail.address', '');
    const doctorName = _.get(state, 'doctorDetail.name', '');
    const suffixes = _.get(state, 'doctorDetail.suffixes', []);
    const specialties = _.get(state, 'doctorDetail.specialties', []);
    const existingAppointmentTime = _.get(state, 'doctorDetail.existingAppointmentTime', 15);
    const newAppointmentTime = _.get(state, 'doctorDetail.newAppointmentTime', 15);
    const customSpecialty = _.get(state, 'doctorDetail.customSpecialty', null);
    const newAppointmentTimeorExAT =
      existingAppointmentTime > newAppointmentTime ? existingAppointmentTime : newAppointmentTime;
    const appointment = {
      appointmentStartDateTime: apStartDateTimeObj.utc().toISOString(),
      isExistingPatient: true,
      providerAvailability: {
        isVirtual,
        practiceLocation: {
          id: locationId,
          address,
        },
        provider: {
          id: providerId,
          suffixes,
          specialties,
          doctorName,
          newAppointmentTimeorExAT,
          newAppointmentTime,
          existingAppointmentTime,
          customSpecialty,
        },
      },
    };

    Nux.sendEvent(
      Analytics.Category.PatientAppointment,
      Analytics.Actions.Click,
      'editAppointmentTime',
    );
    const {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: RescheduleAppointmentModal,
        customComponentProps: {
          data: {
            title: 'Edit Appointment Time',
            appointment,
          },
          timeSelected: this.handleTimeSelection,
        },
      }),
    );
  };

  handleTimeSelection = (dateString, timeObject) => {
    // const query = new URLSearchParams(window.location.search);
    const state = this.getHistoryState();
    const appointmentId = state.appointmentData.appointmentId;
    // const appointmentSource = query.get('appointmentSource');
    // const accessLogProviderId = query.get('accessLogProviderId');
    // const accessLogPracticeId = query.get('accessLogPracticeId');
    // let appointmentSourceUrl = appointmentSource ? `?appointmentSource=${appointmentSource}` : '';
    // const accessLog = accessLogProviderId
    //   ? `accessLogProviderId=${accessLogProviderId}`
    //   : accessLogPracticeId
    //   ? `accessLogPracticeId=${accessLogPracticeId}`
    //   : '';
    // appointmentSourceUrl = appointmentSourceUrl
    //   ? `${appointmentSourceUrl}&${accessLog}`
    //   : `?${accessLog}`;

    // const state = this.getHistoryState();
    // this.props.history.replace({
    //   pathname: `/confirm-appointment`,
    //   search: appointmentSourceUrl,
    //   state: {
    //     ...state,
    //     dateAndTimeObject: {dateString, timeObject},
    //   },
    // });
    // const dateTimeString = dateString + ' ' + timeObject.time;
    // const utcDateTimeString = moment(dateTimeString, 'YYYY-MM-DD h:mm a')
    //   .utc()
    //   .format('YYYY-MM-DD HH:mm:ss');
    // const providerAvailabilityId = _.get(appointment, 'providerAvailability.id', 0);
    // this.handleAppointmentTimeUpdate({
    //   appointmentId: appointmentId,
    //   providerAvailabilityId,
    //   appointmentDateTime: utcDateTimeString,
    // });
  };
  handleAppointmentTimeUpdate = async data => {
    const {setGlobal} = this.rootContext;
    const response = await updateAppointmentTime(data).catch(this.handleErrorAndRefetch);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getAndSetAppointments();
          },
        }),
      );
    }
  };
  getDomain(url, subdomain) {
    subdomain = subdomain || false;

    url = url.replace(/(https?:\/\/)?(www.)?/i, '');

    if (!subdomain) {
      url = url.split('.');

      url = url.slice(url.length - 2).join('.');
    }

    if (url.indexOf('/') !== -1) {
      return url.split('/')[0];
    }

    return url;
  }
  componentDidMount() {
    let storageValue = window.sessionStorage.getItem('referrer');
    if (storageValue && storageValue !== '') {
      let domain = new URL(storageValue.trim());
      if (!domain.toString().includes('kaly.com')) {
        this.setState({
          differentDomain: true,
          // domainLink: storageValue.toString(),
          // domainName: domain.toString(),
        });
        let domainName = this.getDomain(domain.toString());
        if (
          [
            'newyorkpaincare.com',
            'thespineandrehabgroup.com',
            'advancedheadachecenter.com',
          ].includes(domainName)
        ) {
          setTimeout(() => {
            window.location.replace(`${domain.origin}/thank-you/`);
          }, 1000);
        } else {
          setTimeout(() => {
            window.location.replace(storageValue.toString());
          }, 1000);
        }
      }
    }
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem('referrer');
  }
  render() {
    const state = this.getHistoryState();

    // if (!state) {
    //   this.props.history.push('/');
    //   return null;
    // }
    const {auth, updateAuthStatus} = this.context;
    const {showAppointmentRequest, differentDomain} = this.state;
    let currentStep = 1;
    if (auth) {
      currentStep = 2;
    }
    return (
      <div
        className={`ConfirmAppointmentThanks ${
          showAppointmentRequest ? 'showappointment-mobile' : ''
        }`}>
        <PatientLayout hideFooter={true} planeHeader={true}>
          <RootContext.Consumer>
            {context => {
              this.rootContext = context;
              return null;
            }}
          </RootContext.Consumer>
          <div className="ConfirmAppointmentThanks-content">
            <div className="title-heading">
              <img src={images.okthanks} alt="success" />
              <h1>Thank You</h1>
              <h3>Your appointment request has been successfully sent.</h3>
            </div>
            <div className="goback-div">
              {differentDomain ? (
                <></>
              ) : (
                // <a
                //   onClick={() => {
                //     updateAuthStatus(null);
                //   }}
                //   href={domainLink}>
                //   Go to <span style={{textDecoration: 'underline'}}>{domainName}</span>
                // </a>
                <Link to={'/'}>Go to my Appointments</Link>
              )}
            </div>
            {/* <div style={{}}>
              <AppointmentRequestThankYou
                hideBackArrow={true}
                isShowEditAppointmentButton={false}
                openEditAppointmentTimeModal={this.openEditAppointmentTimeModal}
                state={state}
              />
            </div> */}
          </div>
        </PatientLayout>
      </div>
    );
  }
}

export default ConfirmAppointmentThanks;
