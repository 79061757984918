import axiosInstance from 'config/axios';


const searchHistoryCreate = (data) => {
  return axiosInstance.post('v1/searchHistory/create',data,{noLoader: true});
};

const searchHistoryUpdate = data => {
  return axiosInstance.put('v1/searchHistory/update', data,{noLoader: true});
};

export  {
  searchHistoryCreate,
  searchHistoryUpdate,
};
