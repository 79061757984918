import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import images from 'data/images';

import InfiniteScroll from 'components/InfiniteScroll';

import './ProfileSpecialtyTitles.css';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import adminService from 'services/admin'
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';

const { updateCustomSpecialty } = adminService;

const ProfileSpecialtyTitles = props => {
  const { closeModal } = props;
  const { data, getProviderData } = props;
  const { providerId, providerName, specialties, customSpecialty } = data;
  const { modalFunctions } = modalConfig;
  const { successModalData, errorModalData } = modalFunctions;
  const rootContext = useContext(RootContext);
  const [customSpecialties, setCustomSpecialties] = useState('');
  const { setGlobal } = rootContext;
  useEffect(() => {
    setCustomSpecialties(customSpecialty)
  }, [])
  const getPreviewText = () => {
    if (customSpecialties)
      return _.map([...specialties, customSpecialties], data => {
        return data;
      }).join(', ')
    else {
      return _.map([...specialties], data => {
        return data;
      }).join(', ')
    }
  }
  const handleError = error => {
    const { message } = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const saveupdateCustomSpecialty = async (isClear = false) => {
    updateCustomSpecialty({ providerId: providerId, customSpecialty: isClear ? '' : customSpecialties }).then((res) => {
      const { message } = res;
      getProviderData()
      setGlobal('modal', successModalData(message));


    }).catch(handleError)

  }
  const getErrorMsg = () => {
    let tempMsg = customSpecialties

    if (tempMsg && (tempMsg.startsWith(',') || tempMsg.startsWith(' '))) {
      return 'Enter the specialty without comma or space.'
    }
    return false;
  }
  return (
    <div className="ProfileSpecialtyTitles">
      <div className="modal-content-header">
        <div className="heading3">
          Custom Specialty Titles - {providerName}
        </div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <div className="patient-details">
          <InfiniteScroll
            offset={100}

            setIsFetching={() => { }}
          >
            <div>
              <h3>Specialties in Profile</h3>
              <div className='each-spec'>
                {_.map(specialties, data => {
                  return data;
                }).join(', ')}
              </div>
            </div>
            <div>
              <h3>Custom Specialties</h3>
              <p className='sub-para'>Enter the specialty without comma or space</p>
              <Input
                error={getErrorMsg() ? true : false}
                type="text"
                onChange={event => setCustomSpecialties(event.target.value)}
                value={customSpecialties}
                containerClass="column3"
                placeholder='Custom Specialty'
              />
              {getErrorMsg() && (
                <span className='error-span'>{getErrorMsg()}</span>
              )}
            </div>
            <div>
              <h3>Preview</h3>
              <div className='each-spec'>
                {getPreviewText()}
              </div>
            </div>
            <div className='footer-div' style={{}}>
              <CircleButton
                disabled={getErrorMsg()}
                onClick={() => {
                  saveupdateCustomSpecialty()
                }}>
                Save
              </CircleButton>
              <CircleButton

                disabled={customSpecialties === customSpecialty}
                onClick={() => { setCustomSpecialties(customSpecialty); }}>
                Reset
              </CircleButton>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>


  );
};

export default ProfileSpecialtyTitles;
