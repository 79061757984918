import React from 'react';
import _ from 'lodash';
import momentHelper from 'data/moment/momentHelper';
import images from 'data/images';
import './AppointmentsList.css';
import TimeZoneLabelShort from 'components/MyDatetime/TimeZoneLabelShort';

const {utcToLocalMoment} = momentHelper;

const AppointmentsList = props => {
  const {appointments, maxUpcomingAppointments, isShowAll} = props;
  const contentCompoent = appointments.map((appointment, i) => {
    if (!isShowAll && i + 1 > maxUpcomingAppointments) {
      return null;
    }
    const firstName = _.get(appointment, 'patient.firstName', '');
    const lastName = _.get(appointment, 'patient.lastName', '');
    const appointmentStartDateTime = _.get(appointment, 'appointmentStartDateTime');
    const appointmentStartDateTimeObject = utcToLocalMoment(appointmentStartDateTime);
    const isVirtual = _.get(appointment, 'isVirtual', '');

    return (
      <span className="Appointments-item" key={i}>
        <div className="Patient-name">
          {firstName} {lastName.substr(0, 1)}.
        </div>
        <div className="appointment-time">
          {appointmentStartDateTimeObject.format('dddd, MMM DD [at] h:mm a')}<TimeZoneLabelShort/>
        </div>
        <span className="appoinment-icon">
          {isVirtual ? (
            <img alt="video-icon" src={images.videoCamera} />
          ) : (
            <img alt="person-icon" src={images.personIcon} />
          )}
        </span>
      </span>
    );
  });
  return <div className="AppointmentsList">{contentCompoent}</div>;
};

export default AppointmentsList;
