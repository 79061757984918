import React from 'react';
import './Overlay.css';

const Overlay = props => {
  const {className = '', show = true, ...otherProps} = props;

  return show && <div className={`Overlay ${className}`} {...otherProps}></div>;
};

export default Overlay;
