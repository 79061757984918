import React, {useEffect} from 'react';
import _ from 'lodash';
import Input from 'components/FormControls/Input';
import TagsInput from 'components/FormControls/TagsInput';
import InputList from 'components/FormControls/InputList';
import CircleButton from 'components/CircleButton';
import validators from 'data/validators';
import config from 'config/config';
import './ProviderInformation.css';
import helper from 'data/helper';

const {isArrayEqual} = helper;
const {__required, __maxLength,_scrollToView} = validators;
const {MAX_TEXT_LENGTH} = config;

const ProviderInformation = props => {
  const {data, infoFormData, setInfoFormData, options, onSaveAdditionalInfo, setChangedSection} =
    props;
  const {graduateSchoolStatus, undergraduateSchoolStatus, trainingStatus,hospitalAffiliationStatus=[]} = data;
  const {certificationList, languageList} = options;
  // if (trainings.length === 0) trainings.push('');
  // if (languages.length === 0) languages.push('');
  // if (hospitalAffiliations.length === 0) hospitalAffiliations.push('');
  // pre-saved array information validation
  const {values: formValues, errors: formErrors} = infoFormData;

  useEffect(() => {
    const isAnyChanged =
      checkGraduateSchool() ||
      checkUndergraduateSchool() ||
      checklanguages() ||
      checkBoardCertifications() ||
      checkHospitalAffiliations() ||
      checkTrainings();
    setSectionActivation(isAnyChanged);
  }, [
    formValues.educationGraduateSchool,
    formValues.educationUndergraduateSchool,
    formValues.languages,
    formValues.boardCertifications,
    formValues.hospitalAffiliations,
    formValues.trainings,
  ]);

  const checkGraduateSchool = () => {
    const ischanged = !_.isEqual(
      _.get(data, 'educationGraduateSchool', ''),
      _.get(formValues, 'educationGraduateSchool', ''),
    );
    return ischanged;
  };

  const checkUndergraduateSchool = () => {
    const ischanged = !_.isEqual(
      _.get(data, 'educationUndergraduateSchool', ''),
      _.get(formValues, 'educationUndergraduateSchool', ''),
    );
    return ischanged;
  };

  const checklanguages = () => {
    const ischanged = !isArrayEqual(
      _.get(data, 'languages', ''),
      _.get(formValues, 'languages', []),
    );
    return ischanged;
  };

  const checkBoardCertifications = () => {
    const ischanged = !isArrayEqual(
      _.get(data, 'boardCertifications', ''),
      _.get(formValues, 'boardCertifications', []),
    );
    return ischanged;
  };

  const checkHospitalAffiliations = () => {
    const ischanged = !isArrayEqual(
      _.get(data, 'hospitalAffiliations', ''),
      _.get(formValues, 'hospitalAffiliations', []),
    );
    return ischanged;
  };

  const checkTrainings = () => {
    const ischanged = !isArrayEqual(
      _.get(data, 'trainings', ''),
      _.get(formValues, 'trainings', []),
    );
    return ischanged;
  };

  const setSectionActivation = isChanged => {
    if (isChanged) {
      setChangedSection('info');
    } else {
      setChangedSection('');
    }
  };

  // Validation functions
  const __validateList = name => {
    const array = formValues[name];
    let isValid = true;
    if (array) {
      array.forEach(email => {
        if (!__required(email)) {
          isValid = false;
        }
      });
    }
    return isValid;
  };
  const __validateTrainings = (name,key) => {
    const array = formValues[name];
    let tempvalArray=[]
    array.forEach(email => {
      if(!__required(email)){
        tempvalArray.push(`${key} is required`)
      }else     
      tempvalArray.push(null)
    });
    
    return tempvalArray;
  };
  const __validateLanguageList = (name,value=null) => {
    const array =value?value: formValues[name];
    if(array && array.length=== 0){
      return `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
    }
    return null
  };
  const __validateEducationGraduateSchool = (value = null) => {
    const fValue = value !== null ? value : formValues['educationGraduateSchool'];
    if (!__required(fValue)) {
      return 'Education: Graduate School is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateEducationUndergraduateSchool = (value = null) => {
    const fValue = value !== null ? value : formValues['educationUndergraduateSchool'];
    if (!__required(fValue)) {
      return 'Education: Undergraduate School is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };

  const __validateForm = () => {
    const validEducationGraduateSchool = !__validateEducationGraduateSchool();
    const validEducationUndergraduateSchool = !__validateEducationUndergraduateSchool();
    const validTrainings = __validateList('trainings');
    const validLanguages = !__validateLanguageList('languages');
    const validHospitalAffiliations = __validateList('hospitalAffiliations');
    
    return (
      validEducationGraduateSchool &&
      validEducationUndergraduateSchool &&
      validTrainings &&
      validLanguages &&
      validHospitalAffiliations
    );
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'educationGraduateSchool':
        errorObject = {
          [name]: __validateEducationGraduateSchool(value),
        };
        break;
      case 'educationUndergraduateSchool':
        errorObject = {
          [name]: __validateEducationUndergraduateSchool(value),
        };
        break;
      case 'trainings':
        errorObject = {
          [name]: __validateTrainings('trainings','Training'),
        };
        break;
      case 'languages':
        errorObject = {
          [name]: __validateLanguageList('languages',value),//event.target.error,
        };
        break;
      case 'hospitalAffiliations':
        errorObject = {
          [name]: __validateTrainings('hospitalAffiliations','Hospital Affiliations'),
        };
        break;
      default:
        break;
    }
    // setInfoFormData({
    //   ...infoFormData,
    //   values: {
    //     ...infoFormData.values,
    //     [name]: value,
    //   },
    //   errors: {
    //     ...infoFormData.errors,
    //     ...errorObject,
    //   },
    // });
    setInfoFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,          
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  const handleForm = () => {
    if (!isValidForm) {
      let valError = [
        'educationGraduateSchool',
        'educationUndergraduateSchool',
        'trainings',
        'languages','hospitalAffiliations'
      
      ];
      valError.forEach(name => {
        changeHandler({target: {name: name, value:formValues[name]}});
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    onSaveAdditionalInfo(formValues);
  };

  const onAdd = (name, specialty) => {
    const customEvent = {
      target: {
        value: [...formValues[name], specialty],
        name,
      },
    };
    changeHandler(customEvent);
  };
  const onRemove = (name, specialty) => {
    const updatedSpecialities = [...formValues[name]];
    _.remove(updatedSpecialities, value => value === specialty);
    const customEvent = {
      target: {
        value: updatedSpecialities,
        name,
      },
    };
    changeHandler(customEvent);
  };
  const onInputBlur = (name, selectedValues) => {
    const customEvent = {
      target: {
        value: selectedValues,
        name,
      },
    };
    changeHandler(customEvent);
  };

  const trainingListProps = {
    name: 'trainings',
    listItems: formValues['trainings'],
    errors: formErrors['trainings'],
    onListUpdated: changeHandler,
    validateItem: __required,
    label: 'Residency, Fellowships, Training:',
    addLabelText: 'Add Another',
    removeLabelText: 'Remove',
    maxItems: 5,
    minItems: 0,
    checkStatus: true,
    dataStatus: trainingStatus,
    placeholder: 'Ex: Residency: ABC Medical Center - Internal Medicine',
    subTitle: 'Add training in chronological order starting with the most recent training first.',
  };
  const hospitalAffiliationListProps = {
    name: 'hospitalAffiliations',
    listItems: formValues['hospitalAffiliations'],
    errors: formErrors['hospitalAffiliations'],
    onListUpdated: changeHandler,
    validateItem: __required,
    label: 'Hospital Affiliations',
    addLabelText: 'Add Another',
    removeLabelText: 'Remove',
    maxItems: 5,
    minItems: 0,
    checkStatus: true,
    dataStatus: hospitalAffiliationStatus,
  };
  return (
    <div className="ProviderInformation">
      <div className="titleHeading">
        <div className="h3">Additional Information</div>
      </div>
      <div className="ProviderInformation-inner">
        <form>
          <InputList {...trainingListProps} />
          <Input
            {...getGeneralInputProps('educationGraduateSchool')}
            label="Education: Graduate School"
            containerClass="required-link"
            checkStatus={true}
            fieldStatus={graduateSchoolStatus}
            placeholder="Ex: Medical School: ABC Medical School"
          />
          <Input
            {...getGeneralInputProps('educationUndergraduateSchool')}
            label="Education: Undergraduate School"
            containerClass="required-link"
            checkStatus={true}
            fieldStatus={undergraduateSchoolStatus}
            placeholder="EX: Undergraduate School: ABC University"
          />

          <TagsInput
            error={formErrors['languages']}
            label="Languages Spoken"
            options={languageList}
            checkStatus={true}
            labelIndex="name"
            valueIndex="id"
            selectedValues={formValues['languages']}
            onAdd={item => onAdd('languages', item)}
            onRemove={item => onRemove('languages', item)}
            onInputBlur={selectedList => onInputBlur('languages', selectedList)}
          />
          <InputList {...hospitalAffiliationListProps} />
          <TagsInput
            error={formErrors['boardCertifications']}
            label="Board Certifications"
            options={certificationList}
            labelIndex="certification"
            valueIndex="id"
            selectedValues={formValues['boardCertifications']}
            onAdd={item => onAdd('boardCertifications', item)}
            onRemove={item => onRemove('boardCertifications', item)}
            onInputBlur={selectedList => onInputBlur('boardCertifications', selectedList)}
            checkStatus={true}
          />
        </form>
      </div>
      <div className="info-bottom">
        <CircleButton onClick={handleForm}>
          Save
        </CircleButton>
      </div>
    </div>
  );
};

export default ProviderInformation;
