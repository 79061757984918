import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import CircleButton from 'components/CircleButton';
import Checkbox from '../Checkbox';
import './Gender.css';
import constants from 'data/constants';

const {DoctorFilters} = constants;

const Gender = props => {
  const {
    options = [],
    values = [],
    labelIndex = 'label',
    valueIndex = 'value',
    onFilterApply = () => {},
    activeFilter = null,
    onFilterSelect = () => {},
  } = props;
  let {containerClass = ''} = props;
  const [selectedFilter, setFilter] = useState(values);
  const [active, toggleActive] = useState(activeFilter === DoctorFilters.GENDER);

  useEffect(() => {
    setFilter(values);
  }, [values]);

  useEffect(() => {
    if (active && activeFilter !== DoctorFilters.GENDER) {
      onFilterSelect(DoctorFilters.GENDER);
    }
  }, [active]);

  useEffect(() => {
    if (active && activeFilter !== DoctorFilters.GENDER) {
      toggleActive(false);
    }
  }, [activeFilter]);

  const toggleCheckbox = value => {
    const newSelectedFilter = _.xor(selectedFilter, [value]);
    setFilter(newSelectedFilter);
  };

  const applyFilter = () => {
    toggleActive(false);
    if (!_.isEqual(values, selectedFilter)) {
      onFilterApply(selectedFilter);
    }
  };
  if (values.length > 0) {
    containerClass += ' selected';
  }

  return (
    <div className={`Gender ${containerClass}`}>
      <CircleButton
        btnType="secondaryButton"
        onClick={() => {
          toggleActive(!active);
        }}>
        Gender
      </CircleButton>
      {active && <div className="overlay" onClick={applyFilter}></div>}
      {active && (
        <div className={`dropdown-menu show-menu`}>
          {options.map(option => {
            return (
              <div className="dropdown-option" key={option[valueIndex]}>
                <Checkbox
                  value={selectedFilter.includes(option[valueIndex])}
                  onChange={e => toggleCheckbox(option[valueIndex])}>
                  {option[labelIndex]}
                  {/* Female <span className="count">(802)</span> */}
                </Checkbox>
              </div>
            );
          })}
          <div className="dropdown-bottom">
            {selectedFilter.length > 0 && (
              <CircleButton btnType="secondaryButton" onClick={() => setFilter([])}>
                Clear
              </CircleButton>
            )}
            <CircleButton btnType="whiteButton" onClick={applyFilter}>
              Apply
            </CircleButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gender;
