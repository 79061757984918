import React, {useState} from 'react';
import _ from 'lodash';
import TagsInput from 'components/FormControls/TagsInput';
import CircleButton from 'components/CircleButton';
import allRegex from 'data/regex';
import validators from 'data/validators';
import './InviteYourProviders.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import InviteMultipleProvider from './InviteMultipleProvider';
const {emailRegex} = allRegex;
const {__minLength} = validators;
const {Analytics} = analyticsData;

const InviteYourProviders = props => {
  const {nextStep, data} = props;
  const values = {...data};
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const [multipleProvider, setMultipleProvider] = useState([]);
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validateEmails = (value = null) => {
    const fValue = value !== null ? value : formValues['emails'];
    if (!__minLength(fValue, 1)) {
      return 'Please add atleast one Email';
    }
    return null;
  };
  const __validatemultipleProvider = () => {
    if (multipleProvider.length > 0) {
      let filteredProviders = multipleProvider.filter(p => p.fullValid);
      if (filteredProviders.length === 0) return 'Please add atleast one Provider';
      else {
        let tempPassData = filteredProviders.map(p => {
          return {
            firstName: p.firstName,
            lastName: p.lastName,
            email: p.email,
          };
        });
      
        if (JSON.stringify(formData.values.userDetails) !== JSON.stringify(tempPassData)) {
          setFormData({
            ...formData,
            values: {
              ...formData.values,
              userDetails: tempPassData,
            },
            errors: {
              ...formData.errors,
            },
          });
        }
      }
      return null;
    }
    return 'Please add atleast one Provider';
  };
  const __validateForm = () => {
    const validEmails = !__validatemultipleProvider();
    return validEmails;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'emails':
        errorObject = {
          [name]: __validateEmails(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleForm = isReset => {
    if (isReset) {
      nextStep(data);
    } else {
      Nux.sendEvent(
        Analytics.Category.DoctorRegistration,
        Analytics.Actions.Click,
        'InviteProviders',
      );
      nextStep(formValues);
    }
  };

  const validateEmail = tag => {
    return emailRegex.test(tag);
  };

  const onAdd = email => {
    const customEvent = {
      target: {
        value: [...formValues['emails'], email],
        name: 'emails',
      },
    };
    changeHandler(customEvent);
  };

  const onRemove = email => {
    const updatedEmails = [...formValues['emails']];
    _.remove(updatedEmails, value => value === email);
    const customEvent = {
      target: {
        value: updatedEmails,
        name: 'emails',
      },
    };
    changeHandler(customEvent);
  };
  const onInputBlur = selectedValues => {
    const customEvent = {
      target: {
        value: selectedValues,
        name: 'emails',
      },
    };
    changeHandler(customEvent);
  };

  return (
    <div className="InviteYourProviders">
      <div className="h3">Invite your Providers</div>
      <div className="paragraph">That are employed by your practice</div>
      <form className="signup-form">
        <div className="providers-input">
          {/* <span className="providers-instruction-text">(press enter to add item)</span>
          <TagsInput
            error={formErrors['emails']}
            label="Email of Providers"
            selectedValues={formValues['emails']}
            onValidate={validateEmail}
            onAdd={onAdd}
            onRemove={onRemove}
            onInputBlur={onInputBlur}
          /> */}
          <InviteMultipleProvider setMultipleProvider={arr => setMultipleProvider(arr)} />
        </div>
        <div className="bottom-paragraph">
          Recipients will receive an email to complete signup and their information will be verified
          by the Kaly team{' '}
        </div>
        <div className="signup-btn">
          <CircleButton
            disabled={!isValidForm}
            onClick={() => handleForm(false)}
            btnType="whiteButton">
            Continue
          </CircleButton>
        </div>
        <div className="signup-bottom">
          <span onClick={() => handleForm(true)}>Skip</span>
        </div>
      </form>
    </div>
  );
};

export default InviteYourProviders;
