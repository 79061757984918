import React, {useContext, useEffect, useState} from 'react';
import images from 'data/images';
import {Link} from 'react-router-dom';
import MyLink from 'components/MyLink';
import moment from 'moment';
import './PatientFooter.css';
import './PatientFooternew.css';
import AuthContext from 'context/AuthContext';
import RootContext from 'context/RootContext';
import _ from 'lodash';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import config from 'config/config';
import constants from 'data/constants';
import masterService from 'services/master';
import helper from 'data/helper';
const {getAllSpecialties, getAllInsurancePlan, getAllSpecialtiesNearme} = masterService;
const {StaticFooterPage} = constants;
const {Analytics} = analyticsData;
const {convertStringToSlug} = helper;
const PatientFooter = props => {
  const authContext = useContext(AuthContext);
  const siteSettings = _.get(authContext, 'siteSettings');
  const footerStype = {
    // backgroundImage: `url(${images.footerLogo})`,
    // backgroundRepeat: `no-repeat`,
    // backgroundPosition: `left bottom`,
    // backgroundSize: '526px',
  };
  // useEffect(()=>{
  //   if(window.location.pathname==="/"||window.location.pathname==="/app"||window.location.pathname==="/app/"){
  //     let aaatraq=document.createElement('script');
  //     aaatraq.src="https://shield.aaatraq.com/1b2c3c4f-5130-434d-a509-453ceb37981a-shield.js";
  //     aaatraq.async =true
  //     aaatraq.defer =true
  //   document.querySelector('#aa-script').appendChild(aaatraq)
  //   }
  //   },[window.location])
  return (
    <div className="PatientFooter" style={footerStype}>
      <div className="footer-top">
        <div className="container">
          <div className="footer-main">
            <div className="footer-left">
              <div className="footer-left-inner">
                <div className="footer-widget widget-one">
                  <img src={images.logo} alt="logo" />
                  <div className="paragraph">Connecting you with the right care</div>
                </div>
                <div className="footer-widget widget-two">
                  <div className="h3">Company</div>
                  <div className="footer-menu-ul">
                    <div className="menu-item-list">
                      <a
                        href={config.AWS_STATIC_PAGE_URL + StaticFooterPage.TERMS_CONDITION}
                        rel="noopener noreferrer">
                        Terms & Conditions
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.AWS_STATIC_PAGE_URL + StaticFooterPage.PRIVACY_POLICY}
                        rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.AWS_STATIC_PAGE_URL + StaticFooterPage.HIPAA}
                        rel="noopener noreferrer">
                        HIPAA NPP
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.AWS_STATIC_PAGE_URL + StaticFooterPage.CCPA}
                        rel="noopener noreferrer">
                        CCPA Policy
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.AWS_STATIC_PAGE_URL + StaticFooterPage.HEALTHCARE}
                        rel="noopener noreferrer">
                        Healthcare Provider Agreement
                      </a>
                    </div>
                    <div className="menu-item-list">
                      <a
                        href={config.AWS_STATIC_PAGE_URL + StaticFooterPage.REVIEW_POLICY}
                        rel="noopener noreferrer">
                        Review Policy
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer-widget widget-three">
                  <div className="h3">Services</div>
                  <div className="footer-menu-ul">
                    <div className="menu-item-list">
                      <Link to="/list-your-practice">List your practice</Link>
                    </div>
                    <div className="menu-item-list">
                      <a href={`mailto:${_.get(siteSettings, 'contactUsLinks', '')}`}>Contact us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-right">
              <div className="footer-right-inner">
                <div className="footer-menu-ul">
                  <div className="menu-item-list">
                    <MyLink
                      to={_.get(siteSettings, 'LinkedInLink', '')}
                      onClick={() => {
                        Nux.sendEvent(
                          Analytics.Category.PatientLanding,
                          Analytics.Actions.Click,
                          'socialLink',
                        );
                      }}>
                      <img src={images.linkedIn} alt="LinkedIn" />
                    </MyLink>
                  </div>
                  <div className="menu-item-list">
                    <MyLink
                      to={_.get(siteSettings, 'twitterLink', '')}
                      onClick={() => {
                        Nux.sendEvent(
                          Analytics.Category.PatientLanding,
                          Analytics.Actions.Click,
                          'socialLink',
                        );
                      }}>
                      <img src={images.twitter} alt="Twitter" />
                    </MyLink>
                  </div>
                  <div className="menu-item-list">
                    <MyLink
                      to={_.get(siteSettings, 'instagramLink', '')}
                      onClick={() => {
                        Nux.sendEvent(
                          Analytics.Category.PatientLanding,
                          Analytics.Actions.Click,
                          'socialLink',
                        );
                      }}>
                      <img src={images.instagram} alt="Instagram" />
                    </MyLink>
                  </div>
                  <div className="menu-item-list">
                    <MyLink
                      to={_.get(siteSettings, 'fbLink', '')}
                      onClick={() => {
                        Nux.sendEvent(
                          Analytics.Category.PatientLanding,
                          Analytics.Actions.Click,
                          'socialLink',
                        );
                      }}>
                      <img src={images.facebook} alt="Facebook" />
                    </MyLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="paragraph">©{moment().year()} Kaly All right reserved. </div>
        </div>
      </div>
    </div>
  );
};
const PatientFooterNew = props => {
  const authContext = useContext(AuthContext);
  const rootContext = useContext(RootContext);
  const siteSettings = _.get(authContext, 'siteSettings');
  const [specialtiesNearme, setSpecialtiesNearme] = useState([]);
  const {specialties, insurancePlans, geoLocationDetails, setGlobal} = rootContext;
  const footerStype = {
    // backgroundImage: `url(${images.footerLogo})`,
    // backgroundRepeat: `no-repeat`,
    // backgroundPosition: `left bottom`,
    // backgroundSize: '526px',
  };
  const [locationDetails, setLocationDetails] = useState({});

  const showallLimit = 9;
  const [showAllSpeciallities, setShowAllSpeciallities] = useState(false);
  const [showAllInsurances, setShowAllInsurances] = useState(false);
  useEffect(() => {
    if (geoLocationDetails) setLocationDetails(geoLocationDetails);
  }, [geoLocationDetails]);
  useEffect(() => {
    getAllSpecialtiesNearme().then(data => {
      setSpecialtiesNearme(data.data);
    });
  }, []);
  useEffect(() => {
    const getAndSetInsurancePlans = async () => {
      // setInsuranceLoading(true);
      if (!insurancePlans) {
        const insurancePlansResponse = await getAllInsurancePlan(true).catch(() => null);
        if (insurancePlansResponse) {
          const {data} = insurancePlansResponse;
          setGlobal('insurancePlans', data);
        }
      }
      //setInsuranceLoading(false);
    };
    if (!insurancePlans) getAndSetInsurancePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurancePlans]);
  // useEffect(()=>{
  // if(window.location.pathname==="/"||window.location.pathname==="/app"||window.location.pathname==="/app/"){
  //   let aaatraq=document.createElement('script');
  //   aaatraq.src="https://shield.aaatraq.com/1b2c3c4f-5130-434d-a509-453ceb37981a-shield.js";
  //   aaatraq.async =true
  //   aaatraq.defer =true
  // document.querySelector('#aa-script').appendChild(aaatraq)
  // }
  // },[window.location])
  let nearmeLocation = 'new-york';
  let gotoStatic = false;
  let regionCode = '';
  if (geoLocationDetails && geoLocationDetails.country_code === 'US') {
    // if (['NY', 'NJ', 'CT'].includes(geoLocationDetails.region_code)) {
    nearmeLocation = convertStringToSlug(geoLocationDetails.region_name);
    regionCode = `regioncode=${geoLocationDetails.region_code}`;
    //} else {
    //gotoStatic = true;
    //}
  }

  return (
    <div className="PatientFooter" style={footerStype}>
      <div className="footer-top new-footer-parent">
        <div className="container">
          <div className="newFooter">
            <div className="each-block">
              <label className="main-title">
                <a>Kaly</a>
              </label>
              <ul>
                <li>
                  <a
                    href={config.APP_DOMAIN + StaticFooterPage.TERMS_CONDITION}
                    rel="noopener noreferrer">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    href={config.APP_DOMAIN + StaticFooterPage.PRIVACY_POLICY}
                    rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href={config.APP_DOMAIN + StaticFooterPage.CCPA} rel="noopener noreferrer">
                    CCPA Policy
                  </a>
                </li>
                <li>
                  <a href={config.APP_DOMAIN + StaticFooterPage.HIPAA} rel="noopener noreferrer">
                    HIPAA NPP
                  </a>
                </li>
                <li>
                  <a
                    href={config.APP_DOMAIN + StaticFooterPage.HEALTHCARE}
                    rel="noopener noreferrer">
                    Healthcare Provider Agreement
                  </a>
                </li>
                <li>
                  <a
                    href={config.APP_DOMAIN + StaticFooterPage.REVIEW_POLICY}
                    rel="noopener noreferrer">
                    Review Policy
                  </a>
                </li>
                <li>
                  <a href={config.APP_DOMAIN + StaticFooterPage.FAQ} rel="noopener noreferrer">
                    FAQ
                  </a>
                </li>
                <li>
                  <a href={`https://www.kaly.com/blog/`} target="_blank" rel="noopener noreferrer">
                    Blog
                  </a>
                </li>
                <li>
                  <span
                    onClick={() => {
                      document.getElementById('accessibilityWidget').click();
                    }}
                    className="accessibilityWidget"
                    tabIndex="0">
                    Click for Accessibility
                  </span>
                </li>
                {/* <li>
                  <a
                    href={config.APP_DOMAIN + StaticFooterPage.PRICING}

                    rel="noopener noreferrer">
                    Pricing
                  </a>
                </li> */}
                <li>
                  <a onClick={() => document.getElementById('c-s-bn').click()}>Manage Cookies</a>
                </li>
                <li>
                  <a
                    href={config.APP_DOMAIN + StaticFooterPage.COOKIEPOLICY}
                    rel="noopener noreferrer">
                    Cookie Policy
                  </a>
                </li>
              </ul>
              <label className="main-title">
                <a>Services</a>
              </label>
              <ul style={{marginTop: '1em'}}>
                <li>
                  <Link to="/list-your-practice">List your practice</Link>
                </li>
                <li>
                  {' '}
                  <a href={`mailto:${_.get(siteSettings, 'contactUsLinks', '')}`}>Contact us</a>
                </li>
                <li style={{display: 'flex', marginTop: '30px'}}>
                  <div className="menu-item-list">
                    <MyLink
                      to={_.get(siteSettings, 'LinkedInLink', '')}
                      onClick={() => {
                        Nux.sendEvent(
                          Analytics.Category.PatientLanding,
                          Analytics.Actions.Click,
                          'socialLink',
                        );
                      }}>
                      <img src={images.linkedIn} alt="LinkedIn" />
                    </MyLink>
                  </div>
                  <div className="menu-item-list">
                    <MyLink
                      to={_.get(siteSettings, 'twitterLink', '')}
                      onClick={() => {
                        Nux.sendEvent(
                          Analytics.Category.PatientLanding,
                          Analytics.Actions.Click,
                          'socialLink',
                        );
                      }}>
                      <img src={images.twitter} alt="Twitter" />
                    </MyLink>
                  </div>
                  <div className="menu-item-list">
                    <MyLink
                      to={_.get(siteSettings, 'instagramLink', '')}
                      onClick={() => {
                        Nux.sendEvent(
                          Analytics.Category.PatientLanding,
                          Analytics.Actions.Click,
                          'socialLink',
                        );
                      }}>
                      <img src={images.instagram} alt="Instagram" />
                    </MyLink>
                  </div>
                  <div className="menu-item-list">
                    <MyLink
                      to={_.get(siteSettings, 'fbLink', '')}
                      onClick={() => {
                        Nux.sendEvent(
                          Analytics.Category.PatientLanding,
                          Analytics.Actions.Click,
                          'socialLink',
                        );
                      }}>
                      <img src={images.facebook} alt="Facebook" />
                    </MyLink>
                  </div>
                </li>
              </ul>
            </div>
            <div className="each-block">
              <label className="main-title">
                <a>Specialty</a>
              </label>
              {specialtiesNearme && specialtiesNearme.length > 0 && (
                <ul>
                  {specialtiesNearme
                    .slice(0, showAllSpeciallities ? specialtiesNearme.length : showallLimit)
                    .filter(i => i.id !== 'null')
                    .map(eachsp => (
                      <li key={eachsp.specialty}>
                        <a
                          rel="noopener noreferrer"
                          href={
                            gotoStatic
                              ? `${config.APP_DOMAIN}staticpage`
                              : `${config.APP_DOMAIN}nearme/${convertStringToSlug(
                                  eachsp.specialty,
                                )}/${nearmeLocation}?${regionCode}`
                          }>
                          {eachsp.specialty}
                        </a>
                      </li>
                    ))}
                  <li>
                    <a onClick={() => setShowAllSpeciallities(!showAllSpeciallities)}>
                      {showAllSpeciallities ? 'Hide' : 'See all'}
                    </a>
                  </li>
                </ul>
              )}
            </div>
            <div className="each-block">
              <label className="main-title">
                <a>Cities</a>
              </label>

              <ul>
                <li>
                  <a
                    href={`${config.APP_DOMAIN}nearme/internal-medicine-physician/new-york`}
                    rel="noopener noreferrer">
                    New York City
                  </a>
                </li>
                <li>
                  <a
                    href={`${config.APP_DOMAIN}nearme/internal-medicine-physician/queens`}
                    rel="noopener noreferrer">
                    Queens
                  </a>
                </li>
                <li>
                  <a
                    href={`${config.APP_DOMAIN}nearme/internal-medicine-physician/long-island`}
                    rel="noopener noreferrer">
                    Long Island
                  </a>
                </li>
                <li>
                  <a
                    href={`${config.APP_DOMAIN}nearme/internal-medicine-physician/brooklyn`}
                    rel="noopener noreferrer">
                    Brooklyn
                  </a>
                </li>
                <li>
                  <a
                    href={`${config.APP_DOMAIN}nearme/internal-medicine-physician/bronx`}
                    rel="noopener noreferrer">
                    Bronx
                  </a>
                </li>
              </ul>
            </div>

            <div className="each-block">
              <label className="main-title">
                <a>Insurances</a>
              </label>
              {insurancePlans && insurancePlans.length > 0 && (
                <ul>
                  {insurancePlans
                    .slice(0, showAllInsurances ? insurancePlans.length : showallLimit)
                    .filter(sp => sp.id !== null)
                    .map(eachin => (
                      <li key={eachin.id}>
                        <a
                          rel="noopener noreferrer"
                          href={
                            gotoStatic
                              ? `${config.APP_DOMAIN}staticpage`
                              : `${
                                  config.APP_DOMAIN
                                }nearme/internal-medicine-physician/${nearmeLocation}?insurances=${convertStringToSlug(
                                  eachin.companyName,
                                )}&insuranceId=${eachin.id}&${regionCode}`
                          }>
                          {eachin.companyName}
                        </a>
                      </li>
                    ))}
                  <li>
                    <a onClick={() => setShowAllInsurances(!showAllInsurances)}>
                      {showAllInsurances ? 'Hide' : 'See all'}
                    </a>
                  </li>
                </ul>
              )}
            </div>
            <div className="each-block">
              <label className="main-title">
                <a>Find your Doctor</a>
              </label>

              <ul>
                <li>
                  <a
                    href={`${config.APP_DOMAIN}nearme/internal-medicine-physician/new-york`}
                    rel="noopener noreferrer">
                    Doctors in New York
                  </a>
                </li>
                <li>
                  <a
                    href={`${config.APP_DOMAIN}nearme/internal-medicine-physician/${nearmeLocation}?${regionCode}`}
                    rel="noopener noreferrer">
                    Doctors by Location
                  </a>
                </li>
                <li>
                  <a
                    href={`${config.APP_DOMAIN}nearme/internal-medicine-physician/${nearmeLocation}?${regionCode}`}
                    rel="noopener noreferrer">
                    Doctors by Insurance
                  </a>
                </li>
                <li>
                  <a
                    href={`${config.APP_DOMAIN}nearme/internal-medicine-physician/${nearmeLocation}?${regionCode}`}
                    rel="noopener noreferrer">
                    Doctors by Specialty
                  </a>
                </li>
              </ul>
              <label className="main-title">
                <a>Get the Kaly app</a>
              </label>
              <ul style={{marginTop: '1em'}}>
                <li>
                  <a
                    href="https://apps.apple.com/us/app/kaly-find-a-perfect-provider/id1610930602"
                    target="_blank">
                    <img width={120} src={images.applestore} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.kalypsys.patient"
                    target="_blank">
                    <img width={120} src={images.androidstore} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="aa-script" className={['footer-bottom'].join(' ')}>
        <div className="container">
          <div className="paragraph hideonmobile">
            ©{moment().year()} Kaly All Rights Reserved.{' '}
          </div>
          <div className={['mobilefooternew'].join(' ')}>
            <a
              href={config.APP_DOMAIN + StaticFooterPage.TERMS_CONDITION}
              rel="noopener noreferrer">
              Terms & Conditions
            </a>

            <a href={config.APP_DOMAIN + StaticFooterPage.PRIVACY_POLICY} rel="noopener noreferrer">
              Privacy Policy
            </a>

            <a href={config.APP_DOMAIN + StaticFooterPage.CCPA} rel="noopener noreferrer">
              CCPA Policy
            </a>

            <a href={config.APP_DOMAIN + StaticFooterPage.HIPAA} rel="noopener noreferrer">
              HIPAA NPP
            </a>

            <a href={config.APP_DOMAIN + StaticFooterPage.HEALTHCARE} rel="noopener noreferrer">
              Healthcare Provider Agreement
            </a>

            <a href={config.APP_DOMAIN + StaticFooterPage.REVIEW_POLICY} rel="noopener noreferrer">
              Review Policy
            </a>

            <a href={config.APP_DOMAIN + StaticFooterPage.FAQ} rel="noopener noreferrer">
              FAQ
            </a>
            <a href={`https://www.kaly.com/blog/`} target="_blank" rel="noopener noreferrer">
              Blog
            </a>
            {/* <a
              href={config.APP_DOMAIN + StaticFooterPage.PRICING}

              rel="noopener noreferrer">
              Pricing
            </a> */}

            <a onClick={() => document.getElementById('c-s-bn').click()}>Manage Cookies</a>
            <a href={config.APP_DOMAIN + StaticFooterPage.COOKIEPOLICY} rel="noopener noreferrer">
              Cookie Policy
            </a>
          </div>
          <div className={['footer-sociallink'].join(' ')}>
            <MyLink
              to={_.get(siteSettings, 'LinkedInLink', '')}
              onClick={() => {
                Nux.sendEvent(
                  Analytics.Category.PatientLanding,
                  Analytics.Actions.Click,
                  'socialLink',
                );
              }}>
              <img src={images.linkedIn} alt="LinkedIn" />
            </MyLink>
            <MyLink
              to={_.get(siteSettings, 'twitterLink', '')}
              onClick={() => {
                Nux.sendEvent(
                  Analytics.Category.PatientLanding,
                  Analytics.Actions.Click,
                  'socialLink',
                );
              }}>
              <img src={images.twitter} alt="Twitter" />
            </MyLink>

            <MyLink
              to={_.get(siteSettings, 'instagramLink', '')}
              onClick={() => {
                Nux.sendEvent(
                  Analytics.Category.PatientLanding,
                  Analytics.Actions.Click,
                  'socialLink',
                );
              }}>
              <img src={images.instagram} alt="Instagram" />
            </MyLink>

            <MyLink
              to={_.get(siteSettings, 'fbLink', '')}
              onClick={() => {
                Nux.sendEvent(
                  Analytics.Category.PatientLanding,
                  Analytics.Actions.Click,
                  'socialLink',
                );
              }}>
              <img src={images.facebook} alt="Facebook" />
            </MyLink>
            <div>
              <span className="accessibilityWidget">Get the Kaly app</span>
              <br></br>
              <div style={{marginTop: '5px'}}>
                <a
                  href="https://apps.apple.com/us/app/kaly-find-a-perfect-provider/id1610930602"
                  target="_blank">
                  <img src={images.applestore} style={{width: '100px', height: 'auto'}} />
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.kalypsys.patient"
                  target="_blank">
                  <img style={{width: '100px', height: 'auto'}} src={images.androidstore} />
                </a>
              </div>
            </div>
            <p>
              <span
                onClick={() => {
                  document.getElementById('accessibilityWidget').click();
                }}
                className="accessibilityWidget"
                tabIndex="0">
                Click for Accessibility
              </span>
            </p>

            <p className="footercopy-right"> ©{moment().year()} Kaly All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientFooter;
export {PatientFooterNew};
