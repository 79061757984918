import React, {useState} from 'react';

import images from 'data/images';
import CircleButton from 'components/CircleButton';
import Loader from 'components/Loader';
export function ViewPhotoId(props) {
  const {url, userName, closeModal,fileType=`Photo Id`} = props;
  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <div className="CreatePasswordModal termsofuse viewPhotoIdmodal">
      <div className="modal-content-header">
        <div className="h3">{userName?`${userName}'s `:''}{fileType}</div>
        <span
          className="close"
          onClick={() => {
            closeModal();
          }}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <div style={{maxWidth: '488px', overflow: 'scroll'}} style={{textAlign: 'center'}}>
          {!url.endsWith('.pdf')?(
            <>
          <div style={{display: loading ? 'block' : 'none', padding: '100px'}}>
            {' '}
            <div style={{position: 'relative'}} className="Loader">
              Loader
            </div>
          </div>
          <img
            style={{display: loading ? 'none' : 'block'}}
            onLoad={onLoad}
            width="100%"
            src={url}
          />
          </>
          ):(
            <>
            <div style={{display: loading ? 'block' : 'none', padding: '100px'}}>
            {' '}
            <div style={{position: 'relative'}} className="Loader">
              Loader
            </div>
          </div>
            <iframe style={{display: loading===true ? 'none' : 'block'}} onLoad={onLoad} src={url} width="100%" />
            </>
          )}
        </div>
      </div>
      <div className="modal-content-footer"></div>
    </div>
  );
}
