import painData from 'data/painData';
// static import of data files.
import lowBackPainData from './lowBackPain';
import midBackPainData from './midBackPain';
import hipPainData from './hipPain';
import neckPainData from './neckPain';
import pinchedNerveData from './pinchedNerve';
import sciaticaData from './sciatica';
import shoulderPainData from './shoulderPain';
import elbowPainData from './elbowPain';
import footPainData from './footPain';
import handPainData from './handPain';
import kneePainData from './kneePain';
import headacheData from './headache';
import pcpSymptomsData from './pcpSymptoms';
import insomniaData from './insomnia';
import erectileDysfunctionData from './erectileDysfunction';
import birthControlData from './birthControl';
import hairLossData from './hairLoss';
import acneData from './acne';
import prematureEjaculationData from './prematureEjaculation';
import urinaryTractInfectionData from './urinaryTractInfection';
import coldSoreData from './coldSore';
import genitalHerpesData from './genitalHerpes';
import asthmaData from './asthma';
import acidRefluxData from './acidReflux';
import hotFlashesData from './hotFlashes';
import darkSpotsData from './darkSpots';
import hypothyroidismData from './hypothyroidism';
import stopSmokingData from './stopSmoking';
import highBloodPressureData from './highBloodPressure';
import cholesterolData from './cholesterol';
import stdTestingData from './stdTesting';
import anxietyData from './anxiety';
import depressionData from './depression';
import toothDecayData from './toothDecay';
import gumProblemsData from './gumProblems';
import jawProblemsData from './jawProblems';
import routineDentalCareData from './routineDentalCare';
import brokenToothData from './brokenTooth';
import crackedToothData from './crackedTooth';
import chippedToothData from './chippedTooth';
import cosmeticDentistryData from './cosmeticDentistry';
import dentalAlignmentData from './dentalAlignment';
import acneDermatologyData from './acneDermatology';
import rashData from './rash';
import skinOrNailInfectionData from './skinOrNailInfection';
import growthData from './growth';
import cancerData from './cancerDermatology';
import cosmeticData from './cosmeticDermatology';
import obgyn from '../specialist/obgyn'
const {painList} = painData;

const fileList = [];
painList.forEach(painObj => {
  if (painObj.fileName) {
    fileList.push(painObj.fileName);
  }
});
fileList.push('pcpSymptoms');
let allSymptomQuestionList = [];
let symptomConfig = {};

const fileData = [];
fileData['lowBackPain'] = lowBackPainData;
fileData['midBackPain'] = midBackPainData;
fileData['hipPain'] = hipPainData;
fileData['neckPain'] = neckPainData;
fileData['pinchedNerve'] = pinchedNerveData;
fileData['sciatica'] = sciaticaData;
fileData['shoulderPain'] = shoulderPainData;
fileData['footPain'] = footPainData;
fileData['elbowPain'] = elbowPainData;
fileData['handPain'] = handPainData;
fileData['kneePain'] = kneePainData;
fileData['headache'] = headacheData;
fileData['pcpSymptoms'] = pcpSymptomsData;
fileData['insomnia'] = insomniaData;
fileData['erectileDysfunction'] = erectileDysfunctionData;
fileData['birthControl'] = birthControlData;
fileData['hairLoss'] = hairLossData;
fileData['acne'] = acneData;
fileData['prematureEjaculation'] = prematureEjaculationData;
fileData['urinaryTractInfection'] = urinaryTractInfectionData;
fileData['coldSore'] = coldSoreData;
fileData['genitalHerpes'] = genitalHerpesData;
fileData['asthma'] = asthmaData;
fileData['acidReflux'] = acidRefluxData;
fileData['hotFlashes'] = hotFlashesData;
fileData['darkSpots'] = darkSpotsData;
fileData['hypothyroidism'] = hypothyroidismData;
fileData['stopSmoking'] = stopSmokingData;
fileData['highBloodPressure'] = highBloodPressureData;
fileData['cholesterol'] = cholesterolData;
fileData['stdTesting'] = stdTestingData;
fileData['anxiety'] = anxietyData;
fileData['depression'] = depressionData;
fileData['toothDecay'] = toothDecayData;
fileData['gumProblems'] = gumProblemsData;
fileData['jawProblems'] = jawProblemsData;
fileData['routineDentalCare'] = routineDentalCareData;
fileData['brokenTooth'] = brokenToothData;
fileData['crackedTooth'] = crackedToothData;
fileData['chippedTooth'] = chippedToothData;
fileData['cosmeticDentistry'] = cosmeticDentistryData;
fileData['dentalAlignment'] = dentalAlignmentData;
fileData['acneDermatology'] = acneDermatologyData;
fileData['rash'] = rashData;
fileData['skinOrNailInfection'] = skinOrNailInfectionData;
fileData['growth'] = growthData;
fileData['cancerDermatology'] = cancerData;
fileData['cosmeticDermatology'] = cosmeticData;
fileData['obgyn']=obgyn
fileList.map(async fileName => {
  const {questionList, config: painConfig} = fileData[fileName];
  allSymptomQuestionList = [...allSymptomQuestionList, ...questionList];
  symptomConfig = {...symptomConfig, [fileName]: painConfig};
});

export default {
  allSymptomQuestionList,
  symptomConfig,
};
