import images from 'data/images';
import rolePermission from './rolePermission';
import Dashboard from 'containers/AfterAuth/Dashboard';
import Settings from 'containers/AfterAuth/Settings';
import Reviews from 'containers/AfterAuth/Reviews';
import Schedule from 'containers/AfterAuth/Schedule';
import ReviewAccounts from 'containers/AfterAuth/ReviewAccounts';
import RequestReviews from 'containers/AfterAuth/RequestReviews';
import ShareReviews from 'containers/AfterAuth/ShareReviews';
import PatientDashboard from 'containers/Patient/AfterAuth/PatientDashboard';
import PatientProfile from 'containers/Patient/PatientProfile';
import AdminProviders from 'containers/Admin/Providers';
import AdminPatients from 'containers/Admin/Patients';
import AdminJoinUs from 'containers/Admin/JoinUs';
import ImportProvider from 'containers/Admin/ImportProvider';
import Configuration from 'containers/Admin/Configuration';
import Languages from 'containers/Admin/Languages';
import Certification from 'containers/Admin/Certification/Certification';
import Suffix from 'containers/Admin/Suffix';
import InsuranceCompany from 'containers/Admin/InsuranceCompany';
import InsurancePlans from 'containers/Admin/InsurancePlans';
import GhostProfiles from 'containers/Admin/GhostProfiles';
import AdminDashboard from 'containers/Admin/AdminDashboard';
import ReviewRequest from 'containers/Admin/ReviewRequest';
import UpdateRequest from 'containers/Admin/UpdateRequest';
import Practices from 'containers/Admin/Practices';
import ManageFaq from 'containers/Admin/ManageFaq';
import Staff from 'containers/Admin/staff';
import UploadFiles from "containers/Admin/UploadFiles"
import Loginlog from 'containers/Admin/Loginlog';
import ActiveProviders from 'containers/Admin/Providers/ActiveProviders';
import Inactiveproviders from "containers/Admin/Providers/Inactiveproviders"
import Subscriptions from "containers/Admin/Subscriptions"
import UtilizationMetrics,{PracticeProfile,providerVisualizations} from 'containers/Admin/UtilizationMetrics';
import Feedback from "containers/AfterAuth/Feedback"
import Reviewsources from 'containers/Admin/Reviewsources';
import Consultrequest from 'containers/AfterAuth/Consultrequest';
const {permissionList} = rolePermission;

const authDashboardContent = {
  siderbarMenuItems: [
    {
      id: 1,
      image: images.dashboardIcon,
      name: 'Dashboard',
      link: '/',
      permissions: [permissionList.BACKEND_USER],
      BaseComponent: Dashboard,
    },

    {
      id: 2,
      image: images.scheduleIcon,
      name: 'Schedule',
      link: '/schedule',
      permissions: [permissionList.BACKEND_USER],
      BaseComponent: Schedule,
    },
    {
      id: 2.5,
      image: images.likeFill,
      name: 'Feedback',
      link: '/feedback',
      permissions: [permissionList.BACKEND_USER],
      BaseComponent: Feedback,
      hasCount:true,
      countKey:'feedbackPendingCount'
    },
    {
      id: 3,
      image: images.starIcon,
      name: 'Reviews',
      link: '/reviews',
      permissions: [permissionList.BACKEND_USER],
      BaseComponent: Reviews,
      subMenuItems: [
        {
          id: 3.1,
          name: 'Connect Reviews',
          link: '/reviews/account',
          permissions: [permissionList.BACKEND_USER],
          BaseComponent: ReviewAccounts,
        },
        {
          id: 3.2,
          name: 'Review Widgets',
          link: '/reviews/Sharereview',
          permissions: [permissionList.BACKEND_USER],
          BaseComponent: ShareReviews,
        },
        {
          id: 3.3,
          name: 'Request Reviews',
          link: '/reviews/requestreviews',
          permissions: [permissionList.BACKEND_USER],
          BaseComponent: RequestReviews,
        },
      ],
    },
    {
      id: 4,
      image: images.settingIcon,
      name: 'Settings',
      link: '/settings',
      permissions: [permissionList.BACKEND_USER],
      BaseComponent: Settings,
    },
    {
      id: 5,
      image: images.phone,
      name: 'Consult Request',
      link: '/consultrequest',
      permissions: [permissionList.BACKEND_USER],
      BaseComponent: Consultrequest,
    },
    // {
    //   id: 5,
    //   image: images.dashboardIcon,
    //   name: 'Admin',
    //   link: '/admin',
    //   permissions: [permissionList.ADMIN],
    //   BaseComponent: JoinUsListing,
    // },
    // Patient menu
    {
      id: 6,
      image: images.dashboardIcon,
      name: 'Patient Dashboard',
      link: '/',
      permissions: [permissionList.FRONTEND_USER],
      BaseComponent: PatientDashboard,
    },
    {
      id: 7,
      image: images.dashboardIcon,
      name: 'Patient Setting',
      link: '/settings',
      permissions: [permissionList.FRONTEND_USER],
      BaseComponent: PatientProfile,
    },
    {
      id: 8,
      image: images.dashboardIcon,
      name: 'Dashboard',
      link: '/admin/dashboard',
      permissions: [permissionList.ADMIN],
      BaseComponent: AdminDashboard,
    },
    {
      id: 9.001,
      image: images.dashboardIcon,
      name: 'Approvals',
      link: '/admin/join-us',
      permissions: [permissionList.ADMIN],
      BaseComponent: null,
      subMenuItems: [
         {
          id: 9.1,
          name: 'Pending Providers',
          link: '/admin/join-us',
          permissions: [permissionList.ADMIN],
          BaseComponent: AdminJoinUs,
          hasCount: true,
          countKey: 'joinUsCountPending',
          provideStatus:0
        },  
        {
          id: 9.2,
          name: 'Profile Updates',
          link: '/admin/update-request',
          permissions: [permissionList.ADMIN],
          BaseComponent: UpdateRequest,
          hasCount: true,
          countKey: 'updateRequest',
        },
        {
          id: 9.3,
          name: 'Flagged Reviews',
          link: '/admin/review-request',
          permissions: [permissionList.ADMIN],
          BaseComponent: ReviewRequest,
          hasCount: true,
          countKey: 'reviewCount',
        }
      ]
    },
    {
      id: 15,
      image: images.dashboardIcon,
      name: 'Practices',
      link: '/admin/practices',
      permissions: [permissionList.ADMIN],
      BaseComponent: Practices,
    },
    {
      id: 9,
      image: images.dashboardIcon,
      name: 'Active Providers',
      link: '/admin/providers',
      permissions: [permissionList.ADMIN],
      BaseComponent: ActiveProviders,
      subMenuItems: [
        {
          id: 9.03,
          name: 'Waitlist Providers',
          link: '/admin/join-us/waiting',
          permissions: [permissionList.ADMIN],
          BaseComponent: AdminJoinUs,
          hasCount: true,
          countKey: 'joinUsCountWaiting',
          provideStatus:3
        },
        {
          id: 9.031,
          name: 'Inactive Providers',
          link: '/admin/join-us/inactive-providers',
          permissions: [permissionList.ADMIN],
          BaseComponent: AdminProviders,
          hasCount: true,
          countKey: 'providerInactive',
          provideStatus:3
        },
             
       
        // {
        //   id: 9.01,
        //   name: 'Approved Providers',
        //   link: '/admin/join-us/approved',
        //   permissions: [permissionList.ADMIN],
        //   BaseComponent: AdminJoinUs,
        //   hasCount: true,
        //   countKey: 'joinUsCountApproved',
        //   provideStatus:1
        // },
        {
          id: 9.02,
          name: 'Rejected Providers',
          link: '/admin/join-us/rejected',
          permissions: [permissionList.ADMIN],
          BaseComponent: AdminJoinUs,
          hasCount: true,
          countKey: 'joinUsCountRejected',
          provideStatus:2
        },       
        {
          id: 9.032,
          name: 'Approved Providers',
          link: '/admin/join-us/approved-providers',
          permissions: [permissionList.ADMIN],
          BaseComponent: AdminJoinUs,
          hasCount: true,
          countKey: 'pendingProfile',
          provideStatus:'pendingProfile'
        },
        {
          id: 9.033,
          name: 'Invited Providers',
          link: '/admin/join-us/invited-providers',
          permissions: [permissionList.ADMIN],
          BaseComponent: Inactiveproviders,
          hasCount: true,
          countKey: 'providerInvited',
         
        },
        {
          id: 9.034,
          image: images.dashboardIcon,
          name: 'Review Source',
          link: '/admin/reviewsource',
          permissions: [permissionList.ADMIN],
          BaseComponent: Reviewsources,
          hasCount:true,
          countKey:'reviewSourcesCount'
        },
      ],
    },
    {
      id: 10,
      image: images.dashboardIcon,
      name: 'Ghost Providers',
      link: '/admin/ghost-profiles',
      permissions: [permissionList.ADMIN],
      BaseComponent: GhostProfiles,
    },

    {
      id: 17,
      image: images.dashboardIcon,
      name: 'Subscriptions',
      link: '/admin/subscriptions',
      permissions: [permissionList.ADMIN],
      BaseComponent: Subscriptions,
    },
    
    {
      id: 10.1,
      image: images.dashboardIcon,
      name: 'Staff',
      link: '/admin/staff',
      permissions: [permissionList.ADMIN],
      BaseComponent: Staff,
    },
    {
      id: 10.2,
      image: images.dashboardIcon,
      name: 'Upload Files',
      link: '/admin/upload-files',
      permissions: [permissionList.ADMIN],
      BaseComponent: UploadFiles,
    },
    {
      id: 11,
      image: images.dashboardIcon,
      name: 'Patients',
      link: '/admin/patient',
      permissions: [permissionList.ADMIN],
      BaseComponent: AdminPatients,
    },
    {
      id: 13,
      image: images.dashboardIcon,
      name: 'Master Data',
      link: '/admin/languages',
      permissions: [permissionList.ADMIN],
      BaseComponent: Languages,
      subMenuItems: [
        {
          id: 13.1,
          name: 'Languages',
          link: '/admin/languages',
          permissions: [permissionList.ADMIN],
          BaseComponent: Languages,
        },
        {
          id: 13.2,
          name: 'Certification',
          link: '/admin/certification',
          permissions: [permissionList.ADMIN],
          BaseComponent: Certification,
        },
        {
          id: 13.3,
          name: 'Suffix',
          link: '/admin/suffix',
          permissions: [permissionList.ADMIN],
          BaseComponent: Suffix,
        },
        {
          id: 13.4,
          name: 'Insurance Company',
          link: '/admin/insurance-company',
          permissions: [permissionList.ADMIN],
          BaseComponent: InsuranceCompany,
        },
        {
          id: 13.5,
          name: 'Insurance Plan',
          link: '/admin/insurance-plan',
          permissions: [permissionList.ADMIN],
          BaseComponent: InsurancePlans,
        },
        {
          id: 13.6,
          name: 'Manage FAQ',
          link: '/admin/managefaq',
          permissions: [permissionList.ADMIN],
          BaseComponent: ManageFaq,
        },
      ],
    },
    {
      id: 14,
      image: images.dashboardIcon,
      name: 'Configurations',
      link: '/admin/configuration',
      permissions: [permissionList.ADMIN],
      BaseComponent: Configuration,
    },
    {
      id: 16,
      image: images.dashboardIcon,
      name: 'Login Log',
      link: '/admin/login-log',
      permissions: [permissionList.ADMIN],
      BaseComponent: Loginlog,
    },
    {
      id: 18,
      image: images.dashboardIcon,
      name: 'Utilization Metrics',
      link: '/admin/utilization-metric',
      permissions: [permissionList.ADMIN],
      BaseComponent: UtilizationMetrics,
      subMenuItems: [
        {
          id: 18.1,
          name: 'Provider Access Log ',
          link: '/admin/provider-access-log',
          permissions: [permissionList.ADMIN],
          BaseComponent: UtilizationMetrics,
        },
        // {
        //   id: 18.11,
        //   name: 'Provider Access Log Visualizations',
        //   link: '/admin/provider-access-log-visualizations',
        //   permissions: [permissionList.ADMIN],
        //   BaseComponent: providerVisualizations,
        // },
        {
          id: 18.2,
          name: 'Practice Access Log',
          link: '/admin/practice-access-log',
          permissions: [permissionList.ADMIN],
          BaseComponent: PracticeProfile,
        },
        
      ],
    },
  ],
  count: [
    {
      number: '24',
      title: 'Upcoming Appointments',
      parcentText: '0.5',
      image: images.downArrow,
    },
    {
      number: '12',
      title: 'New Patients',
      parcentText: '0.5',
      image: images.downArrow,
    },
    {
      number: '120',
      title: 'Total Bookings',
      parcentText: '1.5',
      image: images.downArrow,
    },
    {
      number: '4.9',
      title: 'Your Score',
      parcentText: '1.5',
      image: images.downArrow,
    },
  ],
  appointmentsList: [
    {
      name: 'Sophie E.',
      datetime: 'Today, Apr 13 at 2:30',
    },
    {
      name: 'Haley C.',
      datetime: 'Today, Apr 13 at 2:30',
    },
    {
      name: 'Russell H.',
      datetime: 'Today, Apr 13 at 2:30',
    },
  ],
  ratingList: [
    {
      id: 1,
      name: 'Knowledge',
      rating: 4.9,
    },
    {
      id: 2,
      name: ' Approachable',
      rating: 4.8,
    },
    {
      id: 3,
      name: 'Bedside Manor',
      rating: 5.0,
    },
  ],
  reviewList: [
    {
      id: 1,
      name: 'Jane Damaceno',
      image: images.googleLogo,
      datetime: 'Wednesday, Apr 11 at 1:20',
      description:
        'Stevenson is great. He seems very genuine and cares about patients well being. The staff seemed a little overwhelmed with appointments when I was there.',
    },
    {
      id: 2,
      name: 'Jane Damaceno',
      image: images.googleLogo,
      datetime: 'Wednesday, Apr 11 at 1:20',
      description:
        'Stevenson is great. He seems very genuine and cares about patients well being. The staff seemed a little overwhelmed with appointments when I was there.',
    },
    {
      id: 3,
      name: 'Jane Damaceno',
      image: images.googleLogo,
      datetime: 'Wednesday, Apr 11 at 1:20',
      description:
        'Stevenson is great. He seems very genuine and cares about patients well being. The staff seemed a little overwhelmed with appointments when I was there.',
    },
  ],
  providerList: [
    {
      id: 1,
      name: 'Jane Stevenson, CAc',
      postion: 'MD',
      email: 'jane@mail.com',
      image: images.team1,
      isProfileCompleted: true,
    },
    {
      id: 2,
      name: 'Roberto Suarez',
      postion: 'MD',
      email: 'roberto@mail.com',
      image: images.team1,
      isProfileCompleted: false,
    },
  ],
  builtDoctorsList: [
    {
      dname: 'Dr. Nancy Lovell, MD',
      content:
        "“I always do some research on the doctors I’m interested in seeing, doesn’t everyone? It’s time consuming as you must go multiple sites. Kaly has all the doctors’ reviews in the same place; so easy and saves so much time!”",
      pnamer: 'Danny D.',
      date: 'Sep 4, 2019',
      classContent: 'BuiltDoctorsitem harmonis',
      img: images.builddoctor1,
    },
    {
      dname: 'Dr. Jay Griese, MD',
      content:
      `“I answered a few questions and picked a physical therapist that was around the block. She has excellent reviews and just the right expertise. My friends told me to see an orthopedic surgeon, which would have been a total waste of time and money. I told them about Kaly and they are using it now.”`,
      pnamer: 'Tao H.',
      date: 'Jan 11, 2020',
      classContent: 'BuiltDoctorsitem pipi',
      img: images.builddoctor2,
    },
    {
      dname: 'Dr. Jane Goodell MD',
      content:
        `“Signing up with Kaly was so easy and took seconds! My partner and I are using it regularly and so far, the doctors have been excellent! It’s so easy to research the doctors, make same day appointments and leave reviews. We regularly book video visits and love the reminders we get before our appointments!”`,
      pnamer: 'Johnny T. ',
      date: 'Feb 21, 2020',
      classContent: 'BuiltDoctorsitem Onahau',
      img: images.builddoctor3,
    },
    {
      dname: 'Dr. Alex Demarco  MD',
      content:
        `“My colleague at work told me about Kaly and how easy it was for him to find the right doctor from the start. He was right! I found a doctor with the expertise I needed right away. I’m so happy I found Kaly and I’m telling all my friends.”`,
      pnamer: 'Stacy M.',
      date: 'Oct 11, 2019',
      classContent: 'BuiltDoctorsitem Sinbad',
      img: images.builddoctor4,
    },
  ],
};

export default authDashboardContent;
