import React, {useState, useEffect, useCallback} from 'react';
import InputMask from 'react-input-mask';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import CircleButton from 'components/CircleButton';
import InputList from 'components/FormControls/InputList';
import selectOptions from 'data/selectOptions';
import validators from 'data/validators';
import config from 'config/config';
import allRegex from 'data/regex';
import PlaceAutocomplete from 'components/FormControls/PlaceAutocomplete';
import './EditLocation.css';
import analyticsData from 'data/analytics/analyticsData';
import SwitchToggle from 'components/FormControls/SwitchToggle';
const {practiceLocationStatusOptions} = selectOptions;
const {exceptDigitsRegex} = allRegex;
const {__required, __maxLength, __phoneNumber, __email,_scrollToView} = validators;
const {MAX_TEXT_LENGTH} = config;
const {Analytics} = analyticsData;

const EditLocation = props => {
  const {location, open, onSaveLocation, onDeleteLocation, hasPermissionToEdit} = props;
// let tempLocation={...location};
// tempLocation.address={address:tempLocation.address,latitude:tempLocation.latitude,longitude:tempLocation.longitude}
  const isValidateEmail = value => {
    return __email(value);
  };
  const appointmentEmailErrors = useCallback(() => {
    return location.appointmentEmails.map(email => false);
  }, [location.appointmentEmails]);

  const [formData, setFormData] = useState({
    values: location,
    errors: {appointmentEmails: appointmentEmailErrors()},
    isOpen: open || false,
  });
  const {values: formValues, errors: formErrors, isOpen} = formData;

  useEffect(() => {
    
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        values: location,
        errors: {
          appointmentEmails: appointmentEmailErrors(),
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const toggle = () => {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        isOpen: !prevFormData.isOpen,
      };
    });
  };

  // Validation functions
  const __validateList = name => {
    const array = formValues[name];
    let isValid = true;
    array.forEach(email => {
      if (!isValidateEmail(email)) {
        isValid = false;
      }
    });
    return isValid;
  };
  const __validateLocationName = (value = null) => {
    const fValue = value !== null ? value : formValues['locationName'];
    if (!__required(fValue)) {
      return 'Location Name is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateAddress = (value = null) => {
    const fValue =
      value !== null
        ? value
        : {
            address: formValues['address'],
            latitude: formValues['latitude'],
            longitude: formValues['longitude'],
          };
         
    if (!__required(fValue['address'])) {
      return 'Address is required';
    }
    if (!__required(fValue['latitude'])) {
      return 'Address is invalid';
    }
    return null;
  };
  const __validateOfficePhone = (value = null) => {
    const fValue = value !== null ? value : formValues['officePhone'];
    if (!__required(fValue)) {
      return 'Office Phone is required';
    }
    if (!__phoneNumber(fValue)) {
      return 'Invalid office phone';
    }
    return null;
  };
  const __validateExt = (value = null) => {
    const fValue = value !== null ? value : formValues['officePhoneExt'];
    if (!__maxLength(fValue, 5)) {
      return `Max 5 characters`;
    }
    return null;
  };
  const __validateSuite = (value = null) => {
    const fValue = value !== null ? value : formValues['officeSuite'];
    if (!__maxLength(fValue, 50)) {
      return `Max 50 characters`;
    }
    return null;
  };
  const __validateContactPerson = (value = null) => {
    const fValue = value !== null ? value : formValues['contactPersonName'];
    if (!__required(fValue)) {
      return 'Contact Person is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateStatus = (value = null) => {
    const fValue = value !== null ? value : formValues['status'];
    if (fValue === '') {
      return 'Status is required';
    }
    return null;
  };

  const __validateForm = () => {
    const validLocationName = !__validateLocationName();
    const validAddress = !__validateAddress();
    const validOfficePhone = !__validateOfficePhone();
    const validExt = !__validateExt();
    const validSuite = !__validateSuite();
    const validContactPerson = !__validateContactPerson();
    const validAppointmentEmails = __validateList('appointmentEmails');
    const validStatus = !__validateStatus();
    return (
      validLocationName &&
      validAddress &&
      validOfficePhone &&
      validExt &&
      validSuite &&
      validContactPerson &&
      validAppointmentEmails &&
      validStatus
    );
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name} = event.target;
    let {value} = event.target;
    let errorObject = {
      [name]: null,
    };
    let updateValueObj = {};
    switch (name) {
      case 'locationName':
        errorObject = {
          [name]: __validateLocationName(value),
        };
        break;
      case 'address':
        updateValueObj = {
          ...value,
        };
        errorObject = {
          [name]: __validateAddress(value),
        };
        break;
      case 'officePhone':
        value = value.replace(exceptDigitsRegex, '');
        errorObject = {
          [name]: __validateOfficePhone(value),
        };
        break;
      case 'officePhoneExt':
        errorObject = {
          [name]: __validateExt(value),
        };
        break;
      case 'officeSuite':
        errorObject = {
          [name]: __validateSuite(value),
        };
        break;
      case 'contactPersonName':
        errorObject = {
          [name]: __validateContactPerson(value),
        };
        break;
      case 'appointmentEmails':
        errorObject = {
          [name]: event.target.error,
        };
        break;
      case 'status':
        errorObject = {
          [name]: __validateStatus(value),
        };
        break;
      default:
        break;
    }
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
          ...updateValueObj,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const handleSubmit = () => {
    if (!isValidForm) {
      let valError = ['status', 'appointmentEmails', 'contactPersonName','officeSuite','officePhoneExt','officePhone','address','locationName'];
      valError.forEach(name => {
        if(name==='address')
        changeHandler({target: {name: name, value: { address: formValues['address'],
        latitude: formValues['latitude'],
        longitude: formValues['longitude']}}});
        else
        changeHandler({target: {name: name, value: formValues[name]}});
       
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    onSaveLocation(formValues);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
      disabled: !hasPermissionToEdit,
    };
  };

  const locationNameLabel = (
    <>
      <span>Location Name</span>
      {/* <span className="correct-icon">
        <img src={images.correctIcon} alt="correct" />{' '}
      </span> */}
    </>
  );
  const addressLabel = (
    <>
      <span>Address</span>
      {/* <span className="close-icon">
        <img src={images.whiteClose} alt="close" />{' '}
      </span> */}
    </>
  );
  const appointmentEmailLabel = (
    <>
      <span>Email for Appointment Notification</span>
      <span className="inner-label"> (not visible)</span>
    </>
  );
  const contactLabel = (
    <>
      <span>Contact Person</span>
      <span className="inner-label"> (not visible)</span>
    </>
  );
  const appointmentEmailListProps = {
    name: 'appointmentEmails',
    listItems: formValues['appointmentEmails'],
    errors: formErrors['appointmentEmails'],
    onListUpdated: changeHandler,
    validateItem: isValidateEmail,
    label: appointmentEmailLabel,
    addLabelText: 'Add Email',
    removeLabelText: 'Remove',
    maxItems: 10,
    disabled: !hasPermissionToEdit,
  };

  return (
    <div className={`EditLocation ${isOpen ? 'show' : ''}`}>
      <div className="location-header" onClick={() => toggle(!isOpen)}>
        <div className="toggle-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
        {formValues['locationName']}
      </div>
      <div className="location-content">
        <form className="location-form">
          <Input
            {...getGeneralInputProps('locationName')}
            label={locationNameLabel}
            type="text"
            containerClass="location-name"
          />
          <div className="notification-block">
            <SwitchToggle
              name="hasEmailNotification"
              onChange={changeHandler}
              value={formValues['hasEmailNotification']}
              label={`${
                formValues['hasEmailNotification'] ? 'Send email appointment notifications to provider' : 'Do not send email appointment notifications to provider'
              }`}
            />
          </div>
          <InputList {...appointmentEmailListProps} />
          <div className="form-row">
            {isOpen && (
              <PlaceAutocomplete
                name="address"
                value={{
                  address: formValues['address'],
                  latitude: formValues['latitude'],
                  longitude: formValues['longitude'],
                }}
                error= {formErrors['address']}
                disabled={!hasPermissionToEdit}
                onPlaceSelect={changeHandler}
                label={addressLabel}
                containerClass="column-9"
              />
            )}
            <Input
              {...getGeneralInputProps('officeSuite')}
              type="text"
              containerClass="column-3"
              label="Office/Suite #"
            />
          </div>
          <div className="form-row">
            <Input
              mask="(999) 999-9999"
              maskChar=" "
              type="tel"
              InputComponent={InputMask}
              {...getGeneralInputProps('officePhone')}
              containerClass="column-9"
              label="Office Phone"
            />
            <Input
              {...getGeneralInputProps('officePhoneExt')}
              type="text"
              containerClass="column-3"
              label="Ext."
            />
          </div>
          <Input {...getGeneralInputProps('contactPersonName')} type="text" label={contactLabel} />
          <Select
            {...getGeneralInputProps('status')}
            label="Status"
            className="active-dropdown"
            options={practiceLocationStatusOptions}></Select>
          {hasPermissionToEdit && (
            <div className="save-delete-wrapper">
              <CircleButton  onClick={handleSubmit}>
                Save
              </CircleButton>
              <span onClick={() => onDeleteLocation(formValues.id)} className="delete-link">
                Delete this location?
              </span>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default EditLocation;
