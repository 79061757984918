import React, {useRef, useEffect, useState, useMemo} from 'react';
import moment from 'moment';
import _ from 'lodash';
import Input from '../Input';
import allRegex from 'data/regex';
import helper from 'data/helper';
import Overlay from 'components/Overlay';
import momentHelper from 'data/moment/momentHelper';
import './TimeManual.css';

const {isAfter, isBefore} = momentHelper;
const {exceptDigitsRegex, timeHHmmttRegex} = allRegex;
const {getHighlightedText} = helper;

const amPmArray = ['am', 'pm'];

const TimeManual = props => {
  const timeFormat = 'hh:mm a';
  const {onChange, step = 1, minTime = null, maxTime = null, ...otherProps} = props;
  const [isFocused, toggleFocus] = useState(false);
  const timeRef = useRef(null);
  const [val, setVal] = useState(props.value);
  let prevValue = usePrevious(val);

  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  useEffect(() => {
    if (prevValue !== val) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      prevValue = val;
    }
  }, [val]);

  const valueToTimeObject = value => {
    value = value.substr(0, 8);
    const timeArray = value.split(':');
    let hours = _.get(timeArray, '0', '').replace(exceptDigitsRegex, '');
    hours = hours.substr(0, 2);
    const minutesAmPmArray = _.get(timeArray, '1', '').replace(/\s\s+/g, ' ').split(' ');
    let minutes = _.get(minutesAmPmArray, '0', '').replace(exceptDigitsRegex, '');
    minutes = minutes.substr(0, 2);
    let ampm = _.get(minutesAmPmArray, '1', '').substr(0, 2).toLowerCase();
    return {
      hours,
      minutes,
      ampm,
    };
  };

  const onTimeChange = event => {
    const {target} = event;
    const {name} = event.target;
    let {value} = event.target;
    let {hours, minutes, ampm} = valueToTimeObject(value);
    const oldValue = prevValue || '';
    let oldAmpm = oldValue.substr(6, 2).toLowerCase();
    if (hours.length === 0) {
      hours = '  ';
    } else if (hours.length === 1) {
      if (parseInt(hours) > 1) {
        hours = '0' + hours;
      } else {
        hours = hours + ' ';
      }
    } else if (hours.length === 2) {
      if (parseInt(hours) > 12) {
        hours = `12`;
      }
    }
    if (minutes.length === 0) {
      minutes = '  ';
    } else if (minutes.length === 1) {
      if (parseInt(minutes) > 5) {
        minutes = '0' + minutes;
      } else {
        minutes = minutes + ' ';
      }
    } else if (minutes.length === 2) {
      if (parseInt(minutes) > 59) {
        minutes = `59`;
      }
    }
    if (!amPmArray.includes(oldAmpm)) {
      if (ampm === 'a') {
        ampm = 'am';
      } else if (ampm === 'p') {
        ampm = 'pm';
      } else {
        ampm = '';
      }
    } else if (!amPmArray.includes(ampm)) {
      ampm = '';
    }
    if (value === `${hours}:${minutes}`) {
      minutes = minutes.substr(0, 1);
    } else if (value.trim() === `${hours}`) {
      hours = hours.substr(0, 1);
    }
    const newHours = hours.replace(exceptDigitsRegex, '').trim();
    const newMinutes = minutes.replace(exceptDigitsRegex, '').trim();
    value = `${hours}:${minutes} ${ampm}`;
    // set input selection focus
    if (newHours.length === 0) {
      setTimeout(() => {
        target.setSelectionRange(0, 0);
      }, 1);
    } else if (newHours.length === 1) {
      setTimeout(() => {
        target.setSelectionRange(1, 1);
      }, 1);
    } else if (newHours.length === 2 && minutes.trim() === '') {
      setTimeout(() => {
        target.setSelectionRange(3, 3);
      }, 1);
    } else if (newMinutes.length === 1) {
      setTimeout(() => {
        target.setSelectionRange(4, 4);
      }, 1);
    } else if (newMinutes.length === 2 && ampm.trim() === '') {
      setTimeout(() => {
        target.setSelectionRange(6, 6);
      }, 1);
    }
    if (val !== value) {
      setVal(value);
      if (moment(value, timeFormat, true).isValid()) {
        onChange({
          target: {
            name,
            value,
          },
        });
      }
    }
  };

  const onSelect = option => {
    onChange({
      target: {
        name: otherProps.name,
        value: option,
      },
    });
    toggleFocus(false);
  };

  const onTimeBlur = event => {
    if (!timeHHmmttRegex.test(val)) setVal(props.value);
  };

  let {hours, minutes, ampm} = valueToTimeObject(val);

  let minTimeMoment = null;
  if (minTime) {
    minTimeMoment = moment(minTime, timeFormat, true);
  }
  let maxTimeMoment = null;
  if (maxTime) {
    maxTimeMoment = moment(maxTime, timeFormat, true);
  }

  let timeList = useMemo(() => {
    const amArray = [];
    const am12Array = [];
    const pmArray = [];
    const pm12Array = [];
    amPmArray.forEach(ap => {
      for (let i = 1; i <= 12; i++) {
        for (let j = 0; j <= 59; j++) {
          if (j % step === 0) {
            const timeValString = `${_.padStart(i, 2, '0')}:${_.padStart(j, 2, '0')} ${ap}`;
            let isValidMinTime = false;
            if (minTimeMoment) {
              const currentOpeionTimeMoment = moment(timeValString, timeFormat, true);
              if (
                minTimeMoment.isValid() &&
                currentOpeionTimeMoment.isValid() &&
                isAfter(currentOpeionTimeMoment, minTimeMoment)
              ) {
                isValidMinTime = true;
              }
            } else {
              isValidMinTime = true;
            }

            let isValidMaxTime = false;
            if (maxTimeMoment) {
              const currentOpeionTimeMoment = moment(timeValString, timeFormat, true);
              if (
                maxTimeMoment.isValid() &&
                currentOpeionTimeMoment.isValid() &&
                isBefore(currentOpeionTimeMoment, maxTimeMoment)
              ) {
                isValidMaxTime = true;
              }
            } else {
              isValidMaxTime = true;
            }
            if (isValidMinTime && isValidMaxTime) {
              if (ap === 'am') {
                if (i === 12) {
                  am12Array.push(timeValString);
                } else {
                  amArray.push(timeValString);
                }
              } else if (ap === 'pm') {
                if (i === 12) {
                  pm12Array.push(timeValString);
                } else {
                  pmArray.push(timeValString);
                }
              }
            }
          }
        }
      }
    });
    return [...am12Array, ...amArray, ...pm12Array, ...pmArray];
  }, [minTimeMoment, maxTimeMoment, step]);

  let selector = '';
  if (hours.trim().length > 0) {
    selector += hours;
  }
  if (minutes.trim().length > 0) {
    selector += ':' + minutes;
  }
  if (ampm.trim().length > 0) {
    selector += ' ' + ampm;
  }
  // scroll to time
  setTimeout(() => {
    if (timeRef && timeRef.current) {
      const timeDiv = timeRef.current.querySelector('[id^="' + selector + '"]');
      if (timeDiv) {
        timeDiv.scrollIntoView({behavior: 'auto', block: 'start'});
      }
    }
  }, 100);

  return (
    <div className="TimeManual" ref={timeRef}>
      <Input
        {...otherProps}
        value={val}
        onFocus={() => {
          if (!isFocused) {
            toggleFocus(true);
          }
        }}
        onChange={onTimeChange}
        onBlur={onTimeBlur}
      />
      <Overlay show={isFocused} onClick={() => toggleFocus(false)} />
      {isFocused && (
        <div className="result">
          {timeList.map(option => {
            return (
              <div id={option} className="option" onClick={() => onSelect(option)} key={option}>
                {getHighlightedText(option, selector)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TimeManual;

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
