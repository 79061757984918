import React, {Component} from 'react';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import images from 'data/images';
import _ from 'lodash';
import constants from 'data/constants';
import './NPIRequests.css';


const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  searchText: '',
  pageLimit: 10,
  sortBy: 'createdAt',
  orderBy: 'desc',
  searchType: '',
  totalRecord: 0,
};
const {modalFunctions} = modalConfig;
const {errorModalData, confirmModalData} = modalFunctions;
const {getProviderChangeRequest, rejectProviderChangeRequest} = adminService;
const {OtherChangeTypes, DefaultDebounceMSec} = constants;

class NPIRequests extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount() {
    this.setInformationRequest(false);
    const {updateContextRequestCountData} = this.props;
    updateContextRequestCountData();
  }

  setInformationRequest = async (noLoad = false) => {
    const {activePage, pageLimit, searchText, sortBy, orderBy, searchType} = this.state;
    const res = await getProviderChangeRequest(
      'npinumber',
      {
        page: activePage,
        limit: pageLimit,
        search: searchText,
        sortBy: sortBy,
        orderBy: orderBy,
        type: searchType,
      },
      noLoad,
    ).catch(this.handleError);
    this.setState({
      list: _.get(res, 'data.list', []),
      totalPage: _.get(res, 'data.totalPage', 0),
      totalRecord: _.get(res, 'data.totalRecord', 0),
    });
  };

  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.setInformationRequest);
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };

  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.setInformationRequest();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };

  handleSearch = () => {
    this.setState({activePage: 1}, this.setInformationRequest);
  };

  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.setInformationRequest);
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'firstName');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.setInformationRequest,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.setInformationRequest);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  handleActionRequest = (providerId, infoId, isApprove) => {
    const {setGlobal} = this.context;
    const msg = isApprove===1 ? 'approve' : 'reject';
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to ${msg} this npi number change request?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmRejectRequest(providerId, infoId, isApprove);
          }
        },
      }),
    );
  };

  confirmRejectRequest = async (id, infoId, isApprove) => {
    const data = {
      providerId: id,
      updateId: infoId,
      status: isApprove,
    };
    const response = await rejectProviderChangeRequest('npinumber', data).catch(this.handleError);
    if (response) {
      this.setInformationRequest();
      const {updateContextRequestCountData} = this.props;
      updateContextRequestCountData();
    }
  };

  handleTypeSearch = event => {
    this.setState({searchType: event.target.value, activePage: 1}, this.setInformationRequest);
  };

  render() {
    const {list, totalPage, searchText, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    const requestListComponent = _.map(list, (item, index) => (
      <div className="row" key={index}>
        <div className="cell">
          {item.provider && item.provider.seoPageUrl && (
            <span
              className="link-span"
              onClick={() => window.open(item.provider.seoPageUrl, '_blank')}>
              {`${_.get(item.provider, 'firstName', '')} ${_.get(item.provider, 'lastName', '')}`}
            </span>
          )}
          {item.provider &&
            !item.provider.seoPageUrl &&
            `${_.get(item.provider, 'firstName', '')} ${_.get(item.provider, 'lastName', '')}`}
        </div>
        <div className="cell">{_.get(item.provider, 'email', '')}</div>
        <div className="cell">{_.get(item, 'npiNumber', '')}</div>
        <div className="cell">{item.createdAt && this.formatedDate(item.createdAt)}</div>
        <div className="cell edit-cell">
          <button
            className="btn-approve"
            onClick={() => this.handleActionRequest(item.provider.id, item.id, 1)}>
            Approve
          </button>
          <button
            className="btn-reject"
            onClick={() => this.handleActionRequest(item.provider.id, item.id, 2)}>
            Reject
          </button>
        </div>
      </div>
    ));
    const noRecordComponent = <div className="no-record">No Records found</div>;
    return (
      <div className="OtherInfoRequest">
        <div className="dashboard-panle">
          <div className="search">
           
            <div>
              <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
              {searchText && (
                <button className="btn-clear" onClick={this.handleClearSearch}>
                  <img src={images.close} alt="clear" />
                </button>
              )}
            </div>
            <button onClick={this.handleSearch}>Search</button>
          </div>
          <div className="dataCount">
            <span>{totalRecord} other requests found.</span>
          </div>
          <div className="table-overflow">
            <div className="rtable">
              <div className="heading">
                <div
                  className="cell pointer"
                  data-key="firstName"
                  onClick={this.handleSortingChange}>
                  Provider Name{this.renderShortIcon('firstName')}
                </div>
                <div className="cell pointer" data-key="email" onClick={this.handleSortingChange}>
                  Provider Email{this.renderShortIcon('email')}
                </div>
                <div
                  className="cell pointer"
                  data-key="npiNumber"
                  onClick={this.handleSortingChange}>
                  NPI Number{this.renderShortIcon('npiNumber')}
                </div>
               
                <div
                  className="cell pointer"
                  data-key="createdAt"
                  onClick={this.handleSortingChange}>
                  Request Date{this.renderShortIcon('createdAt')}
                </div>
                <div className="cell edit-cell">Action</div>
              </div>
              {list.length > 0 ? requestListComponent : noRecordComponent}
            </div>
          </div>
          {totalPage > 0 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={activeIndex}
            />
          )}
        </div>
      </div>
    );
  }
}

export default NPIRequests;
