import React, {useEffect, useState, useContext} from 'react';
import images from 'data/images';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import helper from 'data/helper';
import patientService from 'services/patient';
import allRegex from 'data/regex';
import './VerifyPhoneNumberModal.css';
import AuthContext from 'context/AuthContext';
import _ from 'lodash';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import {toast, ToastContainer} from 'react-toastify';

const {formatPhoneNumber} = helper;
const {sendVerificationCode, verifyPhoneNumber} = patientService;
const {exceptDigitsRegex} = allRegex;
const {Analytics} = analyticsData;

const VerifyPhoneNumberModal = props => {
  const authContext = useContext(AuthContext);
  const siteSettings = _.get(authContext, 'siteSettings');
  const {closeModal} = props;
  const {data, afterVerify} = props;
  const {phoneNumber} = data;
  const [verificationData, setverificationData] = useState({phoneNumber, code: '', error: null});

  useEffect(() => {
    sendCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = event => {
    const {name} = event.target;
    let {value} = event.target;
    value = value.replace(exceptDigitsRegex, '');
    setverificationData(prevState => {
      return {
        ...prevState,
        [name]: value,
        error: null,
      };
    });
  };

  const sendCode = async () => {
    await sendVerificationCode({phoneNumber});
  };
  const resentCode = async () => {
    await sendVerificationCode({phoneNumber})
      .then(res => {
        toast.success(res.message);
      })
      .catch(er => {
        toast.error(er.message);
      });
  };
  const handleVerifyPhoneNumber = async () => {
    Nux.sendEvent(
      Analytics.Category.PatientAppointment,
      Analytics.Actions.Click,
      'verifyPhoneNumber',
    );
    const response = await verifyPhoneNumber(verificationData).catch(error => {
      const {message} = error;
      setverificationData(prevState => {
        return {
          ...prevState,
          error: message,
        };
      });
      return null;
    });
    if (response) {
      afterVerify();
      closeModal();
    }
  };

  return (
    <div className="VerifyPhoneNumberModal">
      <div className="modal-content-header">
        <div className="h3">Verify Phone Number</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <div className="text-info">
          Please enter 6-digit pin sent to <span>{formatPhoneNumber(phoneNumber)}</span>
        </div>
        <Input
          label="Enter PIN"
          name="code"
          error={verificationData.error}
          value={verificationData.code}
          onChange={handleChange}
        />
        <span className="resendcode-text">
          Didn’t receive? <span onClick={resentCode}>Resend Now</span>
        </span>
      </div>
      <div className="modal-content-footer">
        <CircleButton
          disabled={verificationData.code.length !== 6}
          onClick={handleVerifyPhoneNumber}>
          Continue
        </CircleButton>
        <div className="contact-us-text">
          If you are still having trouble,{' '}
          <a href={`mailto:${_.get(siteSettings, 'contactUsLinks', '')}`}>Contact us.</a>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default VerifyPhoneNumberModal;
