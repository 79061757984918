import React, { useEffect } from 'react';
import './Loader.css';

const LoaderOnly = (props) => {
  let { delay, callonCallBackLoader } = props
  const closeModal = () => {

    callonCallBackLoader()
  };
  useEffect(() => {
    if(delay)
    setTimeout(() => {
      closeModal()

    }, delay);
  }, [delay])
  return <div className="Loader">Loader</div>;
};

export default LoaderOnly;
