import images from 'data/images';
import React from 'react';
import './ApprovalCard.css';

const ApprovalCard = props => {
  return (
    <div className="ApprovalCard">
      <div className="heading3">Review Account Approval </div>
      <span className="sub-text">Kaly will review and approve updated items.</span>
      <div className="approval-action">
        <span className="Pending">
          <img src={images.minus} alt="" /> Pending
        </span>
        <span className="Approved">
          <img src={images.check} alt="" />
          Approved
        </span>
        <span className="Fetching">
          <img src={images.yellowCircle} alt="" />
          Fetching
        </span>
        <span className="Live">
          <img src={images.checkGreen} alt="" />
          Live
        </span>
        <span className="Rejected">
          <img src={images.closeError} alt="" />
          Rejected
        </span>
      </div>
    </div>
  );
};

export default ApprovalCard;
