import React, {useContext} from 'react';
import moment from 'moment';
import _ from 'lodash';
import images from 'data/images';
import Select from 'components/FormControls/Select';
import selectOptions from 'data/selectOptions';
import momentHelper from 'data/moment/momentHelper';
import helper from 'data/helper';
import MyLink from 'components/MyLink';
import scheduleService from 'services/schedule';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import ViewPatientOverlayModal from 'components/AfterAuth/Dashboard/ViewPatientOverlayModal';
import './AppointmentDetail.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import {Link} from 'react-router-dom';
import config from 'config/config';
import {getHideQuestionID} from 'data/questionnaires/questionnaire.helper';
import TimeZoneLabelShort from 'components/MyDatetime/TimeZoneLabelShort';
import ProviderReschedule from './ProviderReschedule/ProviderReschedule';

const {genderOptions, patientGenderOptions, insuranceDefaultOption} = selectOptions;
const HideQuestionID = getHideQuestionID();
const {getSuffixString, formatPhoneNumber, getAppointmentClassFromStatus, getPatientUserAddress} =
  helper;
const {requestInsuranceCard, requestPatientToCall, changeAppointmentStatus, reschedulebyprovider} =
  scheduleService;
const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;
const {successModalData, errorModalData, warningModalData} = modalFunctions;
const {utcToLocalMoment, timeZoneAbbreviated} = momentHelper;
const {Analytics} = analyticsData;

const AppointmentDetail = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const appointmentStatusOptions = [
    {value: 1, label: 'Confirm Appointment'},
    {value: 2, label: 'Cancel'},
    {value: 3, label: 'Reschedule Appointment'},
  ];
  const appointmentStatusOptionsOnlyCancel = [{value: 2, label: 'Cancel'}];
  const {
    appointmentDetail,
    refetchAppointmentEvents,
    refetchAppointmentEventsDashBoard,
    hidePatientLink = false,
  } = props;
  const {
    id: appointmentId,
    appointmentStartDateTime,
    appointmentEndDateTime,
    isExistingPatient,
    phoneNumber,
    insuranceMemberId,
    insuranceCardFrontPhotoUrl,
    insuranceCardBackPhotoUrl,
    note,
    status,
    insuranceCompany,
    insurancePlan,
    callRequestAt,
    updatedAt,
    isVirtual,
  } = appointmentDetail;
  if (status === 1) {
    appointmentStatusOptions[0].label = 'Confirmed';
    appointmentStatusOptions.push({value: 4, label: 'No Show'});
  }
  //console.log("appointmentStartDateTime",appointmentStartDateTime);
  const appointmentStartDateTimeObject = utcToLocalMoment(appointmentStartDateTime); // utcToLocalMoment(appointmentStartDateTime);
  const appointmentEndDateTimeObject = utcToLocalMoment(appointmentEndDateTime); // utcToLocalMoment(appointmentEndDateTime);
  const answers = _.get(appointmentDetail, 'flowResponse', []);
  const cancelledBy = _.get(appointmentDetail, 'cancelledBy', '');
  const cancelledAt = _.get(appointmentDetail, 'cancelledAt', null);
  const patient = _.get(appointmentDetail, 'patient', {});
  const patientUserId = _.get(patient, 'user.id', 0);
  const genderValueId = _.get(appointmentDetail, 'gender', 1);
  let dateOfBirth = _.get(patient, 'dateOfBirth', null);
  let dateOfBirthString = '';
  if (dateOfBirth) {
    dateOfBirthString =
      moment(dateOfBirth).format('MM/DD/YYYY') +
      ' (' +
      moment().diff(dateOfBirth, 'years') +
      ' years old) ';
  }
  const isPassedAppointment = appointmentStartDateTimeObject.isBefore(moment());
  const gender = _.find(patientGenderOptions, {value: genderValueId});

  // const provider = _.get(appointmentDetail, 'providerAvailability.provider', {});
  const providerUser = _.get(props, 'viewUser', {});
  const provider = _.get(providerUser, 'provider', {});
  const providerLocation = _.get(appointmentDetail, 'providerAvailability.practiceLocation', {});
  const providerSuffixes = _.get(provider, 'suffixes', []);
  const formatedPhoneNumber = formatPhoneNumber(phoneNumber);

  const handleError = (error, showMsg = false) => {
    const {message} = error;
    if (!hidePatientLink || showMsg) {
      setGlobal('modal', errorModalData(message));
    }
    return null;
  };

  const handleRequestInsuranceCard = async () => {
    Nux.sendEvent(Analytics.Category.DoctorDashboard, Analytics.Actions.Click, 'requestInsurance');
    const response = await requestInsuranceCard(appointmentId).catch(handleError);
    if (response) {
      const {message} = response;
      if (!hidePatientLink) {
        setGlobal('modal', successModalData(message));
      }
    }
  };

  const handleRequestPatientToCall = async () => {
    Nux.sendEvent(Analytics.Category.DoctorDashboard, Analytics.Actions.Click, 'requestCall');
    const response = await requestPatientToCall(appointmentId).catch(handleError);
    if (response) {
      const {message} = response;
      if (!hidePatientLink) {
        setGlobal(
          'modal',
          successModalData(message, {
            callBackOnClose: () => {
              refetchAppointmentEvents(appointmentId);
            },
          }),
        );
      } else {
        refetchAppointmentEvents(appointmentId);
      }
    }
  };

  const openViewPatientOverlayModal = patientUserId => {
    const {setGlobal} = rootContext;
    if (!hidePatientLink) {
      setGlobal(
        'modal',
        blankModalData({
          CustomComponent: ViewPatientOverlayModal,
          modalWrapperClass: 'mega-modal',
          customComponentProps: {
            data: {
              patientUserId,
            },
            refetchAppointmentEventsDashBoard: refetchAppointmentEvents,
          },
          callBackOnClose: () => {
            refetchAppointmentEvents(appointmentId, 'modal');
          },
        }),
      );
    }
  };

  const showPatientNote = () => {
    setGlobal('modal', warningModalData(note, {}, 'Note'));
  };

  const isValidAppointmentTime = () => {
    let startDiff = moment().diff(appointmentStartDateTimeObject, 'minutes');
    let endDiff = moment().diff(appointmentEndDateTimeObject, 'minutes');
    if (startDiff > -5 && endDiff <= 0) {
      return true;
    }
    return false;
  };

  const handleAppointmentStatusChange = async event => {
    Nux.sendEvent(Analytics.Category.DoctorDashboard, Analytics.Actions.Click, 'appointmentAction');
    const {value} = event.target;
    const data = {
      appointmentId,
      status: parseInt(value),
      reason: '',
    };
    // if status 2 (cancel appointment) then add reaason.
    if (data.status === 2) {
      data.reason = 'We could not reach patient for information';
    }
    let response = null;
    if (data.status === 3) {
      let newDateDetails = await ProviderReschedule({
        context: rootContext,
        appointmentDetail: appointmentDetail,
      });

      response = await reschedulebyprovider(newDateDetails).catch(handleError);
    } else
      response = await changeAppointmentStatus(data).catch(e => {
        handleError(e, true);
      });
    if (response) {
      const {message} = response;
      if (!hidePatientLink || data.status === 3) {
        setGlobal(
          'modal',
          successModalData(message, {
            callBackOnClose: () => {
              if (refetchAppointmentEventsDashBoard) {
                refetchAppointmentEventsDashBoard(appointmentId);
              } else refetchAppointmentEvents(appointmentId);
            },
          }),
        );
      } else {
        refetchAppointmentEvents(appointmentId);
      }
    }
  };

  const appointmentStatusClass = getAppointmentClassFromStatus(status);

  let insuranceCardComponent = <span>{insuranceDefaultOption.label}</span>;
  if (insuranceCompany) {
    insuranceCardComponent = (
      <span onClick={handleRequestInsuranceCard} className="link-span">
        Request Insurance Card
      </span>
    );
  }
  // if appointment is past or patient does not come for appointment
  if (isPassedAppointment || [2, 4].includes(status)) {
    insuranceCardComponent = null;
  }
  if (
    insuranceCompany &&
    insurancePlan &&
    (insuranceCardFrontPhotoUrl || insuranceCardBackPhotoUrl)
  ) {
    // if patient has uploaded Insurance Card
    insuranceCardComponent = (
      <>
        {insuranceCardFrontPhotoUrl && (
          <MyLink to={insuranceCardFrontPhotoUrl} className="link-span">
            View Front Insurance Card Photo
          </MyLink>
        )}
        <br />
        {insuranceCardBackPhotoUrl && (
          <MyLink to={insuranceCardBackPhotoUrl} className="link-span">
            View Back Insurance Card Photo
          </MyLink>
        )}
      </>
    );
  }
  let patientStatusChangeComponent = null;
  let patientToCallComponent = null;
  let cancellationDetailComponent = null;
  if (status === 2) {
    // if appointment is cancelled
    cancellationDetailComponent = cancelledAt && (
      <>
        <div className="cancelled-time">
          Cancelled on{' '}
          {moment.utc(cancelledAt, 'YYYY-MM-DD HH:mm:ss').local().format('MMM DD, YYYY')}
        </div>
        <div className="cancelled-by">
          Cancelled by <span style={{textTransform: 'capitalize'}}>{cancelledBy}</span>{' '}
        </div>
      </>
    );
  }
  if (status === 4) {
    // if patient does not come for appointment
    cancellationDetailComponent = (
      <>
        <div className="cancelled-time">
          Updated on {utcToLocalMoment(updatedAt).format('MMM DD, YYYY')}
        </div>
        <div className="cancelled-by">No Show</div>
      </>
    );
  }
  if (![2, 4].includes(status)) {
    // if appointment is not cancelled
    if (callRequestAt) {
      patientToCallComponent = (
        <div className="notes">
          <span className="cancelled-time">
            Requested patient to call on {utcToLocalMoment(callRequestAt).format('MMM DD')}
          </span>
        </div>
      );
    } else {
      patientToCallComponent = !isPassedAppointment && status !== 4 && (
        <div className="notes">
          <span onClick={handleRequestPatientToCall} className="link-span">
            Request patient to call you
          </span>
        </div>
      );
    }
    patientStatusChangeComponent = !isPassedAppointment && (
      <Select
        value={status || ''}
        onChange={handleAppointmentStatusChange}
        options={
          patient.email !== 'google_calendar@kaly.com'
            ? appointmentStatusOptions
            : appointmentStatusOptionsOnlyCancel
        }
        icon={images.arrowPointer}
      />
    );
  }
  const addressString = getPatientUserAddress(patient.user);
  const isValidTime = config.CHECK_VISIT_VALIDATION ? isValidAppointmentTime() : true;
  if (patient.email === 'google_calendar@kaly.com') {
    return (
      <div style={{maxWidth: '400px'}} className={`AppointmentDetail ${appointmentStatusClass}`}>
        <div className="PatientDetails-content">
          <div className="PatientDetails">
            <span className="DateTime h3">Blocked Out</span>
            <p>
              This appointment time is blocked out due to conflicting appointments in your Google
              Calendar.
            </p>
            <div className="Patient-status-control">{patientStatusChangeComponent}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={`AppointmentDetail ${appointmentStatusClass}`}>
      <div className="PatientDetails-content">
        <div className="PatientDetails">
          <span className="DateTime h3">
            {/* Friday, Feb 14 at 11:00 am */}
            {appointmentStartDateTimeObject.format('dddd, MMM DD [at] h:mm a')}
            <TimeZoneLabelShort />
          </span>
          <div className="Patientinner-Details">
            <div className="Patient-information">
              <div className="Patient-name">
                <span>
                  {patient.firstName} {patient.lastName}
                </span>
                {!hidePatientLink && (
                  <span
                    onClick={() => openViewPatientOverlayModal(patientUserId)}
                    className="link-span">
                    View Patient
                  </span>
                )}
              </div>
              <div className="Patient-address">
                <span>
                  {/* {gender.label} - 10/30/1984 (36 years old) 96 12th Ave, New York, NY 10012 (917) 551- 1234 */}
                  {gender && gender.label} - {dateOfBirthString}
                  {addressString && (
                    <>
                      <br />
                      {addressString}
                    </>
                  )}
                  <br />
                  {formatedPhoneNumber}
                </span>
                <a className="link-span" href={`mailto:${patient.email}`}>
                  {patient.email}
                </a>
              </div>
              <div className="Patient-data">
                {_.get(insuranceCompany, 'companyName', '') && (
                  <span>{_.get(insuranceCompany, 'companyName', '')}</span>
                )}
                <span>
                  {insurancePlan && insurancePlan.plan}
                  {insuranceCompany && insurancePlan && (
                    <span>
                      Member ID: {insuranceMemberId ? insuranceMemberId : 'not specified'}
                    </span>
                  )}
                </span>
                {insuranceCardComponent}
              </div>
            </div>
            <div className="Patient-status">
              <div className="appointment-btn">
                {isVirtual ? (
                  <span id="videoIconBtn" className="video">
                    <img alt="video-icon" src={images.videoCamera} />
                    Video Visit
                  </span>
                ) : (
                  <span id="personIconBtn" className="person">
                    <img alt="person-icon" src={images.personIcon} />
                    In-Person
                  </span>
                )}
              </div>
              <span className="doctor-item">
                <img src={providerUser.profileImageUrl || images.user} alt="" />
                <span className="doctor-info">
                  <span className="doctor-name">
                    {providerUser.firstName} {providerUser.lastName}
                    <span className="doctor-position">{getSuffixString(providerSuffixes)}</span>
                  </span>
                </span>
              </span>
              <div className="Patient-location">
                <img src={images.mapslocation} alt="" />
                {providerLocation.address}
              </div>
              {isVirtual &&
                status === 1 &&
                isValidTime &&
                moment().isBefore(appointmentEndDateTimeObject) && (
                  <Link
                    to={`/video-visit?appointment=${appointmentId}`}
                    className="start-videolink">
                    Start Video Visit
                  </Link>
                )}
              {/* <div className="Patient-Appointment">
                <img src={images.check} alt="" /> Appointment Credit
              </div> */}
              {patientToCallComponent}
              <div className="Patient-status-control">
                {cancellationDetailComponent}
                {patientStatusChangeComponent}
              </div>
            </div>
          </div>
        </div>
        <div className="Patient-symptoms">
          <span className="h3">{isExistingPatient ? 'Existing Patient' : 'New Patient'}</span>
          <div className="Questionnaire">
            <span className="h3"> Questionnaire:</span>
            {answers
              .filter(q => !HideQuestionID.includes(q.questionId))
              .map((answerObj, index) => {
                return <span key={`${answerObj.questionId}${index}`}>{answerObj.answer}</span>;
              })}
          </div>
          {!hidePatientLink && note && (
            <span onClick={showPatientNote} className="link-span" title={note}>
              View Patient Notes
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetail;
