import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import images from 'data/images';
import PracticeLocation from './PracticeLocation';
import practiceData from 'data/practiceData';
import './PracticeLocations.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {defaultPracticeLocation} = practiceData;
const {Analytics} = analyticsData;

const PracticeLocations = props => {
  const {locationList: list, onSaveLocation, onDeleteLocation, hasPermissionToEdit} = props;
  const [locationList, setLocationList] = useState(list);
  useEffect(() => {
    setLocationList(list);
  }, [list]);

  const addItem = () => {
    if (locationList[0].id !== 0) {
      const updatedLocationList = [{...defaultPracticeLocation}, ...locationList];
      setLocationList(updatedLocationList);
    }
  };
  const removeItem = locationId => {
    if (locationId) {
      Nux.sendEvent(
        Analytics.Category.DoctorPractice,
        Analytics.Actions.Click,
        'practiceLocationActions',
      );
      onDeleteLocation(locationId);
    } else {
      const updatedLocationList = _.filter(list, item => item.id !== locationId);
      setLocationList(updatedLocationList);
    }
  };
  return (
    <div className="PracticeLocations box-content">
      <div className="h3">Office Locations</div>
      <div className="PracticeLocations-inner">
        {hasPermissionToEdit && (
          <div className="add-location" onClick={addItem}>
            <span className="plus-icon">
              <img src={images.blueCross} alt="" />
            </span>
            Add Location
          </div>
        )}
        {locationList.map(location => (
          <PracticeLocation
            location={location}
            open={location.id === 0}
            key={location.id}
            onSaveLocation={onSaveLocation}
            onDeleteLocation={removeItem}
            hasPermissionToEdit={hasPermissionToEdit}
          />
        ))}
      </div>
    </div>
  );
};

export default PracticeLocations;
