import React, {useEffect, useState} from 'react';
import modalConfig from 'modals/modalConfig';
import Checkbox from 'components/FormControls/Checkbox';
import {Link} from 'react-router-dom';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import DateManual from 'components/FormControls/DateManual';
import validators from 'data/validators';
import Input from 'components/FormControls/Input';
import config from 'config/config';
import allRegex from 'data/regex';
import './InviteMultipleProvider.css';
const singleProvider = {firstName: '', lastName: '', email: '', fullValid: false};

function EachRow(props) {
  let {providerData, onUpdateEachObj} = props;
  const {MAX_TEXT_LENGTH} = config;
  const {nameRegex} = allRegex;
  const { __required, __maxLength, __email} = validators;
  const values = {};
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const {values: formValues, errors: formErrors} = formData;
  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'First name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'Last name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
    if (__validateForm()) {
      onUpdateEachObj({...providerData, ...formData.values, fullValid: true});
    } else {
      onUpdateEachObj({...providerData, ...formData.values, fullValid: false});
    }
  };
  const __validateForm = () => {
    const validFirstName = !__validateFirstName();
    const validLastName = !__validateLastName();
    const validateEmail = !__validateEmail();
    return validFirstName && validLastName && validateEmail;
  };
  return (
    <>
      <div
        style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '10px'}}
        className="form-row">
        <Input
          {...getGeneralInputProps('firstName')}
          containerClass="two-input"
          type="text"
          label="First name"
          placeholder={'Enter First Name'}
        />
        <Input
          {...getGeneralInputProps('lastName')}
          containerClass="two-input"
          type="text"
          label="Last name"
          placeholder={'Enter Last Name'}
        />
      </div>
      <Input
        label="Enter Your Email"
        name="email"
        placeholder={'Enter Valid Email'}
        {...getGeneralInputProps('email')}
      />
    </>
  );
}
export default function InviteMultipleProvider(props) {
  const generateObjID = () => Math.floor(Date.now() / 1000);
  const [providerList, setProviderList] = useState([{...singleProvider, Id: generateObjID()}]);

  const actionBtn = (act, id = null) => {
    if (act == 'Add') {
      setProviderList([...providerList, {...singleProvider, Id: generateObjID()}]);
    } else if (id) {
      let removedarray = [...providerList];
      let index = removedarray.findIndex(o => o.Id === id);
      if (index > -1) {
        removedarray.splice(index, 1);
        setProviderList([...removedarray]);
        props.setMultipleProvider([...removedarray]);
      }
    }
  };
  const updateEachObj = (newObj, id) => {
    if (id) {
      let updatedObj = [...providerList];
      let index = updatedObj.findIndex(o => o.Id === id);
      if (index > -1) {
        updatedObj[index] = {...updatedObj[index], ...newObj};
        setProviderList([...updatedObj]);
        props.setMultipleProvider(updatedObj);
      }
    }
  };
  return (
    <>
      {providerList.map((p, index) => (
        <div className="InviteMultipleProvider-maindiv-parent" key={`eachrow-${p.Id}`}>
          <span className="count-Indicater">{index + 1}</span>
          {providerList.length !== 1 && (
            <button
              className="dailog-close-btn"
              onClick={() => {
                actionBtn('Sub', p.Id);
              }}>
              <img alt="deleteRound" src={images.deleteRound} />
            </button>
          )}
          <div className="InviteMultipleProvider-maindiv">
            <div className="input-div">
              <EachRow
                onUpdateEachObj={newObj => {
                  updateEachObj(newObj, p.Id);
                }}
                providerData={p}
              />
            </div>
            {index + 1 === providerList.length && (
              <div className="action-div">
                <button
                  onClick={() => {
                    index + 1 === providerList.length ? actionBtn('Add') : actionBtn('Sub', p.Id);
                  }}
                  disabled={
                    index + 1 === providerList.length && p.fullValid === false ? true : false
                  }
                  className="action-btn"
                  type="button">
                  Add Providers
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}
