import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import CircleButton from 'components/CircleButton';
import Checkbox from '../Checkbox';
import './SortBy.css';
import constants from 'data/constants';

const {DoctorFilters} = constants;

const SortBy = props => {
  const {
    options = [],
    values = [],
    labelIndex = 'label',
    valueIndex = 'value',
    onFilterApply = () => {},
    activeFilter = null,
    onFilterSelect = () => {},
  } = props;
  let {containerClass = ''} = props;
  const [selectedFilter, setFilter] = useState(values);
  const [active, toggleActive] = useState(activeFilter === DoctorFilters.SORTBY);

  useEffect(() => {
    setFilter(values);
  }, [values]);

  useEffect(() => {
    if (active && activeFilter !== DoctorFilters.SORTBY) {
      onFilterSelect(DoctorFilters.SORTBY);
    }
  }, [active]);

  useEffect(() => {
    if (active && activeFilter !== DoctorFilters.SORTBY) {
      toggleActive(false);
    }
  }, [activeFilter]);

  const toggleCheckbox = value => {
    if (selectedFilter === value) {
      setFilter([]);
    } else {
      setFilter(value);
    }
  };

  const applyFilter = () => {
    toggleActive(false);
    if (!_.isEqual(values, selectedFilter)) {
      onFilterApply(selectedFilter);
    }
  };
  if (values.length > 0) {
    containerClass += ' selected';
  }

  return (
    <div className={`SortBy ${containerClass}`}>
      <CircleButton
        btnType="secondaryButton"
        onClick={() => {
          toggleActive(!active);
        }}>
        Sort By
      </CircleButton>
      {active && <div className="overlay" onClick={applyFilter}></div>}
      {active && (
        <div className={`dropdown-menu show-menu`}>
          {options.map(option => {
            return (
              <div className="dropdown-option" key={option[valueIndex]}>
                <Checkbox
                  value={selectedFilter.includes(option[valueIndex])}
                  onChange={e => toggleCheckbox(option[valueIndex])}>
                  {option[labelIndex]}
                </Checkbox>
              </div>
            );
          })}
          <div className="dropdown-bottom">
            {selectedFilter.length > 0 && (
              <CircleButton btnType="secondaryButton" onClick={() => setFilter([])}>
                Clear
              </CircleButton>
            )}
            <CircleButton btnType="whiteButton" onClick={applyFilter}>
              Apply
            </CircleButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortBy;
