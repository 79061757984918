import config from 'config/config';
const {GAID} = config;

/*REF:
https://developers.google.com/analytics/devguides/collection/ga4/basic-tag?technology=analyticsjs
https://www.analyticsmania.com/post/how-to-track-events-with-google-analytics-4-and-google-tag-manager/
*/

const script = document.createElement('script');
script.src = 'https://www.googletagmanager.com/gtag/js?id=' + GAID;
script.async = true;
document.body.appendChild(script);
window.dataLayer = window.dataLayer || [];
let gtagConfig = {
  cookie_flags: 'secure',
};
gtag('js', new Date());
let kalypsysCookie = localStorage.getItem('kalypsys-cookiename');
kalypsysCookie = JSON.parse(kalypsysCookie);

if (kalypsysCookie?.level && !kalypsysCookie?.level.includes('googleAnalytics')) {
   gtagConfig.client_storage = 'none';
  gtagConfig.anonymize_ip = true;
   gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });
  gtag('config', GAID, gtagConfig);
  setTimeout(() => {
   
  }, 1000);
  
 
} else {
  gtag('config', GAID, gtagConfig);
}

window.addEventListener('storage', localStorageUpdated);

function localStorageUpdated() {
 
  let kalypsysCookie = localStorage.getItem('kalypsys-cookiename');
  kalypsysCookie = JSON.parse(kalypsysCookie);
  if (kalypsysCookie?.level && !kalypsysCookie?.level.includes('googleAnalytics')) {
  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
  });
}
}
function gtag() {
  window.dataLayer.push(arguments);
}
// Send page view
function sendPageview(str) {
  if (!str) str = 'trackError';

  gtag('config', GAID, {
    page_path: str,
    cookie_flags: 'secure',
  });
}

// Send custom event
function sendEvent(eventCategory, eventAction, eventLabel) {
  gtag('event', eventCategory, {'event-action': eventAction, 'event-label': eventLabel});
}

var Nux = {sendEvent, sendPageview};
export default Nux;

// for fast testing
// window.Nux = Nux;

/* 
  
  MAIN COMMENT,  EXAMPLES
  =======================
  
  Nux.sendPageview('home');
  
  Args are referred to category action label
  
  this works but only label can be null
    Nux.sendEvent('aa3','bbb22', 'ccc');
    Nux.sendEvent('aa3','bbb22', null);
  
  Approach
  1. In google UI use search tool
  2. Put all valuable info in category separated by underscores
     Nux.sendEvent('perf_checkname_micorservicesCall_61-80', 'perf', null);
     Nux.sendEvent('homepage_mybutton_click', 'flow', null);
  */
