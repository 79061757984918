import React from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

const StripeInput = props => {
  const stripe = useStripe();
  const elements = useElements();
  const {trailPeriodGlobal=90,hideTrail=null}=props
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        backgroundColor: '#fff',
        fontSize: '16px',
        lineHeight: '1.6',
        letterSpacing: '-0.34px',
        color: '#013648',
      },
    },
  };

  const handleChange = async data => {
    if (data.complete) {
      const cardElement = elements.getElement(CardElement);
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) console.log('[createPaymentMethod error]', error);
      else {
        props.setStripePaymentMethod(paymentMethod);
        props.setPaymentValid(true);
      }
    } else {
      props.setPaymentValid(false);
    }
  };

  return (
    <>
      <div className={`Input-control two-input form-group`}>
        <span className="label">
          <span>Payment Details</span>
          {!hideTrail&&(
          <span className="inner-label"> (You will be charged after {trailPeriodGlobal} days trial period)</span>
          )}
        </span>
        <div className="stripe-input">
          <CardElement onChange={handleChange} options={CARD_ELEMENT_OPTIONS} />
        </div>
      </div>
    </>
  );
};

export default StripeInput;
