import images from 'data/images';

const landingPageConstants = {
  posts: [
    {
      id: 'Manage',
      title: 'All-in-One Dashboard',
      content: 'Easily, track metrics and activity from your practice, providers, and patients.',
    },
    {
      id: 'Book',
      title: 'Automate your Front Pffice',
      content: 'Manage appointments and provider availabilities with smarter scheduling.',
    },
    {
      id: 'Grow',
      title: 'Powerful Review Platform',
      content: 'Showcase and reply to reviews from around the web on your profile.',
    },
  ],
  cards: [
    {
      image: images.team1,
      name: 'Jane Goodell MD',
      place: 'New York . Psychiatrist',
      description:
        'My new practice took off in no time, thanks to the advice I received from Kaly!',
    },
    {
      image: images.team2,
      name: 'John Mastey, MD',
      place: 'New York . Pain Management Specialist',
      description:
        'My new practice took off in no time, thanks to the advice I received from Kaly!',
    },
    {
      image: images.team3,
      name: 'Alex Demarco MD',
      place: 'New York . Neurosurgeon',
      description:
        'My new practice took off in no time, thanks to the advice I received from Kaly!',
    },
    {
      image: images.team3,
      name: 'Alex Demarco MD',
      place: 'New York . Neurosurgeon',
      description:
        'My new practice took off in no time, thanks to the advice I received from Kaly!',
    },
  ],
  advisoryboard:[
    {
      image: images.advisoryboard7,
      name: 'Paul Brisson, MD',
      place: 'Board Certified Orthopedic Spine Surgeon',
      description:'Training: New York University',
    },
    {
      image: images.advisoryboard6,
      name: 'Cherise Dyal, MD',
      place: 'Board Certified Orthopedic Foot and Ankle Surgeon',
      description:'Training: Columbia University',
    },   
    {
      image: images.advisoryboard11,
      name: 'Neil Gandhi, MD, MBA',
      place: 'Board Certified in Emergency Medicine',
      description:'Training: Thomas Jefferson University',
    },   
    {
      image: images.advisoryboard8,
      name: 'Cory Golloub, MD',
      place: 'Board Certified in Internal Medicine and Pediatrics',
      description:'Training: Rutgers New Jersey Medical School',
    },
    {
      image: images.advisoryboard13,
      name: 'Sunny Intwala, MD, FACC',
      place: 'Board Certified in Cardiology',
      description:'Training: Brown Medical School',
    },
    {
      image: images.advisoryboard10,
      name: 'Karan Johar, MD',
      place: 'Board Certified in Pain Medicine',
      description:'Training: Washington University in St. Louis',      
    },
    {
      image: images.advisoryboard4,
      name: 'Salvatore Lenzo, MD',
      place: 'Board Certified Orthopedic Hand Surgeon',
      description:'Training: New York University',
    },
    {
      image: images.advisoryboard5,
      name: 'Edward Scheid, MD',
      place: 'Board Certified Neurosurgeon',
      description:'Training: Thomas Jefferson University',
    },    
    {
      image: images.advisoryboard1,
      name: 'Abe Shahim, MD, MBA, FACOG',
      place: 'Board Certified in Obstetrics and Gynecology',
      description:'Training: Northwell Health',
    },
    {
      image: images.advisoryboard3,
      name: 'Adam Shoukry, DDS',
      place: 'Board Certified in Dental Surgery',
      description:'Training: New York University',
    },   
    {
      image: images.advisoryboard9,
      name: 'Thomas Simopoulos, MD',
      place: 'Board Certified in Interventional Pain',
      description:'Training: Harvard Medical School',
    },
    
    {
      image: images.advisoryboard2,
      name: 'Jennifer Wu, MD, FACOG',
      place: 'Board Certified in Obstetrics and Gynecology',
      description:'Training: Northwell Health',
    },
    {
      image: images.advisoryboard12,
      name: 'Ben Archer, DDS, FACS',
      place: 'Board Certified in Oral & Maxillofacial Surgery',
      description:'Training: Baylor University Medical Center',
    },
    
  ]
};

export default landingPageConstants;
