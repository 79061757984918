import React, {useContext, useState, useEffect} from 'react';
import _ from 'lodash';
import SubHeader from 'components/AfterAuth/SubHeader';
import ProviderProfile from '../../../../components/AfterAuth/Settings/ProvidersTab/ProviderProfile';
import adminService from 'services/admin';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import Layout from 'layout/AdminLayout';
import './EditProvider.css';

const {getProviderDataById, updateProviderDetails, updateProviderAccount, updateProvideSeoPage} =
  adminService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;

const EditProvider = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerIdSelected, setProviderId] = useState(null);
  const [userIdSelected, setUserId] = useState(null);

  useEffect(() => {
    getAndSetCurrentProvider();
  }, []);

  const getAndSetCurrentProvider = () => {
    const query = new URLSearchParams(props.location.search);
    const providerId = query.get('provider') || 0;
    const userId = query.get('user') || 0;
    setProviderId(providerId);
    setUserId(userId);
  };

  const handleCancelEdit = () => {
    const {location} = props;
    const searchData = _.get(location, 'state', {});
    const urlKey = _.get(location, 'state.urlKey', null);
    
    let passData = {...searchData};
    if (passData.list && passData.list.length > 0 && !urlKey) {
      let index = -1;
      if (urlKey) index = passData.list.findIndex(pra => pra.providerId == providerIdSelected);
      else index = passData.list.findIndex(pra => pra.provideId == providerIdSelected);
//console.log("index",index);
      if (index >= 0) {
        passData.list[index] = {
          ...passData.list[index],
          seoPage: selectedProvider.provider.seoPage,
          seoPageUrl: selectedProvider.provider.seoPageUrl,

        };
      }
    }
    if (urlKey) {
      //
      props.history.push({
        pathname: `/admin/join-us/${urlKey}`,
        state: passData,
      });
    } else if(searchData.searchStatus==='inActive')
    {
      props.history.push({
        pathname: '/admin/join-us/inactive-providers',
        state: passData,
      });
    }else{
      props.history.push({
        pathname: '/admin/providers',
        state: passData,
      });
    }
  };

  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  const handleProfileUrlChange = async (providerId, newURL) => {
    setSelectedProvider({
      ...selectedProvider,
      provider: {
        ...selectedProvider.provider,
        profileImageUrl: newURL,
      },
    });
  };

  const handleAdditionalPhotosUpload = (providerId, uploadedFileArray) => {
    // append new uploaded files to providerPhotos
    let updatedProviderPhotos = [...selectedProvider.provider.providerPhotos, ...uploadedFileArray];
    updatedProviderPhotos = _.reverse(
      _.sortBy(updatedProviderPhotos, [
        photoObj => {
          return parseInt(photoObj.id);
        },
      ]),
    );

    setSelectedProvider({
      ...selectedProvider,
      provider: {
        ...selectedProvider.provider,
        providerPhotos: updatedProviderPhotos,
      },
    });
  };

  const handleAdditionalPhotosRemove = (providerId, photoId) => {
    // remove file from providerPhotos
    let updatedProviderPhotos = [...selectedProvider.provider.providerPhotos];
    updatedProviderPhotos = _.filter(updatedProviderPhotos, photoObj => photoObj.id !== photoId);

    setSelectedProvider({
      ...selectedProvider,
      provider: {
        ...selectedProvider.provider,
        providerPhotos: updatedProviderPhotos,
      },
    });
  };

  const handleAdditionalVideoUpload = (providerId, uploadedFileArray) => {
    // append new uploaded files to provider videos
    let updatedProviderVideo = [...selectedProvider.provider.providerVideos, ...uploadedFileArray];
    updatedProviderVideo = _.reverse(
      _.sortBy(updatedProviderVideo, [
        photoObj => {
          return parseInt(photoObj.id);
        },
      ]),
    );

    setSelectedProvider({
      ...selectedProvider,
      provider: {
        ...selectedProvider.provider,
        providerVideos: updatedProviderVideo,
      },
    });
  };

  const handleAdditionalVideoRemove = (providerId, videoId) => {
    // remove file from provider videos
    let updatedProviderVideo = [...selectedProvider.provider.providerVideos];
    updatedProviderVideo = _.filter(updatedProviderVideo, photoObj => photoObj.id !== videoId);

    setSelectedProvider({
      ...selectedProvider,
      provider: {
        ...selectedProvider.provider,
        providerVideos: updatedProviderVideo,
      },
    });
  };

  const handleSaveOverView = async (providerId, data) => {
    updateProviderData('overview', providerId, data);
  };

  const handleSaveAdditionalInfo = async (providerId, data) => {
    updateProviderData('information', providerId, data);
  };

  const handleSaveVisitReasons = async (providerId, data) => {
    updateProviderData('visit-reasons', providerId, data);
  };

  const updateProviderData = async (method, providerId, data) => {
    const response = await updateProviderDetails(method, providerId, data).catch(handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            handleGetProviderData(providerId);
          },
        }),
      );
    }
  };

  const handleGetProviderData = async providerId => {
    const response = await getProviderDataById(providerId).catch(handleError);
    if (response) {
      const {data} = response;
      setSelectedProvider(data);
      return data;
    }
  };

  const handleSaveAccount = async (providerId, data) => {
    const response = await updateProviderAccount(userIdSelected, data).catch(handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            handleGetProviderData(providerId);
          },
        }),
      );
    }
  };
  const handleSaveSeoLink = async (providerId, data) => {
    const response = await updateProvideSeoPage(providerId, data).catch(handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            handleGetProviderData(providerId);
          },
        }),
      );
    }
  };

  const handleCommonProviderData = async (providerId, data) => {
    updateProviderData('specialties-conditions-procedures', providerId, data);
  };

  return (
    <Layout>
      <div className="EditProvider">
        <SubHeader title="Provider Details" hideName />
        <div className="dashboard-panle">
          {providerIdSelected && (
            <ProviderProfile
              providerId={providerIdSelected}
              getProviderData={handleGetProviderData}
              user={selectedProvider}
              onProfileImageChange={profileImageUrl =>
                handleProfileUrlChange(providerIdSelected, profileImageUrl)
              }
              onError={handleError}
              onAdditionalPhotosUpload={uploadedFileArray =>
                handleAdditionalPhotosUpload(providerIdSelected, uploadedFileArray)
              }
              onAdditionalPhotosRemove={photoId =>
                handleAdditionalPhotosRemove(providerIdSelected, photoId)
              }
              onAdditionalVideoUpload={uploadedFileArray =>
                handleAdditionalVideoUpload(providerIdSelected, uploadedFileArray)
              }
              onAdditionalVideoRemove={videoId =>
                handleAdditionalVideoRemove(providerIdSelected, videoId)
              }
              onClearProviderSelection={handleCancelEdit}
              onSaveOverView={data => handleSaveOverView(providerIdSelected, data)}
              onSaveAdditionalInfo={data => handleSaveAdditionalInfo(providerIdSelected, data)}
              onCommonDataSave={data => handleCommonProviderData(providerIdSelected, data)}
              onSaveVisitReasons={data => handleSaveVisitReasons(providerIdSelected, data)}
              isAdmin={true}
              currentUserId={userIdSelected}
              onSaveProviderAccount={data => handleSaveAccount(providerIdSelected, data)}
              onHandleSaveSeoLink={data => handleSaveSeoLink(providerIdSelected, data)}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditProvider;
