import React, {Component} from 'react';
import Layout from 'layout/VideoLayout';
import images from 'data/images';
import {Link} from 'react-router-dom';
import './VideoWaitingRoom.css';
import patientService from 'services/patient';
import momentHelper from 'data/moment/momentHelper';
import moment from 'moment';
import _ from 'lodash';
import helper from 'data/helper';
import TimeZoneLabelShort from 'components/MyDatetime/TimeZoneLabelShort';

const {getAppointmentDetailsById} = patientService;
const {utcToLocalMoment} = momentHelper;
const {getSuffixString} = helper;
const initialState = {
  appointmentData: {},
};

class VideoWaitingRoom extends Component {
  state = {...initialState};

  componentDidMount = () => {
    this.getAppointmentData();
  };

  getAppointmentData = async () => {
    const response = await getAppointmentDetailsById(this.props.id).catch(this.handleError);
    if (response) {
      const {data} = response;
      this.setState({appointmentData: data});
    }
  };

  render() {
    const {appointmentData} = this.state;
    const appointmentStartDateTimeObject = utcToLocalMoment(
      appointmentData.appointmentStartDateTime,
    );
    const appointmentEndDateTimeObject = utcToLocalMoment(appointmentData.appointmentEndDateTime);
    const duration = moment
      .duration(appointmentEndDateTimeObject.diff(appointmentStartDateTimeObject))
      .asMinutes();
    const patient = _.get(appointmentData, 'patient', {});
    const doctorUser = _.get(appointmentData, 'providerAvailability.provider.user', []);
    const suffixes = _.get(appointmentData, 'providerAvailability.provider.suffixes', []);
    const doctorName = `${_.get(doctorUser, 'firstName', '')} ${_.get(
      doctorUser,
      'lastName',
      '',
    )} ${getSuffixString(suffixes)}`;
    const profileImg = _.get(doctorUser, 'profileImageUrl', '');
    const detailComponent = (
      <div className="detail-block">
        <div className="detail-heading">
          <img className="schedule-icon" src={images.scheduleWhiteIcon} alt="schedule-icon" />
          Appointment Details
        </div>
        <div className="appoinment-detail">
          <span className="name">
            {patient.firstName} {patient.lastName}
          </span>
          <span className="date-time">
            {appointmentStartDateTimeObject.format('dddd, MMM DD [at] h:mm a')}<TimeZoneLabelShort/>
          </span>
          <span className="time-duration">{duration} min Video Visit</span>
        </div>
      </div>
    );

    return (
      <Layout>
        <div className="WaitingRoomBlock">
          <div className="waiting-top">
            <div className="sub-heading">Welcome to the waiting room for</div>
            <div className="appoinment-wrapper">
              <div className="heading2">{doctorName}</div>
              {profileImg && (
                <span className="doctor-img">
                  <img src={profileImg} alt="doctor-img" />
                </span>
              )}
              {appointmentData && appointmentData.patient && detailComponent}
            </div>
            <div className="sub-heading2">Your provider will start your visit soon….</div>
          </div>
          <div className="waiting-bottom">
            <div className="para">If this is not your appointment please click below</div>
            <Link to="/" className="appoinment-link">
              Not my appointment
            </Link>
          </div>
        </div>
      </Layout>
    );
  }
}

export default VideoWaitingRoom;
