import React, {useRef} from 'react';
import _ from 'lodash';
import Input from '../Input';
import allRegex from 'data/regex';
import moment from 'moment';
import './DateManual.css';

const {exceptDigitsRegex} = allRegex;

const DateManual = props => {
  const {
    name: propName = '',
    value: propValue = '',
    label = '',
    onChange = () => {},
    error = '',
  } = props;
  let {containerClass = ''} = props;

  const dateArray = (propValue || '').split('-');

  const dateConfig = {
    day: _.get(dateArray, '2', ''),
    month: _.get(dateArray, '1', ''),
    year: _.get(dateArray, '0', ''),
  };

  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  const {day, month, year} = dateConfig;

  const isValidDate = (name = null, value = '') => {
    let newYear = year;
    let newMonth = month;
    let newday = day;
    switch (name) {
      case 'day':
        newday = value;
        break;
      case 'month':
        newMonth = value;
        break;
      case 'year':
        newYear = value;
        break;
      default:
        break;
    }
    const date = `${newYear}-${newMonth}-${newday}`;
    return {
      dateString: date,
      isValidDate: moment(date, 'YYYY-MM-DD', true).isValid(),
    };
  };

  const changeHandler = (event, isBlur) => {
    const {name} = event.target;
    let {value} = event.target;
    value = value.replace(exceptDigitsRegex, '');
    switch (name) {
      case 'month':
        value = value.substr(0, 2);
        if ((value.length === 1 && value > 1) || value > 12) {
          value = '0' + value.substr(0, 1);
          !isBlur && dayRef.current.focus();
        }
        if (!isBlur && value.length === 2) {
          dayRef.current.focus();
        }
        break;
      case 'day':
        value = value.substr(0, 2);
        if ((value.length === 1 && value > 3) || value > 31) {
          value = '0' + value.substr(0, 1);
          !isBlur && yearRef.current.focus();
        }
        if (!isBlur && value.length === 2) {
          yearRef.current.focus();
        }
        break;
      case 'year':
        value = value.substr(0, 4);
        break;
      default:
        break;
    }
    if (isBlur && ['day', 'month'].includes(name)) {
      if (value.length === 1) {
        value = '0' + value;
      }
    }
    const dateObj = isValidDate(name, value);
    onChange({
      target: {
        name: propName,
        value: dateObj.dateString,
        isValidDate: dateObj.isValidDate,
      },
    });
  };

  let inputErrorClass = '';
  if (error) {
    inputErrorClass += ' error';
  }

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: e => changeHandler(e, true),
      value: dateConfig[controlName],
      name: controlName,
      containerClass: inputErrorClass,
    };
  };

  return (
    <div className={`DateManual ${containerClass}`}>
      <Input type="number" placeholder="MM" ref={monthRef} {...getGeneralInputProps('month')} />
      <Input type="number" placeholder="DD" ref={dayRef} {...getGeneralInputProps('day')} />
      <Input type="number" placeholder="YYYY" ref={yearRef} {...getGeneralInputProps('year')} />
      {label && <span className="date-manual-label">{label}</span>}
      {error && <span className="error-text">{error}</span>}
    </div>
  );
};

export default DateManual;
