import React, {Component} from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import adminService from 'services/admin';
import './AdminDashboard.css';
import UserCounts from './UserCounts';
import modalConfig from 'modals/modalConfig';

const {getAdminDashboard} = adminService;
const {modalFunctions} = modalConfig;
const {errorModalData} = modalFunctions;
const initialState = {
  analysisData: null,
  practiceAnalysis: null,
  providerAnalysis: null,
  patientAnalysis: null,
};

class AdminDashboard extends Component {
  state = {...initialState};

  componentDidMount() {
    this.getDashboardData();
  }

  getDashboardData = async () => {
    const response = await getAdminDashboard().catch(this.handleError);
    if (response && response.data && response.data.analysisData) {
      const analysis = [...response.data.analysisData];
      const practiceData = analysis.filter(data => data.countType === 'Practice');
      const providerData = analysis.filter(data => data.countType === 'Provider');
      const patientData = analysis.filter(data => data.countType === 'Patient');
      const ghostProviderData = analysis.filter(data => data.countType === 'Ghost');
      this.setState({
        practiceAnalysis: practiceData,
        providerAnalysis: providerData,
        patientAnalysis: patientData,
        ghostProviderAnalysis: ghostProviderData,
      });
    }
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  render() {
    const {practiceAnalysis, providerAnalysis, patientAnalysis, ghostProviderAnalysis} = this.state;
    const title = 'Admin Dashboard';

    const countComponent = (
      <>
        <div className="analysis-title">Practices</div>
        {practiceAnalysis && <UserCounts data={practiceAnalysis} />}
        <div className="monthly-activity">
          <div className="title-content">
            <span className="title"></span>
          </div>
        </div>
        <div className="analysis-title">Providers</div>
        {providerAnalysis && <UserCounts data={providerAnalysis} />}
        <div className="monthly-activity">
          <div className="title-content">
            <span className="title"></span>
          </div>
        </div>
        <div className="analysis-title">Ghost Providers</div>
        {ghostProviderAnalysis && <UserCounts data={ghostProviderAnalysis} />}
        <div className="monthly-activity">
          <div className="title-content">
            <span className="title"></span>
          </div>
        </div>
        <div className="analysis-title">Patients</div>
        {patientAnalysis && <UserCounts data={patientAnalysis} />}
      </>
    );

    return (
      <Layout>
        <div className="AdminDashboard">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="panel-left">
              {(providerAnalysis || patientAnalysis || practiceAnalysis) && countComponent}
            </div>
            <div className="panel-right"></div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default AdminDashboard;
