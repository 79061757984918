import React, { useState } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import constants from 'data/constants';

import validators from 'data/validators';
import CircleButton from 'components/CircleButton';
import images from 'data/images';

import MyDatetime from 'components/MyDatetime';
import momentHelper from 'data/moment/momentHelper';

import modalConfig from 'modals/modalConfig';
import TimeManual from 'components/FormControls/TimeManual';
import holidays from 'data/holidays';

import './ProviderReschedule.css';
import TimeZoneLabel from 'components/MyDatetime/TimeZoneLabel';
import { toast, ToastContainer } from 'react-toastify';

const { getHoliday } = holidays;
const { __required } = validators;
const {
  dateTimeFormat,
  startOf,
  isSameOrAfter,
  isSameOrBefore,
  dateFormat,
  bigDateFormat,
  isSameDay,

  utcToLocalDateFormat,
  isSame,

  utcToLocalMoment,

  localToUtcMoment
} = momentHelper;



const ProviderReschedule = props => {
  const { setGlobal, } = props.context;
  const { appointmentDetail } = props
  const { patient, appointmentStartDateTime, appointmentEndDateTime, id, providerAvailability } = appointmentDetail
  const contDuration = moment.duration(moment(appointmentStartDateTime).diff(moment(appointmentEndDateTime))).asMinutes();

  const { modalFunctions } = modalConfig;
  const { blankModalData } = modalFunctions;
  const Warning = props => {
    const { closeModal, resolve } = props;

    const { data = {}, onSave } = props;
    const now = () => {
      let nowTemp = localToUtcMoment(new Date()).format();
      nowTemp = utcToLocalMoment(nowTemp);
      let nowHH = nowTemp.format('HH');
      let nowMM = nowTemp.format('mm');
      let rdata = new Date();
      rdata.setDate(nowTemp.format('DD'));
      rdata.setHours(nowHH);
      rdata.setMinutes(nowMM);
      return rdata;
    };

    const currentDateTime = moment();
    const [formData, setFormData] = useState({
      values: {
        days: [],
        startDatetime: moment(appointmentStartDateTime),
        practiceLocationId: '',
        endDatetime: moment(appointmentEndDateTime),
        duration: contDuration,
        ...data,
      },
      errors: { serverError: null },
    });
    const { values: formValues, errors: formErrors } = formData;
    const { isAllowRecurringEdit } = formValues;

    const endTime = (starDateTime = null) => {
      return (starDateTime || formValues['startDatetime'])
        .clone()
        .add(formValues['duration'], 'minutes');
    };

    // Validation functions

    const __validateDays = (value = null, repeatValue) => {
      const fValue = value !== null ? value : formValues['days'];
      if (repeatValue === 'CUSTOM' && !fValue.includes(true)) {
        return 'Please select atleast one day';
      }
      return null;
    };
    const __validateLocation = (value = null) => {
      const fValue = value !== null ? value : formValues['practiceLocationId'];
      if (!__required(fValue)) {
        return 'Location is required';
      }
      return null;
    };
    const __validateDuration = (value = null) => {
      const fValue = value !== null ? value : formValues['duration'];
      if (!__required(fValue)) {
        return 'Duration is required';
      }
      return null;
    };
    const __validateStartDate = (value = null) => {
      const fValue = value !== null ? value : formValues['startDatetime'];
      if (!__required(fValue)) {
        return 'Start Date is required';
      }
      if (!fValue.isValid()) {
        return 'Start Date is Invalid';
      }
      return null;
    };
    const __validateEndDate = (value = null) => {
      const fValue = value !== null ? value : formValues['endDatetime'];
      if (!__required(fValue)) {
        return 'End Date is required';
      }
      if (!fValue.isValid()) {
        return 'End Date is Invalid';
      }
      return null;
    };

    const __validateForm = () => {
      if (dateTimeFormat(formValues['startDatetime'], 'YYYY-MM-DD HH:mm a') !== dateTimeFormat(appointmentStartDateTime, 'YYYY-MM-DD HH:mm a')) {
        return true
      }
      return false
    };
    const isValidForm = __validateForm();

    const onServerError = error => {
      setFormData({
        ...formData,
        errors: {
          ...formData.errors,
          serverError: error,
        },
      });
    };



    const getAvailabilityArray = (currentWeekStartDate, dbEvents, daysCount = 6) => {
      const availabilityArray = {};
      let startDate = currentWeekStartDate;
      for (let i = 0; i <= daysCount; i++) {
        const clonedDate = startDate.clone();
        const formatedDate = dateFormat(clonedDate.add(i, 'days'));
        availabilityArray[formatedDate] = [];
      }
      const regularEvents = [];
      const dailyEvents = [];
      const weeklyCustomEvents = [];
      const refEvents = [];
      // devide events according to repeat type
      _.forEach(dbEvents, event => {
        if (event.refId !== null) {
          refEvents.push(event);
          return;
        }
        if (event.repeat === 'none') {
          regularEvents.push(event);
        }
        if (event.repeat === 'daily') {
          dailyEvents.push(event);
        }
        if (event.repeat.includes('CUSTOM')) {
          weeklyCustomEvents.push(event);
        }
      });
      // none
      _.forEach(regularEvents, dEvent => {
        const date = utcToLocalDateFormat(dEvent.startDatetime);
        _.forEach(availabilityArray, (value, keyDate) => {
          const mainDate = keyDate;
          if (isSame(mainDate, date)) {
            availabilityArray[mainDate].push(dEvent);
          }
        });
      });
      // daily
      _.forEach(dailyEvents, dEvent => {
        const date = utcToLocalDateFormat(dEvent.startDatetime);
        const eDate = utcToLocalDateFormat(dEvent.endDatetime);
        _.forEach(availabilityArray, (value, keyDate) => {
          const mainDate = keyDate;
          if (isSameOrAfter(mainDate, date) && isSameOrBefore(mainDate, eDate)) {
            availabilityArray[mainDate].push(dEvent);
          }
        });
      });
      // weekly
      _.forEach(weeklyCustomEvents, dEvent => {
        const date = utcToLocalDateFormat(dEvent.startDatetime);
        const eDate = utcToLocalDateFormat(dEvent.endDatetime);
        const repeatDays = _.split(dEvent.repeat, '-');
        repeatDays.splice(0, 1);
        const days = getDays(repeatDays);
        _.forEach(availabilityArray, (value, keyDate) => {
          const mainDate = keyDate;
          let dateDay = dateFormat(mainDate, 'dddd');
          let dayIndex = constants.DayIndex[dateDay];
          if (
            isSameOrAfter(mainDate, date) &&
            isSameOrBefore(mainDate, eDate) &&
            days.includes(dayIndex)
          ) {
            availabilityArray[mainDate].push(dEvent);
          }
        });
      });
      // remove or modify ref events
      _.forEach(refEvents, refEvent => {
        const formatedRefdate = utcToLocalDateFormat(refEvent.startDatetime);
        const currentFormatedDatesEvents = availabilityArray[formatedRefdate];
        _.forEach(currentFormatedDatesEvents, (cfde, index) => {
          if (cfde.id === refEvent.refId) {
            if (!refEvent.makeAvailable) {
              delete availabilityArray[formatedRefdate][index];
            } else {
              availabilityArray[formatedRefdate][index] = refEvent;
            }
          }
        });
        availabilityArray[formatedRefdate] = _.compact(availabilityArray[formatedRefdate]);
      });
      return availabilityArray;
    };

    const getDays = repeatDays => {
      const newArray = _.map(repeatDays, d => {
        return constants.DAYS[d];
      });
      return newArray;
    };

    const changeHandler = event => {
      let { name } = event.target;
      let { value, duration } = event.target;
      let errorObject = {
        [name]: null,
      };
      const nameArr = name.split('-');
      switch (nameArr[0]) {

        case 'startDatetime':
          errorObject = {
            [name]: __validateStartDate(value),
          };
          break;
        case 'endDatetime':
          errorObject = {
            [name]: __validateEndDate(value),
          };
          break;
        case 'duration':
          errorObject = {
            [name]: __validateDuration(value),
          };
          break;
        case 'days':
          name = 'days';
          const oldValues = [...formValues[name]];
          oldValues[nameArr[1]] = value;
          value = oldValues;
          errorObject = {
            [name]: __validateDays(value, formValues['repeat']),
          };
          break;
        case 'practiceLocationId':
          errorObject = {
            [name]: __validateLocation(value),
          };
          break;
        case 'isVirtual':
          errorObject = {};
          break;
        case 'isInPerson':
          errorObject = {};
          break;
        default:
          break;
      }
      if (name === 'duration') {
        setFormData({
          ...formData,
          values: {
            ...formData.values,
            [name]: value,
          },
          errors: {
            ...formData.errors,
            ...errorObject,
          },
        });
      } else {
        setFormData(prevformData => {
          return {
            ...prevformData,
            values: {
              ...prevformData.values,
              [name]: value,
              duration: duration ? duration : prevformData.values.duration,
            },
            errors: {
              ...prevformData.errors,
              ...errorObject,
            },
          };
        });
      }
    };




    const handleForm = async () => {
      const updatedFormValues = { ...formValues };


      let passData = {
        appointmentId: id,
        providerAvailabilityId: providerAvailability.id,
        appointmentDateTime: moment(updatedFormValues.startDatetime, 'YYYY-MM-DD h:mm a')
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        patientId: patient.id
      }

      resolve(passData)
      return;

    };






    // date time picker functions
    const [dateConfig, setDateConfig] = useState({
      start: { open: false, input: false, viewMode: 'days', timeFormat: 'hh:mm A' },
      end: { open: false, input: false, viewMode: 'days', timeFormat: 'hh:mm A' },
    });

    const updateTimeConstraints = date => {
      const isCurrentDate = moment().startOf('day').diff(date, 'days') === 0;
      const isAllowPastEdit = isAllowRecurringEdit && formValues.id;
      if (isCurrentDate && !isAllowPastEdit) {
        const hours = currentDateTime.get('hours');
        if (date.get('hours') < hours) {
          // if selected hours are greater then current hours,then reset hors to current
          date.set({ hours: hours });
        }
        // if selected hours are queal to current hour,check for minutes
        let minutes = 0;
        if (hours === date.get('hours')) {
          minutes = Math.ceil(currentDateTime.get('minutes') / 15) * 15;
          date.set({ minutes: minutes });
        }
      }
    };
    const handleStartDatetimeChange = starDate => {
      if(moment(starDate).isSameOrBefore(moment()))
      {
        toast.error('Invalid date time.Check time is past')
        setDateConfig({
          ...dateConfig,
          start: {
            ...dateConfig.start,
            open: false,
          },
        });
        return
      }
      let updatedStartDate = formValues['startDatetime'];

      //if (dateFormat(endTime(starDate)) === dateFormat(starDate)) {
        // update Time Constraints to restrict time picker to not select before current time
       // updateTimeConstraints(starDate);
        updatedStartDate = starDate;
     // }
      const customEventObject = {
        target: {
          name: 'startDatetime',
          value: updatedStartDate,
        },
      };
      changeHandler(customEventObject);

      // var isafter = moment(updatedStartDate).isAfter(formValues['endDatetime']);
      // console.log("isafter",isafter);
      // if (isafter) {
      //   const customEventObjectEnd = {
      //     target: {
      //       name: 'endDatetime',
      //       value: updatedStartDate,
      //     },
      //   };
      //   changeHandler(customEventObjectEnd);
      // }
      setDateConfig({
        ...dateConfig,
        start: {
          ...dateConfig.start,
          open: false,
        },
      });
    };

    let isAllowStartDateTimeChange = true;


    const dateTimeFormatString = 'YYYY-MM-DD hh:mm a';
    const startTimeChangeHandle = event => {
      const { value } = event.target;
      const newVal = formValues['startDatetime'].format('YYYY-MM-DD') + ' ' + value;
      const startDatetimeObj = moment(newVal, dateTimeFormatString);
      if (startDatetimeObj.isSameOrBefore()) {
        toast.error('invalid datetime')
        return
      }
      const minutes = Math.ceil(startDatetimeObj.get('minutes') / 15) * 15;
      startDatetimeObj.set('minutes', minutes);

      let minDiff = moment.duration(formValues.startDatetime.diff(startDatetimeObj)).asMinutes();

      let newDuration = formValues.duration + minDiff;
      if (newDuration < 15) newDuration = 15;
      const endDataTime = startDatetimeObj.clone().add(newDuration, 'minutes');
      if (isSameOrAfter(startDatetimeObj, now()) || (isAllowRecurringEdit && formValues.id)) {
        if (isSameDay(startDatetimeObj, endDataTime)) {
          const customEventObject = {
            target: {
              name: 'startDatetime',
              value: startDatetimeObj,
              duration: newDuration,
            },
          };

          changeHandler(customEventObject);
        }
      }
    };


    const changeViewMode = (startOrEnd, viewMode) => {
      const updatedDateConfigs = { ...dateConfig };
      let endConfig = { ...updatedDateConfigs.end };
      let startConfig = { ...updatedDateConfigs.start };
      if (startOrEnd === 'start') {
        endConfig.open = false;
        startConfig = {
          ...startConfig,
          viewMode,
          open: dateConfig[startOrEnd].viewMode === viewMode ? !dateConfig[startOrEnd].open : true,
        };
      } else if (startOrEnd === 'end') {
        startConfig.open = false;
        endConfig = {
          ...startConfig,
          viewMode,
          open: dateConfig[startOrEnd].viewMode === viewMode ? !dateConfig[startOrEnd].open : true,
        };
      }
      setDateConfig({
        ...dateConfig,
        start: startConfig,
        end: endConfig,
      });
    };
    const closeDatePicker = e => {
      setDateConfig({
        ...dateConfig,
        start: {
          ...dateConfig.start,
          open: false,
        },
        end: {
          ...dateConfig.end,
          open: false,
        },
      });
    };

    const chechIsDateAfterOrToday = (currentDatePickerDate, selectedDate) => {
      if (getHoliday(dateFormat(currentDatePickerDate))) {
        return false;
      }
      if (isAllowRecurringEdit && formValues.id) {
        return true;
      }
      const startOfCurrentDay = startOf('day', currentDateTime.clone());
      return isSameOrAfter(currentDatePickerDate, startOfCurrentDay);
      // return true;
    };

    const crossIconComponent = (
      <span className="cross-icon" onClick={closeModal}>
        <img src={images.close} alt="" />
      </span>
    );


    const dialogHeader = (
      <div className="dailog-header">
        <div className="heading1">Reschedule Appointment
          <TimeZoneLabel />
        </div>

        {crossIconComponent}

      </div>
    );

    // if (!practiceLocationList) {
    //   return null;
    // }

    const getStartMinTime = () => {
      // set limit to start time only if availibility is not recurring and its not on today
      if (!isAllowRecurringEdit && isSameDay(formValues['startDatetime'])) {
        return currentDateTime.format('hh:mm a');
      } else {
        return null;
      }
    };

    return (
      <div className="ProviderReschedule no-select">
        {(dateConfig.start.open || dateConfig.end.open) && (
          <div className="datepicker-overlay" onClick={closeDatePicker}></div>
        )}
        {dialogHeader}
        <div className="dailog-body">
          <form>
            <div style={{ minHeight: "330px" }}>
              <div className="location" style={{ marginBottom: "30px" }}>
                <span className="icon">
                  <img src={images.markerIcon} alt="" />
                </span>
                <b style={{ paddingTop: "9px", display: "block" }}>{appointmentDetail.providerAvailability.practiceLocation.address}</b>
              </div>
              
              <div className="select-date-content">
                <span className="icon">
                  <img src={images.clockIcon} alt="" />
                </span>
                <div className="select-picker-content">
                  <span
                    className={`time-padding ${dateConfig.start.open && dateConfig.start.viewMode === 'days' ? 'gray-box' : ''
                      }`}
                      style={{cursor:"pointer"}}
                    onClick={() => isAllowStartDateTimeChange && changeViewMode('start', 'days')}>
                    <span className="small-label">Change Date</span>
                    <span className="gray-back"></span>
                    <u>{bigDateFormat(formValues['startDatetime'], 'MMM DD, YYYY')}</u>
                  </span>
                  <div className={`Datepicker-content ${dateConfig.start.viewMode}`}>
                    <MyDatetime
                      isValidDate={chechIsDateAfterOrToday}
                      value={formValues['startDatetime']}
                      onChange={handleStartDatetimeChange}
                      onViewModeChange={viewMode => changeViewMode('start', viewMode)}
                      {...dateConfig.start}
                    />
                  </div>
                </div>
                <b className="select-time">
                  <span>
                    <span className="small-label">Change Time</span>

                    <TimeManual
                      minTime={getStartMinTime()}
                      step={15}
                      value={formValues['startDatetime'].format('hh:mm a')}
                      name="startDatetime"
                      disabled={!isAllowStartDateTimeChange}
                      onChange={startTimeChangeHandle}
                    />

                  </span>

                </b>

              </div>
              <div className="select-date-content" style={{ zIndex: 1 }}>
                <span className="icon">
                  <img src={images.clockIcon} alt="" />
                </span>
                <div className="select-picker-content" >
                  <span
                    className={`time-padding`}
                  >
                    <span className="small-label">Previous Date time</span>
                    <span className="gray-back"></span>
                    {bigDateFormat(appointmentStartDateTime, 'dddd, MMM DD \\at hh:mm a')}
                  </span>
                </div>
              </div>
              {dateTimeFormat(formValues['startDatetime'], 'YYYY-MM-DD HH:mm a') !== dateTimeFormat(appointmentStartDateTime, 'YYYY-MM-DD HH:mm a') && (
                <div >

                  <div className="location" style={{ marginTop: "30px", marginBottom: "60px" }}>

                    <b style={{ fontSize: "20px" }}>You are about to reschedule an appointment with {`${patient.firstName} ${patient.lastName}`} to {bigDateFormat(formValues['startDatetime'], 'dddd, MMM DD \\at hh:mm a')}.  You are responsible for ensuring that the patient is available at this time. </b>
                  </div>
                </div>

              )}
              {/* <label className="virtual-field">
            <Checkbox {...getGeneralCheckBoxProps('isInPerson')}>In Person</Checkbox>
          </label>
          <label className="virtual-field">
            <Checkbox {...getGeneralCheckBoxProps('isVirtual')}>Virtual</Checkbox>
          </label> */}
            </div>
            <div className="action-btn">
              {formErrors['serverError'] && (
                <span className="error">{formErrors['serverError']}</span>
              )}
              <CircleButton onClick={closeModal} btnType="secondaryButton">
                Cancel
              </CircleButton>
              <CircleButton onClick={handleForm} disabled={!isValidForm} btnType="whiteButton">
                Confirm
              </CircleButton>
            </div>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </form>
        </div>
      </div>
    );
  }
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: Warning,
        modalWrapperClass: 'mega-modal',
        customComponentProps: { resolve: resolve, reject: reject, text: props.text },
      }),
    );
  });
};

export default ProviderReschedule;
