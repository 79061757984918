import React, {Component} from 'react';
import _ from 'lodash';
import {momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import Layout from 'layout/AfterAuth';
import RootContext from 'context/RootContext';
import SubHeader from 'components/AfterAuth/SubHeader';
import scheduleService from 'services/schedule';
import constants from 'data/constants';
import modalConfig from 'modals/modalConfig';
import AddAvailability from 'components/AddAvailability';
import AddAvailabilityBreak from 'components/AddAvailabilityBreak';
import MyCalendar from 'components/MyCalendar';
import CalendarToolbar from 'components/MyCalendar/CalendarToolbar';
import CalendarEvent from 'components/MyCalendar/CalendarEvent';
import CalendarDayHeader from 'components/MyCalendar/CalendarDayHeader';
import momentHelper from 'data/moment/momentHelper';
import masterService from 'services/master';
import ScheduleActivity from 'components/AfterAuth/Schedule/ScheduleActivity';
import CalendarAppointmentEvent from 'components/MyCalendar/CalendarAppointmentEvent';
import CalendarHolidayEvent from 'components/MyCalendar/CalendarHolidayEvent';
import AppointmentDetail from 'components/AfterAuth/Schedule/AppointmentDetail';
import helper from 'data/helper';
import holidays from 'data/holidays';
import contextHelper from 'context/contextHelper';
import './Schedule.css';
import ConnectGoogleCalender from 'components/AfterAuth/ConnectGoogleCalender';
const {getHoliday} = holidays;
const {modalFunctions} = modalConfig;
const {getOffset} = helper;
const {blankModalData, errorModalData} = modalFunctions;
const {getAllAvailabilities, saveAvailability, addAvailabilityBreak, getAllAppointments} =
  scheduleService;
const {getAllMyPracticeLocationList} = masterService;
const {
  startOf,
  dateFormat,
  timeFormat,
  utcToLocalMoment,
  localToUtcMoment,
  utcToLocalDateFormat,
  dateTimeFormat,
  isSame,
  isSameOrAfter,
  isSameOrBefore,
  isAfter,
  duration,
  isBetweenDates,
  convertToSpecificTimezone,
} = momentHelper;
export const currentTimezone = moment.tz.guess();
/**
 * This will create a 'moment' object that *is* moment.tz(), and automatically use the
 * 'timezone' used when you called 'getMoment()'
 */
export const getMoment = (timezone = currentTimezone) => {
  const m = (...args) => moment.tz(...args, timezone);
  m.localeData = moment.localeData;
  return m;
};
class Schedule extends Component {
  static contextType = RootContext;
  state = {
    currentWeekStartDate: startOf('week'),
    availabilityArray: [],
    dateIndexAvailabilityEventsArray: [],
    appointmentTypeId: '',
    appointmentArray: [],
    appointmentConfig: {
      isShow: false,
      appointmentId: null,
    },
  };
  authContext = null;
  oldAuthContext = null;

  localizer = momentLocalizer(moment);
  appointmentDetailTopPosition = 0;

  componentDidMount() {
    const {currentWeekStartDate} = this.state;
    this.getEventsFromDate(currentWeekStartDate);
    this.getPracticeLocationList();
  }

  //  get initial data again, when viewUserId is switched.
  componentDidUpdate() {
    const newViewUserId = _.get(this.authContext, 'auth.viewUserId');
    const oldViewUserId = _.get(this.oldAuthContext, 'auth.viewUserId');
    if (newViewUserId && oldViewUserId && newViewUserId !== oldViewUserId) {
      const {currentWeekStartDate} = this.state;
      this.getEventsFromDate(currentWeekStartDate);
    }
    this.oldAuthContext = this.authContext;
  }

  refetchAppointmentEvents = async () => {
    const {currentWeekStartDate} = this.state;
    const appointmentArray = await this.getAllFormatedAppointments(currentWeekStartDate);
    this.setState({
      appointmentArray,
      appointmentConfig: {
        isShow: false,
        appointmentId: null,
      },
    });
  };
  nowDateTime = () => {
    let nowTemp = localToUtcMoment(new Date()).format();
    nowTemp = utcToLocalMoment(nowTemp);
    let nowHH = nowTemp.format('HH');
    let nowMM = nowTemp.format('mm');
    let rdata = new Date();
    rdata.setDate(nowTemp.format('DD'));
    rdata.setHours(nowHH);
    rdata.setMinutes(nowMM);
    return rdata;
  };
  handleFilterAppointmentEvents = async appointmentTypeId => {
    const {currentWeekStartDate} = this.state;
    const appointmentArray = await this.getAllFormatedAppointments(
      currentWeekStartDate,
      appointmentTypeId,
    );
    this.setState({appointmentArray, appointmentTypeId});
  };

  handleDateChange = date => {
    const newCurrentWeekStartDate = startOf('week', date);
    this.setState({currentWeekStartDate: newCurrentWeekStartDate}, () => {
      this.getEventsFromDate(newCurrentWeekStartDate);
    });
  };

  getDays = repeatDays => {
    const newArray = _.map(repeatDays, d => {
      return constants.DAYS[d];
    });
    return newArray;
  };

  getAvailabilityArray = (currentWeekStartDate, dbEvents, daysCount = 6) => {
    const availabilityArray = {};
    let startDate = currentWeekStartDate;
    for (let i = 0; i <= daysCount; i++) {
      const clonedDate = startDate.clone();
      const formatedDate = dateFormat(clonedDate.add(i, 'days'));
      availabilityArray[formatedDate] = [];
    }

    const previousDay = dateFormat(startDate.clone().subtract(1, 'days'));
    if (!availabilityArray[previousDay]) {
      availabilityArray[previousDay] = [];
    }

    const datesArray = _.keys(availabilityArray);
    const regularEvents = [];
    const dailyEvents = [];
    const weeklyCustomEvents = [];
    const refEvents = [];
    // devide events according to repeat type
    _.map(dbEvents, event => {
      if (event.refId !== null) {
        refEvents.push(event);
        return;
      }
      if (event.repeat === 'none') {
        regularEvents.push(event);
      }
      if (event.repeat === 'daily') {
        dailyEvents.push(event);
      }
      if (event.repeat.includes('CUSTOM')) {
        weeklyCustomEvents.push(event);
      }
    });
    // none
    _.map(regularEvents, dEvent => {
      const date = utcToLocalDateFormat(dEvent.startDatetime);
      _.map(availabilityArray, (value, keyDate) => {
        const mainDate = keyDate;
        if (isSame(mainDate, date)) {
          availabilityArray[mainDate].push(dEvent);
        }
      });
    });
    // daily
    _.map(dailyEvents, dEvent => {
      const date = utcToLocalDateFormat(dEvent.startDatetime);
      const eDate = utcToLocalDateFormat(dEvent.endDatetime);
      _.map(availabilityArray, (value, keyDate) => {
        const mainDate = keyDate;
        if (isSameOrAfter(mainDate, date) && isSameOrBefore(mainDate, eDate)) {
          availabilityArray[mainDate].push(dEvent);
        }
      });
    });
    // weekly
    _.map(weeklyCustomEvents, dEvent => {
      const date = utcToLocalDateFormat(dEvent.startDatetime);
      const eDate = utcToLocalDateFormat(dEvent.endDatetime);
      const repeatDays = _.split(dEvent.repeat, '-');
      repeatDays.splice(0, 1);
      const days = this.getDays(repeatDays);
      _.map(days, dIndex => {
        const dateAsIndex = datesArray[dIndex];
        if (isSameOrAfter(dateAsIndex, date) && isSameOrBefore(dateAsIndex, eDate)) {
          availabilityArray[dateAsIndex].push(dEvent);
        }
      });
    });
    // remove or modify ref events
    _.map(refEvents, refEvent => {
      const formatedRefdate = utcToLocalDateFormat(refEvent.startDatetime);
      const currentFormatedDatesEvents = availabilityArray[formatedRefdate];
      _.map(currentFormatedDatesEvents, (cfde, index) => {
        if (cfde.id === refEvent.refId) {
          if (!refEvent.makeAvailable) {
            delete availabilityArray[formatedRefdate][index];
          } else {
            availabilityArray[formatedRefdate][index] = refEvent;
          }
        }
      });
      availabilityArray[formatedRefdate] = _.compact(availabilityArray[formatedRefdate]);
    });
    return availabilityArray;
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  getPracticeLocationList = async () => {
    const {setGlobal} = this.context;
    const response = await getAllMyPracticeLocationList().catch(this.handleError);
    if (response) {
      const {data: practiceLocationList} = response;
      setGlobal('practiceLocationList', practiceLocationList);
    }
  };

  getAllFormatedAppointments = async (dateObj = null, appointmentTypeId = null) => {
    if (!dateObj) {
      dateObj = this.state.currentWeekStartDate;
    }
    if (appointmentTypeId === null) {
      appointmentTypeId = this.state.appointmentTypeId;
    }
    const startDate = dateObj.clone().subtract(1, 'days').utc().format('YYYY-MM-DD HH:mm:ss');
    const endDate = dateObj.clone().add(6, 'days').endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
    const dataObject = {
      startDate,
      endDate,
      status: appointmentTypeId,
    };
    const appointmentResponse = await getAllAppointments(dataObject);
    let updatedAppointmentList = [];
    if (appointmentResponse) {
      const {data: appointmentList} = appointmentResponse;
      _.forEach(appointmentList, appointment => {
        const updatetdAppointment = {...appointment};
        //console.log("updatetdAppointment",updatetdAppointment);
        updatetdAppointment.appointmentStartDateTime = utcToLocalMoment(
          updatetdAppointment.appointmentStartDateTime,
        );
        updatetdAppointment.appointmentEndDateTime = utcToLocalMoment(
          updatetdAppointment.appointmentEndDateTime,
        );
        // console.log("updatetdAppointment",updatetdAppointment);
        let systemTimeZone = moment.tz.guess();
        const startDateTime = moment(
          utcToLocalMoment(updatetdAppointment.appointmentStartDateTime),
        ).tz(systemTimeZone, true);
        const endDateTime = moment(utcToLocalMoment(updatetdAppointment.appointmentEndDateTime)).tz(
          systemTimeZone,
          true,
        );
        const start = startDateTime.format('YYYY-MM-DD');
        const end = endDateTime.format('YYYY-MM-DD');
        if (moment(end).isAfter(start, this.nowDateTime())) {
          const firstEndDate = new Date(startDateTime);
          firstEndDate.setHours(23, 59, 59, 1000);
          updatetdAppointment.end = firstEndDate;
        } else {
          updatetdAppointment.end = endDateTime.clone().toDate();
        }
        updatetdAppointment.start = startDateTime.clone().toDate();
        updatetdAppointment.type = 'appointment';
        //console.log("updatetdAppointment21312321",updatetdAppointment);
        updatedAppointmentList.push(updatetdAppointment);
      });
    }
    return updatedAppointmentList;
  };

  getEventsFromDate = async newDate => {
    const startDate = dateFormat(newDate.clone().subtract(1, 'days').utc());
    const endDate = dateFormat(newDate.clone().add(6, 'days').endOf('day').utc());
    const response = await getAllAvailabilities(startDate, endDate).catch(() => null);
    let calendarEvensArray = [];
    let newAvailabilityEventsArray = [];
    if (response) {
      const {data} = response;
      newAvailabilityEventsArray = this.getAvailabilityArray(newDate, data);
      _.map(newAvailabilityEventsArray, (valueArray, dateindex) => {
        // remove availability of Public Holiday and add event as holiday event in calendar
        const holidayObject = getHoliday(dateindex);
        if (holidayObject) {
          let holidayupdatedEvent = {
            type: 'holiday',
            label: holidayObject.label,
            start: moment(dateindex).clone().toDate(),
            end: moment(dateindex).clone().add(1, 'day').toDate(),
          };
          calendarEvensArray.push(holidayupdatedEvent);
        }
        //else {
        _.map(valueArray, event => {
          let updatedEvent = {...event};
          const timeIndex = timeFormat(utcToLocalMoment(updatedEvent.startDatetime));
          const dateString = dateindex + ' ' + timeIndex;
          const startTime = moment(dateString);
          const endTime = startTime.clone().add(updatedEvent.duration, 'milliseconds');
          const start = startTime.format('YYYY-MM-DD');
          const end = endTime.format('YYYY-MM-DD');
          if (moment(end).isAfter(start, this.nowDateTime())) {
            let secondAppointment = {...updatedEvent};
            const secondStartDate = new Date(endTime);
            secondStartDate.setHours(0, 0, 0, 0);
            const firstEndDate = new Date(startTime);
            firstEndDate.setHours(23, 59, 59, 1000);
            //first date
            updatedEvent.start = startTime.clone().toDate();
            updatedEvent.end = firstEndDate;
            updatedEvent.originalStartDatetime = updatedEvent.startDatetime;
            updatedEvent.startDatetime = dateTimeFormat(startTime.utc());
            updatedEvent.practiceLocationId = updatedEvent.practiceLocationId || '';
            calendarEvensArray.push(updatedEvent);
            //second date
            secondAppointment.start = secondStartDate;
            secondAppointment.end = endTime.clone().toDate();
            secondAppointment.originalStartDatetime = secondAppointment.startDatetime;
            secondAppointment.startDatetime = dateTimeFormat(startTime.utc());
            secondAppointment.practiceLocationId = secondAppointment.practiceLocationId || '';
            calendarEvensArray.push(secondAppointment);
          } else {
            updatedEvent.start = startTime.clone().toDate();
            updatedEvent.end = endTime.clone().toDate();
            updatedEvent.originalStartDatetime = updatedEvent.startDatetime;
            updatedEvent.startDatetime = dateTimeFormat(startTime.utc());
            updatedEvent.practiceLocationId = updatedEvent.practiceLocationId || '';
            calendarEvensArray.push(updatedEvent);
          }
        });
        /// }
      });
    }
    // appointment mapping
    let updatedAppointmentList = await this.getAllFormatedAppointments(newDate);
    this.setState({
      currentWeekStartDate: newDate,
      availabilityArray: calendarEvensArray,
      dateIndexAvailabilityEventsArray: newAvailabilityEventsArray,
      appointmentArray: updatedAppointmentList,
    });
  };

  handleSaveAvailability = async (data, onServerError) => {
    let newData = {...data};
    newData.duration = newData.duration * (1000 * 60);
    const response = await saveAvailability(newData).catch(error => {
      const {message} = error;
      if (onServerError) {
        onServerError(message);
      }
      return null;
    });
    if (response) {
      const {setGlobal} = this.context;
      setGlobal('modal', {showModal: false});
      // const {data} = response;
      const {currentWeekStartDate} = this.state;
      this.getEventsFromDate(currentWeekStartDate);
    }
  };

  handleAddBreak = async (data, onServerError) => {
    let newData = {...data};
    const response = await addAvailabilityBreak(newData).catch(error => {
      const {message} = error;
      if (onServerError) {
        onServerError(message);
      }
      return null;
    });
    if (response) {
      const {setGlobal} = this.context;
      setGlobal('modal', {showModal: false});
      // const {data} = response;
      const {currentWeekStartDate} = this.state;
      this.getEventsFromDate(currentWeekStartDate);
    }
  };

  onSlotSelect = slotData => {
    const {action, start, end} = slotData;

    if (['select', 'click'].includes(action)) {
      // if selected slot time is after today or the day selected is not holiday then only allow add Availability
      //&& !getHoliday(dateFormat(start))
      if (isAfter(start, this.nowDateTime())) {
        const {setGlobal} = this.context;
        const durationInMinutes = duration(end, start, 'minutes');
        const newEventData = {
          startDatetime: moment(start),
          duration: durationInMinutes < 15 ? 15 : durationInMinutes,
          makeAvailable: true,
          refId: null,
          repeat: 'none',
          isVirtual: false,
          isInPerson: true,
          isAllowRecurringEdit: true,
          endDatetime: moment(start),
        };
        setGlobal(
          'modal',
          blankModalData({
            CustomComponent: AddAvailability,
            closeOnBGClick: false,
            customComponentProps: {data: newEventData, onSave: this.handleSaveAvailability},
          }),
        );
      }
    }
  };
  m;

  onEventSelect = (event, isAllowRecurringEdit = false) => {
    const {setGlobal} = this.context;

    let newData = {...event, isAllowRecurringEdit};

    if (
      isAfter(newData.start, this.nowDateTime()) ||
      isBetweenDates(newData.start, newData.end, this.nowDateTime())
    ) {
      if (event) {
        if (isAllowRecurringEdit) {
          newData.startDatetime = moment(utcToLocalMoment(newData.originalStartDatetime)).tz(
            currentTimezone,
            true,
          );
          newData.endDatetime = moment(utcToLocalMoment(newData.endDatetime)).tz(
            currentTimezone,
            true,
          );
        } else {
          //newData.startDatetime = utcToLocalMoment(newData.startDatetime);
          //newData.endDatetime = utcToLocalMoment(newData.startDatetime);

          newData.startDatetime = moment(newData.start).tz(currentTimezone, true);

          newData.endDatetime = moment(newData.end).tz(currentTimezone, true);
        }
        newData.deleteDatetime = utcToLocalMoment(newData.startDatetime);
        newData.duration = newData.duration / (1000 * 60);
      } else {
        newData = {};
      }

      setGlobal(
        'modal',
        blankModalData({
          CustomComponent: AddAvailability,
          closeOnBGClick: false,
          customComponentProps: {
            data: newData,
            onSave: this.handleSaveAvailability,
          },
        }),
      );
    }
  };

  onAddBreak = (event, isAllowRecurringEdit = false) => {
    const {setGlobal} = this.context;
    let newData = {...event, isAllowRecurringEdit};
    if (
      isAfter(newData.start, this.nowDateTime()) ||
      isBetweenDates(newData.start, newData.end, this.nowDateTime())
    ) {
      if (event) {
        if (isAllowRecurringEdit) {
          newData.startDatetime = utcToLocalMoment(newData.originalStartDatetime);
        } else {
          newData.startDatetime = utcToLocalMoment(newData.startDatetime);
        }
        newData.deleteDatetime = utcToLocalMoment(newData.startDatetime);
        newData.duration = newData.duration / (1000 * 60);
      } else {
        newData = {};
      }
      setGlobal(
        'modal',
        blankModalData({
          CustomComponent: AddAvailabilityBreak,
          customComponentProps: {
            data: newData,
            onSave: this.handleAddBreak,
          },
        }),
      );
    }
  };

  //const dateInNY = moment.tz(dateTimeFormat("2000-10-01 00:00:00"), "M/D/YYYY h:mm a",  "America/New_York");

  handleAppointmentSelect = (appointmentId, e) => {
    let elem = e.currentTarget;
    const clickedDivHeight = elem.offsetHeight;
    const top = getOffset(elem).top;
    this.setState(
      {
        appointmentConfig: {
          isShow: true,
          appointmentId,
        },
      },
      () => {
        // canculate top position based on appointment selection on calendar
        const appointmentDetailDivs = document.getElementsByClassName('AppointmentDetail');
        let appointmentDetailDivHeight = 0;
        if (appointmentDetailDivs.length > 0) {
          appointmentDetailDivHeight = appointmentDetailDivs[0].offsetHeight;
        }
        let y = top - appointmentDetailDivHeight;
        if (y < 0) {
          y = top + clickedDivHeight;
        }
        this.appointmentDetailTopPosition = y;
        const appointmentDetailContainer = document.getElementById('appointment-detail-container');
        if (appointmentDetailContainer) {
          appointmentDetailContainer.style.top = y + 'px';
        }
      },
    );
  };

  handleCloseAppointment = () => {
    this.setState({
      appointmentConfig: {
        isShow: false,
        topPosition: 0,
        appointmentId: null,
      },
    });
  };

  handleNavigate = (start, view, action) => {
    if (['NEXT', 'PREV'].includes(action)) {
      const currentActiveStartDate = this.localizer.format(start, 'YYYY-MM-DD');
      this.getEventsFromDate(moment(currentActiveStartDate));
    }
    return false;
  };

  timeRangeFormat = () => {
    return '';
  };

  onSelecting = slotData => {
    const {start} = slotData;
    //  console.log("start",start);
    let ss = convertToSpecificTimezone(start).format();
    return isAfter(ss, this.nowDateTime()); //&& !getHoliday(dateFormat(start));
  };

  render() {
    const scrollToTime = moment().set({hour: 7, minute: 0, second: 0, millisecond: 0}).toDate();
    //  const getTimeAsDate = (hour, tzMoment) => {
    //   if (hour < 0 || hour > 23) {
    //     throw Error(
    //       `*${hour}* is an invalid 'hour' value, which must be between 0 and 23.`
    //     );
    //   }
    //   const m = tzMoment('1970-01-01');
    //     let mm= m
    //     .hour(hour)
    //     .minute(0).toDate()
    //     console.log("mm",mm);
    //     return mm
    //    return new Date(
    //     m
    //     .hour(hour)
    //     .minute(0)
    //     .format()
    //    );
    // };
    let viewUser = null;
    if (this.authContext) {
      const {getViewUser} = this.authContext;
      viewUser = getViewUser();
    }
    const {
      currentWeekStartDate,
      availabilityArray,
      appointmentArray,
      appointmentConfig,
      appointmentTypeId,
    } = this.state;
    let appointDetailComponent = null;
    if (appointmentConfig.isShow) {
      const appointmentDetail = _.find(appointmentArray, {id: appointmentConfig.appointmentId});
      if (appointmentDetail) {
        appointDetailComponent = (
          <div
            className="monthly-activity-content"
            id="appointment-detail-container"
            style={{top: this.appointmentDetailTopPosition}}>
            <span className="overlay" onClick={this.handleCloseAppointment}></span>
            <AppointmentDetail
              appointmentDetail={appointmentDetail}
              refetchAppointmentEvents={this.refetchAppointmentEvents}
              viewUser={viewUser}
            />
          </div>
        );
      }
    }
    const uniqueWeeklyAppointment = _.map(_.uniqBy(appointmentArray, 'id')).filter(
      appointment =>
        moment(appointment.appointmentStartDateTime).isAfter(
          startOf('week', currentWeekStartDate),
        ) && appointment.patient.email !== 'google_calendar@kaly.com',
    );

    let nowTemp = localToUtcMoment(new Date()).format();
    nowTemp = utcToLocalMoment(nowTemp);
    let nowHH = nowTemp.format('HH');
    let nowMM = nowTemp.format('mm');

    //const dateInNY = moment.tz(dateTimeFormat("2000-10-01 00:00:00"), "M/D/YYYY h:mm a",  "America/New_York");
    const now = () => {
      let rdata = new Date();
      rdata.setDate(nowTemp.format('DD'));
      rdata.setHours(nowHH);
      rdata.setMinutes(nowMM);
      return rdata;
    };

    const getNow = (now, tzMoment) => convertDateTimeToDate(now(), tzMoment);
    const convertDateTimeToDate = (datetime, tzMoment) => {
      return new Date(tzMoment(datetime).format()); // sets Date using ISO 8601 format
    };

    return (
      <Layout>
        {contextHelper.AuthContextConsumer(this)}
        <div className="AfterAuthSchedule">
          <div className="AuthSchedulegoogleparent">
            <SubHeader />
            <ConnectGoogleCalender />
          </div>
          <div className="AfterAuthSchedule-innercontent">
            <ScheduleActivity
              appointments={uniqueWeeklyAppointment}
              appointmentTypeId={appointmentTypeId}
              onAppointmentTypeChange={this.handleFilterAppointmentEvents}
            />
            <div className="box-content calender-box">
              {appointDetailComponent}
              <MyCalendar
                formats={{
                  eventTimeRangeFormat: this.timeRangeFormat,
                }}
                onNavigate={this.handleNavigate}
                popup={true}
                toolbar={true}
                localizer={this.localizer}
                selectable="ignoreEvents"
                components={{
                  timeGutterHeader: () => <p className="all-day-text1"></p>,
                  toolbar: props => (
                    <CalendarToolbar
                      {...props}
                      currentWeekStartDate={currentWeekStartDate}
                      onDateChange={this.handleDateChange}
                    />
                  ),
                  week: {
                    eventWrapper: props => {
                      let calendarEventComponent = null;
                      const eventType = _.get(props, 'event.type');
                      if (eventType === 'appointment') {
                        // if appointment is calceled then don't show them in calendar
                        if (props.event.status !== 2) {
                          calendarEventComponent = (
                            <CalendarAppointmentEvent
                              {...props}
                              onAppointmentSelect={this.handleAppointmentSelect}
                            />
                          );
                        }
                      } else if (eventType === 'holiday') {
                        calendarEventComponent = <CalendarHolidayEvent {...props} />;
                      } else {
                        calendarEventComponent = (
                          <CalendarEvent
                            {...props}
                            onEventSelect={this.onEventSelect}
                            onAddBreak={this.onAddBreak}
                          />
                        );
                      }
                      return calendarEventComponent;
                    },
                  },
                  header: CalendarDayHeader,
                }}
                onSelecting={this.onSelecting}
                // onSelectEvent={this.onEventSelect}
                onSelectSlot={this.onSlotSelect}
                longPressThreshold={1}
                events={[...availabilityArray, ...appointmentArray]}
                timeslots={4}
                step={15}
                views={['week']}
                defaultView="week"
                date={currentWeekStartDate.toDate()}
                scrollToTime={scrollToTime}
                tooltipAccessor={null}
                //getNow={()=>utcToLocalMoment(new Date(2021,10,18,16,15))}
                // getNow={()=>utcToLocalMoment(new Date())}
                getNow={() => getNow(now, moment)}
                //min={moment().startOf('day').toDate()}
                //max={moment().endOf('day').toDate()}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Schedule;
