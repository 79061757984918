import React, {useContext} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';

const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;

const AuthCard = props => {
  const stripe = useStripe();
  const rootContext = useContext(RootContext);
  const {paymentMethodId, authData: authCard, loadInvoice} = props;
  const {setGlobal} = rootContext;

  const openAuthCardModal = async () => {
    if (authCard && authCard.status === 3 && authCard.clientSecret) {
      await stripe
        .confirmCardPayment(authCard.clientSecret, {
          payment_method: paymentMethodId,
        })
        .then(function (result) {
          // Handle result.error or result.paymentIntent
          if (result.paymentIntent) {
            loadInvoice();
            setGlobal('modal', successModalData('Authentication completed successfully.'));
          } else {
            setGlobal('modal', errorModalData('Authentication Failed.'));
          }
        });
    }
  };
  return <button onClick={openAuthCardModal}>3D Secure</button>;
};

export default AuthCard;
