import React, {Component} from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
//import adminService from 'services/admin';
import masterService from 'services/master';
import faqService from 'services/faq';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import images from 'data/images';
import constants from 'data/constants';
import EditFaq from './EditFaq';
import _ from 'lodash';
import './ManageFaq.css';
import Select from 'components/FormControls/Select';

const initialState = {
  fromDbList: [],
  list: [],
  mainList: [],
  specialtyList: [],
  activePage: 1,
  totalPage: 0,
  searchText: '',
  searchSpecialty: '',
  pageLimit: 10,
  sortBy: 'plan',
  orderBy: 'asc',
  totalRecord: 0,
};
const {modalFunctions} = modalConfig;
const {errorModalData, blankModalData, successModalData, confirmModalData} = modalFunctions;
const {getAllFaq, updateFaq} = faqService;
//InsurancePlanStatus
const {DefaultDebounceMSec} = constants;
const {getAllSpecialties} = masterService;
class ManageFaq extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount() {
    this.getAllFaqList(false);
    this.getAllSpecialties();
  }

  getAllFaqList = async (noLoad = false) => {
    //const {fromDbList} = this.state;
    const res = await getAllFaq();
    // {
    //   page: activePage,
    //   limit: pageLimit,
    //   search: searchText,
    //   sortBy: sortBy,
    //   orderBy: orderBy,
    //   insuranceCompanyId: searchCompanyId,
    // },
    // noLoad,
    let resData = _.get(res, 'data', []);

    resData = resData
      .map(f => {
        return {id: f.id, faqs: JSON.parse(f.faqs), specialty: f.specialty};
      })
      .filter(q => q.faqs !== null);
    let mainList = [];
    resData.map(f => {
      f.faqs.map((q, subIndex) => {
        mainList.push({id: f.id, specialty: f.specialty, subIndex: subIndex, ...q});
        return q;
      });
      return f;
    });
    // let totalArray=resData.map((f)=>f.faqs)
    // var merged = [].concat.apply([], totalArray);

    //  resData=resData.map((faq,i,h)=>[...h,...faq])

    this.setState({mainList: mainList, fromDbList: resData}, this.handleSearchLogic);
    // this.setState({
    //   list:mainList.slice((activePage-1)*10,10),
    //   mainList:mainList,
    //   totalPage:Math.ceil(mainList.length/10),
    //   totalRecord:mainList.length,
    // });
  };
  updateSubmit = async (subIndex, reqObj) => {
    let {fromDbList} = this.state;
    let selectedObj = fromDbList.find(d => d.id === reqObj.speciality);
    if (selectedObj && Object.keys(selectedObj).length > 0) {
      let {faqs} = selectedObj;
      faqs[subIndex] = {answer: reqObj.faqAnswer, question: reqObj.faqQuestion};
      let response = await updateFaq({
        speciality: reqObj.speciality,
        faq: JSON.stringify(faqs),
      }).catch(this.handleError);
      if (response) {
        const {message} = response;
        const {setGlobal} = this.context;
        setGlobal('modal', successModalData(message));
        this.getAllFaqList();
      }
    }
  };
  getAllSpecialties = async () => {
    const res = await getAllSpecialties(false);
    this.setState({specialtyList: _.get(res, 'data', [])});
  };

  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.handleSearchLogic);
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };

  openEditModal = data => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: EditFaq,
        modalWrapperClass: 'faq-xl',
        customComponentProps: {
          data,
          updateList: this.getAllFaqList,
          specialtyList: this.state.specialtyList,
          updateSubmit: this.updateSubmit,
        },
      }),
    );
  };

  confirmDelete = data => {
    
    let {subIndex: index, id: Spaciality} = data;
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete this FAQ?`,
        onConfirm: async isConfirmed => {
          if (isConfirmed) {
            let {fromDbList} = this.state;
            let selectedObj = fromDbList.find(d => d.id === Spaciality);
            setGlobal('modal', {showModal: false});
            if (selectedObj && Object.keys(selectedObj).length > 0) {
              let {faqs} = selectedObj;
              faqs.splice(index, 1);
              let response = await updateFaq({
                speciality: Spaciality,
                faq: JSON.stringify(faqs),
              }).catch(this.handleError);
              if (response) {
                setGlobal('modal', successModalData('Faq deleted successfully'));
                this.getAllFaqList();
              }
            }
          }
        },
      }),
    );
  };

  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.handleSearchLogic();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };

  handleSearch = () => {
    this.setState({activePage: 1}, this.handleSearchLogic);
  };

  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.handleSearchLogic);
  };
  handleSearchLogic = () => {
    let {mainList, searchSpecialty, searchText, activePage} = this.state;
    if (searchText) searchText = searchText.toLowerCase();
    let filteredList = [];
    if (searchSpecialty && searchText) {
      filteredList = mainList.filter(
        f =>
          f.id === searchSpecialty &&
          (f.specialty.toLowerCase().includes(searchText) ||
            f.answer.toLowerCase().includes(searchText) ||
            f.question.toLowerCase().includes(searchText)),
      );
    } else if (searchText) {
      filteredList = mainList.filter(
        f =>
          f.specialty.toLowerCase().includes(searchText) ||
          f.answer.toLowerCase().includes(searchText) ||
          f.question.toLowerCase().includes(searchText),
      );
    } else if (searchSpecialty) {
      filteredList = mainList.filter(f => f.id === searchSpecialty);
    } else {
      filteredList = mainList;
    }
    let totalpage = Math.ceil(filteredList.length / 10);
    activePage = activePage > totalpage ? activePage - 1 : activePage;

    this.setState({
      totalPage: totalpage,
      activePage: activePage,
      totalRecord: filteredList.length,
      list: filteredList.slice((activePage - 1) * 10, activePage * 10),
    });
  };
  handleCompanySearch = event => {
    this.setState({activePage: 1, searchSpecialty: event.target.value}, this.handleSearchLogic);
    //   let {mainList}=this.state
    // if(event.target.value){
    //   let filteredList=mainList.filter((f)=>f.id===event.target.value)
    //   this.setState({activePage: 1,searchSpecialty:event.target.value,totalPage:Math.ceil(filteredList.length/10),
    //     totalRecord:filteredList.length,list:filteredList.slice(0,10)});
    // }
    //   else{
    //     this.setState({activePage: 1,searchSpecialty:event.target.value,totalPage:Math.ceil(mainList.length/10),
    //       totalRecord:mainList.length,list:mainList.slice(0,10)});

    //   }
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'companyName');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.setInsurancePlanList,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.setInsurancePlanList);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  render() {
    const {list, specialtyList, totalPage, searchText, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    const title = 'Manage Faq';
    return (
      <Layout>
        <div className="ManageFaq">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div>
                <Select
                  options={specialtyList}
                  labelIndex="specialty"
                  valueIndex="id"
                  name="companyId"
                  defaultOptionProp={{value: '', label: 'All Specialtys'}}
                  isAllowDefaultOpen={true}
                  onChange={this.handleCompanySearch}
                />
              </div>
              <div className="input-control">
                <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
                {searchText && (
                  <button className="btn-clear" onClick={this.handleClearSearch}>
                    <img src={images.close} alt="clear" />
                  </button>
                )}
              </div>
              <button onClick={this.handleSearch}>Search</button>
            </div>
            <button className="btn-add-new" onClick={() => this.openEditModal({})}>
              Add New FAQ
            </button>
            <div className="dataCount">
              <span>{totalRecord} faq found.</span>
            </div>
            <div className="table-overflow">
              <div className="rtable">
                <div className="heading">
                  <div className="cell pointer" data-key="plan" onClick={this.handleSortingChange}>
                    Spaciality{this.renderShortIcon('plan')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="companyName"
                    onClick={this.handleSortingChange}>
                    Question
                  </div>
                  <div
                    className="cell pointer"
                    data-key="companyName"
                    onClick={this.handleSortingChange}>
                    Answer
                  </div>

                  <div className="cell edit-cell">Action</div>
                </div>
                {list.map((item, index) => (
                  <div className="row" key={`eachQ${index}`}>
                    <div className="cell">{item.specialty}</div>
                    <div className="cell">{item.question}</div>
                    <div className="cell">
                      <span dangerouslySetInnerHTML={{__html: item.answer}}></span>
                    </div>

                    <div className="cell edit-cell">
                      <img
                        onClick={() => this.openEditModal(item)}
                        src={images.editPen}
                        className="edit-icon"
                        alt="editPen"
                      />
                      <img
                        onClick={() => this.confirmDelete(item)}
                        src={images.deleteRound}
                        className="delete-icon"
                        alt="delete"
                      />
                    </div>
                  </div>
                ))}
                {/* {_.map(list, (item, index) => (
                  <div className="row" key={index}>
                    <div className="cell">{_.get(item, 'plan', '')}</div>
                    <div className="cell">{_.get(item, 'companyName', '')}</div>
                    <div className="cell">{InsurancePlanStatus[_.get(item, 'status', '')]}</div>
                    <div className="cell">
                      {item.updatedAt && this.formatedDate(item.updatedAt)}
                    </div>
                    <div className="cell edit-cell">
                      <img
                        onClick={() => this.openEditModal(item)}
                        src={images.editPen}
                        className="edit-icon"
                        alt="editPen"
                      />
                    </div>
                  </div>
                ))} */}
              </div>
            </div>
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default ManageFaq;
