import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './MyDatetime.css';

const MyDatetime = props => {
  return <Datetime className={`MyDatetime ${props.viewMode}`} {...props} />;
};

export default MyDatetime;
