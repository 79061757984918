import React, {useState, useCallback, useEffect, useContext} from 'react';
import Input from 'components/FormControls/Input';

import CircleButton from 'components/CircleButton';

import validators from 'data/validators';
import './ProviderSeoLink.css';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import allRegex from 'data/regex';


const {urlSlugRegex} = allRegex;
const {__required, __maxLength, __isValidUrl, __isNumeric,_scrollToView} = validators;

const {modalFunctions} = modalConfig;
const {confirmModalData, errorModalData} = modalFunctions;
const ProviderSeoLink = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const {seoPage, onFormSubmit, hasPermissionToEdit} = props;
  // const websiteErrors = useCallback(() => {
  //   return data.websites.map(website => false);
  // }, [data.websites]);
  const [formData, setFormData] = useState({
    values: {seoPage:seoPage},
    errors: {},
  });
  const {values: formValues, errors: formErrors} = formData;

  useEffect(() => {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        values: {seoPage:seoPage},
        errors: {
          
        },
      };
    });
  }, [seoPage]);
  // Validation functions
 
  const __validateSeoPage = (value = null) => {
    const fValue = value !== null ? value : formValues['seoPage'];
    if (!__required(fValue)) {
      return 'SEO link is required';
    }
    if (!urlSlugRegex.test(fValue)) {
      return 'SEO link is not valid';
    }
    if (fValue.length>250) {
      return 'SEO link does not exceed 250 characters';
    }
    return null;
  };
  

  const __validateForm = () => {
    const validName = !__validateSeoPage();
   
    return validName 
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'seoPage':
        errorObject = {
          [name]: __validateSeoPage(value),
        };
        break;
      default:
        break;
    }
    
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };
  const handleForm = () => {
    if (!isValidForm) {
      let valError = ['seoPage'];
      valError.forEach(name => {
        changeHandler({target: {name: name, value: formValues[name]}});
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    if(formValues['seoPage'].trim()===seoPage.trim()){
      setGlobal('modal', errorModalData('No changes made to SEO link.'));
      return false;
    }
    setGlobal(
      'modal',
      confirmModalData({
        confirmTitle:`Confirm you are updating this provider's SEO Link.?`,
        confirmMessage: <div className="confirm-seolink"><div>
          <p><span>Current SEO Link:</span> <span>{seoPage}</span></p>
          <p><span>New SEO Link:</span> <span>{formValues['seoPage']}</span> </p>
          </div></div>,
        lastModalConfig: {
          ...props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
           
            onFormSubmit(formValues);
          }
        },
      }),
    );
   // 
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
      disabled: !hasPermissionToEdit,
    };
  };


  return (
    <div className="box-content EditPracticeSeoLink">
      <div className="h3">SEO Link</div>
      <div className="EditPracticeSeoLink-inner">
        {/* <form> */}
          <Input {...getGeneralInputProps('seoPage')} label="SEO Link"></Input>
         
          {hasPermissionToEdit && (
            <div style={{textAlign:"right"}}>
            <CircleButton style={{marginRight:'10px'}} onClick={()=>{changeHandler({target:{name:'seoPage',value:seoPage}})}} btnType="whiteButton">
              Reset
            </CircleButton>
            <CircleButton onClick={handleForm} btnType="whiteButton">
              Save
            </CircleButton>
            </div>
          )}
        {/* </form> */}
      </div>
    </div>
  );
};

export default ProviderSeoLink;
