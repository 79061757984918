import React from 'react';
import {Route, Switch} from 'react-router-dom';
import LandingPage from 'containers/LandingPage';
//import PricingPage from 'containers/PricingPage';
import Login from 'components/Auth/Login';
import LoginSuperAdmin from "components/Auth/Login/LoginSuperAdmin"
import ResetPassword from 'components/Auth/ResetPassword';
import NewPassword from 'components/Auth/NewPassword';
import SignUp from 'containers/SignUp';
import CommonRoutes from './CommonRoutes';
import PatientLogin from 'containers/Patient/PatientLogin';
import PatientSignUp from 'containers/Patient/Auth/PatientSignUp';
import PatientLanding from 'containers/Patient/PatientLanding';
import AdminLogin from 'components/Auth/AdminLogin';

const AuthRoutes = () => {
  // These routes will only be accessable when user is not logged in
  return (
    <Switch>
      <Route exact path={'/'} component={props => <PatientLanding {...props} />} />
      <Route exact path={'/list-your-practice'} component={props => <LandingPage {...props} />} />
      <Route exact path={'/list-your-practice-lp'} component={props => <LandingPage lp={true} {...props} />} />
      <Route exact path={'/list-your-practice-lp/#signup'} component={props => <LandingPage ss={true} lp={true} {...props} />} />
      <Route exact path={'/login'} component={props => <Login {...props} />} />
      <Route exact path={'/forgot-password'} component={props => <ResetPassword {...props} />} />
      <Route
        exact
        path={'/reset-password/:token'}
        component={props => <NewPassword {...props} />}
      />
      <Route exact path={'/sign-up'} component={props => <SignUp {...props} />} />
      <Route exact path={'/sign-up/:token'} component={props => <SignUp {...props} />} />
      {/* Patient routes */}
      <Route exact path={'/patient/login'} component={props => <PatientLogin {...props} />} />
      <Route exact path={'/patient/sign-up'} component={props => <PatientSignUp {...props} />} />
      <Route path={'/admin'} component={props => <AdminLogin {...props} />} />
      <Route exact path={'/superadmin'} component={props => <LoginSuperAdmin {...props} />} />
      <CommonRoutes />
    </Switch>
  );
};

export default AuthRoutes;
