import axiosInstance from 'config/axios';
import _ from 'lodash';

const getMyPracticeDetail = () => {
  return axiosInstance.get('v1/practice').then(formatPractice);
};

const savePracticeOverview = data => {
  return axiosInstance.put('v1/practice', data);
};

const saveLocation = data => {
  if (data.id) {
    return axiosInstance.put('v1/practice/location', data);
  } else {
    return axiosInstance.post('v1/practice/location', data);
  }
};

const deleteLocation = locationId => {
  return axiosInstance.delete(`v1/practice/location/${locationId}`);
};
const getConsultRequest = (data) => {
  return axiosInstance.post(`v1/consultRequest/list`,data);
};

// response format
const formatPractice = response => {
  const updatedResponse = {...response};
  // sort location to set latest location as first
  const sortedPracticeLocations = _.orderBy(
    updatedResponse.data.practiceLocations,
    ['id'],
    ['desc'],
  );
  return {
    ...updatedResponse,
    data: {
      ...updatedResponse.data,
      practiceLocations: sortedPracticeLocations,
    },
  };
};
const referredPractice=data=>{
  return axiosInstance.post('v1/referredPractice/create',data)
}
const consultRequestUpdate=(data,requestId)=>{
  return axiosInstance.put(`v1/consultRequest/${requestId}`, data);
}

export default {
  getMyPracticeDetail,
  savePracticeOverview,
  saveLocation,
  deleteLocation,
  referredPractice,
  getConsultRequest,
  consultRequestUpdate
};
