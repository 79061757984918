import React, { Component } from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import selectOptions from 'data/selectOptions';
import _ from 'lodash';
import './Practices.css';
import images from 'data/images';
import constants from 'data/constants';

const { DefaultDebounceMSec } = constants;
const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  searchText: '',
  pageLimit: 10,
  sortBy: 'name',
  orderBy: 'asc',
  totalRecord: 0,
};
const { modalFunctions } = modalConfig;
const { errorModalData, blankModalData } = modalFunctions;
const { getAllPractices, exportPracticeAsCsv } = adminService;
const { practiceSoftwareOptions } = selectOptions;

class Practices extends Component {
  static contextType = RootContext;
  state = { ...initialState };

  componentDidMount() {
    this.setPracticesList(false, true);
  }

  componentWillMount = () => {
    this.props.history.replace({ state: { ...initialState } });
  };

  setPracticesList = async (noLoad = false, findSearchData = false) => {
    const { location } = this.props;
    const isBackLink = _.get(location, 'state.isBackLink', false);
    if (isBackLink) {
      this.getSearchedData(location);
    } else {
      const { activePage, pageLimit, searchText, sortBy, orderBy } = this.state;
      const res = await getAllPractices(
        {
          page: activePage,
          limit: pageLimit,
          search: searchText,
          sortBy: sortBy,
          orderBy: orderBy,
        },
        noLoad,
      );
      this.setState({
        list: _.get(res, 'data.list', []),
        totalPage: _.get(res, 'data.totalPage', 0),
        totalRecord: _.get(res, 'data.totalRecord', 0),
      });
    }
  };

  getSearchedData = location => {
    const searchData = _.get(location, 'state', {
      activePage: 1,
      totalPage: 0,
      searchText: '',
      pageLimit: 10,
      sortBy: 'name',
      orderBy: 'asc',
      totalRecord: 0,
    });
    const {
      activePage,
      pageLimit,
      searchText,
      sortBy,
      orderBy,
      list,
      totalPage,
      totalRecord,
    } = searchData;
    this.setState({
      list: list,
      totalPage: totalPage,
      activePage: activePage,
      pageLimit: pageLimit,
      searchText: searchText,
      sortBy: sortBy,
      orderBy: orderBy,
      totalRecord: totalRecord,
    });
  };

  handlePageChange = data => {
    this.setState({ activePage: data.selected + 1 }, this.setPracticesList);
  };
  handleChange = event => {
    this.setState({
      searchText: event.target.value,
    });
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.setState({ activePage: 1 }, this.setPracticesList);
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };
  handleSearch = () => {
    this.setState({ activePage: 1 }, this.setPracticesList);
  };
  handleClearSearch = () => {
    this.setState({ activePage: 1, searchText: '' }, this.setPracticesList);
  };

  handleError = error => {
    const { setGlobal } = this.context;
    const { message } = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };

  handleSortingChange = e => {
    e.preventDefault();
    const { sortBy, orderBy } = this.state;
    const { dataset } = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        { orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1 },
        this.setPracticesList,
      );
    } else {
      this.setState({ orderBy: 'asc', sortBy: key, activePage: 1 }, this.setPracticesList);
    }
  };

  renderShortIcon = key => {
    const { sortBy, orderBy } = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  getWebsiteDetails = wesiteArr => {
    return _.map(wesiteArr, website => {
      return (
        <a key={website} href={website} target="_blank" className="practice-website">
          {website}
        </a>
      );
    });
  };

  getSoftwareDetails = idSoftware => {
    let softwareName = '';
    const selectedSoft = practiceSoftwareOptions.filter(software => software.value == idSoftware);
    if (selectedSoft && selectedSoft.length > 0) {
      softwareName = _.get(selectedSoft[0], 'label', '');
    }
    return softwareName;
  };

  ShowViewProvider = practiceName => {
    const params = new URLSearchParams();
    if (practiceName) {
      params.append('practice', practiceName);
    }
    this.props.history.push({
      pathname: '/admin/providers',
      search: `?${params.toString()}`,
    });
  };

  EditPracticeDetails = id => {
    const params = new URLSearchParams();
    if (id) {
      params.append('practice', id);
    }
    this.props.history.push({
      pathname: '/admin/practice-edit',
      search: `?${params.toString()}`,
      state: { ...this.state, isBackLink: true },
    });
  };
  exportPractice = async (id, fileName) => {
    let file = await exportPracticeAsCsv(id)
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = fileName
    link.href = URL.createObjectURL(
      new Blob([file], { type: "text/csv" })
    );
    link.click();
  }
  render() {
    const { list, totalPage, searchText, activePage, totalRecord } = this.state;
    const activeIndex = activePage - 1;
    const title = 'Practices';
    return (
      <Layout>
        <div className="Practices">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div>
                <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
                {searchText && (
                  <button className="btn-clear" onClick={this.handleClearSearch}>
                    <img src={images.close} alt="clear" />
                  </button>
                )}
              </div>
              <button onClick={this.handleSearch}>Search</button>
            </div>
            <div className="dataCount">
              <span>{totalRecord} practices found.</span>
            </div>
            <div className="table-overflow">
              <div className="rtable">
                <div className="heading">
                  <div className="cell pointer" data-key="name" onClick={this.handleSortingChange}>
                    Practice Name{this.renderShortIcon('name')}
                  </div>
                  <div className="cell pointer" onClick={this.handleSortingChange}>
                    Export
                  </div>
                  <div
                    className="cell pointer"
                    data-key="websites"
                    onClick={this.handleSortingChange}>
                    Websites{this.renderShortIcon('websites')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="software"
                    onClick={this.handleSortingChange}>
                    Software{this.renderShortIcon('software')}
                  </div>
                  <div
                    className="cell pointer cell-center"
                    data-key="seats"
                    onClick={this.handleSortingChange}>
                    Seats{this.renderShortIcon('seats')}
                  </div>
                  <div
                    className="cell pointer cell-center"
                    data-key="seats"
                    onClick={this.handleSortingChange}>
                    Total Provider{this.renderShortIcon('totalProvider')}
                  </div>
                  <div className="cell edit-cell">Action</div>
                </div>
                {_.map(list, (item, index) => (
                  <div className="row" key={index}>
                    <div className="cell">
                      {item && item.seoPageUrl && (
                        <span
                          className="link-span"
                          onClick={() => window.open(item.seoPageUrl, '_blank')}>
                          {`${item.name}`}
                        </span>
                      )}
                      {item && !item.seoPageUrl && `${item.name}`}
                    </div>
                    <div className="cell">
                      <button
                        className="btn-edit"
                        onClick={() => this.exportPractice(item.id, item.name)}>
                        Export
                      </button>
                    </div>
                    <div className="cell">{this.getWebsiteDetails(item.websites)}</div>
                    <div className="cell">{this.getSoftwareDetails(item.software)}</div>
                    <div className="cell cell-center">{_.get(item, 'seats', '')}</div>
                    <div className="cell cell-center">
                      {_.get(item, 'totalProvider', '')}
                      {item.totalProvider !== '0' && (
                        <button
                          className="btn-edit"
                          onClick={() => this.ShowViewProvider(item.name)}>
                          View
                        </button>
                      )}
                    </div>
                    <div className="cell">
                      {item.totalProvider !== '0' && (
                        <button
                          className="btn-edit"
                          onClick={() => this.EditPracticeDetails(item.id)}>
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Practices;
