const questionList = [
  {
    id: 261,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [1, 2, 3, 4, 5, 6, 7,14], // painIds,
    nextQuestionId: 262,
    descriptions: [],
  },
  {
    id: 262,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 263,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 84, visitReasonIds: [227], extraQuestion: 1},
          {painId: 2, questionId: 163, visitReasonIds: [226], extraQuestion: 1},
          {painId: 3, questionId: 224, visitReasonIds: [], extraQuestion: 1},
          {painId: 4, questionId: 43, visitReasonIds: [224], extraQuestion: 1},
          {painId: 5, questionId: 104, visitReasonIds: [225], extraQuestion: 1},
          {painId: 6, questionId: 123, visitReasonIds: [228], extraQuestion: 1},
          {painId: 7, questionId: 144, visitReasonIds: [], extraQuestion: 1},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 263,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 83, visitReasonIds: [227], extraQuestion: 3},
          {painId: 2, questionId: 164, visitReasonIds: [226], extraQuestion: 3},
          {painId: 3, questionId: 225, visitReasonIds: [], extraQuestion: 1},
          {painId: 4, questionId: 44, visitReasonIds: [224], extraQuestion: 3},
          {painId: 5, questionId: 103, visitReasonIds: [225], extraQuestion: 3},
          {painId: 6, questionId: 124, visitReasonIds: [228], extraQuestion: 3},
          {painId: 7, questionId: 143, visitReasonIds: [], extraQuestion: 2},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 263,
    question: 'Do you still want to see a chiropractor?',
    descriptions: [
      'A chiropractor is a medical professional with a doctorate degree in Chiropractic (D.C.), which takes 4 years to complete, after completing at least 3 years of undergraduate courses. They may be able to treat your condition through conservative treatments such as manual therapy and chiropractic manipulation.',
    ],
    title: 'Chiropractor',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can order some diagnostic imaging tests',
      'Can not prescribe medications',
      'Can not perform injections',
      'Can not perform surgery',
    ],
    options: [
      {groupId: 'A9', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 261,
    lastQuestionId: 263,
    treeDepth: 3,
  },
};
