const questionList = [
  {
    id: 561,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [7, 9], // painIds,
    nextQuestionId: 562,
    descriptions: [],
  },
  {
    id: 562,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 563,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 7, questionId: 144, visitReasonIds: [27], extraQuestion: 1},
          {painId: 9, questionId: 183, visitReasonIds: [28], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 563,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 7, questionId: 143, visitReasonIds: [27], extraQuestion: 2},
          {painId: 9, questionId: 184, visitReasonIds: [28], extraQuestion: 3},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 563,
    question: 'Are you interested in potentially undergoing surgery?',
    options: [
      {
        nextQuestionId: 564,
        label: 'Yes, in the near future',
        shortLabel: 'Interested in surgery',
      },
      {nextQuestionId: 564, label: 'No, not at this time', shortLabel: 'No surgery'},
    ],
    descriptions: [],
  },
  {
    id: 564,
    question: 'Do you still want to see a Shoulder Surgeon?',
    descriptions: [
      'A Shoulder Surgeon is a doctor of Podiatric Medicine (DPM) who completed a 4 year residency in Podiatric Surgery, after completing a 5 year residency in Orthopedic Surgery. A Shoulder Surgeon has further training in treating Shoulder and Elbow conditions that may require surgery. ',
    ],
    title: 'Shoulder Surgeon',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can perform pain injections',
    ],
    options: [
      {groupId: 'B', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 561,
    lastQuestionId: 564,
    treeDepth: 4,
  },
};
