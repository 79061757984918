import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import momentHelper from 'data/moment/momentHelper';
import moment from 'moment';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import SelectAppointmentModal from 'components/Patient/PatientDashboard/SelectAppointmentModal';
const { modalFunctions } = modalConfig;
const { utcToLocalMoment, localToUtcMoment } = momentHelper;
const { errorModalData, warningModalData, blankModalData, successModalData, infoModalData } =
  modalFunctions;

const ShowProviderAvailability = props => {
  const history = useHistory()
  const rootContext = useContext(RootContext);
  const { setGlobal } = rootContext;
  const { location } = history
  useEffect(() => {
    if (location.state && Object.keys(location.state).length > 0) {
      let state = { ...history.location.state };
      delete state.transaction;
      openEditAppointmentTimeModal()
      history.replace();

    }
  }, [location.state])
  const getHistoryState = () => {

    const { state } = location;
    return state;
  };
  const handleTimeSelection = (dateString, timeObject) => {
    const state = getHistoryState();
    const appointmentSource = _.get(state, 'appointmentSource', false);
    const isVirtual = _.get(state, 'isVirtual', false);
    const providerId = _.get(state, 'doctordetails.providerId', 0);
    const locationId = _.get(state, 'doctordetails.locationId', 7);
    const accessLogProviderId = _.get(state, 'accessLogProviderId', false);
    const accessLogPracticeId = _.get(state, 'accessLogPracticeId', false);
   
    const dateTimeString = dateString + ' ' + timeObject.time;


    let appointmentDateTime = moment(dateTimeString, 'YYYY-MM-DD h:mm a', true);
    appointmentDateTime = localToUtcMoment(appointmentDateTime).format('YYYY-MM-DD HH:mm:ss')
    let appointmentSourceUrl = `isVirtual=${isVirtual}&providerId=${providerId}&pLocationId=${locationId}&date=${appointmentDateTime}`

    appointmentSourceUrl = appointmentSource?`?appointmentSource=${appointmentSource}&${appointmentSourceUrl}`:`?${appointmentSourceUrl}`
    if(accessLogProviderId)
    {
      appointmentSourceUrl=`${appointmentSourceUrl}&accessLogProviderId=${accessLogProviderId}`
    }
    if(accessLogPracticeId)
    {
      appointmentSourceUrl=`${appointmentSourceUrl}&accessLogPracticeId=${accessLogPracticeId}`
    }
    //validate-availability?providerId=136&date=2023-04-25%2010:32:00&isVirtual=false&pLocationId=177&appointmentSource=1
    history.push({
      pathname: `/validate-availability`,
      search: appointmentSourceUrl,

    });
  };
  const openEditAppointmentTimeModal = () => {
    const state = getHistoryState();

    const isVirtual = _.get(state, 'isVirtual', false);
    const dateTimeString = _.get(state, 'date');
    const apStartDateTimeObj = utcToLocalMoment(
      localToUtcMoment(moment(dateTimeString, 'YYYY-MM-DD HH:mm:ss', true)).format(),
    );

    const providerId = _.get(state, 'doctordetails.providerId', 0);
    const locationId = _.get(state, 'doctordetails.locationId', 7);
    const address = _.get(state, 'doctordetails.address', '');
    const doctorName = _.get(state, 'doctordetails.name', '');
    const suffixes = _.get(state, 'doctordetails.suffixes', []);
    const specialties = _.get(state, 'doctordetails.specialties', []);
    const existingAppointmentTime = _.get(state, 'doctordetails.existingAppointmentTime', 15);
    const newAppointmentTime = _.get(state, 'doctordetails.newAppointmentTime', 15);
    const newAppointmentTimeorExAT=existingAppointmentTime>newAppointmentTime?existingAppointmentTime:newAppointmentTime
    const appointment = {
      appointmentStartDateTime: apStartDateTimeObj.utc().toISOString(),
      isExistingPatient: true,
      providerAvailability: {
        isVirtual,
        practiceLocation: {
          id: locationId,
          address,
        },
        provider: {
          id: providerId,
          suffixes,
          specialties,
          doctorName,
          newAppointmentTimeorExAT,
          newAppointmentTime,
        },
      },
    };


    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: SelectAppointmentModal,
        customComponentProps: {
          data: {
            title: 'Select Appointment Time',
            appointment,
          },
          timeSelected: handleTimeSelection,
        },
      }),
    );
  };
  return null;
};

export default ShowProviderAvailability;
