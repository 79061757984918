import React, {Component} from 'react';
import _ from 'lodash';
import ErrorTemplate from 'modals/children/ErrorTemplate';
import InfoTemplate from 'modals/children/InfoTemplate';
import SuccessTemplate from 'modals/children/SuccessTemplate';
import WarningTemplate from 'modals/children/WarningTemplate';
import AgreementTemplate from 'modals/children/AgreementTemplate';
import RootContext from 'context/RootContext';
import ConfirmTemplate from './children/ConfirmTemplate';
import helper from 'data/helper';
import './Modal.css';

const {toggleBodyScrollClass} = helper;

class Modal extends Component {
  // modalConfiguration schema: {showModal: false, modalName: "", closeButton: true, closeOnBGClick: true, callBackOnClose: ()=>{}, options: {}}
  static contextType = RootContext;

  // disable body scroll if modal is open
  componentDidUpdate() {
    const {modal: modalConfiguration = {}} = this.context;
    const {showModal} = modalConfiguration;
    toggleBodyScrollClass(showModal);
  }

  componentWillUnmount() {
    toggleBodyScrollClass(false);
  }

  closeModalOnBGClick = modalConfiguration => {
    if (modalConfiguration.closeOnBGClick) {
      this.context.setGlobal('modal', {...modalConfiguration, showModal: false});
    }
  };

  // passFrom="closeIcon || closeButton || background"
  closeModal = (passFrom = 'closeIcon',redirect=false) => {
    const {modal: modalConfiguration, setGlobal} = this.context;

    if (passFrom === 'background') {
      if (!modalConfiguration.closeOnBGClick) {
        return null;
      }
    }
    setGlobal('modal', {...modalConfiguration, showModal: false});
    if (typeof modalConfiguration.callBackOnClose === 'function') {
      if(!redirect)
      modalConfiguration.callBackOnClose(passFrom);
    }
  };

  getCloseButtonText = modalName => {
    let closeButtonText = '';
    switch (modalName) {
      case 'success':
        closeButtonText = 'Ok';
        break;
      case 'info':
        closeButtonText = 'Ok';
        break;
      default:
        closeButtonText = 'Cancel';
    }
    return closeButtonText;
  };

  render() {
    const {modal: modalConfiguration = {}} = this.context;
    if (!modalConfiguration.showModal || modalConfiguration.modalName === '') {
      return null;
    }
    const closeIconOutput = (
      <button className="btn-close" onClick={() => this.closeModal('closeIcon')}>
        ×
      </button>
    );
    
    const closeBtnOutput = modalConfiguration.modalName !== 'agree' && (
      <div className="modal-footer">
        {modalConfiguration.showCancelBtn && (
           <button className="cancel-btn mob-screen" onClick={() => this.closeModal('closeIcon')}>
           Cancel
         </button>
        )}
        <button className="cancel-btn mob-screen" onClick={() => this.closeModal('closeButton')}>
          {modalConfiguration.closeButtonText ||
            this.getCloseButtonText(modalConfiguration.modalName)}
        </button>
        {modalConfiguration.returnToSearch && modalConfiguration.returnToSearch===true &&(
        <button className="cancel-btn mob-screen" onClick={() =>{ this.closeModal('closeButton',true);modalConfiguration.history.goBack();}}>
         Return To Search
        </button>
        )}
      </div>
    );
    let output = null;
    let modalWrapperClassName = '';
    const description =
      (modalConfiguration &&
        modalConfiguration.options &&
        modalConfiguration.options.description) ||
      null;
    const title = _.get(modalConfiguration, 'options.title', null);
    const {confirmMessage = '',confirmTitle=null,CustomFooter=null, lastModalConfig = {}, onConfirm = () => {}} = modalConfiguration;
    switch (modalConfiguration.modalName) {
      case 'error':
        output = <ErrorTemplate description={description} title={title} />;
        break;
      case 'info':
        output = <InfoTemplate description={description} title={title} />;
        break;
      case 'success':
        output = <SuccessTemplate description={description} title={title} />;
        break;
      case 'warning':
        output = <WarningTemplate description={description} title={title} />;
        break;
      case 'agree':
        output = (
          <AgreementTemplate
            title={title}
            description={description}
            onConfirm={onConfirm}
            lastModalConfig={{...lastModalConfig}}
            closeModal={this.closeModal}
          />
        );
        break;
      case 'confirm':
        output = (
          <ConfirmTemplate
            confirmMessage={confirmMessage}
            confirmTitle={confirmTitle}
            onConfirm={onConfirm}
            CustomFooter={CustomFooter}
            lastModalConfig={{...lastModalConfig}}
            closeModal={this.closeModal}
          />
        );
        break;
      case 'blank':
        const {
          CustomComponent = <></>,
          customComponentProps = {},
          modalWrapperClass = '',
        } = modalConfiguration;
        modalWrapperClassName = modalWrapperClass;
        output = (
          <CustomComponent
            {...customComponentProps}
            componentProps={{
              modalName: 'blank',
              CustomComponent,
              customComponentProps: {...customComponentProps},
            }}
            closeModal={this.closeModal}
          />
        );
        break;
      default:
        this.closeModal('background');
    }
    return (
      <div className={`Modal ${modalWrapperClassName}`}>
        <div className="modal-wrapper">
          {modalConfiguration.closeButton ? closeIconOutput : null}
          {output}
          {modalConfiguration.closeButton ? closeBtnOutput : null}
        </div>
        <div className="modal-overlay" onClick={() => this.closeModal('background')}></div>
      </div>
    );
  }
}

export default Modal;
