import procedureData from 'data/procedureData';
// static import of data files.
import covid19TestingData from './covid19Testing';

const {procedureList} = procedureData;

const fileList = [];
procedureList.forEach(procedureObj => {
  if (procedureObj.fileName) {
    fileList.push(procedureObj.fileName);
  }
});

let allProcedureQuestionList = [];
let procedureConfig = {};

const fileData = [];
fileData['covid19Testing'] = covid19TestingData;

fileList.map(async fileName => {
  const {questionList, config} = fileData[fileName];
  allProcedureQuestionList = [...allProcedureQuestionList, ...questionList];
  procedureConfig = {...procedureConfig, [fileName]: config};
});

export default {
  allProcedureQuestionList,
  procedureConfig,
};
