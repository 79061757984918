import React, { useContext, useEffect, useState } from 'react';
import Select from 'components/FormControls/Select';
import './RequestSettings.css';
import Checkbox from 'components/FormControls/Checkbox';
import {Link} from 'react-router-dom';
import reviewService from "services/review"
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import AuthContext from 'context/AuthContext';
const {getBirdEyeSettings,saveBirdEyeSettings} = reviewService;
const {modalFunctions} = modalConfig;
const RequestSettings = props => {
  const rootContext = useContext(RootContext);
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const {errorModalData, successModalData} = modalFunctions;
  const [sources,setSources]=useState([])
  // const selectOptions = {
  //   template: [
  //     {value: 1, label: 'template1'},
  //     {value: 2, label: 'template2'},
  //     {value: 3, label: 'template3'},
  //   ],
  // };
   const selectOptionsDays = {
    template: [
      {value: 7, label: '7 Days to a contact'},
      {value: 10, label: '10 Days to a contact'},
      {value: 30, label: '30 Days to a contact'},
    ],
  };
  const getSettings=async()=>{
    const response = await getBirdEyeSettings().catch((error)=>console.log("ee",error));
    setSources(response.data)
  }
  const oncheckBoxChange=(event,id)=>{
    let val=event.target.value
    let tempSources=[...sources]
    if(val===false && tempSources.filter((s)=>s.inBirdEye===true).length===1){
      const {setGlobal} = rootContext;
      setGlobal('modal', errorModalData("Atleast one review source should be selected"));
      return false;
     
    }
    if(val===true && tempSources.filter((s)=>s.inBirdEye===true).length===3){
      const {setGlobal} = rootContext;
      setGlobal('modal', errorModalData("Only select a maximum of 3 review sources."));
      return false;
      
    }
   
    let index=tempSources.findIndex((s)=>s.birdEyeId===id)
    if(index>=0){
      tempSources[index].inBirdEye=val
    }
    setSources(tempSources)
  }
  useEffect(()=>{
    
  // getSettings()
  },[auth])
  const handleError = error => {
    const {setGlobal} = rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
const saveSettings=async()=>{
  let  reviewSourceIds=sources.filter((s)=>s.inBirdEye===true).map((s)=>s.birdEyeId)
  const response=await saveBirdEyeSettings({reviewSourceIds:reviewSourceIds}).catch(handleError);
  if (response && response.data) {
    const {setGlobal} = rootContext;
    setGlobal('modal', successModalData('Your settings have been saved!  Please allow up to 20 minutes for review site preferences to be updated.'));
  }
}
  return (
    <div>
      <div className="RequestSettings">
        <div className="heading">
          <div className="title">Edit Request Settings </div>
        </div>
        <div className="request-setting-inner">
          <div className="review-request">
            <div className="label-text">Sending Review Request </div>
            <div className="sub-text">
              Send a review request for your{' '}
              <Link to="/reviews/requestreviews">connected review sources.</Link> Kaly reviews
              are excluded from this functionality and sent automatically after an appointment is
              completed.
            </div>
            {/* <div className="label-text">Send review requests via</div>
            <Select containerClass="select-content" options={selectOptions.template} /> */}
            <div className="review-sources">
              {/* <div className="heading-title">Select up to 3 review sources</div> */}
              {/* {sources && sources.length>0 &&(
                <> */}
              <div className="list-review-source">
                {/* {sources.map((s)=>(
                  <Checkbox onChange={(e)=>oncheckBoxChange(e,s.birdEyeId)} value={s.inBirdEye}>{s.name}</Checkbox>
                ))} */}
                 <Checkbox value={true}>Google</Checkbox>
              </div>
              {/* <button style={{marginLeft:0}} className="addwebsite-btn" onClick={saveSettings}>Save</button> */}
              {/* </>
              )} */}
              {/* {sources && sources.length===0&&(
                <p>Please add at least one website</p>
              )} */}
            </div>
          </div>
          {/* <div className="frequently">
            <div className="important-info">
              <div className="label-text"> Important Information</div>
              <div className="description">
                To minimize disruption, text messages are only sent to contacts between 8AM - 8PM.
                The business location that provides their service determines the time zone setting.
              </div>
              <div className="label-text">Review requests to the same patient can only be sent once every 7 days.</div> */}
              {/* <div className="max-email">7 Days to a contact</div> 
            <Select containerClass="select-content" options={selectOptionsDays.template} />*/}
            {/* </div>
          </div>  */}
        </div>
      </div>
    </div>
  );
};

export default RequestSettings;
