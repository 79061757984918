import specialityData from 'data/specialityData';
// static import data file.
import spineSurgeonData from './spineSurgeon';
import shoulderSurgeonData from './shoulderSurgeon';
import handSurgeonData from './handSurgeon';
import orthopedicSportsSurgeonData from './orthopedicSportsSurgeon';
import hipSurgeonData from './hipSurgeon';
import kneeSurgeonData from './kneeSurgeon';
import footAnkleSurgeonData from './footAnkleSurgeon';
import podiatristData from './podiatrist';
import orthopedicSurgeonData from './orthopedicSurgeon';
import painManagementData from './painManagement';
import physiatristData from './physiatrist';
import neurologistData from './neurologist';
import interventionalSportsPhysicianData from './interventionalSportsPhysician';
import physicalTherapistData from './physicalTherapist';
import chiropractorData from './chiropractor';
import acupuncturistData from './acupuncturist';
import primaryCarePhysicianData from './primaryCarePhysician';
import endodontist from './endodontist';
import oralSurgeon from './oralSurgeon';
import orthodontist from './orthodontist';
import periodontist from './periodontist';
import prosthodontist from './prosthodontist';
import dentist from './dentist';
import pediatricDentist from './pediatricDentist';
import familyPhysician from './familyPhysician';
import internist from './internist';
import internalMedicinePhysician from './internalMedicinePhysician';
import dermatologist from './dermatologist';
import headache from '../symptom/headache';
import plasticSurgery from './plasticSurgery';
import obgyn from './obgyn';
import occupationalTherapist from './occupationalTherapist';
const {specialityList} = specialityData;
// const fileList = [
//   'spineSurgeon',
// ];
const fileList = [];
specialityList.forEach(specialityObj => {
  if (specialityObj.fileName) {
    fileList.push(specialityObj.fileName);
  }
});

let allSpecialistQuestionList = [];
let specialistConfig = {};

const fileData = [];
fileData['spineSurgeon'] = spineSurgeonData;
fileData['shoulderSurgeon'] = shoulderSurgeonData;
fileData['handSurgeon'] = handSurgeonData;
fileData['orthopedicSportsSurgeon'] = orthopedicSportsSurgeonData;
fileData['hipSurgeon'] = hipSurgeonData;
fileData['kneeSurgeon'] = kneeSurgeonData;
fileData['footAnkleSurgeon'] = footAnkleSurgeonData;
fileData['podiatrist'] = podiatristData;
fileData['orthopedicSurgeon'] = orthopedicSurgeonData;
fileData['painManagement'] = painManagementData;
fileData['physiatrist'] = physiatristData;
fileData['neurologist'] = neurologistData;
fileData['interventionalSportsPhysician'] = interventionalSportsPhysicianData;
fileData['physicalTherapist'] = physicalTherapistData;
fileData['chiropractor'] = chiropractorData;
fileData['acupuncturist'] = acupuncturistData;
fileData['primaryCarePhysician'] = primaryCarePhysicianData;
fileData['endodontist'] = endodontist;
fileData['oralSurgeon'] = oralSurgeon;
fileData['orthodontist'] = orthodontist;
fileData['periodontist'] = periodontist;
fileData['prosthodontist'] = prosthodontist;
fileData['dentist'] = dentist;
fileData['pediatricDentist'] = pediatricDentist;
fileData['familyPhysician'] = familyPhysician;
fileData['internist'] = internist;
fileData['internalMedicinePhysician'] = internalMedicinePhysician;
fileData['dermatologist'] = dermatologist;
fileData['headache'] = headache;
fileData['plasticsurgery'] = plasticSurgery;
fileData['obgyn'] = obgyn;
fileData['occupationalTherapist'] = occupationalTherapist;
fileList.forEach(fileName => {
  const {questionList, config} = fileData[fileName];
  allSpecialistQuestionList = [...allSpecialistQuestionList, ...questionList];
  specialistConfig = {...specialistConfig, [fileName]: config};
});

export default {
  allSpecialistQuestionList,
  specialistConfig,
};
