import React from 'react';
import _ from 'lodash';
import selectOptions from 'data/selectOptions';
import './selectsearch.css';
import SelectSearch from 'react-select-search';
const {defaultOption} = selectOptions;

const Select = props => {
  const {
    label = '',
    containerClass = '',
    options = [],
    error = null,
    labelIndex = 'label',
    valueIndex = 'value',
    arrowCss = {},
    isAllowDefaultOpen = false,
    defaultOptionProp = null,
    ...otherInputProps
  } = props;
  const {value = ''} = otherInputProps;
  if (!isAllowDefaultOpen) {
    otherInputProps.required = 'required';
  }
  console.log("updatedOptions",options);
  const updatedOptions = [...options];
  // if (_.trim(value) === '' || isAllowDefaultOpen) {
  //   const defaultOptionObj = defaultOptionProp || defaultOption;
  //   const updatedDefaultOption = {};
  //   updatedDefaultOption[labelIndex] = defaultOptionObj['label'];
  //   updatedDefaultOption[valueIndex] = defaultOptionObj['value'];
  //   updatedOptions.unshift({...updatedDefaultOption});
  // }
  const optionsComponent = updatedOptions.map(option => {
    return {name: option[labelIndex], value: option[valueIndex]}
     
    // return (
    //   <option key={option[valueIndex]} value={option[valueIndex]}>
    //     {option[labelIndex]}
    //   </option>
    // );
  });
  let newContainerClass = containerClass;
  if (error) {
    newContainerClass += ' error';
  }
  if (_.trim(value) === '') {
    newContainerClass += ' no-filled-select';
  }
  const fuzzySearch=(options)=>{
    return (value) => {
      if (!value.length) {
          return options;
      }
      
      let filteredArray= options.filter(obj=>obj.name.toLowerCase().includes(value.toLowerCase()));
      if(filteredArray.length===0){
        return [{name:"No Matches",value:""}]
      }
      else return filteredArray
  };
  }
  return (
    <div className={`Select-control form-group ${newContainerClass}`}>
      <span className="label">{label}</span>
      <SelectSearch  options={optionsComponent}
     
      onChange={(value)=>{
        otherInputProps.onChange({target:{name:otherInputProps.name,dataset:{prefix:otherInputProps['data-prefix']},value:value}})
      }}
      // printOptions="always"
      value={otherInputProps.value}
      placeholder={defaultOptionProp?.label}
      filterOptions={fuzzySearch} search/>
      
      {/* <span className="arrow" style={arrowCss}></span> */}
      {error && <span className="error-text">{error}</span>}
    </div>
  );
};

export default Select;
