import React, {Component} from 'react';
import _ from 'lodash';
import Layout from 'layout/AfterAuth';
import RootContext from 'context/RootContext';
import SubHeader from 'components/AfterAuth/SubHeader';
import KScore from 'components/AfterAuth/KScore';
import Select from 'components/FormControls/Select';
import ReviewTabs from 'components/AfterAuth/ReviewTabs';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import reviewService from 'services/review';
import modalConfig from 'modals/modalConfig';
import contextHelper from 'context/contextHelper';
import './Reviews.css';

const {modalFunctions} = modalConfig;
const {errorModalData, confirmModalData} = modalFunctions;
const {
  getAllFilteredReviews,
  giveReply,
  editReply,
  deleteReply,
  toggleFeatureType,
  reviewDeleteRequest,
} = reviewService;

class Reviews extends Component {
  static contextType = RootContext;
  state = {
    activeTabIndex: 0,
    KScoreData: null,
    reviewList: [],
    searchText: '',
    filterType: '',
    filterOptions: [],
  };
  tabs = [
    {label: 'All Reviews', count: 0},
    {label: 'Recent', count: 0},
    {label: 'Featured', count: 0},
    {label: 'Connected Accounts', count: 0},
  ];
  authContext = {};
  oldAuthContext = null;

  componentDidMount = async () => {
    this.changeViewUser();
    await this.getReviews('all');
    this.scroll();
  };

  scroll = () => {
    if (this.props.location.hash) {
      //Ref: https://stackoverflow.com/questions/11662693/how-do-i-get-the-fragment-identifier-value-after-hash-from-a-url
      const id = this.props.location.hash.substr(1);
      if (!id) {
        return;
      }
      const element = document.getElementById(id);
      if (element) {
        //Ref: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
        element.scrollIntoView({behavior: 'smooth'});
      }
    }
  };

  changeViewUser = () => {
    const {auth, setAuthState} = this.authContext;
    const newViewUserId = _.get(auth, 'id', '');
    let isStaff=false
    let {roles}=auth
    if(roles && roles.length>0 &&roles[0].role === 'Staff'){
      isStaff=true
    }
    if (newViewUserId&& !isStaff) {
      setAuthState(prevState => {
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            viewUserId: newViewUserId,
          },
        };
      });
    }
  };

  //  get initial data again, when viewUserId is switched.
  componentDidUpdate() {
    const newViewUserId = _.get(this.authContext, 'auth.viewUserId');
    const oldViewUserId = _.get(this.oldAuthContext, 'auth.viewUserId');
    if (newViewUserId && oldViewUserId && newViewUserId !== oldViewUserId) {
      this.getReviews('all');
    }
    this.oldAuthContext = this.authContext;
  }

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  getReviews = async filterType => {
    const response = await getAllFilteredReviews(filterType).catch(this.handleError);
    if (response) {
      const {data} = response;
      if (data) {
        const {reviewData, totalReview, totalRecentReview, totalFeaturedReview} = data;
        this.tabs[0].count = totalReview;
        this.tabs[1].count = totalRecentReview;
        this.tabs[2].count = totalFeaturedReview;
        const KScoreData = this.formatKScoreData(data);
        let allAvailableReviewSources = _.uniq(
          _.map(reviewData, data => _.get(data, 'reviewSource.title', '')),
        );
        let filterOptions = _.map(allAvailableReviewSources, data => ({value: data, label: data}));
        this.setState({
          reviewList: reviewData,
          filterOptions: filterOptions,
          KScoreData,
        });
      }
    }
  };

  formatKScoreData = data => {
    const {totalRating, totalReview, doctorRating, staffRating, officeRating} = data;
    const KScoreData = {
      totalReview,
      totalAverageRating: totalRating,
      doctorRatingList: [
        {name: 'Knowledge', rating: doctorRating.knowledge},
        {name: 'Approachable', rating: doctorRating.approachable},
        {name: 'Bedside Manner', rating: doctorRating.bedsideManner},
      ],
      staffRatingList: [
        {name: 'Professional', rating: staffRating.professional},
        {name: 'Scheduling', rating: staffRating.scheduling},
        {name: 'Response Time', rating: staffRating.responseTime},
      ],
      officeRatingList: [
        {name: 'Cleanliness', rating: officeRating.cleanliness},
        {name: 'Atmosphere', rating: officeRating.atmosphere},
        {name: 'Wait times', rating: officeRating.waitTimes},
      ],
    };
    return KScoreData;
  };

  handleSearch = event => {
    this.setState({
      searchText: event.target.value,
    });
  };

  handleReply = async data => {
    let response = {};
    if (data && data.replyId) {
      const reqData = {replyId: data.replyId, reply: data.reply};
      response = await editReply(reqData).catch(this.handleError);
    } else {
      response = await giveReply(data).catch(this.handleError);
    }
    if (response) {
      const {reviewList} = this.state;
      let updatedReviewList = [...reviewList];
      updatedReviewList = _.map(updatedReviewList, review => {
        let updatedReview = {...review};
        if (review.id === data.reviewId) {
          updatedReview = {
            ...updatedReview,
            reply: {
              reply: response.data && response.data.reply ? response.data.reply : data.reply,
              id: response.data && response.data.id ? response.data.id : data.replyId,
            },
          };
        }
        return updatedReview;
      });
      this.setState({
        reviewList: updatedReviewList,
      });
    }
  };

  handleDeleteReply = replyId => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete this Reply ?`,
        lastModalConfig: {
          ...this.props.componentProps,
        },
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            setGlobal('modal', {showModal: false});
            this.confirmDelete(replyId);
          }
        },
      }),
    );
  };

  confirmDelete = async data => {
    const response = await deleteReply(data.replyId).catch(this.handleError);
    if (response) {
      const {reviewList} = this.state;
      let updatedReviewList = [...reviewList];
      updatedReviewList = _.map(updatedReviewList, review => {
        let updatedReview = {...review};
        if (review.id === data.reviewId) {
          updatedReview = {
            ...updatedReview,
            reply: null,
          };
        }
        return updatedReview;
      });
      this.setState({
        reviewList: updatedReviewList,
      });
    }
  };

  handleToggleFeatureType = async data => {
    const response = await toggleFeatureType(data);
    if (response) {
      this.getReviews('all');
    }
  };

  handleFilterChange = event => {
    this.setState({filterType: event.target.value});
  };

  handleReviewDeleteReq = async data => {
    const response = await reviewDeleteRequest(data);
    if (response) {
      this.getReviews('all');
    }
  };

  render() {
    const {
      activeTabIndex,
      reviewList,
      searchText,
      KScoreData,
      filterType,
      filterOptions,
    } = this.state;
    const title = 'Provider Reviews';

    let filteredReviewList = reviewList;
    if (filterType.length > 0) {
      filteredReviewList = _.filter(reviewList, data => data.reviewSource.title === filterType);
    }
    return (
      <Layout>
        {contextHelper.AuthContextConsumer(this)}
        <div className="AfterAuthReviews">
          <SubHeader title={title} />
          <div className="main-section-content">
            {KScoreData && (
              <div className="box-panle">
                <KScore data={KScoreData}></KScore>
              </div>
            )}
            <div className="box-panle">
              <div className="search-review-list">
                <Select
                  options={filterOptions}
                  onChange={this.handleFilterChange}
                  defaultOptionProp={{value: '', label: 'All Sources'}}
                  isAllowDefaultOpen={true}
                />
                <Input
                  placeholder="Search Review"
                  onChange={this.handleSearch}
                  value={searchText}
                  icon={images.search}></Input>
              </div>
              <ReviewTabs
                onReply={this.handleReply}
                onToggleFeatureType={this.handleToggleFeatureType}
                searchText={searchText}
                tabs={this.tabs}
                reviewList={filteredReviewList}
                activeTabIndex={activeTabIndex}
                onDelete={this.handleDeleteReply}
                onRequestDelete={this.handleReviewDeleteReq}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Reviews;
