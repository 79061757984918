import React, {useState, useContext} from 'react';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Select from 'components/FormControls/Select';
import selectOptions from 'data/selectOptions';
import validators from 'data/validators';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import FileUpload from 'components/FormControls/FileUpload';
import config from 'config/config';
import './AboutYourPracticeStepOne.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {errorModalData} = modalConfig.modalFunctions;
const {practiceSoftwareOptions} = selectOptions;
const {__required, __isValidUrl, __maxLength} = validators;
const {MAX_TEXT_LENGTH} = config;
const {Analytics} = analyticsData;

const AboutYourPracticeStepOne = props => {
  const rootContext = useContext(RootContext);
  const {nextStep, data, email, token} = props;
  const values = {...data};
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validatePracticeName = (value = null) => {
    const fValue = value !== null ? value : formValues['name'];
    if (!__required(fValue)) {
      return 'Legal Practice Entity Name is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateWebsite = (value = null) => {
    const fValue = value !== null ? value : formValues['website'];
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (fValue && !__isValidUrl(fValue)) {
      return 'Invalid website';
    }
    return null;
  };
  const __validateSoftware = (value = null) => {
    const fValue = value !== null ? value : formValues['software'];
    if (!__required(fValue)) {
      return 'Practice software is required';
    }
    return null;
  };
  const __validateLogo = (value = null) => {
    return null;
  };
  const __validateForm = () => {
    const validPracticeName = !__validatePracticeName();
    const validWebsite = !__validateWebsite();
    const validSoftware = !__validateSoftware();
    const validLogo = !__validateLogo();
    return validPracticeName && validWebsite && validSoftware && validLogo;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name} = event.target;
    let {value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'name':
        errorObject = {
          [name]: __validatePracticeName(value),
        };
        break;
      case 'website':
        errorObject = {
          [name]: __validateWebsite(value),
        };
        break;
      case 'software':
        errorObject = {
          [name]: __validateSoftware(value),
        };
        break;
      case 'logoUrl':
        errorObject = {
          [name]: __validateLogo(value),
        };
        break;
      default:
        break;
    }
    setFormData(prevformData => {
      return {
        ...prevformData,
        values: {
          ...prevformData.values,
          [name]: value,
        },
        errors: {
          ...prevformData.errors,
          ...errorObject,
        },
      };
    });
  };

  const handleSubmit = () => {
    Nux.sendEvent(
      Analytics.Category.DoctorRegistration,
      Analytics.Actions.Click,
      'aboutPracticeStep1',
    );
    nextStep(formValues);
  };
  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const handleError = error => {
    const {setGlobal} = rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  // file upload functions
  const afterUpload = fileUrl => {
    const customEvent = {
      target: {
        name: 'logoUrl',
        value: fileUrl,
      },
    };
    changeHandler(customEvent);
  };
  const handleRemove = () => {
    const customEvent = {
      target: {
        name: 'logoUrl',
        value: '',
      },
    };
    changeHandler(customEvent);
  };

  return (
    <div className="AboutYourPracticeStepOne">
      <div className="h3">About your practice</div>
      <div className="paragraph">You’re signing up as {email}</div>
      <form className="signup-form">
        <FileUpload
          name="logoUrl"
          defaultFileUrl={formValues.logoUrl}
          label="Add Practice Logo"
          subLabel={{title: 'Upload Practice Logo', description: 'Better Photo = More Bookings'}}
          uploadServerUrl={`sign-up/upload/${token}`}
          onError={handleError}
          afterUpload={afterUpload}
          onRemove={handleRemove}
        />
        <Input {...getGeneralInputProps('name')} label="Legal Practice Entity Name" />
        <Input {...getGeneralInputProps('website')} type="text" label="Primary Practice Website" />
        <Select
          {...getGeneralInputProps('software')}
          options={practiceSoftwareOptions}
          label="Practice Software"
        />
        <div className="signup-btn">
          <CircleButton disabled={!isValidForm} onClick={handleSubmit} btnType="whiteButton">
            Continue
          </CircleButton>
        </div>
      </form>
    </div>
  );
};

export default AboutYourPracticeStepOne;
