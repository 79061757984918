import React, { Component } from 'react';
import _ from 'lodash';
import Layout from 'layout/AfterAuth';
import FeedbackComponent from 'components/AfterAuth/ProviderFeedback';
import './Consultrequest.css';
import AuthContext from 'context/AuthContext';
import RootContext from 'context/RootContext';
import ConsultrequestEdit from "./ConsultrequestEdit"
import modalConfig from 'modals/modalConfig';
import SubHeader from 'components/AfterAuth/SubHeader';
import ReactPaginate from 'react-paginate';
import images from 'data/images';
import Divider from 'components/Divider';
import Select from 'components/FormControls/Select';
import practiceService from 'services/practice'
import moment from 'moment';
import painData from 'data/painData';
import constants from 'data/constants';
import SwitchToggle from 'components/FormControls/SwitchToggle';

const { painList } = painData;
const { getConsultRequest, consultRequestUpdate } = practiceService
const { modalFunctions } = modalConfig;
const { blankModalData, confirmModalData, errorModalData } = modalFunctions;
const { DefaultDebounceMSec } = constants;
const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  searchText: '',
  sortBy: 'createdAt',
  orderBy: 'desc',
  isEditProvider: false,
  editProviderId: '',
  editUserId: '',
  searchStatus: 1,
  searchUserType: 'all',
  totalRecord: 0,
};
class Consultrequest extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {
    ...initialState
  };
  tabs = [
    { label: 'Pending Feedback', tab: 'PendingFeedback' },
    { label: 'Completed Feedback', tab: 'Feedback' },
  ];

  componentDidMount() {
    this.getConsultrequestData();
    // const tab = this.getTabName(this.props);
    // this.getAndSetActiveTab(tab);
    // const { location } = this.props;
    // const query = new URLSearchParams(location.search);

    // let selectedFeedbackid = query.get('feedbackid')
    // if (selectedFeedbackid) {
    //   const {setGlobal} = this.rootContext;
    //   // setGlobal(
    //   //   'modal',
    //   //   blankModalData({
    //   //     CustomComponent: ContactViewHistoryList,
    //   //     modalWrapperClass: 'mega-modal',
    //   //     customComponentProps: {
    //   //       data: {
    //   //         contactId,
    //   //       },
    //   //     },
    //   //   }),
    //   // );
    //   setGlobal(
    //     'modal',
    //     blankModalData({
    //       CustomComponent: SingleFeedbackModal,
    //       customComponentProps: {
    //         data: {
    //           title: 'Edit Appointment Time',
    //           selectedFeedbackid:selectedFeedbackid,
    //         }
    //       },
    //     }),
    //   );
    // }
  }

  componentDidUpdate(prevProps) {
    const tab = this.getTabName(this.props);
    const prevTab = this.getTabName(prevProps);
    if (tab !== prevTab) {
      this.getAndSetActiveTab(tab);
    }
  }

  // changeViewUser = () => {
  //   const { auth, setAuthState } = this.context;

  //   const newViewUserId = _.get(auth, 'id', '');

  //   let isStaff = false
  //   let { roles } = auth
  //   if (roles && roles.length > 0 && roles[0].role === 'Staff') {
  //     isStaff = true
  //   }
  //   if (newViewUserId && !isStaff) {
  //     setAuthState(prevState => {
  //       return {
  //         ...prevState,
  //         auth: {
  //           ...prevState.auth,
  //           viewUserId: newViewUserId,
  //         },
  //       };
  //     });
  //   }
  // };

  getTabName = props => {
    const query = new URLSearchParams(props.location.search);
    const tab = query.get('tab') || _.get(this, 'tabs[0].tab');
    return tab;
  };

  getAndSetActiveTab = async tab => {
    const tabObj = _.find(this.tabs, { tab });
    if (!tabObj) {
      tab = _.get(this, 'tabs[0].tab');
    }
    switch (tab) {
      case 'PendingFeedback':
        break;
      case 'Feedback':
        break;
      default:
        break;
    }
    this.setState({
      activeTab: tab,
    });
  };
  handlePageChange = data => {
    this.setState({ activePage: data.selected + 1 }, this.getConsultrequestData);
  };
  setActiveTab = async activeTab => {
    const params = new URLSearchParams();
    params.append('tab', activeTab);
    this.props.history.push({
      pathname: '/feedback',
      search: `?${params.toString()}`,
    });
  };

  renderShortIcon = key => {
    const { sortBy, orderBy } = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };
  getConsultrequestData = async (isCheckSearch, noLoad = false) => {
    const { location } = this.props;
    const isBackLink = _.get(location, 'state.isBackLink', false);
    if (isBackLink) {
      this.getSearchedData(location);
    } else {
      //await this.getSearchPractice();
      const {
        activePage,
        searchText,
        sortBy: sort,
        orderBy: order,
        searchStatus: status,
        searchUserType,
      } = this.state;
      const response = await getConsultRequest(
        {
          search: searchText,
          page: activePage,
          limit: 10,
          sortBy: sort,
          orderBy: order,
          statusId: status,
          // userType: 'provider', // searchUserType === 'all' ? null : searchUserType,
        },
        noLoad,
      ).catch(this.handleError);
      if (response) {
        const { data } = response;
        this.setState({
          list: data.data,
          totalPage: data.totalPages,
          totalRecord: data.totalRecords,
        });
      }
    }
  };
  handleStatusChange = event => {
    this.setState({ searchStatus: parseInt(event.target.value), activePage: 1 }, this.getConsultrequestData);
  };
  handleChange = event => {
    this.setState({ activePage: 1, searchText: event.target.value });
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.getConsultrequestData();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };
  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };
  handleClearSearch = () => {
    this.setState({ activePage: 1, searchText: '' }, this.getConsultrequestData);
  };
  handleSortingChange = e => {
    e.preventDefault();
    const { sortBy, orderBy } = this.state;
    const { dataset } = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        { orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1 },
        this.getConsultrequestData,
      );
    } else {
      this.setState({ orderBy: 'asc', sortBy: key, activePage: 1 }, this.getConsultrequestData);
    }
  };
  openrequestEditer = (data) => {
    let { setGlobal } = this.rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ConsultrequestEdit,
        modalWrapperClass: 'mega-modal',
        customComponentProps: { data: data, isUpdate: true, getConsultrequestData: () => { this.getConsultrequestData() } },
      }),
    );
  };
  confirmComplete = (data) => {
    let { setGlobal } = this.rootContext;
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to complete this request?`,
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            consultRequestUpdate({ statusId: 2, }, data.id).catch(this.handleError).then((res) => {
              this.getConsultrequestData()
              setGlobal('modal', { showModal: false });
            })
            //onSave({...updatedFormValues, isDelete: true}, onServerError);
          }
        },
      }),
    );
  }
  changeHandler = (event) => {
    const { name } = event.target;
    let { value } = event.target;
    // let tempselectedValues = [...selectedValues]


    // let changeIndex = tempselectedValues.findIndex((i) => i.id == name)
    // if (changeIndex >= 0) {
    //   tempselectedValues[changeIndex].doNotDisplay = !value
    //   let trueVal = tempselectedValues.find((ii) => !ii.doNotDisplay)
    //   if (!trueVal) {
    //     setLocalError('Required at least one specialty for your profile.')
    //     return;
    //   } else if (isProviderCare) {
    //     trueVal = tempselectedValues.find((ii) => !ii.doNotDisplay && ii.status == 1);
    //     if (!trueVal) {
    //       setLocalError('Required at least one admin approved specialty visible for your profile.')
    //       return;
    //     }
    //   }
    //   setLocalError(null)
    //   onInputBlur(tempselectedValues);
    // }

  }
  handleError = error => {
    const { setGlobal } = this.rootContext;
    let { message } = error;

    let objOp = {}


    setGlobal('modal', errorModalData(message, {
      ...objOp
    }));
    return null;
  };
  render() {
    const { auth } = this.context;

    const { searchText, totalRecord = 0, list = [], totalPage = 0, activePage = 1 } = this.state;
    const activeIndex = activePage - 1;
    return (
      <Layout>
        <RootContext.Consumer>
          {context => {
            this.rootContext = context;
            return null;
          }}
        </RootContext.Consumer>
        <div className="ConsultRequest">


          <div className="ReviewAccounts-content">
            {/* <ApprovalCardFeedback></ApprovalCardFeedback> */}
            <div className="setting-tabs">

              <div className="tab-content">

                <div className="dashboard-panle">
                  <h2 className='sub-header'>Consult Request</h2>
                  <Divider></Divider>

                  <div className="search">
                    <div>
                      <Select
                        options={[{ label: 'Initiated', value: 1 }, { label: 'Completed', value: 2 },{ label: 'Cancelled', value: 3 }]}
                        labelIndex="label"
                        valueIndex="value"
                        name="providerStatus"
                        //defaultOptionProp={{ value: 'onboardeduser', label: 'All Status' }}
                        isAllowDefaultOpen={true}
                        onChange={this.handleStatusChange}
                        value={this.state.searchStatus}
                      />
                    </div>
                    <div className="input-control">
                      <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
                      {searchText && (
                        <button className="btn-clear" onClick={this.handleClearSearch}>
                          <img src={images.close} alt="clear" />
                        </button>
                      )}
                    </div>
                    <button onClick={this.handleSearch}>Search</button>
                  </div>
                  <div className="dataCount">
                    <span>{totalRecord} Request found.</span>
                  </div>
                  {
                    <div className="table-overflow">
                      <div className="rtable">
                        <div className="heading">
                          <div
                            className="cell pointer"
                            data-key="patientLastName"
                            onClick={this.handleSortingChange}>
                            Name{this.renderShortIcon('patientLastName')}
                          </div>
                          <div
                            className="cell "

                          >
                            Email
                          </div>
                          <div
                            className="cell "

                          >
                            Mobile No#
                          </div>
                          <div
                            className="cell "

                          >
                            Symptom
                          </div>
                          <div
                            className="cell pointer"
                            data-key="createdAt"
                            onClick={this.handleSortingChange}>
                            Request Date{this.renderShortIcon('createdAt')}
                          </div>

                          <div
                            className="cell"
                          >
                            Preferences
                          </div>
                          <div className="cell ">Status</div>
                          {/* <div className="cell edit-cell">Action</div> */}
                        </div>
                        {list.length > 0 ? (
                          _.map(list, (reqest, index) => (
                            <div className="row" key={index}>
                              <div onClick={() => this.openrequestEditer(reqest)} className="cell">
                                {
                                  `${_.get(reqest, 'patientFirstName', '')} ${_.get(
                                    reqest,
                                    'patientLastName',
                                    '',
                                  )}`}
                                {reqest.consultRequestStatusId === "1" && (
                                  <button onClick={() => this.openrequestEditer(reqest)} className='edit-btn'><img style={{ width: "15px" }} src={images.editPen} /></button>
                                )}
                              </div>
                              <div onClick={() => this.openrequestEditer(reqest)} className="cell">{_.get(reqest, 'patientEmail', '')}</div>
                              <div onClick={() => this.openrequestEditer(reqest)} className="cell">
                                {_.get(reqest, 'mobilePhone', '')}
                              </div>
                              <div onClick={() => this.openrequestEditer(reqest)} className="cell">
                                <div style={{ display: 'flex', flexFlow: 'column', maxWidth: "200px", wordBreak: "word-break" }}>
                                  {painList.find(c => c.id == reqest.painId)?.label}

                                </div>
                              </div>

                              <div onClick={() => this.openrequestEditer(reqest)} className="cell">
                                {this.formatedDate(_.get(reqest, 'createdAt', ''))}
                              </div>
                              <div onClick={() => this.openrequestEditer(reqest)} className="cell">
                                {/* <span>{StatusFilter[reqest.status]}</span> */}
                                {reqest.requestPhoneCall && (
                                  <div className="imgtextdisplay">
                                    <img src={images.phone} />
                                    <span>Call Back</span>
                                  </div>
                                )}
                                {reqest.requestSMS && (
                                  <div className="imgtextdisplay">
                                    <img src={images.email} />
                                    <span>SMS</span>
                                  </div>
                                )}
                              </div>
                              <div className="cell">
                                <div className="actionbtndev1">
                                  {reqest.consultRequestStatusId === "1" ? (
                                    <button
                                      className="btn btn-view"
                                      onClick={() => this.confirmComplete(reqest)}>
                                      Move to Complete
                                    </button>
                                    // <span className='only-Toggle'>
                                    //   <SwitchToggle onChange={this.changeHandler} label="Status" />
                                    // </span>
                                  ): reqest.consultRequestStatusId === "2"? (
                                    <div className='imgtextdisplay success'>
                                      <img src={images.ok} />
                                      <span>Completed</span>
                                    </div>
                                  ):(
                                    <div className='imgtextdisplay error'>
                                      <img src={images.closeBlack} />
                                      <span>Cancelled</span>
                                    </div>
                                  )}


                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-record">No Records found</div>
                        )}
                      </div>
                    </div>
                  }
                  {totalPage > 0 && (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={totalPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageChange}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                      forcePage={activeIndex}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Consultrequest;
