import React, {Component} from 'react';
import handleAddToCalendar from 'data/addToCalendar';

class AddToCalendar extends Component {
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const calendarEvent = {
      title: 'Appointment with ' + query.get('docname'),
      description: '',
      location: query.get('location'),
      startTime: new Date(query.get('starttime')).toUTCString(),
      endTime: new Date(query.get('endTime')).toUTCString(),
    };
    handleAddToCalendar(query.get('caltype'), calendarEvent);
    this.props.history.push('/');
  }
  render() {
    return <></>;
  }
}

export default AddToCalendar;
