import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import images from 'data/images';
import {useHistory} from 'react-router-dom';
import helper from 'data/helper';
import MyGoogleMap from 'components/MyGoogleMap';
import momentHelper from 'data/moment/momentHelper';
import './AppointmentRequest.css';
import {getHideQuestionID} from 'data/questionnaires/questionnaire.helper';
const HideQuestionID = getHideQuestionID();
const {getSuffixString} = helper;
const {readableTimeFormat, timeZoneAbbreviated, utcToLocalMoment, localToUtcMoment} = momentHelper;

const AppointmentRequestThankYou = props => {
  const history = useHistory();
  const {isShowEditAppointmentButton = true, openEditAppointmentTimeModal, state} = props;
  const {answers, dateAndTimeObject, doctorDetail} = state;
  const dateTimeString = dateAndTimeObject.dateString + ' ' + dateAndTimeObject.timeObject.time;
  const {name, profileImageUrl, specialties, address, suffixes, customSpecialty} = doctorDetail;
  let specialtyNames = _.map(specialties, 'specialty');
  if (customSpecialty) {
    specialtyNames = [...specialtyNames, customSpecialty];
  }

  let appointmentDateTime = moment(dateTimeString, 'YYYY-MM-DD h:mm a', true);
  appointmentDateTime = utcToLocalMoment(localToUtcMoment(appointmentDateTime).format());

  const isVirtual = _.get(doctorDetail, 'isVirtual', false);
  const {hideBackArrow = false} = props;
  return (
    <div className="AppointmentRequest AppointmentRequestthanks">
      <div className="appointment-request-detail">
        <div className="h3">
          {!hideBackArrow && (
            <>
              <span className="back-arrow" onClick={() => history.goBack()}>
                <img src={images.leftArrow} alt="" />
              </span>
              <div>Appointment Request Details </div>
            </>
          )}
        </div>
        <div className="appointment-doctor">
          <span className="doctor-img">
            <img src={profileImageUrl || images.user} alt="doctor" />
          </span>
          <div className="appointment-doctor-info">
            <div className="title">
              {name}
              {getSuffixString(suffixes)}
            </div>
            <div className="Specialist">{specialtyNames.join(', ')}</div>
            <div className="address">
              <span className="icon">
                <img src={images.mapslocation} alt="address" />
              </span>
              {address}
            </div>
          </div>
        </div>
      </div>
      <div className="questionnaire">
        <div className="qu-left">
          {/* <span className="logo-icon">
            <img src={images.logosymbol} alt="logo" />
          </span>
          <div className="title">Questionnaire</div>
          <div className="questionnaire-list">
            {answers
              .filter(q => !HideQuestionID.includes(q.questionId))
              .map((answerObj, index) => {
                return <span key={index}>{answerObj.answer}</span>;
              })}
          </div> */}
          <div className="appointment-map">
            <MyGoogleMap zoom={14} disableMarkerEvent locationDoctorList={[doctorDetail]} />
          </div>
        </div>
        <div className={`qu-right ${isVirtual ? 'inVideo' : 'inPerson'}`}>
          <div className="appointment-time">
            {isVirtual ? (
              <span className="video-visit">
                <img src={images.videoBtn} alt="video" /> Video Visit
              </span>
            ) : (
              <span className="person-visit">
                <img src={images.personBtn} alt="In Person" /> In-Person
              </span>
            )}
            <div className="time">
              <span>
                <span className="day">{appointmentDateTime.format('dddd')}</span>
                <span className="date">{appointmentDateTime.format('MMM DD')}</span>
                {readableTimeFormat(appointmentDateTime)} {` ${timeZoneAbbreviated()}`}
              </span>
            </div>
            {isShowEditAppointmentButton && (
              <span className="edit-appointment" onClick={openEditAppointmentTimeModal}>
                Edit Appointment Time
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentRequestThankYou;
