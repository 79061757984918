import React from 'react';
import modalConfig from 'modals/modalConfig';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import './RemoveStripeProvider.css';

export function RemoveStripeSubscription(props) {
  const {setGlobal} = props.context;
  const {modalFunctions} = modalConfig;
  const {blankModalData} = modalFunctions;  
  const ConfirmComponent = props => {
    const {closeModal} = props;
  
  
    
    const onSubmit = (key) => {
     
        props.resolve(key);
        closeModal();
      
    };
    return (
      <div className="CreatePasswordModal RemoveStripeProvider RemoveStripeSubscription">
        <div className="modal-content-header">
          <div className="h3">Confirm</div>
          <span
            className="close"
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div className="modal-content-body">
          <p>
          Do you wish to remove the subscription for this provider?  If you are planning on replacing the provider, do not remove the subscription otherwise you may be billed a prorated amount when you add the replacement provider.
          </p>

          
        </div>
        <div className="modal-content-footer">
          <CircleButton
           
            onClick={() => {
              onSubmit(false)
            }}>
            Keep Subscription
          </CircleButton>
          <CircleButton
            onClick={() => {
              onSubmit(true)
            }}>
            Remove Subscription
          </CircleButton>
        </div>
      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ConfirmComponent,
        customComponentProps: {resolve: resolve, reject: reject},
        closeOnBGClick: false,
      }),
    );
  });
}
