import React from 'react';
import _ from 'lodash';
import images from 'data/images';
import validators from 'data/validators';
import './Upload.css';

const {__isFile} = validators;

const Upload = props => {
  const prefix = _.get(props, 'prefix', '');
  const fileInputname = _.get(props, 'name', '');
  const label = _.get(props, 'label', '');
  let fileName = _.get(props, 'file.name', '');

  let imageUrl = images.uploadCloud2;
  if (__isFile(props.file)) {
    const _URL = window.URL || window.webkitURL;
    imageUrl = _URL.createObjectURL(props.file);
  } else if (props.fileUrl) {
    imageUrl = props.fileUrl;
  }

  const error = _.get(props, 'error', '');
  const onChange = _.get(props, 'onChange', () => {});
  return (
    <div className="Upload form-group">
      <span className="label">{label}</span>
      <input
        data-prefix={prefix}
        type="file"
        accept="image/*"
        name={`${fileInputname}`}
        onChange={onChange}
        onClick={event => {
          event.target.value = null;
        }}
        className="form-control"
        title={fileName || 'No file chosen'}
      />
      <img src={imageUrl} alt="search" />
      <span className="filename"> {fileName || 'Upload'} </span>
      {error && <span className="error-text"> {error} </span>}
    </div>
  );
};

export default Upload;
