import React, {Component} from 'react';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import adminService from 'services/admin';
import momentHelper from 'data/moment/momentHelper'; 
import ReactPaginate from 'react-paginate';
import constants from 'data/constants';
import _ from 'lodash';
import './Subscriptions.css';

import images from 'data/images';
import Select from 'components/FormControls/Select';
const {bigDateTimeFormat}=momentHelper
const {DefaultDebounceMSec} = constants;
const initialState = {
  list: [],
  stateList: [
    {label:"Active",value:"1"},
    {label:"Inactive",value:"0"}
  ],
  cityList: [],
  activePage: 1,
  totalPage: 0,
  pageLimit: 10,
  selectedStatus:"",
  sortBy: 'firstName',
  orderBy: 'asc',
  totalRecord: 0,
};

const {subscriptions} = adminService;

const {modalFunctions} = modalConfig;
const {errorModalData, } = modalFunctions;


class GhostProfiles extends Component {
  static contextType = RootContext;
  state = {...initialState};

  componentDidMount() {
    this.getSubscriptions();
  
  }

  componentWillMount = () => {
    this.props.history.replace({state: {...initialState}});
  };

  getSubscriptions = async () => {
    const {location} = this.props;
    
      const {
        activePage,
        pageLimit,
        searchText,
        selectedStatus,
     
        sortBy,
        orderBy,
      } = this.state;
      let passData= {
        page: activePage,
        limit: pageLimit,
        search: searchText,
        status:selectedStatus,          
        sortBy: sortBy,
        orderBy: orderBy,
      }
      if(selectedStatus===""){
        delete passData.status
      }
      const res = await subscriptions(
        passData
      );
      this.setState({
        list: _.get(res, 'data.list', []),
        totalPage: _.get(res, 'data.totalPage', 0),
        totalRecord: _.get(res, 'data.totalRecord', 0),
      });
    
  };

 

 

  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.getSubscriptions);
  };

  handleError = error => {
    const {setGlobal} = this.context;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  

  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.getSubscriptions();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };

  handleSearch = () => {
    this.setState({activePage: 1}, this.getSubscriptions);
  };

  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.getSubscriptions);
  };



  handleStateSearch = event => {
    
    this.setState(
      {
        selectedStatus:event.target.value,
        activePage: 1,
      },
      this.getSubscriptions,
    );
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.getSubscriptions,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.getSubscriptions);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  render() {
    const {list, totalPage, searchText, stateList, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    const title = 'Subscriptions';
    return (
      <Layout>
        <div className="GhostProfiles">
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div className="select-search">
                <Select
                  options={stateList}
                  labelIndex="label"
                  valueIndex="value"
                  name="stateId"
                  defaultOptionProp={{value: '', label: 'Both'}}
                  isAllowDefaultOpen={true}
                  onChange={this.handleStateSearch}
                  value={this.state.selectedStatus}
                />
              </div>
              
              <div className="input-search">
                <div className="input-control">
                  <input
                    placeholder="Search"
                    value={searchText}
                    onChange={this.handleChange}></input>
                  {searchText && (
                    <button className="btn-clear" onClick={this.handleClearSearch}>
                      <img src={images.close} alt="clear" />
                    </button>
                  )}
                </div>
                <button onClick={this.handleSearch}>Search</button>
              </div>
            </div>
            <div className="ghostCount">
              <span>{totalRecord} Subscriptions found.</span>
            </div>
            <div className="table-overflow">
              <div className="rtable">
                <div className="heading">
                <div
                    className="cell pointer"
                    data-key="practiceName"
                    onClick={this.handleSortingChange}>
                    Practice Name{this.renderShortIcon('practiceName')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="firstName"
                    onClick={this.handleSortingChange}>
                    Provider Name{this.renderShortIcon('firstName')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="email"
                    onClick={this.handleSortingChange}>
                   Email{this.renderShortIcon('email')}
                  </div>
                 
                 
                  <div
                    className="cell pointer"
                    data-key="quantity"
                    onClick={this.handleSortingChange}>
                   Quantity{this.renderShortIcon('quantity')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="nextBillDateTime"
                    onClick={this.handleSortingChange}>
                   Next Bill Date{this.renderShortIcon('nextBillDateTime')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="customerId"
                    onClick={this.handleSortingChange}>
                    Customer Id{this.renderShortIcon('customerId')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="paymentMethodId"
                    onClick={this.handleSortingChange}>
                    Payment MethodId{this.renderShortIcon('paymentMethodId')}
                  </div>
                  <div
                    className="cell pointer"
                    data-key="subscriptionId"
                    onClick={this.handleSortingChange}>
                    Subscription Id{this.renderShortIcon('subscriptionId')}
                  </div>
                  <div className="cell edit-cell">Status</div>
                </div>
                {_.map(list, (item, index) => (
                  <div className={ 'row'} key={index}>
                    <div className="cell">{_.get(item, 'practiceName', '')}</div>
                    <div className="cell">
                        {`${item.firstName} ${item.lastName}`}
                    </div>
                    <div className="cell">{_.get(item, 'email', '')}</div>                    
                    <div className="cell">{_.get(item, 'quantity', '0')}</div>
                    <div className="cell">{item.nextBillDateTime?bigDateTimeFormat(item.nextBillDateTime):''}</div>
                    <div className="cell">{_.get(item, 'customerId', '')}</div>
                    <div className="cell">{_.get(item, 'paymentMethodId', '')}</div>
                    <div className="cell">{_.get(item, 'subscriptionId', '')}</div>
                    <div className="cell">{{0:'Inactive',1:'Active'}[_.get(item, 'status', '')]}</div>
                  </div>
                ))}
              </div>
            </div>
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default GhostProfiles;
