import React, {useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import Divider from 'components/Divider';
import validators from 'data/validators';
import config from 'config/config';
import SocialButton from 'components/SocialButton';
import './PatientSignUpStepOne.css';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import userService from 'services/user';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import constants from 'data/constants';
const {DEFAULT_MESSAGES} = constants;
const {__required, __password, __email, __maxLength} = validators;
const {MAX_TEXT_LENGTH} = config;
const {modalFunctions} = modalConfig;
const {errorModalData} = modalFunctions;
const {verifyEmail} = userService;
const {Analytics} = analyticsData;

const PatientSignUpStepOne = props => {
  const {nextStep, handleSocialLoginSuccess, handleSocialLoginFailure, data} = props;
  const values = {...data};
  const errors = {};
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const {values: formValues, errors: formErrors} = formData;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;

  // Validation functions
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  const __validatePassword = (value = null) => {
    const fValue = value !== null ? value : formValues['password'];
    if (!__required(fValue)) {
      return 'Password is required';
    }
    if (!__password(fValue)) {
      return 'Invalid password';
    }
    return null;
  };
  const __validateForm = () => {
    const validEmail = !__validateEmail();
    const validPassword = !__validatePassword();
    return validEmail && validPassword;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      case 'password':
        errorObject = {
          [name]: __validatePassword(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleForm = async () => {
    Nux.sendEvent(Analytics.Category.PatientSignUp, Analytics.Actions.Click, 'createAccount');
    const isExist = await verifyEmail({email: formValues['email']}).catch(handleError);
    if (isExist) {
      nextStep(formValues);
    }
  };

  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  return (
    <div className="PatientSignUpStepOne">
      <div className="ca-social">
        <SocialButton
          className="social-icon"
          provider="google"
          appId={config.GOOGLE_CLIENT_ID}
          scope="profile email"
          onLoginSuccess={data => handleSocialLoginSuccess('google', data)}
          onLoginFailure={error => handleSocialLoginFailure('google', error)}>
          <img src={images.Googleicon} alt="facebook icon" />
          Continue with Google
        </SocialButton>
        <SocialButton
          className="social-icon"
          provider="facebook"
          appId={config.FACEBOOK_CLIENT_ID}
          scope="email,public_profile,email"
          onLoginSuccess={data => handleSocialLoginSuccess('facebook', data)}
          onLoginFailure={error => handleSocialLoginFailure('facebook', error)}>
          <img src={images.facebookblue} alt="google icon" />
          Continue with Facebook
        </SocialButton>
        <SocialButton
          className="social-icon"
          provider="apple"
          appId={config.FACEBOOK_CLIENT_ID}
          scope="email,public_profile,email"
          onLoginSuccess={data => handleSocialLoginSuccess('apple', data)}
          onLoginFailure={error => handleSocialLoginFailure('apple', error)}>
          <img src={images.facebookblue} alt="google icon" />
          Continue with Apple
        </SocialButton>
      </div>
      <div className="divider-content">
        <Divider></Divider>
        <span>Or</span>
      </div>
      <form>
        <Input {...getGeneralInputProps('email')} translationType="transform" label="Email" />
        <Input
          {...getGeneralInputProps('password')}
          type="password"
          translationType="transform"
          label="Password"
          icon={images.eyeView}
          containerClass="password-group"
        />
        <div className="password-label">
         {DEFAULT_MESSAGES.PASSWORD_INSTRUCTION_TEXT}
        </div>
        <div className="login-btn">
          <CircleButton disabled={!isValidForm} onClick={handleForm}>
            Create Account
          </CircleButton>
        </div>
        <div className="login-bottom">
          Already a member? <Link to="/patient/login"> Click here to login</Link>
        </div>
      </form>
    </div>
  );
};

export default PatientSignUpStepOne;
