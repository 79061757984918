const questionList = [
  {
    id: 361,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [9, 11, 13], // painIds,
    nextQuestionId: 362,
    descriptions: [],
  },
  {
    id: 362,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 363,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 9, questionId: 183, visitReasonIds: [39], extraQuestion: 1},
          {painId: 11, questionId: 203, visitReasonIds: [35], extraQuestion: 1},
          {painId: 13, questionId: 203, visitReasonIds: [], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 363,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 9, questionId: 184, visitReasonIds: [39], extraQuestion: 3},
          {painId: 11, questionId: 204, visitReasonIds: [35], extraQuestion: 3},
          {painId: 13, questionId: 204, visitReasonIds: [], extraQuestion: 3},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 363,
    question: 'Are you interested in potentially undergoing surgery?',
    options: [
      {nextQuestionId: 364, label: 'Yes', shortLabel: 'Interested in surgery'},
      {nextQuestionId: 364, label: 'No', shortLabel: 'No surgery'},
    ],
    descriptions: [],
  },
  {
    id: 364,
    question: 'Do you still want to see a Hand Surgeon?',
    descriptions: [
      'A Hand Surgeon is a medical physician (MD/DO) who completed a 1 year fellowship in Hand Surgery, after completing a 5 year residency in Orthopedic surgery. A Hand Surgeon has further training in treating hand or wrist conditions that may require surgery.',
    ],
    title: 'Hand Surgeon',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can perform pain injections',
    ],
    options: [
      {groupId: 'V', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 361,
    lastQuestionId: 364,
    treeDepth: 4,
  },
};
