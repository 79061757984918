import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import images from 'data/images';
import EditLocation from './EditLocation';
import practiceData from 'data/practiceData';
import './EditPracticeLocations.css';

const {defaultPracticeLocation} = practiceData;

const EditPracticeLocations = props => {
  const {locationList: list, onSaveLocation, onDeleteLocation, hasPermissionToEdit} = props;
  const [locationList, setLocationList] = useState(list);
  useEffect(() => {
    setLocationList(list);
  }, [list]);

  const addItem = () => {
    if (locationList[0].id !== 0) {
      const updatedLocationList = [{...defaultPracticeLocation}, ...locationList];
      setLocationList(updatedLocationList);
    }
  };
  const removeItem = locationId => {
    if (locationId) {
      onDeleteLocation(locationId);
    } else {
      const updatedLocationList = _.filter(list, item => item.id !== locationId);
      setLocationList(updatedLocationList);
    }
  };
  return (
    <div className="EditPracticeLocations box-content">
      <div className="h3">Office Locations</div>
      <div className="EditPracticeLocations-inner">
        {hasPermissionToEdit && (
          <div className="add-location" onClick={addItem}>
            <span className="plus-icon">
              <img src={images.blueCross} alt="" />
            </span>
            Add Location
          </div>
        )}
        {locationList.map(location => (
          <EditLocation
            location={location}
            open={location.id === 0}
            key={location.id}
            onSaveLocation={onSaveLocation}
            onDeleteLocation={removeItem}
            hasPermissionToEdit={hasPermissionToEdit}
          />
        ))}
      </div>
    </div>
  );
};

export default EditPracticeLocations;
