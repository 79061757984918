import React from 'react';

const MyLink = ({to, children, className, ...remainingProps}) => {
  return (
    <a
      href={to}
      target="_blank"
      className={`MyLink ${className}`}
      rel="noopener noreferrer"
      {...remainingProps}>
      {children}
    </a>
  );
};

export default MyLink;
