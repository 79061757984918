import React, {useState, useContext} from 'react';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import validators from 'data/validators';
import images from 'data/images';
import './UpdateConfigModal.css';
import Textarea from 'components/FormControls/Textarea';
import adminService from 'services/admin';
import modalConfig from 'modals/modalConfig';
import _ from 'lodash';

const {__required, __email, __isValidUrl, __isNumeric} = validators;
const {updateSiteSetting, updateSiteSettingFile} = adminService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;

const UpdateConfigModal = props => {
  const {data} = props;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const isMultipleValue = _.get(data, 'isMultipleValue', false);
  let oValue = {};
  if (isMultipleValue) {
    _.forEach(_.get(data, 'value', []), (item, index) => {
      oValue['value' + index] = item;
    });
  } else {
    oValue['value'] = _.get(data, 'value', '');
  }

  const [formData, setFormData] = useState({
    values: {...oValue},
    errors: {serverError: null},
  });
  const {values: formValues, errors: formErrors} = formData;

  const __validateValue = (value = null) => {
    const type = _.get(data, 'type', 1);
    const fValue = value !== null ? value : formValues['value0'];
    if (!__required(fValue)) {
      return 'Value is required';
    }
    if (type === 5 && !__email(fValue)) {
      //email
      return 'Invalid Email';
    }
    if (type === 6 && !__isNumeric(fValue)) {
      return 'Invalid number';
    }
    if (type === 7 && !__isNumeric(fValue)) {
      return 'Invalid number';
    }
    if (type === 8 && !__isValidUrl(fValue)) {
      return 'Invalid URL';
    }
    return null;
  };

  const __validateAllValue = () => {
    const type = _.get(data, 'type', 1);
    let isValid = true;
    _.forOwn(formValues, (value, key) => {
      if (!__required(value)) {
        isValid = false;
      }
      if (type === 5 && !__email(value)) {
        //email
        isValid = false;
      }
      if (type === 6 && !__isNumeric(value)) {
        isValid = false;
      }
      if (type === 7 && !__isNumeric(value)) {
        isValid = false;
      }
      if (type === 8 && !__isValidUrl(value)) {
        isValid = false;
      }
    });
    if (!isValid) return 'Value is required';
    return null;
  };

  const changeHandler = event => {
    let {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    errorObject = {
      [name]: __validateValue(value),
    };
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value.trim(),
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const closeModal = () => {
    setGlobal('modal', {showModal: false});
  };

  const __validateForm = () => {
    const validValue = !__validateAllValue();
    return validValue;
  };

  const isValidateForm = __validateForm();

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="Close" title="Close" />
    </span>
  );

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">Update : {_.get(data, 'title', '')}</div>
      {crossIconComponent}
    </div>
  );

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  const handleSubmit = async event => {
    event.preventDefault();
    let response = null;
    if (isMultipleValue) {
      let ValArr = [];
      _.forOwn(formValues, (value, key) => {
        ValArr.push(value);
      });
      response = await updateSiteSetting(_.get(data, 'slug', ''), {value: ValArr}).catch(
        handleError,
      );
      props.updateList();
    } else {
      response = await updateSiteSetting(_.get(data, 'slug', ''), {
        value: _.get(formValues, 'value', ''),
      }).catch(handleError);
      props.updateList();
    }
    if (response) {
      const {message} = response;
      setGlobal('modal', successModalData(message));
    }
  };

  const isValidFormat = fileName => {
    try {
      const getExtentions = fileName.lastIndexOf('.');
      const ext = fileName.substring(getExtentions + 1);
      if (['svg', 'jpeg', 'jpg', 'png'].indexOf(ext) > -1) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  const handleFileUpload = event => {
    const {files} = event.target;
    if (files.length > 0) {
      if (isValidFormat(files[0].name) || _.get(data, 'type', 1) === 4) {
        uploadFile(files[0]);
      } else {
        setGlobal('modal', errorModalData('You can only select svg, jpeg, jpg or png file.'));
      }
    }
  };

  const uploadFile = async file => {
    try {
      let fileFormData = new FormData();
      fileFormData.append('file', file);
      let res = await updateSiteSettingFile(_.get(data, 'slug', ''), fileFormData);
      const {message} = res;
      props.updateList();
      setGlobal('modal', successModalData(message));
    } catch (ex) {
      handleError(ex);
    }
  };

  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  const handleAddNew = () => {
    const size = _.size(formValues);
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        ['value' + size]: '',
      },
    });
  };

  const handleRemove = filterKey => {
    let nValue = {};
    let index = 0;
    _.forOwn(formValues, (value, key) => {
      if (filterKey !== key) {
        nValue['value' + index] = value;
        index++;
      }
    });
    setFormData({
      ...formData,
      values: {
        ...nValue,
      },
    });
  };

  const inputArr = [];
  const size = _.size(formValues);
  const type = _.get(data, 'type', 1);
  if (isMultipleValue) {
    _.forOwn(formValues, (value, key) => {
      if (key === 'value0') {
        inputArr.push(
          <div className="input-close">
            {type === 2 && (
              <Textarea {...getGeneralInputProps(key)} placeholder="Enter Value" label="Value" />
            )}
            {type !== 2 && (
              <Input {...getGeneralInputProps(key)} placeholder="Enter Value" label="Value" />
            )}
            {size > 1 && (
              <img
                src={images.close}
                alt=""
                onClick={() => handleRemove(key)}
                title="Remove"
              />
            )}
          </div>,
        );
      } else {
        inputArr.push(
          <div className="input-close">
            <Input {...getGeneralInputProps(key)} key={key} placeholder="Enter Value" />
            {size > 1 && (
              <img
                src={images.close}
                alt=""
                onClick={() => handleRemove(key)}
                title="Remove"
              />
            )}
          </div>,
        );
      }
    });
  }

  const renderInputElements = () => {
    if (!isMultipleValue) {
      if (type === 2) {
        return (
          <Textarea {...getGeneralInputProps('value')} placeholder="Enter Value" label="Value" />
        );
      } else if (type === 3) {
        return (
          <>
            <img src={_.get(formValues, 'value', '')} alt="" className="img-preview"></img>
            <div className="action-btn btn-change">
              <div className="fileupload">
                <span className="fileupload-btn">
                  <input
                    type="file"
                    accept=".svg,.jpeg,.jpg,.png"
                    onChange={handleFileUpload}
                    onClick={event => {
                      event.target.value = null;
                    }}
                  />
                  <button>Change</button>
                </span>
              </div>
            </div>
          </>
        );
      } else if (type === 4) {
        return (
          <>
            <Input value={_.get(formValues, 'value', '')} label="File" />
            <div className="action-btn btn-change">
              <div className="fileupload">
                <span className="fileupload-btn">
                  <input
                    type="file"
                    onChange={handleFileUpload}
                    onClick={event => {
                      event.target.value = null;
                    }}
                  />
                  <button>Change</button>
                </span>
              </div>
            </div>
          </>
        );
      } else {
        return <Input {...getGeneralInputProps('value')} placeholder="Enter Value" label="Value" />;
      }
    } else if (isMultipleValue) {
      return inputArr;
    }
  };

  return (
    <div className="UpdateConfigModal no-select">
      <div className="modal-body-wrapper">
        {dialogHeader}
        <div className="dailog-body">
          <form onSubmit={handleSubmit}>
            {renderInputElements()}
            {isMultipleValue && (
              <span className="link-add-new" onClick={handleAddNew}>
                Add New
              </span>
            )}
          </form>
        </div>
        {type !== 3 && type !== 4 && (
          <div className="action-btn">
            <CircleButton onClick={closeModal} btnType="secondaryButton">
              Cancel
            </CircleButton>
            <CircleButton btnType="whiteButton" disabled={!isValidateForm} onClick={handleSubmit}>
              Update
            </CircleButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateConfigModal;
