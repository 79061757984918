import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import GoogleMapReact from 'google-map-react';
import config from 'config/config';
import helper from 'data/helper';
import Marker from './Marker';
import './MyGoogleMap.css';

const {getCenterPoint} = helper;
const {GOOGLE_MAP_KEY} = config;

// #set default "manhattan"'s latitude and longitude
const defaultCenter = {
  lat: 40.758896,
  lng: -73.98513,
};

const defaultZoom = 14;

const MyGoogleMap = props => {
  const {
    availabilityType,
    locationDoctorList = [],
    zoom = defaultZoom,
    onTimeSelect = () => {},
    disableMarkerEvent = false,
  } = props;
  const [activeLocation, setActiveLocation] = useState(null);
  useEffect(() => {
    if (activeLocation) {
      setActiveLocation(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityType]);

  let newData = {};
  let locationIds = [];
  locationDoctorList.forEach(locationDoctor => {
    const {locationId, address, latitude, longitude, isVirtual} = locationDoctor;
    if (isVirtual) return;
    if (!newData[locationId]) {
      newData[locationId] = {
        locationId,
        address,
        latitude,
        longitude,
        doctors: [],
      };
      locationIds.push(locationId);
    }
    newData[locationId].doctors.push(locationDoctor);
  });
  let newDataArr = _.values(newData);
  let centerPoint = defaultCenter;
  if (newDataArr.length > 0) {
    centerPoint = getCenterPoint(newDataArr);
  }
  return (
    <div className="MyGoogleMap" style={{height: '100%', width: '100%'}}>
      <GoogleMapReact
        bootstrapURLKeys={{key: `${GOOGLE_MAP_KEY}`, libraries: []}}
        center={centerPoint}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals={true}
        options={maps => {
          return {
            maxZoom: 18,
            clickableIcons: false,
            zoomControl: true,
            zoomControlOptions: {
              position: maps.ControlPosition.TOP_RIGHT,
            },
          };
        }}
        onGoogleApiLoaded={({map, maps}) =>
          !disableMarkerEvent && locationIds.length > 1 && apiIsLoaded(map, maps, newData)
        }>
        {_.map(newData, (locationDoctor, locationId) => {
          const {latitude, longitude} = locationDoctor;
          return (
            <Marker
              setActiveLocation={setActiveLocation}
              activeLocation={activeLocation}
              lat={latitude}
              lng={longitude}
              key={locationId}
              locationDoctor={locationDoctor}
              onTimeSelect={onTimeSelect}
              disableMarkerEvent={disableMarkerEvent}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default MyGoogleMap;

// Return map bounds based on list of locations
const getMapBounds = (map, maps, locations) => {
  const bounds = new maps.LatLngBounds();

  _.map(locations, location => {
    bounds.extend(new maps.LatLng(location.latitude, location.longitude));
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, locations) => {
  // Get bounds by our locations
  const bounds = getMapBounds(map, maps, locations);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};
