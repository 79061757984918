import React, {Component} from 'react';
import Header from 'layout/Header';
import './CCPAPolicy.css';

class CCPAPolicy extends Component {
  render() {
    return (
      <>
        <Header hideHeaderButtons={true}></Header>
        <div className="CCPAPolicy">
          <div className="container">
            <div className="template-inner">
              <div className="main-header">KALY PRIVACY NOTICE FOR CALIFORNIA RESIDENTS</div>
              <div className="template-content">
                <div className="sub-header-title">Effective Date October 21, 2021</div>
                <div className="para">
                  This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS supplements the information contained
                  in the Privacy Policy of Kaly Inc. and its subsidiaries (collectively, “we,”
                  “us,” or “our”) and applies solely to visitors, users, and others who reside in
                  the State of California (“consumers” or “you”). We adopt this notice to comply
                  with the California Consumer Privacy Act of 2018 (“CCPA”) and other California
                  privacy laws. Any terms defined in the CCPA have the same meaning when used in
                  this notice.
                </div>
                <div className="para text-bold">Information We Collect</div>
                <div className="para">
                  We collect information that identifies, relates to, describes, references, is
                  capable of being associated with, or could reasonably be linked, directly or
                  indirectly, with a particular consumer or device (“personal information”). In
                  particular, we may have collected the following categories of personal information
                  from consumers within the last twelve (12) months:
                  <table className="tbl-with-style">
                    <tbody>
                      <tr>
                        <th>Categories</th>
                        <th>Examples of Data</th>
                        <th>Collected?</th>
                      </tr>
                      <tr>
                        <td>A. Identifiers.</td>
                        <td>
                          A real name, email address, telephone number, postal address, online
                          identifier, user ID, device ID, domain server, type of device/operating
                          system, browser used to access our services, Internet Protocol address,
                          account name, driver's license number, passport number, or other similar
                          identifiers.
                        </td>
                        <td>YES</td>
                      </tr>
                      <tr>
                        <td>
                          B. Personal information categories listed in the California Customer
                          Records statute (Cal. Civ. Code § 1798.80(e)).
                        </td>
                        <td>
                          A real name, email address, telephone number, postal address, passport
                          number, driver's license or state identification card number, insurance
                          information (including insurance carrier, insurance plan, member ID, group
                          ID, payer ID), education, employment, employment history, medical
                          information, or health insurance information. Some personal information
                          included in this category may overlap with other categories.
                        </td>
                        <td>YES</td>
                      </tr>
                      <tr>
                        <td>
                          C. Protected classification characteristics under California or federal
                          law.
                        </td>
                        <td>
                          Age, race, ethnicity, color, ancestry, national origin, citizenship, zip
                          code, religion or creed, marital status, medical condition, physical or
                          mental disability, sex (including gender, gender identity, gender
                          expression, pregnancy or childbirth and related medical conditions),
                          sexual orientation, veteran or military status, genetic information
                          (including familial genetic information).
                        </td>
                        <td>YES</td>
                      </tr>
                      <tr>
                        <td>D. Commercial information.</td>
                        <td>
                          Records of personal property, products or services purchased, obtained, or
                          considered, or other purchasing or consuming histories or tendencies.
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>E. Biometric information.</td>
                        <td>
                          Genetic, physiological, behavioral, and biological characteristics, or
                          activity patterns used to extract a template or other identifier or
                          identifying information, such as, fingerprints, faceprints, and
                          voiceprints, iris or retina scans, keystroke, gait, or other physical
                          patterns, and sleep or exercise data.
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>F. Internet or other similar network activity.</td>
                        <td>
                          Browsing history, search history, information on a consumer's interaction
                          with a website, application, or advertisement, referring webpage/source
                          through which you access our services, non-identifiable request IDs, and
                          statistics associated with the interaction between device or browser and
                          our services.
                        </td>
                        <td>YES</td>
                      </tr>
                      <tr>
                        <td>G. Geolocation data.</td>
                        <td>
                          Approximate location (city and state) of the device from which you access
                          out services.
                        </td>
                        <td>YES</td>
                      </tr>
                      <tr>
                        <td>H. Sensory data.</td>
                        <td>
                          Audio, electronic, visual, thermal, olfactory, or similar information.
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>I. Professional or employment-related information.</td>
                        <td>Current or past job history.</td>
                        <td>YES</td>
                      </tr>
                      <tr>
                        <td>
                          J. Non-public education information (per the Family Educational Rights and
                          Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).
                        </td>
                        <td>
                          Education records directly related to a student maintained by an
                          educational institution or party acting on its behalf, such as grades,
                          transcripts, class lists, student schedules, student identification codes,
                          student financial information, or student disciplinary records.
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>K. Inferences drawn from other personal information.</td>
                        <td>
                          Profile reflecting a person's preferences, characteristics, psychological
                          trends, predispositions, behavior, attitudes, intelligence, abilities, and
                          aptitudes.
                        </td>
                        <td>NO</td>
                      </tr>
                      <tr>
                        <td>L. Medical Data</td>
                        <td>
                          Medical history and health information you provide us, including health
                          conditions, healthcare providers visited, reasons for visit, dates of
                          visit, booking and appointment data (including appointment date/time,
                          provider information, appointment procedure, whether or not user is a new
                          patient for a particular provider).
                        </td>
                        <td>YES</td>
                      </tr>
                      <tr>
                        <td>
                          M. Other Identifying Information That You Voluntarily Chose to Provide
                        </td>
                        <td>
                          Personal Data in emails, letters or online forms that you send or submit
                          to us.
                        </td>
                        <td>YES</td>
                      </tr>
                    </tbody>
                  </table>
                  Personal information does not include:
                  <ul>
                    <li>Publicly available information from government records.</li>
                    <li>De-identified or aggregated consumer information.</li>
                    <li>
                      Information excluded from the CCPA's scope, like:
                      <ul>
                        <li>
                          health or medical information covered by the Health Insurance Portability
                          and Accountability Act of 1996 (HIPAA) and the California Confidentiality
                          of Medical Information Act (CMIA) or clinical trial data;
                        </li>
                        <li>
                          personal information covered by certain sector-specific privacy laws,
                          including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act
                          (GLBA) or California Financial Information Privacy Act (FIPA), and the
                          Driver's Privacy Protection Act of 1994.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  We obtain the categories of personal information listed above from the following
                  categories of sources:
                  <ul>
                    <li>
                      Directly from our clients or their agents. For example, from information that
                      our clients provide to us related to the services for which they engage us.
                    </li>
                    <li>
                      Indirectly from Physician Providers or their agents. For example, through
                      information we collect from Physician Providers in the course of providing
                      services to them.
                    </li>
                    <li>
                      Directly and indirectly from activity on our website (Kaly.com). For
                      example, from submissions through our website portal or website usage details
                      collected automatically.
                    </li>
                    <li>
                      From third-parties that interact with us in connection with the services we
                      perform.
                    </li>
                  </ul>
                </div>
                <div className="para text-bold">Use of Personal Information</div>
                <div className="para">
                  We may use or disclose the personal information we collect for one or more of the
                  following business purposes:
                  <ul>
                    <li>
                      To fulfill or meet the reason for which the information is provided. For
                      example, if you provide us with personal information in order for us to
                      provide you with a selection of healthcare providers relevant to the criteria
                      you input, we will use that information to prepare and submit to you a
                      selection of such healthcare providers.
                    </li>
                    <li>
                      To provide you with information, products or services that you request from
                      us.
                    </li>
                    <li>
                      To provide you with email alerts and other notices concerning our products or
                      services, or events or news, that may be of interest to you.
                    </li>
                    <li>
                      To carry out our obligations and enforce our rights arising from any contracts
                      entered into between you and us, including for billing and collections.
                    </li>
                    <li>To improve our website and present its contents to you.</li>
                    <li>For testing, research, analysis and development of service offerings.</li>
                    <li>
                      As necessary or appropriate to protect the rights, property or safety of us,
                      our clients or others.
                    </li>
                    <li>
                      To respond to law enforcement requests and as required by applicable law,
                      court order, or governmental regulations.
                    </li>
                    <li>
                      As described to you when collecting your personal information or as otherwise
                      set forth in the CCPA.
                    </li>
                    <li>
                      To evaluate or conduct a merger, divestiture, restructuring, reorganization,
                      dissolution, or other sale or transfer of some or all of our assets, whether
                      as a going concern or as part of bankruptcy, liquidation, or similar
                      proceeding, in which personal information held by us is among the assets
                      transferred.
                    </li>
                  </ul>
                  We will not collect additional categories of personal information or use the
                  personal information we collected for materially different, unrelated, or
                  incompatible purposes without providing you notice.
                </div>
                <div className="para text-bold">Sharing Personal Information</div>
                <div className="para">
                  We may disclose your personal information to a third party for a business purpose.
                  When we disclose personal information for a business purpose, we enter a contract
                  that describes the purpose and requires the recipient to both keep that personal
                  information confidential and not use it for any purpose except performing the
                  contract.
                </div>
                <div className="para">
                  In the preceding twelve (12) months, we have or may have disclosed the following
                  categories of personal information for a business purpose:
                  <table>
                    <tbody>
                      <tr>
                        <td>Category A:</td>
                        <td>Identifiers.</td>
                      </tr>
                      <tr>
                        <td>Category B:</td>
                        <td>California Customer Records personal information categories.</td>
                      </tr>
                      <tr>
                        <td>Category C:</td>
                        <td>
                          Protected classification characteristics under California or federal law.
                        </td>
                      </tr>
                      <tr>
                        <td>Category F:</td>
                        <td>Internet or other similar network activity.</td>
                      </tr>
                      <tr>
                        <td>Category G:</td>
                        <td>Geolocation Data.</td>
                      </tr>
                      <tr>
                        <td>Category I:</td>
                        <td>Professional or employment-related information.</td>
                      </tr>
                      <tr>
                        <td>Category L:</td>
                        <td>Medical Data.</td>
                      </tr>
                      <tr>
                        <td>Category M:</td>
                        <td>
                          Other Identifying Information That You Voluntarily Chose to Provide.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br/>
                  We disclose your personal information for a business purpose to the following
                  categories of third parties:
                  <ul>
                    <li>Our affiliates.</li>
                    <li>Service providers, including Physician Providers.</li>
                    <li>
                      Third parties to whom you or your agents authorize us to disclose your
                      personal information in connection with products or services we provide to
                      you.
                    </li>
                  </ul>
                  In the preceding twelve (12) months, we have not sold any personal information.
                </div>
                <div className="para text-bold">Your Rights and Choices</div>
                <div className="para">
                  The CCPA provides consumers (California residents) with specific rights regarding
                  their personal information. This section describes your CCPA rights and explains
                  how to exercise those rights.
                </div>
                <div className="para">
                  <i>Access to Specific Information and Data Portability Rights</i>
                  <br />
                  You have the right to request that we disclose certain information to you about
                  our collection and use of your personal information over the past 12 months. Once
                  we receive and confirm your verifiable consumer request, we will disclose to you:
                  <ul>
                    <li>The categories of personal information we collected about you.</li>
                    <li>
                      The categories of sources for the personal information we collected about you.
                    </li>
                    <li>
                      Our business or commercial purpose for collecting or selling that personal
                      information.
                    </li>
                    <li>
                      The categories of third parties with whom we share that personal information.
                    </li>
                    <li>
                      The specific pieces of personal information we collected about you (also
                      called a data portability request).
                    </li>
                    <li>
                      If we sold or disclosed your personal information for a business purpose, two
                      separate lists disclosing:
                      <ul>
                        <li>
                          sales, identifying the personal information categories that each category
                          of recipient purchased; and
                        </li>
                        <li>
                          disclosures for a business purpose, identifying the personal information
                          categories that each category of recipient obtained.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="para">
                  <i>Deletion Request Rights</i>
                  <br />
                  You have the right to request that we delete any of your personal information that
                  we collected from you and retained, subject to certain exceptions. Once we receive
                  and confirm your verifiable consumer request, we will delete (and direct our
                  service providers to delete) your personal information from our records, unless an
                  exception applies.
                </div>
                <div className="para">
                  We may deny your deletion request if retaining the information is necessary for us
                  or our service providers to:
                  <ol>
                    <li>
                      Complete the transaction for which we collected the personal information,
                      provide a good or service that you requested, take actions reasonably
                      anticipated within the context of our ongoing business relationship with you,
                      or otherwise perform our contract with you.
                    </li>
                    <li>
                      Detect security incidents, protect against malicious, deceptive, fraudulent,
                      or illegal activity, or prosecute those responsible for such activities.
                    </li>
                    <li>
                      Debug products to identify and repair errors that impair existing intended
                      functionality.
                    </li>
                    <li>
                      Exercise free speech, ensure the right of another consumer to exercise their
                      free speech rights, or exercise another right provided for by law.
                    </li>
                    <li>
                      Comply with the California Electronic Communications Privacy Act (Cal. Penal
                      Code § 1546 seq.).
                    </li>
                    <li>
                      Engage in public or peer-reviewed scientific, historical, or statistical
                      research in the public interest that adheres to all other applicable ethics
                      and privacy laws, when the information's deletion may likely render impossible
                      or seriously impair the research's achievement, if you previously provided
                      informed consent.
                    </li>
                    <li>
                      Enable solely internal uses that are reasonably aligned with consumer
                      expectations based on your relationship with us.
                    </li>
                    <li>Comply with a legal obligation.</li>
                    <li>
                      Make other internal and lawful uses of that information that are compatible
                      with the context in which you provided it.
                    </li>
                  </ol>
                </div>
                <div className="para">
                  <i>Exercising Access, Data Portability, and Deletion Rights</i>
                  <br />
                  To exercise the access, data portability, and deletion rights described above,
                  please submit a verifiable consumer request to us by either:
                  <ul>
                    <li>
                      Calling us at <a href="tel:9737355775">9737355775</a>
                    </li>
                    <li>
                      <a href="mailto:hello@kaly.com">hello@kaly.com</a>
                    </li>
                  </ul>
                </div>
                <div className="para">
                  Only you or a person registered with the California Secretary of State that you
                  authorize to act on your behalf, may make a verifiable consumer request related to
                  your personal information. You may also make a verifiable consumer request on
                  behalf of your minor child.
                </div>
                <div className="para">
                  You may only make a verifiable consumer request for access or data portability
                  twice within a 12-month period. The verifiable consumer request must:
                  <ul>
                    <li>
                      Provide sufficient information that allows us to reasonably verify you are the
                      person about whom we collected personal information or a legally authorized
                      representative.
                    </li>
                    <li>
                      Describe your request with sufficient detail that allows us to properly
                      understand, evaluate, and respond to it.
                    </li>
                  </ul>
                </div>
                <div className="para">
                  We cannot respond to your request or provide you with personal information if we
                  cannot verify your identity or authority to make the request and confirm the
                  personal information relates to you. Making a verifiable consumer request does not
                  require you to create an account with us. We will only use personal information
                  provided in a verifiable consumer request to verify the requestor's identity or
                  authority to make the request.
                </div>
                <div className="para">
                  <i>Response Timing and Format</i>
                  <br />
                  We endeavor to respond to a verifiable consumer request within 45 days of its
                  receipt. If we require more time (up to 90 days), we will inform you of the reason
                  and extension period in writing. If you have an account with us, we will deliver
                  our written response to that account. If you do not have an account with us, we
                  will deliver our written response by mail or electronically, at your option. Any
                  disclosures we provide will only cover the 12-month period preceding the
                  verifiable consumer request's receipt. The response we provide will also explain
                  the reasons we cannot comply with a request, if applicable. For data portability
                  requests, we will select a format to provide your personal information that is
                  readily useable and should allow you to transmit the information from one entity
                  to another entity without hindrance.
                </div>
                <div className="para">
                  We do not charge a fee to process or respond to your verifiable consumer request
                  unless it is excessive, repetitive, or manifestly unfounded. If we determine that
                  the request warrants a fee, we will tell you why we made that decision and provide
                  you with a cost estimate before completing your request.
                </div>
                <div className="para text-bold">Non-Discrimination</div>
                <div className="para">
                  We will not discriminate against you for exercising any of your CCPA rights. In
                  that regard, we will not take the following actions in response to your exercising
                  of your CCPA rights, unless permitted by the CCPA:
                  <ul>
                    <li>Deny you goods or services.</li>
                    <li>
                      Charge you different prices or rates for goods or services, including through
                      granting discounts or other benefits, or imposing penalties.
                    </li>
                    <li>Provide you a different level or quality of goods or services.</li>
                    <li>
                      Suggest that you may receive a different price or rate for goods or services
                      or a different level or quality of goods or services.
                    </li>
                  </ul>
                </div>
                <div className="para text-bold">Changes to Our Privacy Notice</div>
                <div className="para">
                  We reserve the right to amend this privacy notice at our discretion and at any
                  time. When we make changes to this privacy notice, we will notify you by email or
                  through a notice on our website homepage. You will also be able to determine the
                  date that the CCPA policy has become effective, by viewing the Effective Date at
                  the top of this document.
                </div>
                <div className="para text-bold">Contact Information</div>
                <div className="para">
                  If you have any questions or comments about this notice, our Privacy Statement,
                  the ways in which we collect and use your personal information, your choices and
                  rights regarding such use, or wish to exercise your rights under California law,
                  please do not hesitate to contact us at:
                  <br />
                  Phone: <a href="tel:9737355775">9737355775</a>
                  <br />
                  Email: <a href="mailto:hello@kaly.com">hello@kaly.com</a>
                  <br />
                  <table>
                    <tbody>
                      <tr>
                        <td className="address-cell">Postal Address:</td>
                        <td>
                          Kaly, LLC
                          <br />
                          c/o Wilson Elser
                          <br />
                          Attn: Neil Prupis
                          <br />
                          200 Campus Drive
                          <br />
                          Florham Park, NJ 07932
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CCPAPolicy;
