const questionList = [
  {
    id: 141,
    question: 'Do you have any of these symptoms?',
    optionType: 1, // {1=>'single line options',2=>'select option'} // other will be default UI
    descriptions: [
      'Fever greater than 100.3°F',
      'Sudden Chest Pain.',
      'Sudden Shortness of Breath.',
      'Acute Dislocated Shoulder due to a recent traumatic injury.',
    ],
    options: [
      {nextQuestionId: 146, label: 'Yes', shortLabel: 'Red flags? Yes'},
      {nextQuestionId: 142, label: 'No', shortLabel: 'Red flags? No'},
    ],
  },
  {
    id: 142,
    question: 'How long have you had shoulder pain for?',
    descriptions: [],
    options: [
      {nextQuestionId: 144, label: 'Less than 2 weeks', shortLabel: '<2 weeks'},
      {nextQuestionId: 143, label: 'More than 2 weeks', shortLabel: '>2 weeks'},
    ],
  },
  {
    id: 143,
    question: 'Are you considering surgery?',
    descriptions: [],
    options: [
      {groupId: 'V', label: 'Yes, in the near future', shortLabel: 'Interested in surgery'},
      {nextQuestionId: 145, label: 'No, not at this time.', shortLabel: 'No surgery'},
    ],
  },
  {
    id: 144,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'L',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching, and exercises',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'E',
        label: 'Preliminary imaging (Xrays) and/or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'U',
        label: 'A minimally invasive procedure such as an Injection.',
        shortLabel: 'Open to invasive procedures',
        
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'E',
        label: 'I am not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
        showKalyMd: true,
      },
    ],
  },
  {
    id: 145,
    question: 'What type of treatment are you looking for?',
    descriptions: [],
    options: [
      {
        groupId: 'L',
        label:
          'Conservative type treatments such as manual or massage therapy, stretching and exercises.',
        shortLabel: 'Conservative treatment',
      },
      {
        groupId: 'U',
        label: 'Diagnostic imaging studies (MRI/Xray) or medications.',
        shortLabel: 'Imaging/Meds',
        showKalyMd: true,
      },
      {
        groupId: 'U',
        label: 'A minimally invasive procedure such as an Injection.',
        shortLabel: 'Open to invasive procedures',
      },
      {groupId: 'D', label: 'Acupuncture', shortLabel: 'Acupuncture'},
      {
        groupId: 'U',
        label: 'I am not sure what type of treatment I am looking for.',
        shortLabel: 'Undecided treatment',
      },
    ],
  },
  {
    id: 146,
    question:
      'You may have a condition that requires immediate medical attention, such as seeking care at an Emergency Room or Urgent Care Center. Kaly does not diagnose conditions and is not meant to be a replacement for a trained medical professional.',
    optionType: 1,
    descriptions: [
      'Would you still like to see doctors who may help you after you seek urgent care?',
    ],
    options: [
      {nextQuestionId: 142, label: 'Yes'},
      {groupId: 'A', label: 'No'},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 141,
    treeDepth: 5,
  },
};
