import React from 'react';
import Select from 'components/FormControls/Select';
import selectOptions from 'data/selectOptions';
import ActivityItem from '../ActivityItem';
import images from 'data/images';
import './ScheduleActivity.css';
import InfiniteScroll from 'components/InfiniteScroll';

const {activityOptions} = selectOptions;
let infiniteScrollOffSet = 150;
const ScheduleActivity = props => {
  const {appointments, appointmentTypeId, onAppointmentTypeChange} = props;
  return (
    <div className="ScheduleActivity">
      <div className="title">
        <span className="h3">Weekly Activity</span>
        <Select
          className="active-dropdown"
          isAllowDefaultOpen={true}
          defaultOptionProp={{value: '', label: 'All'}}
          onChange={e => {
            onAppointmentTypeChange(e.target.value);
          }}
          options={activityOptions}
          value={appointmentTypeId}></Select>
      </div>
      <div className="content">
        {appointments.length > 0 && (
                              <InfiniteScroll
                              offset={infiniteScrollOffSet}
                              allFetched={true}
                              
                              isFetching={false}
                              >
          <div className="activity-list">
            {appointments.map(appointment => {
              return <ActivityItem key={appointment.id} appointment={appointment} />;
            })}
          </div>
          </InfiniteScroll>
        )}
        {(!appointments || appointments.length === 0) && (
          <div className="acitive-bg-content">
            <div className="icon">
              <img src={images.calenderBg} alt="" />
            </div>
            <span>No upcoming appointments or activity for this provider</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleActivity;
