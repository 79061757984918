import React, {useState} from 'react';
import modalConfig from 'modals/modalConfig';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import validators from 'data/validators';
import Input from 'components/FormControls/Input';
import './CancelSubscription.css';

export function ReconcileConfirm(props) {
  const {setGlobal} = props.context;
  const {onProviderClick,numberOfProvider,quantity} = props;
  const {modalFunctions} = modalConfig;
  const {blankModalData} = modalFunctions;
  const {__required} = validators;

  const ConfirmComponent = props => {
    const {closeModal, onProviderClick} = props;
    const values = {};
    const errors = {};
    const [formData, setFormData] = useState({
      values,
      errors,
    });

  const onproviderClick=()=>{
    props.reject(true);
   
    onProviderClick()
    closeModal();
  }
  
    return (
      <div className="CreatePasswordModal CancelSubscription">
        <div className="modal-content-header">
          <div className="h3">Reconcile</div>
          <span
            className="close"
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div className="modal-content-body">
          <p>
            Your practice is over subscribed with {quantity} subscriptions for {numberOfProvider} providers. Either add
            providers to match the subscription or reduce your subscription to {numberOfProvider}.
          </p>
          <p>
            Do not Reduce your Subscription if you plan on adding additional Providers soon. You
            will not be refunded for un-used time and you may incur prorated charges when you add a
            new provider with a new subscription.
          </p>
        </div>
        <div className="modal-content-footer">
       
          <CircleButton
           
            onClick={() => {
              props.resolve(true);
              closeModal();
            }}>
           Reduce Subscription
          </CircleButton>
          <CircleButton
            onClick={() => {
              onproviderClick()
            }}>
            Add Providers
          </CircleButton>
          <CircleButton
            
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
          Cancel
          </CircleButton>
        </div>
      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ConfirmComponent,
        customComponentProps: {
          resolve: resolve,
          reject: reject,
          onProviderClick: onProviderClick,
         
        },
        closeOnBGClick: false,
      }),
    );
  });
}
