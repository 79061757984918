import React, {Component} from 'react';
import Header from 'layout/Header';
import './ContactUs.css';

class ContactUs extends Component {
  render() {
    return (
      <>
        <Header hideHeaderButtons={true}></Header>
        <div className="ContactUs">
          <div className="container">
            <div className="template-inner">
              <div className="main-header">Contact Us</div>
              <div className="template-content">
                <div className="sub-header-title">Effective Date: January 1, 2020</div>
                <div className="sub-header-title title-txt">Title Copy</div>
                <div className="para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eleifend faucibus
                  ex ac feugiat. Nam suscipit ex sit amet risus dictum, at volutpat velit
                  vestibulum. Mauris in augue tortor. Donec iaculis sapien sit amet metus hendrerit,
                  sit amet dictum dolor convallis. Morbi euismod mi et orci egestas tempor. Morbi
                  non eros velit. Integer condimentum nisi faucibus quam blandit varius. Aenean
                  blandit interdum quam, at lacinia elit sollicitudin ut. In hac habitasse platea
                  dictumst. Suspendisse quis purus turpis.
                </div>
                <div className="para">
                  Nullam tincidunt venenatis ligula, eu vulputate nunc luctus ac. Vivamus rhoncus
                  risus eget erat posuere gravida. Duis non arcu ex. In hac habitasse platea
                  dictumst. Aenean a elit ut nunc lobortis ultricies id non est. Proin fermentum
                  arcu eu diam sagittis, non iaculis ante gravida. Pellentesque nisl est, facilisis
                  ut justo ac, sodales ornare nunc. Interdum et malesuada fames ac ante ipsum primis
                  in faucibus. Duis vitae venenatis ex. Nunc ut ultricies eros.
                </div>
                <div className="para">
                  Integer ac sapien imperdiet ligula pellentesque ullamcorper. Aenean egestas nulla
                  vel gravida convallis. Vivamus consectetur ultricies elit, ut commodo tortor
                  dictum ac. Nulla lacus ex, scelerisque vel semper vel, vehicula quis urna. Duis
                  dictum leo rutrum ultricies pretium. Donec ac quam dolor. Class aptent taciti
                  sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut
                  tincidunt tortor sed ex commodo, nec varius eros lacinia. Cras nisi sapien,
                  facilisis posuere diam aliquet, vulputate volutpat nisl. Sed porttitor libero et
                  neque placerat, viverra porta enim pulvinar.
                </div>
                <div className="para">
                  Phasellus tellus dolor, mattis vel sem ut, vehicula suscipit nisi. Ut nec lectus
                  nunc. Phasellus ac tempor elit, id sodales orci. Mauris sit amet augue ut ipsum
                  condimentum tristique. Curabitur aliquet cursus tempor. Curabitur mollis gravida
                  ornare. Cras eget sem euismod quam faucibus hendrerit. Suspendisse vulputate, nisl
                  in viverra placerat, massa sem pharetra erat, quis efficitur ante erat consequat
                  lectus. Nulla ullamcorper nisi sapien, at accumsan lectus suscipit et. Proin
                  accumsan ornare tempus. Aliquam erat volutpat. Quisque eget fringilla enim,
                  fermentum pretium velit. In neque leo, commodo et tincidunt quis, cursus nec
                  justo. Suspendisse molestie lorem non eleifend posuere. Aliquam placerat massa sed
                  lacinia lacinia. Nunc malesuada pharetra metus, ac iaculis dolor luctus ut.
                </div>
                <div className="para">
                  Vestibulum id accumsan nibh, in mollis dui. Quisque nec vulputate nunc, vel porta
                  sem. Maecenas fringilla, augue sed ullamcorper sagittis, enim ligula mattis
                  lectus, eu facilisis leo nisl non leo. Suspendisse ac enim sit amet leo convallis
                  ultrices. Donec pretium purus et dictum mattis. In hac habitasse platea dictumst.
                  Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
                  turpis egestas. Vestibulum aliquet ligula non sapien blandit faucibus. Curabitur a
                  pellentesque felis, a dapibus metus. Mauris quis ipsum mi.
                </div>
                <div className="para">
                  Aenean iaculis euismod tincidunt. Maecenas bibendum molestie ex, non varius libero
                  luctus et. Nam tempus eget metus quis pellentesque. Nam ullamcorper nibh nec enim
                  finibus vehicula at ut metus. Quisque egestas sem quis tellus ultrices aliquet.
                  Etiam suscipit lacus imperdiet imperdiet ultrices. Cras quis mattis neque. Nullam
                  justo tortor, condimentum quis tempus in, commodo quis risus. Ut vitae scelerisque
                  mauris, egestas laoreet felis. Praesent luctus nisi eu tellus rutrum, vel
                  fermentum lacus tristique. Cras at diam non nunc pellentesque iaculis vitae ut
                  lectus.
                </div>
                <div className="para">
                  Aliquam lacinia at erat in viverra. Ut mi libero, rutrum at euismod at, maximus et
                  mi. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus feugiat
                  a orci vitae laoreet. Maecenas ligula velit, maximus sit amet nulla quis,
                  hendrerit gravida massa. Etiam porttitor risus pretium leo luctus, nec luctus
                  metus hendrerit. Duis euismod pellentesque felis, eget faucibus est sagittis
                  sodales.
                </div>
                <div className="para">
                  Sed vel leo et justo convallis eleifend vel a turpis. Vivamus erat sem, gravida
                  vel turpis vel, ornare vestibulum mauris. Aliquam id mi at urna pharetra congue.
                  Aliquam dignissim at justo vitae sagittis. Sed viverra mi vitae enim dignissim
                  sagittis quis eget elit. Sed pretium velit vel arcu ultricies, non elementum erat
                  vulputate. Integer ultrices ac odio ac congue. Proin euismod et metus ut
                  vulputate. Proin mollis, dolor at volutpat posuere, dui erat porttitor orci, id
                  rhoncus velit ligula ac arcu. Quisque lorem lorem, ullamcorper eu dolor ac,
                  blandit ultricies ligula.
                </div>
                <div className="para">
                  Ut vehicula sem vitae semper rutrum. Proin sed tortor sit amet massa varius
                  iaculis tristique sed lorem. Donec in erat ipsum. Donec dignissim, arcu id
                  hendrerit laoreet, purus justo commodo massa, vel dictum enim risus ac arcu. Cras
                  at elit sollicitudin, porta orci vitae, eleifend augue. Morbi tortor magna,
                  tincidunt sagittis risus et, lobortis porta dui. Integer eu nunc in sem placerat
                  dignissim. Donec vitae condimentum sapien. Pellentesque vitae hendrerit leo. Morbi
                  a neque vitae justo vehicula sollicitudin. Nulla facilisi. Maecenas efficitur
                  magna lorem, ac ultrices mi vehicula quis. Praesent facilisis nec lorem non
                  finibus.
                </div>
                <div className="para">
                  Mauris sit amet tellus urna. Donec quis porttitor tortor. Aenean nec efficitur
                  tortor. Praesent mattis porta vulputate. Vestibulum magna nibh, fringilla vitae
                  dui quis, semper vulputate felis. Nulla consequat at dui vel consectetur. Donec
                  orci nunc, semper quis interdum sed, tincidunt id eros. Praesent pellentesque
                  condimentum elit at dignissim. Nullam dapibus varius ex, vel egestas augue
                  accumsan ultrices. Praesent accumsan, augue sit amet aliquam ultricies, dolor
                  ligula condimentum lacus, non bibendum lectus orci eget leo.
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactUs;
