const questionList = [
  {
    id: 341,
    question: 'What type of pain condition do you have?',
    optionType: 2,
    painOptionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14], // painIds,
    nextQuestionId: 342,
    descriptions: [],
  },
  {
    id: 342,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 343,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 84, visitReasonIds: [237], extraQuestion: 1},
          {painId: 2, questionId: 163, visitReasonIds: [236], extraQuestion: 1},
          {painId: 3, questionId: 224, visitReasonIds: [239], extraQuestion: 1},
          {painId: 4, questionId: 43, visitReasonIds: [231], extraQuestion: 1},
          {painId: 5, questionId: 104, visitReasonIds: [232], extraQuestion: 1},
          {painId: 6, questionId: 123, visitReasonIds: [238], extraQuestion: 1},
          {painId: 7, questionId: 144, visitReasonIds: [233], extraQuestion: 1},
          {painId: 8, questionId: 23, visitReasonIds: [241], extraQuestion: 1},
          {painId: 9, questionId: 183, visitReasonIds: [234], extraQuestion: 1},
          {painId: 10, questionId: 24, visitReasonIds: [242], extraQuestion: 2},
          {painId: 11, questionId: 203, visitReasonIds: [235], extraQuestion: 1},
          {painId: 12, questionId: 64, visitReasonIds: [240], extraQuestion: 1},
          {painId: 13, questionId: 203, visitReasonIds: [], extraQuestion: 1},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 343,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 83, visitReasonIds: [237], extraQuestion: 3},
          {painId: 2, questionId: 164, visitReasonIds: [236], extraQuestion: 3},
          {painId: 3, questionId: 225, visitReasonIds: [239], extraQuestion: 1},
          {painId: 4, questionId: 44, visitReasonIds: [231], extraQuestion: 3},
          {painId: 5, questionId: 103, visitReasonIds: [232], extraQuestion: 3},
          {painId: 6, questionId: 124, visitReasonIds: [238], extraQuestion: 3},
          {painId: 7, questionId: 143, visitReasonIds: [233], extraQuestion: 2},
          {painId: 8, questionId: 24, visitReasonIds: [241], extraQuestion: 2},
          {painId: 9, questionId: 184, visitReasonIds: [234], extraQuestion: 3},
          {painId: 10, questionId: 24, visitReasonIds: [242], extraQuestion: 2},
          {painId: 11, questionId: 204, visitReasonIds: [235], extraQuestion: 3},
          {painId: 12, questionId: 65, visitReasonIds: [240], extraQuestion: 1},
          {painId: 13, questionId: 204, visitReasonIds: [], extraQuestion: 3},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 343,
    question: 'Do you still want to see an acupuncturist?',
    descriptions: [
      'An acupuncturist (L.Ac./C.Ac.) is a medical professional who has masters degree in Oriental Medicine, which takes about 3-5 years. A medical doctor (MD/DO), chiropractor (DC) or dentist (DMD/DDS) can also perform acupuncture in some states after completing additional training. An acupuncturist treats your condition by inserting tiny needles into your body or recommending medicinal herbs that can help your body heal naturally.',
    ],
    title: 'Acupuncturist',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can not prescribe medications',
      'Can not order diagnostic imaging studies',
      'Can not perform therapeutic injections',
      'Can not perform surgery',
    ],
    options: [
      {groupId: 'D', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 341,
    lastQuestionId: 343,
    treeDepth: 3,
  },
};
