import React, {useState, useContext} from 'react';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import constants from 'data/constants';
import validators from 'data/validators';
import './ChangePassword.css';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import adminService from 'services/admin';
import modalConfig from 'modals/modalConfig';

const {__required, __password, __eq} = validators;
const {DEFAULT_MESSAGES} = constants;
const {changePassword} = adminService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;

const ChangePassword = props => {
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;

  const passwordFormConfig = {
    values: {password: '', newPassword: '', confirmPassword: ''},
    errors: {},
  };
  const [passwordFormData, setPasswordFormData] = useState({...passwordFormConfig});
  const {values: passwordFormValues, errors: passwordFormErrors} = passwordFormData;

  const resetPasswordForm = () => {
    setPasswordFormData({...passwordFormConfig});
  };

  const getGeneralPasswordFormInputProps = controlName => {
    return {
      onChange: changePasswordFormHandler,
      onBlur: changePasswordFormHandler,
      error: passwordFormErrors[controlName],
      value: passwordFormValues[controlName],
      name: controlName,
    };
  };

  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  const getPassowrdComponent = labelText => {
    return (
      <>
        {labelText}{' '}
        <span className="password-instruction-text">
          ({DEFAULT_MESSAGES.PASSWORD_INSTRUCTION_TEXT})
        </span>
      </>
    );
  };

  const __validateCurrentPassword = (value = null) => {
    const fValue = value !== null ? value : passwordFormValues['password'];
    if (!__required(fValue)) {
      return 'Current password is required';
    }
    return null;
  };
  const __validateNewPassword = (value = null) => {
    const fValue = value !== null ? value : passwordFormValues['newPassword'];
    if (!__required(fValue)) {
      return 'New password is required';
    }
    if (!__password(fValue)) {
      return 'Invalid new password';
    }
    return null;
  };
  const __validateConfirmPassword = (value = null) => {
    const fValue = value !== null ? value : passwordFormValues['confirmPassword'];
    if (!__required(fValue)) {
      return 'Confirm password is required';
    }
    if (!__eq(fValue, passwordFormValues['newPassword'])) {
      return 'New password and confirm password must be same';
    }
    return null;
  };
  const __validateConfirmPasswordChangeNew = (value = null) => {
    const fValue = value !== null ? value : passwordFormValues['newPassword'];
    if (!__required(fValue)) {
      return 'Confirm password is required';
    }
    if (!__eq(fValue, passwordFormValues['confirmPassword'])) {
      return 'New password and confirm password must be same';
    }
    return null;
  };
  const changePasswordFormHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'password':
        errorObject = {
          [name]: __validateCurrentPassword(value),
        };
        break;
      case 'newPassword':
        errorObject = {
          [name]: __validateNewPassword(value),
        };
        if (passwordFormData['confirmPassword']) {
          errorObject = { ...errorObject, 'confirmPassword': __validateConfirmPasswordChangeNew(value) }
        }
        break;
      case 'confirmPassword':
        errorObject = {
          [name]: __validateConfirmPassword(value),
        };
        break;
      default:
        break;
    }
    setPasswordFormData({
      ...passwordFormData,
      values: {
        ...passwordFormData.values,
        [name]: value,
      },
      errors: {
        ...passwordFormData.errors,
        ...errorObject,
      },
    });
  };

  const handlePasswordChange = () => {
    onChangePassword(passwordFormValues, resetPasswordForm);
  };

  const onChangePassword = async (data, resetForm) => {
    const response = await changePassword(data).catch(handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            resetForm();
          },
        }),
      );
    }
  };

  const __validatePasswordForm = () => {
    const validCuurrentPassword = !__validateCurrentPassword();
    const validNewPassword = !__validateNewPassword();
    const validConfirmPassword = !__validateConfirmPassword();
    return validCuurrentPassword && validNewPassword && validConfirmPassword;
  };
  const isValidPasswordForm = __validatePasswordForm();

  const title = 'Change Password';

  return (
    <Layout>
      <div className="ChangePassword">
        <SubHeader title={title} hideName />
        <div className="dashboard-panle">
          <div className="password-form">
            <form className="accountPassword-form">
              <Input
                {...getGeneralPasswordFormInputProps('password')}
                type="password"
                label="Current Password"
                icon={images.eyeView}
              />
              <Input
                {...getGeneralPasswordFormInputProps('newPassword')}
                type="password"
                label={getPassowrdComponent('New Password')}
                icon={images.eyeView}
              />
              <Input
                {...getGeneralPasswordFormInputProps('confirmPassword')}
                type="password"
                label="Confirm Password"
                icon={images.eyeView}
              />
              <div className="save-btn">
                <CircleButton onClick={handlePasswordChange} disabled={!isValidPasswordForm}>
                  Save
                </CircleButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChangePassword;
