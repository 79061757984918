const questionList = [
  {
    id: 577,
    question: 'What type of procedure are you looking for?',
    optionType: 3,
    procedureOptionIds: [111, 87, 112, 88, 78, 110, 93],
    nextQuestionId: 578,
    descriptions: [],
  },
  {
    id: 578,
    question: 'Do you get nervous when you go to the dentist?',
    questionType: 4,
    optionType: 0,
    options: [
      {
        nextQuestionId: 579,
        label: 'Not at all',
        shortLabel: 'Not Nervous',
      },
      {
        nextQuestionId: 579,
        label: 'A little bit',
        shortLabel: 'A little nervous',
      },
      {
        nextQuestionId: 579,
        label: 'Very nervous',
        shortLabel: 'Very nervous',
      },
    ],
    descriptions: [
      'Your answer may help your dentist accomodate you by scheduling you at an ideal time or potentially offering you other options that may help.',
    ],
  },
  {
    id: 579,
    question: 'Do you still want to see an Prosthodontist?',
    descriptions: [
      'A Prosthodontist is a dentist (DMD/DDS) who completed a three year residency in Prosthodontic Disorders after completing four years of dental school.   A Prosthodontist specializes in the diagnosis and treatment of dental and facial problems that involve restoring missing tooth and jaw structures.',
    ],
    title: 'Prosthodontist',
    optionType: 0,
    questionType: 1,
    canDoList: [],
    options: [
      {groupId: 'A5', label: 'Yes, see Prosthodontist', isGoToSymptomFlow: false},
      {label: 'No, I am looking for something else', nextQuestionId: 2202, addExtraQuestion: 4},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 577,
    lastQuestionId: 579,
    treeDepth: 3,
  },
};
