
import { searchHistoryCreate, searchHistoryUpdate } from "services/searchHistory"
import helper from "./helper";
const { getLocationAwait } = helper
const initialData = {
  id: 0,
  patientId: null,
  providerId: null,
  searchValue: '',
  flowTypeId: 1,
  flowResponse: JSON.stringify({}),
  finishedFlowResponse: false,
  providerViewed: false,
  viewedProviderId: []
}
let sendData = {}
const setOrUpdateLocalData = async (type, value) => {

  let ipData = await getLocationAwait()
  let existData = localStorage.getItem('trackFlowQuestions')


  if (existData) {
    existData = JSON.parse(existData)
  }
  else {
    existData = { ...initialData }

  }
  let svk = localStorage.getItem('SearchValueKey')
  if (svk) {
    if (existData['searchValue'] !== svk) {
      existData = { ...initialData }
      existData['searchValue'] = svk
      existData.id = 0
    }

  }
  if (type == 'finishedFlowResponse' && existData.finishedFlowResponse == value) {

    return;
  }
  if (type == 'flowResponse') {

    value = JSON.stringify(value)

    if (value == sendData) {

      return;
    }

  }
  if (localStorage.getItem('p-id-me') && localStorage.getItem('accessToken')) {
    existData['patientId'] = localStorage.getItem('p-id-me')
  } else {
    existData['patientId'] = 0
  }

  if (type == 'providerId') {
    existData['providerViewed'] = true;
    if (existData.viewedProviderId && existData.viewedProviderId.find((i) => i == value)) {
      return
    } else {
      if (existData.viewedProviderId && existData.viewedProviderId.length > 0) {
        existData.viewedProviderId.push(value)
        existData.id = 0

      }
      else {
        existData['viewedProviderId'] = [value]

      }

    }
  }

  existData.location = ipData?.loc_details?.location ? ipData?.loc_details?.location : (ipData?.loc_details?.region_name ? ipData?.loc_details?.region_name : '');
  existData[type] = value

  if (existData.id != 0) {
    if (existData.flowResponse)
      sendData = existData.flowResponse
    updateToDb(existData)
    localStorage.setItem('trackFlowQuestions', JSON.stringify(existData))
  } else {
    createToDb(existData)

  }

}
const updateToDb = async (data) => {
  let passData = { ...data }
  delete passData.viewedProviderId
  searchHistoryUpdate(passData)
}
const createToDb = async (data) => {
  let passData = { ...data }
  delete passData.viewedProviderId
  let responce = await searchHistoryCreate(passData)
  if (responce && responce.data) {
    data.id = responce.data.id
    localStorage.setItem('trackFlowQuestions', JSON.stringify(data))
  }
}
export {
  setOrUpdateLocalData
};
