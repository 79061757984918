import React, {Component} from 'react';
import StarRatings from 'react-star-ratings';
import images from 'data/images';
import _ from 'lodash';
import './ReviewWidget.css';

export default class ReviewWidgetView extends Component {
  openProfile = () => {
    const {reviewData} = this.props;
    const seoPageUrl = _.get(reviewData, 'seoPageUrl', '');
    if (seoPageUrl.length > 0) window.open(seoPageUrl, '_blank');
  };

  render() {
    const {reviewData, shapeClass, backgroundColor} = this.props;
    let startSize = '16px';
    let starSpacing = '1px';
    if (shapeClass === 'mini-rectangle') {
      startSize = '14px';
      starSpacing = '0.8px';
    }
    return (
      <div className="ReviewWidget">
        <div
          className={'widget-preview ' + shapeClass}
          onClick={this.openProfile}
          style={{backgroundColor: '#' + backgroundColor}}>
          <div className="preview-text">
            <span>{_.get(reviewData, 'totalReview', 0)}</span>
            <span>Patient</span>
            <span>Reviews</span>
          </div>
          <div className="star">
            <StarRatings
              rating={_.get(reviewData, 'totalRating', 5)}
              starDimension={startSize}
              starSpacing={starSpacing}
              starRatedColor="#ffb300"
            />
            &nbsp;
            <span className="rating">{_.get(reviewData, 'totalRating', 5)}</span>
          </div>
          <img src={images.Certificate} alt="" className="certificate-img" />
        </div>
      </div>
    );
  }
}
