import React, {Component} from 'react';
import _ from 'lodash';
import Layout from 'layout/AdminLayout';
import SubHeader from 'components/AfterAuth/SubHeader';
import adminService from 'services/admin';
import AuthContext from 'context/AuthContext';
import ReactPaginate from 'react-paginate';
import modalConfig from 'modals/modalConfig';
import allStatus from 'data/status';
import moment from 'moment';
import helper from 'data/helper';
import RootContext from 'context/RootContext';
import './JoinUs.css';
import images from 'data/images';
import constants from 'data/constants';
import joinUsService from 'services/joinUs';
import {ViewPhotoId} from './viewPhotoId';
import ViewDocumentFile from "./viewDocumentFile"
const initialState = {
  list: [],
  activePage: 1,
  totalPage: 0,
  searchText: '',
  sortBy: 'createdAt',
  orderBy: 'desc',
  totalRecord: 0,
};
const {errorModalData, successModalData, blankModalData} = modalConfig.modalFunctions;
const {joinUsStatus} = allStatus;
const {getJoinUsRequestStatusList, getRequestCounts} = adminService;
const {verifyJoinUsByToken, rejectUser,waitUser} = joinUsService;
const {formatPhoneNumber} = helper;
const {DefaultDebounceMSec} = constants;

class AdminJoinUs extends Component {
  static contextType = AuthContext;
  rootContext = null
  state = {...initialState};
  pathname=window.location.pathname;
  componentDidMount() {
    this.getJoinUsList(false);
  }

  formatedDate = date => {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  };
  viewPhotoId = (url, userName) => {
    let {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ViewPhotoId,
        customComponentProps: {url: url, userName: userName},
      }),
    );
  };
  getJoinUsList = async (noLoad = false) => {
    const {activePage, searchText, sortBy, orderBy} = this.state;
    let {provideStatus}=this.props;
    const response = await getJoinUsRequestStatusList(
      {
        search: searchText,
        page: activePage,
        limit: 10,
        sortBy: sortBy,
        orderBy: orderBy,
        status:provideStatus?provideStatus:0
      },
      noLoad,
    ).catch(this.handleError);
    if (response) {
      const {data} = response;
      this.setState({
        list: data.list,
        totalPage: data.list.length>0&& data.totalPage>0?data.totalPage:0,
        totalRecord: data.totalRecord,
      });
    }
  };

  handleError = error => {
    const {setGlobal} = this.rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  handlePageChange = data => {
    this.setState({activePage: data.selected + 1}, this.getJoinUsList);
  };
  handleChange = event => {
    this.setState({activePage: 1, searchText: event.target.value});
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.getJoinUsList();
      }, DefaultDebounceMSec);
    }
    this.debouncedFn();
  };
  handleSearch = () => {
    this.setState({activePage: 1}, this.getJoinUsList);
  };
  handleClearSearch = () => {
    this.setState({activePage: 1, searchText: ''}, this.getJoinUsList);
  };

  handleSortingChange = e => {
    e.preventDefault();
    const {sortBy, orderBy} = this.state;
    const {dataset} = e.target;
    const key = _.get(dataset, 'key', 'name');
    if (sortBy === key) {
      this.setState(
        {orderBy: orderBy === 'asc' ? 'desc' : 'asc', activePage: 1},
        this.getJoinUsList,
      );
    } else {
      this.setState({orderBy: 'asc', sortBy: key, activePage: 1}, this.getJoinUsList);
    }
  };

  renderShortIcon = key => {
    const {sortBy, orderBy} = this.state;
    if (key === sortBy) {
      if (orderBy === 'asc') {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowUpFilledTriangle} alt="arrowUpFilledTriangle" />
          </span>
        );
      } else {
        return (
          <span data-key={key} className={'sort-icon'}>
            <img data-key={key} src={images.arrowDownFilledTriangle} alt="arrowDownFilledTriangle" />
          </span>
        );
      }
    }
    return '';
  };

  handleApproveJoinUs = async token => {
    const response = await verifyJoinUsByToken(token).catch(this.handleError);
    if (response) {
      const {message} = response;
      const {setGlobal} = this.rootContext;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.updateJoinUsData();
          },
        }),
      );
    }
  };
  handleRejectJoinUs = async token => {
    const response = await rejectUser(token).catch(this.handleError);
    if (response) {
      const {message} = response;
      const {setGlobal} = this.rootContext;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.updateJoinUsData();
          },
        }),
      );
    }
  };
  handleWaitingJoinUs = async token => {
    const response = await waitUser(token).catch(this.handleError);
    if (response) {
      const {message} = response;
      const {setGlobal} = this.rootContext;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.updateJoinUsData();
          },
        }),
      );
    }
  };
  updateJoinUsData = async () => {
    this.getJoinUsList();
    await this.updateContextData();
  };

  updateContextData = async () => {
    const requestCountData = await getRequestCounts().catch(() => null);
    if (requestCountData && requestCountData.data) {
      const {setAdminRequestCountState} = this.context;
      setAdminRequestCountState(prevState => {
        return {
          ...prevState,
          requestCount: {
            ...requestCountData.data,
          },
        };
      });
    }
  };
  ShowEditProvider = (providerId, userId) => {
    this.setState({isEditProvider: true, editProviderId: providerId, editUserId: userId});
    const params = new URLSearchParams();
    if (providerId) {
      params.append('provider', providerId);
    }
    if (userId) {
      params.append('user', userId);
    }
    this.props.history.push({
      pathname: '/admin/providerDetails',
      search: `?${params.toString()}`,
      state: {...this.state, isBackLink: true,urlKey:'approved'},
    });
  };
  render() {
    let {provideStatus,name}=this.props;
    let title = 'Pending Profile Approvals';
    let subTitle='';

    switch(provideStatus){
      case 1:
        title=name;
        subTitle='Approved'
        break;
        case 2:
        title=name;
        subTitle='Rejected'
        break;
        case 3:
          title=name;
          subTitle='Waiting'
          break;
          default:
            title=name;
            subTitle='Pending'
          break; 
    }
    const {list, totalPage, searchText, activePage, totalRecord} = this.state;
    const activeIndex = activePage - 1;
    // wait for api data
    if (!list) {
      return null;
    }
    // list rows
    const defaultText = '--';
    let tableOutput = '';
    let listingComponent = _.map(list, (item, index) => {
      let {
        id = defaultText,
        firstName = defaultText,
        lastName = defaultText,
        email = defaultText,
        status = defaultText,
        specialtyName = defaultText,
        phoneNumber = defaultText,
        zipCode = defaultText,
        userId=defaultText,
        requestUrl = '',
        createdAt,
        requestToken = '',
        userDocInfo = '',
        npiNumber='',
        providerId="",
        approvalDate=null,
        setupStatus=''
      } = item;
      if (specialtyName === null || specialtyName === `null`) {
        specialtyName = item.newSpecialtyRequest ? item.newSpecialtyRequest : defaultText;
      }
      return (
        <tr key={index + id} data-id={id}>
          <td className="text-cap">
            {`${firstName} ${lastName}`}
            {userDocInfo && (
              // <img
              //   onClick={() => this.viewPhotoId(userDocInfo, `${firstName} ${lastName}`)}
              //   className="photoid"
              //   src={images.photoID}
              //   alt="photoID"
              // />
              <ViewDocumentFile userId={userId} joinUsId={id} userName={`${firstName} ${lastName}`} />
            )}
          </td>
          <td>{email}</td>
          <td className="text-cap">{specialtyName}</td>
          <td>{formatPhoneNumber(phoneNumber)}</td>
          <td>{zipCode}</td>
          <td>{npiNumber}</td>
          <td>
            {joinUsStatus[status]}<br/>
            <div className="actionbtndev">
              {((status === 3 && item.specialtyName !== null && item.specialtyName !== `null`) || (requestUrl&& item.specialtyName !== null && item.specialtyName !== `null`)) && ( subTitle!=='Approved' && subTitle!=='Rejected') && (
              <button
                className="btn btn-approve"
                onClick={() => this.handleApproveJoinUs(requestToken)}>
                Approve
              </button>
            )}
            {(status === 0 ||status === 3||(this.props.provideStatus === 'pendingProfile' && status === 1 )) && (
              <button
                className="btn btn-reject"
                onClick={() => this.handleRejectJoinUs(requestToken)}>
                Reject
              </button>
            )}
            {(status === 0 || (this.props.provideStatus === 'pendingProfile' && status === 1 )) && (
              <button
                className="btn btn-waiting"
                onClick={() => this.handleWaitingJoinUs(requestToken)}>
                Waiting
              </button>
            )}

            </div>
          </td>
          <td>{this.formatedDate(createdAt)}</td>
          {this.props.provideStatus === 'pendingProfile' && (
            <>
          <td>{!approvalDate?'-':this.formatedDate(approvalDate)}</td>
          <td>{setupStatus}</td>
          </>
          )}
         {this.pathname===`/app/admin/join-us/approved` && (
           <td className="AdminProviders">
            <button
                  className="btn-edit"
                  onClick={() =>
                    this.ShowEditProvider(providerId, null)
                  }>
                  Edit
                </button>
           </td>
         )}
         </tr>
      );
    });
    if (list.length === 0) {
      tableOutput = <div className="no-record">No Records found</div>;
    } else {
      tableOutput = (
        <div className="table-overflow">
          <table className="admin-provider-list">
            <thead>
              <tr>
                <td className="pointer" data-key="firstName" onClick={this.handleSortingChange}>
                  Name{this.renderShortIcon('firstName')}
                </td>
                <td className="pointer" data-key="email" onClick={this.handleSortingChange}>
                  Email{this.renderShortIcon('email')}
                </td>
                <td className="pointer" data-key="specialty" onClick={this.handleSortingChange}>
                  Specialty{this.renderShortIcon('specialty')}
                </td>
                <td className="pointer" data-key="phoneNumber" onClick={this.handleSortingChange}>
                  Phone#{this.renderShortIcon('phoneNumber')}
                </td>
                <td className="pointer" data-key="zipCode" onClick={this.handleSortingChange}>
                  Zip{this.renderShortIcon('zipCode')}
                </td>
                <td className="pointer" data-key="npiNumber" onClick={this.handleSortingChange}>
                  NPI #{this.renderShortIcon('npiNumber')}
                </td>
                <td className="pointer" data-key="status" >
                  Status
                </td>
                <td className="pointer" data-key="createdAt" onClick={this.handleSortingChange}>
                  Request Date (UTC){this.renderShortIcon('createdAt')}
                </td>
                {this.props.provideStatus === 'pendingProfile' && (
                  <>
                <td className="pointer" data-key="approvalDate" onClick={this.handleSortingChange}>Approval Date (UTC){this.renderShortIcon('approvalDate')}</td>
                <td>Setup Status</td>
                </>
                )}
                {this.pathname===`/app/admin/join-us/approved` && (
                 <td className="pointer" data-key="createdAt" onClick={this.handleSortingChange}>
                Action
               </td> 
                )}
              </tr>
            </thead>
            <tbody>{listingComponent}</tbody>
          </table>
        </div>
      );
    }
   
    return (
      <Layout>
        <RootContext.Consumer>
          {context => {
            this.rootContext = context;
          }}
        </RootContext.Consumer>
        <div className={['AdminJoinUs',this.pathname===`/app/admin/join-us/approved`?'approvedJoinUs':''].join(' ')}>
          <SubHeader title={title} hideName />
          <div className="dashboard-panle">
            <div className="search">
              <div>
                <input placeholder="Search" value={searchText} onChange={this.handleChange}></input>
                {searchText && (
                  <button className="btn-clear" onClick={this.handleClearSearch}>
                    <img src={images.close} alt="clear" />
                  </button>
                )}
              </div>
              <button onClick={this.handleSearch}>Search</button>
            </div>
            <div className="dataCount">
              <span>{totalRecord} {subTitle} profile found.</span>
            </div>
            {tableOutput}
            {totalPage > 0 && (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activeIndex}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default AdminJoinUs;
