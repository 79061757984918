import React from 'react';
import CircleButton from '../../CircleButton';
import './AmazingDoctor.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {Analytics} = analyticsData;

const AmazingDoctor = props => {
  return (
    <div className="AmazingDoctor">
      <div className="container">
        <div className="title">Caring for doctors too!</div>
        <div className="AmazingDoctor-content">
          <div className="description">
          From their specialties to their care styles, every great doctor provides something truly unique. Sound like you? Then allow us to connect you with the patients who are looking for exactly what you have to offer. 
          </div>
          <CircleButton
            link="/list-your-practice"
            onClick={() =>
              Nux.sendEvent(
                Analytics.Category.PatientLanding,
                Analytics.Actions.Click,
                'listYourPractice',
              )
            }>
            List your practice
          </CircleButton>
        </div>
      </div>
    </div>
  );
};

export default AmazingDoctor;
