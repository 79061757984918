import React, {useState} from 'react';
import modalConfig from 'modals/modalConfig';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import OtpInput from 'react-otp-input';
export function EnterOtp(props) {
  const {setGlobal} = props.context;
  const {modalFunctions} = modalConfig;
  const {blankModalData} = modalFunctions;
  const OtpComponent = props => {
    const {closeModal} = props;
   
    const[otp,setOpt]=useState("")
    
    
    return (
      <div className="CreatePasswordModal termsofuse">
        <div className="modal-content-header">
          <div className="h3">Enter OTP</div>
          <span
            className="close"
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div className="modal-content-body">
          <div className="otp-maindiv">
            <div className="otp-subdiv" >
        <OtpInput
        value={otp}
        onChange={(otp)=>{setOpt(otp)}}
        numInputs={6}
        separator={<span>-</span>}
      />
      </div>
      </div>
        </div>
        <div className="modal-content-footer">
          <CircleButton
            disabled={otp.length===6?false:true}
            onClick={() => {       
              props.resolve(otp);      
              props.closeModal();
            }}>
            Continue
          </CircleButton>
        </div>
      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: OtpComponent,
        customComponentProps: {resolve: resolve, reject: reject},
      }),
    );
  });
}
