import React, {Component} from 'react';
import Layout from 'layout';
import images from 'data/images';
import './LoadingPage.css';
import PatientLayout from 'layout/PatientLayout';

class LoadingPage extends Component {
  render() {
    const {hideTitle = false} = this.props;
    return (
      <PatientLayout planeHeader={true} hideFooter={true}>
        <div className="LoadingPage">
          <div className="loading-content">
            {!hideTitle && (
              <div className="h3">We have a few questions to match you with the best doctors…</div>
            )}
            <img src={images.logosymbol} alt="logo symbol" />
          </div>
        </div>
      </PatientLayout>
    );
  }
}

export default LoadingPage;
