import React, {useState} from 'react';
import moment from 'moment';
import Input from 'components/FormControls/Input';
import momentHelper from 'data/moment/momentHelper';
import MyDatetime from 'components/MyDatetime';
import images from 'data/images';
import './SelectDate.css';

const {bigDateFormat} = momentHelper;

const SelectDate = props => {
  const {
    inputProps = {},
    format = 'MMMM DD, YYYY',
    datePickerProps = {value: moment()},
    onDateChange = () => {},
    containerClass = '',
    disableViewModeChange = false,
  } = props;
  const [dateConfig, setDateConfig] = useState({
    open: false,
    input: false,
    viewMode: 'days',
    timeFormat: false,
  });

  const onViewModeChange = mode => {
    if (disableViewModeChange) {
      setDateConfig(prevState => {
        return {
          ...prevState,
          viewMode: 'days',
        };
      });
    }
  };

  const toggleDatePicker = () => {
    setDateConfig({
      ...dateConfig,
      open: !dateConfig.open,
    });
  };

  const closeDatePicker = () => {
    setDateConfig({
      ...dateConfig,
      open: false,
    });
  };

  const handleDateChange = (name, dateOfBirth) => {
    const customEventObject = {
      target: {
        name: name,
        value: dateOfBirth,
      },
    };
    onDateChange(customEventObject);
    setDateConfig({
      ...dateConfig,
      open: false,
    });
  };

  let keyProp = {};
  if (disableViewModeChange) {
    keyProp.key = Math.random();
    datePickerProps.onViewModeChange = onViewModeChange;
  }
  return (
    <div className={`SelectDate ${containerClass}`}>
      {dateConfig.open && <div className="overlay" onClick={closeDatePicker}></div>}
      <div onClick={toggleDatePicker}>
        <Input
          label="Date of Birth"
          readOnly={true}
          icon={images.calendar2}
          value={bigDateFormat(datePickerProps.value, format)}
          {...inputProps}
        />
      </div>
      {dateConfig.open && (
        <div className="datepicker" {...keyProp}>
          {disableViewModeChange && <div className="overlay-month-change"></div>}
          <MyDatetime
            onChange={selectedDate => handleDateChange(datePickerProps.name, selectedDate)}
            {...datePickerProps}
            {...dateConfig}
          />
        </div>
      )}
    </div>
  );
};

export default SelectDate;
