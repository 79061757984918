import React, {Component} from 'react';
import moment from 'moment';
import _ from 'lodash';
import PatientLayout from 'layout/PatientLayout';
import Input from 'components/FormControls/Input';
import images from 'data/images';
import SelectDate from 'components/FormControls/SelectDate';
import DoctorItem from 'components/Patient/DoctorItem';
import helper from 'data/helper';
import scheduleService from 'services/schedule';
import momentHelper from 'data/moment/momentHelper';
import ElasticSearch from 'components/FormControls/ElasticSearch';
import {Link} from 'react-router-dom';
import Gender from 'components/FormControls/Gender';
import SortBy from 'components/FormControls/SortBy';
import Availability from 'components/FormControls/Availability';
import InsuranceFilterSelect from 'components/FormControls/InsuranceFilterSelect';
import selectOptions from 'data/selectOptions';
import CircleButton from 'components/CircleButton';
import MyGoogleMap from 'components/MyGoogleMap';
import InfiniteScroll from 'components/InfiniteScroll';
import './DoctorListing.css';
import Language from 'components/FormControls/Language';
import reviewService from 'services/review';
import RootContext from 'context/RootContext';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import modalConfig from 'modals/modalConfig';
import config from 'config/config';
import InsuranceFilterSelectPlan from 'components/FormControls/InsuranceFilterSelect/InsuranceFilterSelectPlan';
import DoctorItemOrg from 'components/Patient/DoctorItemOrg';

const greatProvidersAns = [
  {answer: 'Low Back Pain', question: 'Symptom'},
  {
    addExtraQuestion: 0,
    answer: 'Red flags? No',
    isGoToSymptomFlow: false,
    originalAnswer: 'No',
    question: 'Do you have any of these symptoms?',
    questionId: 81,
  },
  {
    addExtraQuestion: 0,
    answer: '>3 weeks',
    isGoToSymptomFlow: false,
    originalAnswer: 'More than 3 weeks',
    question: 'How long have you had back pain for?',
    questionId: 82,
  },
  {
    addExtraQuestion: 0,
    answer: 'No surgery',
    isGoToSymptomFlow: false,
    originalAnswer: 'Not at this time',
    question: 'Are you considering surgery?',
    questionId: 83,
  },
  {
    addExtraQuestion: 0,
    answer: 'No invasive procedures',
    isGoToSymptomFlow: false,
    originalAnswer: 'Not at this time.',
    question: 'Are you considering minimally invasive procedures?',
    questionId: 85,
  },
  {
    addExtraQuestion: 0,
    answer: 'Undecided treatment',
    isGoToSymptomFlow: false,
    originalAnswer: 'I am not sure what type of treatment I am looking for.',
    question: 'What type of treatment are you looking for?',
    questionId: 86,
  },
];
const {modalFunctions} = modalConfig;
const {errorModalData} = modalFunctions;
const {Analytics} = analyticsData;
const {dateFormat, dateOrCurrentDate, checkIsDateAfterOrToday, isSameDay, endOf} = momentHelper;
const {getAvailability, getLocation, getLatestAvailabilityDate} = helper;
const {getFilteredDoctors} = scheduleService;
const {genderOptions, availabilityOptions, availabilityTypeOptions, sortByoptions} = selectOptions;
const {getAllReviewSource} = reviewService;

// deep link url structure
// /doctors?zipCode=10036&specialties=Physical%20Therapist

class DoctorListing extends Component {
  static contextType = RootContext;
  state = {
    doctorList: null,
    ogProvidersList: [],
    answers: [],
    specialityIds: [],
    filter: {
      selectedResult: null,
      zipCode: '',
      insurance: null,
      date: dateOrCurrentDate(),
    },
    availabilityType: 'all',
    scrolling: '',
    searchScrolling: '',
    filterActive: '',
    mapActive: false,
    isFetching: false,
    allFetched: false,
    page: 1,
    isTabChanging: false,
    availabilityFilter: [],
    activeFilter: null,
  };
  limit = 15;
  additionalFilter = {
    latitude: null,
    longitude: null,
    genders: [],
    languageIds: [],
    isVirtual: false,
    sortBy: [],
  };

  componentDidMount() {
    this.getAndSetReviewSource();
    //Reverted PR #605 https://bitbucket.org/bluelabellabs1/bl278-react/pull-requests/605
    //https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
    //In some browser based on OS setting 'navigator.geolocation.getCurrentPosition' is not trigger callback automatically,
    //so if in the browser location permission preference is not setted, then browser show popup to allow or deny location permission,
    //if user don't select any option, then no any callback called, and site will stuck
    this.getAndSetDoctorListing();

    getLocation(position => {
      if (Object.keys(position).length > 0) {
        this.additionalFilter = {
          ...this.additionalFilter,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      }
      this.getAndSetDoctorListing();
    });
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  getAndSetReviewSource = async () => {
    const {setGlobal, reviewSourceList} = this.context;
    if (!reviewSourceList) {
      const response = await getAllReviewSource().catch();
      if (response) {
        const {data} = response;
        setGlobal('reviewSourceList', data);
      }
    }
  };

  handleScroll = event => {
    const elements = document.getElementsByClassName('PatientHeader');
    const search = document.getElementsByClassName('dc-main-contant');
    let height = 119;
    let offsetSearch;
    if (elements.length > 0) {
      height = elements[0].offsetHeight;
    }
    if (search.length > 0) {
      offsetSearch = search[0].offsetTop;
    }
    if (window.scrollY > height) {
      this.setState({scrolling: 'sticky-header'});
    } else {
      this.setState({scrolling: ''});
    }
    if (window.scrollY > offsetSearch) {
      this.setState({searchScrolling: 'sticky-search'});
    } else {
      this.setState({searchScrolling: ''});
    }
  };

  getHistoryState = () => {
    const {filter} = this.state;
    const {location} = this.props;
    const query = new URLSearchParams(location.search);
    const specialties = query.get('specialties') || '';
    const zipCode = query.get('zipCode') || '';
    return _.get(location, 'state', {
      answers: [],
      specialties,
      specialityIds: [],
      filter: {...filter, zipCode, date: filter['date'].format('YYYY-MM-DD')},
      flowType: 0,
      providerId: null,
    });
  };

  getAndSetDoctorListing = async (newPage = 1, greatProviders = false) => {
    const {availabilityType} = this.state;
    const state = this.getHistoryState();
    let {
      answers = [],
      specialityIds = [],
      filter: stateFilter = {},
      providerId = null,
      specialties = '',
    } = state;
    const startDateString = _.get(stateFilter, 'date');
    const insurance = _.get(stateFilter, 'insurance');
    const selectedResult = _.get(stateFilter, 'selectedResult');
    const datesObj = {};
    let startDate = dateOrCurrentDate();
    if (startDateString) {
      startDate = moment(startDateString, 'YYYY-MM-DD');
    }
    const endDate = startDate.clone();
    // if previous day is current date then add additional day to remove business hours from it
    let extraDay = 0;
    if (isSameDay(endDate.clone().subtract(1, 'day'))) {
      extraDay = 1;
      datesObj[dateFormat()] = [];
      startDate.subtract(1, 'day');
    }
    const utcStartDateString = dateFormat(startDate.clone().utc());
    // add extra day if removed 1 day previously
    startDate.add(extraDay, 'day');
    datesObj[dateFormat(endDate)] = [];
    for (let i = 1; i < 30; i++) {
      endDate.add(1, 'day');
      datesObj[dateFormat(endDate)] = [];
    }
    let TempEnddate = endDate.clone();
    TempEnddate = TempEnddate.add(85, 'day');
    const sortValue = this.additionalFilter.sortBy ? this.additionalFilter.sortBy : null;
    const lat = this.additionalFilter.latitude
      ? this.additionalFilter.latitude
      : stateFilter.latitude;
    const long = this.additionalFilter.longitude
      ? this.additionalFilter.longitude
      : stateFilter.longitude;
    let filter = {
      page: newPage,
      limit: this.limit,
      startDate: utcStartDateString,
      endDate: dateFormat(endOf('day', TempEnddate).utc()),
      specialtyIds: specialityIds,
      providerId,
      insurance,
      zipCode: stateFilter.zipCode || '',
      specialties,
      ...this.additionalFilter,
      sortBy: sortValue,
      latitude: lat,
      longitude: long,
    };
    if (filter.providerId) {
      filter.specialtyIds = [];
    }
    if (selectedResult && selectedResult.type === 'procedure') {
      answers = [{question: 'Procedure', answer: selectedResult.name}];
      filter.procedureIds = [selectedResult.id];
    }
    if (selectedResult && selectedResult.type === 'symptom') {
      filter.painIds = [selectedResult.id];
      const answerWithProcedureData = _.find(answers, answer => {
        return answer.procedureData;
      });
      if (answerWithProcedureData) {
        filter.procedureIds = [answerWithProcedureData.procedureData.id];
      }
      const answerWithMultiProcedureData = _.find(answers, answer => {
        return answer.multiProcedureData;
      });
      if (answerWithMultiProcedureData) {
        filter.procedureIds = _.map(
          answerWithMultiProcedureData.multiProcedureData,
          item => item.id,
        );
      }
    } else {
      const answerWithPainData = _.find(answers, answer => {
        return answer.painData;
      });
      if (answerWithPainData) {
        filter.painIds = [answerWithPainData.painData.id];
      }
      const answerWithProcedureData = _.find(answers, answer => {
        return answer.procedureData;
      });
      if (answerWithProcedureData) {
        filter.procedureIds = [answerWithProcedureData.procedureData.id];
      }
      const answerWithMultiProcedureData = _.find(answers, answer => {
        return answer.multiProcedureData;
      });
      if (answerWithMultiProcedureData) {
        filter.procedureIds = _.filter(
          answerWithMultiProcedureData.multiProcedureData,
          item => item.id != 0,
        ).map(item => item.id);
      }
      const answerWithMultiPainData = _.find(answers, answer => {
        return answer.multiPainData;
      });
      if (answerWithMultiPainData) {
        filter.painIds = _.map(answerWithMultiPainData.multiPainData, item => item.id);
      }
      const answerWithPatientsAccepted = _.find(answers, answer => {
        return answer.patientsAccepted;
      });
      if (answerWithPatientsAccepted) {
        filter.patientsAccepted = answerWithPatientsAccepted.patientsAccepted;
      }
    }
    let updateFilter = {};
    if (stateFilter) {
      updateFilter = {
        ...stateFilter,
        date: startDate,
      };
    }
    if (greatProviders) {
      filter.zipCode = '10001';
      filter.providerId = config.greatProvidersId;
      filter.specialtyIds = [];
      filter.specialties = '';
      filter.painIds = [];
      filter.procedureIds = [];
      answers = [...greatProvidersAns];
    }
    const response = await getFilteredDoctors(filter).catch(() => null);
    if (response) {
      let {data} = response;
      let ogProviders = [];
      if (data.length > 0) {
        ogProviders = data.filter(o => o.isOgProvider);

        data = [
          ...data.filter(d => d.isVirtual === false),
          ...data.filter(d => d.isVirtual === true),
        ];
      }
      let allFetched = false;
      if (data.length < this.limit) {
        allFetched = true;
      }
      let updatedData = [];
      _.forEach(data, item => {
        const {
          providerAvailabilities = [],
          existingAppointmentTime,
          patientAppointments = [],
          timezone = null,
          newAppointmentTime,
        } = item;
        const newAppointmentTimeorExAT =
          existingAppointmentTime > newAppointmentTime
            ? existingAppointmentTime
            : newAppointmentTime;
        let nextOpenDate = getLatestAvailabilityDate(
          item.isVirtual,
          item.latestAvailability,
          providerAvailabilities,
          newAppointmentTimeorExAT,
          patientAppointments,
          timezone,
        );
        // if(!nextOpenDate){
        //   alert(3)
        // }
        const updatedAvailabilities = getAvailability(
          providerAvailabilities,
          datesObj,
          newAppointmentTimeorExAT,
          patientAppointments,
          timezone,
          nextOpenDate,
        );
        if (extraDay) {
          delete updatedAvailabilities[dateFormat()];
        }
        let isAvailable = false;
        let specialTimeAvailable = false;
        let firstDayIndex = 0;
        //Display all doctors 13/08/2021
        _.forEach(updatedAvailabilities, (value, index) => {
          if (!isAvailable) firstDayIndex++;
          if (!isAvailable && value.length > 0) {
            isAvailable = true;
          }
        });
        //Display all doctors 13/08/2021
        _.forEach(updatedAvailabilities, (value, index) => {
          if (!specialTimeAvailable && value.length > 0) {
            if (_.filter(value, data => data.isSpecialTime).length > 0) specialTimeAvailable = true;
          }
        });
        if (isAvailable) {
          const clonedData = _.cloneDeep(item);
          clonedData.formatedAvailabilities = updatedAvailabilities;
          clonedData.firstDayIndex = firstDayIndex;
          clonedData.specialTimeAvailable = specialTimeAvailable;
          updatedData.push(clonedData);
        }
      });

      // Take first 5 availability date
      if (updatedData.length > 0) {
        updatedData.map(itemData => {
          let tempItem = {...itemData};
          let tempformatedAvailabilities = itemData.formatedAvailabilities;
          let availabilityIndex = Object.entries(tempformatedAvailabilities).findIndex(
            ([key, value]) => value.length > 0,
          );
          let startKey = Object.keys(tempformatedAvailabilities)[availabilityIndex];
          startKey = moment(startKey, 'YYYY-MM-DD');
          let tempDateOnj = {};
          tempDateOnj[dateFormat(startKey)] = tempformatedAvailabilities[dateFormat(startKey)];
          let nxtFiveDate = dateFormat(startKey.clone().add(5, 'days'));
          if (!tempformatedAvailabilities[nxtFiveDate]) {
            for (let i = 1; i < 5; i++) {
              startKey.add(-1, 'day');
              let kkey = dateFormat(startKey);
              tempDateOnj[kkey] = tempformatedAvailabilities[kkey]
                ? tempformatedAvailabilities[kkey]
                : [];
            }
            let reverseKey = Object.keys(tempDateOnj).reverse();
            let tempSortK = {};
            for (let i of reverseKey) {
              tempSortK[i] = tempDateOnj[i];
            }
            tempDateOnj = _.clone(tempSortK);
          } else {
            for (let i = 1; i < 5; i++) {
              startKey.add(1, 'day');
              let kkey = dateFormat(startKey);
              tempDateOnj[kkey] = tempformatedAvailabilities[kkey]
                ? tempformatedAvailabilities[kkey]
                : [];
            }
          }
          itemData.formatedAvailabilities = tempDateOnj;
          itemData.firstDayIndex = 1;
          return tempItem;
        });
      }
      // remove doctor from list if doctor has no availability in next 5 days

      updatedData = updatedData.filter(item => {
        let isAvailable = true;
        if (availabilityType === 'video') {
          isAvailable = item.isVirtual;
        } else if (availabilityType === 'inPerson') {
          isAvailable = !item.isVirtual;
        }
        return isAvailable;
      });
      if (updatedData.length > 0 || allFetched) {
        this.setState(prevState => {
          let newDoctorList = [...updatedData];
          let ogProvidersListPrv = [...ogProviders];
          if (newPage !== 1) {
            newDoctorList = [...(prevState.doctorList || []), ...newDoctorList];
          }
          if (ogProviders.length === 0) {
            ogProvidersListPrv = [...prevState.ogProvidersList];
          }
          return {
            ...prevState,
            allFetched,
            page: newPage,
            doctorList: newDoctorList,
            answers,
            specialityIds,
            filter: updateFilter,
            ogProvidersList: ogProvidersListPrv,
          };
        });
      } else {
        this.getAndSetDoctorListing(newPage + 1);
      }
    }
    this.setState({isFetching: false});
  };

  handleTimeSelection = (dateAndTimeObject, doctorDetail) => {
    const query = new URLSearchParams(window.location.search);
    const appointmentSource = query.get('appointmentSource');
    const appointmentSourceUrl = appointmentSource ? `?appointmentSource=${appointmentSource}` : '';
    const state = this.getHistoryState();
    const {answers} = this.state;

    if (answers.length > 0) {
      this.props.history.push({
        pathname: `/confirm-appointment`,
        search: appointmentSourceUrl,
        state: {
          doctorDetail,
          answers: answers,
          dateAndTimeObject,
          flowType: state.flowType,
        },
      });
    } else {
      this.props.history.replace({
        pathname: '/doctor',
        search: appointmentSourceUrl,
        state: {
          filter: {
            selectedResult: {
              ...doctorDetail,
            },
          },
          dateAndTimeObject,
        },
      });
    }
  };

  changeHandler = event => {
    const {name, value} = event.target;
    this.setState(prevState => {
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          [name]: value,
        },
      };
    });
  };

  showfilter = className => {
    this.setState({
      filterActive: className,
    });
  };

  toggleMap = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        mapActive: !prevState.mapActive,
      };
    });
  };
  showError = () => {
    const {setGlobal} = this.context;
    setGlobal(
      'modal',
      errorModalData('Please select any one Condition, specialty, procedure, doctor.'),
    );
  };
  handleRedirect = () => {
    Nux.sendEvent(Analytics.Category.PatientDoctorListing, Analytics.Actions.Click, 'findDoctor');
    const {history} = this.props;
    const {filter: stateFilter} = this.state;
    const {selectedResult} = stateFilter;
    localStorage.setItem('SearchValueKey', selectedResult.name);
    //setOrUpdateLocalData("searchValue",selectedResult.name)
    const {date, ...otherFilter} = stateFilter;
    const filter = {
      date: stateFilter['date'].format('YYYY-MM-DD'),
      ...otherFilter,
    };
    let finalData = {
      pathname: `/doctors`,
      state: {
        filter,
        answers: [],
        specialityIds: [],
      },
    };
    if (selectedResult) {
      switch (selectedResult.type) {
        case 'specialty':
          if (selectedResult.algo) {
            finalData.pathname = `/specialist/${selectedResult.algo}/${selectedResult.name}`;
          }
          break;
        case 'doctor':
          finalData.pathname = `/doctor`;
          break;
        case 'symptom':
          if (selectedResult.algo) {
            finalData.pathname = `/symptom/${selectedResult.algo}/${selectedResult.name}`;
          }
          break;
        case 'procedure':
          if (selectedResult.specialityIds) {
            if (selectedResult.algo) {
              finalData.pathname = `/procedure/${selectedResult.algo}/${selectedResult.name}`;
            } else {
              finalData.pathname = `/doctors`;
            }
          }
          break;
        default:
          break;
      }
    }
    history.push(finalData);
  };

  handleAvailabilityChange = selectedValues => {
    this.setState({availabilityFilter: selectedValues});
    this.resetAndFetchDoctors();
  };

  handleGenderChange = selectedValues => {
    this.additionalFilter = {
      ...this.additionalFilter,
      genders: selectedValues,
    };
    this.resetAndFetchDoctors();
  };

  handleSortByChange = selectedValues => {
    this.additionalFilter = {
      ...this.additionalFilter,
      sortBy: selectedValues,
    };
    this.resetAndFetchDoctors();
  };

  handleLanguageChange = selectedValues => {
    this.additionalFilter = {
      ...this.additionalFilter,
      languageIds: selectedValues,
    };
    this.resetAndFetchDoctors();
  };

  handleActiveFilter = selectedFilter => {
    Nux.sendEvent(Analytics.Category.PatientDoctorListing, Analytics.Actions.Click, 'doctorFilter');
    this.setState({activeFilter: selectedFilter});
  };

  resetAndFetchDoctors = () => {
    this.getAndSetDoctorListing(1);
  };

  handleVirtualChange = availabilityType => {
    this.setState({
      availabilityType,
      isTabChanging: true,
    });
  };

  getFilteredDoctorList = () => {
    const {availabilityType, doctorList, availabilityFilter} = this.state;
    let specialTimeOnly = false;
    let maxDays = 6;
    if (availabilityFilter.length > 0 && _.max(availabilityFilter) > 0)
      maxDays = _.max(availabilityFilter);
    //special time
    if (_.indexOf(availabilityFilter, -1) > -1) specialTimeOnly = true;
    if (!doctorList) {
      return null;
    }
    const filteredDoctorList = doctorList.filter(doctorDetail => {
      const {isVirtual, firstDayIndex, specialTimeAvailable} = doctorDetail;
      let isShowDoctorTtem = true;
      if (availabilityType === 'video') {
        isShowDoctorTtem = isVirtual;
      } else if (availabilityType === 'inPerson') {
        isShowDoctorTtem = !isVirtual;
      }
      return (
        isShowDoctorTtem && firstDayIndex <= maxDays && (!specialTimeOnly || specialTimeAvailable)
      );
    });
    return filteredDoctorList;
  };

  render() {
    const {
      answers,
      filter,
      scrolling,
      searchScrolling,
      filterActive,
      mapActive,
      page,
      availabilityType,
      isTabChanging,
      availabilityFilter,
      ogProvidersList,
    } = this.state;
    const {allFetched, isFetching} = this.state;
    const dateConfig = {
      inputProps: {label: ''},
      datePickerProps: {name: 'date', value: filter.date, isValidDate: checkIsDateAfterOrToday},
      onDateChange: this.changeHandler,
      disableViewModeChange: true,
    };
    const doctorList = this.getFilteredDoctorList();
    if (!doctorList) {
      return null;
    }
    let infiniteScrollOffSet = 400;
    if (doctorList.length === 2) infiniteScrollOffSet = 600;
    else if (doctorList.length > 2) infiniteScrollOffSet = 700;
    const doctorFilterComponent = (
      <>
        <span className="map-overlay" onClick={this.toggleMap}>
          <span className="map-text">Map</span>
          <span className="list-text">List</span>
        </span>
        <div className="search-warp" onClick={() => this.showfilter('show-filter')}></div>
        <ElasticSearch
          name="selectedResult"
          onSelect={this.changeHandler}
          selectedResult={filter['selectedResult']}
        />
        <Input
          label="Zip Code"
          containerClass="zipcode"
          translationType="transform"
          icon={images.mapslocation}
          name="zipCode"
          value={filter['zipCode']}
          onChange={this.changeHandler}
        />
        <SelectDate {...dateConfig} />
        {/* <InsuranceFilterSelect
          inputPlaceholder="Choose Insurance"
          inputIcon={images.guardian}
          containerClass="chooseinsurance"
          insurance={filter['insurance']}
          name="insurance"
          onChange={this.changeHandler}
        /> */}
        <InsuranceFilterSelectPlan
          inputPlaceholder="Choose Insurance"
          inputIcon={images.guardian}
          containerClass="chooseinsurance"
          insurance={filter['insurance']}
          name="insurance"
          onChange={this.changeHandler}
        />
        <button
          className="search-btn"
          // disabled={!filter['selectedResult']}
          onClick={!filter['selectedResult'] ? this.showError : this.handleRedirect}>
          <img src={images.magnifierWhite} alt="serach" className="mg-icon" />
          <img src={images.magnifierWhite} alt="serach" className="mghover-icon" />
          <span>Find Doctors</span>
        </button>
        <CircleButton onClick={() => this.showfilter('')}>Cancel</CircleButton>
      </>
    );
    const noDoctorAvailableComponent = (
      <div className="provider-not-available">
        <div className="h3">
          <span>Sorry, we couldn't match you with any providers.</span>
          <img src={images.close} alt="close" />
        </div>
        <div className="message">
          {/* We are onboarding and screening amazing doctors everyday to provide you with the absolute
          best care. You can still browse relevant providers below or{' '} */}
          <a style={{cursor: 'pointer'}} onClick={() => this.getAndSetDoctorListing(1, true)}>
            Take a look at these great Kaly Providers!
          </a>
          .
        </div>
      </div>
    );
    const toggleTabChange = () => {
      if (isTabChanging) this.setState({isTabChanging: false});
    };
    return (
      <div className={`DoctorListing ${scrolling} ${mapActive ? 'show-map' : ''}`}>
        <PatientLayout hideFooter={true}>
          <div className={`filter sticky-filter ${filterActive}`}>
            <div className="logo">
              <Link to="/">
                <img src={images.logoWhite} alt="logo" />
              </Link>
            </div>
            {doctorFilterComponent}
          </div>
          <div className={`filter ${filterActive}`}>{doctorFilterComponent}</div>
          <div className={`dc-main-contant ${searchScrolling}`}>
            <div className="search-result">
              <div className="search-text">
                <div className="tab-menus">
                  {availabilityTypeOptions.map(availabilityTypeOption => {
                    const {value, label} = availabilityTypeOption;
                    return (
                      <span
                        key={value}
                        onClick={() => this.handleVirtualChange(value)}
                        className={availabilityType === value ? 'active' : ''}>
                        {label}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="search-action">
                <Availability
                  options={availabilityOptions}
                  values={availabilityFilter}
                  onFilterApply={this.handleAvailabilityChange}
                  activeFilter={this.state.activeFilter}
                  onFilterSelect={this.handleActiveFilter}
                />
                <Gender
                  options={genderOptions}
                  values={this.additionalFilter.genders}
                  onFilterApply={this.handleGenderChange}
                  activeFilter={this.state.activeFilter}
                  onFilterSelect={this.handleActiveFilter}
                />
                <Language
                  values={this.additionalFilter.languageIds}
                  onFilterApply={this.handleLanguageChange}
                  activeFilter={this.state.activeFilter}
                  onFilterSelect={this.handleActiveFilter}
                />
                <SortBy
                  options={sortByoptions}
                  values={this.additionalFilter.sortBy}
                  onFilterApply={this.handleSortByChange}
                  activeFilter={this.state.activeFilter}
                  onFilterSelect={this.handleActiveFilter}
                />
              </div>
            </div>
            <div className="doctor-list-wrapper">
              <div className="doctor-list-content">
                {doctorList.length === 0 &&
                  ogProvidersList.length === 0 &&
                  noDoctorAvailableComponent}
                {!isTabChanging && (
                  <>
                    <InfiniteScroll
                      offset={infiniteScrollOffSet}
                      allFetched={allFetched}
                      onScrollToBotton={() => {
                        this.getAndSetDoctorListing(page + 1);
                      }}
                      isFetching={isFetching}
                      setIsFetching={() => {
                        if (!isFetching) {
                          this.setState({isFetching: true});
                        }
                      }}>
                      {doctorList.map((doctorDetail, index) => {
                        const {userId, locationId, isVirtual} = doctorDetail;

                        let keyPart = 'video';
                        if (!isVirtual) {
                          keyPart = `inPerson`;
                        }
                        return (
                          <DoctorItem
                            key={`${userId}-${locationId}-${keyPart}`}
                            selectedResult={filter.selectedResult}
                            answers={answers}
                            doctorDetail={doctorDetail}
                            isVirtual={isVirtual}
                            onTimeSelect={dateAndTimeObject =>
                              this.handleTimeSelection(dateAndTimeObject, doctorDetail)
                            }
                          />
                        );
                      })}
                      {ogProvidersList.map((doctorDetail, index) => {
                        const {id} = doctorDetail;

                        // let keyPart = 'video';
                        // if (!isVirtual) {
                        let keyPart = `inPerson`;
                        //}
                        return (
                          <DoctorItemOrg
                            key={`og_${id}-${index}-${keyPart}`}
                            selectedResult={filter.selectedResult}
                            answers={answers}
                            doctorDetail={doctorDetail}
                            isVirtual={false}
                            onTimeSelect={dateAndTimeObject =>
                              this.handleTimeSelection(dateAndTimeObject, doctorDetail)
                            }
                          />
                        );
                      })}
                    </InfiniteScroll>
                  </>
                )}
                {toggleTabChange()}
              </div>
              <div className="map">
                <MyGoogleMap
                  availabilityType={availabilityType}
                  locationDoctorList={doctorList}
                  onTimeSelect={this.handleTimeSelection}
                />
              </div>
            </div>
          </div>
        </PatientLayout>
      </div>
    );
  }
}

export default DoctorListing;
