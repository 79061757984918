import axios from 'axios';
import _ from 'lodash';
import config from 'config/config';
import clearLocalStorageOnlogout from 'data/localStorageClear';
import cookieHelper from 'data/cookie.helper';
const BASENAME = 'app';
// passing full url to axios overwrite baseUrl
const {removeUserIdCookie} = cookieHelper;
var axiosInstance = axios.create({
  baseURL: config.API_URL,
});
axiosInstance.interceptors.request.use(request => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers.userId = localStorage.getItem('userID');
  }
  return request;
});

axiosInstance.interceptors.response.use(
  response => {
    consoleResponseTime(response);
    // added custom config to remove loader from some api
    response.data.noLoader = _.get(response, 'config.noLoader', false);
    return response.data;
  },
  error => {
    const errorObject = {
      code: 500,
      message: 'Server not responding',
      customCode: 500,
    };
    if (error.response) {
      consoleResponseTime(error.response);
      errorObject.code = error.response.status;
      errorObject.message = _.get(error, 'response.data.message');
      errorObject.customCode = _.get(error, 'response.data.code');
      errorObject.customData = _.get(error, 'response.data.data');
    } else if (error.request) {
    } else {
      errorObject.message = 'Something went wrong';
    }
    if (errorObject.code === 401 || errorObject.code === 4006 || errorObject.code === 408) {
      clearLocalStorageOnlogout();
      removeUserIdCookie();
      console.log('checking request data');
      let islistyourpractice = localStorage.getItem('islistyourpractice');

      if (
        window.location.pathname === '/app/validate-availability' ||
        window.location.pathname === '/app/confirm-appointment'
      ) {
        return {data: {}};
      }
      if (!islistyourpractice) window.location.href = `/${BASENAME}/login`;
      return {data: {}};
    }
    return Promise.reject(errorObject);
  },
);

export default axiosInstance;

//console response time of api
const consoleResponseTime = response => {
  const apiResponseTime = _.get(response, 'data.apiResponseTime');
  // don't console on local environment
  if (apiResponseTime && config.ENVIRONMENT !== 'local') {
    console.table({
      [config.API_URL]: apiResponseTime,
    });
  }
};
