import React, { useEffect, useState } from 'react';
import modalConfig from 'modals/modalConfig';
import Checkbox from 'components/FormControls/Checkbox';
import { Link } from 'react-router-dom';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import DateManual from 'components/FormControls/DateManual';
import validators from 'data/validators';
import Input from 'components/FormControls/Input';
import config from 'config/config';
import allRegex from 'data/regex';
import Select from 'components/FormControls/Select';
import selectOptions from 'data/selectOptions';

export function SocialTermsofUse(props) {
  const { setGlobal } = props.context;
  const { user, provider } = props;
  const { modalFunctions } = modalConfig;
  const { blankModalData } = modalFunctions;
  const { patientGenderOptions } = selectOptions
  const { __validDate, __required, __maxLength, __email, __validDateofBirthDate,__validDateofBirthDatelessthan300 } = validators;
  const { MAX_TEXT_LENGTH } = config;
  const { nameRegex } = allRegex;
  const showName = false; // user?.firstName;
  const TermComponent = props => {
    const { closeModal } = props;
    const values = {};
    const errors = {};
    const [formData, setFormData] = useState({
      values,
      errors,
    });
    useEffect(() => {
      if (user?.firstName) {
        setFormData({
          ...formData,
          values: {
            ...formData.values,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
          },
        });
      }
      console.log('user', user);
    }, [user]);
    const { values: formValues, errors: formErrors } = formData;
    const getGeneralInputProps = controlName => {
      return {
        onChange: changeHandler,
        onBlur: changeHandler,
        error: formErrors[controlName],
        value: formValues[controlName],
        name: controlName,
      };
    };

    const __validateHasAcceptedTerms = (value = null) => {
      const fValue = value !== null ? value : formValues['hasAcceptedTerms'];
      if (fValue === false || !fValue) {
        return 'Plaese accept Terms';
      }
      return null;
    };
    const __validateHasReadHipaa = (value = null) => {
      const fValue = value !== null ? value : formValues['hasReadHipaa'];
      if (fValue === false || !fValue) {
        return 'Plaese accept HIPAA Authorization';
      }
      return null;
    };
    const __validateDateOfBirth = (value = null) => {
      const fValue = value !== null ? value : formValues['dateOfBirth'];
      if (!__validDate(fValue)) {
        return 'Date of birth is invalid ';
      }
      if (!__validDateofBirthDate(fValue)) {
        return "Date of birth must be before today's date";
      }
      if(!__validDateofBirthDatelessthan300(fValue)){
        return "Check date of birth is valid";
      }
      return null;
    };
    const __validateFirstName = (value = null) => {
      const fValue = value !== null ? value : formValues['firstName'];
      if (!__required(fValue)) {
        return 'First name is required';
      }
      if (!nameRegex.test(fValue)) {
        return 'First name is invalid';
      }
      if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
        return `Max ${MAX_TEXT_LENGTH} characters`;
      }
      return null;
    };
    const __validateLastName = (value = null) => {
      const fValue = value !== null ? value : formValues['lastName'];
      if (!__required(fValue)) {
        return 'Last name is required';
      }
      if (!nameRegex.test(fValue)) {
        return 'Last name is invalid';
      }
      if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
        return `Max ${MAX_TEXT_LENGTH} characters`;
      }
      return null;
    };
    const __validateEmail = (value = null) => {
      const fValue = value !== null ? value : formValues['email'];
      if (!__required(fValue)) {
        return 'Email is required';
      }
      if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
        return `Max ${MAX_TEXT_LENGTH} characters`;
      }
      if (!__email(fValue)) {
        return 'Invalid Email';
      }
      return null;
    };
    const __validateGender = (value = null) => {
      const fValue = value !== null ? value : formValues['genderId'];
      if (!__required(fValue)) {
        return 'Gender is required';
      }
      if (fValue<=0) {
        return 'Gender is required';
      }
      return null;
    };
    const __validateForm = () => {
      const validHasAcceptedTerms = !__validateHasAcceptedTerms();
      const validHasReadHipaa = !__validateHasReadHipaa();
      const validDateOfBirth = !__validateDateOfBirth();
      const validGender = !__validateGender();
      if (provider === 'apple' && !showName) {
        const validFirstName = !__validateFirstName();
        const validLastName = !__validateLastName();
        const validateEmail = !__validateEmail();
        
        return (
          validHasAcceptedTerms &&
          validHasReadHipaa &&
          validDateOfBirth &&
          validFirstName &&
          validLastName &&
          validateEmail &&
          validGender
        );
      }
      return validHasAcceptedTerms && validHasReadHipaa && validDateOfBirth && validGender;
    };
    const isValidForm = __validateForm();
    const changeHandler = event => {
      const { name, value } = event.target;
      let errorObject = {
        [name]: null,
      };
      switch (name) {
        case 'hasAcceptedTerms':
          errorObject = {
            [name]: __validateHasAcceptedTerms(value),
          };
          break;
        case 'hasReadHipaa':
          errorObject = {
            [name]: __validateHasReadHipaa(value),
          };
          break;
        case 'dateOfBirth':
          errorObject = {
            [name]: __validateDateOfBirth(value),
          };
          break;
        case 'firstName':
          errorObject = {
            [name]: __validateFirstName(value),
          };
          break;
        case 'lastName':
          errorObject = {
            [name]: __validateLastName(value),
          };
          break;
        case 'email':
          errorObject = {
            [name]: __validateEmail(value),
          };
          break;
        case 'genderId':
          errorObject = {
            [name]: __validateGender(value),
          };
          break;
        default:
          break;
      }
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          [name]: value,
        },
        errors: {
          ...formData.errors,
          ...errorObject,
        },
      });
    };
    const onSubmit = () => {
      if (provider === 'apple' && !showName) {
        props.resolve({
          dob: formValues['dateOfBirth'],
          user: {
            firstName: formValues['firstName'],
            lastName: formValues['lastName'],
            email: formValues['email'],
          },
          genderId:formValues['genderId']
        });
        closeModal();
      } else {
        props.resolve({ dob: formValues['dateOfBirth'],genderId:formValues['genderId'] });
        closeModal();
      }
    };
    return (
      <div className="CreatePasswordModal termsofuse">
        <div className="modal-content-header">
          <div className="h3">Additional Info</div>
          <span
            className="close"
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div className="modal-content-body">
          {provider === 'apple' && !showName && (
            <>
              <div
                style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '10px' }}
                className="form-row">
                <Input
                  {...getGeneralInputProps('firstName')}
                  containerClass="two-input"
                  type="text"
                  label="First name"
                  placeholder={'Enter First Name'}
                />
                <Input
                  {...getGeneralInputProps('lastName')}
                  containerClass="two-input"
                  type="text"
                  label="Last name"
                  placeholder={'Enter Last Name'}
                />
              </div>
              <Input
                label="Enter Your Email"
                name="email"
                placeholder={'Enter Valid Email'}
                instructionText={'Please use an account that will be regularly checked.'}
                {...getGeneralInputProps('email')}
              />
            </>
          )}
          <DateManual label="Date of Birth" {...getGeneralInputProps('dateOfBirth')} />
          <Select
            {...getGeneralInputProps('genderId')}
            defaultOptionProp={{ value: '', label: 'Select Gender' }}
            options={patientGenderOptions}
            label="Gender"
          />
          <div className="checkbox-block privacy">
            <Checkbox {...getGeneralInputProps('hasAcceptedTerms')}></Checkbox>
            <p style={{ margin: 0 }}>
              I have read and accept Kaly{' '}
              <Link className="p-4" to="/terms" target="_blank">
                Terms of Use
              </Link>
              and
              <Link className="p-4" to="/privacy-policy" target="_blank">
                Privacy Policy.
              </Link>
            </p>
          </div>
          <div className="checkbox-block">
            <Checkbox {...getGeneralInputProps('hasReadHipaa')}></Checkbox>
            <p style={{ margin: 0 }}>
              I have read and accept kaly{' '}
              <Link className="p-4" to="/hipaa-policy" target="_blank">
                HIPAA Authorization
              </Link>
            </p>
          </div>
        </div>
        <div className="modal-content-footer">
          <CircleButton
            disabled={!isValidForm}
            onClick={() => {
              onSubmit();
            }}>
            Continue
          </CircleButton>
        </div>
      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: TermComponent,
        customComponentProps: { resolve: resolve, reject: reject },
        closeOnBGClick: false,
      }),
    );
  });
}
