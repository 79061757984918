import React, {useEffect, useContext, useState} from 'react';
import _ from 'lodash';
import images from 'data/images';
import DoctorAppointmentTime from '../DoctorAppointmentTime';
import Divider from 'components/Divider';
import Input from 'components/FormControls/Input';
import Upload from 'components/FormControls/Upload';
import Checkbox from 'components/FormControls/Checkbox';
import CircleButton from 'components/CircleButton';
import masterService from 'services/master';
import validators from 'data/validators';
import RootContext from 'context/RootContext';
import Select from 'components/FormControls/Select';
import helper from 'data/helper';
import './InsuranceInfoModal.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {getAllInsurancePlan} = masterService;
const {__required} = validators;
const {validateFile} = helper;
const {Analytics} = analyticsData;

const InsuranceInfoModal = props => {
  const {closeModal} = props;
  const rootContext = useContext(RootContext);
  const {setGlobal, insurancePlans} = rootContext;
  const {data, updateAppointmentInsuranceData} = props;
  const appointment = _.get(data, 'appointment');
  const {id: appointmentId} = appointment;
  const insuranceCompanyId = _.get(appointment, 'insuranceCompany.id', '');
  const insurancePlanId = _.get(appointment, 'insurancePlan.id', '');
  const insuranceMemberId = _.get(appointment, 'insuranceMemberId', '');
  const insuranceCardFrontPhoto = {name: _.get(appointment, 'insuranceCardFrontPhoto', '')};
  const insuranceCardFrontPhotoUrl = _.get(appointment, 'insuranceCardFrontPhotoUrl', '');
  const insuranceCardBackPhoto = {name: _.get(appointment, 'insuranceCardBackPhoto', '')};
  const insuranceCardBackPhotoUrl = _.get(appointment, 'insuranceCardBackPhotoUrl', '');

  useEffect(() => {
    const getAndSetInsurancePlans = async () => {
      if (!insurancePlans) {
        const insurancePlansResponse = await getAllInsurancePlan().catch(() => null);
        if (insurancePlansResponse) {
          const {data} = insurancePlansResponse;
          setGlobal('insurancePlans', data);
        }
      }
    };
    getAndSetInsurancePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [formData, setFormData] = useState({
    values: {
      appointmentId,
      insuranceCompanyId,
      insurancePlanId,
      insuranceMemberId,
      isSaveToProfile: false,
      insuranceCardFrontPhoto,
      insuranceCardFrontPhotoUrl,
      insuranceCardBackPhoto,
      insuranceCardBackPhotoUrl,
    },
    errors: {},
  });
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validateInsuranceCompany = (value = null) => {
    const fValue = value !== null ? value : formValues['insuranceCompanyId'];
    if (!__required(fValue)) {
      return 'Company is required';
    }
    return null;
  };
  const __validateInsurancePlan = (value = null) => {
    const fValue = value !== null ? value : formValues['insurancePlanId'];
    if (!__required(fValue)) {
      return 'Plan is required';
    }
    return null;
  };
  const __validateInsuranceMemberId = (value = null, prefix) => {
    const fValue = value !== null ? value : formValues[`insuranceMemberId`];
    if (!__required(fValue)) {
      return 'MemberId is required';
    }
    return null;
  };
  const __validateForm = () => {
    const validInsuranceCompany = !__validateInsuranceCompany();
    const validInsurancePlan = !__validateInsurancePlan();
    const validInsuranceMemberId = !__validateInsuranceMemberId();
    return validInsuranceCompany && validInsurancePlan && validInsuranceMemberId;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    let {name} = event.target;
    let {value} = event.target;
    let errorObject = {
      [name]: null,
    };
    let updateValueObj = {};
    switch (name) {
      case 'insuranceCompanyId':
        errorObject = {
          [name]: __validateInsuranceCompany(value),
          insurancePlanId: __validateInsurancePlan(''),
        };
        updateValueObj = {
          insurancePlanId: '',
          insuranceMemberId: '',
        };
        break;
      case 'insurancePlanId':
        errorObject = {
          [name]: __validateInsurancePlan(value),
        };
        updateValueObj = {};
        break;
        case 'insuranceMemberId':
            errorObject = {
              [name]: __validateInsuranceMemberId(value),
            };
          break;  
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
        ...updateValueObj,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  const handleForm = () => {
    Nux.sendEvent(
      Analytics.Category.PatientDashboard,
      Analytics.Actions.Click,
      'appointmentInsuranceSave',
    );
    updateAppointmentInsuranceData(formValues);
  };

  const handleFileSelection = async event => {
    const {files, name} = event.target;
    if (files.length > 0) {
      const newFile = files[0];
      if (await validateFile(newFile)) {
        const customEvent = {
          target: {
            name: name,
            value: newFile,
          },
        };
        changeHandler(customEvent);
      }
    }
  };

  const getInsuranceCompanyPlans = () => {
    let selectedInsuranceCompany = null;
    if (formValues['insuranceCompanyId']) {
      selectedInsuranceCompany = _.find(insurancePlans, {id: formValues['insuranceCompanyId']});
    }
    const plans = _.get(selectedInsuranceCompany, 'insurancePlans', []);
    return plans;
  };

  if (!insurancePlans) {
    return null;
  }

  const memberIdLabelComponent = (
    <>
      <span>Member ID</span>
      {/* <span className="secondary-label"> (optional)</span> */}
    </>
  );
  return (
    <div className="InsuranceInfoModal">
      <div className="modal-content-header">
        <div className="h3">Add Insurance Card Info</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <DoctorAppointmentTime appointment={appointment} />
        <Divider />
        <div className="medical-insurance">
          <div className="h3">Add Medical Insurance</div>
          <form>
            <div className="form-group-2-colum ">
              <Select
                {...getGeneralInputProps('insuranceCompanyId')}
                options={insurancePlans}
                labelIndex="companyName"
                valueIndex="id"
                label="Carrier"
                defaultOptionProp={{label: 'Select Carrier', value: ''}}
              />
              <Select
                {...getGeneralInputProps('insurancePlanId')}
                options={getInsuranceCompanyPlans()}
                labelIndex="plan"
                valueIndex="id"
                className={`plan ${!formValues['insuranceCompanyId'] ? 'disable' : ''}`}
                disabled={!formValues['insuranceCompanyId']}
                label="Plan"
                defaultOptionProp={{label: 'Select Plan', value: ''}}
              />
            </div>
            <Input
              {...getGeneralInputProps('insuranceMemberId')}
              label={memberIdLabelComponent}
              containerClass={`${!formValues['insurancePlanId'] ? 'disable' : ''}`}
              disabled={!formValues['insurancePlanId']}
              placeholder="Member ID"
            />
            <div className="form-group-2-colum ">
              <Upload
                name="insuranceCardFrontPhoto"
                label="Front Photo"
                onChange={handleFileSelection}
                file={formValues['insuranceCardFrontPhoto']}
                fileUrl={formValues['insuranceCardFrontPhotoUrl']}
                error={formErrors['insuranceCardFrontPhoto']}
              />
              <Upload
                name="insuranceCardBackPhoto"
                label="Back Photo"
                onChange={handleFileSelection}
                file={formValues['insuranceCardBackPhoto']}
                fileUrl={formValues['insuranceCardBackPhotoUrl']}
                error={formErrors['insuranceCardBackPhoto']}
              />
            </div>
            <Checkbox {...getGeneralInputProps('isSaveToProfile')}>
              Save to my profile for later use
            </Checkbox>
          </form>
        </div>
      </div>
      <div className="modal-content-footer">
        <CircleButton disabled={!isValidForm} onClick={handleForm}>
          Save & Continue
        </CircleButton>
      </div>
    </div>
  );
};

export default InsuranceInfoModal;
