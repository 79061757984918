const painList = [
  { id: 1, label: 'Low Back Pain', fileName: 'lowBackPain', isTop: true },
  { id: 2, label: 'Mid Back Pain', fileName: 'midBackPain' },
  // {id: 2, label: 'Herniated Disc', fileName: ''},
  { id: 3, label: 'Hip Pain', fileName: 'hipPain' },
  { id: 4, label: 'Neck Pain', fileName: 'neckPain', isTop: true },
  { id: 5, label: 'Pinched nerve in neck', fileName: 'pinchedNerve' },
  { id: 6, label: 'Sciatica', fileName: 'sciatica', isTop: true },
  { id: 7, label: 'Shoulder Pain', fileName: 'shoulderPain' },
  { id: 8, label: 'Ankle Pain', fileName: 'footPain' },
  { id: 9, label: 'Elbow Pain', fileName: 'elbowPain' },
  { id: 10, label: 'Foot Pain', fileName: 'footPain' },
  { id: 11, label: 'Hand Pain', fileName: 'handPain' },
  { id: 12, label: 'Knee Pain', fileName: 'kneePain', isTop: true },
  { id: 13, label: 'Wrist Pain', fileName: 'handPain' },
  { id: 14, label: 'Headache', fileName: 'headache' },
  { id: 15, label: 'Pelvic Floor Dysfunction', fileName: '' },
  { id: 16, label: 'Tooth Decay', fileName: 'toothDecay' },
  { id: 17, label: 'Gum Problems', fileName: 'gumProblems' },
  { id: 18, label: 'Jaw Problems', fileName: 'jawProblems' },
  { id: 19, label: 'Routine Dental Care', fileName: 'routineDentalCare' },
  { id: 20, label: 'Allergy', fileName: '' },
  { id: 21, label: 'Skin Problem', fileName: '' },
  { id: 22, label: 'Respiratory Infection', fileName: '' },
  { id: 23, label: 'Mental Health', fileName: '' },
  { id: 24, label: 'Insomnia', fileName: 'insomnia' },
  { id: 25, label: 'Erectile Dysfunction', fileName: 'erectileDysfunction' },
  { id: 26, label: 'Birth Control', fileName: 'birthControl' },
  { id: 27, label: 'Hair Loss', fileName: 'hairLoss' },
  { id: 28, label: 'Acne', fileName: 'acne' },
  { id: 29, label: 'Premature Ejaculation', fileName: 'prematureEjaculation' },
  { id: 30, label: 'Urinary Tract Infection', fileName: 'urinaryTractInfection' },
  { id: 31, label: 'Cold Sore', fileName: 'coldSore' },
  { id: 32, label: 'Genital Herpes', fileName: 'genitalHerpes' },
  { id: 33, label: 'Asthma', fileName: 'asthma' },
  { id: 34, label: 'Acid Reflux', fileName: 'acidReflux' },
  { id: 35, label: 'Hot Flashes', fileName: 'hotFlashes' },
  { id: 36, label: 'Dark Spots', fileName: 'darkSpots' },
  { id: 37, label: 'Hypothyroidism', fileName: 'hypothyroidism' },
  { id: 38, label: 'Stop Smoking', fileName: 'stopSmoking' },
  { id: 39, label: 'High Blood Pressure', fileName: 'highBloodPressure' },
  { id: 40, label: 'Cholesterol', fileName: 'cholesterol' },
  { id: 41, label: 'STD testing', fileName: 'stdTesting' },
  { id: 42, label: 'Anxiety', fileName: 'anxiety' },
  { id: 43, label: 'Depression', fileName: 'depression' },
  { id: 44, label: 'Broken Tooth', fileName: 'brokenTooth' },
  { id: 45, label: 'Cracked Tooth', fileName: 'crackedTooth' },
  { id: 46, label: 'Chipped Tooth', fileName: 'chippedTooth' },
  { id: 47, label: 'Cosmetic Dentistry', fileName: 'cosmeticDentistry' },
  { id: 48, label: 'Dental Alignment', fileName: 'dentalAlignment' },
  { id: 49, label: 'Acne Dermatology', fileName: 'acneDermatology' },
  { id: 50, label: 'Rash', fileName: 'rash' },
  { id: 51, label: 'Skin or Nail Infection', fileName: 'skinOrNailInfection' },
  { id: 52, label: 'Growth', fileName: 'growth' },
  { id: 53, label: 'Cancer Dermatology', fileName: 'cancerDermatology' },
  { id: 54, label: 'Cosmetic Dermatology', fileName: 'cosmeticDermatology' },
  { id: 86, label: 'Aesthetic Medicine', fileName: '' }
];

export default {
  painList,
};
