import React, {Component} from 'react';
import _ from 'lodash';
import Layout from 'layout/AfterAuth';
import SubHeader from 'components/AfterAuth/SubHeader';
import AuthContext from 'context/AuthContext';
import clipboard from 'clipboard';
import {PhotoshopPicker} from 'react-color';
import Select from 'components/FormControls/Select';
import selectOptions from 'data/selectOptions';
import ReviewWidgetView from 'containers/Widget/ReviewWidget/ReviewWidgetView';
import review from 'services/review';
import config from 'config/config';
import './ShareReviews.css';

const {getReviewsForWidget} = review;

class ShareReviews extends Component {
  static contextType = AuthContext;
  state = {
    selectedColor: '#026ca2',
    shapeOption: 1,
    reviewData: {},
    npiId: 0,
  };

  handleChange = color => {
    this.setState({selectedColor: color.hex});
  };

  componentDidMount() {
    this.changeViewUser();
    const {
      auth: {provider},
    } = this.context;
    let npiId = _.get(provider, 'npi', '');
    this.setState({npiId: npiId});
    new clipboard('.copy-link');
    this.getReviewData(npiId);
  }

  changeViewUser = () => {
    const {auth, setAuthState} = this.context;
    const newViewUserId = _.get(auth, 'id', '');
    let isStaff=false
    let {roles}=auth
    if(roles && roles.length>0 &&roles[0].role === 'Staff'){
      isStaff=true
    }
    if (newViewUserId&& !isStaff) {
      setAuthState(prevState => {
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            viewUserId: newViewUserId,
          },
        };
      });
    }
  };

  getReviewData = async npiId => {
    try {
      const response = await getReviewsForWidget(npiId); //2342423423
      this.setState({reviewData: _.get(response, 'data', {})});
    } catch (ex) {
      console.log(ex);
    }
  };

  render() {
    const {selectedColor, shapeOption, reviewData, npiId} = this.state;
    const shapeData = _.find(selectOptions.reviewShapeOptions, data => data.value === +shapeOption);
    const shapeClass = _.get(shapeData, 'className', '');
    const backgroundColor = selectedColor.replace('#', '');
    const newStyles = {
      default: {
        head: {
          display: 'none',
        },
        controls: {display: 'none'},
        picker: {
          width: '216px',
        },
        saturation: {
          width: '156px',
          height: '156px',
          display: 'inline-block',
        },
        previews: {
          display: 'inline-block',
        },
        hue: {
          padding: '0px 2px',
          position: 'relative',
          height: '156px',
        },
        body: {
          display: 'flex',
          padding: '15px 15px 15px 15px',
        },
      },
    };
    //const {auth} = this.context;
    // const birdEyeBusinessId=_.get(auth, 'birdEyeBusinessId', null);
    // if(!birdEyeBusinessId){
    //   return <NotConnectMsg/>
    // }
    const widgetDimension = ['', '270 X 180', '180 X 180', '145 X 75', '165 X 165'];
    return (
      <Layout>
        <div className="ShareReviews">
          <SubHeader title="Review Widget" />
          <div className="ShareReviews-content">
            <div className="setting-tabs">
              <div className="tab-content">
                <div className="box-panel">
                  <div className="heading">
                    <div className="title">Share reviews on your website </div>
                  </div>
                  <div className="box-inner-content">
                    <div className="Widgets-section">
                      <div className="Widgets-control">
                        <div className="text-info">Customize Widget</div>
                        <div className="Widgets-form">
                          <Select
                            label="Widgets"
                            options={[{value: 1, label: 'Review badge'}]}
                            isAllowDefaultOpen={false}
                            value={1}
                            readOnly
                          />
                          <Select
                            label="Shape"
                            options={selectOptions.reviewShapeOptions}
                            isAllowDefaultOpen={false}
                            value={shapeOption}
                            onChange={e => this.setState({shapeOption: e.target.value})}
                          />
                        </div>
                      </div>
                      <div className="Widgets-control">
                        <div className="text-info">Customize Color</div>
                        <div>
                          <PhotoshopPicker
                            styles={newStyles}
                            header="Customize Color"
                            color={selectedColor}
                            className={'custom-photoshop-picker'}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="Widgets-control">
                        <div className="text-info">Preview Widget</div>
                        <ReviewWidgetView
                          reviewData={reviewData}
                          shapeClass={shapeClass}
                          backgroundColor={backgroundColor}
                        />
                        <div className="dimantion-text">
                          Dimension {widgetDimension[shapeOption]} pixels
                        </div>
                      </div>
                    </div>
                    <div className="Widgets-info">
                      <div className="heading-title">
                        Copy and paste the below snippet to your website to share reviews on your
                        website.
                      </div>
                      <div className="widget-inner-content">
                        <div className="Textarea widget-textarea form-group">
                          <span className="label"></span>
                          <textarea
                            readOnly={true}
                            className="form-control copy-review"
                            value={`<script id="kaly-review-script" type="text/javascript" src="${config.APP_URL}review-widget.js" host="${config.APP_URL}" provider="${npiId}" shape="${shapeOption}" color="${backgroundColor}"></script><div id="kely-review-widget"></div>`}
                          />
                        </div>
                        <span
                          className="copy-link"
                          data-clipboard-action="copy"
                          data-clipboard-target=".copy-review">
                          Copy source code
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ShareReviews;
