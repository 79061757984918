const questionList = [
  {
    id: 180123,
    question: 'Please do not go to the doctor’s office until you have spoken to the staff.',
    options: [{nextQuestionId: 1807, label: 'Okay'}],
    optionType: 1,
    exclude: true,
    descriptions: [
      'Once you make an appointment, your doctor’s office will call you to advise you what to do. ',
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 180123,
    treeDepth: 2,
  },
};
