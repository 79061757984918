import React, {useState} from 'react';
import modalConfig from 'modals/modalConfig';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import validators from 'data/validators';
import Input from 'components/FormControls/Input';
import './CancelSubscription.css';

export function CancelSubscription(props) {
  const {setGlobal} = props.context;
  const {nextBillingDateLong} = props;
  const {modalFunctions} = modalConfig;
  const {blankModalData} = modalFunctions;
  const {__required} = validators;

  const ConfirmComponent = props => {
    const {closeModal, nextBillingDateLong} = props;
    const values = {};
    const errors = {};
    const [formData, setFormData] = useState({
      values,
      errors,
    });

    const {values: formValues, errors: formErrors} = formData;
    const getGeneralInputProps = controlName => {
      return {
        onChange: changeHandler,
        onBlur: changeHandler,
        error: formErrors[controlName],
        value: formValues[controlName],
        name: controlName,
      };
    };

    const __validateTextinput = (value = null) => {
      const fValue = value !== null ? value : formValues['textinput'];
      
      if (!__required(fValue)) {
        return 'Text is required';
      }
      if (fValue !== 'Cancel Subscription') {
        return 'Text is invalid';
      }

      return null;
    };

    const __validateForm = () => {
      const validLastName = !__validateTextinput();

      return validLastName;
    };
    const isValidForm = __validateForm();
    const changeHandler = event => {
      const {name, value} = event.target;
      let errorObject = {
        [name]: null,
      };
      switch (name) {
        case 'textinput':
          errorObject = {
            [name]: __validateTextinput(value),
          };
          break;

        default:
          break;
      }
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          [name]: value,
        },
        errors: {
          ...formData.errors,
          ...errorObject,
        },
      });
    };
    const onSubmit = () => {
      props.resolve(true);
      closeModal();
    };
    return (
      <div className="CreatePasswordModal CancelSubscription">
        <div className="modal-content-header">
          <div className="h3">Confirm</div>
          <span
            className="close"
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div className="modal-content-body">
          <p>
            Please confirm that you wish to permanently cancel your subscription. If you cancel,
            your account will remain active until your next bill date, {nextBillingDateLong}. This
            operation cannot be undone. Type <b>"Cancel Subscription"</b> in the text box below to
            confirm.
          </p>

          <Input
            label="Input text"
            name="textinput"
            placeholder={'Input text'}
            onPaste={e => {
              e.preventDefault();
              return false;
            }}
            onCopy={e => {
              e.preventDefault();
              return false;
            }}
            {...getGeneralInputProps('textinput')}
          />
        </div>
        <div className="modal-content-footer">
          <CircleButton
            disabled={!isValidForm}
            onClick={() => {
              onSubmit();
            }}>
            Cancel Subscription
          </CircleButton>
          <CircleButton
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            Keep Subscription
          </CircleButton>
        </div>
      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ConfirmComponent,
        customComponentProps: {
          resolve: resolve,
          reject: reject,
          nextBillingDateLong: nextBillingDateLong,
        },
        closeOnBGClick: false,
      }),
    );
  });
}
