import React, {Component} from 'react';
import _, {isLength} from 'lodash';
import PatientLayout from 'layout/PatientLayout';
import images from 'data/images';
import AutoSuggestionSelect from 'components/FormControls/AutoSuggestionSelect';
import LoadingPage from '../LoadingPage';
import CircleButton from 'components/CircleButton';
import Divider from 'components/Divider';
import {Redirect} from 'react-router-dom';
import symptom from 'data/questionnaires/symptom';
import doctor from 'data/questionnaires/doctor';
import specialist from 'data/questionnaires/specialist';
import procedure from 'data/questionnaires/procedure';
import questionnaires from 'data/questionnaires';
import questionnaireHelper from 'data/questionnaires/questionnaire.helper';
import painData from 'data/painData';
import procedureData from 'data/procedureData';
import staticOptionsData from 'data/staticOptionsData';
import groupData from 'data/groupData';
import specialityData from 'data/specialityData';
import helper from 'data/helper';
import RootContext from 'context/RootContext';

import modalConfig from 'modals/modalConfig';
import SpecialityModal from 'components/Patient/SpecialityFlow/SpecialityModal';
import './SpecialityFlow.css';
import doctorPainHelper from 'doctorPainHelper';
import masterService from 'services/master';
import patientservice from 'services/patient';
import {setOrUpdateLocalData} from 'data/trackFlowQuestions.helper';
import algoStorageHelper from 'data/algoStorage.helper';
import KalyMDRequestForm from '../KalyMDRequestForm';

const {findConsultRequestPractice} = patientservice;
const {getPainidsArray} = doctorPainHelper;
const {specialityList} = specialityData;
const {getSuffixString, scrollToDiv, getLocation, setAppointmentSourceStore, getLocationAwait} =
  helper;
const {painList} = painData;
const {procedureList} = procedureData;
const {staticOptionsList} = staticOptionsData;
const {getQuestionById} = questionnaireHelper;
const {allQuestionList} = questionnaires;
const {config: doctorConfig} = doctor;
const {symptomConfig} = symptom;
const {procedureConfig} = procedure;
const {specialistConfig} = specialist;
const {groupFunctions} = groupData;
const {getGroupById} = groupFunctions;
const {modalFunctions} = modalConfig;
const {blankModalData, infoModalData} = modalFunctions;
const {setAlgo} = algoStorageHelper;
const {getAllVisitReasons} = masterService;
class SpecialityFlow extends Component {
  static contextType = RootContext;

  state = {
    currentQuestion: null,
    answers: [],
    totalQuestions: 0,
    extraQuestion: 0,
    isShowLoadingPage: true,
    multiSelectProcedureOptionAnswer: [],
    multiSelectPainOptionAnswer: [],
    multiSelectOptionAnswer: [],
    location: false,
    selectedSpecialty: '',
    lastOption: null,
    showKalyMd: false,
    showKalyMdStage: 1,
    selectedCity: null,
    ansCountDifference: 0,
    ConsultRequest: null,
    selectedPainId: null,
  };
  specialityAnswer = null;
  doctorDetails = null;
  dateAndTimeObject = null;
  painAnswer = null;
  procedureAnswer = null;
  flowType = 1; // 1 = symptom, 2 = specialist, 3 = doctor
  speciality = null;
  isObgyn = false;
  componentDidMount() {
    this.setQuestionConfig();
    getLocation(location => {
      if (location.loc_details) this.setState({location: location.loc_details});
    });
    this.getVisitReasons();
  }
  getVisitReasons = async () => {
    const {visitReasons, setGlobal} = this.context;
    if (!visitReasons) {
      const response = await getAllVisitReasons(true).catch(null);
      if (response) {
        const {data} = response;
        setGlobal('visitReasons', data);
      }
    }
  };
  setQuestionConfig = async () => {
    const {match} = this.props;
    const {params} = match;
    const {type, flowType, name} = params;
    let config = null;
    let currentConfig = null;
    const {location} = this.props;
    const {state} = location;

    if (flowType === 'doctor') {
      if (!state) {
        this.props.history.push('/');
        return null;
      }
      this.doctorDetails = state.filter.selectedResult;
      // if user comes from seo page then store dateAndTimeObject
      this.dateAndTimeObject = state.dateAndTimeObject;
      currentConfig = doctorConfig;
      this.flowType = 3;
    } else {
      if (flowType === 'symptom') {
        this.flowType = 1;
        config = symptomConfig;
        let painData = _.find(painList, sp => {
          return sp.fileName === type && sp.label == name;
        });
        if (!painData) {
          painData = _.find(painList, sp => {
            return sp.fileName === type;
          });
        }
        let zipCode = _.get(state.filter, 'zipCode', null);
        let ipdata = await getLocationAwait();

        let passData = {};
        if (zipCode) {
          passData = {
            painId: [painData.id],
            zipCode: zipCode, //optional if below is present
          };
        } else if (ipdata.loc_details && ipdata.loc_details.region_code) {
          passData = {
            painId: [painData.id],
            regionName: ipdata.loc_details.region_name,
            regionCode: ipdata.loc_details.region_code,
          };
        }
        await findConsultRequestPractice(passData)
          .catch(error => {
            console.log('er', error);
            this.setState({
              ConsultRequest: null,
              selectedPainId: painData.id,
              answers: [{question: 'Symptom', answer: name ? name : painData.label}],
            });
          })
          .then(res => {
            if (res && res.data)
              this.setState({
                ConsultRequest: res.data,
                selectedPainId: painData.id,
                answers: [{question: 'Symptom', answer: name ? name : painData.label}],
              });
            else
              this.setState({
                ConsultRequest: null,
                selectedPainId: painData.id,
                answers: [{question: 'Symptom', answer: name ? name : painData.label}],
              });
          });
      } else if (flowType === 'procedure') {
        this.flowType = 4;
        config = procedureConfig;
        let procedureData = _.find(procedureList, sp => {
          return sp.fileName === type;
        });
        this.setState({
          answers: [{question: 'Procedure', answer: name ? name : procedureData.procedure}],
        });
      } else {
        this.flowType = 2;
        config = specialistConfig;
        this.speciality = _.find(specialityList, sp => {
          return sp.fileName === type;
        });
        let selectedSpecialty = this.speciality.specialty ? this.speciality.specialty : '';
        this.setState({
          answers: [
            {
              question: 'Specialty',
              answer: name ? name : this.speciality.specialty,
              specialityId: this.speciality.id,
            },
          ],
          selectedSpecialty: selectedSpecialty,
        });
      }
      currentConfig = config[type];
    }
    if (!currentConfig) {
      this.props.history.push('/');
      return;
    }
    const {firstQuestionId, treeDepth, groupIdNewRedirect = null} = currentConfig;
    const currentQuestion = getQuestionById(firstQuestionId);
    setOrUpdateLocalData('flowTypeId', this.flowType);
    setTimeout(() => {
      this.setState({
        totalQuestions: treeDepth,
        isShowLoadingPage: false,
        currentQuestion,
        groupIdNewRedirect,
      });
    }, 3000);
  };

  goBack = () => {
    const {answers, totalQuestions, ansCountDifference, showKalyMd, showKalyMdStage} = this.state;

    const ans = answers.pop();
    let {extraQuestion} = this.state;
    if (ans.isGoToSymptomFlow) {
      extraQuestion = 0;
    }
    if (_.get(ans, 'addExtraQuestion', 0) > 0) {
      extraQuestion = extraQuestion - _.get(ans, 'addExtraQuestion', 0);
    }
    if (ans.painData) {
      this.painAnswer = null;
      this.procedureAnswer = null;
    }
    const prevQuestion = getQuestionById(ans.questionId);
    if (showKalyMdStage - 1 >= 1) {
      this.setState({
        answers,
        showKalyMdStage: showKalyMdStage - 1,
      });
    } else {
      let tempTotalQuestions = totalQuestions;
      if (showKalyMd) {
        tempTotalQuestions = totalQuestions - ansCountDifference;
      }
      this.setState({
        answers,
        currentQuestion: prevQuestion,
        finalAnswer: null,
        extraQuestion,
        showKalyMd: false,
        showKalyMdStage: 1,
        selectedCity: null,
        totalQuestions: tempTotalQuestions,
      });
    }
  };

  getNextQuestionOrAnswer = (option = null) => {
    let nextQuestion = null;
    let finalAnswer = null;
    const {currentQuestion, redirectObj, groupIdNewRedirect} = this.state;
    const {nextQuestionId, isNextQuestionOfSpeciality} = currentQuestion;
    const isGoToSymptomFlow = _.get(option, 'isGoToSymptomFlow');
    let groupId = '';
    if (currentQuestion && currentQuestion.questionGroupId) {
      groupId = _.get(currentQuestion, 'questionGroupId');
    } else {
      groupId = _.get(option, 'groupId');
    }
    const optionNextQuestionId = _.get(option, 'nextQuestionId');
    if (isNextQuestionOfSpeciality && this.specialityAnswer) {
      const selectedSp = _.find(specialityList, sp => {
        return sp.id.toString() === this.specialityAnswer.id.toString();
      });
      if (selectedSp) {
        const lastQuestionId = _.get(specialistConfig, `${selectedSp.fileName}.lastQuestionId`);
        if (lastQuestionId) {
          nextQuestion = getQuestionById(lastQuestionId);
        } else if (this.painAnswer) {
          const redirectQuestionIds = _.get(option, 'redirectQuestionIds');
          let newRedirectObj;
          if (redirectQuestionIds) {
            let redirectQuestionObj = _.find(redirectQuestionIds, {
              painId: parseInt(this.painAnswer),
            });
            if (redirectQuestionObj) {
              newRedirectObj = redirectQuestionObj;
            }
          }
          if (newRedirectObj) {
            if (newRedirectObj.questionId) {
              nextQuestion = getQuestionById(newRedirectObj.questionId);
            } else if (newRedirectObj.groupId) {
              finalAnswer = {...getGroupById(newRedirectObj.groupId)};
            } else {
              this.props.history.push('/');
            }
          } else {
            this.props.history.push('/');
          }
        } else {
          this.props.history.push('/');
        }
      }
    } else if (nextQuestionId) {
      if (
        option &&
        option.id == 66 &&
        isGoToSymptomFlow == true &&
        option.redirectQuestionIds == '901'
      ) {
        nextQuestion = getQuestionById(901);
      } else if (
        option &&
        ['67', '68', '69', '70', '71'].includes(option.id) &&
        isGoToSymptomFlow == true &&
        option.redirectQuestionIds == '911'
      ) {
        nextQuestion = getQuestionById(911);
      } else nextQuestion = getQuestionById(nextQuestionId);
    } else if (groupId) {
      finalAnswer = {...getGroupById(groupId)};
    } else if (optionNextQuestionId) {
      nextQuestion = getQuestionById(optionNextQuestionId);
    } else if (isGoToSymptomFlow) {
      if (redirectObj) {
        if (redirectObj.questionId) {
          nextQuestion = getQuestionById(redirectObj.questionId);
        } else if (redirectObj.groupId) {
          finalAnswer = {...getGroupById(redirectObj.groupId)};
        } else {
          this.props.history.push('/');
        }
      } else {
        this.props.history.push('/');
      }
    }

    if (finalAnswer) {
      setOrUpdateLocalData('finishedFlowResponse', true);
    } else {
      setOrUpdateLocalData('finishedFlowResponse', false);
    }
    if (finalAnswer && groupIdNewRedirect) {
      if (finalAnswer.id === 'E') {
        finalAnswer.specialityIds = groupIdNewRedirect;
      }
    }
    return {
      nextQuestion,
      finalAnswer,
    };
  };

  setPainAnswer = e => {
    const {value} = e.target;
    if (value) {
      const {currentQuestion} = this.state;
      let isweek =
        currentQuestion.questOptions &&
        (value.toString().includes('week') || value.toString().includes('Not sure'));

      this.painAnswer = value;
      const painObj = !isweek ? _.find(painList, {id: parseInt(value)}) : {};
      let {nextQuestion, finalAnswer} = this.getNextQuestionOrAnswer();
      const answer = {
        questionId: currentQuestion.id,
        question: currentQuestion.question,
        answer: !isweek ? painObj.label : value,
        originalAnswer: !isweek ? painObj.label : value,
        //painData: { ...painObj },
      };
      if (!isweek) {
        answer['painData'] = {...painObj};
      }
      this.setState(prevState => {
        const updatedAnswers = [...prevState.answers, answer];
        return {
          ...prevState,
          answers: updatedAnswers,
          currentQuestion: nextQuestion,
          finalAnswer: finalAnswer,
        };
      });
    }
    scrollToDiv();
  };

  setProcedureAnswer = e => {
    const {value} = e.target;
    if (value) {
      const {currentQuestion} = this.state;
      this.procedureAnswer = value;
      const procedureObj = _.find(procedureList, {id: parseInt(value)});
      let {nextQuestion} = this.getNextQuestionOrAnswer();
      const answer = {
        questionId: currentQuestion.id,
        question: currentQuestion.question,
        answer: procedureObj.procedure,
        originalAnswer: procedureObj.procedure,
        procedureData: {...procedureObj},
      };
      this.setState(prevState => {
        const updatedAnswers = [...prevState.answers, answer];
        return {
          ...prevState,
          answers: updatedAnswers,
          currentQuestion: nextQuestion,
        };
      });
    }
    scrollToDiv();
  };

  setMultiProcedureAnswer = () => {
    const {multiSelectProcedureOptionAnswer} = this.state;
    if (multiSelectProcedureOptionAnswer.length > 0) {
      const {currentQuestion} = this.state;
      let {nextQuestion, finalAnswer} = this.getNextQuestionOrAnswer();
      const selectedProcedures = _.filter(procedureList, procedureObj =>
        multiSelectProcedureOptionAnswer.includes(procedureObj.id),
      );
      const ansString = _.map(selectedProcedures, item => item.procedure).join(', ');
      const answer = {
        questionId: currentQuestion.id,
        question: currentQuestion.question,
        answer: ansString,
        originalAnswer: ansString,
        multiProcedureData: selectedProcedures,
      };
      if (nextQuestion) {
        this.setState(prevState => {
          const updatedAnswers = [...prevState.answers, answer];
          return {
            ...prevState,
            answers: updatedAnswers,
            currentQuestion: nextQuestion,
          };
        });
      } else {
        this.setState(prevState => {
          const updatedAnswers = [...prevState.answers, answer];
          return {
            ...prevState,
            answers: updatedAnswers,
            currentQuestion: nextQuestion,
            finalAnswer: finalAnswer,
          };
        });
      }
    }
    scrollToDiv();
  };

  setMultiPainAnswer = () => {
    const {multiSelectPainOptionAnswer} = this.state;
    if (multiSelectPainOptionAnswer.length > 0) {
      const {currentQuestion} = this.state;
      let {nextQuestion} = this.getNextQuestionOrAnswer();
      const selectedPains = _.filter(painList, painObj =>
        multiSelectPainOptionAnswer.includes(painObj.id),
      );
      const ansString = _.map(selectedPains, item => item.label).join(', ');
      const answer = {
        questionId: currentQuestion.id,
        question: currentQuestion.question,
        answer: ansString,
        originalAnswer: ansString,
        multiPainData: selectedPains,
      };
      this.setState(prevState => {
        const updatedAnswers = [...prevState.answers, answer];
        return {
          ...prevState,
          answers: updatedAnswers,
          currentQuestion: nextQuestion,
        };
      });
    }
    scrollToDiv();
  };

  setMultiOptionAnswer = () => {
    const {multiSelectOptionAnswer} = this.state;
    if (multiSelectOptionAnswer.length > 0) {
      const {currentQuestion} = this.state;
      let {nextQuestion, finalAnswer} = this.getNextQuestionOrAnswer();
      const selectedOptions = _.filter(staticOptionsList, option =>
        multiSelectOptionAnswer.includes(option.id),
      );
      const ansString = _.map(selectedOptions, item => item.label).join(', ');
      const answer = {
        questionId: currentQuestion.id,
        question: currentQuestion.question,
        answer: ansString,
        originalAnswer: ansString,
      };
      if (nextQuestion) {
        this.setState(prevState => {
          const updatedAnswers = [...prevState.answers, answer];
          return {
            ...prevState,
            answers: updatedAnswers,
            currentQuestion: nextQuestion,
          };
        });
      } else {
        this.setState(prevState => {
          const updatedAnswers = [...prevState.answers, answer];
          return {
            ...prevState,
            answers: updatedAnswers,
            currentQuestion: nextQuestion,
            finalAnswer: finalAnswer,
          };
        });
      }
    }
    scrollToDiv();
  };

  storeAnswer = async (option = null) => {
    const {setGlobal} = this.context;
    const {currentQuestion, redirectObj, extraQuestion, totalQuestions, ConsultRequest} =
      this.state;
    let {nextQuestion, finalAnswer} = this.getNextQuestionOrAnswer(option);
    let isGoToSymptomFlow = _.get(option, 'isGoToSymptomFlow', false);
    let patientsAccepted = _.get(option, 'patientsAccepted', 0);
    let painId = _.get(option, 'painId', 0);
    let procedureId = _.get(option, 'procedureId', 0);
    let addExtraQuestion = _.get(option, 'addExtraQuestion', 0);
    let showKalyMd = _.get(option, 'showKalyMd', false);
    if (showKalyMd && ConsultRequest && Object.keys(ConsultRequest).length > 0) {
      //const { setGlobal } = this.context;
      let tempOption = {...option};
      try {
        tempOption.showKalyMd = false;

        let answertemp = {
          questionId: currentQuestion.id,
          question: currentQuestion.question,
          answer: option.shortLabel || option.label,
          originalAnswer: option.label,
          isGoToSymptomFlow,
          addExtraQuestion,
        };
        this.setState(prevState => {
          const {answers} = prevState;
          let ansCountDifference = totalQuestions - answers.length;
          let temptotalQuestions =
            ansCountDifference > 3
              ? totalQuestions
              : ansCountDifference == 3
              ? totalQuestions + 1
              : totalQuestions + ansCountDifference;
          ansCountDifference =
            ansCountDifference > 3 ? 0 : ansCountDifference == 3 ? 1 : ansCountDifference;
          let updatedAnswers = [...answers, answertemp];
          setOrUpdateLocalData('flowResponse', updatedAnswers);

          return {
            lastOption: tempOption,
            ansCountDifference: ansCountDifference,
            answers: updatedAnswers,
            totalQuestions: temptotalQuestions,
            showKalyMd: true,
            showKalyMdStage: 1,
            selectedCity: null,
          };
        });
        scrollToDiv();
        return null;
      } catch (error) {
        console.log('error', error);
      }
    } else if (this.doctorDetails && currentQuestion.id == 601) {
      let selectedSp = '';
      let {specialties} = this.doctorDetails;
      selectedSp = specialties.find(s => s.id == option.id);

      if (selectedSp.id == '72') {
        let answertemp = {
          questionId: currentQuestion.id,
          question: currentQuestion.question,
          answer: option.shortLabel || option.label,
          originalAnswer: option.label,
          isGoToSymptomFlow,
          addExtraQuestion,
        };
        //let tempAnsArray = [...this.state.answers, answertemp]
        if (this.state.finalAnswer !== null) {
          return;
        }
        this.setState(prevState => {
          const {answers} = prevState;
          let updatedAnswers = [...answers, answertemp];
          setOrUpdateLocalData('flowResponse', updatedAnswers);
          setAlgo({
            flowType: this.flowType,
            answers: updatedAnswers,
            providerId: this.doctorDetails?.providerId,
          });
          return {answers: updatedAnswers, finalAnswer: currentQuestion};
        });

        //Aesthetic Medicine Specialist redirect to doctor Profile
        // if (this.dateAndTimeObject) {
        //   const query = new URLSearchParams(window.location.search);
        //   const appointmentSource = query.get('appointmentSource');
        //   const accessLogProviderId = query.get('accessLogProviderId');
        //   const accessLogPracticeId = query.get('accessLogPracticeId');
        //   let appointmentSourceUrl = appointmentSource ? `?appointmentSource=${appointmentSource}` : '';
        //   const accessLog = accessLogProviderId ? `accessLogProviderId=${accessLogProviderId}` : accessLogPracticeId ? `accessLogPracticeId=${accessLogPracticeId}` : '';
        //   appointmentSourceUrl = appointmentSourceUrl ? `${appointmentSourceUrl}&${accessLog}` : `?${accessLog}`

        //  let redirectObject = {
        //     pathname: `/confirm-appointment`,
        //     search: appointmentSourceUrl,
        //     state: {
        //       ...state,
        //       answers: tempAnsArray,
        //       doctorDetail: this.doctorDetails,
        //     },
        //   };
        // } else {
        //   this.redirectToDoctorProfile()
        // }
        return;
      }
    }
    const answer = {
      questionId: currentQuestion.id,
      question: currentQuestion.question,
      answer: option.shortLabel || option.label,
      originalAnswer: option.label,
      isGoToSymptomFlow,
      addExtraQuestion,
    };
    if (patientsAccepted > 0) {
      answer.patientsAccepted = patientsAccepted;
    }
    if (currentQuestion.questionType === 3) {
      this.specialityAnswer = option;
    }
    if (Array.isArray(procedureId)) {
      const selectedProcedures = _.filter(procedureList, procedureObj =>
        procedureId.includes(procedureObj.id),
      );
      answer.multiProcedureData = selectedProcedures;
    } else if (procedureId > 0) {
      answer.procedureData = {id: procedureId};
    }
    if (painId > 0) {
      answer.painData = {id: painId};
    }
    this.setState(prevState => {
      const {answers} = prevState;
      let updatedAnswers = [...answers, answer];
      if (
        prevState.currentQuestion.questionType === 2 ||
        _.get(prevState, 'currentQuestion.exclude', false)
      ) {
        updatedAnswers = [...answers];
      }
      let updateObj = {finalAnswer};
      const redirectQuestionIds = _.get(option, 'redirectQuestionIds');
      let isGoToSymptomFlow = _.get(option, 'isGoToSymptomFlow', false);
      let addExtraQuestion = _.get(option, 'addExtraQuestion', 0);
      if (redirectQuestionIds) {
        let redirectQuestionObj = _.find(redirectQuestionIds, {painId: parseInt(this.painAnswer)});
        if (redirectQuestionObj) {
          updateObj.redirectObj = redirectQuestionObj;
        }
      }
      if (isGoToSymptomFlow) {
        if (redirectObj) {
          updateObj.extraQuestion = extraQuestion + _.get(redirectObj, 'extraQuestion', 3);
        } else {
          updateObj.extraQuestion = extraQuestion + 3;
        }
      } else if (addExtraQuestion > 0) {
        updateObj.extraQuestion = extraQuestion + addExtraQuestion;
      }

      setOrUpdateLocalData('flowResponse', updatedAnswers);
      return {
        ...prevState,
        answers: updatedAnswers,
        currentQuestion: nextQuestion,
        ...updateObj,
      };
    });
    if (nextQuestion && nextQuestion.questionType === 2) {
      setGlobal(
        'modal',
        blankModalData({
          CustomComponent: SpecialityModal,
          customComponentProps: {
            currentQuestion: nextQuestion,
            storeAnswer: this.storeAnswer,
            goBack: this.goBack,
          },
          closeOnBGClick: false,
        }),
      );
    }
    scrollToDiv();
  };
  showInfo = msg => {
    const {setGlobal} = this.context;
    setGlobal('modal', infoModalData(msg, {}, ' '));
  };
  renderAnswerComponent = () => {
    const {answers, finalAnswer, extraQuestion} = this.state;
    const {location} = this.props;
    const {state = {}} = location;
    let answerComponent = null;
    if (finalAnswer.id === 'A') {
      answerComponent = (
        <div className="sf-main-content sf-step-complete">
          <div className="sf-inner-content sf-select-answer">
            <div className="heading2">Please seek urgent medical attention.</div>
            <div className="sf-list-content ">
              <span className="select-option-text">
                Please seek help from the following for your symptoms.
              </span>
              <span className="urgent-care-text">
                {finalAnswer.descriptions.map((description, index) => {
                  return (
                    <span key={index}>
                      {description} <br />
                    </span>
                  );
                })}
              </span>
            </div>
          </div>
          <div className="sf-action complete-action">
            <CircleButton link="/" btnType="WhiteButton">
              Ok
            </CircleButton>
          </div>
        </div>
      );
    } else {
      let specialityIds = [];
      if (finalAnswer.id === 'GOTO_LIST') {
        const answerWithSpecialityId = _.filter(answers, answer => {
          return answer.specialityId;
        });
        if (answerWithSpecialityId) {
          specialityIds = _.map(answerWithSpecialityId, item => item.specialityId);
        }
      } else if (finalAnswer.id === 'GOTO_LIST_WITHOUT_SP') {
        specialityIds = [];
      } else {
        specialityIds = finalAnswer.specialityIds;
      }
      const newStateObject = {
        ...state,
        providerId: extraQuestion === 0 ? _.get(this.doctorDetails, 'providerId', null) : null,
        specialityIds: specialityIds,
        answers: answers,
        flowType: this.flowType,
      };
      let redirectObject = {
        pathname: '/doctors',
        state: newStateObject,
      };
      let isPush = true;
      setAlgo({
        flowType: this.flowType,
        answers,
        providerId: this.doctorDetails?.providerId,
      });

      // if flow is of doctor
      if (this.flowType === 3) {
        // if user has selected datetime already from seo page
        if (this.dateAndTimeObject) {
          const query = new URLSearchParams(window.location.search);
          const appointmentSource = query.get('appointmentSource');
          const accessLogProviderId = query.get('accessLogProviderId');
          const accessLogPracticeId = query.get('accessLogPracticeId');
          let appointmentSourceUrl = appointmentSource
            ? `?appointmentSource=${appointmentSource}`
            : '';
          const accessLog = accessLogProviderId
            ? `accessLogProviderId=${accessLogProviderId}`
            : accessLogPracticeId
            ? `accessLogPracticeId=${accessLogPracticeId}`
            : '';
          appointmentSourceUrl = appointmentSourceUrl
            ? `${appointmentSourceUrl}&${accessLog}`
            : `?${accessLog}`;

          isPush = false;
          redirectObject = {
            pathname: `/confirm-appointment`,
            search: appointmentSourceUrl,
            state: {
              ...state,
              answers: answers,
              doctorDetail: this.doctorDetails,
            },
          };
        } else {
          // if user comes from elastic search page. then redirect to seo page for time selection.
          this.redirectToDoctorProfile();
          return;
        }
      }
      return <Redirect push={isPush} to={redirectObject} />;
    }
    return answerComponent;
  };
  redirectToDoctorProfile = () => {
    setAppointmentSourceStore(1);
    if (localStorage.getItem('accessToken') && localStorage.getItem('userID')) {
      let uid = localStorage.getItem('userID');
      var date = new Date();
      date.setTime(date.getTime() + 60 * 60 * 1000);
      var expires = '; expires=' + date.toGMTString();
      //  setCookie('userID',localStorage.getItem('userID'),0.0333333)
      let host = window.location.host;
      document.cookie = `userId=${uid};domain=.${host};path=/;secure; SameSite=none${expires}`;
    }

    setOrUpdateLocalData('providerId', this.doctorDetails.providerId);
    window.location.href = `${this.doctorDetails.seoPageUrl}`;
  };
  renderBackArrowComponent = () => {
    const {answers} = this.state;
    const {match} = this.props;
    const {params} = match;
    const {flowType} = params;
    let docAnsCount = 0;
    if (flowType === 'doctor') {
      docAnsCount = 1;
    }
    let backArrowComponent = null;
    if (answers.length + docAnsCount - 1 > 0) {
      backArrowComponent = (
        <span className="arrow" onClick={this.goBack}>
          <img src={images.arrowLeft} alt="arrow" />
        </span>
      );
    }
    return backArrowComponent;
  };
  calltoAction = async action => {
    let answertemp = {};
    if (action == 2) {
      answertemp = {
        questionId: '86945',
        question:
          'Get a free virtual consultation from a KalyMD physician now.  We currently only support patients physically located in New York and New Jersey.',
        answer: 'Request Call/SMS',
        originalAnswer: 'Request Call/SMS',
        isGoToSymptomFlow: false,
        addExtraQuestion: 0,
      };
    } else if (action == 3) {
      let {selectedCity} = this.state;
      answertemp = {
        questionId: '86946',
        question:
          'To connect you to the best provider, please confirm what state you are located in.',
        answer: selectedCity,
        originalAnswer: selectedCity,
        isGoToSymptomFlow: false,
        addExtraQuestion: 0,
      };
    }

    const {answer} = this.state;
    // answer.painData
    this.setState(prevState => {
      const {answers} = prevState;
      let updatedAnswers = [...answers, answertemp];
      setOrUpdateLocalData('flowResponse', updatedAnswers);

      return {showKalyMdStage: action, answers: updatedAnswers};
    });
    scrollToDiv();
  };
  renderQuestionComponent = () => {
    const {
      currentQuestion,
      showKalyMd,
      lastOption,
      showKalyMdStage,
      selectedCity,
      ConsultRequest,
      answers,
    } = this.state;
    const {question} = currentQuestion;
    const questionType = _.get(currentQuestion, 'questionType', 0);
    let questionComponent;
    if (showKalyMd && ConsultRequest) {
      let introductionObject = JSON.parse(ConsultRequest.introductionText.replace('`', ''));
      if (showKalyMdStage == 1) {
        return (
          <div className="sf-main-content sf-step-3">
            <div className="sf-inner-content">
              {/* <div className="heading2">Get a free consultation from a KalyMD physician now.   We currently only support New York and New Jersey.</div> */}
              <div className="sf-inner-content sf-description">
                {/* <Divider></Divider> */}
                <div className="heading2">{ConsultRequest.callToAction}</div>
              </div>
              <div className="sf-action kalymd-form RequestaCallSMS">
                <CircleButton
                  key={'RequestCal'}
                  onClick={() => this.calltoAction(2)}
                  btnType="secondaryButton">
                  Request a Call/SMS
                </CircleButton>
                <CircleButton
                  key={'ContinueSearch'}
                  onClick={() => this.storeAnswer(lastOption)}
                  btnType="secondaryButton">
                  Continue to find local physicians
                </CircleButton>
              </div>
            </div>
          </div>
        );
      } else if (showKalyMdStage == 2) {
        return (
          <div className="sf-main-content sf-step-3">
            <div className="sf-inner-content">
              <div className="heading2">
                To connect you to the best provider, please confirm what state you are located in.
              </div>
              <div className="sf-list-content pms-content">
                <Divider></Divider>
                <div className="h3">
                  Please confirm the state you will be in when we contact you.
                </div>
              </div>
              <>
                <div className={`sf-list-content sf-select-answer`}>
                  {['New York', 'New Jersey', 'Other'].map(option => {
                    return (
                      <span
                        key={option}
                        onClick={() => {
                          this.setState({selectedCity: option});
                          scrollToDiv(null, 'focusbtndiv');
                        }}
                        className={[
                          'sf-description-item',
                          selectedCity == option ? 'active-item' : '',
                        ].join(' ')}>
                        <span>{option}</span>
                      </span>
                    );
                  })}
                </div>

                {/* <div className="sf-action">
                  <span className="select-option-text">Please select one</span>
                </div>
              */}
              </>
              <div className="sf-list-content">
                {selectedCity === 'Other' && (
                  <>
                    <div style={{marginBottom: '15px'}} className="error-msg">
                      We currently only support New York and New Jersey. Please press 'Go back to
                      Search' to finding local providers.
                    </div>
                  </>
                )}
                <div id="focusbtndiv" className="item-right kalymd-form sf-action">
                  <CircleButton
                    key={`Continuebtn`}
                    disabled={selectedCity === 'Other' || !selectedCity}
                    onClick={() => this.calltoAction(3)}
                    btnType="secondaryButton">
                    Continue
                  </CircleButton>
                  <CircleButton
                    key={`Gobacktosearch`}
                    onClick={() => this.storeAnswer(lastOption)}
                    btnType="secondaryButton">
                    Go back to Search
                  </CircleButton>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (showKalyMdStage == 3) {
        return (
          <div className="sf-main-content sf-step-3">
            <div className="sf-inner-content">
              <div className="heading2">{introductionObject.title}</div>
            </div>
            <div className="sf-list-content pms-content">
              <Divider></Divider>
              <div style={{textAlign: 'left'}} className="h3">
                <div dangerouslySetInnerHTML={{__html: introductionObject.Para}}></div>
              </div>
            </div>
            <KalyMDRequestForm
              history={this.props.history}
              selectedPainId={this.state.selectedPainId}
              flowType={this.flowType}
              answers={answers}
              callToActionData={ConsultRequest}
            />
          </div>
        );
      }
    }

    if (questionType === 1) {
      questionComponent = (
        <div className="sf-main-content sf-step-3">
          <div className="sf-inner-content">
            <div className="heading2">{currentQuestion.title}</div>
            <div className="sf-list-content pms-content">
              {currentQuestion.descriptions.map((description, index) => {
                return (
                  <div key={index} className="description">
                    {description}
                  </div>
                );
              })}
              <div className="pms-list">
                {currentQuestion.canDoList.map((description, index) => {
                  return (
                    <span key={index}>
                      {index + 1}. {description}
                    </span>
                  );
                })}
              </div>
              <Divider></Divider>
              <div className="h3">{question}</div>
            </div>
            {this.renderOptions(currentQuestion)}
          </div>
        </div>
      );
    } else if (questionType === 2) {
      questionComponent = null;
    } else if (questionType === 3) {
      let specialities = [];
      if (this.doctorDetails) {
        specialities = this.doctorDetails.specialties;
      }
      const options = specialities.map(sp => {
        if (sp.id == 66) {
          return {
            id: sp.id,
            label: sp.specialty,
            isGoToSymptomFlow: true,
            redirectQuestionIds: 901,
            addExtraQuestion: 5,
          };
        } else if (['67', '68', '69', '70', '71'].includes(sp.id)) {
          return {
            id: sp.id,
            label: sp.specialty,
            isGoToSymptomFlow: true,
            redirectQuestionIds: 911,
            addExtraQuestion: 1,
          };
        } else {
          return {
            id: sp.id,
            label: sp.specialty,
          };
        }
      });

      questionComponent = (
        <div className="sf-main-content">
          <div className="sf-inner-content sf-description">
            <div className="heading2">{question}</div>
            {currentQuestion.descriptions.length > 0 && (
              <div className="sf-list-content sf-description-content">
                {currentQuestion.descriptions.map((description, index) => {
                  return (
                    <span key={index} className="sf-description-item">
                      <span>{description}</span>
                    </span>
                  );
                })}
              </div>
            )}
            {this.renderOptions({...currentQuestion, options})}
          </div>
        </div>
      );
    } else if (questionType === 4) {
      questionComponent = (
        <div className="sf-main-content description-without-gray-background">
          <div className="sf-inner-content sf-description">
            <div className="heading2">{question}</div>
            <div className="sf-list-content pms-content">
              {currentQuestion.descriptions.map((description, index) => {
                return (
                  <span key={index} className="description">
                    <span>{description}</span>
                  </span>
                );
              })}
            </div>
            {this.renderOptions(currentQuestion)}
          </div>
        </div>
      );
    } else {
      questionComponent = (
        <div className="sf-main-content">
          <div className="sf-inner-content sf-description">
            <div className="heading2">{question}</div>
            {currentQuestion.descriptions.length > 0 && (
              <div className="sf-list-content sf-description-content">
                {currentQuestion.descriptions.map((description, index) => {
                  return (
                    <span key={index} className="sf-description-item">
                      <span>{description}</span>
                    </span>
                  );
                })}
              </div>
            )}
            {this.renderOptions(currentQuestion)}
          </div>
        </div>
      );
    }
    return questionComponent;
  };

  renderOptions = currentQuestion => {
    const {answers} = this.state;
    const optionType = _.get(currentQuestion, 'optionType', 0);
    const options = _.get(currentQuestion, 'options', []);
    const multiColOption = _.get(currentQuestion, 'multiColOption', false);

    if (optionType === 0) {
      const isAnyLongOption = !!_.find(options, op => {
        return op.label.length > 35;
      });
      return (
        <>
          <div
            className={`sf-list-content sf-select-answer ${isAnyLongOption ? 'long-options' : ''} ${
              multiColOption ? 'multi-col-option' : ''
            }`}>
            {options.map(option => {
              return (
                <span
                  key={option.label}
                  onClick={() => this.storeAnswer(option)}
                  className="sf-description-item">
                  <span>{option.label}</span>
                </span>
              );
            })}
          </div>
          {options.length > 1 && (
            <div className="sf-action">
              <span className="select-option-text">Please select one</span>
            </div>
          )}
        </>
      );
    }
    if (optionType === 1) {
      return (
        <div className="sf-action">
          {options.map((option, index) => {
            return (
              <CircleButton
                key={index}
                onClick={() => this.storeAnswer(option)}
                btnType="secondaryButton">
                {option.label}
              </CircleButton>
            );
          })}
        </div>
      );
    }
    if (optionType === 2) {
      // if It's a doctor flow and option is painList then take painIds from doctorDetail object
      let painOptionIds = [];
      let painOptions = [];
      let questOptions = _.get(currentQuestion, 'questOptions', null);
      if (!questOptions) {
        if (this.doctorDetails) {
          let selectedq = answers.find(a => a.questionId === 601);
          if (selectedq) {
            let selectedSp = selectedq.answer;
            let {specialties} = this.doctorDetails;
            selectedSp = specialties.find(s => s.specialty == selectedSp);
            if (selectedSp) {
              const {visitReasons} = this.context;
              painOptionIds = getPainidsArray(selectedSp.id, visitReasons);
            }
          }
          //
          // const { painIds } = this.doctorDetails;
          // if (painIds) {
          //   painOptionIds = painIds;
          // }
        } else {
          painOptionIds = _.get(currentQuestion, 'painOptionIds', []);
        }
        painOptions = _.filter(painList, painObj => painOptionIds.includes(painObj.id));
        painOptions = painOptions.sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        );
      } else {
        painOptions = questOptions;
      }
      return (
        <div className="sf-list-content">
          <div className="sf-pain-select">
            <AutoSuggestionSelect
              name="paintype"
              options={painOptions}
              labelIndex="label"
              valueIndex="id"
              inputPlaceholder={!questOptions ? 'Search pain condition' : 'Select Approximate'}
              onSelection={this.setPainAnswer}
              inputIcon={images.magnifier}
            />
          </div>
        </div>
      );
    }
    if (optionType === 3) {
      let procedureOptionIds = [];
      procedureOptionIds = _.get(currentQuestion, 'procedureOptionIds', []);
      const procedureOptions = _.filter(procedureList, procedureObj =>
        procedureOptionIds.includes(procedureObj.id),
      );
      return (
        <div className="sf-list-content">
          <div className="sf-pain-select">
            <AutoSuggestionSelect
              name="proceduretype"
              options={procedureOptions}
              labelIndex="procedure"
              valueIndex="id"
              onSelection={this.setProcedureAnswer}
              inputIcon={images.magnifier}
              inputPlaceholder="Search procedure"
            />
          </div>
        </div>
      );
    }
    if (optionType === 4) {
      const {multiSelectProcedureOptionAnswer} = this.state;
      let procedureOptionIds = [];
      procedureOptionIds = _.get(currentQuestion, 'procedureOptionIds', []);
      let procedureOptions = _.filter(procedureList, procedureObj =>
        procedureOptionIds.includes(procedureObj.id),
      );
      let selectedProcedures = _.filter(procedureList, procedureObj =>
        multiSelectProcedureOptionAnswer.includes(procedureObj.id),
      );
      let selectedProceduresArr = selectedProcedures.map(data => data.id);
      selectedProcedures = _.orderBy(selectedProcedures, ['procedure'], ['asc']);
      procedureOptions = _.orderBy(procedureOptions, ['procedure'], ['asc']);
      const isAnyLongOption = !!_.find(procedureOptions, op => {
        return op.procedure.length > 35;
      });
      const addRemoveMultiSelectProcedure = (isActiveItem, option) => {
        if (isActiveItem) {
          const newMultiSelectProcedureOptionAnswer = _.filter(
            multiSelectProcedureOptionAnswer,
            item => item !== option.id,
          );
          this.setState({
            multiSelectProcedureOptionAnswer: newMultiSelectProcedureOptionAnswer,
          });
        } else {
          this.setState({
            multiSelectProcedureOptionAnswer: _.uniq([
              ...multiSelectProcedureOptionAnswer,
              option.id,
            ]),
          });
        }
      };
      return (
        <>
          <div
            className={`sf-list-content sf-select-answer ${isAnyLongOption ? 'long-options' : ''} ${
              multiColOption ? 'multi-col-option' : ''
            }`}>
            {procedureOptions.map(option => {
              const isActiveItem = selectedProceduresArr.includes(option.id);
              return (
                <span
                  key={option.id}
                  onClick={() => addRemoveMultiSelectProcedure(isActiveItem, option)}
                  className={`sf-description-item ` + (isActiveItem ? 'active-item' : '')}>
                  <span>{option.procedure} </span>
                </span>
              );
            })}
          </div>
          <div className={`sf-list-content`}>
            {multiSelectProcedureOptionAnswer.length > 4 && (
              <div className="error-msg">You can add up to four procedures</div>
            )}
            <div className="item-right">
              <CircleButton
                btnType="secondaryButton"
                onClick={this.setMultiProcedureAnswer}
                disabled={
                  multiSelectProcedureOptionAnswer.length === 0 ||
                  multiSelectProcedureOptionAnswer.length > 4
                }>
                {'Continue'}
              </CircleButton>
            </div>
          </div>
        </>
      );
    }
    if (optionType === 5) {
      const {multiSelectPainOptionAnswer} = this.state;
      let painOptionIds = [];
      painOptionIds = _.get(currentQuestion, 'painOptionIds', []);
      let painOptions = _.filter(painList, painObj => painOptionIds.includes(painObj.id));
      let selectedPains = _.filter(painList, painObj =>
        multiSelectPainOptionAnswer.includes(painObj.id),
      );
      painOptions = _.orderBy(painOptions, ['label'], ['asc']);
      let selectedPainArr = selectedPains.map(data => data.id);
      selectedPains = _.orderBy(selectedPains, ['label'], ['asc']);
      painOptions = _.orderBy(painOptions, ['label'], ['asc']);
      const isAnyLongOption = !!_.find(painOptions, op => {
        return op.label.length > 35;
      });
      const addRemoveMultiSelectPain = (isActiveItem, option) => {
        if (isActiveItem) {
          const newMultiSelectPainOptionAnswer = _.filter(
            multiSelectPainOptionAnswer,
            item => item !== option.id,
          );
          this.setState({
            multiSelectPainOptionAnswer: newMultiSelectPainOptionAnswer,
          });
        } else {
          this.setState({
            multiSelectPainOptionAnswer: _.uniq([...multiSelectPainOptionAnswer, option.id]),
          });
        }
      };

      return (
        <>
          <div
            className={`sf-list-content sf-select-answer ${isAnyLongOption ? 'long-options' : ''} ${
              multiColOption ? 'multi-col-option' : ''
            }`}>
            {painOptions.map(option => {
              const isActiveItem = selectedPainArr.includes(option.id);
              return (
                <span
                  key={option.id}
                  onClick={() => addRemoveMultiSelectPain(isActiveItem, option)}
                  className={`sf-description-item ` + (isActiveItem ? 'active-item' : '')}>
                  <span>{option.label} </span>
                </span>
              );
            })}
          </div>
          <div className={`sf-list-content`}>
            {multiSelectPainOptionAnswer.length > 4 && (
              <div className="error-msg">You can add up to four problems</div>
            )}
            <div className="item-right">
              <CircleButton
                btnType="secondaryButton"
                onClick={this.setMultiPainAnswer}
                disabled={
                  multiSelectPainOptionAnswer.length === 0 || multiSelectPainOptionAnswer.length > 4
                }>
                {'Continue'}
              </CircleButton>
            </div>
          </div>
        </>
      );
    }
    if (optionType === 6) {
      const {multiSelectOptionAnswer} = this.state;
      let staticOptionIds = [];
      staticOptionIds = _.get(currentQuestion, 'staticOptionIds', []);
      let staticOptions = _.filter(staticOptionsList, option =>
        staticOptionIds.includes(option.id),
      );
      let selectedOptions = _.filter(staticOptions, obj =>
        multiSelectOptionAnswer.includes(obj.id),
      );
      let selectedOptionsArr = selectedOptions.map(data => data.id);
      const isAnyLongOption = false;
      const addRemoveMultiSelectProcedure = (isActiveItem, option) => {
        if (isActiveItem) {
          const newMultiSelectOptionAnswer = _.filter(
            multiSelectOptionAnswer,
            item => item !== option.id,
          );
          this.setState({
            multiSelectOptionAnswer: newMultiSelectOptionAnswer,
          });
        } else {
          this.setState({
            multiSelectOptionAnswer: _.uniq([...multiSelectOptionAnswer, option.id]),
          });
        }
      };
      return (
        <>
          <div
            className={`sf-list-content sf-select-answer ${isAnyLongOption ? 'long-options' : ''} ${
              multiColOption ? 'multi-col-option' : ''
            }`}>
            {staticOptions.map(option => {
              const isActiveItem = selectedOptionsArr.includes(option.id);
              return (
                <span
                  key={option.id}
                  onClick={() => addRemoveMultiSelectProcedure(isActiveItem, option)}
                  className={`sf-description-item ` + (isActiveItem ? 'active-item' : '')}>
                  <span>{option.label} </span>
                </span>
              );
            })}
          </div>
          <div className={`sf-list-content`}>
            <div className="item-right">
              <CircleButton
                btnType="secondaryButton"
                onClick={this.setMultiOptionAnswer}
                disabled={multiSelectOptionAnswer.length === 0}>
                {'Continue'}
              </CircleButton>
            </div>
          </div>
        </>
      );
    }
    if (optionType === 7) {
      const {multiSelectProcedureOptionAnswer} = this.state;
      let procedureOptionIds = [];
      procedureOptionIds = _.get(currentQuestion, 'procedureOptionIds', []);
      let procedureOptions = _.filter(procedureList, procedureObj =>
        procedureOptionIds.includes(procedureObj.id),
      );
      let selectedProcedures = _.filter(procedureList, procedureObj =>
        multiSelectProcedureOptionAnswer.includes(procedureObj.id),
      );
      let selectedProceduresArr = selectedProcedures.map(data => data.id);
      selectedProcedures = _.orderBy(selectedProcedures, ['procedure'], ['asc']);
      procedureOptions = _.orderBy(procedureOptions, ['procedure'], ['asc']);
      let sml = procedureOptions.findIndex(o => o.id == 0);
      if (sml >= 0) {
        procedureOptions.push(procedureOptions.splice(sml, 1)[0]);
      }
      const isAnyLongOption = !!_.find(procedureOptions, op => {
        return op.procedure.length > 35;
      });
      const addRemoveMultiSelectProcedure = (isActiveItem, option) => {
        if (isActiveItem) {
          const newMultiSelectProcedureOptionAnswer = _.filter(
            multiSelectProcedureOptionAnswer,
            item => item !== option.id,
          );
          this.setState({
            multiSelectProcedureOptionAnswer: newMultiSelectProcedureOptionAnswer,
          });
        } else {
          this.setState({
            multiSelectProcedureOptionAnswer: _.uniq([
              ...multiSelectProcedureOptionAnswer,
              option.id,
            ]),
          });
        }
      };
      return (
        <>
          <div
            className={`sf-list-content sf-select-answer ${isAnyLongOption ? 'long-options' : ''} ${
              multiColOption ? 'multi-col-option' : ''
            }`}>
            {procedureOptions.map(option => {
              const isActiveItem = selectedProceduresArr.includes(option.id);
              return (
                <>
                  <span
                    key={option.id}
                    onClick={() => {
                      addRemoveMultiSelectProcedure(isActiveItem, option);
                      if (!isActiveItem && option.id == 0) {
                        this.showInfo(option.msg);
                      }
                    }}
                    className={`sf-description-item ` + (isActiveItem ? 'active-item' : '')}>
                    <span>{option.procedure} </span>
                  </span>
                </>
              );
            })}
          </div>
          <div className={`sf-list-content`}>
            <div className="item-right">
              <CircleButton
                btnType="secondaryButton"
                onClick={this.setMultiProcedureAnswer}
                disabled={multiSelectProcedureOptionAnswer.length === 0}>
                {'Continue'}
              </CircleButton>
            </div>
          </div>
        </>
      );
    }
  };

  renderSidebarComponent = () => {
    const {match} = this.props;
    const {params} = match;
    const {flowType} = params;
    const {totalQuestions, answers, extraQuestion, finalAnswer} = this.state;
    let docAnsCount = 0;
    if (flowType === 'doctor') {
      docAnsCount = 1;
    }
    const sidebarAnswerListComponent = answers.map((answer, index) => {
      return (
        <span key={index} className="question-title answer">
          {answer.answer}
        </span>
      );
    });
    const backArrowComponent = this.renderBackArrowComponent();
    const currentQuestionCount = answers.length + docAnsCount;
    let questionListTitle = `${currentQuestionCount} of ${
      totalQuestions + extraQuestion
    } Questions`;
    const isAnswerFound = answers.length + docAnsCount - 1 === totalQuestions + extraQuestion;
    if (isAnswerFound || finalAnswer) {
      questionListTitle = 'Complete';
    }
    let doctorComponent = null;
    if (this.doctorDetails) {
      const {name, profileImageUrl, suffixes} = this.doctorDetails;
      doctorComponent = (
        <div className="doctor-info">
          <span className="doctor-img">
            <img src={profileImageUrl || images.user} alt="arrow" />
          </span>
          <span className="doctor-name">
            {name}
            {getSuffixString(suffixes)}
          </span>
        </div>
      );
    }
    const sidebarComponent = (
      <div className="sf-sidebar">
        {backArrowComponent}
        {doctorComponent}
        <span className="question-title">{questionListTitle}</span>
        {sidebarAnswerListComponent}
      </div>
    );
    return sidebarComponent;
  };
  componentWillUnmount() {
    document.title = 'Kaly | Get Matched to a Doctor Near You | Book & Review Online';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Get matched to a doctor near you with Kaly—the exact care you need, from exactly the right doctors. Book an in-person or telemedicine appointment today.',
      );
  }

  render() {
    const {isShowLoadingPage, finalAnswer, currentQuestion, location, selectedSpecialty} =
      this.state;

    if (this.props.match?.params?.flowType === 'specialist' && this.props.match?.params?.type) {
      document.title = `Let Us Match You to the Right ${selectedSpecialty} Near You | Kaly`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          'content',
          `Take our quiz and let us match you with the right ${selectedSpecialty} for you near ${
            location && location.country_code === 'US' ? location.region_name : 'New York'
          }, ${
            location && location.country_code === 'US' ? location.region_code : 'NY'
          }. Get the exact care you need from exactly the right doctors.`,
        );
    }
    if (isShowLoadingPage) return <LoadingPage />;
    if (!allQuestionList) return null;
    return (
      <PatientLayout planeHeader={true} hideFooter={true}>
        <div className="SpecialityFlow">
          {this.renderBackArrowComponent()}

          {currentQuestion && this.renderQuestionComponent()}
          {finalAnswer && this.renderAnswerComponent()}
          {this.renderSidebarComponent()}
        </div>
      </PatientLayout>
    );
  }
}

export default SpecialityFlow;

//Note:

// questionType
// 0 = general
// 1 = last speciality question Type
// 2 = popup
// 3 = speciality select type with dynamic options
// 4 = description without gray background

// optionType
// 0 = general
// 1 = boolean type
// 2 = pain select option
// 3 = procedure select option
// 4 = multi procedure select option
// 5 = multi pain select option
