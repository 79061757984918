import React, { useContext, useState } from 'react';

import './PaymentModal.css';
import PaymentSession from 'components/SignUpSteps/PaymentSession';
import RootContext from 'context/RootContext';
import { toast, ToastContainer } from 'react-toastify';
import modalConfig from 'modals/modalConfig';
import subscriptionService from 'services/subscription';
import _ from 'lodash';
import images from 'data/images';
import LoaderOnly from 'components/Loader/LoaderOnly';
import AuthContext from 'context/AuthContext';

const { updatePaymentWithProduct } = subscriptionService;
const PaymentModal = props => {
  const authContext = useContext(AuthContext);
  const {updateAuthStatus} = authContext;
  const { closeModal, hideTrail = null, updateSubscriptionData, TitleText = null, showcloseBtn = false, hideInputInput = false } = props;
  const { modalFunctions } = modalConfig;
  const { successModalData, blankModalData } = modalFunctions;
  const [showLoader, setShowLoader] = useState(false)
  const rootContext = useContext(RootContext);
  const { setGlobal } = rootContext;
  const scrolltoParent = (className) => {
    let topPos = 0;
    const classElements = document.getElementsByClassName(className);
    if (classElements.length > 0) {
      topPos = classElements[0].offsetTop;
    }
    document.getElementById('PaymentSession-ParentDiv-id').scrollTop = topPos;
  }
  const callonCallBackLoader = async () => {
    setGlobal('modal', { showModal: false });
    await updateSubscriptionData();
  }
  const nextStep = async (data) => {
    // Nux.sendEvent(Analytics.Category.DoctorSettingBilling, Analytics.Actions.Click, 'cardChange');
    // event.preventDefault();
    // const { updateSubscriptionData } = props;
    // const cardElement = elements.getElement(CardElement);
    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement,
    // });
    //  if (error) toast.error(error)
    // else {
    const response = await updatePaymentWithProduct(data).catch((er) => toast.error(er.message));
    if (_.get(response, 'message', '') === 'Success') {

      closeModal();
      setGlobal('modal', successModalData(
        hideInputInput ? `Subscription updated successfully` : `Subscription created successfully`, {
        callBackOnClose: async () => {
          if (updateSubscriptionData) {
            setGlobal(
              'modal',
              blankModalData({
                CustomComponent: LoaderOnly,
                customComponentProps: { delay: 500, callonCallBackLoader: callonCallBackLoader },

              }),

            );



          }

        }
      }));
    }
    // }
  }
  return (
    <div className="PaymentModal-PlanSelection">

      <div className="modal-content-header">
        <div className="heading3">{TitleText ? TitleText : `Subscription required`}</div>
        {showcloseBtn ? (
          <span className="close" onClick={closeModal}>
            <img src={images.close} alt="close" />
          </span>
        ):(
          <span className="close logout" onClick={() =>{ updateAuthStatus(null);closeModal()}}>
                <img alt="logout" src={images.logoutGray} /> <span>Logout</span>
              </span>
        )}
      </div>
      <div className="modal-content-body" id="PaymentSession-ParentDiv-id">
        <div className="PaymentSession-ParentDiv">
          <PaymentSession hideTrail={hideTrail} hideInputInput={hideInputInput} nextStep={nextStep} onScrollView={scrolltoParent} isSubscription={true} />
        </div>

      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default PaymentModal;
