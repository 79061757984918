import React from 'react';
import './SuccessTemplate.css';

const SuccessTemplate = props => {
  return (
    <div className="modal-dialog success">
      <div className="modal-header">
        <div className="title">Success!</div>
      </div>
      <div className="modal-body">{props.description || 'No functionalities working yet.'}</div>
    </div>
  );
};

export default SuccessTemplate;
