import React, {} from 'react';
import modalConfig from 'modals/modalConfig';

import images from 'data/images';
import CircleButton from 'components/CircleButton';

export function DeleteConform(props) {
  const {setGlobal,} = props.context;
  const {modalFunctions} = modalConfig;
  const {blankModalData} = modalFunctions;

  const Warning = props => {
    const {closeModal,text} = props;
 



    return (
      <div className="CreatePasswordModal ConfirmTemplate termsofuse">
        <div style={{minHeight:"auto",paddingTop:"12px"}} className="modal-content-header">
          <div className="h3">Confirm</div>
          <span
            className="close"
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div style={{paddingTop:"20px",paddingBottom:"30px"}} className="modal-content-body">
       <p style={{fontSize:"17px"}}>{text}</p>
        </div>
        <div className="modal-content-footer">
          <div style={{textAlign:"right"}}>
        <CircleButton
          style={{width:"25%"}}
            onClick={() => {
              props.resolve();
              props.closeModal();
            }}>
            No
          </CircleButton>
          <CircleButton
           style={{width:"25%",marginLeft: "10px"}}
            onClick={() => {
              props.resolve(true);
              props.closeModal();
            }}>
            Yes
          </CircleButton>
          </div>
        </div>
      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: Warning,
        customComponentProps: {resolve: resolve, reject: reject,text:props.text},
      }),
    );
  });
}
