import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import images from 'data/images';
import fileUploadService from 'services/fileUpload';
import MyImageCrop from 'components/MyImageCrop';
import './FileUpload.css';

const {fileUpload, removeFileFromServer} = fileUploadService;

const FileUpload = props => {
  const {
    label,
    subLabel = null,
    defaultFileUrl = '',
    uploadServerUrl,
    onError,
    afterUpload,
    onRemove,
    ...otherProps
  } = props;
  // file input
  const [fileObj, setFile] = useState({
    files: null,
    preview: defaultFileUrl,
  });
  const {files, preview} = fileObj;

  const handleFileSelection = event => {
    const {files: filesInstance} = event.target;
    if (filesInstance) {
      setFile(prevState => {
        return {
          ...prevState,
          files: filesInstance,
        };
      });
    }
  };

  const handleFileUpload = async blobFile => {
    const response = await fileUpload(blobFile, uploadServerUrl, preview).catch(onError);
    if (response) {
      const {data} = response;
      const {fileUrl} = data;
      setFile({
        files: null,
        preview: fileUrl,
      });
      afterUpload(fileUrl);
    }
  };

  const removeFile = async () => {
    const response = await removeFileFromServer(uploadServerUrl, preview).catch(onError);
    if (response) {
      onRemove();
      setFile({
        files: null,
        preview: null,
      });
    }
  };
  return (
    <div className="FileUpload">
      <span className="label">{label}</span>
      <div className={`fileupload-wrapper ${preview && 'upload-img'}`}>
        <MyImageCrop files={files} afterCropImage={handleFileUpload} onError={onError} />
        <div className="Fileuploadinput">
          <input
            type="file"
            {...otherProps}
            onChange={handleFileSelection}
            onClick={event => {
              event.target.value = null;
            }}
          />
          <img alt="" src={preview || images.uploadCloud} />
          {preview && (
            <span className="RemoveImage" onClick={removeFile}>
              <img alt="" src={images.close} />
            </span>
          )}
        </div>
        {subLabel && (
          <div className="upload-link">
            <div className="Fileuploadinput">
              <input
                type="file"
                {...otherProps}
                onChange={handleFileSelection}
                onClick={event => {
                  event.target.value = null;
                }}
              />
              <Link to="/">{subLabel.title}</Link>
            </div>
            <div className="upload-txt">{subLabel.description}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
