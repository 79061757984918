import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import config from 'config/config';

const PatientVisitDetails = props => {
  const {
    name,
    appointmentTime,
    patientUserId,
    onViewPatient,
    appointmentId,
    patientInRoom,
    isRejoin,
    startTime,
    endTime,
  } = props;
  const checkAppointmentTime = () => {
    let startDiff = moment().diff(startTime, 'minutes');
    let endDiff = moment().diff(endTime, 'minutes');
    if (startDiff > -5 && endDiff <= 0) {
      return true;
    }
    return false;
  };
  const url = `/video-visit?appointment=${appointmentId}`;
  const showJoinVisit = config.CHECK_VISIT_VALIDATION ? checkAppointmentTime() : true;
  return (
    <div className="patient-list">
      <div className="appname-block">
        <div className="appname-left">
          <span className="patient-name">{name}</span>
          <span className="patient-link" onClick={() => onViewPatient(patientUserId)}>
            View Patient
          </span>
        </div>
        <div className="appoinment-time">{appointmentTime}</div>
      </div>
      <div className={`link-actions ${!showJoinVisit ? 'no-room-txt' : ''}`}>
        {showJoinVisit && moment().isBefore(endTime) && (
          <Link to={url} className="rejoin-videolink">
            {isRejoin ? 'Rejoin Video Visit' : 'Start Video Visit'}
          </Link>
        )}
        <span className="room-txt">{patientInRoom ? 'In Room' : 'Not In Room'}</span>
      </div>
    </div>
  );
};

export default PatientVisitDetails;
