import React, { useEffect, useState } from 'react';
import './TeamCard.css';

const TeamCard = ({card}) => {
  const [teamWidth,setTeamWidth]=useState(0)
  const [teamHeight,setteamHeight]=useState(291)
  useEffect(()=>{
    function handleResize() {
      let w=window.screen.width;
      if (w>1300){
          w=w/4;
          w=w-30
          setTeamWidth(w)
          setteamHeight(w-(w*.25)) 
      }else if(w>=767){
        w=w/3;
        w=w-39
        setTeamWidth(w)
        setteamHeight(w-(w*.25)) 
      }else if(w>=665){
        w=w/2;
        w=w-39
        setTeamWidth(w)
        setteamHeight(w-(w*.25)) 
      }
      else if(w<=665){
        
      //  w=w-39
        setTeamWidth(w)
        setteamHeight(w-(w*.25)) 
      }
      //console.log("window.screen",w);
      
    }
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);

  },[])
  return (
    <div style={{width:teamWidth}} className="TeamCard">
      <div style={{height:teamHeight}} className="team-img">
        <img src={card.image} alt="" />
      </div>
      <div className="name">{card.name}</div>
      <div className="place">{card.place}</div>
      <div className="description">{card.description}</div>
    </div>
  );
};

export default TeamCard;
