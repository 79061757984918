import React, {useState} from 'react';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import allRegex from 'data/regex';
import validators from 'data/validators';
import config from 'config/config';
import './AddStaffToPractice.css';

const defaultFormValues = {firstName: '', lastName: '', email: ''};
const {nameRegex} = allRegex;
const {__required, __email, __maxLength,_scrollToView} = validators;
const {MAX_TEXT_LENGTH} = config;

const AddStaffToPractice = props => {
  const {addStaffRef, emailRef, onInviteToBeStaff} = props;

  const [formData, setFormData] = useState({
    values: {...defaultFormValues},
    errors: {},
  });
  const {values: formValues, errors: formErrors} = formData;

  // Validation functions
  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'First name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'Last name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  const __validateForm = () => {
    const validFirstName = !__validateFirstName();
    const validLastName = !__validateLastName();
    const validEmail = !__validateEmail();
    return validFirstName && validLastName && validEmail;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      default:
        break;
    }
    // setFormData({
    //   ...formData,
    //   values: {
    //     ...formData.values,
    //     [name]: value,
    //   },
    //   errors: {
    //     ...formData.errors,
    //     ...errorObject,
    //   },
    // });
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const resetForm = () => {
    setFormData({
      values: {...defaultFormValues},
      errors: {},
    });
  };

  const handleForm = () => {
    if (!isValidForm) {
      let valError = [
        'firstName',
        'lastName',
        'email',
      
      ];
      valError.forEach(name => {
        changeHandler({target: {name: name, value:formValues[name]}});
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);
      return false;
    }
    onInviteToBeStaff(formValues, resetForm);
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };

  return (
    <div className="AddStaffToPractice" ref={addStaffRef}>
      <div className="h3">
        <span>Add Staff Member to your Practice</span>
        <div className="para">Recipients will receive an email with a temporary password</div>
      </div>
      <form className="add-staff-form">
        <div className="email-row">
          <Input
            ref={emailRef}
            {...getGeneralInputProps('email')}
            placeholder="Enter Email"
            containerClass="email"
          />
          <Input {...getGeneralInputProps('firstName')} placeholder="First Name" />
          <Input {...getGeneralInputProps('lastName')} placeholder="Last Name" />
        </div>
        <div className="action">
          <CircleButton onClick={handleForm}  btnType="whiteButton">
            Invite Staff
          </CircleButton>
        </div>
      </form>
    </div>
  );
};

export default AddStaffToPractice;
