import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import images from 'data/images';
import DoctorAppointmentTime from '../DoctorAppointmentTime';
import Divider from 'components/Divider';
import momentHelper from 'data/moment/momentHelper';
import scheduleService from 'services/schedule';
import helper from 'data/helper';
import './RescheduleAppointmentModal.css';
import moment from 'moment';

const {
  utcToLocalMoment,
  isSameOrAfter,
  dateOrCurrentDate,
  isSameDay,
  dateFormat,
  endOf,
  localToUtcMoment
} = momentHelper;
const { getFilteredAvailabilityOfDoctor } = scheduleService;
const { getAvailability, getLatestAvailabilityDate } = helper;
const showAvailabilityOfDays = 5;

const RescheduleAppointmentModal = props => {
  const { closeModal } = props;
  const [availabilities, setAvailabilities] = useState(null);
  const [isShowAll, setShowAll] = useState(false);
  const { data, timeSelected } = props;
  const title = _.get(data, 'title', 'Reschedule your appointment');
  const appointment = _.get(data, 'appointment');
  const { appointmentStartDateTime } = appointment;

  const apStartDateTimeObj = utcToLocalMoment(appointmentStartDateTime);
  const isVirtual = _.get(appointment, 'providerAvailability.isVirtual', false);
  const providerId = _.get(appointment, 'providerAvailability.provider.id', 0);
  const locationId = _.get(appointment, 'providerAvailability.practiceLocation.id', 7);
  const existingAppointmentTime = _.get(appointment, 'providerAvailability.provider.existingAppointmentTime', 15);
  const newAppointmentTime = _.get(appointment, 'providerAvailability.provider.newAppointmentTime', 15);
  const isExistingPatient = _.get(appointment, 'isExistingPatient', false);
  let newAppointmentTimeorExAT = _.get(appointment, 'providerAvailability.provider.newAppointmentTimeorExAT', false);
  console.log("appointment", appointment);
  let slotDurationInMinutes = _.get(
    appointment,
    `providerAvailability.provider.${isExistingPatient ? 'existingAppointmentTime' : 'newAppointmentTime'
    }`,
    15,
  );
  if (!newAppointmentTimeorExAT) {
    newAppointmentTimeorExAT = existingAppointmentTime > newAppointmentTime ? existingAppointmentTime : newAppointmentTime
  }
  slotDurationInMinutes = newAppointmentTimeorExAT ? newAppointmentTimeorExAT : slotDurationInMinutes

  let dateArray = [];

  const getAndSetDoctorAvailabilities = async (type = 'next') => {

    const utcStartDateString = dateFormat(apStartDateTimeObj.clone().utc());

    const startDate = _.get(dateArray, '0', utcStartDateString);
    const endDate = dateArray[dateArray.length - 1];
    let nextStartDateObj = null;
    let nextEndDateObj = null;
    switch (type) {
      case 'initial':
        nextStartDateObj = apStartDateTimeObj.clone();
        nextEndDateObj = nextStartDateObj.clone().add(showAvailabilityOfDays - 1, 'days');
        break;
      case 'next':
        nextStartDateObj = dateOrCurrentDate(startDate).add(showAvailabilityOfDays, 'days');
        nextEndDateObj = dateOrCurrentDate(endDate);
        nextEndDateObj.add(showAvailabilityOfDays, 'days');
        break;
      case 'prev':
        nextStartDateObj = dateOrCurrentDate(startDate).subtract(showAvailabilityOfDays, 'days');
        nextEndDateObj = dateOrCurrentDate(startDate);
        nextEndDateObj.subtract(1, 'days');
        break;
      default:
        break;
    }
    if (nextEndDateObj) {
      const datesObj = {};
      // if previous day is current date then add additional day to remove business hours from it
      let extraDay = 0;
      if (isSameDay(nextStartDateObj.clone().subtract(1, 'day'))) {
        extraDay = 1;
        datesObj[dateFormat()] = [];
        nextStartDateObj.subtract(1, 'day');
      }
      const startDateString = dateFormat(nextStartDateObj.clone().subtract(1, 'day').utc());
      const endDateString = dateFormat(endOf('day', nextEndDateObj.clone()).utc());
      // add extra day if removed 1 day previously
      nextStartDateObj.add(extraDay, 'day');
      datesObj[dateFormat(nextStartDateObj)] = [];
      for (let i = 1; i < showAvailabilityOfDays; i++) {
        nextStartDateObj.add(1, 'day');
        datesObj[dateFormat(nextStartDateObj)] = [];
      }
      const response = await getFilteredAvailabilityOfDoctor(
        providerId,
        startDateString,
        endDateString,
        locationId,
        isVirtual,
      ).catch(() => null);
      if (response) {
        const { data } = response;
        const providerAvailabilities = _.get(data, '0.providerAvailabilities', []);
        const patientAppointments = _.get(data, '0.patientAppointments', []);
        const timezone = _.get(data, '0.timezone', null);
        const api_isVirtual = _.get(data, '0.isVirtual', null);
        const api_latestAvailability = _.get(data, '0.latestAvailability', null);
        let nextOpenDate = getLatestAvailabilityDate(api_isVirtual, api_latestAvailability, providerAvailabilities,
          slotDurationInMinutes,
          patientAppointments,
          timezone);
        const updatedAvailabilities = getAvailability(
          providerAvailabilities,
          datesObj,
          slotDurationInMinutes,
          patientAppointments,
          timezone,
          nextOpenDate
        );
        // if extra day then delete availability of extra day
        if (extraDay) {
          delete updatedAvailabilities[dateFormat()];
        }
        setAvailabilities(updatedAvailabilities);
      }
    }
  };

  useEffect(() => {
    getAndSetDoctorAvailabilities('initial');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!availabilities) {
    return null;
  }
  dateArray = _.keys(availabilities);
  let isDisablePrevButton = true;
  const firstStartDate = _.get(dateArray, '0', null);
  if (firstStartDate) {
    isDisablePrevButton = isSameOrAfter(dateOrCurrentDate(), dateOrCurrentDate(firstStartDate));
  }

  const timeRenderComponents = [];
  let max = 3;
  let availabilityMax = max;
  _.map(availabilities, (val, dateIndex) => {
    const arrayLength = val.length;
    if (arrayLength > availabilityMax) {
      availabilityMax = arrayLength;
    }
  });
  if (isShowAll) {
    max = availabilityMax;
  }
  for (let i = 0; i < max; i++) {
    timeRenderComponents.push(
      <div className="tr" key={i}>
        {dateArray.map((dateString, j) => {
          const timeObject = availabilities[dateString][i];
          if (timeObject) {
            return (
              <span
                onClick={() => {
                  if (!timeObject.isBooked) {
                    //console.log("dateString",dateString);
                    //console.log("timeObject",timeObject);
                    //console.log("localToUtcMoment",localToUtcMoment(`${dateString} ${timeObject.time}`).format('dd mm yyyy hh:mm a'));
                    timeSelected(dateString, timeObject);
                    closeModal();
                  }
                }}
                className={`${timeObject.isBooked ? 'booked' : 'available'} td`}
                key={`${i}-${j}`}>
                <div className="time">{timeObject.time}</div>
              </span>
            );
          } else {
            return (
              <span className="td" key={`${i}-${j}`}>
                <div className="time">-</div>
              </span>
            );
          }
        })}
      </div>,
    );
  }
  return (
    <div className="RescheduleAppointmentModal">
      <div className="modal-content-header">
        <div className="h3">{title}</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <DoctorAppointmentTime appointment={appointment} />
        <Divider />
        <div className={["dc-appointment-time", locationId === "0" ? 'appointment-video' : ''].join(' ')}>
          <div className="appointment-inner-block">
            <div className="table-wrapper">
              <span
                onClick={() => !isDisablePrevButton && getAndSetDoctorAvailabilities('prev')}
                className={`prev-link ${isDisablePrevButton ? 'disabled' : ''}`}>
                <img src={images.arrowLeft} alt="left-arrow" />
              </span>
              <span onClick={() => getAndSetDoctorAvailabilities('next')} className="right-link">
                <img src={images.arrowLeft} alt="right-arrow" />
              </span>
              <div className="table timing-table">
                <div className="tr">
                  {dateArray.map(dateString => {
                    const dateObj = dateOrCurrentDate(dateString);
                    return (
                      <span key={dateString} className="th">
                        {dateObj.format('ddd')}{' '}
                        <span className="date">{dateObj.format('MMM DD')}</span>
                      </span>
                    );
                  })}
                </div>
                <div className="time-container">{timeRenderComponents}</div>
                {!isShowAll && availabilityMax > max && (
                  <div onClick={() => setShowAll(true)} className="more-time">
                    <span>View more times</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RescheduleAppointmentModal;
