import React, {useState, useContext, useRef} from 'react';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import validators from 'data/validators';
import images from 'data/images';
import './AddFile.css';
import manageFileUpload from "services/manageFileUpload"
import modalConfig from 'modals/modalConfig';
import _ from 'lodash';
import Select from 'components/FormControls/Select';
import {toast, ToastContainer} from 'react-toastify';
import Input from 'components/FormControls/Input/Input';
const {__required} = validators;
const {postMyFiles} = manageFileUpload;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;
const specialtyList = [
  {id: 1, label: 'Photo ID'},
  {id: 2, label: 'Graduate School Diploma'},
  {id: 3, label: 'Undergraduate School Diploma'},
  {id: 4, label: 'Active State Professional license.'},
  {id: 5, label: 'Post-Graduate Diploma (Residency, Fellowship, etc)'},
  {id: 6, label: 'Hospital Affiliation'},
  {id: 7, label: 'Board Certificate'},
  {id: 8, label: 'Other'},
];
const AddFile = props => {
  const {data, updateSubmit} = props;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const subIndex = _.get(data, 'subIndex', null);
  const editorRef = useRef(null);
  const [photoidPreview, setPhotoidPreview] = useState('');
  const [imageName, setImageName] = useState('File');
  const [formData, setFormData] = useState({
    values: {
      categoryId: _.get(data, 'categoryId', ''),
      document: _.get(data, 'document', ''),
    },
    errors: {serverError: null},
  });

  const {values: formValues, errors: formErrors} = formData;

  const validateFile = fileObj => {
    const docTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/PNG',
      'image/JPEG',
      'image/JPG',
      'application/pdf',
    ];
    const allowedTypes = [...docTypes];
    return allowedTypes.includes(fileObj.type);
  };
  const __validateDocument = () => {
    if (formValues['document'] === '' || !formValues['document']) return 'File is required';
    return null;
  };
  const handleFileSelection = async event => {
    const {files} = event.target;
    if (files.length > 0) {
      const newFile = event.target.files[0];
      if (!validateFile(newFile)) {
        handleError({message: 'Only jpg, jpeg, png and pdf file is allowed'});
        return;
      }
      if (!newFile.name.endsWith('.pdf')) setPhotoidPreview(URL.createObjectURL(newFile));
      else {
        setPhotoidPreview('');
      }
      
      setImageName(newFile.name);
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          ['document']: newFile,
        },
        errors: {
          ...formData.errors,
          // ...errorObject,
        },
      });
    }
    // return null;
  };
  const __validateCategory = (value = null) => {
    const fValue = value !== null ? value : formValues['categoryId'];
    if (!__required(fValue)) {
      return 'Category is required';
    }
    return null;
  };
  const __validateotherCategoryName = (value = null) => {
    const fValue = value !== null ? value : formValues['otherCategoryName'];
    if (!__required(fValue)) {
      return 'Your File category is required';
    }
    return null;
  };
  const changeHandler = event => {
    let {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'categoryId':
        errorObject = {
          [name]: __validateCategory(value),
        };
        break;

      case 'otherCategoryName':
        errorObject = {
          [name]: __validateotherCategoryName(value),
        };
        break;
      default:
        break;
    }

    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const closeModal = () => {
    setGlobal('modal', {showModal: false});
  };

  const __validateForm = () => {
    const validateCategory = !__validateCategory();
    const validateDocument = !__validateDocument();
    //const validateAnswer=!__validateAnswer();
    const validSpecialtyNew =
      validateCategory && formValues['categoryId'] === 8 ? !__validateotherCategoryName() : true;
    return validateCategory && validateDocument && validSpecialtyNew;
  };

  const isValidateForm = __validateForm();

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="Close" title="Close" />
    </span>
  );

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">
        {subIndex !== null ? `Update File : ${_.get(data, 'plan', '')}` : 'Add New File'}
      </div>
      {crossIconComponent}
    </div>
  );

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const {updateList} = props;
    let response = null;
    var formData = new FormData();
    formData.append('document', formValues['document']);
    formData.append('categoryId', formValues['categoryId']);
    if(formValues['categoryId']==='8' || formValues['categoryId']===8)
    formData.append('otherCategoryName', formValues['otherCategoryName']);
    // let reqObj = {
    //   document: formValues['speciality'],
    //   faqQuestion: formValues['question'],
    //   faqAnswer: formValues['answer'],
    // };
    // console.log("reqObj",reqObj);
    // return
    if (subIndex !== null) {
    //  updateSubmit(subIndex, reqObj);
      return;
      // response = await updateFaq(subIndex, reqObj).catch(handleError);
    } else {
      response = await postMyFiles(formData).catch(handleError);
    }
    if (response) {
      const {message} = response;
      setGlobal('modal', successModalData(message));
      updateList();
    }
  };

  return (
    <div className="addeditfile no-select">
      <div className="modal-body-wrapper">
        {dialogHeader}
        <div className="dailog-body">
          <form onSubmit={handleSubmit}>
            <div>
              <Select
                {...getGeneralInputProps('categoryId')}
                options={specialtyList}
                labelIndex="label"
                valueIndex="id"
                label="Category"
              />
              {formValues['categoryId'] === '8' && (
               
                <div className="Input-control  form-group">
                  <span className="label">Other Category Name</span>
                  <input
                    className="form-control"
                    onChange={changeHandler}
                    onBlur={changeHandler}
                    type="text"
                    autoComplete="off"
                    name="otherCategoryName"
                    placeholder="Other Category Name"
                    value={formValues['otherCategoryName']}
                  />
                  {formErrors['otherCategoryName'] !== '' && (
                    <span class="error-text">{formErrors['otherCategoryName']}</span>
                  )}
                </div>
              )}
              <div className="Input-control  form-group">
                <span className="label">File</span>
                <div className="fileupload">
                  <div
                    className={['preview-img', !photoidPreview ? '' : 'preview-img-multy'].join(
                      ' ',
                    )}>
                    {photoidPreview && <img src={photoidPreview} />}
                    <span>{imageName}</span>
                  </div>
                  <span className="fileupload-btn">
                    <input
                      type="file"
                      onChange={handleFileSelection}
                      onClick={event => {
                        event.target.value = null;
                      }}
                    />

                    <button>Choose file</button>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="action-btn">
          <CircleButton onClick={closeModal} btnType="secondaryButton">
            Cancel
          </CircleButton>
          <CircleButton btnType="whiteButton" disabled={!isValidateForm} onClick={handleSubmit}>
            {subIndex !== null ? 'Update' : 'Add'}
          </CircleButton>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};

export default AddFile;
