import React from 'react';
import Header from 'layout/AdminLayout/Header';
import Sidebar from 'layout/AdminLayout/Sidebar';
import './Layout.css';

const Layout = props => {
  const {children} = props;
  return (
    <div className="AdminLayout">
      <Header />
      <div className="wrap-content-row">
        <Sidebar />
        {children}
      </div>
    </div>
  );
};

export default Layout;
