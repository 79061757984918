import React, { useState, useEffect, useCallback, useContext, Fragment } from 'react';
import _ from 'lodash';
import Input from '../Input';
import RootContext from 'context/RootContext';
import masterService from 'services/master';
import selectOptions from 'data/selectOptions';
import images from 'data/images';
import './InsuranceFilterSelect.css';
var debounce = require('lodash/debounce');
const { getAllInsurancePlan, getAllInsurancePlanPaginate, getAllInsuranceCompanysPaginate,
  getAllInsurancePlansPaginate } = masterService;
const { insuranceDefaultOption } = selectOptions;

const InsuranceFilterSelectPlan = props => {
  const {
    containerClass = '',
    inputPlaceholder = '',
    inputIcon = null,
    insurance,
    name = '',
    onChange = () => { },
  } = props;
  const limit = 10
  //const rootContext = useContext(RootContext);
  // const {insurancePlans, setGlobal} = rootContext;
  const [insuranceList, setInsuranceList] = useState([])
  const [insurancePlanList, setInsurancePlanList] = useState([])
  const [topCompanys, setTopCompanys] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchPlanText, setSearchPlanText] = useState('');
  const [isFocused, setFocus] = useState(false);
  const [insuranceLoading, setInsuranceLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showCopmany, setShowCopmany] = useState(true);

  useEffect(() => {


    apiCall(1)
  }, []);
  async function apiCall(pageNo = 1, text = '') {
    if (!insuranceLoading) {
      
      setInsuranceLoading(true);
      setCurrentPage(pageNo)
      let textVal = text
      const insuranceCompanisResponse = await getAllInsuranceCompanysPaginate(true, { "search": textVal, "page": pageNo, "limit": limit, "sortBy": "companyName", "orderBy": "asc", "status": null }).catch(() => { setInsuranceLoading(false); });
      if (insuranceCompanisResponse) {
        const { data } = insuranceCompanisResponse;
      
        let { totalPage, list, topCompanyList } = data
        setTopCompanys(topCompanyList)
        let ISTop =pageNo === 1?[]: [...insuranceList]
        if (textVal === '' && pageNo === 1) {
          ISTop.push({ id: '00011', isLabel: true, Name: 'Companys' })

        }
        let passData = textVal === '' ? list.filter((i) => i.isTop === false) : list
        passData.map((item) => {


          // if (!ISTop.find((company) => company.companyId === item.companyId && company.isLabel == true)) {
          //   ISTop.push({
          //     Name: item.companyName,
          //     isLabel: true,
          //     companyId: item.companyId
          //   });
          // }
          // if (!ISTop.find((company) => company.id === item.id )) {
          ISTop.push({
            id: item.id,
            Name: item.companyName,
            // isPlan: true,
            // companyName: item.companyName,
            // companyId: item.companyId
          });

          // }
          return item
        })

        setInsuranceList(ISTop)
        //let TOTALPAGES = Math.ceil(totalPlanCount / limit);
        setTotalPages(totalPage)
      }
      setInsuranceLoading(false);
    }
  }
  async function apiCallPlan(pageNo = 1, text = '', insuranceCompanyId = null) {
  
    if (!insuranceLoading) {
     
      setInsuranceLoading(true);
      setCurrentPage(pageNo)
      let textVal = text
      const insurancePlansResponse = await getAllInsurancePlansPaginate(true, { "search": textVal, "page": pageNo, "limit": 10, "sortBy": "plan", "orderBy": "asc", "status": null, "insuranceCompanyId": insuranceCompanyId }).catch(() => { setInsuranceLoading(false); });
     
      if (insurancePlansResponse) {
        const { data } = insurancePlansResponse;
       
        let { totalPage, list } = data

        let ISTop = pageNo === 1 ? [] : [...insurancePlanList]

        list.map((item) => {



          ISTop.push({
            id: item.id,
            Name: item.plan,
            isPlan: true,
            companyName: item.companyName,
            companyId: item.companyId
          });


          return item
        })

        setInsurancePlanList(ISTop)
        //let TOTALPAGES = Math.ceil(totalPlanCount / limit);
        setTotalPages(totalPage)
        
        setInsuranceLoading(false)
      }
      
      setInsuranceLoading(false);
    }
  }
  useEffect(() => {
    
    if (insuranceList && insurance) {
      if (insurance.companyId) {
        let selectedCompany = insuranceList.find((item) => item.id === insurance.companyId);
        // instrancePlanList.forEach(company => {
        //   if (company.id === insurance.companyId) {
        //     selectedCompany = {...company};
        //   }
        // });

        if (!selectedCompany) {
          selectedCompany = topCompanys.find((item) => item.id == insurance.companyId)
          if (selectedCompany) {

            selectedCompany.Name = selectedCompany.companyName ? selectedCompany.companyName : selectedCompany.Name
          }
        }

        if (selectedCompany) {
          setSearchText(`${selectedCompany.Name}`);
        }
      } else if (insurance.planId) {

        let plan = insurancePlanList.find((plan) => plan.id === insurance.planId)
        // instrancePlanList.forEach(company => {
        //   company.insurancePlans.forEach(p => {
        //     if (parseInt(p.id) === parseInt(insurance.planId)) {
        //       plan = {...p};
        //     }
        //   });
        //   if (plan && !plan.companyName) {
        //     plan.companyName = company.companyName;
        //   }
        // });

        if (plan) {
          setSearchText(`${plan.companyName}-${plan.Name}`);
        }
      } else {
        setSearchText(insuranceDefaultOption.label);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurance, insuranceList, insurancePlanList, topCompanys]);

  //const instrancePlanList = insurancePlans || [];

  // let companies = [];
  // _.cloneDeep(instrancePlanList).forEach(insurance => {
  //   const {id, companyName, isTop = true} = insurance;
  //   if (
  //     isTop &&
  //     isFocused &&
  //     (companyName.toLowerCase().includes(searchText.toLowerCase()) || searchText === '')
  //   ) {
  //     companies.push({
  //       id,
  //       companyName,
  //     });
  //   }
  // });

  // let filteredInstrancePlanList = _.filter(_.cloneDeep(instrancePlanList), company => {
  //   if (!isFocused) {
  //     return false;
  //   }
  //   const isShowInsurance = company.companyName.toLowerCase().includes(searchText.toLowerCase());
  //   if (isShowInsurance) {
  //     return true;
  //   }
  //   company.insurancePlans = _.filter(company.insurancePlans, insurancePlan => {
  //     const isShowInsurancePlan = insurancePlan.plan
  //       .toLowerCase()
  //       .includes(searchText.toLowerCase());
  //     return isShowInsurancePlan;
  //   });
  //   if (company.insurancePlans.length > 0) {
  //     return true;
  //   }
  // });

  const handleChange = event => {
    const { value: textValue } = event.target;
    setSearchText(textValue);
    setShowCopmany(true)
    setSelectedCompany(null)
    searchInsurance(textValue)
    if (insurance) {
      onChange({ target: { name, value: null } });
    }
  };
  const handlePlanChange = event => {
    const { value: textValue } = event.target;
    setSearchPlanText(textValue);
    searchInsurancePlan(textValue)
    // if (insurance) {
    //   onChange({ target: { name, value: null } });
    // }
  };

  const onBlur = useCallback(() => {
    setFocus(false);
  }, []);

  // if (insuranceList.length===0) {
  //   return null;
  // }

  const handleCompanySelection = company => {
    let { companyName, Name } = company
    let selectedCompany = { ...company }
    selectedCompany.Name = Name ? Name : companyName
    setSelectedCompany(selectedCompany)
    setShowCopmany(false)
    setInsurancePlanList([])
    apiCallPlan(1, '', company.id)
    // setFocus(false);
    // onChange({ target: { name, value: { companyId: company.id } } });
  };
  const clickShowCopmany = () => {
    //setShowCopmany(true)
  
    setFocus(false);
    onChange({ target: { name, value: { companyId: selectedCompany.id } } })
  }
  const handlePlanSelection = plan => {

    setFocus(false);
    //setShowCopmany(true)
    onChange({ target: { name, value: { planId: plan.id } } });
  };
  const searchInsurance = useCallback(
    debounce((value) => {

      if (value.length > 0 && value.length >= 2)
        apiCall(1, value)
      else {
        apiCall(1)
      }
      setInsuranceList([])
    }, 500),
    [],
  );
  const clearcompanySearch=()=>{
    setInsuranceList([])
    apiCall(1)
    
  }
  const searchInsurancePlan = useCallback(
    debounce((value) => {
     
      if (value.length > 0 && value.length >= 2)
        apiCallPlan(1, value, selectedCompany.id)
      else {
        
        apiCallPlan(1, '', selectedCompany.id)
      }
      setInsurancePlanList([])
    }, 500),
    [selectedCompany,insuranceLoading],
  );
  const handleScroll = (e) => {

    const bottom = (e.target.scrollHeight - e.target.scrollTop - 10) <= e.target.clientHeight;

    if (bottom) {
      if (totalPages > 0) {
        if (currentPage + 1 <= totalPages) {
          setTimeout(() => {
            if (showCopmany) {
              apiCall(currentPage + 1, searchText)
            } else {

              apiCallPlan(currentPage + 1, searchPlanText, selectedCompany.id)


            }
          }, 500);

        }

      }
    }
  }

  return (
    <div onScroll={handleScroll} className={`InsuranceFilterSelect ${isFocused ? 'active' : ''} ${containerClass}`}>
      <Input
        type="text"
        placeholder={inputPlaceholder}
        value={searchText}
        onFocus={() => {
          setFocus(true);
        }}
        onChange={handleChange}
        icon={inputIcon}
        title={searchText}
      />
      {isFocused && <div className="overlay" onClick={onBlur}></div>}
      {isFocused && showCopmany && (
        <div className="result">
          {insuranceList.length > 0 ? (
            <div className="result1">
              {searchText == '' && (
                <div
                  title={insuranceDefaultOption.label}
                  className="option plan"
                  onClick={() => handlePlanSelection({ id: null })}>
                  {insuranceDefaultOption.label}
                </div>
              )}
              {topCompanys.length > 0 && searchText === '' && (
                <>
                  <div className="option company">Top Carriers</div>
                  {topCompanys.map(company => {
                    return (
                      <div
                        title={company.companyName}
                        className="option plan"
                        onClick={() => handleCompanySelection(company)}
                        key={`${company.id}`}>
                        {company.companyName}
                      </div>
                    );
                  })}
                </>
              )}
              {insuranceList.map(company => {
                return (
                  <Fragment key={company.id}>
                    {company.isLabel ? (
                      <div className="option company">{company.Name}</div>
                    ) : (


                      <div
                        className="option plan"
                        title={company.Name}
                        onClick={() => handleCompanySelection(company)}
                        key={`${company.companyId}-${company.id}`}>
                        {company.Name}
                      </div>
                    )}

                  </Fragment>
                );
              })}

            </div>
          ) : (
            <Fragment>
              {!insuranceLoading && insuranceList.length === 0 && (
                <div className="result1">
                  <div
                    className="option plan"
                  >
                    No match found
                  </div>
                </div>
              )}
            </Fragment>
          )}
          {isFocused && insuranceList.length > 0 && insuranceLoading && (
            <div className="transparent-loader">
              <div className="loader">
                <span>
                  <img src={images.logosymbol} alt="logo symbol" />
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {isFocused && !showCopmany && (
        <div className="result">
          <div className="result1">
            <p style={{
              fontSize: "22px",
              fontWeight: "bold",
              margin: 0,
              color: '#023648'
            }}>Select Plan</p>
            <div className='selectedCompany-text'><span style={{ fontWeight: "bold", fontSize: '18px' }}>Carrier :</span><span style={{ wordBreak: "break-word" }}> {selectedCompany.Name}</span></div>
            <div className='backdivclass' onClick={() => { setShowCopmany(true);setSearchText(''); setSearchPlanText('');onChange({ target: { name, value: null } });clearcompanySearch(); }}>
              <span>Choose a different carrier</span></div>

            <Input
              type="text"
              placeholder={`Search Plan`}
              value={searchPlanText}
              onFocus={() => {
                setFocus(true);
              }}

              onChange={handlePlanChange}
              containerClass="plansearch"
              // icon={inputIcon}
              title={searchPlanText}
            />
          </div>
          {insurancePlanList.length > 0 && (
            <div onClick={() => clickShowCopmany()} className="option company">I don't know my plan</div>
          )}
          {insurancePlanList.map(company => {
            return (
              <Fragment key={company.id}>
                {company.isLabel ? (
                  <div className="option company">{company.Name}</div>
                ) : (


                  <div
                    className="option plan"
                    title={company.Name}
                    onClick={() => handlePlanSelection(company)}
                    key={`${company.companyId}-${company.id}`}>
                    {company.Name}
                  </div>
                )}

              </Fragment>
            );
          })}
          {insuranceLoading&&!showCopmany &&(

          
          <div className="transparent-loader">
              <div className="loader">
                <span>
                  <img src={images.logosymbol} alt="logo symbol" />
                </span>
              </div>
            </div>
            )}
          <Fragment>
            {!insuranceLoading && !showCopmany && insurancePlanList.length === 0 && (
              <div className="result1">
                <div
                  className="option plan"
                >
                  No match found
                </div>
              </div>
            )}
          </Fragment>
        </div>
      )}
      {/* {isFocused && !showCopmany && (
         insurancePlanList
       } */}
      {isFocused && insuranceLoading && insuranceList.length === 0 && (
        <div className="result">
          <div className="loader">
            <span>
              <img src={images.logosymbol} alt="logo symbol" />
            </span>
          </div>
        </div>
      )}

    </div>
  );
};

export default InsuranceFilterSelectPlan;
