import React, { useState, useContext } from 'react';
import images from 'data/images';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import subscriptionService from 'services/subscription';
import RootContext from 'context/RootContext';
import _ from 'lodash';
import modalConfig from 'modals/modalConfig';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import { toast, ToastContainer } from 'react-toastify';

const { updatePayment } = subscriptionService;
const { Analytics } = analyticsData;

const StripeInput = props => {
  const { closeModal, showcloseBtn = true } = props;
  const { cardType = 'cardType' } = props;
  const [isValid, setIsValid] = useState(false);
  const rootContext = useContext(RootContext);
  const { setGlobal } = rootContext;
  const stripe = useStripe();
  const elements = useElements();
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        backgroundColor: '#fff',
        fontSize: '16px',
        lineHeight: '1.6',
        letterSpacing: '-0.34px',
        color: '#013648',
      },
    },
  };

  const { modalFunctions } = modalConfig;
  const { successModalData } = modalFunctions;

  const handleSubmit = async event => {
    Nux.sendEvent(Analytics.Category.DoctorSettingBilling, Analytics.Actions.Click, 'cardChange');
    event.preventDefault();
    const { updateSubscriptionData } = props;
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (error) toast.error(error)
    else {
      const response = await updatePayment(paymentMethod.id).catch((er) => toast.error(er.message));
      if (_.get(response, 'message', '') === 'Success') {

        closeModal();
        setGlobal('modal', successModalData(
          `Your card details has been successfully ${cardType != 'cardType'?'changed':'added'} `, {
            callBackOnClose: async() => {
              updateSubscriptionData && (await updateSubscriptionData());
            }
        }));
      }
    }
  };

  const handleChange = async data => {
    if (data.complete) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
 
  
  return (
    <>
      <div className="modal-content-body">
        <img src={images.stripe} alt="stripe" />

        <div className="card-description">
          {props.nextBillingDateSort ? (
            <div className="description">
              {cardType && (
                <span>
                  <span style={{ textTransform: 'capitalize' }}>{cardType} </span>****
                  {props.cardLastDigit}, {props.cardExpiry}
                </span>
              )}

            </div>
          ) : (
            <div className="description">
              <span>Enter your card details</span>
            </div>
          )}
          <div className="description">
            Next payment will be automatically collected from this card after{!showcloseBtn?' 90 days':''}.
          </div>
          {props.nextBillingDateSort && (
            <div className="description">
              Next billing is due on {props.nextBillingDateSort} for ${props.nextBillAmount}.
            </div>
          )}
        </div>

        <div className="stripe-input">
          <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
        </div>
      </div>
      <div className="modal-content-footer">
        <button className="save" disabled={!isValid} onClick={handleSubmit}>
          Save Changes
        </button>
        {showcloseBtn && (
          <button className="cancel" onClick={closeModal}>
            Cancel
          </button>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default StripeInput;
