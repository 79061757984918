import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import images from 'data/images';

import InfiniteScroll from 'components/InfiniteScroll';

import './InvitePractice.css';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import InputMask from 'react-input-mask';
import allRegex from 'data/regex';
import config from 'config/config';
import validators from 'data/validators';
import helper from 'data/helper';
import Select from 'components/FormControls/Select';
import Textarea from 'components/FormControls/Textarea';
import masterService from 'services/master';
import practice from 'services/practice';
import { toast, ToastContainer } from 'react-toastify';
const { referredPractice } = practice;

const InvitePractice = props => {
  const { closeModal } = props;
  const { data } = props;
  const { specialties } = data;
  const { modalFunctions } = modalConfig;
  const { exceptDigitsRegex, phonenumberRegex } = allRegex;
  const { MAX_TEXT_LENGTH } = config;
  const { __required, __email, __maxLength, __phoneNumber, _scrollToView } = validators;
  const { formatPhoneNumberWithDash } = helper;
  const { successModalData, errorModalData } = modalFunctions;
  const rootContext = useContext(RootContext);
  const errorObj = {
    firstName: "First Name is required",
    lastName: "Last Name is required",
    email: "Email is required",
    phoneNumber: "Phone Number is required",
    specialtyId: "Specialty is required",
    zipCode: "Zip code is required",
    practiceName: "Practice Name is required"
  }
  const referredPracticesObj = {

    values: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      specialtyId: '',
      zipCode: "",
      practiceName: "",
      personalMessage: "I'd like to invite you to join Kaly.com."
    },
    errors: {},
    isValid: false

  }
  const [referredPractices, setReferredPractices] = useState([{ ...referredPracticesObj }]);
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const { setGlobal } = rootContext;
  const { getAllSpecialties } = masterService;
  useEffect(() => {
    if (!specialties)
      getSpecialities()
    else
      setSpecialtiesList(specialties)
  }, [])
  const getSpecialities = async () => {

    const response = await getAllSpecialties().catch(handleError);
    if (response) {
      const { data } = response;
      setGlobal('specialties', data);
      setSpecialtiesList(data)
    }

  };
  const handleError = error => {
    const { message } = error;
    // setGlobal('modal', errorModalData(message));
    toast.error(message)
    return null;
  };
  const inviteFn = async () => {
   
    let errorval = referredPractices.find((ii) => ii.isValid == false)
    if (errorval) {
      let letErrorIndex = referredPractices.findIndex((ii) => ii.isValid == false)
      if (letErrorIndex >= 0) {
        let errors = referredPractices[letErrorIndex].errors
        let tempRf = [...referredPractices]
        if (Object.keys(errors).length === 0) {

          tempRf[letErrorIndex].errors = { ...errorObj }
          setReferredPractices(tempRf)
          setTimeout(() => {
            _scrollToView(`error-text`);
          }, 100);
          //toast.error('Please fill the required fields')

        } else {
          Object.keys(errorObj).forEach((key) => {
            if (!(key in tempRf[letErrorIndex].errors)) {
              tempRf[letErrorIndex].errors[key] = errorObj[key]
            }
          })
          setReferredPractices(tempRf)
          setTimeout(() => {
            _scrollToView(`error-text`);
          }, 100);
        }
      }

      return;
    }

    let processedData = referredPractices.map((item) => {
      return { ...item.values }
    })
    referredPractice({ referredPractices: [...processedData] }).then((res) => {
      const { message } = res;
      setGlobal('modal', successModalData(message));
    }).catch(handleError)

  }

  const InviteInputs = (props) => {
    const { index, keyelement } = props


    let formData = referredPractices[index]
    const { values: formValues, errors: formErrors } = formData;

    const checkSameNumber = (num) => {

      if ((num[0] === num[1]) && (num[0] === num[2]))
        return true
      return false
    }
    const __validateFirstName = (value = null) => {
      const fValue = value !== null ? value : formValues['firstName'];
      if (!__required(fValue)) {
        return 'First Name is required';
      }
      if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
        return `Max ${MAX_TEXT_LENGTH} characters`;
      }
      return null;
    };
    const __validateLastName = (value = null) => {
      const fValue = value !== null ? value : formValues['lastName'];
      if (!__required(fValue)) {
        return 'Last Name is required';
      }
      if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
        return `Max ${MAX_TEXT_LENGTH} characters`;
      }
      return null;
    };
    const __validatepracticeName = (value = null) => {
      const fValue = value !== null ? value : formValues['practiceName'];
      if (!__required(fValue)) {
        return 'Practice Name is required';
      }
      if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
        return `Max ${MAX_TEXT_LENGTH} characters`;
      }
      return null;
    };
    const __validateEmail = (value = null) => {
      const fValue = value !== null ? value : formValues['email'];
      if (!__required(fValue)) {
        return 'Email is required';
      }
      if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
        return `Max ${MAX_TEXT_LENGTH} characters`;
      }
      if (!__email(fValue)) {
        return 'Invalid Email';
      }
      return null;
    };
    const __validatePhoneNumber = (value = null) => {

      const fValue = value !== null ? value : formValues['phoneNumber'];
      if (!__required(fValue)) {
        return 'Phone Number is required';
      }
      
      if (fValue && !__phoneNumber(fValue)) {

        return 'Invalid Phone number';
      }
      if (fValue && !phonenumberRegex.test(formatPhoneNumberWithDash(fValue))) {
        return 'Invalid Phone number';
      }

      if (fValue && formatPhoneNumberWithDash(fValue).length > 2) {
        if (checkSameNumber(formatPhoneNumberWithDash(fValue).substring(0, 3))) {
          return 'Invalid Phone number';
        }
      }
      return null;
    };
    const __validateZipCode = (value = null) => {
      const fValue = value !== null ? value : formValues['zipCode'];
      if (!__required(fValue)) {
        return 'Zip code is required';
      }
      if (!__maxLength(fValue, 10)) {
        return `Max 10 characters`;
      }
      return null;
    };
    const __validateSpecialty = (value = null) => {
      const fValue = value !== null ? value : formValues['specialtyId'];
      if (!__required(fValue)) {
        return 'Specialty is required';
      }
      return null;
    };
    const changeHandler = event => {
      let { name, value } = event.target;

      let errorObject = {
        [name]: null,
      };
      switch (name) {
        case 'firstName':
          errorObject = {
            [name]: __validateFirstName(value),
          };
          break;
        case 'lastName':
          errorObject = {
            [name]: __validateLastName(value),
          };
          break;
        case 'email':
          errorObject = {
            [name]: __validateEmail(value),
          };
          break;
        case 'phoneNumber':
          value = value.replace(exceptDigitsRegex, '');
          errorObject = {
            [name]: __validatePhoneNumber(value),
          };
          break;
        case 'specialtyId':
          errorObject = {
            [name]: __validateSpecialty(value),
          };
          break;
        case 'zipCode':
          errorObject = {
            [name]: __validateZipCode(value),
          };
          break;
        case 'practiceName':
          errorObject = {
            [name]: __validatepracticeName(value)
          }
          break;
        default:
          break;
      }

      let tempval = {
        ...formData,
        values: {
          ...formData.values,
          [name]: value,
        },
        errors: {
          ...formData.errors,
          ...errorObject,
        },
      };

      tempval.isValid = __validateForm()
      let tempArray = [...referredPractices]
      tempArray[index] = tempval
      setReferredPractices(tempArray)
      //updatePracticesValue(tempVal)
    };

    const __validateForm = () => {
      const validEmail = !__validateEmail();
      const validName = !__validateFirstName();
      const lastName = !__validateLastName();
      const validPhoneNumber = !__validatePhoneNumber();
      const validateSpecialty = !__validateSpecialty()
      const validZipCode = !__validateZipCode();
      const validatepracticeName = !__validatepracticeName()
      return validEmail && validatepracticeName && validZipCode && validName && validPhoneNumber && lastName && validateSpecialty;
    };

    const getGeneralInputProps = controlName => {
      return {
        onChange: changeHandler,
        onBlur: changeHandler,
        error: formErrors[controlName],
        value: formValues[controlName],
        name: controlName,
        key: `${controlName}${keyelement}`
      };
    };
    const formatLabel = (text) => {
      return (<span>{text}<span style={{ color: 'red' }}> *</span></span>)
    }
    return (<div key={`mdiv${keyelement}`} >
      <div className="form-row" >
        <Input
          {...getGeneralInputProps('firstName')}
          containerClass="two-input"
          type="text"
          label={formatLabel('First Name')}
          placeholder="First Name"
        />
        <Input
          {...getGeneralInputProps('lastName')}
          containerClass="two-input"
          type="text"
          label={formatLabel('Last Name')}
          placeholder="Last Name"
        />
      </div>
      <div className="form-row">
        <Input
          {...getGeneralInputProps('email')}
          placeholder="Enter Email Address"
          label={formatLabel("Email")}
        />
        <Input
          mask="(999) 999-9999"
          maskChar=" "
          type="tel"
          InputComponent={InputMask}
          {...getGeneralInputProps('phoneNumber')}
          placeholder="Enter Phone Number"
          label={formatLabel("Phone Number  eg. (480) 123 4567")}
        />
      </div>
      <div className="form-row">
        <Select
          {...getGeneralInputProps('specialtyId')}
          options={specialtiesList}
          labelIndex="specialty"
          valueIndex="id"
          label={formatLabel("Primary Specialty")}

        />
        <Input
          {...getGeneralInputProps('zipCode')}
          placeholder="Enter Zip Code"
          label={formatLabel("Zip Code")}
        />
      </div>

      <Input
        {...getGeneralInputProps('practiceName')}
        placeholder="Enter Practice Name"
        label={formatLabel("Practice Name")}
      />
      <Textarea  {...getGeneralInputProps('personalMessage')} className="textarea-comments" placeholder="Personal Message" label="Personal Message"></Textarea>


    </div>)
  }
  const deleteInvitation = (index) => {
    let tempIndex = [...referredPractices]
    tempIndex.splice(index, 1);
    setReferredPractices(tempIndex)
  }
  const updatePracticesValue = useCallback((index, value) => {
    let tempIndex = [...referredPractices]
    tempIndex[index] = value
    setReferredPractices(tempIndex)
  }, [referredPractices])
  return (
    <div className="InvitePractice">
      <div className="modal-content-header">
        <div className="heading3">
          Invite New Practices, Earn $299!
        </div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div>
      <div className="modal-content-body">
        <p>You will receive a $299 credit once that practice's free trial ends and they become a regular subscriber.</p>
        <div className="patient-details">
          <InfiniteScroll
            offset={100}

            setIsFetching={() => { }}
          >
            {referredPractices.map((item, index) => (
              <div key={`invitationdiv${index}`}>
                {index > 0 && (
                  <div className='seperater-div'></div>
                )}
                {referredPractices.length > 1 && (
                  <div className="add-invitation top" >
                    <span className="count" >
                      {index + 1}
                    </span>
                    <span className="plus-icon" onClick={() => {
                      deleteInvitation(index)
                    }}>
                      <img src={images.deleteRound} alt="" />
                    </span>
                  </div>
                )}

                {InviteInputs({ index: index, keyelement: `InviteInputs${index}`, updatePracticesValue: (value) => updatePracticesValue(index, value), item: item })}

              </div>

            ))}
            <div className="add-invitation" >
              <span className="plus-icon" onClick={() => {
                setReferredPractices([...referredPractices, { ...referredPracticesObj }])
              }}>
                <img src={images.documentplus} alt="" />
              </span>
            </div>

            <div className='footer-div' style={{}}>
              <CircleButton
                disabled={false}
                onClick={() => {
                  inviteFn()
                }}>
                Invite
              </CircleButton>
              <CircleButton
                onClick={closeModal}>
                Cancel
              </CircleButton>
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>


  );
};

export default InvitePractice;
