import React, { useContext } from 'react';
import image from 'data/images';
import SingleRating from '../SingleRating';
import AuthContext from 'context/AuthContext';
import './KScore.css';
import rolePermission from 'data/rolePermission';

const KScore = props => {
  const {helperFunctions,permissionList} = rolePermission;
const {getAllMyRoleIds,hasPermission} = helperFunctions;
  const authContext = useContext(AuthContext);
  const {auth} = authContext;
  const roleIds = getAllMyRoleIds(auth);
  const isAdminUser = hasPermission(roleIds, permissionList.ADMIN);
  const {data} = props;
  const {
    totalReview,
    totalAverageRating,
    doctorRatingList,
    staffRatingList,
    officeRatingList,
  } = data;
  const doctorRatingListComponent = doctorRatingList.map((rating, index) => (
    <SingleRating key={index} rating={rating} />
  ));
  const staffRatingListComponent = staffRatingList.map((rating, index) => (
    <SingleRating key={index} rating={rating} />
  ));
  const officeRatingListComponent = officeRatingList.map((rating, index) => (
    <SingleRating key={index} rating={rating} />
  ));
  return (
    <div className="KScore">
      <div className="k-score">
        <div className="k-score-content">
          <div className="h3">{isAdminUser?'kScore':`Your kScore`}</div>
          <div className="content-score">
            <span className="star">
              {' '}
              <img alt="" src={image.star} />
            </span>
            <span className="rating">{totalAverageRating.toFixed(1)}</span>
            <span className="review-count">{totalReview} Reviews</span>
          </div>
        </div>
        <div className="all-score">
          <div className="rating-content">
            <div className="h3">Doctor</div>
            {doctorRatingListComponent}
          </div>
          <div className="rating-content">
            <div className="h3">Staff</div>
            {staffRatingListComponent}
          </div>
          <div className="rating-content">
            <div className="h3">Office</div>
            {officeRatingListComponent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KScore;
