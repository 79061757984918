import React, {Component} from 'react';
import _ from 'lodash';
import SubHeader from 'components/AfterAuth/SubHeader';
import PatientHeader from 'layout/PatientLayout/PatientHeader';
import CustomFooter from 'layout/CustomFooter';
import AuthContext from 'context/AuthContext';
import PatientAccount from 'components/Patient/PatientProfile/PatientAccount';
import PatientPassword from 'components/Patient/PatientProfile/PatientPassword';
import patientService from 'services/patient';
import userService from 'services/user';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import PatientNotification from 'components/Patient/PatientProfile/PatientNotification';
import PatientInsurance from 'components/Patient/PatientProfile/PatientInsurance';
import PatientDemographic from 'components/Patient/PatientProfile/PatientDemographic';
//import PatientAuthorization from 'components/Patient/PatientProfile/PatientAuthorization';
import PatientDataPrivacy from 'components/Patient/PatientProfile/PatientDataPrivacy';
import './PatientProfile.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import VerifyPhoneNumberModal from 'components/Patient/ConfirmAppointment/VerifyPhoneNumberModal';

const {
  updateProfile,
  updateNotificationSettings,
  updateMyProfileInsurance,
  updateDemographicInformation,
  updateHipaaPolicy,
  inActivateMyAccount,
} = patientService;
const {changePassword} = userService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData, blankModalData} = modalFunctions;
const {Analytics} = analyticsData;

class PatientProfile extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {
    activeTab: null,
    invitedUserList: null,
    practiceInsurancePlanList: null,
  };
  tabs = [
    {label: 'Account', tab: 'account'},
    {label: 'Password', tab: 'password'},
    {label: 'Notifications', tab: 'notifications'},
    {label: 'Insurance', tab: 'insurance'},
    {label: 'Demographic Info', tab: 'demographic'},
    // {label: 'Authorizations', tab: 'authorizations'},
    {label: 'Data Privacy', tab: 'privacy'},
  ];

  componentDidMount() {
    const tab = this.getTabName(this.props);
    this.getAndSetActiveTab(tab);
  }

  componentDidUpdate(prevProps) {
    const tab = this.getTabName(this.props);
    const prevTab = this.getTabName(prevProps);
    if (tab !== prevTab) {
      this.getAndSetActiveTab(tab);
    }
  }

  handleError = error => {
    const {setGlobal} = this.rootContext;
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };

  getTabName = props => {
    const query = new URLSearchParams(props.location.search);
    const tab = query.get('tab') || this.tabs[0].tab;
    return tab;
  };

  getAndSetActiveTab = async tab => {
    const tabObj = _.find(this.tabs, {tab});
    if (!tabObj) {
      tab = this.tabs[0].tab;
    }
    this.setState({
      activeTab: tab,
    });
  };

  setActiveTab = async activeTab => {
    const params = new URLSearchParams();
    params.append('tab', activeTab);
    this.props.history.push({
      pathname: '/settings',
      search: `?${params.toString()}`,
    });
  };

  handleUpdateProfile = async data => {
    const {setGlobal} = this.rootContext;
    const {setAuthState} = this.context;
    const response = await updateProfile(data).catch(err => {
      if (err.customCode === 'phoneNumberVerificationFailed') {
        setGlobal(
          'modal',
          blankModalData({
            CustomComponent: VerifyPhoneNumberModal,
            customComponentProps: {
              data,
              afterVerify: () => {
                setAuthState(prevState => {
                  return {
                    ...prevState,
                    auth: {
                      ...prevState.auth,
                      hasPhoneNumberVerified: true,
                      phoneNumber: data.phoneNumber,
                    },
                  };
                });
                this.handleUpdateProfile({...data, hasPhoneNumberVerified: true});
              },
            },
            modalWrapperClass: 'mega-modal',
          }),
        );
      } else {
        this.handleError(err);
      }
    });
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            setAuthState(prevState => {
              return {
                ...prevState,
                auth: {
                  ...prevState.auth,
                  ...data,
                },
              };
            });
          },
        }),
      );
    }
  };

  handleUpdatePassword = async data => {
    const {setGlobal} = this.rootContext;
    const response = await changePassword(data).catch(this.handleError);
    if (response) {
      const {message} = response;
      setGlobal('modal', successModalData(message));
    }
  };

  handleDemographicUpdate = async data => {
    const {setAuthState} = this.context;
    const {setGlobal} = this.rootContext;
    const response = await updateDemographicInformation(data).catch(this.handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            setAuthState(prevState => {
              return {
                ...prevState,
                auth: {
                  ...prevState.auth,
                  demographicInformation: data.demographicInformation,
                  zipCode: data.zipCode,
                },
              };
            });
          },
        }),
      );
    }
  };

  handleInActivateMyAccount = async () => {
    Nux.sendEvent(Analytics.Category.PatientSettings, Analytics.Actions.Click, 'deactivateAccount');
    const {updateAuthStatus} = this.context;
    const {setGlobal} = this.rootContext;
    const response = await inActivateMyAccount().catch(this.handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            updateAuthStatus(false);
          },
        }),
      );
    }
  };

  handleUpdateHipaaPolicy = async data => {
    Nux.sendEvent(
      Analytics.Category.PatientSettings,
      Analytics.Actions.Click,
      'revokeAuthorization',
    );
    const {setAuthState} = this.context;
    const {setGlobal} = this.rootContext;
    const response = await updateHipaaPolicy(data).catch(this.handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            setAuthState(prevState => {
              return {
                ...prevState,
                auth: {
                  ...prevState.auth,
                  ...data,
                },
              };
            });
          },
        }),
      );
    }
  };

  handleInsuranceUpdate = async data => {
    const {setAuthState} = this.context;
    const {setGlobal} = this.rootContext;
    const response = await updateMyProfileInsurance(data).catch(this.handleError);
    if (response) {
      const {message, data: responseFiles} = response;
      let dataTobeUpdated = _.cloneDeep(data);
      _.forEach(dataTobeUpdated, (val, index) => {
        if (_.isObject(val)) {
          dataTobeUpdated[index] = _.get(val, 'name');
        }
      });
      _.map(responseFiles, (responseFileObject, index) => {
        const {fileName = '', fileUrl = ''} = responseFileObject;
        dataTobeUpdated[index] = fileName;
        dataTobeUpdated[`${index}Url`] = fileUrl;
      });
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            setAuthState(prevState => {
              return {
                ...prevState,
                auth: {
                  ...prevState.auth,
                  insurance: {
                    ...prevState.insurance,
                    ...dataTobeUpdated,
                  },
                },
              };
            });
          },
        }),
      );
    }
  };

  handleUpdateNotificationSettings = async data => {
    const {setAuthState} = this.context;
    const {setGlobal} = this.rootContext;
    const response = await updateNotificationSettings(data).catch(this.handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            setAuthState(prevState => {
              return {
                ...prevState,
                auth: {
                  ...prevState.auth,
                  notificationSettings: {
                    ...prevState.notificationSettings,
                    ...data,
                  },
                },
              };
            });
          },
        }),
      );
    }
  };

  renderActiveTab = () => {
    const {activeTab} = this.state;
    // const roleIds = getAllMyRoleIds();
    let activeTabComponent = null;
    switch (activeTab) {
      case 'account':
        activeTabComponent = <PatientAccount onUpdateProfile={this.handleUpdateProfile} />;
        break;
      case 'password':
        activeTabComponent = <PatientPassword onUpdatePassword={this.handleUpdatePassword} />;
        break;
      case 'notifications':
        activeTabComponent = (
          <PatientNotification
            onUpdateNotificationSettings={this.handleUpdateNotificationSettings}
          />
        );
        break;
      case 'insurance':
        activeTabComponent = <PatientInsurance onInsuranceUpdate={this.handleInsuranceUpdate} />;
        break;
      case 'demographic':
        activeTabComponent = (
          <PatientDemographic onDemographicUpdate={this.handleDemographicUpdate} />
        );
        break;
      // case 'authorizations':
      //   activeTabComponent = (
      //     <PatientAuthorization onUpdateHipaaPolicy={this.handleUpdateHipaaPolicy} />
      //   );
      //   break;
      case 'privacy':
        activeTabComponent = (
          <PatientDataPrivacy onInActivateMyAccount={this.handleInActivateMyAccount} />
        );
        break;
      default:
        activeTabComponent = null;
        break;
    }
    return activeTabComponent;
  };

  render() {
    const {activeTab} = this.state;
    const tabHeaderList = (
      <ol className="tab-list">
        {this.tabs.map((tab, index) => {
          return (
            <li
              key={index}
              className={`tab-list-item ${tab.tab === activeTab ? 'tab-list-active' : ''}`}
              onClick={() => this.setActiveTab(tab.tab)}>
              {tab.label}
              <span className="review-count">{tab.count}</span>
            </li>
          );
        })}
      </ol>
    );
    return (
      <div className="PatientProfile">
        <RootContext.Consumer>
          {context => {
            this.rootContext = context;
            return null;
          }}
        </RootContext.Consumer>
        <PatientHeader showMyKalypsys={true} />
        <div className="main-container">
          <SubHeader title="Settings" />
          <div className="setting-conettn">
            <div className="setting-tabs">
              {tabHeaderList}
              <div className="tab-content">{this.renderActiveTab()}</div>
            </div>
          </div>
        </div>
        <CustomFooter />
      </div>
    );
  }
}

export default PatientProfile;
