import axiosInstance from 'config/axios';
import _ from 'lodash';
import momentHelper from 'data/moment/momentHelper';

const {isInLastNDay, bigDateTimeFormat} = momentHelper;

const getAllFilteredReviews = (type = 'all') => {
  return axiosInstance.get(`v1/review/${type}`).then(formatReviewRespose);
};

const getReviewsForWidget = npiNumber => {
  return axiosInstance.get(`v1/review/widget/${npiNumber}`, {noLoader: true});
};

const giveReply = data => {
  return axiosInstance.post(`v1/review/reply`, data);
};
const editReply = data => {
  return axiosInstance.put(`v1/review/reply`, data);
};
const deleteReply = replyId => {
  return axiosInstance.delete(`v1/review/reply/${replyId}`);
};

const toggleFeatureType = data => {
  return axiosInstance.put(`v1/review/featured`, data);
};

const getAllReviewSource = () => {
  return axiosInstance.get(`v1/master/review-sources`);
};

const getAllMyReviewSource = () => {
  return axiosInstance.get(`v1/review/bird-eye/source/my`);
};

const saveReviewSource = data => {
  if (data.userReviewSourceId) {
    return axiosInstance.put(`v1/review/bird-eye/source/update`, data);
  } else {
    return axiosInstance.post(`v1/review/bird-eye/source/create`, data);
  }
};
const saveReviewSourceAdmin = data => {
  if (data.userReviewSourceId) {
    return axiosInstance.put(`v1/admin/review/bird-eye/source/update`, data);
  } else {
    return axiosInstance.post(`v1/admin/review/bird-eye/source/create`, data);
  }
};

const deleteReviewSourceAdmin = (sourceId, userId) => {
  return axiosInstance.delete(`v1/admin/review/bird-eye/source/delete/${sourceId}/${userId}`, {});
};

const updateReviewSoruceStatus = data => {
  return axiosInstance.post(`v1/review-source/verify`, data);
};

const deleteReviewSoruce = userReviewSourceId => {
  return axiosInstance.delete(`v1/review/bird-eye/source/delete/${userReviewSourceId}`);
};

const refetchReviews = userReviewSourceId => {
  return axiosInstance.get(`v1/review/bird-eye/source/refresh/${userReviewSourceId}`);
};

// response format functions
const formatReviewRespose = response => {
  // sort review by first feature then creation date
  let sortedReviewData = {...response.data.reviewData};
  sortedReviewData = _.orderBy(sortedReviewData, ['isFeatured', 'createdAt'], ['desc', 'desc']);
  let totalRecentReviewCount = 0;
  let totalFeaturedReviewCount = 0;
  sortedReviewData = _.map(sortedReviewData, review => {
    const updatedReview = {...review};
    updatedReview.isRecentReview = isInLastNDay(updatedReview.createdAt, 7);
    updatedReview.formatedCreateDate = bigDateTimeFormat(updatedReview.createdAt, true);
    if (updatedReview.isRecentReview) {
      totalRecentReviewCount++;
    }
    if (updatedReview.isFeatured) {
      totalFeaturedReviewCount++;
    }
    const {
      doctorKnowledge,
      doctorApproachable,
      doctorBedsideManner,
      staffProfessional,
      staffResponseTime,
      staffScheduling,
      officeAtmosphere,
      officeCleanliness,
      officeWaitTimes,
    } = review;
    const doctorAverageRating = (doctorKnowledge + doctorApproachable + doctorBedsideManner) / 3;
    const staffAverageRating = (staffProfessional + staffResponseTime + staffScheduling) / 3;
    const officeAverageRating = (officeAtmosphere + officeCleanliness + officeWaitTimes) / 3;
    updatedReview.ratings = [
      {
        name: 'Doctor',
        rating: Math.round(doctorAverageRating * 10) / 10,
        subRatings: [
          {name: 'Knowledge', rating: doctorKnowledge},
          {name: 'Approachable', rating: doctorApproachable},
          {name: 'Bedside Manner', rating: doctorBedsideManner},
        ],
      },
      {
        name: 'Staff',
        rating: Math.round(staffAverageRating * 10) / 10,
        subRatings: [
          {name: 'Professional', rating: staffProfessional},
          {name: 'Scheduling', rating: staffScheduling},
          {name: 'Response Time', rating: staffResponseTime},
        ],
      },
      {
        name: 'Office',
        rating: Math.round(officeAverageRating * 10) / 10,
        subRatings: [
          {name: 'Cleanliness', rating: officeCleanliness},
          {name: 'Atmosphere', rating: officeAtmosphere},
          {name: 'Wait times', rating: officeWaitTimes},
        ],
      },
    ];
    return updatedReview;
  });
  const updatedResponse = {
    ...response,
    data: {
      ...response.data,
      totalRecentReview: totalRecentReviewCount,
      totalFeaturedReview: totalFeaturedReviewCount,
      reviewData: sortedReviewData,
    },
  };
  return updatedResponse;
};

const sendReviewRequest = data => {
  return axiosInstance.post(`v1/review/sendreviewrequestnew`, data);
};

const getSocialConnect = () => {
  return axiosInstance.get(`v1/review/bird-eye/social`);
};
const getSocialConnectAdmin = userId => {
  return axiosInstance.get(`v1/admin/review/bird-eye/social/${userId}`);
};
const postSocialConnectEmail = userId => {
  return axiosInstance.post(`v1/master/sendMail`, {userId: userId});
};

const addSocialConnect = data => {
  return axiosInstance.post(`v1/review/bird-eye/social`, data);
};
const disconnectGoogleSso = data => {
  return axiosInstance.post(`v1/googleSSO/disconnect`, data);
};

const deleteSocialConnect = data => {
  return axiosInstance.delete(`v1/review/bird-eye/social/${data}`);
};

const reviewDeleteRequest = data => {
  return axiosInstance.put(`v1/review/delete-request`, data);
};

const getContacts = args => {
  return axiosInstance.post(`v1/provider/contact/getall`, args);
};
const ViewContactHistory = args => {
  let paramsParse = [];
  if (Object.keys(args).length > 0) {
    paramsParse = Object.keys(args).map(i => [`${i}=${args[i]}`]);
  }
  return axiosInstance.get(`v1/provider/contacthistory?${paramsParse.join('&')}`);
};
const addContacts = data => {
  return axiosInstance.post(`v1/provider/contact/create`, data);
};
const editContacts = data => {
  return axiosInstance.put(`v1/provider/contact/update`, data);
};
const deleteContacts = id => {
  return axiosInstance.delete(`v1/provider/contact/delete/${id}`);
};
const getBirdEyeSettings = () => {
  return axiosInstance.get(`v1/review/bird-eye/userSourceList`);
};
const saveBirdEyeSettings = data => {
  return axiosInstance.put(`v1/review/bird-eye/email-template/review-source`, data);
};
const getgoogleSSOConnect = () => {
  return axiosInstance.get(`v1/googleSSO/validate`);
};
const postGoogleSSO = data => {
  return axiosInstance.post(`v1/googleSSO/register`, data);
};
const postGoogleSSORegisterGoogleCalendar = data => {
  return axiosInstance.post(`v1/googleSSO/registerGoogleCalendar`, data);
};

const getgoogleSSOLocations = () => {
  return axiosInstance.get(`v1/googleSSO/locations`);
};
const saveLocationList = data => {
  return axiosInstance.post(`v1/userReviewSourceLocation/save`, data);
};
const getGoogleSSouserReviewSourceLocation = () => {
  return axiosInstance.get(`v1/userReviewSourceLocation/list`);
};
const connectGoogleCalender = () => {
  return axiosInstance.post(`v1/googleSSO/connectCalendar`);
};
const disconnectGoogleCalender = () => {
  return axiosInstance.post(`v1/googleSSO/disconnectCalendar`);
};
const checkGoogleCalendarConnected = () => {
  return axiosInstance.get(`v1/googleSSO/checkGoogleCalendarConnected`);
};

export default {
  getAllFilteredReviews,
  giveReply,
  editReply,
  deleteReply,
  toggleFeatureType,
  getAllReviewSource,
  getAllMyReviewSource,
  saveReviewSource,
  saveReviewSourceAdmin,
  updateReviewSoruceStatus,
  deleteReviewSoruce,
  refetchReviews,
  getReviewsForWidget,
  sendReviewRequest,
  getSocialConnect,
  addSocialConnect,
  deleteSocialConnect,
  reviewDeleteRequest,
  getContacts,
  addContacts,
  editContacts,
  deleteContacts,
  getBirdEyeSettings,
  saveBirdEyeSettings,
  ViewContactHistory,
  deleteReviewSourceAdmin,
  getSocialConnectAdmin,
  postSocialConnectEmail,
  getgoogleSSOConnect,
  postGoogleSSO,
  getgoogleSSOLocations,
  saveLocationList,
  disconnectGoogleSso,
  getGoogleSSouserReviewSourceLocation,
  connectGoogleCalender,
  disconnectGoogleCalender,
  checkGoogleCalendarConnected,
  postGoogleSSORegisterGoogleCalendar,
};
