import React, {Component} from 'react';
import Header from 'layout/Header';
import './Terms.css';
import moment from 'moment';

class Terms extends Component {
  render() {
    return (
      <>
        <Header hideHeaderButtons={true}></Header>
        <div className="Terms">
          <div className="container">
            <div className="template-inner">
              <div className="main-header">KALY MANDATORY TERMS OF USE</div>
              <div className="template-content">
                    <div className="sub-header-title">Effective Date: December 1, 2020</div>
                    <div className="sub-header-title title-txt">WELCOME TO KALY</div>
                    <div className="para text-bold">
                        PLEASE READ THESE TERMS CAREFULLY. YOU MUST AGREE TO THIS MANDATORY TERMS OF USE
                        AGREEMENT BEFORE USING THIS WEBSITE. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT
                        USE THIS WEBSITE, DO NOT PROVIDE ANY INFORMATION, AND NAVIGATE AWAY FROM THE SITE.
                    </div>
                    <div className="para text-bold">ACCEPTANCE AND SCOPE OF TERMS</div>
                    <div className="para">
                        Kaly, LLC (hereinafter, “Kaly”) provides this website, www.kaly.com,
                        and any affiliated websites, together with the website-related content and
                        services (the “Website”) subject to your agreement and compliance with the
                        mandatory terms of use set forth in this Mandatory Terms of Use Agreement (the
                        “Agreement”). This Agreement, and the terms and conditions listed in the
                        incorporated Privacy Policy (the “Privacy Policy”), as well as any other
                        agreements or policies mentioned herein, govern the relationship between Kaly
                        and you, as well as your access to and use of the Website.
                    </div>
                    <div className="para">
                        By accessing or using the Website, you acknowledge that you have read and
                        understood the terms and conditions of this Agreement, the Privacy Policy, and any
                        other agreements or policies mentioned herein, and that you accept and agree to be
                        bound by all terms and conditions of this Agreement, the Privacy Policy, and any
                        agreements or policies mentioned herein without limitation or qualification. This
                        will create a legally binding contract. If you object to any term herein and would
                        still like to use the Website, you must contact Kaly to discuss your issue.
                        Only variations signed off by an executive officer shall be considered effective.
                        No one else at Kaly may modify these terms. This Website is for the use of
                        persons eighteen (18) years of age and older. By accessing and/or using this site,
                        you represent and warrant that you are over the age of 18, and capable of entering
                        into this Agreement.
                    </div>
                    <div className="para text-bold">DEFINITIONS</div>
                    <div className="para">
                        As used in this Agreement, the words “you” and “your” refer to any person
                        accessing the Kaly Website. The words “we,” “us,” and “our” refer to Kaly.
                    </div>
                    <div className="para">
                        The words “Healthcare Provider” include, physician, dentists, psychologists,
                        social workers, physical therapists, and others who have a “Provider Account” with
                        Kaly. Healthcare Providers have descriptions of their services aggregated on
                        the Website and provide patient care. Kaly provides no healthcare or health
                        advice.
                    </div>
                    <div className="para">
                        “Disclaimer” shall include the following phrase: “The content on this Website is
                        not intended to be, or is, a substitute for professional medical advice,
                        diagnosis, or treatment. You should always seek the advice of Your physician or
                        other qualified healthcare provider with any questions you may have regarding a
                        medical condition.”
                    </div>
                    <div className="para">
                        “Healthcare Provider Agreement” shall mean the contract entered into between the
                        Healthcare Provider and Kaly for the Healthcare Provider’s presence and
                        involvement on the Website.
                    </div>
                    <div className="para">
                        “Provider Account” shall mean the record for each
                        Healthcare Provider who has entered into a Healthcare Provider Agreement.
                    </div>
                    <div className="para">
                        “User” shall mean the individual who uses the Kaly site for its intended
                        purpose. This differs from a “Visitor” to the website who merely encounters the
                        Kaly service/Website but takes no action. Visitors are bound to the same terms
                        as that of the User.
                    </div>
                    <div className="para">
                        “User Account” shall mean the account created by or for the User of the Kaly
                        service. Such User Account is dependent on the User agreeing to the terms of this
                        Agreement, the Privacy Policy and abiding by the laws applicable to the use of
                        this Website.
                    </div>
                    <div className="para text-bold">MODIFICATIONS TO THE AGREEMENTS</div>
                    <div className="para">
                        Kaly may, at any time and in our sole discretion, revise or update this
                        Agreement, the Privacy Policy or any other agreements or policies referenced
                        herein, by posting an amended Agreement, Privacy Policy or other agreements or
                        policies on the Website. Please check this page periodically for changes to the
                        Agreement, as well as other agreements and policies. Your use of the Website on or
                        after the effective date of any such Agreement constitutes your acceptance of the
                        last updated agreement or policy.
                    </div>
                    <div className="para">
                        Kaly reserves the right, at any time, to modify, suspend or discontinue,
                        temporarily or permanently, the Website (or any part thereof) without notice to
                        you. You agree that Kaly shall not be liable to you or to any third party for
                        any unavailability, modification, suspension or discontinuance of the Website, or
                        of any Materials (as defined below).
                    </div>
                    <div className="para">
                        If you have any questions about this Agreement, or otherwise desire to contact
                        Kaly, please direct your communication to:
                        <br />
                        Kaly, LLC
                        <br />
                        c/o Wilson Elser
                        <br />
                        Attn: Neil Prupis
                        <br /> 200 Campus Drive
                        <br /> Florham Park, NJ 07932
                    </div>
                    <div className="para text-bold">LICENSE TO USE WEBSITE</div>
                    <div className="para">
                        As long as you are in compliance with the terms of this Agreement and all
                        associated documents incorporated herein, Kaly hereby grants you a limited,
                        revocable, non-assignable, non-sublicensable, non-exclusive license to access and
                        use the Website via an ordinary Internet browser, and to view the Website and all
                        content and Materials thereon that are intended to be displayed publicly. Any
                        violation of the terms or conditions of this Agreement is grounds for immediate
                        termination of this limited license, which Kaly, in our sole discretion and
                        without prior notice or liability, may terminate at any time. No other rights are
                        granted, implied or otherwise. You agree that this license is extended only to the
                        extent that your activity is legal and does not violate any international,
                        federal, state or local law or regulation.
                    </div>
                    <div className="para text-bold">INCORPORATED TERMS</div>
                    <div className="para">
                        The following additional terms are hereby incorporated into this Agreement by
                        reference, and your assent to this Agreement binds you to all of the additional
                        terms in the following documents:
                    </div>
                    <div className="para">
                        1. Copyright Policy;
                        <br />
                        2. Complaint Policy, including trademark complaints; and
                        <br />
                        3. Privacy Policy.
                    </div>
                    <div className="para">
                        The terms of this Agreement shall be incorporated into the Healthcare Provider
                        Agreement. However, if there are any conflicting terms, the Healthcare Provider
                        Agreement shall control.
                    </div>
                    <div className="para text-bold">WEBSITE DESCRIPTION</div>
                    <div className="para">
                        Kaly is an aggregator of Healthcare Provider information. You may establish a
                        “User Account” to use the features on Kaly to learn about the various
                        Healthcare Providers who have a Provider Account with Kaly. You may use your
                        User Account to schedule appointments to see and visit Healthcare Providers who
                        have Provider Accounts with Kaly.
                    </div>
                    <div className="para">
                        Upon receiving a request from a user with a User Account, Kaly provides
                        options of Healthcare Providers who have Provider Accounts who may be an available
                        Healthcare Provider for the user. Kaly makes no endorsement or recommendation
                        of any particular healthcare provider and is not responsible for the care or
                        advice rendered by such Healthcare Provider. Kaly is only an aggregator of
                        information and the choice of healthcare provider is made solely by the User.
                        Kaly cannot and does not guarantee the availability of appointments for any
                        Healthcare Provider whose information is displayed on the Website. Kaly cannot
                        and does not guarantee any level of patient care that is provided by any
                        Healthcare Provider whose information is displayed on the Website.
                    </div>
                    <div className="para text-bold">ERRORS & INACCURACIES</div>
                    <div className="para">
                        While Kaly attempts to be as accurate as possible with respect to the Website,
                        the Website may contain typographical errors or inaccuracies, including but not
                        limited to errors or inaccuracies related to price, product or service
                        information, product or service description or availability, insurance
                        information, and may not be complete or current. Information may be provided by a
                        Healthcare Provider who represents that the information is accurate, and Kaly
                        is not responsible for independently ensuring the accuracy of this information.
                        Kaly does not warrant that the Website or any Materials are accurate,
                        complete, reliable, current, or error-free. Kaly reserves the right to correct
                        any errors, inaccuracies or omissions and to change or update information at any
                        time without prior notice.
                    </div>
                    <div className="para">
                        Any price quotation, service information or any other such information regarding
                        the provision of and fees for any goods or service offered by Kaly or others
                        are provided purely for informational and illustrative purposes. Kaly does not
                        guarantee, and makes no warranty, promise, or agreement that any depicted or
                        described goods or services are actually offered or comports with its description
                        on the Website. Kaly reserves the right to cease offering any such services or
                        benefits to any prospective or actual User at any time, in all cases without
                        notice or liability.
                    </div>
                    <div className="para">
                        Kaly requires its Healthcare Providers who have established accounts with
                        Kaly to ensure that the information displayed about them on the Website is
                        represented to be true when posted and is updated at least once per calendar year.
                    </div>
                    <div className="para text-bold">
                        NO DOCTOR/PATIENT RELATIONSHIP/NO HEALTHCARE SERVICES RENDERED
                    </div>
                    <div className="para">
                        Kaly is an aggregator of physician and Healthcare Provider information. It is
                        not a source for healthcare advice, diagnosis or treatment. Nothing provided in
                        the Website is or should be understood as or relied upon for medical opinion,
                        advice, diagnostics or treatment. Use or perusal of the Website does not create a
                        Doctor-Patient relationship. Nothing in the Website or use of the Website
                        constitutes or shall be deemed the practice of medicine.
                    </div>
                    <div className="para">
                        Kaly does not and cannot make any representations or warranties regarding the
                        quality of care that will be provided by any Healthcare Provider when an
                        appointment to see that Healthcare Provider is scheduled on the Website. Kaly
                        encourages users to independently verify the credentials of Healthcare Providers
                        whose services are aggregated and listed on the Website.
                    </div>
                    <div className="para">
                        In case of medical emergency, contact your Healthcare Provider directly and/or
                        dial 911; do not attempt to contact a Healthcare Provider through Kaly.
                    </div>
                    <div className="para">
                        Kaly does not and cannot make any guarantees regarding the availability of
                        appointments to see and visit any Healthcare Provider. While Kaly makes
                        efforts to ensure that the information displayed on the Website is up-to-date and
                        accurate, Kaly does not and cannot make any guarantees regarding any
                        Healthcare Provider’s acceptance of any particular insurance, regardless of what
                        is listed on the Website.
                    </div>
                    <div className="para text-bold">USING THE SITE AND CREATING A USER ACCOUNT</div>
                    <div className="para">
                        When you create a User Account, you are solely responsible for maintaining the
                        confidentiality and security of your login information and remain at all times
                        solely responsible for all usage or activity on the Website occurring in
                        connection with your Login Information. You agree and acknowledge that Kaly
                        shall have no responsibility or liability to you or any third party for any
                        claims, harm, or losses arising in connection with or resulting from the use of
                        your Login Information. If you believe that your Login Information has been lost,
                        stolen, or otherwise compromised, or that an unauthorized person has or may
                        attempt to access the Website, you should immediately contact Kaly via e-mail
                        to <a href="mailto:hello@kaly.com">hello@kaly.com</a> with the phrase
                        “Account Breach Notice” in the subject line, and Kaly will engage in
                        reasonable efforts with reasonable speed, to disable, lock or otherwise address
                        your situation.
                    </div>
                    <div className="para">
                        When you create a User Account you may be prompted to provide some information to
                        the Website, including, but not limited to, your name and contact information,
                        insurance information, medical history, current medical needs and social security
                        number. Any given Healthcare Provider may require this or even more information to
                        schedule and confirm an appointment. Your privacy and online safety is our
                        priority and therefore we take reasonable efforts to safeguard your information in
                        accordance with our Privacy Policy.
                    </div>
                    <div className="para">
                        When you create a User Account, you agree that Kaly may assign to you a User
                        Score. This score may be updated periodically to reflect certain of your behaviors
                        with respect to appointments that you schedule on the Website. For example, your
                        score may go down if you do not show up for scheduled appointments, or if you show
                        up late for your appointments. You agree and understand that Healthcare Providers
                        may accept or reject a request to schedule an appointment with them based on your
                        User Score. Kaly is not responsible for you User Score. The User Score is
                        based solely on objective factors and/or the input from Healthcare Providers. If
                        you believe that you have wrongly been assigned a User Score, please contact us,
                        providing your name, your User Account information, your email address, and the
                        substance of your claim. Such information should be provided to: <a href="mailto:info@kaly.com">info@kaly.com</a> Kaly will
                        endeavor to reasonably look into your claim, but can make no promises with respect
                        to a change in the User Score. Please note that if you do contact us to look into
                        the situation, your User Score may not change for the better and could decrease.
                        You agree that Kaly shall not be responsible for such User Score or any
                        actions taken related thereto.
                    </div>
                    <div className="para text-bold">LIMITATION OF LIABILITY AND HEALTHCARE FEES</div>
                    <div className="para">
                        Your use of the Website is free of charge. However, you may and likely will incur
                        fees when you visit Healthcare Providers and obtain healthcare from a Healthcare
                        Provider listed on the Website. You are solely responsible for all medical fees,
                        consultation fees, treatment fees, and other fees imposed by a Healthcare
                        Provider.
                    </div>
                    <div className="para">
                        Kaly and the Website are not liable for loss or injury sustain by you in
                        connection with your use of the Website or in connection with any healthcare you
                        obtain that was scheduled through the use of the Website.
                    </div>
                    <div className="para text-bold">
                        OWNERSHIP OF WEBSITE CONTENT & INTELLECTUAL PROPERTY
                    </div>
                    <div className="para">
                        The content, functions, features, services, information and materials on the
                        Website, including but not limited to text, data, audio, video, images,
                        photographs, graphics, documents, summaries, descriptions, forums, message boards,
                        user profiles, professional summaries, articles, publications, news, surveys,
                        seminars, information regarding Healthcare Providers or Users, tutorials, icons
                        and any other information or material appearing on or otherwise accessible through
                        the Website (the “Materials”), together with the selection and arrangement of such
                        Materials, are the property of Kaly, our affiliates, or our content suppliers,
                        and are protected by copyright, trademark, patent and/or other intellectual
                        property laws of the United States and other jurisdictions.
                    </div>
                    <div className="para">
                        The Materials, the Website and its downloadable contents are protected by the
                        copyright laws of the United States and other jurisdictions. You recognize that
                        while some of the Materials available on the Website may be compiled from publicly
                        available sources, the compilation of such materials represents protectable effort
                        and expression exerted by Kaly, and you agree not to contest same.
                    </div>
                    <div className="para">
                        The trademarks, service marks, logos and any designs used or displayed on the
                        Website specific to Kaly are registered and unregistered trademarks and/or
                        service marks owned by Kaly. Other copyrighted works, trademarks, service
                        marks, trade names and company logos utilized on the Website not owned by Kaly
                        are the property of their respective owners. All rights reserved. The appearance
                        of any such third party trademarks does not in any way imply any connection,
                        license, affiliation, approval, sponsorship or other such relationship of any kind
                        between Kaly and such third party.
                    </div>
                    <div className="para">
                        The removal of any trademark, copyright, or other rights notice which appears on
                        the Website is strictly prohibited. Further, except as expressly permitted herein,
                        any use, reproduction, duplication, publication, display, copying, sale,
                        modification, exploitation, or other use of the Materials, copyrighted works,
                        trademarks or service marks, or other portion of the Website, in whole or in part,
                        is strictly prohibited without the prior express written consent of Kaly.
                    </div>
                    <div className="para text-bold">
                        USER GENERATED CONTENT & USE OF WEBSITE SERVICES
                    </div>
                    <div className="para">
                        The Website may include information and materials generated by third parties not
                        affiliated with Kaly. The Website may contain bulletin board services, comment
                        sections, chat areas, news groups, forums, communities, personal web pages,
                        calendars, message or communication facilities, and/or other features or functions
                        in connection with which you may submit, upload, transmit, publish, share, post,
                        distribute or otherwise make available certain information, content and materials
                        to Kaly, the Website, other users and/or the public at large (collectively,
                        “Communication Services”). Any information, content or other material you submit,
                        upload, transmit, publish, share, post, distribute or otherwise make available on
                        the Website, whether in connection with the Communication Services or otherwise,
                        including without limitation any text, audio, video, images, graphics, documents,
                        artwork, designs, ideas, concepts, techniques, communications and any other
                        content shall be regarded as a “Submission” (collectively the “Submissions).
                    </div>
                    <div className="para">
                        You are under no obligation to submit, upload, transmit, publish, share, post,
                        distribute or otherwise make any information, content or other materials available
                        on the Website, whether in connection with the Communication Services or
                        otherwise. However, by submitting, uploading, transmitting, publishing, sharing,
                        posting, distributing or otherwise making available any Submission or other
                        content on the Website, whether in connection with the Communication Services or
                        otherwise, you hereby grant Kaly a worldwide, fully paid up, royalty-free,
                        nonexclusive, unrestricted, unlimited, assignable, sub-licensable, perpetual,
                        irrevocable license to Kaly to use, copy, license, sublicense, retain,
                        distribute, reproduce, modify, edit, adapt, improve, create derivative works from,
                        publish, remove, delete, translate, publicly perform, publicly display,
                        commercialize, or otherwise exploit such Submission or the content, in whole or in
                        part, in any media, way or manner now known or in the future discovered or
                        developed, and to incorporate such Submission or content into other works in any
                        format or medium now known or later developed, for any purpose whatsoever,
                        commercial or otherwise, without providing compensation or attribution to you or
                        any person or entity, without further consent, and without any notice or liability
                        to you or to any third party whatsoever. NOTHING HEREIN SHALL IN ANYWAY AFFECT
                        YOUR PRIVACY RIGHTS EXPRESSLY SET FORTH IN THE PRIVACY POLICY. You do, however,
                        agree to allow Kaly to use your name, likeness, image or other right of
                        publicity (both during your lifetime and post-mortem) in connection with any
                        Submission. You further expressly permit, license, and assent to Kaly’ right
                        to register, deposit, or otherwise record any modification or derivative work
                        created by Kaly from the Submission or content with any foreign or domestic
                        body, including but not limited to the United States Copyright Office or the
                        United States Patent and Trademark Office. You further hereby expressly and
                        irrevocably waive all moral rights, proprietary rights, rights of privacy and
                        publicity, and rights of attribution in connection with the Submission or content,
                        in favor of Kaly and any of its successors or assigns.
                    </div>
                    <div className="para">
                        You agree not to submit, upload, transmit, publish, share, post, distribute, or
                        otherwise make available through the Website or any service thereon, any
                        Submission or other content that, in whole or in part: (a) contains a virus,
                        corrupt file, malware, or any other similar software of programs which may damage
                        the operation of another’s computer; is unlawful or encouraging of another to
                        commit unlawful activity; (b) is violative of the rights of any party, infringes
                        upon the patent, trademark, trade secret, copyright, or other intellectual or
                        proprietary property right of any party; (c) is defamatory, libelous, obscene,
                        pornographic, indecent, lewd, inappropriate, invasive of privacy or publicity
                        rights, abusive, harasses, threatens, or is otherwise objectionable; (d)
                        constitutes advertisements or offers to sell or buy goods or services of any kind,
                        unless authorized by Kaly; (e) contains a survey, contest, pyramid scheme or
                        chain letter; (f) may restrict or inhibit any other user from using the
                        Communication Services; or (g) violates any rules of conduct which may be
                        applicable to a particular Communication Service.
                    </div>
                    <div className="para">
                        By submitting, uploading, transmitting, publishing, sharing, posting, distributing
                        or otherwise making available any Submission or other content to the Website, you
                        hereby explicitly agree, represent and warrant that: (a) you are the owner or
                        rights holder of the Submission or content (b) all such Submissions and content
                        are true and accurate and do not contain confidential, trade secret, or
                        proprietary information; (c) your provision of the Submission or content is not a
                        violation of any law or contractual or legal right of any party (including any
                        intellectual property rights); (d) your Submission or content automatically
                        becomes the property of Kaly without any obligation, including payment and
                        attribution, of Kaly to you; (e) Kaly is not under any obligation of
                        confidentiality relating to the Submissions or content, either express or implied;
                        (f) Kaly shall be entitled to use or disclose the Submission or content in any
                        way, manner, purpose or otherwise, worldwide; (g) Kaly may have under
                        consideration or development similar ideas to the Submission or content; (h) you
                        are not entitled to any compensation or reimbursement of any kind from Kaly in
                        exchange for the Submission or content; and (i) you are at least eighteen (18)
                        years of age. If you are accessing the Website on behalf of a third party (whether
                        an individual or an entity), you represent that you have the authority to bind
                        that third-party as a principal to all of the terms of this Agreement and you
                        agree to accept liability for harm caused by any wrongful use of the Website
                        resulting from such access or use.
                    </div>
                    <div className="para">
                        You must provide true and accurate information at all times in any content,
                        Submission, or other information that you provide, upload or otherwise make
                        available to the Website. You agree to review and promptly comply with all
                        messages received from Kaly regarding the Website. You agree to use the
                        Communication Services only to post, send and receive messages and material that
                        are proper and related to the particular Communication Service. Kaly does not
                        warrant that any information contained on or in said Communications Services is
                        accurate, reliable or confidential. You agree that Kaly cannot be held
                        responsible for any ramifications of your posting or use of any content or
                        Submissions or any Materials from or to said Communications Services.
                    </div>
                    <div className="para">
                        Further, Kaly may access and use the Website and any service and any
                        information stored thereon, including the contents of any content or Submissions,
                        for any lawful purpose. In accordance with our Privacy Policy, Kaly may
                        disclose the contents of any content or Submissions, including the identity and
                        other personal information of any user, submitter or poster of such materials, to
                        any third party, including any law enforcement agency, to protect our rights or
                        property, or for any other reason. Certain content, Submissions or material you
                        may choose to make available on the Website may reveal your age, gender,
                        nationality, sexual orientation or religion. You acknowledge that you are fully
                        aware and responsible for all actions and impact of providing said materials. You
                        agree that Kaly will not be held responsible for any such impact. By providing
                        any sensitive personally identifiable information to Kaly, you explicitly
                        grant Kaly the right to process, transfer and/or disclose all or part of such
                        information, IN ACCORDANCE WITH THE PRIVACY POLICY. You hereby take and accept
                        full responsibility for all ramifications of the public availability of any
                        content or Submissions you make available on the Website, and agree that Kaly
                        shall be held harmless from any result of such availability or use, in accordance
                        with this Agreement.
                    </div>
                    <div className="para">
                        You alone are responsible for your interactions with other users of the Website.
                        Kaly may monitor disputes between you and other users, and may terminate your
                        User Account at any time, in its sole discretion; however, Kaly is under no
                        obligation to monitor such interactions or take any particular action. In the
                        event that you believe that any user of the Website has violated this Agreement,
                        please contact Kaly immediately, and Kaly may take action that it, in its
                        sole discretion, deems appropriate under the circumstances.
                    </div>
                    <div className="para">
                        Kaly does not control the content or Submission submitted to Kaly or
                        otherwise made available to others on the Website, and has no obligation or duty
                        to verify the accuracy of any such content or Submission, or the identity of any
                        user that provides such materials or to supervise the uploading, use or
                        availability of such materials on or to the Website. Kaly retains the right,
                        but not the obligation, to monitor any activity and Submission or content
                        submitted, posted, uploaded, or otherwise communicated to Kaly or the Website,
                        or is made available on the Website. Kaly may block, edit, modify or remove
                        any Submission or content it deems to be violative of this Agreement, or any other
                        content or Submissions that Kaly deems, in Kaly’ sole discretion, to be
                        objectionable or in any way not in furtherance of the goals or purposes of
                        Kaly, the Website or the particular area of the Website to which such content
                        or Submission may have been placed.
                    </div>
                    <div className="para">
                        Should you seek to remove any Submission or content from the Website or other
                        platform operated by Kaly, you should contact Kaly in writing with your
                        removal request at <a href="mailto:hello@kaly.com">hello@kaly.com</a> with
                        the phrase “Content Removal Request” in the subject line. Kaly in its sole
                        discretion shall assess whether to remove any content or Submission that is the
                        subject of such a removal request. Kaly does not guarantee it will remove any
                        post.
                    </div>
                    <div className="para">
                        Kaly is not responsible, and assumes no liability, for any content or
                        Submission posted by you, or any damage caused by another user’s access to such
                        content on the Website, whether before or after any removal of such content or
                        Submission by Kaly. Kaly in no way endorses any content or Submission
                        posted to or made available through the Website.
                    </div>
                    <div className="para text-bold">
                        RESTRICTIONS ON USE OF THE WEBSITE & WEBSITE CONTENT
                    </div>
                    <div className="para">
                        Your use of the Website and all services and content provided in connection with
                        the Website is subject to the terms and conditions of this Agreement and all
                        applicable laws, rules and regulations. Any use of the Website other than as
                        expressly permitted herein terminates the limited license set forth in this
                        Agreement without prejudice to any other remedy provided by applicable law.
                    </div>
                    <div className="para">
                        You may print and/or download a copy of any part of the Website solely for your
                        personal, non-commercial use, but you may not copy, distribute, make commercial
                        use of or otherwise exploit any part of the Materials or the Website for any other
                        purpose without the express written consent of Kaly, and you may not modify
                        any part of the Website for any reason. Inclusion of any part of the Website in
                        another work, whether in printed, electronic or other form, or inclusion of any
                        part of the Website in another website by linking, framing, in-line linking,
                        embedding or otherwise, is strictly prohibited without agreeing to Kaly’
                        Mandatory Terms of Use and obtaining Kaly’ prior express written
                        authorization.
                    </div>
                    <div className="para">
                        You agree not to use the Website for any unlawful or fraudulent purpose, including
                        impersonating any person or entity, including, but not limited to, any Kaly
                        employee, agent, or representative; or expressing or implying that Kaly
                        endorses any statement you make. You may not use any information obtained from the
                        Website to harass or intimidate others or to contact them without their
                        permission.
                    </div>
                    <div className="para">
                        You may not modify, adapt, translate, copy, reverse engineer decompile or
                        disassemble any portion of the Website or Materials. Further, you may not
                        interfere with or disrupt the operation of the Website, including restricting or
                        inhibiting any other person from using the Website by means of hacking, or
                        defacing any portion of the Website. Further, transmitting or making available in
                        connection with the Website any denial of service attack, virus, worm, Trojan
                        horse, root kit or other harmful code or activity is prohibited. Moreover, you may
                        not interfere with or violate any other Website Visitor’s or User’s right to
                        privacy or other rights, or harvest or collect personally identifiable information
                        about Website Visitors or Users, or about Kaly employees or other individuals
                        identified on the Website, without their express written consent.
                    </div>
                    <div className="para">
                        You are also proscribed from selling, reselling, transferring, licensing, lending
                        or exploiting for any commercial purposes any use of or access (including sharing
                        of passwords and login information) to the Website or the Materials. You may not
                        harvest information from the Website, including any scraping of the Website. You
                        may not use the Website to solicit any other Website users, either through Website
                        functionality or through outside functionality.
                    </div>
                    <div className="para">
                        You may not access any portion of the Website that is not public or is not meant
                        to be accessed by general Users, and you may not attempt to override any security
                        measures in place on the Website.
                    </div>
                    <div className="para">
                        To the extent that you may access or download any software that is made available
                        through Kaly from the Website (“Software”), such Software is the copyrighted
                        work of Kaly and/or its various third party licensors. Your use of the
                        Software is governed by the terms of the end user license agreement, if any, which
                        accompanies or is included with the Software. You may not install or use any
                        software that is accompanied by or includes such an end user license agreement
                        unless you first agree to the terms of such agreement. Any warranty applicable to
                        the Software will be specified in the terms of the License Agreement and no
                        additional warranties are provided herein. If you have any inquiries concerning
                        these terms, please consult Kaly before accessing any programs.
                    </div>
                    <div className="para">
                        In the event that you are obligated by law to take actions contrary to any term of
                        this Agreement, you agree to notify Kaly at least 30 days before you are
                        required to take such actions.
                    </div>
                    <div className="para text-bold">AVAILABILITY OF THE WEBSITE</div>
                    <div className="para">
                        While Kaly makes reasonable efforts to ensure that the Website is available at
                        all times, Kaly does not guarantee, represent or warrant that access to the
                        Website will be uninterrupted or error-free, and Kaly does not guarantee that
                        users will be able to access or use all or any of the Website features at all
                        times.
                    </div>
                    <div className="para">
                        Kaly may change, suspend or discontinue, temporarily or permanently, any (or
                        all) aspects of the Website at any time without prior notice, including the
                        availability of any Website feature or your particular user account, in its sole
                        discretion. Kaly may also impose limits on the use of or access to certain
                        features or portions of the Website, or restrict your access to any part or all of
                        the Website, in all cases without notice or liability.
                    </div>
                    <div className="para">
                        You are prohibited from attempting to or actually accessing those portions of the
                        Website for which you have no permission.
                    </div>
                    <div className="para">
                        Kaly is under no obligation to maintain the Website or any information,
                        content, Submissions, Materials, or other matters that you submit, post or make
                        available to the Website, or are otherwise available on the Website. Kaly
                        reserves the right to withhold, remove and/or discard any such material on the
                        Website or made available or placed by you thereon without notice at any time. You
                        agree that even if Kaly has maintained such materials, you have no control or
                        right to access them, and have no right to request that Kaly restore or
                        produce such material.
                    </div>
                    <div className="para">
                        You acknowledge and agree that Kaly may send you important notices or other
                        information related to the Website, the relationship with Kaly or other
                        information we believe may be of interest to you, by email or through other means,
                        including mobile or other devices. You acknowledge and agree that Kaly has no
                        liability related to or arising from any failure by you to maintain accurate or up
                        to date contact information, or other profile information, or related to your
                        failure to receive important or critical information regarding the Website.
                    </div>
                    <div className="para text-bold">LINKS TO OTHER WEBSITES</div>
                    <div className="para">
                        The Website may contain links to other sites (“Third Party Websites”). These links
                        are provided solely for the convenience of our users. Kaly does not endorse,
                        sanction or verify the accuracy of the information contained on Third Party
                        Websites or any products or services advertised on Third Party Websites, nor does
                        it claim any affiliation with these third parties. If you decide to leave the
                        Website and navigate to Third Party Websites, or install any applications,
                        software or download content from any such websites, you do so at your own risk.
                        Once you access a Third Party Website through a link on the Website, you are no
                        longer covered by Kaly’ Privacy Policy or Mandatory Terms of Use of the
                        Website, and you may be subject to the Terms and Conditions and Privacy Policy of
                        such Third Party Website. You should review the applicable terms and policies,
                        including privacy and data gathering practices, of any site to which you navigate
                        to from the Website, or relating to any applications you use or install from such
                        Third Party Websites. Concerns regarding a Third Party Website should be directed
                        to the Third Party Website itself. Kaly bears no responsibility for any action
                        associated with any Third Party Website.
                    </div>
                    <div className="para text-bold">ENFORCEMENT OF THE AGREEMENT</div>
                    <div className="para">
                        Kaly may investigate any reported, alleged or suspected violation of this
                        Agreement, and take any action that Kaly, in its sole discretion, deems
                        appropriate. Such action may include issuing warnings, suspension of a User’s
                        access to the Website or complete termination of such access, at any time.
                        Kaly reserves the right to recover those amounts spent in connection with
                        enforcement, from any violation of these terms.
                    </div>
                    <div className="para text-bold">TERMINATION</div>
                    <div className="para">
                        Either you or Kaly may terminate this Agreement at any time, for cause or
                        without cause. Without limiting the foregoing rights, Kaly has a policy of
                        terminating Users who are repeat violators of the United States Copyright Act, as
                        noted in the Copyright and Trademark Policy.
                    </div>
                    <div className="para">
                        All grants of any rights from you to Kaly related to content, Submissions, or
                        other materials, including but not limited to copyright or other intellectual
                        property licenses shall survive any termination of this Agreement.
                    </div>
                    <div className="para text-bold">
                        LIMITATION OF LIABILITY AND DISCLAIMER OF WARRANTIES
                    </div>
                    <div className="para">
                        THE WEBSITE, INCLUDING ALL CONTENT, SOFTWARE, FUNCTIONS, MATERIALS AND INFORMATION
                        MADE AVAILABLE THROUGH THE WEBSITE IS PROVIDED “AS IS.” KALY MAKES NO
                        REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER, WHETHER EXPRESSED OR
                        IMPLIED, FOR THE WEBSITE AND SERVICES PROVIDED IN CONNECTION WITH THE WEBSITE. IN
                        NO EVENT SHALL KALY, ITS SUPPLIERS OR OTHER THIRD PARTIES MENTIONED ON THE
                        WEBSITE BE LIABLE FOR ANY DAMAGES WHATSOEVER RESULTING FROM YOUR USE OR ANY OTHER
                        INDIVIDUAL’S USE OF OR ACCESS TO THE WEBSITE OR ANY GOODS OR SERVICES CONTAINED
                        HEREON. KALY DISCLAIMS ANY AND ALL LIABILITY FROM ANY MALFUNCTIONS,
                        LIMITATIONS ON ACCESS OR INTERRUPTIONS IN AND TO THE WEBSITE.
                    </div>
                    <div className="para">
                        KALY DOES NOT WARRANT THAT:
                        <ol>
                            <li>ACCESS TO THE WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE;</li>
                            <li>
                                ANY INFORMATION TRANSMITTED BY KALY TO ANY INDIVIDUAL VIA THE WEBSITE IS
                                ACCURATE OR EFFECTIVE;
                            </li>
                            <li>YOUR USE OF THE WEBSITE WILL NOT VIOLATE THE RIGHTS OF OTHERS.</li>
                        </ol>
                        FURTHER, NEITHER KALY, NOR ANY OTHER PARTY INVOLVED IN THE CREATION,
                        PRODUCTION OR HOSTING OF THE WEBSITE SHALL BE LIABLE FOR ANY DIRECT, INDIRECT,
                        INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES ARISING OUT OF YOUR USE
                        AND/OR ACCESS TO THE WEBSITE OR ANY GOODS OR SERVICES CONTAINED HEREON. YOUR SOLE
                        REMEDY FOR DISSATISFACTION WITH THE WEBSITE OR THE MATERIALS IS TO STOP USING THE
                        WEBSITE OR THE MATERIALS.
                    </div>
                    <div className="para">
                        KALY HAS NO OBLIGATION TO VERIFY THE IDENTITY OF ANY USER UTILIZING THE
                        WEBSITE OR THE SERVICES AVAILABLE THEREON, NOR DOES KALY HAVE ANY OBLIGATION
                        TO MONITOR THE USE OF THE WEBSITE BY OTHER USERS. KALY WILL NOT BE HELD LIABLE
                        FOR IDENTITY THEFT OR ANY OTHER MISUSE OF YOUR IDENTITY OR INFORMATION.
                    </div>
                    <div className="para">
                        WITHOUT LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT PERMISSIBLE UNDER LAW,
                        THE WEBSITE INCLUDING WITHOUT LIMITATION ALL CONTENT, SOFTWARE, FUNCTIONS,
                        SERVICES, FEATURES, MATERIALS AND INFORMATION MADE AVAILABLE THROUGH THE WEBSITE
                        IS PROVIDED TO YOU “AS IS” AND KALY SPECIFICALLY DISCLAIMS ALL WARRANTIES,
                        EXPRESSED OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT WITH RESPECT
                        TO THE WEBSITE, ITS CONTENTS, AND ANY GOODS OR SERVICE PROVIDED VIA THE WEBSITE.
                        IF YOU ARE DISSATISFIED OR HARMED BY KALY, THE WEBSITE OR ANYTHING RELATED TO
                        KALY, YOU MAY LEAVE KALY AND USE OF THE WEBSITE AND TERMINATE THE
                        AGREEMENT AND SUCH TERMINATION SHALL BE YOUR SOLE AND EXCLUSIVE REMEDY.
                    </div>
                    <div className="para">
                        Notwithstanding the foregoing, in the event that a court shall find that the above
                        disclaimers are not enforceable, the Parties agree that neither Kaly nor any
                        of its subsidiaries, affiliated companies, employees, shareholders, or directors
                        shall be liable or responsible for any direct, indirect, incidental, punitive,
                        special, exemplary, consequential or other damages or loss of use, lost revenue,
                        lost profits or data to you or any third party arising out of or in any way
                        related to your use of the Website or any services, Submissions, Materials or
                        other content or materials on, accessed through or downloaded from the Website,
                        even if Kaly is aware or has been advised of the possibility of such damages.
                        Your sole and exclusive remedy for your dissatisfaction with the Website or the
                        Materials is to terminate your usage of the Website. This limitation shall apply
                        regardless of the basis of your claim or whether or not the limited remedies
                        provided herein fail of their essential purpose. This limitation shall not apply
                        to any damage that Kaly causes you intentionally and knowingly in violation of
                        this Agreement or applicable law that cannot be disclaimed in this Agreement.
                    </div>
                    <div className="para text-bold">INDEMNITY</div>
                    <div className="para">
                        You agree to defend, indemnify and hold harmless Kaly, its affiliates and its
                        suppliers, and each of their respective partners, employees, representatives and
                        agents, from and against all claims, losses, costs, damages, liabilities and
                        expenses (including, but not limited to, reasonable attorneys’ fees) arising out
                        of: (a) Your activities in connection with the Website, including without
                        limitation your use of the Communication Services and/or your provision of any
                        Submission or other material in connection with the Website; (b) Any violation of
                        this Agreement by you; (c) Any improper or unauthorized use of the Website,
                        Communication Services, and/or Materials by you; (d) Any conduct, activity or
                        action which is unlawful or illegal under any state, federal or common law, or
                        violative of the rights of any individual or entity, engaged in, caused by, or
                        facilitated in any way through the Website or the use of software or information
                        which appears on the Website; and (e) Any allegation that anything you transmit or
                        attempt to transmit, including but not limited to any content or Submission you
                        submit, post, or otherwise make available, through or in connection with the
                        Website, infringes or otherwise violates the intellectual property, privacy or
                        other rights of any third party.
                    </div>
                    <div className="para text-bold">INCORPORATION OF PRIVACY POLICY</div>
                    <div className="para">
                        We use your information only as described in the Privacy Policy, which is
                        expressly incorporated as terms of this Agreement. If you object to anything in
                        the Privacy Policy, please do not use our services, or contact us directly to
                        determine whether a special exemption or modification may be warranted.
                        Exemptions, if any, can only be granted by an executive officer of Kaly, and
                        will be done in writing. Any verbal exemption or an exemption by an individual who
                        is not an executive officer of Kaly shall be void and ineffective.
                    </div>
                    <div className="para text-bold">CHOICE OF LAW AND JURISDICTION</div>
                    <div className="para">
                        Unless otherwise specified, the Website and its contents are provided solely for
                        providing information. The Website is controlled and operated by Kaly from New
                        Jersey, in the United States of America.
                    </div>
                    <div className="para text-bold">
                        All disputes with Kaly will be venued within the exclusive jurisdiction of the
                        State of New Jersey.
                    </div>
                    <div className="para">
                        You agree that any dispute arising under the Agreement or your use of the Website
                        in any way shall be resolved exclusively by New Jersey state or federal courts,
                        applying New Jersey law, regardless of any principles of conflicts of law. By your
                        use, you waive any jurisdictional, venue or inconvenient forum objections to such
                        courts for purposes of resolving these disputes.
                    </div>
                    <div className="para">
                        Although the Website may be available worldwide, you understand and agree that the
                        law of your home jurisdiction may provide protections not provided in the United
                        States, and that by visiting the Website, you unequivocally and unambiguously
                        agree to waive your right to suit in your home jurisdiction and to submit and
                        adjudicate any disputes which arise out of such use pursuant to the terms and
                        conditions stated in this Choice of Law and Jurisdiction clause, and United States
                        law will apply.
                    </div>
                    <div className="para">
                        At Kaly’ sole discretion, it may require you to submit any disputes arising
                        from the use of the Mandatory Terms or use of the website, including disputes
                        arising from or concerning their interpretation, violation, invalidity,
                        non-performance, or termination, to final and binding arbitration under the Rules
                        of Arbitration of the American Arbitration Association applying New Jersey law.
                        Arbitration shall be held in New Jersey.
                    </div>
                    <div className="para text-bold">EXPORT CONTROL</div>
                    <div className="para">
                        Your use of the Website, including any services thereon, is subject to export and
                        re-export control laws and regulations, including the Export Administration
                        Regulations (“EAR”) maintained by the United States Department of Commerce and any
                        programs maintained by the Treasury Department’s Office of Foreign Assets Control.
                        You acknowledge that you may not export, re-export, sell, divert, transfer or
                        otherwise dispose of any software or service to any end-user without obtaining
                        required authorizations. You also warrant that you are not prohibited from
                        receiving US origin products, including but not limited to those that may appear
                        on the Website.
                    </div>
                    <div className="para text-bold">GENERAL TERMS</div>
                    <div className="para">
                        SEVERABILITY
                        <br />
                        If any provision of this Agreement is found for any reason to be unlawful, void or
                        unenforceable, then that provision will be given its maximum enforceable effect,
                        or shall be deemed severable from this Agreement and will not affect the validity
                        and enforceability of any remaining provision.
                    </div>
                    <div className="para">
                        ENTIRE AGREEMENT AND AMENDMENTS
                        <br />
                        This Agreement, and its incorporated agreements, constitutes the entire agreement
                        between you and Kaly regarding your use and interaction with the Website. This
                        Agreement supersedes any and all prior agreements related to this topic, whether
                        written or oral. Any amendments to this Agreement by you must be in writing, and
                        Kaly shall not be bound to any action of activity that it does not so bind
                        itself to do in writing. Kaly may modify this Agreement at any time by posting
                        modified terms to the Website, and all such terms will be binding prospectively,
                        but not retroactively, upon posting. We will post the Effective Date so that you
                        can see the date that the terms were last updated.
                    </div>
                    <div className="para">
                        NOTICES
                        <br />
                        All notices to you under this Agreement may be provided via e-mail or any other
                        communications means you provide to the Website. All notices from you to Kaly
                        must be sent to the following address by mail with a return receipt requested or
                        email. Any notices that are not sent to this address will be given no legal
                        effect.
                    </div>
                    <div className="para">
                        {' '}
                        Kaly, LLC <br />
                        c/o Wilson Elser <br />
                        Attn: Neil Prupis <br />
                        200 Campus Drive <br />
                        Florham Park, NJ 07932 <br />
                        <br />
                        Or <br />
                        <br />
                        <a href="mailto:hello@kaly.com">hello@kaly.com</a>
                    </div>
                    <div className="para">
                        WAIVER
                        <br />
                        Any failure by Kaly to enforce any term of this Agreement against you or any
                        other user shall not be considered a waiver of Kaly’ right to enforce this
                        Agreement as to any future violations committed by you or any other User.
                    </div>
                    <div className="para">
                        ASSIGNMENT
                        <br />
                        This Agreement is personal to you, and you have no right to assign this Agreement
                        to any party. Kaly may assign all of part of this Agreement to any party, at
                        any time. Kaly may substitute itself, by way of unilateral novation, effective
                        upon notice to you or upon posting to the Website, for any third party that
                        assumes Kaly’ rights and obligations under this Agreement.
                    </div>
                    <div className="para">{moment().year()}, Kaly, LLC, All rights reserved.</div>
                    <div className="sub-header-title title-txt new-section">COPYRIGHT POLICY</div>
                    <div className="sub-header-title">Effective Date: December 1, 2020</div>
                    <div className="para">
                        Kaly respects the intellectual property rights of others. If you believe in
                        good faith that any content, Submission, or other material posted or otherwise
                        available on the Website infringes the copyright in your work, please contact our
                        copyright agent in writing, designated under the Digital Millennium Copyright Act
                        (“DMCA”) (17 U.S.C. §512(c)(3)), with correspondence containing the following:
                    </div>
                    <div className="para">
                        <ol>
                            <li>
                                A physical or electronic signature of the owner, or a person authorized to act
                                on behalf of the owner, of the copyrighted work that is allegedly infringed;
                            </li>
                            <li>Identification of the copyrighted work claimed to have been infringed;</li>
                            <li>
                                Identification of the material that is claimed to be infringing together with
                                information reasonably sufficient to allow us to identify its location on the
                                Website;
                            </li>
                            <li>Information reasonably sufficient to permit Kaly to contact you;</li>
                            <li>
                                A statement that you have a good faith belief that use of the material in the
                                manner complained of is not authorized by the copyright owner, its agent, or
                                the law; and
                            </li>
                            <li>
                                A statement that the information in the notification is accurate, and under
                                penalty of perjury, that you are authorized to act on behalf of the owner of
                                an exclusive right that is allegedly infringed.
                            </li>
                        </ol>
                    </div>
                    <div className="para">
                        You acknowledge that if you fail to comply with all of the requirements of this
                        Section, your DMCA notice may not be valid. For any questions regarding this
                        procedure, or to submit a complaint, please contact Kaly’ designated DMCA
                        Copyright Agent:
                    </div>
                    <div className="para">
                        <a href="mailto:hello@kaly.com">hello@kaly.com</a>
                    </div>
                    <div className="para">
                        We will review and address all notices that comply with the requirements above. If
                        we remove or disable access in response to such a notice, we may notify the owner
                        or administrator of the affected site or content so that he or she can make a
                        counter notification.
                    </div>
                    <div className="para">© {moment().year()}, Kaly, LLC. All rights reserved.</div>
                    <div className="sub-header-title title-txt new-section">
                        COMPLAINT POLICY (INCLUDING TRADEMARK AND PRIVACY)
                    </div>
                    <div className="sub-header-title">Effective Date: December 1, 2020</div>
                    <div className="para">
                        If you believe in good faith that any content, Submission, or other material
                        posted on the Website infringes any of your rights other than in copyright (which
                        are subject to the Copyright Policy), or is otherwise unlawful, you must send a
                        notice to hello@kaly.com containing the following information:
                    </div>
                    <div className="para">
                        <ol>
                            <li>Your name, physical address, e-mail address and phone number;</li>
                            <li>
                                A description of the content, Submission, or other material posted on the
                                Website that you believe violates your rights or is otherwise unlawful, and
                                which parts of said materials you believe should be remedied or removed;
                            </li>
                            <li>
                                Identification of the location of the material on the Website. Including the
                                URL (the location as provided in your browser bar);
                            </li>
                            <li>
                                If you believe that the material violates your rights, a statement as to the
                                basis of the rights that you claim are violated;
                            </li>
                            <li>
                                If you believe that the material is unlawful or violates the rights of others,
                                a statement as to the basis of this belief;
                            </li>
                            <li>
                                A statement under penalty of perjury that you have a good faith belief that
                                use of the material in the manner complained of is not authorized and that the
                                information you are providing is accurate to the best of your knowledge and in
                                good faith; and,
                            </li>
                            <li>Your physical or electronic signature.</li>
                        </ol>
                    </div>
                    <div className="para">
                        If we receive a message that complies with all of these requirements, we will
                        evaluate the submission, and if appropriate, in Kaly’ sole discretion,
                        Kaly will take action. We may disclose your submission to the poster of the
                        claimed violative material, or any other party. However, Kaly does not
                        guarantee that it will comply with all requests not required by law, and reserves
                        the right, in its sole discretion, to determine the course of conduct, if any, it
                        chooses to take.
                    </div>
                    <div className="para">© {moment().year()}, Kaly, LLC. All rights reserved.</div>
                </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Terms;
