import React, {useContext, useState} from 'react';
import images from 'data/images';
import Overlay from 'components/Overlay';
import AuthContext from 'context/AuthContext';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import rolePermission from 'data/rolePermission';
import _ from 'lodash';
import './StaffItem.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';

const {modalFunctions} = modalConfig;
const {confirmModalData} = modalFunctions;
const {helperFunctions, permissionList} = rolePermission;
const {getAllMyRoleIds, hasPermission} = helperFunctions;
const {Analytics} = analyticsData;

const StaffItem = props => {
  const {data, loggedInProviderId, onStaffStatusChange, onDeleteStaff} = props;
  const {providerId, fullName, status, roleName, image} = data;
  const authContext = useContext(AuthContext);
  const auth = _.get(authContext, 'auth');
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;

  const confirmRemove = () => {
    setGlobal(
      'modal',
      confirmModalData({
        confirmMessage: `Are you sure you want to delete staff member?`,
        onConfirm: isConfirmed => {
          if (isConfirmed) {
            onDeleteStaff(providerId);
          }
        },
      }),
    );
  };

  let statusObj = {
    statusName: 'Pending',
    statusClass: 'pending',
  };
  if (status === 1) {
    statusObj = {
      statusName: 'Active',
      statusClass: 'active',
    };
  } else if (status === 2) {
    statusObj = {
      statusName: 'Inactive',
      statusClass: 'inactive',
    };
  }
  const [active, toggleActive] = useState(false);
  const handleStatusChange = () => {
    Nux.sendEvent(
      Analytics.Category.DoctorSettingStaff,
      Analytics.Actions.Click,
      'staffStatusChange',
    );
    toggleActive(!active);
    onStaffStatusChange({staffId: providerId, status: status === 1 ? 0 : 1});
  };
  const roleIds = getAllMyRoleIds(auth);
  const canEditStaff = hasPermission(roleIds, permissionList.CAN_EDIT_STAFF);
  return (
    <div className="StaffItem">
      <div className="staff-inner-content">
        <div className="staff-image">
          <img src={image} alt="" />
        </div>
        <div className="member-detail">
          <span className="name">{fullName}</span>
          <div className="status">
            <div className={`status-text ${statusObj.statusClass}`}>{statusObj.statusName}</div> .{' '}
            {roleName}
          </div>
        </div>
      </div>
      <Overlay className="overlay" show={active} onClick={() => toggleActive(!active)} />
      {canEditStaff && loggedInProviderId !== providerId && (
        <span
          onClick={() => toggleActive(!active)}
          className={active ? 'show-menu dot-icon' : 'dot-icon'}>
          <img src={images.threeDot} alt="" />
          <span className="dropdown-menu">
            {status !== 0 && (
              <span onClick={handleStatusChange}>
                Move to {status === 1 ? 'Inactive' : 'Active'}
              </span>
            )}
            <span className="remove-user" onClick={confirmRemove}>
              Remove User
            </span>
          </span>
        </span>
      )}
    </div>
  );
};

export default StaffItem;
