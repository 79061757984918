const questionList = [
  {
    id: 4801,
    question: 'What type of dental alignment treatment are you looking for?',
    optionType: 0,
    questionType: 4,
    options: [
      {
        groupId: 'GOTO_LIST',
        procedureId: 75,
        label: `I am only interested in 'invisible' treatments, such as Invisalign.`,
        shortLabel: 'Invisible treatments',
      },
      {
        groupId: 'A3',
        label:
          'I am interested in seeing a dental specialist who offers all types of dental alignment treatments.',
        shortLabel: 'All types of dental alignments',
      },
    ],
    descriptions: [],
  },
];
export default {
  questionList,
  config: {
    firstQuestionId: 4801,
    lastQuestionId: 4801,
    treeDepth: 1,
  },
};
