import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import SpecialityFlow from 'containers/Patient/SpecialityFlow';
import ConfirmAppointment from 'containers/Patient/ConfirmAppointment';
import rolePermission from 'data/rolePermission';
import DoctorListing from 'containers/Patient/DoctorListing';
import ConfirmAppointmentThanks from 'containers/Patient/ConfirmAppointmentThanks';

const {helperFunctions, permissionList} = rolePermission;
const {hasPermission, getAllMyRoleIds} = helperFunctions;

const PatientCommonRoutes = auth => {
  const roleIds = getAllMyRoleIds(auth);
  const isFrontEndUser = hasPermission(roleIds, permissionList.FRONTEND_USER);
  if (!auth || isFrontEndUser) {
    return [
      <Route
        key="doctors"
        exact
        path={'/doctors'}
        component={props => <DoctorListing {...props} />}
      />,
      <Route
        key="confirm-appointment"
        exact
        path={'/confirm-appointment'}
        component={props => <ConfirmAppointment {...props} />}
      />,
      <Route
        key="confirm-appointment-thanks"
        exact
        path={'/confirm-appointment-thanks'}
        component={props => <ConfirmAppointmentThanks {...props} />}
      />,
      <Route
        key="symptom-specialist"
        exact
        path={'/:flowType(symptom|specialist|doctor|procedure)/:type?/:name?'}
        component={props => <SpecialityFlow {...props} />}
      />,
      <Route key="login" exact path={'/patient/login'} render={() => <Redirect to="/" />} />,
      <Route exact key="sign-up" path={'/patient/sign-up'} render={() => <Redirect to="/" />} />,
    ];
  } else {
    return null;
  }
};

export default PatientCommonRoutes;
