import React from 'react';
import {Link} from 'react-router-dom';
import './CustomFooter.css';
import moment from 'moment';
import config from 'config/config';
import constants from 'data/constants';

const {StaticFooterPage} = constants;

const CustomFooter = props => {
  return (
    <div className="CustomFooter">
      <div className="container">
        <div className="footer-block">
          <div className="footer-menu-ul">
            <div className="menu-item-list">
              <a
                href={config.APP_DOMAIN + StaticFooterPage.TERMS_CONDITION}
                target="_blank"
                rel="noopener noreferrer">
                Terms & Conditions
              </a>
            </div>
            <div className="menu-item-list">
              <a
                href={config.APP_DOMAIN + StaticFooterPage.PRIVACY_POLICY}
                target="_blank"
                rel="noopener noreferrer">
                Privacy Policy
              </a>
            </div>
            <div className="menu-item-list">
              <a
                href={config.APP_DOMAIN + StaticFooterPage.HIPAA}
                target="_blank"
                rel="noopener noreferrer">
                HIPAA NPP
              </a>
            </div>
            <div className="menu-item-list">
              <a
                href={config.APP_DOMAIN + StaticFooterPage.CCPA}
                target="_blank"
                rel="noopener noreferrer">
                CCPA Policy
              </a>
            </div>
            <div className="menu-item-list">
              <a
                href={config.APP_DOMAIN + StaticFooterPage.HEALTHCARE}
                target="_blank"
                rel="noopener noreferrer">
                Healthcare Provider Agreement
              </a>
            </div>
            <div className="menu-item-list">
              <a
                href={config.APP_DOMAIN + StaticFooterPage.REVIEW_POLICY}
                target="_blank"
                rel="noopener noreferrer">
                Review Policy
              </a>
            </div>
            <div className="menu-item-list">
              <a
                href={config.APP_DOMAIN + StaticFooterPage.FAQ}
                target="_blank"
                rel="noopener noreferrer">
                FAQ
              </a>
            </div>
            <div className="menu-item-list">
              <a href={'https://www.kaly.com/blog/'} target="_blank" rel="noopener noreferrer">
                Blog
              </a>
            </div>
            <div className="menu-item-list">
              <a href={config.APP_DOMAIN + StaticFooterPage.COOKIEPOLICY} rel="noopener noreferrer">
                Cookie Policy
              </a>
            </div>
            <div className="menu-item-list">
              <a onClick={() => document.getElementById('c-s-bn').click()}>Manage Cookies</a>
            </div>
            <div className="menu-item-list">
              <a>
                <span
                  onClick={() => {
                    document.getElementById('accessibilityWidget').click();
                  }}
                  className="accessibilityWidget"
                  tabIndex="0">
                  Click for Accessibility
                </span>
              </a>
            </div>
          </div>
          <div style={{paddingTop: '10px'}} className="paragraph">
            ©{moment().year()} Kaly All right reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomFooter;
