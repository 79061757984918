import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import moment from 'moment';
import images from 'data/images';
import helper from 'data/helper';
import MyLink from 'components/MyLink';
import './Marker.css';

const {getSuffixString,setAppointmentSourceStore} = helper;

const Marker = props => {
  const {activeLocation, setActiveLocation} = props;
  const {locationDoctor, disableMarkerEvent, onTimeSelect} = props;
  const {address, locationId, doctors} = locationDoctor;
  const isCurerntLocationActive = activeLocation === locationId;
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setActiveIndex(0);
  }, [doctors]);

  const formatTime = av => {
    return moment(av.dateString + ' ' + av.timeObject.time, 'YYYY-MM-DD h:mm a').format(
      'ddd, MMM D',
    );
  };

  const isIndexValid = i => {
    return i >= 0 && i < doctors.length;
  };

  const nextIndex = activeIndex + 1;
  const isValidNextIndex = isIndexValid(nextIndex);
  const previousIndex = activeIndex - 1;
  const isValidPrevioustIndex = isIndexValid(previousIndex);

  const prevDoctor = () => {
    if (isIndexValid(previousIndex)) {
      changePosition(1);
      setActiveIndex(previousIndex);
    }
  };

  const changePosition = (number = -1) => {
    const cardElement = document.querySelector('.Marker .doctor-card');
    const containerElement = document.querySelector('.Marker .doctor-child-container');
    if (cardElement && containerElement) {
      containerElement.style.left = `${
        containerElement.offsetLeft + number * cardElement.offsetWidth
      }px`;
      containerElement.style.transition = 'all 1s';
    }
  };

  const nextDoctor = () => {
    if (isIndexValid(nextIndex)) {
      changePosition(-1);
      setActiveIndex(nextIndex);
    }
  };

  return (
    <div className="Marker">
      <img
        className="marker-image"
        onClick={() =>
          !disableMarkerEvent && setActiveLocation(isCurerntLocationActive ? null : locationId)
        }
        src={isCurerntLocationActive ? images.mapActive : images.map}
        alt=""
      />
      {!disableMarkerEvent && isCurerntLocationActive && (
        <div className="doctor-detail-map">
          {doctors.length > 1 && (
            <div className="doctor-slider">
              <div>
                {activeIndex + 1} of {doctors.length}
              </div>
              <div
                className={`pagination-div ${isValidPrevioustIndex ? '' : 'disabled'}`}
                onClick={prevDoctor}>{`<`}</div>
              <div
                className={`pagination-div ${isValidNextIndex ? '' : 'disabled'}`}
                onClick={nextDoctor}>{`>`}</div>
            </div>
          )}
          <div className="doctor-container">
            <div className="doctor-child-container">
              {doctors.map((doctor, index) => {
                const {
                  userId,
                  name,
                  seoPageUrl,
                  profileImageUrl,
                  suffixes = [],
                  specialties = [],
                  totalRating,
                  formatedAvailabilities,
                  customSpecialty=null,
                } = doctor;
                let firstAvailability = null;
                _.forEach(formatedAvailabilities, (availabilityList, dateString) => {
                  if (availabilityList.length > 0) {
                    firstAvailability = {
                      dateString,
                      timeObject: availabilityList[0],
                    };
                    return false;
                  }
                });
                let specialtyNames = _.map(specialties, 'specialty');
                if(customSpecialty){
                  specialtyNames=[...specialtyNames,customSpecialty]
                }
                return (
                  <div data-user-id={userId} key={userId} className={`doctor-card`}>
                    <div className="dc-img">
                      <img src={profileImageUrl || images.user} alt="doctor" />
                    </div>
                    <div className="dc-info">
                      <div className="dc-content">
                        <div className="dc-details">
                          <div className="dc-name">
                            {name}
                            {getSuffixString(suffixes)}
                          </div>
                          <div className="dc-specialist">{specialtyNames.join(', ')}</div>
                          <div className="dc-address">{address}</div>
                        </div>
                        <div className="dc-rating">
                          <img src={images.star} alt="rating" />
                          <span className="rating-text">{totalRating}</span>
                        </div>
                      </div>
                      <div className="dc-action">
                        <MyLink onClick={()=>setAppointmentSourceStore(1)}  to={`${seoPageUrl}`}>View Profile</MyLink>
                        {/* <span>Available: Tue, Mar 7</span> */}
                        {firstAvailability && (
                          <span
                            onClick={() => {
                              onTimeSelect(firstAvailability, doctor);
                            }}>
                            Available: {formatTime(firstAvailability)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Marker;
