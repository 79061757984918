import React, { useContext, useState } from 'react';
import images from 'data/images';
import './ContactSourceList.css';
import _ from 'lodash';
import reviewService from 'services/review';
import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RootContext from 'context/RootContext';
import modalConfig from 'modals/modalConfig';
import ContactViewHistoryList from "../ContactViewHistory";
import moment from 'moment';
import helper from 'data/helper';
import { DeleteConform } from 'containers/Admin/Practices/EditPracticeRegistration/DeleteConform';
const { formatPhoneNumberWithOutDash, formatPhoneNumberWithDash } = helper;
const { modalFunctions } = modalConfig;
const { blankModalData } = modalFunctions;
const { deleteContacts } = reviewService;
const ContactSourceList = props => {
  const {totalCount}=props
  const rootContext = useContext(RootContext);
  const [active, toggleActive] = useState(null);
  const [viewActionId, toggleViewAction] = useState(null);
  const deleteAction = async (id) => {
    //const {setGlobal} = rootContext;
    let deleteConfirm = await DeleteConform({
      context: rootContext,
      text: `Are you sure, you want to delete this contact?`,
    });
    if (!deleteConfirm) return false;
    let response = await deleteContacts(id);
    if (response) {
      toast.success('Contact deleted successfully')
      props.getContactList()
    }
  };
  const handleActions = id => {
    if (id !== viewActionId) {
      toggleViewAction(id);
      toggleActive(true);
    } else {
      toggleActive(!active);
    }
  };

  const handleSendRequest = (contactName, contactEmail, contactNumber,contact) => {
    const { sendContactRequest } = props;
    const reqData = {
      name: contactName,
      email: contactEmail,
      phoneNumber: contactNumber,//formatPhoneNumberWithDash(contactNumber),
      contact:contact
    };
    sendContactRequest(reqData);
  };
  const copyToClipboard = (str, type) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.style.width = '0px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success(`${type} has been copied to the clipboard.`);
  };
  const onViewHistory = contactId => {
    const { setGlobal } = rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ContactViewHistoryList,
        modalWrapperClass: 'mega-modal',
        customComponentProps: {
          data: {
            id:contactId,
          },
        },
      }),
    );
  };
  const getPhoneEmailData = (phone, email, id) => {
    if (phone && email) {
      return (
        <>
          <a data-for={`phone${id}`} data-tip={phone} >
            <img style={{ width: "20px", marginRight: "5px" }} src={images.phone} />
          </a>
          <a data-for={`email${id}`} data-tip data-iscapture="true" >
            {' '}
            <img style={{ width: "20px" }} alt="email" src={images.email} />
          </a>

          <ReactTooltip
            id={`phone${id}`}
            place={'bottom'}
            type={'info'}
            effect={'solid'}
            multiline={true}
            delayHide={100}
            delayUpdate={100}>
            {' '}
            <span className="copy-span">
              {phone}
              <span><img alt="copy-img" className='copy-img' onClick={() => copyToClipboard(phone, 'Phone number')} style={{ width: "20px", cursor: "pointer" }} src={images.copy} /></span>            </span>
          </ReactTooltip>
          <ReactTooltip
            id={`email${id}`}
            place={'bottom'}
            type={'info'}
            effect={'solid'}
            multiline={true}
            delayHide={100}
            delayUpdate={100}>
            {' '}
            <span className="copy-span">
              {email}
              {<img alt="copy-img" className='copy-img' onClick={() => copyToClipboard(email, 'Email')} style={{ width: "20px", cursor: "pointer" }} src={images.copy} />}
            </span>
          </ReactTooltip>
        </>
      );
    } else {
      // return <span title={phone ? phone : email}> {phone ? 'Phone' : 'Email'}</span>;
      return phone ? <> <a data-for={`phone${id}`} data-tip={phone} >
        <img alt="phone" style={{ width: "20px", marginRight: "5px" }} src={images.phone} />
      </a><ReactTooltip
        id={`phone${id}`}
        place={'bottom'}
        type={'info'}
        effect={'solid'}
        multiline={true}
        delayHide={100}
        delayUpdate={100}>
          {' '}
          <span className="copy-span">
            {phone}
            <span><img alt="copy-img" className='copy-img' onClick={() => copyToClipboard(phone, 'Phone number')} style={{ width: "20px", cursor: "pointer" }} src={images.copy} /></span>            </span>
        </ReactTooltip></> : <> <a data-for={`email${id}`} data-tip data-iscapture="true" >
          {' '}
          <img alt="email" style={{ width: "20px" }} src={images.email} />
        </a> <ReactTooltip
          id={`email${id}`}
          place={'bottom'}
          type={'info'}
          effect={'solid'}
          multiline={true}
          delayHide={100}
          delayUpdate={100}>
          {' '}
          <span className="copy-span">
            {email}
            {<img alt="copy-img" className='copy-img' onClick={() => copyToClipboard(email, 'Email')} style={{ width: "20px", cursor: "pointer" }} src={images.copy} />}
          </span>
        </ReactTooltip></>
    }
  };

  return (
    <div className="ContactSourceList" >
      <div className="rtable">
        <div className="rtable-row rtable-row--head">
          <div className="rtable-cell name-cell column-heading">Contact Name </div>
          <div className="rtable-cell name-cell column-heading">Phone / Email</div>

          <div className="rtable-cell name-cell column-heading">Created Date</div>
          <div className="rtable-cell name-cell column-heading"></div>
        </div>
        {props.contactData.map(contact => {
          const contactName = contact.lastName
            ? `${_.get(contact, 'firstName', '')} ${_.get(contact, 'lastName', '')}`
            : _.get(contact, 'firstName', '');
          return (
            <div className="rtable-row" key={contact.id}>
              <div className="rtable-cell name-cell content-cell">{contactName}</div>
              <div className="rtable-cell name-cell content-cell">
                {getPhoneEmailData(contact.phone, contact.email, contact.id)}
              </div>

              <div className="rtable-cell name-cell content-cell">{moment(contact.createdAt).format('MMM DD YYYY')}</div>
              <div className="rtable-cell name-cell content-cell">
                <div
                  className={`action-menu ${active && contact.id === viewActionId ? 'show-menu' : ''
                    }`}>
                  <span
                    className="action-btn"
                    onClick={() => {
                      handleActions(contact.id);
                    }}>
                    <img src={images.threeDot} alt="" />
                  </span>
                  <span className="dropdown-menu">
                    <span onClick={() => toggleViewAction(null)} className="close-btn">X</span>
                    <span onClick={() => { props.clickAddEditContact('Edit', contact); toggleViewAction(null); }}>Edit Contact</span>
                    <span onClick={() => { onViewHistory(contact.id); toggleViewAction(null); }} >
                      View History
                    </span>
                    <span onClick={() => { deleteAction(contact.id); toggleViewAction(null); }} className="remove-user">
                      Delete Contact
                    </span>
                  </span>
                </div>
                <div>
                  <button
                    className="send-btn"
                    onClick={() => handleSendRequest(contactName, contact.email, formatPhoneNumberWithOutDash(contact.phone),contact)}>
                    <img src={images.send} alt="" /> Send
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        {
          totalCount===0&&(
            <p className='nocontact'>No Contacts</p>
          )
        }
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ContactSourceList;
