import React, {Component} from 'react';
import Layout from 'layout/VideoLayout';
import images from 'data/images';
import Chat from 'twilio-chat';
import './DoctorVideoExit.css';
import scheduleService from 'services/schedule';
import _ from 'lodash';
import momentHelper from 'data/moment/momentHelper';
import AuthContext from 'context/AuthContext';
import RootContext from 'context/RootContext';
import ViewPatientOverlayModal from 'components/AfterAuth/Dashboard/ViewPatientOverlayModal';
import modalConfig from 'modals/modalConfig';
import PatientVisitDetails from './PatientVisitDetails';
import helper from 'data/helper';
import twillioService from 'services/twillio';
import moment from 'moment';

const {getTwilioToken} = twillioService;
const {getSuffixString} = helper;
const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;
const {getAllAppointments} = scheduleService;
const {utcToLocalMoment} = momentHelper;

const initialState = {
  upcomingAppointments: [],
  providerName: '',
  appointmentCount: 0,
  origin: '',
};

class DoctorVideoExit extends Component {
  static contextType = AuthContext;
  rootcontext = null;
  state = {...initialState};

  componentDidMount = () => {
    this.getProviderDetails();
    this.getAndSetUpcomingAppointments();
  };

  getProviderDetails = async () => {
    const {auth} = this.context;
    const firstName = _.get(auth, 'firstName', '');
    const lastName = _.get(auth, 'lastName', '');
    const suffixes = _.get(auth, 'provider.suffixes', []);
    const fullName = firstName + ' ' + lastName + getSuffixString(suffixes);
    this.setState({providerName: fullName});
  };

  getAndSetUpcomingAppointments = async () => {
    let today = moment();
    const upcomingAppointmentResponse = await getAllAppointments({type: 'upcoming'}).catch(
      error => null,
    );
    if (upcomingAppointmentResponse) {
      const {data} = upcomingAppointmentResponse;
      const upcomingAppointmentData = data.filter(
        appointment =>
          appointment.isVirtual &&
          appointment.status === 1 &&
          utcToLocalMoment(appointment.appointmentStartDateTime).isSame(today, 'day'),
      );
      this.setState({upcomingAppointments: [], appointmentCount: upcomingAppointmentData.length});
      this.getAppoinmentStatus(upcomingAppointmentData);
    }
  };

  getAppoinmentStatus = data => {
    const waitingList = data.map(appointment => {
      const id = _.get(appointment, 'id', '');
      return {...appointment, roomName: 'kalypsysRoom' + id, patientInRoom: false, isRejoin: false};
    });
    const query = new URLSearchParams(this.props.location.search);
    const origin = query.get('origin');
    const queryId = query.get('appointment') || 0;
    const {auth} = this.context;
    const firstName = _.get(auth, 'firstName', '');
    const lastName = _.get(auth, 'lastName', '');
    const fullName = `${firstName} ${lastName}`;
    waitingList.forEach(async appointment => {
      const id = _.get(appointment, 'id', '');
      if (id) {
        const room = 'kalypsysRoom' + id;
        let response = await getTwilioToken({
          identity: fullName,
          roomName: _.get(appointment, 'roomName', ''),
          appointmentId: id,
        });
        const {data: res} = response;
        this.getChatToken(res.token)
          .then(() => this.createChatClient(res.token))
          .then(client => this.checkChannelData(client, room, appointment, origin, queryId))
          .then(channel => this.channelCheck(channel, appointment, queryId))
          .catch(error => {
            console.log('error:' + error);
          });
      }
    });
  };

  getChatToken = token => {
    return new Promise((resolve, reject) => {
      // this.addMessage({body: `Connecting...`});
      resolve(token);
    });
  };

  createChatClient = token => {
    return new Promise((resolve, reject) => {
      resolve(new Chat.create(token));
    });
  };

  checkChannelData = (chatClient, roomName, appointment) => {
    return new Promise((resolve, reject) => {
      chatClient
        .getSubscribedChannels()
        .then(() => {
          chatClient
            .getChannelByUniqueName(roomName)
            .then(channel => {
              resolve(channel);
            })
            .catch(error => {
              this.setDefaultAppointment(appointment);
              console.log(error);
            });
        })
        .catch(error => {
          console.log('error:' + error);
          reject(Error('Could not get channel list.'));
        });
    });
  };

  setDefaultAppointment = appointment => {
    const {upcomingAppointments} = this.state;
    const appointmentList = [...upcomingAppointments];
    appointmentList.push({
      ...appointment,
      patientInRoom: false,
      isRejoin: false,
    });
    this.setState({upcomingAppointments: [...appointmentList]});
  };

  channelCheck = (channel, appointment, id) => {
    if (channel) {
      const fullName = `${_.get(appointment, 'patient.firstName', '')} ${_.get(
        appointment,
        'patient.lastName',
        '',
      )}`;
      const {upcomingAppointments} = this.state;
      const appointmentList = [...upcomingAppointments];
      channel
        .getMembers()
        .then(data => {
          const patientMember = data.filter(member => member.identity === fullName);
          if (patientMember && patientMember.length > 0) {
            appointmentList.push({
              ...appointment,
              patientInRoom: true,
              isRejoin: origin !== 'providermail' && id === appointment.id ? true : false,
            });
            this.setState({upcomingAppointments: [...appointmentList]});
          } else {
            this.setDefaultAppointment(appointment);
          }
        })
        .catch(() => {
          channel.getMembersCount().then(countMember => {
            appointmentList.push({
              ...appointment,
              patientInRoom: countMember && countMember > 0,
              isRejoin: origin !== 'providermail' && id === appointment.id ? true : false,
            });
            this.setState({upcomingAppointments: [...appointmentList]});
          });
        });
    }
  };

  openViewPatientOverlayModal = patientUserId => {
    const {setGlobal} = this.rootcontext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ViewPatientOverlayModal,
        modalWrapperClass: 'mega-modal',
        customComponentProps: {
          data: {
            patientUserId,
          },
        },
        callBackOnClose: () => {
          // refetchAppointmentEvents(appointmentId, 'modal');
          this.getAndSetUpcomingAppointments();
        },
      }),
    );
  };

  render() {
    const {upcomingAppointments = [], providerName, appointmentCount} = this.state;
    const upcomingData = _.orderBy(upcomingAppointments, ['appointmentStartDateTime'], ['asc']);
    return (
      <Layout>
        <RootContext.Consumer>
          {context => {
            this.rootcontext = context;
          }}
        </RootContext.Consumer>
        <div className="DoctorVideoExit">
          <div className="sub-heading">Kaly Telemedicine Room</div>
          <div className="heading2">{providerName}</div>
          <div className="patient-list-block">
            <div className="patient-heading">
              <img className="person-img" src={images.personBlue} alt="person-img" />
              Patients in Waiting Rooms
            </div>
            <div className="patient-list-wrapper">
              <div className="PatientVisitDetails">
                {appointmentCount > 0 && upcomingData.length === 0 && <span>Connecting..</span>}
                {appointmentCount === 0 && <span>No Upcoming Appointment.</span>}
                {upcomingData.length > 0 &&
                  upcomingData.map(appointment => {
                    let firstname = _.get(appointment, 'patient.firstName', '');
                    let lastname = _.get(appointment, 'patient.lastName', '');
                    let fullname = `${firstname} ${lastname}`;
                    let appointmentStartDateTime = utcToLocalMoment(
                      appointment.appointmentStartDateTime,
                    );
                    let appointmentEndDateTime = utcToLocalMoment(
                      appointment.appointmentEndDateTime,
                    );
                    let appointmentId = _.get(appointment, 'id', '');
                    let patientUser = _.get(appointment, 'patient.user', '');
                    let patientUserId = _.get(patientUser, 'id', '');

                    return (
                      <PatientVisitDetails
                        key={appointmentId}
                        name={fullname}
                        onViewPatient={this.openViewPatientOverlayModal}
                        appointmentTime={appointmentStartDateTime.format('h:mm a')}
                        patientUserId={patientUserId}
                        appointmentId={appointmentId}
                        patientInRoom={appointment.patientInRoom}
                        isRejoin={appointment.isRejoin}
                        startTime={appointmentStartDateTime}
                        endTime={appointmentEndDateTime}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default DoctorVideoExit;
