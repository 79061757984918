import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Layout from 'layout';
import AuthContext from 'context/AuthContext';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Checkbox from 'components/FormControls/Checkbox';
import userService from 'services/user';
import images from 'data/images';
import validators from 'data/validators';
import modalConfig from 'modals/modalConfig';
import './Login.css';

const {signIn} = userService;
const {errorModalData} = modalConfig.modalFunctions;
const {__required, __email} = validators;

class Login extends Component {
  static contextType = AuthContext;
  
  rootContext = null;
  state = {
    isProcessing: false,
    values: {
      type: 'provider',
      email: '',
      password: '',
      rememberMe: false,
    },
    errors: {},
  };
setvallocal=(key,value)=>{
  return Promise.resolve().then(function () {
    localStorage.setItem(key, value);
});
}
  loginHandler = async () => {
    const {updateAuthStatus} = this.context;
    const {history} = this.props;
    const {values: formValues} = this.state;
    this.setState({isProcessing: true});
    localStorage.setItem('showreviewLogin','Y');
    const response = await signIn(formValues).catch(this.handleError);
    this.setState({isProcessing: false});
    if (response) {
      const {data} = response;
      const {accessToken, refreshToken} = data;
      await this.setvallocal('accessToken', accessToken)
      await this.setvallocal('refreshToken', refreshToken)
    //  localStorage.setItem('accessToken', accessToken);
     // localStorage.setItem('refreshToken', refreshToken);
     await updateAuthStatus(true);
      let urlList = window.location.href.split('goto=');
      if (urlList.length === 2) {
        // const url = new URL(urlList[1]);
        // let pathName=url.pathname.replace('/app','')
        // pathName=pathName===""?'/':pathName

        //window.location.href = urlList[1]
     //   setTimeout(() => {
          //window.location.href = urlList[1]
       //   history.push({pathname: pathName, search: url.search, hash: url.hash});
      //  }, 1000);
      //  history.push({pathname: pathName, search: url.search, hash: url.hash});
        
      } else {
        history.push({
          pathname: '/',
        });
      }
    }
  };

  handleError = error => {
    const {setGlobal} = this.rootContext;
    let {message} = error;
    if(message==="Invalid credentials.")
    {
      message=<p>The username or password was not recognized.  If you are a patient, please log in with the <Link onClick={()=>{
        setGlobal('modal',{errorModalData, showModal: false});
      }} to="/patient/login" >Patient Login</Link>.</p>
    }
    let objOp={}

    if(message=='Your account has been locked.  Please try again later, reset your password, or contact support.')
    {
      
      objOp={
        closeButtonText: 'Ok'
      }
    }
    setGlobal('modal', errorModalData(message,{...objOp}));
    return null;
  };

  // validation methods
  __validateEmail = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  __validatePassword = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['password'];
    if (!__required(fValue)) {
      return 'Password is required';
    }
    return null;
  };
  __validateForm = () => {
    const validEmail = !this.__validateEmail();
    const validPassword = !this.__validatePassword();
    return validEmail && validPassword;
  };

  changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'email':
        errorObject = {
          [name]: this.__validateEmail(value),
        };
        break;
      case 'password':
        errorObject = {
          [name]: this.__validatePassword(value),
        };
        break;
        case 'rememberMe':
        localStorage.setItem("rememberMe",value)
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  render() {
    const {isProcessing, values: formValues, errors: formErrors} = this.state;
    const getGeneralInputProps = controlName => {
      return {
        onChange: this.changeHandler,
        onBlur: this.changeHandler,
        error: formErrors[controlName],
        value: formValues[controlName],
        name: controlName,
      };
    };

    const isValidForm = this.__validateForm();
    return (
      <Layout>
        <div className="Login">
          <div className="login-container">
            <h1 className="h3">Log in with email</h1>
            <form className="login-form" onSubmit={e => e.preventDefault()}>
              <Input {...getGeneralInputProps('email')} translationType="transform" label="Email" />
              <Input
                {...getGeneralInputProps('password')}
                type="password"
                translationType="transform"
                icon={images.eyeView}
                label="Password"
              />
              <div className="checkbox-block">
                <Checkbox {...getGeneralInputProps('rememberMe')}>keep me logged in</Checkbox>
                <Link to="/forgot-password" className="reset-link">
                  Reset Password?
                </Link>
              </div>
              <RootContext.Consumer>
                {context => {
                  this.rootContext = context;
                  return (
                    <div className="login-btn doctor-loginbtn">
                      <CircleButton
                        type="submit"
                        disabled={!isValidForm || isProcessing}
                        onClick={this.loginHandler}
                        btnType="whiteButton">
                        Log in
                      </CircleButton>
                    </div>
                  );
                }}
              </RootContext.Consumer>
              <div className="login-bottom">
                Patient?{' '}
                <Link to="/patient/login" replace>
                  Click here to login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Login;
