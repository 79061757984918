import axiosInstance from 'config/axios';
import _ from 'lodash';

const getMyFiles = (args={}) => {
  let paramsParse = [];
        if (Object.keys(args).length > 0) {
            paramsParse = Object.keys(args).map((i) => [`${i}=${args[i]}`]);
        }else{
          paramsParse=["pageLimit=150","pageSize=1"]
        }
  return axiosInstance.get(`v1/document?${paramsParse.join("&")}`);
};
const postMyFiles = (data) => {
  return axiosInstance.post('v1/document',data);
};
const postFileAdmin = (data) => {
  return axiosInstance.post('v1/admin/document',data);
};
const updateStatusUploadFile = (id,status) => {
  return axiosInstance.put(`v1/document/status/${id}/${status}`);
};
const deleteUploadFile = (id) => {
  return axiosInstance.delete(`v1/document/${id}`);
};
export default {
  getMyFiles,
  postMyFiles,
  updateStatusUploadFile,
  deleteUploadFile,
  postFileAdmin
};
