import React, {useState} from 'react';
import _ from 'lodash';
import './ReadMore.css';

const ReadMore = props => {
  const {textString, min = 50, readMoreText = 'read more', readLessText = 'read less'} = props;
  const [isShowReadMoreButton, hideReadMoreButton] = useState(true);
  let updatedTextString = _.trim(textString);
  const hasMoreText = updatedTextString.length > min;
  if (hasMoreText && isShowReadMoreButton) {
    updatedTextString = updatedTextString.substring(0, min) + ' ...';
  }
  let readMoreOrLessText = '';
  if (isShowReadMoreButton) {
    readMoreOrLessText = readMoreText;
  } else {
    readMoreOrLessText = readLessText;
  }

  return (
    <div className="ReadMore">
      <p className="review-pragraph">
        {updatedTextString}
        {hasMoreText && (
          <span className="readmore-link" onClick={() => hideReadMoreButton(!isShowReadMoreButton)}>
            {readMoreOrLessText}
          </span>
        )}
      </p>
    </div>
  );
};

export default ReadMore;
