const questionList = [
  {
    id: 574,
    question: 'What type of procedure are you looking for?',
    optionType: 3,
    procedureOptionIds: [107, 108, 109, 93, 92, 81, 110, 77, 80, 78],
    nextQuestionId: 575,
    descriptions: [],
  },
  {
    id: 575,
    question: 'Do you get nervous when you go to the dentist?',
    questionType: 4,
    optionType: 0,
    options: [
      {
        nextQuestionId: 576,
        label: 'Not at all',
        shortLabel: 'Not nervous',
      },
      {
        nextQuestionId: 576,
        label: 'A little bit',
        shortLabel: 'Very nervous',
      },
      {
        nextQuestionId: 576,
        label: 'Very nervous',
      },
    ],
    descriptions: [
      'Your answer may help your dentist accomodate you by scheduling you at an ideal time or potentially offering you other options that may help.',
    ],
  },
  {
    id: 576,
    question: 'Do you still want to see an Periodontist ?',
    descriptions: [
      'A Periodontist is a dentist (DMD/DDS) who completed a three year residency in Periodontal Disorders after completing four years of dental school. A Periodontist specializes in the prevention, diagnosis, and treatment of diseases that affect the gums.  They also specialize in dental implants.',
    ],
    title: 'Periodontist ',
    optionType: 0,
    questionType: 1,
    canDoList: [],
    options: [
      {groupId: 'A4', label: 'Yes, see Periodontist', isGoToSymptomFlow: false},
      {label: 'No, I am looking for something else', nextQuestionId: 2202, addExtraQuestion: 4},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 574,
    lastQuestionId: 576,
    treeDepth: 3,
  },
};
