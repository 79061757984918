import React, { Component, useRef } from 'react';

import constants from 'data/constants';
import _ from 'lodash';
import './Metrics.css';
import images from 'data/images';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Print } from './PrintBtn';
const { DefaultDebounceMSec } = constants;


const defineds = {

  startOfToday: moment().startOf('day').toDate(),
  endOfToday: moment().endOf('day').toDate(),
  startLast7day: moment().subtract(7, 'days').toDate(),
  startLast14day: moment().subtract(14, 'days').toDate(),
  startLast30day: moment().subtract(30, 'days').toDate(),
  LastYear: moment().subtract(365, 'days').toDate(),

};
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);


const Visualizations=(props)=> {
  // constructor(props) {
  //   super(props);
  //   this.calenderRef = React.createRef();
  //   this.componentRef = React.createRef();
  // }
 const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Practice Hits Over Time - ${props.name} (${props.range}) ${props.daysDifference+1} days`,
      },
    },
  };

  //static contextType = RootContext;
  
  


  // componentDidMount() {

  // //  this.getStateList();
  //   document.addEventListener('mousedown', this.handleClickOutside, true);
  //   let range=[ {
  //     startDate: defineds.startLast7day,
  //     endDate: new Date(),
  //     key: 'selection'
  //   }]
  //   this.setState({dateRange:range},()=>{
  //     this.setProviderList(false, true);
  //   })
  // }


  // componentWillUnmount=()=>{
  //   document.removeEventListener('mousedown', this.handleClickOutside, true);
  // }
  
 

  
 
  

  

  const closeModal = () => {
    const { setGlobal } =props
    setGlobal('modal', { showModal: false });
  };
 
  const getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(moment(dt).format('MMM DD'));
    }
    return arr;
  };
  const getWeeksArray = function (start, end) {
    for (var arr = {}, dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr[`Week ${moment(dt).isoWeek()}`]=[];
    }
    return Object.keys(arr);
  };
  const getMonthArray = function (start, end) {
    for (var arr = {}, dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr[`${moment(dt).format('MMM YYYY')}`]=[];
    }
    return Object.keys(arr);
  };
  const setxaxis = (daysDifference, data) => {
    let returnarray = [];
    if (daysDifference+1 <= 30) {
      returnarray = data.map((item) => moment(Object.keys(item)[0]).format('MMM DD'))
    } else if (daysDifference+1 <= 90) {
      returnarray = data.map((item) => `Week ${Object.keys(item)[0]}`)
    } else if (daysDifference+1 >= 91) {
      returnarray = data.map((item) => {
        let itemarray = Object.keys(item)[0].split('-')
        return `${moment(itemarray[1],'MM').format('MMM')} ${itemarray[0]}`
      })
    }
    return returnarray;
  }
    const { daysDifference, graphData } = props
    let labels = []
    labels = setxaxis(daysDifference, graphData)
    //labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
      labels,
      datasets: [
        {
          fill: false,
          label: 'Total Hits',
          data: graphData.map((item) => item[Object.keys(item)[0]].practiceProfileTotalHits),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          fill: false,
          label: 'Unique Device Hits',
          data: graphData.map((item) => item[Object.keys(item)[0]].practiceProfileUniqueDeviceGuid),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          fill: false,
          label: 'Appointment Conversions',
          data: graphData.map((item) => item[Object.keys(item)[0]].practiceProfileProfileAppointmentConversions),
          borderColor: 'rgb(3, 252, 7)',
          backgroundColor: 'rgba(3, 252, 7, 0.5)',
        },
        {
          fill: false,
          label: 'IOS App Hits',
          data: graphData.map((item) => item[Object.keys(item)[0]].practiceProfileTotalIosApphits),
          borderColor: 'rgb(255, 136, 0)',
          backgroundColor: 'rgba(255, 136, 0, 0.5)',
        },
        {
          fill: false,
          label: 'Android App Hits',
          data: graphData.map((item) => item[Object.keys(item)[0]].practiceProfileTotalAndroidApphits),
          borderColor: 'rgb(255, 187, 51)',
          backgroundColor: 'rgba(255, 187, 51, 0.5)',
        },
        {
          fill: false,
          label: 'Mobile Browser Hits',
          data: graphData.map((item) => item[Object.keys(item)[0]].practiceProfileTotalMobileBrowserhits),
          borderColor: 'rgb(170,102,204)',
          backgroundColor: 'rgba(170,102,204, 0.5)',
        },
        {
          fill: false,
          label: 'Desktop Browser Hits',
          data: graphData.map((item) => item[Object.keys(item)[0]].practiceProfileTotalDesktopBrowserhits),
          borderColor: 'rgb(0, 126, 51)',
          backgroundColor: 'rgba(0, 126, 51, 0.5)',
        },
      ],
    };
    // const chartRef = useRef<ChartJS<"line">>(null);
    return (

      <div className='Visualizations'>
        <div className="modal-content-header">
          <div className="h3">{`Practice Hits Over Time - ${props.name} (${props.range}) ${props.daysDifference+1} days`}</div>
          <div>
          <Print />
          <span className="close" onClick={closeModal}>
            <img src={images.close} alt="close" />
          </span>
          
          </div>
         
        </div>
       
        <div className='chartBody'>
          <Line  options={options} data={data} id="chartRefPractice"  />
        </div>

      </div>

    );
  
}

export default Visualizations;
