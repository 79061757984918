import React, {useState} from 'react';
import images from 'data/images';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import validators from 'data/validators';
import './PrompOTPModal.css';
import constants from 'data/constants';
import patientService from 'services/patient';
import helper from 'data/helper';
import allRegex from 'data/regex';
import {Link} from 'react-router-dom';
const {__required, __password, __phoneNumber} = validators;
const {DEFAULT_MESSAGES} = constants;
const {updatepatientphonenumberotp, verifyPatientPhoneNumberOtp, resendotpphonenumber} =
  patientService;
const {phonenumberRegex} = allRegex;
const {formatPhoneNumberWithDash} = helper;
const PrompOTPModal = props => {
  const {closeModal, callAfterPhoneNumberOtp, tempUserId} = props;
  const values = {otp: '', phoneNumber: ''};
  const errors = {};
  const [showUpdatePhoneNumber, setShowUpdatePhoneNumber] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [formData, setFormData] = useState({
    values,
    errors,
  });
  const {values: formValues, errors: formErrors} = formData;
  const __validatePhoneNumber = (value = null) => {
    const fValue = value !== null ? value : formValues['phoneNumber'];

    if (!fValue) return 'Phone number is required';
    if (fValue && !__phoneNumber(fValue)) {
      return 'Invalid Phone number';
    }
    if (fValue && !phonenumberRegex.test(formatPhoneNumberWithDash(fValue))) {
      return 'Invalid Phone number';
    }

    if (fValue && formatPhoneNumberWithDash(fValue).length > 2) {
      if (checkSameNumber(formatPhoneNumberWithDash(fValue).substring(0, 3))) {
        return 'Invalid Phone number';
      }
    }
    return null;
  };
  const checkSameNumber = num => {
    if (num[0] === num[1] && num[0] === num[2]) return true;
    return false;
  };
  // Validation functions
  const _validateOtp = (value = null) => {
    const fValue = value !== null ? value : formValues['otp'];
    if (!__required(fValue)) {
      return 'Security code is required';
    }

    return null;
  };
  const __validateForm = () => {
    if (!showUpdatePhoneNumber) {
      const validPassword = !_validateOtp();
      return validPassword;
    } else {
      const validPhoneNumber = !__validatePhoneNumber();
      return validPhoneNumber;
    }
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'otp':
        errorObject = {
          [name]: _validateOtp(value),
        };
        break;
      case 'phoneNumber':
        errorObject = {
          [name]: __validatePhoneNumber(value),
        };
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        ...errorObject,
      },
    });
  };

  const handleForm = () => {
    if (showUpdatePhoneNumber) {
      if (tempUserId > 0)
        updatepatientphonenumberotp({
          phoneNumber: formValues['phoneNumber'],
          userID: tempUserId,
        })
          .then(res => {
            setSuccessMsg(res.message);
            setTimeout(() => {
              setSuccessMsg('');
            }, 3000);
            setShowUpdatePhoneNumber(false);

            setFormData({
              values,
              errors,
            });
          })
          .catch(err => {
            let {message, customData} = err;
            if (customData && customData.showLogin === 1) {
              let passState = props.state ? {...props.state} : {};
              let fromReserveAppointment = props.state ? true : false;
              const loginStateToObj = {
                pathname: '/patient/login',
                state: {...passState, fromReserveAppointment: fromReserveAppointment},
              };
              message = (
                <p>
                  {message} please log in with the{' '}
                  <Link onClick={() => closeModal()} to={loginStateToObj}>
                    Patient Login
                  </Link>
                  .
                </p>
              );
              setErrorMsg(message);
            } else {
              setErrorMsg(err.message);
              setTimeout(() => {
                setErrorMsg('');
              }, 3000);
            }
          });
    } else {
      if (tempUserId > 0)
        verifyPatientPhoneNumberOtp({
          userOtp: formValues['otp'],
          userID: tempUserId,
          hasPhoneNumberVerified: true,
        })
          .then(res => {
            callAfterPhoneNumberOtp(res);
            closeModal();
          })
          .catch(err => {
            setErrorMsg(err.message);
            setTimeout(() => {
              setErrorMsg('');
            }, 3000);
          });
    }
    // onPasswordSave(formValues);
    // closeModal();
  };

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const resentCode = () => {
    resendotpphonenumber({userID: tempUserId})
      .then(res => {
        setSuccessMsg(res.message);
        setTimeout(() => {
          setSuccessMsg('');
        }, 3000);
      })
      .catch(err => {
        setErrorMsg(err.message);
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
      });
  };
  return (
    <div className="PrompOTPModal">
      {/* <div className="modal-content-header">
        <div className="h3">Verify your identity</div>
        <span className="close" onClick={closeModal}>
          <img src={images.close} alt="close" />
        </span>
      </div> */}
      <div className="modal-content-body">
        <div className="opttext">
          <img src={images.logoIcon} />
          <h3>Confirm your phone number</h3>
          <p className="opttext-para">Please enter the code we have sent to your number.</p>
        </div>
        {showUpdatePhoneNumber ? (
          <>
            {' '}
            <Input
              {...getGeneralInputProps('phoneNumber')}
              translationType="transform"
              label="Phone Number"
            />
            <div className="actiondiv">
              <span
                onClick={() => {
                  setShowUpdatePhoneNumber(false);
                  setFormData({
                    values,
                    errors,
                  });
                }}>
                Enter Security Code
              </span>
            </div>
          </>
        ) : (
          <>
            {' '}
            <Input
              {...getGeneralInputProps('otp')}
              type="text"
              translationType="transform"
              //icon={images.eyeView}
              label="Security Code"
              // containerClass="password-group"
            />
            <div className="actiondiv">
              <span
                onClick={() => {
                  setShowUpdatePhoneNumber(true);
                  setFormData({
                    values,
                    errors,
                  });
                }}>
                Update Phone Number
              </span>
              <span
                onClick={() => {
                  resentCode();
                  setFormData({
                    values,
                    errors,
                  });
                }}>
                Resend Code
              </span>
            </div>
          </>
        )}
        {errorMsg && <span className="error-span">{errorMsg}</span>}
        {successMsg && <span className="success-span">{successMsg}</span>}
      </div>
      <div className="modal-content-footer">
        <CircleButton disabled={!isValidForm} onClick={handleForm}>
          {showUpdatePhoneNumber ? 'Update' : 'Continue'}
        </CircleButton>
      </div>
    </div>
  );
};

export default PrompOTPModal;
