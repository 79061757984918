import React, {useState, useContext, useEffect} from 'react';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import validators from 'data/validators';
import images from 'data/images';
import './addStaff.css';
import staffService from 'services/staff';
import modalConfig from 'modals/modalConfig';
import _ from 'lodash';
import Select from 'components/FormControls/Select';
import { ToastContainer} from 'react-toastify';
import adminService from 'services/admin';
import Input from 'components/FormControls/Input';
import config from 'config/config';
import allRegex from 'data/regex';
const {__required, __maxLength, __email} = validators;
const {addStaff} = staffService;
const {modalFunctions} = modalConfig;
const {successModalData, errorModalData} = modalFunctions;
const {getAllPractices} = adminService;
const AddStaff = props => {
  const {data, updateSubmit} = props;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const subIndex = _.get(data, 'subIndex', null);
  const [practiceList, setPracticesList] = useState([]);
  const [formData, setFormData] = useState({
    values: {
      practiceId: _.get(data, 'practiceId', ''),
      lastName: _.get(data, 'lastName', ''),
      firstName: _.get(data, 'firstName', ''),
      email: _.get(data, 'email', ''),
    },
    errors: {serverError: null},
  });
  const {values: formValues, errors: formErrors} = formData;
  const {MAX_TEXT_LENGTH} = config;
  const {nameRegex} = allRegex;
  
  useEffect(() => {
    getAllPracticesFn();
  }, []);
  const getAllPracticesFn = async () => {
    let responce = await getAllPractices({limit: 999});
    if (responce && responce.data && responce.data.list) {
      let spl=responce.data.list.map((sp)=>{
        let returnData={...sp}
        if(returnData.name.length>40){
          returnData.name= returnData.name.substring(0, 40) + "...";
        }
       return returnData
      })
      setPracticesList(spl);
    }
  };
  const __validatePracticeId = (value = null) => {
    const fValue = value !== null ? value : formValues['practiceId'];
    if (!__required(fValue)) {
      return 'practice is required';
    }
    return null;
  };

  const __validateFirstName = (value = null) => {
    const fValue = value !== null ? value : formValues['firstName'];
    if (!__required(fValue)) {
      return 'First name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'First name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateLastName = (value = null) => {
    const fValue = value !== null ? value : formValues['lastName'];
    if (!__required(fValue)) {
      return 'Last name is required';
    }
    if (!nameRegex.test(fValue)) {
      return 'Last name is invalid';
    }
    if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
      return `Max ${MAX_TEXT_LENGTH} characters`;
    }
    return null;
  };
  const __validateEmail = (value = null) => {
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };

  const changeHandler = event => {
    let {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'practiceId':
        errorObject = {
          [name]: __validatePracticeId(value),
        };
        break;
      case 'firstName':
        errorObject = {
          [name]: __validateFirstName(value),
        };
        break;
      case 'lastName':
        errorObject = {
          [name]: __validateLastName(value),
        };
        break;
      case 'email':
        errorObject = {
          [name]: __validateEmail(value),
        };
        break;
      default:
        break;
    }

    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const closeModal = () => {
    setGlobal('modal', {showModal: false});
  };

  const __validateForm = () => {
    const validatePracticeId = !__validatePracticeId();
    const validateFirstName = !__validateFirstName();
    const validateLastName = !__validateLastName();
    const validateEmail=!__validateEmail();
    return validatePracticeId && validateFirstName && validateLastName && validateEmail;
  };

  const isValidateForm = __validateForm();

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="Close" title="Close" />
    </span>
  );

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">
        {subIndex !== null ? `Update Faq : ${_.get(data, 'plan', '')}` : 'Add New Staff'}
      </div>
      {crossIconComponent}
    </div>
  );

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  
  };
  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const {getAllStaff} = props;
    let response = null;
    let reqObj = {
      firstName: formValues['firstName'],
      lastName: formValues['lastName'],
      email: formValues['email'],
      practiceId: formValues['practiceId'],
    };
    // return
    if (subIndex !== null) {
     // updateSubmit(subIndex, reqObj);
      return;
      // response = await updateFaq(subIndex, reqObj).catch(handleError);
    } else {
      response = await addStaff(reqObj).catch(handleError);
    }
    if (response) {
      const {message} = response;
      setGlobal('modal', successModalData(message));
      getAllStaff();
    }
  };

  return (
    <div className="Addstaff no-select">
      <div className="modal-body-wrapper">
        {dialogHeader}
        <div className="dailog-body">
          <form onSubmit={handleSubmit}>
            <div>
              <Select
                {...getGeneralInputProps('practiceId')}
                options={practiceList}
                labelIndex="name"
                valueIndex="id"
                label="Practice"
              />
             
             <div className="form-group-2-colum">
                <Input type="text" {...getGeneralInputProps('firstName')} label="First name" />
                <Input type="text" {...getGeneralInputProps('lastName')} label="Last name" />
              </div>
            
               <Input type="email" {...getGeneralInputProps('email')} label="Email" />
            </div>
          </form>
        </div>
        <div className="action-btn">
          <CircleButton onClick={closeModal} btnType="secondaryButton">
            Cancel
          </CircleButton>
          <CircleButton btnType="whiteButton" disabled={!isValidateForm} onClick={handleSubmit}>
            {subIndex !== null ? 'Update' : 'Add'}
          </CircleButton>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};

export default AddStaff;
