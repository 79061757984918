import React, {useContext, useEffect, useState, useCallback} from 'react';
import _ from 'lodash';
import Input from 'components/FormControls/Input';
import CircleButton from 'components/CircleButton';
import Upload from 'components/FormControls/Upload';
//import Select from 'components/FormControls/Select';
import Selectsearch from 'components/FormControls/Select/Selectsearch'
import masterService from 'services/master';
import AuthContext from 'context/AuthContext';
import RootContext from 'context/RootContext';
import helper from 'data/helper';
import validators from 'data/validators';
import './PatientInsurance.css';

const {getAllInsurancePlan} = masterService;
const {__required, _scrollToView} = validators;
const {validateFile} = helper;

const insuranceTypeList = [
  {
    label: 'Medical',
    prefix: 'medical',
  },
  {
    label: 'Dental',
    prefix: 'dental',
  },
  {
    label: 'Vision',
    prefix: 'vision',
  },
];

const PatientInsurance = props => {
  const authContext = useContext(AuthContext);
  const rootContext = useContext(RootContext);
  const {setGlobal, insurancePlans} = rootContext;
  const {onInsuranceUpdate} = props;
  const auth = _.get(authContext, 'auth', {});
  const authInsurance = auth.insurance;

  useEffect(() => {
    const getAndSetInsurancePlans = async () => {
      if (!insurancePlans) {
        const insurancePlansResponse = await getAllInsurancePlan().catch(() => null);
        if (insurancePlansResponse) {
          const {data} = insurancePlansResponse;
          setGlobal('insurancePlans', data);
        }
      }
    };
    getAndSetInsurancePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInsurance = useCallback(() => {
    const obj = {};
    insuranceTypeList.forEach(item => {
      const {prefix} = item;
      obj[`${prefix}CompanyId`] = _.get(authInsurance, `${prefix}CompanyId`, '');
      obj[`${prefix}PlanId`] = _.get(authInsurance, `${prefix}PlanId`, '');
      obj[`${prefix}MemberId`] = _.get(authInsurance, `${prefix}MemberId`, '');
      obj[`${prefix}FrontPhotoUrl`] = _.get(authInsurance, `${prefix}FrontPhotoUrl`, '');
      obj[`${prefix}FrontPhoto`] = {name: _.get(authInsurance, `${prefix}FrontPhoto`, '')};
      obj[`${prefix}BackPhotoUrl`] = _.get(authInsurance, `${prefix}BackPhotoUrl`, '');
      obj[`${prefix}BackPhoto`] = {name: _.get(authInsurance, `${prefix}BackPhoto`, '')};
    });
    return {...obj};
  }, [authInsurance]);

  const [formData, setFormData] = useState(() => {
    const insuranceData = getInsurance();
    return {
      values: insuranceData,
      errors: {},
    };
  });
  const {values: formValues, errors: formErrors} = formData;

  useEffect(() => {
    if (authInsurance) {
      const insuranceData = getInsurance();
      setFormData(prevState => {
        return {
          ...prevState,
          values: {
            ...prevState.values,
            ...insuranceData,
          },
        };
      });
    }
  }, [authInsurance, getInsurance]);

  // Validation functions
  const __validateInsuranceCompany = (value = null, prefix) => {
    const fValue = value !== null ? value : formValues[`${prefix}CompanyId`];
    if (!__required(fValue)) {
      return 'Company is required';
    }
    return null;
  };
  const __validateInsurancePlan = (value = null, prefix) => {
    const fValue = value !== null ? value : formValues[`${prefix}PlanId`];
    if (!__required(fValue)) {
      return 'Plan is required';
    }
    return null;
  };
  const __validateInsuranceMemberId = (value = null, prefix) => {
    const fValue = value !== null ? value : formValues[`${prefix}MemberId`];
    if (!__required(fValue)) {
      return 'MemberId is required';
    }
    return null;
  };
  
  const __validateForm = () => {
    let validInsuranceCompany = true;
    let validInsurancePlan = true;
    let validInsuranceMemberId = true;
    insuranceTypeList.forEach(item => {
      if (item.prefix === 'medical') {
        if (__validateInsuranceCompany(null, item.prefix)) {
          validInsuranceCompany = false;
        }
        if (__validateInsurancePlan(null, item.prefix)) {
          validInsurancePlan = false;
        }
        if(__validateInsuranceMemberId(null,item.prefix)){
          validInsuranceMemberId=false
        }
      }else{
        if(formValues[`${item.prefix}CompanyId`] || formValues[`${item.prefix}PlanId`]){
          if(formValues[`${item.prefix}CompanyId`]){
            if (__validateInsurancePlan(null, item.prefix)) {
              validInsurancePlan = false;
            }
          }
          if(formValues[`${item.prefix}PlanId`] && __validateInsuranceMemberId(null,item.prefix)){
            validInsuranceMemberId=false
          }
        }
        
      }
    });
    return validInsuranceCompany && validInsurancePlan && validInsuranceMemberId;
  };
  const isValidForm = __validateForm();

  const changeHandler = event => {
    let {name, dataset} = event.target;
    let {value} = event.target;
    let errorObject = {
      [name]: null,
    };
    let updateValueObj = {};
    const {prefix} = dataset;
    switch (name) {
      case `CompanyId`:
        if (prefix === 'medical') {
          errorObject = {
            [`${prefix}${name}`]: __validateInsuranceCompany(value, prefix),
            [`${prefix}PlanId`]: __validateInsurancePlan('', prefix),
          };
        }else if(formValues[`${prefix}CompanyId`]){
          errorObject = {
            [`${prefix}PlanId`]: __validateInsurancePlan('', prefix),
          };
        }
        updateValueObj = {
          [`${prefix}PlanId`]: '',
          [`${prefix}MemberId`]: '',
        };
        break;
      case `PlanId`:
        if (prefix === 'medical') {
          errorObject = {
            [`${prefix}${name}`]: __validateInsurancePlan(value, prefix),
          };
        }else{
          if(formValues[`${prefix}CompanyId`]){           
            errorObject = {
              [`${prefix}${name}`]: __validateInsurancePlan(value, prefix),
            };
          }
        }
        updateValueObj = {};
        break;
        case 'MemberId':
          if (prefix === 'medical') {
          errorObject = {
            [`${prefix}${name}`]: __validateInsuranceMemberId(value, prefix),
          };
        }else{
          if(formValues[`${prefix}CompanyId`] && formValues[`${prefix}PlanId`]){
            errorObject = {
              [`${prefix}${name}`]: __validateInsuranceMemberId(value, prefix),
            };
          }
        }
          break;
      default:
        break;
    }
    // setFormData({
    //   ...formData,
    //   values: {
    //     ...formData.values,
    //     [`${prefix}${name}`]: value,
    //     ...updateValueObj,
    //   },
    //   errors: {
    //     ...formData.errors,
    //     ...errorObject,
    //   },
    // });
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [`${prefix}${name}`]: value,
        //  [name]: value,
          ...updateValueObj,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const getGeneralInputProps = (controlName, prefix) => {
    const newControlName = `${prefix}${controlName}`;
    return {
      'data-prefix': prefix,
      onChange: changeHandler,
      error: formErrors[newControlName],
      value: formValues[newControlName],
      name: controlName,
    };
  };

  const handleForm = () => {
    if (!isValidForm) {

    

      let valError = [{name:'CompanyId', prefix: 'medical'}, {name:'PlanId',prefix: 'medical'},{name:'MemberId',prefix: 'medical'},{name:'CompanyId', prefix: 'dental'}, {name:'PlanId',prefix: 'dental'},{name:'MemberId',prefix: 'dental'},{name:'CompanyId', prefix: 'vision'}, {name:'PlanId',prefix: 'vision'},{name:'MemberId',prefix: 'vision'}];
      valError.forEach(key => {
        changeHandler({
          target: {name: key.name, value: formValues[`${key.prefix}${key.name}`], dataset: {prefix: key.prefix}},
        });
      });
      setTimeout(() => {
        _scrollToView(`error-text`);
      }, 100);

      return false;
    }
    onInsuranceUpdate(formValues);
  };

  const handleFileSelection = async event => {
    const {files, name, dataset} = event.target;
    if (files.length > 0) {
      const newFile = files[0];
      if (await validateFile(newFile)) {
        const customEvent = {
          target: {
            name: `${name}`,
            value: newFile,
            dataset,
          },
        };
        changeHandler(customEvent);
      }
    }
  };
const clearItem=(prefix)=>{
  let tempValus={...formData.values}
  tempValus[`${prefix}CompanyId`] = ""
  tempValus[`${prefix}PlanId`] = ""
  tempValus[`${prefix}MemberId`] = ''
  tempValus[`${prefix}FrontPhotoUrl`] =''
  tempValus[`${prefix}FrontPhoto`] = {name: ''};
  tempValus[`${prefix}BackPhotoUrl`] =''
  tempValus[`${prefix}BackPhoto`] = {name:''};
  setFormData(prevState => {
   
    return {
      ...prevState,
      values: {
        ...prevState.values,
       ...tempValus,
     
      },
      errors: {
        ...prevState.errors,
        
      },
    };
  });
}
  const getInsuranceCompanyPlans = prefix => {
    let selectedInsuranceCompany = null;
    if (formValues[`${prefix}CompanyId`]) {
      selectedInsuranceCompany = _.find(insurancePlans, {id: formValues[`${prefix}CompanyId`]});
    }
    const plans = _.get(selectedInsuranceCompany, 'insurancePlans', []);
    return plans;
  };

  if (!insurancePlans) {
    return null;
  }

  const medicalLabelComponent = (
    <>
      {' '}
      <span>Member ID</span> 
      {/* <span className="light_text">(optional)</span>{' '} */}
    </>
  );

  return (
    <div className="PatientInsurance">
      <div className="h3">Insurance</div>
      <div className="PatientInsurance-inner">
        <form>
          {insuranceTypeList.map(item => {
            const {label, prefix} = item;
            return (
              <div key={prefix} className="PatientInsurance-block">
                <div className="heading2">{label}{prefix!=="medical" && (<span className="clear-btn" onClick={()=>clearItem(prefix)}>Clear</span>)}</div>
                <div className="form-row">
                  <Selectsearch
                    {...getGeneralInputProps(`CompanyId`, prefix)}
                    options={insurancePlans}
                    labelIndex="companyName"
                    valueIndex="id"
                    label="Carrier"
                    defaultOptionProp={{label: 'Select Carrier', value: ''}}
                    containerClass="column6"
                  />
                  <Selectsearch
                    {...getGeneralInputProps(`PlanId`, prefix)}
                    options={getInsuranceCompanyPlans(prefix)}
                    labelIndex="plan"
                    valueIndex="id"
                    className={`plan ${!formValues[`${prefix}CompanyId`] ? 'disable' : ''}`}
                    disabled={!formValues[`${prefix}CompanyId`]}
                    label="Plan"
                    defaultOptionProp={{label: 'Select Plan', value: ''}}
                    containerClass="column6"
                  />
                </div>
                <div className="form-row">
                  <Input
                    {...getGeneralInputProps(`MemberId`, prefix)}
                    label={medicalLabelComponent}
                    placeholder="Member ID"
                  />
                </div>
                <div className="form-row">
                  <div className="form-group column6">
                    <Upload
                      prefix={prefix}
                      name={`FrontPhoto`}
                      label="Front Photo"
                      onChange={handleFileSelection}
                      file={formValues[`${prefix}FrontPhoto`]}
                      fileUrl={formValues[`${prefix}FrontPhotoUrl`]}
                      error={formErrors[`${prefix}FrontPhoto`]}
                    />
                  </div>
                  <div className="form-group column6">
                    <Upload
                      prefix={prefix}
                      name={`BackPhoto`}
                      label="Back Photo"
                      onChange={handleFileSelection}
                      file={formValues[`${prefix}BackPhoto`]}
                      fileUrl={formValues[`${prefix}BackPhotoUrl`]}
                      error={formErrors[`${prefix}BackPhoto`]}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <CircleButton onClick={handleForm}>Save</CircleButton>
        </form>
      </div>
    </div>
  );
};

export default PatientInsurance;
