import React, {Component} from 'react';
import Header from 'layout/Header';
import './ReviewPolicy.css';

class ReviewPolicy extends Component {
  render() {
    return (
      <>
        <Header hideHeaderButtons={true}></Header>
        <div className="ReviewPolicy">
          <div className="container">
            <div className="template-inner">
              <div className="main-header">KALY REVIEW POLICY</div>
              <div className="template-content">
                <div className="para">
                  At Kaly, one of the most essential tools we provide our patients is our
                  comprehensive online review system. Single source online review sites tend to be
                  procured and edited. Reviews on Kaly are collated from multiple, industry
                  respected websites to provide a ‘360 degree’ online reputation that saves patients
                  significant time researching the provider.
                </div>
                <div className="para text-bold">FREQUENTLY ASKED QUESTIONS</div>
                <div className="para">
                  <ol>
                    <li>
                      <span>What are Kaly reviews?</span>
                      <div className="para">
                        Kaly reviews are generated only from patients who have seen the provider
                        by directly booking an appointment through the Kaly platform. This
                        ensures that the patient encounter actually occurred and prevents ‘fake’
                        reviews.
                      </div>
                    </li>
                    <li>
                      <span>What external review sites are shown?</span>
                      <div className="para">
                        Our software automatically collates online reviews from respected websites
                        such as Google, Healthgrades, and Vitals. We continue to partner with market
                        leading websites to provide the most robust online review system available.
                      </div>
                    </li>
                    <li>
                      <span>Are reviews deleted or moderated?</span>
                      <div className="para">
                        At Kaly, we are growing a community of providers and patients. Online
                        reviews help other patients find the right provider, and the reviews also
                        help providers grow their practice. We allow providers to respond to patient
                        reviews. By allowing a response by the provider, this provides a fair and
                        transparent review system to both patients and providers.
                      </div>
                    </li>
                  </ol>
                </div>
                <div className="para">
                  <span className="text-bold">
                    We have a few review guidelines that help grow our community.
                  </span>
                  <ol className="alpha-type">
                    <li>
                      Patient Privacy - As a HIPAA compliant platform, your privacy is paramount. We
                      will not post reviews that contain your personal information or specific
                      medical diagnoses.
                    </li>
                    <li>Profanity - We will not tolerate offensive or derogatory language.</li>
                    <li>
                      Conflict of Interest - reviews are most effective when they are unbiased and
                      genuine. Reviews posted on Kaly as well as on external sites that are
                      found to be written by current or former employees or competitors may be
                      removed.
                    </li>
                    <li>
                      Accuracy of Diagnosis and Treatment - Kaly is committed to ensuring our
                      patients receive the medical treatment they deserve. A medical online review
                      system is not the place to argue, report, or criticize the accuracy of the
                      diagnosis and treatment that was received. We encourage patients to contact
                      the appropriate channels if they have concerns about the accuracy of their
                      care.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ReviewPolicy;
