const Analytics = {
  Category: {
    PatientLanding: 'PatientLanding',
    PatientSignUp: 'PatientSignUp',
    PatientLogin: 'PatientLogin',
    PatientDashboard: 'PatientDashboard',
    PatientSettings: 'PatientSettings',
    PatientDoctorListing: 'PatientDoctorListing',
    PatientAppointment: 'PatientAppointment',
    DoctorLanding: 'DoctorLanding',
    DoctorRegistration: 'DoctorRegistration',
    DoctorDashboard: 'DoctorDashboard',
    DoctorReview: 'DoctorReview',
    DoctorPractice: 'DoctorPractice',
    DoctorSettingProvider: 'DoctorSettingProvider',
    DoctorSettingStaff: 'DoctorSettingStaff',
    DoctorSettingBilling: 'DoctorSettingBilling',
    DoctorSettingInsurance: 'DoctorSettingInsurance',
    DoctorListing: 'DoctorListing',
  },
  Actions: {
    Action: 'Action',
    Click: 'Click',
  },
};

export default {
  Analytics,
};
