const questOptionsFn=()=>{
  let returnArray=[]
  for (let i=1;i<=42;i++){
    returnArray.push({id:`${i} week${i>1?'s':''}`,label:`${i} week${i>1?'s':''}`})
  }
  return [{id:`Not sure`,label:`Not sure`},...returnArray,{id:`42+ weeks`,label:`42+ weeks`}]
}
const questionList = [
  {
    id: 911,
    question: 'What are you looking for help with?',
    // if optionType is not specified we will use default UI
    descriptions: [],
    options: [
      { groupId: 'A18', label: 'Routine Gynecologic Care', shortLabel: 'Routine Gynecologic Care' },
      { nextQuestionId: 912,addExtraQuestion: 2, label: 'Pregnancy and Delivery', shortLabel: 'Pregnancy and Delivery' },
      { nextQuestionId: 916,addExtraQuestion: 1, label: 'A Specific Gynecologic Problem', shortLabel: 'A Specific Gynecologic Problem' },
    ],
  },
  {
    id: 912,
    question: 'Are you currently pregnant?',
    descriptions: [],
    options: [
      { nextQuestionId: 915,addExtraQuestion: 1, label: 'Yes', shortLabel: 'Yes' },
      { nextQuestionId: 913, label: 'No', shortLabel: 'No' },
      { nextQuestionId: 913,label: 'I’m not sure', shortLabel: 'I’m not sure' },
    ],
  },
  {
    id: 913,
    question: 'Which statement is most accurate?',
    descriptions: [],
    options: [
      { groupId: 'GOTO_LIST_WITHOUT_SP',procedureId:[224,225], label: 'I am looking for a physician to help me deliver a future pregnancy.', shortLabel: 'I am looking for a physician to help me deliver a future pregnancy.' },
      { groupId: 'A20', label: 'I am looking for help with becoming pregnant.', shortLabel: 'I am looking for help with becoming pregnant.'},
      { groupId: 'GOTO_LIST_WITHOUT_SP',procedureId:263, label: 'I am looking for family planning and/or contraceptive options?', shortLabel: 'I am looking for family planning and/or contraceptive options?' },
    ],
  },
  {
    id: 914,
    question: 'Which statement is most accurate?',
    descriptions: [],
    options: [
      { groupId: 'GOTO_LIST_WITHOUT_SP',procedureId:263, label: 'I am looking for family planning and/or contraceptive options?', shortLabel: 'I am looking for family planning and/or contraceptive options?' },
      { groupId: 'A21', label: 'My pregnancy has been categorized as high risk.',shortLabel:'My pregnancy has been categorized as high risk.' },
      { groupId: 'A19', label: 'I am pregnant and am either low risk or am not sure if I am high risk or not.', shortLabel: 'I am pregnant and am either low risk or am not sure if I am high risk or not.' },
    ],
  },
  {
    id: 915,
    question: 'Approximately how far along are you?',
    descriptions: [],
    optionType:2,
    questOptions: [...questOptionsFn()],
    nextQuestionId: 914
  },
  {
    id: 916,
    question: 'Please select the specific problem you are looking for help with.',
    descriptions: [],
    multiColOption:true,
    options: [
      { groupId: 'A19', label: 'Menopause', shortLabel: 'Menopause' },
      { groupId: 'A19', label: 'Abnormal Cervical Issues',shortLabel:'Abnormal Cervical Issues' },
      { groupId: 'A19', label: 'Menstruation issues', shortLabel: 'Menstruation issues' },
      { groupId: 'A19', label: 'Fibroids', shortLabel: 'Fibroids' },
      { groupId: 'A19', label: 'Endometriosis', shortLabel: 'Endometriosis' },
      { groupId: 'A22', label: 'Cancer', shortLabel: 'Cancer' },
      {groupId:'A23',label:'Urinary Issues',shortLabel:'Urinary Issues'}

    ],
  },
  
];

export default {
  questionList,
  config: {
    firstQuestionId: 911,
    treeDepth: 1,
  },
};
