import React, {useState} from 'react';
import AboutYouAsProviderStepOne from 'components/SignUpSteps/AboutYouAsProvider/AboutYouAsProviderStepOne';
import AboutYouAsProviderStepTwo from 'components/SignUpSteps/AboutYouAsProvider/AboutYouAsProviderStepTwo';
import './AboutYouAsProvider.css';

const AboutYouAsProvider = props => {
  const {nextStep, data: stepData} = props;
  const [formData, setFormData] = useState({
    step: 1,
    data: stepData,
  });
  const {step, data} = formData;
  const {subStep1Data, subStep2Data} = data;

  const handleError = () => {
    // reset step to 1 if we get error from api
    setFormData(prevState => {
      return {
        ...prevState,
        step: 1,
      };
    });
  };

  const changeStep = subStepData => {
    if (step === 1) {
      setFormData({
        ...formData,
        step: step + 1,
        data: {
          ...formData.data,
          subStep1Data: {
            ...subStepData,
          },
        },
      });
    } else {
      nextStep({subStep1Data, subStep2Data: {...subStepData}}, handleError);
    }
  };

  return (
    <div className="AboutYouAsProvider">
      {step === 1 && (
        <AboutYouAsProviderStepOne
          data={subStep1Data}
          npiNumber={props.npiNumber}
          nextStep={changeStep}></AboutYouAsProviderStepOne>
      )}
      {step === 2 && (
        <AboutYouAsProviderStepTwo
          data={subStep2Data}
          nextStep={changeStep}></AboutYouAsProviderStepTwo>
      )}
    </div>
  );
};

export default AboutYouAsProvider;
