import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Layout from 'layout';
import AuthContext from 'context/AuthContext';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import Input from 'components/FormControls/Input';
import Checkbox from 'components/FormControls/Checkbox';
import userService from 'services/user';
import images from 'data/images';
import validators from 'data/validators';
import modalConfig from 'modals/modalConfig';
import {EnterOtp} from './EnterOtp';
import './Login.css';

const {signIn} = userService;
const {errorModalData} = modalConfig.modalFunctions;
const {__required, __email} = validators;

class LoginSuperAdmin extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {
    isProcessing: false,
    values: {
      type: 'superAdmin',
      email: '',
      password: '',
      rememberMe: false,
      otp:""
    },
    errors: {},
  };

  loginHandler = async () => {
    const {updateAuthStatus} = this.context;
    const {history} = this.props;
    const {values: formValues} = this.state;
    this.setState({isProcessing: true});
   let passdata={...formValues}
   if(!passdata.otp){
     delete passdata.otp
   }
    const response = await signIn(passdata).catch(this.handleError);
    this.setState({isProcessing: false});
       
    if (response) {
      
      const {data,message} = response;
      if(message?.trim()==="Otp shared Successfully"){
        let otp=await EnterOtp({context:this.rootContext})
        if(!otp)
        return null
        else{
          let {values}=this.state
          values.otp=otp
          this.setState({isProcessing: false,values:values},this.loginHandler);
          return null;
        }
      } 
     
      const {accessToken, refreshToken} = data;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      updateAuthStatus(true);
      let urlList = window.location.href.split('goto=');
      if (urlList.length === 2) {
        const url = new URL(urlList[1]);
        let pathName=url.pathname.replace('/app','')
        history.push({pathname: pathName, search: url.search, hash: url.hash});
      } else {
        history.push({
          pathname: '/',
        });
      }
    }
  };

  handleError = async (error) => {
    const {setGlobal} = this.rootContext;
    const {message} = error;
    if(message?.trim()==="InValid Otp Please Request for another One."){
      let {values}=this.state
      values.otp="";
      values.password="";
      this.setState({values:values})
    }
    setGlobal('modal', errorModalData(message));
    return null;
  };

  // validation methods
  __validateEmail = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['email'];
    if (!__required(fValue)) {
      return 'Email is required';
    }
    if (!__email(fValue)) {
      return 'Invalid Email';
    }
    return null;
  };
  __validatePassword = (value = null) => {
    const {values: formValues} = this.state;
    const fValue = value !== null ? value : formValues['password'];
    if (!__required(fValue)) {
      return 'Password is required';
    }
    return null;
  };
  __validateForm = () => {
    const validEmail = !this.__validateEmail();
    const validPassword = !this.__validatePassword();
    return validEmail && validPassword;
  };

  changeHandler = event => {
    const {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'email':
        errorObject = {
          [name]: this.__validateEmail(value),
        };
        break;
      case 'password':
        errorObject = {
          [name]: this.__validatePassword(value),
        };
        break;
      default:
        break;
    }
    this.setState(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  render() {
    const {isProcessing, values: formValues, errors: formErrors} = this.state;
    const getGeneralInputProps = controlName => {
      return {
        onChange: this.changeHandler,
        onBlur: this.changeHandler,
        error: formErrors[controlName],
        value: formValues[controlName],
        name: controlName,
      };
    };

    const isValidForm = this.__validateForm();
    return (
      <Layout>
        <div className="Login">
          <div className="login-container">
            <div className="h3">Log in with email</div>
            <form className="login-form" onSubmit={e => e.preventDefault()}>
              <Input {...getGeneralInputProps('email')} translationType="transform" label="Email" />
              <Input
                {...getGeneralInputProps('password')}
                type="password"
                translationType="transform"
                icon={images.eyeView}
                label="Password"
              />
            
              <RootContext.Consumer>
                {context => {
                  this.rootContext = context;
                  return (
                    <div className="login-btn doctor-loginbtn">
                      <CircleButton
                        type="submit"
                        disabled={!isValidForm || isProcessing}
                        onClick={this.loginHandler}
                        btnType="whiteButton">
                        Log in
                      </CircleButton>
                    </div>
                  );
                }}
              </RootContext.Consumer>
              
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default LoginSuperAdmin;
