import React from 'react';
import {Route, Switch} from 'react-router-dom';
import RouteNotFound from 'containers/RouteNotFound';
import LoadPage from 'containers/LoadPage';
import PrivacyPolicy from 'containers/PrivacyPolicy';
import ReviewPolicy from 'containers/ReviewPolicy';
import Terms from 'containers/Terms';
import HIPAAPolicy from 'containers/HIPAAPolicy';
import CCPAPolicy from 'containers/CCPAPolicy';
import HealthcareProviderAgreement from 'containers/HealthcareProviderAgreement';
import AboutUs from 'containers/AboutUs';
import FAQ from 'containers/FAQ';
import ContactUs from 'containers/ContactUs';
import Blog from 'containers/Blog';
import MedicalTerms from 'containers/MedicalTerms';
import LoadingPage from 'containers/Patient/LoadingPage';
import PatientForm from 'containers/Patient/PatientForm';
import PatientCommonRoutes from './PatientCommonRoutes';
import ValidateAvailability from 'containers/ValidateAvailability';
import AddToCalendar from 'containers/AddToCalendar';
import ReviewWidget from 'containers/Widget/ReviewWidget';
import AlgoConnect from 'containers/Connectors/AlgoConnect';
// twilio video chat
import VideoChat from 'containers/VideoChat';
import VideoVisit from 'containers/VideoVisit';
import VideoWaitingRoom from 'containers/VideoWaitingRoom';
import DoctorVideoExit from 'containers/DoctorVideoExit';
import PatientVideoExit from 'containers/PatientVideoExit';
import PatientVideoClosed from 'containers/PatientVideoClosed';
import Gohandle from 'containers/Gohandle';
import LandingPage from 'containers/LandingPage';
import WriteReview from 'containers/WriteReview';

const CommonRoutes = props => {
  const {auth} = props;
  // These routes will be accessable by all users.
  return (
    <Switch>
      {/* static pages start */}
      {PatientCommonRoutes(auth)}
      <Route
        exact
        path={'/validate-availability'}
        component={props => <ValidateAvailability {...props} />}
      />
      <Route exact path={'/list-your-practice'} component={props => <LandingPage {...props} />} />
      <Route
        exact
        path={'/list-your-practice-lp'}
        component={props => <LandingPage lp={true} {...props} />}
      />
      <Route
        exact
        path={'/list-your-practice-lp/#signup'}
        component={props => <LandingPage ss={true} lp={true} {...props} />}
      />

      <Route exact path={'/patient-form'} component={props => <PatientForm {...props} />} />
      <Route exact path={'/loading-page'} component={props => <LoadingPage {...props} />} />
      <Route exact path={'/privacy-policy'} component={props => <PrivacyPolicy {...props} />} />
      <Route exact path={'/terms'} component={props => <Terms {...props} />} />
      <Route exact path={'/medical-terms'} component={props => <MedicalTerms {...props} />} />
      <Route exact path={'/hipaa-policy'} component={props => <HIPAAPolicy {...props} />} />
      <Route exact path={'/ccpa'} component={props => <CCPAPolicy {...props} />} />
      <Route
        exact
        path={'/healthcare-provider-agreement'}
        component={props => <HealthcareProviderAgreement {...props} />}
      />
      <Route exact path={'/review-policy'} component={props => <ReviewPolicy {...props} />} />
      <Route exact path={'/about-us'} component={props => <AboutUs {...props} />} />
      <Route exact path={'/faq'} component={props => <FAQ {...props} />} />
      <Route exact path={'/contact-us'} component={props => <ContactUs {...props} />} />
      <Route exact path={'/blog'} component={props => <Blog {...props} />} />
      {/* static pages end */}
      <Route
        exact
        path={'/verify/:verificationType/:token'}
        component={props => <LoadPage {...props} />}
      />
      <Route
        exact
        path={'/consultrequest/:verificationType/:token'}
        component={props => <LoadPage {...props} />}
      />

      <Route
        exact
        path={'/verify/:verificationType'}
        component={props => <RouteNotFound {...props} />}
      />
      <Route exact path={'/verify/'} component={props => <RouteNotFound {...props} />} />
      <Route exact path={'/add-to-calendar'} component={props => <AddToCalendar {...props} />} />
      <Route exact path={'/algo-connect'} component={props => <AlgoConnect {...props} />} />
      <Route
        exact
        path={'/review-widget/:providerId/:shape/:color'}
        component={props => <ReviewWidget {...props} />}
      />
      {/* twilio video chat */}
      <Route exact path={'/video-chat'} component={props => <VideoChat {...props} />} />
      <Route exact path={'/video-visit'} component={props => <VideoVisit {...props} />} />
      <Route
        exact
        path={'/doctor-waiting-room'}
        component={props => <DoctorVideoExit {...props} />}
      />
      <Route
        exact
        path={'/patient-video-exit'}
        component={props => <PatientVideoExit {...props} />}
      />
      <Route
        exact
        path={'/patient-video-closed'}
        component={props => <PatientVideoClosed {...props} />}
      />
      <Route exact path={'/go'} component={props => <Gohandle {...props} />} />
      <Route
        exact
        path={'/write-review/:appointmentId'}
        component={props => <WriteReview {...props} />}
      />

      <Route path={'/'} component={props => <RouteNotFound {...props} />} />
    </Switch>
  );
};

export default CommonRoutes;
