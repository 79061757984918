import React from 'react';
import './DoctorToday.css';
import images from 'data/images';
import {Link} from 'react-router-dom';

const DoctorToday = props => {
  return (
    <div className="DoctorToday">
      <div id="doctorTodayContainer" className="container">
        <div className="heading2">Book an in-person or telemedicine appointment today.</div>
        <div className="today-row">
          <div className="today-col symptoms-today-col">
            <div className="today-box">
              <div className="today-box-inner">
                <div className="heading3 fst">How long have you had back pain for?</div>
                <div className="sf-select-answer">
                  <span className="sf-description-item active">
                    <span>Less than 3 weeks</span>
                  </span>
                  <span className="sf-description-item second">
                    <span>More than 3 weeks</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="today-desc">Answer a few questions so we can learn about your unique condition, concerns,
and care needs.</div>
          </div>
          <div className="today-col">
            <div className="today-box">
              <div className="today-box-inner">
                <div className="doctor-profile-wrapper">
                  <div className="profile-photo">
                    <img src={images.doctor1} alt="Doctor" />
                  </div>
                  <div className="profile-info">
                    <div className="profile-row">
                      <div className="profile-left">
                        <div className="profile-name">Dr. Jane Mazza, MD</div>
                        <div className="profile-specialist">Orthopedic Surgeon </div>
                        <div className="rating-parent-div">
                        <img src={images.starGold} className="star-icon" alt="starGold" />
                        <img src={images.starGold} className="star-icon" alt="starGold" />
                        <img src={images.starGold} className="star-icon" alt="starGold" />
                        <img src={images.starGold} className="star-icon" alt="starGold" />
                        <img src={images.starGold} className="star-icon" alt="starGold" />
                        <span >4.9</span>
                        </div>
                      </div>
                      <div className="reviews-block">
                        <div className="rating-image">
                          <img src={images.star} className="star-icon" alt="star" />
                          <div className="review-count">4.9</div>
                        </div>
                      </div>
                    </div>
                    <div className="progress-bar">
                      <div className="pt-category-box">
                        <span className="box active" style={{width: `35%`}}>
                          <span className="dc-symptoms-result">
                            <span className="dc-symptoms-result-inner">
                              <span className="dc-symptoms-inner">35% Sciatica</span>
                            </span>
                          </span>
                        </span>
                        <span className="box" style={{width: `20%`}}>
                          <span className="dc-symptoms-result">
                            <span className="dc-symptoms-result-inner">
                              <span className="dc-symptoms-inner">20% Low Back Pain</span>
                            </span>
                          </span>
                        </span>
                        <span className="box" style={{width: `15%`}}>
                          <span className="dc-symptoms-result">
                            <span className="dc-symptoms-result-inner">
                              <span className="dc-symptoms-inner">15% Neck Pain</span>
                            </span>
                          </span>
                        </span>
                        <span className="box" style={{width: `15%`}}>
                          <span className="dc-symptoms-result">
                            <span className="dc-symptoms-result-inner">
                              <span className="dc-symptoms-inner">15% Knee Pain</span>
                            </span>
                          </span>
                        </span>
                        <span className="box" style={{width: `15%`}}>
                          <span className="dc-symptoms-result">
                            <span className="dc-symptoms-result-inner">
                              <span className="dc-symptoms-inner">15% Shoulder Pain</span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div className="dc-symptoms">
                        <div>
                          35% Sciatica <span className="symptoms-txt">Commonly Treated</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rating-block">
                  <div className="star-rating">
                    <div className="rating-inner">
                      <div className="name">Doctor</div>
                      <span className="rate-icon">
                        <img src={images.starGold} alt="star-rate" />
                      </span>
                      <div className="number">4.9</div>
                    </div>
                    <div className="rating-inner">
                      <div className="name">Staff</div>
                      <span className="rate-icon">
                        <img src={images.starGold} alt="star-rate" />
                      </span>
                      <div className="number">5.0</div>
                    </div>
                    <div className="rating-inner">
                      <div className="name">Office</div>
                      <span className="rate-icon">
                        <img src={images.starGold} alt="star-rate" />
                      </span>
                      <div className="number">4.7</div>
                    </div>
                  </div>
                  <div className="review-content">
                    “ Dr. Mazza was very informative, nice and listened to my concerns. “
                  </div>
                </div>
              </div>
            </div>
            <div className="today-desc">
            Confidently connect with providers with exactly the right set of skills to help.
            </div>
          </div>
          <div className="today-col appoinment-today-col">
            <div className="today-box">
              <div className="today-box-inner">
                <div className="heading3">Appointment Confirmed!</div>
                <div className="appoinment-timing">
                  <span className="video-btn">
                    <img src={images.videoCamera} alt="video" />
                    <span>Video Visit</span>
                  </span>
                  <div className="time">
                    <span>
                      <span className="day">Today</span>
                      <span className="date">Mar 12</span>
                      4:30 pm
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="today-desc">Book an appointment, either in-person or via video chat.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorToday;
