import React, {memo} from 'react';
import _ from 'lodash';
import {Route, Switch} from 'react-router-dom';
import CommonRoutes from './CommonRoutes';
import authDashboardContent from 'data/authDashboardContent';
import rolePermission from 'data/rolePermission';
import ChangePassword from 'containers/Admin/ChangePassword';
import EditProvider from 'containers/Admin/Providers/EditProvider';
import ProviderReviews from 'containers/Admin/Providers/ProviderReviews';
import ImportProvider from 'containers/Admin/ImportProvider';
import EditPractice from 'containers/Admin/Practices/EditPractice';
import GhostProviderReviews from 'containers/Admin/GhostProfiles/GhostProviderReviews';
import Dashboard from 'containers/AfterAuth/Dashboard';
import config from 'config/config';
const {siderbarMenuItems} = authDashboardContent;
const {helperFunctions, permissionList} = rolePermission;
const {hasAnyPermission, hasPermission, getAllMyRoleIds} = helperFunctions;

const MainRoutes = props => {
  const {auth,getSubscriptionText} = props;
  const roleIds = getAllMyRoleIds(auth);
  const isAdminUser = hasPermission(roleIds, permissionList.ADMIN);
  const accessRoutes = [];
  const adminRoutes = [];
  
  _.map(siderbarMenuItems, item => {
    if (hasAnyPermission(roleIds, item.permissions)) {
      const { subMenuItems = []} = item;
      let {BaseComponent}=item
      if(BaseComponent){
        if(getSubscriptionText==='yourStripeAccountNotCreated'&&!auth.email.endsWith("kaly.com") && config.showStripe==true){
          BaseComponent=Dashboard
        }
      accessRoutes.push(
        <Route
          exact
          path={item.link}
          key={item.name}
          component={props => <BaseComponent {...props} />}
        />,
      
      );
      }
      // sub menu configuration
      subMenuItems.forEach(subItem => {
        const {BaseComponent: SubBaseComponent} = subItem;
        if (hasAnyPermission(roleIds, subItem.permissions)) {
          
          accessRoutes.push(
            <Route
              exact
              path={subItem.link}
              key={subItem.name}
              component={props =>{
                let tempProps={...props}
                if(subItem.provideStatus)
                {
                  tempProps.provideStatus=subItem.provideStatus
                }
                if(subItem.name)
                {
                  tempProps.name=subItem.name
                }
               
                return <SubBaseComponent {...tempProps} />
              } }
            />,
          );
        }
      });
    }
  });

  if (isAdminUser) {
    adminRoutes.push(
      <Route
        exact
        key="admin-change-password"
        path={'/admin/change-password'}
        component={props => <ChangePassword {...props} />}
      />,
    );
    adminRoutes.push(
      <Route
        exact
        key="admin-practice-edit"
        path={'/admin/practice-edit'}
        component={props => <EditPractice {...props} />}
      />,
    );
    adminRoutes.push(
      <Route
        exact
        key="admin-provider-Details"
        path={'/admin/providerDetails'}
        component={props => <EditProvider {...props} />}
      />,
    );
    adminRoutes.push(
      <Route
        exact
        key="admin-provider-reviews"
        path={'/admin/provider-reviews'}
        component={props => <ProviderReviews {...props} />}
      />,
    );
    adminRoutes.push(
      <Route
        exact
        key="admin-import-provider"
        path={'/admin/import-provider'}
        component={props => <ImportProvider {...props} />}
      />,
    );
    adminRoutes.push(
      <Route
        exact
        key="admin-import-provider"
        path={'/admin/import-provider-new'}
        component={props => <ImportProvider new={true} {...props} />}
      />,
    );
    adminRoutes.push(
      <Route
        exact
        key="admin-ghost-reviews"
        path={'/admin/ghost-reviews'}
        component={props => <GhostProviderReviews {...props} />}
      />,
    );
    // adminRoutes.push(
    //   <Route
    //     exact
    //     key="admin-faq-add"
    //     path={'/admin/manage-faq/add'}
    //     component={props => <GhostProviderReviews {...props} />}
    //   />,
    // );
  }

  return (
    <Switch>
      {accessRoutes}
      {adminRoutes}
      <CommonRoutes auth={auth} />
    </Switch>
  );
};

const noRerender = (prevProps, nextProps) => {
  if(prevProps.getSubscriptionText!==nextProps.getSubscriptionText){
    return false
  }
  const prevRoleIds = getAllMyRoleIds(prevProps.auth);
  const nextRoleIds = getAllMyRoleIds(nextProps.auth);

  return _.isEqual(prevRoleIds, nextRoleIds);
};

export default memo(MainRoutes, noRerender);
