import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import ReviewDetail from 'components/AfterAuth/ReviewTabs/ReviewDetail';
import './ReviewTabs.css';
import images from 'data/images';

const ReviewTabs = props => {
  const {
    reviewList = [],
    tabs = [],
    searchText,
    onReply,
    onDelete,
    onToggleFeatureType,
    onRequestDelete,
  } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(props.activeTabIndex || 0);
  const [sortType, setSortType] = useState(0);
  // 0-Newest, 1-Highest Rated, 2-Lowest Rated
  const inCo = useRef(null);
  let reviewListComponent = null;
  const activeTabLabel = _.get(tabs, `${activeTabIndex}.label`);

  useEffect(() => {
    function handleClick() {
      setTimeout(() => {
        try {
          let vvv = window.getComputedStyle(document.querySelector('.info-content'));
          if (vvv.display === 'block') {
            inCo.current.classList.add('min-h');
          } else {
            inCo.current.classList.remove('min-h');
          }
        } catch (error) {}
      }, 200);
    }
    // window.addEventListener("click", handleClick);
    // window.addEventListener("mouseout", handleClick);
    // document.addEventListener("mouseout", handleClick);
    // window.addEventListener("scroll", handleClick);
    document.addEventListener('scroll', handleClick);
    document.addEventListener('mousemove', handleClick);
    document.addEventListener('touchmove', handleClick);
    document.addEventListener('touchstart', handleClick);
    // inCo.current.addEventListener("scroll", handleClick);
    // inCo.current.addEventListener("focus", handleClick);
    // inCo.current.addEventListener("mousemove", handleClick);
    return () => {
      // window.removeEventListener("click", handleClick);
      // window.removeEventListener("mouseout", handleClick);
      document.removeEventListener('mousemove', handleClick);
      // window.removeEventListener("scroll", handleClick);
      document.removeEventListener('scroll', handleClick);
      document.removeEventListener('touchmove', handleClick);
      document.removeEventListener('touchstart', handleClick);
      // inCo.current.removeEventListener("scroll", handleClick);
      // inCo.current.removeEventListener("focus", handleClick);
      // inCo.current.removeEventListener("mousemove", handleClick);
    };
  }, []);

  const getReviewTotal = reviewObj => {
    if(reviewObj.reviewSource.title=='Kaly')
    {
      let total=reviewObj.doctorApproachable +
      reviewObj.doctorBedsideManner +
      reviewObj.doctorKnowledge +
      reviewObj.officeAtmosphere +
      reviewObj.officeCleanliness +
      reviewObj.officeWaitTimes +
      reviewObj.staffProfessional +
      reviewObj.staffResponseTime +
      reviewObj.staffScheduling;
      return total/9
    }
   
    else
    return reviewObj.overallRating

  };

  if (activeTabLabel === 'Connected Accounts') {
    reviewListComponent = <p className="no-desingn">No design available</p>;
  } else {
    let filteredReviewList = _.filter(reviewList, review => {
      let isValidReviewForTab = false;
      switch (activeTabIndex) {
        case 0:
          isValidReviewForTab = true;
          break;
        case 1:
          isValidReviewForTab = review.isRecentReview;
          break;
        case 2:
          isValidReviewForTab = review.isFeatured;
          break;
        default:
          break;
      }
      if (isValidReviewForTab && _.trim(searchText)) {
        if (_.isNull(review.review)) review.review = '';
        if (!review.review.toLowerCase().includes(searchText.toLowerCase())) {
          isValidReviewForTab = false;
        }
      }
      return isValidReviewForTab;
    });
    if (sortType === 0) filteredReviewList = _.orderBy(filteredReviewList, ['createdAt'], ['desc']);
    else if (sortType === 1)
      filteredReviewList = _.orderBy(
        filteredReviewList,
        [
          item => {
            return getReviewTotal(item);
          },
           'createdAt',
        ],
        ['desc','desc' ],
      );
    else if (sortType === 2)
      filteredReviewList = _.orderBy(
        filteredReviewList,
        [
          item => {
            return getReviewTotal(item);
          },
          'createdAt',
        ],
        ['asc', 'desc'],
      );
    reviewListComponent = (
      <div className="review-list">
        <div className="review-sort-wrapper">
          <span className={sortType === 0 ? 'active' : ''} onClick={() => setSortType(0)}>
            Newest
          </span>
          <span className={sortType === 1 ? 'active' : ''} onClick={() => setSortType(1)}>
            Highest Rated
          </span>
          <span className={sortType === 2 ? 'active' : ''} onClick={() => setSortType(2)}>
            Lowest Rated
          </span>
        </div>
        {filteredReviewList.map(review => {
          const reviewDetailProps = {
            review,
            key: review.id,
            onReply,
            onDelete,
            onToggleFeatureType,
            onRequestDelete,
          };
          return <ReviewDetail {...reviewDetailProps}></ReviewDetail>;
        })}
      </div>
    );
  }

  const tabHeaderList = (
    <ol ref={inCo} className="tab-list">
      {tabs.map((tab, index) => {
        return (
          <li
            key={index}
            className={`tab-list-item ${index === activeTabIndex ? 'tab-list-active' : ''}`}
            onClick={() => setActiveTabIndex(index)}>
            {tab.label}
            {tab.label === 'Featured' && (
              <span className="info-wrapper">
                <img src={images.info} alt="info" className="info" />
                <span className="info-content">
                  Please select up to two reviews to be showcased on your profile.
                </span>
              </span>
            )}
            <span className="review-count">{tab.count}</span>
          </li>
        );
      })}
    </ol>
  );

  return (
    <div className="ReviewTabs">
      {tabHeaderList}
      <div className="tab-content">{reviewListComponent}</div>
    </div>
  );
};

export default ReviewTabs;
