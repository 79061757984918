import React from 'react';
import './Divider.css';

const Divider = () => {
  return (
    <div className="Divider">
      <div className="container">
        <span className="horizontal-line"></span>
      </div>
    </div>
  );
};

export default Divider;
