import React, {useState} from 'react';
import image from 'data/images';
import MyDatetime from 'components/MyDatetime';
import momentHelper from 'data/moment/momentHelper';
import './CalendarToolbar.css';
import TimeZoneLabel from 'components/MyDatetime/TimeZoneLabel';

const {startOf, endOf, isBetweenDates} = momentHelper;

const CalendarToolbar = props => {
  const {currentWeekStartDate, onDateChange} = props;

  const handleDateChange = e => {
    toggle(false);
    onDateChange(e);
  };

  const [isOpen, toggle] = useState(false);

  const startDate = startOf('week', currentWeekStartDate);
  const endDate = endOf('week', currentWeekStartDate);
  const renderDay = (props, currentDate, selectedDate) => {
    const updatedProps = {...props};
    if (isBetweenDates(startDate, endDate, currentDate)) {
      updatedProps.className += ' rdtActive';
    }
    return <td {...updatedProps}>{currentDate.date()}</td>;
  };
  return (
    <div className="CalendarToolbar">
      <div className="date-range">
        {startDate.format('MMMM DD') + ' - ' + endDate.format('MMMM DD')}
        <span> {endDate.format('YYYY')}</span>
        <TimeZoneLabel />
      </div>
      <div>
        <div className="calendar-datepicker">
          <button type="button" className="cal-btn" onClick={() => toggle(!isOpen)}>
            <img alt="" src={image.calender} />
          </button>
          <div className="datepicker">
            <MyDatetime
              renderDay={renderDay}
              value={currentWeekStartDate}
              onChange={handleDateChange}
              viewMode="days"
              open={isOpen}
              input={false}
            />
          </div>
        </div>
        <button
          type="button"
          className="cal-btn arrow-btn"
          onClick={() => props.onNavigate('PREV')}>
          <img alt="" src={image.angleLeft} />
        </button>
        <button
          type="button"
          className="cal-btn arrow-btn"
          onClick={() => props.onNavigate('NEXT')}>
          <img alt="" src={image.angleRight} />
        </button>
      </div>
    </div>
  );
};
export default CalendarToolbar;
