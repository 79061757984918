import React, {Component} from 'react';
import _ from 'lodash';
import moment from 'moment';
import Layout from 'layout/AfterAuth';
import SubHeader from 'components/AfterAuth/SubHeader';
import DashboardCount from 'components/AfterAuth/Dashboard/DashboardCount';
import UpcomingAppointments from 'components/AfterAuth/Dashboard/UpcomingAppointments';
import FinishProfile from 'components/AfterAuth/Dashboard/FinishProfile';
import Select from 'components/FormControls/Select';
import userService from 'services/user';
import scheduleService from 'services/schedule';
import selectOptions from 'data/selectOptions';
import AppointmentDetail from 'components/AfterAuth/Schedule/AppointmentDetail';
import InfiniteScroll from 'components/InfiniteScroll';
import AuthContext from 'context/AuthContext';
import PracticeAccount from 'components/AfterAuth/Dashboard/PracticeAccount';
import RootContext from 'context/RootContext';
import ViewPatientOverlayModal from 'components/AfterAuth/Dashboard/ViewPatientOverlayModal';
import './Dashboard.css';
import modalConfig from 'modals/modalConfig';
import config from 'config/config';

const {activityOptions} = selectOptions;
const {getDashboardAnalysisData} = userService;
const {getAllAppointments, getPaginatedAppointments, getAppointmentForProviderBytId} =
  scheduleService;
const {modalFunctions} = modalConfig;
const {blankModalData} = modalFunctions;
const initialState = {
  analysisData: null,
  profileStatusData: null,
  upcomingAppointments: null,
  appointmentTypeId: '',
  appointments: [],
  isFetching: false,
  allFetched: false,
};

class Dashboard extends Component {
  static contextType = AuthContext;
  rootContext = null;
  state = {...initialState};
  limit = 10;
  oldContext = this.context;

  componentDidMount() {
    this.checkPatientDetails();
    this.getAndSetDashboardData();
  }

  //  get initial data again, when viewUserId is switched.
  componentDidUpdate() {
    const newViewUserId = _.get(this.context, 'auth.viewUserId');
    const oldViewUserId = _.get(this.oldContext, 'auth.viewUserId');
    if (newViewUserId !== oldViewUserId) {
      this.setState({...initialState}, () => {
        this.componentDidMount();
      });
    }
    this.oldContext = this.context;
  }

  checkPatientDetails = () => {
    const {location} = this.props;
    const query = new URLSearchParams(location.search);
    const type = query.get('type');
    const patientUserId = query.get('patientUserId');
    if (type && type === 'dashboard' && patientUserId) {
      this.openViewPatientOverlayModal(patientUserId);
    }
  };

  openViewPatientOverlayModal = patientUserId => {
    const {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: ViewPatientOverlayModal,
        modalWrapperClass: 'mega-modal',
        customComponentProps: {
          data: {
            patientUserId,
          },
          refetchAppointmentEventsDashBoard: this.refetchAppointmentEvents,
        },
        callBackOnClose: () => {
          this.getAndSetAppointments();
        },
      }),
    );
  };

  getAndSetAnalysisData = async () => {
    const response = await getDashboardAnalysisData().catch(error => null);
    if (response) {
      const {data} = response;
      const {analysisData, profileStatusData} = data;
      // let tempdata=[...analysisData,{number:8,title:
      //   "Pending Appointment Feedback"}]
      //console.log("analysisData",analysisData);
      this.setState({
        analysisData: analysisData,
        profileStatusData,
      });
    }
  };

  getAndSetUpcomingAppointments = async () => {
    const upcomingAppointmentResponse = await getAllAppointments({type: 'upcoming'}).catch(
      error => null,
    );
    if (upcomingAppointmentResponse) {
      let {data} = upcomingAppointmentResponse;
      data = data.filter(ap => ap.patient.email !== 'google_calendar@kaly.com');
      this.setState({
        upcomingAppointments: data,
      });
    }
  };

  getAndSetDashboardData = async () => {
    this.getAndSetAnalysisData();
    this.getAndSetUpcomingAppointments();
    this.getAndSetAppointments();
  };

  // for infinite scroll
  getAndSetAppointments = async (appointmentType = null) => {
    const {appointmentTypeId, appointments} = this.state;
    let updateObject = {};
    appointmentType = appointmentType !== null ? appointmentType : appointmentTypeId;
    const isappointmentTypeIdChanged = appointmentTypeId !== appointmentType;
    let lastUpdatedAt = null;
    if (isappointmentTypeIdChanged) {
      updateObject.allFetched = false;
    }
    if (!isappointmentTypeIdChanged && appointments.length > 0) {
      lastUpdatedAt = moment(appointments[appointments.length - 1].updatedAt)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const dataObject = {
      status: appointmentType,
      limit: this.limit,
      lastUpdatedAt,
    };
    const appointmentResponse = await getPaginatedAppointments(dataObject);
    if (appointmentResponse) {
      let {data} = appointmentResponse;

      if (Array.isArray(data))
        data = data.filter(ap => ap.patient.email !== 'google_calendar@kaly.com');
      this.setState(prevState => {
        let newAppointments = [...data];
        if (!isappointmentTypeIdChanged) {
          newAppointments = [...prevState.appointments, ...newAppointments];
          updateObject.allFetched = false;
        }
        if (data.length < this.limit) {
          updateObject.allFetched = true;
        }
        newAppointments = _.uniqBy(newAppointments, 'id');
        return {
          ...prevState,
          appointments: newAppointments,
          appointmentTypeId: appointmentType,
          ...updateObject,
        };
      });
    }
    this.setState({isFetching: false});
  };

  refetchAppointmentEvents = async (appointmentId, from = '') => {
    if (from === 'modal') {
      this.setState(
        {
          appointments: [],
        },
        () => {
          this.getAndSetAppointments();
        },
      );
    } else {
      const response = await getAppointmentForProviderBytId(appointmentId);
      if (response) {
        const {data} = response;
        this.setState(prevState => {
          let updatedAppointments = [...prevState.appointments];
          updatedAppointments = updatedAppointments.map(appointment => {
            if (appointment.id === appointmentId) {
              return data;
            } else {
              return appointment;
            }
          });
          return {
            ...prevState,
            appointments: updatedAppointments,
          };
        });
      }
    }
  };

  handleAppointmentTypeChange = event => {
    const {value} = event.target;
    this.getAndSetAppointments(value);
  };

  render() {
    const {
      analysisData,
      profileStatusData,
      upcomingAppointments,
      appointments,
      appointmentTypeId,
      isFetching,
      allFetched,
    } = this.state;
    const {getViewUser} = this.context;
    const viewUser = getViewUser();
    // const Count = [...authDashboardContent.count];
    const title = 'Provider Dashboard';
    return (
      <Layout>
        <RootContext.Consumer>
          {context => {
            this.rootContext = context;
          }}
        </RootContext.Consumer>
        <div className="AfterAuthDashboard">
          <SubHeader title={title} />
          <div className="dashboard-panle">
            <div className="panel-left">
              {analysisData && <DashboardCount data={analysisData} />}
              <div className="monthly-activity">
                <div className="title-content">
                  <span className="title">
                    <span>Activity</span>
                  </span>
                  <Select
                    isAllowDefaultOpen={true}
                    defaultOptionProp={{value: '', label: 'All'}}
                    onChange={this.handleAppointmentTypeChange}
                    value={appointmentTypeId}
                    options={activityOptions}
                  />
                </div>
                <InfiniteScroll
                  offset={700}
                  allFetched={allFetched}
                  onScrollToBotton={() => this.getAndSetAppointments(appointmentTypeId)}
                  isFetching={isFetching}
                  setIsFetching={() => !isFetching && this.setState({isFetching: true})}>
                  {appointments &&
                    appointments.map(appointment => {
                      return (
                        <AppointmentDetail
                          key={appointment.id}
                          appointmentDetail={appointment}
                          viewUser={viewUser}
                          refetchAppointmentEvents={this.refetchAppointmentEvents}
                        />
                      );
                    })}
                </InfiniteScroll>
              </div>
            </div>
            <div className="panel-right">
              {upcomingAppointments && <UpcomingAppointments appointments={upcomingAppointments} />}
              <FinishProfile profileStatusData={profileStatusData} />
              {/* ====| START: Hide Stripe Code |==== */}
              {config.showStripe === true && <PracticeAccount />}
              {/* ====| END: Hide Stripe Code |==== */}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Dashboard;
