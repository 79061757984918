import React, {useState} from 'react';
import images from 'data/images';

import {Link, useHistory} from 'react-router-dom';

import './ListyourpracticeIntro.css';

import analyticsData from 'data/analytics/analyticsData';

const {Analytics} = analyticsData;

const AppointmentConnecting = props => {
  const history = useHistory();
const {scrollToJoinkalypsys,lp=false}=props
  return (
    <div className="ListyourpracticeIntro">
      <div className="Ac-left">
        <div className="practice-banner">
          <div className="text-div">
            <h1 className="mainheadding">
              Connect with patients who need exactly your kind of care.
            </h1>
            <p className="textpara">
              Kaly matches patients with unique needs to the providers with the perfect set of
              skills to help.
            </p>
            <a style={{cursor:"pointer"}} onClick={scrollToJoinkalypsys}  className="listyourpractice">
            List your practice
            </a>
          </div>
          <div className="img-listyourpractice" style={{position:"relative"}}>
            <img className="landingpage" src={images.listyourPractice} alt={'listyourPractice'} />
            <a style={{cursor:"pointer"}} onClick={scrollToJoinkalypsys}  className="listyourpractice">
            List your practice
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentConnecting;
