import React, {useState} from 'react';
import _ from 'lodash';
import ReadMore from 'components/ReadMore';
import SingleRating from 'components/AfterAuth/SingleRating';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import './ReviewData.css';
import helper from 'data/helper';
import config from 'config/config';
import constants from 'data/constants';
const {StaticFooterPage} = constants;
const {parseSpecialCharacter} = helper;

const ReviewData = props => {
  const {isGhost, review, onReply, onDelete, onToggleFeatureType, onReviewDelete, onRejectRequest} =
    props;
  const {
    id: reviewId,
    review: reviewText,
    isRecentReview,
    isFeatured,
    reply,
    formatedCreateDate,
    ratings,
    name = '',
    reviewSource,
    hasRequestedForDelete,
    status = '',
    overallRating,
  } = review;
  const [isOpen, toggle] = useState(false);
  const [replyText, setReply] = useState('');
  const [hoverRating, setHoverrating] = useState(null);
  const [isEdit, toggleEdit] = useState(false);
  const [editReplyId, setEditReplyId] = useState(null);
  const addonText = () => (
    <span>
      . Please adhere to the{' '}
      <a className="linka" href={config.APP_DOMAIN + StaticFooterPage.REVIEW_POLICY}>
        Kaly Review Policy
      </a>{' '}
      or your response may get flagged. If you have further questions regarding reviews, please see
      our provider{' '}
      <a className="linka" href={config.APP_DOMAIN + StaticFooterPage.FAQ}>
        FAQs
      </a>
    </span>
  );
  const reviewSourceIcon = reviewSource?.logoUrl || images.logoIcon;
  const validateReply = () => {
    return _.trim(replyText).length === 0;
  };

  const submitReply = async () => {
    if (isEdit && editReplyId) {
      await onReply({reviewId, replyId: editReplyId, reply: replyText});
      toggleEdit(false);
      setEditReplyId(null);
    } else {
      const data = {reviewId, reply: replyText};
      await onReply(data);
      toggle(false);
    }
  };

  const handleHover = hoveredRating => {
    setHoverrating(hoveredRating);
  };

  const handleHoverOut = hoveredRating => {
    setHoverrating(null);
  };

  const toggleFeatureType = () => {
    onToggleFeatureType({
      reviewId,
      isFeatured: !isFeatured,
    });
  };

  const deleteReview = () => {
    onReviewDelete(reviewId);
  };

  const rejectDeleteRequest = () => {
    onRejectRequest({
      reviewId,
      hasRequestedForDelete: !hasRequestedForDelete,
    });
  };

  const openEditMode = reply => {
    toggleEdit(!isEdit);
    setEditReplyId(reply.id);
    setReply(parseSpecialCharacter(reply.reply));
  };

  const deleteReply = async data => {
    await onDelete(data);
    setReply('');
  };

  let popupRatingComponent = null;
  if (hoverRating) {
    let ratingList = [...hoverRating.subRatings];
    popupRatingComponent = (
      <div className="popup-rating">
        {ratingList.map((rating, index) => (
          <SingleRating key={index} rating={rating} />
        ))}
      </div>
    );
  }

  let reviewTagComponet = [];
  if (isFeatured) {
    reviewTagComponet.push(
      <span key={'featured'} className="status-btn featured-btn">
        Featured
      </span>,
    );
  }
  if (hasRequestedForDelete) {
    reviewTagComponet.push(
      <span key={'requestSent'} className="status-btn featured-btn">
        Requested To Delete
      </span>,
    );
  }
  if (isGhost && (status === 1 || status === 3)) {
    reviewTagComponet.push(
      <span key={'recent'} className="status-btn featured-btn">
        Verified
      </span>,
    );
  }
  if (isRecentReview) {
    reviewTagComponet.push(
      <span key={'recent'} className="status-btn recent-btn">
        Recent
      </span>,
    );
  }
  // reply component setup
  let replyComponent = null;
  if (reply) {
    const andSeenOn =
      _.indexOf(['google', 'facebook'], _.get(reviewSource, 'slug', '')) > -1
        ? ` and ${_.get(reviewSource, 'title', '')}`
        : ``;
    replyComponent = (
      <>
        {!isEdit && (
          <div className="replyBox">
            <span className="reply">
              <img src={images.blackReturnIcon} alt="" />
              <span>Response from the Practice</span>
            </span>
            <div className="reply-text">{parseSpecialCharacter(reply.reply)}</div>
          </div>
        )}
        {isEdit && reply.id === editReplyId && (
          <div className="Confirmation">
            <div className="confirmation-content">
              <div className="textarea-wrapper">
                <textarea
                  name="reply_text"
                  onChange={e => setReply(e.target.value)}
                  value={replyText}
                  rows="4"></textarea>
              </div>
              <div className="confirmation-content-action">
                <CircleButton disabled={validateReply()} onClick={() => submitReply()}>
                  Confirm
                </CircleButton>{' '}
                <span>
                  Reply will be seen on Kaly{andSeenOn}
                  {addonText()}
                </span>
              </div>
            </div>
          </div>
        )}
        {_.indexOf(['google', 'facebook'], _.get(reviewSource, 'slug', '')) <= -1 && (
          <div className="replyaction">
            <span className="btn" onClick={() => openEditMode(reply)}>
              {isEdit ? <img src={images.closeBlue} alt="" /> : <img src={images.penBlue} alt="" />}
              <span className="link">{isEdit ? 'Cancel' : 'Edit'}</span>
            </span>
            {!isEdit && (
              <span
                className="btn"
                onClick={() => deleteReply({replyId: reply.id, reviewId: reviewId})}>
                <img src={images.deleteBlue} alt="delete" />
                <span className="link">Delete</span>
              </span>
            )}
          </div>
        )}
      </>
    );
  } else {
    const andSeenOn =
      _.indexOf(['google', 'facebook'], _.get(reviewSource, 'slug', '')) > -1
        ? ` and ${_.get(reviewSource, 'title', '')}`
        : ``;
    replyComponent = (
      <>
        {isOpen && (
          <div className="Confirmation">
            <div className="confirmation-content">
              <div className="textarea-wrapper">
                <textarea
                  name="reply_text"
                  onChange={e => setReply(e.target.value)}
                  value={replyText}
                  rows="4"></textarea>
              </div>
              <div className="confirmation-content-action">
                <CircleButton disabled={validateReply()} onClick={() => submitReply()}>
                  Confirm
                </CircleButton>
                <span>
                  Reply will be seen on Kaly{andSeenOn}
                  {addonText()}
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  const allKalypsysDetails = ratings.map((rating, index) => (
    <SingleRating key={index} rating={rating} onHover={handleHover} onHoverOut={handleHoverOut} />
  ));
  const nonKalypsysRating = (
    <div className="star-rating">
      <span className="name">Rating</span>
      <img className="rate-icon" src={images.starBlue} alt="star-rate" />
      <span className="number">{overallRating}</span>
    </div>
  );
  const ratingListComponent =
    _.indexOf(['kaly'], _.get(reviewSource, 'slug', '')) <= -1
      ? nonKalypsysRating
      : allKalypsysDetails;

  return (
    <div className="ReviewData" id={reviewId}>
      <div className="review-detail-innercontent">
        <div className="name">
          <span className="person-name">{name}</span>
          {reviewTagComponet}
          <span className="logo">
            <img alt="" src={reviewSourceIcon} />
          </span>
        </div>
        <div className="Description">
          <span className="datetime">{formatedCreateDate}</span>
          <ReadMore
            textString={parseSpecialCharacter(reviewText)}
            min={300}
            readMoreText="read more"
          />
          {replyComponent}
        </div>
        <div className="rating">
          <div className="rating-list">{ratingListComponent}</div>
          {hoverRating && popupRatingComponent}
          <div className="featured-review">
            {/* {!isGhost && (
              <CircleButton
                btnType={isFeatured ? 'whiteButton' : 'secondaryButton'}
                onClick={toggleFeatureType}>
                {isFeatured ? 'Remove' : ''} Feature Review
              </CircleButton>
            )} */}
            <CircleButton btnType="secondaryButton" onClick={deleteReview}>
              Delete Review
            </CircleButton>
          </div>
          <div className="delete-request">
            {hasRequestedForDelete && (
              <button className="btn-delete" onClick={rejectDeleteRequest}>
                Reject Request
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewData;
