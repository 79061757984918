import React, {Component, Fragment} from 'react';
import moment from 'moment';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import images from 'data/images';
import Input from 'components/FormControls/Input';
import SelectDate from 'components/FormControls/SelectDate';
import AppointmentItem from 'components/Patient/PatientDashboard/AppointmentItem';
import PatientLayout from 'layout/PatientLayout';
import ElasticSearch from 'components/FormControls/ElasticSearch';
import AuthContext from 'context/AuthContext';
import patientService from 'services/patient';
import modalConfig from 'modals/modalConfig';
import RootContext from 'context/RootContext';
import FeedItem from 'components/Patient/PatientDashboard/FeedItem';
import ReviewVisitModal from 'components/Patient/PatientDashboard/ReviewVisitModal';
import AppointmentConfirmedModal from 'components/Patient/PatientDashboard/AppointmentConfirmedModal';
import RescheduleAppointmentModal from 'components/Patient/PatientDashboard/RescheduleAppointmentModal';
import InsuranceFilterSelect from 'components/FormControls/InsuranceFilterSelect';
import helper from 'data/helper';
import momentHelper from 'data/moment/momentHelper';
import CircleButton from 'components/CircleButton';
import CancelAppointmentModal from 'components/Patient/PatientDashboard/CancelAppointmentModal';
import InsuranceInfoModal from 'components/Patient/PatientDashboard/InsuranceInfoModal';
import allRegex from 'data/regex';
import constants from 'data/constants';
import XLSX from 'xlsx';
import InfiniteScroll from 'components/InfiniteScroll';
import './PatientDashboard.css';
import Nux from 'config/Nux';
import analyticsData from 'data/analytics/analyticsData';
import InsuranceFilterSelectPlan from 'components/FormControls/InsuranceFilterSelect/InsuranceFilterSelectPlan';
import ShowProviderAvailability from 'components/ShowProviderAvailability';

const {Analytics} = analyticsData;
const {DEFAULT_MESSAGES} = constants;
const {exceptDigitsRegex} = allRegex;
const {focusElasticSearchBox, getGreetingTime, getSuffixString, getAppointmentClassFromStatus} =
  helper;
const {
  getMyAppointments,
  saveAppointmentInsurance,
  cancelAppointment,
  updateAppointmentTime,
  reviewAppointment,
  getMyNotifications,
  getMyNotificationCount,
  clearCompletedNotification,
  getAppointmentDetailsById,
  getMyProfileInsurance,
} = patientService;
const {modalFunctions} = modalConfig;
const {errorModalData, successModalData, blankModalData} = modalFunctions;
const {
  dateOrCurrentDate,
  checkIsDateAfterOrToday,
  utcToLocalMoment,
  localToUtcMoment,
  isAfter,
  bigDateTimeFormat,
} = momentHelper;
const {getLocation} = helper;

class PatientDashboard extends Component {
  static contextType = AuthContext;
  state = {
    appointmentType: 'upcoming',
    appointments: null,
    notifications: [],
    notificationCount: 0,
    isFetching: false,
    intialFetching: true,
    allFetched: false,
    scrolling: '',
    filterActive: '',
    filter: {
      selectedResult: null,
      zipCode: '',
      insurance: null,
      date: dateOrCurrentDate(),
    },
    userLatitude: null,
    userLongitude: null,
  };
  rootContext = null;
  limit = 10;

  componentDidMount() {
    getLocation(position => {
      if (Object.keys(position).length > 0) {
        this.setState({
          userLatitude: position.coords.latitude,
          userLongitude: position.coords.longitude,
        });
      }
    });
    this.getAndSetAppointments();
    this.getAndSetNotifications();
    this.openModalFromQueryParams();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  openModalFromQueryParams = async () => {
    const {location} = this.props;
    const query = new URLSearchParams(location.search);
    const type = query.get('type');
    const appointmentId = (query.get('id') || '').replace(exceptDigitsRegex, '');
    const types = ['reschedule', 'cancel', 'insurance'];
    if (types.includes(type) && appointmentId) {
      const response = await getAppointmentDetailsById(appointmentId).catch(this.handleError);
      if (response) {
        const {data: appointmentData} = response;
        const status = _.get(appointmentData, 'status');
        const insuranceCardFrontPhotoUrl = _.get(appointmentData, 'insuranceCardFrontPhotoUrl');
        const insuranceCardBackPhotoUrl = _.get(appointmentData, 'insuranceCardBackPhotoUrl');
        if ([0, 1].includes(status)) {
          if (type === 'reschedule') {
            this.openRescheduleAppointmentModal(appointmentData);
          }
          if (type === 'cancel') {
            this.openCancelAppointmentModal(appointmentData);
          }
          if (type === 'insurance' && (!insuranceCardFrontPhotoUrl || !insuranceCardBackPhotoUrl)) {
            this.openInsuranceInfoModal(appointmentData);
          }
        }
      }
    } else if (type === 'review' && appointmentId) {
      this.handleReviewAppointmentModal(appointmentId);
    }
  };

  handleScroll = event => {
    const elements = document.getElementsByClassName('PatientHeader');
    let height = 119;
    if (elements.length > 0) {
      height = elements[0].offsetHeight;
    }
    if (window.scrollY > height) {
      this.setState({scrolling: 'sticky-header'});
    } else {
      this.setState({scrolling: ''});
    }
  };

  handleError = error => {
    if (this.rootContext) {
      const {setGlobal} = this.rootContext;
      const {message} = error;
      setGlobal('modal', errorModalData(message));
    }
    return null;
  };

  handleClearCompletedNotification = async () => {
    const {setGlobal} = this.rootContext;
    const response = await clearCompletedNotification().catch(this.handleError);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getPaginatedNotifications();
          },
        }),
      );
    }
  };

  getPaginatedNotifications = async (lastId = 0) => {
    const response = await getMyNotifications({lastId, limit: this.limit}).catch(() => {});
    let stateData = {isFetching: false};
    let {notifications: oldNotificationsList} = this.state;
    if (lastId === 0) {
      oldNotificationsList = [];
      const countResponse = await getMyNotificationCount().catch(() => {});
      const {data: notificationCount} = countResponse;
      stateData = {
        ...stateData,
        allFetched: false,
        notificationCount,
        intialFetching: false,
      };
    }
    if (response) {
      const {data: notifications} = response;
      if (notifications.length < this.limit) {
        stateData = {
          ...stateData,
          allFetched: true,
          intialFetching: false,
        };
      }

      this.setState({
        ...stateData,
        notifications: [...oldNotificationsList, ...notifications],
      });
    }
  };

  getAndSetNotifications = async () => {
    this.getPaginatedNotifications().catch(() => {});
  };

  getAndSetAppointments = async (apType = null) => {
    const {appointmentType} = this.state;
    apType = apType || appointmentType;
    const response = await getMyAppointments(apType).catch(this.handleError);
    if (response) {
      const {data: appointments} = response;
      this.setState({
        appointments,
        appointmentType: apType,
      });
    }
  };
  getAndSetMyProfileInsurance = async () => {
    const {setAuthState} = this.context;
    const profileInsuranceResponse = await getMyProfileInsurance().catch(() => null);
    if (profileInsuranceResponse) {
      const {data: insurance} = profileInsuranceResponse;
      setAuthState(prevState => {
        return {
          ...prevState,
          auth: {
            ...prevState.auth,
            insurance,
          },
        };
      });
    }
  };
  handleAlgoSelection = event => {
    const {value: selectedResult} = event.target;
    if (selectedResult) {
      switch (selectedResult.type) {
        case 'specialty':
          if (selectedResult.algo) {
            this.props.history.push({
              pathname: `/specialist/${selectedResult.algo}`,
              state: {
                filter: {
                  selectedResult,
                },
                answers: [],
                specialityIds: [],
              },
            });
          }
          break;
        case 'doctor':
          this.props.history.push({
            pathname: `/doctor`,
            state: {
              filter: {
                selectedResult,
              },
              answers: [],
              specialityIds: [],
            },
          });
          break;
        case 'symptom':
          if (selectedResult.algo) {
            this.props.history.push({
              pathname: `/symptom/${selectedResult.algo}`,
              state: {
                filter: {
                  selectedResult,
                },
                answers: [],
                specialityIds: [],
              },
            });
          }
          break;
        default:
          break;
      }
    }
  };

  handleAppointmentCancellation = async data => {
    const {setGlobal} = this.rootContext;
    const response = await cancelAppointment(data).catch(this.handleErrorAndRefetch);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getAndSetAppointments();
            this.getAndSetNotifications();
          },
        }),
      );
    }
  };

  handleUpdateAppointmentInsuranceData = async data => {
    let passData = data;
    //   const {setAuthState} = this.context;
    const {setGlobal} = this.rootContext;
    const response = await saveAppointmentInsurance(data).catch(this.handleErrorAndRefetch);
    if (response) {
      const {message, data} = response;
      // const medicalFrontPhoto = _.get(data, 'medicalFrontPhoto');
      // const medicalBackPhoto = _.get(data, 'medicalBackPhoto');
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            // if (data.isSaveToProfile) {
            //   // update data to auth state
            //   let dataTobeUpdated = {
            //     medicalCompanyId: data.insuranceCompanyId,
            //     medicalPlanId: data.insurancePlanId,
            //     medicalMemberId: data.insuranceMemberId,
            //   };
            //   if (medicalFrontPhoto) {
            //     dataTobeUpdated = {
            //       ...dataTobeUpdated,
            //       medicalFrontPhoto: medicalFrontPhoto.fileName,
            //       medicalFrontPhotoUrl: medicalFrontPhoto.fileUrl,
            //     };
            //   }
            //   if (medicalBackPhoto) {
            //     dataTobeUpdated = {
            //       ...dataTobeUpdated,
            //       medicalBackPhoto: medicalBackPhoto.fileName,
            //       medicalBackPhotoUrl: medicalBackPhoto.fileUrl,
            //     };
            //   }
            //   setAuthState(prevState => {
            //     return {
            //       ...prevState,
            //       auth: {
            //         ...prevState.auth,
            //         insurance: {
            //           ...prevState.insurance,
            //           ...dataTobeUpdated,
            //         },
            //       },
            //     };
            //   });
            // }
            if (passData.isSaveToProfile === true) {
              this.getAndSetMyProfileInsurance();
            }
            this.getAndSetAppointments();
          },
        }),
      );
    }
  };

  handleViewAppointmentModal = async appointmentId => {
    const {setGlobal} = this.rootContext;
    const response = await getAppointmentDetailsById(appointmentId).catch(this.handleError);
    if (response) {
      const {data: appointment} = response;
      if (appointment.status === 1) {
        setGlobal(
          'modal',
          blankModalData({
            CustomComponent: AppointmentConfirmedModal,
            customComponentProps: {
              data: {
                appointment,
              },
              onRescheduleAppointmentModal: this.openRescheduleAppointmentModal,
            },
          }),
        );
      } else {
        const errorMessage = DEFAULT_MESSAGES.CAN_ONLY_VIEW_CONFIRMED_APPOINTMENT;
        setGlobal(
          'modal',
          errorModalData(errorMessage, {
            callBackOnClose: () => {
              this.getAndSetNotifications();
            },
          }),
        );
      }
    }
  };
  now = () => {
    let nowTemp = localToUtcMoment(new Date()).format();
    nowTemp = utcToLocalMoment(nowTemp);
    let nowHH = nowTemp.format('HH');
    let nowMM = nowTemp.format('mm');
    let rdata = new Date();
    rdata.setDate(nowTemp.format('DD'));
    rdata.setHours(nowHH);
    rdata.setMinutes(nowMM);
    return rdata;
  };
  openRescheduleAppointmentModal = appointment => {
    Nux.sendEvent(
      Analytics.Category.PatientDashboard,
      Analytics.Actions.Click,
      'appointmentReschedule',
    );
    const {setGlobal} = this.rootContext;
    const {appointmentStartDateTime} = appointment;
    const apStartDateTimeObj = utcToLocalMoment(appointmentStartDateTime);
    const isAppointmentRunning = !isAfter(apStartDateTimeObj, this.now());
    if (isAppointmentRunning) {
      this.handleError({
        message: DEFAULT_MESSAGES.RUNNING_APPOINTMENT_CAN_NOT_BE_RESCHEDULED,
      });
    } else {
      setGlobal(
        'modal',
        blankModalData({
          CustomComponent: RescheduleAppointmentModal,
          customComponentProps: {
            data: {
              appointment,
            },
            timeSelected: (dateString, timeObject) => {
              const dateTimeString = dateString + ' ' + timeObject.time;
              const utcDateTimeString = moment(dateTimeString, 'YYYY-MM-DD h:mm a')
                .utc()
                .format('YYYY-MM-DD HH:mm:ss');
              const providerAvailabilityId = _.get(appointment, 'providerAvailability.id', 0);
              this.handleAppointmentTimeUpdate({
                appointmentId: appointment.id,
                providerAvailabilityId,
                appointmentDateTime: utcDateTimeString,
              });
            },
          },
        }),
      );
    }
  };

  openInsuranceInfoModal = appointment => {
    const {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: InsuranceInfoModal,
        customComponentProps: {
          data: {
            appointment,
          },
          updateAppointmentInsuranceData: this.handleUpdateAppointmentInsuranceData,
        },
      }),
    );
  };

  openCancelAppointmentModal = appointment => {
    Nux.sendEvent(
      Analytics.Category.PatientDashboard,
      Analytics.Actions.Click,
      'appointmentCancel',
    );
    const {setGlobal} = this.rootContext;
    const {appointmentStartDateTime} = appointment;
    const apStartDateTimeObj = utcToLocalMoment(appointmentStartDateTime);
    const isAppointmentRunning = !isAfter(apStartDateTimeObj, this.now());
    if (isAppointmentRunning) {
      this.handleError({
        message: DEFAULT_MESSAGES.RUNNING_APPOINTMENT_CAN_NOT_BE_CANCELLED,
      });
    } else {
      setGlobal(
        'modal',
        blankModalData({
          CustomComponent: CancelAppointmentModal,
          customComponentProps: {
            data: {
              appointment,
            },
            onCancelAppointment: this.handleAppointmentCancellation,
          },
        }),
      );
    }
  };

  handleReviewAppointmentModal = async appointmentId => {
    const {setGlobal} = this.rootContext;
    const response = await getAppointmentDetailsById(appointmentId).catch(this.handleError);
    if (response) {
      const {data: appointment} = response;
      if (!appointment.review) {
        setGlobal(
          'modal',
          blankModalData({
            CustomComponent: ReviewVisitModal,
            customComponentProps: {
              data: {
                appointment,
              },
              onReviewSave: this.handleReviewSave,
            },
            modalWrapperClass: 'mega-modal',
          }),
        );
      } else {
        const errorMessage = DEFAULT_MESSAGES.REVIEW_ALREADY_SUBMITTED;
        setGlobal(
          'modal',
          errorModalData(errorMessage, {
            callBackOnClose: () => {
              this.getAndSetNotifications();
            },
          }),
        );
      }
    }
  };

  handleReviewSave = async data => {
    const {setGlobal} = this.rootContext;
    const response = await reviewAppointment(data).catch(this.handleErrorAndRefetch);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getAndSetAppointments();
            this.getAndSetNotifications();
          },
        }),
      );
    }
  };

  handleErrorAndRefetch = error => {
    const {setGlobal} = this.rootContext;
    const {message} = error;
    setGlobal(
      'modal',
      errorModalData(message, {
        callBackOnClose: () => this.getAndSetAppointments(),
      }),
    );
    return null;
  };

  handleAppointmentTimeUpdate = async data => {
    const {setGlobal} = this.rootContext;
    const response = await updateAppointmentTime(data).catch(this.handleErrorAndRefetch);
    if (response) {
      const {message} = response;
      setGlobal(
        'modal',
        successModalData(message, {
          callBackOnClose: () => {
            this.getAndSetAppointments();
          },
        }),
      );
    }
  };

  changeHandler = event => {
    const {name, value} = event.target;
    this.setState(prevState => {
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          [name]: value,
        },
      };
    });
  };

  showfilter = className => {
    this.setState({
      filterActive: className,
    });
  };

  handleElasticSearchFocus = () => {
    focusElasticSearchBox();
    this.showfilter('show-filter');
  };

  exportXls = () => {
    const filename = 'appoiintments.xlsx';
    const appointmentDataFirstRow = [
      'Doctor',
      'Patient Name',
      'Type',
      'Date',
      'Location',
      'Status',
      'Insurance',
      'Insurance Member Id',
    ];
    const {appointments} = this.state;
    const appointmentData = (appointments || []).map(appointment => {
      const {patient, appointmentStartDateTime, providerAvailability, isVirtual} = appointment;
      const {provider, practiceLocation} = providerAvailability;
      const startDatetime = moment.utc(appointmentStartDateTime).local();
      const patientFirstName = _.get(patient, 'firstName', '');
      const patientLastName = _.get(patient, 'lastName', '');
      const providerFirstName = _.get(provider, 'user.firstName', '');
      const providerLastName = _.get(provider, 'user.lastName', '');
      const suffixes = _.get(provider, 'suffixes', []);
      const status = _.get(appointment, 'status');
      const insurancePlanName = _.get(appointment, 'insurancePlan.plan', '');
      const insuranceMemberId = _.get(appointment, 'insuranceMemberId');
      const address = _.get(practiceLocation, 'address');
      const appointmentStatus = getAppointmentClassFromStatus(status);
      const appointmentType = isVirtual ? 'Video' : 'In Person';
      return [
        `${providerFirstName} ${providerLastName}${getSuffixString(suffixes)}`,
        `${patientFirstName} ${patientLastName}`,
        appointmentType,
        bigDateTimeFormat(startDatetime),
        address,
        _.capitalize(appointmentStatus),
        insurancePlanName,
        insuranceMemberId,
      ];
    });
    const sheet_name = 'Appointments';
    const mainFile = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet([appointmentDataFirstRow, ...appointmentData]);
    sheet['!cols'] = [
      {width: 25}, // Doctor
      {width: 15}, // Patient
      {width: 11}, // Type
      {width: 25}, // Date
      {width: 35}, // Location
      {width: 11}, // Status
      {width: 18}, // Insurance
      {width: 18}, // Insurance Member Id
    ];
    XLSX.utils.book_append_sheet(mainFile, sheet, sheet_name);
    XLSX.writeFile(mainFile, filename);
  };
  showError = () => {
    const {setGlobal} = this.rootContext;
    setGlobal(
      'modal',
      errorModalData('Please select any one Condition, specialty, procedure, doctor.'),
    );
  };
  handleRedirect = () => {
    Nux.sendEvent(Analytics.Category.PatientDashboard, Analytics.Actions.Click, 'findDoctor');
    const {history} = this.props;
    const {filter: stateFilter} = this.state;
    const {selectedResult} = stateFilter;
    localStorage.setItem('SearchValueKey', selectedResult.name);
    //setOrUpdateLocalData("searchValue",selectedResult.name)
    const {date, ...otherFilter} = stateFilter;
    const filter = {
      date: stateFilter['date'].format('YYYY-MM-DD'),
      ...otherFilter,
    };
    let finalData = {
      pathname: `/doctors`,
      state: {
        filter,
        answers: [],
        specialityIds: [],
      },
    };
    if (selectedResult) {
      switch (selectedResult.type) {
        case 'specialty':
          if (selectedResult.algo) {
            finalData.pathname = `/specialist/${selectedResult.algo}/${selectedResult.name}`;
          }
          break;
        case 'doctor':
          finalData.pathname = `/doctor`;
          break;
        case 'symptom':
          if (selectedResult.algo) {
            finalData.pathname = `/symptom/${selectedResult.algo}/${selectedResult.name}`;
          }
          break;
        case 'procedure':
          if (selectedResult.specialityIds) {
            if (selectedResult.algo) {
              finalData.pathname = `/procedure/${selectedResult.algo}/${selectedResult.name}`;
            } else {
              finalData.pathname = `/doctors`;
            }
          }
          break;
        default:
          break;
      }
    }
    history.push(finalData);
  };

  render() {
    const rootContextComponent = (
      <RootContext.Consumer>
        {context => {
          this.rootContext = context;
        }}
      </RootContext.Consumer>
    );
    const {
      appointmentType,
      appointments,
      notifications,
      notificationCount,
      scrolling,
      filter,
      filterActive,
      userLatitude,
      userLongitude,
      isFetching,
      allFetched,
      intialFetching,
    } = this.state;
    const {auth} = this.context;
    const dateConfig = {
      inputProps: {label: ''},
      datePickerProps: {name: 'date', value: filter.date, isValidDate: checkIsDateAfterOrToday},
      onDateChange: this.changeHandler,
      disableViewModeChange: true,
    };
    if (!appointments) {
      return rootContextComponent;
    }
    const inverseAppointmentType = appointmentType === 'upcoming' ? 'past' : 'upcoming';
    const noAppointmentCompoent = (
      <div className="no-appointment">
        <div className="no-appointment-content">
          <div className="no-appointment-img">
            <img src={images.calendarbg2} alt="" />
          </div>
          <div className="no-appointment-text">
            <span> No appointments have been booked yet.</span>
            <span>Find an amazing doctor today!</span>
          </div>
          <div className="finddoctor">
            <span onClick={this.handleElasticSearchFocus}>
              <img src={images.magnifierActive} alt="" /> Find a new doctor{' '}
            </span>
          </div>
        </div>
      </div>
    );
    let enableClearBtn = false;
    if (notifications.length > 0) {
      enableClearBtn = true;
      if (
        notifications.filter(n => n.slug === 'appointmentCompleted' && !n.reviewId).length ===
        notifications.length
      ) {
        enableClearBtn = false;
      }
    }
    return (
      <div className={`PatientDashboard ${scrolling}`}>
        {rootContextComponent}
        <PatientLayout hideFooter={true}>
          <div className={`filter ${filterActive}`}>
            <div className="logo">
              <Link to="/">
                <img src={images.logoWhite} alt="logo" />
              </Link>
            </div>
            <div className="search-warp" onClick={() => this.showfilter('show-filter')}></div>

            <ElasticSearch
              name="selectedResult"
              onSelect={this.changeHandler}
              selectedResult={filter['selectedResult']}
            />
            <Input
              label="Zip Code"
              translationType="transform"
              containerClass="zipcode"
              icon={images.mapslocation}
              name="zipCode"
              value={filter['zipCode']}
              onChange={this.changeHandler}
            />
            <SelectDate {...dateConfig} />
            {/* <InsuranceFilterSelect
              inputPlaceholder="Choose Insurance"
              inputIcon={images.guardian}
              containerClass="chooseinsurance"
              insurance={filter['insurance']}
              name="insurance"
              onChange={this.changeHandler}
            /> */}
            <InsuranceFilterSelectPlan
              inputPlaceholder="Choose Insurance"
              inputIcon={images.guardian}
              containerClass="chooseinsurance"
              insurance={filter['insurance']}
              name="insurance"
              onChange={this.changeHandler}
            />
            <button
              className="search-btn"
              // disabled={!filter['selectedResult']}
              onClick={!filter['selectedResult'] ? this.showError : this.handleRedirect}>
              <img src={images.magnifierWhite} alt="serach" className="mg-icon" />
              <img src={images.magnifierWhite} alt="serach" className="mghover-icon" />
              <span>Find Doctors</span>
            </button>
            <CircleButton onClick={() => this.showfilter('')}>Cancel</CircleButton>
          </div>
          <div className="patien-dashboard-wrapper">
            <div className="left-panle">
              <div className="user-name">
                <div>{getGreetingTime()}</div>
                <div className="capitalize">{auth.firstName}</div>
              </div>
              <div className="appointment-list">
                <div className="title">
                  <span className="app-list-detail">
                    <span className="calendar-icon">
                      <img
                        src={images.calendarCheck}
                        onClick={this.exportXls}
                        className="app-calender"
                        alt="check"
                      />
                    </span>
                    You have {appointments.length} {appointmentType} appointments
                  </span>
                  <span
                    onClick={() => {
                      this.getAndSetAppointments(inverseAppointmentType);
                    }}
                    className="past-appointment">
                    View {_.capitalize(inverseAppointmentType)} Appointments
                  </span>
                </div>
                {appointments.length > 0 ? (
                  <div className="dc-appointment-list">
                    {appointments.map(appointment => {
                      return (
                        <AppointmentItem
                          appointmentType={appointmentType}
                          key={appointment.id}
                          appointment={appointment}
                          onViewInsuranceInfoModal={this.openInsuranceInfoModal}
                          onViewCancelAppointmentModal={this.openCancelAppointmentModal}
                          onViewAppointmentModal={this.handleViewAppointmentModal}
                          onViewRescheduleAppointmentModal={this.openRescheduleAppointmentModal}
                          userLatitude={userLatitude}
                          userLongitude={userLongitude}
                        />
                      );
                    })}
                  </div>
                ) : (
                  noAppointmentCompoent
                )}
              </div>
            </div>
            {notifications && (
              <div className="right-panle">
                <div className="title">
                  <span>
                    My Kaly Feed <span className="feed-count">{notificationCount}</span>
                  </span>
                  <span
                    className={[
                      'clear-complete',
                      enableClearBtn === true ? 'active' : 'inactive',
                    ].join(' ')}
                    onClick={() => {
                      if (enableClearBtn === true) {
                        this.handleClearCompletedNotification();
                      }
                    }}>
                    Clear Reviewed Notifications
                  </span>
                </div>
                <div className="feed-list reviewed-notification-feed-list">
                  <InfiniteScroll
                    offset={400}
                    allFetched={allFetched}
                    onScrollToBotton={() => {
                      const lastId = _.get(notifications[notifications.length - 1], 'id');
                      this.getPaginatedNotifications(lastId);
                    }}
                    isFetching={isFetching}
                    setIsFetching={() => !isFetching && this.setState({isFetching: true})}>
                    {notifications.map((notification, index) => {
                      return (
                        <FeedItem
                          onViewAppointment={this.handleViewAppointmentModal}
                          onReviewAppointment={this.handleReviewAppointmentModal}
                          key={index}
                          notification={notification}
                        />
                      );
                    })}
                    {notifications.length === 0 && !intialFetching && (
                      <FeedItem notification={{createdAt: auth.createdAt, isPast: false}} />
                    )}
                    {intialFetching && (
                      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <div>Loading...</div>
                      </div>
                    )}
                  </InfiniteScroll>
                  <ShowProviderAvailability />
                </div>
              </div>
            )}
          </div>
        </PatientLayout>
      </div>
    );
  }
}

export default PatientDashboard;
