const questionList = [
  {
    id: 581,
    question: 'What type of pain condition do you have?',
    optionType: 2, // {1=>'single line options',2=>'select option'} // other will be default UI
    painOptionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,14], // painIds,
    nextQuestionId: 582,
    descriptions: [],
  },
  {
    id: 582,
    question: 'How long have you had pain for?',
    options: [
      {
        nextQuestionId: 583,
        label: 'Less than 3 weeks',
        shortLabel: '<3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 84, visitReasonIds: [194], extraQuestion: 1},
          {painId: 2, questionId: 163, visitReasonIds: [193], extraQuestion: 1},
          {painId: 3, questionId: 224, visitReasonIds: [196], extraQuestion: 1},
          {painId: 4, questionId: 43, visitReasonIds: [188], extraQuestion: 1},
          {painId: 5, questionId: 104, visitReasonIds: [189], extraQuestion: 1},
          {painId: 6, questionId: 123, visitReasonIds: [195], extraQuestion: 1},
          {painId: 7, questionId: 144, visitReasonIds: [190], extraQuestion: 1},
          {painId: 8, questionId: 23, visitReasonIds: [198], extraQuestion: 1},
          {painId: 9, questionId: 183, visitReasonIds: [191], extraQuestion: 1},
          {painId: 10, questionId: 24, visitReasonIds: [199], extraQuestion: 2},
          {painId: 11, questionId: 203, visitReasonIds: [192], extraQuestion: 1},
          {painId: 12, questionId: 64, visitReasonIds: [197], extraQuestion: 1},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
      {
        nextQuestionId: 583,
        label: 'More than 3 weeks',
        shortLabel: '>3 weeks',
        redirectQuestionIds: [
          {painId: 1, questionId: 83, visitReasonIds: [194], extraQuestion: 3},
          {painId: 2, questionId: 164, visitReasonIds: [193], extraQuestion: 3},
          {painId: 3, questionId: 225, visitReasonIds: [196], extraQuestion: 1},
          {painId: 4, questionId: 44, visitReasonIds: [188], extraQuestion: 3},
          {painId: 5, questionId: 103, visitReasonIds: [189], extraQuestion: 3},
          {painId: 6, questionId: 124, visitReasonIds: [195], extraQuestion: 3},
          {painId: 7, questionId: 143, visitReasonIds: [190], extraQuestion: 2},
          {painId: 8, questionId: 24, visitReasonIds: [198], extraQuestion: 2},
          {painId: 9, questionId: 184, visitReasonIds: [191], extraQuestion: 3},
          {painId: 10, questionId: 24, visitReasonIds: [199], extraQuestion: 2},
          {painId: 11, questionId: 204, visitReasonIds: [192], extraQuestion: 3},
          {painId: 12, questionId: 65, visitReasonIds: [197], extraQuestion: 1},
          {painId: 14, questionId: 3,visitReasonIds: [], extraQuestion: 1},
        ],
      },
    ],
    descriptions: [],
  },
  {
    id: 583,
    question: 'Do you still want to see an Interventional Sports Medicine Physician?',
    descriptions: [
      'An Interventional Sports Medicine Physician is a medical physician (MD/DO) who completed a 1 year fellowship in Sports Medicine, after completing a 3-4 year residency in Family Medicine, Internal Medicine or Physical Medicine and Rehabilitation. An Interventional Sports Medicine Physician has further training in treating musculoskeletal conditions that do not require surgery.',
    ],
    title: 'Interventional Sports Medicine Physician',
    optionType: 1,
    questionType: 1,
    canDoList: [
      'Can prescribe medications',
      'Can order diagnostic tests',
      'Can perform pain injections',
      'Can not perform invasive surgeries',
    ],
    options: [
      {groupId: 'Y', label: 'Yes', isGoToSymptomFlow: false},
      {label: 'No', isGoToSymptomFlow: true},
    ],
  },
];

export default {
  questionList,
  config: {
    firstQuestionId: 581,
    lastQuestionId: 583,
    treeDepth: 3,
  },
};
