import React, {useContext, useEffect} from 'react';
import _ from 'lodash';
//import patientService from 'services/patient';
import AppleLogin from 'react-apple-login';
import './AppleLoginButton.css';
import config from 'config/config';
import images from 'data/images';


const AppleLoginButton = props => {
  const {onLoginFailure, onLoginSuccess} = props;
 
  return (
    <AppleLogin
      clientId={config['APPLE_CLIENT_ID']}
      scope={'name email'}
      redirectURI={window.location.origin}
      responseType={'code'}
      responseMode={'form_post'}
      callback={(data, err) => {
        if (data) {
          if (!data.error){
            onLoginSuccess(data);
          } 
          else {
            onLoginFailure(data);
          }
        } else {
          // Error occurred while sign in
          console.log('err', err);
          onLoginFailure(err);
        }
      }}
      usePopup={true}      
      nonce="checkloginname"
      render={renderProps => (
        <span onClick={renderProps.onClick} className="FacebookLoginButton social-icon apple-login">
          <img src={images.apple} alt="google icon" className="apple-img" />
          Continue with Apple
        </span>
      //   <span onClick={()=>{onLoginSuccess({user:{name:{},email:'akhilarumanoor+01@gmail.com'}})}} className="FacebookLoginButton social-icon apple-login">
      //   <img src={images.apple} alt="google icon" className="apple-img" />
      //   Continue with Apple
      // </span>
      )}
    />
  );
};

export default AppleLoginButton;
