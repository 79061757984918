import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import './Input.css';
import images from 'data/images';

const Input = (props, ref) => {
  // translationType will be basic or translation
  const {
    label,
    icon,
    angle,
    containerClass = '',
    error = null,
    type,
    linkData,
    InputComponent = null,
    translationType = 'basic',
    instructionText = null,
    onFocus,
    onBlur,
    charcount = null,
    checkStatus = false,
    fieldStatus = '',
    ...otherInputProps
  } = props;
  // set value to blank string, if value is null or undefind
  otherInputProps.value = otherInputProps.value || '';
  otherInputProps.onChange = otherInputProps.onChange || (() => {});
  otherInputProps.onFocus=otherInputProps.onFocus||(() => {});
  if (ref) otherInputProps.ref = ref;
  const [passwordType, setPasswordType] = useState(type);
  const [isFocused, toggleFocusStatus] = useState(false);
  const [isPasswordField] = useState(type === 'password');
  const {id} = otherInputProps;

  const toggleType = () => {
    if (isPasswordField) {
      if (passwordType === 'password') {
        setPasswordType('text');
      } else if (passwordType === 'text') {
        setPasswordType('password');
      }
    }
  };

  const onInputFocus = e => {
    toggleFocusStatus(true);
    if (onFocus) {
      onFocus(e);
    }
  };
  const onInputBlur = e => {
    e.target.value = _.trim(e.target.value);
    toggleFocusStatus(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const getStatusIcon = () => {
    let imgIcon = '';
    switch (fieldStatus) {
      case 0: // pending
        imgIcon = <img src={images.minus} alt="" />;
        break;
      case 2: // rejected
        imgIcon = <img src={images.closeError} alt="" />;
        break;
      default:
        break;
    }
    return imgIcon;
  };

  let newContainerClass = containerClass;
  if (translationType === 'basic') {
    newContainerClass += ' form-group';
  } else if (translationType === 'transform') {
    otherInputProps.placeholder = label;
    newContainerClass += ' transform-input';
    if (isFocused || (otherInputProps.value && otherInputProps.value.length)) {
      newContainerClass += ' filled-focus-input';
    }
  }
  if (linkData) {
    newContainerClass += ' link-container';
  }
  if (error) {
    newContainerClass += ' error';
  }

  let labelComponent = label && (
    <span className="label" htmlFor={id}>
      {label}
      {checkStatus && getStatusIcon()}
    </span>
  );

  // custom input component
  let inputComponent = null;
  if (InputComponent) {
    inputComponent = (
      <InputComponent
        className="form-control"
        {...otherInputProps}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
      />
    );
  } else {
    inputComponent = (
      <input
        className="form-control"
        type={passwordType}
        autoComplete={passwordType === 'password' ? 'new-password' : 'off'}
        {...otherInputProps}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
      />
    );
  }

  return (
    <div className={`Input-control ${newContainerClass}`}>
      {translationType === 'basic' && labelComponent}
      {inputComponent}
      {translationType !== 'basic' && labelComponent}
      {icon && <img alt="" src={icon} onClick={toggleType} className="view-img" />}
      {angle && <img alt="" src={images.inputDownArrow} className="angle-up" />}
      {angle && <img alt="" src={images.inputDownArrow} className="angle-down" />}
      {linkData && linkData.link && (
        <Link to={linkData.link} target="_blank" className="lookup-link">
          {linkData.label}
        </Link>
      )}
      {linkData && linkData.externalLink && (
        <a
          href={linkData.externalLink}
          target="_blank"
          rel="noopener noreferrer"
          className="lookup-link">
          {linkData.label}
        </a>
      )}
      {error && !instructionText && <span className="error-text">{error}</span>}
      {instructionText && <span className="instruction-text">{instructionText}</span>}
      {charcount && <span className="charectercount-text">{charcount}</span>}
    </div>
  );
};

export default Input;
