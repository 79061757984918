import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';
import Input from '../Input';
import images from 'data/images';
import patientService from 'services/patient';
import RootContext from 'context/RootContext';
import masterService from 'services/master';
import helper from 'data/helper';
import painData from 'data/painData';
import './SearchPatients.css';

const { painList } = painData;
const { getAllPatientsSearchall } = patientService;
const { getAllSpecialties } = masterService;
const { getSuffixString, getHighlightedText, getLocation, getDistance } = helper;

const SearchPatients = props => {
  const {  selectedResult, onSelect } = props;
  const rootContext = useContext(RootContext);

  const [specialityLoading, setSpecialityLoading] = useState(false);
  const [patientList,setPatientList]= useState([]);

 
 const getAndSetResultList=useCallback(
  _.debounce(async value => {
    if (value.length > 1) {
      setSpecialityLoading(true);
      const response = await getAllPatientsSearchall(value).catch(() => null);
      console.log(response);
      if (response) {
        const { data } = response;
        setPatientList(data)
       
      }
      setSpecialityLoading(false);
    }
  },500))

  
  const [config, setConfig] = useState({
    searchText: _.get(selectedResult, 'name', ''),
    isFocused: false,
    searchResultList: null,
  });
  const { searchText, isFocused, searchResultList } = config;

  const handleSearchTextChange = event => {
    let { value } = event.target;
    value = value.length > 0 ? value.replace(/[^a-zA-Z0-9 ]/g, '') : ''
    //handleSelectedResult(null);
    getAndSetResultList(value);
    setConfig(prevConfig => {
      return {
        ...prevConfig,
        searchText: value,
        searchResultList: null,
      };
    });
  };

  const handleSelectedResult = data => {
    let isFocused = false;
    if (data && data.hide === false) {
      isFocused = true;
    }
    
    if (data) {
      setConfig(prevConfig => {
        return {
          ...prevConfig,
          searchText: `${data.firstName} ${data.lastName}`,
          isFocused: isFocused,
        };
      });
    }
    onSelect({...data});
  };

  const onClickOutSide = useCallback(() => {
    setConfig(prevConfig => {
      return {
        ...prevConfig,
        isFocused: false,
      };
    });
  }, []);
 

  const isShowFilterResult = searchResultList && isFocused;

 

  return (
    <div className="SearchPatients">
      <div className={`search ${isFocused ? 'active' : ''}`}>
       
        <Input
          name="searchText"
          label="Search Patient"
          value={searchText}
          icon={images.magnifier}
          onFocus={() => {
            setConfig({ ...config, isFocused: true });
          }}
          onChange={handleSearchTextChange}
          placeholder="Search patient name or email"
        />
        {isFocused && <div className="overlay" onClick={onClickOutSide}></div>}

        {isFocused && !searchResultList && !specialityLoading && patientList.length > 0 && (
          <div className="search-result">
            
           
              <div className="search-category">
                
                {patientList.map(sp => {
                  return (
                    <div
                      onClick={() => handleSelectedResult(sp)}
                      key={sp.id}
                      className="search-item">
                      <p><span>{sp.firstName}</span><span>{sp.lastName}</span></p>
                      <p className='email-ptag'>{sp.email}</p>
                    </div>
                  );
                })}
              </div>
          
          </div>
        )}
       
        {isFocused && specialityLoading && (
          <div className="search-result">
            <div className="loader">
              <span>
                <img src={images.logosymbol} alt="logo symbol" />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPatients;
