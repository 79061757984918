import React from 'react';
import PracticeLogo from 'components/AfterAuth/Settings/PracticeTab/PracticeLogo';
import PracticeOverview from 'components/AfterAuth/Settings/PracticeTab/PracticeOverview';
import PracticeLocations from 'components/AfterAuth/Settings/PracticeTab/PracticeLocations';
import PatientRegistration from 'components/AfterAuth/Settings/PracticeTab/PatientRegistration';
import './PracticeTab.css';

const PracticeTab = props => {
  const {
    practice,
    afterFileChange,
    onError,
    onPracticeOverviewSave,
    saveLocation,
    deleteLocation,
    hasPermissionToEdit,
  } = props;
  const {practiceLocations: locationList = []} = practice;
  const {logoUrl} = practice;
  const {patientFormUrl} = practice;
  const {name, description, websites, software} = practice;

  const practiceOverViewData = {name, description, websites, software};
  return (
    <div className="PracticeTab">
      <div className="leftpanle">
        <PracticeLogo
          defaultFileUrl={logoUrl}
          uploadServerUrl={`practice/upload/logo`}
          onError={onError}
          afterUpload={newURL => afterFileChange('logoUrl', newURL)}
          onRemove={() => afterFileChange('logoUrl', null)}
          hasPermissionToEdit={hasPermissionToEdit}
        />
        <PracticeOverview
          data={practiceOverViewData}
          onFormSubmit={onPracticeOverviewSave}
          hasPermissionToEdit={hasPermissionToEdit}
        />
        <PatientRegistration
          defaultFileUrl={patientFormUrl}
          uploadServerUrl={`practice/upload/form`}
          onError={onError}
          afterUpload={newURL => afterFileChange('patientFormUrl', newURL)}
          onRemove={() => afterFileChange('patientFormUrl', null)}
          hasPermissionToEdit={hasPermissionToEdit}
        />
      </div>
      <div className="rightpanle">
        <PracticeLocations
          locationList={locationList}
          onSaveLocation={saveLocation}
          onDeleteLocation={deleteLocation}
          hasPermissionToEdit={hasPermissionToEdit}
        />
      </div>
    </div>
  );
};

export default PracticeTab;
