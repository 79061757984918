import React, {useState} from 'react';
import Overlay from 'components/Overlay';
import images from 'data/images';
import MyLink from 'components/MyLink';
import './ReviewSourceList.css';

const ReviewSourceList = props => {
  const [activeId, toggleActive] = useState(0);
  const {sourceList = [], onSourceSelect, onDelete, onRefresh} = props;
  if (sourceList.length === 0) {
    return null;
  }
  const handleSetActive = id => {
    if (id === activeId) {
      toggleActive(0);
    } else {
      toggleActive(id);
    }
  };
  const getStatusImage = (status, isReviewFetched) => {
    let statusImage = images.minus;
    switch (status) {
      case 0:
        // pending
        statusImage = images.minus;
        break;
      case 1:
        if (isReviewFetched) {
          // Live
          statusImage = images.checkGreen;
        } else {
          statusImage = images.yellowCircle;
        }
        break;
      case 2:
        // rejected
        statusImage = images.closeError;
        break;
      default:
        break;
    }
    return statusImage;
  };

  return (
    <div className="ReviewSourceList">
      <Overlay className="overlay" show={!!activeId} onClick={() => handleSetActive(0)} />
      <div className="Rtable">
        <div className="Rtable-row Rtable-row--head">
          <div className="Rtable-cell date-cell column-heading">Source</div>
          <div className="Rtable-cell staus-cell column-heading">Status</div>
          <div className="Rtable-cell access-link-cell column-heading">Review Site</div>
          <div className="Rtable-cell action-cell column-heading"></div>
        </div>
        {sourceList.map(source => {
          const {
            id,
            reviewSource: {title: sourceName = ''},
            url,
            status,
            isReviewFetched,
          } = source;
          return (
            <div key={id} className="Rtable-row">
              <div className="Rtable-cell date-cell content-cell">{sourceName}</div>
              <div className="Rtable-cell staus-cell content-cell">
                <img src={getStatusImage(status, isReviewFetched)} alt="" />
              </div>
              <div className="Rtable-cell access-link-cell content-cell">
                <MyLink to={url}>{url}</MyLink>
              </div>
              <div className="Rtable-cell action-cell content-cell">
                <div className={`action-menu ${activeId === id ? 'show-menu' : ''}`}>
                  <span className="action-btn" onClick={() => handleSetActive(id)}>
                    <img src={images.threeDot} alt="" />
                  </span>
                  <span className="dropdown-menu">
                    {status === 1 &&sourceName!=='Google' && (
                      <span onClick={() => onRefresh(id, toggleActive)}>Refresh Reviews</span>
                    )}
                    {sourceName!=='Google'&&(
                    <span onClick={() => onSourceSelect(source)}>Edit</span>
                    )}
                    <span onClick={() => onDelete(sourceName!=='Google'?id:sourceName)} className="remove-user">
                      Delete
                    </span>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReviewSourceList;
