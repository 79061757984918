import React, {useState, useContext, useRef} from 'react';
import RootContext from 'context/RootContext';
import CircleButton from 'components/CircleButton';
import Textarea from 'components/FormControls/Textarea';
import validators from 'data/validators';
import images from 'data/images';
import './Editfaq.css';
import faqService from 'services/faq';
import modalConfig from 'modals/modalConfig';
import _ from 'lodash';
import Select from 'components/FormControls/Select';
import { Editor } from '@tinymce/tinymce-react';
import { toast, ToastContainer } from 'react-toastify';
const {__required} = validators;
const {addFaq} = faqService;
const {modalFunctions} = modalConfig;
const {successModalData,errorModalData} = modalFunctions;

const EditFaq = props => {
  const {data, specialtyList,updateSubmit} = props;
  const rootContext = useContext(RootContext);
  const {setGlobal} = rootContext;
  const subIndex = _.get(data, 'subIndex', null);
  const editorRef = useRef(null);
  const [formData, setFormData] = useState({
    values: {
      speciality: _.get(data, 'id', ''),
      question: _.get(data, 'question', ''),
      answer: _.get(data, 'answer', ""),
    },
    errors: {serverError: null},
  });
  const [tinymcFocus, setTinymcFocus] = useState(false)
  const {values: formValues, errors: formErrors} = formData;
  const copyToClipboard = (str,type) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.style.width = '0px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success(`${str} has been copied to the clipboard.`);
  };
  const __validatePlanName = (value = null) => {
    const fValue = value !== null ? value : formValues['speciality'];
    if (!__required(fValue)) {
      return 'Specialty is required';
    }
    return null;
  };

  const __validateQuestion = (value = null) => {
    const fValue = value !== null ? value : formValues['question'];
    if (!__required(fValue)) {
      return 'Question is required';
    }
    return null;
  };
  const __validateAnswer = (value = null) => {
    const fValue = editorRef.current?editorRef.current.getContent({ format: 'text' }):'';
    if (!__required(fValue)) {
      return 'Answer is required';
    }
    return null;
  };

  const changeHandler = event => {
    let {name, value} = event.target;
    let errorObject = {
      [name]: null,
    };
    switch (name) {
      case 'speciality':
        errorObject = {
          [name]: __validatePlanName(value),
        };
        break;
      case 'question':
        errorObject = {
          [name]: __validateQuestion(value),
        };
        break;
      case 'answer':
        errorObject = {
          [name]: __validateAnswer(value),
        };
        break;
      default:
        break;
    }
   
    setFormData(prevState => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [name]: value,          
        },
        errors: {
          ...prevState.errors,
          ...errorObject,
        },
      };
    });
  };

  const closeModal = () => {
    setGlobal('modal', {showModal: false});
  };

  const __validateForm = () => {
    const validatePlanName = !__validatePlanName();
    const validateCompany = !__validateQuestion();
    const validateAnswer=!__validateAnswer();
    return validatePlanName && validateCompany && validateAnswer;
  };

  const isValidateForm = __validateForm();

  const crossIconComponent = (
    <span className="cross-icon" onClick={closeModal}>
      <img src={images.close} alt="Close" title="Close" />
    </span>
  );

  const dialogHeader = (
    <div className="dailog-header">
      <div className="heading1">
        {subIndex!==null
          ? `Update Faq : ${_.get(data, 'plan', '')}`
          : 'Add New Faq'}
      </div>
      {crossIconComponent}
    </div>
  );

  const getGeneralInputProps = controlName => {
    return {
      onChange: changeHandler,
      onBlur: changeHandler,
      error: formErrors[controlName],
      value: formValues[controlName],
      name: controlName,
    };
  };
  const handleError = error => {
    const {message} = error;
    setGlobal('modal', errorModalData(message));
    return null;
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const {updateList} = props;
    let response = null;
    let reqObj = {
      speciality: formValues['speciality'],
      faqQuestion: formValues['question'],
      faqAnswer: formValues['answer'],
    };
    // console.log("reqObj",reqObj);
    // return
    if (subIndex!==null) {
      updateSubmit(subIndex, reqObj)
      return
     // response = await updateFaq(subIndex, reqObj).catch(handleError);
    } else {
      response = await addFaq(reqObj).catch(handleError);
    }
    if (response) {
      const {message} = response;
      setGlobal('modal', successModalData(message));
      updateList();
    }
  };
const editerChange=(ed)=>{
  changeHandler({target:{name:'answer',value:ed.getContent()}})
}
  const renderInputElements = () => {
    return (
      <>
        
        <Select
          {...getGeneralInputProps('speciality')}
          options={specialtyList}
          labelIndex="specialty"
          valueIndex="id"
          label="Specialty"
        />
        <div className="dynamic-variables">
          <label className="main-label">Dynamic Variables</label>
          <div className="variables">
            <ul>
              <li>{`{{cityName}}`} {<img className='copy-img' onClick={() => copyToClipboard('{{cityName}}')} style={{width:"20px",cursor:"pointer"}} src={images.copy} />}</li>
              </ul> 
       </div>
        </div>
        
         <Textarea
          containerClass="textarea-content"
          {...getGeneralInputProps('question')}
          label="Question"
          ></Textarea>
        <div className="faqEditor">
         <label>Answer</label>
       
       <div className="editer-div">
        <Editor
         onInit={(evt, editor) => editorRef.current = editor}
         initialValue=""
         name={'answer'}
        content={formValues['answer']}
     
         init={{
           height: 250,
           menubar: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           block_formats: 'Header 1=h1; Header 2=h2; Header 3=h3; Paragraph=p;',
           setup:function(ed) {
            ed.on('change', function(e) {
              editerChange(ed)
            });
            ed.on('focus', function(e) {
              editerChange(ed)
              setTinymcFocus(true)
          });
          ed.on('keyup', function(e) {
            editerChange(ed)
        });
          ed.on('blur', function(e) {
            editerChange(ed)
        });
  
        },
           toolbar: 'undo redo | formatselect | ' +
           'bold italic | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
           init_instance_callback : function(editor) {
            var freeTiny = document.querySelector('.tox .tox-notification--in');
            if(freeTiny&& freeTiny.style)
            freeTiny.style.display = 'none';
           editor.setContent(formValues['answer']);
           changeHandler({target:{name:'answer',value:formValues['answer']}})
          }
         }}
       />
       {tinymcFocus && __validateAnswer() &&(
        <span className="validation-error">{__validateAnswer()}</span>
       )}
       
       </div>
        </div>
      </>
    );
  };

  return (
    <div className="Editfaq no-select">
      <div className="modal-body-wrapper">
        {dialogHeader}
        <div className="dailog-body">
          <form onSubmit={handleSubmit}>{renderInputElements()}</form>
        </div>
        <div className="action-btn">
          <CircleButton onClick={closeModal} btnType="secondaryButton">
            Cancel
          </CircleButton>
          <CircleButton btnType="whiteButton" disabled={!isValidateForm} onClick={handleSubmit}>
            {subIndex!==null ? 'Update' : 'Add'}
          </CircleButton>
        </div>
        <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      </div>
    </div>
  );
};

export default EditFaq;
