import reactIcon from 'static/images/react-icon.png';
import DoctorImage from 'static/images/doctor.png';
import logo from 'static/images/logo.svg';
import dashboard from 'static/images/dashboard.png';
import fairOwl from 'static/images/fair-owl.svg';
import patientOwl from 'static/images/patient-owl.svg';
import dataOwl from 'static/images/data-owl.svg';
import professionalOwl from 'static/images/professional-owl.svg';
import facebook from 'static/images/facebook.svg';
import twitter from 'static/images/twitter.svg';
import linkedIn from 'static/images/linkedIn.svg';
import instagram from 'static/images/instagram.svg';
import bannerBackground from 'static/images/banner-background.png';
import team1 from 'static/images/team1.jpg';
import team2 from 'static/images/team2.jpg';
import team3 from 'static/images/team3.jpg';
import groupdoctor2 from 'static/images/group-doctor2.png';
import groupdoctor from 'static/images/group-doctor.png';
import shapemask from 'static/images/shape-mask.svg';
import rating from 'static/images/rating.png';
import eyeView from 'static/images/eye-view.svg';
import blueCross from 'static/images/cross-blue.svg';
import uploadCloud from 'static/images/cloudIcon.svg';
import googleLogo from 'static/images/google-logo.png';
import star from 'static/images/star.svg';
import starBlue from 'static/images/star-blue.svg';
import user from 'static/images/user.png';
import designyellow from 'static/images/designyellow.svg';
import arrowPointer from 'static/images/arrow-pointer-down.svg';
import blackArrowPointer from 'static/images/black-arrow-pointer-down.svg';
import whiteArrowPointer from 'static/images/white-arrow-pointer-down.svg';
import close from 'static/images/close.svg';
import search from 'static/images/search.svg';
import searchWhite from 'static/images/search-white.svg';
import downArrow from 'static/images/down-arrow.svg';
import upArrow from 'static/images/up-arrow.svg';
import envelope from 'static/images/envelope.svg';
import dashboardIcon from 'static/images/dashboard-icon.svg';
import scheduleIcon from 'static/images/schedule-icon.svg';
import scheduleWhiteIcon from 'static/images/schedule-white-icon.svg';
import settingIcon from 'static/images/setting-icon.svg';
import settingGray from 'static/images/setting-gray.svg';
import settingWhite from 'static/images/setting-white.svg';
import keyGray from 'static/images/key-gray.svg';
import logoutGray from 'static/images/logout-gray.svg';
import logoutWhite from 'static/images/logout-white.svg';
import starIcon from 'static/images/star-icon.svg';
import angleLeft from 'static/images/ic-chevron-left.svg';
import angleRight from 'static/images/ic-chevron-right.svg';
import calender from 'static/images/calender.svg';
import deleteIcon from 'static/images/delete.svg';
import logoIcon from 'static/images/logo-icon.svg';
import repeatIcon from 'static/images/repeat.svg';
import clockIcon from 'static/images/clock.svg';
import timerIcon from 'static/images/timer.svg';
import markerIcon from 'static/images/marker.svg';
import angleDown from 'static/images/angle-down.svg';
import returnIcon from 'static/images/return.svg';
import blackReturnIcon from 'static/images/black-return-icon.svg';
import closePink from 'static/images/close-pink.svg';
import correctIcon from 'static/images/correct.svg';
import whiteClose from 'static/images/close-white.svg';
import threeDot from 'static/images/three-dot.svg';
import plusBlue from 'static/images/cross-blue2.svg';
import closeBlack from 'static/images/close-black.svg';
import arrowLeftBlue from 'static/images/arrows-left-blue.svg';
import closeGray from 'static/images/close-gray.svg';
import calenderBg from 'static/images/calender-bg.svg';
import arrowLeft from 'static/images/arrows-left.svg';
import logosymbol from 'static/images/logosymbol.svg';
import defaultPracticeLogo from 'static/images/logosymbol.svg';
import facebookblue from 'static/images/facebookblue.svg';
import Googleicon from 'static/images/Googleicon.svg';
import mapslocation from 'static/images/mapslocation.svg';
import magnifier from 'static/images/magnifier.svg';
import calendar2 from 'static/images/calendar2.svg';
import guardian from 'static/images/guardian.svg';
import info from 'static/images/info.svg';
import leftArrow from 'static/images/left-arrow.svg';
import rightArrow from 'static/images/right-arrow.svg';
import rightWhiteArrow from 'static/images/right-white-arrow.svg';
import checkmark from 'static/images/checkmark.svg';
import landingImage from 'static/images/landing-image.png';
import dcartical1 from 'static/images/dc-artical1.png';
import dcartical2 from 'static/images/dc-artical2.jpg';
import dcartical3 from 'static/images/dc-artical3.jpg';
import design from 'static/images/design.svg';
import design2 from 'static/images/design2.svg';
import design3 from 'static/images/design-top.png';
import owl2x from 'static/images/owl2x.svg';
import dotshapemark from 'static/images/dotshapemark.svg';
import doctor1 from 'static/images/doctor11.jpg';
import doctor2 from 'static/images/doctor2.png';
import doctor3 from 'static/images/doctor3.png';
import doctor4 from 'static/images/doctor4.png';
import bell from 'static/images/bell.svg';
import appointmentCancel from 'static/images/appointmentCancel.svg';
import fillStar from 'static/images/fillstar.svg';
import calendarbg2 from 'static/images/calendarbg-2.svg';
import magnifierActive from 'static/images/magnifier-active.svg';
import profile from 'static/images/profile.svg';
import magnifierWhite from 'static/images/magnifier-white.svg';
import uploadCloud2 from 'static/images/upload-cloud.svg';
import uploadCloud3 from 'static/images/upload-cloud3.svg';
import logoWhite from 'static/images/logo-white.svg';
import calendarCheck from 'static/images/calendarCheck.svg';
import practiceShade1 from 'static/images/practice-shade1.svg';
import practiceShade2 from 'static/images/practice-shade2.svg';
import practiceShade3 from 'static/images/practice-shade3.svg';
import practiceShade4 from 'static/images/practice-shade4.svg';
import map from 'static/images/map.svg';
import mapActive from 'static/images/map-active.svg';
import reviewStar from 'static/images/review-star.svg';
import reviewStarPatient from 'static/images/review-star-patient.svg';
import videoCamera from 'static/images/video-camera.svg';
import videoCameraWhite from 'static/images/video-camera-white.svg';
import videoIcon from 'static/images/video-icon.svg';
import personIcon from 'static/images/person-icon.svg';
import personBtn from 'static/images/in-person-btn.svg';
import videoBtn from 'static/images/video-btn.svg';
import podiatrists from 'static/images/podiatrists.png';
import physicalTherapist from 'static/images/physical-therapists.png';
import orthopedicSurgeon from 'static/images/orthopedic-surgeon.png';
import neurologist from 'static/images/neurologist.png';
import dentist from 'static/images/dentist.png';
import chiropractors from 'static/images/chiropractors.png';
import stethoscope from 'static/images/stethoscope.png';
import dermatologist from 'static/images/dermatologist.png';
import orthopedicSportsSurgeon from 'static/images/orthopedicSportsSurgeon.png';
import Reviewlogos from 'static/images/review-logos.png';
import minus from 'static/images/minus.svg';
import check from 'static/images/check.svg';
import checkGreen from 'static/images/check-green.svg';
import closeError from 'static/images/close-error.svg';
import stripe from 'static/images/stripe.png';
import card from 'static/images/card.svg';
import owl from 'static/images/owl.png';
import facebookicon from 'static/images/facebook-icon.png';
import fillstarYellow from 'static/images/fillstar-yellow.svg';
import Certificate from 'static/images/Certificate-icon.svg';
import experienceDoctors from 'static/images/experience-doctors.png';
import practiceShade22 from 'static/images/practice-shade22.png';
import arrowPointerBlack from 'static/images/arrowPointerBlack.svg';
import arrowPointerWhite from 'static/images/arrowPointerWhite.svg';
import practiceShadebg from 'static/images/practice-shadebg.png';
import TakingPoint2 from 'static/images/TakingPoint2.png';
import yellowCircle from 'static/images/yellow-circle.svg';
import okthanks from 'static/images/okthanks.svg';
import editPen from 'static/images/pen.svg';
import send from 'static/images/send.svg';
import arrowDownFilledTriangle from 'static/images/arrow-down-filled-triangle.svg';
import arrowUpFilledTriangle from 'static/images/arrow-up-filled-triangle.svg';
import edit from 'static/images/edit.svg';
import check2 from 'static/images/check2.svg';
import profile2 from 'static/images/profile2.svg';
import calendar1 from 'static/images/calendar1.svg';
import inputDownArrow from 'static/images/input-up-arrow.svg';
import reviewLogo1 from 'static/images/review-logos_01.png';
import reviewLogo2 from 'static/images/review-logos_02.png';
import reviewLogo3 from 'static/images/review-logos_03.png';
import reviewLogo4 from 'static/images/review-logos_04.png';
import closeBlue from 'static/images/close-blue.svg';
import deleteBlue from 'static/images/delete-blue.svg';
import penBlue from 'static/images/pen-blue.svg';
import starRate from 'static/images/star-rate.png';
import description from 'static/images/description.svg';
import descriptionWhite from 'static/images/description-white.svg';
import scaleScreen from 'static/images/scale-screen.svg';
import videoCameraChat from 'static/images/video-camera-chat.svg';
import phoneCall from 'static/images/phone-call.svg';
import voice from 'static/images/voice.svg';
import voiceDisabled from 'static/images/voice-disabled.svg';
import videoCameraDisabled from 'static/images/video-camera-disabled.svg';
import personBlue from 'static/images/person-blue.svg';
import chatBlue from 'static/images/chat-blue.svg';
import personGray from 'static/images/person-gray.svg';
import chatGray from 'static/images/chat-gray.svg';
import arrowDownBlue from 'static/images/down-arrow-blue.svg';
import orangeCross from 'static/images/cross-orange.svg';
import redCross from 'static/images/cross-red.svg';
import document from 'static/images/document.svg';
import waitingRoom from 'static/images/waiting-room.svg';
import cameraWhite from 'static/images/combined-shape.svg';
import sendMsgBlack from 'static/images/send-right-icon.svg';
import dentistSquare from 'static/images/dentistSquare.png';
import chiropractorSquare from 'static/images/chiropractorSquare.png';
import deleteRound from 'static/images/deleteRound.svg';
import copy from 'static/images/copy.svg';
import phone from 'static/images/phone.svg';
import email from 'static/images/email.svg';
import arrowBlue from 'static/images/arrow_blue_icon.svg';
import photoID from 'static/images/id_card_identity_icon.svg';
import PhysicalTherapists from 'static/images/PhysicalTherapists.svg';
import starGold from 'static/images/stargold.svg';
import videovisit from 'static/images/videovisit.svg';
import doctor5 from 'static/images/doctor5.png';
import builddoctor1 from 'static/images/builddoctor1.png';
import builddoctor2 from 'static/images/builddoctor2.png';
import builddoctor3 from 'static/images/builddoctor3.png';
import builddoctor4 from 'static/images/builddoctor4.png';
import footerLogo from 'static/images/footerlogo.png';
import logohiddenttopright from 'static/images/logohiddenttopright.png';

import logohiddenbottomleft from 'static/images/logohiddenbottomleft.png';
import listyourPractice from 'static/images/listyour-practice.jpg';
import doctorprofileimg from 'static/images/doctor-profileimg.png';
import advisoryboard1 from 'static/images/advisoryboard1.jpg';
import advisoryboard2 from 'static/images/advisoryboard2.jpg';
import advisoryboard3 from 'static/images/advisoryboard3.jpg';
import advisoryboard4 from 'static/images/advisoryboard4.png';
import advisoryboard5 from 'static/images/advisoryboard5.jpg';
import advisoryboard6 from 'static/images/advisoryboard6.jpg';
import advisoryboard7 from 'static/images/advisoryboard7.jpg';
import advisoryboard8 from 'static/images/advisoryboard8.jpg';
import advisoryboard9 from 'static/images/advisoryboard9.jpg';
import advisoryboard10 from 'static/images/advisoryboard10.jpg';
import practice_bg1 from 'static/images/practice_bg1.svg';
import practice_bg2 from 'static/images/practice_bg2.svg';
import hiddendesign3 from 'static/images/hiddendesign3.png';
import okstar from 'static/images/okstar.png';
import ok from 'static/images/ok.png';
import healthgrades from 'static/images/healthgrades.svg';
import google from 'static/images/google.svg';
import file from 'static/images/file.svg';
import apple from 'static/images/apple.svg';
import advisoryboard11 from 'static/images/advisoryboard11.jpeg';
import advisoryboard12 from 'static/images/advisoryboard12.jpeg';
import advisoryboard13 from 'static/images/advisoryboard13.jpg';
import applestore from 'static/images/apple-store.svg';
import androidstore from 'static/images/android-store.svg';
import graph_icon from 'static/images/graph_icon.png';
import print from 'static/images/print.png';
import feedbackok from 'static/images/feedback.svg';
import likeIcon from 'static/images/like.svg';
import UnlikeIcon from 'static/images/unLike.svg';
import likeIconGreen from 'static/images/like_green.svg';
import UnlikeIconRed from 'static/images/unlike_red.svg';
import likeFill from 'static/images/like-fill.png';
import invite_icon from 'static/images/invite_icon.svg';
import documentplus from 'static/images/documentplus.svg';
const images = {
  calendarCheck,
  reactIcon,
  DoctorImage,
  logo,
  dashboard,
  fairOwl,
  patientOwl,
  dataOwl,
  professionalOwl,
  facebook,
  twitter,
  instagram,
  bannerBackground,
  team1,
  team2,
  team3,
  groupdoctor,
  groupdoctor2,
  shapemask,
  rating,
  eyeView,
  blueCross,
  uploadCloud,
  googleLogo,
  star,
  starBlue,
  user,
  arrowPointer,
  blackArrowPointer,
  whiteArrowPointer,
  close,
  search,
  searchWhite,
  downArrow,
  upArrow,
  envelope,
  dashboardIcon,
  scheduleIcon,
  scheduleWhiteIcon,
  settingIcon,
  settingGray,
  settingWhite,
  keyGray,
  logoutGray,
  logoutWhite,
  starIcon,
  angleLeft,
  angleRight,
  calender,
  deleteIcon,
  logoIcon,
  repeatIcon,
  clockIcon,
  timerIcon,
  markerIcon,
  angleDown,
  returnIcon,
  blackReturnIcon,
  closePink,
  correctIcon,
  whiteClose,
  threeDot,
  plusBlue,
  closeBlack,
  arrowLeftBlue,
  closeGray,
  calenderBg,
  arrowLeft,
  logosymbol,
  defaultPracticeLogo,
  facebookblue,
  Googleicon,
  mapslocation,
  magnifier,
  calendar2,
  guardian,
  info,
  leftArrow,
  rightArrow,
  rightWhiteArrow,
  checkmark,
  dcartical1,
  dcartical2,
  owl2x,
  dotshapemark,
  landingImage,
  doctor1,
  doctor2,
  doctor3,
  doctor4,
  bell,
  appointmentCancel,
  fillStar,
  calendarbg2,
  magnifierActive,
  profile,
  magnifierWhite,
  uploadCloud2,
  uploadCloud3,
  logoWhite,
  practiceShade1,
  practiceShade2,
  practiceShade3,
  practiceShade4,
  map,
  mapActive,
  reviewStar,
  reviewStarPatient,
  videoCamera,
  videoCameraWhite,
  videoIcon,
  personIcon,
  personBtn,
  videoBtn,
  podiatrists,
  physicalTherapist,
  orthopedicSurgeon,
  neurologist,
  dentist,
  chiropractors,
  stethoscope,
  dermatologist,
  orthopedicSportsSurgeon,
  Reviewlogos,
  minus,
  check,
  checkGreen,
  closeError,
  stripe,
  card,
  owl,
  facebookicon,
  fillstarYellow,
  Certificate,
  experienceDoctors,
  practiceShade22,
  arrowPointerBlack,
  arrowPointerWhite,
  practiceShadebg,
  TakingPoint2,
  yellowCircle,
  editPen,
  arrowDownFilledTriangle,
  arrowUpFilledTriangle,
  send,
  edit,
  check2,
  calendar1,
  profile2,
  inputDownArrow,
  reviewLogo1,
  reviewLogo2,
  reviewLogo3,
  reviewLogo4,
  closeBlue,
  penBlue,
  deleteBlue,
  starRate,
  description,
  descriptionWhite,
  scaleScreen,
  videoCameraChat,
  phoneCall,
  voice,
  voiceDisabled,
  videoCameraDisabled,
  personBlue,
  chatBlue,
  personGray,
  chatGray,
  arrowDownBlue,
  orangeCross,
  redCross,
  document,
  waitingRoom,
  cameraWhite,
  sendMsgBlack,
  dentistSquare,
  chiropractorSquare,
  deleteRound,
  copy,
  phone,
  email,
  arrowBlue,
  photoID,
  PhysicalTherapists,
  starGold,
  videovisit,
  design,
  design2,
  designyellow,
  doctor5,
  builddoctor1,
  builddoctor2,
  builddoctor3,
  builddoctor4,
  footerLogo,
  logohiddenttopright,
  logohiddenbottomleft,
  listyourPractice,
  design3,
  doctorprofileimg,
  advisoryboard1,
  advisoryboard2,
  advisoryboard3,
  advisoryboard4,
  advisoryboard5,
  advisoryboard6,
  advisoryboard7,
  advisoryboard8,
  advisoryboard9,
  practice_bg1,
  practice_bg2,
  hiddendesign3,
  dcartical3,
  okstar,
  ok,
  healthgrades,
  google,
  advisoryboard10,
  file,
  apple,
  advisoryboard11,
  advisoryboard12,
  advisoryboard13,
  androidstore,
  applestore,
  graph_icon,
  print,
  feedbackok,
  likeIcon,
  UnlikeIcon,
  likeIconGreen,
  UnlikeIconRed,
  likeFill,
  linkedIn,
  invite_icon,
  documentplus,
  okthanks,
};
export default images;
