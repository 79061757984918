import React, {useEffect, useState} from 'react';
import modalConfig from 'modals/modalConfig';
import {Link} from 'react-router-dom';
import images from 'data/images';
import CircleButton from 'components/CircleButton';
import validators from 'data/validators';
import Input from 'components/FormControls/Input';
import config from 'config/config';
import userService from 'services/user';
import allRegex from 'data/regex';
const {signIn} = userService;
export function Passwordrequired(props) {
  const {setGlobal} = props.context;
  const {user, provider} = props;
  const {modalFunctions} = modalConfig;
  const {blankModalData,errorModalData} = modalFunctions;
  const { __required, __maxLength, __email} = validators;
  const {MAX_TEXT_LENGTH} = config;
  //const {nameRegex} = allRegex;
  const showName = false; // user?.firstName;
  const LoginComponent = props => {
    const {closeModal} = props;
    const values = {};
    const errors = {};
    const [formData, setFormData] = useState({
      values,
      errors,
    });
    useEffect(() => {
      if (user?.email) {
        setFormData({
          ...formData,
          values: {
            ...formData.values,
          password:'',
            email: user?.email,
            type: 'patient',
            rememberMe: false,
          },
        });
      }
     // console.log('user', user);
    }, [user]);
    const {values: formValues, errors: formErrors} = formData;
    const getGeneralInputProps = controlName => {
      return {
        onChange: changeHandler,
        onBlur: changeHandler,
        error: formErrors[controlName],
        value: formValues[controlName],
        name: controlName,
      };
    };

   
    const __validateEmail = (value = null) => {
      const fValue = value !== null ? value : formValues['email'];
      if (!__required(fValue)) {
        return 'Email is required';
      }
      if (!__maxLength(fValue, MAX_TEXT_LENGTH)) {
        return `Max ${MAX_TEXT_LENGTH} characters`;
      }
      if (!__email(fValue)) {
        return 'Invalid Email';
      }
      return null;
    };
    const __validatePassword = (value = null) => {
      // const {values: formValues} = this.state;
      const fValue = value !== null ? value : formValues['password'];
      if (!__required(fValue)) {
        return 'Password is required';
      }
      return null;
    };
    const __validateForm = () => {
     // const validEmail = !__validateEmail();
      const validPassword = !__validatePassword();
     // return validEmail && validPassword;
     return validPassword
    };
  
    const isValidForm = __validateForm();
    const changeHandler = event => {
      const {name, value} = event.target;
      let errorObject = {
        [name]: null,
      };
      switch (name) {
        
        case 'email':
          errorObject = {
            [name]: __validateEmail(value),
          };
          break;
          case 'password':
        errorObject = {
          [name]: __validatePassword(value),
        };
        break;
          break;
        default:
          break;
      }
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          [name]: value,
        },
        errors: {
          ...formData.errors,
          ...errorObject,
        },
      });
    };
    const handleError = error => {
      const {message} = error;
      setGlobal('modal', errorModalData(message));
      return null;
    };
    const onSubmit = async () => {
     
    //  const response = await signIn(formValues)
     
    props.resolve({password: formValues['password']});
    closeModal();
      // if (provider === 'apple' && !showName) {
      //   props.resolve({
      //     dob: formValues['dateOfBirth'],
      //     user: {
      //       firstName: formValues['firstName'],
      //       lastName: formValues['lastName'],
      //       email: formValues['email'],
      //     },
      //   });
      //   closeModal();
      // } else {
      //   props.resolve({dob: formValues['dateOfBirth']});
      //   closeModal();
      // }
    };
    return (
      <div className="CreatePasswordModal termsofuse">
        <div className="modal-content-header">
          <div className="h3">Password Required</div>
          <span
            className="close"
            onClick={() => {
              props.reject(true);
              closeModal();
            }}>
            <img src={images.close} alt="close" />
          </span>
        </div>
        <div className="modal-content-body">
          
              {/* <Input
                label="Enter Email"
                name="email"
                placeholder={'Enter Email'}
                // instructionText={'Your email account must be valid and monitored.'}
                {...getGeneralInputProps('email')}
              /> */}
              {user.message && (
                <p>{user.message}</p>
              )}
              
               <Input
                {...getGeneralInputProps('password')}
                type="password"
                translationType="transform"
                icon={images.eyeView}
                label="Password"
                containerClass="password-group"
              />
        </div>
        <div className="modal-content-footer">
          <CircleButton
            disabled={!isValidForm}
            onClick={() => {
              onSubmit();
            }}>
            Continue
          </CircleButton>
        </div>
      </div>
    );
  };
  return new Promise((resolve, reject) => {
    // resolve("ok")
    setGlobal(
      'modal',
      blankModalData({
        CustomComponent: LoginComponent,
        customComponentProps: {resolve: resolve, reject: reject},
        closeOnBGClick: false,
      }),
    );
  });
}
